import React, { createRef } from 'react';
import './UserManagement.css';
//Redux Import
import { connect } from 'react-redux';
import md5 from 'md5';
import FileBase64 from 'react-file-base64';
import Moment from 'react-moment';

//import RealTimeAlert from '../Navbar/RealTimeAlert';
import PopupNotification from '../../popupNotification/PopupNotification'
import Checkbox from './Checkbox'

class UserRoleDescription extends React.Component {
    constructor(props) {
        super();
 
    }
 

    render() {
        return  ( <div className="UserRoleDescriptionContainer">
            User Privilege
            <br />
            <br />
            <div className="UserRoleDescriptionInnerRow">
                <div className="UserRoleDescriptionTitle">System Administrator access</div>

                <div className="UserRoleDescriptionInnerColumn">
                    <div> "User Management"</div>
                    <div> "Audit Trial Log"</div>

                </div>
            </div>
            <div className="UserRoleDescriptionInnerRow">
                <div className="UserRoleDescriptionTitle">Engineer access</div>

                <div className="UserRoleDescriptionInnerColumn">
                    <div> "Coldbox Management"</div>
                    <div> "LoRaWAN Gateway Management"</div>
                    <div> "BLE Beacon Management"</div>
                    <div> "Coldbox Location"</div>
                    <div> "Coldbox Operator Overview"</div>
                    <div> "Data Trend"</div>
                    <div> "Alarm (Warning & Fault)"</div>
                    <div> "Event"</div>

                </div>
            </div>
            <div className="UserRoleDescriptionInnerRow">
                <div className="UserRoleDescriptionTitle">Operator access</div>

                <div className="UserRoleDescriptionInnerColumn"> 
                    <div> "Coldbox Location"</div>
                    <div> "Coldbox Operator Overview"</div>
                    <div> "Data Trend"</div>
                    <div> "Alarm (Warning & Fault)"</div>
                    <div> "Event"</div>

                </div>
            </div>
        </div>
        )
    }
}

//Redux inside component function
function mapStateToProps(state) {
    //console.log(state);
    return {
        UserInfo: state.UserInfo,
        UserManageInfo: state.UserManageInfo,
        UserManageUserList: state.UserManageUserList,
        socket: state.socket
    };
}

export default connect(mapStateToProps)(UserRoleDescription);