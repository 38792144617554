import React from 'react';
//Redux Import
import { connect } from 'react-redux';
import './SummaryCard2.css';

import Loading from '../../../loading';

class SummaryCard2 extends React.Component {
    constructor(props)
    {
        super();
        this.state= {
            OccupancyStatus: false,
            didFoundItem: false
        }

        this.SetOccupancy = this.SetOccupancy.bind(this)
    }

    componentDidMount()
    {
        let thisoutside = this;
        this.SetOccupancy(thisoutside.props)
    }

    componentWillReceiveProps(nextProps) {
        this.SetOccupancy(nextProps)
    }

    SetOccupancy(props)
    {
        const { UserInfo, DeviceData, currentSite, currentlevel } = props
        if(UserInfo == null || DeviceData == null) return
        const CurrentFloorItem = DeviceData[currentSite].nestedLocs.find(function(floorItem) {return floorItem.locName == currentlevel})
        const DeviceList = CurrentFloorItem.nestedLocs
        //console.log(DeviceList)

        let isDeviceFound = false
        let isOccupied = false
        try
        {
            DeviceList.forEach(DeviceItem => {
                const payloadData = DeviceItem.payload
                if(payloadData != null && payloadData.roomOccupied != null) {
                    //have reading
                    isDeviceFound = true                                            // show there is some devices
                    if(payloadData.roomOccupied == true) isOccupied = true          // one occupied, show occupied in this widget
                }
            })
        }
        catch(err)
        {
            console.error('SummaryCard get Occupany Error')
        }
        //console.error(OccuResult);
        this.setState({
            OccupancyStatus: isOccupied,
            didFoundItem: isDeviceFound
        })
    }

    render() {
        //Since there is no pple count data, make Occupancy status card
        let { UserInfo, DeviceData, currentSite, currentlevel } = this.props
        if(UserInfo == null || DeviceData == null)
        {
            return <Loading/>
        }
        
        //let OccupancyStatus = false;
        const {OccupancyStatus, didFoundItem} = this.state
        // console.log(OccupancyStatus)
        // console.log(didFoundItem)


        let OccupancyStatusClass = " OccupancyFalse"
        let OccupancyStatusLbl = "No People"
        if(OccupancyStatus)
        {
            OccupancyStatusClass = " OccupancyTrue"
            OccupancyStatusLbl = "Occupied"
        }
        if(!didFoundItem)
        {
            OccupancyStatusLbl = "No Device"
        }
        return <div className="smallcard_right_top">
            <div className="text-left small_head">
                <i className="fa fa-users headericon"></i>
                Occupancy
            </div>
            <div className="flex justify-between flex-col small_body">
                <div className="flex flex-col level1 box2_level1">
                    <div className={"icon_pplcount fa fa-users" + OccupancyStatusClass}>                        
                    </div>
                    <div className={"lbl_Occupancy "+ OccupancyStatusClass}>
                        {OccupancyStatusLbl}
                    </div>
                </div>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    //console.log(state.UserInfo);
    return {
      count: state.count,
      log: state.log,
      socket: state.socket,
      level: state.level,
      currentlevel: state.currentlevel,
      SiteList: state.SiteList,
      currentSite: state.currentSite,
      MenuDisplay: state.MenuDisplay,
      currentBottomMenu: state.currentBottomMenu,
      currentBottomCard: state.currentBottomCard,
      DashboardNeedReload: state.DashboardNeedReload,
      DashboardData: state.DashboardData,
      UserInfo: state.UserInfo,
      DeviceData: state.DeviceData,
      DeviceDataLastUpdate: state.DeviceDataLastUpdate              //try trigger render after data is udpated
    };
}
export default connect(mapStateToProps)(SummaryCard2);