import React from 'react'
import './carbonDetect.scss'
import Moment from 'react-moment'

const BotCarbonDetect = props => {
    const {deviceItem, hoverFunc, cardnumber, isFocus} = props
    const status = deviceItem==null||deviceItem.payload==null?
        false
        :
        deviceItem.payload.carbon==false||deviceItem.payload.carbon==0?
            false
            :
            true
    
    return <div className='botCarbonCard' style={{
        background: isFocus?'var(--focuscardbackground)':'',
        color: isFocus?'white':''
    }} onMouseOver={() => hoverFunc(cardnumber)}>
        <div className='title'>{deviceItem==null?'--':deviceItem.locName}</div>
        <div className='status' style={{background: !status?'green':'red'}}>
            {!status?'Normal':'Fault'}
        </div>
        <div className='bot'>Last Update:{getLastUpdate(deviceItem)}</div>
    </div>
}
export default BotCarbonDetect

const getLastUpdate = deviceItem => {
    if(deviceItem == null || deviceItem.payload == null) return '--'
    const lastUpdate = deviceItem.payload.updateDate //deviceItem.payload.lastUpdate  // deviceItem.lastUpdate
    if(lastUpdate==null||lastUpdate=='') return ''
    return <Moment format='DD/MM HH:mm'>{lastUpdate}</Moment>
}