import React from 'react';

//Redux Import
import { connect } from 'react-redux';

class MenuListItem extends React.Component {
    constructor(props) {
        super(props);

        this.ListMenuPicked = this.ListMenuPicked.bind(this);
    }

    ListMenuPicked(Event) {
        //console.log(Event.currentTarget.children[0].id);
        this.props.pickeditem(Event.currentTarget.children[1].id);  //pick the second chidren item and get its id, put inside the input value
        //Redux would be called outside
    }

    render() {
        let listfocus = "";

        let { title, name } = this.props;

        //highlight the area card if it is hovered
        if(this.props.currentBottomMenu === name)
        {
            listfocus = "listfocus";
        }

        //for different menu item, use different icon
        let iconClassName = "book";
        if(name === "bottom1")
        {
            iconClassName = "thermometer-half";
        }
        else if(name === "bottom2")
        {
            iconClassName = "users";
        }
        else if(name === "bottom3")
        {
            iconClassName = "bolt";
        }
        else
        {
            iconClassName = "battery fa-rotate-270";
        }

        return <div className={"flex flex-row list " + listfocus} onClick={ this.ListMenuPicked } >
            <i className={"fa fa-" + iconClassName + " menucardicon"}></i>
            <div className="text-left menucardtitle" id={name}>
                {title}
            </div>
        </div>

        //return <div className={"list " + listfocus} onClick={ this.ListMenuPicked } >
        //    <div className="text-left" id={name}>
        //        { title }
        //    </div>
        //</div>
    }
}


//Redux inside component function
function mapStateToProps(state) {
    return {
      count: state.count,
      log: state.log,
      socket: state.socket,
      level: state.level,
      currentlevel: state.currentlevel,
      SiteList: state.SiteList,
      currentSite: state.currentSite,
      MenuDisplay: state.MenuDisplay,
      currentBottomMenu: state.currentBottomMenu
    };
}

export default connect(mapStateToProps)(MenuListItem);  