import React from 'react'
import './Coldbox_GroupDiff.css'

//Redux Import
import { connect } from 'react-redux'

//import inner widgets
import PageBtns from './pagebtns/pagebtns'
import Popup from './popup/popup'
import Searchbar from './searchbar/searchbar'
import Table from './table/table'
import TableHead from './table/tablehead'

class ColdboxGroupDiff extends React.Component {
    constructor(props)
    {
        super()

        this.state = {
            isPopup: false,         //when true, show the popup coldbox single screen
        }

        this.backpage = this.backpage.bind(this)
        this.statusPage = this.statusPage.bind(this)

        this.openPopup = this.openPopup.bind(this)
        this.closePopup = this.closePopup.bind(this)
    }
    backpage() {
        this.props.history.goBack()
    }
    statusPage() {
        this.props.history.push('/Main/FloorPlan/ColdboxStatus')
    }
    openPopup() {
        this.setState({
            isPopup: true
        })
    }
    closePopup() {
        this.setState({
            isPopup: false
        })
    }

    render()
    {
        const {isPopup} = this.state

        return [
        <div className="Coldbox_GDPage">
            <Searchbar backpage={this.backpage} statusPage={this.statusPage} />
            <div className="Coldbox_GD_TableMain">
                <TableHead />
                <Table openPopup={this.openPopup} />
            </div>
            <PageBtns/>
        </div>,
        <Popup isPopup={isPopup} closePopup={this.closePopup} />,
        ]
    }
}

function mapStateToProps(state) {
    return {
    }
}
  
export default connect(mapStateToProps)(ColdboxGroupDiff)