import './LiftGraph.css'
import React from 'react'
//Redux Import
import { connect } from 'react-redux'
import { isNumber } from 'highcharts'

//inner widgets
import LiftAcc from './LiftAcc'
import RangeLimit from './RangeLimit'
import DoorStatus from './DoorStatus'
import IAQStatus from './IAQStatus'
import PplDetect from './PplDetect'

class LiftGraph extends React.Component {
    constructor(props)
    {
        super(props);

        this.state = {
            Range: "In Range",
            isDoorOpen: false,
            havePeople: false,
        };

        this.TopOutRangeSet = this.TopOutRangeSet.bind(this);
        this.BottomOutRangeSet = this.BottomOutRangeSet.bind(this);
        this.InRangeSet = this.InRangeSet.bind(this);

        this.OpenDoor = this.OpenDoor.bind(this);
        
        this.SetPeople = this.SetPeople.bind(this);
    }
    TopOutRangeSet() {
        this.setState({
            Range: "Top Out Range"
        });
    }
    BottomOutRangeSet() {
        this.setState({
            Range: "Bot Out Range"
        });
    }
    InRangeSet() {
        this.setState({
            Range: "In Range"
        });
    }
    OpenDoor() {
        let {isDoorOpen} = this.state;
        this.setState({
            isDoorOpen: !isDoorOpen
        })
    }
    SetPeople() {
        let {havePeople} = this.state;
        this.setState({
            havePeople: !havePeople
        });
    }
    render()
    {
        // //FIND DEVICE ITEM
        // const {DeviceData, currentSite, currentlevel, UserInfo, currentlevelID} = this.props;
        // if(currentlevel == null || currentSite == null || DeviceData == null) return <div></div>        //exception case
        // const FindSite = DeviceData[currentSite]
        // if(FindSite == null) return <div></div>             //2nd exceptional case
        // const FindLevel = FindSite.nestedLocs.find(function(levelItem) {
        //     return (levelItem.locName == currentlevel) || (levelItem.locName == currentlevel.toString())
        // })
        // console.log(FindLevel)
        // const Payload = FindLevel.nestedLocs[0].payload
        // console.log(Payload)

        // //ARRAOW STATUS AND ACCELERATION READINGS
        // let XUP_class = ""
        // let XDN_class = ""
        // let YUP_class = ""
        // let YDN_class = ""
        // let ZUP_class = ""
        // let ZDN_class = ""
        // if(Payload.accel_X != null && Payload.accel_X > 0) XUP_class = "LiftArrowOn"
        // if(Payload.accel_X != null && Payload.accel_X < 0) XDN_class = "LiftArrowOn"
        // if(Payload.accel_Y != null && Payload.accel_Y > 0) YUP_class = "LiftArrowOn"
        // if(Payload.accel_Y != null && Payload.accel_Y < 0) YDN_class = "LiftArrowOn"
        // if(Payload.accel_Z != null && Payload.accel_Z > 0) ZUP_class = "LiftArrowOn"
        // if(Payload.accel_Z != null && Payload.accel_Z < 0) ZDN_class = "LiftArrowOn"
        // let X_value = 0
        // let Y_value = 0
        // let Z_value = 0
        // if(Payload.accel_X != null && isNumber(Payload.accel_X)) X_value = Payload.accel_X.toFixed(2)
        // if(Payload.accel_Y != null && isNumber(Payload.accel_Y)) Y_value = Payload.accel_Y.toFixed(2)
        // if(Payload.accel_Z != null && isNumber(Payload.accel_Z)) Z_value = Payload.accel_Z.toFixed(5)

        // //IAQ ADN FLOOR DETECTION READINGS
        // let SumTemp = 0
        // let CountTemp = 0
        // let SumHumid = 0
        // let CountHumid = 0
        // let isDoorClose = true;
        // let isFloorDetect = false;
        // let isLiftOccupied = false;
        // FindLevel.nestedLocs.forEach(deviceItem => {
        //     if(deviceItem.payload != null) {
        //         const DevicePayload = deviceItem.payload
        //         if(DevicePayload.temperature != null) {
        //             SumTemp += DevicePayload.temperature
        //             CountTemp++
        //         }
        //         if(DevicePayload.humidity != null) {
        //             SumHumid += DevicePayload.humidity
        //             CountHumid++
        //         }
        //         if(DevicePayload.doorClose != null) isDoorClose = DevicePayload.doorClose
        //         if(DevicePayload.floorDetection != null) isFloorDetect = DevicePayload.floorDetection
        //         if(DevicePayload.liftOccupied != null) isLiftOccupied = DevicePayload.liftOccupied
        //     }
        // })
        // let humid_value = 0
        // let temp_value = 0
        // if(CountTemp != 0) temp_value = (SumTemp/CountTemp).toFixed(1)
        // else temp_value = "--"
        // if(CountHumid != 0) humid_value = (SumHumid/CountHumid).toFixed(0)
        // else humid_value = "--"
        // let MoveLiftClass = ""
        // let highlightTop = ""
        // let highlightMid = ""
        // if(!isFloorDetect) {    //false == out of range
        //     MoveLiftClass = "LiftRangeBotOutRange"
        //     highlightTop = "LiftRangeHighlightRed"
        // }
        // else highlightMid = "LiftRangeHighlight"
        // let DoorStatusClass = ""
        // let DoorStatusDisplayText = "Doors Closed"
        // if(!isDoorClose) {
        //     DoorStatusClass = "LiftDoorOpenStatus"
        //     DoorStatusDisplayText = "Doors Open"
        // }
        // let HavePeopleClass = ""
        // if(isLiftOccupied) HavePeopleClass = "LiftGraph_HavePeopleStatus"

        return <div className="Lift_graphContainer">
            <LiftAcc/>
            <RangeLimit/>
            <div className="Lift_graphDirection2">
                <DoorStatus/>
                <IAQStatus/>
                <PplDetect/>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
      socket: state.socket,
      UserInfo: state.UserInfo,
      currentSite: state.currentSite,
      currentlevel: state.currentlevel,
      DeviceData: state.DeviceData,
      currentlevelID: state.currentlevelID,
      DeviceDataLastUpdate: state.DeviceDataLastUpdate
    };
}
export default connect(mapStateToProps)(LiftGraph);