import React, { useEffect, useState } from 'react'
import './CleanSchedule.css'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faHistory, faSpinner, faRedo } from '@fortawesome/free-solid-svg-icons'
import getColorCode from './commonfunction'

const ScheduleUpdateInterval = 30       //minutes

function CleanScheduleWidget(props) {
    const {ToiletType, socket, currentSite, currentlevel, DeviceData, UserInfo} = props

    const [start, setStart] = useState(new Date())
    const [end, setEnd] = useState(new Date())
    const [ResponseData, setResponseData] = useState(null)      //schedule response data storage

    const {color1, color2, gradient1, gradient2} = getColorCode(ToiletType)

    const [DisplayItems, setDisplayItems] = useState({
        NextMaintenance: '--',
        ProgressPercent: 0,
    })
    useEffect(() => {
        let IntervalFunction = setInterval(tickingTimer, 500)
        let RequestIntervalFunction = setInterval(RequestScheduleFromServer, 1000*60*ScheduleUpdateInterval)
        let locallistener = socket.on("Toilet_schedule", data => {
            console.log(data)
            //update the localstate that relative to schedule
            if(data == null || data.result == null) return
            if(data.result == "Success" && data.method == "Read") {
                setResponseData(data.data)
            }
        })
        return function TerminateInterval() {
            clearInterval(IntervalFunction)
            clearInterval(RequestIntervalFunction)
            socket.off("Toilet_schedule")
        }
    }, [])
    useEffect(() => {
        RequestScheduleFromServer()         //when chaneed toilet to display, update the data
    }, [ToiletType, currentSite, currentlevel])

    useEffect(() => {   // when response data is received, update the clean start and end time
        let PreTime = null
        let NxtTime = null
        let currentTime = new Date()
        currentTime.setTime(currentTime.getTime() + (+8*60*60*1000))

        const ReponseList = ResponseData==null?[]:ResponseData
        ReponseList.forEach(ScheduleItem => {
            const {scheduleTime, startClean, toiletType} = ScheduleItem
            if(toiletType == null) return
            if(ToiletType.toString() != toiletType.toString()) return

            let RecordCleanTime = null
            if(startClean == null || startClean == "1970-01-01T00:00:00.000Z") RecordCleanTime = Date.parse(scheduleTime.toString())
            else RecordCleanTime = Date.parse(startClean.toString())

            let isPre = currentTime > RecordCleanTime
            if(isPre) {
                //previous records
                if(PreTime == null) PreTime = RecordCleanTime
                else if(PreTime < RecordCleanTime) PreTime = RecordCleanTime
            }
            else {
                //next records
                if(NxtTime == null) NxtTime = RecordCleanTime
                else if(NxtTime > RecordCleanTime) NxtTime = RecordCleanTime
            }
        })
        setStart(PreTime)
        setEnd(NxtTime)
    }, [ResponseData, ToiletType])

    function tickingTimer() {
        const {NextMaintenance, ProgressPercent} = PreNextDiff(start, end)
        setDisplayItems({
            NextMaintenance: NextMaintenance,
            ProgressPercent: ProgressPercent,
        })
    }
    function RequestScheduleFromServer() {
        setResponseData(null)
        //request again
        const EmitJson = EmitJsonData(ToiletType, socket, currentSite, currentlevel, DeviceData, UserInfo) 
        console.log(EmitJson)
        props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "StatRequest",
            EmitSocketData: EmitJson
        })
    }
    
    if(start == null && end == null) {
        return <div className="Toilet_cln_row1" style={{background: 'linear-gradient(to right, '+gradient1+', '+gradient2+')'}}>
            <div className="Toilet_cln_col1">
                <FontAwesomeIcon icon={faUser} className="fa" />
            </div>
            <div className="Toilet_cln_loading">
                <FontAwesomeIcon icon={faSpinner} className="fa" />
            </div>
        </div>
    }
    return <div className="Toilet_cln_row1" style={{background: 'linear-gradient(to right, '+gradient1+', '+gradient2+')'}}>
        <div className="Toilet_cln_col1">
            <FontAwesomeIcon icon={faUser} className="fa" />
        </div>
        <div className="Toilet_cln_col3">
            <div className="Toilet_cln_title">Last Clean Service</div>
            <div className="Toilet_cln_value">
                {GenDisplayDate(start)}
            </div>
        </div>
        <div className="Toilet_cln_col5">
            <div className="Toilet_cln_title">Next Clean Service</div>
            <div className="Toilet_cln_value">
                {GenDisplayDate(end)}
            </div>
        </div>
        <div className="Toilet_cln_refresh" onClick={RequestScheduleFromServer}>
            <FontAwesomeIcon icon={faRedo} className="fa" />
        </div>
    </div>
}

function mapStateToProps(state) {
    return {
        ToiletType: state.ToiletType,
        socket: state.socket,
        currentSite: state.currentSite,
        currentlevel: state.currentlevel,
        DeviceData: state.DeviceData,
        UserInfo: state.UserInfo,
    }
}

export default connect(mapStateToProps)(CleanScheduleWidget)

//Calculation functions
function PreNextDiff(start, end) {
    var DateNow = new Date()
    var SecDiff = parseInt((end-start)/1000)
    var nextCleanSec = parseInt((end - DateNow)/1000)
    var ProgressPercent = (SecDiff - nextCleanSec)/SecDiff * 100
    var cleanHr = parseInt(nextCleanSec/60/60)
    var cleanMin_remain = nextCleanSec % 3600
    var cleanMin = parseInt(cleanMin_remain/60)
    var cleanSec_remain = nextCleanSec % 60
    return {
        NextMaintenance: AutoFillZero(cleanHr) + ":" + AutoFillZero(cleanMin) + ":" + AutoFillZero(cleanSec_remain),
        ProgressPercent: ProgressPercent,
    }
}
function GenDisplayDate(DateValue) {
    if(DateValue == null) return '--'
    let DateValue_date = new Date(DateValue)
    DateValue_date.setTime(DateValue_date.getTime() + (-8*60*60*1000))
    return AutoFillZero(DateValue_date.getHours()) + ':' + AutoFillZero(DateValue_date.getMinutes())
}
function AutoFillZero(inputIndex) {
    if(inputIndex < 10) return '0' + inputIndex.toString()
    return inputIndex
}

//Request Format Function
function EmitJsonData(ToiletType, socket, currentSite, currentlevel, DeviceData, UserInfo) {
    const {SiteID, SiteName} = GetSiteID(DeviceData, currentSite)
    const {LevelID, LevelName} = GetLevelID(DeviceData, currentSite, currentlevel)
    const {start, end} = GetStartEndRange()
    const client_id = UserInfo.client_id
    const ReturnResult = {
        "msgType": "Toilet_schedule",
        "method": "Read",
        "client_id": client_id,
        "locationInfo": [
            {
                "id": SiteID,
                "lvl": 1,
                "name": SiteName
            },
            {
                "id": LevelID,
                "lvl": 2,
                "name": LevelName
            }
        ],
        "toiletType": ToiletType.toString(), //1 = male, 2 = female, 3 = other
        "start": convertDate(start),
        "end": convertDate(end),
    }
    return ReturnResult
}
function GetSiteID(DeviceData, currentSite) {
    try {
        const SiteItem = DeviceData[currentSite]
        const SiteID = SiteItem._id
        const SiteName = SiteItem.locName
        return {
            SiteID: SiteID,
            SiteName: SiteName
        }
    }
    catch(err) {
        return err
    }
}
function GetLevelID(DeviceData, currentSite, currentlevel) {
    try {
        const LevelList = DeviceData[currentSite].nestedLocs
        let LevelID = "No Level ID found"
        let LevelName = "No Level Name found"
        LevelList.forEach(LevelItem => {
            if(LevelItem.locName == currentlevel || LevelItem.locDesc == currentlevel) {
                LevelID = LevelItem._id
                LevelName = LevelItem.locName
            }
        })
        return {
            LevelID: LevelID,
            LevelName: LevelName,
        }
    }
    catch(err) {
        return err
    }
}
function GetStartEndRange() {
    var StartDate = new Date()
    var EndDate = new Date()
    StartDate.setDate(StartDate.getDate() - 2)
    EndDate.setDate(EndDate.getDate() + 2)
    return {
        start: StartDate,
        end: EndDate,
    }
}

function convertDate(DateInput)
{
    let DateMonth = DateInput.getMonth() + 1
    let DateDay = DateInput.getDate()
    if(DateMonth < 10) DateMonth = "0" + DateMonth
    if(DateDay < 10) DateDay = "0" + DateDay
    let HourStr = DateInput.getHours()
    if(HourStr < 10) HourStr = "0" + HourStr
    let MinutesStr = DateInput.getUTCMinutes()
    if(MinutesStr < 10) MinutesStr = "0" + MinutesStr
    const DateStr = DateInput.getFullYear() + "-" + DateMonth + "-" + DateDay + "T" + HourStr + ":" + MinutesStr + ":00Z"
    return DateStr
}