import React from 'react'
import './carpark.css'
import { connect } from 'react-redux'
import { faCar, faWheelchair } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CardContainer from '../../../common/cardContainer/cardContainer'
import {getTxt} from '../../../common/language/language'

function carparkwidget(props) {
    const {countA, totalA, countB, totalB} = getData(props)
    return <CardContainer icon={faCar} head={getTxt('Dashbd', 'sensorTitle', 'carpark')}>
        <div className='carpark'>
            <div className='row'>
                <div className='img car'>
                    <FontAwesomeIcon icon={faWheelchair} className='fa' />
                </div>
                <div className='txt'>{(totalA==0)?'-/-':(totalA-countA)+'/'+totalA}</div>
            </div>
            <div className='row'>
                <div className='img car'>
                    <FontAwesomeIcon icon={faCar} className='fa' />
                </div>
                <div className='txt'>{(totalB==0)?'-/-':(totalB-countB)+'/'+totalB}</div>
            </div>
        </div>
    </CardContainer>
}

function mapStateToProps(state) {
    return {
        DeviceData: state.DeviceData,
        currentSite: state.currentSite,
        currentlevel: state.currentlevel,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate,
    }
}
export default connect(mapStateToProps)(carparkwidget)

function getData(props) {
    const locList = getLocList(props)
    const relatedList = locList.filter(item => {
        if(item.payload_definition_id==null) return false
        if(item.payload_definition_id.nameID=='Car Park'||item.payload_definition_id.nameID=='Disabled Parking') return true
        return false
    })

    const {countA, totalA, countB, totalB} = separateData(relatedList)

    return {
        countA: countA,
        totalA: totalA,
        countB: countB,
        totalB: totalB
    }
}

// sub function
function getLocList(props) {
    const {DeviceData, currentSite, currentlevel} = props
    if(DeviceData==null) return []

    const site = DeviceData[currentSite]
    if(site == null || site.nestedLocs == null) return []

    const lvl = site.nestedLocs.find(item => item.locName == currentlevel)
    if(lvl == null || lvl.nestedLocs == null) return []

    return lvl.nestedLocs
}
function separateData(dataList) {
    if(dataList == null||dataList.length==null) return

    console.log(dataList)
    let countA = 0
    let totalA = 0
    let countB = 0
    let totalB = 0

    dataList.forEach(item => {
        if(item.payload_definition_id!=null&&item.payload_definition_id.nameID=='Disabled Parking') {
            totalA++
            if(item.payload!=null&&(item.payload.occupied=='occupied'||item.payload.occupied==true)) countA++
        }
        if(item.payload_definition_id!=null&&item.payload_definition_id.nameID=='Car Park') {
            totalB++
            if(item.payload!=null&&(item.payload.occupied=='occupied'||item.payload.occupied==true)) countB++
        }
    })

    return {
        countA: countA,
        totalA: totalA,
        countB: countB,
        totalB: totalB
    }
}