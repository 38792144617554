import React from 'react'
import './IEQ.scss'

const IEQ = (props) => {
    const {payload, locName, isFocus, cardnumber, DeviceDataLastUpdate} = props
    const {hoverFunc} = props
    const focusStyle = isFocus?{background: 'var(--focuscardbackground', color: 'white'}:{}

    const keys_list = locName.includes('NT-IAQ')?keys_NT:keys

    return <div className='IEQbtnCard' onMouseOver={() => hoverFunc(cardnumber)} style={focusStyle}>
        <div className='loc'>{locName}</div>
        <div style={{display: 'none'}}>{DeviceDataLastUpdate.toString()}</div>
        <div className='main'>
        {
            keys_list.sort((a, b) => {
                if(a.key > b.key) return 1
                if(a.key < b.key) return -1
                return 0
            })
            .map(item => {
                return Reading(item, payload)
            })
        }
        </div>
    </div>
}
export default IEQ

const Reading = (item, payload) => {
    const {title, key, decimal, unit} = item
    if(payload==null||payload[key] == null) return <div style={{display: 'none'}}></div>

    return <div className='row'>
        <div className='title'>{title}</div>
        <div className='value'>
            {Number(payload[key].toString()).toFixed(decimal)}
            <div className='unit'>{unit}</div>
        </div>
    </div>
}
const keys = [
    {
        title: 'Temp',
        key: 'temperature',
        decimal: 1,
        unit: '°C'
    },
    {
        title: 'Humid',
        key: 'humidity',
        decimal: 1,
        unit: '%'
    },
    {
        title: 'HCHO',
        key: 'hcho',
        decimal: 1,
        unit: 'ppm'
    },
    {
        title: 'CO',
        key: 'co',
        decimal: 1,
        unit: 'ppm'
    },
    {
        title: 'CO2',
        key: 'co2',
        decimal: 1,
        unit: 'ppm'
    },
    {
        title: 'O3',
        key: 'o3',
        decimal: 1,
        unit: 'ppb'
    },
    {
        title: 'PM1.0',
        key: 'pm01',
        decimal: 1,
        unit: 'µg/m³'
    },
    {
        title: 'PM2.5',
        key: 'pm2_5',
        decimal: 1,
        unit: 'µg/m³'
    },
    {
        title: 'PM10',
        key: 'pm10',
        decimal: 1,
        unit: 'µg/m³'
    },
    {
        title: 'NH3',
        key: 'nh3',
        decimal: 1,
        unit: 'ppb'
    },
    {
        title: 'NO2',
        key: 'no2',
        decimal: 1,
        unit: 'ppb'
    },
    {
        title: 'TVOC',
        key: 'tvoc',
        decimal: 1,
        unit: 'µg/m³'
    },
    {
        title: 'Light',
        key: 'light',
        decimal: 1,
        unit: 'lx'
    },
    {
        title: 'Motion',
        key: 'motion',
        decimal: 0,
        unit: ''
    },
    {
        title: 'Sound',
        key: 'sound',
        decimal: 1,
        unit: 'dB'
    }
]

const keys_NT = [
    {
        title: 'Temp',
        key: 'temperature',
        decimal: 1,
        unit: '°C'
    },
    {
        title: 'Humid',
        key: 'humidity',
        decimal: 1,
        unit: '%'
    },
    {
        title: 'HCHO',
        key: 'hcho',
        decimal: 1,
        unit: 'ppb'
    },
    {
        title: 'CO',
        key: 'co',
        decimal: 1,
        unit: 'ppm'
    },
    {
        title: 'CO2',
        key: 'co2',
        decimal: 1,
        unit: 'ppm'
    },
    {
        title: 'O3',
        key: 'o3',
        decimal: 1,
        unit: 'ppb'
    },
    {
        title: 'PM1.0',
        key: 'pm01',
        decimal: 1,
        unit: 'µg/m³'
    },
    {
        title: 'PM2.5',
        key: 'pm2_5',
        decimal: 1,
        unit: 'µg/m³'
    },
    {
        title: 'PM10',
        key: 'pm10',
        decimal: 1,
        unit: 'µg/m³'
    },
    {
        title: 'NH3',
        key: 'nh3',
        decimal: 1,
        unit: 'ppb'
    },
    {
        title: 'NO2',
        key: 'no2',
        decimal: 1,
        unit: 'ppb'
    },
    {
        title: 'TVOC',
        key: 'tvoc',
        decimal: 1,
        unit: 'µg/m³'
    },
    {
        title: 'Light',
        key: 'light',
        decimal: 1,
        unit: 'lx'
    },
    {
        title: 'Motion',
        key: 'motion',
        decimal: 0,
        unit: ''
    },
    {
        title: 'Sound',
        key: 'sound',
        decimal: 1,
        unit: 'dB'
    }
]