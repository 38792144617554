import React from 'react';
import './forgetPassword.css';

//Redux Import
import { connect } from 'react-redux';
import { timeout } from 'q';
import SoundPlay from './Sound/Sound'

class ForgetPassword extends React.Component {
    constructor(props)
    {
        super(props);

        this.state = {
            showPopup: false,
            confirmPopup: false,
            ShowMsg: 'Page Loading..',
            IsLoading: false
        };

        this.showPopupMsg = this.showPopupMsg.bind(this);
        this.closePopupMsg = this.closePopupMsg.bind(this);
        this.submitForgetpassword = this.submitForgetpassword.bind(this);
        this.redirectLoginpage = this.redirectLoginpage.bind(this);
        this.checkSubmitTimeout = this.checkSubmitTimeout.bind(this);

        this.inputref = React.createRef();
        this.inputEmailRef = React.createRef();

        this.soundRef = React.createRef()
    }

    redirectLoginpage()
    {
        this.props.history.push("/");
    }

    showPopupMsg()
    {
        this.soundRef.current.alarmMp3Run("click")
        this.setState({
            showPopup: true,
            confirmPopup: true,
            ShowMsg: 'Re-set your Password?'
        });
    }

    closePopupMsg()
    {
        this.soundRef.current.alarmMp3Run("hover")
        this.setState({
            showPopup: false
        });
    }

    submitForgetpassword()
    {
        this.soundRef.current.alarmMp3Run("click")
        //get the username
        let thisoutside = this;
        let username = this.inputref.current.value;
        let email = this.inputEmailRef.current.value;
        //alert(username);
        if(username == null || username == "")
        {
            thisoutside.setState({
                showPopup: true,
                confirmPopup: false,
                ShowMsg: 'Your Input Username is not correct, please re-try.'
            });
            return;
        }
        //check socket connection
        this.setState({
            showPopup: true,
            confirmPopup: false,
            ShowMsg: "Connecting to server.."
        });
        if(this.props.socket != null)
        {
            //if true, emit data
            if(!thisoutside.props.socket.connected)
            {
                //if false, show error
                thisoutside.setState({
                    showPopup: true,
                    confirmPopup: false,
                    ShowMsg: "Your server connection is disconnected, Please refresh and retry"
                });
                return;
            }
            thisoutside.setState({
                showPopup: true,
                confirmPopup: false,
                IsLoading: true,            //hang loading, for timeout checking
                ShowMsg: "Waiting Response from Server."
            });
            //emit data
            let emitData = {
                msgType: "ForgetPassword",
                username: username,
                email: email
            };
            console.log(emitData);
            //thisoutside.props.socket.emit("ForgetPassword", emitData);
            this.props.dispatch({ type: 'EmitSocket', 
                EmitSocketName: "ForgetPassword",
                EmitSocketData: emitData
            });

            setTimeout(function(){
                thisoutside.checkSubmitTimeout();
            }, 3000);
        }
        else
        {
            //if false, show error
            thisoutside.setState({
                showPopup: true,
                confirmPopup: false,
                ShowMsg: "Failed to connect the server. Please refresh the page to re-try."
            });
            return;
        }
    }

    checkSubmitTimeout()
    {
        let {IsLoading} = this.state;
        let thisoutside = this;
        if(IsLoading)
        {
            //submit timeout no response
            thisoutside.setState({
                showPopup: true,
                confirmPopup: false,
                ShowMsg: "Timeout. Please Retry."
            });
        }
    }

    componentDidCatch(error, info)
    {
        console.error('componentDidCatch() on forgetPassword.js');
        console.error(error);
        console.error(info);
        //redirect to App.js to reload all the datas
        this.props.history.push("/App");
    }

    componentDidMount()
    {
        let thisoutside = this;
        //check the socket connection
        if(this.props.socket != null)
        {
            if(this.props.socket.connected)
            {
                //if true, set the socket Listener
                //clear old listeners
                //this.props.socket.removeAllListeners(); //remove previous socket functions
                let thisoutside = this;
                //place the submit success socket listeners
                this.props.socket.on("ForgetPassword", data => {
                    console.log(data);
                    if(data.result == "Success")
                    {
                        //success                        
                        thisoutside.setState({
                            showPopup: true,
                            confirmPopup: false,
                            IsLoading: false,
                            ShowMsg: "Password is re-set! Please check the new password in your email."
                        });
                    }
                    else
                    {
                        //failed
                        thisoutside.setState({
                            showPopup: true,
                            confirmPopup: false,
                            IsLoading: false,
                            ShowMsg: "Re-set Password Failed: " + data.failed
                        });
                    }
                });
            }
            else
            {
                //if false, show the msgbox with warning
                thisoutside.setState({
                    showPopup: true,
                    confirmPopup: false,
                    ShowMsg: "You are disconnected to server! Please refresh the page."
                });
            }
        }
        else
        {
            thisoutside.setState({
                showPopup: true,
                confirmPopup: false,
                ShowMsg: "You are disconnected to server! Please re-try."
            });
            this.props.dispatch({ type: 'SocketCon' });
        }
    }

    render() {
        let hideForgetBox = "";
        if(!this.state.showPopup)
        {
            hideForgetBox = "hideForgetBox";
        }
        let hideForgetBtns = "";
        if(!this.state.confirmPopup)
        {
            hideForgetBtns = "hideForgetBtns";
        }
        return <div className="forgetpassword_maindiv">
            <div className="forgetpassword_header">
                Re-set Your Password
            </div>
            <div className="forgetpassword_description">
                Please enter your account name, we would send a new password to your email
            </div>
            <div className="forgetpassword_inputdiv">
                <div className="fa fa-user forgetpassword_inputIcon"></div>
                <input className="forgetpasswordInput" placeholder="Username" name="Username" autoFocus autoComplete="off" ref={this.inputref}></input>
            </div>
            <div className="forgetpassword_inputdiv">
                <div className="fa fa-address-book forgetpassword_inputIcon"></div>
                <input className="forgetpasswordInput" placeholder="Email" name="Email" autoComplete="off" ref={this.inputEmailRef}></input>
            </div>
            <div className="forgetpassword_btn" onClick={this.showPopupMsg}>
                Send Me New Password
            </div>
            <div className={"forgetpassword_msgbox " + hideForgetBox}>
                <div className="forgetpassword_msgbox_Msg">{this.state.ShowMsg}</div>
                <div className={"fa fa-check forgetpassword_acceptBtn " + hideForgetBtns} onClick={this.submitForgetpassword}>Confirm</div>
                <div className={"forgetpassword_cancelBtn "} onClick={this.closePopupMsg}>Cancel</div>
                <div className="fa fa-times forgetpassword_msgbox_closeBtn" onClick={this.closePopupMsg}></div>
            </div>
            <div className="fa fa-long-arrow-left forgetpassword_backBtn" onClick={this.redirectLoginpage}></div>
            <SoundPlay ref={this.soundRef}/>
        </div>
    };
}

//Redux inside component function
function mapStateToProps(state) {
    return {
      socket: state.socket,
      level: state.level,
      currentlevel: state.currentlevel,
      currentSite: state.currentSite,
      MenuDisplay: state.MenuDisplay
    };
  }
  
  export default connect(mapStateToProps)(ForgetPassword);