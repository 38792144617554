import React from 'react';
import './AlarmTrigger.css';

//Redux Import
import { connect } from 'react-redux';

import AlarmTriggerTable from './table/alarmTriggerTable';
import AlarmTriggerPopup from './popup/alarmTriggerPopup';

class AlarmTrigger extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            isPopup: false,
            currentTrigger: null
        };
        this.AddUser = this.AddUser.bind(this);
        this.editRow = this.editRow.bind(this);
        this.closepopup = this.closepopup.bind(this);
        
        this.AlarmTriggerPopupRef = React.createRef();
    }
    AddUser()       //add alarm triggers
    {
        this.setState({
            isPopup: true
        });

        //clean the device list inside the component
        let thisoutside = this;
        try
        {
            console.log(thisoutside.AlarmTriggerPopupRef.clearDeviceList);
            thisoutside.AlarmTriggerPopupRef.clearDeviceList();
        }
        catch(err)
        {
            console.log(err);
        }

        //set it to state
        this.setState({
            currentTrigger: null
        });
    }
    editRow(ListItem)
    {
        this.setState({
            isPopup: true
        });
        //get editing item
        console.log(ListItem);
        //set it to state
        this.setState({
            currentTrigger: ListItem
        });
    }
    closepopup()
    {
        this.setState({
            isPopup: false
        });
    }
    componentDidMount()
    {
        //set the url to storage (F5 would return to this page)
        let data = "/Main/AlarmTrigger";
        this.props.dispatch({ type: 'LatestPage', data });
    }
    render() {
        let {isPopup} = this.state;
        let popupClass = "";
        let thisoutside = this;
        if(!isPopup)
        {
            popupClass = "hideATPopup";
        }
        return [
        <AlarmTriggerTable AddUser={this.AddUser} editRow={this.editRow}/>,
        <AlarmTriggerPopup popupClass={popupClass} closepopup={this.closepopup} 
        currentTrigger={this.state.currentTrigger}
        onRef={ref => (this.AlarmTriggerPopupRef = ref)}/>
        ]
    }
}

//Redux inside component function
function mapStateToProps(state) {
    return {
      MenuDisplay: state.MenuDisplay,
      SiteList: state.SiteList,
      currentSite: state.currentSite,
      level: state.level,
      UserInfo: state.UserInfo
    };
}

export default connect(mapStateToProps)(AlarmTrigger);