import React from 'react';
import './App.css';
//Redux Import
import { connect } from 'react-redux';
import { width } from '@material-ui/system';
import { Redirect } from 'react-router-dom';

import SoundPlay from './Sound/Sound';

class App extends React.Component {
  constructor(props)
  {
    super(props);

    // this.SocketChecking = this.SocketChecking.bind(this);
    // this.timeoutChecking = this.timeoutChecking.bind(this);

    // this.DownloadData = this.DownloadData.bind(this);
    // this.DownloadFloorImages = this.DownloadFloorImages.bind(this);
    // this.redirectPage = this.redirectPage.bind(this);
    this.redirectHome = this.redirectHome.bind(this);

    // this.state = {
    //   displayString: "Start Redirect. If This message displayed a long time, please contact for help.",
    //   isLoading: false,
    //    progress: 0,
    //    subDisplay: "",
    //    totalFunctions: [0, 0],    //when download data from socket success, change it to 1
    //    isTimeOut: false           //show time out redirect button
    // };
  }

  // componentWillUnmount()
  // {
  //   //clear all the socket listeners before exit this page
  //   let {socket} = this.props;
  //   try
  //   {
  //     if(socket == null)
  //     {
  //       console.log('socket == null in App.js');
  //       return;
  //     }
  //     if(socket.connected)
  //     {
  //       console.log('socket.removeAllListeners() App.js');
  //       socket.removeAllListeners();
  //     }
  //     else
  //     {
  //       console.log('socket.connected == false App.js');
  //     }
  //   }
  //   catch(err)
  //   {
  //     console.error(err);
  //   }
  // }

  componentDidMount() {
    this.redirectHome()     //use Auto login function in login.js
    return
                                           ///STEP ONE
    let thisoutside = this;

    //re-connect the socketIO
    this.props.dispatch({ type: 'SocketCon' });

    this.setState({
      displayString: "Re-connecting the Socket..",
      progress: 10,
      subDisplay: "0/2"
    });

    setTimeout(function(){thisoutside.SocketChecking()}, 1000);  
  }

  // SocketChecking() {                                          ///STEP TWO
  //   let thisoutside = this;
  //   if(this.props.socket == null)
  //   {
  //     this.setState({
  //       displayString: "Socket Server Connect is Failed. Please check the network connection and re-try...",
  //       progress: 0
  //     });
  //     return;
  //   }
  //   else
  //   {
  //     this.setState({
  //       displayString: "Server is Re-connected!",
  //       progress: 15,
  //       subDisplay: "1/2",
  //       isLoading: true
  //     });
  //   }
  //   setTimeout(function(){thisoutside.timeoutChecking()}, 10*1000);  
  //   //receive the connect function
  //   //this.props.socket.removeAllListeners(); //remove previous socket functions
  //   this.props.socket.on("LoginSuccess", data => {
  //     if(data.result == "Success")
  //     {
  //       let totalFunctions = this.state.totalFunctions;
  //       totalFunctions[0] = 1;
  //       thisoutside.setState({
  //         displayString: "Authenticated, Download the Data from server..",
  //         isLoading: false,
  //         progress: 20,
  //         subDisplay: "2/2",
  //         totalFunctions: totalFunctions
  //       });
  //       //save the UserInfo to Client
  //       //authData
  //       thisoutside.props.dispatch({ type: 'SaveUserInfo', data: data });

  //       //console.log('checkAuth() redux:SaveUserInfo()');
  //       //console.log(data);
  //       setTimeout(function(){thisoutside.DownloadData()}, 1500);  
  //     }
  //     else
  //     {
  //       thisoutside.setState({
  //         displayString: "Auth is Invalid, redirecting to Login page..",
  //         isLoading: false,
  //         progress: 0,
  //         subDisplay: "1/2"
  //       });
  //       setTimeout(function(){thisoutside.props.history.push("/")}, 1500);  
  //     }
  //   });

  //   //request checkAuth
  //   //get the localstorage from browser
  //   let sessionID = localStorage.getItem("SessionID");
  //   let authToken = localStorage.getItem("AuthToken");
  //   let username = localStorage.getItem("useracc");
  //   let hashpassword = localStorage.getItem("userhashpassword");

  //   this.props.socket.emit("Login", {
  //     msgType: "Auth",
  //     sessionID: sessionID,
  //     authToken: authToken,
  //     username: username,
  //     password: hashpassword,
  //     expires: "2019-08-01",
  //     data: "session cookie"
  //   });


  //   //socket listener

  //   this.props.socket.on("LatestDeviceData", data => {
  //     console.warn("LatestDeviceData()");
  //     console.log(data);
  //     thisoutside.props.dispatch({ type: 'LatestDeviceData', 
  //       data: data
  //     });
  //     let totalFunctions = this.state.totalFunctions;
  //     totalFunctions[1] = 1;
  //     thisoutside.setState({
  //       totalFunctions: totalFunctions
  //     });
  //     this.DownloadData();
  //   });

  //   this.props.socket.on("ActiveAlarms", data=> {
  //     console.warn("ActiveAlarms() in App.js (sometime may listened in Navbar.js)");
  //     console.log(data);
  //     //same as Navbar.js ActiveAlarms listener
  //     thisoutside.props.dispatch({ type: 'ActiveAlarms', 
  //       data: data,
  //     });
  //   });
  // }

  // DownloadData()
  // {
  //   //download data from server

  //   //emit the sockets
  //   //* no need since when login the data would be sent from websocket

  //   let {totalFunctions} = this.state;
  //   let DoneCount = 0;
  //   totalFunctions.forEach(element => {
  //     if(element == 1)
  //     {
  //       DoneCount ++;
  //     }
  //   });
  //   this.setState({
  //     displayString: "Requesting Socket Data",
  //     progress: 25 + DoneCount/totalFunctions.length*55,
  //     subDisplay: DoneCount + "/" + totalFunctions.length
  //   });

  //   if(this.state.totalFunctions.length == DoneCount)
  //   {
  //     this.setState({
  //       progress: 60
  //     });
  //     this.DownloadFloorImages();
  //   }
  // }

  // DownloadFloorImages()
  // {
  //   this.redirectPage();
  //   return;     //disabled download image function, since it is not completed

  //   //check all the floors
  //   let {UserInfo} = this.props;
  //   //console.log(UserInfo.locations);
  //   //cal the total floors
  //   let FloorCount = 0; //calculate the total floors in UserInfo
  //   let SiteCount = 0;
  //   let ImgStorage = [];
  //   if(UserInfo.locations == null || UserInfo.locations == undefined) {
  //     console.log('App.js: DownloadFloorImages(), there is no locations item');
  //     return;
  //   }
  //   UserInfo.locations.forEach(SiteItem => {
  //     //console.log(SiteItem);
  //     ImgStorage.push({
  //       Site: SiteItem.imgUrl,
  //       FloorImg: [],
  //       SiteImage: require('./assets/images/floors/' + SiteItem.imgUrl + '/default.jpg')
  //     });
  //     SiteItem.nestedLocs.forEach(floorItem => {
  //       //console.log(floorItem);
  //       ImgStorage[SiteCount].FloorImg.push({
  //         ImgCount: FloorCount,
  //         SiteCount: SiteCount,
  //         SiteName: SiteItem.imgUrl,
  //         Img: require('./assets/images/floors/' + SiteItem.imgUrl + '/' + floorItem.locName + '.png'),
  //         FloorName: floorItem.locName
  //       });
  //       FloorCount++;
  //     });
  //     SiteCount++;
  //   });
  //   this.setState({
  //     displayString: "Pre-load the Floor Images",
  //     progress: 60,
  //     subDisplay: "0/" + FloorCount
  //   });
  //   console.log(ImgStorage);

  //   //when completed, set the redux floor
  //   this.props.dispatch({ type: 'SaveImgStorage', 
  //     data: ImgStorage
  //   });
    
  //   this.redirectPage();
  // }

  // redirectPage()
  // {
  //   this.setState({
  //     displayString: "Completed",
  //     progress: 100,
  //     subDisplay: "",
  //     isLoading: false
  //   });

  //   let {LatestPage} = this.props;
  //   let thisoutside = this;
  //   //console.log(LatestPage);
  //   if(LatestPage == "/" || LatestPage == null || LatestPage == "")
  //   {
  //     LatestPage = "/Main";
  //   }
  //   setTimeout(function(){thisoutside.props.history.push(LatestPage)}, 1500); 
  // }

  // timeoutChecking()
  // {
  //   let thisoutside = this;
  //   this.setState({
  //     displayString: "Time out Error",
  //     progress: 0,
  //     subDisplay: ""
  //   });
  //   if(this.state.isLoading)
  //   {
  //     setTimeout(function(){
  //       thisoutside.props.history.push("/");
  //       thisoutside.setState({
  //         isTimeOut: true
  //       });
  //     }, 1500);
  //   }
  // }

  redirectHome()
  {
    this.props.history.push("/");
  }

  render() {
    return <div></div>
    // return [
    //   <div className="ReAuth_maindiv">
    //       <img src={require("./assets/images/ATAL_white.png")} alt="ATAL" className="login_image"></img>
    //       <div className="App_title">
    //         Loading to IoT Hub Platform.
    //         <div className={"loading_background1"}>
    //           <p>{this.state.displayString}</p>
    //         </div>
    //       </div>
    //       <div className="app_progressBar">
    //         <div className="progressItem" style={ { width: `${ this.state.progress }%` } }></div>
    //       </div>
    //       {/*<div className="subDisplay">
    //         {this.state.subDisplay}
    //       </div>*/}
    //       <div className="fa fa-home loading_redirectBtn" onClick={this.redirectHome}>
    //       </div>
    //   </div>,
    //   <SoundPlay/>
    // ]
  }
}

//Redux inside component function
function mapStateToProps(state) {
  return {
    socket: state.socket,
    level: state.level,
    currentlevel: state.currentlevel,
    currentSite: state.currentSite,
    MenuDisplay: state.MenuDisplay,
    LatestPage: state.LatestPage,
    UserInfo: state.UserInfo,
  };
}

export default connect(mapStateToProps)(App);
