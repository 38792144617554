import './LiftHeader.css';
import React from 'react';
//Redux Import
import { connect } from 'react-redux';
class LiftHeader extends React.Component {
    constructor(props)
    {
        super(props);
        this.OpenSiteList = this.OpenSiteList.bind(this);
        this.OpenLevelList = this.OpenLevelList.bind(this);
        this.closePopups = this.closePopups.bind(this);
        this.closePopupsLevel = this.closePopupsLevel.bind(this);

        this.PickSite = this.PickSite.bind(this);
        this.setReduxCurrentLevel = this.setReduxCurrentLevel.bind(this);

        this.PickLevel = this.PickLevel.bind(this);

        this.state = {
            isPickSite: false,
            isPickLevel: false
        };
    }
    OpenSiteList()
    {
        this.setState({
            isPickSite: true,
            isPickLevel: false
        });
    }
    OpenLevelList()
    {
        this.setState({
            isPickSite: false,
            isPickLevel: true
        });
    }
    closePopups()
    {
        this.setState({
            isPickSite: false,
            isPickLevel: false
        });
    }
    closePopupsLevel()
    {
        this.setState({
            isPickSite: false,
            isPickLevel: false
        }); 
    }
    PickLevel(event)
    {
        //alert(event.target.id);
        let clickedID = event.target.id;
        //remove "widgetlevelbtn_" from id
        let level = clickedID.replace('widgetlevelbtn_', '');
        //redux update the currentlevel
        this.props.dispatch({ type: 'PickLevel', data: level });

        this.closePopupsLevel();
    }
    PickSite(event)
    {
        //get the picker item
        //console.log(event.target.id); //this is the site ID
        let siteID = event.target.id;
        let LevelList = [];
        //get level list of that site
        if(this.props.UserInfo == null)
        {
            console.error('this.props.UserInfo == null');
            return;
        }
        this.props.UserInfo.locations.map((data, i) => {
            let locIdx_int = parseInt(data.locIdx);
            let siteID_int = parseInt(siteID) + 1;
            //console.log(locIdx_int);
            //console.log(siteID_int + 1);
            if(locIdx_int == siteID_int)
            {
                LevelList = data.nestedLocs;
            }
            return null;
        })
        //send the redux function
        this.props.dispatch({ type: 'PIckSite', data: {
            level: LevelList,
            Siteid: siteID
        }});
        //close the popup
        this.closePopups();        
        this.setReduxCurrentLevel(siteID, 0);
    }
    setReduxCurrentLevel(currentSite, currentLevel)
    {
        //set the current level in redux
        //currentLevel is the array[0] number, not the real level value
        let thisoutside = this;
        try
        {
            thisoutside.props.dispatch({ type: 'PickLevel', data: thisoutside.props.UserInfo.locations[currentSite].nestedLocs[currentLevel].locName });
        }
        catch(err)
        {
            console.error(err);
        }
    }
    render()
    {
        let {currentSite, currentlevel, UserInfo} = this.props;
        let {isPickLevel, isPickSite} = this.state;
        let currentSite_Name = "";
        try
        {
            currentSite_Name = UserInfo.locations[currentSite].locName;
            if(this.state.isPickSite)
            {
                currentSite_Name = "";
            }
        }
        catch(error)
        {
            currentSite_Name = "Loading";
        }
        let LiftSitePopupClass = "LiftHideSitePopup";
        if(isPickSite)
        {
            LiftSitePopupClass = "";
        }
        let LiftLevelPopupClass = "LiftHideSitePopup";
        if(isPickLevel)
        {
            LiftLevelPopupClass = "";
        }

        let LevelList = [];
        if(UserInfo == null)
        {
            return<div>data is missing</div>
        }
        UserInfo.locations[currentSite].nestedLocs.map((data, i) => {
            //if(data.locIdx == currentSite)
            //{
                LevelList.push(data.locName);
            //}
        })
        return [
            <div className="Lift_headerContainer">
                <div className="fa fa-building-o LiftHeaderIcon"></div>
                <div className="LiftHeaderTitle" onClick={this.OpenSiteList}>
                    {currentSite_Name}
                </div>
                <div className="fa fa-caret-down LiftHeaderIcon2"></div>
                <div className="Lift_Sitewidth"></div>
                <div className="fa fa-caret-down LiftHeaderLevelIcon"></div>
                <div className="LiftHeaderLevelTitle "
                onClick={this.OpenLevelList}>{"Lift " + currentlevel}</div>
            </div>,
            <div className={"Lift_headerSitePopup " + LiftSitePopupClass}>
                {
                    this.props.UserInfo.locations.map((data, i) => {
                        let IsSelectedSite = "";
                        if(i == this.props.currentSite)
                        {
                            IsSelectedSite = "highlightSiteItem";
                        }
                        return <div value={i} id={i} onClick={this.PickSite} 
                        className={"Lift_HeaderPiskSiteItem " + IsSelectedSite}>
                            {data.locName}
                        </div>
                    })
                }
                {/* <div className="fa fa-times Lift_SiteCloseBtn" onClick={this.closePopups}></div> */}
            </div>,
            <div className={"Lift_HeaderPiskLevelItem " + LiftLevelPopupClass} >                
                <div className="fa fa-times PickLevelPopupCloseBtn" onClick={this.closePopupsLevel}></div>
                {LevelList.map((data, i) => {
                    let HighlightClass = "";
                    if(this.props.currentlevel == data)
                    {
                        HighlightClass = "CurrentFloor";
                    }
                    return <button className={"PickLevelWidget_LevelBtn " + HighlightClass} key={"widgetlevelbtn_" + i} id={"widgetlevelbtn_" + data} onClick={this.PickLevel}>
                        {data}
                    </button>
                })}
            </div>
        ]
    }
}

function mapStateToProps(state) {
    return {
        socket: state.socket,
        level: state.level,
        currentlevel: state.currentlevel,
        currentSite: state.currentSite,
        MenuDisplay: state.MenuDisplay,
        UserInfo: state.UserInfo
    };
}
export default connect(mapStateToProps)(LiftHeader);