import React from 'react'
import { connect } from 'react-redux'
import './Guage.css'

import HighCharts from 'highcharts/highcharts'
import highchartsMore from 'highcharts/highcharts-more'
import solidGauge from "highcharts/modules/solid-gauge"
import HighchartsReact from 'highcharts-react-official'

import Loading from '../../loading'

import {getTxt} from '../../common/language/language'

highchartsMore(HighCharts)
solidGauge(HighCharts)

class Guage extends React.Component {
    constructor(props) {
        super(props)

        this.Guage_body = React.createRef()
        this.Guage_maindiv = React.createRef()
        this.Guage_header = React.createRef()

        this.state = {
            chartOption: {}
        }

        this.getAverage = this.getAverage.bind(this)
        this.DrawHighChart = this.DrawHighChart.bind(this)
    }

    DrawHighChart(tempValue) {
        try {
            let Guagebody_currentheight = this.Guage_maindiv.current.offsetHeight - this.Guage_header.current.offsetHeight

            this.setState({
                chartHeight: Guagebody_currentheight,
                chartOption: {   
                    credits: false,
                    chart: {
                        type: "gauge",
                        plotBackgroundColor: null,
                        plotBackgroundImage: null,
                        plotBorderWidth: 0,
                        plotShadow: false,
                        backgroundColor: {
                            linearGradient: [0, 0, 500, 500],
                            stops: [
                                [0, 'rgba(255, 255, 255, 0)'],
                                [1, 'rgba(255, 255, 255, 0)']
                            ]
                        },
                    },
                    title: {
                        text: ''
                    },            
                    pane: {
                        startAngle: -150,
                        endAngle: 150,
                        background: [{
                            backgroundColor: {
                                linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                                stops: [
                                    [0, '#FFF'],
                                    [1, '#333']
                                ]
                            },
                            borderWidth: 0,
                            outerRadius: '109%'
                        }, {
                            backgroundColor: {
                                linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                                stops: [
                                    [0, '#333'],
                                    [1, '#FFF']
                                ]
                            },
                            borderWidth: 1,
                            outerRadius: '107%'
                        }, {
                            // default background
                        }, {
                            backgroundColor: '#DDD',
                            borderWidth: 0,
                            outerRadius: '105%',
                            innerRadius: '103%'
                        }]
                    },            
                    // the value axis
                    yAxis: {
                        min: 0,
                        max: 35,
                
                        minorTickInterval: 'auto',
                        minorTickWidth: 1,
                        minorTickLength: 10,
                        minorTickPosition: 'inside',
                        minorTickColor: '#666',
                
                        tickPixelInterval: 30,
                        tickWidth: 2,
                        tickPosition: 'inside',
                        tickLength: 10,
                        tickColor: '#666',
                        labels: {
                            step: 2,
                            rotation: 'auto'
                        },
                        title: {
                            text: ' °C'
                        },
                        plotBands: [                                                                                                                                            
                        {
                            from: 0,
                            to: 12,
                            color: '#1000ff'
                        },                                                                                                                   
                        {
                            from: 12,
                            to: 14,
                            color: '#002bff'
                        },                                                                                               
                        {
                            from: 14,
                            to: 16,
                            color: '#007eff'
                        },                                                                   
                        {
                            from: 16,
                            to: 18,
                            color: '#00e7ff'
                        },                                                                           
                        {
                            from: 18,
                            to: 20,
                            color: '#00ffb8'
                        },                                               
                        {
                            from: 20,
                            to: 22,
                            color: '#00ff37'
                        },                         
                        {
                            from: 22,
                            to: 24,
                            color: '#7aff00'
                        }, 
                        {
                            from: 24,
                            to: 26,
                            color: '#fbff00'
                        }, 
                        {
                            from: 26,
                            to: 28,
                            color: '#ffb100'
                        }, 
                        {
                            from: 28,
                            to: 30,
                            color: '#ff6a00'
                        }, {
                            from: 30,
                            to: 38,
                            color: '#ff0000' // red
                        }
                    ]
                    },            
                    series: [{
                        name: 'Temperature',
                        data: [tempValue],
                        tooltip: {
                            valueSuffix: ' °C',
                            valueDecimals: 2,
                        }
                    }]
                }
            });
        }
        catch(err) {
            console.log(err)
        }
    }

    getAverage(propsItem) {
        //get the average temp in site
        const { currentlevel, currentSite, UserInfo, DeviceData } = propsItem
        const currentSiteInt = parseInt(currentSite, 0)
        try{
            const CurrentFloorItem = DeviceData[currentSiteInt].nestedLocs.find(function(floorItem) {return floorItem.locName == currentlevel})
            const CurrentDeviceList = CurrentFloorItem.nestedLocs

            let SumTemp = 0
            let tempCount = 0
            CurrentDeviceList.forEach(deviceItem => {
                const payloadData = deviceItem.payload
              if(payloadData != null && payloadData.temperature != null) {
                tempCount++
                SumTemp += Number(payloadData.temperature)
              }
            })
            if(tempCount == 0) {
                this.DrawHighChart(0);
                return
            }
            const averageTemp = SumTemp/tempCount
            const average1Dec = Number(averageTemp.toFixed(1))
            this.DrawHighChart(average1Dec)
        }
        catch(err) {
            console.log(err)
            this.DrawHighChart(0)
        }
    }

    componentDidMount() {
        this.getAverage(this.props)
    }

    componentWillReceiveProps(nextProps) {
        this.getAverage(nextProps)
    }

    render() {
        if(this.props.DeviceData == null) return <Loading/>

        //only ad the chart when the highchart this.state parameter is prepared, else just add a empty body (need 'ref' for calculation)
        if(this.state.chartHeight == null) {
            return <div className="Guage_maindiv" ref={this.Guage_maindiv}>
                <div className="Guage_header" ref={this.Guage_header}>
                    <div className="fa fa-bell"></div>
                    {getTxt('Statistic', 'temp')}
                </div>
                <div className="Guage_body" ref={this.Guage_body}>
                </div>
            </div>
        }

        return <div className="Guage_maindiv" ref={this.Guage_maindiv}>
            <div className="Guage_header" ref={this.Guage_header}>
                <div className="fa fa-bell"></div>
                {getTxt('Statistic', 'temp')}
            </div>
            <div className="Guage_body" ref={this.Guage_body}>
                <HighchartsReact highcharts={HighCharts} options={this.state.chartOption} constructorType={'chart'} className="Guage_maindiv" 
                containerProps={{ style: { height: this.state.chartHeight + "px" } }}/>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
      currentlevel: state.currentlevel,
      currentSite: state.currentSite,
      UserInfo: state.UserInfo,
      DeviceData: state.DeviceData,
      DeviceDataLastUpdate: state.DeviceDataLastUpdate
    }
}

export default connect(mapStateToProps)(Guage)