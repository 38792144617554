export const getSensorList = floor => {
    if(floor=='35'||floor==35) {
        return [
            'NT-IAQ-01',
            'NT-IAQ-02',
            'NT-IAQ-03',
            'NT-IAQ-04',
            'NT-IAQ-05',
            'NT-IAQ-06'
        ]
    }
    if(floor=='36'||floor==36) {
        return [
            'NT-IAQ-07',
            'NT-IAQ-08',
            'NT-IAQ-09',
            'NT-IAQ-10',
            'NT-IAQ-11',
            'NT-IAQ-12'
        ]
    }
    if(floor=='37'||floor==37) {
        return [
            'NT-IAQ-13',
            'NT-IAQ-14',
            'NT-IAQ-15',
            'NT-IAQ-16',
            'NT-IAQ-17',
            'NT-IAQ-18'
        ]
    }
    if(floor=='38'||floor==38) {
        return [
            'NT-IAQ-19',
            'NT-IAQ-20',
            'NT-IAQ-21',
            'NT-IAQ-22',
            'NT-IAQ-23',
            'NT-IAQ-24'
        ]
    }
}

export const getFloor = () => {
    let url = window.location.href
    let origin = window.location.origin

    return url.replace(origin, '').replace('/#/CCGHQ/', '')
}