import React from 'react'
import './AuditTrial.css'
//Redux Import
import { connect } from 'react-redux'
//import datetimepicker library
import DatePicker from 'react-datepicker'
import Moment from 'react-moment'
import moment from 'moment';
//inner widgets
import Loading from '../../loading'
import PopupNotification from '../../../popupNotification/PopupNotification'

//import inner widgets
import Searchbar from './searchbar/searchbar'
import Table from './table/table'
import TableHead from './table/tablehead'
import Downloadcsv from './bottom/downloadcsv'
import Pagebtns from './bottom/pagebtns'

class AuditTrial extends React.Component {
    constructor(props) {
        super(props)
        // let startD =  new Date()
        // startD.setDate(startD.getMonth-1)

        this.state = {
            isLoading:false,
            isAdvanceSearch: false,
            rawAuditTrialList: null,
            AuditTrialList: null,
            //sorted result (by table head .js)
            sortedList: null,
            //filtered result (by searchbar.js)
            filteredList: null,
            //slice by page (by pages.js widget)
            pageSliceList: null,

            updateCount: 0,
            startdate: new Date(moment().subtract(1, 'months')),
            enddate: new Date(),
            selectedLevel: ""
        }
        this.AdvanceSearch = this.AdvanceSearch.bind(this)
        this.closeAdSearch = this.closeAdSearch.bind(this) 
        this.getAuditTrial = this.getAuditTrial.bind(this)
        this.convertDate = this.convertDate.bind(this)
        this.refresh = this.refresh.bind(this)
        this.relist = this.relist.bind(this)
        this.setStartTime = this.setStartTime.bind(this)
        this.setEndTime = this.setEndTime.bind(this) 
        this.userRoles = this.userRoles.bind(this)

        //Update function from child widgets
        this.UpdateList = this.UpdateList.bind(this)
    }
    AdvanceSearch() {
        this.setState({ isAdvanceSearch: true })
    }
    closeAdSearch() {
        this.setState({ isAdvanceSearch: false })
    }
   

    refresh() {
        const outside = this
        this.getAuditTrial()
        this.relist()
    }
 
    userRoles(userLevel) { 
        if ( userLevel === undefined || userLevel === null) {
            return ""
        }
        let str = "" 
        userLevel.forEach(Item => { 
            if(str !== ""){
                str = str + ","
            }
           str = str + Item
          }) 
          return str

    }

    relist() {
        const outside = this
        let newList = []

        if (this.props.AuditTrialList !== null) {
            // console.log("relist")
            // console.log(this.props.AuditTrialList.data )
            // console.log(this.state.sortedList )
            if (this.props.AuditTrialList.data !== null) {
                this.props.AuditTrialList.data.map(function (data) {
                    let dataItem = {
                        username: data.username,
                        userLevel: outside.userRoles(data.userLevel),
                        action: data.action,
                        actionDetail: data.actionDetail,
                        createdTime: data.createdTime,
                        remark: data.remark,
                        keywords: JSON.stringify(data)

                    }
                    newList.push(dataItem)
                })

            }

            outside.setState(prevState => ({
                ...prevState,
                AuditTrialList: newList,
                rawAuditTrialList: this.props.AuditTrialList.data,
                isLoading:false

            }))
        }

    }



    componentDidMount() {
        this.refresh()
    }
    componentDidUpdate() {
        const outside = this
        let newList = []
        let { startdate, enddate } = this.state
        if (this.props.AuditTrialList !== null && this.state.rawAuditTrialList !== this.props.AuditTrialList.data) {
            this.relist()
        }
    }

    convertDate(DateInput) {
        let RawTimestamp = DateInput
        var Output = RawTimestamp.toISOString()
        return Output
    }
    getAuditTrial() {
        this.setState(prevState => ({
            ...prevState,
            isLoading:true
        }))
        const { UserInfo } = this.props
        const { startdate, enddate, selectedLevel } = this.state
        if (UserInfo == null) return
        const EmitUserID = UserInfo.userID
        //request json
        // let startDate = new Date()
        // let endDate = new Date()
        startdate.setMonth(startdate.getMonth())
        const startDateStr = this.convertDate(startdate)
        const EndDateStr = this.convertDate(enddate)
        let requestData = {}
        requestData = {
            "msgType": "AuditTrialList",
            "userID": EmitUserID,
            "client_id": UserInfo.client_id,
            "timeConstraint": {
                "start": startDateStr,
                "end": EndDateStr,
            },
        }

        //send with redux socket function
        this.props.dispatch({
            type: 'EmitSocket',
            EmitSocketName: "AuditTrialRequest",
            EmitSocketData: requestData
        })

    }

    //Update function from child widgets
    UpdateList(StateStr, Value) {
        // console.log("UpdateList " + StateStr)
        // console.log(Value)
        const { updateCount } = this.state
        const _this = this
        this.setState({
            [StateStr]: Value,
            updateCount: updateCount + 1,
        })
    }

    setStartTime(data) {
        this.setState({ startdate: data })
    }

    setEndTime(data) {
        this.setState({ enddate: data })
    }

    render() {
        const { isAdvanceSearch, AuditTrialList, sortedList, filteredList, pageSliceList, updateCount, isLoading } = this.state 
        const _this = this
        // console.log(filteredList)
        return (_this.state.isLoading? 
            <Loading/>
            :
        
        <div className="CBAuditTrial">
            <Searchbar isAdvanceSearch={isAdvanceSearch} closeAdSearch={_this.closeAdSearch} AdvanceSearch={_this.AdvanceSearch}
               refresh={this.refresh}
                coldboxList={AuditTrialList} UpdateList={_this.UpdateList}
                setStartTime={_this.setStartTime} setEndTime={_this.setEndTime}
                isLoading={isLoading}   />

            <div className="CBAudit_table">
                <TableHead filteredList={filteredList} UpdateList={_this.UpdateList} />

                {/* {(AuditTrialList !== null && AuditTrialList !== undefined ?
                    AuditTrialList.map(function (data) {
                        return <Table
                            data={data}
                        />
                    })
                    :
                    ""
                )} */}
                {(pageSliceList !== null && pageSliceList !== undefined ?
                    pageSliceList.map(function (data) {
                        return <Table
                        data={data}
                            acknowledgeUpdate={_this.acknowledgeUpdate}
                        />
                    })
                    :
                    ""
                )}

            </div>
            <div className="CBAudit_bottom">
                <Downloadcsv sortedList={sortedList} />
                <Pagebtns sortedList={sortedList} UpdateList={_this.UpdateList} count={updateCount} />
            </div>

            {/* <PopupNotification onRef={ref => {this.PopupNotificationRef = ref}} /> */}
        </div>
            )
    }
}

//Redux inside component function
function mapStateToProps(state) {
    return {
        socket: state.socket,
        currentlevel: state.currentlevel,
        currentSite: state.currentSite,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate, //when this value is updated, render would triggered
        StatisticData: state.StatisticData,
        UserInfo: state.UserInfo,
        currentlevelID: state.currentlevelID,
        AuditTrialList: state.AuditTrialList
    }
}

export default connect(mapStateToProps)(AuditTrial)