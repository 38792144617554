import React from 'react'
import './BinSummary.css'

//Redux Import
import { connect } from 'react-redux'

class BinSummary extends React.Component {
    constructor(props)
    {
        super()
    }

    render()
    {
        const {DeviceData, currentSite, currentlevel} = this.props
        if(DeviceData == null || currentlevel == null || currentSite == null) return <div></div>       //exception cases
        const CurrentFloorItem = DeviceData[currentSite].nestedLocs.find(function(floorItem) {return floorItem.locName == currentlevel})
        const CurrentDeviceList = CurrentFloorItem.nestedLocs
        console.log(CurrentDeviceList)

        let BinSum = 0
        let BinCount = 0
        let isBinExist = false
        CurrentDeviceList.forEach(deviceItem => {
            const payload = deviceItem.payload
            if(payload == null) return      //exception case
            if(payload.fillDistance != null) {
                BinSum += payload.fillDistance
                BinCount++
                isBinExist = true
            }
        })
        let Reading = "--"
        if(BinCount != 0) {
            Reading = (BinSum/BinCount).toFixed(1)
        }

        return <div className="BinSummaryCardContainer">
            <div className="BinSummaryCardHead">
                <div className="fa fa-trash BinSummaryIcon"></div>
                <div className="BinSummaryTitle">Bin Summary</div>
            </div>
            <div className="BinDisplayInnerBody">
                <div className="BinDisplayTitle">
                    Bin Usage
                </div>
                <div className="BinDisplayResult">
                    <div className="fa fa-trash BinResultIcon"></div>
                    <div className={isBinExist?"BinResultReading":"BinResultReading NoDevice"}>
                        {isBinExist?Reading:"No Device"}
                        <div className="BinResultReadingUnit">
                            {isBinExist?"cm":""}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
      socket: state.socket,
      DeviceData: state.DeviceData,
      currentlevel: state.currentlevel,
      currentSite: state.currentSite,
      DeviceDataLastUpdate: state.DeviceDataLastUpdate
    }
}
  
export default connect(mapStateToProps)(BinSummary)