export const GetRelatedList = (DeviceData, Site, Level) => {
    if(DeviceData == null || Site == null || Level == null) return []
    let RelatedList_1 = []
    if(Site == "All") {
        //show all coldbox
        DeviceData.forEach(SiteItem => {
            const SiteLocName = SiteItem.locName
            SiteItem.nestedLocs.forEach(LevelItem => {
                const LevelLocName = LevelItem.locName
                LevelItem.nestedLocs.forEach(locItem => {
                    let newlocItem = locItem
                    newlocItem.SiteLocName = SiteLocName
                    newlocItem.LevelLocName = LevelLocName
                    RelatedList_1.push(newlocItem)
                })
            })
        })
    }
    else if(Level == "All") {
        //show one site coldbox
        const LevelArray = DeviceData[Site].nestedLocs
        LevelArray.forEach(LevelItem => {
            const LevelLocName = LevelItem.locName
            LevelItem.nestedLocs.forEach(locItem => {
                let newlocItem = locItem
                newlocItem.LevelLocName = LevelLocName
                RelatedList_1.push(newlocItem)
            })   
        })
    }
    else {
        //show one level coldbox
        const LocArray = DeviceData[Site].nestedLocs.find((lvlItem) => {
            return lvlItem.locName == Level
        })
        LocArray.nestedLocs.forEach(locItem => {
            RelatedList_1.push(locItem)
        })
    }
    const RelatedList = RelatedList_1.filter(function(item) {
        return item.payload_definition_id != null && item.payload_definition_id.cardTitle == "Coldbox"
    })
    return RelatedList
}

export const GetLocItem = (DeviceData, Sitename, Levelname, Locname) => {
    let RelatedList_1 = []
    DeviceData.forEach(SiteItem => {
        if(Sitename == SiteItem.locName) {
            SiteItem.nestedLocs.forEach(LevelItem => {
                if(Levelname == LevelItem.locName) {
                    LevelItem.nestedLocs.forEach(locItem => {
                        if(Locname == locItem.locName) RelatedList_1.push(locItem)
                        else {
                            //not relative loc item
                        }
                    })
                }
                else {
                    //not relative level item
                }
            })
        }
        else {
            //not relative site item
        }
    })
    return RelatedList_1
}