import React, { useState, useEffect } from 'react'
import './highchart.css'
import { connect } from 'react-redux'

import LoadingWidget from '../../common/loading/loading'
import GetDataList from '../commonfunction'
import ColorCodeList from './chartcolorList'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

function LiftDoorStatHighchart(props) {
    const {StatisticData, ToShow, DailyOrMonth} = props
    if(StatisticData == null || StatisticData.doorOpenCount == null || StatisticData.liftStartupCount == null || StatisticData.liftDirectionReverseCount == null)  return <LoadingWidget />
    if(DailyOrMonth) return <LoadingWidget />

    const DataList = GetDataList(StatisticData)

    const FloorList = getUniqStrList(DataList, 2).sort((a, b) => {
        return a - b
    })

    // convert the floor index to floor name
    const floornameList = getFloorList(props)
    const XAxisArray = FloorList.map(item => {
        return floornameList[item] + '/F'
    })

    const LiftNameList = getUniqStrList(DataList, 1)
    let SeriesArray = []
    if(ToShow[0]) {
        LiftNameList.forEach((LiftName, index) => {
            const FindLiftData = DataList.filter((item) => {
                return item[1] == LiftName
            })
            const DoorArray = FindLiftData.map(item => {
                return item[3]
            })
            if(FindLiftData != null && FindLiftData.length != null && FindLiftData.length != 0) {
                SeriesArray.push({
                    // showInLegend:false,
                    name: LiftName + ' Door Open Count',
                    data: DoorArray,
                    color: {
                        linearGradient: [0, 0, 0, 500],
                        stops: [
                            [0, ColorCodeList['type1'][index]['color1']],
                            [1, ColorCodeList['type1'][index]['color2']]
                        ]
                    },
                })
            }
        })
    }
    if(ToShow[1]) {
        LiftNameList.forEach((LiftName, index) => {
            const FindLiftData = DataList.filter((item) => {
                return item[1] == LiftName
            })
            const StartArray = FindLiftData.map(item => {
                return item[4]
            })
            if(FindLiftData != null && FindLiftData.length != null && FindLiftData.length != 0) {
                SeriesArray.push({
                    // showInLegend:false,
                    name: LiftName + ' Lift Start Up Count',
                    data: StartArray,
                    color: {
                        linearGradient: [0, 0, 0, 500],
                        stops: [
                            [0, ColorCodeList['type2'][index]['color1']],
                            [1, ColorCodeList['type2'][index]['color2']]
                        ]
                    },
                })
            }
        })
    }
    if(ToShow[2]) {
        LiftNameList.forEach((LiftName, index) => {
            const FindLiftData = DataList.filter((item) => {
                return item[1] == LiftName
            })
            const DirReverArray = FindLiftData.map(item => {
                return item[5]
            })
            if(FindLiftData != null && FindLiftData.length != null && FindLiftData.length != 0) {
                SeriesArray.push({
                    // showInLegend:false,
                    name: LiftName + ' Lift Direction Reverse Count',
                    data: DirReverArray,
                    color: {
                        linearGradient: [0, 0, 0, 500],
                        stops: [
                            [0, ColorCodeList['type3'][index]['color1']],
                            [1, ColorCodeList['type3'][index]['color2']]
                        ]
                    },
                })
            }
        })
    }
    
    let options = {
        title:{
            text:''
        },
        chart: {
            type: 'column',
            backgroundColor: 'transparent'
        },
        xAxis: {
            categories: XAxisArray,
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Count'
            }
        },
        credits: {
            enabled: false
        },
        series: SeriesArray,
    }

    return <div className="LiftDrStat_Highchart">
        <HighchartsReact highcharts={Highcharts} options={options} constructorType={'chart'} className=""
                    containerProps={{ style: { height: '100%' } }}/>
    </div>
}

function mapStateToProps(state) {
    return {
        StatisticData: state.StatisticData,
        currentSite: state.currentSite,
        currentlevel: state.currentlevel,
        DeviceData: state.DeviceData,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate
    }
}
export default connect(mapStateToProps)(LiftDoorStatHighchart)


function getUniqStrList(ArrayList, keystr) {
    const MapStrList = ArrayList.map((item) => {
        return item[keystr]
    })
    return MapStrList.filter(onlyUnique)
}
function onlyUnique(value, index, self) {
    return self.indexOf(value) === index
}

const getFloorList = (props) => {
    const {DeviceData, currentSite, currentlevel} = props
    if(DeviceData==null || currentSite==null || currentlevel==null) return []
    const siteItem = DeviceData[currentSite]
    if(siteItem==null) return []
    const levelItem = siteItem.nestedLocs.find(item => {
        return item.locName == currentlevel
    })
    if(levelItem==null) return []

    const locItem = levelItem.nestedLocs[0]
    return locItem.payload.LiftLevels
}