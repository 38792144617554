import React from 'react'
import {connect} from 'react-redux'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faThermometerHalf, faTint, faCloud} from '@fortawesome/free-solid-svg-icons'
import './IEQ_V3.css'

function IEQ_V3(props) {
    const {temp, humid, co2, pm25} = GetReadings(props)
    return <div className='IEQV3_main'>
        <div className='head'>
            <FontAwesomeIcon icon={faThermometerHalf} className="fa icon" />
            <div className='txt'>IEQ</div>
        </div>
        <div className='inner'>
            {RowItem(faThermometerHalf, '', '', temp, 'C', 'o', '', 'red')}
            {RowItem(faTint, '', '', humid, '%', '', '', '#00abff')}
            {RowItem(faCloud, 'CO', '2', co2, 'ppm', '', '', '#ce2fce')}
            {RowItem(faCloud, 'PM', '2.5', pm25, 'µg/m', '', '3', 'grey')}
        </div>
    </div>
}
function mapStateToProps(state) {
    return {
      currentlevel: state.currentlevel,
      currentSite: state.currentSite,
      DeviceData: state.DeviceData,
    }
}
export default connect(mapStateToProps)(IEQ_V3)

// widget item
function RowItem(icon, title, titleUnit, reading, unit, unit1, unit2, color) {
    return <div className='rowItem'>
        <div className='innerRow' style={{color: color}}>
            <div className='title'>
                {title}
                <sub>{titleUnit}</sub>
            </div>
            <FontAwesomeIcon icon={icon} className="fa icon" />
            <div className='reading'>
                {reading}
                <div className='unit'>
                    <sup>{unit1}</sup>
                    {unit}
                    <sup>{unit2}</sup></div>
            </div>
        </div>
    </div>
}

// GetReadings
function GetReadings(props) {
    const emptyData = {
        temp: 0,
        humid: 0,
        co2: 0,
        pm25: 0,
    }
    const {DeviceData, currentSite, currentlevel} = props
    if(DeviceData==null||currentSite==null||currentlevel==null) return emptyData
    
    // get loc list
    const site = DeviceData[currentSite]
    if(site==null||site.nestedLocs==null) return emptyData
     const level = site.nestedLocs.find(item => {
         return item.locName == currentlevel
    })
    if(level==null||level.nestedLocs==null) return emptyData

    const locList = level.nestedLocs
    if(locList==null||locList.length==null) return emptyData
    
    //get value
    let temp_S = 0
    let humid_S = 0
    let co2_S = 0
    let pm25_S = 0
    let count = 0
    locList.forEach(item => {
        if(item.payload==null) return emptyData
        const {co2, humidity, temperature, pm2_5} = item.payload
        console.log(item.payload)
        if(temperature!=null) temp_S+= parseFloat(temperature.toString())
        if(humidity!=null) humid_S+= parseFloat(humidity.toString())
        if(co2!=null) co2_S+= parseFloat(co2.toString())
        if(pm2_5!=null) pm25_S+= parseFloat(pm2_5.toString())

        if(temperature!=null) count++
    })

    //average & round off
    if(count == 0) return emptyData
    const temp_A = temp_S/count
    const humid_A = humid_S/count
    const co2_A = co2_S/count
    const pm25_A = pm25_S/count

    return {
        temp: isNaN(temp_A)?0:temp_A.toFixed(1),
        humid: isNaN(humid_A)?0:humid_A.toFixed(1),
        co2: isNaN(co2_A)?0:co2_A.toFixed(0),
        pm25: isNaN(pm25_A)?0:pm25_A.toFixed(1)
    }
}