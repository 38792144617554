const ColorCodeList = {
    'type1': [
        {
            'color1': 'rgb(0, 175, 255, 0.3)',
            'color2': 'rgb(0, 175, 255, 1)',
        },
        {
            'color1': 'rgb(0, 145, 234, 0.3)',
            'color2': 'rgb(0, 145, 234, 1)',
        },
        {
            'color1': 'rgb(64, 196, 255, 0.3)',
            'color2': 'rgb(64, 196, 255, 1)',
        },
        {
            'color1': 'rgb(79, 195, 247, 0.3)',
            'color2': 'rgb(79, 195, 247, 1)',
        },
        {
            'color1': 'rgb(68, 138, 255, 0.3)',
            'color2': 'rgb(68, 138, 255, 1)',
        },
    ],
    'type2': [
        {
            'color1': 'rgb(255, 171, 0, 0.3)',
            'color2': 'rgb(255, 171, 0, 1)',
        },
        {
            'color1': 'rgb(255, 145, 0, 0.3)',
            'color2': 'rgb(255, 145, 0, 1)',
        },
        {
            'color1': 'rgb(230, 81, 0, 0.3)',
            'color2': 'rgb(230, 81, 0, 1)',
        },
        {
            'color1': 'rgb(251, 140, 0, 0.3)',
            'color2': 'rgb(251, 140, 0, 1)',
        },
        {
            'color1': 'rgb(255, 61, 0, 0.3)',
            'color2': 'rgb(255, 61, 0, 1)',
        },
    ],
    'type3': [
        {
            'color1': 'rgb(169, 0, 97, 0.3)',
            'color2': 'rgb(169, 0, 97, 1)',
        },
        {
            'color1': 'rgb(170, 0, 255, 0.3)',
            'color2': 'rgb(170, 0, 255, 1)',
        },
        {
            'color1': 'rgb(213, 0, 249, 0.3)',
            'color2': 'rgb(213, 0, 249, 1)',
        },
        {
            'color1': 'rgb(74, 20, 140, 0.3)',
            'color2': 'rgb(74, 20, 140, 1)',
        },
        {
            'color1': 'rgb(186, 104, 200, 0.3)',
            'color2': 'rgb(186, 104, 200, 1)',
        },
    ]
}

export default ColorCodeList