import React from 'react'
import './alarmCountDisplay.css'
//Redux Import
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import {getTxt} from '../../common/language/language'

class AlarmCountDisplay extends React.Component {
    constructor(props) {
        super(props)

        this.NotificationPage = this.NotificationPage.bind(this)

        this.state = {
            redirectNoti: false
        }
    }
    NotificationPage()
    {
        const {UserInfo} = this.props
        if(UserInfo == null) return
        if(UserInfo.userID == "5ed06c042f196f392848de97") return        //hardcode: this person cannot access to notification page
        //redirect to "/Main/Notification"
        this.setState({
            redirectNoti: true
        })
    }

    render() {
        const {redirectNoti} = this.state
        if(redirectNoti) {
            return <Redirect to='/Main/Notification' />
        }

        const {SystemAlerts} = this.props
        //get the current Alarm data
        let alarmnew = 0
        try {
            if(SystemAlerts == null) {
                console.log('there is no SystemAlerts alarmCountDisplay.js')
                alarmnew = 0
            }
            else {
                const SystemAlertsArray = Object.keys(SystemAlerts).map((key) => [Number(key), SystemAlerts[key]])
                if(SystemAlertsArray != null && SystemAlertsArray != undefined) alarmnew = SystemAlertsArray.length
                else alarmnew = 0
            }
        }
        catch(err) {
            console.log(err)
        }
        return  <div className="AlarmCount_div">
            <div className="AlarmCount_newalarms" onClick={this.NotificationPage}>
                <div className="AlarmCount_newalarms_title">
                    {getTxt('Statistic', 'newAlarm')}:
                </div>
                <div className="AlarmCount_newalarms_readingMaindiv">
                    <div className="fa fa-bell AlarmCount_newalarms_reading_icon">
                    </div>
                    <div className="AlarmCount_newalarms_reading_value">
                        {alarmnew}
                    </div>
                </div>
            </div>
        </div>        
    }
}

//Redux inside component function
function mapStateToProps(state) {
    return {
      SystemAlerts: state.SystemAlerts,
      history: state.history,
      socket: state.socket,
      level: state.level,
      currentlevel: state.currentlevel,
      currentSite: state.currentSite,
      MenuDisplay: state.MenuDisplay,
      SiteList: state.SiteList,
      currentBottomMenu: state.currentBottomMenu,
      currentBottomCard: state.currentBottomCard,
      UserInfo: state.UserInfo,
      DeviceDataLastUpdate: state.DeviceDataLastUpdate
    }
}

export default connect(mapStateToProps)(AlarmCountDisplay)