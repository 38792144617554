import React from 'react'
import Loading from '../../../loading'
import { connect } from 'react-redux'

class Warning extends React.Component {
    constructor(props) {
        super()
        this.renderRow = this.renderRow.bind(this)
        this.groupRow = this.groupRow.bind(this)
        this.state = {
            isLoading: false,
            alarmData: {}
        }
    }

    componentDidUpdate(prevProps) {
        const preDeviceDataLastUpdate = prevProps.DeviceDataLastUpdate
        const {DeviceDataLastUpdate} = this.props
        if(preDeviceDataLastUpdate==DeviceDataLastUpdate&&prevProps.Item==this.props.Item) return

        const {Item} = this.props
        if(Item==null||Item.payload==null) return

        this.setState({
            isLoading: false,
            alarmData: Item.payload
        })
    }

    renderRow(title, value, unit, faultcolor) {
        return (
            <div className="row">
                <div className="readingTitle">
                    {title}
                </div>
                <div className="reading" style={{color: faultcolor}}>
                    {value} {(unit === "C" ? <sup>o</sup> : unit)}{(unit === "C" ? unit : "")}
                </div>
            </div>
        )
    }
    groupRow(title, data, unit, isbool, trueDisplay, falseDisplay, isRedWhenTrue) {
        let valueDisplay = '--' + JSON.stringify(data)
        if(isbool) {
            const {min, max} = data
            if(min==0&&max==0) valueDisplay = trueDisplay
            if(min==1&&max==1) valueDisplay = falseDisplay
            if(min!=max) valueDisplay = trueDisplay + ' & ' + falseDisplay
        }
        if(!isbool) {
            if(data == 1) valueDisplay = trueDisplay
            if(data == 2 || data == 0 || data == undefined || data == null) valueDisplay = falseDisplay
            if(data == 3) valueDisplay = trueDisplay + ' & ' + falseDisplay
        }

        let isRed = false
        if(isRedWhenTrue&&(data==1||data==3)) isRed = true
        if(isRedWhenTrue==false&&(data==2||data==3)) isRed = true

        return <div className="row">
            <div className="readingTitle">
                {title}
            </div>
            <div className="reading" style={{color: isRed?'red':''}}>
                {valueDisplay} {(unit==="C"?<sub>o</sub>:unit)}{(unit==="C"?unit:"")}
            </div>
        </div>
    }

    render() {
        const _this = this
        const {groupDisplay, isGpDisplay} = this.props
        const {alarmData, isLoading} = this.state
        if(isLoading) {
            return <Loading/>
        }
        if(isGpDisplay) {
            return <fieldset>
                <legend>Warning</legend> 
                <div className="Coldbox_S_table">            
                    <div className="col_alarm_set_point">
                        {_this.groupRow("Door Position", groupDisplay.DoorPositionEvent, "", false, "Normal", "NoChange")}
                        {_this.groupRow("Compressor Fan", groupDisplay.CompressorFanEvent, "", false, "Normal", "NoChange")}
                        {_this.groupRow("Evaporator Fan", groupDisplay.EvaporatorFanEvent, "", false, "Normal", "NoChange")}
                        {_this.groupRow("Defrost Heater", groupDisplay.DefrostHeaterEvent, "", false, "0", "Normal", "NoChange")}
                        {/* no defrostHeater //.. */}
                        {_this.groupRow("System Running Status", groupDisplay.SystemRunningStatusEvent, "", false, "Normal", "NoChange")}
                        {_this.groupRow("System Start/ Stop", groupDisplay.SystemEvent, "", false, "Normal", "NoChange")}
                        {_this.groupRow("Light", groupDisplay.LightEvent, "", false, "Normal", "NoChange")}
                        {_this.groupRow("UPS-Battery Low Warning", groupDisplay.UPSBatteryLowWarning, "", false, "Fault", "Normal", true)}
                        {_this.groupRow("UPS-Warning-Input voltage abnormal", groupDisplay.UPSWarningInputVoltageAbnormal, "", false, "Fault", "Normal", true)}
                    </div>
                </div>
            </fieldset>
        }
        return <fieldset>
            <legend>Warning</legend> 
            <div className="Coldbox_S_table">            
                <div className="col_alarm_set_point">
                    {this.renderRow("Door Position", alarmData.DoorPositionEvent=== true ? "Normal" : "NoChange", "")}
                    {this.renderRow("Compressor Fan", alarmData.CompressorFanEvent === true ? "Normal" : "NoChange", "")}
                    {this.renderRow("Evaporator Fan", alarmData.EvaporatorFanEvent === true ? "Normal" : "NoChange", "")}
                    {this.renderRow("Defrost Heater", alarmData.DefrostHeaterEvent=== true ? "Normal" : "NoChange", "")}
                    {this.renderRow("System Running Status", alarmData.SystemRunningStatusEvent === true ? "Normal" : "NoChange", "")}
                    {this.renderRow("System Start/ Stop", alarmData.SystemEvent === true ? "Normal" : "NoChange", "")}
                    {this.renderRow("Light", alarmData.LightEvent === true ? "Normal" : "NoChange", "")}
                    {this.renderRow("UPS-Battery Low Warning", alarmData.UPSBatteryLowWarning === true ? "Fault" : "Normal", "",
                    IfIsFault(alarmData.UPSBatteryLowWarning, 'UPSBatteryLowWarning'))}
                    {this.renderRow("UPS-Warning-Input voltage abnormal", alarmData.UPSWarningIpnutVoltageAbnormal === true ? "Fault" : "Normal", "",
                    IfIsFault(alarmData.UPSWarningIpnutVoltageAbnormal, 'UPSWarningIpnutVoltageAbnormal'))}
                </div>
            </div>
        </fieldset>
    }
}

function mapStateToProps(state) {
    return {
        currentlevel: state.currentlevel,
        currentSite: state.currentSite,
        UserInfo: state.UserInfo,
        DeviceData: state.DeviceData,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate,
        DashboardPickedColdboxID: state.DashboardPickedColdboxID
    }
}  
export default connect(mapStateToProps)(Warning)

function IfIsFault(value, fieldName) {
    const configItem = fieldConfig.find(item => item.name == fieldName)
    if(configItem==null) {
        // console.log('fieldConfig exception')
        // console.log('fieldName: ' + fieldName)
        return
    }

    const {type, faultV, faultMax, faultMin} = configItem

    if(type=='bool') {
        if(faultV==true&&(value==true||value==1||value=='1')) return 'red'
        if(faultV==false&&(value==false||value==0||value=='0')) return 'red'
        if(value==undefined||value==null) return 'red'  //exception: no value, highlight it red for attention
    }
    if(type=='string') {
        if(faultV==value) return 'red'
        return ''
    }
    if(type=='number') {
        if(faultMax!=null && value>faultMax) {
            return 'red'
        }
        if(faultMax!=null && value<faultMin) {
            return 'red'
        }
    }

    return ''
}

const fieldConfig = [
    //samples
    {
        name: 'bool sample',
        type: 'bool',   //bool sample
        faultV: true
    },
    {
        name: 'number sample',
        type: 'number', //number sample
        faultMax: 100,
        faultMin: null  //null means no minimum
    },
    {
        name: 'string sample',
        type: 'string', //string sample
        faultV: 'Fault String Value'
    },
    //real usage
    {
        name: 'UPSBatteryLowWarning',
        type: 'bool',
        faultV: true
    },
    {
        name: 'UPSWarningIpnutVoltageAbnormal',
        type: 'bool',
        faultV: true
    },
]