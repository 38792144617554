import React from 'react'
import './table.css'
//Redux Import
import { connect } from 'react-redux'
import Moment from 'react-moment'
// import Loading from '../../../loading'
// import PopupNotification from '../../../../popupNotification/PopupNotification'
// import Coldbox_popup from '../popup/popup';

class Table extends React.Component {
    constructor(props) {
        super(props) 
        this.state = {
            isLoading: false ,       //loading effect on button
            isShowEditPopup:false
        } 
        this.updateEquipment = this.updateEquipment.bind(this)  
        this.openPopup = this.openPopup.bind(this)
    }

    componentDidMount(){ 
    }
    componentDidUpdate(){ 
        if(this.state.isLoading === true){
            this.setState({
                isLoading: false
            })
        }        
    }

     updateEquipment() {
        this.setState(prevState => ({
            ...prevState,
            isLoading:true
        }))
        const { UserInfo } = this.props 
        if (UserInfo == null) return
        const EmitUserID = UserInfo.userID
        //request json 
        let requestData = {}
        requestData = {
            "msgType": "updateEquipment",
            "userID": EmitUserID,
            "client_id": UserInfo.client_id, 
        }

        //send with redux socket function
        this.props.dispatch({
            type: 'EmitSocket',
            EmitSocketName: "EquipmentManageRequest",
            EmitSocketData: requestData
        })
    }

    openPopup(data){        
        const _this = this
        console.log(_this.props.data)
        _this.props.setSelected(_this.props.data)
    }
 
     
    render() { 
        const _this = this
        const data = this.props.data 
        const {isLoading} = this.state
        
        if(data === null && data === undefined){ 
            return <div></div>
        }
        return <div>
            <div className="CBAudit_tableRow" >
                <div className="col2">{(data.gatewayID)}</div>
                <div className="col2">{(data.uniqueID)}</div>
                <div className="col2">{(data.location)}</div>
                <div className="col2">{data.onlineStatus}</div>
                {/* <div className="col2">
                    <Moment format={'YYYY/MM/DD HH:mm'}>
                        {data.updateTime}
                    </Moment>
                </div> */}
                <div id={"UM_table_R"} className="fa fa-edit EditBtn " onClick={ _this.openPopup}></div>
            </div>
        </div>
    }
}

//Redux inside component function
function mapStateToProps(state) {
    return {
        UserInfo: state.UserInfo,
        socket: state.socket,
    }
}

export default connect(mapStateToProps)(Table) 