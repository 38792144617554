import React from 'react'
import './style.css'
//Redux Import
import { connect } from 'react-redux'

import {getTxt} from '../../common/language/language'

class SearchBar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pickedType: null,
            pickedSite: null,
            pickedFloor: null,
            pickedLoc: null,
        }
        //onChange functions
        this.pickType = this.pickType.bind(this)
        this.pickSite = this.pickSite.bind(this)
        this.pickFloor = this.pickFloor.bind(this)
        this.pickedLoc = this.pickedLoc.bind(this)

        //trigger parents function
        this.triggerparent = this.triggerparent.bind(this)
    }
    async pickType(event) {
        if(event.target.value == "all") this.setState({
            pickedType: null
        })
        await this.setState({
            pickedType: event.target.value,
        })
        this.triggerparent()
    }
    async pickSite(event) {
        if(event.target.value == "all") this.setState({
            pickedSite: null,
            pickedFloor: null,      //prevent keep floor value and cause bug
            pickedLoc: null,        //prevent keep floor value and cause bug
        })
        await this.setState({
            pickedSite: event.target.value
        })
        this.triggerparent()
    }
    async pickFloor(event) {
        if(event.target.value == "all") this.setState({
            pickedFloor: null,
            pickedLoc: null,        //prevent keep floor value and cause bug
        })
        await this.setState({
            pickedFloor: event.target.value
        })
        this.triggerparent()
    }
    async pickedLoc(event) {
        if(event.target.value == "all") this.setState({
            pickedLoc: null,
        })
        await this.setState({
            pickedLoc: event.target.value
        })
        this.triggerparent()
    }
    triggerparent() {
        const {pickedType, pickedSite, pickedFloor, pickedLoc} = this.state
        this.props.searchbarTrigger(pickedType, pickedSite, pickedFloor, pickedLoc)
    }

    render() {
        const thisoutside = this
        const {menuList} = this.props           //input from parent widget, not redux
        const {DeviceData} = this.props         //Redux Reading
        const {pickedSite,pickedFloor, pickedLoc} = this.state      //picked storage (local)
        if(menuList == null || menuList.length == null || menuList.length == 0) return <div></div>
        if(DeviceData == null || DeviceData.length == null) return <div></div>
        //floor list generate
        let floorList = []
        if(pickedSite != null) {
            let sitePicked = DeviceData.find(function(siteItem) {
                return siteItem.locName == pickedSite
            })
            if(sitePicked != null) floorList = sitePicked.nestedLocs
        }
        //loc list generate
        let locList = []
        if(pickedSite != null && pickedFloor != null) {
            let floorPicked = floorList.find(function(floorItem) {
                return floorItem.locName == pickedFloor
            })
            if(floorPicked != null) locList = floorPicked.nestedLocs
        }
        return <div className="floorplantable_SearchBar">
            <div className="floorplantable_txt">
                {getTxt('DashTable', 'type')}
            </div>
            <select className="floorplantable_Select" onChange={thisoutside.pickType}>
                <option value="all">All ReadingType</option>
                {
                    menuList.map(function(menuItem) {
                        return <option value={menuItem}>
                            {menuItem}
                        </option>
                    })
                }
            </select>
            <div className="floorplantable_txt">
                {getTxt('DashTable', 'site')}
            </div>
            <select className="floorplantable_Select" onChange={thisoutside.pickSite}>
                <option value="all">All Site</option>
                {
                    DeviceData.map(function(siteItem) {
                        return <option value={siteItem.locName}>
                            {siteItem.locName}
                        </option>
                    })
                }
            </select>
            <div className="floorplantable_txt">
                {getTxt('DashTable', 'flr')}
            </div>
            <select className="floorplantable_Select" onChange={thisoutside.pickFloor}>
                <option value="all">All Floor</option>
                {
                    (floorList == [])?<option></option>:floorList.map(function(floorItem) {
                        return <option value={floorItem.locName}>
                            {floorItem.locName}
                        </option>
                    })
                }
            </select>
            <div className="floorplantable_txt">
                {getTxt('DashTable', 'loc')}
            </div>
            <select className="floorplantable_Select" onChange={thisoutside.pickedLoc}>
                <option value="all">All Location</option>
                {
                    (locList == [])?<option></option>:locList.map(function(locItem) {
                        return <option value={locItem.locName}>
                            {locItem.locName}
                        </option>
                    })
                }
            </select>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        DeviceData: state.DeviceData,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate,
    }
}

export default connect(mapStateToProps)(SearchBar)