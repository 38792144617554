export function getTxt(page, key1, key2) {  // key1 only, key2 when 2 lvl in json
    const langKey = getLang()
    const displayTxt = key2==null?langTxt[page][langKey][key1]
    :
    langTxt[page][langKey][key1]!==null?langTxt[page][langKey][key1][key2]:'wrong key1 or key2'

    return displayTxt
}
export function invertLang() {
    const langStr = getLang()
    if(langStr=='eng') localStorage.setItem('lang', 'chin')
    else localStorage.setItem('lang', 'eng')
}
export function LangBtnDisplay() {
    const langStr = getLang()
    if(langStr=='eng') return '中文'
    return 'English'
}

// usage guide
// import {invertLang, getTxt} from '../../language/language.js'
// {getTxt('page', 'key')}

// inner function
export function getLang() {
    const langStr = localStorage.getItem('lang')
    if(langStr==null||langStr=='') {
        localStorage.setItem('lang', 'eng')
        return 'eng'
    }

    return langStr
}

//lang data
const langTxt = {
    Main: {
        eng: {
            center: 'center',
            iothub: 'IoT Hub Platform',
            company: 'ATAL Engineering Ltd.',
            dash: 'Dashboard',
            stat: 'Statistic'
        },
        chin: {
            center: '中心', //..
            iothub: '物聯網平台',
            company: 'ATAL Engineering Ltd.',
            dash: '平面圖',
            stat: '數據統計'
        }
    },
    LSide: {
        eng: {
            menu: 'Menu',
            home: 'Home',
            dashbd: 'Dashboard',
            dashbdT: 'Dashboard Table',
            statistic: 'Statistic',
            notification: 'Notification',
            userMgt: 'User Management',
            alrmTrigger: 'Alarm Trigger',
            auditTrail: 'Audit Trail Log'
        },
        chin: {
            menu: '',
            home: '主頁',
            dashbd: '平面圖',
            dashbdT: '平面圖數據',
            statistic: '數據統計',
            notification: '警報',
            userMgt: '用戶管理',
            alrmTrigger: '警報設置',
            auditTrail: '用戶操作記錄'
        }
    },
    RSide: {
        eng: {
            alarmTitle: 'Alarm Centre',
            alarmDesc: 'Lastest 10 Record',
            view: 'View',
            setting: 'Setting',
            downApp: 'Download App',
            advance: 'Advanced',
            pickClr: 'Pick Color Theme',
            darkMde: 'Dark / Light Mode',
            changePwd: 'Change Password',
            logout: 'Logout'
        },
        chin: {
            alarmTitle: '警報',
            alarmDesc: '最新10條記錄',
            view: '查看全部',
            setting: '設定',
            downApp: '下載 App',
            advance: '其他設定',
            pickClr: '選擇主題',
            darkMde: '夜/日',
            changePwd: '更改密碼',
            logout: '登出'
        }
    },
    Dashbd: {
        eng: {
            platformTitle: 'IoT Platform Console',
            sensorTitle: {
                waterL: 'Water Leakage',
                waterP: 'Water Pump',
                waterPF: 'Water Pump Fault',
                occ: 'Occupancy',
                vib: 'Vibration',
                drContact: 'Door Contact',
                IEQ: 'IEQ',
                ToiletVac: 'Toilet Vacancy',
                open: 'Open',
                close: 'Close',
                allClose: 'All Closed',
                someOpen: 'Some Opened',
                carpark: 'Car Park'
            },
            cardTxt: {
                normal: 'Normal',
                abNormal: 'Abnormal',
                occ: 'Occupied',
                vac: 'Vacant',
                temp: 'Temp',
                humid: 'Humid',
                co2: 'CO2',
                pm2_5: 'PM2.5'
            }
        },
        chin: {
            platformTitle: 'IoT Platform Console',
            sensorTitle: {
                waterL: '漏水',
                waterP: '水泵',
                waterPF: '水泵故障',
                occ: '使用狀況',
                vib: '振動',
                drContact: '門 開/關',
                IEQ: 'IEQ',
                ToiletVac: '可用廁格',
                open: '開',
                close: '關',
                allClose: '全關',
                someOpen: '部分已開',
                carpark: '停車場'
            },
            cardTxt: {
                normal: '正常',
                abNormal: '不正常',
                occ: '有人',
                vac: '可用', //'空位',
                temp: '溫度',
                humid: '濕度',
                co2: 'CO2',
                pm2_5: 'PM2.5'
            }
        }
    },
    DashTable: {
        eng: {
            type: 'Sensor Type',
            site: 'Site',
            flr: 'Floor',
            loc: 'Location'
        },
        chin: {
            type: 'Sensor 種類',
            site: '地點',
            flr: '樓層',
            loc: '位置'
        }
    },
    Statistic: {
        eng: {
            pickSite: 'Pick Site',
            pickLvl: 'Pick Level',
            newAlarm: 'New Alarms',
            ackAlarm: 'Acknowledge Alarms',
            temp: 'Temperature',
            monthSum: 'Temperature and Humidity Monthly Summary',
            IAQSum: 'IAQ Summary',
            start: 'Start',
            end: 'End',
            readType: 'Reading Type',
            interval: 'Time Interval',
            downCsv: 'Download CSV',
            sensorFilter: 'Sensor Filter',
            autoY: 'Auto Y',
            defaultY: 'Default Y',
            last7Sum: 'Last 7 Day Summary'
        },
        chin: {
            pickSite: '選擇地點',
            pickLvl: '選擇樓層',
            newAlarm: '警報',
            ackAlarm: '已確認警報',
            temp: '溫度',
            monthSum: '溫濕度 Monthly Summary',
            IAQSum: 'IAQ Summary',
            start: '開始時間',
            end: '結束時間',
            readType: '種類',//..
            interval: 'Time Interval',
            downCsv: 'Download CSV',
            sensorFilter: 'Sensor Filter',
            autoY: 'Auto Y',
            defaultY: 'Default Y',
            last7Sum: 'Last 7 Day Summary'
        }
    },
    Notification: {
        eng: {
            type: 'Type',
            status: 'Status',
            from: 'From',
            to: 'To',
            site: 'Site',
            level: 'Level',
            loc: 'Location',
            Ackowledge: 'Acknowledge',
            ackT: 'Acknowledge Time',
            ackPer: 'Acknowledge by',
            alarmName: 'Alarm Name',
            severity: 'Severity',
            triggerT: 'Trigger Time',
            msg: 'Message',
            shwInFlrPlan: 'Show in Floor Plan',
            shw: 'Show',

            //type items
            all: 'All',
            system: 'System',
            equip: 'Equipment',
            device: 'Device',
            //status items
            ack: 'Acknowledge',
            newAlarm: 'New Alarms'
        },
        chin: {
            type: '種類',
            status: '狀態',
            from: '開始',
            to: '結束',
            site: '地點',
            level: '樓層',
            loc: '位置',
            Ackowledge: '狀態',
            ackT: '已讀時間',
            ackPer: '負責跟進',
            alarmName: '警報名稱',
            severity: '嚴重性',
            triggerT: '創建時間',
            msg: '訊息',
            shwInFlrPlan: '在平面圖顯示',
            shw: '顯示',

            //type items
            all: '顯示全部',
            system: '系統',
            equip: '裝置',
            device: '設備',
            //status items
            ack: '已讀警報',
            newAlarm: '新警報'
        }
    },
    userMgt: {
        eng: {
            accName: 'Account Name',
            userRole: 'User Role',
            company: 'Company',
            createDateTime: 'Create Date Time',
            lastLogin: 'Last Login',
            del: 'Delete',
            submit: 'Submit',
            cancel: 'Cancel'
        },
        chin: {
            accName: '用戶名',
            userRole: '用戶級別',
            company: '公司',
            createDateTime: '創建時間',
            lastLogin: '上次登錄',
            del: '刪除',
            submit: '提交',
            cancel: '取消'
        }
    },
    resetPwd: {
        eng: {
            firstChangePassword: 'Please reset your Password when First Login',
            changePassword: 'Change Password',
            currentPassword: 'Current Password',
            newPassword: 'New Password',
            retypeNewPassword: 'Retype new Password',
            submit: 'Submit',
            cancel: 'cancel'
        },
        chin: {
            firstChangePassword: '第一次登錄密碼',
            changePassword: '更改密碼',
            currentPassword: '現時密碼',
            newPassword: '新密碼',
            retypeNewPassword: '重新輸入新密碼',
            submit: '提交',
            cancel: '取消'
        }
    }
}