import { faCloudShowersHeavy } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { dataset_dev } from 'svelte/internal'
import './Weather.css'

const MinutesIntervak = 1

function WeatherWidget(props) {
    const [WeatherData, setWeatherData] = useState()

    useEffect(() => {
        let WeatherUpdaLoop= setInterval(UpdateWeather, 1000 * 60 * 60 * MinutesIntervak)
        UpdateWeather()

        return function TerminateInterval() {
            clearInterval(WeatherUpdaLoop)
        }
    }, [])
    async function UpdateWeather() {
        fetch('https://data.weather.gov.hk/weatherAPI/opendata/weather.php?dataType=rhrread&lang=en')
        .then(res => res.json())
        .then((data) => {
            const {rainfall, icon, temperature, humidity} = data

            const RainfallData = rainfall.data
            const TempData = temperature.data
            const HumidData = humidity.data
        
            const RainfallMax = AverageValue(RainfallData, "max")
            const Temp = AverageValue(TempData, "value")
            const Humid = AverageValue(HumidData, "value")
        
            let LocalWeatherStorage = {
                rain: RainfallMax,
                rainunit: 'mm',
                temp: Temp,
                tempunit: 'C',
                humid: Humid,
                humidunit: '%',
                icon: icon,
            }
            setWeatherData(LocalWeatherStorage)
        })
        .catch(console.log)
    }

    const BGColor = (WeatherData==null||WeatherData.icon==null||WeatherData.icon.length==null||WeatherData.icon.length==0)?FindCardBGColor():FindCardBGColor(WeatherData.icon.toString())
    let WeatherImagePath
    if(WeatherData==null||WeatherData.icon==null||WeatherData.icon.length==null||WeatherData.icon.length==0) {
        WeatherImagePath = +'url(https://47.52.20.40:30405/assets/images/toilet/weathericons/pic51.png)'
    }
    else {
        WeatherImagePath = 'url(https://47.52.20.40:30405/assets/images/toilet/weathericons/pic' + WeatherData.icon.toString() + '.png)'
    }


    return <div className={"Weather " + BGColor} style={{backgroundImage: WeatherImagePath, backgroundSize: '70%', backgroundPosition: '50% 40%', backgroundRepeat: 'no-repeat'}}>
        <div className="WeatherEmptySpace"></div>
        <div className="Weather_loc">Hong Kong</div>
        <div className="Weather_botRow">
            <div className="Weather_temp">
                {(WeatherData==null||WeatherData.temp==null)?'0':WeatherData.temp.toFixed(0)}°
            </div>
            <div className="Weather_card">
                <div className="Weather_card_top">Rain</div>
                <div className="Weather_card_bot">
                {(WeatherData==null||WeatherData.rain==null)?'--':WeatherData.rain.toFixed(0)}
                <sub>mm</sub>
                </div>
            </div>
            <div className="Weather_card">
                <div className="Weather_card_top">Humid</div>
                <div className="Weather_card_bot">
                {(WeatherData==null||WeatherData.humid==null)?'0':WeatherData.humid.toFixed(0)}
                <sub>%</sub>
                </div>
            </div>
        </div>
    </div>
}

export default WeatherWidget

// w = 2
// h = 6


function AverageValue(array, keystr) {
    if(array == null || array.length == null || array.length == 0) return []

    let totalvalue = 0
    array.forEach(item => {
        totalvalue += item[keystr]
    });
    const totalCount = array.length
    const average = totalvalue / totalCount
    return average
}

function FindCardBGColor(index) {
    let ColorCode = 'white'
    if(index >= 50 && index <=54) ColorCode = 'Sunny'
    else if(index >= 90 && index <=91) ColorCode = 'Sunny'
    else if(index >= 60 && index <=62) ColorCode = 'Cloudy'
    else if(index >= 63 && index <=64) ColorCode = 'Rainny'
    else if(index == 65) ColorCode = 'dark'
    else if(index >= 80 && index <=85) ColorCode = 'wind'
    else if(index >= 92 && index <=93) ColorCode = 'wind'
    else if(index >= 70 && index <=77) ColorCode = 'dark'

    return ColorCode
}