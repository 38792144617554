import React from 'react'
import Level from './Level'
import {connect} from 'react-redux'

class LevelContainer extends React.Component {
    componentDidCatch(error, info) {
        console.error('componentDidCatch() on LevelContainer.js')
        console.error(error)
        console.error(info)
        this.props.history.push("/App")                             //redirect to App.js to reload all the datas
    }
    render() {
        const {history, currentLevel, currentSite, UserInfo} = this.props
        let level
        try {
            level = UserInfo.locations[currentSite].nestedLocs      //find the level item, with currentSite and currentLevel, from UserInfo
            level = level.sort((a, b) => a.locIdx - b.locIdx)
        }
        catch(err) {
            console.log(err)
        }

        if(level===undefined || level===null) return <></>

        return [
            level.map((result, i) => {
                let longBtnClass = (result.locName.length >= 3)?'autoWidthItem':''

                return <Level
                    title={result.locName}
                    id={'Level_' + result.locName}
                    history={history}
                    currentLevel={currentLevel}
                    myclass={longBtnClass}
                />
            })
        ]
    }
}
function mapStateToProps(state) {
    return {
      currentlevel: state.currentlevel,
      UserInfo: state.UserInfo,
      currentSite: state.currentSite
    }
}
export default connect(mapStateToProps)(LevelContainer)