import React from 'react'
import './ColdboxStatus.css'
import { connect } from 'react-redux'

import Searchbar from './searchbar/searchbar'
import Status from './statustable/status'
import SetPoint from './statustable/setpoint'
import UPSAlarm from './statustable/upsalarm'
import Alarm from './statustable/alarm'
import Warning from './statustable/warning'

class ColdboxStatus extends React.Component {
    constructor(props) {
        super()
        this.state = {
            displayitem: {},
            isGpDisplay: false,
            selectedGp: null,
        }
        this.backpage = this.backpage.bind(this)
        // this.groupDiffpage = this.groupDiffpage.bind(this)
        this.setItem = this.setItem.bind(this)
        this.selectGp = this.selectGp.bind(this)
    }
    backpage() {
        this.props.history.goBack()
    }
    // groupDiffpage() {
    //     this.props.history.push('/Main/FloorPlan/ColdboxGroupDiff')
    // }
    componentDidMount() {
        let pathUrl = "/Main/FloorPlan";
        this.props.dispatch({ type: 'LatestPage', data: pathUrl })
       
        this.setState({
            displayitem: this.props.location.Item
        })
    }

    componentDidUpdate() {}

    setItem(i) {
        console.log("setItem")
        this.setState(prevState => ({
            ...prevState,
            displayitem: i
        }))
    }
    selectGp(gpInput, isGpDisplay) {
        this.setState({
            isGpDisplay: isGpDisplay,
            selectedGp: gpInput,
        })
    }

    render() {
        const {displayitem, isGpDisplay, selectedGp} = this.state
        const {DeviceData, GroupList} = this.props
        const displatTitle = ColdboxTitle(displayitem, isGpDisplay, selectedGp)
        const groupDisplay = ColdboxGroupDisplay(DeviceData, selectedGp, GroupList)

        return <div className="Coldbox_SPage">
            <Searchbar backpage={this.backpage} groupDiffpage={this.groupDiffpage} setItem={this.setItem} selectGp={this.selectGp} />
            <div className="Coldbox_S_tablemain">
                <div className="Coldbox_S_tablemainTitle">{displatTitle}</div>
                <div className="Coldbox_S_tableList">
                    <div className="list_col">
                        <Status Item={displayitem} groupDisplay={groupDisplay} isGpDisplay={isGpDisplay} />
                        <SetPoint Item={displayitem} groupDisplay={groupDisplay} isGpDisplay={isGpDisplay} />
                    </div>
                    <div className="list_col">
                        <Alarm Item={displayitem} groupDisplay={groupDisplay} isGpDisplay={isGpDisplay} />
                    </div>
                    <div className="list_col">
                        <UPSAlarm Item={displayitem} groupDisplay={groupDisplay} isGpDisplay={isGpDisplay} />
                        <Warning Item={displayitem} groupDisplay={groupDisplay} isGpDisplay={isGpDisplay} />
                    </div>
                </div>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        DeviceData: state.DeviceData,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate,
        DashboardPickedColdboxID: state.DashboardPickedColdboxID,
        GroupList: state.GroupList,
    }
}
export default connect(mapStateToProps)(ColdboxStatus)

function ColdboxTitle(displayitem, isGpDisplay, selectedGp) {
    let returnStr = 'Coldbox ID: '
    if(isGpDisplay == false) {
        returnStr += displayitem==null||displayitem.locName==null?'--':displayitem.locName
    }
    else if(selectedGp==null) {
        returnStr = 'Coldbox Group: All'
    }
    else {
        returnStr = 'Coldbox Group: ' + selectedGp
    }

    return returnStr
}

// Main function
function ColdboxGroupDisplay(DeviceData, selectedGp, GroupList) {
    if(DeviceData == null || GroupList == null) return []
    const coldBoxList = GetAllColdbox(DeviceData)
    const coldboxInGroup = getcoldboxListFromGroup(GroupList, selectedGp)       // get all coldbox (list of id)
    const filteredCdbxList = coldBoxList.filter(item => {
        if(selectedGp==null) return true                                        // always true, since selectedGp==null only when 'select all group'
        let isInGroup
        coldboxInGroup.forEach(locID => {
            if(item._id == locID) isInGroup = true
        })
        return isInGroup
    })
    const StrNoResult = GetValueResult(filteredCdbxList, 'strNumber')
    const NoResult = GetValueResult(filteredCdbxList, 'number')
    const BoolResult = GetValueResult(filteredCdbxList, 'bool')

    return {
        ...StrNoResult,
        ...NoResult,
        ...BoolResult,
    }
}

// Sub functions
function GetAllColdbox(DeviceData) {
    let coldBoxList = []
    DeviceData.forEach(siteItem => {
        siteItem.nestedLocs.forEach(lvlItem => {
            lvlItem.nestedLocs.forEach(locItem => {
                coldBoxList.push(locItem)
            })
        })
    })
    return coldBoxList
}
function getcoldboxListFromGroup(GroupList, selectedGroup) {
    if(GroupList == null || GroupList.groups == null) return []
    const grouplist = GroupList.groups
    const groupItem = grouplist.find(item => {
        return item.group_name == selectedGroup
    })
    const locList = groupItem==null?[]:groupItem.location
    let coldboxList = []
    locList.forEach(locItem => {
        locItem.forEach(innerLoc => {
            coldboxList.push(innerLoc._id)
        })
    })

    return coldboxList
}
const PayloadDataType = {
    strNumber: [
        'Defrostheater',
        'Status',
        'System',
        'UPSOutputCurrent',
        'Zone',
    ],
    number: [
        'COP',
        'CondensingPressureWarningHighSetPoint',
        'CondensingPresureWarningHighSetPoint',
        'EvaporatingPressureWaringLowSetpoint',
        'EvaporatingPressureWarningLowSetpoint',
        'ExpansionValveOpening',
        'Expansionvalveopening',
        'PressureCondensing',
        'PressureEvaporating',
        'RH',
        'RHWarningHighSetPoint',
        'RHWarningLowSetPoint',
        'ReturnAirTemp',
        'RunHrs',
        // 'Runhrs',
        'Temp1',
        'Temp2',
        'Temp3',
        'Temp4',
        'Temp5',
        'TempHighSetPoint',
        'TempLowSetPoint',
        'TempSetPoint',
        'TempSuction',
        'UPSBatteryVoltage',
        'UPSCharging',
        'UPSLoad',
        'UPSOutputCurrent',
        'UPSOutputFrequency',
        'UPSOutputVoltage',
        'UPSchargingtime',
        'SuctionTempWarningHighSetPoint',
        'UPSoutputfrequency',
        'UPSoutputvoltage',
        'WeightedFactorReturnAirTemp',
        'WeightedFactorTemp1',
        'WeightedFactorTemp2',
        'WeightedFactorTemp3',
        'WeightedFactorTemp4',
        'WeightedFactorTemp5',
        'WeightedMeanTemp',
        'UPSBatteryCapacity'
    ],
    bool: [
        'GeneralAlarmUPS',
        'ColdboxHighTemperatureAlarm',
        'ColdboxLowTemperatureAlarm',
        'Coldboxhightemperaturealarm',
        'Coldboxlowtemperaturealarm',
        'CompressorFan',
        'CompressorFanEvent',
        'CompressorMotorFault',
        'CompressorNotRunFault',
        'Compressormotorfault',
        'Compressornotrunfault',
        'CondensingPressureSensorFault',
        'CondensingPressureWarningHighSetPointEvent',
        'CondensingPresureWarningHighSetPointEvent',
        'Condensingpressuresensorfault',
        'DefrostHeaterEvent',
        'DefrostheaterEvent',
        'DoorPosition',
        'DoorPositionEvent',
        'DoorPositionEvent',
        'Dooropenwarning',
        'DoorpositionEvent',
        'EmergencyStop',
        'EvaporatingPressureSensorFault',
        'EvaporatingPressureWaringLowSetpointEvent',
        'EvaporatingPressureWarningLowSetpointEvent',
        'Evaporatingpressuresensorfault',
        'EvaporatorFan',
        'EvaporatorFan',
        'EvaporatorFanEvent',
        'EvaporatorFanNotRunFault',
        'EvaporstorFanNotRunFault',
        'ExpansionValveControllerFault',
        'Expansionvalvecontrollerfault',
        'FanMotorFault',
        'Fanmotorfault',
        'FreqInverterFault',
        'GeneralAlarm',
        'HMIAlarm',
        'HighPressureCutout',
        'Highpressurecutout',
        'Light',
        'LightEvent',
        'LowPressureCutout',
        'Lowpressurecutout',
        'RHSensorFault',
        'RHWarningHighSetPointEvent',
        'RHWarningLowSetPointEvent',
        'RHsensorfault',
        'SuctionTempWarningHighSetPointEvent',
        'SystemEvent',
        'SystemRunningStatus',
        'SystemRunningStatusEvent',
        'SystemStart',
        'SystemrunningstatusEvent',
        'TempHighSetPointEvent',
        'TempLowSetPointEvent',
        'TempSensor1Fault',
        'TempSensor2Fault',
        'TempSensor3Fault',
        'TempSensor4Fault',
        'TempSensor5Fault',
        'TempSensorARfault',
        'TempSetPointEvent',
        'Tempsensor1fault',
        'Tempsensor2fault',
        'Tempsensor3fault',
        'Tempsensor4fault',
        'Tempsensor5fault',
        'TempsensorARfault',
        'UPSBatteryLowFault',
        'UPSBatteryLowWarning',
        'UPSBatteryLowfault',
        'UPSBatteryNotConnected',
        'UPSBatterynotconnected',
        'UPSChargingEvent',
        'UPSFanFailure',
        'UPSInverterFault',
        'UPSInverterOverload',
        'UPSInverteroverload',
        'UPSOverTemperature',
        'UPSRectifierFault',
        'UPSWarningInputVoltageAbnormal',
        'UPSWarningInputvoltageabnormal',
        'UPSOutputShorted',
        'UPSfanfailure',
        'UPSinverterfault',
        'UPSoutputshorted',
        'UPSovertemperature',
        'UPSrectifierfault',
        'WeightedFactorReturnAirTempEvent',
        'WeightedFactorTemp1Event',
        'WeightedFactorTemp2Event',
        'WeightedFactorTemp3Event',
        'WeightedFactorTemp4Event',
        'WeightedFactorTemp5Event',
        'ATALControllerFault',
        'BeaconLowBatteryFault',
        'ContaclessChargerFault',
        'Defrostheaterfault',
        'DoorPosition',
        'InnIceControllerFault',
        'LoRaWANGatewayFault',
        'SystemRunningStatus',
    ]
}
function GetValueResult(coldboxList, type) {
    const keyList = PayloadDataType[type]
    let Result = {
        type: type,
    }
    keyList.forEach(keyStrItem => {
        Result[keyStrItem] = []
    })

    coldboxList.forEach(coldboxItem => {
        const payload = coldboxItem.payload
        keyList.forEach(keyStrItem => {
            if(payload==null||payload[keyStrItem]==null) return
            try {
                Result[keyStrItem] = [
                    payload[keyStrItem],
                    ...Result[keyStrItem]
                ]
            }
            catch(err) {
                console.log(err)
                console.log(Result)
                console.log(keyStrItem)
            }
        })
    })

    let returnResult = {}
    keyList.forEach(keyStrItem => {
        const valueList = Result[keyStrItem]
        if(type=='bool') {
            let boolResult = 0  // 0=start, 1=only T, 2=only F, 3=Both
            valueList.forEach(boolItem => {
                const boolTrue = (boolItem==true||boolItem==1||boolItem=='1')
                const boolFalse = (boolItem==false||boolItem==0||boolItem=='0')

                if(boolTrue && (boolResult==0||boolResult==1)) boolResult = 1
                if(boolTrue && (boolResult==2||boolResult==3)) boolResult = 3
                if(boolFalse && (boolResult==0||boolResult==2)) boolResult = 2
                if(boolFalse && (boolResult==1||boolResult==3)) boolResult = 3
            })
            returnResult[keyStrItem] = boolResult
        }
        else if(type=='strNumber'||type=='number') {
            const NumList = Result[keyStrItem].map(item=> {
                if(item==null||item==undefined) return false
                return parseFloat(item.toString())
            })
            const maxV = Math.max(...NumList)   //... is the debug for Math.min(array)
            const minV = Math.min(...NumList)

            returnResult[keyStrItem] = {
                max: maxV,
                min: minV
            }
        }
    })
    return returnResult
}