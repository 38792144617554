import React from 'react'
import './create.css'
import { connect } from 'react-redux'

class Create extends React.Component {
    constructor(props) {
        super()

        this.state = {
            coldboxID: '',
            coldboxName: '',
            groupPicked: '',
        }

        this.InputChange = this.InputChange.bind(this)
        this.Submit = this.Submit.bind(this)
        this.Close = this.Close.bind(this)
    }

    InputChange(event, keyStr) {
        try {
            const InputValue = event.target.value
            this.setState({
                [keyStr]: InputValue
            })
        }
        catch(err) {
            console.log(err)
        }
    }
    Submit() {
        const {groupPicked, coldboxID, coldboxName} = this.state
        const {UserInfo} = this.props
        if(UserInfo==null) return

        const userID = UserInfo.userID

        const requestData = {
            "msgType": "LocationManagement",
            "method": "Create",
            "userID": userID,
            'coldboxID': coldboxID,
            'device_id': coldboxName,
            'group': groupPicked,
        }
        console.log(requestData)

        this.props.dispatch({
            type: 'EmitSocket',
            EmitSocketName: "StatRequest",      //StatRequest can request for any type data
            EmitSocketData: requestData
        })
        this.props.closePopup()
    }
    Close() {
        this.props.closePopup()
    }

    render() {
        const _this = this
        const {isEdit, isPopup, deleteConfirm, DeviceManageList} = this.props        
        const groupOptionList = genGroupOptionList(_this.props)

        return  (!isEdit && isPopup && !deleteConfirm)?
        [
            <div className="ColdboxIndi_popupMain">
                <div className="ColdboxIndi_popupTitle">Add Coldbox</div>
                <div className="ColdboxIndi_popupcloseBtn" onClick={this.Close}>
                    <div className="fa fa-times"></div>
                </div>
                <div className="ColdboxIndi_popupRow">
                    <input className="ColdboxIndi_popupInput" placeholder="Coldbox ID" onChange={(e) => _this.InputChange(e, 'coldboxID')}></input>
                    <input className="ColdboxIndi_popupInput" placeholder="Device ID" list='deviceList' onChange={(e) => _this.InputChange(e, 'coldboxName')}></input>
                    <datalist id='deviceList' className='ColdboxIndi_DeviceIDList'>
                    {
                        DeviceManageList.map(deviceItem => {
                            const {deviceSerial, deviceName} = deviceItem
                            return <option value={deviceSerial}>
                                {deviceName}
                            </option>
                        })
                    }
                    </datalist>
                </div>
                <div className='row'>
                    <div>Select Coldbox Group:</div>
                    <select className="ColdboxIndi_popupselect" onChange={(e) => _this.InputChange(e, 'groupPicked')}>
                        <option style={{background: 'rgba(0,0,0,0.3)'}}>No Group</option>
                        {
                            groupOptionList.map(function(groupItem) {
                                return <option style={{background: 'rgba(0,0,0,0.3)'}} value={groupItem.name}>
                                    {groupItem.name}
                                </option>
                            })
                        }
                    </select>
                </div>            
                <div className='ColdboxIndi_popupBottom'>
                    <div className="ColdboxIndi_popupSubmit" onClick={this.Submit}>Submit</div>
                    <div className="ColdboxIndi_popupCancel" onClick={this.Close}>Cancel</div>
                </div>
            </div>
        ]
        :
        <div style={{display:'none'}}></div>
    }
}

function mapStateToProps(state) {
    return {
        DeviceManageList: state.DeviceManageList,
        GroupList: state.GroupList,
        UserInfo: state.UserInfo
    }
}  
export default connect(mapStateToProps)(Create)

function genGroupOptionList(props) {
    const {GroupList} = props
    if(GroupList == null || GroupList.groups == null || GroupList.groups.length == null || GroupList.groups.length == 0) return []
    var optionList = GroupList.groups.map(item => {
        return {
            value: item.id,
            name: item.group_name,
        }
    })
    var sortedList = optionList.sort((a,b) => {
        if (b.name > a.name) return -1
        if (b.name < a.name) return 1
        return 0
    })
    return sortedList
}