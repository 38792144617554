import React from 'react'
import './delete.css'

//Redux Import
import { connect } from 'react-redux'

class Delete extends React.Component {
    constructor(props) {
        super()
        this.state = {
            selectedGroup: null
        }
        this.sendDeleteGroupReq = this.sendDeleteGroupReq.bind(this)
        this.Close = this.Close.bind(this)
    }

    Close() {
        this.props.closePopup()
    }

    sendDeleteGroupReq() {
        const { UserInfo, GroupList, data } = this.props
        const { selectedGroup } = this.state
        if (UserInfo == null) return
        const EmitUserID = UserInfo.userID

        if (data !== null && data !== undefined) {

            //request json 
            let requestData = {}
            requestData = {
                "msgType": "DeleteGroup",
                "userID": EmitUserID,
                "_id": data._id
            }

            //send with redux socket function
            this.props.dispatch({
                type: 'EmitSocket',
                EmitSocketName: "GroupManageRequest",
                EmitSocketData: requestData
            })
        }
        this.Close()
    }

    render() {
        const { isPopup, isEdit, isDelete, data } = this.props     //send from parent

        return (isPopup && isDelete) ?
            <div className="Coldbox_group_PopupBG" onClick={this.Close}></div>
            :
            <div style={{ display: 'none' }}></div>,
            (isPopup && isDelete) ?
                <div className="Coldbox_delete_group_PopupMain">
                    <div className="Coldbox_group_PopupTitle">Delete Group?</div>
                    <div className="Coldbox_group_PopupcloseBtn" onClick={this.Close}>
                        <div className="fa fa-times"></div>
                    </div>
                    <input className="Coldbox_delete_group_Popup_input" placeholder="Group Name" value={(data !== null && data !== undefined? data.group_name: "")} disabled></input>
                    <div className="Coldbox_group_Popup_Btns">
                        <div className="Coldbox_group_Popup_DelBtns" onClick={this.sendDeleteGroupReq}>Delete</div>
                        <div className="Coldbox_group_Popup_CancelBtns" onClick={this.Close}>Cancel</div>
                    </div>
                </div>
                :
                <div style={{ display: 'none' }}></div>
    }
}

function mapStateToProps(state) {
    return {
        socket: state.socket,
        currentlevel: state.currentlevel,
        currentSite: state.currentSite,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate, //when this value is updated, render would triggered
        StatisticData: state.StatisticData,
        UserInfo: state.UserInfo,
        currentlevelID: state.currentlevelID,
        GroupList: state.GroupList
    }
}

export default connect(mapStateToProps)(Delete)