import React, { useRef, useEffect, useState } from 'react'
import './setpointHead.css'
import { GetRelatedList } from './function'

function SetpointHead(props) {
    const { SetColdboxID, backPage,SetSelectedGroup, isGroupSetPt, selectedGroup, setisGroupSetPt } = props          //2 functions from parent
    const { DeviceData, GroupList } = props                      //redux
    const textInput = React.createRef()
    const groupInput = React.createRef()
    const [coldboxList, setcoldboxList] = useState([])

    function Search() {
        if (textInput == null && textInput.current == null && groupInput == null || groupInput.current == null) return
        const Value = textInput.current.value
        localStorage.setItem('coldboxSetPt', Value)
        SetColdboxID(Value)
    }    
    function SearchGroup(event) {
        let value = event.target.value
        // if (groupInput == null || groupInput.current == null) return
        // const Value = groupInput.current.value
        if(value == 'All Group') {
            localStorage.setItem('groupSetPt', '')
            SetSelectedGroup(null)
            setisGroupSetPt(false)
            return
        }
        localStorage.setItem('groupSetPt', GroupList.groups[value])
        SetSelectedGroup(GroupList.groups[value])
        setisGroupSetPt(true)
    }
    useEffect(() => {
        //keyboard onpress listener (enter) when didmount
        document.onkeydown = function (event) {
            switch (event.keyCode) {
                case 13:
                    Search()
                    break
                default:
                    break
            }
        }
        return () => {
            //alert('******************* UNMOUNTED')
            try {
                document.onkeydown = null
            }
            catch (err) {
                console.log(err)
            }
        }
    })//, [])
    useEffect(() => {
        //Set the Coldbox List from DeviceData
        const ColdboxList = GetRelatedList(DeviceData)
        const ColdboxSerialList = ColdboxList.map((item) => {
            // const deviceSerial = (item == null || item.device_id == null)?'Device Serial Missing:' + item.locName:item.device_id.deviceSerial
            // return deviceSerial
            return item.locName
        })
        setcoldboxList(ColdboxSerialList)
    }, [])
    function inputOnChange(event) {
        var inputV = event.target.value
        if(inputV == null || inputV == '') setisGroupSetPt(true)
        else {
            setisGroupSetPt(false)
            localStorage.setItem('coldboxSetPt', inputV)
            SetColdboxID(inputV)
        }
    }

    return <div className="ColdboxSetPt_head">
        <div className="ColdboxSetPt_headL">
            <div className="ColdboxSetPt_backBtn" onClick={backPage}>
                <div className="fa fa-arrow-left"></div>
            </div>
            <div className="ColdboxSetPt_headTitle">Coldbox Set-point:{isGroupSetPt?' Group':' Individual'}</div>
        </div>
        <div className="ColdboxSetPt_headR">
            <div className="ColdboxSetPt_headTitle">Search Coldbox ID</div>
            <input className="ColdboxSetPt_headInput" type="search" list="coldboxList" placeholder="Coldbox ID" ref={textInput} onChange={(e)=>inputOnChange(e)}></input>
            <datalist id="coldboxList">
                {
                    selectedGroup==null||selectedGroup==undefined||selectedGroup.location==null?
                    coldboxList.map((coldboxitem) => {
                        return <option value={coldboxitem}></option>
                    })
                    :
                    GenGroupResult(selectedGroup)
                    // selectedGroup.location[0].map(item => {
                    //     return <option value={item.locName}></option>
                    // })
                }
            </datalist>
            <div className="ColdboxSetPt_headTitle">Group</div>
            {/* <input className="ColdboxSetPt_headInput" type="search" list="coldboxList" placeholder="Group" ref={groupInput}></input> */}
            <select className="ColdboxSetPt_headselect"  onChange={SearchGroup}>
                <option>All Group</option>
                {(GroupList == null ?
                    <option value="all">Group List not found</option>
                    :
                    SortGroupItems(GroupList.groups).map((item, index) => {
                        return <option value={index}>{item.group_name}</option>
                    })
                )
                }
            </select>
            <div className="ColdboxSetPt_headreload" onClick={Search}>
                <div className="fa fa-refresh"></div>
            </div>
        </div>
    </div>
}

export default SetpointHead

function GenGroupResult(selectedGroup) {
    const locList = selectedGroup.location
    
    if(locList == null || locList.length == null || locList.length == 0) return <option value=""></option>
    let boxList = []
    locList.forEach(locItem => {
        locItem.forEach(innerLocItem => {
            boxList.push(innerLocItem.locName)
        })
    })

    return boxList.map((item, index) => {
        return <option value={item}>{item}</option>
    })
}

function SortGroupItems(gpList) {
    if(gpList==null || gpList.length==null) return []
    const returnGpList = gpList.sort(function (a, b) {
        if (a.group_name > b.group_name) return 1
        if (b.group_name > a.group_name) return -1
        return 0
    })

    return returnGpList
}