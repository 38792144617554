import React from 'react'
import { connect } from 'react-redux'
import BottomDisplayCards from './BottomDisplayCards'
import SoundPlay from '../../../../Sound/Sound'
import {GetSensorTypeName} from '../../../common/language/locDisplay'

import ScrollToCardViewWIdget from './BottomDisplay.ScrollToView'
import { isConvertWaterLeakageToWaterPump, isConvertToWaterLeakageAndPump } from '../MenuListItem'

class BottomDisplay extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            deviceList: []
        }
        
        this.PickedCard = this.PickedCard.bind(this)                    //set up the function (this)
        this.initPickedMenuItem = this.initPickedMenuItem.bind(this)    //reset the list when changed site / level
        this.updateDeviceList = this.updateDeviceList.bind(this)        //update deviceList local state
        this.scrollItem = this.scrollItem.bind(this)
        
        this.soundRef = React.createRef()
        this.scrollRef = React.createRef()
    }

    PickedCard(cardNumber) {
        // this.soundRef.current.alarmMp3Run("hover")
        //trigger the floorplan.js to reload the card data
        this.props.dispatch({type: 'bottomcardhover', data: cardNumber})
    }
    initPickedMenuItem() {
        const {deviceList, currentBottomMenu} = this.props
        const number_str = currentBottomMenu.replace('bottom', '')
        const menunumber = parseInt(number_str)
        if(deviceList[menunumber] != null) return     //have picked menu item in this Floor

        this.props.dispatch({type: 'FloorPlanBottomMenu', data: 'bottom0'})   //if not in this floor, initial it to the first DeviceList
    }
    updateDeviceList() {
        const {UserInfo, currentlevel, currentSite} = this.props
        let deviceList = []
        try {
            let deviceList_try = []
            UserInfo.locations[currentSite].nestedLocs.forEach(floorItem => {
                if(currentlevel == floorItem.locName) {
                    floorItem.nestedLocs.forEach(LocItem => {
                        let newItem = false
                        deviceList_try.forEach(element => {
                            if(element.cardTitle == LocItem.payload_definition_id.cardTitle) newItem = true
                        })
                        if(!newItem) {    //new cardTitle
                            try {
                                deviceList_try.push(LocItem.payload_definition_id)
                            }
                            catch(err){
                                console.log(err)
                            }
                        }
                    })
                    deviceList = deviceList_try
                }
            })
        }
        catch(err) {
            console.error(err)
        }

        this.setState({
            deviceList: deviceList
        })
    }
    componentDidUpdate(preProps) {
        const {currentlevel, currentSite} = this.props
        const oldCurrentSite = preProps.currentSite
        const oldCurrentLevel = preProps.currentlevel
        if(currentlevel != oldCurrentLevel || currentSite != oldCurrentSite) this.initPickedMenuItem()
        //if changed site or level, update the deviceList
        if(currentlevel != oldCurrentLevel || currentSite != oldCurrentSite) this.updateDeviceList()
    }

    componentDidMount() {
        this.updateDeviceList()     //ready the device List (array of type of reading types)

        // pre-select the location  (highlight it), if redirect from notification page
        const {UserInfo, currentSite, currentlevel} = this.props
        if(UserInfo==null) return
        const locName = localStorage.getItem('notiRedirect_locName')
        let floorData
        let deviceDataArray = []

        try {
            UserInfo.locations[currentSite].nestedLocs.forEach(floorItem => {
                if(floorItem.locName == currentlevel) floorData = floorItem
            })
            if(floorData.nestedLocs == null || floorData.nestedLocs == false) return
            const locItem = floorData.nestedLocs.find(item => item.locName==locName)
            if(locItem==null||locItem==undefined) return
            const sensorNameID = (locItem.payload_definition_id==null||locItem.payload_definition_id.nameID==null)?'':locItem.payload_definition_id.nameID
            const locList = floorData.nestedLocs.filter(item => {
                if(item.payload_definition_id==null) return false
                return item.payload_definition_id.nameID == sensorNameID
            })
            deviceDataArray = locList.map(item => item.locName)
        }
        catch(err) {
            console.error(err)
        }

        const DeviceIndex = deviceDataArray.indexOf(locName)
        if(DeviceIndex==-1) return
        
        this.props.dispatch({type: 'bottomcardhover', data: 'bottomcard_'+DeviceIndex.toString()})

        setTimeout(function() {
            localStorage.setItem('notiRedirect_locName', '')        //clear localstorage
        }, 1000)
    }
    scrollItem(scrollValue) {
        this.scrollRef.current.scrollLeft += scrollValue
    }

    render() {
        const { currentBottomMenu, title, UserInfo, currentlevel } = this.props
        const {deviceList} = this.state
        const _this = this

        let cardType = 'No Data in this floor'
        let nameID = ''
        let typeTitle = 'No Data in this floor'
        let displayTitle = 'undefined display title'
        //set Title
        try {
            const number_str = currentBottomMenu.replace('bottom', '')
            const menunumber = parseInt(number_str)
            if(deviceList[menunumber] == null || deviceList[menunumber] == undefined) {
                console.log('deviceList[menunumber] == null BottomDisplay.js render()')
                if(isSwimmingPool(menunumber)) {
                    //swimming, virtual header
                    const swimmingData = getSwimmingData(menunumber)
                    cardType = swimmingData.cardType
                    nameID = swimmingData.nameID
                    displayTitle = swimmingData.displayTitle
                }
            }
            else {
                cardType = deviceList[menunumber].name
                nameID = deviceList[menunumber].nameID
                typeTitle = deviceList[menunumber].cardTitle
                displayTitle = GetSensorTypeName(deviceList[menunumber])
            }
        }
        catch(err) {
            console.error('BottomDisplay.js set title failed')
            console.error(err)
        }

        let cardTitleIcon = TypeToIconClassName[cardType]==null?'wrench fa-rotate-270':TypeToIconClassName[cardType]

        //set Title Icon
        try {
            if(nameID === 'LED Tube' || cardType === 'LED Tube') cardTitleIcon = 'minus'
            if(nameID === 'Disabled Parking') cardTitleIcon = 'wheelchair'
            if(nameID === 'Car Park') cardTitleIcon = 'car'
        }
        catch(err) {
            console.error('BottomDisplay.js set Icon Failed')
            console.error(err)
        }

        //Get the serailnumbers from UserInfo
        let {currentSite} = this.props
        let floorData
        let deviceDataArray = []
        try {
            UserInfo.locations[currentSite].nestedLocs.forEach(floorItem => {
                if(floorItem.locName == currentlevel) floorData = floorItem
            })
            if(floorData.nestedLocs == null || floorData.nestedLocs == false) console.log('floorData.nestedLocs == null BottomDisplay.js UserInfo>site does not have any floor data')
            else {
                floorData.nestedLocs.forEach(locItem => {
                    if(locItem.payload_definition_id.cardTitle == typeTitle) deviceDataArray.push(locItem) //gen the list with cardCount, SerialNumber
                })
            }
        }
        catch(err) {
            console.error('BottomDsiplay.js gen card list failed')
            console.error(err)
        }
        
        const number_str = currentBottomMenu.replace('bottom', '')
        const menunumber = parseInt(number_str)
        if(isSwimmingPool(menunumber)) { //swimming: virtual loc list
            deviceDataArray = swimmingPoolVirtualLocs(menunumber)
        }
        if(isConvertWaterLeakageToWaterPump({..._this.props,nameID})) {
            displayTitle = 'Water Pump'
        }
        if(isConvertToWaterLeakageAndPump({..._this.props,nameID})) {
            displayTitle = 'Water Pump'
            cardTitleIcon = 'tint'
            deviceDataArray = this.props.DeviceData.find(item => item._id == '62b94ac6854329b83855b397')
                .nestedLocs.find(item => item._id == '62b94ac6854329b83855b398')
                    .nestedLocs.filter(item => item.locName.includes('TCP-WP'))
            cardType = 'Water Pump'
        }
        if(nameID === 'Water Leakage' && this.props.currentlevelID==='62b94ac6854329b83855b398') {
            deviceDataArray = deviceDataArray.filter(item => item.locName.includes('WLS'))
        }

        return <div>
            <div className="text-left flex flex-row bottom_head">
                <i className={"fa fa-" + cardTitleIcon + " cardTitleIcon"}></i>
                <div className="cardTitleText">
                    {displayTitle}
                </div>
                <ScrollToCardViewWIdget deviceDataArray={deviceDataArray} currentBottomCard={_this.props.currentBottomCard} />
                {/* <div className="fa fa-arrow-left scrollBtn" onClick={() => this.scrollItem(-1000)}></div>
                <div className="fa fa-arrow-right scrollBtn" onClick={() => this.scrollItem(1000)}></div> */}
            </div>
            <div className="bottom_inside" ref={this.scrollRef}>
                <div className="bottomcards_scrollbody">
                {
                    deviceDataArray.map((deviceListItem, i) => {    //for loop to create relative cards components
                        return <BottomDisplayCards cardnumber={i} PickedCard={this.PickedCard} LocationName={deviceListItem.locName} 
                        cardType={cardType} nameID={nameID} deviceListItem={deviceListItem} />
                    })
                }
                </div>
            </div>
            <SoundPlay ref={this.soundRef}/>
        </div>
    }
}

const mapStateToProps = state => {
    return {
      count: state.count,
      log: state.log,
      level: state.level,
      currentlevel: state.currentlevel,
      currentlevelID: state.currentlevelID,
      currentSite: state.currentSite,
      currentBottomMenu: state.currentBottomMenu,
      UserInfo: state.UserInfo,
      DeviceData: state.DeviceData,
      currentBottomCard: state.currentBottomCard
    }
}
export default connect(mapStateToProps)(BottomDisplay)

const TypeToIconClassName = {
    'Temperature & Humidity': 'thermometer-half',
    'Car Park': 'car',
    'Disabled Parking': 'wheelchair',
    'IAQ': 'cloud',
    'IEQ': 'cloud',
    'LUX': 'lightbulb-o',
    'AC Power': 'bolt',
    'Rubbish Bin': 'trash',
    'Waste Bin': 'trash',
    'Toilet Occupancy': 'user',
    'Fall Detection': 'user',
    'People Count': 'user',
    'Water Leakage (Point)': 'tint',
    'Water Leakage': 'tint',
    'Door Contact': 'opencart',
    'Door': 'opencart',
    'Vibration': 'arrows',
    'Push Button': 'bell',
    'LED Tube': 'minus',
    'Soap Level': 'database',
    'Water Level': 'tint',
    'Toilet Paper': 'sticky-note',
    'Water Quality': 'tint',
    'Outdoor Environment Quality': 'cloud',
    'Outdoor Environment Quality (Wind)': 'cloud',
    'Soap Level': 'flask',
    'Soap': 'flask',
    'PMC': 'flash',
    'CCTV': 'eye',
    'Thermocouple': 'tachometer',
    'Reed Switch Detection': 'magnet',
    
    //new item for swimming pool (kwai shing)
    'Water Quality Sensor': 'tint',
    'Plant Status': 'flash',
    'Swimming_Automatic Control Mode': 'bars',
    'Swimming_Flow Meter': 'tint',
    'Swimming_Carbon Detection Sensor': 'cloud',
    'Swimming_Pump Statistic': 'line-chart',
    'Swimming_Pump Consumption': 'bolt',
    'Swimming_Pump Status': 'flag',
    'IAQ15in1': 'thermometer-half',
    'IAQ16in1': 'thermometer-half',

    'Netvox_ADC': 'flash',              //HSF site new device
}

const isSwimmingPool = menuNumber => {
    if(menuNumber >= 100) return true
    return false
}
const getSwimmingData = menunumber => {
    switch(menunumber) {
        case 100:
            return {
                cardType: 'Swimming_Water Quality Sensor',
                nameID: 'Swimming_Water Quality Sensor',
                displayTitle: 'Water Quality Sensor'
            }
        case 101:
            return {
                cardType: 'Swimming_Plant Status',
                nameID: 'Swimming_Plant Status',
                displayTitle: 'Plant Status'
            }
        case 102:
            return {
                cardType: 'Swimming_Automatic Control Mode',
                nameID: 'Swimming_Automatic Control Mode',
                displayTitle: 'Automatic Control Mode'
            }
        case 103:
            return {
                cardType: 'Swimming_Flow Meter',
                nameID: 'Swimming_Flow Meter',
                displayTitle: 'Flow Meter'
            }
        case 104:
            return {
                cardType: 'Swimming_Carbon Detection Sensor',
                nameID: 'Swimming_Carbon Detection Sensor',
                displayTitle: 'Carbon Detection Sensor'
            }
        case 105:
            return {
                cardType: 'Swimming_Pump Statistic',
                nameID: 'Swimming_Pump Statistic',
                displayTitle: 'Pump Statistic'
            }
        case 106:
            return {
                cardType: 'Swimming_Pump Consumption',
                nameID: 'Swimming_Pump Consumption',
                displayTitle: 'Pump Consumption'
            }
        case 107:
            return {
                cardType: 'Swimming_Pump Status',
                nameID: 'Swimming_Pump Status',
                displayTitle: 'Pump Status'
            }
    }
}
const swimmingPoolVirtualLocs = menunumber => {
     switch(menunumber) {
        case 100:
            return [
                {
                    locName: 'Main-Pool-Water-Quality',
                    locUI: {desc: ''},
                    _id: 'water q 1',
                    isSwimmingPool: true
                },
                {
                    locName: '2nd-Pool-Water-Quality',
                    locUI: {desc: ''},
                    _id: 'water q 2',
                    isSwimmingPool: true
                },
                {
                    locName: 'Diving-Pool-Water-Quality',
                    locUI: {desc: ''},
                    _id: 'water q 3',
                    isSwimmingPool: true
                }
            ]
        case 101:
            return [{
                locName: 'Plant Status',
                locUI: {desc: ''},
                _id: 'plant status 1',
                isSwimmingPool: true
            }]
        case 102:
            return [{
                locName: 'Automatic Control Mode',
                locUI: {desc: ''},
                _id: 'Automatic Control Mode 1',
                isSwimmingPool: true
            }]
        case 103:
            return [{
                locName: 'Flow Meter',
                locUI: {desc: ''},
                _id: 'Flow Meter 1',
                isSwimmingPool: true
            }]
        case 104:
            return [
                {
                    locName: 'Carbon-Filter-001',
                    locUI: {desc: ''},
                    _id: 'Carbon detect 1',
                    isSwimmingPool: true
                },
                {
                    locName: 'Carbon-Filter-002',
                    locUI: {desc: ''},
                    _id: 'Carbon detect 2',
                    isSwimmingPool: true
                },
                {
                    locName: 'Carbon-Filter-003',
                    locUI: {desc: ''},
                    _id: 'Carbon detect 3',
                    isSwimmingPool: true
                },
                {
                    locName: 'Carbon-Filter-004',
                    locUI: {desc: ''},
                    _id: 'Carbon detect 4',
                    isSwimmingPool: true
                }
            ]
        case 105:
            return [
                {
                    locName: 'Pump-Statistic-001',
                    locUI: {desc: ''},
                    _id: 'Pump statistic 1',
                    isSwimmingPool: true
                },
                {
                    locName: 'Pump-Statistic-002',
                    locUI: {desc: ''},
                    _id: 'Pump statistic 2',
                    isSwimmingPool: true
                }
            ]
        case 106:
            return [
                {
                    locName: 'Pump-Consumption-001',
                    locUI: {desc: ''},
                    _id: 'Pump Consumption 1',
                    isSwimmingPool: true
                },
                {
                    locName: 'Pump-Consumption-002',
                    locUI: {desc: ''},
                    _id: 'Pump Consumption 2',
                    isSwimmingPool: true
                }
            ]
        case 107:
            return [
                {
                    locName: 'Pump-Status-001',
                    locUI: {desc: ''},
                    _id: 'Pump Status 1',
                    isSwimmingPool: true
                },
                {
                    locName: 'Pump-Status-002',
                    locUI: {desc: ''},
                    _id: 'Pump Status 2',
                    isSwimmingPool: true
                }
            ]
        // case 108: //..
        //     return [
        //         {
        //             locName: 'iaq 15 in 1 item 1',
        //             locUI: {desc: ''},
        //             _id: 'iaq 15 in 1 item 1',
        //             isSwimmingPool: true
        //         },
        //         {
        //             locName: 'iaq 15 in 1 item 2',
        //             locUI: {desc: ''},
        //             _id: 'iaq 15 in 1 item 2',
        //             isSwimmingPool: true
        //         }
        //     ]
        // case 109: //..
        //     return [
        //         {
        //             locName: 'iaq 16 in 1 item 1',
        //             locUI: {desc: ''},
        //             _id: 'iaq 16 in 1 item 1',
        //             isSwimmingPool: true
        //         },
        //         {
        //             locName: 'iaq 16 in 1 item 2',
        //             locUI: {desc: ''},
        //             _id: 'iaq 16 in 1 item 2',
        //             isSwimmingPool: true
        //         }
        //     ]
    }
}