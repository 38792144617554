import React, { useState, useEffect } from 'react'
import './SingleDatePicker.css'

import DatePicker from 'react-datepicker'

function SingleDatePickerWidget(props) {
    const [inputDate, setDate] = useState(new Date())
    const {DefaultDate, ChangeDate, ID} = props

    useEffect(() => {
        //onload function
        let d = new Date()
        let SetDateValue
        switch (DefaultDate) {
            case 'Yesterday':
                SetDateValue = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 1)
                break
            case 'Today':
                SetDateValue = new Date(d.getFullYear(), d.getMonth(), d.getDate())
                break
        }
        SetDateValue.setHours(16)
        SetDateValue.setMinutes(0)
        setDate(SetDateValue)
    }, [])

    useEffect(() => {
        //send the updated Date to parent
        if(inputDate == null) return
        let outputDate = inputDate
        outputDate.setHours(16)
        outputDate.setMinutes(0)
        ChangeDate(ID, outputDate)
    }, [inputDate])
    
    return <div className="SingleDatePickerClass">
        <DatePicker selected={inputDate} className="SingleDateLiftSummary_input" dateFormat="dd MMM yyyy" onChange={(date) => setDate(date)} />
    </div>
    
}

export default SingleDatePickerWidget