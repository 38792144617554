import React from 'react'
import '../bottom/ThreePartSearchBar.css'
import { connect } from 'react-redux'

class Searchbar extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            deviceID: '',
            //option list
            typeList: ['All', 'Dynamic', 'Periodic', 'Manual'],
            filterType: '',
            filterTxt: '',
            //Filter Result
            Filtered: null,
        }

        this.GenOptions = this.GenOptions.bind(this)
        this.FilterOnchange = this.FilterOnchange.bind(this)
        this.Filter = this.Filter.bind(this)
        this.setStartTime = this.setStartTime.bind(this)
        this.setEndTime = this.setEndTime.bind(this)
    }
    componentDidMount() {
        var { sortedList } = this.props
        if (sortedList == null || sortedList == undefined) return
        this.GenOptions()
    }
    componentDidUpdate(preProps) {
        //Re-gen option list when data is updated
        var { sortedList } = this.props
        var oldsortedList = preProps.sortedList
        if (sortedList != oldsortedList || (sortedList != null && oldsortedList == null)) this.GenOptions()
    }
    GenOptions() {
        const { sortedList } = this.props
        if (sortedList === null || sortedList === undefined) {
            return
        } else {
            var list = sortedList.filter(function (item) {
                return item.DeviceType != null && item.DeviceType != undefined && item.DeviceType != ""
            }).map(function (item, index) {
                return item.DeviceType
            })
            list = Array.from(new Set(list)).sort()
            this.setState({ typeList: list })
            this.Filter()
        }
    }
    FilterOnchange(keyStr, e) {
        try {
            const value = e.target.value.toString()
            this.setState({
                [keyStr]: value,
            }, function () {        //run right after state is set
                this.Filter()       //re-filter
            })
        }
        catch (err) {
            console.log(err)
        }
    }

    Filter() {
        const { deviceID, filterType, filterTxt } = this.state
        const { sortedList } = this.props

        var FilteredList = sortedList
        if (deviceID != null && deviceID !== "") {
            FilteredList = FilteredList.filter(function (item) {
                return item.DeviceID.includes(deviceID.toString())
            })
        }
        if (filterType != null && filterType !== "") {
            FilteredList = FilteredList.filter(function (item) {
                return item.DeviceType === filterType.toString()
            })
        }
        if (filterTxt != null && filterTxt !== "") {
            FilteredList = FilteredList.filter(function (coldboxItem) {
                return (coldboxItem.PMFrequency != null && coldboxItem.PMFrequency.includes(filterTxt)) ||
                    (coldboxItem.Works != null && coldboxItem.Works.includes(filterTxt)) ||
                    (coldboxItem.LastMaintDate != null && coldboxItem.LastMaintDate.includes(filterTxt)) ||
                    (coldboxItem.LastMaintType != null && coldboxItem.LastMaintType.includes(filterTxt)) ||
                    (coldboxItem.NextMaintDate != null && coldboxItem.NextMaintDate.includes(filterTxt)) ||
                    (coldboxItem.NextMaintType != null && coldboxItem.NextMaintType.includes(filterTxt)) ||
                    (coldboxItem.Vendor != null && coldboxItem.Vendor.includes(filterTxt)) ||
                    (coldboxItem.ServiceImpact != null && coldboxItem.ServiceImpact.includes(filterTxt)) ||
                    (coldboxItem.Duration != null && coldboxItem.Duration.includes(filterTxt))
            })
        }
        this.setState({
            Filtered: FilteredList
        })
        this.props.UpdateList("filterList", FilteredList)
    }

    setStartTime(data) {
        this.setState({ startdate: data })
        this.props.setStartTime(data)
    }

    setEndTime(data) {
        this.setState({ enddate: data })
        this.props.setEndTime(data)
    }

    render() {
        const _this = this

        const { typeList } = this.state

        return <div className='ThreePartSearch_Main'>
            <div className='ThreePartSearch_Block'>
                <div className='ThreePartSearch_left'>
                    <div className="ThreePartSearch_txt">Device Type:</div>
                    <select className="ThreePartSearch_select" onChange={(e) => _this.FilterOnchange('filterType', e)}>
                        <option value="">All</option>
                        {
                            (typeList == null || typeList.length == null || typeList.length == 0) ?
                                <option style={{ display: 'none' }}></option>
                                :
                                typeList.map(function (item) {
                                    return <option value={item} >{item}</option>
                                })
                        }
                    </select>
                    <div className="ThreePartSearch_adSearchBtn" onClick={_this.props.refresh}>
                        <div className="fa fa-refresh" />
                    </div>
                </div>
            </div>
            <div className='ThreePartSearch_Block'>
                <div className='ThreePartSearch_right'>
                    <div className='ThreePartSearch_keyword_title'>Keyword search:</div>
                    <input className="ThreePartSearch_keyword_input" placeholder="Keyword search" onChange={(e) => _this.FilterOnchange('filterTxt', e)}></input>
                </div>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {

        UserInfo: state.UserInfo,
        currentSite: state.currentSite,
    }
}
export default connect(mapStateToProps)(Searchbar)
