import React from 'react';
import { connect } from 'react-redux';
import './ReportIssue.css';

class ReportIssue extends React.Component {
    constructor(props)
    {
        super(props);

        this.BackRedirect = this.BackRedirect.bind(this);
        this.submitIssue = this.submitIssue.bind(this);
        this.ClosePopup = this.ClosePopup.bind(this);

        this.textarea = React.createRef();
        this.EmailInput = React.createRef();
        this.ContactInput = React.createRef();

        this.state = {
            isShowAlert: false,
            msg: '',
            IsLoading: false
        };
    }

    BackRedirect()
    {
        this.props.history.goBack();
    }

    ClosePopup()
    {
        this.setState({
            isShowAlert:  false,
            IsLoading: false,
            msg: ''
        });
    }

    submitIssue()
    {
        let thisoutside = this;
        this.setState({
            isShowAlert: true,
            msg: 'Submit start'
        });
        //check socket in redux
        if(thisoutside.props.socket == null)
        {
            thisoutside.setState({
                isShowAlert: true,
                msg: 'Socket server is not connected, please refresh the page and re-try'
            });
            return;
        }
        //check socket is connected
        if(!thisoutside.props.socket.connected)
            {thisoutside.setState({
                isShowAlert: true,
                msg: 'Socket server is disconnected, please refresh the page to re-connect'
            });
            return;
        }
        //get the inputs
        let inputdata = {
            textarea: thisoutside.textarea.current.value,
            EmailInput: thisoutside.EmailInput.current.value,
            ContactInput: thisoutside.ContactInput.current.value
        };
        //console.log(inputdata);
        //emit socket to server
        //thisoutside.props.socket.emit("ReportIssue", {
        //    data: inputdata
        //}); 
        thisoutside.props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "ReportIssue",
            EmitSocketData: inputdata
        });
        this.setState({
            IsLoading: true
        }); 
        setTimeout(function(){
            if(!thisoutside.state.IsLoading)
            {
                //returned success or failed from server, not timeout condition
                return;
            }
            thisoutside.setState({
                msg: "Submit failed! Please re-try later",
                isShowAlert: true
            });    
        }, 4000); 
    }

    componentDidMount()
    {
        //save current url to redux
		let pathUrl = "/Main/ReportIssue";
        this.props.dispatch({ type: 'LatestPage', data: pathUrl });
        
        let thisoutside = this;
        //check socket in redux
        if(thisoutside.props.socket == null)
        {
            thisoutside.setState({
                isShowAlert: true,
                msg: 'Socket server is not connected, please refresh the page and re-try',
                IsLoading: false
            });
            return;
        }
        //check socket is connected
        if(!thisoutside.props.socket.connected)
        {
            thisoutside.setState({
                isShowAlert: true,
                msg: 'Socket server is disconnected, please refresh the page to re-connect',
                IsLoading: false
            });
            return;
        }

        //remove existing listeners
        //thisoutside.props.socket.removeAllListeners();
        //create listeners
        thisoutside.props.socket.on("ReportSuccess", data => {
            thisoutside.setState({
                isShowAlert: true,
                msg: 'Issue is submitted.',
                IsLoading: false
            });
        });
    }

    componentDidCatch(error, info)
    {
        console.error('componentDidCatch() on ReportIssue.js');
        console.error(error);
        console.error(info);
        //redirect to App.js to reload all the datas
        this.props.history.push("/App");
    }

    render()
    {
        let hidePopup = 'showAlert';
        if(!this.state.isShowAlert)
        {
            hidePopup = "hideAlert";
        }
        return [
        <div className={"ReportIssue_alertPopup " + hidePopup}>
            <div className="fa fa-times ReportIssue_closeBtn" onClick={this.ClosePopup}></div>
            <div className="fa fa-warning ReportIssue_warnIcon"></div>
            <div className="ReportIssue_alertMsg">
                {this.state.msg}
            </div>
        </div>,
        <div className={"ReportIssue_alertPopBg " + hidePopup}  onClick={this.ClosePopup}></div>,
        <div className="fa fa-arrow-left ReportIssue_backBtn" onClick={this.BackRedirect}></div>,
        <div className="shadow-lg ReportIssue_maindiv">
            {
                //<img className="innerBackgroundImg" src="http://47.75.91.98/Demo/assets/images/report.jpg" alt="image not found"></img>
            }
            <div className="ReportIssue_EmailOutside">
                <div className="fa fa-user"></div>
                <input placeholder="Email" type="text" className="ReportIssue_EmailInput" ref={this.EmailInput} autoComplete="off" autoFocus></input>
            </div>
            <div className="ReportIssue_ContactOutside">
                <div className="fa fa-phone"></div>
                <input placeholder="Contact Number / Fax" type="text" className="ReportIssue_ContactInput" ref={this.ContactInput} autoComplete="off"></input>
            </div>
            <textarea rows="8" cols="80" ref={this.textarea} placeholder="Enter your question / Issue" 
            className="ReportIssue_textarea"></textarea>
            <div className="ReportIssue_sbumitBtn" onClick={this.submitIssue}>
                <div>
                    Report the Issue
                </div>
            </div>
        </div>
        ]
    }
}

//Redux inside component function
function mapStateToProps(state) {
    return {
      socket: state.socket,
      level: state.level,
      currentlevel: state.currentlevel,
      SiteList: state.SiteList,
      currentSite: state.currentSite,
      MenuDisplay: state.MenuDisplay
    };
}
export default connect(mapStateToProps)(ReportIssue);