import React from 'react'
import Loading from '../../../loading'
import { connect } from 'react-redux'

class Alarm extends React.Component {
    constructor(props) {
        super()
        this.renderRow = this.renderRow.bind(this)
        this.groupRow = this.groupRow.bind(this)
        this.state = {
            isLoading: false,
            alarmData: {}
        }
    }

    componentDidUpdate(prevProps) {
        const preDeviceDataLastUpdate = prevProps.DeviceDataLastUpdate
        const {DeviceDataLastUpdate} = this.props
        if(preDeviceDataLastUpdate==DeviceDataLastUpdate&&prevProps.Item==this.props.Item) return

        const {Item} = this.props
        if(Item==null||Item.payload==null) return

        this.setState({
            isLoading: false,
            alarmData: Item.payload
        })
    }

    renderRow(title, value, unit, faultDisplay) {
        return (
            <div className="row">
                <div className="readingTitle">
                    {title}
                </div>
                <div className="reading" style={{color: faultDisplay}}>
                    {value} {(unit === "C" ? <sup>o</sup> : unit)}{(unit === "C" ? unit : "")}
                </div>
            </div>
        )
    }
    groupRow(title, data, unit, trueDisplay, falseDisplay, isRedWhenTrue) {
        let valueDisplay = '--'
        if(data == 1) valueDisplay = trueDisplay
        if(data == 2 || data == 0 || data == undefined || data == null) valueDisplay = falseDisplay
        if(data == 3) valueDisplay = trueDisplay + ' & ' + falseDisplay

        let isRed = false
        if(isRedWhenTrue&&(data==1||data==3)) isRed = true
        if(isRedWhenTrue==false&&(data==2||data==3)) isRed = true

        return <div className="row">
            <div className="readingTitle">
                {title}
            </div>
            <div className="reading" style={{color: isRed?'red':''}}>
                {valueDisplay} {(unit==="C"?<sub>o</sub>:unit)}{(unit==="C"?unit:"")}
            </div>
        </div>
    }

    render() {
        const _this = this
        const {groupDisplay, isGpDisplay} = this.props
        const {alarmData} = this.state

        if(this.state.isLoading) {
            return <Loading/>
        }
        if(isGpDisplay) {
            return <fieldset>
                <legend>Alarm</legend>  
                <div className="Coldbox_S_table">
                    <div className="col_alarm_set_point">
                        {_this.groupRow("General Alarm", groupDisplay.GeneralAlarm, "", "Fault", "Normal", true)}
                        {_this.groupRow("Alarm (HMI)", groupDisplay.HMIAlarm, "", "Fault", "Normal", true)}
                        {_this.groupRow("High Pressure Cutout", groupDisplay.HighPressureCutout, "", "Fault", "Normal", true)}
                        {_this.groupRow("Low Pressure Cutout", groupDisplay.LowPressureCutout, "", "Fault", "Normal", true)}
                        {_this.groupRow("Fan Motor Fault", groupDisplay.CompressorMotorFault, "", "Fault", "Normal", true)}
                        {_this.groupRow("Compressor Motor Fault", groupDisplay.FanMotorFault, "", "Fault", "Normal", true)}
                        {_this.groupRow("Compressor Not Run Alarm Upon Command issued", groupDisplay.CompressorNotRunFault, "", "Fault", "Normal", true)}
                        {_this.groupRow("Evaporstor Fan Not Run Alarm Upon Command issued", groupDisplay.EvaporatorFanNotRunFault, "", "Fault", "Normal", true)}
                        {_this.groupRow("High Temperature Alarm", groupDisplay.ColdboxHighTemperatureAlarm, "", "Fault", "Normal", true)}
                        {_this.groupRow("Low Temperature Alarm", groupDisplay.ColdboxLowTemperatureAlarm, "", "Fault", "Normal", true)}
                        {_this.groupRow("Temperature Sensor 1 Fault", groupDisplay.TempSensor1Fault, "", "Fault", "Normal", true)}
                        {_this.groupRow("Temperature Sensor 2 Fault", groupDisplay.TempSensor2Fault, "", "Fault", "Normal", true)}
                        {_this.groupRow("Temperature Sensor 3 Fault", groupDisplay.TempSensor3Fault, "", "Fault", "Normal", true)}
                        {_this.groupRow("Temperature Sensor 4 Fault", groupDisplay.TempSensor4Fault, "", "Fault", "Normal", true)}
                        {_this.groupRow("Temperature Sensor 5 Fault", groupDisplay.TempSensor5Fault, "", "Fault", "Normal", true)}
                        {_this.groupRow("RH Sensor Fault (over-range)", groupDisplay.RHSensorFault, "", "Fault", "Normal", true)}
                        {_this.groupRow("Condensing Pressure Sensor Fault (over-range)", groupDisplay.CondensingPressureSensorFault, "", "Fault", "Normal", true)}
                        {_this.groupRow("Evaporating Pressure Sensor Fault (over-range)", groupDisplay.EvaporatingPressureSensorFault, "", "Fault", "Normal", true)}
                        {_this.groupRow("Defrost Heater Fault (not run upon command issued)", groupDisplay.DefrostHeaterFault, "", "Fault", "Normal", true)}
                        {_this.groupRow("Expansion Valve Controller Fault", groupDisplay.ExpansionValveControllerFault, "", "Fault", "Normal", true)}
                    </div>
                </div>
            </fieldset>
        }
        return <fieldset >
            <legend>Alarm</legend>  
            <div className="Coldbox_S_table">
                    <div className="col_alarm_set_point">
                        {this.renderRow("General Alarm", alarmData.GeneralAlarm === false ? "Normal" : "Fault", "", IfIsFault(alarmData.GeneralAlarm, 'GeneralAlarm'))}
                        {this.renderRow("Alarm (HMI)", alarmData.HMIAlarm === false ? "Normal" : "Fault", "", IfIsFault(alarmData.HMIAlarm, 'HMIAlarm'))}
                        {this.renderRow("High Pressure Cutout", alarmData.HighPressureCutout === false ? "Normal" : "Fault", "", IfIsFault(alarmData.HighPressureCutout, 'HighPressureCutout'))}
                        {this.renderRow("Low Pressure Cutout", alarmData.LowPressureCutout=== false ? "Normal" : "Fault", "", IfIsFault(alarmData.LowPressureCutout, 'LowPressureCutout'))}
                        {this.renderRow("Fan Motor Fault", alarmData.CompressorMotorFault=== false ? "Normal" : "Fault", "", IfIsFault(alarmData.CompressorMotorFault, 'CompressorMotorFault'))}
                        {this.renderRow("Compressor Motor Fault", alarmData.FanMotorFault === false ? "Normal" : "Fault", "", IfIsFault(alarmData.FanMotorFault, 'FanMotorFault'))}
                        {this.renderRow("Compressor Not Run Alarm Upon Command issued", alarmData.CompressorNotRunFault === false ? "Normal" : "Fault", "", IfIsFault(alarmData.CompressorNotRunFault, 'CompressorNotRunFault'))}
                        {this.renderRow("Evaporstor Fan Not Run Alarm Upon Command issued", alarmData.EvaporatorFanNotRunFault === false ? "Normal" : "Fault", "", IfIsFault(alarmData.EvaporatorFanNotRunFault, 'EvaporatorFanNotRunFault'))}
                        {this.renderRow("High Temperature Alarm", alarmData.ColdboxHighTemperatureAlarm === false ? "Normal" : "Fault", "", IfIsFault(alarmData.ColdboxHighTemperatureAlarm, 'ColdboxHighTemperatureAlarm'))}
                        {this.renderRow("Low Temperature Alarm", alarmData.ColdboxLowTemperatureAlarm === false ? "Normal" : "Fault", "", IfIsFault(alarmData.ColdboxLowTemperatureAlarm, 'ColdboxLowTemperatureAlarm'))}
                        {this.renderRow("Temperature Sensor 1 Fault", alarmData.TempSensor1Fault === false ? "Normal" : "Fault", "", IfIsFault(alarmData.TempSensor1Fault, 'TempSensor1Fault'))}
                        {this.renderRow("Temperature Sensor 2 Fault", alarmData.TempSensor2Fault === false ? "Normal" : "Fault", "", IfIsFault(alarmData.TempSensor2Fault, 'TempSensor2Fault'))}
                        {this.renderRow("Temperature Sensor 3 Fault", alarmData.TempSensor3Fault === false ? "Normal" : "Fault", "", IfIsFault(alarmData.TempSensor3Fault, 'TempSensor3Fault'))}
                        {this.renderRow("Temperature Sensor 4 Fault", alarmData.TempSensor4Fault === false ? "Normal" : "Fault", "", IfIsFault(alarmData.TempSensor4Fault, 'TempSensor4Fault'))}
                        {this.renderRow("Temperature Sensor 5 Fault", alarmData.TempSensor5Fault === false ? "Normal" : "Fault", "", IfIsFault(alarmData.TempSensor5Fault, 'TempSensor5Fault'))}
                        {this.renderRow("RH Sensor Fault (over-range)", alarmData.RHSensorFault === false ? "Normal" : "Fault", "", IfIsFault(alarmData.RHSensorFault, 'RHSensorFault'))}
                        {this.renderRow("Condensing Pressure Sensor Fault (over-range)", alarmData.CondensingPressureSensorFault === false ? "Normal" : "Fault", "", IfIsFault(alarmData.CondensingPressureSensorFault, 'CondensingPressureSensorFault'))}
                        {this.renderRow("Evaporating Pressure Sensor Fault (over-range)", alarmData.EvaporatingPressureSensorFault === false ? "Normal" : "Fault", "", IfIsFault(alarmData.EvaporatingPressureSensorFault, 'EvaporatingPressureSensorFault'))}
                        {this.renderRow("Defrost Heater Fault (not run upon command issued)", alarmData.DefrostHeaterFault === false ? "Normal" : "Fault", "", IfIsFault(alarmData.DefrostHeaterFault, 'DefrostHeaterFault'))}
                        {this.renderRow("Expansion Valve Controller Fault", alarmData.ExpansionValveControllerFault === false ? "Normal" : "Fault", "", IfIsFault(alarmData.ExpansionValveControllerFault, 'ExpansionValveControllerFault'))}
                    </div>
            </div>
        </fieldset>
    }
}

function mapStateToProps(state) {
    return {
        currentlevel: state.currentlevel,
        currentSite: state.currentSite,
        UserInfo: state.UserInfo,
        DeviceData: state.DeviceData,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate,
        DashboardPickedColdboxID: state.DashboardPickedColdboxID
    }
}
  
export default connect(mapStateToProps)(Alarm)

function IfIsFault(value, fieldName) {
    const configItem = fieldConfig.find(item => item.name == fieldName)
    if(configItem==null) {
        // console.log('fieldConfig exception')
        // console.log('fieldName: ' + fieldName)
        return
    }

    const {type, faultV, faultMax, faultMin} = configItem

    if(type=='bool') {
        if(faultV==true&&value==true) return 'red'
        if(faultV==false&&(value==false)) return 'red'
        if(value==undefined||value==null) return 'red'  //exception: no value, highlight it red for attention
    }
    if(type=='string') {
        if(faultV==value) return 'red'
        return ''
    }
    if(type=='number') {
        if(faultMax!=null && value>faultMax) {
            return 'red'
        }
        if(faultMax!=null && value<faultMin) {
            return 'red'
        }
    }

    return ''
}
const fieldConfig = [
    //samples
    {
        name: 'bool sample',
        type: 'bool',   //bool sample
        faultV: true
    },
    {
        name: 'number sample',
        type: 'number', //number sample
        faultMax: 100,
        faultMin: null  //null means no minimum
    },
    {
        name: 'string sample',
        type: 'string', //string sample
        faultV: 'Fault String Value'
    },
    //real usage
    {
        name: 'GeneralAlarm',
        type: 'bool',
        faultV: true
    },
    {
        name: 'HMIAlarm',
        type: 'bool',
        faultV: true
    },
    {
        name: 'HighPressureCutout',
        type: 'bool',
        faultV: true
    },
    {
        name: 'LowPressureCutout',
        type: 'bool',
        faultV: true
    },
    {
        name: 'CompressorMotorFault',
        type: 'bool',
        faultV: true
    },
    {
        name: 'FanMotorFault',
        type: 'bool',
        faultV: true
    },
    {
        name: 'CompressorNotRunFault',
        type: 'bool',
        faultV: true
    },
    {
        name: 'EvaporatorFanNotRunFault',
        type: 'bool',
        faultV: true
    },
    {
        name: 'ColdboxHighTemperatureAlarm',
        type: 'bool',
        faultV: true
    },
    {
        name: 'ColdboxLowTemperatureAlarm',
        type: 'bool',
        faultV: true
    },
    {
        name: 'TempSensor1Fault',
        type: 'bool',
        faultV: true
    },
    {
        name: 'TempSensor2Fault',
        type: 'bool',
        faultV: true
    },
    {
        name: 'TempSensor3Fault',
        type: 'bool',
        faultV: true
    },
    {
        name: 'TempSensor4Fault',
        type: 'bool',
        faultV: true
    },
    {
        name: 'TempSensor5Fault',
        type: 'bool',
        faultV: true
    },
    {
        name: 'RHSensorFault',
        type: 'bool',
        faultV: true
    },
    {
        name: 'CondensingPressureSensorFault',
        type: 'bool',
        faultV: true
    },
    {
        name: 'EvaporatingPressureSensorFault',
        type: 'bool',
        faultV: true
    },
    {
        name: 'DefrostHeaterFault',
        type: 'bool',
        faultV: true
    },
    {
        name: 'ExpansionValveControllerFault',
        type: 'bool',
        faultV: true
    },
]