import React from 'react'
import './PplDetect.css'
import { connect } from 'react-redux'

class PplDetect extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        //FIND DEVICE ITEM
        const {DeviceData, currentSite, currentlevel} = this.props
        if(currentlevel == null || currentSite == null || DeviceData == null) return <div></div>        //exception case
        const FindSite = DeviceData[currentSite]
        if(FindSite == null) return <div></div>             //2nd exceptional case
        const FindLevel = FindSite.nestedLocs.find(function(levelItem) {
            return (levelItem.locName == currentlevel) || (levelItem.locName == currentlevel.toString())
        })
        // console.log(FindLevel)
        const Payload = FindLevel.nestedLocs[0].payload
        // console.log(Payload)

        let isLiftOccupied = false
        FindLevel.nestedLocs.forEach(deviceItem => {
            if(deviceItem.payload != null) {
                const DevicePayload = deviceItem.payload
                if(DevicePayload.liftOccupied != null) {
                    if(DevicePayload.liftOccupied==true || DevicePayload.liftOccupied==1) isLiftOccupied = true
                    else isLiftOccupied = false
                }
            }
        })

        let HavePeopleClass = ""
        if(isLiftOccupied) HavePeopleClass = "LiftGraph_HavePeopleStatus"
        return <div className="Lift_graph_main">
            <div className="Lift_graphTitle">
                People Detect
            </div>
            <div className={"Lift_graphHavePeople " + HavePeopleClass} onClick={this.SetPeople}>
                <div className="fa fa-users"></div>
            </div>
        </div>
        
    }
}

function mapStateToProps(state) {
    return {
      DeviceDataLastUpdate: state.DeviceDataLastUpdate,
      currentlevel: state.currentlevel,
      currentSite: state.currentSite,
      DeviceData: state.DeviceData,
    }
}
export default connect(mapStateToProps)(PplDetect)