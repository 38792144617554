export const GetRelatedList = (DeviceData) => {
    if(DeviceData == null) return []
    let RelatedList_1 = []
    DeviceData.forEach(SiteItem => {
        SiteItem.nestedLocs.forEach(LevelItem => {
            LevelItem.nestedLocs.forEach(locItem => {
                RelatedList_1.push(locItem)
            })
        })
    })
    const RelatedList = RelatedList_1.filter(function(item) {
        return item.payload_definition_id != null && item.payload_definition_id.cardTitle == "Coldbox"
    })
    return RelatedList
}