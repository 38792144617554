import React from 'react'
import '../bottom/ThreePartSearchBar.css'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { Checkbox } from '@material-ui/core'


class Searchbar extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            startdate: new Date(moment().subtract(30, 'days')),
            enddate: new Date(),
            deviceList: [],
            filterDevice: ''
        }

        this.GenOptions = this.GenOptions.bind(this)
        this.FilterOnchange = this.FilterOnchange.bind(this)
        this.Filter = this.Filter.bind(this)
        this.setStartTime = this.setStartTime.bind(this)
        this.setEndTime = this.setEndTime.bind(this)
    }
    componentDidMount() {
        var { sortedList } = this.props
        if (sortedList == null || sortedList == undefined) return
        this.GenOptions()
    }
    componentDidUpdate(preProps) {
        //Re-gen option list when data is updated
        var { sortedList } = this.props
        var oldSortedList = preProps.sortedList
        if (sortedList != oldSortedList || (sortedList != null && oldSortedList == null)) this.GenOptions()
    }
    GenOptions() {
        const { sortedList } = this.props
        if (sortedList === null || sortedList === undefined) {
            return
        } else {
            var Options = sortedList.filter(function (item) {
                return item.DeviceID != null && item.DeviceID !== undefined && item.DeviceID !== ""
            }).map(function (item, index) {
                return item.DeviceID
            })
            Options = Array.from(new Set(Options)).sort()
            this.setState({
                deviceList: Options,
            })
            this.Filter()
        }
    }
    FilterOnchange(e) {
        try {
            const value = e.target.value.toString()
            this.setState({
                filterDevice: value,
            }, function () {        //run right after state is set
                this.Filter()       //re-filter
            })
        }
        catch (err) {
            console.log(err)
        }
    }

    Filter() {
        const { filterDevice } = this.state
        const { sortedList } = this.props

        var FilteredList = sortedList
        if (filterDevice != null && filterDevice !== "" && filterDevice !== "All") {
            FilteredList = FilteredList.filter(function (item) {
                return item.DeviceID === filterDevice
            })
        }
        this.props.UpdateList("FilteredList", FilteredList)
    }

    setStartTime(data) {
        this.setState({ startdate: data })
        this.props.setStartTime(data)
    }

    setEndTime(data) {
        this.setState({ enddate: data })
        this.props.setEndTime(data)
    }

    render() {
        const _this = this
        const { deviceList } = this.state

        return <div className='ThreePartSearch_Main'>
            <div className='ThreePartSearch_Block'>
                <div className="ThreePartSearch_middle">
                    <div className="ThreePartSearch_txt">From:</div>
                    <div className="WOLDatepicker">
                        <DatePicker
                            selected={this.state.startdate}
                            onChange={this.setStartTime}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="d-MMMM yyyy HH:mm"
                            timeCaption="time"
                            className='cdbxEPicker'
                        />
                    </div>
                    <div className="ThreePartSearch_txt">To:</div>
                    <div className="WOLDatepicker">
                        <DatePicker
                            selected={this.state.enddate}
                            onChange={this.setEndTime}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="d-MMMM yyyy HH:mm"
                            timeCaption="time"
                            className='cdbxEPicker'
                        />
                    </div>
                    <div className="ThreePartSearch_adSearchBtn" onClick={_this.props.refresh}>
                        <div className="fa fa-refresh" />
                    </div>
                </div>
                <div className="ThreePartSearch_hiddenSearches">
                    <div className="ThreePartSearch_txt">Device Type:</div>
                    <select className="ThreePartSearch_select" onChange={(e) => _this.FilterOnchange(e)}>
                        <option value="">All</option>
                        {
                            (deviceList == null || deviceList.length == null || deviceList.length == 0) ?
                                <option style={{ display: 'none' }}></option>
                                :
                                deviceList.map(function (item) {
                                    return <option value={item} >{item}</option>
                                })
                        }
                    </select>
                </div>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {

        UserInfo: state.UserInfo,
        currentSite: state.currentSite,
    }
}
export default connect(mapStateToProps)(Searchbar)