import React from 'react'
import './addButton.css'
//Redux Import
import { connect } from 'react-redux'

function AddButton(props) {
    let onClick = props.onClick
    return (
        <div>
            <button class="button" onClick={onClick}>+</button>
        </div>
    )
}

//Redux inside component function
function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps)(AddButton)