import React from 'react';
import './alarmTriggerHeader.css';
//Redux Import
import { connect } from 'react-redux';
import RealTimeAlert from '../../../Navbar/RealTimeAlert';

class AlarmTriggerHeader extends React.Component {
    constructor(props)
    {
        super(props);

        this.search = this.search.bind(this)   //update the alarm trigger list
        
        this.searchText = this.searchText.bind(this)
        this.searchType = this.searchType.bind(this)
        this.searchPriority = this.searchPriority.bind(this)
        this.searchSite = this.searchSite.bind(this)
        this.searchLevel = this.searchLevel.bind(this)
        this.searchLocation = this.searchLocation.bind(this)
        this.searchDevice = this.searchDevice.bind(this)
        this.selectEnabled = this.selectEnabled.bind(this)

        //these local states are not effecting the filtering result, just for getting the level list, location list and device list
        this.state = {
            pickedSite: "",
            pickedLevel: "",
        }
    }
    search()
    {
        this.props.refreshList()
    }
    searchText(event)
    {
        this.props.SearchHeader('searchText', event.target.value)
    }
    searchType(event)
    {
        this.props.SearchHeader('searchType', event.target.value)
    }
    searchPriority(event)
    {
        this.props.SearchHeader('searchPriority', event.target.value)
    }
    searchSite(event)
    {
        this.props.SearchHeader('searchSite', event.target.value)
        this.setState({
            pickedSite: event.target.value
        })
    }
    searchLevel(event)
    {
        this.props.SearchHeader('searchLevel', event.target.value)
        this.setState({
            pickedLevel: event.target.value
        })
    }
    searchLocation(event)
    {
        this.props.SearchHeader('searchLocation', event.target.value)
    }
    searchDevice(event)
    {
        this.props.SearchHeader('searchDevice', event.target.value)
    }
    selectEnabled(event) {
        this.props.SearchHeader('searchEnable', event.target.value)
    }
    render()
    {
        const {UserInfo} = this.props
        if(UserInfo == null) return <div>...</div>
        const {pickedSite, pickedLevel} = this.state

        let levelList = [{
            locName: "--",
            _id: "",
        }]
        let locationList = [{
            locName: "--",
            _id: "",
        }]
        let DeviceList = [{
            locName: "--",
            _id: "",
        }]

        //find the level list
        if(pickedSite != null && pickedSite != "") {
            let siteChosen = UserInfo.locations.find(function(siteItem) {
                return siteItem._id == pickedSite
            });
            if(siteChosen != null && siteChosen != "") {
                levelList = siteChosen.nestedLocs
            }
            //find the location list
            if(pickedLevel != null && pickedLevel != "" && siteChosen != null) {
                let levelChosen = siteChosen.nestedLocs.find(function(levelItem) {
                    return levelItem._id == pickedLevel
                })
                if(levelChosen != null) locationList = levelChosen.nestedLocs
            }
        }

        return [<div className="AT_header_Container">
            <input className="AT_header_TxtInput" placeholder="Search.." onChange={this.searchText}></input>
            <select className="AT_header_selectOption" onChange={this.searchType}>
                <option value="">All Type of Alarm</option>
                <option value="Device">Device</option>
                <option value="System">System</option>
                <option value="Equipment">Equipment</option>
            </select>
            <select className="AT_header_selectOption" onChange={this.searchPriority}>
                <option value="">All Priority</option>
                <option value="Urgent">Urgent</option>
                <option value="High">High</option>
                <option value="Normal">Normal</option>
                <option value="Low">Low</option>
            </select>
            <select className="AT_header_selectOption" onChange={this.selectEnabled}>
                <option value="Enabled">Enabled</option>
                <option value="Disabled">Disabled</option>
                <option value="Both">Both</option>
            </select>
            <select className="AT_header_selectOption" onChange={this.searchSite}>
                <option value="">All Site</option>
                {
                    UserInfo.locations.map(function(siteItem){
                        return <option value={siteItem._id}>
                            {
                                siteItem.locName
                            }
                        </option>
                    })
                }
            </select>
            <select className="AT_header_selectOption" onChange={this.searchLevel}>
                <option value="">All Level</option>
                {
                    levelList.map(function(levelItem) {
                        return <option value={levelItem._id}>
                            {
                                levelItem.locName
                            }
                        </option>
                    })
                }
            </select>
            <select className="AT_header_selectOption" onChange={this.searchLocation}>
                <option value="">All Locations</option>
                {
                    locationList.map(function(LocItem) {
                        if(LocItem.device_id == null) {
                            return <option value={LocItem._id}>
                            {
                                LocItem.locName //+ LocItem.device_id.deviceName
                            }
                            </option>
                        }
                        else {
                            return <option value={LocItem._id}>
                            {
                                LocItem.locName + " | " + LocItem.device_id.deviceName
                            }
                            </option>
                        }
                    })
                }
            </select>
            <div className="fa fa-search AT_header_searchBtn" onClick={this.search}></div>
        </div>,
         <RealTimeAlert onRef={ref => (this.RealTimeAlertRef = ref)} />
        ]
    }
}
//Redux inside component function
function mapStateToProps(state) {
    return {
      UserInfo: state.UserInfo
    };
}

export default connect(mapStateToProps)(AlarmTriggerHeader);