import React from 'react'
import './TableHead.css'
//Redux Import
import { connect } from 'react-redux'


function TableHead(props) {

    return <div className="PR_Table">
        <div className={"col1"}>
            Device ID
            {
                <div style={{ display: 'none' }}></div>
            }
        </div>
        <div className={"col2"}>
            Total Down Time(hr)
            {
                <div style={{ display: 'none' }}></div>
            }
        </div>
        <div className={"col2"}>
            W.O. Count
            {
                <div style={{ display: 'none' }}></div>
            }
        </div>
        <div className={"col2"}>
            Fault Count
            {
                <div style={{ display: 'none' }}></div>
            }
        </div>
        <div className={"col2"}>
            Availability
            {
                <div style={{ display: 'none' }}></div>
            }
        </div>
        <div className={"col2"}>
            MTBF(hr)
            {
                <div style={{ display: 'none' }}></div>
            }
        </div>
        <div className={"col2"}>
            MTTR(hr)
            {
                <div style={{ display: 'none' }}></div>
            }
        </div>
        <div className='col3'/>
    </div>
}

//Redux inside component function
function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps)(TableHead)