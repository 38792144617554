import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle, faTimes } from '@fortawesome/free-solid-svg-icons'
import './infoPanel.css'
import { isConvertToWaterLeakageAndPump, isConvertWaterLeakageToWaterPump } from '../../MenuList/MenuListItem'

function InfoPanel(props) {
    const {DeviceData, currentSite, currentlevel} = props
    const [isPopup, setIsPopup] = useState(false)
    const [displayList, setDisplayList] = useState([])

    useEffect(() => {
        //onload function, or when devicedata is juse ready
        if(DeviceData==null) return
        if(isSwimmingPool(props)) {
            setDisplayList(SwimmingList)
            return
        }
        if(props.currentlevelID==='62b94ac6854329b83855b398') { //water pump in china chem TCP
            setDisplayList(legendOfTCP)
            return
        }
        let locList = []
        try {
            locList = DeviceData[currentSite].nestedLocs.find(item => item.locName == currentlevel).nestedLocs
        }
        catch(err) {
            console.error(err)
        }
        if(locList==null||locList ==[]||locList.length==null) return

        const colorList = locList.map(item => {
            if(item.payload_definition_id==null||item.locUI==null||item.locUI.indicator==null) return
            return {
                name: item.payload_definition_id.name,
                color: item.locUI.indicator.color
            }
        })
        const arrayUniqueByKey = [...new Map(colorList.map(item => [item['name'], item])).values()]
        setDisplayList(arrayUniqueByKey)
    }, [DeviceData, currentSite])

    const isWaterpump = isConvertWaterLeakageToWaterPump({
        ...props,
        nameID: 'Water Leakage'
    })

    return isPopup?
        <div class='InfoPl_open'>
            <FontAwesomeIcon icon={faTimes} className='fa icon' onClick={()=>setIsPopup(false)} />
            <div className='scroll'>
                {/* panelItems */}
                {
                    displayList.map(item => {
                        if(isWaterpump && item.name === 'Water Leakage') {
                            return <div className='row'>
                                <div className='pt' style={{background: 'rgb(0,0,128)'}}></div>
                                <div className='name'>Water Pump</div>
                            </div>
                        }
                        return <div className='row'>
                            <div className='pt' style={{background: item.color}}></div>
                            <div className='name'>{item.name}</div>
                        </div>
                    })
                }
                {
                    hasGateWay(props)==true?
                    <div className='row'>
                        <div className='pt' style={{background: 'lightgreen'}}></div>
                        {/* <div className='pt' style={{background: 'red'}}></div> */}
                        <div className='name'>Gateway</div>
                    </div>
                    :
                    <div style={{display: 'none'}}></div>
                }
            </div>
        </div>
        :
        <div className='InfoPl' onClick={()=>setIsPopup(true)}>
            <FontAwesomeIcon icon={faQuestionCircle} className='fa icon' />
        </div>
}

const mapStateToProps = state => {
    return {
      DeviceData: state.DeviceData,
      currentSite: state.currentSite,
      currentlevel: state.currentlevel,
      currentlevelID: state.currentlevelID
    }
}
export default connect(mapStateToProps)(InfoPanel)

const hasGateWay = (props) => {
    const {DeviceData} = props
    if(DeviceData==null || DeviceData.length==null) return false

    let hasEquip = false
    DeviceData.forEach(siteItem => {
        siteItem.nestedLocs.forEach(lvlItem => {
            if(lvlItem.equipments==null || lvlItem.equipments.length==null) return
            if(lvlItem.equipments.length >=1) hasEquip = true
        })
    })

    return hasEquip
}


//Kwai Shing KSSP swimming pool
const isSwimmingPool = props => {
    const {DeviceData, currentSite} = props
    if(DeviceData==null || currentSite==null) return false
    const site = DeviceData[currentSite]
    const sitename = site.locName
    return sitename.includes('Swimming')
}
const SwimmingList = [
    {
        name: 'Water Quality',
        color: 'rgb(45, 241, 97)'
    },
    {
        name: 'Flow Meter',
        color: 'rgb(255, 126, 249)'
    },
    {
        name: 'Carbon Filter',
        color: 'green'
    },
    {
        name: 'Pump Statistic',
        color: 'rgb(0, 145, 255)'
    },
    {
        name: 'Pump Consumption',
        color: 'rgb(212, 191, 0)'
    },
    {
        name: 'Pump Status',
        color: 'rgb(0, 202, 27)'
    }
]

// ChinaChem TCP G/F with water pump
const legendOfTCP = [
    {
        name: 'Water Pump',
        color: 'rgba(140, 14, 255, 0.43)'
    },
    {
        name: 'Water Leakage',
        color: 'rgb(0, 208, 255)'
    },
    {
        name: 'IEQ',
        color: 'var(--focuscardbackground)'
    },
]