import React from 'react'
import './Table.css'
import { connect } from 'react-redux'
//import Moment from 'react-moment'
import moment from 'moment';
import { useFormik } from 'formik';

function Table(props) {
    let row = props.row
    let startTime = moment(row.startTime, "YYYY-MM-DD").toDate()
    let endTime = moment(row.endTime, "YYYY-MM-DD").toDate()
    startTime.setHours(0, 0, 0, 0)
    endTime.setHours(0, 0, 0, 0)

    let handleActionChange = e => {
        if (e.target.value === "Pending Resume") {
            props.updateWorkOrder(row, "Pending Resume")
        } else if (e.target.value === "Waiting Approval") {
            props.updateWorkOrder(row, "Waiting Approval")
        } else if (e.target.value === "Active") {
            props.onClick()
        }
    }

    return (
        <div className="wrap" >
            <div className="Dash_TableRow">
                <div className="col1">{row.name}</div>
                <div className="col1">{row.workOrderType}</div>
                <div className="col1">{row.parts}</div>
                <div className="col1">{row.orderNo}</div>
                <div className="col1">{row.requestor}</div>
                <div className="col1">{row.downTime}</div>
                <select className="col2" key="3" value={row.workOrderStatus} onChange={handleActionChange}>
                    <option key={0} value="Pending Resume">Pending</option>
                    <option key={1} value="Waiting Approval">Waiting</option>
                    <option key={2} value="Active">Resume</option>
                </select>
                <div id='UM_table_R' className='fa fa-edit EditBtn' onClick={props.onClick}></div>
                <div className='col3'></div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        UserInfo: state.UserInfo,
        socket: state.socket,
    }
}
export default connect(mapStateToProps)(Table) 