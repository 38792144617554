import React from 'react'
//Drag test
import { WidthProvider, Responsive  } from 'react-grid-layout'
import _ from "lodash"
//Redux Import
import { connect } from 'react-redux'
//option bar for managing the widgets in Statistic page
import DragOptionBar from '../../FloorPlan/DragLayout/DragOptionbar'
//widget Import
import LineChart from '../Widgets/LineChart'
import MenuList from '../Widgets/MenuList/MenuList'
//widget title
import SpiderChart from '../Widgets/SpiderChart'
//Widget alarm Count
import AlarmCountDisplay from '../Widgets/alarmCountDisplay'
import AlarmCountDisplay2 from '../Widgets/alarmCountDisplay2'
//header widget (13-Aug-2019)
import SiteLevelPicker from '../../FloorPlan/SiteLevelPicker/SiteLevelPicker'
import SiteDisplay from '../Widgets/SiteDisplay'
//Guage chart and 'Heat' Map
import Guage from '../Widgets/Guage'
import HeatMap from '../Widgets/Heatmap'
import LiftHeader from '../../FloorPlan/LiftWidgets/LiftHeader'        //Share the same header with Dashboard page
import LiftSensors from '../LiftWidgets/MonthSummary/LiftSensors'
import LiftAlarmList from '../LiftWidgets/LiftAlarmList/LiftAlarmList'
import LiftXYZStatistic from '../LiftWidgets/LiftAlarmList/LiftAlarmList_statistic'
import LiftPowerSummary from '../LiftWidgets/LiftPower/LiftPowerSummary'
import LiftRuntimeSummary from '../LiftWidgets/LiftRuntime/LiftRuntime'
import LiftDoorStat from '../LiftWidgets/LiftDoorStat/LiftDoorStat'
import ColdboxLineChart from '../ColdboxWidgets/Linechart'
// import LiftSummary from '../ColdboxWidgets/Linechart'
import $ from 'jquery'
import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons'

//const ReactGridLayout = WidthProvider(RGL);
const ResponsiveReactGridLayout = WidthProvider(Responsive)

class DragLayout extends React.Component {
    static defaultProps = {
        className: "layout",
        rowHeight: (window.innerHeight - 75)/20,
        cols: { lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 },
    }

    constructor(props) {
        super(props)

        var layout = this.generateLayout()
        const componentCount = 7
        this.state = { layout, componentCount }
        this.onLayoutChange = this.onLayoutChange.bind(this)
        this.generateLayout = this.generateLayout.bind(this)
        this.saveLayoutPosition = this.saveLayoutPosition.bind(this)
        this.onAddItem = this.onAddItem.bind(this)
        this.UpdateWidgetLockUnlock = this.UpdateWidgetLockUnlock.bind(this)

        this.resetLayout = this.resetLayout.bind(this)
        this.resetLayout2 = this.resetLayout2.bind(this)

        //for parent component call function inside this component, set onRef
        this.props.onRef(this)
    }

    resetLayout() {
        this.setState({		
            layout: []		
        });	
    }		
    resetLayout2() {	
        console.log('resetLayout2()')
        let {UserInfo, currentSite, currentlevel} = this.props
        let currentSiteInt = parseInt(currentSite, 0) 
        let thisoutside = this
        try {
            //get the current level ID
            let levelItem = UserInfo.locations[currentSiteInt].nestedLocs.find(function(element) {
                return element.locName == currentlevel;
            })
            if(levelItem == null || levelItem == undefined) {
                alert('Cannot find Level Itme with currentSite, currentLevel')
                return
            }
            //console.log(levelItem);
            let levelId = levelItem._id
            if(levelId == null || levelId == undefined) {
                alert('Cannot get level ID from Redux > UserInfo')
                return
            }
            //get the widget array based on level ID
            let UIWidgetList = UserInfo.ui.find(function(uiItem) {
                return uiItem.location_id.toString() == levelId && uiItem.pageType == "Statistics" //"Statistics"
            })
            //console.log(UIWidgetList);
            if(UIWidgetList == null || UIWidgetList == undefined) {
                //alert('UI Widget List in UserInfo does not included location _id:' + levelId
                // + '. Would use default Statistic widget page');
                let defaultLayout = this.generateLayout(); //use default layout
                thisoutside.setState({
                    layout: defaultLayout
                })
            }
            else {
                let LayoutSet = UIWidgetList.widgetConfig
                LayoutSet.forEach(LayoutItem => {
                    LayoutItem.static = true
                })

                thisoutside.setState({
                    layout: LayoutSet
                })
            }
        }
        catch(err) {
            console.log('error when getting the widget json with level ID in Draglayout.js, Floorplan')
            console.log(err)
        }
    }		
    componentDidUpdate(nextProps) {
        //console.log('componentWillReceiveProps() in draglayout.js /floorplan');		
        //run update only when currentsite value is chhanged		
        let oldCurrentSite = this.props.currentSite
        let newCurrentSite = nextProps.currentSite
        if(oldCurrentSite == newCurrentSite) {		
            return	
        }
        let thisoutside = this	
        setTimeout(function(){thisoutside.resetLayout()}, 0)	
        setTimeout(function(){thisoutside.resetLayout2()}, 10)
    }		

    generateLayout() {
        //get the component list from DB
        let {UserInfo, currentSite, currentlevel} = this.props
        let currentSiteInt = parseInt(currentSite, 0)
        try {
            //get the current level ID
            let levelItem = UserInfo.locations[currentSiteInt].nestedLocs.find(function(element) {
                return element.locName == currentlevel
            })
            if(levelItem == null || levelItem == undefined) {
                alert('Cannot find Level Itme with currentSite, currentLevel')
                return
            }

            let levelId = levelItem._id
            if(levelId == null || levelId == undefined) {
                alert('Cannot get level ID from Redux > UserInfo')
                return
            }
            //get the widget array based on level ID
            let UIWidgetList = UserInfo.ui.find(function(uiItem) {
                return uiItem.location_id.toString() == levelId && uiItem.pageType == "Statistics" //"Statistics"
            })
            if(UIWidgetList == null || UIWidgetList == undefined) {
                //alert('UI Widget List in UserInfo does not included location _id:' + levelId
                // + '. Would use default Statistic widget page')
                return [
                    {
                        x:8, y:1, w:4, h:6, i:"SpiderChart", isResizable: true, maxH: 200, maxW: 200, minH: 0, minW: 0, static: true
                    },
                    {
                        x:0, y:7, w:8, h:9 , i:"LineChart", isResizable: true, maxH: 200, maxW: 200, minH: 0, minW: 0, static: true
                    },
                    {
                        x:0, y:1, w:2, h:6, i:"siteDisplay", isResizable: true, maxH: 200, maxW: 200, minH: 0, minW: 0, static: true
                    },
                    {
                        x:2, y:1, w:3, h:3, i:"alarmCountDisplay", isResizable: true, maxH: 200, maxW: 200, minH: 0, minW: 0, static: true
                    },
                    {
                        x:2, y:4, w:3, h:3, i:"alarmCountDisplay2", isResizable: true, maxH: 200, maxW: 200, minH: 0, minW: 0, static: true
                    },
                    {
                        x:5, y:1, w:3, h:6, i:"Guage", isResizable: true, maxH: 200, maxW: 200, minH: 0, minW: 0, static: true
                    },
                    {
                        x:8, y:7, w:4, h:9, i:"Heatmap", isResizable: true, maxH: 200, maxW: 200, minH: 0, minW: 0, static: true
                    },
                    {
                        x:0, y:0, w:12, h:1, i:"siteLevelPicker", isResizable: true, maxH: 200, maxW: 200, minH: 0, minW: 0, static: true
                    },
                ]
            }
            else {
                let LayoutSet = UIWidgetList.widgetConfig
                LayoutSet.forEach(LayoutItem => {
                    LayoutItem.static = true
                })
                console.log(LayoutSet)
                return LayoutSet
            }
        }
        catch(err) {
            console.log('error when getting the widget json with level ID in Draglayout.js, Floorplan')
            console.log(err)
        }

        //return [];
        //return the array layout (hardcode, would get from DB later)
        // return [
        //     {
        //         x:8, y:1, w:4, h:6, i:"SpiderChart", isResizable: true, maxH: 200, maxW: 200, minH: 0, minW: 0, static: true
        //     },
        //     {
        //         x:0, y:7, w:8, h:9 , i:"LineChart", isResizable: true, maxH: 200, maxW: 200, minH: 0, minW: 0, static: true
        //     },
        //     {
        //         x:0, y:1, w:2, h:6, i:"siteDisplay", isResizable: true, maxH: 200, maxW: 200, minH: 0, minW: 0, static: true
        //     },
        //     {
        //         x:2, y:1, w:3, h:3, i:"alarmCountDisplay", isResizable: true, maxH: 200, maxW: 200, minH: 0, minW: 0, static: true
        //     },
        //     {
        //         x:2, y:4, w:3, h:3, i:"alarmCountDisplay2", isResizable: true, maxH: 200, maxW: 200, minH: 0, minW: 0, static: true
        //     },
        //     {
        //         x:5, y:1, w:3, h:6, i:"Guage", isResizable: true, maxH: 200, maxW: 200, minH: 0, minW: 0, static: true
        //     },
        //     {
        //         x:8, y:7, w:4, h:9, i:"Heatmap", isResizable: true, maxH: 200, maxW: 200, minH: 0, minW: 0, static: true
        //     },
        //     {
        //         x:0, y:0, w:12, h:1, i:"siteLevelPicker", isResizable: true, maxH: 200, maxW: 200, minH: 0, minW: 0, static: true
        //     },
        // ]
    }

    onLayoutChange(layout) {
        // console.log(layout)

        this.setState({ layout: layout })
    }

    saveLayoutPosition() {
        // console.log('save');
        // console.log(this.state.layout);
        const {layout} = this.state
        //send back (return)
        return layout           //save feature is in inner widget, which shared with Dashboard draglayout.js
    }

    onBreakpointChange(breakpoint, cols) {
        console.log('onBreakpointChange')
        console.log(breakpoint)
        console.log(cols)
    }

    onRemoveItem(i) {
        console.log("removing", i)
        this.setState({ layout: _.reject(this.state.layout, { i: i }) })
    }
    
    onAddItem() {
        console.log("adding");
        console.log(this.state.layout)

        //set to state (push a new item to array list)
        console.log(this.state)
        let newcomponent = {
            x:12, y:0, w:1, h:4, i:this.state.componentCount.toString(), component: "SummartCard4"
        }
        this.setState({
            layout: [...this.state.layout, newcomponent],
            componentCount: this.state.componentCount + 1
        })
    }
    
    createElement(el) {
        const removeStyle = {

        }
        const i = el.add ? "+" : el.i

        let { isLock } = this.props
        let isHide = ""
        if(isLock) {
            isHide = ""
        }
        else {
            isHide = " hide"
        }

        switch(el.i) {
            case "SpiderChart":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <SpiderChart/>
                        }
                        <span
                            className={"remove" + isHide}
                            style={removeStyle}
                            onClick={this.onRemoveItem.bind(this, i)}
                            >
                            <i className="fa fa-times"></i>
                        </span>
                    </div>
                )
            case "LineChart":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <LineChart editbuttonhideclass={isHide}/>
                        }
                        <span
                            className={"remove" + isHide}
                            style={removeStyle}
                            onClick={this.onRemoveItem.bind(this, i)}
                            >
                            <i className="fa fa-times"></i>
                        </span>
                    </div>
                )
            case "menu":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <MenuList/>
                        }
                        <span
                            className={"remove" + isHide}
                            style={removeStyle}
                            onClick={this.onRemoveItem.bind(this, i)}
                            >
                            <i className="fa fa-times"></i>
                        </span>
                    </div>
                )
            case "siteLevelPicker":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <SiteLevelPicker/>
                        }
                        <span
                            className={"remove" + isHide}
                            style={removeStyle}
                            onClick={this.onRemoveItem.bind(this, i)}
                            >
                            <i className="fa fa-times"></i>
                        </span>
                    </div>
                )
            case "siteDisplay":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <SiteDisplay/>
                        }
                        <span
                            className={"remove" + isHide}
                            style={removeStyle}
                            onClick={this.onRemoveItem.bind(this, i)}
                            >
                            <i className="fa fa-times"></i>
                        </span>
                    </div>
                )
            case "alarmCountDisplay":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <AlarmCountDisplay/>
                        }
                        <span
                            className={"remove" + isHide}
                            style={removeStyle}
                            onClick={this.onRemoveItem.bind(this, i)}
                            >
                            <i className="fa fa-times"></i>
                        </span>
                    </div>
                )
            case "alarmCountDisplay2":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <AlarmCountDisplay2/>
                        }
                        <span
                            className={"remove" + isHide}
                            style={removeStyle}
                            onClick={this.onRemoveItem.bind(this, i)}
                            >
                            <i className="fa fa-times"></i>
                        </span>
                    </div>
                )
            case "Guage":
                    return (
                        <div key={i} data-grid={el} className="drag">
                            {
                                <Guage/>
                            }
                            <span
                                className={"remove" + isHide}
                                style={removeStyle}
                                onClick={this.onRemoveItem.bind(this, i)}
                                >
                                <i className="fa fa-times"></i>
                            </span>
                        </div>
                    )
            case "Heatmap":
                    return (
                        <div key={i} data-grid={el} className="drag">
                            {
                                <HeatMap/>
                            }
                            <span
                                className={"remove" + isHide}
                                style={removeStyle}
                                onClick={this.onRemoveItem.bind(this, i)}
                                >
                                <i className="fa fa-times"></i>
                            </span>
                        </div>
                    )
            case "LiftsiteLevelPicker":
                return (
                    <div key={i} data-grid={el} className="drag">
                    {
                        <LiftHeader/>
                    }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                    </div>
                )
            case "LiftSensors":
                return (
                    <div key={i} data-grid={el} className="drag">
                    {
                        <LiftSensors/>
                    }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                    </div>
                )
            case "LiftAlarmList":
               return (
                    <div key={i} data-grid={el} className="drag">
                    {
                        <LiftAlarmList/>
                    }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                    </div>
                ) 
            case "LiftPowerSummary":
               return (
                    <div key={i} data-grid={el} className="drag">
                    {
                        <LiftPowerSummary/>
                    }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                    </div>
                )
            case "LiftRuntimeSummary":
                return (
                    <div key={i} data-grid={el} className="drag">
                    {
                        <LiftRuntimeSummary/>
                    }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                    </div>
                )
            // case 'LiftSummary':
            //     return (
            //         <div key={i} data-grid={el} className="drag">
            //         {
            //             <LiftSummary/>
            //         }
            //         <span
            //             className={"remove" + isHide}
            //             style={removeStyle}
            //             onClick={this.onRemoveItem.bind(this, i)}
            //             >
            //             <i className="fa fa-times"></i>
            //         </span>
            //         </div>
            //     )
            case 'Coldbox_Linechart':
                return (
                    <div key={i} data-grid={el} className="drag">
                    {
                        <ColdboxLineChart/>
                    }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                    </div>
                )
            case "LiftDoorStat":
                return (
                    <div key={i} data-grid={el} className="drag">
                    {
                        <LiftDoorStat/>
                    }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                    </div>
                )
            case 'LiftXYZStatistic':
                return (
                    <div key={i} data-grid={el} className="drag">
                    {
                        <LiftXYZStatistic/>
                    }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                    </div>
                )
            default: 
                break;
        }
        return (
          <div key={i} data-grid={el}>
            {el.add ? (
              <span
                className="add text"
                onClick={this.onAddItem}
                title="You can add an item by clicking here, too."
              >
                Add +
              </span>
            ) : (
              <span className="text">{i} is not correct case!</span>
            )}
            <span
              className={"remove" + isHide}
              style={removeStyle}
              onClick={this.onRemoveItem.bind(this, i)}
            >
              x
            </span>
          </div>
        )
    }

    UpdateWidgetLockUnlock() {
        //check the widget lock / unlock setting
        let { isLock } = this.props
        
        let currentlayout = this.state.layout
        let updatedlayout = []
        //foreach widget item, static: true/false
        $.each(currentlayout, function(key, value){
            //console.log(value);
            let foreachitem = value
            if(isLock) {
                foreachitem.static = true
            }
            else {
                foreachitem.static = false
            }
            updatedlayout.push(foreachitem)
        })
        console.log(updatedlayout)
        //set the updatedlayout to state
        this.setState({
            layout: updatedlayout
        })
    }

    render() {
        const _this = this
        const {layout} = this.state
        const {isLock} = this.props
        
        console.log(layout)
        if(layout==null || layout.length == null || layout.length == 0) return <div></div>

        return [
            <ResponsiveReactGridLayout
                onBreakpointChange={this.onBreakpointChange}
                onLayoutChange={this.onLayoutChange}
                layouts={layout}
                {...this.props}	                
                history={this.props.history}
                useCSSTransforms={true}        //false = no tranisition css effect
            >                
            {_.map(this.state.layout, el => this.createElement(el))}
            </ResponsiveReactGridLayout>,
            //,<SvelteComponent this={SvelteMap} {...this.props}/>
            <DragOptionBar isLock={isLock} WidgetLock={_this.props.WidgetLock} saveLayoutPosition={_this.saveLayoutPosition} onAddItem={_this.onAddItem} WidgetLock={_this.props.WidgetLock} widgetType="Statistic" />
        ]
    }
}

function mapStateToProps(state) {
    return {
      currentlevel: state.currentlevel,
      currentSite: state.currentSite,
      MenuDisplay: state.MenuDisplay,
      SiteList: state.SiteList,
      UserInfo: state.UserInfo,
      DeviceData: state.DeviceData,
      DeviceDataLastUpdate: state.DeviceDataLastUpdate          //try to trigger render in inner items when pick Site / Level
    }
}
export default connect(mapStateToProps)(DragLayout)