import React from 'react'
import './RangeLimit.css'
import { connect } from 'react-redux'

class RangeLimit extends React.Component {
    constructor(props)
    {
        super(props)
    }
    render() {
        //FIND DEVICE ITEM
        const {DeviceData, currentSite, currentlevel} = this.props
        if(currentlevel == null || currentSite == null || DeviceData == null) return <div></div>        //exception case
        const FindSite = DeviceData[currentSite]
        if(FindSite == null) return <div></div>             //2nd exceptional case
        const FindLevel = FindSite.nestedLocs.find(function(levelItem) {
            return (levelItem.locName == currentlevel) || (levelItem.locName == currentlevel.toString())
        })
        // console.log(FindLevel)
        const Payload = FindLevel.nestedLocs[0].payload
        // console.log(Payload)

        let isFloorDetect = false
        FindLevel.nestedLocs.forEach(deviceItem => {
            if(deviceItem.payload != null) {
                const DevicePayload = deviceItem.payload
                if(DevicePayload.floorDetection != null) isFloorDetect = DevicePayload.floorDetection
            }
        })
        // let MoveLiftClass = ""
        let highlightTop = ""
        let highlightMid = ""
        if(!isFloorDetect) {    //false == out of range
            // MoveLiftClass = "LiftRangeBotOutRange"
            highlightTop = "LiftRangeHighlightRed"
        }
        else highlightMid = "LiftRangeHighlight"

        return <div className="Lift_graphDirection">
        <div className="Lift_graphTitle">
            Over Limit
        </div>
        <div className="Lift_graph_rangeMoveContainer">
                <div className={"Lift_graph_rangeStatusDisplay " + highlightTop} onClick={this.TopOutRangeSet}>
                    Out of Range
                </div>
                <div className={"Lift_graph_rangeStatusDisplay " + highlightMid} onClick={this.InRangeSet}>
                    In Range
                </div>
        </div>
    </div>
    }
}

function mapStateToProps(state) {
    return {
        socket: state.socket,
        UserInfo: state.UserInfo,
        currentSite: state.currentSite,
        currentlevel: state.currentlevel,
        DeviceData: state.DeviceData,
        currentlevelID: state.currentlevelID,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate
    }
}
export default connect(mapStateToProps)(RangeLimit)