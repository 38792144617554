import React, { useEffect, useState } from 'react'
import './TestPage.css'
import { connect } from 'react-redux'
import HardcodeDeviceData from './HardcodeInput'

import Test_demo from './TestWidget/Test_demo'
// import TestingWidget from './bottomcards/bottomcards'
// import TestingWidget from '../Main/FloorPlan/Summary/ntu/ntu'
// import TestingWidget from './bottomcards/controlMode/controlMode'
// import TestingWidget from './peopleCountSum/peopleCountSum'
// import TestingWidget from './bottomcards/carbonDetect/carbonDetect'
// import TestingWidget from './scrollTest/scrollTest'
import TestingWidget from './TestFunction/chinachemhardcodetest'

function TestPage(props) {
    const [WidgetWidth, setWidgetWidth] = useState(10)         //1 - 12
    const [WidgetHeight, setWidgetHeight] = useState(5)       //1 - 16

    useEffect(() => {
        //hardcode data store to redux for simulation
        const SaveUserInfo = HardcodeDeviceData
        props.dispatch({
            type: "SaveUserInfo",
            data: SaveUserInfo,
        })
        props.dispatch({
            type: "PIckSite",
            data: {
                Siteid: 0,
                Level: '1',
            },
        })
    }, [])

    return <div className="TestP">
        <div className="TestP_settings">
            <div className="TestP_head">Test Page: Build new widgets</div>
            <div className="TestP_setRow">
                <div className="TestP_setRowtxt">Widget Width:</div>
                <div className="TestP_setRowvalue">{WidgetWidth + '/12'}</div>
                <input type="range" className="testP_points" min="1" max="12" defaultValue="1" onChange={(e)=>setWidgetWidth(e.target.value)}></input>
            </div>
            <div className="TestP_setRow">
                <div className="TestP_setRowtxt">Widget Height:</div>
                <div className="TestP_setRowvalue">{WidgetHeight + '/16'}</div>
                <input type="range" className="testP_points" min="1" max="16" defaultValue="1" onChange={(e)=>setWidgetHeight(e.target.value)}></input>
            </div>
        </div>
        <div className="TestP_displayWidget">
            <div className="TestP_widgetItem" style={{width: 'calc(' + WidgetWidth + ' * 100vw / 12)', height: 'calc(' + WidgetHeight + ' * 100vh / 16)'}}>
                {/* <Test_demo /> */}
                <TestingWidget />
            </div>
        </div>
    </div>
}

function mapStateToProps(state) {
    console.log(state)
    return {
        DeviceData: state.DeviceData
    }
}
export default connect(mapStateToProps)(TestPage)