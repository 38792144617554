import React, { createRef } from 'react'
import './popup.css' 

//Redux Import
import { connect } from 'react-redux'

class coldbox_popup extends React.Component {
    constructor(props)
    {
        super()
        this.state = {
            UniqueID : null
        }
        this.handleInputChage = this.handleInputChage.bind(this)

        this.UniqueID = createRef()
        this.submitRecord = this.submitRecord.bind(this)
        this.closePopup = this.closePopup.bind(this)
    }

    closePopup(){
        this.setState({
            UniqueID : null
        })
        this.props.closePopup()
    }

    componentDidUpdate() {
        // console.log('componentDidUpdate()');
        const {  data} = this.props 
        // console.log(this.props);
        if(data !== null){
            if(this.state.UniqueID == null  ){
                this.setState({
                    UniqueID : data.uniqueID
                })
            }
           
        }
      
    }
    handleInputChage(event) {
        // console.log('handleInputChage()');
        // console.log({[event.target.name]: event.target.value});

        this.setState({
            [event.target.name]: event.target.value
        })
    }

    submitRecord(){
        const {UniqueID} = this.state
        const { UserInfo,data } = this.props
        if (UniqueID == null || UniqueID == "") return
        const EmitUserID = UserInfo.userID
        //request json 
        let requestData = {}
        requestData = {
            "msgType": "ModifyEquipment",
            "userID": EmitUserID,
            "client_id": UserInfo.client_id,
            "equipmentSerial": UniqueID,
            "_id":data._id
        }

        //send with redux socket function
        this.props.dispatch({
            type: 'EmitSocket',
            EmitSocketName: "EquipmentManageRequest",
            EmitSocketData: requestData
        }) 
        // this.props.reloadData()
        this.closePopup()
    }

    render()
    { 
        const {UniqueID} = this.state 
        const {isPopup, item,currentArea, data} = this.props 
        return [
            isPopup?
        <div className="Gateway_GD_popupBG" onClick={this.props.closePopup}></div>:<div></div>,
        isPopup?
        <div className="Gateway_GD_popupMain">
            <div className="gateway_popup_header">LoraWan Gateway Management</div>
            <div className="main">
            <div className="PopupRowContainer">
                            <input className="PopupInput" placeholder="Gateway ID" name="gatewayID" 
                                onChange={this.handleInputChage}  
                                value={data === undefined || data == null? "" : data.gatewayID} disabled
                                autoComplete="off"></input>
                            <input className="PopupInput" placeholder="Unique ID" name="UniqueID" 
                                onChange={this.handleInputChage} 
                                value={UniqueID === undefined || UniqueID == null? "" : UniqueID} 
                                autoComplete="off"></input>
                        </div>
                
            </div>
            <div className="buttonsContainer">
                   
                    <div className="UserManageSubmit" 
                    onClick={this.submitRecord}
                    >Submit</div>
                    <div className="UserManageCancel" 
                    onClick={this.closePopup}
                    >Cancel</div>
                </div>
        </div>
        :<div></div>
    ]
    }
}

function mapStateToProps(state) {
    return {
        UserInfo: state.UserInfo,
    }
}
  
export default connect(mapStateToProps)(coldbox_popup)