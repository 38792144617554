import React, { useEffect, useState } from 'react'
import './progress.css'

//const PercentInput = 95
// const ColorDefault = "lightgreen"
// const Highlight = "teal"
// const WhiteColor = "white"

function ProgressWidget(props) {
    const {PercentInput, ColorDefault, Highlight, WhiteColor, text, OccTotal, OccInUse} = props
    const [Percent, setPercent] = useState(0)
    const [CountUpPercent, setCountUpPercent] = useState(0)

    useEffect(() => {
        setTimeout(() => {
            setPercent(PercentInput)
        }, 100)
    }, [PercentInput, OccInUse])
    useEffect(() => {
        Tick(CountUpPercent, Percent)
    }, [Percent, OccInUse])
    function Tick(Percent, LastPercent) {
        let nextPercent
        if(LastPercent == Percent) return
        else if(LastPercent < Percent) {
            nextPercent = Percent - 1
            setCountUpPercent(nextPercent)
        }
        else {
            nextPercent = Percent + 1
            setCountUpPercent(nextPercent)
        }
        
        setTimeout(() => {
            Tick(nextPercent, LastPercent)
        }, 10)
    }

    const ColorStyleR = 'conic-gradient(from -180deg, transparent, transparent 50%, '+WhiteColor+' 50%, '+WhiteColor+' 51%, '+Highlight+' 51%, '+Highlight+')'
    const ColorStyleL = 'conic-gradient(from -180deg, transparent, transparent 50%, '+Highlight+' 50%, '+Highlight+' 99%, '+WhiteColor+' 99%)'
    const R_angle = (Percent>=50)?0:-180 + Percent/100*360          //if larger than 50%, would hide
    const L_angle = (Percent>=50)?(Percent-50)/100*360:R_angle      //if smaller than 50%, hide behind R_angle
    const L_block = (Percent<=50)

    const Occ_Vacancy = OccTotal - OccInUse
    return <div className="ProgressWidget" style={{background: ColorDefault}}>
        <div className="ProgressCir_R" style={{transform: 'rotateZ(' + R_angle + 'deg)', background: ColorStyleR}}>
        </div>
        <div className="ProgressCir_L" style={{transform: 'rotateZ(' + L_angle + 'deg)', background: ColorStyleL}}>
        </div>
        {
            L_block?<div className="ProgressCir_LBlock" style={{background: "conic-gradient(from 3.6deg, transparent, transparent 49%, "+ColorDefault+" 49%, "+ColorDefault+" 99%, "+WhiteColor+" 99%)"}}></div>:<div></div>
        }
        <div className="ProgressCir_Center" style={{color: Occ_Vacancy==0?'red':Highlight, background: WhiteColor}}>
            <div className="Reading">
                {Occ_Vacancy}
                <sub>
                    {"/" + OccTotal}
                </sub>
            </div>
            <div className="desc">
            {text}
            </div>
        </div>
    </div>
}

export default ProgressWidget