import React from 'react'
import './style.css'
import { connect } from 'react-redux'

function TestLocUpdate(props) {
    function callRedux(input) {
        let data
        switch(input) {
            case 1:
                data = test1
                break
            case 2:
                data = test2
                break
            case 3:
                data = test3
                break
            case 4:
                data = test4
                break
            default:
                data = test1
                break
        }
        props.dispatch({ type: "LocationCoordinatesUpdate", data: data })
    }
    return <div style={{display: 'none'}}></div>
    return <div className='testContainer'>
        <button onClick={() => callRedux(1)}>test 1</button>
        <button onClick={() => callRedux(2)}>test 2</button>
        <button onClick={() => callRedux(3)}>test 3</button>
        <button onClick={() => callRedux(4)}>test 4</button>
    </div>
}

function mapStateToProps(state) {
    return {}
}
export default connect(mapStateToProps)(TestLocUpdate)

const test1 = {
    "msgType":"LocationCoordinatesUpdate",
    "timestamp":"2021-10-21T06:21:26.408Z",
    "client_id":"6006850161a378359cb738bf",
    "locationInfo":[
        {
            "id":"60125eb463aad015e4989d48",
            "lvl":1,
            "name":"CPSL Coldbox Location"
        },
        {
            "id":"6020af3b6878e34868456679",
            "lvl":2,
            "name":"CT 3"
        },
        {
            "id":"60f64ba3aa35aa00182118c0",
            "lvl":3,
            "name":"C70001"
        }
    ],
    "newLocationInfo":[
        {
            "id":"60125eb463aad015e4989d48",
            "lvl":1,
            "name":"CPSL Coldbox Location"
        },
        {
            "_id":"6020af3b6878e34868456679",
            "lvl":2,
            "name":"CT 3"
        },
        {
            "id":"60f64ba3aa35aa00182118c0",
            "lvl":3,
            "name":"C70001"
        }
    ],
    "coordinates": [
        0.29500000000000004,
        0.26499999999999996
    ]
}

const test2 = {
    "msgType":"LocationCoordinatesUpdate",
    "timestamp":"2021-10-21T06:22:56.419Z",
    "client_id":"6006850161a378359cb738bf",
    "locationInfo":[
        {
            "id":"60125eb463aad015e4989d48",
            "lvl":1,
            "name":"CPSL Coldbox Location"
        },
        {
            "id":"6020af3b6878e34868456679",
            "lvl":2,
            "name":"CT 3"
        },
        {
            "id":"60f64ba3aa35aa00182118c0",
            "lvl":3,
            "name":"C70001"
        }
    ],
    "newLocationInfo":[
        {
            "id":"60125eb463aad015e4989d48",
            "lvl":1,
            "name":"CPSL Coldbox Location"
        },
        {
            "_id":"6020af3b6878e34868456679",
            "lvl":2,
            "name":"CT 3"
        },
        {
            "id":"60f64ba3aa35aa00182118c0",
            "lvl":3,
            "name":"C70001"
        }
    ],
    "coordinates":[
        0.08499999999999999,
        0.07500000000000001
    ]
}

const test3 = {
    "msgType":"LocationCoordinatesUpdate",
    "timestamp":"2021-10-21T06:35:56.551Z",
    "client_id":"6006850161a378359cb738bf",
    "locationInfo":[
        {
            "id":"60125eb463aad015e4989d48",
            "lvl":1,
            "name":"CPSL Coldbox Location"
        },
        {
            "id":"6020af3b6878e34868456679",
            "lvl":2,
            "name":"CT 3"
        },
        {
            "id":"60f64ba3aa35aa00182118c0",
            "lvl":3,
            "name":"C70001"
        }
    ],
    "newLocationInfo":[
        {
            "id":"60125eb463aad015e4989d48",
            "lvl":1,
            "name":"CPSL Coldbox Location"
        },
        {
            "_id":"60386f0b05f55b93d0d7a736",
            "lvl":2,
            "name":"CT 5"
        },
        {
            "id":"60f64ba3aa35aa00182118c0",
            "lvl":3,
            "name":"C70001"
        }
    ],
    "coordinates":[
        1.015,
        0.475
    ]
}

const test4 = {
    "msgType":"LocationCoordinatesUpdate",
    "timestamp":"2021-10-21T06:35:56.551Z",
    "client_id":"6006850161a378359cb738bf",
    "locationInfo":[
        {
            "id":"60125eb463aad015e4989d48",
            "lvl":1,
            "name":"CPSL Coldbox Location"
        },
        {
            "id":"6020af3b6878e34868456679",
            "lvl":2,
            "name":"CT 5"
        },
        {
            "id":"60f64ba3aa35aa00182118c0",
            "lvl":3,
            "name":"C70001"
        }
    ],
    "newLocationInfo":[
        {
            "id":"60125eb463aad015e4989d48",
            "lvl":1,
            "name":"CPSL Coldbox Location"
        },
        {
            "_id":"60386f0b05f55b93d0d7a736",
            "lvl":2,
            "name":"CT 5"
        },
        {
            "id":"60f64ba3aa35aa00182118c0",
            "lvl":3,
            "name":"C70001"
        }
    ],
    "coordinates":[
        0.15,
        1.475
    ]
}