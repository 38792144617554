const LocationData = (state, action) => {                     //redux 'locationData' function
    const InputData = action.data
    let {DeviceData} = state
    if(DeviceData == null || InputData == null) return DeviceData //exception cases

    const {Names, IDs} = NamesAndIDs(InputData)               // [Site, Floor, Location]

    const Indexs = GetSiteLvlLocIndex(DeviceData, Names, IDs) // [Site, Floor, Location]
    if(Indexs[0]==-1 || Indexs[1]==-1 || Indexs[2]==-1) return UpdateAtWrongFloor(DeviceData, Indexs, Names, IDs, InputData)

    return UpdateDeviceData(InputData, DeviceData, Indexs)  //return updated DeviceData
}
export default LocationData

//sub functions
const NamesAndIDs = InputData => {
    let Names = ['', '', '']    //site, level, location
    let IDs = [-1, -1, -1]
    InputData.locationInfo.forEach(locationInfoItem => {
        let index = -1
        switch(locationInfoItem.lvl) {
            case 1:
                index = 0
                break
            case 2:
                index = 1
                break
            case 3:
                index = 2
                break
        }
        Names[index] = locationInfoItem.name
        IDs[index] = locationInfoItem.id
    })

    return {
        Names: Names,
        IDs: IDs
    }
}
const GetSiteLvlLocIndex = (DeviceData, Names, IDs) => {
    const SiteCount = DeviceData.findIndex(item => item.locName==Names[0] || item.id==IDs[0])
    if(DeviceData[SiteCount]==null) return [-1, -1, -1]
    const FloorCount = DeviceData[SiteCount].nestedLocs.findIndex(item => item.locName==Names[1] || item.id==IDs[1])

    if(DeviceData[SiteCount]==null || DeviceData[SiteCount].nestedLocs[FloorCount]==null) return [SiteCount, FloorCount, -1]
    const LocationCount = DeviceData[SiteCount].nestedLocs[FloorCount].nestedLocs.findIndex(item => item.locName==Names[2] || item.id==IDs[2])

    return [SiteCount, FloorCount, LocationCount]
}
const UpdateDeviceData = (InputData, DeviceData, Indexs) => {
    try {
        const arrayPayload = json2array(InputData.payload)
        let newPayloadItem = DeviceData[Indexs[0]].nestedLocs[Indexs[1]].nestedLocs[Indexs[2]].payload
        if(newPayloadItem!=null && newPayloadItem!=undefined) {
            arrayPayload.forEach(updateItem => {
                newPayloadItem[updateItem[0]] = updateItem[1]
            })
        }
        newPayloadItem.updateDate = new Date()
        DeviceData[Indexs[0]].nestedLocs[Indexs[1]].nestedLocs[Indexs[2]].payload = newPayloadItem
    }
    catch(err) {
        console.log(err)
    }

    return DeviceData
}

//Fix the exceptional case
const UpdateAtWrongFloor = (DeviceData, Indexs, Names, IDs, InputData) => {
    //find index of floor, loc
    let floorIndex = 0
    let LocIndex = 0
    let floorCount = 0
    try {
        DeviceData[Indexs[0]].nestedLocs.forEach(floorItem => {
            floorItem.nestedLocs.forEach(locItem => {
                if(locItem.locName==Names[2] || locItem.id==IDs[2]) {
                    floorIndex = floorCount
                    LocIndex = floorItem.nestedLocs.findIndex(item => item.locName==Names[2] || item.id==IDs[2])
                }
            })
            floorCount++
        })
    }
    catch(err) {
        console.log(err)
    }

    //update the payload
    return UpdateDeviceData(InputData, DeviceData, [Indexs[0], floorIndex, LocIndex])
}

//reusable functions
const json2array = json => {
    var result = []
    var keys = Object.keys(json)
    keys.forEach(function (key) {
      result.push([key, json[key]])
    });
    return result
}