import React, { createRef } from 'react';
//Redux Import
import { connect } from 'react-redux';
import './SetDarkMode.css';

import SoundPlay from '../../Sound/Sound';

class SetDarkMode extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            SettingControlIsOn: true
        };

        this.OnOffControlClick = this.OnOffControlClick.bind(this);
        this.SetDarkMode = this.SetDarkMode.bind(this);
        this.NormalMode = this.NormalMode.bind(this);
        this.setStateValue = this.setStateValue.bind(this);

        this.IntervalLoopCheckStatus = {};

        this.soundRef = createRef();
    }
    setStateValue()
    {
        //check is dark or light mode now
        let DarkMode = localStorage.getItem('DarkMode');
        if(DarkMode != "false")
        {
            this.setState({
                SettingControlIsOn: false 
            });
        }
        else
        {
            this.setState({
                SettingControlIsOn: true 
            });
        }
    }
    componentDidMount()
    {
        let thisoutside = this;
        this.MapOnloadLoop = setInterval(() => {
            thisoutside.setStateValue();                //Set the loop function
            //console.log('I am alive');
        }, 1000);
    }
    componentWillUnmount()
    {
        clearInterval(this.IntervalLoopCheckStatus);    //remove the loop function
    }
    OnOffControlClick()
    {
        let {SettingControlIsOn} = this.state;
        let thisoutside = this;
        //set color
        if(!SettingControlIsOn)
        {
            thisoutside.NormalMode();
        }
        else
        {
            thisoutside.SetDarkMode();
        }
        this.setState({
            SettingControlIsOn: !SettingControlIsOn
        });
        //submit setting to DB
        //..
        try
        {
            this.soundRef.current.alarmMp3Run("hover")
        }
        catch(err)
        {
            console.log(err);
        }
    }
    SetDarkMode()
    {
        localStorage.setItem('DarkMode', true)
        var element = document.getElementById("root")
        element.classList.add("DarkMode")
        //since the background gradient is outside root, have to set the style manually
        const {configStorage} = this.props
        if(configStorage==null||configStorage.darkColorTheme==null) return  //exception condition
        document.documentElement.style.setProperty('--colortheme1', configStorage.darkColorTheme['--colortheme1'])
        document.documentElement.style.setProperty('--colortheme2', configStorage.darkColorTheme['--colortheme2'])
        document.documentElement.style.setProperty('--optiontext',  configStorage.darkColorTheme['--optiontext'])
        console.log('set dark mode')
        document.documentElement.style.setProperty('--slideBG_1', configStorage.darkColorTheme['--slideBG_1'])
        document.documentElement.style.setProperty('--slideBG_2', configStorage.darkColorTheme['--slideBG_2'])
        document.documentElement.style.setProperty('--slideBG_3', configStorage.darkColorTheme['--slideBG_3'])
        document.documentElement.style.setProperty('--optiontext', 'white')
    }
    NormalMode()
    {
        localStorage.setItem('DarkMode', false)
        var element = document.getElementById("root")
        element.classList.remove("DarkMode")

        //since the background gradient is outside root, have to set the style manually
        let ColorNavigationBar = localStorage.getItem('--colortheme1')
        let ColorBackground = localStorage.getItem('--colortheme2')

        //set to the css variable
        if(ColorNavigationBar != null && ColorNavigationBar != '')
        {
            document.documentElement.style.setProperty('--colortheme1', ColorNavigationBar)
            document.documentElement.style.setProperty('--colortheme2', ColorBackground)
        }
        document.documentElement.style.setProperty('--optiontext', 'black')
        //set normal mode
        console.log('set normal mode')
        //Navbar styling variable, since navbar slide is outside "#root"
        const {configStorage} = this.props
        if(configStorage==null||configStorage.darkColorTheme==null) return  //exception condition
        document.documentElement.style.setProperty('--slideBG_1', configStorage.darkColorTheme['--slideBG_1_Normal'])
        document.documentElement.style.setProperty('--slideBG_2', configStorage.darkColorTheme['--slideBG_2_Normal'])
        document.documentElement.style.setProperty('--slideBG_3', configStorage.darkColorTheme['--slideBG_3_Normal'])
    }
    render() {
        let {SettingControlIsOn} = this.state;
        let OnOffState = "";
        let OnOffMarginLeft = "";
        if(SettingControlIsOn)
        {
            OnOffState = "OnOffState";
            OnOffMarginLeft = "OnOffMarginLeft";
        }
        return <div className="SetDarkModeContainer">
            <div className="SetDarkModeTitle">
                Dark / Normal Mode
            </div>
            <div className={"SettingOnOffControl "+OnOffState} onClick={this.OnOffControlClick}>
                <div className={"SettingOnOffPointer "+OnOffMarginLeft}></div>
            </div>
            <SoundPlay ref={this.soundRef}/>
        </div>
    }
}

//Redux inside component function
function mapStateToProps(state) {
    return {
      socket: state.socket,
      configStorage: state.configStorage
    };
}

export default connect(mapStateToProps)(SetDarkMode);