import React, { useState } from 'react'
import { connect } from 'react-redux'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

function HighchartWidget(props) {
    const {ResponseData, color1, color2, markercolor, typeKey} = props
    const {StatisticData} = props       //redux

    let max, min, keyStr
    switch(typeKey) {
        case 'temp':
            max = 25
            min = 15
            keyStr = 'temperature'
            break
        case 'humid':
            max = 100
            min = 0
            keyStr = 'humidity'
            break
        case 'co2':
            max = 2000
            min = 0
            keyStr = 'co2'
            break
        case 'pm25':
            max = 20
            min = 0
            keyStr = 'pm2_5'
            break
        case 'h2s':
            max = 15
            min = 0
            keyStr = 'h2S'
            break
        case 'so2':
            max = 20
            min = 0
            keyStr = 'sO2'
            break
    }
    let dataArray = []
    if(StatisticData != null && StatisticData[typeKey] != null) {
        dataArray = StatisticData[typeKey].map(item => {
            let timeValue =  new Date(item['time'])
            let shiftValue = timeValue.setHours(timeValue.getHours()+8)
            return [
                shiftValue,
                item[keyStr],
            ]
        })
    }

    if(dataArray == null || dataArray.length == null || dataArray.length == 0) return <div className="ToiletAdminIAQChartLoading">
        <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
    </div>
    
    let options = {
        title:{
            text:''
        },
        chart: {
            type: 'area',
            backgroundColor: 'transparent',
            zoomType: 'x'
        },
        xAxis: {
            type: 'datetime',
        },
        yAxis: {
            min: min,
            max: max,
            title: {
                text: ''
            },
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            series: {
                series: {
                    stickyTracking: false
                },
                fillColor: {
                    linearGradient : {
                        x1: 0,
                        y1: 0,
                        x2: 1,
                        y2: 0
                    },
                    stops: [
                        [0, color1],
                        [1, color2]
                    ]
                },
                lineWidth: 0,
                marker: {
                    enabled: false,
                    fillColor: markercolor
                }
            }
        },
        series: [{
            type: 'areaspline',
            name: 'Loc 1',
            data: dataArray,
        }],
    }

    const item_H = window.screen.width>1280?'100%':'90%'
    return <div className="Toilet_HighchartWidget">
        <HighchartsReact highcharts={Highcharts} options={options} constructorType={'chart'} className=""
                    containerProps={{ style: { height: item_H } }}/>
    </div>
}

function mapStateToProps(state) {
    return {
        StatisticData: state.StatisticData,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate
    }
}
export default connect(mapStateToProps)(HighchartWidget)