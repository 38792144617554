import React from 'react';
import './loading.css';

//Redux Import
import { connect } from 'react-redux';

class Loading extends React.Component {
    constructor(props)
    {
        super();

        this.state = {
            IsTimeout: false
        };
    }

    render()
    {
        let {DeviceDataStatus} = this.props;
        if(DeviceDataStatus == 4)
        {
            return <div className="loadingUIMainDiv">
                <div className="">
                    <div className="loadingIconAnimate loadingTxtMarginTop">
                        <i className="fa fa-exclamation-circle"></i>
                        <br></br>
                        No Response
                    </div>
                </div>
            </div> 
        }
        else if(DeviceDataStatus == 2)
        {
            return <div className="loadingUIMainDiv">
                <div className="">
                    <div className="loadingIconAnimate loadingTxtMarginTop">
                        <i className="fa fa-exclamation-circle"></i>
                        <br></br>
                        No Reading
                    </div>
                </div>
            </div> 
        }
        else if(DeviceDataStatus == 1)
        {
            return <div className="loadingUIMainDiv">
                <div className="loadinginnerContainerDiv">
                    <div className="loadingIconAnimate">
                        <i className="fa fa-spinner fa-spin loadingUIIcon"></i>
                    </div>
                </div>
            </div>
        }
        else
        {
            return <div className="loadingUIMainDiv">
                <div className="loadinginnerContainerDiv">
                    <div className="loadingIconAnimate">
                        {DeviceDataStatus}
                    </div>
                </div>
            </div>   
        }
    }
}

function mapStateToProps(state) {
    return {
      socket: state.socket,
      level: state.level,
      currentlevel: state.currentlevel,
      currentSite: state.currentSite,
      MenuDisplay: state.MenuDisplay,
      DeviceDataStatus: state.DeviceDataStatus
    };
}
  
export default connect(mapStateToProps)(Loading);