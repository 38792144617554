import React from 'react'
import './searchbar.css'
import { connect } from 'react-redux'

class Searchbar extends React.Component {
    constructor(props) {
        super()
        this.state = {
            selectedColdboxID: null,        // null or string
            isGroupDisplay: false,
            selectedGroup: null,            //null or string
        }
        this.ColdBoxIDSelect = this.ColdBoxIDSelect.bind(this)
        this.RequestGroupList = this.RequestGroupList.bind(this)
        this.ColdboxGroupSelect = this.ColdboxGroupSelect.bind(this)
    }

    componentDidMount() {
        const _this = this
        const {DashboardPickedColdboxID} = this.props
        let pathUrl = "/Main/FloorPlan"
        this.props.dispatch({ type: 'LatestPage', data: pathUrl })
        let storedColdboxID = localStorage.getItem('selectedColdboxID')
        if(storedColdboxID != null && storedColdboxID != '') {
            _this.setState({
                selectedColdboxID: storedColdboxID
            })
        }

        this.RequestGroupList()
    }
    ColdBoxIDSelect(event) {
        const {currentSite, currentlevel, DeviceData} = this.props
        const _this = this
        let selectedID = event.target.value

        console.log(selectedID)
        const CurrentFloorItem = DeviceData[currentSite].nestedLocs.find(function (floorItem) { return floorItem.locName == currentlevel })

        const CurrentDeviceList = CurrentFloorItem.nestedLocs

        if (selectedID !== null || selectedID !== undefined) {
            const ColdBoxItem = CurrentDeviceList.find(function (coldBoxItem) {
                return (coldBoxItem.payload !== null &&
                    coldBoxItem.locName === selectedID)
            })
            
            console.log(ColdBoxItem)
            this.props.setItem(ColdBoxItem)
            _this.setState({
                selectedColdboxID: selectedID
            })
            localStorage.setItem("selectedColdboxID", selectedID)
        }

        this.setState({
            isGroupDisplay: false
        })
        if(selectedID=='all') {
            _this.props.selectGp(null, true)
            return
        }
        this.props.selectGp(null, false)
    }
    RequestGroupList() {
        const {UserInfo} = this.props
        if(UserInfo == null) return
        const EmitJson = {
          "msgType": "GetGroupList",
          "userID": UserInfo.userID,
          "client_id": UserInfo.client_id,
        }
        console.log(EmitJson)
        this.props.dispatch({
          type: 'EmitSocket',
          EmitSocketName: "StatRequest",
          EmitSocketData: EmitJson
        })
    }
    ColdboxGroupSelect(event) {
        const _this = this
        if(event==null||event.target==null||event.target.value==null) return
        const selectedGp = event.target.value
        _this.setState({
            isGroupDisplay: true,
            selectedGroup: selectedGp
        })

        // update the group filter str in parent
        if(selectedGp=='all') {
            _this.props.selectGp(null, true)
            return
        }
        this.props.selectGp(selectedGp, true)
    }
    render() {
        const _this = this
        const {UserInfo, currentSite, currentlevel, GroupList} = this.props
        const {selectedColdboxID, selectedGroup, isGroupDisplay} = this.state
        let coldBoxList = []
        try {
            coldBoxList = getColdboxInGroup(GroupList, UserInfo, currentSite, currentlevel, selectedGroup, isGroupDisplay)
        }
        catch (err) {
            return <div>Some error happened</div>
        }
        const groupList = GetGroupList(GroupList)
        return <div className="Coldbox_S_header">
            <div className="Right">
                <div className="Coldbox_BackBtn" onClick={this.props.backpage}>
                    <div className="fa fa-arrow-left"></div>
                </div>
                Coldbox Status
                {/* <div className="Coldbox_Tab selected">Status Screen</div>
                <div className="Coldbox_Tab" onClick={this.props.groupDiffpage}>Group Difference</div> */}
            </div>
            <div className="Left">
                <div className="Coldbox_S_title">Coldbox Group</div>
                <select className="cdbxSlt" onChange={_this.ColdboxGroupSelect}>
                    <option value="all">All Groups</option>
                    {
                        groupList.map(item=> {
                            return <option value={item.name}>{item.name}</option>
                        })
                    }
                </select>
                <div className="Coldbox_S_title">Search</div>
                <input className='cdbxSlt' type="search" placeholder='search colbox' list='coldboxList' onChange={this.ColdBoxIDSelect}></input>
                <datalist id="coldboxList">
                    {
                        coldBoxList==null==coldBoxList==undefined||coldBoxList.length==null?
                        <option>No Colbox in this group</option>
                        :
                        coldBoxList.map((item) => {
                            return <option value={item.locName}></option>
                        })
                    }
                </datalist>
                <div className="Coldbox_S_title">Coldbox ID</div>
                <select className="cdbxSlt" onChange={this.ColdBoxIDSelect} value={selectedColdboxID}>
                    <option value='all'>All</option>
                    {
                        coldBoxList.map(function (item) {
                            return <option value={item.locName}>
                                {item.locName}
                            </option>
                        })
                    }
                </select>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        UserInfo: state.UserInfo,
        currentlevel: state.currentlevel,
        currentSite: state.currentSite,
        DeviceData: state.DeviceData,
        DashboardPickedColdboxID: state.DashboardPickedColdboxID,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate,
        GroupList: state.GroupList,
    }
}

export default connect(mapStateToProps)(Searchbar)

function GetGroupList(GroupList) {
    if(GroupList==null||GroupList.groups==null) return []
    const returnList = GroupList.groups.map(item => {
        const locList = item.location[0].map(locItem=> {
            return {
                locName: locItem.locName,
                _id: locItem._id
            }
        })
        return {
            name: item.group_name,
            locations: locList
        }
    }).sort(function (a, b) {
        if (a.name > b.name) return 1
        if (b.name > a.name) return -1
        return 0
    })
    return returnList
}
function getColdboxInGroup(GroupList, UserInfo, currentSite, currentlevel, selectedGroup, isGroupDisplay) {
    if(GroupList==null||GroupList.groups==null) return []
    if(UserInfo==null||currentSite==null||currentlevel==null) return []

    let coldBoxList = []

    // individual display (all items in same floor)
    if(isGroupDisplay == false) {
        let curSite = UserInfo.locations[currentSite]
        if (curSite !== null && curSite !== undefined) {
            let floor_items = curSite.nestedLocs.find(function (floorItem) { return floorItem.locName == currentlevel })
            coldBoxList = floor_items.nestedLocs
        }
        return coldBoxList
    }

    // group display
    if(selectedGroup=='all') {
        // show all coldbox items in group
        UserInfo.locations.forEach(siteItem => {
            siteItem.nestedLocs.forEach(lvlItem => {
                lvlItem.nestedLocs.forEach(locItem => {
                    coldBoxList.push(locItem)
                })
            })
        })
    }
    else {
        // show coldbox in the selected group
        coldBoxList = getcoldboxListFromGroup(GroupList, selectedGroup)
    }
    return coldBoxList
}

function getcoldboxListFromGroup(GroupList, selectedGroup) {
    if(GroupList == null || GroupList.groups == null) return []
    const grouplist = GroupList.groups
    const groupItem = grouplist.find(item => {
        return item.group_name == selectedGroup
    })
    const locList = groupItem.location
    let coldboxList = []
    locList.forEach(locItem => {
        locItem.forEach(innerLoc => {
            coldboxList.push(innerLoc)
        })
    })

    return coldboxList
}