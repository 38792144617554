import React, {useState} from 'react'
import './optionpopup.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCogs, faTimes } from '@fortawesome/free-solid-svg-icons'

import { CSVLink, CSVDownload } from "react-csv"

function OptionPopupWidget(props) {
    const [isOpen, setisOpen] = useState(false)
    const [localcsvData, setcsvData] = useState([])
    const {csvData, LiftAlarmStatistic} = props

    function OpenPopup() {
        //update state to open popup
        setisOpen(true)

        //set csv data
        //LiftAlarmStatistic
        const AlarmRawData = (LiftAlarmStatistic==null||LiftAlarmStatistic.data==null)?[]:LiftAlarmStatistic.data
        let csvRowRecords = csvData
        Object.keys(AlarmRawData).forEach(k => {
            const LiftList = getUniqStrList(AlarmRawData[k], 'locationName')
    
            LiftList.forEach(LiftStr => {
                const FilteredList = LiftAlarmStatistic.data[k].filter(item => {
                    return item.locationName == LiftStr
                })
                console.log(FilteredList)
                let TotalCount = 0
                FilteredList.forEach(recordItem => {
                    TotalCount += recordItem[k]
                })
                csvRowRecords.push([
                    LiftStr,
                    k,
                    TotalCount
                ])
            })
        })
        csvRowRecords = csvRowRecords.sort(function(a, b) {
            return a.count - b.count
        })
        //csvData
        setcsvData(csvRowRecords)
    }

    return !isOpen?
    <div className="OptionPopup_btn" onClick={OpenPopup}>
        <FontAwesomeIcon icon={faCogs} className="fa" />
    </div>
    :
    <div className="OptionPopup_popup" key="optionpopup">
        <div className="Opt_popup_head">
            <div className="Opt_popup_headtxt">Options</div>
            <div className="Opt_popup_headclose" onClick={() => setisOpen(false)}>
                <FontAwesomeIcon icon={faTimes} className="fa" />
            </div>
        </div>
        <div className="Opt_popup_bottom">
            <fieldset className="Opt_popup_Legend">
                <legend>Color (Frequent):</legend>
                <div className="Opt_popup_LegendRow VHigh">
                    {"Very High (>100)"}
                </div>
                <div className="Opt_popup_LegendRow High">
                    {"High (>50)"}      
                </div>
                <div className="Opt_popup_LegendRow">
                    Normal
                </div>
            </fieldset>
            <div className="Opt_popup_Excel">
                <CSVLink data={localcsvData} filename={"Alarm Report.csv"} className="">
                    Download CSV
                </CSVLink>
            </div>
        </div>
    </div>
}

export default OptionPopupWidget


function getUniqStrList(ArrayList, keystr) {
    const MapStrList = ArrayList.map((item) => {
        return item[keystr]
    })
    return MapStrList.filter(onlyUnique)
}
function onlyUnique(value, index, self) {
    return self.indexOf(value) === index
}