import React from 'react'
import Moment from 'react-moment'
import { connect } from 'react-redux'
import {getTxt, getLang} from '../common/language/language'

class NotificationItem extends React.Component {
    constructor(props) {
        super()
        this.state = {AlarmList: null}
        this.redirectNotPage = this.redirectNotPage.bind(this)
    }
    redirectNotPage() {        
        this.props.NotificationViewAll()
    }
    componentDidMount() {
        const {SystemAlerts} = this.props
        if(SystemAlerts == null || SystemAlerts == undefined) return
        this.setState({ AlarmList: SystemAlerts})
    }
    componentDidUpdate(preProps) { //updatet the this.state.AlarmList
        const { SystemAlerts } = this.props
        const {AlarmList} = this.state
        if(SystemAlerts == null || SystemAlerts == undefined || AlarmList == SystemAlerts) return
        this.setState({AlarmList: SystemAlerts})
    }

    render() {
        const _this = this
        const {AlarmList} = this.state  //json list format
        const lang = getLang()

        let AlarmListArray = []         //array format
        let isAlarmListExist = false
        try {
            if(AlarmList != null) {
                const result = Object.keys(AlarmList).map((key) => [Number(key), AlarmList[key]])
                console.log(result)
                if(result != null || result.length != null || result.length != 0) isAlarmListExist = true
                result.forEach(resultItem => {
                    if(resultItem[1].client_id.toString() ==="6006850161a378359cb738bf" ) {     //CPSL
                        if(resultItem[1].severity !== "Low" && resultItem[1].isClear == false) AlarmListArray.push(resultItem[1]) // Do not show event if low severity
                    }
                    else {
                        AlarmListArray.push(resultItem[1])
                    }
                })
            }
        }
        catch(err) {
            isAlarmListExist = false
            console.log(err)
        }

        if(AlarmList == null) return <div className="popup_notification_loading"></div>

        if(!isAlarmListExist) return <div className={"flex flex-row navbar_popup_notification_item"} key={ "noNewAlarm" }>No New Alarm</div>

        //let deviceName = ""
        let countAlarm = 0     //count++, show first 10 record only 
        return <div className="popup_notification_listContainer">
        {
            AlarmListArray.map((result, i) => {
                let iconClass = ""
                let iconColorClass = ""
                //deviceName = result.name
                //switch the icon class
                switch(result.alarmComponent.type) {
                    case "Device":
                        iconClass = "fa fa-tasks"
                    break
                    case "Equipment":
                        iconClass = "fa fa-bell"
                        break
                    case "System":
                        iconClass = "fa fa-database"
                        break
                    default:
                        iconClass = "fa fa-info"
                        break
                }
                //switch the color class
                if(result.isClear == null) iconColorClass = "AlarmList1"
                else iconColorClass = "AlarmList3"
                countAlarm++
                if(countAlarm > 10) return <div></div>          //show latest 10 records only

                return <div className={"flex flex-row navbar_popup_notification_item "+iconColorClass} key={ result.alarmComponent.id} onClick={this.redirectNotPage}>
                    <div className={"nav_alarm_icon"}>
                        <div className={iconClass}></div>
                    </div>
                    <div className="nav_alarm_2rowbody">
                        <div className="navbar_alarm_name">
                            {AlarmTitleLang(result, lang)}
                        </div>
                        <div className="navbar_alarm_location">
                            {
                                result.locationInfo.map((locationItem, i) => {
                                    return " " + AlarmLocLang(locationItem, lang, _this.props) + " "
                                })
                            }
                        </div>
                        <div className="navbar_alarm_datetime">
                            <Moment format="YYYY/MM/DD HH:mm:ss">{ result.createdTime }</Moment>
                        </div>
                    </div>
                </div>
            })
        }       
        </div>
    }
}

function mapStateToProps(state) {
    return {
        UserInfo: state.UserInfo,
        Notification: state.Notification,
        SystemAlerts: state.SystemAlerts,
        DeviceData: state.DeviceData,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate
    }
}
export default connect(mapStateToProps)(NotificationItem)

function AlarmTitleLang(alarmItem, lang) {
    if(alarmItem==null) return ''

    if(lang=='eng'||alarmItem['name_zh-hk'] == null) return alarmItem.name
    return alarmItem['name_zh-hk']
}
function AlarmLocLang(locItem, lang, props) {
    if(locItem==null) return ''

    if(lang=='eng') return locItem.name
    return locChinTxt(locItem.name, locItem.lvl, props)
}

function locChinTxt(name, lvl, props) {
    if(name == null || lvl == null || props == null) return '-'
    const {DeviceData} = props
    if(DeviceData==null) return '--'

    if(lvl==1) {
        let returnStr = ''
        DeviceData.forEach(siteItem => {
            if(siteItem.locName==name) returnStr = siteItem['locName_zh-hk']
        })
        return returnStr
    }
    if(lvl==2) {
        let returnStr = ''
        DeviceData.forEach(siteItem => {
            siteItem.nestedLocs.forEach(lvlItem => {
                if(lvlItem.locName==name) returnStr = lvlItem['locDesc_zh-hk']
            })
        })
        return returnStr + '/F'
    }
    if(lvl==3) {
        let returnStr = ''
        DeviceData.forEach(siteItem => {
            siteItem.nestedLocs.forEach(lvlItem => {
                lvlItem.nestedLocs.forEach(locItem => {
                    if(locItem.locName==name) returnStr = locItem['locName_zh-hk']
                    if(locItem.locName==name && locItem.locUI != null && locItem.locUI['desc_zh-hk'] != null) returnStr = locItem.locUI['desc_zh-hk']
                })
            })
        })
        return returnStr
    }
    return '---'
}