import React from 'react'
import {connect} from 'react-redux'
import './OEQ.scss'

const OEQ = (props) => {
    const locData = getLocsInLevel(props)
    const related = relatedData(locData)
    const result = countData(related)

    if(related==null||related.length==null||related.length==0) return <div className='OEQ'>
        <div className='head'>OEQ</div>
        <div className='msg'>No OEQ Data</div>
    </div>

    return <div className='OEQ'>
        <div className='head'>OEQ</div>
        {
            result.sort((a, b) => {
                if (a.key < b.key) return -1
                if (a.key > b.key) return 1
                return 0
            })
            .map(item => {
                const {key, name, decimal, sum, count, list, unit} = item

                if(decimal=='string') {
                    return <div className='row'>
                        <div className='title'>{name}</div>
                        <div className='value'>
                            {
                                list.filter(onlyUnique).map(strItem => {
                                    return <div className='direction'>{strItem}</div>
                                })
                            }
                        </div>
                    </div>
                }
                return <div className='row'>
                    <div className='title'>{name}</div>
                    <div className='value'>
                        {(sum/count).toFixed(decimal)}
                        {
                            unit==null?
                            <div style={{display: 'none'}}></div>
                            :
                            <div className='unit'>{unit}</div>
                        }
                    </div>
                </div>
            })
        }
    </div>
}

function mapStateToProps(state) {
    return {
      currentlevel: state.currentlevel,
      currentSite: state.currentSite,
      DeviceData: state.DeviceData,
      DeviceDataLastUpdate: state.DeviceDataLastUpdate
    }
}
export default connect(mapStateToProps)(OEQ)

const getLocsInLevel = (props) => {
    const {currentSite, currentlevel, DeviceData} = props
    if(currentSite==null||currentlevel==null||DeviceData==null) return []

    const siteItem = DeviceData[currentSite]
    if(siteItem==null) return []

    const levelItem = siteItem.nestedLocs.find(item => {
        return item.locName == currentlevel
    })
    if(levelItem==null) return []

    return levelItem.nestedLocs
}
const relatedData = (locData) => {
    return locData.filter(item => {
        let exist = false
        relatedDataKey.forEach(nameID => {
            if(nameID == item.payload_definition_id.nameID) exist = true
        })
        return exist
    })
}
const relatedDataKey = [
    'Outdoor Environment Quality (Wind)',
    'Outdoor Environment Quality'
]
const countData = (locData) => {
    let returnData = []
    dataConfig.forEach(item => {
        let dataItem = {
            key: item.key,
            name: item.name,
            decimal: item.decimal,
            unit: item.unit,
            sum: 0,
            count: 0,
            list: []
        }
        locData.forEach(locItem => {
            if(locItem.payload[item.key] != null) {
                dataItem.sum = dataItem.sum + locItem.payload[item.key]
                dataItem.count = dataItem.count + 1
                dataItem.list.push(locItem.payload[item.key])
            }
        })

        returnData.push(dataItem)
    })

    return returnData
}
const dataConfig = [
    {
        key: 'temperature',
        name: 'Temperature',
        decimal: 1,
        unit: '°C'
    },
    {
        key: 'humidity',
        name: 'Humidity',
        decimal: 1,
        unit: '%'
    },
    {
        key: 'co2',
        name: 'CO2',
        decimal: 1,
        unit: 'ppm'
    },
    {
        key: 'windDirection',
        name: 'Wind Direct',
        decimal: 'string',
        unit: null
    },
    {
        key: 'windSpeed',
        name: 'Wind Speed',
        decimal: 1,
        unit: 'km/hr'
    }
]
function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}