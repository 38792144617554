import React from 'react'
import './searchbar.css'
//Redux Import
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker';
import moment from 'moment';

class Searchbar extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            startdate: new Date(moment().subtract(1, 'months')),
            enddate: new Date(),
            //option list
            groupList: null,
            siteList: null,
            floorList: null,
            typeList: ['Fault', 'Warning'],
            statusList: ['Closed', 'Open', 'Acknowledged', 'Auto-resumed'],
            //filter current values (null or empty str = no filter)
            filterTxt: '',
            filterGroup: '',
            filterSite: '',
            filterLevel: '',
            filterStatus: '',
            filterType: '',
            filterFrom: '',
            filterTo: '',
            //Filter Result
            Filtered: null,

            isLoading: false,
        }

        this.GenOptions = this.GenOptions.bind(this)
        this.FilterOnchange = this.FilterOnchange.bind(this)
        this.Filter = this.Filter.bind(this)
        this.setStartTime = this.setStartTime.bind(this)
        this.setEndTime = this.setEndTime.bind(this)

        this.refresh = this.refresh.bind(this)
    }

    componentDidMount() {
        var { coldboxList } = this.props
        var {startdate, enddate} = this.setState
        // console.log(coldboxList)
        if (coldboxList == null || coldboxList == undefined) return
        if(startdate === null){
            let temp = enddate
            temp.setDate(enddate.getMonth -1)
            this.setState(prevState => ({
                ...prevState,
                startdate: temp, 
            }))
        }
        this.GenOptions()
    }

    componentDidUpdate(preProps) {
        //Re-gen option list when data is updated
        var { coldboxList } = this.props 
        // console.log(coldboxList)
        var oldcoldboxList = preProps.coldboxList
        if (coldboxList != oldcoldboxList || (coldboxList != null && oldcoldboxList == null)) this.GenOptions()
    }
    refresh() {
        const _this = this
        this.setState({isLoading: true})
        this.props.refresh()
        setTimeout(() => {
            _this.setState({isLoading: false})
        }, 2000)
    }
    GenOptions() {
        const { coldboxList, UserInfo,currentSite } = this.props
        if (coldboxList === null || coldboxList === undefined) {
            return
        } else {
            var locationInfo = UserInfo.locations[currentSite].nestedLocs
            var Options = coldboxList.map(function (coldboxItem, index) {
                // console.log(coldboxItem)
                const { group, site, floor, type, status, action } = coldboxItem
                return {
                    group: group,
                    site: site,
                    level: floor,
                    id: index,
                    type: type,
                    status: status,
                    action: action
                }
            })
            // console.log(Options)

            //Group List
            var GroupList = Options.map(item => item.group)
                .filter((value, index, self) => self.indexOf(value) === index)
            GroupList = GroupList.sort()
            //Site List
            var SiteList = Options.map(item => item.site)
                .filter((value, index, self) => self.indexOf(value) === index)
            SiteList = SiteList.sort()
            //Level List
            // var levelList = Options.map(item => item.level)
            //     .filter((value, index, self) => self.indexOf(value) === index)
            // levelList = levelList.sort()
             var levelList = locationInfo
            //  .map(item => item.locName)
            // .filter((value, index, self) => self.indexOf(value) === index)
        levelList = levelList.sort()
            //Type List
            var typeList = Options.map(item => item.type)
                .filter((value, index, self) => self.indexOf(value) === index)
            typeList = typeList.sort()
            //Status List
            var statusList = Options.map(item => item.action)
                .filter((value, index, self) => self.indexOf(value) === index)
            statusList = statusList.sort()

            this.setState({
                groupList: GroupList,
                siteList: SiteList,
                floorList: levelList,
                Filtered: coldboxList,
                typeList: typeList,
                statusList: statusList
            })
            this.Filter()
        }
    }
    FilterOnchange(keyStr, e) {
        try {
            
            if(keyStr ==="filterLevel"){  
                let obj = this.state.floorList.find(o =>o._id===e.target.value) 
                const id = e.target.value.toString() 
                const name = obj.locName.toString()  
                this.setState({
                    [keyStr]: name,
                }, function () {        //run right after state is set
                    this.Filter()       //re-filter
                })
            }else{
                const value = e.target.value.toString()
                // console.log(e.target)
                this.setState({
                    [keyStr]: value,
                }, function () {        //run right after state is set
                    this.Filter()       //re-filter
                })
            }
        }
        catch (err) {
            console.log(err)
        }
    }
    Filter() {
        const { filterTxt, filterGroup, filterType, filterLevel, filterStatus,
            statusList, floorList, typeList } = this.state
        const { coldboxList } = this.props

        var FilteredList = coldboxList
        if (filterTxt != null && filterTxt != "") {
            FilteredList = FilteredList.filter(function (coldboxItem) {
                return coldboxItem.keywords.includes(filterTxt.toString())
            })
        }
        if (filterGroup != null && filterGroup != "") {
            FilteredList = FilteredList.filter(function (coldboxItem) {
                return coldboxItem.group == filterGroup.toString()
            })
        }
        if (filterType != null && filterType != "") {
            FilteredList = FilteredList.filter(function (coldboxItem) {
                return coldboxItem.type == filterType.toString()
            })
        } 
        if (filterLevel != null && filterLevel != "") {
            FilteredList = FilteredList.filter(function (coldboxItem) { 
                return coldboxItem.floor.toString() == filterLevel.toString()
            })
        }
        if (filterStatus != null && filterStatus != "") {
            // console.log(filterStatus)
            FilteredList = FilteredList.filter(function (coldboxItem) { 
                return coldboxItem.action.toString() == filterStatus.toString()
            })
        }

        // console.log({
        //     coldboxList: coldboxList,
        //     FilteredList: FilteredList,
        // })
        this.setState({
            Filtered: FilteredList
        })
        this.props.UpdateList('filteredList', FilteredList)
    }

    setStartTime(data) {
        this.setState({ startdate: data })
        this.props.setStartTime(data)
    }

    setEndTime(data) {
        this.setState({ enddate: data })
        this.props.setEndTime(data)
    }

    render() {
        const _this = this
        const { isAdvanceSearch } = this.props

        const { groupList, siteList, floorList, statusList, typeList,
            filterTxt, filterGroup, filterSite, filterLevel, filterStatus, filterType, isLoading } = this.state

        return <div className="CBAudit_head">
            <div className="CBAudit_txt">From:</div>
            {/* <input className="CBAudit_input" placeholder="Start Date Time"></input> */}
            <div className="CBAudit_datepick">
                <DatePicker
                    selected={this.state.startdate}
                    onChange={this.setStartTime}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="d-MMMM yyyy HH:mm"
                    timeCaption="time"
                />
            </div>
            <div className="CBAudit_txt">To:</div>
            {/* <input className="CBAudit_input" placeholder="End Date Time"></input> */}
            <div className="CBAudit_datepick">
                <DatePicker
                    selected={this.state.enddate}
                    onChange={this.setEndTime}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="d-MMMM yyyy HH:mm"
                    timeCaption="time"
                />
            </div>
            {
                isAdvanceSearch ?
                    <div className="CBAudit_hiddenSearches"> 
                        
                        <div className="CBAudit_txt">Keyword</div>
                        <input className="CBAudit_input" placeholder="Keyword search" onChange={(e) => _this.FilterOnchange('filterTxt', e)}></input>
                       
                        <div className="CBAudit_txt">Status</div>
                        <select className="CBAudit_select" onChange={(e) => _this.FilterOnchange('filterStatus', e)}>
                            <option value="">All</option>
                            {
                                (statusList == null || statusList.length == null || statusList.length == 0) ?
                                    <div style={{ display: 'none' }}></div>
                                    :
                                    statusList.map(function (statusItem) {
                                        return <option value={statusItem}>{statusItem}</option>
                                    })
                            }
                        </select>
                        <div className="CBAudit_adSearchBtn" onClick={_this.props.closeAdSearch}>
                            <div className="fa fa-times"></div>
                        </div>
                    </div>
                    :
                    <div className="CBAudit_adSearchBtn" key="advnaceBtn" onClick={_this.props.AdvanceSearch}>Advanced Search</div>
            }
            <div className="CBAudit_searchBtn">
                <div className={isLoading?"fa fa-refresh fa-spin":"fa fa-refresh"} onClick={_this.refresh}></div>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        UserInfo: state.UserInfo,
        currentSite: state.currentSite,
    }
}
export default connect(mapStateToProps)(Searchbar)