import React from 'react'
import './OperationOverview.css'
import { connect } from 'react-redux'
//import inner widgets
import Headbar from './headbar/headbar'
import StatusDisplay from './statusdisplay/statusdisplay'
import TableItem from './table/table'
import OperOverWidget from './panel/panel'
import SearchInput from './searchinput/searchinput'

class OperationOverview extends React.Component {
    constructor(props) {
        super()

        this.state = {
            ServerStatus: {
                IoT: 1,                 //1 = Healthy, 2 = Unstable, 3 = Unhealty
                CPSL: 2,                //1 = Healthy, 2 = Unstable, 3 = Unhealty
                LoRa: 3,                //1 = Healthy, 2 = Unstable, 3 = Unhealty
            },
            optionOpenID: null,         //open which option droplist of coldbox

            MaxTableList: null,         //null = show 4 table, 1-4 means show 1 table

            filterSite: 'All',
            filterLevel: 'All',
            selectedColdbox: null,
        }

        this.backBtn = this.backBtn.bind(this)
        this.OptionDroplist = this.OptionDroplist.bind(this)

        this.ShowFullTable = this.ShowFullTable.bind(this)
        this.SetFilterSiteLevel = this.SetFilterSiteLevel.bind(this)
        this.SetSelectedColdbox = this.SetSelectedColdbox.bind(this)

        this.OnloadLoop = {}            //parameter to store loop interval function
    }
    SetFilterSiteLevel(Site, Level) {
        this.setState({
            filterSite: Site,
            filterLevel: Level,
        })
    }
    SetSelectedColdbox(coldboxItem) {
        this.setState({selectedColdbox: coldboxItem})
    }
    backBtn() {
        this.props.history.goBack()
    }

    OptionDroplist(coldboxID) {
        this.setState({
            optionOpenID: coldboxID
        })
    }
    ShowFullTable(tableID) {
        this.setState({
            MaxTableList: tableID
        })
    }

    render() {
        const _this = this
        const {ServerStatus, optionOpenID, MaxTableList, selectedColdbox} = this.state
        const {filterSite, filterLevel} = this.state
        const {DeviceData} = this.props

        return <div className="OperationOverview">
            <Headbar backBtn={this.backBtn} SetFilterSiteLevel={this.SetFilterSiteLevel} />
            <div className="OperOver_Main">
                <div className="OperOver_leftCol">
                    <TableItem card="1" filterSite={filterSite} filterLevel={filterLevel} optionOpenID={optionOpenID} OptionDroplist={this.OptionDroplist} selectedColdbox={selectedColdbox}
                        history={this.props.history} MaxTableList={MaxTableList} ShowFullTable={_this.ShowFullTable} />
                    <TableItem card="2" filterSite={filterSite} filterLevel={filterLevel} optionOpenID={optionOpenID} OptionDroplist={this.OptionDroplist} selectedColdbox={selectedColdbox}
                        history={this.props.history} MaxTableList={MaxTableList} ShowFullTable={_this.ShowFullTable} />
                    <TableItem card="3" filterSite={filterSite} filterLevel={filterLevel} optionOpenID={optionOpenID} OptionDroplist={this.OptionDroplist} selectedColdbox={selectedColdbox}
                        history={this.props.history} MaxTableList={MaxTableList} ShowFullTable={_this.ShowFullTable} />
                    <TableItem card="4" filterSite={filterSite} filterLevel={filterLevel} optionOpenID={optionOpenID} OptionDroplist={this.OptionDroplist} selectedColdbox={selectedColdbox}
                        history={this.props.history} MaxTableList={MaxTableList} ShowFullTable={_this.ShowFullTable} />
                    <SearchInput filterSite={filterSite} filterLevel={filterLevel} DeviceData={DeviceData} SetSelectedColdbox={this.SetSelectedColdbox} />
                </div>
                <div className="OperOver_rightCol">
                    <OperOverWidget />
                </div>
            </div>
            <StatusDisplay ServerStatus={ServerStatus} />
        </div>
    }
}

function mapStateToProps(state) {
    return {
        DeviceData: state.DeviceData
    }
}
export default connect(mapStateToProps)(OperationOverview)