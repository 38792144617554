import React from 'react';
import './AlarmTriggerDevice.css';
//Redux Import
import { connect } from 'react-redux';

class AlarmTriggerDevice extends React.Component {
    constructor(props)
    {
        super();

        this.ClearPickedDevices = this.ClearPickedDevices.bind(this);   //can clear from outside
        this.EditUpdatePickedDevices = this.EditUpdatePickedDevices.bind(this); //update from outside component when clicked on edit item
        this.pickedDevice = this.pickedDevice.bind(this);
        this.unPickDevice = this.unPickDevice.bind(this);

        this.state = {
            pickedList: []
        };
    }
    componentDidMount()
    {
        this.props.onRef(this);     //create ref so can call outside
    }
    componentWillMount()
    {
        this.props.onRef(undefined);    //create ref so can call outside
    }
    EditUpdatePickedDevices(DeviceList)
    {
        let formattedDeviceList = []
        DeviceList.forEach(DeviceItem => {
            //console.log(DeviceItem)
            let FormattedDeviceItem = {
                _id: DeviceItem.location_id,
                locLvl: DeviceItem.lvl,
                locName: DeviceItem.name,
                // device_id:{
                //     _id: DeviceItem.device_id,
                //     deviceName: DeviceItem.deviceName
                // }
            }
            formattedDeviceList.push(FormattedDeviceItem)
        })

        this.setState({
            pickedList: formattedDeviceList
        })
    }
    ClearPickedDevices()
    {
        //reset the picked Device List
        //this function also called from outside (popup.js)
        this.setState({
            pickedList: []
        });

        this.props.updateDevice([]);
    }
    pickedDevice(event)
    {
        const {Devicelist} = this.props
        //get the picked device .json
        //console.log(event.target.id);
        if(event.target == null)
        {
            console.error('event.target is null');
            return;
        }
        if(event.target.id == null)
        {
            console.error('event.target.id is null');
            return;
        }
        let deviceID_str = event.target.id.replace("devicecheckbox_", "");
        deviceID_str = deviceID_str.replace("deviceList_", "");
        //console.log({deviceID_str: deviceID_str});
        let pickedItem = Devicelist.find(deviceItem=>{
            return deviceItem._id == deviceID_str
        });
        //console.log(pickedItem);

        if(pickedItem == null)
        {
            console.error('Array.find function cannot get the Device from current List, which should never be happened');
            return;
        }
        const {pickedList} = this.state
        //check if the picked list already has that value
        let tryGetDuplicated = pickedList.find(newpickedItem => {
            return newpickedItem._id == pickedItem._id
        });
        if(tryGetDuplicated != null)
        {
            console.log('this item already picked!')
            console.log(tryGetDuplicated)
            return
        }
        //insert it into this.state.pickedList
        const newpickList = pickedList
        newpickList.push(pickedItem);
        this.setState({
            pickedList: newpickList
        })
        this.props.updateDevice(newpickList)
    }
    unPickDevice(unpickItem)
    {
        const {pickedList} = this.state
        /*console.log({
            pickedList: pickedList,
            unpickItem: unpickItem
        });*/
        let newPickedList = pickedList
        //remove that item from list
        newPickedList = newPickedList.filter(function(pickedItems, arr){
            return pickedItems.locName != unpickItem.locName
        })
        this.setState({
            pickedList: newPickedList
        })

        this.props.updateDevice(newPickedList)
    }
    render()
    {
        const {Devicelist} = this.props
        const {pickedList} = this.state
        const thisoutside = this
        //console.log(pickedList)
        return [
        <div className="Al_Trig_DevicePick_Container">
            {
                Devicelist.map(function(deviceItem){
                    return <div className="AL_Trig_DevicePick_checkContainer" onClick={thisoutside.pickedDevice}
                    id={"deviceList_" + deviceItem._id}>
                        <div type="checkbox" id={"devicecheckbox_" + deviceItem._id}>{deviceItem.locName}</div>
                    </div>
                })
            }
        </div>,
        <div className="Al_Trig_pickedTItle">
            Selected Devices: 
        </div>,
        <div className="Al_Trig_DevicePicked_OutsideContainer">
            <div className="Al_Trig_DevicePicked_Container">
                {
                    pickedList.map(function(deviceItem){
                        return <div className="AL_Trig_DevicePick_checkContainer">
                            <div type="checkbox">{deviceItem.locName}</div>
                            <div className="fa fa-times AL_Trig_CloseBtn" onClick={()=>thisoutside.unPickDevice(deviceItem)}></div>
                        </div>
                    })
                }
            </div>
        </div>
        ]
    }
}

//Redux inside component function
function mapStateToProps(state) {
    return {
      UserInfo: state.UserInfo
    };
}

export default connect(mapStateToProps)(AlarmTriggerDevice);