import React from 'react'
import './table.css'
//Redux Import
import { connect } from 'react-redux'
import Moment from 'react-moment'
import Loading from '../../../../loading'
import PopupNotification from '../../../../../popupNotification/PopupNotification'

class Table extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false        //loading effect on button
        } 
        this.acknowledge = this.acknowledge.bind(this)
    }

    acknowledge() {
        const _this = this
        console.log(this.props)
        //get request data
        const {UserInfo } = this.props
        const alarm = this.props.alarm 
        const UserID = UserInfo.userID
        const AlertID = alarm.alarmID
        const UserName = UserInfo.username
        // if(alarm.type !== "Fault") return
        //request json
        const requestData = {
            msgType: "UpdateAlarm",
			AlarmID: AlertID,	//acknowledging Alarm ID
			UserID: UserID,		//acknowledge person
            Username: UserName,	//acknowledge person
            AckEventName:alarm.name
        }
        console.log(requestData)
        //console.log(currentAlarm)
        //emit request
        this.props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "AlarmRequest",
            EmitSocketData: requestData
        })

        const thisoutside = this
        this.setState({
            isLoading: true
        })
        // setTimeout(() => {
        //     thisoutside.setState({
        //         isLoading: false
        //     })
        // }, 3000)
    }
    componentDidMount(){  
        // console.log(AlarmList)
    }
    render() {
        const _this = this
        const alarm = this.props.alarm 
         const {isLoading} = this.state
        if (alarm !== null && alarm !== undefined) {

            return <div className="CBEvent_tableRow" 
            // onClick={_this.acknowledge}
            >
                {/* <div className="col1">{(alarm.locationInfo !== null && alarm.locationInfo !== undefined? alarm.locationInfo[2].name: "")}</div>
            <div className="col1">{(alarm.name.includes("Fault")? "Fault": "Warning")}</div>
            <div className="col1">{(alarm.locationInfo !== null && alarm.locationInfo !== undefined?alarm.locationInfo[0].name:"")}</div>
                <div className="col2">{alarm.name}</div>
                <div className="col3">{alarm.message}</div>
                <div className="col2"><Moment className="" format="YYYY-MMM-DD HH:mm">{alarm.createdTime}</Moment></div>
                <div className="col1">Open</div>
                <div className="col1">-</div> */}
                <div className="col1">{(alarm.coldboxID)}</div>
                {/* <div className="col1">{(alarm.type)}</div> */}
                <div className="col1">{
                //alarm.location + '-' +
                alarm.floor}</div>
                <div className="col2">{alarm.name}</div>
                <div className="col3">{alarm.message}</div>
                <div className="col2"><Moment className="" format="YYYY-MMM-DD HH:mm">{alarm.createdTime}</Moment></div>
                <div className="col1">{alarm.status}</div>
                {/* <div className="col1">{alarm.remark}</div> */}
            </div>
        } else {
            return <div></div>
        }
    }
}

//Redux inside component function
function mapStateToProps(state) {
    return {

        UserInfo: state.UserInfo,
        socket: state.socket,
    }
}

export default connect(mapStateToProps)(Table)