import React from 'react'
import './alarmCountDisplay.css'
//Redux Import
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import {getTxt} from '../../common/language/language'

class AlarmCountDisplay2 extends React.Component {
    constructor(props) {
        super(props)
        this.NotificationPage = this.NotificationPage.bind(this)
        this.state = {
            redirectNoti: false
        }
    }

    NotificationPage()
    {
        return  //disabled fuction
        const {UserInfo} = this.props
        if(UserInfo == null) return
        if(UserInfo.userID == "5ed06c042f196f392848de97") return        //hardcode: this person cannot access to notification page
        //redirect to "/Main/Notification"
        this.setState({
            redirectNoti: true
        });
    }

    render() {
        const {redirectNoti} = this.state
        if(redirectNoti)
        {
            return <Redirect to='/Main/Notification' />
        }
        
        //get the current Alarm data
        // let alarmack = 0
        const {SystemAlerts} = this.props
        return <div className="AlarmCount_div">
            <div className="AlarmCount_ackalarms">
                <div className="AlarmCount_ackalarms_title">
                    {getTxt('Statistic', 'ackAlarm')}:
                </div>
                <div className="AlarmCount_ackalarms_readingMaindiv">
                    <div className="fa fa-check AlarmCount_ackalarms_reading_icon">
                    </div>
                    <div className="AlarmCount_ackalarms_reading_value">
                        {"--"}
                    </div>
                </div>
            </div>
        </div>
        if(SystemAlerts == null || SystemAlerts.length <= 0) return <div className="AlarmCount_div">
            <div className="AlarmCount_ackalarms" onClick={this.NotificationPage}>
                No Alarm
            </div>
        </div>     
        // try
        // {
        //     if(SystemAlerts == null || SystemAlerts.length <= 0)
        //     {
        //         console.log('there is no SystemAlerts alarmCountDisplay.js')
        //         alarmack = 0
        //     }
        //     else
        //     {
        //         const result = Object.keys(SystemAlerts).map((key) => [Number(key), SystemAlerts[key]])
        //         let AckAlarmList = result.filter(function(AlertItem){
        //             return AlertItem.isClear == true
        //         })
        //         if(AckAlarmList != null && AckAlarmList != undefined) alarmack = AckAlarmList.length
        //         else alarmack = 0
        //     }
        // }
        // catch(err)
        // {
        //     console.log(err);
        // }
        let alarmList = []
        const result = Object.keys(SystemAlerts).map((key) => [Number(key), SystemAlerts[key]])
        result.forEach(alarmItem => {
            alarmList.push(alarmItem)
        })
        return  <div className="AlarmCount_div">
            <div className="AlarmCount_ackalarms" onClick={this.NotificationPage}>
                <div className="widget2_AlarmList">
                    {
                        alarmList.map(function(alarmitem) {
                            try {
                                return <div className="widget2_alarmrow">
                                    <div className="widget2_alarmNumber">
                                        {
                                            alarmitem[0] + 1
                                        }
                                    </div>
                                    <div className="widget2_alarmName">
                                    {
                                        alarmitem[1].name
                                    }
                                    </div>
                                </div>
                            }
                            catch(err) {
                                return <div className="widget2_alarmrow">Exception</div>
                            }
                        })
                    }
                </div>
            </div>
        </div>        
    }
}

//Redux inside component function
function mapStateToProps(state) {
    return {
      SystemAlerts: state.SystemAlerts,
      history: state.history,
      socket: state.socket,
      level: state.level,
      currentlevel: state.currentlevel,
      currentSite: state.currentSite,
      MenuDisplay: state.MenuDisplay,
      SiteList: state.SiteList,
      currentBottomMenu: state.currentBottomMenu,
      currentBottomCard: state.currentBottomCard,
      UserInfo: state.UserInfo,
      DeviceDataLastUpdate: state.DeviceDataLastUpdate
    }
}

export default connect(mapStateToProps)(AlarmCountDisplay2)