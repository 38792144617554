import React, { useEffect, useState } from 'react'
import './TableRow.css'
import GetGroupValue from './groupFunc'

function TableRow(props) {
    const {DroplistSelect, InputTextChange} = props              //functions
    const {rowItem, displayData, groupDisplayData, selectedGroup, isGroupSetPt, DeviceData} = props

    const [tempV, setTempV] = useState(0)
    const [battV, setbattV] = useState(0)

    useEffect(() => {
        if(rowItem==null||rowItem.title==null) return
        const tempvalue = GetValueFromDeviceData(DeviceData, displayData, 'WeightedMeanTemp')
        setTempV(tempvalue)
        const battvalue = GetValueFromDeviceData(DeviceData, displayData, 'UPSBatteryCapacity')
        setbattV(battvalue)
    }, [displayData])  //onload function

    if(false) {
        return <div>All group is selected</div>
    }

    if(isGroupSetPt) {
        const getGroupData = {
            DeviceData: DeviceData,
            selectedGroup: selectedGroup,
            rowItem: rowItem
        }
        const value = GetGroupValue(getGroupData, groupDisplayData)
        // console.log(groupDisplayData)

        return <div className="ColdboxSetPt_row">
            <div className="ColdboxSetPt_rowtxt">{rowItem.title}</div>
            {
                (rowItem.type=='select')?
                <select className="ColdboxSetPt_rowselect" value={value!=null&&value.input!=null?value.input.value:value==null||value.data==null||value.data.length==null||value.data.length==0?'-1':value.data[0].value}
                 onChange={(e) => DroplistSelect(rowItem.key, e, true)}>
                    {
                        rowItem.display.map(function(displayItem) {                            
                            return <option value={displayItem.value}>{displayItem.name}</option>
                        })
                    }
                </select>
                :
                <input className="ColdboxSetPt_rowsinput" placeholder={rowItem.display} value={value.input} 
                type="number" onChange={(e) => InputTextChange(rowItem.key, e, true)}></input>
            }
            <div className="ColdboxSetPt_rowtxt">{rowItem.desc}</div>
            <div className="CdbxSetPt_preValue">
                <div className="value">
                {
                    value.type=='Number'?
                        value.min==value.max?
                            value.min
                            :
                            value.min + '-' + value.max
                    :
                        value.data==null||value.data==undefined?'':value.data.map(item => {
                            return item.name + ' '
                        })
                }
                </div>
            </div>
        </div>
    }

    return <div className="ColdboxSetPt_row">
        <div className="ColdboxSetPt_rowtxt">{rowItem.title}</div>
        {
            (rowItem.type=='select')?
            <select className="ColdboxSetPt_rowselect" value={displayData[rowItem.key]} onChange={(e) => DroplistSelect(rowItem.key, e)}>
                {
                    rowItem.display.map(function(displayItem) {
                        const value = displayData[rowItem.key]
                        if(value.toString() == displayItem.value.toString()) return <option value={displayItem.value} selected>
                            {displayItem.name}
                        </option>
                        return <option value={displayItem.value}>
                            {displayItem.name}
                        </option>
                    })
                }
            </select>
            :
            <input className="ColdboxSetPt_rowsinput" placeholder={rowItem.display} value={displayData[rowItem.key]} 
            type="number" onChange={(e) => InputTextChange(rowItem.key, e)}></input>
        }
        <div className="ColdboxSetPt_rowtxt">{rowItem.desc}</div>
        <div className="CdbxSetPt_preValue">
            <div className="value">
            {
                GetPreValue(DeviceData, displayData, rowItem.key)
            }
            </div>
        </div>
        {
            (rowItem.title=='Coldbox Temperature')?
            <div className="cdbxsetPt_temp">
                <div className="title">Mean Weighted Temp</div>
                <div className="value">
                    {tempV} C<sup>o</sup>
                </div>
            </div>
            :
            <div />
        }
        {
            (rowItem.title=='Temperature 1 Weighted Factor')?
            <div className="cdbxsetPt_temp">
                <div className="title">Battery Level</div>
                <div className="value">
                    {battV} %
                </div>
            </div>
            :
            <div />
        }
    </div>
}

export default TableRow

function GetValueFromDeviceData(DeviceData, displayData, input) {
    if(DeviceData===null||displayData===null) return 0
    const coldboxID = displayData.id

    let locPayloadData
    DeviceData.forEach(siteItem => {
        siteItem.nestedLocs.forEach(levelItem => {
            levelItem.nestedLocs.forEach(locItem => {
                if(coldboxID == locItem.locName) locPayloadData = locItem.payload
            })
        })
    })

    if(locPayloadData==null||locPayloadData==undefined) return 0

    return locPayloadData[input]
}

function GetPreValue(DeviceData, displayData, keyStr) {
    if(DeviceData===null||displayData===null) return 0
    const coldboxID = displayData.id
    const title = displayData.title

    let locStatus
    DeviceData.forEach(siteItem => {
        siteItem.nestedLocs.forEach(levelItem => {
            levelItem.nestedLocs.forEach(locItem => {
                if(coldboxID == locItem.locName) locStatus = locItem.payload
            })
        })
    })

    if(locStatus==null||locStatus==undefined) return 0

    const Value = (locStatus==null)?0:locStatus[ GetKey[keyStr] ]

    if(keyStr=='systemStart') return (Value==1||Value==true)?'Start':'Stop'
    if(keyStr=='status') {
        let returnValue
        switch(Value) {
            case '0':
                returnValue = 'Empty'
                break                
            case '1':
                returnValue = 'Pre-Cooling'
                break                
            case '2':
                returnValue = 'Loaded'
                break                
            case '3':
                returnValue = 'Damage'
                break                
            case 0:
                returnValue = 'Empty'
                break                
            case 1:
                returnValue = 'Pre-Cooling'
                break                
            case 2:
                returnValue = 'Loaded'
                break                
            case 3:
                returnValue = 'Damage'
                break
        }
        return returnValue
    }

    return Value==null||Value==undefined?'--':Value.toString()
}
const GetKey = {
    "systemStart": "SystemStart", //SystemEvent?
    "status": "Status",
    "temp": "TempSetPoint",
    "temp1Factor": "WeightedFactorTemp1",
    "temp2Factor": "WeightedFactorTemp2",
    "temp3Factor": "WeightedFactorTemp3",
    "temp4Factor": "WeightedFactorTemp4",
    "temp5Factor": "WeightedFactorTemp5",
    "returnAirFactor": "WeightedFactorReturnAirTemp",		//ReturnAirTemp?
    "highTemp": "TempHighSetPoint",
    "lowTemp": "TempLowSetPoint",
    "HCondPress": "CondensingPressureWarningHighSetPoint",
    "LCondPress": "EvaporatingPressureWarningLowSetpoint",
    "HRH": "RHWarningHighSetPoint",
    "LRH": "RHWarningLowSetPoint",
    "HSuctionTemp": "SuctionTempWarningHighSetPoint",
}