import React from 'react'
import './Table.css'
import { connect } from 'react-redux'

function Table(props) {
    let row = props.row

    return (
        <div className="wrap" >
            <div className="PR_TableRow">
                <div className="col1">{row.Name}</div>
                <div className="col2">{row.TotalDownTime}</div>
                <div className="col2">{row.CountWO}</div>
                <div className="col2">{row.CountFailure}</div>
                <div className="col2">{row.Availability}</div>
                <div className="col2">{row.Mtfb}</div>
                <div className="col2">{row.Mttr}</div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        UserInfo: state.UserInfo,
        socket: state.socket,
    }
}
export default connect(mapStateToProps)(Table) 