import React from 'react'
import './table.css'
//Redux Import
import { connect } from 'react-redux'
import Moment from 'react-moment'
import Loading from '../../../loading'
import PopupNotification from '../../../../popupNotification/PopupNotification'

class Table extends React.Component {
    constructor(props) {
        super(props) 
        this.state = {
            isLoading: false        //loading effect on button
        } 
     

    }

    componentDidMount(){ 
    }
    componentDidUpdate(){ 
 
        if(this.state.isLoading === true){
            this.setState({
                isLoading: false
            })
        }
        
    }

 
     
    render() { 
        const _this = this
        const data = this.props.data 
         const {isLoading} = this.state
        if(data !== null && data !== undefined){
 
        return <div>
            <div className="CBAudit_tableRow" >
            <div className="col1">{(data.username)}</div>
            <div className="col2">{(data.userLevel)}</div>
            <div className="col2">{(data.action)}</div>
            <div className="col2">{ClearUndefineString(data.actionDetail)}</div> 
            <div className="col2"><Moment className="" format="YYYY-MMM-DD HH:mm">{data.createdTime}</Moment></div> 
            <div className="col1">{data.remark}</div>   
        </div>
    
    {/* <PopupNotification onRef={ref => {this.PopupNotificationRef = ref}} /> */}
    </div>
        }else{
            return <div></div>
        }
     
       
    }
}

//Redux inside component function
function mapStateToProps(state) {
    return {
        UserInfo: state.UserInfo,
        socket: state.socket,

    }
}

export default connect(mapStateToProps)(Table)

function ClearUndefineString(strInput) {
    const outputStr = strInput.replace('undefined', '--')
    return outputStr
}