import React from 'react';
import './resetPassword.css';

import { connect } from 'react-redux';
import md5 from 'md5';

import RealTimeAlert from './Main/Navbar/RealTimeAlert';

class ResetPassword extends React.Component {
    constructor(props)
    {
        super(props);
        this.getTokenValue = this.getTokenValue.bind(this);
        this.socketListener = this.socketListener.bind(this);
        this.submitnewPassword = this.submitnewPassword.bind(this);
        this.checkPasswordValid = this.checkPasswordValid.bind(this);
        this.redirectHome = this.redirectHome.bind(this);

        this.closePopup = this.closePopup.bind(this);

        this.state = {
            token: "Reset Password Page",
            isPopupShow: false,
            PopupMsg: ""
        };

        this.passwordinput = React.createRef();
        this.resubmitPasswordinput = React.createRef();
    }
    componentDidMount()
    {
        this.props.dispatch({ type: 'SocketCon' }); //connect the socket
        //functions when socket is connected
        let thisoutside = this;
        setTimeout(function(){
            thisoutside.getTokenValue();
            thisoutside.socketListener();
        }, 1000);
    }
    closePopup()
    {
        this.setState({
            isPopupShow: false,
            PopupMsg: ""
        });
    }
    redirectHome()
    {
        //redirect to login page
        this.props.history.push("/");
    }
    checkPasswordValid()
    {
        //get password input
        let passwordInput = this.passwordinput.current.value;
        let resubpasswordInput = this.resubmitPasswordinput.current.value;
        let thisoutside = this;
        //check
        if(passwordInput != resubpasswordInput)
        {
            thisoutside.RealTimeAlertRef.newAlertNotification("Invalid", 
                "Password and resubmit password is different", "");
            return false;
        }
        if(passwordInput.length < 5)
        {
            thisoutside.RealTimeAlertRef.newAlertNotification("Invalid", 
                "Password should longer than 5 char.", "");
            return false;
        }
        return true;
    }
    submitnewPassword()
    {
        //check if the password is valid
        let isValid = this.checkPasswordValid();
        let thisoutside = this;
        if(!isValid)
        {
            return;
        }
        //hash the password (MD5)
        let passwordInput = this.passwordinput.current.value;
        let password_hash = md5(passwordInput);
        //create submit Json
        let requestData = {
            msgType: "ResetPassword",
            password: password_hash,
            token:  thisoutside.state.token
        };
        console.log(requestData);
        //submit to server with redux function
        this.props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "UserManageRequest",      //UserManageRequest can request for any type data
            EmitSocketData: requestData
        });

        this.RealTimeAlertRef.newAlertNotification("Submit", 
                "Submiting.. please wait..", "");
    }
    socketListener()
    {
        //create socket listener
        let {socket} = this.props;
        let thisoutside = this;
        if(socket != null)
        {
            if(socket.connected)
            {
                this.props.socket.on("ResetPassword", data => {
                    console.log(data);
                    if(data.result == "Success") //true / false
                    {
                        /*thisoutside.RealTimeAlertRef.newAlertNotification("Password Is Reset", 
                            "Now redirect to login page...", "");*/
                        thisoutside.setState({
                            isPopupShow: true,
                            PopupMsg: "Password is Reset!"
                        });
                    }
                    else
                    {
                        /*thisoutside.RealTimeAlertRef.newAlertNotification("Reset Password Failed", 
                        "Failde Message:" + data.failed, "");*/
                        thisoutside.setState({
                            isPopupShow: true,
                            PopupMsg: "Set Password Failed:" + data.failed + " " + data.result
                        });
                    }
                });   
            }
            else
            {
                thisoutside.RealTimeAlertRef.newAlertNotification("Disconnected to Server Side", 
                "Browser is disconnected to server side. Please try again", "");
            }
        }
    }
    getTokenValue()
    {
        //get the token
        let currentUrl = window.location.href;
        let splitStr = currentUrl.split("?token=");
        //set the token to this.state
        this.setState({
            token: splitStr[1]
        });
    }
    render()
    {
        let {token, isPopupShow, PopupMsg} = this.state;
        let PopupItem = <div></div>;
        let thisoutside = this;
        if(isPopupShow)
        {
            PopupItem = <div className={"ResetPwd_PopupBG"} onClick={thisoutside.closePopup}>
            <div className="ResetPwd_PopupContainer">
                <div className="ResetPwd_PopupMsg">
                    {PopupMsg}
                </div>
                <div className="ResetPwd_PopupCloseBtn" onClick={thisoutside.closePopup}>Close</div>
            </div>
        </div>
        }
        return [
            <div className="ResetPwd_Container">
                <div className="fa fa-long-arrow-left ResetePwd_backBtn" onClick={this.redirectHome}></div>
                {"Token: " + token}
                <div className="ResetPwd_innerContainer">
                    <input className="ResetPwd_Input" type="password" placeholder="New Password" ref={this.passwordinput}></input>
                    <input className="ResetPwd_Input" type="password" placeholder="Resubmit Password" ref={this.resubmitPasswordinput}></input>
                    <div className="ResetPwd_submitBtn" onClick={this.submitnewPassword}>Submit</div>            
                </div>
            </div>,
            <RealTimeAlert onRef={ref => (this.RealTimeAlertRef = ref)} />,
            PopupItem
        ]
    }
}

//Redux inside component function
function mapStateToProps(state) {
    return {
      socket: state.socket
    };
}

export default connect(mapStateToProps)(ResetPassword);