import React from 'react'
import './searchbar.css'
import { connect } from 'react-redux'

class Searchbar extends React.Component {
    componentDidMount() {
        let pathUrl = "/Main/FloorPlan";
        this.props.dispatch({ type: 'LatestPage', data: pathUrl })
    }

    render() {
        return <div className="Coldbox_S_header">
            <div className="Right">
                <div className="Coldbox_BackBtn" onClick={this.props.backpage}>
                    <div className="fa fa-arrow-left"></div>
                </div>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {}
}
export default connect(mapStateToProps)(Searchbar)