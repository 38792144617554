import React from 'react'
import './table.css'

import { connect } from 'react-redux'
import Moment from 'react-moment'

class Table extends React.Component {
    constructor(props) {
        super(props) 
        this.state = {
            isShowEditPopup:false
        } 
        this.updateEquipment = this.updateEquipment.bind(this)  
        this.openPopup = this.openPopup.bind(this)
    }

    updateEquipment() {
        this.setState(prevState => ({
            ...prevState
        }))
        const {UserInfo} = this.props 
        if (UserInfo == null) return

        this.props.dispatch({
            type: 'EmitSocket',
            EmitSocketName: 'EquipmentManageRequest',
            EmitSocketData: {
                'msgType': 'updateEquipment',
                'userID': UserInfo.userID,
                'client_id': UserInfo.client_id, 
            }
        })
    }

    openPopup(){
        this.props.setSelected(this.props.data)
    }
      
    render() { 
        const _this = this
        const {data} = this.props
        if(data === null || data === undefined) return <div></div>
        const {gatewayID, uniqueID, location, onlineStatus, updateTime} = data

        return <div className='CBAudit_tableRow'>
            <div className='col2'>{gatewayID}</div>
            <div className='col2'>{uniqueID}</div>
            <div className='col2'>{location}</div>
            <div className='col2'>{onlineStatus}</div>
            <div className='col2'>
                <Moment format='YYYY-MM-DD HH:mm'>{updateTime}</Moment>
            </div>
            <div id='UM_table_R' className='fa fa-edit EditBtn' onClick={ _this.openPopup}></div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        UserInfo: state.UserInfo
    }
}
export default connect(mapStateToProps)(Table) 