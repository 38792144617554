import React from 'react'
import './LuxSummary.css'

//Redux Import
import { connect } from 'react-redux'

class LuxSummary extends React.Component {
    constructor(props)
    {
        super()
    }

    render()
    {
        const {DeviceData, currentSite, currentlevel} = this.props
        if(DeviceData == null || currentSite == null || currentlevel == null) return <div></div>            //exceptional cases
        const CurrentFloorItem = DeviceData[currentSite].nestedLocs.find(function(floorItem) {return floorItem.locName == currentlevel})
        const CurrentDeviceList = CurrentFloorItem.nestedLocs
        console.log(CurrentDeviceList)
        
        let isExistLuxDevice = false
        let LuxStatus = "Off"
        CurrentDeviceList.forEach(deviceItem => {
            const payload = deviceItem.payload
            if(payload == null) return      //exception case
            if(payload.lightOn != null) {
                isExistLuxDevice = true
                if(payload.lightOn) {
                    //true status
                    LuxStatus = "On"
                }
            }
        })

        return <div className="LuxSummaryCardContainer">
            <div className="LuxSummaryCardHead">
                <div className="fa fa-star LuxSummaryIcon"></div>
                <div className="LuxSummaryTitle">Lux</div>
            </div>
            <div className="LuxSummaryBox">
                <div className="LuxDisplayTitle">
                    Lux Status
                </div>
                <div className={LuxStatus=="Off"?"LuxDisplayResult":"LuxDisplayResult OnResult"}>
                    {isExistLuxDevice?LuxStatus:"No Lux Device"}
                </div>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
      socket: state.socket,
      DeviceData: state.DeviceData,
      currentlevel: state.currentlevel,
      currentSite: state.currentSite,      
      DeviceDataLastUpdate: state.DeviceDataLastUpdate,
    }
}
  
export default connect(mapStateToProps)(LuxSummary)