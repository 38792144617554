import React, { useState, useEffect } from 'react'
import './Occupancy.css'

import { connect } from 'react-redux'

import ProgressWidget from '../progress/progress'
import FallAlarmWidget from '../../ToiletAdmin/Occupancy/fallalarm/fallalarm'
import PositionWidget from './Position/Position'
import getColorCode from '../commonfunction'

const FixedImage_W = 36
const FixedImage_H = 20

function OccupancyWidget(props) {
    const {configStorage, DeviceData, currentSite, currentlevel, ToiletType, DeviceDataLastUpdate} = props
    const [OccPercent, setOccPercent] = useState(0)
    const [OccTotal, setOccTotal] = useState(0)
    const [OccInUse, setOccInUse] = useState(0)
    const [MaleOccPercent, setMaleOccPercent] = useState(0)
    const [MaleOccTotal, setMaleOccTotal] = useState(0)
    const [MaleOccInUse, setMaleOccInUse] = useState(0)
    
    const {color1, color2, gradient1, gradient2} = getColorCode(ToiletType)
    
    useEffect(() => {
        const LocationList = FilterLocationList(props)
        const FormattedLocList = FormatOccFallItems(LocationList, ToiletType)
        
        let OccupiedCount = 0           //seat toilet
        let MaleOccupiedCount = 0       //male unisex urinel

        let Total = FormattedLocList.filter(item => {
            return item.IsPoint == false
        }).length
        let MaleTotal = FormattedLocList.filter(item => {
            return item.IsPoint == true
        }).length

        FormattedLocList.forEach(item => {
            if(item.Occupied == true && item.IsPoint == true) MaleOccupiedCount += 1
            if(item.Occupied == true && item.IsPoint == false) OccupiedCount += 1
        })

        const Percent = (Total == 0)?0:((Total - OccupiedCount)/Total * 100).toFixed(0)
        const MalePercent = (MaleTotal == 0)?0:((MaleTotal - MaleOccupiedCount)/MaleTotal * 100).toFixed(0)
        setTimeout(() => {
            setOccPercent(Percent)
            setOccTotal(Total)
            setOccInUse(OccupiedCount)
            setMaleOccPercent(MalePercent)
            setMaleOccTotal(MaleTotal)
            setMaleOccInUse(MaleOccupiedCount)
        }, 500)
    })

    //Occ and Fall alarm
    const LocationList = FilterLocationList(props)
    const FormattedLocList = FormatOccFallItems(LocationList, ToiletType)

    //bin and soap items
    const PointArray = PointList(props)
    console.log(PointArray)

    const FloorImgPath_str = FloorImgPath(configStorage, DeviceData, currentSite, currentlevel, ToiletType)
    const CurrentFloorDesc = GetFloorDesc(DeviceData, currentSite, currentlevel, ToiletType)

    return <div className="Toilet_occ">
        <div className="Toilet_occ_head">
            Occupancy
            <div className="Toilet_occ_CurrentSiteLevel">
                {CurrentFloorDesc}
            </div>
        </div>
        <div className="Toilet_occ_inner">
            <div className="Toilet_occ_floorplan" style={{backgroundImage: 'url(' + FloorImgPath_str + ')'}}>
            {
                FormattedLocList.map(item => {
                    const colorStyle1 = item.Occupied?color2:'transparent'

                    if(item.IsPoint == true) {
                        return <div className="Toilet_occ_PosPt" style={{marginLeft: item.x * FixedImage_W +'rem', marginTop: item.y * FixedImage_H +'rem', background: colorStyle1}}>
                        </div>
                    }

                    return <div className="Toilet_occ_Pos" style={{marginLeft: item.x * FixedImage_W +'rem', marginTop: item.y * FixedImage_H +'rem', width: item.w * FixedImage_W + 'rem', height: item.h * FixedImage_H + 'rem', background: colorStyle1}}>
                        {
                            item.fallDetected?
                            <FallAlarmWidget fallTime={new Date()} />
                            :
                            <div style={{display: 'none'}}></div>
                        }
                    </div>

                    // const itemx = 17.5 / FixedImage_W
                    // const itemy = 10.6 / FixedImage_H
                    // const itemw = 4 / FixedImage_W
                    // const itemh = 7.5 / FixedImage_H

                    // console.log({
                    //     itemx: itemx,
                    //     itemy: itemy,
                    //     itemw: itemw,
                    //     itemh: itemh
                    // })

                    // return <div className="Toilet_occ_Pos" style={{marginLeft: itemx * FixedImage_W +'rem', marginTop: itemy * FixedImage_H +'rem', width: itemw * FixedImage_W + 'rem', height: itemh * FixedImage_H + 'rem', background: colorStyle1}}>
                    //     {
                    //         item.fallDetected?
                    //         <FallAlarmWidget fallTime={new Date()} />
                    //         :
                    //         <div style={{display: 'none'}}></div>
                    //     }
                    // </div>
                })
            }
            {
                PointArray==null?
                <div style={{display: 'none'}}></div>
                :
                PointArray.map(item => {
                    return <PositionWidget item={item} color2={color2} w={FixedImage_W-2} h={FixedImage_H} />
                })
            }
            </div>
            <div className="Toilet_occ_sum">
                {/* {
                    (ToiletType==1||ToiletType=="1")&&MaleOccTotal!=0?
                    <div className="Toilet_occ_item">
                        <ProgressWidget PercentInput={MaleOccPercent} OccTotal={MaleOccTotal} OccInUse={MaleOccInUse} ColorDefault={color1} Highlight={color2} WhiteColor="white" text="Vacancy" />
                        <div className="title">Urinale</div>
                    </div>
                    :
                    <div style={{display: 'none'}}></div>
                } */}
                <div className="Toilet_occ_item">
                    <ProgressWidget PercentInput={OccPercent} OccTotal={OccTotal} OccInUse={OccInUse} ColorDefault={color1} Highlight={color2} WhiteColor="white" text="Vacancy" />
                    <div className="title">Seats Toilet</div>
                </div>
            </div>
        </div>
        {/* <TimeProgressWidget DisplaySecond={80} /> */}
    </div>
}

function mapStateToProps(state) {
    return {
        ToiletType: state.ToiletType,
        DeviceData: state.DeviceData,
        currentSite: state.currentSite,
        currentlevel: state.currentlevel,
        configStorage: state.configStorage,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate,
    }
}

export default connect(mapStateToProps)(OccupancyWidget)

//Occ and Fall readings
function FilterLocationList(props) {
    const {DeviceData, currentSite, currentlevel, ToiletType} = props
    if(DeviceData == null || currentlevel == null || currentSite == null) return

    const SiteItem = DeviceData[currentSite]
    if(SiteItem == null) return
    const LevelItem = SiteItem.nestedLocs.find(item => {
        return item.locName.toString() == currentlevel.toString()
    })
    if(LevelItem == null) return
    const ReturnLocationList = LevelItem.nestedLocs.filter(item => {
        if(item.payload == null || item.payload_definition_id == null || item.payload.toiletType == null) return false
        return item.payload.toiletType.toString() == ToiletType.toString() && (item.payload_definition_id.nameID == "Cubicle Occupancy"||item.payload_definition_id.nameID == "Fall Detection" || item.payload_definition_id.nameID == "Urinal Occupancy")
    })
    return ReturnLocationList
}
function FormatOccFallItems(LocArray, inputToiletType) {
    if(LocArray == null || LocArray.length == null || LocArray.length == 0) return []

    const FilteredList = LocArray.filter(item => {
        if(item?.payload == null) return false
        const {occupied, fallDetected, toiletType} = item.payload
        if(occupied == null) return false
        if(inputToiletType != toiletType) return false

        return true
    })

    let MappedArray = FilteredList.map(item => {
        //convert zone to x,y,w,h
        let pointist = (item.locUI?.indicator?.point == null)?[]:item.locUI.indicator.point
        let Xcoor_list = pointist.map(item => {
            return item[0]
        })
        let Ycoor_list = pointist.map(item => {
            return item[1]
        })
        let X_min = Math.min(...Xcoor_list)
        let X_max = Math.max(...Xcoor_list)
        let Y_min = Math.min(...Ycoor_list)
        let Y_max = Math.max(...Ycoor_list)
        let width = X_max - X_min
        let height = Y_max - Y_min

        const {occupied, fallDetected, toiletType} = item.payload

        //point
        if(pointist[0].type == "Point") {
            const coordinate = pointist[0].coordinates
            const x = coordinate[0]
            const y = coordinate[1]
            return {
                IsPoint: true,
                locName: item.locName,
                x: x,
                y: y,
                Occupied: occupied,
                fallDetected: fallDetected,
                toiletType: toiletType
            }
        }

        //area
        return {
            IsPoint: false,
            locName: item.locName,
            x: X_min,
            y: Y_min,
            w: width,
            h: height,
            Occupied: occupied,
            fallDetected: fallDetected,
            toiletType: toiletType
        }
    })
    return MappedArray
}

//waste bin and soap level readings
function PointList(props) {
    const GetPointList = filterPointList(props)
    const FormattedList = PointItemFormat(GetPointList)

    return FormattedList
}
function filterPointList(props) {
    //filter the location list
    const {DeviceData, currentSite, currentlevel, ToiletType} = props
    if(DeviceData == null || currentlevel == null || currentSite == null) return

    const SiteItem = DeviceData[currentSite]
    if(SiteItem == null) return
    const LevelItem = SiteItem.nestedLocs.find(item => {
        return item.locName.toString() == currentlevel.toString()
    })
    if(LevelItem == null) return

    const ReturnList = LevelItem.nestedLocs.filter(item => {
        if(item.payload == null || item.payload_definition_id == null || item.payload.toiletType == null) return false
        return item.payload.toiletType.toString() == ToiletType.toString() && (item.payload_definition_id.nameID == "Waste Bin" || item.payload_definition_id.nameID == "Soap" || 
        item.payload_definition_id.nameID == "Toilet Paper" || item.payload_definition_id.nameID == "Flushing Water")
    })
    return ReturnList
}
function PointItemFormat(PointList) {
    if(PointList == null || PointList.length == null || PointList.length == 0) return

    let DefaultCoor = 0
    let ReturnArray = []
    PointList.forEach(item => {
        if(item.payload == null) return
        const {isFull, hasSoap, soapLevel, hasPaper, PaperLevel, flushingWater} = item.payload
        let Point
        if(item.locUI==null||item.locUI.indicator==null||item.locUI.indicator.point==null||item.locUI.indicator.point[0].coordinates==null) {
            Point = [DefaultCoor, DefaultCoor]
            DefaultCoor += 0.1
        }
        else {
            Point = item.locUI.indicator.point[0].coordinates
        }

        let FormattedItem
        if(isFull != null) {
            FormattedItem = {
                type: "bin",
                isFull: isFull,
                x: Point[0],
                y: Point[1]
            }
        }
        else if(hasSoap != null || soapLevel != null) {
            FormattedItem = {
                type: "soap",
                hasSoap: hasSoap,
                soapLevel: soapLevel,
                x: Point[0],
                y: Point[1]
            }
        }
        else if(flushingWater != null) {
            FormattedItem = {
                type: "flushingWater",
                flushingWater: flushingWater,
                x: Point[0],
                y: Point[1]
            }
        }
        else {
            FormattedItem = {
                type: "paper",
                hasPaper: hasPaper,
                PaperLevel: PaperLevel,
                x: Point[0],
                y: Point[1]
            }
        }
        ReturnArray.push(FormattedItem)
    })

    return ReturnArray
}

//Get Floor Plan Image Path
function FloorImgPath(configStorage, DeviceData, currentSite, currentlevel, ToiletType) {
    const {socketUrl} = configStorage
    const SiteItem = DeviceData[currentSite]
    const SiteUrl = SiteItem.imgUrl

    let ToiletType_str = 'm'
    switch (ToiletType) {
        case 1:
            ToiletType_str = 'm'
            break
        case 2:
            ToiletType_str = 'f'
            break
        case 3:
            ToiletType_str = 'd'
            break
    }

    //https://47.52.20.40:30405/assets/images/floors/PY_toilet/18_m.jpg
    const ReturnImgPath = socketUrl + '/assets/images/floors/' + SiteUrl + '/' + currentlevel + "_" + ToiletType_str + ".jpg"
    // alert(ReturnImgPath)

    return ReturnImgPath
}

function GetFloorDesc(DeviceData, currentSite, currentlevel, ToiletType) {
    if(DeviceData==null||currentSite==null||currentlevel==null) return "Redux Items are missing"

    const SiteItem = DeviceData[currentSite]
    const SiteName = SiteItem.locName
    const LevelName = currentlevel

    let MaleFemale
    if(ToiletType == 1 || ToiletType == "1") MaleFemale = "Male"
    else if(ToiletType == 2 || ToiletType == "2") MaleFemale = "Female"
    else if(ToiletType == 3 || ToiletType == "3") MaleFemale = "Disable"
    else  MaleFemale = ""

    return SiteName + " " + LevelName + "F - " + MaleFemale + " Toilet"
}

//w = 5
//h = 8