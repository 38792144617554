import React, { useState } from 'react'
import './floorPlanInner.css'

import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding } from '@fortawesome/free-solid-svg-icons'

import FallAlarmWidget from '../../Occupancy/fallalarm/fallalarm'
import PositionWidget from '../../../Toilet/Occ/Position/Position'

//unit: rem
const screenWidth = window.screen.width

const FloorPlanWImage_W = screenWidth>1280?42:30
const FloorPlanWImage_H = screenWidth>1280?23:16.5

function FloorPlanInnerWidget(props) {
    const {color2, configStorage, DeviceData, currentSite, currentlevel, ToiletType} = props
    const {setDisplayIndex} = props

    if(DeviceData == null) return <div>No DeviceData</div>
    const FloorPlanImage = FloorImgPath(configStorage, DeviceData, currentSite, currentlevel, ToiletType)
    //Occ and Fall alarm
    const LocationList = FilterLocationList(props)
    const FormattedLocList = FormatOccFallItems(LocationList, ToiletType)
    // console.log({
    //     FormattedLocList: FormattedLocList,
    //     LocationList: LocationList,
    //     ToiletType: ToiletType,
    // })

    const PointArray = PointList(props)

    const SiteName = DeviceData[currentSite].locName
    return [
        <div className="head">
            <div className="Toilet_ad_floorplan_site" style={{color: color2}}>
                <div className="icon">
                    <FontAwesomeIcon icon={faBuilding} className="fa" />
                </div>
                <div className="title" onClick={()=>setDisplayIndex(1)} style={{background: color2, color: 'white'}}>{SiteName + '▼'}</div>
                <div>Level:</div>
                <div className="level" onClick={()=>setDisplayIndex(2)} style={{background: color2, color: 'white'}}>{currentlevel}/F ▼</div>
            </div>
            <div className="headitem">
                <div className="headitem_color" style={{background: color2, borderColor: color2}}></div>
                <div className="headitem_txt">In-use</div>
            </div>
        </div>,
        <div className="Toilet_ad_floorplan" style={{ backgroundImage: 'url(' + FloorPlanImage + ')' }}>
            {
                FormattedLocList.map(item => {
                    const colorStyle1 = item.Occupied?color2:'transparent'

                    if(item.IsPoint == true) {
                        return <div className="Toilet_occ_PosPt" style={{marginLeft: item.x * FloorPlanWImage_W +'rem', marginTop: item.y * FloorPlanWImage_H +'rem', background: colorStyle1}}>
                            {
                                item.fallDetected?
                                <FallAlarmWidget fallTime={new Date()} />
                                :
                                <div style={{display: 'none'}}></div>
                            }
                        </div>
                    }

                    return <div className="Toilet_ad_floorplan_Pos" style={{marginLeft: item.x * FloorPlanWImage_W +'rem', marginTop: item.y * FloorPlanWImage_H +'rem', width: item.w * FloorPlanWImage_W + 'rem', height: item.h * FloorPlanWImage_H + 'rem',
                    background: colorStyle1}}>
                        {
                            item.fallDetected?
                            <FallAlarmWidget fallTime={new Date()} />
                            :
                            <div style={{display: 'none'}}></div>
                        }
                    </div>
                })
            }
            {
                PointArray==null?
                <div style={{display: 'none'}}></div>
                :
                PointArray.map(item => {
                    return <PositionWidget item={item} color2={color2} w={FloorPlanWImage_W-2} h={FloorPlanWImage_H} />
                })
            }
        </div>
    ]
}

function mapStateToProps(state) {
    return {
        ToiletType: state.ToiletType,        
        configStorage: state.configStorage,
        DeviceData: state.DeviceData,
        currentSite: state.currentSite,
        currentlevel: state.currentlevel,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate,
    }
}

export default connect(mapStateToProps)(FloorPlanInnerWidget)

function FloorImgPath(configStorage, DeviceData, currentSite, currentlevel, ToiletType) {
    const {socketUrl} = (configStorage==null)?{socketUrl: 'https://47.52.20.40:30405'}:configStorage
    const SiteItem = DeviceData[currentSite]
    const SiteUrl = SiteItem.imgUrl

    let ToiletType_str = 'm'
    switch (ToiletType) {
        case 1:
            ToiletType_str = 'm'
            break
        case 2:
            ToiletType_str = 'f'
            break
        case 3:
            ToiletType_str = 'd'
            break
    }

    //https://47.52.20.40:30405/assets/images/floors/PY_toilet/18_m.jpg
    const ReturnImgPath = socketUrl + '/assets/images/floors/' + SiteUrl + '/' + currentlevel + "_" + ToiletType_str + ".jpg"
    // alert(ReturnImgPath)

    return ReturnImgPath
}

//Occ and Fall readings
function FilterLocationList(props) {
    const {DeviceData, currentSite, currentlevel, ToiletType} = props
    if(DeviceData == null || currentlevel == null || currentSite == null) return

    const SiteItem = DeviceData[currentSite]
    if(SiteItem == null) return
    const LevelItem = SiteItem.nestedLocs.find(item => {
        return item.locName.toString() == currentlevel.toString()
    })
    if(LevelItem == null) return
    const ReturnLocationList = LevelItem.nestedLocs.filter(item => {
        if(item.payload == null || item.payload_definition_id == null || item.payload.toiletType == null) return false
        return item.payload.toiletType.toString() == ToiletType.toString() && (item.payload_definition_id.nameID == "Cubicle Occupancy"||
        item.payload_definition_id.nameID == "Fall Detection" || item.payload_definition_id.nameID == "Urinal Occupancy")
    })
    return ReturnLocationList
}
function FormatOccFallItems(LocArray, inputToiletType) {
    if(LocArray == null || LocArray.length == null || LocArray.length == 0) return []

    const FilteredList = LocArray.filter(item => {
        if(item?.payload == null) return false
        const {occupied, fallDetected, toiletType} = item.payload
        if(occupied == null) return false
        if(inputToiletType != toiletType) return false

        return true
    })

    let MappedArray = FilteredList.map(item => {
        //convert zone to x,y,w,h
        let pointist = (item.locUI?.indicator?.point == null)?[]:item.locUI.indicator.point
        let Xcoor_list = pointist.map(item => {
            return item[0]
        })
        let Ycoor_list = pointist.map(item => {
            return item[1]
        })
        let X_min = Math.min(...Xcoor_list)
        let X_max = Math.max(...Xcoor_list)
        let Y_min = Math.min(...Ycoor_list)
        let Y_max = Math.max(...Ycoor_list)
        let width = X_max - X_min
        let height = Y_max - Y_min

        const {occupied, fallDetected, toiletType} = item.payload

        //point
        if(pointist[0].type == "Point") {
            const coordinate = pointist[0].coordinates
            const x = coordinate[0]
            const y = coordinate[1]
            return {
                IsPoint: true,
                locName: item.locName,
                x: x,
                y: y,
                Occupied: occupied,
                fallDetected: fallDetected,
                toiletType: toiletType
            }
        }

        //area
        return {
            locName: item.locName,
            x: X_min,
            y: Y_min,
            w: width,
            h: height,
            Occupied: occupied,
            fallDetected: fallDetected,
            toiletType: toiletType
        }
    })
    return MappedArray
}

//Other Sensir (point icons)
function PointList(props) {
    const GetPointList = filterPointList(props)
    const FormattedList = PointItemFormat(GetPointList)

    return FormattedList
}
function filterPointList(props) {
    //filter the location list
    const {DeviceData, currentSite, currentlevel, ToiletType} = props
    if(DeviceData == null || currentlevel == null || currentSite == null) return

    const SiteItem = DeviceData[currentSite]
    if(SiteItem == null) return
    const LevelItem = SiteItem.nestedLocs.find(item => {
        return item.locName.toString() == currentlevel.toString()
    })
    if(LevelItem == null) return

    const ReturnList = LevelItem.nestedLocs.filter(item => {
        if(item.payload == null || item.payload_definition_id == null || item.payload.toiletType == null) return false
        return item.payload.toiletType.toString() == ToiletType.toString() && 
        (item.payload_definition_id.nameID == "Waste Bin" || item.payload_definition_id.nameID == "Soap" || item.payload_definition_id.nameID == "Toilet Paper" ||
         item.payload_definition_id.nameID == "Flushing Water" || item.payload_definition_id.nameID == "Water Leakage" || item.payload_definition_id.nameID == 'IAQ 4in1' || item.payload_definition_id.nameID == 'IAQ'
         || item.payload_definition_id.nameID == "H2S" || item.payload_definition_id.nameID == "SO2" )
    })
    return ReturnList
}
function PointItemFormat(PointList) {
    if(PointList == null || PointList.length == null || PointList.length == 0) return

    let DefaultCoor = 0
    let ReturnArray = []
    PointList.forEach(item => {
        if(item.payload == null) return
        const {isFull, hasSoap, soapLevel, hasPaper, PaperLevel, flushingWater, waterLeakageStatus, temperature, humidity, co2, pm2_5, sO2, h2S} = item.payload
        let Point
        if(item.locUI==null||item.locUI.indicator==null||item.locUI.indicator.point==null||item.locUI.indicator.point[0].coordinates==null) {
            Point = [DefaultCoor, DefaultCoor]
            DefaultCoor += 0.1
        }
        else {
            Point = item.locUI.indicator.point[0].coordinates
        }

        let FormattedItem
        if(isFull != null) {
            FormattedItem = {
                type: "bin",
                isFull: isFull,
                x: Point[0],
                y: Point[1]
            }
        }
        if(hasSoap != null || soapLevel != null) {
            FormattedItem = {
                type: "soap",
                hasSoap: hasSoap,
                soapLevel: soapLevel,
                x: Point[0],
                y: Point[1]
            }
        }
        if(hasPaper != null || PaperLevel != null) {
            FormattedItem = {
                type: "paper",
                hasPaper: hasPaper,
                PaperLevel: PaperLevel,
                x: Point[0],
                y: Point[1]
            }
        }
        if(flushingWater != null) {
            FormattedItem = {
                type: "flushingWater",
                flushingWater: flushingWater,
                x: Point[0],
                y: Point[1]
            }
        }
        if(waterLeakageStatus != null) {
            FormattedItem = {
                type: "waterLeakage",
                waterLeakageStatus: waterLeakageStatus,
                x: Point[0],
                y: Point[1]
            }
        }
        if(temperature != null || humidity != null || co2 != null || pm2_5 != null) {
            FormattedItem = {
                type: 'IAQ',
                temperature: temperature,
                humidity: humidity,
                co2: co2,
                pm2_5: pm2_5,
                x: Point[0],
                y: Point[1]
            }
        }
        if((sO2 !== null && sO2 !== undefined) || (h2S !== null && h2S !== undefined)) {
            FormattedItem = {
                type: 'SO2|H2S',
                so2: sO2,
                h2s: h2S,
                x: Point[0],
                y: Point[1]
            }
        }
        ReturnArray.push(FormattedItem)
    })

    return ReturnArray
}