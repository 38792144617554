const ToiletColorStyle = {
    'male': {
        'color1': 'rgb(156, 209, 155)',
        'color2': 'rgb(73, 191, 181)',
        'gradient1': 'rgb(0, 145, 175)',
        'gradient2': 'rgb(73, 191, 181)',
    },
    'female': {
        'color2': 'rgb(232, 132, 134)',
        'color1': 'rgb(255, 217, 161)',
        'gradient1': 'rgb(246, 148, 90)',
        'gradient2': 'rgb(255, 217, 161)',
    },
    'disable': {
        'color1': 'rgb(156, 219, 248)',
        'color2': 'rgb(0, 140, 207)',
        'gradient1': 'rgb(0, 140, 207)',
        'gradient2': 'rgb(184, 228, 250)',
    }
}

function getColorCode(TypeIndex) {
    let TypeStr
    switch (TypeIndex) {
        case 1:
            TypeStr = 'male'
            break
        case 2:
            TypeStr = 'female'
            break
        case 3:
            TypeStr = 'disable'
            break
        default:
            TypeStr = 'male'
            break
    }
    const {color1, color2, gradient1, gradient2} = ToiletColorStyle[TypeStr]
    return {
        'color1': color1,
        'color2': color2,
        'gradient1': gradient1,
        'gradient2': gradient2,
    }
}

export default getColorCode

//male          1
//female        2
//disable       3