import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import './BottomDisplay.ScrollToView.scss'

const ScrollToCardViewWIdget = props => {
    const { deviceDataArray, currentBottomCard } = props

    useEffect(() => {
        ScrollToCardView(currentBottomCard)
    }, [currentBottomCard])

    const selectedCard = getIndexFromCardID(currentBottomCard)

    return <div className='scrollIconList'>
        {
            deviceDataArray.map((item, i) => {
                return <div className='cardIcon' style={{background: selectedCard==i?'var(--focuscardbackground)':'rgba(255,255,255,0.3)'}} onClick={()=>SelectCard(props, i)}></div>
            })
        }
    </div>
}
function mapStateToProps(state) {
    return {
        DeviceDataLastUpdate: state.DeviceDataLastUpdate,              //update this redux storage to trigger render of cards
    }
}
export default connect(mapStateToProps)(ScrollToCardViewWIdget)

const ScrollToCardView = (currentBottomCard) => {
    const cardClassNames = ['cardScrollable', 'BtmCard', 'DefaultCardSize', 'cardScrollable4Reading']

    if(currentBottomCard == 'bottomcard_-1') return     //not selected any item
    let intStr = currentBottomCard.replace('bottomcard_', '')
    let Int_i = parseInt(intStr, 0)

    let existClass = ''
    cardClassNames.forEach(classStr => {
        let getObjects = document.getElementsByClassName(classStr)
        if(getObjects == null || getObjects.length == null || getObjects.length == 0) return
        existClass = classStr
    })

    if(existClass == '') return
    if(document.getElementsByClassName(existClass)[Int_i] == null) return
    document.getElementsByClassName(existClass)[Int_i].scrollIntoView()
}
const SelectCard = (props, i) => {
    props.dispatch({type: 'bottomcardhover', data: 'bottomcard_' + i})
    const {LocationName} = props
    props.dispatch({ type: 'PickPolygon', 
        data: LocationName
    })
}

const getIndexFromCardID = currentBottomCard => {
    if(currentBottomCard==null) return

    const indexStr = currentBottomCard.replace('bottomcard_', '')
    const index = parseInt(indexStr)

    return index
}