import React from 'react';
import './BottomDisplayCards.css';
//Redux Import
import { connect } from 'react-redux';

class BottomDisplayCards extends React.Component {
    render() {
        let { cardnumber, currentBottomMenu, weekdayname } = this.props;
        let number_str = currentBottomMenu.replace('bottom', '');
        let menunumber = parseInt(number_str);
        //console.log(menunumber);
        let fontsize = {
            fontSize: '2rem',
            margin: 'auto',
            marginRight: 0
        };
        switch(menunumber)
        {
            case 1:
                return <div className={"flex flex-col justify-between card"} id={ "bottomcard_" + cardnumber } >
                    <div className="BottomCardInnerDiv">
                        <div className="BottomCardTitle">
                            <div className="BottomCardTitleLine">
                                {weekdayname}
                            </div>
                        </div>
                        <div className={"BottomCardReadingBody temptextcolor"}>
                            <div className="flex flex-row justify-between BottomCardReadingLine">
                                <div className="flex flex-row BottomCardReadingDisplay">
                                    28
                                    <div className="flex flex-row BottomCardReadingSymbol">
                                        <div className="BottomCardReadingSymbolSmall">
                                            o
                                        </div>
                                        C
                                    </div>
                                </div>
                                <i className="fa fa-thermometer-half BottomCardIcon" style={fontsize}></i>
                            </div>
                            <div className={"BottomCardReadingLine humidtextcolor"}>
                                <div className="flex flex-row justify-between BottomCardReadingLine">
                                    <div className="flex flex-row BottomCardReadingDisplay">
                                        50
                                        <div className="flex flex-row BottomCardReadingSymbol">
                                            %
                                        </div>
                                    </div>
                                    <i className="fa fa-tint BottomCardIcon" style={fontsize}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            case 2:
                return <div className={"flex flex-col justify-between card"} id={ "bottomcard_" + cardnumber } >
                    <div className="BottomCardInnerDiv">
                        <div className="BottomCardTitle">
                            <div className="BottomCardTitleLine">
                                {weekdayname}
                            </div>
                        </div>
                        <div className={"BottomCardReadingBody peopletextcolor"}>
                            <div className="flex flex-row justify-between BottomCardReadingLine">
                                <div className="flex flex-row BottomCardReadingDisplay">
                                    133
                                    <div className="flex flex-row BottomCardReadingSymbol">
                                    </div>
                                </div>
                                <i className="fa fa-users BottomCardIcon" style={fontsize}></i>
                            </div>
                        </div>
                    </div>
                </div>
            case 3:
                return <div className={"flex flex-col justify-between card"} id={ "bottomcard_" + cardnumber } >
                    <div className="BottomCardInnerDiv">
                        <div className="BottomCardTitle">
                            <div className="BottomCardTitleLine">
                                {weekdayname}
                            </div>
                        </div>
                        <div className={"BottomCardReadingBody powertextcolor"}>
                            <div className="flex flex-row justify-between BottomCardReadingLine">
                                <div className="flex flex-row BottomCardReadingDisplay">
                                    28
                                    <div className="flex flex-row BottomCardReadingSymbol">
                                        %
                                    </div>
                                </div>
                                <i className="fa fa-bolt BottomCardIcon" style={fontsize}></i>
                            </div>
                        </div>
                    </div>
                </div>
            case 4:
                return <div className={"flex flex-col justify-between card"} id={ "bottomcard_" + cardnumber } >
                    <div className="BottomCardInnerDiv">
                        <div className="BottomCardTitle">
                            <div className="BottomCardTitleLine">
                                {weekdayname}
                            </div>
                        </div>
                        <div className={"BottomCardReadingBody batterytextcolor"}>
                            <div className="flex flex-row justify-between BottomCardReadingLine">
                                <div className="flex flex-row BottomCardReadingDisplay">
                                    75
                                    <div className="flex flex-row BottomCardReadingSymbol">
                                        %
                                    </div>
                                </div>
                                <i className="fa fa-battery fa-rotate-270 BottomCardIcon" style={fontsize}></i>
                            </div>
                        </div>
                    </div>
                </div>
            default:
                return <div className={"flex flex-col justify-between card"} id={ "bottomcard_" + cardnumber } >
                    Some thing wrong ! Please Contact with Help Center
                </div>
        }
    }
}

//Redux inside component function
function mapStateToProps(state) {
    //console.log(state);
    return {
      count: state.count,
      log: state.log,
      socket: state.socket,
      level: state.level,
      currentlevel: state.currentlevel,
      SiteList: state.SiteList,
      currentSite: state.currentSite,
      MenuDisplay: state.MenuDisplay,
      currentBottomMenu: state.currentBottomMenu,
      currentBottomCard: state.currentBottomCard
    };
}

export default connect(mapStateToProps)(BottomDisplayCards);