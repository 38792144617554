import React from 'react'
import './manage.css'

import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';

//Redux Import
import { connect } from 'react-redux'

const options = [
    { value: 'one', label: 'Option One' },
    { value: 'two', label: 'Option Two' },
];
class Manage extends React.Component {
    constructor(props) {
        super()
        this.state = {
            selectedGroup: null,
            members: [
                { value: 'one', label: 'Option One' },
                { value: 'two', label: 'Option Two' },
            ],
            selected: ['one'],
            groupName: "",
            AvailableColdBox: null
        }
        this.sendGroupReq = this.sendGroupReq.bind(this)
        // this.GetLocLv3Req = this.GetLocLv3Req.bind(this)
        // this.GetAssignedItemsReq = this.GetAssignedItemsReq.bind(this)
        this.onChangeList = this.onChangeList.bind(this)
        this.initOption = this.initOption.bind(this)
        // this.Listener = this.Listener.bind(this)
        this.filterAvailableColdbox = this.filterAvailableColdbox.bind(this)
        this.closePopup = this.closePopup.bind(this)
        this.handleInputChage = this.handleInputChage.bind(this)

        this.deleteConfirm = this.deleteConfirm.bind(this)
    }

    deleteConfirm() {
        this.props.deleteConfirm()
    }

    handleInputChage(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    closePopup() {
        console.log("closePopup")
        const thisoutside = this
        this.setState({
            groupName: ""
        })
        thisoutside.props.closePopup()
    }

    componentDidMount() {

    }
    componentDidUpdate() {
        if (this.props.data !== null && this.state.selectedGroup !== this.props.data) {

            this.setState({
                groupName: this.props.data.group_name,
                selectedGroup: this.props.data
            })

            this.filterAvailableColdbox()
        }

    }


    initOption(AvailableColdBox) {
        const { data } = this.props
        let newList = []
        let selectedList = []
        if (AvailableColdBox !== null && AvailableColdBox !== undefined) {
            if (AvailableColdBox.length > 0) {
                AvailableColdBox.map(function (item) {
                    let id = item._id
                    if (id === undefined) {
                        id = item.id
                    }

                    let m = {
                        value: id,
                        label: item.locName
                    }
                    newList.push(m)

                })
            }
        }
        if (data !== null && data !== undefined) {

            if (data.members !== null && data.members !== undefined) {

                if (data.members.length > 0) {
                    data.members.map(function (item) {
                        selectedList.push(item.id)

                    })
                }
            }
        }
        this.setState({
            members: newList,
            selected: selectedList

        })
    }

    filterAvailableColdbox() {
        const { data } = this.props
        const { AllColdBox, AllAssignedColdBox } = this.props
        if (data !== null && AllColdBox !== null && AllAssignedColdBox !== null) {
            let all = AllColdBox[0].nestedLocs.filter(function (array_el) {
                return array_el._id !== undefined
            });
            let Assigned = AllAssignedColdBox[0].members
            let result = all.filter(function (array_el) {
                return Assigned.filter(function (anotherOne_el) {
                    return anotherOne_el.id == array_el._id && anotherOne_el !== {};
                }).length == 0
            });
            let primes = result.concat(data.members);
            this.setState({ AvailableColdBox: primes });
            this.initOption(primes)
        }

    }

    onChangeList = (selected) => {
        this.setState({ selected });
    }

    sendGroupReq() {
        const { UserInfo, data } = this.props
        const { selected, members, groupName } = this.state
        if (UserInfo == null) return
        const EmitUserID = UserInfo.userID
        let selectedGroup = []
        if (selected !== null && members !== null) {
            if (selected.length > 0) {
                members.forEach((option, i) => {
                    selected.forEach((selected_value, i) => {
                        if (option.value == selected_value) {
                            let m = {
                                id: option.value,
                                locName: option.label
                            }
                            selectedGroup.push(m)
                        }
                    });
                });

                console.log("result")
                console.log(selectedGroup)

            }
        }
        //request json 
        let requestData = {}
        requestData = {
            "msgType": "UpdateGroup",
            "userID": EmitUserID,
            "_id": data._id,
            "members": selectedGroup,
            "client_id": UserInfo.client_id,
            "group_name": groupName
        }

        //send with redux socket function
        this.props.dispatch({
            type: 'EmitSocket',
            EmitSocketName: "GroupManageRequest",
            EmitSocketData: requestData
        })

        this.closePopup()
    }


    render() {
        const { isPopup, isEdit, isDelete, } = this.props     //send from parent
        const { selectedGroup, members, groupName } = this.state
        console.log(this.state)
        return (isPopup && isEdit && !isDelete) ?
            <div className="Coldbox_group_PopupMain">
                <div className="Coldbox_group_PopupTitle">Group Management</div>
                <div className="Coldbox_group_PopupcloseBtn" onClick={this.closePopup}>
                    <div className="fa fa-times"></div>
                </div>
                <input className="Coldbox_group_Popup_input" placeholder="Group Name"
                    value={groupName === undefined || groupName == null ? "" : groupName}
                    name="groupName"
                    onChange={this.handleInputChage}
                ></input>

                <div className="Coldbox_group_Popup_DualListBox_Container">
                    
                <div className="Coldbox_group_Popup_DualListBox_Title">
                <div className="ColL">
                        <div>Coldbox Available</div>
                    </div>
                    <div className="colR">
                        <div>Coldbox in this group</div>

                    </div>
                </div>                    
                    <DualListBox
                        options={this.state.members}
                        selected={this.state.selected}
                        onChange={this.onChangeList}
                    />
                </div>
                <div className="Coldbox_group_Popup_Btns">
                    <div className="Coldbox_group_Popup_DelBtns" onClick={this.deleteConfirm}>Delete</div>
                    <div className="Coldbox_group_Popup_SubmitBtns" onClick={this.sendGroupReq}>Submit</div>
                    <div className="Coldbox_group_Popup_CancelBtns" onClick={this.closePopup}>Cancel</div>
                </div>
            </div>
            :
            <div style={{ display: 'none' }}></div>
    }
}

function mapStateToProps(state) {
    return {
        socket: state.socket,
        currentlevel: state.currentlevel,
        currentSite: state.currentSite,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate, //when this value is updated, render would triggered
        StatisticData: state.StatisticData,
        UserInfo: state.UserInfo,
        currentlevelID: state.currentlevelID,
        GroupList: state.GroupList
    }
}

export default connect(mapStateToProps)(Manage)