import React from 'react'
import './table.css'
import { connect } from 'react-redux'
//import Moment from 'react-moment'
import moment from 'moment';

function Table(props) {
    let onClick = props.onClick
    let row = props.row
    let rowColor = 'transparent'

    let duration = props.duration
    let timeline_arr = [...Array(30).keys()]
    let startTime = moment(row.startTime, "YYYY-MM-DD").toDate()
    let endTime = moment(row.endTime, "YYYY-MM-DD").toDate()
    startTime.setHours(0, 0, 0, 0)
    endTime.setHours(0, 0, 0, 0)

    console.log(timeline_arr)

    let schedile_color = 'Aqua'
    if (row.workOrder_Status.includes('Dynamic')) {
        schedile_color = 'Orange'
    } else if (row.workOrder_Status.includes('Pending')) {
        schedile_color = 'Red'
    } else if (row.workOrder_Status.includes('Waiting')) {
        schedile_color = 'Grey'
    }
    return (
        <div className="" onClick={onClick}>
            <div className="Planner_tableRow" style={{ background: rowColor }}>
                <div className="col1">{(row.name)}</div>
                <div className="col1">{(row.workOrderType)}</div>
                <div className="col2">{(row.parts.join(", "))}</div>
                <div className="col2">{(row.orderNo)}</div>
                <div className="col1">{(row.requestor)}</div>
                <div className="subScroll" onScroll={bindHandleScroll}>
                {
                    timeline_arr.map(idx => {
                        let D = new Date()
                        D.setDate(D.getDate() + idx)
                        D.setHours(0, 0, 0, 0)
                        if (D.getTime() == startTime.getTime() && D.getTime() == endTime.getTime()) {
                            return <div className="planned_one" style={{ background: schedile_color }}>&nbsp;</div>
                        } else if (D.getTime() == startTime.getTime()) {
                            return <div className="planned_left" style={{ background: schedile_color }}>&nbsp;</div>
                        } else if (D.getTime() == endTime.getTime()) {
                            return <div className="planned_right" style={{ background: schedile_color }}>&nbsp;</div>
                        } else if (D.getTime() > startTime.getTime() && D.getTime() < endTime.getTime()) {
                            return <div className="planned_middle" style={{ background: schedile_color }}>&nbsp;</div>
                        }
                        return <div className="col2"></div>
                    })
                }
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        UserInfo: state.UserInfo,
        socket: state.socket,
    }
}

function bindHandleScroll(event) {
    let subScrollList = document.getElementsByClassName("subScroll")
    for (let i=0; i< subScrollList.length; i++) {
        let item = subScrollList[i]
        item.scrollLeft = event.currentTarget.scrollLeft
    }
}

export default connect(mapStateToProps)(Table)
