import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import './SchedulingManagement.css'
import SearchBar from "./SearchBar";
import TableHead from "./TableHead";
import Table from "./Table";
import { useHistory } from "react-router-dom";
import Pagebtns from "../bottom/pagebtns";
import AddButton from "../bottom/addButton";

const axios = require('axios');
function SchedulingManagement(props) {
    let type = props.match.params.type || "Dynamic"
    const history = useHistory()
    // const [isAdvanceSearch, setAdvanceSearch] = useState({
    //     isAdvanceSearch: false,
    //     AlarmList: null,
    //     rawAlarmList: null,
    //     //sorted result (by table head .js)
    //     sortedList: null,
    //     //filtered result (by searchbar.js)
    //     filteredList: null,
    //     //slice by page (by pages.js widget)
    //     pageSliceList: null,

    //     updateCount: 0
    // })
    const [ruleList, setRuleList] = useState([])
    const [filteredList, setFilteredList] = useState([])
    const [pageSliceList, setPageSliceList] = useState([])
    const [page, setPage] = useState(0)

    useEffect(() => {
        refresh()
    }, [])

    function refresh() {
        const RequestData_MaintenanceRule_List = {
            "options": {
                "type": type
            }
        }

        axios.post('https://47.52.20.40:31001/AtalAPI/v1/CPSL_MaintRule_List', RequestData_MaintenanceRule_List)
            .then(function (response) {
                console.log("CPSL_MaintenanceRule_List recv!", response)
                let list = response.data.info.map(item => {
                    return {
                        Device: item.deviceType,
                        PMFrequency: item.repeatFrequency,
                        Fault: item.description,
                        ServiceImpact: item.serviceSuspension,
                        Duration: Math.round(item.maintenanceDuration / 36000.0) / 100.0 + 'hr',
                        Vendor: item.vendorName,
                        ruleID: item.rule_id
                    }
                })
                setRuleList(list)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function UpdateList(key, list) {
        if (key === "pageSliceList")
            setPageSliceList(list)
        else setFilteredList(list)
    }

    return <div className="workOrderList">
        <SearchBar sortedList={ruleList} UpdateList={UpdateList} refresh={refresh} />
        <TableHead />
        {
            (pageSliceList != null && pageSliceList != undefined) ?
                pageSliceList.map(row => {
                    return <Table row={row} onClick={() => history.push({ pathname: "/Main/MmsAddSchedueType/" + type, state: { "mode": "Update", "data": (row) } })} />
                }) : ""
        }
        <div className="ColdboxIndi_bottom">
            <AddButton onClick={() => history.push({ pathname: "/Main/MmsAddSchedueType/" + type, state: { "mode": "Create", "data": {} } })} />
            <Pagebtns sortedList={filteredList} UpdateList={UpdateList} />
        </div>
    </div>
}

function mapStateToProps(state) {
    return {
        socket: state.socket,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate, //when this value is updated, render would triggered
        UserInfo: state.UserInfo,
        Notification: state.Notification,
    }
}
export default connect(mapStateToProps)(SchedulingManagement)
