import React from 'react'
import {connect} from 'react-redux'
import './IEQ_V4.scss'

const IEQ_V4 = (props) => {
    const locList = getLocsInLevel(props).sort((a, b) => {
        return a.title > b.title
    })
    const locCount = countIEQValues(locList).sort((a, b) => {
        if (a.key < b.key) return -1
        if (a.key > b.key) return 1
        return 0
    })
    const maxCount = locCount.map(item => item.count)

    if(maxCount==null||maxCount.length==null||maxCount.length==0||maxCount[1]==0) {
        return <div className='IEQV4'>
            <div className='head'>IEQ</div>
            <div className='msg'>No IEQ Data</div>        
        </div>
    }

    return <div className='IEQV4'>
        <div className='head'>IEQ</div>
        <div className='list'>
        {
            locCount.map(item => {
                const {title, unit, round, count, sum} = item
                if(count==0) return <></>

                if(item.round == 'string') {
                    let strList = item.array
                    let display = []
                    strList.forEach(strItem => {
                        let isExist = display.find(disItem => disItem == strItem)
                        if(isExist==null) display.push(strItem)
                    })
                    return <div className='row'>
                        <div className='title'>{title}</div>
                        <div className='value'>
                            {
                                display.map(disItem => {
                                    return <div className='displays'>{disItem}</div>
                                })
                            }
                        </div>
                    </div>
                }

                return <div className='row'>
                    <div className='title'>{title}</div>
                    <div className='value'>
                        {(sum/count).toFixed(round)}
                        <div className='unit'>{unit}</div>
                    </div>
                </div>
            })
        }
        </div>
    </div>
}

function mapStateToProps(state) {
    return {
      currentlevel: state.currentlevel,
      currentSite: state.currentSite,
      DeviceData: state.DeviceData,
      DeviceDataLastUpdate: state.DeviceDataLastUpdate
    }
}
export default connect(mapStateToProps)(IEQ_V4)

const getLocsInLevel = (props) => {
    const {currentSite, currentlevel, DeviceData} = props
    if(currentSite==null||currentlevel==null||DeviceData==null) return []

    const siteItem = DeviceData[currentSite]
    if(siteItem==null) return []

    const levelItem = siteItem.nestedLocs.find(item => {
        return item.locName == currentlevel
    })
    if(levelItem==null) return []

    return levelItem.nestedLocs
}

const IEQItem = [
    {
        key: 'temperature',
        title: 'Temp',
        unit: '°C',
        round: 1
    },
    {
        key: 'humidity',
        title: 'Humid',
        unit: '%',
        round: 1
    },
    {
        key: 'co',
        title: 'CO',
        unit: 'ppm',
        round: 1
    },
    {
        key: 'co2',
        title: 'CO2',
        unit: 'ppm',
        round: 1
    },
    {
        key: 'o3',
        title: 'O3',
        unit: 'ppm',
        round: 1
    },
    {
        key: 'hcho',
        title: 'HCHO',
        unit: 'ppm',
        round: 1
    },
    {
        key: 'pm01',
        title: 'PM01',
        unit: 'µg/m³',
        round: 1
    },
    {
        key: 'pm2_5',
        title: 'PM2.5',
        unit: 'µg/m³',
        round: 1
    },
    {
        key: 'pm10',
        title: 'PM10',
        unit: 'µg/m³',
        round: 1
    },
    {
        key: 'nh3',
        title: 'NH3',
        unit: 'ppm',
        round: 1
    },
    {
        key: 'no2',
        title: 'NO2',
        unit: 'ppm',
        round: 1
    },
    {
        key: 'tvoc',
        title: 'TVOC',
        unit: 'ppb',
        round: 1
    },
    // {
    //     key: 'windDirection',
    //     title: 'Wind Direction',
    //     unit: '',
    //     round: 'string'
    // },
    // {
    //     key: 'windSpeed',
    //     title: 'Wind Speed',
    //     unit: '',
    //     round: 1
    // },
    {
        key: 'sound',
        title: 'Sound',
        unit: 'dB',
        round: 1
    },
    {
        key: 'light',
        title: 'Light',
        unit: 'lx',
        round: 1
    },
    {
        key: 'motion',
        title: 'Motion',
        unit: '',
        round: 0
    }
]
const countIEQValues = (locDataList) => {
    let countList = IEQItem.map((item) => (
        {
            key: item.key,
            title: item.title,
            unit: item.unit,
            round: item.round,
            count: 0,
            sum: 0.0,
            array: []
        }
    ))
    locDataList.forEach(locItem => {
        const payload = locItem.payload
        let index = 0
        countList.forEach(item => {
            const key = item.key

            if(payload[key] != null) {
                countList[index].count = countList[index].count + 1

                if(item.round == 'string') {    //string item
                    countList[index].array.push(payload[key])
                }
                else {  //number item
                    countList[index].sum = countList[index].sum + parseFloat(payload[key].toString())      //.. different when using string item
                }
            }
            index++
        })
    })

    return countList
}