import React from 'react'
import './SummaryCard3.css'
//Redux Import
import { connect } from 'react-redux'
//import 2 highchat libraries
import HighCharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
//loading effect widgets
import Loading from '../../../loading'

class SummaryCard3 extends React.Component {
    constructor(props)
    {
        super(props);

        this.innercard = React.createRef()
        this.chartComponent = React.createRef()

        this.chartCalSize = this.chartCalSize.bind(this)
        this.powerDataRequest = this.powerDataRequest.bind(this)
        //get timestamp to string format
        this.convertDate = this.convertDate.bind(this)
        //function to update highchart reading
        this.updatehighChart = this.updatehighChart.bind(this)

        this.state = {
            highChartOption: null,
            reading: 0,
            isTimeout: false,           //set to true after 10 second
        }
    }

    componentDidMount()
    {
        const _this = this
        this.chartCalSize()
        this.powerDataRequest(this.props)   //request socket to server

        //timeout function
        setTimeout(() => {
            _this.setState({
                isTimeout: true
            })
        }, 10 * 1000)
    }
    componentDidUpdate(preProps) {
        const props = this.props
        //1. check if data is ready
        const {DashboardPower, DashboardPower2} = this.props
        if(DashboardPower == null || DashboardPower2 == null) return        //exception: response data is not ready
        //2. check if is change level/site, request data when changed to new level
        const {currentlevel} = this.props
        const oldCurrentLevel = preProps.currentlevel
        if(currentlevel != oldCurrentLevel) {
            this.chartCalSize()
            //clear power data
            props.dispatch({ type: 'DashboardPower1', data: null})
            props.dispatch({ type: 'DashboardPower2', data: null})
            this.powerDataRequest(props)        //request data
        }
        //3. When no data > have data > save to local state
        if(preProps.DashboardPower==null||preProps.DashboardPower2==null) this.updatehighChart(DashboardPower, DashboardPower2)//function to Update highchart option's json and readings
    }

    convertDate(DateInput)
    {
        let DateMonth = DateInput.getMonth() + 1
        let DateDay = DateInput.getDate()
        if(DateMonth < 10) DateMonth = "0" + DateMonth
        if(DateDay < 10) DateDay = "0" + DateDay
        let DateStr = DateInput.getFullYear() + "-" + DateMonth + "-" + DateDay + "T16:00:00Z"
        return DateStr
    }
    chartCalSize() {
        //set the chart container
        try{
            let container = this.chartComponent.current.container.current
            container.style.height = "100%"
            container.style.width = "100%"
            this.chartComponent.current.chart.reflow()
        }
        catch(err)
        {
            console.log(err)
        }
    }
    powerDataRequest(props)
    {
        //1. timestamp start and end of last 2 month, in string
        let startDate = new Date()
        startDate.setMonth(startDate.getMonth()-1)
        let EndDate = new Date()

        let startDate2 = new Date()
        startDate2.setMonth(startDate2.getMonth()-2)
        let EndDate2 = new Date()
        EndDate2.setMonth(EndDate2.getMonth()-1)
        

        //format the values
        const startDateStr = this.convertDate(startDate)
        const endDatestr = this.convertDate(EndDate)

        const startDate2Str = this.convertDate(startDate2)
        const endDate2str = this.convertDate(EndDate2)

        //get locID
        const {currentlevelID, UserInfo} = props
        if(UserInfo == null) return
        const ClientID = UserInfo.client_id

        // const requestData = {                        //old format's requestData
        //     "msgType": "DeviceStatistics",
        //     "msgID": "DashboardPower1",
        //     "clientID": ClientID,
        //     "deviceType": "ACP",						// IAQ-CO2 / IAQ-TH / ACP (Power)
        //     "timeConstraint": {
        //           "start": startDateStr,
        //           "end": endDatestr,
        //     },
        //     "timeFrequency": {
        //       "unit": "day",                    // month / day / hour / minute
        //       "value": 30
        //     },
        //     "locationConstraint": {
        //       "locLvl": 2,                      // 1 - Site / 2 - Floor / 3 - Location
        //       "id": currentlevelID // location id
        //     },
        //     "locationGrouping": 3,              // 1 - Site / 2 - Floor / 3 - Location
        //     "calculationMethod": "difference"      // average / difference
        // }
        const requestData = {
            "msgType": "LocationStatistics",
            "msgID": "DashboardPower1",
            // "socketId": "8SpxJb0PqsT0zcboAAEa",
            "clientID": ClientID,
            "payload_field": ["acPower"],
            "timeConstraint": {
              "start": startDateStr,
              "end": endDatestr,
            },
            "timeFrequency": {
              "unit": "day",
              "value": 30
            },
            "locationConstraint": {
              "locLvl": 2,
              "id": currentlevelID
            },
            "locationGrouping": 3,
            "calculationMethod": "average" //"difference"
          }

        // const requestData2 = {
        //     "msgType": "DeviceStatistics",
        //     "msgID": "DashboardPower2",
        //     "clientID": ClientID,
        //     "deviceType": "ACP",						// IAQ-CO2 / IAQ-TH / ACP (Power)
        //     "timeConstraint": {
        //           "start": startDate2Str,
        //           "end": endDate2str,
        //     },
        //     "timeFrequency": {
        //       "unit": "day",                    // month / day / hour / minute
        //       "value": 30
        //     },
        //     "locationConstraint": {
        //       "locLvl": 2,                      // 1 - Site / 2 - Floor / 3 - Location
        //       "id": currentlevelID // location id
        //     },
        //     "locationGrouping": 3,              // 1 - Site / 2 - Floor / 3 - Location
        //     "calculationMethod": "difference"      // average / difference
        // }
        const requestData2 = {
            "msgType": "LocationStatistics",
            "msgID": "DashboardPower2",
            "clientID": ClientID,
            "payload_field": ["acPower"],
            "timeConstraint": {
              "start": startDate2Str,
              "end": endDate2str,
            },
            "timeFrequency": {
              "unit": "day",
              "value": 30
            },
            "locationConstraint": {
              "locLvl": 2,
              "id": currentlevelID
            },
            "locationGrouping": 3,
            "calculationMethod": "average" //"difference"
        }
        // console.log({
        //     requestData: requestData,
        //     requestData2: requestData2,
        // })

        //2. emit to socket server
        this.props.dispatch({ type: 'EmitSocket',
            EmitSocketName: "StatRequest",
            EmitSocketData: requestData2
        })
        this.props.dispatch({ type: 'EmitSocket',
            EmitSocketName: "StatRequest",
            EmitSocketData: requestData
        })
    }

    updatehighChart(power1, power2) {
        if(power1 == null || power2 == null) return             //exception case 1
        if(power1.data == null || power2.data == null || power1.data.acPower == null || power2.data.acPower == null) return   //exception case 2 & 3
        const power1Data = power1.data.acPower      //this month (July, Aug) *more than one reading on same time
        const pwoer2Data = power2.data.acPower      //previous month (June, July) * more than one reading on same

        //for pwoer 1, calculate the different of each device, then Sum of the difference
        let firstTimestamp = null
        let firstGroupSum = 0
        let SecondGroupSum = 0
        power1Data.forEach(function(powerItem) {
            if(firstTimestamp == null) firstTimestamp = powerItem.time     //Group A timestamp, Group B is another one
            if(firstTimestamp == powerItem.time) firstGroupSum += powerItem.acPower     //group A Sum
            else SecondGroupSum += powerItem.acPower                                    //group B Sum
        })
        const power1Diff = (firstGroupSum>=SecondGroupSum)?(firstGroupSum-SecondGroupSum):(SecondGroupSum-firstGroupSum)

        //for power 2, calculate the different of each device, then Sum of the difference (same as above)
        let firstTimestamp2 = null
        let firstGroupSum2 = 0
        let SecondGroupSum2 = 0
        pwoer2Data.forEach(function(powerItem) {
            if(firstTimestamp2 == null) firstTimestamp2 = powerItem.time

            if(firstTimestamp2 == powerItem.time) firstGroupSum2 += powerItem.acPower
            else SecondGroupSum2 += powerItem.acPower
        })
        const power2Diff = (firstGroupSum2>=SecondGroupSum2)?(firstGroupSum2-SecondGroupSum2):(SecondGroupSum2-firstGroupSum2)

        //Set the reading variables
        const MaxReading = power2Diff * 1.5
        const currentReading = Math.abs(power1Diff)
        const previousReading = Math.abs(power2Diff)
        const reading_percent = currentReading/MaxReading                                                               //percentage of current month value in chart
        const previous_percent = (previousReading-currentReading>=0)?(previousReading-currentReading)/MaxReading:0      //if this month value > last month, set to 0, else show another color as the extend of previous month value
        const remain_percent = Math.abs((MaxReading-previousReading-currentReading)/MaxReading)                         //remain empty space

        //generate the highchart required parameters to local states
        let fillColor = "purple"
        let fillColor2 = "grey"
        if(reading_percent <0.2)
        {
            fillColor = "#00fadb"
            fillColor2 = "#00fadb8f"
        }
        else if(reading_percent <0.4)
        {
            fillColor = "#0093fa"
            fillColor2 = "#0093fa"
        }
        else if(reading_percent <0.6)
        {
            fillColor = "#ffb102"
            fillColor2 = "#ffb102"
        }
        else if(reading_percent <0.8)
        {
            fillColor = "red"
            fillColor2 = "#ff8100"
        }
        else
        {
            fillColor = "red"
            fillColor2 = "purple"
        }
        const options = {
            tooltip: {
                enabled: true,
                formatter: function() {
                    //check the hover item name
                    //console.log(this.colorIndex);
                    switch(this.colorIndex)
                    {
                        case 0:
                            return "This Month Power Usage: " + currentReading.toFixed(2)
                        case 1:
                            return "Last Month Power Usage: " + previousReading.toFixed(2)
                        case 2:
                            return "Remain Power Range"
                        default:
                            return "Switch hover item failed!"
                    }
               }
            },
            credits: true,
            title: {
              text: ''
            },
            chart: {
                backgroundColor: 'transparent'
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: true,
                        distance: -50,
                        style: {
                            fontWeight: 'bold',
                            color: 'white'
                        }
                    },
                    startAngle: -90,
                    endAngle: 90,
                    center: ['50%', '75%'],
                    size: '110%'
                }
            },
            series: [{
                type: 'pie',
                name: '',
                innerSize: '50%',
                data: [
                    {
                        name: '',
                        y: reading_percent,
                        sliced: false,
                        selected: true
                    },
                    ['', previous_percent],
                    ['', remain_percent]
                ]
            }],
            colors: [{
                linearGradient: [0, 0, 0, 300],
                stops: [
                    [0, fillColor],
                    [2, fillColor2]
                ]
            },
            '#00000033',
            {
                linearGradient: [0, 0, 0, 300],
                stops: [
                    [0, 'rgba(0,0,0,.3)'],
                    [1, 'rgba(0,0,0,.1)']
                ]
            }]
        }
        this.setState({highChartOption: options, reading: currentReading})
    }
    
    render() {
        const {highChartOption, reading, isTimeout} = this.state
        const ReadingDisplay = (reading != "NaN" && reading != '0.00' && reading != '0')?reading.toFixed(2):"--"

        const {DashboardPower, DashboardPower2} = this.props

        //timeout gui
        if((DashboardPower == null && DashboardPower2 == null && isTimeout)) {
           return <div className="smallcard_left_bottom">
                <div className="text-;eft small_head">
                    <i className="fa fa-bolt headericon"></i>
                    AC Power Monthly
                </div>
                <div className="small_body">
                    <div className="TimeoutText">
                        Timeout: No Data
                    </div>
                </div>
            </div>
        }

        return <div className="smallcard_left_bottom" onLoad={this.chartCalSize}>
            <div className="text-;eft small_head">
                <i className="fa fa-bolt headericon"></i>
                AC Power Monthly
            </div>
            <div className="flex small_body" ref={this.innercard}>
                {
                    (DashboardPower == null || DashboardPower2 == null)?<div className="SummaryCardACPower_Loading"><Loading/></div>:<div className="hide"></div>
                }
                <div className={(DashboardPower == null || DashboardPower2 == null)?"powerCardChart width0":"powerCardChart"}>
                    <HighchartsReact highcharts={HighCharts} options={highChartOption} ref={this.chartComponent} constructorType={"chart"} containerProps={{ style: {  } }}/>
                </div>
                <div className="powerCardReadingDiv">
                    <div className="powerCardReadingTxt">
                        {ReadingDisplay}
                    </div>
                    <div className="powerCardReadingUnit">
                        kWh
                    </div>
                </div>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
      currentlevel: state.currentlevel,
      UserInfo: state.UserInfo,
      DeviceDataLastUpdate: state.DeviceDataLastUpdate,
      currentlevelID: state.currentlevelID,
      DashboardPower: state.DashboardPower,
      DashboardPower2: state.DashboardPower2,
    }
}
export default connect(mapStateToProps)(SummaryCard3)