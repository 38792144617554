import React from 'react';
import './alarmTriggerPopup.css';
//Redux Import
import { connect } from 'react-redux';

import AlarmTriggerDevice from '../pickDevice/AlarmTriggerDevice';
import RealTimeAlert from '../../Navbar/RealTimeAlert';

class AlarmTriggerPopup extends React.Component {
    constructor(props)
    {
        super(props);

        //this function would set all this.state from select>option onchange
        this.handleInputChage = this.handleInputChage.bind(this);
        //this function would set all the this.state from inputbox onchange
        this.handleInputBoxChange = this.handleInputBoxChange.bind(this);

        this.submit = this.submit.bind(this);

        //when device list component have updated, trigger this function from inner component
        this.updateDevice = this.updateDevice.bind(this);

        this.CreateAlarmTrigger = this.CreateAlarmTrigger.bind(this);
        this.ModifyAlarmTrigger = this.ModifyAlarmTrigger.bind(this);

        //pick sms and email send targets
        this.sendOptions = this.sendOptions.bind(this)

        this.state = {
            AlarmType: "",
            PickSite: "",
            PickFloor: "",
            PickDeviceList: [],
            TitleName: "",
            LimitType: "Upper",     //default picked item
            LimitValue: "",
            Priority: "Normal",     //default picked item
            isEnabled: true,
            SendEmail: [],           //Send Email to some positions
            SendSms: [],             //Send SMS to some positions
            AlarmMsg: "",
            LimitReadingType: "",

            selectedDevices: [],
        };

        this.DeviceListRef = React.createRef(); //Ref also need to set inside the component to make calling function in inner component to work
    }
    componentDidMount()
    {
        this.props.onRef(this);             //add this component to ref
    }
    componentWillMount()
    {
        this.props.onRef(undefined);        //remove this component from ref
    }
    updateDevice(newDeviceList)
    {
        //console.log('updateDevice()');
        //console.log(newDeviceList);
        this.setState({
            selectedDevices: newDeviceList
        })
    }
    clearDeviceList()
    {
        console.log(this.DeviceListRef);
        //trigger function inside deviceList component, clean the alarm
        this.DeviceListRef.ClearPickedDevices();
    }
    forceUpdateDevice(TriggerDeviceList)
    {
        //this function would update currenct list, and the list in inner Device component
        this.DeviceListRef.EditUpdatePickedDevices(TriggerDeviceList);
    }
    submit()
    {
        //this.RealTimeAlertRef.newAlertNotification("Submit function is not prepared", 
        //"Please try later", "", true);

        //check is 'Create' or 'Modify'
        const {currentTrigger} = this.props
        const thisoutside = this
        if(currentTrigger == null) thisoutside.CreateAlarmTrigger() //create
        else thisoutside.ModifyAlarmTrigger()                       //Modify
    }
    CreateAlarmTrigger()
    {
        //get all the inputs from this popup
        const {TitleName, AlarmMsg, Priority, selectedDevices, LimitValue, LimitType, LimitReadingType} = this.state
        let alarmComponentData = []
        try
        {
            selectedDevices.forEach(DeviceItem => {
                alarmComponentData.push({
                    "id" : DeviceItem._id,
                    "lvl" : DeviceItem.locLvl,
                    "name" : DeviceItem.locName
                })
            })
        }
        catch(err)
        {
            console.log(err)
        }
        console.log(alarmComponentData)
        //get inputs in REDUX
        const {UserInfo} = this.props
        const client_id = UserInfo.client_id
        const userID = UserInfo.userID
        //build Rule json format
        //LimitValue, LimitType, LimitReadingType
        let LimitInput
        const LimitValueNumber = Number(LimitValue)
        switch(LimitType)
        {
            case "Upper":
                LimitInput = { ">=": [{ "var": LimitReadingType }, LimitValueNumber] }
                break
            case "Lower":
                LimitInput = { "<=": [{ "var": LimitReadingType }, LimitValueNumber] }
                break
            case "UpperMore":
                LimitInput = { ">": [{ "var": LimitReadingType }, LimitValueNumber] }
                break
            case "LowerMore":
                LimitInput = { "<": [{ "var": LimitReadingType }, LimitValueNumber] }
                break
            case "NotEqual":
                LimitInput = { "!==": [{ "var": LimitReadingType }, LimitValue] }
                break
            case "gateway":
                LimitInput = { "===": [{ "var": LimitReadingType }, "Disconnected"] }
                break
            case "On":
                LimitInput = { "===": [{ "var": LimitReadingType }, true] }
                break
            case "Off":
                LimitInput = { "===": [{ "var": LimitReadingType }, false] }
                break
        }
        //Send Email and SMS options
        const {SendEmail, SendSms} = this.state
        //Alarm trigger type
        const {AlarmType} = this.state
        //make json format
        const emitJson = {
            "msgType": "AlarmSettings",
            "method": "Create",
            // "socketId": "8SpxJb0PqsT0zcboAAEa",
            "userID": userID,
            "severity": Priority,
            "isEnabled": true,
            "client_id": client_id,
            "locationInfo": alarmComponentData,
            "alarmComponentType": AlarmType,
            "name": TitleName,
            "message": AlarmMsg,
            "rule": LimitInput,
            "emailReceive" : SendEmail,
            "smsReceive" : SendSms
        }
        console.log(emitJson)
        //emit socket with redux function
        this.props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "AlarmRequest",
            EmitSocketData: emitJson
        })
        this.props.closepopup()        //clsoe the popup and waait the response
    }
    ModifyAlarmTrigger()
    {
        const {TitleName, AlarmMsg, Priority, AlarmType, selectedDevices, LimitValue, LimitType, LimitReadingType} = this.state
        let alarmComponentData = []
        try
        {
            selectedDevices.forEach(DeviceItem => {
                if(DeviceItem.id != null) {
                    //original item's format, location items
                    alarmComponentData.push(DeviceItem)
                }
                else if(DeviceItem._id != null && DeviceItem.lvl != null && DeviceItem.name != null) {
                    //original site item's format
                    alarmComponentData.push({
                        "id" : DeviceItem._id,
                        "lvl" : DeviceItem.lvl,
                        "name" : DeviceItem.name
                    })
                }
                else {
                    //new input item's format
                    alarmComponentData.push({
                        "id" : DeviceItem._id,
                        "lvl" : DeviceItem.locLvl,
                        "name" : DeviceItem.locName
                    })
                }
            })
        }
        catch(err)
        {
            console.log(err)
        }

        //get inputs in REDUX
        const {UserInfo} = this.props
        const client_id = UserInfo.client_id
        const userID = UserInfo.userID
        
        //build Rule json format
        let LimitInput
        const LimitValueNumber = Number(LimitValue)
        switch(LimitType)
        {
            case "Upper":
                LimitInput = { ">=": [{ "var": LimitReadingType }, LimitValueNumber] }
                break
            case "Lower":
                LimitInput = { "<=": [{ "var": LimitReadingType }, LimitValueNumber] }
                break
            case "UpperMore":
                LimitInput = { ">": [{ "var": LimitReadingType }, LimitValueNumber] }
                break
            case "LowerMore":
                LimitInput = { "<": [{ "var": LimitReadingType }, LimitValueNumber] }
                break
            case "NotEqual":
                LimitInput = { "!==": [{ "var": LimitReadingType }, LimitValue] }
                break
            case "gateway":
                LimitInput = { "===": [{ "var": LimitReadingType }, "Disconnected"] }
                break
            case "On":
                LimitInput = { "===": [{ "var": LimitReadingType }, true] }
                break
            case "Off":
                LimitInput = { "===": [{ "var": LimitReadingType }, false] }
                break
        }
        //Send Email and SMS options
        const {SendEmail, SendSms} = this.state
        //get alarm trigger ID
        const {currentTrigger} = this.props
        const AlarmTriggerID = currentTrigger.id
        const emitJson = {
            "msgType": "AlarmSettings",
            "method": "Update",
            // "socketId": "8SpxJb0PqsT0zcboAAEa",
            "userID": userID,
            "id": AlarmTriggerID,
            "severity": Priority,
            "isEnabled": true,	                        //..
            "client_id": client_id,
            "locationInfo": alarmComponentData,
            "alarmComponentType": AlarmType,
            "name": TitleName,
            "message": AlarmMsg,
            "rule": LimitInput,
            "emailReceive": SendEmail,
            "smsReceive": SendSms
        }        
        console.log(emitJson)
        //emit socket with redux function
        this.props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "AlarmRequest",
            EmitSocketData: emitJson
        })
        
        this.props.closepopup()        //clsoe the popup and waait the response
    }
    handleInputChage(event)
    {
        //console.log('handleInputChage()');
        //console.log({[event.target.name]: event.target.value});
        this.setState({
            [event.target.name]: event.target.value
        })
    }
    handleInputBoxChange(event)
    {
        this.setState({
            [event.target.name]: event.target.value
        })
    }
    componentWillReceiveProps(nextProps)
    {
        const {currentTrigger} = nextProps
        const oldTrigger = this.props.currentTrigger
        const thisoutside = this
        if(currentTrigger == null)
        {
            console.warn('currentTrigger input is null.')
            //reset the state to create new account state (initial state)
            thisoutside.setState({
                AlarmType: "",
                PickSite: "",
                PickFloor: "",
                PickDeviceList: [],
                TitleName: "",
                LimitType: "Upper",     //default picked item
                LimitValue: "",
                Priority: "Normal",     //default picked item
                isEnabled: true,
                SendEmail: [],
                SendSms: [],
                AlarmMsg: "",
                LimitReadingType: "",

                //this part cannot reset state inside deviceComponent
                //need to run Ref.xx function to clear with inner function in deviceComponent
                selectedDevices: [],
            })
            //for create new trigger popup, this is the end, return
            return
        }
        //for modify trigger, would need to update the state in the continue codes
        // console.log({
        //     currentTrigger: currentTrigger,
        //     oldTrigger: oldTrigger
        // })

        let LimitReadingType = ""
        //Limit Type (relative to currentTrigger.rule)
        let LimitType = ""
        const ruleInput = currentTrigger.rule
        //check if there is 'status' string inside ruleInput (GATEWAY)
        let statusInside = ruleInput.includes("Disconnect")    //'status' or 'Disconnected'
        if(statusInside)
        {
            LimitType = "gateway"
            LimitReadingType = "status"
        }
        //check if there is '>= or <=' (UPPER or LOWER LIMIT + Device Type e.g. temperature)
        const isUpperLimit = ruleInput.includes("larger than")
        const isLowerLimit = ruleInput.includes("less than")
        const orEquapsto = ruleInput.includes("or equal to")
        if(isUpperLimit)
        {
            if(orEquapsto) LimitType = "Upper"
            else LimitType = "UpperMore"
        }
        else if(isLowerLimit)
        {
            if(orEquapsto) LimitType = "Lower"
            else LimitType = "LowerMore"
        }
        //check if there is '===' (ON TRIGGER ON/OFF)
        const isNotEquals = ruleInput.includes("Not")        // 'trigger false'
        const islightOn = ruleInput.includes("lightOn")
        const isroomOccupied = ruleInput.includes("roomOccupied")
        const isstatus = ruleInput.includes("status")
        if(islightOn || isroomOccupied || isstatus)
        {
            if(!isNotEquals) LimitType = "On"
            else LimitType = "Off"
        }
        const istemperature = ruleInput.includes("temperature")
        const ishumidity = ruleInput.includes("humidity")
        const isco2 = ruleInput.includes("co2")
        const ispm2_5 = ruleInput.includes("pm2_5")
        const isacPower = ruleInput.includes("acPower")
        if(islightOn) LimitReadingType = "lightOn"
        else if(isroomOccupied) LimitReadingType = "roomOccupied"
        else if(isstatus) LimitReadingType = "status"
        else if(istemperature) LimitReadingType = "temperature"
        else if(ishumidity) LimitReadingType = "humidity"
        else if(isco2) LimitReadingType = "co2"
        else if(ispm2_5) LimitReadingType = "pm2_5"
        else if(isacPower) LimitReadingType = "acPower"

        //Limit Value           //ruleInput
        const ruleSplitIfTo = ruleInput.split("to")
        const ruleSplitIfThan = ruleInput.split("than")
        let ruleValueNumber = null
        if(ruleSplitIfTo.length > 1 || ruleSplitIfThan.length > 1)
        {
            const ruleValue = ruleSplitIfTo[1]
            //convert ruleValue to number
            ruleValueNumber = Number(ruleValue)
        }

        //update the state in this component
        this.setState({
            AlarmType: currentTrigger.alarmComponentType,
            PickSite: "",
            PickFloor: "",
            PickDeviceList: currentTrigger.locationInfo, 
            TitleName: currentTrigger.name,
            LimitType: LimitType,
            LimitValue: ruleValueNumber,
            LimitReadingType: LimitReadingType,
            Priority: currentTrigger.severity,
            isEnabled: currentTrigger.isEnabled,
            SendEmail: currentTrigger.emailReceive,
            SendSms: currentTrigger.smsReceive,
            AlarmMsg: currentTrigger.message,
            selectedDevices: currentTrigger.locationInfo,
        })

        //trigger update function of device component
        this.forceUpdateDevice(currentTrigger.locationInfo)
    }

    sendOptions(type, userType) {
        const thisoutside = this
        const {SendEmail, SendSms} = this.state         //2 array parameters
        if(type == "SMS") {
            const findTypeInState = SendSms.find(function(SMSPickedItem) {
                return SMSPickedItem == userType
            })
            let newSendSms = SendSms
            if(findTypeInState == null) newSendSms.push(userType)   //no such item, add it into the state
            else {
                //have this item, remove it from the state
                newSendSms = SendSms.filter(function(SMSPickedItem) {
                    return SMSPickedItem != userType
                })
            }
            //set the new local State value
            thisoutside.setState({SendSms: newSendSms})
        }
        else if(type == "Email") {
            const findTypeInState = SendEmail.find(function(EmailPickedItem) {
                return EmailPickedItem == userType
            })
            let newSendEmail = SendEmail
            if(findTypeInState == null) newSendEmail.push(userType)   //no such item, add it into the state
            else {
                //have this item, remove it from the state
                newSendEmail = SendEmail.filter(function(EmailPickedItem) {
                    return EmailPickedItem != userType
                })
            }
            //set the new local State value
            thisoutside.setState({SendEmail: newSendEmail})
        }
    }

    render()
    {
        const {popupClass, currentTrigger, UserInfo} = this.props
        const {PickSite, PickFloor, SendEmail, SendSms} = this.state
        //console.log(this.state);
        let popupTitle = "";
        if(currentTrigger == null)
        {
            popupTitle = "Create new Alarm Trigger";
        }
        else
        {
            popupTitle = "Modify Alarm Trigger";
        }

        //after picked site / floor, load the relative list items
        let SiteList = [];
        let FloorList = [];
        let Devicelist = [];    //should be the location list
        if(UserInfo == null)
        {
            return <div>...</div>
        }
        SiteList = UserInfo.locations;
        if(PickSite != "" && PickSite != "-Site-")   //if not set, keep the list empty
        {
            let pickedSite = UserInfo.locations.find(function(siteItem){
                return siteItem.locName == PickSite
            })
            if(pickedSite != null) FloorList = pickedSite.nestedLocs
        }
        //console.log(PickFloor);
        if(PickFloor != "" && PickFloor != "-Floor-")
        {
            let pickedFloor = FloorList.find(function(floorItem){
                return floorItem.locName == PickFloor
            })
            //console.log(FloorList);
            if(pickedFloor != null)
            {
                Devicelist = pickedFloor.nestedLocs;
            }
        }
        //site List for picking
        if(PickSite == "" || PickSite == "-Site-") Devicelist = UserInfo.locations
        //level List for picking
        if(PickFloor == "" || PickFloor == "-Floor-") {
            let pickedSite = UserInfo.locations.find(function(siteItem){
                return siteItem.locName == PickSite
            })
            if(pickedSite != null)  Devicelist = pickedSite.nestedLocs
        }

        //sms and email option class
        let SMS1 = ""
        let SMS2 = ""
        let SMS3 = ""
        let SMS4 = ""
        let Email1 = ""
        let Email2 = ""
        let Email3 = ""
        let Email4 = ""
        SendSms.forEach(EmailItem => {
            switch(EmailItem) {
                case "System Admin":
                    SMS1 = "SendOptionOn"
                    break
                case "Client Admin":
                    SMS2 = "SendOptionOn"
                    break
                case "Site Admin":
                    SMS3 = "SendOptionOn"
                    break
                case "Operators":
                    SMS4 = "SendOptionOn"
                    break
            }
        })
        SendEmail.forEach(SMSItem => {
            switch(SMSItem) {
                case "System Admin":
                    Email1 = "SendOptionOn"
                    break
                case "Client Admin":
                    Email2 = "SendOptionOn"
                    break
                case "Site Admin":
                    Email3 = "SendOptionOn"
                    break
                case "Operators":
                    Email4 = "SendOptionOn"
                    break
            }
        })
        console.log(this.state)

        return [            
            <div className={"AT_popupBg " + popupClass} onClick={this.props.closepopup}></div>,
            <div className={"AT_popup " + popupClass}>
                <div className="fa fa-times AT_popupCloseBtn" onClick={this.props.closepopup}></div>
                <div className="AT_popupTitle">{popupTitle}</div>
                <div className="AT_popupInputContainer">
                    <select className="AT_popupAlarmTypePicker" name="AlarmType" onChange={this.handleInputChage} 
                     value={this.state.AlarmType}>
                        <option value="">-Alarm Type-</option>
                        <option value="Device">Device</option>
                        <option value="System">System</option>
                        <option value="Equipment">Equipment</option>
                    </select>
                        <input type="text" className="AT_popupInput" placeholder="Title Name" name="TitleName" 
                        value={this.state.TitleName} onChange={this.handleInputBoxChange}></input>
                        <div className="AT_popupInput_priorityTxt">Priority</div>
                        <select className="AT_popupPriority" name="Priority" onChange={this.handleInputChage}
                         value={this.state.Priority}>
                            <option value="Normal">Normal</option>
                            <option value="Urgent">Urgent</option>
                            <option value="High">High</option>
                            <option value="Low">Low</option>
                        </select>
                        <select className="AT_popupSitePicker" name="PickSite" onChange={this.handleInputChage} 
                        value={this.state.PickSite}>
                            <option>-Site-</option>
                            {
                                SiteList.map(function(siteItem){
                                    return <option value={siteItem.locName}>
                                        {siteItem.locName}
                                        </option>
                                })
                            }
                        </select>
                        <select className="AT_popupPickFloor" name="PickFloor" onChange={this.handleInputChage} 
                     value={this.state.PickFloor}>
                            <option value="">-Floor-</option>
                            {
                                FloorList.map(function(floorItem){
                                    return <option value={floorItem.locName}>
                                        {floorItem.locName + "/F"}
                                    </option>
                                })
                            }
                        </select>
                    <div className="AT_DeviceListContainer">
                        <AlarmTriggerDevice Devicelist={Devicelist} 
                        onRef={ref => (this.DeviceListRef = ref)}
                        updateDevice={this.updateDevice}/>
                    </div>
                    <div className="AT_popupTriggerContainer">
                        <select className="AT_popupTriggerType" value={this.state.LimitType} 
                        onChange={this.handleInputChage} name="LimitType">
                            <option value="Upper">Upper Limit</option>
                            <option value="UpperMore">Upper (MoreThan)</option>
                            <option value="Lower">Lower Limit</option>
                            <option value="LowerMore">Lower (LowerThan)</option>
                            <option value="On">On Trigger (true)</option>
                            <option value="Off">Off Trigger (false)</option>
                            <option value="NotEqual">Not Equal to</option>
                            <option value="gateway">GateWay</option>
                        </select>
                        <select className="AT_popupTriggerType" value={this.state.LimitReadingType} 
                        onChange={this.handleInputChage} name="LimitReadingType">
                            <option value="">-Limit Type-</option>
                            <option value="lightOn">Light On/Off</option>
                            <option value="acPower">AC Power</option>
                            <option value="status">Status</option>
                            <option value="co2">CO2</option>
                            <option value="pm2_5">PM2.5</option>
                            <option value="roomOccupied">Occupied</option>
                            <option value="temperature">Temperature</option>
                            <option value="humidity">Humidity</option>
                        </select>
                        <input type="number" className="AT_popupTriggerValue" placeholder="Limit Value"
                        value={this.state.LimitValue} onChange={this.handleInputChage} name="LimitValue"></input>
                    </div>
                    <div className="AT_checkboxRowContainer">
                        <div className="AT_popupEnableContainer">
                            <input type="checkbox" id="Enable" name="Enable" checked/>
                            <label for="Enable"><span></span>Enable</label>
                        </div>
                        <div className="AT_popupEnableContainer">
                            <input type="checkbox" id="Settle" name="Settle" />
                            <label for="Settle"><span></span>Re-send alarm if not settled</label>
                        </div>
                    </div>
                </div>
                <div className="AT_popupSendOptionContainer">
                    <div className="AT_popupSendEmailTxt">Send Alarm to Email:</div>
                    <div className="AT_popupSendEmailContainer">
                        <div className={"AT_popupSendEmailItem " + Email1} onClick={() => this.sendOptions("Email", "System Admin")}>System Admin</div>
                        <div className={"AT_popupSendEmailItem " + Email2} onClick={() => this.sendOptions("Email", "Client Admin")}>Client Admin</div>
                        <div className={"AT_popupSendEmailItem " + Email3} onClick={() => this.sendOptions("Email", "Site Admin")}>Site Admin</div>
                        <div className={"AT_popupSendEmailItem " + Email4} onClick={() => this.sendOptions("Email", "Operators")}>Operators</div>
                    </div>
                    <div className="AT_popupSendSMSTxt">Send Alarm to SMS:</div>
                    <div className="AT_popupSendSMSContainer">
                        <div className={"AT_popupSendSMSItem " + SMS1} onClick={() => this.sendOptions("SMS", "System Admin")}>System Admin</div>
                        <div className={"AT_popupSendSMSItem " + SMS2} onClick={() => this.sendOptions("SMS", "Client Admin")}>Client Admin</div>
                        <div className={"AT_popupSendSMSItem " + SMS3} onClick={() => this.sendOptions("SMS", "Site Admin")}>Site Admin</div>
                        <div className={"AT_popupSendSMSItem " + SMS4} onClick={() => this.sendOptions("SMS", "Operators")}>Operators</div>
                    </div>
                </div>
                <textarea rows="6" cols="50" placeholder="Alarm Message" className="AT_popupAlarmMsg" name="AlarmMsg" 
                        value={this.state.AlarmMsg} onChange={this.handleInputBoxChange}
                >
                </textarea>
                <div className="AT_popupBtnContainer">
                    <div className="AT_popupSubBtn" onClick={this.submit}>Submit</div>
                    <div className="AT_popupCancelBtn" onClick={this.props.closepopup}>Cancel</div>
                </div>
            </div>,
            <RealTimeAlert onRef={ref => (this.RealTimeAlertRef = ref)} />
        ]
    }
}

//Redux inside component function
function mapStateToProps(state) {
    return {
      UserInfo: state.UserInfo,
      socket: state.socket
    };
}

export default connect(mapStateToProps)(AlarmTriggerPopup);