import React from 'react'
import './ToiletOcc.css'

//Redux Import
import { connect } from 'react-redux'

class ToiletOcc extends React.Component {
    constructor(props)
    {
        super()
    }

    render()
    {
        const {DeviceData, currentSite, currentlevel} = this.props
        if(DeviceData == null || currentSite == null || currentlevel == null) return <div></div>            //exceptional cases
        const CurrentFloorItem = DeviceData[currentSite].nestedLocs.find(function(floorItem) {return floorItem.locName == currentlevel})
        const CurrentDeviceList = CurrentFloorItem.nestedLocs
        //console.log(CurrentDeviceList)

        if(CurrentDeviceList == null || CurrentDeviceList.length == null || CurrentDeviceList.length == 0) return <div className="ToiletOcc_Summary">
            <div className="ToiletOcc_header">
                <div className="fa fa-user"></div>
                <div className="ToiletOcc_headertxt">
                    Toilet Occupancy
                </div>
            </div>
            <div className="ToiletOcc_Inner">
                <div className="ToiletOcc_Male">
                    <div className="ToiletOcc_Txt">
                        <div className="fa fa-male"></div>
                    </div>
                    <div className="ToiletOcc_Reading">
                        No Data
                    </div>
                </div>
                <div className="ToiletOcc_FMale">
                    <div className="ToiletOcc_Txt">
                        <div className="fa fa-female"></div>
                    </div>
                    <div className="ToiletOcc_Reading">
                        No Data
                    </div>
                </div>
            </div>
        </div>

        //fallDetected: "fallDetected"
        //roomOccupied: "roomOccupied"
        const ToiletList = CurrentDeviceList.filter(function(deviceItem) {
            if(deviceItem == null || deviceItem.payload_definition_id == null) return
            return deviceItem.payload_definition_id.name == "Door Contact"
        })
        console.log(ToiletList)
        let maleCount = 0
        let mOccCount = 0
        let fMaleCount = 0
        let fOccCount = 0

        ToiletList.forEach(locItem => {
            if(locItem.locName == "CIC-DR001" || locItem.locName == "CIC-DR002" || locItem.locName == "CIC-DR003") {
                fMaleCount++
                if(locItem.payload != null && locItem.payload.doorOpen != null && locItem.payload.doorOpen == false) fOccCount++
            }
            else if(locItem.locName == "CIC-DR004" || locItem.locName == "CIC-DR005") {
                maleCount++
                if(locItem.payload != null && locItem.payload.doorOpen != null && locItem.payload.doorOpen == false) mOccCount++
            }
        })
        
        return <div className="ToiletOcc_Summary">
            <div className="ToiletOcc_header">
                <div className="fa fa-user"></div>
                <div className="ToiletOcc_headertxt">
                    Toilet Occupancy
                </div>
            </div>
            <div className="ToiletOcc_Inner">
                <div className="ToiletOcc_Male">
                    <div className="ToiletOcc_Txt">
                        <div className="fa fa-male"></div>
                    </div>
                    <div className="ToiletOcc_Reading">
                        {
                            mOccCount.toString() + "/" + maleCount.toString()
                        }
                    </div>
                </div>
                <div className="ToiletOcc_FMale">
                    <div className="ToiletOcc_Txt">
                        <div className="fa fa-female"></div>
                    </div>
                    <div className="ToiletOcc_Reading">
                        {
                            fOccCount.toString() + "/" + fMaleCount.toString()
                        }
                    </div>
                </div>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
      DeviceData: state.DeviceData,
      currentlevel: state.currentlevel,
      currentSite: state.currentSite,      
      DeviceDataLastUpdate: state.DeviceDataLastUpdate,
    }
}
  
export default connect(mapStateToProps)(ToiletOcc)