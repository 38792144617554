import React from 'react'
import './setpoint.css'
import CSVReader from 'react-csv-reader'
import { CSVLink, CSVDownload } from "react-csv"
import { connect } from 'react-redux'
import PopupNotification from '../../../popupNotification/PopupNotification'
import SetpointHead from './header/setpointHead'
import TableHead from './table/Tablehead'
import TableRow from './table/TableRow'
import checkvalid from './checkvalid'
import {GetEmitJson} from './saveSetting'

const Key_desc = [
    {
        title: 'System Start/Stop',
        desc: 'Start/Stop',
        type: 'select',
        display: [
            {
                name: 'Start',
                value: '1'
            },
            {
                name: 'Stop',
                value: '0'
            },
        ],
        key: 'systemStart',
        max: 1,
        min: 0,
    },
    {
        title: 'Coldbox Status',
        desc: 'Empty / Precooling / Loaded / Damage',
        display: [
            {
                name: 'Loaded',
                value: '2'
            },
            {
                name: 'Empty',
                value: '0'
            },
            {
                name: 'Precooling',
                value: '1'
            },
            {
                name: 'Damage',
                value: '3'
            },
        ],
        type: 'select',
        key: 'status',
        max: 3,
        min: 0,
    },
    {
        title: 'Coldbox Temperature',
        desc: '0-25 C',
        type: 'Number',
        display: 'temp (C)',
        key: 'temp',
        max: 25,
        min: 0,
    },
    {
        title: 'Temperature 1 Weighted Factor',
        desc: '20%',
        type: 'Number',
        display: 'Factor',
        key: 'temp1Factor',
        max: 100,
        min: 0,
    },
    {
        title: 'Temperature 2 Weighted Factor',
        desc: '20%',
        type: 'Number',
        display: 'Factor',
        key: 'temp2Factor',
        max: 100,
        min: 0,
    },
    {
        title: 'Temperature 3 Weighted Factor',
        desc: '20%',
        type: 'Number',
        display: 'Factor',
        key: 'temp3Factor',
        max: 100,
        min: 0,
    },
    {
        title: 'Temperature 4 Weighted Factor',
        desc: '20%',
        type: 'Number',
        display: 'Factor',
        key: 'temp4Factor',
        max: 100,
        min: 0,
    },
    {
        title: 'Temperature 5 Weighted Factor',
        desc: '20%',
        type: 'Number',
        display: 'Factor',
        key: 'temp5Factor',
        max: 100,
        min: 0,
    },
    {
        title: 'Return Air Weighted Factor',
        desc: '20%',
        type: 'Number',
        display: 'Factor',
        key: 'returnAirFactor',
        max: 100,
        min: 0,
    },
    {
        title: 'High Temperature Warning',
        desc: '-20-80 C',
        type: 'Number',
        display: 'temp (C)',
        key: 'highTemp',
        max: 80,
        min: -20,
    },
    {
        title: 'Low Temperature Warning',
        desc: '-20-80 C',
        type: 'Number',
        display: 'temp (C)',
        key: 'lowTemp',
        max: 80,
        min: -20,
    },
    {
        title: 'High Condensing Pressure Alarm',
        desc: '0-40 Bar',
        type: 'Number',
        display: 'Bar',
        key: 'HCondPress',
        max: 40,
        min: 0,
    },
    {
        title: 'Low Condensing Pressure Alarm',
        desc: '0-40 Bar',
        type: 'Number',
        display: 'Bar',
        key: 'LCondPress',
        max: 40,
        min: 0,
    },
    {
        title: 'High RH Warning',
        desc: '0-100%',
        type: 'Number',
        display: '%',
        key: 'HRH',
        max: 40,
        min: 0,
    },
    {
        title: 'Low RH Warning',
        desc: '0-100%',
        type: 'Number',
        display: '%',
        key: 'LRH',
        max: 40,
        min: 0,
    },
    {
        title: 'High Suction Temperature Warning',
        desc: '0-100C',
        type: 'Number',
        display: 'temp (C)',
        key: 'HSuctionTemp',
        max: 100,
        min: 0,
    },
]
const DefaultValue = [
    {
        key: 'status',
        value: 0,
    },
    {
        key: 'temp',
        value: 5,
    },
    {
        key: 'temp1Factor',
        value: 20,
    },
    {
        key: 'temp2Factor',
        value: 20,
    },
    {
        key: 'temp3Factor',
        value: 20,
    },
    {
        key: 'temp4Factor',
        value: 20,
    },
    {
        key: 'temp5Factor',
        value: 20,
    },
    {
        key: 'returnAirFactor',
        value: 10,
    },
    {
        key: 'highTemp',
        value: 40,
    },
    {
        key: 'lowTemp',
        value: -20,
    },
    {
        key: 'HCondPress',
        value: 40,
    },
    {
        key: 'LCondPress',
        value: 0,
    },
    {
        key: 'HRH',
        value: 100,
    },
    {
        key: 'LRH',
        value: 20,
    },
    {
        key: 'HSuctionTemp',
        value: 90,
    },
]
const CsvHeaders = [
    {
        key: 'systemStart',
        label: 'Coldbox Start-stop',
    },
    {
        key: 'status',
        label: 'Coldbox Status',
    },
    {
        key: 'temp',
        label: 'Coldbox Temperature',
    },
    {
        key: 'temp1Factor',
        label: 'Temperature 1 Weighted Factor',
    },
    {
        key: 'temp2Factor',
        label: 'Temperature 2 Weighted Factor',
    },
    {
        key: 'temp3Factor',
        label: 'Temperature 3 Weighted Factor',
    },
    {
        key: 'temp4Factor',
        label: 'Temperature 4 Weighted Factor',
    },
    {
        key: 'temp5Factor',
        label: 'Temperature 5 Weighted Factor',
    },
    {
        key: 'returnAirFactor',
        label: 'Return Air Weighted Factor',
    },
    {
        key: 'highTemp',
        label: 'High Temperature Warning',
    },
    {
        key: 'lowTemp',
        label: 'Low Temperature Warning',
    },
    {
        key: 'HCondPress',
        label: 'High Condensing Pressure Alarm',
    },
    {
        key: 'LCondPress',
        label: 'Low Condensing Pressure Alarm',
    },
    {
        key: 'HRH',
        label: 'High RH Warning',
    },
    {
        key: 'LRH',
        label: 'Low RH Warning',
    },
    {
        key: 'HSuctionTemp',
        label: 'High Suction Temperature Warning',
    },
]

class ColdboxSetpoint extends React.Component {
    constructor(props) {
        super()
        this.state = {
            coldboxID: null,                    //when onload, localstorage would save the coldboxID

            displayData: null,
            groupDisplayData: null,

            csvData: null,              //null > not show, array > popup to download csv
            clearCsvInput: false,       //toggle to true then false, to clear csv reader lib input file
            selectedGroup: null,

            isGroupSetPt: false,        // false = individual setpoint, true = group setpoint
        }

        //searchbar function
        this.backPage = this.backPage.bind(this)
        this.reload = this.reload.bind(this)
        //select and input function
        this.DroplistSelect = this.DroplistSelect.bind(this)
        this.InputTextChange = this.InputTextChange.bind(this)
        this.SetColdboxID = this.SetColdboxID.bind(this)
        this.ChangeValue = this.ChangeValue.bind(this)
        //bottom buttons functions
        this.Save = this.Save.bind(this)
        
        this.EmitForEachColdbox = this.EmitForEachColdbox.bind(this)
        this.Discard = this.Discard.bind(this)
        this.ResetToDefault = this.ResetToDefault.bind(this)
        this.ImportCSV = this.ImportCSV.bind(this)
        this.ExportCSV = this.ExportCSV.bind(this)
        this.CloseExportCSV = this.CloseExportCSV.bind(this)
        this.getGroupList = this.getGroupList.bind(this)
        this.SetSelectedGroup = this.SetSelectedGroup.bind(this)
        this.setisGroupSetPt = this.setisGroupSetPt.bind(this)
    }
    setisGroupSetPt(value) {
        this.setState({
            isGroupSetPt: value
        })
    }
    componentDidMount() {
        console.log("getGroupList")
        let pathUrl = "/Main/FloorPlan";
        this.props.dispatch({ type: 'LatestPage', data: pathUrl });

        this.getGroupList()
        this.reload()
        var PickedColdboxID = localStorage.getItem('coldboxSetPt')
        this.setState({
            coldboxID: PickedColdboxID
        })
    }
    backPage() {
        this.props.history.goBack()
    }
    reload() {
        const _this = this
        const coldboxID = localStorage.getItem('coldboxSetPt')      // const {coldboxID} = this.state 
        const { DeviceData, currentSite } = this.props
        const { isGroupSetPt, selectedGroup } = this.state
        if (coldboxID == null || DeviceData == null || currentSite == null) return
        //find the coldbox item
        const Site = DeviceData[currentSite]
        const LeveList = Site.nestedLocs
        let SearchedLocation
        LeveList.forEach(LvlItem => {
            LvlItem.nestedLocs.forEach(LocItem => {
                if (LocItem == null || LocItem.device_id == null) {
                    //exception, no DeviceSerial
                }
                else if (LocItem.locName == coldboxID || LocItem.device_id.deviceSerial == coldboxID) {
                    SearchedLocation = LocItem
                }
            })
        })
        // console.log(SearchedLocation)
        if(isGroupSetPt == true) {
            const coldboxNameList =  GetColdboxListFromGroup(selectedGroup)
            const coldboxDataList = GetColdboxDataList(DeviceData, currentSite, coldboxNameList)

            // prepare the group data display
            const dataToDisplayInGroup = getDataToDisplayInGroup(_this.props, selectedGroup._id)
            console.log({
                dataToDisplayInGroup: dataToDisplayInGroup
            })
            
            // set to state
            _this.setState({
                groupDisplayData: {
                    groupID: selectedGroup._id,
                    systemStart: dataToDisplayInGroup.systemStart,
                    status: dataToDisplayInGroup.status,
                    temp: dataToDisplayInGroup.temp,
                    temp1Factor: dataToDisplayInGroup.temp1Factor,
                    temp2Factor: dataToDisplayInGroup.temp2Factor,
                    temp3Factor: dataToDisplayInGroup.temp3Factor,
                    temp4Factor: dataToDisplayInGroup.temp4Factor,
                    temp5Factor: dataToDisplayInGroup.temp5Factor,
                    returnAirFactor: dataToDisplayInGroup.returnAirFactor,
                    highTemp: dataToDisplayInGroup.highTemp,
                    lowTemp: dataToDisplayInGroup.lowTemp,
                    HCondPress: dataToDisplayInGroup.HCondPress,
                    LCondPress: dataToDisplayInGroup.LCondPress,
                    HRH: dataToDisplayInGroup.HRH,
                    LRH: dataToDisplayInGroup.LRH,
                    HSuctionTemp: dataToDisplayInGroup.HSuctionTemp,
                }
            })
        }
        else if (SearchedLocation == null) {
            //nothing is find
            _this.setState({
                displayData: null
            })
        }
        else {
            if(SearchedLocation==null||SearchedLocation.payload==null) return           // exception
            const {
                SystemStart,
                Status,
                TempSetPoint,
                WeightedFactorTemp1,
                WeightedFactorTemp2,
                WeightedFactorTemp3,
                WeightedFactorTemp4,
                WeightedFactorTemp5,
                WeightedFactorReturnAirTemp,
                TempHighSetPoint,
                TempLowSetPoint,
                CondensingPressureWarningHighSetPoint,
                EvaporatingPressureWarningLowSetpoint,
                RHWarningHighSetPoint,
                RHWarningLowSetPoint,
                SuctionTempWarningHighSetPoint,
            } = SearchedLocation.payload
            // coldboxID
             _this.setState({
                displayData: {
                    id: coldboxID,
                    systemStart: SystemStart,
                    status: Status,
                    temp: TempSetPoint,
                    temp1Factor: parseFloat(WeightedFactorTemp1),
                    temp2Factor: parseFloat(WeightedFactorTemp2),
                    temp3Factor: parseFloat(WeightedFactorTemp3),
                    temp4Factor: parseFloat(WeightedFactorTemp4),
                    temp5Factor: parseFloat(WeightedFactorTemp5),
                    returnAirFactor: WeightedFactorReturnAirTemp,
                    highTemp: TempHighSetPoint,
                    lowTemp: TempLowSetPoint,
                    HCondPress: CondensingPressureWarningHighSetPoint,
                    LCondPress: EvaporatingPressureWarningLowSetpoint,
                    HRH: RHWarningHighSetPoint,
                    LRH: RHWarningLowSetPoint,
                    HSuctionTemp: SuctionTempWarningHighSetPoint,
                }
            })
        }
    }
    DroplistSelect(key, e, isGroup) {
        const value = e.target.value
        this.ChangeValue(key, value, isGroup)
    }
    InputTextChange(key, e, isGroup) {
        const value = e.target.value
        this.ChangeValue(key, value, isGroup)
    }
    SetColdboxID(coldboxID) {
        const _this = this
        this.setState({
            coldboxID: coldboxID
        })
        setTimeout(() => {
            _this.reload()       //update the coldbox readings
        }, 200)
    }
    SetSelectedGroup(GroupObj) {
        console.log("SetSelectedGroup")
        console.log(GroupObj)
        this.setState({
            selectedGroup: GroupObj
        })
        if (GroupObj!==null && GroupObj!==undefined && GroupObj.location!==null && GroupObj.location[0]!==null 
            && GroupObj.location[0][0]!==null && GroupObj.location[0][0]!==undefined && GroupObj.location[0][0]!=={}) {
            this.SetColdboxID(GroupObj.location[0][0].locName)
        }
    }
    ChangeValue(key, value, isGroup) {
        const _this = this
        let { displayData, groupDisplayData } = this.state
        if(isGroup==true) {
            if(groupDisplayData!=null) groupDisplayData[key] = value
            else groupDisplayData = {
                [key]: value
            }
        }
        else displayData[key] = value

        if(isGroup) _this.setState({ groupDisplayData: groupDisplayData })
        else _this.setState({ displayData: displayData })
    }
    Save() {
        const _this = this
        const {displayData, selectedGroup, isGroupSetPt, groupDisplayData} = this.state
        const {UserInfo, DeviceData} = this.props
        
        if(IsAdminEngineer(UserInfo) == false) {
            _this.PopupNotificationRef.addNotificationPopup("No Permission to save", "Require Engineer or higher level", null, true)
            return
        }
        const {id} = displayData
        const RequestDeviceID = GetDevice_ID_ID(id, DeviceData, selectedGroup, isGroupSetPt)
        
        const EmitJson = GetEmitJson(_this.props, _this.state, RequestDeviceID)

        const CheckVlidResult = checkvalid(EmitJson)
        const {isValid, message} = CheckVlidResult
        if (!isValid) {            //show error msg
            let errorCount = 0     //prevent push popup and cause error
            message.forEach(msgItem => {
                if (errorCount >= 4) return
                setTimeout(() => {
                    _this.PopupNotificationRef.addNotificationPopup(msgItem.title, msgItem.body, null, true)
                }, errorCount * 100)
                errorCount++
            })
            return
        }

        RequestDeviceID.forEach(DeviceID => {
            _this.EmitForEachColdbox(EmitJson, DeviceID)
        })
    }
    EmitForEachColdbox(EmitJson, DeviceID) {
        let IndivEmitjson = EmitJson
        IndivEmitjson.id = DeviceID

        console.log(IndivEmitjson)
        this.props.dispatch({
            type: 'EmitSocket',
            EmitSocketName: "StatRequest",
            EmitSocketData: IndivEmitjson
        })
    }
    Discard() {
        this.reload()
    }
    ResetToDefault() {
        let { displayData, groupDisplayData } = this.state
        DefaultValue.forEach(defaultItem => {
            const keyStr = defaultItem.key
            const value = defaultItem.value
            displayData[keyStr] = value
            groupDisplayData[keyStr] = value
        })

        this.setState({
            displayData: displayData,
            groupDisplayData: groupDisplayData
        })
    }
    ImportCSV(data, fileInfo) {
        const { displayData } = this.state
        console.log(data)
        console.log(fileInfo)
        const _this = this
        if (data == null || data.length == null || data.length <= 1) {
            _this.PopupNotificationRef.addNotificationPopup("Import csv failed", "Please check the csv file format", null, true)
            return
        }
        if (data[0] == null || data[0].length == null || data[0].length <= 10) {
            _this.PopupNotificationRef.addNotificationPopup("Import csv failed", "Some Header are Missing", null, true)
            return
        }
        //Get All Values from Excel and convert them to float numbers
        let StateJson = {}
        CsvHeaders.forEach((csvItem, index) => {
            StateJson[csvItem.key] = data[1][index]
        })
        //Local SetState on those items
        const {
            systemStart, status, temp, temp1Factor, temp2Factor, temp3Factor, temp4Factor, temp5Factor, returnAirFactor, highTemp, lowTemp, HCondPress, LCondPress, HRH, LRH, HSuctionTemp
        } = StateJson
        this.setState({
            displayData: {
                id: (displayData == null) ? '0' : displayData.id,
                systemStart: parseFloat(systemStart).toString()=='NaN'?
                    systemStart==true?1:0
                    :
                    parseFloat(systemStart),
                status: parseFloat(status),
                temp: parseFloat(temp),
                temp1Factor: parseFloat(temp1Factor),
                temp2Factor: parseFloat(temp2Factor),
                temp3Factor: parseFloat(temp3Factor),
                temp4Factor: parseFloat(temp4Factor),
                temp5Factor: parseFloat(temp5Factor),
                returnAirFactor: parseFloat(returnAirFactor),
                highTemp: parseFloat(highTemp),
                lowTemp: parseFloat(lowTemp),
                HCondPress: parseFloat(HCondPress),
                LCondPress: parseFloat(LCondPress),
                HRH: parseFloat(HRH),
                LRH: parseFloat(LRH),
                HSuctionTemp: parseFloat(HSuctionTemp),
            }
        })

        //clear the csv input html object
        this.setState({
            clearCsvInput: true
        })
        setTimeout(function () {
            _this.setState({
                clearCsvInput: false
            })
        }, 100)
    }
    ExportCSV() {
        const { displayData } = this.state
        this.setState({
            csvData: [displayData]      //array is required
        })
    }
    CloseExportCSV() {
        this.setState({
            csvData: null
        })
    }
    getGroupList() {
        console.log("getGroupList")
        const { UserInfo } = this.props
        if (UserInfo == null) return
        const EmitUserID = UserInfo.userID
        //request json 
        let requestData = {}
        requestData = {
            "msgType": "GetGroupList",
            "userID": EmitUserID,
            "client_id": UserInfo.client_id,
        }
        //send with redux socket function
        this.props.dispatch({
            type: 'EmitSocket',
            EmitSocketName: "GroupManageRequest",
            EmitSocketData: requestData
        })
    }
    render() {
        const _this = this
        const {coldboxID, displayData, groupDisplayData, csvData, clearCsvInput, isGroupSetPt, selectedGroup} = this.state
        const {DeviceData, GroupList} = this.props

        return [<div className="ColdboxSetpoint">
            <SetpointHead SetColdboxID={this.SetColdboxID} backPage={this.backPage} DeviceData={DeviceData}
                GroupList={GroupList} SetSelectedGroup={_this.SetSelectedGroup} isGroupSetPt={isGroupSetPt} selectedGroup={selectedGroup} setisGroupSetPt={_this.setisGroupSetPt} />
            <div className="ColdboxSetPt_Table">
                <TableHead coldboxID={coldboxID} isGroupSetPt={isGroupSetPt} selectedGroup={selectedGroup} />
                {
                    displayData == null ?
                        <div className="CdbxSetPt_Unfindbox">
                            <div>Coldbox Not Found</div>
                        </div>
                        :
                        Key_desc.map(rowItem => {
                            return <TableRow displayData={displayData} groupDisplayData={groupDisplayData} rowItem={rowItem} InputTextChange={_this.InputTextChange}
                            DroplistSelect={_this.DroplistSelect} selectedGroup={selectedGroup} isGroupSetPt={isGroupSetPt} DeviceData={DeviceData} />
                        })
                }
                <div className="ColdboxSetPt_btnrow">
                    <div className="ColdboxSetPt_btn">
                        {
                            clearCsvInput ?
                            <div style={{ display: 'none' }}></div>
                            :
                            <CSVReader onFileLoaded={(data, fileInfo) => this.ImportCSV(data, fileInfo)} label="Import csv file:" />
                        }
                    </div>
                    <div className="ColdboxSetPt_btn" onClick={this.ExportCSV}>Export to csv</div>
                </div>
                <div className="ColdboxSetPt_btnrow">
                    <div className="ColdboxSetPt_btn" onClick={this.ResetToDefault}>Reset to Default Value</div>
                    <div className="ColdboxSetPt_btn" onClick={this.Save}>Save</div>
                    <div className="ColdboxSetPt_btn" onClick={this.Discard}>Discard</div>
                </div>
            </div>
        </div>,
        (csvData == null) ? <div style={{ display: 'none' }}></div>
            :
            <div className="ColdboxSetPt_DownloadBG" onClick={_this.CloseExportCSV}></div>,
        (csvData == null) ? <div style={{ display: 'none' }}></div>
            :
            <div className="ColdboxSetPt_Download">
                <CSVLink data={csvData} headers={CsvHeaders} filename={coldboxID + '.csv'}>
                    Download csv
                </CSVLink>
                <div className="ColdboxSetPt_DownloadClose" onClick={_this.CloseExportCSV}>
                    Close
                </div>
            </div>,
        <PopupNotification onRef={ref => { this.PopupNotificationRef = ref }} />
        ]
    }
}
function mapStateToProps(state) {
    return {
        DeviceData: state.DeviceData,
        currentSite: state.currentSite,
        currentlevel: state.currentlevel,
        UserInfo: state.UserInfo,
        GroupList: state.GroupList
    }
}
export default connect(mapStateToProps)(ColdboxSetpoint)

function GetDevice_ID_ID(SearchLocName, DeviceDataRedux, selectedGroup, isGroupSetPt) {
    const DeviceData = DeviceDataRedux
    if (DeviceData == null) return
    let ReturnDeviceID = 'No ID find'
    let idSet = []
    DeviceData.forEach(SiteItem => {
        SiteItem.nestedLocs.forEach(LevelItem => {
            LevelItem.nestedLocs.forEach(LocItem => {
                if (LocItem.locName == SearchLocName) {
                    ReturnDeviceID = (LocItem == null || LocItem.device_id == null || LocItem.device_id._id == null) ? 'No Inner Device_id values' : LocItem.device_id._id
                    if (ReturnDeviceID !== 'No Inner Device_id values') {
                        idSet.push(ReturnDeviceID)
                    }
                }
            })
        })
    })
    if (selectedGroup !== null && isGroupSetPt == true) {
        selectedGroup.location[0].forEach(gItem => {
            console.log(gItem)
            if (gItem.device_id !== ReturnDeviceID) {
                idSet.push(gItem.device_id)
            }
        })
    }
    console.log(idSet)
    // return ReturnDeviceID
    return idSet
}
function IsAdminEngineer(UserInfo) {
    if(UserInfo == null || UserInfo.userLevel == null || UserInfo.userLevel.length == null) return false
    let isAdminEngineer = false
    UserInfo.userLevel.forEach(levelStr => {
        if(levelStr.includes('Admin') || levelStr.includes('Engineer')) isAdminEngineer = true
    })
    return isAdminEngineer
}

// Group Coldbox Data Display functions
function GetColdboxListFromGroup(selectedGroup) {
    if(selectedGroup == null || selectedGroup.location == null || selectedGroup.location.length == null || selectedGroup.location.length == 0) return []
    const locationArray = selectedGroup.location[0]
    if(locationArray == null) return
    return locationArray.map(item => {
        return item.locName
    })
}
function GetColdboxDataList(DeviceData, currentSite, coldboxNameList) {
    if(DeviceData == null || currentSite == null || coldboxNameList == null) return []
    let returnList = []
    // foreach site, level, location
    const lvlList = DeviceData[currentSite].nestedLocs
    lvlList.forEach(lvlItem => {
        lvlItem.nestedLocs.forEach(locItem => {
            const {locName} = locItem
            coldboxNameList.forEach(NameStr => {
                if(NameStr == locName) returnList.push(locItem)
            })
        })
    })
    // if container coldboxName from the list
    return returnList
}
const getMostCommon = arr => {
    const count = {}
    let res = []
    arr.forEach(el => {
       count[el] = (count[el] || 0) + 1
    })
    res = Object.keys(count).reduce((acc, val, ind) => {
       if (!ind || count[val] > count[acc[0]]) {
          return [val]
       };
       if (count[val] === count[acc[0]]) {
          acc.push(val)
       };
       return acc
    }, []);
    return res[0]
 }

function getDataToDisplayInGroup(props, Group_id) {
    const {GroupList, DeviceData} = props
    if(GroupList==null||GroupList.groups==null) return

    const groupItem = GroupList.groups.find(item => item._id == Group_id)
    if(groupItem==null||groupItem.members==null) return

    const memberList = groupItem.members.map(item => item.locName)
    let filteredColdboxList = []
    DeviceData.forEach(siteItem => {
        siteItem.nestedLocs.forEach(lvlItem => {
            lvlItem.nestedLocs.forEach(locItem => {
                let locName = locItem.locName
                let findInMemberList = memberList.find(item => item == locName)
                if(findInMemberList!=null) filteredColdboxList.push(locItem)
            })
        })
    })

    let result = {
        dataFrom: 'setpoint.js getDataToDisplayInGroup()'
    }
    Key_desc.forEach(keyItem => {
        const {type, key} = keyItem
        const Key = GetKey[key]

        const readingList = filteredColdboxList.map(item => item.payload[Key])
        let value = null
        if(type=='Number') {
            if(key=='highTemp'||key=='HCondPress'||key=='HRH'||key=='HSuctionTemp') value = Math.max(...readingList)
            else value = Math.min(...readingList)
        }
        else {
            value = readingList[0]
        }
        result[key] = value
    })

    return result
}
const GetKey = {
    "systemStart": "SystemStart", //SystemEvent?
    "status": "Status",
    "temp": "TempSetPoint",
    "temp1Factor": "WeightedFactorTemp1",
    "temp2Factor": "WeightedFactorTemp2",
    "temp3Factor": "WeightedFactorTemp3",
    "temp4Factor": "WeightedFactorTemp4",
    "temp5Factor": "WeightedFactorTemp5",
    "returnAirFactor": "WeightedFactorReturnAirTemp",		//ReturnAirTemp?
    "highTemp": "TempHighSetPoint",
    "lowTemp": "TempLowSetPoint",
    "HCondPress": "CondensingPressureWarningHighSetPoint",
    "LCondPress": "EvaporatingPressureWarningLowSetpoint",
    "HRH": "RHWarningHighSetPoint",
    "LRH": "RHWarningLowSetPoint",
    "HSuctionTemp": "SuctionTempWarningHighSetPoint",
}