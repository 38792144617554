import React from 'react'
import './loading.css'

class Loading extends React.Component {
    render() {
      const {textShow, isHide} = this.props
      const hideclass = !isHide?'loading_hide':''

      return <div className={'loading_maindiv ' + hideclass}>
        <p>{textShow}</p>
        <div className={'loading ' + hideclass}>
          <div className='loading-bar'></div>
          <div className='loading-bar'></div>
          <div className='loading-bar'></div>
          <div className='loading-bar'></div>
          <div className='loading-bar'></div>
          <div className='loading-bar'></div>
          <div className='loading-bar'></div>
          <div className='loading-bar'></div>
        </div>
      </div>
    }
}

export default Loading