import React from 'react';
import BottomDisplayCards from './BottomDisplayCards';

//Redux Import
import { connect } from 'react-redux';

class BottomDisplay extends React.Component {
    constructor(props) {
        super(props);

        //set up the function (this)
        this.PickedCard = this.PickedCard.bind(this);

        this.state = {
            week_str_array: []
        };
    }

    PickedCard(cardNumber) {
        //trigger the floorplan.js to reload the card data
        //console.log("cardNumer:" + cardNumber);
        this.props.dispatch({ type: 'bottomcardhover', data: cardNumber });
    }

    componentDidMount() {
        //create state with monday, tuesday, etc
        //date now
        let DateNow = new Date();
        //console.log(DateNow);
        let Week_day_str = DateNow.getDay();
        //console.log(Week_day_str);
        let week_str_array = [
            'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'
        ];
        let pushresult = [];
        let i = 0;
        for(i=0;i<=5;i++)   //loop 6 times
        {
            let arraycounter = Week_day_str - 1;
            let current_week_str = week_str_array[arraycounter];
            
            pushresult.push(current_week_str);
            if(Week_day_str == 7)
            {
                Week_day_str = 1;
            }
            else
            {
                Week_day_str ++;
            }
        }
        //console.log(pushresult);

        this.setState({
            week_str_array: pushresult
        });
    }

    render() {
        //Load the parameter and decide which to show
        let { currentBottomMenu } = this.props;
        let week_str_array = this.state.week_str_array;

        let cardTitle = "TITLE ERROR";
        let cardTitleIcon = "book";
        if(currentBottomMenu == "bottom1")
        {
            cardTitle = "IAQ";
            cardTitleIcon = "thermometer-half";
        }
        else if(currentBottomMenu == "bottom2")
        {
            cardTitle = "People Counting";
            cardTitleIcon = "users";
        }
        else if(currentBottomMenu == "bottom3")
        {
            cardTitle = "Power Consumption";
            cardTitleIcon = "bolt";
        }
        else if(currentBottomMenu == "bottom4")
        {
            cardTitle = "Smart Litter Bin";
            cardTitleIcon = "battery fa-rotate-270";
        }

        //console.log(week_str_array[0]);

        return <div>
            <div className="text-left flex flex-row bottom_head">
                <i className={"fa fa-" + cardTitleIcon + " cardTitleIcon"}></i>
                <div className="cardTitleText">
                    {cardTitle}
                </div>
            </div>
            <div className="flex justify-between bottom_inside">
                <BottomDisplayCards cardnumber={ 1 } PickedCard={this.PickedCard} weekdayname={week_str_array[0]} />
                <BottomDisplayCards cardnumber={ 2 } PickedCard={this.PickedCard} weekdayname={week_str_array[1]} />
                <BottomDisplayCards cardnumber={ 3 } PickedCard={this.PickedCard} weekdayname={week_str_array[2]} />
                <BottomDisplayCards cardnumber={ 4 } PickedCard={this.PickedCard} weekdayname={week_str_array[3]} />
                <BottomDisplayCards cardnumber={ 5 } PickedCard={this.PickedCard} weekdayname={week_str_array[4]} />
                <BottomDisplayCards cardnumber={ 6 } PickedCard={this.PickedCard} weekdayname={week_str_array[5]} />
            </div>
        </div>
    }
}

//Redux inside component function
function mapStateToProps(state) {
    return {
      count: state.count,
      log: state.log,
      socket: state.socket,
      level: state.level,
      currentlevel: state.currentlevel,
      SiteList: state.SiteList,
      currentSite: state.currentSite,
      MenuDisplay: state.MenuDisplay,
      currentBottomMenu: state.currentBottomMenu,
      currentBottomCard: state.currentBottomCard
    };
}

export default connect(mapStateToProps)(BottomDisplay);