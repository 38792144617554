import React from 'react'
import ValueCard from '../valueCard/valueCard'

const WaterLevelCard = props => {
    const {item, isFocus, hoverFunc, cardnumber} = props

    return <ValueCard
        dataFields = {dataFields}
        deviceItem = {item}
        isFocus={isFocus}
        hoverFunc={hoverFunc}
        cardnumber={cardnumber}
    />
}
export default WaterLevelCard

const dataFields = [
    {
        isBool: false,
        strKey: 'waterLevel',
        icon: 'tint',
        color: 'rgb(0, 145, 255)',
        fontSize: [24, 24, 35],
        unit: '%',
    }
]