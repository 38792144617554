export const GetEmitJson = (props, state, RequestDeviceID) => {
    if(props==null ||props.UserInfo==null || state==null) return    
    const {isGroupSetPt} = state

    let result
    if(isGroupSetPt) result = GroupEmitJson(props, state, RequestDeviceID)
    else result = IndiEmitJson(props, state, RequestDeviceID)

    return result
}

//sub functions
function GroupEmitJson(props, state, RequestDeviceID) {
    const {groupDisplayData} = state
    const {UserInfo} = props
    if(groupDisplayData==null || UserInfo==null) return

    const {client_id, userID} = UserInfo
    const {
        id,
        systemStart,
        status,
        temp,
        temp1Factor,
        temp2Factor,
        temp3Factor,
        temp4Factor,
        temp5Factor,
        returnAirFactor,
        highTemp,
        lowTemp,
        HCondPress,
        LCondPress,
        HRH,
        LRH,
        HSuctionTemp,
    } = groupDisplayData

    const EmitJson = {
        "msgType": "DeviceConfiguration",
        "updateDeviceName": "Set Point",
        "operatorID": userID,
        "client_id": client_id,
        "ids": RequestDeviceID,
        "deviceConfiguration": {
            "System": systemInputConvert(systemStart), // (for CPSL) 0: stop, 1: start
            "Status": statusInputConvert(status), // (for CPSL) 0: Empty, 1: Precooling, 2: Loaded, 3: Damage / Maintenance
            "TempSetPoint": parseFloat(temp),
            "WeightedFactorTemp1": parseFloat(temp1Factor),
            "WeightedFactorTemp2": parseFloat(temp2Factor),
            "WeightedFactorTemp3": parseFloat(temp3Factor),
            "WeightedFactorTemp4": parseFloat(temp4Factor),
            "WeightedFactorTemp5": parseFloat(temp5Factor),
            "WeightedFactorReturnAirTemp": parseFloat(returnAirFactor),
            "TempHighSetPoint": parseFloat(highTemp),
            "TempLowSetPoint": parseFloat(lowTemp),
            "CondensingPressureWarningHighSetPoint": parseFloat(HCondPress),
            "EvaporatingPressureWaringLowSetpoint": parseFloat(LCondPress),
            "RHWarningHighSetPoint": parseFloat(HRH),
            "RHWarningLowSetPoint": parseFloat(LRH),
            "SuctionTempWarningHighSetPoint": parseFloat(HSuctionTemp),
        }
    }

    return EmitJson
}
function IndiEmitJson(props, state, RequestDeviceID) {
    const {displayData} = state
    const {UserInfo} = props
    if(displayData==null || UserInfo==null) return

    const {client_id, userID} = UserInfo
    const {
        id,
        systemStart,
        status,
        temp,
        temp1Factor,
        temp2Factor,
        temp3Factor,
        temp4Factor,
        temp5Factor,
        returnAirFactor,
        highTemp,
        lowTemp,
        HCondPress,
        LCondPress,
        HRH,
        LRH,
        HSuctionTemp,
    } = displayData

    const EmitJson = {
        "msgType": "DeviceConfiguration",
        "updateDeviceName": "Set Point",
        "operatorID": userID,
        "client_id": client_id,
        "id": RequestDeviceID[0],
        "deviceConfiguration": {
            "System": systemInputConvert(systemStart), // (for CPSL) 0: stop, 1: start
            "Status": statusInputConvert(status), // (for CPSL) 0: Empty, 1: Precooling, 2: Loaded, 3: Damage / Maintenance
            "TempSetPoint": parseFloat(temp),
            "WeightedFactorTemp1": parseFloat(temp1Factor),
            "WeightedFactorTemp2": parseFloat(temp2Factor),
            "WeightedFactorTemp3": parseFloat(temp3Factor),
            "WeightedFactorTemp4": parseFloat(temp4Factor),
            "WeightedFactorTemp5": parseFloat(temp5Factor),
            "WeightedFactorReturnAirTemp": parseFloat(returnAirFactor),
            "TempHighSetPoint": parseFloat(highTemp),
            "TempLowSetPoint": parseFloat(lowTemp),
            "CondensingPressureWarningHighSetPoint": parseFloat(HCondPress),
            "EvaporatingPressureWaringLowSetpoint": parseFloat(LCondPress),
            "RHWarningHighSetPoint": parseFloat(HRH),
            "RHWarningLowSetPoint": parseFloat(LRH),
            "SuctionTempWarningHighSetPoint": parseFloat(HSuctionTemp),
        }
    }

    return EmitJson
}

function systemInputConvert(systemStart) {
    if(parseFloat(systemStart).toString() == 'NaN') {
        if(systemStart=='1'||systemStart==true) return 1
        else if(systemStart=='0'||systemStart==false) return 0
    }
    else {
        return parseFloat(systemStart)          // 0 or 1
    }
    return -1   //exception
}
const statusInputConvert = (status) => {
    if(parseFloat(status).toString() == 'NaN') {
        if(status=='0'||status==0) return 0
        if(status=='1'||status==1) return 1
        if(status=='2'||status==2) return 2
        if(status=='3'||status==3) return 3
    }
    else {
        return parseFloat(status)
    }
    return -1   //exception
}