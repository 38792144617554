const HardcodeDeviceData = {
    locations: [
        {
            "_id": "(building id)",
            "coor": {
              "type": "Point",
              "coordinates": [
                22.3110549,
                114.2226636
              ]
            },
            "img": "xxx",
            "imgUrl": "PY_toilet",
            "locIdx": 1,
            "locLvl": 1,
            "locName": "Paul Y. Center -- Toilet Demo",
            "locDesc": "ATAL ICBT Office",
            "nestedLocs": [
              {
                "_id": "(site ID)",
                "locIdx": 1,
                "locLvl": 2,
                "locName": "18",
                "locDesc": "18/F",
                "nestedLocs": [
                  //Male Items
                  {
                    "_id": "loc ID",
                    "device_id": "(device id)", // no use
                    "locIdx": 1,
                    "locLvl": 3,
                    "locName": "Occupancy 1",
                    "locUI": {
                        "type": "zone",
                        "point": [
                            [0.025, 0.025],
                            [0.025, 0.535],
                            [0.15, 0.535],
                            [0.15, 0.025]
                        ]
                    },
                    "payload": {
                      "temperature": 22.4,
                      "humidity": 32,
                      "co2": 1200,
                      "pm2_5": 1.6,

                      "flushingwater": true,
                      "waterLeakageStatus": false,
                      "PaperLevel":	0.5,
					  "hasPaper": true,
					  "soapLevel": 0.3,
					  "hasSoap": true,

                      "Occupied": true,
                      "fallDetected": false,
                      "Latest Alarm": null,
                      "toiletType": "1" //1 is male, 2 is female, 3 is other
                    },
                          "payload_definition_id": {
                              "_id": "(payload definition id)", // no use
                              "cardTitle": "Occupancy",
                              "createdTime": "(payload definition created time)", // no use
                              "displayedFields": ["flushingWater"], // fields to be displayed on web
                              "name": "Occupancy", // no use
                              "nameID": "Occupancy", // Used to distinguish each sensor type (card categories)
                              "payload": { "flushingWater": "flushingWater" }, // no use
                              "updateTime": "(payload definition update time)", // no use
                          },
                  },
                  {
                    "_id": "loc ID",
                    "device_id": "(device id)", // no use
                    "locIdx": 2,
                    "locLvl": 3,
                    "locName": "Occupancy 2",
                    "locUI": {
                        "type": "zone",
                        "point": [
                            [0.16, 0.025],
                            [0.16, 0.535],
                            [0.28, 0.535],
                            [0.28, 0.025]
                        ]
                    },
                    "payload": {
                      "temperature": 25.5,
                      "humidity": 40,
                      "co2": 900,
                      "pm2_5": 1.6,

                      "flushingwater": true,
                      "waterLeakageStatus": false,
                      "PaperLevel":	0.2,
					  "hasPaper": true,
					  "soapLevel": 0.9,
					  "hasSoap": true,

                      "Occupied": false,
                      "fallDetected": false,
                      "Latest Alarm": null,
                      "toiletType": "1" //1 is male, 2 is female, 3 is other
                    },
                          "payload_definition_id": {
                              "_id": "(payload definition id)", // no use
                              "cardTitle": "Occupancy",
                              "createdTime": "(payload definition created time)", // no use
                              "displayedFields": ["flushingWater"], // fields to be displayed on web
                              "name": "Occupancy", // Either name or name ID (not sure which one currently using)
                              "nameID": "Occupancy", // Either name or name ID (not sure which one currently using)
                              "payload": { "flushingWater": "flushingWater" }, // no use
                              "updateTime": "(payload definition update time)", // no use
                          },
                  },
                  {
                    "_id": "loc ID",
                    "device_id": "(device id)", // no use
                    "locIdx": 1,
                    "locLvl": 3,
                    "locName": "Occupancy 1",
                    "locUI": {
                        "type": "zone",
                        "point": [
                            [0.29, 0.025],
                            [0.29, 0.535],
                            [0.423, 0.535],
                            [0.423, 0.025]
                        ]
                    },
                    "payload": {
                      "Occupied": true,
                      "fallDetected": false,
                      "Latest Alarm": null,
                      "flushingwater": false,
                      "waterLeakageStatus": true,
                      "PaperLevel":	0.6,
					  "hasPaper": true,
					  "soapLevel": 0.85,
					  "hasSoap": true,

                      "Occupied": true,
                      "fallDetected": false,
                      "Latest Alarm": null,

                      "toiletType": "1" //1 is male, 2 is female, 3 is other
                    },
                          "payload_definition_id": {
                              "_id": "(payload definition id)", // no use
                              "cardTitle": "Occupancy",
                              "createdTime": "(payload definition created time)", // no use
                              "displayedFields": ["flushingWater"], // fields to be displayed on web
                              "name": "Occupancy", // no use
                              "nameID": "Occupancy", // Used to distinguish each sensor type (card categories)
                              "payload": { "flushingWater": "flushingWater" }, // no use
                              "updateTime": "(payload definition update time)", // no use
                          },
                  },
                  {
                    "_id": "loc ID",
                    "device_id": "(device id)", // no use
                    "locIdx": 2,
                    "locLvl": 3,
                    "locName": "Occupancy 2",
                    "locUI": {
                        "type": "zone",
                        "point": [
                            [0.425, 0.025],
                            [0.425, 0.535],
                            [0.55, 0.535],
                            [0.55, 0.025]
                        ]
                    },
                    "payload": {
                      "temperature": 24.5,
                      "humidity": 65,
                      "co2": 900,
                      "pm2_5": 2,
                      "flushingwater": false,
                      "waterLeakageStatus": false,

                      "Occupied": true,
                      "fallDetected": false,
                      "Latest Alarm": null,
                      "toiletType": "1" //1 is male, 2 is female, 3 is other
                    },
                          "payload_definition_id": {
                              "_id": "(payload definition id)", // no use
                              "cardTitle": "Occupancy",
                              "createdTime": "(payload definition created time)", // no use
                              "displayedFields": ["flushingWater"], // fields to be displayed on web
                              "name": "Occupancy", // Either name or name ID (not sure which one currently using)
                              "nameID": "Occupancy", // Either name or name ID (not sure which one currently using)
                              "payload": { "flushingWater": "flushingWater" }, // no use
                              "updateTime": "(payload definition update time)", // no use
                          },
                  },
                  {
                    "_id": "loc ID",
                    "device_id": "(device id)", // no use
                    "locIdx": 2,
                    "locLvl": 3,
                    "locName": "Occupancy 2",
                    "locUI": {
                        "indicator": {
                            "point": [
                                [0.8, 0.2]
                            ]
                        }
                    },
                    "payload": {
                      "isFull": true,
                      "toiletType": "1" //1 is male, 2 is female, 3 is other
                    },
                          "payload_definition_id": {
                              "_id": "(payload definition id)", // no use
                              "cardTitle": "Rubbish Bin",
                              "createdTime": "(payload definition created time)", // no use
                              "displayedFields": ["flushingWater"], // fields to be displayed on web
                              "name": "Rubbish Bin", // Either name or name ID (not sure which one currently using)
                              "nameID": "Rubbish Bin", // Either name or name ID (not sure which one currently using)
                              "payload": { "flushingWater": "flushingWater" }, // no use
                              "updateTime": "(payload definition update time)", // no use
                          },
                  },
                  {
                    "_id": "loc ID",
                    "device_id": "(device id)", // no use
                    "locIdx": 2,
                    "locLvl": 3,
                    "locName": "Occupancy 2",
                    "locUI": {
                        "indicator": {
                            "point": [
                                [0.8, 0.025]
                            ]
                        }
                    },
                    "payload": {
                        hasSoap:false,
		            	soapLevel: 0,
                      "toiletType": "1" //1 is male, 2 is female, 3 is other
                    },
                          "payload_definition_id": {
                              "_id": "(payload definition id)", // no use
                              "cardTitle": "Soap Level",
                              "createdTime": "(payload definition created time)", // no use
                              "displayedFields": ["flushingWater"], // fields to be displayed on web
                              "name": "Soap Level", // Either name or name ID (not sure which one currently using)
                              "nameID": "Soap Level", // Either name or name ID (not sure which one currently using)
                              "payload": { "flushingWater": "flushingWater" }, // no use
                              "updateTime": "(payload definition update time)", // no use
                          },
                  },
                  {
                    "_id": "loc ID",
                    "device_id": "(device id)", // no use
                    "locIdx": 2,
                    "locLvl": 3,
                    "locName": "Occupancy 2",
                    "locUI": {
                        "indicator": {
                            "point": [
                                [0.56, 0.025]
                            ]
                        }
                    },
                    "payload": {
                        hasSoap:true,
		            	soapLevel: 0.7,
                      "toiletType": "1" //1 is male, 2 is female, 3 is other
                    },
                          "payload_definition_id": {
                              "_id": "(payload definition id)", // no use
                              "cardTitle": "Soap Level",
                              "createdTime": "(payload definition created time)", // no use
                              "displayedFields": ["flushingWater"], // fields to be displayed on web
                              "name": "Soap Level", // Either name or name ID (not sure which one currently using)
                              "nameID": "Soap Level", // Either name or name ID (not sure which one currently using)
                              "payload": { "flushingWater": "flushingWater" }, // no use
                              "updateTime": "(payload definition update time)", // no use
                          },
                  },
                  //Female items
                  {
                    "_id": "loc ID",
                    "device_id": "(device id)", // no use
                    "locIdx": 3,
                    "locLvl": 3,
                    "locName": "Occupancy 1 F",
                    "locUI": {
                        "type": "zone",
                        "point": [
                            [0.6942857142857143, 0.6044905008635578],
                            [0.6914285714285714, 0.9723661485319517],
                            [0.9685714285714285, 0.9723661485319517],
                            [0.9657142857142857, 0.6079447322970639]
                        ]
                    },
                    "payload": {
                      "temperature": 24.5,
                      "humidity": 65,
                      "co2": 900,
                      "pm2_5": 3.1,
                      "toiletType": "2" //1 is male, 2 is female, 3 is other
                    },
                          "payload_definition_id": {
                              "_id": "(payload definition id)", // no use
                              "cardTitle": "Occupancy",
                              "createdTime": "(payload definition created time)", // no use
                              "displayedFields": ["flushingWater"], // fields to be displayed on web
                              "name": "Occupancy", // Either name or name ID (not sure which one currently using)
                              "nameID": "Occupancy", // Either name or name ID (not sure which one currently using)
                              "payload": { "flushingWater": "flushingWater" }, // no use
                              "updateTime": "(payload definition update time)", // no use
                          },
                  },
                  {
                    "_id": "loc ID",
                    "device_id": "(device id)", // no use
                    "locIdx": 4,
                    "locLvl": 3,
                    "locName": "Occupancy 2 F",
                    "locUI": {
                        "type": "zone",
                        "point": [
                            [0.6942857142857143, 0.6044905008635578],
                            [0.6914285714285714, 0.9723661485319517],
                            [0.9685714285714285, 0.9723661485319517],
                            [0.9657142857142857, 0.6079447322970639]
                        ]
                    },
                    "payload": {
                      "temperature": 24.5,
                      "humidity": 65,
                      "co2": 900,
                      "pm2_5": 3.1,
                      "toiletType": "2" //1 is male, 2 is female, 3 is other
                    },
                          "payload_definition_id": {
                              "_id": "(payload definition id)", // no use
                              "cardTitle": "Occupancy",
                              "createdTime": "(payload definition created time)", // no use
                              "displayedFields": ["flushingWater"], // fields to be displayed on web
                              "name": "Occupancy", // Either name or name ID (not sure which one currently using)
                              "nameID": "Occupancy", // Either name or name ID (not sure which one currently using)
                              "payload": { "flushingWater": "flushingWater" }, // no use
                              "updateTime": "(payload definition update time)", // no use
                          },
                  }
                ],
              }
            ]
        }
    ]
}


export default HardcodeDeviceData