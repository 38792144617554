import React, { useEffect, useState } from 'react'
import './ledcard.css'
import { connect } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faLightbulb, faMoon, faSpinner } from '@fortawesome/free-solid-svg-icons'

function LEDCard(props) {
    const [isLoading, setisLoading] = useState(false)
    //listfocus_inner == "listfocus_inner" > the class to focused color style
    const {listfocus_inner, Data} = props    //Data is the card's Location data
    const {UserInfo, DeviceData, currentSite, currentlevel} = props

    function SetLED(locationItem, status) {
        if(status == null || locationItem == null) return
        // generate all data for request json
        const {SiteID, SiteName, LevelID, LevelName, LocID, LocName, DeviceID} = GetLocationData(locationItem, DeviceData, currentSite, currentlevel)
        const {clientID, operatorID} = GetUserIDs(UserInfo)

        const EmitJson = {
            "msgType": "DeviceConfiguration",
            "operatorID": operatorID,
            "client_id": clientID,
            "id": DeviceID,
            "deviceConfiguration": {
                "lightLevel": status
            }
        }
        console.log(EmitJson)
        props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "StatRequest",
            EmitSocketData: EmitJson
        })

        // set loading on local state // when timeout, stop the loading in local state
        setisLoading(true)
        setTimeout(function() {
            setisLoading(false)
        }, 2000)
    }

    if(Data == null || Data.payload == null) return <div>Payload missing</div>
    const {lightLevel} = Data.payload

    let LEDStatus = ''
    let LEDIcon = <FontAwesomeIcon icon={faMinus} className="fa" />
    switch(lightLevel) {
        case "0":
            LEDStatus = 'Off'
            LEDIcon = <FontAwesomeIcon icon={faMoon} className="fa" />
            break
        case 0:
            LEDStatus = 'Off'
            LEDIcon = <FontAwesomeIcon icon={faMoon} className="fa" />
            break
        case "1":
            LEDStatus = 'On (dim)'
            LEDIcon = <FontAwesomeIcon icon={faLightbulb} className="fa" />
            break
        case 1:
            LEDStatus = 'On (dim)'
            LEDIcon = <FontAwesomeIcon icon={faLightbulb} className="fa" />
            break
        case "2":
            LEDStatus = 'On'
            LEDIcon = <FontAwesomeIcon icon={faLightbulb} className="fa" />
            break
        case 2:
            LEDStatus = 'On'
            LEDIcon = <FontAwesomeIcon icon={faLightbulb} className="fa" />
            break
    }
    if(isLoading) return <div className="LEDCard">
        <div className="LEDCard_status">
        </div>
        <div className="LEDCard_controls">
            <div className="LEDCard_loading">
                <FontAwesomeIcon icon={faSpinner} className="fa" />
            </div>
        </div>
    </div>

    return <div className="LEDCard">
        <div className="LEDCard_status">
            {LEDIcon}
            {LEDStatus}
        </div>
        <div className="LEDCard_controls">
            {
                lightLevel=="2"||lightLevel==2?
                <div className="control disable" onClick={() => SetLED(Data, 2)}>On</div>
                :
                <div className="control" onClick={() => SetLED(Data, 2)}>On</div>
            }
            {
                lightLevel=="1"||lightLevel==1?
                <div className="control disable" onClick={() => SetLED(Data, 1)}>Dim</div>
                :
                <div className="control" onClick={() => SetLED(Data, 1)}>Dim</div>
            }
            {
                lightLevel=="0"||lightLevel==0?
                <div className="control disable" onClick={() => SetLED(Data, 0)}>Off</div>
                :
                <div className="control" onClick={() => SetLED(Data, 0)}>Off</div>
            }
        </div>
    </div>
}

function mapStateToProps(state) {
    return {
        DeviceDataLastUpdate: state.DeviceDataLastUpdate,              //update this redux storage to trigger render of cards
        UserInfo: state.UserInfo,
        DeviceData: state.DeviceData,
        currentSite: state.currentSite,
        currentlevel: state.currentlevel,
    }
}
export default connect(mapStateToProps)(LEDCard)



function GetLocationData(LocItem, DeviceData, currentSite, currentlevel) {
    if(LocItem == null || DeviceData == null || currentSite == null || currentlevel == null) return {
        SiteID: '1',
        SiteName: '',
        LevelID: '',
        LevelName: '',
        LocID: '',
        LocName: '',
    }

    const SiteItem = DeviceData[currentSite]
    if(SiteItem == null) return {
        SiteID: '2',
        SiteName: '',
        LevelID: '',
        LevelName: '',
        LocID: '',
        LocName: '',
    }
    const {_id, locName} = LocItem
    //find items from site / level and location
    const LevelItem = SiteItem.nestedLocs.find(item => {
        return item.locName.toString() == currentlevel.toString()
    })
    if(LevelItem == null) return {
        SiteID: '3',
        SiteName: '',
        LevelID: '',
        LevelName: '',
        LocID: '',
        LocName: '',
    }
    const locItem = LevelItem.nestedLocs.find(item => {
        return item.locName.toString() == locName
    })
    if(locItem == null) return {
        SiteID: '4',
        SiteName: '',
        LevelID: '',
        LevelName: '',
        LocID: '',
        LocName: '',
    }

    return {
        SiteID: SiteItem._id,
        SiteName: SiteItem.locName,
        LevelID: LevelItem._id,
        LevelName: LevelItem.locName,
        LocID: locItem._id,
        LocName: locItem.locName,
        DeviceID: locItem?.device_id?._id
    }
}

function GetUserIDs(UserInfo) {
    if(UserInfo == null) return {
        clientID: '',
        operatorID: '' 
    }

    const {client_id, userID} = UserInfo

    return {
        clientID: client_id,
        operatorID: userID
    }
}