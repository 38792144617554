import React from 'react'
import './SipderChart.css'

//Redux Import
import { connect } from 'react-redux'

//import chart library
import HighCharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import {getTxt} from '../../common/language/language'

class SipderChart extends React.Component {
    constructor(props) {
        super(props)

        this.barchart_ref = React.createRef()
        this.header_ref = React.createRef()

        this.state = {
            chartwidth: 500,
            chartheight: 500,
            ChartData: null,
        }
        this.LoadData = this.LoadData.bind(this)
        this.convertDate = this.convertDate.bind(this)

        this.highchartData = this.highchartData.bind(this)
    }

    componentDidMount() {
        console.log('did mount')
        //set the chart height and width with ref
        let innerbody_width = this.barchart_ref.current.offsetWidth
        let innerbody_height = this.barchart_ref.current.offsetHeight - this.header_ref.current.offsetHeight

        this.setState({
            chartwidth: innerbody_width,
            chartheight: innerbody_height
        })

        //request socket to load ac power
        this.LoadData(this.props)
    }
    convertDate(DateInput)
    {
        let DateMonth = DateInput.getMonth() + 1;
        let DateDay = DateInput.getDate();
        if(DateMonth < 10)
        {
            DateMonth = "0" + DateMonth;
        }
        if(DateDay < 10)
        {
            DateDay = "0" + DateDay;
        }
        let DateStr = DateInput.getFullYear() + "-" + DateMonth + "-" + 
        DateDay + "T16:00:00Z";
        return DateStr;
    }
    LoadData(props)
    {
        //clear redux storage
        this.props.dispatch({ type: 'IAQSpiderChart', data: null})
        //..
        const {currentlevelID, UserInfo} = props
        let startDate = new Date();
        let EndDate = new Date();
        startDate.setMonth(startDate.getMonth()-1);         //time range: today to (today-1 month)
        let startDateStr = this.convertDate(startDate);
        let EndDateStr = this.convertDate(EndDate);

        let ClientID = "";
        if(UserInfo != null) ClientID = UserInfo.client_id
        else return             //*********no client_id, canno t request

        // let requestData = {
        //     "msgType": "DeviceStatistics",
        //     "msgID": "IAQSpiderChart",
        //     "clientID": ClientID,
        //     "deviceType": "IAQ-TH",						// IAQ-CO2 / IAQ-TH / ACP (Power)
        //     "timeConstraint": {
        //           "start": startDateStr,
        //           "end": EndDateStr,
        //     },
        //     "timeFrequency": {
        //       "unit": "day",                    // month / day / hours / minutes
        //       "value": 1
        //     },
        //     "locationConstraint": {
        //       "locLvl": 2,                      // 1 - Site / 2 - Floor / 3 - Location
        //       "id": currentlevelID  // location id
        //     },
        //     "locationGrouping": 3,              // 1 - Site / 2 - Floor / 3 - Location
        //     "calculationMethod": "average"      // average / difference
        // }
        const requestData = {
            "msgType": "LocationStatistics",
            "msgID": "IAQSpiderChart",
            "clientID": ClientID,
            "payload_field": ["temperature", "humidity"],
            "timeConstraint": {
              "start": startDateStr,
              "end": EndDateStr,
            },
            "timeFrequency": {
              "unit": "day",
              "value": 1
            },
            "locationConstraint": {
              "locLvl": 2,
              "id": currentlevelID,
            },
            "locationGrouping": 3,
            "calculationMethod": "average"
          }
        console.log(requestData)
        //request the socket for Device Staticstics (contain both temperature and humidity)
        this.props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "StatRequest",
            EmitSocketData: requestData
        })
    }

    componentDidUpdate(preProps) {
        const {currentSite, currentlevel} = this.props
        if(currentSite != preProps.currentSite || currentlevel != preProps.currentlevel) this.LoadData(this.props)

        //check if new props is data change for this widget
        const thisoutside = this
        const {StatSpider} = this.props
        const oldSpiderChart = preProps.StatSpider

        if(oldSpiderChart == null && StatSpider != null) {
            //if yes, generate the new highchart local state
            const ChartData = StatSpider.data
            if(ChartData == null) return            //exception condition
            const tempData = ChartData.temperature
            const humidData = ChartData.humidity
            if(tempData == null || tempData.length == null || humidData == null || humidData.length == null) return    //exception condition, no data or not an array

            //set to local state
            thisoutside.highchartData(tempData, humidData)
        }
        else return //no update redux value, do not do anything
    }

    highchartData(tempData, humidData) {
        const {chartheight} = this.state
        if(tempData == null || humidData == null) return    //exception condition

        // let distinctList = []               //distinct location list
        // tempData.forEach(dataItem => {
        //     let tryfound = distinctList.find(function(listItem){
        //         return listItem.locationName == dataItem.locationName
        //     })
        //     if(tryfound == null) distinctList.push({locationName: dataItem.locationName})
        // })
        // let DisplayData = []
        // distinctList.forEach(distinctListItem => {
        //     let locationName = distinctListItem.locationName
        //     let relatedData = ChartData.filter(dataItem => {
        //         return dataItem.locationName == locationName
        //     })
        //     let ReadingSumTemp = 0
        //     let ReadingSumHumid = 0
        //     let ReadingAverageTemp = 0
        //     let ReadingAverageHumid = 0
        //     let ReadingCount = 0
        //     relatedData.forEach(dataItem => {
        //         ReadingSumTemp += dataItem.temperature
        //         ReadingSumHumid += dataItem.humidity
        //         ReadingCount += 1
        //     })
        //     if(ReadingCount == 0)
        //     {
        //         //no data
        //     }
        //     else
        //     {
        //         ReadingAverageTemp = ReadingSumTemp/ReadingCount
        //         ReadingAverageHumid = ReadingSumHumid/ReadingCount
        //     }
        //     let TempInput = ReadingAverageTemp
        //     let HumidInput = ReadingAverageHumid
        //     DisplayData.push({
        //         temp: TempInput,
        //         humid: HumidInput,
        //         locationName: locationName
        //     })
        // })
        // let tempArray = []
        // let humidArray = []
        // let deviceList = []
        // DisplayData.forEach(DisplayItem => {
        //     tempArray.push(DisplayItem.temp);
        //     humidArray.push(DisplayItem.humid);
        //     deviceList.push(DisplayItem.locationName);
        // })

        //Prepare Location array list
        let locationList = []               //distinct location list
        tempData.forEach(dataItem => {      //add locationname from temp array, if location does not exist
            let isExist = locationList.find(function(listItem){
                return listItem.locationName == dataItem.locationName
            })
            if(isExist == null) locationList.push({locationName: dataItem.locationName})
        })
        humidData.forEach(dataItem => {     //add locationname from humid array, if location does not exist
            let isExist = locationList.find(function(listItem){
                return listItem.locationName == dataItem.locationName
            })
            if(isExist == null) locationList.push({locationName: dataItem.locationName})
        })

        let tempArray = []
        let humidArray = []
        let deviceList = []
        locationList.forEach(locItem => {
            //find all temp records in this location
            const tempArraylist = tempData.filter(function(tempItem) {
                return tempItem.locationName == locItem.locationName.toString()
            })
            let tempTotal = 0.0
            let tempCount = 0.0
            if(tempArraylist == null || tempArraylist.length == null || tempArraylist.length == 0) {tempCount = 1}   //exception condition (no temperature records), show reading value = 0
            else {
                //have records, find the Temp Total and Temp Count
                tempArraylist.forEach(filteredItem => {
                    tempTotal += filteredItem.temperature
                    tempCount++
                })
            }
            //similarly in humidity..
            const humidArraylist = humidData.filter(function(humidItem) {
                return humidItem.locationName == locItem.locationName.toString()
            })
            let humidTotal = 0.0
            let humidCount = 0.0
            if(humidArraylist == null || humidArraylist.length == null || humidArraylist.length == 0) {tempCount = 1}   //exception condition (no temperature records), show reading value = 0
            else {
                //have records, find the Temp Total and Temp Count
                humidArraylist.forEach(filteredItem => {
                    humidTotal += filteredItem.humidity
                    humidCount++
                })
            }
            //input the values into array
            tempArray.push(tempTotal/tempCount)
            humidArray.push(humidTotal/humidCount)
            deviceList.push(locItem.locationName)

            // console.log({
            //     tempTotal: tempTotal,
            //     tempCount: tempCount,
            //     tempArraylist: tempArraylist,
            //     tempData: tempData,
            //     humidTotal: humidTotal,
            //     humidCount: humidCount,
            //     humidArraylist: humidArraylist,
            //     humidData: humidData,
            //     locationList: locationList,
            // })
        })

        const options = {    
            credits: false,        
            chart: {
                //type: 'column',
                height: chartheight,
                //width: chartwidth,
                backgroundColor: {
                    linearGradient: [0, 0, 500, 500],
                    stops: [
                        [0, 'rgba(200, 200, 200, 0)'],
                        [1, 'rgba(0, 0, 0, 0)']
                    ]
                },
                style: {
                    fontFamily: '',
                    color: "var(--optiontext)"
                },
                zoomType: "xy"
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: deviceList,
                /*lineWidth: 5,
                labels: {
                    style: {
                    color: 'black'
                    }
                },*/
                crosshair: true,
                labels: {
                    style: {
                        color: 'var(--optiontext)',
                        width: 50 //where 50 is value in pixels
                    },
                    useHTML:true,
                },
            },        
            yAxis: [{
                labels: {
                    format: '{value} %',
                    lineColor: 'black',
                    style: {
                        color: 'var(--optiontext)'
                    },
                },
                title: '',
                min: 20,
                startOnTick: false
            },
            {
                labels: {
                    format: '{value} C',
                    lineColor: 'black',
                    style: {
                        color: 'var(--optiontext)'
                    },
                },
                title: '',
                min: 10,
                startOnTick: false,
                opposite: true
            }],
            tooltip: {
                shared: true,
                valueDecimals: 2,
            },
            legend: {
                align: 'center',
                verticalAlign: 'bottom',
                itemStyle: {
                    color: 'var(--optiontext)'
                },
                itemHoverStyle: {
                    color: 'grey'
                },
                y: 20,
                x: -20
            },        
            series: [
              {
                name: 'humidity',
                data: humidArray,
                type: 'column',
                color: {
                    linearGradient: [0, 0, 0, 500],
                    stops: [
                        [0, 'rgb(142, 237, 241)'],
                        [1, 'transparent']
                    ]
                },
                tooltip: {
                    valueSuffix: ' %'
                }
              },
              {
                name: 'Temperature',
                data: tempArray,
                type: 'column',
                yAxis: 1,
                color: {
                    linearGradient: [0, 0, 0, 500],
                    stops: [
                        [0, 'rgb(255, 0, 0, 0.3)'],
                        [1, 'red']
                    ]
                },
                tooltip: {
                    valueSuffix: ' C'
                }
            }]
        }

        this.setState({
            ChartData: options
        })
    }

    render() {
        const {chartheight, ChartData} = this.state

        ///**START OF HARDCODE FOR NEW VCITY USER (SHOULD DELETE THIS WHEN PERMISSION WORK IS DONE) */
        // const {UserInfo} = this.props
        // if(UserInfo == null) return <div></div>
        // if(UserInfo.userID == "5f59a0e1114c77095486ca67") return <div className="NoPerm3_main"  ref={this.header_ref}>
        //     <div className="noPerm3_blur" ref={this.barchart_ref}>
        //         <div className="NoPerm3_Txt">No Permission</div>
        //     </div>
        // </div>
        ///**END OF HARDCODE FOR NEW VCITY USER (SHOULD DELETE THIS WHEN PERMISSION WORK IS DONE) */
        
        return <div className="shadow-lg SpiderChart_maindiv">
            <div className="SpiderChart_header" ref={this.header_ref}>
                <i className="fa fa-book headericon"></i>
                {getTxt('Statistic', 'monthSum')}
            </div>
            <div className="SpiderChart_inside" ref={this.barchart_ref}>
                <div className="SpiderChart_innerChartdiv">
                    <HighchartsReact highcharts={HighCharts} options={ChartData} className="highchart_maindiv" />
                </div>           
            </div>
        </div>       
    }
}

//Redux inside component function
function mapStateToProps(state) {
    //console.log(state);
    return {
      socket: state.socket,
      level: state.level,
      currentlevel: state.currentlevel,
      currentSite: state.currentSite,
      MenuDisplay: state.MenuDisplay,
      SiteList: state.SiteList,
      currentBottomMenu: state.currentBottomMenu,
      currentBottomCard: state.currentBottomCard,
      StatisticData: state.StatisticData,
      DeviceDataLastUpdate: state.DeviceDataLastUpdate,
      UserInfo: state.UserInfo,
      currentlevelID: state.currentlevelID,
      StatSpider: state.StatSpider
    };
}

export default connect(mapStateToProps)(SipderChart);