import React from 'react'

class NotiItem extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isClosing: true,
            closeAlready: false,
        }
        this.removeNoti = this.removeNoti.bind(this)
    }
    removeNoti(e, notiItem, index) {
        const _this = this
        this.setState({
            isClosing: true         //close animation
        })

        setTimeout(() => {
            _this.setState({
                closeAlready: true
            })
            _this.props.removeNotifItem(notiItem.id)
        }, 500)
    }
    componentDidMount() {
        const _this = this
        setTimeout(() => {
            _this.setState({
                isClosing: false
            })
        }, 200)
    }

    render() {
        const {notiItem, showListCount, closedList} = this.props
        const {isClosing, closeAlready} = this.state          // for closing animation effect
        const CloseClass = isClosing?"NotificationPopupContainer notiItemclosing":"NotificationPopupContainer"
        const Card_ID = notiItem.id
        if(closeAlready) return <div style={{display: 'none'}}></div>
        
        //count how many item false after me
        const AfterMe = closedList.slice(notiItem.id+1,closedList.length)
        //hide if count >=3
        let count = 0
        AfterMe.forEach(item => {
            if(item == false) count++
        })
        if(count >= 3) return <div style={{display: 'none'}}></div>

        if(notiItem.locationInfo != null && notiItem.locationInfo.length != null && notiItem.locationInfo.length != 0) {
            return <div className={CloseClass} key={Card_ID}>
                <div className="fa fa-bell NotificationPopup_Icon"></div>
                <div className={isClosing?"NotificationWordsContainer notiItemclosing":"NotificationWordsContainer" }>
                    <div className="NotificationPopupTitle">
                        {notiItem.title}
                    </div>
                    <div className="NotificationPopupRow">
                        {notiItem.description}
                    </div>
                    <div className="NotificationPopupLocationRow">
                    {
                        notiItem.locationInfo.map(function(locationItem) {
                            return <div className="NotificationPopupLocationitem">
                                {
                                    (locationItem.lvl == 2)?locationItem.name + "/F":locationItem.name
                                }
                            </div>
                        })
                    }
                    </div>
                </div>
                <div className="fa fa-times NotificationPopup_close" onClick={((e) => this.removeNoti(e, notiItem))}></div>
            </div>
        }
        return <div className={CloseClass} key={Card_ID}>
            <div className="fa fa-bell NotificationPopup_Icon"></div>
            <div className="NotificationWordsContainer">
                <div className="NotificationPopupTitle">
                    {notiItem.title}
                </div>
                <div className="NotificationPopupRow">
                    {notiItem.description}
                </div>
            </div>
            <div className="fa fa-times NotificationPopup_close" onClick={((e) => this.removeNoti(e, notiItem))}></div>
        </div>
    }
}

export default (NotiItem)