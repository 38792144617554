import React from 'react'
import './group.css'
import { connect } from 'react-redux'
//inner widgets
import PageBtns from './pagebtns/pagebtns'
import SearchBar from './searchbar/searchbar'
import Table from './table/table'
import TableHead from './table/tablehead'
//popup widgets
import Create from './popup/create'
import Delete from './popup/delete'
import Manage from './popup/manage'

class ColdboxGroupManage extends React.Component {
    constructor(props) {
        super()

        this.state = {
            isEdit: false,      //true means edit, false mean add
            isDelete: false,    //true when confirm delete the message
            isPopup: false,     //true means open popup, false mean hide popup 
            isLoading: true,
            isAdvanceSearch: false,
            rawGroupList: null,
            GroupList: null,
            //sorted result (by table head .js)
            sortedList: null,
            //filtered result (by searchbar.js)
            filteredList: null,
            //slice by page (by pages.js widget)
            pageSliceList: null,

            updateCount: 0,
            selectedData:null,
            
            AllAssignedColdBox: null,
            AllColdBox: null,
        }

        this.backPage = this.backPage.bind(this)

        this.addPopup = this.addPopup.bind(this)
        this.editPopup = this.editPopup.bind(this)

        this.deletePopup = this.deletePopup.bind(this)
        this.closeDelPopup = this.closeDelPopup.bind(this)

        this.closePopup = this.closePopup.bind(this)
        this.getGroupList = this.getGroupList.bind(this)
        this.refresh = this.refresh.bind(this)
        this.relist = this.relist.bind(this)
        this.UpdateList = this.UpdateList.bind(this)
        this.setSelected = this.setSelected.bind(this)
 
        this.GetLocLv3Req = this.GetLocLv3Req.bind(this)
        this.GetAssignedItemsReq = this.GetAssignedItemsReq.bind(this)
        this.Listener = this.Listener.bind(this)
    } 

    Listener() {
        //create socket listener for acknowledged alarm
        const { socket } = this.props
        const _this = this
        if (socket == null) {
            console.log('socket is null, gateway.js acknowledgeListener()')
            return
        } 
        socket.on("GetAssignedItems", data => {
            console.log(data)
            //display result in popup msg box
            if (data.result == "Success") {
                this.setState({
                    AllAssignedColdBox: data.data
                }) 
            }
            else {
                _this.PopupNotificationRef.addNotificationPopup("Update Failed!", "Failed Message: " + data.message, "")
            }
        })
        socket.on("GetAllLocLv3List", data => {
            console.log(data)
            //display result in popup msg box
            if (data.result == "Success") {
                this.setState({
                    AllColdBox: data.data
                }) 
            }
            else {
                _this.PopupNotificationRef.addNotificationPopup("Update Failed!", "Failed Message: " + data.message, "")
            }
        }) 
    }

    UpdateList(StateStr, Value) {
        console.log("UpdateList " + StateStr)
        const {updateCount} = this.state
        this.setState({
            [StateStr]: Value,
            updateCount: updateCount + 1, 
        })        
    }

    refresh() {
        console.log("refresh")
        
        this.setState(prevState => ({
            ...prevState, 
            isLoading: true
        }))
        this.GetAssignedItemsReq()
        this.GetLocLv3Req()
        this.getGroupList()
        this.relist()
    }
    relist() {
        const _this = this
        const {GroupList} = this.props
        let newList = [] 
        if (GroupList !== null) {
            console.log("this.props.GroupList.groups")
            console.log(this.props.GroupList.groups)
            // console.log(this.state.sortedList )
            if (GroupList.groups !== null) {
                GroupList.groups.map(function (data) {
                    let dataItem = {
                        _id : data._id,
                        group_name: data.group_name,
                        members: data.members,  
                        keywords: JSON.stringify(data)

                    }
                    newList.push(dataItem)
                })
            }

            _this.setState(prevState => ({
                ...prevState,
                GroupList: newList,
                rawGroupList: GroupList.groups, 
                isLoading: false                
            }))        
        }
    }

    GetAssignedItemsReq() {
        const {UserInfo} = this.props

        if (UserInfo == null) return
        const EmitUserID = UserInfo.userID
        //request json 
        const requestData = {
            "msgType": "GetAssignedItems",
            "userID": EmitUserID,
            "client_id": UserInfo.client_id,
        }
        //send with redux socket function
        this.props.dispatch({
            type: 'EmitSocket',
            EmitSocketName: "GroupManageRequest",
            EmitSocketData: requestData
        })
    }
 
    GetLocLv3Req() {
        const { UserInfo, GroupList } = this.props
        if (UserInfo == null) return
        const EmitUserID = UserInfo.userID
        //request json 
        let requestData = {}
        requestData = {
            "msgType": "GetAllLocLv3List",
            "userID": EmitUserID,
            "client_id": UserInfo.client_id,
        }
        //send with redux socket function
        this.props.dispatch({
            type: 'EmitSocket',
            EmitSocketName: "GroupManageRequest",
            EmitSocketData: requestData
        })
    }
    getGroupList() {
        const { UserInfo } = this.props
        if (UserInfo == null) return
        const EmitUserID = UserInfo.userID
        //request json 
        let requestData = {}
        requestData = {
            "msgType": "GetGroupList",
            "userID": EmitUserID,
            "client_id": UserInfo.client_id,
        }

        //send with redux socket function
        this.props.dispatch({
            type: 'EmitSocket',
            EmitSocketName: "GroupManageRequest",
            EmitSocketData: requestData
        })
    }

    componentDidMount() {
        this.refresh() 
         
        this.GetAssignedItemsReq()
        this.GetLocLv3Req()
        this.Listener()
        if(this.props.socket !== undefined ){
            this.props.socket.on("CreateGroup", data=> {
                console.log(data)
                if (data.result == "Success"){
                    this.refresh()
                }
            })
        }       
    }


    componentDidUpdate() {
        const outside = this
        if (this.props.GroupList === null || this.state.rawGroupList !== this.props.GroupList.groups) {
            this.relist()
        }
    }
    backPage() {
        this.props.history.goBack()
    }  
    addPopup() {
        this.setState({
            isEdit: false,
            isPopup: true,
            isDelete: false,
        })
    }
    editPopup() {
        this.setState({
            isEdit: true,
            isPopup: true,
            isDelete: false,
        })
    }
    closePopup() {
        console.log("closePopup")
        this.refresh()
        this.setState({
            isEdit: false,
            isPopup: false,
            isDelete: false,
        })
    }
    deletePopup() {
        this.setState({
            isDelete: true
        })
    }
    closeDelPopup() {
        this.setState({
            isDelete: false,
        })
    }

    setSelected(data) {
        this.setState({ 
            selectedData:data, 
        });
        this.editPopup()
    }
    render() {
        const _this = this
        const {isEdit, isDelete, isPopup,filteredList,pageSliceList,GroupList,sortedList,updateCount,AllAssignedColdBox,AllColdBox} = this.state
        
        return [
        <div className="ColdboxGp_Manage">
            <SearchBar backPage={_this.backPage} refresh={_this.refresh}
                    GroupList={GroupList} UpdateList={_this.UpdateList} />
            <div className="Coldbox_Manage_table">
                <TableHead addPopup={_this.addPopup}  filteredList={filteredList} UpdateList={_this.UpdateList} />
                
                {(pageSliceList !== null && pageSliceList !== undefined ?
                        pageSliceList.map(function (data) {
                            return <Table editPopup={_this.editPopup}
                            data={data}
                            setSelected={_this.setSelected}
                            />
                        })
                        :
                        ""
                    )}
                
            </div>
            <PageBtns sortedList={sortedList} UpdateList={_this.UpdateList} count={updateCount} />
        </div>,
        (isPopup && !isDelete)?
            <div className="Coldbox_group_PopupBG" onClick={this.closePopup}></div>
            :
            <div style={{display: 'none'}}></div>,
        <Manage isPopup={isPopup} isEdit={isEdit} isDelete={isDelete}  closePopup={this.closePopup} 
        data = {_this.state.selectedData} deleteConfirm={this.deletePopup} 
        AllAssignedColdBox= {AllAssignedColdBox}
        AllColdBox= {AllColdBox} GetAssignedItemsReq = {this.GetAssignedItemsReq} GetLocLv3Req = {this.GetLocLv3Req}/>,
        <Create isPopup={isPopup} isEdit={isEdit} isDelete={isDelete}  closePopup={this.closePopup} />,
        <Delete isPopup={isPopup} isEdit={isEdit} isDelete={isDelete}  closePopup={this.closePopup}  data = {_this.state.selectedData} />,
        ]
    }
}

function mapStateToProps(state) {
    return {
        socket: state.socket,
        currentlevel: state.currentlevel,
        currentSite: state.currentSite,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate, //when this value is updated, render would triggered
        StatisticData: state.StatisticData,
        UserInfo: state.UserInfo,
        currentlevelID: state.currentlevelID,
        GroupList:state.GroupList
    }
}  
export default connect(mapStateToProps)(ColdboxGroupManage)