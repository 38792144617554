import React from 'react'
import './searchbar.css'
//Redux Import
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker';
import moment from 'moment';

class Searchbar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            startdate: new Date(moment().subtract(7, 'days')),
            enddate: new Date(),
            //option list
            groupList: null,
            siteList: null,
            floorList: null,
            typeList: ['Fault', 'Warning'],
            statusList: ['Closed', 'Open', 'Acknowledged', 'Auto-resumed'],            
            //filter current values (null or empty str = no filter)
            filterTxt: '',
            filterGroup: '',
            filterSite: '',
            filterLevel: '',
            filterStatus: '',
            filterType: '',
            filterFrom: '',
            filterTo: '',
           
            Filtered: null,      // Filter Result
            loadAnimate: false,  // when true, rotate
        }

        this.GenOptions = this.GenOptions.bind(this)
        this.FilterOnchange = this.FilterOnchange.bind(this)
        this.Filter = this.Filter.bind(this)
        this.setStartTime = this.setStartTime.bind(this)
        this.setEndTime = this.setEndTime.bind(this)
        this.refresh = this.refresh.bind(this)
    }
    componentDidMount() {
        const {coldboxList} = this.props
        const {startdate, enddate} = this.setState
        if (coldboxList==null||coldboxList==undefined) return
        if(startdate === null) {
            let temp = enddate
            temp.setDate(enddate.getMonth -1)
            this.setState(prevState => ({
                ...prevState,
                startdate: temp, 
            }))
        }
        this.GenOptions()
    }
    componentDidUpdate(preProps) {
        const { coldboxList } = this.props
        const oldcoldboxList = preProps.coldboxList
        if (coldboxList!=oldcoldboxList||(coldboxList!=null&&oldcoldboxList==null)) this.GenOptions()  //Re-gen option list when data is updated
    }
    refresh() {
        const _this = this
        _this.setState({loadAnimate: true})
        _this.props.refresh()
        setTimeout(function() {_this.setState({loadAnimate: false})}, 2000)
    }
    GenOptions() {
        const {coldboxList, UserInfo, currentSite} = this.props
        if (coldboxList === null || coldboxList === undefined) return

        const locationInfo = UserInfo.locations[currentSite].nestedLocs
        const Options = coldboxList.map((coldboxItem, index) => {
            const { group, site, floor, type, status } = coldboxItem
            return {
                group: group,
                site: site,
                level: floor,
                id: index,
                type: type,
                status: status
            }
        })
        //Group List
        var GroupList = Options.map(item => item.group)
            .filter((value, index, self) => self.indexOf(value) === index)
        GroupList = GroupList.sort()
        //Site List
        var SiteList = Options.map(item => item.site)
            .filter((value, index, self) => self.indexOf(value) === index)
        SiteList = SiteList.sort()
        //Level List
        var levelList = locationInfo
        levelList = levelList.sort()
        //Type List
        var typeList = Options.map(item => item.type)
            .filter((value, index, self) => self.indexOf(value) === index)
        typeList = typeList.sort()
        //Status List
        var statusList = Options.map(item => item.status)
            .filter((value, index, self) => self.indexOf(value) === index)
        statusList = statusList.sort()

        this.setState({
            groupList: GroupList,
            siteList: SiteList,
            floorList: levelList,
            Filtered: coldboxList,
            //typeList: typeList,
            statusList: statusList
        })
        this.Filter()
    }
    FilterOnchange(keyStr, e) {
        try {
            if(keyStr ==="filterLevel"){  
                let obj = this.state.floorList.find(o =>o._id===e.target.value) 
                const id = e.target.value.toString() 
                const name = obj.locName.toString() 
                this.props.setSelectedLevel(id)
                this.setState({
                    [keyStr]: name,
                }, function () {        //run right after state is set
                    this.Filter()       //re-filter
                })
            } else {
                const value = e.target.value.toString()
                // console.log(e.target)
                this.setState({
                    [keyStr]: value,
                }, function () {        //run right after state is set
                    this.Filter()       //re-filter
                })
            }
        }
        catch (err) {
            console.log(err)
        }
    }
    Filter() {
        const {filterTxt, filterGroup, filterType, filterLevel, filterStatus} = this.state
        const {coldboxList} = this.props

        var FilteredList = coldboxList
        if (filterTxt != null && filterTxt != "") {
            FilteredList = FilteredList.filter(function (coldboxItem) {
                return coldboxItem.keywords.includes(filterTxt.toString())
            })
        }
        if (filterGroup != null && filterGroup != "") {
            FilteredList = FilteredList.filter(function (coldboxItem) {
                return coldboxItem.group == filterGroup.toString()
            })
        }
        if (filterType != null && filterType != "") {
            FilteredList = FilteredList.filter(function (coldboxItem) {
                return coldboxItem.type == filterType.toString()
            })
        } 
        if (filterLevel != null && filterLevel != "") {
            FilteredList = FilteredList.filter(function (coldboxItem) { 
                return coldboxItem.floor.toString() == filterLevel.toString()
            })
        }
        if (filterStatus != null && filterStatus != "") {
            // console.log(filterStatus)
            FilteredList = FilteredList.filter(function (coldboxItem) { 
                return coldboxItem.status.toString() == filterStatus.toString()
            })
        }
        this.setState({
            Filtered: FilteredList
        })
        this.props.UpdateList('filteredList', FilteredList)
    }

    setStartTime(data) {
        this.setState({ startdate: data })
        this.props.setStartTime(data)
    }
    setEndTime(data) {
        this.setState({ enddate: data })
        this.props.setEndTime(data)
    }

    render() {
        const _this = this
        const { isAdvanceSearch } = this.props
        const {floorList, statusList, typeList, loadAnimate} = this.state

        return <div className="CBAlarm_head">
            <div className="CBAlarm_txt">From:</div>
            <div className="cdbxDPicker">
                <DatePicker
                    selected={this.state.startdate}
                    onChange={this.setStartTime}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="d-MMMM yyyy HH:mm"
                    timeCaption="time"
                />
            </div>
            <div className="CBAlarm_txt">To:</div>
            <div className="cdbxDPicker">
                <DatePicker
                    selected={this.state.enddate}
                    onChange={this.setEndTime}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="d-MMMM yyyy HH:mm"
                    timeCaption="time"
                    className="cdbxDPicker"
                />
            </div>
            {
                isAdvanceSearch?
                    <div className="CBAlarm_hiddenSearches">
                        <div className="CBAlarm_txt">Level</div>
                        <select className="CBAlarm_select" onChange={(e) => _this.FilterOnchange('filterLevel', e)}>
                            <option value="">All Levels</option>
                            {
                                (floorList == null || floorList.length == null || floorList.length == 0) ?
                                    <div style={{ display: 'none' }}></div>
                                    :
                                    floorList.map(function (levelItem, index) {
                                        return <option value={levelItem._id} >{levelItem.locName}</option>
                                    })
                            }
                        </select>
                        <div className="CBAlarm_txt">Keyword</div>
                        <input className="CBAlarm_input" placeholder="Keyword search" onChange={(e) => _this.FilterOnchange('filterTxt', e)}></input>
                        <div className="CBAlarm_txt">Type</div>
                        <select className="CBAlarm_select" onChange={(e) => _this.FilterOnchange('filterType', e)}>
                            <option value="">All</option>
                            {
                                (typeList == null || typeList.length == null || typeList.length == 0) ?
                                    <div style={{ display: 'none' }}></div>
                                    :
                                    typeList.map(function (typeItem) {
                                        return <option value={typeItem}>{typeItem}</option>
                                    })
                            }
                        </select>
                        <div className="CBAlarm_txt">Status</div>
                        <select className="CBAlarm_select" onChange={(e) => _this.FilterOnchange('filterStatus', e)}>
                            <option value="">All</option>
                            {
                                (statusList == null || statusList.length == null || statusList.length == 0) ?
                                    <div style={{ display: 'none' }}></div>
                                    :
                                    statusList.map(function (statusItem) {
                                        return <option value={statusItem}>{statusItem}</option>
                                    })
                            }
                        </select>
                        <div className="CBAlarm_adSearchBtn" onClick={_this.props.closeAdSearch}>
                            <div className="fa fa-times"></div>
                        </div>
                    </div>
                    :
                    <div className="CBAlarm_adSearchBtn" onClick={_this.props.AdvanceSearch} key="cdbxsearchbtn">Advanced Search</div>
            }
            <div className="CBAlarm_searchBtn">
                <div className={loadAnimate?"fa fa-refresh fa-spin":"fa fa-refresh"} onClick={()=>_this.refresh()}></div>
            </div>
            <div className='CBAAlarm_tips'>
                <div className='row'>
                    <div className='point' style={{background: 'red'}}></div>
                    Fault
                </div>
                <div className='row'>
                    <div className='point' style={{background: 'orange'}}></div>
                    Warning
                </div>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        UserInfo: state.UserInfo,
        currentSite: state.currentSite,
    }
}
export default connect(mapStateToProps)(Searchbar)