import React from 'react'
import './style.css'
import { connect } from 'react-redux'
import Moment from 'react-moment'
import SoundPlay from '../../../Sound/Sound'

class FloorPlanTableTable extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            closedMenu: []  //array of string of menuList, when exist in list, hide the inner reading rows
        }
        this.hideRow = this.hideRow.bind(this)        
        this.getNoti = this.getNoti.bind(this)          //get notification records (1 month)

        this.soundRef = React.createRef()
    }
    hideRow(menuStr) {
        this.soundRef.current.alarmMp3Run('hover')
        let newCloseMenu = this.state.closedMenu
        const {closedMenu} = this.state
        let ifExist = closedMenu.find(menuItem => menuItem == menuStr)
        if(ifExist == null) newCloseMenu.push(menuStr)                  //add to hide list
        else newCloseMenu = closedMenu.filter(menuItem => menuItem != menuStr) //remove from hide list
        this.setState({
            closedMenu: newCloseMenu
        })
    }
    componentDidMount() {
        this.getNoti()      //request for notification (1 month) to get 'lastalarmdatetime' of each location
    }
    getNoti() {
        const {UserInfo} = this.props
        if(UserInfo == null) return
        let startDate = new Date()
        startDate.setMonth(startDate.getMonth()-1)
        let endDate = new Date()
        const requestData = {
            'msgType': 'AlarmList',
            'userID': UserInfo.userID,
            'timeConstraint': {
                  'start': startDate.toISOString(),
                  'end': endDate.toISOString(),
            }
        }
        this.props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: 'AlarmRequest',
            EmitSocketData: requestData
        })
    }

    render() {
        const _this = this
        const {menuList, pickedType, UserInfo} = this.props             //send from parent, filtering items
        if(UserInfo == null) return <></>

        let {Notification} = this.props  //redux value, stored the one month notification record
        if(Notification==null) Notification = {data: []}
        const {closedMenu} = this.state
        let menuListArray = pickedType==null||pickedType=='all'?menuList:[pickedType]

        return <div className='floorplantable_table'>
            <SoundPlay ref={this.soundRef}/>
            <div className='floorplantable_tableMain'>
                {
                    menuListArray.map(menuItem => {  //get all item that fit this menu string (devicename)
                        let deviceList = getDeviceList(_this.props, menuItem)

                        ///**START OF HARDCODE FOR NEW VCITY USER (SHOULD DELETE THIS WHEN PERMISSION WORK IS DONE) */
                        if(UserInfo.userID=='5f59a0e1114c77095486ca67' && menuItem!='Rubbish Bin' && menuItem!='Toilet Occupancy') return <></>     //hardcode, not show this type of reading
                        ///**END OF HARDCODE FOR NEW VCITY USER (SHOULD DELETE THIS WHEN PERMISSION WORK IS DONE) */
                        
                        return [
                            <div className={(!closedMenu.includes(menuItem))?'floorplantable_menuRow':'floorplantable_menuRow disableMenuRow'} onClick={() => _this.hideRow(menuItem)}>
                                {(!closedMenu.includes(menuItem))?menuItem:'↓ ' + menuItem}
                            </div>,
                            (closedMenu.includes(menuItem))?<div className='hideMenuReadingList'></div>:deviceList.map(deviceItem => {
                                const readingArray = getReadingArray(deviceItem)  //keys and value in deviceItem payload
                                const {alarmTriggerTime, UpdateTime} = getAlarmTriggerTime(deviceItem, Notification) //get last trigger alarm

                                return <div className='floorplantable_ReadingItem'>
                                    <div className='floorplantable_leftrowItem'>
                                        <div className='FlrTblrow'>{deviceItem.floor + '/F'}</div>
                                        <div className='FlrTblrow'>{deviceItem.locItem.locName}</div>
                                        {
                                            readingArray.map(function(readingItem) {
                                                const keyStr = readingItem.key
                                                if(keyStr==null || keyStr=='lastTriggerTime' || keyStr=='lightOn') return <></>//some hard code keys which should not be show

                                                //key to be show
                                                let boolReading
                                                let NumberReading
                                                let keyDisplay = readingItem.key
                                                if (typeof readingItem.reading === 'boolean' && readingItem.key !== 'roomOccupied') boolReading = readingItem.reading.toString()
                                                else if(readingItem.key == 'updateDate') return <></>
                                                else if(readingItem.key == 'roomOccupied') {
                                                    keyDisplay = 'Occupied'
                                                    if(readingItem.reading) boolReading = 'Occupied'
                                                    else boolReading = 'Vacant'
                                                }
                                                else if(readingItem.key=='waterLeakageStatus') {
                                                    keyDisplay = 'Water Leakage'
                                                    if(readingItem.reading==0||readingItem.reading==false) boolReading = 'Normal'
                                                    else boolReading = 'Abnormal'
                                                }
                                                else NumberReading = Number(readingItem.reading).toFixed(1)

                                                return <div className='FlrTblrow'>
                                                    <div className='key'>{keyDisplay + ':'}</div>
                                                    <div className='value'>{(boolReading== null)?NumberReading:boolReading}</div>
                                                </div>
                                            })
                                        }
                                        <div className='FlrTblrow alarm'>
                                            <div className='key'>Latest Alarm:</div>
                                            <div className='value'>{alarmTriggerTime}</div>
                                        </div>
                                        <div className='FlrTblrow alarm'>
                                            <div className='key'>Update Alarm:</div>
                                            <div className='value'>{(deviceItem.locItem.payload==null||deviceItem.locItem.payload.updateDate==null)?
                                                '--':UpdateTime}</div>
                                        </div>
                                    </div>
                                    {/* <div className='floorplantable_rightrowItem'>
                                        <div className='floorplantable_RowReadingColMain lastalarm'>
                                            <div className='floorplantable_RowReadingCol innerlastalarm'>Latest Alarm:</div>
                                            <div className='floorplantable_RowReadingCol innerlastalarm'>
                                                {alarmTriggerTime}
                                            </div>
                                        </div>
                                        <div className='floorplantable_RowReadingColMain lastalarm'>
                                            <div className='floorplantable_RowReadingCol innerlastalarm'>Update Time:</div>
                                            <div className='floorplantable_RowReadingCol innerlastalarm'>
                                                {(deviceItem.locItem.payload==null||deviceItem.locItem.payload.updateDate==null)?
                                                '--':UpdateTime}
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            })
                        ]
                    })
                }
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        DeviceData: state.DeviceData,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate,
        UserInfo: state.UserInfo,
        Notification: state.Notification,
    }
}
export default connect(mapStateToProps)(FloorPlanTableTable)

const getDeviceList = (props, menuItem) => {
    const {DeviceData, pickedSite, pickedFloor, pickedLoc} = props
    let deviceList = []
    DeviceData.forEach(siteItem => {
        if(pickedSite != null && pickedSite != 'all' && pickedSite != siteItem.locName || siteItem.nestedLocs == null) return
        siteItem.nestedLocs.forEach(floorItem => {
            if(pickedFloor != null && pickedFloor != 'all' && pickedFloor != floorItem.locName || floorItem.nestedLocs == null) return
            floorItem.nestedLocs.forEach(locItem => {
                if(pickedLoc != null && pickedLoc != 'all' && pickedLoc != locItem.locName || locItem.payload_definition_id == null || locItem.payload_definition_id.name == null) return
                const deviceName = locItem.payload_definition_id.name
                if(deviceName == menuItem) deviceList.push({
                    locItem: locItem,
                    floor: floorItem.locName
                })
            })
        })
    })

    return deviceList
}
const getReadingArray = (deviceItem) => {
    let readingArray = []       //reading key and values
    for (var key in deviceItem.locItem.payload) {
        readingArray.push({
            key: key,
            reading: deviceItem.locItem.payload[key]
        })
    }
    return readingArray
}
const getAlarmTriggerTime = (deviceItem, Notification) => {
    const NotiList = Notification.data
    const getAlarmItem = (NotiList==null||NotiList.length==null)?null:NotiList.find(alarmItem => {
        if(alarmItem==null || alarmItem.locationInfo==null) return false //exception notification data item
        let isExist = false
        alarmItem.locationInfo.forEach(locInfoItem => {
            if(locInfoItem.name == deviceItem.locItem.locName) isExist = true
        })
        return isExist
    })
    let formattedDate
    if(getAlarmItem != null && getAlarmItem.createdTime != null) {
        const splitdate = getAlarmItem.createdTime.split('T')
        formattedDate = splitdate[0] + ' ' + splitdate[1]
    }
    const alarmTriggerTime = (formattedDate == null)?'--':<Moment className='' format='DD/MM HH:mm'>{formattedDate}</Moment>
    const UpdateTime = (deviceItem.locItem.payload==null||deviceItem.locItem.payload.updateDate==null)?
    '--':<Moment className='' format='DD/MM HH:mm'>{deviceItem.locItem.payload.updateDate}</Moment>

    return {
        alarmTriggerTime: alarmTriggerTime,
        UpdateTime: UpdateTime
    }
}