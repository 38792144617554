import React from 'react';
import './LineChartDataItem.css';
//Redux Import
import { connect } from 'react-redux'

import SoundPlay from '../../../Sound/Sound'

class LineChartDataItem extends React.Component {
    constructor(props)
    {
        super()
        this.state = {
            deviceList: [],
            selectList: [],
            isHide: true,
            isAutoY: false,
        }
        this.selectDevice = this.selectDevice.bind(this)

        this.HideWidget = this.HideWidget.bind(this)
        this.ShowWidget = this.ShowWidget.bind(this)
        this.AutoY = this.AutoY.bind(this)
        this.DefaultY = this.DefaultY.bind(this)

        this.soundRef = React.createRef()
    }
    componentDidUpdate(preProps) {
        const {chartValue} = this.props
        const oldchartValue = preProps.chartValue
        if(chartValue != oldchartValue) {
            let newSelectList = []
            chartValue.forEach(Item => {
                newSelectList.push({
                    name: Item.name,
                    isShow: true
                })
            })

            this.setState({
                deviceList: chartValue,
                selectList: newSelectList,
            })
        }
    }
    selectDevice(selectedDevice) {
        this.soundRef.current.alarmMp3Run("page")
        //update local state
        const {selectList} = this.state
        let newSelectList = []
        selectList.forEach(deviceItem => {
            if(deviceItem.name != selectedDevice.name) {
                newSelectList.push(deviceItem)
            } else {
                let clickedItem = deviceItem
                clickedItem.isShow = !clickedItem.isShow
                newSelectList.push(clickedItem)
            }
        })
        this.setState({
            selectList: newSelectList,
        })
        //send to parent with function
        this.props.setDeviceFunction(newSelectList)
    }
    HideWidget() {
        this.setState({
            isHide: true
        })
    }
    ShowWidget() {
        this.setState({
            isHide: false
        })
    }
    AutoY() {
        this.setState({
            isAutoY: true
        })
        this.props.SetIsAuto(true)
    }
    DefaultY() {
        this.setState({
            isAutoY: false
        })
        this.props.SetIsAuto(false)
    }
    render()
    {
        const thisoutside = this
        const {deviceList, selectList, isHide, isAutoY} = this.state
        const DeviceListWidget = (isHide)?<div className="DeviceList_ShowBtn" key="develist_showBtn" onClick={thisoutside.ShowWidget}>
            Sensor Filter
        </div>
        :
        <div className="DeviceList_main">
            <div className="DeviceList_hideBtn" onClick={thisoutside.HideWidget}>
                {"Hide Filter" }
            </div>
            <div className="DeviceList">
                <SoundPlay ref={this.soundRef}/>
                {
                    selectList.map(function(deviceItem) {
                        return <div className={(deviceItem.isShow)?"deviceItemDiv":"deviceItemDiv hideenDevice"} 
                        onClick={() => thisoutside.selectDevice(deviceItem)}>
                            {deviceItem.name}
                        </div>
                    })
                }
            </div>
        </div>

        return [
            DeviceListWidget,
        <div className="YAxis_option">
            <div className={(isAutoY)?"YAxis_auto YAxisHighlight":"YAxis_auto "} onClick={thisoutside.AutoY}>
                Auto Y
            </div>
            <div className={(!isAutoY)?"YAxis_default YAxisHighlight":"YAxis_default "} onClick={thisoutside.DefaultY}>
                Default Y
            </div>
        </div>
        ]
    }
}

function mapStateToProps(state) {
    return {
    }
}
  
export default connect(mapStateToProps)(LineChartDataItem)

// This is the sub widget inside Linechart.js, to show/hide specific device item from chart