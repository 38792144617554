import React, { useState, useEffect } from 'react'
import './Schedule.css'
import { connect } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'

import getColorCode from '../commonfunction'
import SchedulePopupWidget from './popup'

const MinutesInterval = 10
let TickFunction

function ScheduleWidget(props) {
    const {ToiletType, UserInfo, DeviceData, currentSite, currentlevel, socket, UserManageUserList} = props
    const [ResponseData, setResponseData] = useState()
    const [PopupData, setPopupData] = useState()                //null means close popup, data means the edit item, or adding
    const [UserListArr, setUserListArr] = useState([])

    const {color1, color2, gradient1, gradient2} = getColorCode(ToiletType)

    useEffect(() => {
        if(socket == null) return
        socket.on("Toilet_schedule", data => {
            console.log(data)
            if(data==null||data.result==null||data.result=="Fail") {
                console.error("Get Toilet Schedule fail:" + data.error)
                return
            }
            //save to local state
            setResponseData(data.data)
        })
        socket.on("", data => {
            console.log(data)
        })
        return function ClearWidgetItems() {
            socket.off("Toilet_schedule")
        }
    }, [])

    useEffect(() => {
        if(TickFunction != null) clearInterval(TickFunction)
        TickFunction = setInterval(RequestData, 1000 * 60 * 60 * MinutesInterval)
        RequestData()
        RequestUserList()
    }, [DeviceData, ToiletType, currentlevel, currentSite])
    useEffect(() => {
        return function TerminateInterval() {
            clearInterval(TickFunction)
        }
    }, [])
    useEffect(() => {
        if(UserManageUserList == null) return
        if(UserManageUserList.users == null || UserManageUserList.users.length == null || UserManageUserList.users.length == 0) return
        setUserListArr(UserManageUserList.users)
    }, [UserManageUserList])
    function RequestData() {
        if(DeviceData == null) return
        //setFallRecords([])           //clear old data         //..

        const DateStart = GetStartDate()
        const DateEnd = GetEndDate()
        const {SiteID, SiteName} = GetSiteID(DeviceData, currentSite)
        const {LevelID, LevelName} = GetLevelID(DeviceData, currentSite, currentlevel)
        const client_id = UserInfo.client_id
        const EmitJson = {
            "msgType": "Toilet_schedule",
            "method": "Read",
            "client_id": client_id,
            "locationInfo": [
                {
                    "id": SiteID,
                    "lvl": 1,
                    "name": SiteName
                },
                {
                    "id": LevelID,
                    "lvl": 2,
                    "name": LevelName
                }
            ],
            "toiletType": ToiletType.toString(), //1 = male, 2 = female, 3 = other
            // "staffID": "",
            "start": convertDate(DateStart),
            "end": convertDate(DateEnd),
        }
        console.log(EmitJson)
        setTimeout(() => {
            //emit statistic request
            props.dispatch({ type: 'EmitSocket', 
                EmitSocketName: "StatRequest",
                EmitSocketData: EmitJson
            })
        }, 100)
    }
    function RequestUserList() {
        const { UserInfo } = props
        const EmitData = {
            msgType: "AccountList",
            userID: UserInfo.userID
        }
        props.dispatch({
            type: 'EmitSocket',
            EmitSocketName: "UserManageRequest",
            EmitSocketData: EmitData
        })
    }

    const FilterList = ResponseData==null?[]:ResponseData.filter(item => {
        return item.toiletType.toString() == ToiletType.toString()
    })
    const FormattedList = FilterList.map(item => {
        let mainT = ''
        let mainF = ''
        if(item.startClean == "1970-01-01T00:00:00.000Z" || item.endClean == "1970-01-01T00:00:00.000Z") {
            mainF = '--'
            mainT = item.scheduleTime
        }
        else {
            mainF = item.endClean
            mainT = item.startClean
        }
        return {
            name: item.staffID,
            mainT: mainT,
            mainF: mainF,
            id: item.id,
        }
    })

    return <div className="Toilet_admin_schedule" style={{background: 'linear-gradient(to right top, '+ gradient1 +', '+ gradient2 +')'}}>
        <div className="head">
            <div className='Toilet_admin_sch_col1'>Staff</div>
            <div className="Toilet_admin_sch_col2">Main. Time</div>
            <div className="Toilet_admin_sch_col3">Finish Time</div>
            <div className="Toilet_admin_addbtn" onClick={()=>setPopupData({type: 'add'})}>+</div>
        </div>
        <div className="headline"></div>
        <div className="table">
            {
                FormattedList.map(item => {
                    return <div className="Toilet_admin_sch_row">
                        <div className='Toilet_admin_sch_col1'>
                            {GetEditUserFromUserID(item.name, UserListArr)}
                        </div>
                        <div className="Toilet_admin_sch_col2">
                            {convertDateTodisplay(item.mainT)}
                        </div>
                        <div className="Toilet_admin_sch_col3">
                            {convertDateTodisplay(item.mainF)}
                        </div>
                        <div className="Toilet_admin_addbtn" onClick={()=>setPopupData({type: 'edit', data: item})}>
                            <FontAwesomeIcon icon={faPen} />
                        </div>
                    </div>
                })
            }
        </div>
        <SchedulePopupWidget PopupData={PopupData} setPopupData={setPopupData} RequestData={RequestData} UserList={UserManageUserList} />
    </div>
}
function mapStateToProps(state) {
    return {
        ToiletType: state.ToiletType,
        DeviceData: state.DeviceData,
        currentSite: state.currentSite,
        currentlevel: state.currentlevel,
        socket: state.socket,
        UserInfo: state.UserInfo,
        UserManageUserList: state.UserManageUserList,
    }
}
export default connect(mapStateToProps)(ScheduleWidget)

function GetSiteID(DeviceData, currentSite) {
    try {
        const SiteItem = DeviceData[currentSite]
        const SiteID = SiteItem._id
        const SiteName = SiteItem.locName
        return {
            SiteID: SiteID,
            SiteName: SiteName
        }
    }
    catch(err) {
        return err
    }
}
function GetLevelID(DeviceData, currentSite, currentlevel) {
    try {
        const LevelList = DeviceData[currentSite].nestedLocs
        let LevelID = "No Level ID found"
        let LevelName = "No Level Name found"
        LevelList.forEach(LevelItem => {
            if(LevelItem.locName == currentlevel || LevelItem.locDesc == currentlevel) {
                LevelID = LevelItem._id
                LevelName = LevelItem.locName
            }
        })
        return {
            LevelID: LevelID,
            LevelName: LevelName,
        }
    }
    catch(err) {
        return err
    }
}
function GetStartDate() {
    var d = new Date()
    d.setMonth(d.getMonth() - 1)
    return d
}
function GetEndDate() {
    var d = new Date()
    d.setMonth(d.getMonth() + 1)
    return d
}
function convertDate(DateInput)
{
    let DateMonth = DateInput.getMonth() + 1
    let DateDay = DateInput.getDate()
    if(DateMonth < 10) DateMonth = "0" + DateMonth
    if(DateDay < 10) DateDay = "0" + DateDay
    let HourStr = DateInput.getHours()
    if(HourStr < 10) HourStr = "0" + HourStr
    let MinutesStr = DateInput.getUTCMinutes()
    if(MinutesStr < 10) MinutesStr = "0" + MinutesStr
    const DateStr = DateInput.getFullYear() + "-" + DateMonth + "-" + DateDay + "T" + HourStr + ":" + MinutesStr + ":00Z"
    return DateStr
}
function convertDateTodisplay(DateInputStr) {
    if(DateInputStr == '--') return '--'
    try {
        let DateInput = new Date(DateInputStr)
        DateInput.setHours(DateInput.getHours() - 8)

        let DateMonth = DateInput.getMonth() + 1
        let DateDay = DateInput.getDate()

        if(DateMonth < 10) DateMonth = "0" + DateMonth
        if(DateDay < 10) DateDay = "0" + DateDay
        let HourStr = DateInput.getHours()
        if(HourStr < 10) HourStr = "0" + HourStr
        let MinutesStr = DateInput.getUTCMinutes()
        if(MinutesStr < 10) MinutesStr = "0" + MinutesStr
        
        const DateStr = DateInput.getFullYear() + "-" + DateMonth + "-" + DateDay + " " + HourStr + ":" + MinutesStr
        return DateStr
    }
    catch(err) {
        return '--'
    }
}

function GetEditUserFromUserID(SelectUser, UserListArr) {
    if(UserListArr == null || UserListArr.length == null || UserListArr.length == 0) return     //exception
    let ModifyingItem = UserListArr.find(data=> {
        return data.userID == SelectUser
    })
    if(ModifyingItem == null) return '--'
    return ModifyingItem.username
}