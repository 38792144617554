export default function genDataArray(monthArray, StatisticData, readingField) {
    const statData = getStatData(StatisticData, readingField)

    if(monthArray==null || monthArray.length == null || monthArray.length == 0) return []

    let returnArray = []
    monthArray.forEach(monthItem => {
        const tryFindMonthData = statData.filter(item => item.timeStr == monthItem)

        if(tryFindMonthData==null || tryFindMonthData.length==null || tryFindMonthData.length==0) {
            returnArray.push({
                timeStr: monthItem,
                // [readingField]: '--'
            })
        }
        else {
            tryFindMonthData.forEach(element => {
                returnArray.push({
                    ...element,
                    timeStr: monthItem
                })
            })
        }
    })
    return returnArray
}

function getStatData(StatisticData, readingField) {
    if(StatisticData==null||readingField==null) return

    const dataList = StatisticData[readingField]
    if(dataList==null) return []

    const formmatedData = dataList.map((item) => {
        const HKTime = convertHKZone(item.time)
        const {month, year} = getMonthYear(HKTime)
        return {
            ...item,
            timeStr: month + '-' + year
        }
    })
    return formmatedData
}

function convertHKZone(dateInput) {
    let dateValue = new Date(dateInput)
    dateValue.setHours(dateValue.getHours() + 8)
    return dateValue
}
function getMonthYear(dateInput) {
    let month = dateInput.getMonth()
    let year = dateInput.getFullYear()
    return {
        month: monthArray[month],
        year: year
    }
}
const monthArray = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
]

// const hardCMonthList = [
//     'Aug-2021',
//     'Sep-2021',
//     'Oct-2021',
//     'Nov-2021'
// ]
// const hardCStatisticData = {
//     mileageTravelled: [
//         {
//             locationName: "Lift Demo #1",
//             location_id: "5e72e57d1013069fa9a3abd3",
//             mileageTravelled: 119128,
//             time: "2021-08-31T16:00:00.000Z"  // > Sep-2021
//         },
//         {
//             locationName: "Lift Demo #1",
//             location_id: "5e72e57d1013069fa9a3abd3",
//             mileageTravelled: 49483,
//             time: "2021-09-31T16:00:00.000Z"  // > Oct-2021
//         }
//     ]
// }
// const hardCreadingField = 'mileageTravelled'

// console.log(genDataArray(hardCMonthList, hardCStatisticData, hardCreadingField))