import React from 'react'
import './table.css'

//Redux Import
import { connect } from 'react-redux'

class Table extends React.Component {
    constructor(props)
    {
        super()
    }

    render()
    {
        return <div className="Coldbox_GD_TableRow" onClick={this.props.openPopup}>
            <div className="col1">C0001</div>
            <div className="col2 warn">4.2 C</div>
            <div className="col3">20 Bar</div>
            <div className="col4">34 Bar</div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
    }
}
  
export default connect(mapStateToProps)(Table)