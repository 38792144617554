import React, { useState, useEffect } from 'react'
import './dashboardtable.css'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTable } from '@fortawesome/free-solid-svg-icons'

import {getTxt} from '../../common/language/language'

function DashboardTableBtn(props) {
    const [IsColdboxUser, SetIsColdboxUser] = useState(false)
    useEffect(() => {
        const {UserInfo} = props
        if(UserInfo == null) return     //exception
        const permissions = UserInfo.permissions
        if(permissions == null || permissions.length == null || permissions.length == 0) return
        let IsColdboxBool = false
        permissions.forEach(PerItem => {
            if(PerItem.collectionType.includes('Coldbox')) IsColdboxBool = true
        })
        SetIsColdboxUser(IsColdboxBool)
    }, [])

    return (IsColdboxUser==false)?
    <div className="SideNavRowItem" onClick={props.FloorPlanTable}>
        <FontAwesomeIcon icon={faTable} className="fa sideIcon" />
        <div>
            {getTxt('LSide', 'dashbdT')}
        </div>
    </div>
    :
    <div style={{display: 'none'}}>Not show Dashboard Table for Coldbox</div>
}

//Redux inside component function
function mapStateToProps(state) {
    return {
      UserInfo: state.UserInfo,
    }
}

export default connect(mapStateToProps)(DashboardTableBtn)