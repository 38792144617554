export const GetRelatedList = (props, Site, Level, StatusString) => {
    const {DeviceData, UserInfo} = props
    let RelatedList_1 = []
    if(Site == "All") {
        //show all coldbox
        DeviceData.forEach(SiteItem => {
            SiteItem.nestedLocs.forEach(LevelItem => {
                LevelItem.nestedLocs.forEach(locItem => {
                    RelatedList_1.push(locItem)
                })
            })
        })
    }
    else if(Level == "All") {
        //show one site coldbox
        const LevelArray = DeviceData[Site].nestedLocs
        LevelArray.forEach(LevelItem => {
            LevelItem.nestedLocs.forEach(locItem => {
                RelatedList_1.push(locItem)
            })   
        })
    }
    else {
        //show one level coldbox
        const LocArray = DeviceData[Site].nestedLocs.find((lvlItem) => {
            return lvlItem.locName == Level
        })
        LocArray.nestedLocs.forEach(locItem => {
            RelatedList_1.push(locItem)
        })
    }
    const RelatedList = RelatedList_1.filter(function(item) {
        if(item == null || item.payload_definition_id == null || item.status == null) return
        const itemStatusStr = (item.payload==null||item.payload.Status==null)?'':item.payload.Status.toString()
        const tableStatusStr = StatusString.toString()
        // return item.payload_definition_id != null && item.payload_definition_id.cardTitle == "Coldbox" && item.status.Status == StatusString     // status.Status is the hardcode data
        return item.payload_definition_id != null && item.payload_definition_id.cardTitle == "Coldbox" && itemStatusStr == tableStatusStr
    })
    return RelatedList
}