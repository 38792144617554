import React from 'react'
//Drag test
import { WidthProvider, Responsive  } from 'react-grid-layout'
import _ from "lodash"
//Redux Import
import { connect } from 'react-redux'
import DragOptionBar from './DragOptionbar'
//page Import
import Map from '../Map/Map'
import MenuList from '../MenuList/MenuList'
//header widget (13-Aug-2019)
import SiteLevelPicker from '../SiteLevelPicker/SiteLevelPicker'
import SiteLevelPicker_single from '../SiteLevelPicker/SiteLevelPicker_single/SiteLevelPicker_single'
//summayr title
import Summary from '../Summary/Summary'
//summary cards
import SummartCard from '../Summary/SummaryCard/SummaryCard_4reading'
import SummartCard2 from '../Summary/SummaryCard/SummaryCard2'
import SummartCard3 from '../Summary/SummaryCard/SummaryCard3'
import SummartCard4 from '../Summary/SummaryCard/SummaryCard4'
import IEQ_V3 from '../Summary/IEQ_v3/IEQ_V3'
import IEQ_V4 from '../Summary/IEQ_V4/IEQ_V4'
import OEQ from '../Summary/OEQ/OEQ'
//VCity new cards
import LuxSummary from '../Summary/LuxSummary'
import BinSummary from '../Summary/BinSummary'
//import Lift Components
import LiftCCTV from '../LiftWidgets/LiftCCTV'
import LiftGraph from '../LiftWidgets/LiftGraph'
import LiftHeader from '../LiftWidgets/LiftHeader'
import LiftRecords from '../LiftWidgets/LiftRecords'
import LiftAcc from '../LiftWidgets/LiftAcc'
import RangeLimit from '../LiftWidgets/RangeLimit'
import DoorStatus from '../LiftWidgets/DoorStatus'
import IAQStatus from '../LiftWidgets/IAQStatus'
import PplDetect from '../LiftWidgets/PplDetect'
//new reading card widgets for dashboard
import MenuList_new from '../ScrollCard/MenuList'
import BottomDisplay from '../MenuList/BottomDisplay/BottomDisplay'
//CIC project widgets: water leakage, toilet occ
import WLeakage from '../Summary/WLeakage'
import ToiletOcc from '../Summary/ToiletOcc'
import ToiletOcc_V2 from '../Summary/ToiletOcc_V2/ToiletOcc_V2'
// Toilet project Widgets (old, demo only)
// import LevelWidget from '../Toilet/LevelWidget'             //not used
// import ToiletFloorPlan from '../Toilet/ToiletFloorPlan'
// import ToiletInfo from '../Toilet/ToiletInfo'
// import ToiletList from '../Toilet/ToiletList'               //may use later
// import Feedback from '../Toilet/feedback'
// import DefectList from '../Toilet/DefectList'
// import DateTimeWidget from '../Toilet/DateTimeWidget'
import ConditionToiletWidget from '../Toilet/ConditionToilet'
import OccupancyWidget from '../Toilet/Occ/Occupancy'
import CleanScheduleWidget from '../Toilet/CleanSchedule'
import FeedbackWidget from '../Toilet/feedback'
// import IssueReportWidget from '../Toilet/IssueReport'
// import SelectSiteWidget from '../Toilet/SelectSite'
import ToiletSelectWidget from '../Toilet/ToiletSelect'
import Emergency from '../Toilet/Emergency/Emergency'
import WeatherWidget from '../Toilet/Weather/Weather'
// Toilet Admin
import FloorPlanWidget from '../ToiletAdmin/FloorPlan/FloorPlan'
import AdminOccupancyWidget from '../ToiletAdmin/Occupancy/Occupancy'
import ScheduleWidget from '../ToiletAdmin/Schedule/Schedule'
import ToiletIAQWidget1 from '../ToiletAdmin/chart/chart1'
// import ToiletIAQWidget2 from '../ToiletAdmin/chart/chart2'
// import ToiletIAQWidget3 from '../ToiletAdmin/chart/chart3'
// import ToiletIAQWidget4 from '../ToiletAdmin/chart/chart4'
import ToiletTaskWidget from '../ToiletAdmin/tasks/tasks'
// import ToiletFallAlarmWidget from '../ToiletAdmin/fallalarm/fallalarm'
import ToiletFeedbackRecord from '../ToiletAdmin/feedbackRecord/feedbackRecord'
import ToiletServiceRecord from '../ToiletAdmin/requestService/requestService'
import ToiletOccStatisticWidget from '../ToiletAdmin/Occupancy/OccupancyStatistic'
//CPSL coldbox project widgets
import ColdboxFloorPlan from '../Coldbox/coldboxFloorPlan'
import ColdboxList from '../Coldbox/coldboxList'
import ColdboxStatus from '../Coldbox/coldboxStatus'
//central market project widgets
import CarParkSummary from '../Summary/carpark/carpark'
import DoorContactSummary from '../Summary/doorcontact/doorcontact'
import CarOccSummary from '../Summary/occupancy/occupancy'
import $ from 'jquery'
import Loading from '../../Login/loading'

import NTUSummary from '../Summary/ntu/ntu'
import PumpSummary from '../Summary/pumpSummary/pumpSummary'
import PeopleCountSum from '../Summary/peopleCountSum/peopleCountSum'

//const ReactGridLayout = WidthProvider(RGL);
const ResponsiveReactGridLayout = WidthProvider(Responsive)

class DragLayout extends React.Component {
    static defaultProps = {
        className: "layout",
        rowHeight: (window.innerHeight - 75)/20,
        cols: { lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 },
    }

    constructor(props) {
        super(props)

        var layout = this.generateLayout()
        const componentCount = 7
        this.state = { layout, componentCount, isLoading:false}
        this.onLayoutChange = this.onLayoutChange.bind(this)
        this.generateLayout = this.generateLayout.bind(this)
        this.saveLayoutPosition = this.saveLayoutPosition.bind(this)
        this.onAddItem = this.onAddItem.bind(this)
        this.UpdateWidgetLockUnlock = this.UpdateWidgetLockUnlock.bind(this)

        this.resetLayout = this.resetLayout.bind(this)
        this.resetLayout2 = this.resetLayout2.bind(this)

        //for parent component call function inside this component, set onRef
        this.props.onRef(this)
    }

    generateLayout() {
        return []
        //return the array layout (hardcode, would get from DB later)
        return [
            //{
            //    x:8, y:1, w:4, h:1, i:"summary", isResizable: false, maxH: 200, maxW: 200, minH: 0, minW: 0, static: true
            //},
            {
                x:0, y:1, w:8, h:10, i:"map", isResizable: false, maxH: 200, maxW: 200, minH: 0, minW: 0, static: true
            },
            {
                x:0, y:11, w:12, h:4, i:"menu", isResizable: false, maxH: 200, maxW: 200, minH: 0, minW: 0, static: true
            },
            {
                x:8, y:1, w:4, h:5, i:"card1", isResizable: false, maxH: 200, maxW: 200, minH: 0, minW: 0, static: true
            },
            {
                x:8, y:6, w:2, h:5, i:"card2", isResizable: false, maxH: 200, maxW: 200, minH: 0, minW: 0, static: true
            },
            {
                x:10, y:6, w:2, h:5, i:"card3", isResizable: false, maxH: 200, maxW: 200, minH: 0, minW: 0, static: true
            },
            {
                x:8, y:0, w:12, h:1, i:"siteLevelPicker", isResizable: false, maxH: 200, maxW: 200, minH: 0, minW: 0, static: true
            }
        ];
    }

    onLayoutChange(layout) {
        console.log(layout)
        this.setState({ layout: layout })
    }

    saveLayoutPosition() {
        //get the layout current positions
        //..
        console.warn('save()')
        console.warn(this.state.layout)
        const {layout} = this.state
        //send back (return)
        return layout
    }

    onBreakpointChange(breakpoint, cols) {
        /*this.setState({
          breakpoint: breakpoint,
          cols: cols
        });*/

        //console.log('onBreakpointChange');
        //console.log(breakpoint);
        //console.log(cols);
    }

    onRemoveItem(i) {
        console.log("removing", i)
        this.setState({ layout: _.reject(this.state.layout, { i: i }) })
    }
    
    onAddItem(AddWidget) {
        // console.log("adding")
        // console.log(this.state.layout)

        //set to state (push a new item to array list)
        try {
            const Width = parseInt(AddWidget.W.toString())
            const height = parseInt(AddWidget.H.toString())
            let newcomponent = {
                x:0, y:0, w: Width, h: height, i: AddWidget.itemStr.toString(), component: AddWidget.rawStr
            }
            this.setState({
                layout: [...this.state.layout, newcomponent],
                componentCount: this.state.componentCount + 1
            })
        }
        catch(err){
            console.log(err)
        }
    }
    
    createElement(el) {
        // console.log(el)
        const removeStyle = {}
        const i = el.add ? "+" : el.i

        let { isLock } = this.props
        let isHide = ""
        if(isLock) isHide = ""
        else isHide = " hide"

        //console.log(el.component);
        //console.log(el.i);
        //console.log('creatELement');
        switch(el.i) {
            case "summary":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <Summary/>
                        }
                        <span
                            className={"remove" + isHide}
                            style={removeStyle}
                            onClick={this.onRemoveItem.bind(this, i)}
                            >
                            <i className="fa fa-times"></i>
                        </span>
                    </div>
                );
            case "map":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <Map editbuttonhideclass={isHide}/>
                        }
                        <span
                            className={"remove" + isHide}
                            style={removeStyle}
                            onClick={this.onRemoveItem.bind(this, i)}
                            >
                            <i className="fa fa-times"></i>
                        </span>
                    </div>
                );
            case "menu":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <MenuList/>
                        }
                        <span
                            className={"remove" + isHide}
                            style={removeStyle}
                            onClick={this.onRemoveItem.bind(this, i)}
                            >
                            <i className="fa fa-times"></i>
                        </span>
                    </div>
                );
            case "card1":
                return (
                    <div key={i} data-grid={el} className="drag">
                            {
                                <SummartCard/>
                            }
                        <span
                            className={"remove" + isHide}
                            style={removeStyle}
                            onClick={this.onRemoveItem.bind(this, i)}
                            >
                            <i className="fa fa-times"></i>
                        </span>
                    </div>
                );
            case "card2":
                return (
                    <div key={i} data-grid={el} className="drag">
                            {
                                <SummartCard2/>
                            }
                        <span
                            className={"remove" + isHide}
                            style={removeStyle}
                            onClick={this.onRemoveItem.bind(this, i)}
                            >
                            <i className="fa fa-times"></i>
                        </span>
                    </div>
                );
            case "card3":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <SummartCard3/>
                        }
                        <span
                            className={"remove" + isHide}
                            style={removeStyle}
                            onClick={this.onRemoveItem.bind(this, i)}
                            >
                            <i className="fa fa-times"></i>
                        </span>
                    </div>
                );
            case "card4":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <SummartCard4/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                </div>
            );
            case "LuxSummary":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <LuxSummary/>
                        }
                        <span
                            className={"remove" + isHide}
                            style={removeStyle}
                            onClick={this.onRemoveItem.bind(this, i)}
                            >
                            <i className="fa fa-times"></i>
                        </span>
                    </div>
                )
            case "BinSummary":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <BinSummary/>
                        }
                        <span
                            className={"remove" + isHide}
                            style={removeStyle}
                            onClick={this.onRemoveItem.bind(this, i)}
                            >
                            <i className="fa fa-times"></i>
                        </span>
                    </div>
                ) 
            case "siteLevelPicker":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <SiteLevelPicker/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                </div>
            )
            case 'siteLevelPicker_singleSite':
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <SiteLevelPicker_single/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                </div>
                )
            case "Lift_header":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <LiftHeader/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                </div>
            );
            case "Lift_graph":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <LiftGraph/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                </div>
            );
            case "Lift_CCTV":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <LiftCCTV/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                </div>
            );
            case "Lift_records":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <LiftRecords/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                </div>
            );
            case "LiftAcc":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <LiftAcc/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                </div>
            )
            case "RangeLimit":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <RangeLimit/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                </div>
            )
            case "DoorStatus":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <DoorStatus/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                </div>
            )
            case "IAQStatus":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <IAQStatus/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                </div>
            )
            case "PplDetect":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <PplDetect/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                </div>
            )
            case "LevelBtnList":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <LiftRecords WidgetType="LevelBtnList"/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                </div>
            )
            case "LevelDisplay":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <LiftRecords WidgetType="LevelDisplay"/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                </div>
            )
            case "LiftDetail":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <LiftRecords WidgetType="LiftDetail"/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                </div>
            )
            case "LiftSiteImg":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <LiftRecords WidgetType="LiftSiteImg"/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                </div>
            )
            case "MenuList_new":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <MenuList_new WidgetType="MenuList_new"/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                </div>
            )
            case "BottomDisplay":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <BottomDisplay WidgetType="BottomDisplay"/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                </div>
            )
            // case "LevelWidget":
            //     return (
            //         <div key={i} data-grid={el} className="drag">
            //             {
            //                 <LevelWidget WidgetType="LevelWidget"/>
            //             }
            //         <span
            //             className={"remove" + isHide}
            //             style={removeStyle}
            //             onClick={this.onRemoveItem.bind(this, i)}
            //             >
            //             <i className="fa fa-times"></i>
            //         </span>
            //         </div>
            // )
            // case "ToiletFloorPlan":
            //     return (
            //         <div key={i} data-grid={el} className="drag">
            //             {
            //                 <ToiletFloorPlan WidgetType="ToiletFloorPlan"/>
            //             }
            //         <span
            //             className={"remove" + isHide}
            //             style={removeStyle}
            //             onClick={this.onRemoveItem.bind(this, i)}
            //             >
            //             <i className="fa fa-times"></i>
            //         </span>
            //         </div>
            // )
            // case "ToiletInfo":
            //     return (
            //         <div key={i} data-grid={el} className="drag">
            //             {
            //                 <ToiletInfo WidgetType="ToiletInfo"/>
            //             }
            //         <span
            //             className={"remove" + isHide}
            //             style={removeStyle}
            //             onClick={this.onRemoveItem.bind(this, i)}
            //             >
            //             <i className="fa fa-times"></i>
            //         </span>
            //         </div>
            // )
            // case "toiletList":
            //     return (
            //         <div key={i} data-grid={el} className="drag">
            //             {
            //                 <ToiletList WidgetType="toiletList"/>
            //             }
            //         <span
            //             className={"remove" + isHide}
            //             style={removeStyle}
            //             onClick={this.onRemoveItem.bind(this, i)}
            //             >
            //             <i className="fa fa-times"></i>
            //         </span>
            //         </div>
            // )
            case "WLeakCard":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <WLeakage WidgetType="WLeakage"/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                    </div>
            )
            case "ToiletOccCard":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <ToiletOcc WidgetType="ToiletOcc"/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                    </div>
            )
            case 'ToiletOccCard_V2':
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <ToiletOcc_V2 WidgetType="ToiletOcc_V2"/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                    </div>
                )
            // case "ToiletFeedback":
            //     return (
            //     <div key={i} data-grid={el} className="drag">
            //         {
            //             <Feedback WidgetType="Feedback"/>
            //         }
            //     <span
            //         className={"remove" + isHide}
            //         style={removeStyle}
            //         onClick={this.onRemoveItem.bind(this, i)}
            //         >
            //         <i className="fa fa-times"></i>
            //     </span>
            //     </div>
            // )
            // case "DefectList":
            //     return (
            //     <div key={i} data-grid={el} className="drag">
            //         {
            //             <DefectList WidgetType="DefectList"/>
            //         }
            //     <span
            //         className={"remove" + isHide}
            //         style={removeStyle}
            //         onClick={this.onRemoveItem.bind(this, i)}
            //         >
            //         <i className="fa fa-times"></i>
            //     </span>
            //     </div>
            // )
            case 'Coldbox_FloorPlan':
                return (
                <div key={i} data-grid={el} className="drag">
                    {
                        <ColdboxFloorPlan WidgetType="ColdboxFloorPlan" history={this.props.history}/>
                    }
                <span
                    className={"remove" + isHide}
                    style={removeStyle}
                    onClick={this.onRemoveItem.bind(this, i)}
                    >
                    <i className="fa fa-times"></i>
                </span>
                </div>
            )
            case 'Coldbox_List':
                return (
                <div key={i} data-grid={el} className="drag">
                    {
                        <ColdboxList WidgetType="ColdboxList" history={this.props.history} />
                    }
                <span
                    className={"remove" + isHide}
                    style={removeStyle}
                    onClick={this.onRemoveItem.bind(this, i)}
                    >
                    <i className="fa fa-times"></i>
                </span>
                </div>
            )
            case 'Coldbox_StatusTable':
                return (
                <div key={i} data-grid={el} className="drag">
                    {
                        <ColdboxStatus WidgetType="ColdboxStatus"/>
                    }
                <span
                    className={"remove" + isHide}
                    style={removeStyle}
                    onClick={this.onRemoveItem.bind(this, i)}
                    >
                    <i className="fa fa-times"></i>
                </span>
                </div>
            )
            // case "DateTimeWidget":
            //     return (
            //         <div key={i} data-grid={el} className="drag">
            //             {
            //                 <DateTimeWidget WidgetType="DateTimeWidget"/>
            //             }
            //         <span
            //             className={"remove" + isHide}
            //             style={removeStyle}
            //             onClick={this.onRemoveItem.bind(this, i)}
            //             >
            //             <i className="fa fa-times"></i>
            //         </span>
            //         </div>
            //     )
            case "ConditionToiletWidget":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <ConditionToiletWidget WidgetType="ConditionToiletWidget"/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                    </div>
                )
            case "OccupancyWidget":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <OccupancyWidget WidgetType="OccupancyWidget"/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                    </div>
                )
            case "CleanScheduleWidget":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <CleanScheduleWidget WidgetType="CleanScheduleWidget"/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                    </div>
                )
            case "FeedbackWidget":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <FeedbackWidget WidgetType="FeedbackWidget"/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                    </div>
                )
            // case "IssueReportWidget":
            //     return (
            //         <div key={i} data-grid={el} className="drag">
            //             {
            //                 <IssueReportWidget WidgetType="IssueReportWidget"/>
            //             }
            //         <span
            //             className={"remove" + isHide}
            //             style={removeStyle}
            //             onClick={this.onRemoveItem.bind(this, i)}
            //             >
            //             <i className="fa fa-times"></i>
            //         </span>
            //         </div>
            //     )
            // case "SelectSiteWidget":
            //     return (
            //         <div key={i} data-grid={el} className="drag">
            //             {
            //                 <SelectSiteWidget WidgetType="DateTimeWidget"/>
            //             }
            //         <span
            //             className={"remove" + isHide}
            //             style={removeStyle}
            //             onClick={this.onRemoveItem.bind(this, i)}
            //             >
            //             <i className="fa fa-times"></i>
            //         </span>
            //         </div>
            //     )
            case "ToiletSelectWidget":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <ToiletSelectWidget WidgetType="ToiletSelectWidget"/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                    </div>
                )
            case "FloorPlanWidget":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <FloorPlanWidget WidgetType="FloorPlanWidget"/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                    </div>
                )
            case "AdminOccupancyWidget":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <AdminOccupancyWidget WidgetType="AdminOccupancyWidget"/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                    </div>
                )
            case "ScheduleWidget":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <ScheduleWidget WidgetType="ScheduleWidget"/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                    </div>
                )
            case "Emergency":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <Emergency WidgetType="Emergency"/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                    </div>
                )
            case "WeatherWidget":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <WeatherWidget WidgetType="WeatherWidget"/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                    </div>
                )
            case "ToiletIAQ_1":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <ToiletIAQWidget1 WidgetType="ToiletIAQ_1"/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                    </div>
                )
            // case "ToiletIAQ_2":
            //     return (
            //         <div key={i} data-grid={el} className="drag">
            //             {
            //                 <ToiletIAQWidget2 WidgetType="ToiletIAQ_2"/>
            //             }
            //         <span
            //             className={"remove" + isHide}
            //             style={removeStyle}
            //             onClick={this.onRemoveItem.bind(this, i)}
            //             >
            //             <i className="fa fa-times"></i>
            //         </span>
            //         </div>
            //     )
            // case "ToiletIAQ_3":
            //     return (
            //         <div key={i} data-grid={el} className="drag">
            //             {
            //                 <ToiletIAQWidget3 WidgetType="ToiletIAQ_3"/>
            //             }
            //         <span
            //             className={"remove" + isHide}
            //             style={removeStyle}
            //             onClick={this.onRemoveItem.bind(this, i)}
            //             >
            //             <i className="fa fa-times"></i>
            //         </span>
            //         </div>
            //     )
            // case "ToiletIAQ_4":
            //     return (
            //         <div key={i} data-grid={el} className="drag">
            //             {
            //                 <ToiletIAQWidget4 WidgetType="ToiletIAQ_4"/>
            //             }
            //         <span
            //             className={"remove" + isHide}
            //             style={removeStyle}
            //             onClick={this.onRemoveItem.bind(this, i)}
            //             >
            //             <i className="fa fa-times"></i>
            //         </span>
            //         </div>
            //     )
            case "ToiletTasks":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <ToiletTaskWidget WidgetType="ToiletTasks"/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                    </div>
                )
            // case "ToiletFallAlarm":
            //     return (
            //         <div key={i} data-grid={el} className="drag">
            //             {
            //                 <ToiletFallAlarmWidget WidgetType="ToiletFallAlarm"/>
            //             }
            //         <span
            //             className={"remove" + isHide}
            //             style={removeStyle}
            //             onClick={this.onRemoveItem.bind(this, i)}
            //             >
            //             <i className="fa fa-times"></i>
            //         </span>
            //         </div>
            //     )
            case "feedbackrecord":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <ToiletFeedbackRecord WidgetType="ToiletFeedbackRecord"/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                    </div>
                )
            case "servicerecord":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <ToiletServiceRecord WidgetType="ToiletServiceRecord"/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                    </div>
                )
            case "ToiletOccStatistic":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <ToiletOccStatisticWidget WidgetType="ToiletOccStatisticWidget"/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                    </div>
                )
            case "IEQ_V3":
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <IEQ_V3 WidgetType="IEQ_V3"/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                    </div>
                )
            case 'IEQ_V4':
                return (
                    <div key={i} data-grid={el} className="drag">
                        {
                            <IEQ_V4 WidgetType="IEQ_V4"/>
                        }
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                    </div>
                )
            case 'cardparkSummary':
                return (
                    <div key={i} data-grid={el} className="drag">
                        {<CarParkSummary WidgetType="CarParkSummary"/>}
                        <span
                            className={"remove" + isHide}
                            style={removeStyle}
                            onClick={this.onRemoveItem.bind(this, i)}
                            >
                            <i className="fa fa-times"></i>
                        </span>
                    </div>
                )
            case 'DoorContactSummary':
                return (
                    <div key={i} data-grid={el} className="drag">
                        {<DoorContactSummary WidgetType="DoorContactSummary"/>}
                        <span
                            className={"remove" + isHide}
                            style={removeStyle}
                            onClick={this.onRemoveItem.bind(this, i)}
                            >
                            <i className="fa fa-times"></i>
                        </span>
                    </div>
                )
            case 'carOccSummary':
                return (
                    <div key={i} data-grid={el} className="drag">
                        {<CarOccSummary WidgetType="CarOccSummary"/>}
                        <span
                            className={"remove" + isHide}
                            style={removeStyle}
                            onClick={this.onRemoveItem.bind(this, i)}
                            >
                            <i className="fa fa-times"></i>
                        </span>
                    </div>
                )
            case 'OEQ':
                return (
                    <div key={i} data-grid={el} className="drag">
                    {<OEQ WidgetType="OEQ"/>}
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                </div>
                )
            case 'NTUSummary':
                return (
                    <div key={i} data-grid={el} className="drag">
                    {<NTUSummary WidgetType="NTUSummary"/>}
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                </div>
                )
            case 'PumpSummary':
                return (
                    <div key={i} data-grid={el} className="drag">
                    {<PumpSummary WidgetType="PumpSummary"/>}
                    <span
                        className={"remove" + isHide}
                        style={removeStyle}
                        onClick={this.onRemoveItem.bind(this, i)}
                        >
                        <i className="fa fa-times"></i>
                    </span>
                </div>
                )
            case 'PeopleflowSensor':
                return (
                    <div key={i} data-grid={el} className="drag">
                        {<PeopleCountSum WidgetType="PeopleCountSum"/>}
                        <span
                            className={"remove" + isHide}
                            style={removeStyle}
                            onClick={this.onRemoveItem.bind(this, i)}
                            >
                            <i className="fa fa-times"></i>
                        </span>
                    </div>
                )
        default: 
            break
        }
        return (
          <div key={i} data-grid={el}>
            {el.add ? (
              <span
                className="add text"
                onClick={this.onAddItem}
                title="You can add an item by clicking here, too."
              >
                Add +
              </span>
            ) : (
              <span className="text">{i} is not correct case!</span>
            )}
            <span
              className={"remove" + isHide}
              style={removeStyle}
              onClick={this.onRemoveItem.bind(this, i)}
            >
              x
            </span>
          </div>
        )
      }

    UpdateWidgetLockUnlock() {
        //check the widget lock / unlock setting
        let { isLock } = this.props
        
        let currentlayout = this.state.layout
        let updatedlayout = []
        //foreach widget item, static: true/false
        $.each(currentlayout, function(key, value) {
            //console.log(value);
            let foreachitem = value
            if(isLock != null) {
                foreachitem.static = isLock
            }
            updatedlayout.push(foreachitem)
        })
        // console.log(updatedlayout)
        //set the updatedlayout to state
        this.setState({
            layout: updatedlayout
        })
    }

    resetLayout() {
        this.setState({
            layout: []
        })
    }
    resetLayout2(PropsInput) {
        const {UserInfo, currentSite, currentlevel} = PropsInput
        const currentSiteInt = parseInt(currentSite, 0)
        try {
            //get the current level ID
            const levelItem = UserInfo.locations[currentSiteInt].nestedLocs.find(function(element) {
                return element.locName == currentlevel
            })
            if(levelItem == null || levelItem == undefined) {
                alert('Cannot find Level Itme with currentSite, currentLevel')
                return
            }
            const levelId = levelItem._id
            if(levelId == null || levelId == undefined) {
                alert('Cannot get level ID from UserInfo')
                return
            }
            //get the widget array based on level ID
            const UIWidgetList = UserInfo.ui.find(function(uiItem) {
                return uiItem.location_id.toString() == levelId && uiItem.pageType == "Dashboard" //"Statistics"
            })
            if(UIWidgetList == null || UIWidgetList == undefined) {
                alert('UI Widget List in UserInfo does not included location _id:' + levelId)
                alert("Each floor should have one widget array in UserInfo")
                return
            }
            const LayoutSet = UIWidgetList.widgetConfig
            LayoutSet.forEach(LayoutItem => {
                LayoutItem.static = true
            })
            //set the widget Layout to this.state
            this.setState({
                layout: LayoutSet
            })
        }
        catch(err) {
            console.log('error when getting the widget json with level ID in Draglayout.js, Floorplan')
            console.log(err)
        }
    }

    componentDidUpdate(preProps) { 
        const _this = this
        try {
            //console.log('componentWillReceiveProps() in draglayout.js /floorplan');
            //run update only when currentsite value is chhanged
            const newCurrentSite = this.props.currentSite
            const oldCurrentSite = preProps.currentSite
            const newCurrentlevel = this.props.currentlevel
            const oldCurrentlevel = preProps.currentlevel

            if(oldCurrentSite == newCurrentSite && oldCurrentlevel == newCurrentlevel) return       //no update in picked site and level

            setTimeout(function(){
                if(_this==null||_this==undefined) return
                _this.resetLayout()
            }, 0)
            setTimeout(function(){
                if(_this==null||_this==undefined) return
                _this.resetLayout2(_this.props)
            }, 10)
        }
        catch(err) {
            console.log(err)
        }
    }

    componentDidMount() {
        //will mount tasks
        //onload, set the widget with UserInfo.ui
        const _this = this
        setTimeout(function(){_this.resetLayout()}, 0)
        setTimeout(function(){_this.resetLayout2(_this.props)}, 10)
        //end of will mount tasks
    }

    render() {
        const {isLock} = this.props
        const {layout} = this.state
        const _this = this
        //get layout at render()
        if(layout == null) return <Loading/>
        //set the layout into .map return
        return [
            <ResponsiveReactGridLayout
                onBreakpointChange={this.onBreakpointChange}
                onLayoutChange={this.onLayoutChange}
                layouts={this.state.layout}
                {...this.props}
                history={this.props.history}
                useCSSTransforms={true}        //false = no tranisition css effect
            >
            {_.map(this.state.layout, el => this.createElement(el))}
            </ResponsiveReactGridLayout>,
            <DragOptionBar isLock={isLock} WidgetLock={_this.props.WidgetLock} saveLayoutPosition={_this.saveLayoutPosition} onAddItem={_this.onAddItem}  widgetType="Dashboard" />
        ]
    }
}

function mapStateToProps(state) {
    return {
      socket: state.socket,
      level: state.level,
      currentlevel: state.currentlevel,
      currentSite: state.currentSite,
      MenuDisplay: state.MenuDisplay,
      UserInfo: state.UserInfo,
      DeviceData: state.DeviceData,
      DashboardPickedColdboxID: state.DashboardPickedColdboxID
    }
}
export default connect(mapStateToProps)(DragLayout)