import React from 'react'
import './statusdisplay.css'

//Redux Import
import { connect } from 'react-redux'

class StatusDisplay extends React.Component {
    constructor(props)
    {
        super()
    }

    render()
    {
        const {ServerStatus} = this.props
        const {IoT, CPSL, LoRa} = ServerStatus

        let IoTTxt, IoTClass
        switch(IoT) {
            case 1:
                IoTTxt = 'Healthy'
                IoTClass = 'health'
                break
            case 2:
                IoTTxt = 'Unstable'
                IoTClass = 'warn'
                break
            case 3:
                IoTTxt = 'Unhealthy'
                IoTClass = 'error'
                break
        }
        let CPSLTxt, CPSLClass
        switch(CPSL) {
            case 1:
                CPSLTxt = 'Healthy'
                CPSLClass = 'health'
                break
            case 2:
                CPSLTxt = 'Unstable'
                CPSLClass = 'warn'
                break
            case 3:
                CPSLTxt = 'Unhealthy'
                CPSLClass = 'error'
                break
        }
        let LoRaTxt, LoRaClass
        switch(LoRa) {
            case 1:
                LoRaTxt = 'Healthy'
                LoRaClass = 'health'
                break
            case 2:
                LoRaTxt = 'Unstable'
                LoRaClass = 'warn'
                break
            case 3:
                LoRaTxt = 'Unhealthy'
                LoRaClass = 'error'
                break
        }

        return <div className="operOver_sysStatus">
            <div className={"operOver_sysStatusItem " + IoTClass}>
                IoT Server Connection: {IoTTxt}
            </div>
            <div className={"operOver_sysStatusItem " + CPSLClass}>
                CPSL Server Connection: {CPSLTxt}
            </div>
            <div className={"operOver_sysStatusItem " + LoRaClass}>
                LoRaWAN Gateway: {LoRaTxt}
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
    }
}
  
export default connect(mapStateToProps)(StatusDisplay)