import moment from 'moment'

const getLastUpdate = (locs, type) => {
    const {ctrl1, ctrl2, ctrl3, PMC1, PMC2} = locs
    let {ctrl1_v, ctrl2_v, ctrl3_v, PMC1_v, PMC2_v} = getUpdateDates(locs)

    let latestValue = getLatestRelatedValue({
        ctrl1: ctrl1_v,
        ctrl2: ctrl2_v,
        ctrl3: ctrl3_v,
        PMC1: PMC1_v,
        PMC2: PMC2_v
    }, type)

    return latestValue
}
export {getLastUpdate}

const getUpdateDates = locs => {
    const {ctrl1, ctrl2, ctrl3, PMC1, PMC2} = locs

    return {
        ctrl1_v: ctrl1==null?null:ctrl1.lastUpdate,
        ctrl2_v: ctrl2==null?null:ctrl2.lastUpdate,
        ctrl3_v: ctrl3==null?null:ctrl3.lastUpdate,
        PMC1_v: PMC1==null?null:PMC1.lastUpdate,
        PMC2_v: PMC2==null?null:PMC2.lastUpdate
    }
}
const getLatestRelatedValue = (locs, type) => {
    let setting = typeList.find(item => item.id == type)
    if(setting==null || setting.locName==null) return
    let relatedLocs = setting.locName
    let updateDateList = relatedLocs.map(key => Date.parse(locs[key]))
    
    return Math.max(...updateDateList)
}
const typeList = [
    {
        id: 'water q 1',
        locName: ['ctrl2', 'ctrl3']
    },
    {
        id: 'water q 2',
        locName: ['ctrl1', 'ctrl2']
    },
    {
        id: 'water q 3',
        locName: ['ctrl2', 'ctrl3']
    },
    {
        id: 'plant status 1',
        locName: ['ctrl2']
    },
    {
        id: 'Automatic Control Mode 1',
        locName: ['ctrl2']
    },
    {
        id: 'Flow Meter 1',
        locName: ['ctrl1']
    },
    {
        id: 'Carbon detect 1',
        locName: ['ctrl1']
    },
    {
        id: 'Carbon detect 2',
        locName: ['ctrl1']
    },
    {
        id: 'Carbon detect 3',
        locName: ['ctrl1']
    },
    {
        id: 'Carbon detect 4',
        locName: ['ctrl1']
    },
    {
        id: 'Pump statistic 1',
        locName: ['ctrl1', 'ctrl2']
    },
    {
        id: 'Pump statistic 2',
        locName: ['ctrl1', 'ctrl2']
    },
    {
        id: 'Pump Consumption 1',
        locName: ['PMC1']
    },
    {
        id: 'Pump Consumption 2',
        locName: ['PMC2']
    },
    {
        id: 'Pump Status 1',
        locName: ['ctrl1', 'ctrl3']
    },
    {
        id: 'Pump Status 2',
        locName: ['ctrl1', 'ctrl3']
    },
]