import React from 'react'
//Redux Import
import { connect } from 'react-redux'
import './pickLevelWidget.css'

class PickLevelWidget extends React.Component {
    constructor(props) {
        super(props)
        this.PickLevel = this.PickLevel.bind(this)
    }

    PickLevel(event) {
        // alert(event.target.id);
        let clickedID = event.target.id
        //remove "widgetlevelbtn_" from id
        let level = clickedID.replace('widgetlevelbtn_', '')
        console.log(level)
        //redux update the currentlevel
        this.props.dispatch({ type: 'PickLevel', data: level })
        if (this.props.UserInfo.client_id === "6006850161a378359cb738bf") { //reset coldbox dashboard data
            this.props.dispatch({
                type: "DashboardPickedColdboxID",
                data: ""
            });

            this.props.dispatch({
                type: 'PickPolygon',
                data: "" //string of locName, highlight the polygon inside Map.js
            })
        }

        this.props.closePopup()
    }

    render() {
        const { isShow, closePopup, currentSite, UserInfo } = this.props
        //console.log('pick level widget');
        let LevelList = []
        if (UserInfo == null) return <div>data is missing</div>

        UserInfo.locations[currentSite].nestedLocs.map((data, i) => {
            LevelList.push(data.locName)
        })
        try {
            LevelList = LevelList.sort(function (a, b) {
                return a - b
            })
            console.log(LevelList);
        }
        catch (err) {
            console.log(err)
        }

        let BuildingDisplay = "showlevelpopup"
        if (!isShow) BuildingDisplay = "hidelevelpopup"

        return [
            <div className={BuildingDisplay + " PickLevelWidget_div shadow-lg"}>
                <i className="fa fa-times PickLevelWidget_closeBtn" onClick={closePopup}></i>
                <p className="PickLevelWidget_txt">
                    Choose Level
            </p>
                <div className="PickLevelWidget_LevelContainer">
                    {
                        LevelList.map((data, i) => {
                            //console.log('map item inside')
                            //console.log(data)
                            let HighlightClass = ""
                            if (this.props.currentlevel == data) {
                                HighlightClass = "CurrentFloor"
                            }
                            //check string length, show another size button if too long
                            const strLength = data.length
                            let largeFlrBtn = ""
                            if (strLength >= 3) {
                                largeFlrBtn = "largeFlrBtn "        //auto width
                            }
                            return <button className={"PickLevelWidget_LevelBtn " + largeFlrBtn + HighlightClass} key={"widgetlevelbtn_" + i} id={"widgetlevelbtn_" + data}
                                onClick={this.PickLevel}>
                                {data}
                            </button>
                        })
                    }
                </div>
            </div>,
            <div className={" PickLevelWidget_BgDiv " + BuildingDisplay} onClick={closePopup}></div>
        ]
    }
}

//Redux inside component function
function mapStateToProps(state) {
    return {
        currentlevel: state.currentlevel,
        currentSite: state.currentSite,
        MenuDisplay: state.MenuDisplay,
        SiteList: state.SiteList,
        UserInfo: state.UserInfo
    }
}

export default connect(mapStateToProps)(PickLevelWidget)