import React from 'react'
import './style.css'
import { connect } from 'react-redux'
//inner widget
import SearchBar from './searchbar/SearchBar'
import FloorPlanTableTable from './table/table'

class FloorPlanTable extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            menuList: [],       //generate the list here, then send to searcg=hbar widget

            //generate from inner searchbar widget, then update this local state, finally send to inner table widget
            pickedType: null,
            pickedSite: null,
            pickedFloor: null,
            pickedLoc: null
        }

        this.searchbarTrigger = this.searchbarTrigger.bind(this)
    }
    componentDidMount() {
        const {DeviceData} = this.props
        if(DeviceData == null || DeviceData.length == null || DeviceData.length == 0) return
        //1. gen menuList
        let newMenuList = []
        DeviceData.forEach(siteItem => {
            if(siteItem.nestedLocs == null) return
            siteItem.nestedLocs.forEach(floorItem => {
                if(floorItem.nestedLocs == null) return
                floorItem.nestedLocs.forEach(locItem => {
                    if(locItem.payload_definition_id == null || locItem.payload_definition_id.name == null) return
                    const deviceName = locItem.payload_definition_id.name
                    let checkExist = newMenuList.find(function(menuItem) {
                        return menuItem == deviceName
                    })
                    if(checkExist == null) newMenuList.push(deviceName)//add to list
                })
            })
        })
        this.setState({
            menuList: newMenuList
        })
    }
    searchbarTrigger(pickedType, pickedSite, pickedFloor, pickedLoc) {
        this.setState({
            pickedType: pickedType, 
            pickedSite: pickedSite, 
            pickedFloor: pickedFloor, 
            pickedLoc: pickedLoc
        })
    }

    render() {
        const {menuList, pickedType, pickedSite, pickedFloor, pickedLoc} = this.state
        return <div className="floor_base floorplantable">
            <SearchBar menuList={menuList} searchbarTrigger={this.searchbarTrigger}/>
            <FloorPlanTableTable pickedType={pickedType} pickedSite={pickedSite} pickedFloor={pickedFloor} pickedLoc={pickedLoc} menuList={menuList} />
        </div>
    }
}

function mapStateToProps(state) {
    return {
        DeviceData: state.DeviceData,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate,
    }
}
export default connect(mapStateToProps)(FloorPlanTable)