import React, { useEffect, useState } from 'react'
import './ntu.scss'
import { connect } from 'react-redux'
// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faSpinner } from '@fortawesome/free-solid-svg-icons'
// RESTFUL Function in another js file
import { SetNTU, GetNTU, GetUrl } from './setNTU'

//CONFIG HERE
const DeviceSerial = 'KSSP_Setting_S'
const max = 4.0
const min = 0.4
const updateInterval = 60   //unit = second

// Widget
const NTUSummary = props => {
    const [res, setRes] = useState()
    const [inputV, setInputV] = useState(0) // input field
    const [isLoading, setIsLoading] = useState(false)
    const [getingData, setGetData] = useState(true) // check if first get data is working, else let user setup NTU control
    const [time, setTime] = useState(new Date().toString())

    useEffect(async () => {    //onload function
        setTimeout(() => {
            setGetData(false)
        }, 5000)

        reqNTUValue(props, setRes)
    }, [])

    useEffect(() => {							//loop timer trigger function + display of date time
        const interval = setInterval(() => {
            console.log(new Date().toString())
            setTime(new Date().toString())
        }, updateInterval * 1000)
        return () => {
            clearInterval(interval)
        }
    }, [time])
    useEffect(() => {   //periodic get ntu value
        reqNTUValue(props, setRes)
    }, [time])

    return <div className='NTUSummary'>
        <div className='head'>
            NTU
            {/* <div className='guide' onClick={()=>openTab()}><FontAwesomeIcon icon={faInfoCircle} /></div> */}
        </div>
        <div className='inner'>
            {
                (res==null||res.readings==null) && getingData==false?
                <div className='setupBtn' onClick={()=>openTab()}>
                    Connection Failed to NTU Control
                    <div className='sub'>Allow Access</div>
                </div>
                :
                [
                    <div className='left'>
                        <div className='name'>NTU</div>
                        <div className='reading'>{getNTUValue(res)}</div>
                    </div>,
                    <div className='right'>
                        <div className='name'>NTU Setpoint Input</div>
                        <input type='number'
                            min={min.toString()}
                            max={max.toString()}
                            onChange={e=>setInputV(e.target.value)}
                            step='0.1'
                        ></input>
                        <div className='button' onClick={()=>updateNTU(inputV, setIsLoading, setRes, isLoading)}>
                            {isLoading?<FontAwesomeIcon icon={faSpinner} className='fa-spin' />:'Change'}
                        </div>
                    </div>
                ]
            }
        </div>
    </div>
}
const mapStateToProps = state => {
    return {
        configStorage: state.configStorage
    }
}
export default connect(mapStateToProps)(NTUSummary)

// Functions
const reqNTUValue = async (props, setRes) => {
    let res = await GetNTU({
        DeviceSerial: DeviceSerial,
        configStorage: props.configStorage
    })
    setRes(res)
}
const getNTUValue = res => {
    if(res==null||res.readings==null) return '-'
    if(res.readings.NTU_Setpt==null) return '--'
    return res.readings.NTU_Setpt.toString()
}
const updateNTU = async (inputV, setIsLoading, setRes, isLoading) => {
    if(isLoading) return
    console.log('updateNTU')
    setIsLoading(true)
    setTimeout(() => {
        setIsLoading(false)
    }, 5000)

    let valueNumber = Number(inputV)
    if(valueNumber>max||valueNumber<min) {
        alert('Invalid Input\nrange:' + min + ' - ' + max)
        setIsLoading(false)
        return
    }

    let res = await SetNTU({
        DeviceSerial: DeviceSerial,
        NTU_Setpt: inputV.toString()
    })
    console.log(res)
    if(res == 'Success') {
        setTimeout(async () => {
            let res = await GetNTU({DeviceSerial: DeviceSerial})
            setRes(res)
            setIsLoading(false)
        }, 1000)
    }
    else {
        setIsLoading(false)
    }
}

const openTab = () => {
    if (window.confirm('Please allow the access in new tab')) {
        window.open(GetUrl() + '/Auth', '_blank').focus();
      } else {
        // close
      }
}