import React, { useEffect, useState } from 'react'
import './Emergency.css'
import { connect } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCheckCircle, faExclamationTriangle, faTimes, faVolumeDown } from '@fortawesome/free-solid-svg-icons'

function IssueReportWidget(props) {
    const {ToiletType, socket, UserInfo, DeviceData, currentSite, currentlevel} = props

    const [IsOpen, setIsOpen] = useState(false)
    const [EmergencyOn, setEmergencyOn] = useState(false)
    const [SystemLog, setSystemLog] = useState('')
    const [Option, setOption] = useState()

    useEffect(() => {
        if(socket == null) return

        socket.on("Toilet_Emergency", data=> {
            console.log(data)
            if(data.method == "Create" && data.result == 'Success') setSystemLog("Submit success")
            else setSystemLog("Submit Failed" + data.error)
        })
        return function ClearSocketListener() {
            socket.off("Toilet_Emergency")
        }
    }, [])
    function EmergencySubmit() {
        setEmergencyOn(true)
        setIsOpen(false)
        setSystemLog('')
        const {SiteID, SiteName} = GetSiteID(DeviceData, currentSite)
        const {LevelID, LevelName} = GetLevelID(DeviceData, currentSite, currentlevel)
        const EmitJson = {
            "msgType": "Toilet_Emergency",
            "method": "Create",
            "client_id": UserInfo.client_id,
            "UserID": UserInfo.userID,
            "locationInfo": [
              {
                "id": SiteID,
                "lvl": 1,
                "name": SiteName
              },
              {
                "id": LevelID,
                "lvl": 2,
                "name": LevelName
              }
            ],
            "toiletType": ToiletType.toString(), //1 = male, 2 = female, 3 = other
            "type": Option,
        }
        console.log(EmitJson)

        props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "StatRequest",
            EmitSocketData: EmitJson
        })
    }
    function StopAlarm() {
        setEmergencyOn(false)

        setSystemLog([])
    }

    if(EmergencyOn) {
        return [
            <div className="Toilet_Em_bg"></div>,
            <div className="Toilet_Em_popup toilet_submitted">
                <div className="Toilet_Em_popupdesc">
                    Issue report:
                    <br></br>
                    {Option}
                    <br></br>
                    {SystemLog==''?'Waiting server response ...':SystemLog}
                </div>
                <div className="Toilet_Em_popupIcon toilet_submitted">
                    <FontAwesomeIcon icon={faCheckCircle} className="fa" />
                </div>
                <div className="Toilet_Em_Off" onClick={StopAlarm}>Close</div>
            </div>,
        ]
    }
    return !IsOpen?
    <div className="Toilet_Em" key="Toilet_Em" onClick={()=>setIsOpen(true)}>
        <FontAwesomeIcon icon={faExclamationTriangle} className="fa" />
        <div className='Toilet_Em_title'>Service Request</div>
    </div>
    :
    [
        <div className="Toilet_Em_bg" onClick={()=>setIsOpen(false)}></div>,
        <div className="Toilet_Em_popup" key="Toilet_Em_popup">
            <div className="Toilet_Em_popupclose" onClick={()=>setIsOpen(false)}>
                <FontAwesomeIcon icon={faTimes} className="fa" />
            </div>
            <div className="Toilet_Em_popupdesc">
                What is the issue?
            </div>
            <div className="Toilet_Em_popupoptionList">
                <div className={Option=="No Paper"?"option selected":"option"} onClick={()=>setOption('No Paper')}>
                    No Toilet Paper
                </div>
                <div className={Option=="No Soap"?"option selected":"option"} onClick={()=>setOption('No Soap')}>
                    No Soap
                </div>
                <div className={Option=="No Flushing Water"?"option selected":"option"} onClick={()=>setOption('No Flushing Water')}>
                    No Flushing Water
                </div>
                <div className={Option=="Too Dirty"?"option selected":"option"} onClick={()=>setOption('Too Dirty')}>
                    Too Dirty
                </div>
                <div className={Option=="Floor Too Wet"?"option selected":"option"} onClick={()=>setOption('Floor Too Wet')}>
                    Floor Too Wet
                </div>
                <div className={Option=="Strange Smell"?"option selected":"option"} onClick={()=>setOption('Strange Smell')}>
                    Strange Smell
                </div>
                <div className={Option=="Other"?"option selected":"option"} onClick={()=>setOption('Other')}>
                    Other
                </div>
            </div>
            <div className="Toilet_Em_popupbtns">
                <div className="Toilet_Em_popupbtn1" onClick={EmergencySubmit}>Confirm</div>
                <div className="Toilet_Em_popupbtn2" onClick={()=>setIsOpen(false)}>Cancel</div>
            </div>
        </div>
    ]
}

function mapStateToProps(state) {
    return {
      socket: state.socket,
      ToiletType: state.ToiletType,
      UserInfo: state.UserInfo,
      DeviceData: state.DeviceData,
      currentSite: state.currentSite,
      currentlevel: state.currentlevel,
    }
}
export default connect(mapStateToProps)(IssueReportWidget)

function GetSiteID(DeviceData, currentSite) {
    try {
        const SiteItem = DeviceData[currentSite]
        const SiteID = SiteItem._id
        const SiteName = SiteItem.locName
        return {
            SiteID: SiteID,
            SiteName: SiteName
        }
    }
    catch(err) {
        return err
    }
}
function GetLevelID(DeviceData, currentSite, currentlevel) {
    try {
        const LevelList = DeviceData[currentSite].nestedLocs
        let LevelID = "No Level ID found"
        let LevelName = "No Level Name found"
        LevelList.forEach(LevelItem => {
            if(LevelItem.locName == currentlevel || LevelItem.locDesc == currentlevel) {
                LevelID = LevelItem._id
                LevelName = LevelItem.locName
            }
        })
        return {
            LevelID: LevelID,
            LevelName: LevelName,
        }
    }
    catch(err) {
        return err
    }
}