import React from 'react'
import './Login.css'
import { connect } from 'react-redux'

import md5 from 'md5'
import Checkbox from './checkbox'
import Loading from './loading'
import Alertbox from './alertbox'

import SoundPlay from '../../Sound/Sound'

class Login extends React.Component {
  LoginAuth = () => {
    this.soundRef.current.alarmMp3Run("click")
    if (!this.props.socket.connected) {
      this.AuthFailMsg('Failed connect to service!')
      return
    }

    //login fail too many time check
    const LoginFailCount_str = localStorage.getItem('LoginFailCount')
    const LoginFailCount = parseInt((LoginFailCount_str == "" || LoginFailCount_str == "NaN") ? '0' : LoginFailCount_str)

    const LastLoginRequestTime = localStorage.getItem('LastLoginRequestTime')
    const LastDatetime = Date.parse((LastLoginRequestTime == "" || LastLoginRequestTime == "NaN") ? Date().toString() : LastLoginRequestTime)
    const currentDatetime = Date.parse(Date().toString())

    const Diff = currentDatetime - LastDatetime
    const MinutesDiff = Diff / 1000 / 60
    const waitMinute = (5 - MinutesDiff)

    if (LoginFailCount >= 5 && MinutesDiff <= 5) {         //login failed more than 5 times, and last login is less than 5 minute
      this.AuthFailMsg('Login failed more than 5 times, please re-try after ' + (waitMinute + 1).toFixed(0) + ' minutes')
      localStorage.setItem("useracc", '')
      localStorage.setItem("userhashpassword", '')
      return
    }
    else if (LoginFailCount >= 5 && MinutesDiff > 5) {     //login failed more than 5 times, and last login is 5 minutes or before
      localStorage.setItem('LoginFailCount', "0")
      localStorage.setItem('LastLoginRequestTime', Date().toString())
    }
    else {                                                //Login failed less than 5 times
      const newLoginFailCount = LoginFailCount + 1
      localStorage.setItem('LoginFailCount', newLoginFailCount.toString())
      localStorage.setItem('LastLoginRequestTime', Date().toString())
    }

    //Auth function
    let { socket } = this.props
    let thisoutside = this
    if (socket != null) {
      //console.log(socket.connected);
      if (socket.connected)  //check the connection status
      {
        //get the account name and password
        let username_input = ""
        let password_input2 = ""
        try {
          username_input = thisoutside.usernameinput.current.value
          password_input2 = thisoutside.passwordinput.current.value
        }
        catch (err) {
          console.log(err)
        }
        //create md5 password
        if (password_input2 == null) {
          password_input2 = ''
        }
        let password_hash = md5(password_input2)
        //save the hashed password and the account name in localstorage
        if (this.state.rememberMe) {
          localStorage.setItem("useracc", username_input)
          localStorage.setItem("userhashpassword", password_hash)
          localStorage.setItem('userpassword', password_input2)
          localStorage.setItem("rememberme", true)
        }
        else {
          localStorage.setItem("useracc", '')
          localStorage.setItem("userhashpassword", '')
          localStorage.setItem("rememberme", false)
        }

        let SessionID = localStorage.getItem('SessionID')
        if (SessionID == null) {
          SessionID = ''
        }
        //Send to server with socketIO
        let data = {
          msgType: "Auth",
          username: username_input,
          password: password_hash,
          sessionID: SessionID,
          expires: "2021-12-01",
          data: "session cookie"
        }
        //this.props.socket.emit("Login", data); 
        this.props.dispatch({ type: 'EmitSocket', EmitSocketName: "Login", EmitSocketData: data })
        //console.log(data);

        this.showloading()
      }
      else {
        //the connection status is false, need reconnect
        console.warn('Re-Connect Socket')
        //alert('Disconnected, Please re-try');
        this.props.dispatch({ type: 'SocketCon' })
      }

      this.showloading()
    }
  }

  constructor(props) {
    super(props)
    this.backgroundImageOnload = this.backgroundImageOnload.bind(this)
    this.LoginAuth = this.LoginAuth.bind(this)
    this.socketlistener = this.socketlistener.bind(this)

    this.rememberMe = this.rememberMe.bind(this)
    this.showloading = this.showloading.bind(this)
    this.AuthFailMsg = this.AuthFailMsg.bind(this)
    this.closealertbox = this.closealertbox.bind(this)

    this.forgetpasswordRedirect = this.forgetpasswordRedirect.bind(this)

    this.PreloadImagesInRedux = this.PreloadImagesInRedux.bind(this)

    this.usernameinput = React.createRef()
    this.passwordinput = React.createRef()
    this.soundRef = React.createRef()

    this.state = {
      backgroundOnloadCompleted: false,
      rememberMe: true,
      LoginLoading: false,
      alertShow: false,
      message: '',
      isFocusUserInput: false,
      isFocusPwdInput: false,

      isAutoLoginLoading: true,        //terminate this at autoLogin function
    }

    //focus input effect functions
    this.UsernameFocus = this.UsernameFocus.bind(this)
    this.UsernameBlur = this.UsernameBlur.bind(this)
    this.PasswordFocus = this.PasswordFocus.bind(this)
    this.PasswordBlur = this.PasswordBlur.bind(this)

    //auto login function
    this.AutoLogin = this.AutoLogin.bind(this)
  }
  AutoLogin() {
    //delay, since first open does not connect the socket yet
    const thisoutside = this
    setTimeout(() => {
      //check the localstorage and get all the data required
      let userAcc = localStorage.getItem('useracc');
      let HashPwd = localStorage.getItem('userhashpassword');
      let remMe = localStorage.getItem('rememberme');
      let SessionID = localStorage.getItem('SessionID');
      if (userAcc == null || userAcc == "" || HashPwd == null || HashPwd == "" ||
        SessionID == null || SessionID == "") {
        console.log('first access to this site, or logout');
        thisoutside.setState({
          isAutoLoginLoading: false
        })
        return;
      }
      //if can auto login
      if (remMe == true || remMe == "true") {
        let { socket } = thisoutside.props;
        if (socket.connected == false) {
          //alert('socket is not connected');
          thisoutside.AutoLogin();
          return;
        }
        //trigger auto login
        let data = {
          msgType: "Auth",
          username: userAcc,
          password: HashPwd,
          sessionID: SessionID,
          expires: "2019-08-01",
          data: "session cookie"
        };
        thisoutside.props.dispatch({ type: 'EmitSocket', EmitSocketName: "Login", EmitSocketData: data });
        //show auto login is progressing
        thisoutside.setState({
          isAutoLoginLoading: true
        })
      }
    }, 0)

    setTimeout(() => {
      thisoutside.setState({
        isAutoLoginLoading: false
      })
    }, 1000 * 10)
  }
  UsernameFocus() {
    this.setState({
      isFocusUserInput: true
    })
  }
  UsernameBlur() {
    this.setState({
      isFocusUserInput: false
    })
  }
  PasswordFocus() {
    this.setState({
      isFocusPwdInput: true
    })
  }
  PasswordBlur() {
    this.setState({
      isFocusPwdInput: false
    })
  }
  componentDidMount() {
    //check if can auto login
    this.AutoLogin();
    const thisoutside = this
    document.onkeydown = function (event) {
      switch (event.keyCode) {
        case 13:
          const href = window.location.href
          if (href.includes("Main")) return   //if not login page, would not trigger this function

          //enter button is pressed
          thisoutside.LoginAuth();
          break;
        default:
        //console.log(event.keyCode);
      }
    }

    //reconnect the redux
    this.props.dispatch({ type: 'SocketCon' })

    //check current screen size
    const WindowWidth = window.innerWidth
    //if (WindowWidth < 450) this.props.history.push('/Main/InstallMobileApp')//if screen width < 400, should be mobile, redirect to download app page  //..
  }

  componentWillWillMount() {
    //set the css parameter based on the localstorage
    console.log('set css color theme');
    let ColorHighlight = localStorage.getItem('--focuscardbackground');
    let ColorNavigationBar = localStorage.getItem('--colortheme1');
    let ColorBackground = localStorage.getItem('--colortheme2');
    let ColorHeader = localStorage.getItem('--divheader');

    //set to the css variable
    if (ColorHighlight !== null && ColorHighlight !== '') {
      document.documentElement.style.setProperty('--focuscardbackground', ColorHighlight);
      document.documentElement.style.setProperty('--colortheme1', ColorNavigationBar);
      document.documentElement.style.setProperty('--colortheme2', ColorBackground);
      document.documentElement.style.setProperty('--divheader', ColorHeader);
    }

    //redirect to home page if the user is 'remember me'
    let rememberMe = localStorage.getItem('rememberme');
    //alert('check remember me: ' + rememberMe);
    if (rememberMe === "true") {
      //alert('push to main.js');
      //thieoutside.props.history.push('/Main');
      //then, inside the Main page, check the Auth
    }
    else {
      //else, stay at login page
    }
  }

  showloading() {
    //set loading state to true
    this.setState({
      LoginLoading: true
    });
    //timeout, set state loading to false
    let thisoutside = this;
    setTimeout(function () {
      if (thisoutside.state.LoginLoading) {
        thisoutside.setState({
          LoginLoading: false,
          isAutoLoginLoading: false,    //in case: timeout still cannot login, hide it
        });
        thisoutside.AuthFailMsg('No auth response from server!');
      }
    }, 10 * 1000)
  }

  rememberMe(rememberStatus) {
    this.soundRef.current.alarmMp3Run("page")
    //console.log(rememberStatus);
    if (rememberStatus === this.state.rememberMe) {
      //the status is same as current status, no need setstate()
      //console.log('same stataus');
      return;
    }

    this.setState({
      rememberMe: rememberStatus
    });
  }

  backgroundImageOnload() {
    //console.log('background Image Onload Completed');
    if (this.state.backgroundOnloadCompleted) {
      //if state is updated, no need to setstate and reload the page
      return;
    }
    this.setState({
      backgroundOnloadCompleted: true
    });
  }

  PreloadImagesInRedux() {
    const { UserInfo } = this.props

    if (UserInfo != null) {
      console.error('PreloadImagesInRedux()');
      //cal the total floors
      let FloorCount = 0; //calculate the total floors in UserInfo
      let SiteCount = 0;
      let ImgStorage = [];
      UserInfo.locations.forEach(SiteItem => {
        //console.log('../../assets/images/floors/' + SiteItem.imgUrl + '/default.jpg')
        console.log('./assets/images/floors/' + SiteItem.imgUrl + '/default.jpg');
        ImgStorage.push({
          Site: SiteItem.imgUrl,
          FloorImg: [],
          SiteImage: './assets/images/floors/' + SiteItem.imgUrl + '/default.jpg',
          //require(UrlPath + '/assets/images/floors/' + SiteItem.imgUrl + '/default.jpg')
        });
        SiteItem.nestedLocs.forEach(floorItem => {
          //console.log(floorItem);
          ImgStorage[SiteCount].FloorImg.push({
            ImgCount: FloorCount,
            SiteCount: SiteCount,
            SiteName: SiteItem.imgUrl,
            Img: './assets/images/floors/' + SiteItem.imgUrl + '/' + floorItem.locName + '.png',
            //require('./assets/images/floors/' + SiteItem.imgUrl + '/' + floorItem.locName + '.png'),
            FloorName: floorItem.locName
          });
          FloorCount++;
        });
        SiteCount++;
      });
      this.setState({
        displayString: "Pre-load the Floor Images",
        progress: 60,
        subDisplay: "0/" + FloorCount
      });
      console.log(ImgStorage);

      //when completed, set the redux floor
      this.props.dispatch({
        type: 'SaveImgStorage',
        data: ImgStorage
      });
    }

    //redirect to main page
    const storedUrl = localStorage.getItem('refreshUrl')
    localStorage.setItem('refreshUrl', "")
    let splitUrl
    if (storedUrl != null && storedUrl != "") splitUrl = storedUrl.split('#')
    const storedUrlstr = (storedUrl == null || storedUrl == "" || splitUrl == null || splitUrl.length == null || splitUrl.length <= 1) ? "/Main" : splitUrl[1]
    this.props.dispatch({ type: 'PIckSite', data: { Siteid: 0 } }) //set the default site and level, so would prevent errors in dashboard page
    this.props.history.push(storedUrlstr)
  }

  socketlistener = () => {
    this.props.socket.removeAllListeners(); //remove previous socket functions
    let thisoutside = this;
    //this function is run when the socket-io is connected
            this.props.socket.on("BeaconManagement", data => { 
              console.log(data)
              thisoutside.props.dispatch({ type: "BeaconManagement", data: data })
          })
    //socket receive functions
    this.props.socket.on("LoginSuccess", data => {
      console.warn("LoginSuccess");
      localStorage.setItem('LoginFailCount', "0")     //clear login fail count storage
      //console.log(data);
      thisoutside.props.dispatch({ type: 'SaveUserInfo', data: data });
      
      if (data !== null) { 
        //cpsl   
        const EmitJson = {
          "msgType": "BeaconManagement",
          "method": "Get",
          "userID": data.userID,
          "client_id": data.client_id,
          "deviceType_id": "605aad52ec93eb0dd4ec5b49"
        }
        console.log(EmitJson)
        this.props.dispatch({
          type: 'EmitSocket',
          EmitSocketName: "StatRequest",      //StatRequest can request for any type data
          EmitSocketData: EmitJson
        })


        const EmitJson_Zone = {
          "msgType": "GetZoneList",
          "client_id": data.client_id,
          "userID": data.userID,
        }
        console.log(EmitJson)
        this.props.dispatch({
          type: 'EmitSocket',
          EmitSocketName: "ZoneManagement",      //GetZoneList can request for any type data
          EmitSocketData: EmitJson_Zone
        })
        //end CPSL
      }
      //2020: auth stored latest device data, so save it to DeviceData..
      thisoutside.props.dispatch({ type: 'LatestDeviceData', data: data.locations })
      setTimeout(function () { thisoutside.PreloadImagesInRedux()}, 1000)
      //save to token
      //localStorage.setItem("AuthToken", data);
      
      if(window.innerWidth < 500) window.screen.orientation.lock('landscape')
    });

    //onload_sessionID
    this.props.socket.on("onload_sessionID", data => {
      // console.log('onload_sessionID:' + data);
      //save the sessionID in localstorage
      localStorage.setItem("SessionID", data);
    });

    //Auto Login functions
    this.props.socket.on("LoginFailed", data => {
      console.log(data)
      thisoutside.AuthFailMsg(data);
      thisoutside.setState({
        isAutoLoginLoading: false   //in case: auto login failed, need to login manually
      });
    });
    this.props.socket.on("LatestDeviceData", data => {
      console.warn("LatestDeviceData()");
      //console.log(data);
      thisoutside.props.dispatch({
        type: 'LatestDeviceData',
        data: data
      });
    });
  }

  AuthFailMsg(data) {
    console.log('Authfail():' + data)
    const DisplayMsg = (data == null) ? "Login Failed" : data
    //show message box
    this.setState({
      alertShow: true,
      message: DisplayMsg,
      LoginLoading: false
    })
  }

  closealertbox() {
    this.soundRef.current.alarmMp3Run("hover")
    this.setState({
      alertShow: false,
      message: ''
    })
  }

  forgetpasswordRedirect() {
    this.props.history.push('/forgetpassword');
  }

  render() {
    //console.log(this.props);
    if (this.props.socket != null) {
      //start the socket functions after it is connected
      this.socketlistener()
    }

    let backgroundOnloadCompleted = this.state.backgroundOnloadCompleted;
    let preloadclass = "";//"preloading";
    if (backgroundOnloadCompleted) {
      preloadclass = "Onloaded";
    }

    let isLoginLoading = this.state.LoginLoading;
    //console.log(isLoginLoading);

    let focusUserInputClass = "";
    let focusPwdInputClass = "";
    let { isFocusUserInput, isFocusPwdInput } = this.state;
    if (isFocusUserInput) {
      focusUserInputClass = "focusing";
    }
    if (isFocusPwdInput) {
      focusPwdInputClass = "focusing"
    }

    //auto login css
    let isAutoLogin = "hideIsAutoLogin";
    let { isAutoLoginLoading } = this.state;
    let { socket } = this.props;
    if (isAutoLoginLoading || socket == null) {
      isAutoLogin = "";
    }

    return [
      <div className={"loginbody " + preloadclass + " "} onLoad={this.backgroundImageOnload}>
        <div className='Left'>
          <img src={require("../../assets/images/atal.png")} alt="ATAL" className="login_image"></img>
          <div className="loginSlogan">
            IoT Hub Platform
            <div className="loginSloganbody">
              Committed to People, Committed to the Future.
            </div>
          </div>
        </div>
        <div className='Right'>
          <div className="loginform">
            <div className={"logininput " + focusUserInputClass}>
              <img src={require("../../assets/images/user_icon.png")} className="Loginuserimg" alt="acc user img"></img>
              <input className="logininputtextbox"
                type="text" id="username" name="username" placeholder="Username" autoComplete="off" autoFocus ref={this.usernameinput}
                value={this.state.inputValue} onFocus={this.UsernameFocus} onBlur={this.UsernameBlur}></input>
            </div>
            <div className={"logininput " + focusPwdInputClass}>
              <img src={require("../../assets/images/pwd_icon.png")} className="Loginuserimg" alt="acc pwd img"></img>
              <input className="logininputtextbox"
                type="password" id="password" name="password" placeholder="Password" ref={this.passwordinput}
                value={this.state.inputValue2} onFocus={this.PasswordFocus} onBlur={this.PasswordBlur}></input>
            </div>
            <div className="formInnerRow">
              <div className="flex flex-row justify-between remember_me_div">
                <Checkbox rememberme={this.rememberMe} />
                    Remember Me
                  </div>
              <div className="forgetpassword" onClick={this.forgetpasswordRedirect}>
                Forget Password
              </div>
            </div>
            <button className="loginbutton" onClick={this.LoginAuth}>
              Login
            </button>
            <Loading isHide={isLoginLoading} textShow="Loading..." />
          </div>
        </div>
        {
          <Alertbox isShow={this.state.alertShow} msg={this.state.message} closealertbox={this.closealertbox} />
        }
      </div>,
      <div className={"AutoLoginBackground " + isAutoLogin}></div>,
      <div className={"AutoLoginContainer " + isAutoLogin}>
        <div className="AutoLoginIcon">
          <div></div>
        </div>
      </div>,
      <SoundPlay ref={this.soundRef} />,
    ]
  }
}

function mapStateToProps(state) {
  return {
    socket: state.socket,
    level: state.level,
    currentlevel: state.currentlevel,
    currentSite: state.currentSite,
    MenuDisplay: state.MenuDisplay,
    UserInfo: state.UserInfo
  }
}
export default connect(mapStateToProps)(Login)