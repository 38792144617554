import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux'
import './Dashboard.css'
import Tablehead from './TableHead'
import Chart from './Chart'
import Table from './Table'
import Downloadcsv from '../bottom/downloadcsv';
import PageBtns from '../bottom/pagebtns'
import moment from 'moment';

const axios = require('axios');

function Dashboard(props) {
    const history = useHistory();
    const [status, setStatus] = useState({
        title: { text: '' },
        border: { enabled: false },
        series: []
    })
    const [forecast, setForecast] = useState([])
    const [workOrderList, setWorkOrderList] = useState([]);

    const requestData_forecast = {
        "options": {
            "dummy": true,
            "start": new Date(),
            "end": new Date(moment().add(8, 'days')),
        }
    }
    const requestData_workorder_list = {
        "options": {
            "startTime": "2022-01-13T00:00:00.000Z",
            "status": ["Waiting Approval", "Active", "Pending Resume"]
        }
    }

    //
    //emit
    useEffect(() => {
        axios.post('https://47.52.20.40:31001/AtalAPI/v1/CPSL_ColdBox_Status', {})
            .then(function (response) {
                console.log("CPSL_ColdBox_Status recv!", response)
                let data = response.data.info
                setStatus({
                    title: {
                        text: ''
                    },
                    border: {
                        enabled: false
                    },
                    series: [
                        {
                            name: 'Empty',
                            y: data.countEmpty
                        },
                        {
                            name: 'Loaded',
                            y: data.countLoaded
                        },
                        {
                            name: 'Maint',
                            y: data.countMaint
                        },
                        {
                            name: 'Precool',
                            y: data.countPrecool
                        }
                    ]
                })
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.post('https://47.52.20.40:31001/AtalAPI/v1/CPSL_ColdBox_Forecast', requestData_forecast)
            .then(function (response) {
                console.log("CPSL_ColdBox_Forecast recv!", response)
                let data = response.data.info.map(item => {
                    return {
                        title: {
                            text: item.date
                        },
                        border: {
                            enabled: true,
                            color: item.color
                        },
                        series: [
                            {
                                name: 'Adhoc',
                                y: item.countAdhoc
                            },
                            {
                                name: 'Planned',
                                y: item.countPlanned
                            },
                            {
                                name: 'Available',
                                y: item.countAvailable
                            },
                            {
                                name: 'Loaded',
                                y: item.countLoaded
                            }
                        ]
                    }
                })
                setForecast(data)
            })
            .catch(function (error) {
                console.log(error);
            });
        refreshList()
    }, [])

    function refreshList() {
        axios.post('https://47.52.20.40:31001/AtalAPI/v1/CPSL_WorkOrder_List', requestData_workorder_list)
            .then(function (response) {
                console.log("CPSL_WorkOrder_List recv!", response)
                let pageSliceList = response.data.info.map(item => {
                    return {
                        "name": item.deviceName,
                        "workOrderType": item.type,
                        "parts": item.parts,
                        "orderNo": item.number,
                        "requestor": item.requestedStaffName,
                        "downTime": item.downTime,
                        "workOrderStatus": item.status,
                        "order_id": item.order_id
                    }
                })
                setWorkOrderList(pageSliceList);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function updateWorkOrder(workOrder, action) {
        let requestData = {
            "method": "Update",
            "info": {
                "status": action,
                "order_id": workOrder.order_id
            },
        }
        console.log("call save", requestData)
        //emit
        axios.post("https://47.52.20.40:31001/AtalAPI/v1/CPSL_WorkOrder", requestData)
            .then(function (response) {
                refreshList()
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const CsvHeaders = [
        {
            key: 'name',
            label: 'Coldbox ID',
        },
        {
            key: 'workOrderType',
            label: 'Type',
        },
        {
            key: 'parts',
            label: 'Parts',
        },
        {
            key: 'orderNo',
            label: 'Work Order',
        },
        {
            key: 'requestor',
            label: 'Requestor',
        },
        {
            key: 'downTime',
            label: 'Down Time',
        },
        {
            key: 'workOrderStatus',
            label: 'Action',
        }
    ]

    return (
        <div className='main_board'>
            <div className="caption">
                <div className="left_top">
                    Coldbox Status
                </div>
                <div className="right_top">
                    7 day Maintenance Forecast
                </div>
            </div>
            <div className="dashboard">
                <div className="left_panel">
                    <Chart options={status} width="290" height="290" />
                </div>
                <div className="right_panel">
                    {forecast.map(item => {
                        return <Chart options={item} width="290" height="290" />
                    })}
                </div>
            </div>
            <div className='tableTitle'>Completed Work Order - Pending to Resume</div>
            <div className="dashTablehead">
                <Tablehead />
            </div>
            {
                workOrderList.map(row => {
                    return <Table row={row} updateWorkOrder={updateWorkOrder} onClick={() => history.push({ pathname: "/Main/MmsWorkOrderDetail", state: { "mode": "Update", "data": (row) } })} />
                })
            }
            <div className="ColdboxIndi_bottom">
                <Downloadcsv sortedList={workOrderList} headers={CsvHeaders} filename='MmsDashBoard.csv' />
                <PageBtns sortedList={workOrderList} UpdateList={() => { }} />
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        UserInfo: state.UserInfo,
        socket: state.socket
    }
}

export default connect(mapStateToProps)(Dashboard)