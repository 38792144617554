/**

Input:
ClickFunction:  the function that would be triggered when the button is clicked
Icon:           String: the icon to display

*/

import React from 'react'
import './searchbtn.css'

function ButtonWidget(props) {
    return <div className="LiftSummary_searchBtn" onClick={props.ClickFunction}>
        <div className={"fa fa-" + props.Icon}></div>
    </div>
}

export default ButtonWidget