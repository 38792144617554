import React, { useState, useEffect, useRef } from 'react'
import './popup.css'
import { connect } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import DatePicker from 'react-datepicker'
import convertDate from '../../../Summary/LiftWidgets/common/convertDate'

function SchedulePopupWidget(props) {
    const {ToiletType, socket, UserInfo, DeviceData, currentSite, currentlevel} = props              //redux data
    const {PopupData, setPopupData, RequestData, UserList} = props         //parent input data, setPopupData and RequestData are function

    const [PopupMsg, SetPopupMsg] = useState()      //null means no message, string would be the display msg
    const [ConfirmDel, SetConfirmDel] = useState(false) //true means open confirm del window

    const [StartDate, setStartDate] = useState(new Date())
    const [EndDate, setEndDate] = useState(new Date())
    const [SelectUser, setSelectUser]  = useState('')       //userID
    const [Username, setUsername] = useState('')            //username
    const [AddDate, setAddDate] = useState(new Date())
    const [UserListArr, setUserListArr] = useState([])

    const Input_name = useRef(null)

    function AddRequest() {
        // get all data required
        const {client_id, userID} = UserInfo
        const {SiteID, SiteName} = GetSiteID(DeviceData, currentSite)
        const {LevelID, LevelName} = GetLevelID(DeviceData, currentSite, currentlevel)

        const EmitJson = {
            "msgType": "Toilet_schedule",
            "method": "Create",
            "client_id": client_id,
            "operatorID": Input_name.current.value,
            "locationInfo": [
              {
                "id": SiteID,
                "lvl": 1,
                "name": SiteName
              },
              {
                "id": LevelID,
                "lvl": 2,
                "name": LevelName
              }
            ],
            "toiletType": ToiletType.toString(), //1 = male, 2 = female, 3 = other
            "staffID": GetUserIDFromUserName(Username, UserListArr),
            "startClean": null,
            "endClean": null,
            "scheduleTime": convertDate(AddDate)
        }
        console.log(EmitJson)

        // socket emit
        props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "StatRequest",
            EmitSocketData: EmitJson
        })
    }
    useEffect(() => {
        if(socket == null) return
        // create socket listener
        socket.on('Toilet_schedule', data => {
            console.log(data)
            if(data.method == "Create") {
                if(data.result == "Success") SetPopupMsg('Added new record')
                if(data.result == "Fail") SetPopupMsg('Add record Failed: ' + data.error)
            }
            if(data.method == "Delete") {
                if(data.result == "Success") {
                    SetPopupMsg('Record Deleted')
                    SetConfirmDel(false)
                }
                if(data.result == "Fail") {
                    SetPopupMsg('Delete record Failed: ' + data.error)
                    SetConfirmDel(false)
                }
            }
            if(data.method == "Modify") {
                if(data.result == "Success") SetPopupMsg('Record Updated')
                if(data.result == "Fail") SetPopupMsg('Record Update Failed: ' + data.error)
            }
            if(data.method != "Read") RequestData()   //trigger parent to refresh shedule list
        })

        // exit: remove socket listener
        return () => {
            socket.off('Toilet_schedule')
        }
    }, [])
    useEffect(() => {
        if(UserList == null) return
        if(UserList.users == null || UserList.users.length == null || UserList.users.length == 0) return
        setUserListArr(UserList.users)
    }, [UserList])
    useEffect(() => {
        console.log(PopupData)
        if(PopupData == null || PopupData.data == null) {
            setSelectUser('')
            return
        }

        const {id, mainF, mainT, name} = PopupData.data
        if(id == null || mainF == null || mainT == null || name == null) return

        // setStartDate(new Date(mainF=="--"?null:mainF))
        // setEndDate(new Date(mainT=="--"?null:mainT))
        setStartDate(mainF=="--"||mainF==""||mainF==null?new Date():new Date(mainF))
        setEndDate(mainT=="--"||mainT==""||mainT==null?new Date():new Date(mainT))
        setSelectUser(name)
        setUsername(GetEditUserFromUserID(name, UserListArr))
    }, [PopupData])

    function CloseMsgbox() {
        SetPopupMsg(null)
        setPopupData(null)
    }
    function DeleteRequest() {
        // console.log(PopupData)
        // console.log(UserInfo)
        if(PopupData == null || PopupData.data == null || PopupData.data.id == null || UserInfo== null) return

        //get delete item id
        const RecordID = PopupData.data.id
        const {client_id, userID} = UserInfo
        //send delete in socket
        const EmitJson = {
            "msgType": "Toilet_schedule",
            "method": "Delete",
            "operatorID": userID,
            "ScheduleID": RecordID
        }
        console.log(EmitJson)

        // socket emit
        props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "StatRequest",
            EmitSocketData: EmitJson
        })
    }
    function UpdateSchedule() {
        if(StartDate == null || EndDate == null || SelectUser == null || SelectUser == "" || PopupData == null || PopupData.data == null || UserInfo == null || ToiletType == null
        || DeviceData == null || currentSite==null || currentlevel == null) return

        const ScheduleID = PopupData.data.id
        const CleanStaffID = PopupData.data.name
        const {client_id, userID} = UserInfo
        const {SiteID, SiteName} = GetSiteID(DeviceData, currentSite)
        const {LevelID, LevelName} = GetLevelID(DeviceData, currentSite, currentlevel)

        const EmitJson = {
            "msgType": "Toilet_schedule",
            "method": "Modify",
            "client_id": client_id,
            "operatorID": userID,
            "locationInfo": [
                {
                  "id": SiteID,
                  "lvl": 1,
                  "name": SiteName
                },
                {
                  "id": LevelID,
                  "lvl": 2,
                  "name": LevelName
                }
            ],
            "toiletType": ToiletType.toString(), //1 = male, 2 = female, 3 = other
            "ScheduleID": ScheduleID,
            "staffID": CleanStaffID,
            "startClean": convertDate(StartDate),
            "endClean": convertDate(EndDate),
            "scheduleTime": convertDate(StartDate)
        }
        console.log(EmitJson)
        // socket emit
        props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "StatRequest",
            EmitSocketData: EmitJson
        })
    }

    if(PopupData == null || PopupData.type == null) return <div style={{display: 'none'}}></div>          //Popup close, not add or edit

    const Type = PopupData.type
    // const EditData = PopupData.data

    if(Type == "add") {
        return <div className="ToiletAdmin_schedulepopup">
            <div className="closebtn" onClick={()=>setPopupData(null)}>
                <FontAwesomeIcon icon={faTimes} />
            </div>
            <div className="MainRow">
                <div className="inputs">
                    <input placeholder="User Name" ref={Input_name} list="userlist"></input>
                    <datalist id="userlist">
                        {
                            UserListArr.map(data=> {
                                return <option value={data.username}>{data.username}</option>
                            })
                        }
                    </datalist>
                    <DatePicker
                        selected={AddDate}
                        onChange={setAddDate}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={30}
                        dateFormat="d-MMMM yyyy HH:mm"
                        timeCaption="time"
                    />
                </div>
                <div className="bottomBtn">
                    <div className="add" onClick={AddRequest}>Add Record</div>
                    <div className="cancel" onClick={()=>setPopupData(null)}>Cancel</div>
                </div>
            </div>
            {
                PopupMsg!=null?
                <div className="ToiletAdmin_schedulemsg">
                    {PopupMsg}
                    <div className="closebtn" onClick={CloseMsgbox}>Ok</div>
                </div>
                :
                <div style={{display: 'none'}}></div>
            }
        </div>
    }

    return <div className="ToiletAdmin_schedulepopup">
        <div className="closebtn" onClick={()=>setPopupData(null)}>
            <FontAwesomeIcon icon={faTimes} />
        </div>
        <div className="MainRow">
            <div className="inputs">
                <input placeholder="User Name" ref={Input_name} onChange={(event)=>setUsername(event.target.value)} value={Username} list="userlist"></input>
                <datalist id="userlist">
                    {
                        UserListArr.map(data=> {
                            return <option value={data.username}>{data.username}</option>
                        })
                    }
                </datalist>
                <DatePicker
                    selected={StartDate}
                    onChange={setStartDate}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    dateFormat="d-MMMM yyyy HH:mm"
                    timeCaption="time"
                />
                <DatePicker
                    selected={EndDate}
                    onChange={setEndDate}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    dateFormat="d-MMMM yyyy HH:mm"
                    timeCaption="time"
                />
            </div>
            <div className="bottomBtn">
                <div className="delete" onClick={()=>SetConfirmDel(true)}>Delete</div>
                <div className="add" onClick={UpdateSchedule}>Update</div>
                <div className="cancel" onClick={()=>setPopupData(null)}>Cancel</div>
            </div>
            {
                PopupMsg!=null?
                <div className="ToiletAdmin_schedulemsg">
                    {PopupMsg}
                    <div className="closebtn" onClick={CloseMsgbox}>Ok</div>
                </div>
                :
                <div style={{display: 'none'}}></div>
            }
            {
                ConfirmDel?
                <div className="ToiletAdmin_scheduleconfirm">
                    <div className="title">Confirmed to Delete this record?</div>
                    <div className="buttonrow">
                        <div className="del" onClick={DeleteRequest}>Delete</div>
                        <div className="cancel" onClick={()=>SetConfirmDel(false)}>Cancel</div>
                    </div>
                </div>
                :
                <div style={{display: 'none'}}></div>
            }
        </div>
    </div>
}

function mapStateToProps(state) {
    return {
        ToiletType: state.ToiletType,
        socket: state.socket,
        UserInfo: state.UserInfo,
        DeviceData: state.DeviceData,
        currentSite: state.currentSite,
        currentlevel: state.currentlevel,
    }
}

export default connect(mapStateToProps)(SchedulePopupWidget)


function GetSiteID(DeviceData, currentSite) {
    try {
        const SiteItem = DeviceData[currentSite]
        const SiteID = SiteItem._id
        const SiteName = SiteItem.locName
        return {
            SiteID: SiteID,
            SiteName: SiteName
        }
    }
    catch(err) {
        return err
    }
}
function GetLevelID(DeviceData, currentSite, currentlevel) {
    try {
        const LevelList = DeviceData[currentSite].nestedLocs
        let LevelID = "No Level ID found"
        let LevelName = "No Level Name found"
        LevelList.forEach(LevelItem => {
            if(LevelItem.locName == currentlevel || LevelItem.locDesc == currentlevel) {
                LevelID = LevelItem._id
                LevelName = LevelItem.locName
            }
        })
        return {
            LevelID: LevelID,
            LevelName: LevelName,
        }
    }
    catch(err) {
        return err
    }
}

function GetEditUserFromUserID(SelectUser, UserListArr) {
    if(UserListArr == null || UserListArr.length == null || UserListArr.length == 0) return     //exception
    let ModifyingItem = UserListArr.find(data=> {
        return data.userID == SelectUser
    })
    if(ModifyingItem == null) return ''
    return ModifyingItem.username
}
function GetUserIDFromUserName(usernameStr, UserListArr) {
    if(UserListArr == null || UserListArr.length == null || UserListArr.length == 0) return     //exception
    let ModifyingItem = UserListArr.find(data=> {
        return data.username == usernameStr
    })
    if(ModifyingItem == null) return 'ModifyingItem == null'
    return ModifyingItem.userID
}