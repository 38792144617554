import React from 'react';
import './SummaryCard_4reading.css'
import './SummaryCard_4reading_2row.css'

//Redux Import
import { connect } from 'react-redux';

import Loading from '../../../loading';

class SummaryCard extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            x2_rowWidget: false,        //when true, would become CIC demo widget, with two rows only
        }
    }

    componentDidMount() {
        const _this = this
        //check the user 's client id, if is CIC, show 2 row 4 reading display
        const {UserInfo} = this.props
        if(UserInfo == null || UserInfo.client_id == null) return       //exception case

        const client_id = UserInfo.client_id
        if(client_id == "5f97d5dc758e2f5f08190afd" || client_id == "60d00728633f3484443850bd") {
            _this.setState({
                x2_rowWidget: true
            })
        }
    }

    render() {
        const {x2_rowWidget} = this.state
        const { UserInfo, currentSite, currentlevel, DeviceData } = this.props
        if(DeviceData == null || UserInfo == null || DeviceData[currentSite] == null)
        {
            return <Loading/>
        }
        //cal the real average summary readings
        //1. get currentFloorItem and its inner device list
        const CurrentFloorItem = DeviceData[currentSite].nestedLocs.find(function(floorItem) {return floorItem.locName == currentlevel})
        if(CurrentFloorItem == null) return <Loading/>
        const CurrentDeviceList  = CurrentFloorItem.nestedLocs

        let averageTemp = 0
        let averageHumid = 0
        let CO2Reading = 0
        let pm25Reading = 0
        try
        {
            let SumTemp = 0
            let CountTemp = 0
            let SumHumid = 0
            let CountHumid = 0
            let SumCO = 0
            let CountCo = 0
            let SumPM25 = 0
            let CountPM25 = 0
            CurrentDeviceList.forEach(DeviceItem => {
                const payloadData = DeviceItem.payload
                if(payloadData == null) return      //exception case

                //check if is vibration sensor, would not count its IAQ / IEQ readings
                if(DeviceItem != null && DeviceItem.payload_definition_id != null && DeviceItem.payload_definition_id.nameID != null 
                    && DeviceItem.payload_definition_id.nameID == 'Vibration') return

                if(payloadData.temperature != null) {
                    SumTemp += Number(payloadData.temperature)
                    CountTemp++
                }
                if(payloadData.humidity != null) {
                    SumHumid += Number(payloadData.humidity)
                    CountHumid++
                }
                if(payloadData.co2 != null) {
                    SumCO += Number(payloadData.co2)
                    CountCo++
                }
                if(payloadData.pm2_5 != null) {
                    SumPM25 += Number(payloadData.pm2_5)
                    CountPM25++
                }
            })
            //cal the average value
            if(CountTemp != 0) averageTemp = SumTemp/CountTemp
            if(CountHumid != 0) averageHumid = SumHumid/CountHumid
            if(CountCo != 0) CO2Reading = SumCO/CountCo
            if(CountPM25 != 0) pm25Reading = SumPM25/CountPM25
            //round off to 1 decimal
            averageTemp = averageTemp.toFixed(1)
            averageHumid = averageHumid.toFixed(0)
            CO2Reading = CO2Reading.toFixed(0)
            pm25Reading = pm25Reading.toFixed(2)
            if(averageTemp == "NaN" || averageTemp == "0.0") averageTemp = "--.-"
            if(averageHumid == "NaN" || averageHumid == "0") averageHumid = "--.-"
            if(CO2Reading == "NaN") CO2Reading = "--.-"
            if(pm25Reading == "NaN") pm25Reading = "--.-"

            console.log({
                SumTemp: SumTemp,
                CountTemp: CountTemp,
                SumHumid: SumHumid,
                CountHumid: CountHumid,
                SumCO: SumCO,
                CountCo: CountCo,
                SumPM25: SumPM25,
                CountPM25: CountPM25,
            })
        }
        catch(err)
        {
            console.log('4 reading Summary error');
        }
        
        console.log(CurrentDeviceList)

        // check if there are missing Temperature, Humidity, CO2, or PM25 reading, if missing then hide that row
        let TempReadingExist = false
        let HumidReadingExist = false
        let COReadingExist = false
        let PM25ReadingExist = false
        CurrentDeviceList.forEach(DeviceItem => {
            const payloadData = DeviceItem.payload
            if(payloadData == null) return
            if(payloadData.temperature != null) TempReadingExist = true        
            if(payloadData.humidity != null) HumidReadingExist = true            
            if(payloadData.co2 != null) COReadingExist = true
            if(payloadData.pm2_5 != null) PM25ReadingExist = true
        })

        //4 reading widget with 2 rows only (first for CIC project requirement)
        if(x2_rowWidget) {
            return <div className="_4reading2row">
                <div className="_4read2r_header">
                    <i className="fa fa-thermometer-half _4read2r_headerIcon"></i>
                    IEQ
                </div>
                <div className="_4read2r_Row">
                    <div className="_4read2r_Left temp">
                        <div className="_4read2r_Type">
                            <i className="fa fa-thermometer-half"></i>
                        </div>
                        <div className="_4read2r_Reading">
                            {averageTemp}
                            <div className="_4read2r_Unit">
                                C<sup>o</sup>
                            </div>
                        </div>
                    </div>
                    <div className="_4read2r_right humid">
                        <div className="_4read2r_Type">
                            <i className="fa fa-tint"></i>
                        </div>
                        <div className="_4read2r_Reading">
                            {averageHumid}
                            <div className="_4read2r_Unit">
                                %
                            </div>
                        </div>
                    </div>
                </div>
                <div className="_4read2r_Row">
                    <div className="_4read2r_Left co2">
                        <div className="_4read2r_Type2">
                            CO₂
                           <div className="_4read2r_Unit2">
                               {/* 2 */}
                           </div>
                        </div>
                        <div className="_4read2r_Reading">
                            {CO2Reading}
                        </div>
                    </div>
                    <div className="_4read2r_right pm25">
                        <div className="_4read2r_Type2">
                           PM<sub>2.5</sub>
                        </div>
                        <div className="_4read2r_Reading">
                            {pm25Reading}
                        </div>
                    </div>
                </div>
            </div>
        }

        //original 4 reading widget
        return <div className="smallcard_left_top">
            <div className="text-left small_head">
                <i className="fa fa-thermometer-half headericon"></i>
                IEQ
            </div>
            <div className="flex justify-between flex-col small_body">
                <div className="FloorPlan_Widget_SummaryCard1_row">
                    <div className="FloorPlan_SummaryCard1_topItem">
                        <div className={TempReadingExist?"FloorPlan_Widget_SummaryCard1_rowitem cardcolor-red":"HideRow"}>
                            <div className="fa fa-thermometer-three-quarters FloorPlan_widget_SummaryCard_icon"></div>
                            <div className="FloorPlan_widget_SummaryCard_txt">
                                {
                                    //this.props.DashboardData.Summary.IAQ.temp
                                    averageTemp
                                }
                                <div className="FloorPlan_widget_SummaryCard_txt_unit">
                                    C
                                    <div className="FloorPlan_widget_SummaryCard_txt_unit2">
                                        o
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="FloorPlan_Widget_SummaryCard1_title">
                            Temperature
                        </div>
                    </div>
                    <div className="FloorPlan_SummaryCard1_topItem">
                        <div className={HumidReadingExist?"FloorPlan_Widget_SummaryCard1_rowitem2 cardcolor-blue":"HideRow"}>
                            <div className="fa fa-tint FloorPlan_widget_SummaryCard_icon"></div>
                            <div className="FloorPlan_widget_SummaryCard_txt">
                                {
                                    //this.props.DashboardData.Summary.IAQ.humid
                                    averageHumid
                                }
                                <div className="FloorPlan_widget_SummaryCard_txt_unit">
                                    %
                                    <div className="FloorPlan_widget_SummaryCard_txt_unit2">
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="FloorPlan_Widget_SummaryCard1_title">
                            Humidity
                        </div>
                    </div>
                </div>
                <div className="FloorPlan_Widget_SummaryCard1_row2">
                    <div className={COReadingExist?"FloorPlan_Widget_SummaryCard1_rowitem cardcolor-brown Summarybottomrow":"HideRow"}>
                        <div className="flex-col co2container">
                            <div className="FloorPlan_widget_SummaryCard_iconBot">CO2</div>
                            <div className="FloorPlan_widget_SummaryCard_txt_unit summarycardbottomunit">
                                ppmv
                            </div>
                        </div>
                        <div className="FloorPlan_widget_SummaryCard_txt co2container">
                            {
                                //this.props.DashboardData.Summary.IAQ.CO2
                                CO2Reading
                            }
                        </div>
                    </div>
                    <div className={PM25ReadingExist?"cardcolor-green Summarybottomrow":"HideRow"}>
                        <div className="flex-col co2container">
                            <div className="FloorPlan_widget_SummaryCard_iconBot">PM2.5</div>
                            <div className="FloorPlan_widget_SummaryCard_txt_unit summarycardbottomunit">
                                μg/m3
                            </div>
                        </div>
                        <div className="FloorPlan_widget_SummaryCard_txt co2container">
                            {
                                //this.props.DashboardData.Summary.IAQ.PM25
                                pm25Reading
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    //console.log(state.UserInfo);
    return {
      currentlevel: state.currentlevel,
      currentSite: state.currentSite,
      UserInfo: state.UserInfo,
      DeviceData: state.DeviceData,
      DeviceDataLastUpdate: state.DeviceDataLastUpdate
    };
}
export default connect(mapStateToProps)(SummaryCard);