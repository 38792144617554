import React from 'react'
import './status.css'
//Redux Import
import { connect } from 'react-redux'
import moment from 'moment';

function Status(props) {
    let rowColor = 'transparent'
    let status = props.status
    let duration = props.duration
    let timeline_arr = [...Array(duration).keys()]

    console.log(status, duration, "status")
    return (
        // <div className="wrap">
            <div className="Planner_statusRow" style={{background: rowColor}}>
                <div className="col1">Planned/Adhoc/Available&nbsp;&nbsp;&nbsp;</div>
                <div className="subScroll" onScroll={bindHandleScroll}>
                {
                    timeline_arr.map(idx => {
                        let D = new Date()
                        D.setDate(D.getDate() + idx)
                        D.setHours(0,0,0,0)
                        let date = moment(D).format('YYYY-MM-DD');
                        var result = status.find(obj => {
                            return obj.date === date
                        })
                        if (result == undefined) {
                            return <div className="col2">&nbsp;</div>
                        } else {
                            let date_color = result.color
                            return <div className="col2" style={{color: date_color}}>{(result.countPlanned)}/ {(result.countAdhoc)} /{(result.countAvailable)}</div>
                        }
                    })
                }
                </div>
            </div>
        // </div>
    )
}

//Redux inside component function
function mapStateToProps(state) {
    return {
    }
}

function bindHandleScroll(event) {
  let subScrollList = document.getElementsByClassName("subScroll")
  for (let i=0; i< subScrollList.length; i++) {
    let item = subScrollList[i]
    item.scrollLeft = event.currentTarget.scrollLeft
  }
}


export default connect(mapStateToProps)(Status)
