import React from 'react'
import './Navbar.css'
//Redux Import
import { connect } from 'react-redux'
//Moment for formating the date and tie string
import Moment from 'react-moment'
import ReactModal from 'react-modal'
import { useLocation } from 'react-router-dom'  //get route path
import NavPopUp from './navPopup'
import Side from '../Side/Side'
import RealTimeAlert from './RealTimeAlert'            // show the debug message on bottom

import PopupNotification from '../../popupNotification/PopupNotification'
import ConnectStatusWidget from './connectStatus/connectStatus'

import SoundPlay from '../../Sound/Sound'

ReactModal.defaultStyles.overlay.backgroundColor = 'transparent'

class Navbar extends React.Component {
    constructor(props) {
        super();
        this.LevelPick = this.LevelPick.bind(this);

        this.state = {
            ShowLevel: false,
            date: new Date(),
            ShowNavMenu: false,
            ShowNavType: 1,
            ShowLeftHandSideMenu: false
        };

        this.tick = this.tick.bind(this);
        //this.navbarDroplistChange = this.navbarDroplistChange.bind(this);
        this.NotificationViewAll = this.NotificationViewAll.bind(this);
        this.HomeRedirect = this.HomeRedirect.bind(this);
        this.userinfoclick = this.userinfoclick.bind(this);
        this.notiPopup = this.notiPopup.bind(this);
        this.settingPopup = this.settingPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.Logout = this.Logout.bind(this);
        this.MenuPick = this.MenuPick.bind(this);

        this.initialDarkMode = this.initialDarkMode.bind(this);
        this.NormalMode = this.NormalMode.bind(this);
        this.SetDarkMode = this.SetDarkMode.bind(this);

        this.checkTimeoutDeviceData = this.checkTimeoutDeviceData.bind(this);

        this.refreshPage = this.refreshPage.bind(this)

        this.FirstLoginChangePwd = this.FirstLoginChangePwd.bind(this)

        this.soundRef = React.createRef()
    }
    refreshPage() {
        const currentUrl = window.location.href
        localStorage.setItem('refreshUrl', currentUrl)
        window.location.reload(false)
    }
    FirstLoginChangePwd() {
        const { UserInfo } = this.props
        if (UserInfo == null || UserInfo.IsFirstLogin == false || UserInfo.IsFirstLogin == null) return      //no need trigger reset password popup
        this.setState({
            ShowNavMenu: true,
            ShowNavType: 1
        })
    }
    initialDarkMode() {
        //check the Localstorage 'DarkMode' is true / false
        let DarkMode = localStorage.getItem('DarkMode');
        if (DarkMode == "true") {
            console.log('SetDarkMode() navbar.js');
            this.SetDarkMode();
        }
        else {
            console.log('NormalMode() not darkmode navbar.js');
            this.NormalMode();
        }
    }
    SetDarkMode() {
        const { configStorage } = this.props
        if (configStorage == null || configStorage.darkColorTheme == null) return  //exception condition

        localStorage.setItem('DarkMode', true)
        var element = document.getElementById("root")
        element.classList.add("DarkMode")

        //since the background gradient is outside root, have to set the style manually
        // document.documentElement.style.setProperty('--colortheme1', '#4e4e4e');
        // document.documentElement.style.setProperty('--colortheme2', '#2e2e2e');
        // document.documentElement.style.setProperty('--colortheme1', configStorage.darkColorTheme['--colortheme1'])
        // document.documentElement.style.setProperty('--colortheme2', configStorage.darkColorTheme['--colortheme2'])
        // document.documentElement.style.setProperty('--optiontext', 'white');

        // console.log('set dark mode');
        // //Navbar styling variable, since navbar slide is outside "#root"
        // document.documentElement.style.setProperty('--slideBG_1', 'rgba(0,0,0,1)');
        // document.documentElement.style.setProperty('--slideBG_2', 'rgba(93,93,93)');
        // document.documentElement.style.setProperty('--slideBG_3', 'rgba(75,75,75,0.72)');

        document.documentElement.style.setProperty('--colortheme1', configStorage.darkColorTheme['--colortheme1'])
        document.documentElement.style.setProperty('--colortheme2', configStorage.darkColorTheme['--colortheme2'])
        // document.documentElement.style.setProperty('--optiontext',  configStorage.darkColorTheme['--optiontext'])
        console.log('set dark mode')
        document.documentElement.style.setProperty('--slideBG_1', configStorage.darkColorTheme['--slideBG_1'])
        document.documentElement.style.setProperty('--slideBG_2', configStorage.darkColorTheme['--slideBG_2'])
        document.documentElement.style.setProperty('--slideBG_3', configStorage.darkColorTheme['--slideBG_3'])
        document.documentElement.style.setProperty('--optiontext', 'white')

        this.setState({
            IsDark: true
        });
    }
    NormalMode() {
        //convert to Light Mode
        localStorage.setItem('DarkMode', false)
        var element = document.getElementById("root")
        element.classList.remove("DarkMode")

        //since the background gradient is outside root, have to set the style manually
        let ColorNavigationBar = localStorage.getItem('--colortheme1')
        let ColorBackground = localStorage.getItem('--colortheme2')

        //set to the css variable
        if (ColorNavigationBar != null && ColorNavigationBar != '') {
            document.documentElement.style.setProperty('--colortheme1', ColorNavigationBar)
            document.documentElement.style.setProperty('--colortheme2', ColorBackground)
        }
        document.documentElement.style.setProperty('--optiontext', 'black')

        console.log('set normal mode');
        //Navbar styling variable, since navbar slide is outside "#root"
        document.documentElement.style.setProperty('--slideBG_1', 'rgba(255,255,255,1)')
        document.documentElement.style.setProperty('--slideBG_2', 'rgba(255,255,255,1)')
        document.documentElement.style.setProperty('--slideBG_3', 'rgba(255,255,255,0.8)')

        this.setState({
            IsDark: false
        })
    }

    componentDidMount() {
        const _this = this
        this.initialDarkMode()
        this.FirstLoginChangePwd()

        //tick the clock
        this.intervalID = setInterval(
            () => this.tick(),
            2000
        )

        const thisoutside = this
        console.log('Navbar.js Socket Listener Onload (/Main Listener)');
        //clean all socket listeners in any pages
        try {
            const {socket, UserInfo} = this.props
            if (socket == null || socket == undefined) {
                return
            }
            socket.removeAllListeners()

            console.log('Navbar.js removeAllListeners() reset the socket listeners for storage of redux');
            socket.on('disconnect', () => {
                console.error('Socket Disconnect Detected (navbar.js)')
                _this.props.dispatch({type: 'socketUpdate', data: false})
            })
            socket.on('connect', () => {
                console.error('Socket connect / re-connect Detected (navbar.js)')
                _this.props.dispatch({type: 'socketUpdate', data: true})
            })
            socket.on("ActiveAlarms", data => {
                if(isColdbox(UserInfo)) return  //coldbox project use 'alarmlist' to update the list

                console.warn("ActiveAlarms()");
                console.log(data);
                thisoutside.props.dispatch({
                    type: 'ActiveAlarms',
                    data: data,
                });
                try {
                    thisoutside.RealTimeAlertRef.CreateBottomAlert("ActiveAlarms()", true, "black");
                }
                catch (err) {
                    console.log(err);
                }

                // thisoutside.RealTimeAlertRef.newAlertNotification("Active alarm is updated", 
                // "Please check the active alarms", "", false);
                // thisoutside.PopupNotificationRef.addNotificationPopup("Active alarm is updated", 
                //  "Please check the active alarms", "", false);
            })

            socket.on("SiteInfo", data => {
                console.log("SiteInfo data");
                console.log(data);
                thisoutside.props.dispatch({
                    type: 'UserManageInfo',
                    data: data
                });
                try {
                    thisoutside.RealTimeAlertRef.CreateBottomAlert("SiteInfo()", true, "black");
                }
                catch (err) {
                    console.log(err);
                }
            })
            socket.on("RemoveAccount", data => {
                //console.log(data);
                // thisoutside.RealTimeAlertRef.newAlertNotification("RemoveAccount()", 
                // "Status:" + data.result, null, true);
                try {
                    thisoutside.PopupNotificationRef.addNotificationPopup("RemoveAccount()",
                        "Status:" + data.result, null, true);
                }
                catch (err) {
                    console.log(err);
                }
            })
            socket.on("CreateAccount", data => {
                console.log(data);
                if (data.result == null) {
                    // thisoutside.RealTimeAlertRef.newAlertNotification("CreateAccount Failed", 
                    // "Status Value is missing from response", null, true);
                    thisoutside.PopupNotificationRef.addNotificationPopup("CreateAccount Failed",
                        "Status Value is missing from response", null, true);
                }
                else if (data.result == "Success") {
                    // thisoutside.RealTimeAlertRef.newAlertNotification("CreateAccount Success", 
                    // "If the account is not appeared, please refresh", null, true);
                    thisoutside.PopupNotificationRef.addNotificationPopup("CreateAccount Success",
                        "If the account is not appeared, please refresh", null, true);
                }
                else {
                    // thisoutside.RealTimeAlertRef.newAlertNotification("CreateAccount Failed", 
                    // "Failed Message: " + data.message, null, true);
                    thisoutside.PopupNotificationRef.addNotificationPopup("CreateAccount Failed",
                        "Failed Message: " + data.message, null, true);
                }
            })
            socket.on("ModifyAccount", data => {
                console.log(data);
                if (data.result == null) {
                    // thisoutside.RealTimeAlertRef.newAlertNotification("ModifyAccount Failed", 
                    // "Modify Account Response Status is missing", null, true);
                    thisoutside.PopupNotificationRef.addNotificationPopup("Modify Account Failed",
                        "Modify Account Response Status is missing", null, true);
                }
                else if (data.result == "Success") {
                    // thisoutside.RealTimeAlertRef.newAlertNotification("Modify Account Success", 
                    // "If the data is not updated, please refresh", null, true);
                    thisoutside.PopupNotificationRef.addNotificationPopup("Modify Account Success",
                        "If the data is not updated, please refresh", null, true);
                }
                else {
                    // thisoutside.RealTimeAlertRef.newAlertNotification("Modify Account failed",
                    // "Failed Message: " + data.message, null, true);
                    thisoutside.PopupNotificationRef.addNotificationPopup("Modify Account failed",
                        "Failed Message: " + data.message, null, true);
                }
            })
            socket.on("AccountList", data => {
                //for user management page
                console.warn('AccountList()');
                console.log(data);
                //"UserManageUserList"
                thisoutside.props.dispatch({
                    type: 'UserManageUserList',
                    data: data,
                });
                //thisoutside.RealTimeAlertRef.newAlertNotification("AccountList()", "Account List is updated");
                thisoutside.PopupNotificationRef.addNotificationPopup("Account List is updated",
                    "", null, true);
            })

            socket.on("LocationStatistics", data => {
                console.log(data)

                //console.log(data.msgID)
                if (data.msgID != "LineChart" && data.msgID != null) {   //except linechart, all widget use this redux function
                    thisoutside.props.dispatch({
                        type: data.msgID,  //HeatmapCO2, HeatmapIAQ, IAQSpiderChart
                        data: data
                    })
                    return
                }
                if(data.msgID == 'lift') {
                    const {accel_X, accel_Y, accel_Z} = data.data
                    thisoutside.props.dispatch({
                        type: 'DeviceStatistic',
                        data: {
                            loading: false,
                            lift: {
                                accel_X: accel_X,
                                accel_Y: accel_Y,
                                accel_Z: accel_Z
                            },
                            mileageTravelled: StatisticData.mileageTravelled,
                            powerConsumed: StatisticData.powerConsumed,
                            runtimeTravelled: StatisticData.runtimeTravelled,
                            doorOpenCount: StatisticData.doorOpenCount,
                            liftStartupCount: StatisticData.liftStartupCount,
                            liftDirectionReverseCount: StatisticData.liftDirectionReverseCount,
                        },
                    })
                    return
                }
                //new linechart data response
                const { StatisticData } = thisoutside.props
                if (data == null || data.data == null || StatisticData == null) return            //exception cases

                let NewData = {
                    type: 'DeviceStatistic',
                    data: {      //store all the DeviceStatistic data in Statistic page
                        co2: StatisticData.co2,
                        pm25: StatisticData.pm25,
                        temp: StatisticData.temp,
                        humid: StatisticData.humid,
                        h2s: StatisticData.h2S,
                        so2: StatisticData.sO2,
                        power: StatisticData.power,
                        illuminance: StatisticData.illuminance,
                        loading: false,
                        lift: StatisticData.lift,
                        mileageTravelled: StatisticData.mileageTravelled,
                        powerConsumed: StatisticData.powerConsumed,
                        runtimeTravelled: StatisticData.runtimeTravelled,
                        doorOpenCount: StatisticData.doorOpenCount,
                        liftStartupCount: StatisticData.liftStartupCount,
                        liftDirectionReverseCount: StatisticData.liftDirectionReverseCount,
                        coldboxStat: StatisticData.coldboxStat
                    },
                }
                //CPSL
                // 'Temp1', 'Temp2', 'Temp3', 'Temp4', 'Temp5',
                // 'ReturnAirTemp', 'WeightedMeanTemp', 'RH', 'PressureCondensing', 'PressureEvaporating',
                // 'TempSuction', 'ExpansionValveOpening', 'UPSBatteryVoltage', 'UPSBatteryCapacity', 'UPSLoad',
                // 'UPSOutputVoltage', 'UPSoutputfrequency', 'UPSoutputcurrent', 'UPSchargingtime', 'COP'
                if(data.data.Temp1 != null || data.data.Temp2 != null || data.data.Temp3 != null || data.data.Temp4 != null ||
                    data.data.Temp5 != null || data.data.ReturnAirTemp != null ||data.data.WeightedMeanTemp != null || data.data.RH != null ||
                    data.data.PressureCondensing != null || data.data.PressureEvaporating != null ||data.data.TempSuction != null || data.data.ExpansionValveOpening != null ||
                    data.data.UPSBatteryVoltage != null || data.data.UPSBatteryCapacity != null ||data.data.UPSLoad != null || data.data.UPSOutputVoltage != null ||
                    data.data.UPSOutputFrequency!= null || data.data.UPSOutputCurrent!= null ||data.data.UPSChargingTime!= null || data.data.COP != null ) {
                    thisoutside.props.dispatch({
                        type: 'DeviceStatistic',
                        data: {      //store all the DeviceStatistic data in Statistic page
                            co2: StatisticData.co2,
                            pm25: StatisticData.pm25,
                            temp: StatisticData.temp,
                            humid: StatisticData.humid,
                            power: StatisticData.power,
                            illuminance: StatisticData.illuminance,
                            loading: false,
                            lift: StatisticData.lift,
                            mileageTravelled: StatisticData.mileageTravelled,
                            powerConsumed: StatisticData.powerConsumed,
                            runtimeTravelled: StatisticData.runtimeTravelled,
                            doorOpenCount: StatisticData.doorOpenCount,
                            liftStartupCount: StatisticData.liftStartupCount,
                            liftDirectionReverseCount: StatisticData.liftDirectionReverseCount,
                            coldboxStat:data.data
                        }
                    })
                    return
                }

                if(data.data.temperature != null) {  //check if there is temperature
                    NewData.data.temp = data.data.temperature
                }
                if(data.data.humidity != null) {     //check if there is humid
                    NewData.data.humid = data.data.humidity
                }
                if(data.data.co2 != null) {          //check if there is co2
                    NewData.data.co2 = data.data.co2
                }
                if(data.data.pm2_5 != null) {        //check pm25
                    NewData.data.pm25 = data.data.pm2_5
                }
                if(data.data.h2S != null) {
                    NewData.data.h2s = data.data.h2S
                }
                if(data.data.sO2 != null) {
                    NewData.data.so2 = data.data.sO2
                }
                if(data.data.acPower != null) {      //check if there is power
                    NewData.data.power = data.data.acPower
                }
                if(data.data.illuminance != null) {  //check if there is illuminance
                    NewData.data.illuminance = data.data.illuminance
                }
                if(data.data.mileageTravelled != null) {   //check if there is mileageTravelled (lift readings)
                    NewData.data.mileageTravelled = data.data.mileageTravelled
                }
                if(data.data.powerConsumed != null) {      //powerConsumed (lift readings)
                    NewData.data.powerConsumed = data.data.powerConsumed
                }
                if(data.data.runtimeTravelled != null) {   //runtimeTravelled (lift readings)
                    NewData.data.runtimeTravelled = data.data.runtimeTravelled
                }
                if(data.data.doorOpenCount != null && data.data.liftStartupCount != null || data.data.liftDirectionReverseCount != null) { //doorOpenCount //liftDirectionReverseCount //liftStartupCount
                    thisoutside.props.dispatch({
                        type: 'DeviceStatistic',
                        data: {      //store all the DeviceStatistic data in Statistic page
                            co2: StatisticData.co2,
                            pm25: StatisticData.pm25,
                            temp: StatisticData.temp,
                            humid: StatisticData.humid,
                            power: StatisticData.power,
                            illuminance: StatisticData.illuminance,
                            loading: false,
                            lift: StatisticData.lift,
                            mileageTravelled: StatisticData.mileageTravelled,
                            powerConsumed: StatisticData.powerConsumed,
                            runtimeTravelled: StatisticData.runtimeTravelled,
                            doorOpenCount: data.data.doorOpenCount,
                            liftStartupCount: data.data.liftStartupCount,
                            liftDirectionReverseCount: data.data.liftDirectionReverseCount,
                        },
                    })

                    return
                }

                thisoutside.props.dispatch(NewData) //update new data to redux
            })

            //Equipment (LoraWanGateway) 
            socket.on("GetEquipmentList", data => {
                //thisoutside.PopupNotificationRef.addNotificationPopup("AlarmList", data.toString(), null, true);
                console.log(data)
                thisoutside.props.dispatch({
                    type: 'EquipmentList',
                    data: data
                })
            })

            //Coldbox Group
            socket.on("GetGroupList", data => {

                console.log(data)
                thisoutside.props.dispatch({
                    type: 'GroupList',
                    data: data
                })
            })
            socket.on("DeleteGroup", data => {

                console.log(data)

                try {
                    if (data.result == "Success") {
                        thisoutside.PopupNotificationRef.addNotificationPopup("Delete Group",
                            "Delete Group Success!", data.result, false)            //get, delete, update, create as data.method
                    }
                    else {
                        thisoutside.PopupNotificationRef.addNotificationPopup("Delete Group",
                            "Delete Group Failed: " + data.message, data.result, false)
                    }
                }
                catch (err) {
                    console.log(err)
                }
            })
            socket.on("UpdateGroup", data => {

                console.log(data)

                try {
                    if (data.result == "Success") {
                        thisoutside.PopupNotificationRef.addNotificationPopup("Update Group",
                            "Update Group Success!", data.result, false)            //get, delete, update, create as data.method
                    }
                    else {
                        thisoutside.PopupNotificationRef.addNotificationPopup("Update Group",
                            "Update Group Failed: " + data.message, data.result, false)
                    }
                }
                catch (err) {
                    console.log(err)
                }
            })

            //Coldbox Zone
            socket.on("GetZoneList", data => {

                console.log(data)
                thisoutside.props.dispatch({
                    type: 'GetZoneList',
                    data: data.data
                })
            })

            //Audit Trial  
            socket.on("AuditTrialList", data => {

                //thisoutside.PopupNotificationRef.addNotificationPopup("AlarmList", data.toString(), null, true);
                console.log(data)
                thisoutside.props.dispatch({
                    type: 'AuditTrial',
                    data: data
                })
            })

            socket.on("AlarmList", data => {
                console.log(data)
                if(isColdbox(UserInfo)) {
                    //check routing page, if not event/alarm page, update activealarm
                    if(!window.location.href.includes('/Coldbox/Alarm') && !window.location.href.includes('/Coldbox/Event')) {
                        _this.props.dispatch({
                            type: 'ActiveAlarms',
                            data: data //alarmlist
                        })
                        return
                    }
                }
                //not realtime alert, this is the alarm on Notification Page
                try {
                    thisoutside.RealTimeAlertRef.CreateBottomAlert("AlarmList()", true, "black")
                }
                catch (err) {
                    console.log(err)
                }

                //thisoutside.PopupNotificationRef.addNotificationPopup("AlarmList", data.toString(), null, true);
                thisoutside.props.dispatch({
                    type: 'Notification',
                    data: data
                })
            })
            socket.on("AlarmData", data => {
                this.soundRef.current.alarmMp3Run("alarm")
                console.log(data)
                //set the timestamp format
                let RawTimestamp = data.timestamp
                var DateItem = new Date(RawTimestamp)
                //add 8 hours
                DateItem.setHours(DateItem.getHours() + 8)

                //convert to IOS string Date
                let month = DateItem.getMonth()
                if (month < 10) month = "0" + month
                let DateStr2 = DateItem.getDate()
                if (DateStr2 < 10) DateStr2 = "0" + DateStr2
                let Hour = DateItem.getHours()
                if (Hour < 10) Hour = "0" + Hour
                let Minutes = DateItem.getMinutes()
                if (Minutes < 10) Minutes = "0" + Minutes
                let Second = DateItem.getSeconds()
                if (Second < 10) Second = "0" + Second
                let outputStr = DateItem.getFullYear() + "-" + month + "-" + DateStr2 + " | " + Hour + ":" + Minutes + ":" + Second

                // _this.RealTimeAlertRef.newAlertNotification(data.name, data.message, outputStr, true, data)
                if(isColdbox(data)) { //coldbox Alarm
                    if(isHighPriority(data)) _this.PopupNotificationRef.addNotificationPopup(data.name, data.message, outputStr, true, data)
                }
                else _this.PopupNotificationRef.addNotificationPopup(data.name, data.message, outputStr, true, data)    //All Alarms

                try {
                    const {Notification} = _this.props
                    if(Notification==null||Notification.data==null||Notification.data.length==null) return
                    let isExist = Notification.data.filter(item => {
                        return item.name==data.name && item.message==data.message 
                        && item.id==data.id
                    })
                    if(isExist.length==null||isExist.length!=0) return
                    
                    //update redux value
                    let newNotification = Notification
                    newNotification.data.push({
                        ...data,
                        createdTime: data.timestamp
                    })
                    _this.props.dispatch({
                        type: 'Notification',
                        data: newNotification
                    })
                }
                catch(err) {
                    console.log(err)
                }
            })
            socket.on("AlarmSettings", data => {
                console.log(data)
                //error condition and returns
                if (data.error != null) {
                    //show popup
                    if (data.method != null) {
                        thisoutside.PopupNotificationRef.addNotificationPopup("Alarm Triggers",
                            data.method + " Alarm trigger failed: " + data.error.toString(), data.result, false)            //get, delete, update, create as data.method
                    }
                    else {
                        thisoutside.PopupNotificationRef.addNotificationPopup("Alarm Triggers",
                            "Alarm trigger Methods failed: " + data.error.toString(), data.result, false)
                    }
                    return
                }
                //get method, save data
                if (data.method == "GET") {
                    thisoutside.props.dispatch({
                        type: "triggerAlarmList",
                        data: data
                    });
                }
                let isXX = "";
                switch (data.method) {
                    case "GET":
                        isXX = "loaded";
                        break;
                    case "Create":
                        isXX = "Created";
                        break;
                    case "Update":
                        isXX = "Updated";
                        break;
                    case "Delete":
                        isXX = "Deleted";
                    default:
                        isXX = data.method;
                        break;
                }
                // thisoutside.RealTimeAlertRef.newAlertNotification("Alarm Triggers", 
                // "Alarm trigger is " + isXX, data.result, false);
                thisoutside.PopupNotificationRef.addNotificationPopup("Alarm Triggers",
                    "Alarm trigger is " + isXX, data.result, false);
                //thisoutside.RealTimeAlertRef.CreateBottomAlert("AlarmTrigger()", true, "black");
            })
            socket.on("LocationData", data => {
                //new Device Data update method (backend updated format and method by Vincent 2020)
                //console.log('LocationData()')
                console.log(data)
                thisoutside.props.dispatch({ type: "LocationData", data: data })
                if(thisoutside.RealTimeAlertRef!=null) thisoutside.RealTimeAlertRef.CreateBottomAlert("Data Update", true, "black")
            })

            socket.on("LocationStatus", data => {
                thisoutside.RealTimeAlertRef.CreateBottomAlert("LocationStatus()", true, "black")
                console.log(data)
                //Call Redux function (do all the tasks inside)
                thisoutside.props.dispatch({ type: "LocationStatus", data: data })
            })
            socket.on("LocationStatusManualSet", data => {
                thisoutside.RealTimeAlertRef.CreateBottomAlert("LocationStatusManualSet()", true, "black")
                console.log(data)
                if (data.result == null) thisoutside.PopupNotificationRef.addNotificationPopup("Coldbox Status Update Failed", "Status Value is missing from response", "", false)
                else if (data.result == "Success") {
                    thisoutside.PopupNotificationRef.addNotificationPopup("Coldbox Status", 'Coldbox Status is Updated', "", false)

                    //change redux variable
                    const HARDCODE_LvlName = localStorage.getItem('HARDCODE_LvlName')
                    const HARDCODE_LocName = localStorage.getItem('HARDCODE_LocName')
                    const HARDCODE_Status = localStorage.getItem('HARDCODE_Status')

                    try {
                        let newDeviceData = this.props.DeviceData
                        let LVLCOUNT = 0
                        let LOCCOUNT = 0
                        let lvlcount, loccount
                        newDeviceData[0].nestedLocs.forEach(LvlItem => {
                            LvlItem.nestedLocs.forEach(locItem => {
                                if (LvlItem.locName == HARDCODE_LvlName && locItem.locName == HARDCODE_LocName) {
                                    lvlcount = LVLCOUNT
                                    loccount = LOCCOUNT
                                }
                                LOCCOUNT++
                            })
                            LVLCOUNT++
                        })
                        if (lvlcount != null && loccount != null) {
                            newDeviceData[0].nestedLocs[lvlcount].nestedLocs[loccount].status.Status = HARDCODE_Status
                        }
                        //update to redux
                        thisoutside.props.dispatch({ type: "SaveDeviceData", data: newDeviceData })
                    }
                    catch (err) {
                        console.log(err)
                    }
                }
                else thisoutside.PopupNotificationRef.addNotificationPopup("Coldbox Status Update Failed", "Failed Message: " + data.message, "", false)
            })
            socket.on("DeviceManagement", data => {
                thisoutside.RealTimeAlertRef.CreateBottomAlert("DeviceManagement()", true, "black")
                console.log(data)
                thisoutside.props.dispatch({ type: "DeviceManagement", data: data })
            })
            socket.on("BeaconManagement", data => {
                thisoutside.RealTimeAlertRef.CreateBottomAlert("BeaconManagement()", true, "black")
                console.log(data)
                thisoutside.props.dispatch({ type: "BeaconManagement", data: data })
            })
            socket.on("LocationManagement", data => {
                console.log(data)
                if (data.result == null) thisoutside.PopupNotificationRef.addNotificationPopup("Modify Coldbox Failed", "Response Status is missing", null, true)
                else if (data.result == "Success") thisoutside.PopupNotificationRef.addNotificationPopup("Modify Coldbox Success", "If the data is not updated, please refresh", null, true)
                else thisoutside.PopupNotificationRef.addNotificationPopup("Modify Coldbox failed", "Failed Message: " + data.error, null, true)
            })
            socket.on("DeviceConfiguration", data => {
                console.log(data)
                if (data.result == null) thisoutside.PopupNotificationRef.addNotificationPopup("Device Configuration Failed", "Response Status is missing", null, true)
                else if (data.result == "Success") thisoutside.PopupNotificationRef.addNotificationPopup("Device Configuration Update Success", "", null, true)
                else thisoutside.PopupNotificationRef.addNotificationPopup("Device Configuration failed", "Failed Message: " + data.error, null, true)
            })
            socket.on("AlarmStatistics", data => {
                console.log(data)
                thisoutside.props.dispatch({ type: "LiftAlarmStatistic", data: data })
            })
            socket.on('LocationCoordinatesUpdate', data => {  //CPSL: update coldbox's floor or coordinate
                console.log(data)
                thisoutside.props.dispatch({ type: "LocationCoordinatesUpdate", data: data })
            })
        }
        catch (err) {
            console.log(err)
        }

        //set a timeout checking for DeviceData
        setTimeout(function () { thisoutside.checkTimeoutDeviceData() }, 15 * 1000)
    }
    componentWillUnmount() {
        clearInterval(this.intervalID)
    }

    checkTimeoutDeviceData() {
        let { DeviceData, DeviceDataStatus } = this.props
        let thisoutside = this
        if (DeviceData == null && DeviceDataStatus != 2) {
            //timeout
            thisoutside.props.dispatch({ type: 'TimeoutDeviceData' })
        }
        else {
            //do nothing, not timeout
        }
    }

    userinfoclick() {
        this.soundRef.current.alarmMp3Run("hover")
        //console.log('userinfoclick()');
        this.setState({
            ShowNavMenu: true,
            ShowNavType: 1
        })
    }

    notiPopup() {
        this.soundRef.current.alarmMp3Run("hover")
        //clear activealarm redux storage
        const _this = this
        const { UserInfo } = this.props
        if(isColdbox(UserInfo)) {
            // coldbox do not use 'activealarm'
        }
        else {
            _this.props.dispatch({
                type: 'ActiveAlarms',
                data: {
                    data: null
                },
            })
        }
        //request with socket for active alarm
        //get socketID from UserInfo
        if (UserInfo == null) return
        const userSocketID = UserInfo.socketId
        const userID = UserInfo.userID
        if (userSocketID == null || userID == null) return
        //socket request function
        const requestData = {
            "msgType": "ActiveAlarms",
            "socketId": userSocketID.toString(),
            "userID": userID.toString(),
        }
        console.log(requestData)
        //request the socket for Device Staticstics
        if(isColdbox(UserInfo)) {
            // coldbox do not use 'activealarm'
        }
        else {
            _this.props.dispatch({
                type: 'EmitSocket',
                EmitSocketName: "AlarmRequest",
                EmitSocketData: requestData
            })
        }
        //open right slide
        this.setState({
            ShowNavMenu: true,
            ShowNavType: 2
        })
    }

    settingPopup() {
        this.soundRef.current.alarmMp3Run("hover")
        this.setState({
            ShowNavMenu: true,
            ShowNavType: 3
        });
    }

    closePopup() {
        this.soundRef.current.alarmMp3Run("hover")
        this.setState({
            ShowNavMenu: false,
            ShowNavType: 1
        });
    }

    NotificationViewAll() {
        this.setState({
            ShowNavMenu: false,
            ShowNavType: 1
        });
        this.props.history.push('/Main/Notification');
    }

    tick() {
        this.setState({
            date: new Date()
        });
    }

    Logout() {
        //this.props.socket.emit("logout", "clear UserListRecord in socketIO");
        let { UserInfo } = this.props;
        let requestData = {}
        requestData = {
            "msgType": "Logout",
            "userID": UserInfo.userID,
            "username": UserInfo.username,
            "client_id": UserInfo.client_id,
        };

        console.log(requestData)

        this.props.dispatch({
            type: 'EmitSocket',
            EmitSocketName: "logout",
            EmitSocketData: requestData
        });

        // this.props.dispatch({ type: 'EmitSocket', 
        //     EmitSocketName: "logout",
        //     EmitSocketData: "clear UserListRecord in socketIO"
        // });

        localStorage.setItem("rememberme", false);
        localStorage.setItem("useracc", '');
        localStorage.setItem("userhashpassword", '');
        localStorage.setItem('userpassword', '')
        //clean the Userinfo in Redux
        this.props.dispatch({ type: 'SaveUserInfo', data: null });
        this.props.dispatch({ type: 'UpdateNotif', data: null });
        //redirect to login page
        this.props.history.push('/');
    }

    //#choose_level_popup_div
    LevelPick() {
        this.props.dispatch({
            type: "OpenLevelPopUp"
        });
    }

    HomeRedirect() {
        this.props.history.push('/Main');
    }

    MenuPick() {
        this.soundRef.current.alarmMp3Run("hover")
        console.log('MenuPick()');
        this.setState({
            ShowLeftHandSideMenu: !this.state.ShowLeftHandSideMenu
        });
        this.props.dispatch({ type: 'MenuButton' });
    }

    componentDidCatch(error, info) {
        console.error('componentDidCatch() on Navbar.js');
        console.error(error);
        console.error(info);
        //redirect to App.js to reload all the datas
        this.props.history.push("/App");
    }

    render() {
        // const WindowWidth = window.innerWidth
        // if (WindowWidth < 450) return <div></div>

        //let showlevelstate = this.props.ShowLevelPopUp;
        let DateString = this.state.date
        const { currentlevel, history, UserInfo } = this.props

        let highlightNavItem = this.state.ShowNavType
        let Nav1Class = ""
        let Nav2Class = ""
        let Nav3Class = ""
        if (highlightNavItem === 1 && this.state.ShowNavMenu) {
            Nav1Class = " highlightNav"
        }
        else if (highlightNavItem === 2) {
            Nav2Class = " highlightNav2"
        }
        else if (highlightNavItem === 3) {
            Nav3Class = " highlightNav2"
        }

        //right hand side menu show/hide window redux storage parameter
        let MenuDisplay = this.state.ShowLeftHandSideMenu

        let profileImage = ""
        if (UserInfo != null) profileImage = UserInfo.profile.image

        let alarmCount = 0
        try {
            //count the new alarms
            let { SystemAlerts } = this.props
            if (SystemAlerts == null) SystemAlerts = []
            let newAlarmCount = 0
            const result = Object.keys(SystemAlerts).map((key) => [Number(key), SystemAlerts[key]])
            result.forEach(resultItem => {
                if (resultItem[1].client_id.toString() === "6006850161a378359cb738bf") { //CPSL
                    if (resultItem[1].severity == "High" && resultItem[1].isClear == false) { // Do not show event
                        newAlarmCount++
                    }
                } else {

                    newAlarmCount++
                }
            })
            alarmCount = newAlarmCount
        }
        catch (err) {
            console.warn('Cannot Set the this.props.SystemAlerts')
            console.log(err)
        }

        let isShowAlarmCountClass = ""
        let ResizeAlarmCountBackgroundColor = ""
        if (alarmCount == 0) {
            isShowAlarmCountClass = "HideAlarmCount"
        }
        else if (alarmCount >= 10) {
            ResizeAlarmCountBackgroundColor = "resizeBackgroundcircle"
        }

        //decide display company icon
        let companyImagePath = <img src={require('../../assets/images/atal.png')} alt="ATAL" className="icon_image_atal"></img>
        if (UserInfo != null && UserInfo.permissions != null && UserInfo.permissions.length != null && UserInfo.permissions.length != 0) {
            const PermList = UserInfo.permissions
            const findColdboxPermission = PermList.find(function (item) {
                return item.collectionType == "Coldbox Manage" || item.collectionType == "Coldbox Location"
            })
            if (findColdboxPermission != null) companyImagePath = <img src={require('../../assets/images/CPSL/CPSL_hor.png')} alt="ATAL" className="icon_image_atalcoldbox"></img>
            const findLogoSetting = PermList.find(function (item) {
                return item.collectionType == 'Logo'
            })
            if(findLogoSetting != null) companyImagePath = [
                <img src={getImgPath(this.props)} alt="ATAL" style={{maxHeight: '3.7rem', marginLeft: 'calc(6vw - 3.7rem)'}}></img>,
                <img src={require('../../assets/images/atal.png')} alt="ATAL" className="icon_image_atal"></img>
            ]
        }

        return (
            <div className='navbarContainer'>
                <nav className="flex items-center justify-between flex-wrap nav-bar z-10">
                    <button className="NavbarMenuIcon" onClick={this.MenuPick}>
                        <i className="fa fa-bars">
                        </i>
                    </button>
                    <ReactModal
                        isOpen={MenuDisplay}
                        contentLabel=""
                        onRequestClose={this.MenuPick}
                        className={"LeftHandSide_popup_menu_outside"}
                        id="LeftHandSide_popup_menu"
                        ariaHideApp={false}
                        closeTimeoutMS={500}
                    >
                        <Side history={history} key={"SideBody"} closefunction={this.MenuPick} level={currentlevel} levels={this.props.level} />
                    </ReactModal>
                    <div className="flex items-center flex-no-shrink text-white mr-6 atal_logo" onClick={this.HomeRedirect}>
                        {companyImagePath}
                    </div>
                    <p className="text-white choose_level_title" onClick={this.LevelPick}>
                    </p>
                    <div className="block flex-grow flex items-center nav_list">
                        <div className="text-sm flex-grow text-white">
                        </div>
                        <div className="flex flex-row-reverse">
                            <div className={"user_image_div" + Nav1Class} onClick={this.userinfoclick}>
                                {
                                    profileImage==null||profileImage==''?
                                    <div className='user_image_atal noImage'>
                                        <div className='fa fa-user'></div>
                                    </div>
                                    :
                                    <img src={/*"data:image/png;base64, " + */profileImage} alt="user" className="user_image_atal"></img>
                                }
                            </div>
                            <div className="navbar_notification_div" to="/Main/Notification" onClick={this.settingPopup}>
                                <div className={"fa fa-gear nav_icon" + Nav3Class}>
                                </div>
                            </div>
                            <div className="navbar_notification_div" to="/Main/Notification" onClick={this.notiPopup}>
                                <div className={"NotiBGEffect " + isShowAlarmCountClass}></div>
                                <div className={"fa fa-bell nav_icon" + Nav2Class}>
                                    <span className={"fa fa-circle numbercountbackgroundicon " + isShowAlarmCountClass + " " + ResizeAlarmCountBackgroundColor}></span>
                                    <span className={"num " + isShowAlarmCountClass}>{alarmCount}</span>
                                </div>
                            </div>
                            <div className="navbar_notification_div" onClick={this.refreshPage}>
                                <div className="fa fa-refresh nav_icon">
                                </div>
                            </div>
                            <div className="">
                                <Moment className="font-hairline text-white time_p" format="HH:mm">{DateString}</Moment>
                            </div>
                            <div className="">
                                <Moment className="font-hairline text-white date_p" format='DD-MMM-YYYY'>{DateString}</Moment>
                            </div>
                            <ConnectStatusWidget />
                            <SoundPlay ref={this.soundRef} />
                        </div>
                    </div>

                </nav>
                <ReactModal
                    isOpen={this.state.ShowNavMenu}
                    contentLabel=""
                    onRequestClose={this.closePopup}
                    className="nav_popup_Outsidemaindiv"
                    ariaHideApp={false}
                >
                    <NavPopUp input={this.state} closefunction={this.closePopup} NotificationViewAll={this.NotificationViewAll}
                        history={this.props.history} Logout={this.Logout} SiteList={this.props.SiteList} UserInfo={this.props.UserInfo} />
                </ReactModal>
                <RealTimeAlert onRef={ref => (this.RealTimeAlertRef = ref)} />
                <PopupNotification onRef={ref => { this.PopupNotificationRef = ref }} />
            </div>
        )
    }
}

//Redux inside component function
function mapStateToProps(state) {
    console.log(state)
    return {
        socket: state.socket,
        level: state.level,
        currentlevel: state.currentlevel,
        SiteList: state.SiteList,
        currentSite: state.currentSite,
        MenuDisplay: state.MenuDisplay,
        ShowLevelPopUp: state.ShowLevelPopUp,
        UserInfo: state.UserInfo,
        Notification: state.Notification,
        DeviceData: state.DeviceData,
        SystemAlerts: state.SystemAlerts,
        Polygons: state.Polygons,
        PickedPolygonLoc: state.PickedPolygonLoc,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate,
        StatisticData: state.StatisticData,
        DeviceDataStatus: state.DeviceDataStatus,
        UserManageUserList: state.UserManageUserList,
        configStorage: state.configStorage,
        DashboardPickedColdboxID: state.DashboardPickedColdboxID
    }
}

export default connect(mapStateToProps)(Navbar)

function getImgPath(props) {         //get path to site logo
    if(props == null) return
    const {configStorage, UserInfo} = props
    if(configStorage==null||UserInfo==null) return

    const {imgUrl} = configStorage
    const imgStr = UserInfo.locations[0].imgUrl
    return imgUrl + '/assets/images/floors/' + imgStr + '/logo.jpg'
}

const isColdbox = data => {
    if(data == null) return null
    const {client_id} = data
    if(client_id == null) return false
    if(client_id == '6006850161a378359cb738bf') return true
    return false
}
const isHighPriority = data => {
    if(data == null) return null
    const {severity} = data
    if(severity == null) return false
    if(severity == 'High') return true
    return false
}