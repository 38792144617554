import React from 'react'
import './NavPopUp.css'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoon, faMobile, faPalette, faCogs, faKey, faSignOutAlt, faGlobeAmericas } from '@fortawesome/free-solid-svg-icons'
import NotificationItem from './NotiItem'
import ResetPwd from './resetPwd/resetPwd'
import {getTxt, invertLang, LangBtnDisplay} from '../common/language/language'

class NavPopUp extends React.Component {
    constructor(props) {
        super(props)

        this.closePopup = this.closePopup.bind(this)
        this.NotificationViewAll = this.NotificationViewAll.bind(this)
        this.settingRedirect = this.settingRedirect.bind(this)
        this.Logout = this.Logout.bind(this)
        this.DarkMode = this.DarkMode.bind(this)
        this.NormalMode = this.NormalMode.bind(this)
        this.SetDarkMode = this.SetDarkMode.bind(this)
        this.invertLangBtn = this.invertLangBtn.bind(this)

        this.HelpCenter = this.HelpCenter.bind(this)

        this.PickColorTheme = this.PickColorTheme.bind(this)
        this.PickColorThemeInPopUp = this.PickColorThemeInPopUp.bind(this)

        this.ResetPwd = this.ResetPwd.bind(this)

        this.mobileRedirect = this.mobileRedirect.bind(this)

        this.FirstLoginChangePwd = this.FirstLoginChangePwd.bind(this)

        this.state = {
            showResetPwd: false,   //show and hide reset password popup
            displayMenu: 0,     //0 show nothing, 1 show color, 2 ??
            Colortheme: [],
            IsFirstLogin: false,
            forceUpdate: 0
        }
        try {         
            this.state = {
                Colortheme: this.props.configStorage.colorThemeList
            }
        }
        catch(err) {
            //* cannot read configure file when 'npm run start'
            console.error("cannot read configure file")
        }
    }

    componentDidMount() {
        this.FirstLoginChangePwd()
        //check if there is config data in redux(for color theme)
        let { configStorage } = this.props
        const _this = this
        try {
            if(configStorage == null || configStorage.ColorThemeList == null) return        //exception case
            //if yes, set the state to new color theme list
            _this.setState({
                Colortheme: configStorage.ColorThemeList
            })
        }
        catch(err) {
            console.log(err)
        }

    }
    FirstLoginChangePwd() {
        const {UserInfo} = this.props
        if(UserInfo == null || UserInfo.IsFirstLogin == false || UserInfo.IsFirstLogin == null) return      //no need trigger reset password popup
        this.setState({
            showResetPwd: true,
            IsFirstLogin: true
        })
    }

    ResetPwd() {
        this.setState({
            showResetPwd: !this.state.showResetPwd
        })
    }

    closePopup() {
        //trigger close function from parent component
        this.props.closefunction()
    }

    settingRedirect() {
        //redirect setting page
        this.props.history.push('/Main/Setting')
        //close the popup
        this.props.closefunction()
    }
    mobileRedirect() {
        //redirect setting page
        this.props.history.push('/Main/InstallMobileApp')
        //close the popup
        this.props.closefunction()
    }

    NotificationViewAll() {
        const _this = this
        const {UserInfo} = this.props
        if(UserInfo == null || UserInfo.permissions == null || UserInfo.permissions.length == null || UserInfo.permissions.length == 0) {
            //unexpected condition
            console.log('Side.js Notification() Unexpected condition')
        }
        else {
            const permission = UserInfo.permissions.find(function(item) {
                return item.collectionType == "Coldbox Manage" || item.collectionType == "Coldbox Location"
            })
            if(permission != null) {
                _this.props.history.push('/Main/Coldbox/Alarm')
                return
            }
        }

        this.props.NotificationViewAll()
    }

    Logout() {
        this.props.Logout()
    }

    DarkMode() {   
        //check the Localstorage 'DarkMode' is true / false
        let DarkMode = localStorage.getItem('DarkMode')
        if(DarkMode == "false") this.SetDarkMode()
        else this.NormalMode()
        
        this.props.dispatch({type: 'forceUpdate'})
    }
    SetDarkMode() {
        localStorage.setItem('DarkMode', true)
        var element = document.getElementById("root")
        element.classList.add("DarkMode")
        //since the background gradient is outside root, have to set the style manually
        const {configStorage} = this.props
        if(configStorage==null||configStorage.darkColorTheme==null) return  //exception condition
        document.documentElement.style.setProperty('--colortheme1', configStorage.darkColorTheme['--colortheme1'])
        document.documentElement.style.setProperty('--colortheme2', configStorage.darkColorTheme['--colortheme2'])
        console.log('set dark mode')
        document.documentElement.style.setProperty('--slideBG_1', configStorage.darkColorTheme['--slideBG_1'])
        document.documentElement.style.setProperty('--slideBG_2', configStorage.darkColorTheme['--slideBG_2'])
        document.documentElement.style.setProperty('--slideBG_3', configStorage.darkColorTheme['--slideBG_3'])
        document.documentElement.style.setProperty('--optiontext', 'white')
    }
    NormalMode() {
        //convert to Light Mode
        localStorage.setItem('DarkMode', false)
        var element = document.getElementById("root")
        element.classList.remove("DarkMode")

        //since the background gradient is outside root, have to set the style manually
        let ColorNavigationBar = localStorage.getItem('--colortheme1')
        let ColorBackground = localStorage.getItem('--colortheme2')

        //set to the css variable
        if(ColorNavigationBar != null && ColorNavigationBar != '')
        {
            document.documentElement.style.setProperty('--colortheme1', ColorNavigationBar)
            document.documentElement.style.setProperty('--colortheme2', ColorBackground)
        }
        document.documentElement.style.setProperty('--optiontext', 'black')
        //set normal mode
        console.log('set normal mode')
        //Navbar styling variable, since navbar slide is outside "#root"
        const {configStorage} = this.props
        if(configStorage==null||configStorage.darkColorTheme==null) return  //exception condition
        document.documentElement.style.setProperty('--slideBG_1', configStorage.darkColorTheme['--slideBG_1_Normal'])
        document.documentElement.style.setProperty('--slideBG_2', configStorage.darkColorTheme['--slideBG_2_Normal'])
        document.documentElement.style.setProperty('--slideBG_3', configStorage.darkColorTheme['--slideBG_3_Normal'])
    }
    invertLangBtn() {
        const {forceUpdate} = this.state
        this.setState({
            forceUpdate: forceUpdate+1
        })
        invertLang()
        this.props.dispatch({type: 'forceUpdate'})
    }

    HelpCenter() {
        this.props.history.push("/Main/ReportIssue")
    }

    PickColorTheme() {
        this.setState({
            displayMenu: 1  //1 is setting the color
        })
    }
    PickColorThemeInPopUp(e) {        
        if(e.target.id == null || e.target.id == undefined || e.target.id == "") {
            console.error('get OnClick ID Failed')
            return
        }
        //get id
        let RawId = e.target.id.replace('PickColor_', '')
        //get array count
        let Id_int = parseInt(RawId)
        //get the color theme data
        let ColorData = this.state.Colortheme[Id_int]
        //set to the css variable
        document.documentElement.style.setProperty('--focuscardbackground', ColorData.ColorHighlight)
        document.documentElement.style.setProperty('--colortheme1', ColorData.ColorNavigationBar)
        document.documentElement.style.setProperty('--colortheme2', ColorData.ColorBackground)
        document.documentElement.style.setProperty('--divheader', ColorData.ColorHeader)
        
        //set to the localstorage
        localStorage.setItem('--focuscardbackground', ColorData.ColorHighlight)
        localStorage.setItem('--colortheme1', ColorData.ColorNavigationBar)
        localStorage.setItem('--colortheme2', ColorData.ColorBackground)
        localStorage.setItem('--divheader', ColorData.ColorHeader)
        let DarkMode = localStorage.getItem('DarkMode')
        if(DarkMode != "false") this.SetDarkMode()
        else this.NormalMode()
    }

    render() {
        let {ShowNavType} = this.props.input
        let {UserInfo} = this.props
        const _this = this
        const {IsFirstLogin} = this.state

        if(UserInfo == null) return <div>Storage UserInfo is empty</div>

        if(ShowNavType == 1) {
            //console.log(UserInfo.locations>locName);
            //user info pop-up
            return [<div className="shadow-lg nav_popup_maindiv">
                <div>
                    {
                        UserInfo.profile==null||UserInfo.profile.image==null||UserInfo.profile.image==''?
                        <div className='NavpopupUserImage noImage'>
                            <div className='fa fa-user'></div>
                        </div>
                        :
                        <img src={UserInfo.profile.image} alt="user" className="NavpopupUserImage"></img>
                    }                
                </div>
                    <div className="NavbarPopupAccInfoMainDiv">
                        <div className="NavbarPopupUsername">
                            {UserInfo.profile.displayName}
                        </div>
                        <div className="NavPopupPermissionLevel">
                            <div>
                                {companyTxt(UserInfo.company)}
                            </div>
                            <div>
                                {accLvlTxt(UserInfo.userLevel)}
                            </div>
                        </div>
                        <div className="">
                            {UserInfo.profile.email}
                        </div>
                        <div className="NavPopupPermissionContainer">
                            <div className="navpopup_accessTxt">
                                {accessTitle()}
                            </div>
                            <div className="navpopup_accessList">
                                {                                    
                                    UserInfo.permissions.map(permissionItem => {
                                        if(permissionItem.collectionType == "Client" || permissionItem.collectionType == "Location") return <div></div>
                                        return <div className="NavPopupPermissionItem">
                                            {transPermissionType(permissionItem.collectionType)}
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                {
                    (IsFirstLogin)?
                    <div className="SideNavRowItem">
                        <FontAwesomeIcon icon={faKey} className="fa NavKeyIcon" />
                        <div>
                            {getTxt('RSide', 'changePwd')}
                        </div>
                    </div>
                    :
                    <div className="SideNavRowItem" onClick={this.ResetPwd}>
                        <FontAwesomeIcon icon={faKey} className="fa NavKeyIcon" />
                        <div>
                            {getTxt('RSide', 'changePwd')}
                        </div>
                    </div>
                }
                <div className="logout-most-bottom" onClick={this.Logout}>
                    <FontAwesomeIcon icon={faSignOutAlt} className="fa NavLogoutIcon" />
                    <div className="NavLogoutTxt">
                        {getTxt('RSide', 'logout')}
                    </div>
                </div>
            </div>,
            <ResetPwd showPopup={this.state.showResetPwd} ResetPwd={this.ResetPwd}/>
            ]
        }
        if(ShowNavType == 2) {
            //notification pop-up
            return <div className="shadow-lg nav_popup_maindiv">
            <div className="NavPopUpTitle">
                {getTxt('RSide', 'alarmTitle')}
            </div>
            <div className="NavPopupSubtitle">
                {getTxt('RSide', 'alarmDesc')}
            </div>
            <div className="NavbarPopupAccInfoMainDiv">
                <NotificationItem NotificationViewAll={_this.NotificationViewAll}/>
                <button className="navbar_notification_viewbutton" onClick={this.NotificationViewAll}>
                    {getTxt('RSide', 'view')}
                </button>
            </div>
        </div>
        }
        if(ShowNavType == 3) {
            //setting pop-up
            let colorthemeClass = "";
            if(this.state.displayMenu != 1) {
                colorthemeClass = "hide";
            }
            const {UserInfo} = this.props
            const permissions = UserInfo.permissions
            if(permissions == null || permissions.length == null || permissions.length == 0) return
            let isShowDownloadApp = true
            let isShowChangeLanguage = false
            permissions.forEach(PerItem => {
                if(PerItem.collectionType.includes('Coldbox')) isShowDownloadApp = false
                if(PerItem.collectionType.includes('WebsiteOnly')) isShowDownloadApp = false
                if(PerItem.collectionType.includes('Chinese')) isShowChangeLanguage = true
            })
            return <div className="nav_popup_maindiv">
                <div className="NavPopUpTitle">
                    {getTxt('RSide', 'setting')}
                </div>
                {
                    !isShowDownloadApp?<div style={{display: 'none'}}></div>
                    :
                    <div className="SideNavRowItem" onClick={this.mobileRedirect}>
                        <FontAwesomeIcon icon={faMobile} className="fa" />
                        <div className="SideNavRowItemText">
                            {getTxt('RSide', 'downApp')}
                        </div>
                    </div>
                }
                <div className="SideNavRowItem" onClick={this.settingRedirect}>
                    <FontAwesomeIcon icon={faCogs} className="fa" />
                    <div className="SideNavRowItemText">
                    {getTxt('RSide', 'advance')}
                    </div>
                </div>
                <div className="SideNavRowItem" onClick={this.PickColorTheme}>
                    <FontAwesomeIcon icon={faPalette} className="fa" />
                    <div className="SideNavRowItemText">
                    {getTxt('RSide', 'pickClr')}
                    </div>
                </div>
                <div className={"SideNavRowItem"} onClick={this.DarkMode}>
                    <FontAwesomeIcon icon={faMoon} className="fa" />
                    <div className="SideNavRowItemText">
                    {getTxt('RSide', 'darkMde')}
                    </div>
                </div>
                {
                    isShowChangeLanguage?
                    <div className={"SideNavRowItem"} onClick={this.invertLangBtn}>
                        <FontAwesomeIcon icon={faGlobeAmericas} className="fa" />
                        <div className="SideNavRowItemText">{LangBtnDisplay()}</div>
                    </div>
                    :
                    <div style={{display: 'none'}}></div>
                }
                <div className={"NavPopupPickColor " + colorthemeClass}>
                    {
                        this.state.Colortheme.map((result, i) => {
                            return <div className="shadow-lg ColorThemeRow" onClick={this.PickColorThemeInPopUp} id={"PickColor_" + i}>
                                <div className="ColorThemeRow_colorBox" style={{backgroundColor: result.ColorHighlight}} id={"PickColor_" + i}>
                                    {result.ColorHighlight}
                                </div>
                                <div className="ColorThemeRow_colorBox" style={{backgroundColor: result.ColorNavigationBar}} id={"PickColor_" + i}>
                                    {result.ColorNavigationBar}
                                </div>
                                <div className="ColorThemeRow_colorBox" style={{backgroundColor: result.ColorBackground}} id={"PickColor_" + i}>
                                    {result.ColorBackground}
                                </div>
                                <div className="ColorThemeRow_colorBox" style={{backgroundColor: result.ColorHeader}} id={"PickColor_" + i}>
                                    {result.ColorHeader}
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        }
        
        return <div className="shadow-lg nav_popup_maindiv"></div>
    }
}

function mapStateToProps(state) {
    return {
        UserInfo: state.UserInfo,
        MenuDisplay: state.MenuDisplay,
        Notification: state.Notification,
        configStorage: state.configStorage,
    }
}
export default connect(mapStateToProps)(NavPopUp)

const accessTitle = () => {
    if(getLang()=='eng') return 'Access level'
    return '帳戶權限'
}
function transPermissionType(permissionStr) {
    if(getLang()=='eng') return permissionStr

    switch(permissionStr) {
        case 'Logo':
            return '導航標誌'
        case 'Chinese':
            return '中文'
        case 'ClientLogo':
            return '客戶標誌'
        case 'Dashboard':
            return '主板'
        case 'DashboardTable':
            return '表格顯示'
        case 'Notifications':
            return '通知和警報'
        case 'WebsiteOnly':
            return '僅限網站'
    }
}
function accLvlTxt(lvlStr) {
    if(getLang()=='eng') return lvlStr

    switch(lvlStr) {
        case 'Client Admin':
            return '系統管理員'
        case 'Site Admin':
            return '管理人員'
        case 'Operators':
            return '操作員'
    }
}
function companyTxt(company) {
    if(getLang()=='eng') return company
    if(company=='Housing Authority') return '香港房屋委員會'

    return company
}
function getLang() {
    const langStr = localStorage.getItem('lang')
    if(langStr==null||langStr=='') {
        localStorage.setItem('lang', 'eng')
        return 'eng'
    }

    return langStr
}