import React from 'react'
import './tablehead.css'
//Redux Import
import { connect } from 'react-redux'

const HeaderKeyList = [
    {
        HeaderCount: 0,
        key: 'username'
    },
    {
        HeaderCount: 1,
        key: 'userLevel'
    },
    {
        HeaderCount: 2,
        key: 'action'
    },
    {
        HeaderCount: 3,
        key: 'actionDetail'
    }, 
    {
        HeaderCount: 4,
        key: 'createdTime'
    }, 
    {
        HeaderCount:5,
        key: 'remark'
    },
]
class TableHead extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            sortedList: null,
            sortOption: {
                key: 'id',
                value: true,        //true = Asec, false = Desc
                col: 0,
            },
        }

        this.OptnChange = this.OptnChange.bind(this)
        this.Sort = this.Sort.bind(this)
    }
    componentDidMount() {
        this.Sort()
    }
    componentDidUpdate(preProps) {
        const {filteredList} = this.props
        const oldfilteredList = preProps.filteredList
        if(filteredList != oldfilteredList || oldfilteredList == null && oldfilteredList != null) this.Sort()
    }
    OptnChange(keyStr) {
        const {sortOption} = this.state
        const {key, value} = sortOption
        let newsortOption = sortOption
        if(key == keyStr) {
            //doggle DESC/AESC
            newsortOption.value = !value
        }
        else {
            //Set new Key sorting
            newsortOption.key = keyStr
        }

        const ColCount = HeaderKeyList.findIndex((item) => item.key == keyStr)
        newsortOption.col = ColCount

        this.setState({
            sortOption: newsortOption
        }, function() {this.Sort()})
    }
    Sort() {
        const _this = this
        const {filteredList} = this.props
        const {sortOption} = this.state
        const {key, value, col} = sortOption

        var newFilteredList = filteredList
        if(filteredList == null || filteredList.length == null || filteredList  == undefined) return

        const SortOrder = value?1:-1
        newFilteredList = newFilteredList.sort(function(a, b) {
            let A_value = a[key]
            let B_value = b[key]
            if(A_value > B_value) return 1 * SortOrder
            if(A_value < B_value) return -1 * SortOrder
            return 0
        })
        // newFilteredList.forEach(item => {
        //     console.log(item[key])
        // })
        //update local state
        this.setState({
            sortedList: newFilteredList
        })
        //update parent's coldbox Filtered List (state)
        this.props.UpdateList('sortedList', null)
        setTimeout(function() {_this.props.UpdateList('sortedList', newFilteredList)}, 200)
    }
 
    render()
    {
        const {sortOption} = this.state
        const {key, value, col} = sortOption        //selected sort option values
        var arrowIcon = value?'sort-up':'sort-down'

        return <div className="CBAudit_tableHead">
            <div className={col==0?"col1 sortHighlight":"col1"} onClick={() => this.OptnChange(HeaderKeyList[0].key)}>
                User ID
                {
                    col==0?<div className={"fa fa-" + arrowIcon}></div>
                    :
                    <div style={{display: 'none'}}></div>
                }
            </div>
            <div className={col==1?"col2 sortHighlight":"col2"} onClick={() => this.OptnChange(HeaderKeyList[1].key)}>
            Type
                {
                    col==1?<div className={"fa fa-" + arrowIcon}></div>
                    :
                    <div style={{display: 'none'}}></div>
                }
            </div>
            <div className={col==2?"col2 sortHighlight":"col2"} onClick={() => this.OptnChange(HeaderKeyList[2].key)}>
            Action
                {
                    col==2?<div className={"fa fa-" + arrowIcon}></div>
                    :
                    <div style={{display: 'none'}}></div>
                }
            </div>
            <div className={col==3?"col2 sortHighlight":"col2"} onClick={() => this.OptnChange(HeaderKeyList[3].key)}>
           Action Detail
                {
                    col==3?<div className={"fa fa-" + arrowIcon}></div>
                    :
                    <div style={{display: 'none'}}></div>
                }
            </div> 
            <div className={col==5?"col2 sortHighlight":"col2"} onClick={() => this.OptnChange(HeaderKeyList[4].key)}>
             Trigger Time
                {
                    col==4?<div className={"fa fa-" + arrowIcon}></div>
                    :
                    <div style={{display: 'none'}}></div>
                }
            </div> 
            <div className={col==7?"col1 sortHighlight":"col1"} onClick={() => this.OptnChange(HeaderKeyList[5].key)}>
            Remark
                {
                    col==5?<div className={"fa fa-" + arrowIcon}></div>
                    :
                    <div style={{display: 'none'}}></div>
                }
            </div>
           
        </div>
    }
}

//Redux inside component function
function mapStateToProps(state) {
    return {

    }
}

export default connect(mapStateToProps)(TableHead)