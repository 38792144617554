import React from 'react';
import './Setting.css';

//Redux Import
import { connect } from 'react-redux';

import SettingPage1 from './SettingPage1';
import Demo3D from './3dDemo/demo3D';

class Setting extends React.Component {
    constructor(props)
    {
        super(props);

        this.state = {
            Colortheme: [
                {
                    ColorHighlight: '#F15A2D',
                    ColorNavigationBar: '#123342',
                    ColorBackground: '#FFFFFF',
                    ColorHeader: '#878683'
                },
                {
                    ColorHighlight: '#EE1F32',
                    ColorNavigationBar: '#EE1F32',
                    ColorBackground: '#F04B58',
                    ColorHeader: '#878683'
                },
                {
                    ColorHighlight: '#0297A7',
                    ColorNavigationBar: '#0297A7',
                    ColorBackground: '#7DCFD9',
                    ColorHeader: '#878683'
                },
                {
                    ColorHighlight: '#C05D3A',
                    ColorNavigationBar: '#C05D3A',
                    ColorBackground: '#D8B982',
                    ColorHeader: '#878683'
                },
                {
                    ColorHighlight: '#007460',
                    ColorNavigationBar: '#007460',
                    ColorBackground: '#41BDA6',
                    ColorHeader: '#034033'
                },
                {
                    ColorHighlight: '#F79035',
                    ColorNavigationBar: '#F79035',
                    ColorBackground: '#FCE754',
                    ColorHeader: '#0B8C87'
                },
                {
                    ColorHighlight: '#EF3D36',
                    ColorNavigationBar: '#EF3D36',
                    ColorBackground: '#F0E4B2',
                    ColorHeader: '#878683'
                },
                {
                    ColorHighlight: '#C56EAC',
                    ColorNavigationBar: '#C56EAC',
                    ColorBackground: '#CFE8D4',
                    ColorHeader: '#C56EAC'
                },
                {
                    ColorHighlight: '#c53211',
                    ColorNavigationBar: '#2e3830',
                    ColorBackground: '#e6dbc9',
                    ColorHeader: '#878683'
                }
            ],
        };

        try
        {
            //try to get the color theme from config file from 'assets/config.json'
            //** for compile, i.e. npm run start, config file is not readable
            this.state = {
                Colortheme: this.props.configStorage.ColorThemeList,
                SettingControlIsOn: false
            };
        }
        catch(err)
        {
            console.error('Get Config Color Failed');
            console.error(err);
        }

        this.changeColorTheme = this.changeColorTheme.bind(this);
        this.previouspage = this.previouspage.bind(this);

        this.OnOffControlClick = this.OnOffControlClick.bind(this);
    }

    componentDidMount()
    {
        //save current url to redux
		let pathUrl = "/Main/Setting";
		this.props.dispatch({ type: 'LatestPage', data: pathUrl });
    }

    changeColorTheme(e)
    {
        //console.log('change color theme function');
        //console.log(e.target.value);
        let number_selected = Number(e.target.value);
        let ColorHighlight = this.state.Colortheme[number_selected].ColorHighlight;
        //console.log(ColorHighlight);
        let ColorNavigationBar = this.state.Colortheme[number_selected].ColorNavigationBar;
        let ColorBackground = this.state.Colortheme[number_selected].ColorBackground;
        let ColorHeader = this.state.Colortheme[number_selected].ColorHeader;

        //set to the css variable
        document.documentElement.style.setProperty('--focuscardbackground', ColorHighlight);
        document.documentElement.style.setProperty('--colortheme1', ColorNavigationBar);
        document.documentElement.style.setProperty('--colortheme2', ColorBackground);
        document.documentElement.style.setProperty('--divheader', ColorHeader);

        //set to the localstorage
        localStorage.setItem('--focuscardbackground', ColorHighlight);
        localStorage.setItem('--colortheme1', ColorNavigationBar);
        localStorage.setItem('--colortheme2', ColorBackground);
        localStorage.setItem('--divheader', ColorHeader);

        //check if it is darkmode
        //check the Localstorage 'DarkMode' is true / false
        let DarkMode = localStorage.getItem('DarkMode');
        if(DarkMode != "false")
        {
            console.log('SetDarkMode()');
            this.SetDarkMode();
        }
        else
        {
            console.log('NormalMode()');
            this.NormalMode();
        }
    }
    SetDarkMode()
    {
        localStorage.setItem('DarkMode', true);
        var element = document.getElementById("root");
        element.classList.add("DarkMode");

        //since the background gradient is outside root, have to set the style manually
        document.documentElement.style.setProperty('--colortheme1', '#4e4e4e');
        document.documentElement.style.setProperty('--colortheme2', '#2e2e2e');
        document.documentElement.style.setProperty('--optiontext', 'white');

        this.setState({
            IsDark: true
        });
    }
    NormalMode()
    {
        //convert to Light Mode
        localStorage.setItem('DarkMode', false);
        var element = document.getElementById("root");
        element.classList.remove("DarkMode");

        //since the background gradient is outside root, have to set the style manually
        let ColorNavigationBar = localStorage.getItem('--colortheme1');
        let ColorBackground = localStorage.getItem('--colortheme2');

        //set to the css variable
        if(ColorNavigationBar != null && ColorNavigationBar != '')
        {
            document.documentElement.style.setProperty('--colortheme1', ColorNavigationBar);
            document.documentElement.style.setProperty('--colortheme2', ColorBackground);
        }
        document.documentElement.style.setProperty('--optiontext', 'black');

        this.setState({
            IsDark: false
        });
    }

    previouspage()
    {
        //redirect to previous page
        this.props.history.goBack();
    }

    componentDidCatch(error, info)
    {
        console.error('componentDidCatch() on Setting.js');
        console.error(error);
        console.error(info);
        //redirect to App.js to reload all the datas
        this.props.history.push("/App");
    }

    OnOffControlClick()
    {
        let {SettingControlIsOn} = this.state;
        this.setState({
            SettingControlIsOn: !SettingControlIsOn
        });
    }
    render() {
        let {SettingControlIsOn} = this.state;
        let OnOffState = "";
        let OnOffMarginLeft = "";
        if(SettingControlIsOn)
        {
            OnOffState = "OnOffState";
            OnOffMarginLeft = "OnOffMarginLeft";
        }
        return [<div className="Setting_div">
            <div className="fa fa-arrow-left setting_backwardBtn" onClick={this.previouspage}>
            </div>
            <div className="setting_title">
                Setting
            </div>,
            <SettingPage1/>,
            <Demo3D/>,
            </div>
    ]
    }
}

//Redux inside component function
function mapStateToProps(state) {
    return {
      count: state.count,
      log: state.log,
      socket: state.socket,
      level: state.level,
      currentSite: state.currentSite,
      MenuDisplay: state.MenuDisplay,
      configStorage: state.configStorage
    };
}

export default connect(mapStateToProps)(Setting);