import React, { useState } from 'react'
import './ConditionToilet.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToiletPaper, faPumpSoap, faInfoCircle, faTimesCircle, faExclamation, faCheck } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'

import getColorCode from './commonfunction'

function ConditionToiletWidget(props) {
    const {ToiletType} = props
    const [isOpenInfo, setisOpenInfo] = useState(false)
    const [HoverDetail, setHoverDetail] = useState(null)    //null means no hover to show detail, else 1 to 6, foreach card items

    const {color1, color2, gradient1, gradient2} = getColorCode(ToiletType)

    const LocationList = FilterLocationList(props)
    const TempAverage = CalAverage(LocationList, "temperature")
    const HumidAverage = CalAverage(LocationList, "humidity")
    const co2Average = CalAverage(LocationList, "co2")
    const pm25Average = CalAverage(LocationList, "pm2_5")
    const {AirIcon, CleanIcon, AirStr, CleanStr, L3_1, L3_2, L3_3, L3_4, L2_1, L2_2, L2_3, L2_4, leakage, flush, wastefull} = QualityLevel(LocationList)
    const {SoapAverage, SoapAvail, SoapTotal, PaperAverage, PaperAvail, PaperTotal} = GetPaperSoapAver(LocationList)

    return <div className="Toilet_Cond_main">
        <div className="Toilet_Cond_head">
            Condition of Toilet
            <div className="Toilet_Cond_infoBtn"style={{color: color2}}>
             <FontAwesomeIcon icon={faInfoCircle} className="fa" onClick={() => setisOpenInfo(true)} />
            </div>
        </div>
        <div className='Toilet_Cond_inner'>
            <div className="Toilet_Cond_item" onMouseOver={()=>setHoverDetail(4)} onMouseLeave={()=>setHoverDetail(null)}>
                <div className="Toilet_Cond_card fourth" style={{background: color2}}>
                    <div className="Toilet_progresscircle" style={{background: 'conic-gradient(from 0deg, #fff, #fff ' + PaperAverage*100 + '%, rgba(255, 255, 255, 0.4) ' + PaperAverage*100 + '%)'}}>
                        <div className="Toilet_progressIcon"style={{background: color2}}>
                            <FontAwesomeIcon icon={faToiletPaper} className="fa" />
                        </div>
                        {
                            HoverDetail==4?
                            <div className="detail">{PaperAvail + '/' + PaperTotal}</div>
                            :
                            <div className="detail_tick" key="detail_tick1" style={(PaperAvail==PaperTotal)?{color: 'rgb(0, 255, 34, 0.6)'}:{color: 'rgb(0, 0, 0, 0)'}}>
                                <FontAwesomeIcon icon={(PaperAvail==PaperTotal)?faCheck:faExclamation} className="fa" />
                            </div>
                        }
                    </div>
                </div>
                Toilet Paper
            </div>
            <div className="Toilet_Cond_item">
                <div className="Toilet_Cond_card first">
                    {TempAverage.toFixed(1)}
                    °
                </div>
                Temperature
            </div>
            <div className="Toilet_Cond_item" onMouseOver={()=>setHoverDetail(3)} onMouseLeave={()=>setHoverDetail(null)}>
                <div className="Toilet_Cond_card third">
                    <div className={AirIcon}></div>
                    {
                        HoverDetail==3?
                        <div className="detail2">
                            <div className="row">
                            CO<sub>2</sub> <div style={{width: '100%', textAlign: 'end'}}>{co2Average.toFixed(0)}</div>
                            </div>
                            <div className="row">
                            PM<sub>2.5</sub> <div style={{width: '100%', textAlign: 'end'}}>{pm25Average.toFixed(0)}</div>
                            </div>
                        </div>
                        :
                        <div style={{display: 'none'}}></div>
                    }
                </div>
                Air Quality
            </div>
            <div className="Toilet_Cond_item" onMouseOver={()=>setHoverDetail(5)} onMouseLeave={()=>setHoverDetail(null)}>
                <div className="Toilet_Cond_card fifth" style={{background: color2}}>
                    <div className="Toilet_progresscircle" style={{background: 'conic-gradient(from 0deg, #fff, #fff ' + SoapAverage*100 + '%, rgba(255, 255, 255, 0.4) ' + SoapAverage*100 + '%)'}}>
                        <div className="Toilet_progressIcon"style={{background: color2}}>
                            <FontAwesomeIcon icon={faPumpSoap} className="fa" />
                        </div>
                        {
                            HoverDetail==5?
                            <div className="detail">{SoapAvail + '/' + SoapTotal}</div>
                            :
                            <div className="detail_tick" key="detail_tick2" style={(SoapAvail==SoapTotal)?{color: 'rgb(0, 255, 34, 0.6)'}:{color: 'rgb(0, 0, 0, 0)'}}>
                                <FontAwesomeIcon icon={(SoapAvail==SoapTotal)?faCheck:faExclamation} className="fa" />
                            </div>
                        }
                    </div>
                </div>
                Soap Liquid
            </div>
            <div className="Toilet_Cond_item">
                <div className="Toilet_Cond_card second">
                    {HumidAverage.toFixed(1)}
                    <sup>%</sup>
                </div>
                Humidity
            </div>
            <div className="Toilet_Cond_item">
                <div className="Toilet_Cond_card sixth">
                    <div className={CleanIcon}></div>
                    {/* {CleanStr} */}
                </div>
                Cleanliness
            </div>
        </div>
        {
            isOpenInfo?
            [
            <div className="Toilet_Cond_info">
                <dataset>
                    <legend>Air Quality</legend>
                    <div className="level">
                        Level 3: Very Good
                        <div className="Toilet_Cond_info_airQ_3"></div>
                        <div className="desc">
                            <div style={{color: L3_1?color2:''}}>
                                Temperature: between 23 - 25C
                            </div>
                            <div style={{color: L3_2?color2:''}}>
                                Humidity:    between 35 - 45%
                            </div>
                            <div style={{color: L3_3?color2:''}}>
                                CO2:         less than 1200
                            </div>
                            <div style={{color: L3_4?color2:''}}>
                                PM2.5:       less than 1.6
                            </div>
                        </div>
                    </div>
                    <div className="level">
                        Level 2: Good
                        <div className="Toilet_Cond_info_airQ_2"></div>
                        <div className="desc">
                            <div style={{color: L2_1?color2:''}}>
                                Temperature: between 21 - 27C
                            </div>
                            <div style={{color: L2_2?color2:''}}>
                                Humidity:    between 30 - 50%
                            </div>
                            <div style={{color: L2_3?color2:''}}>
                                CO2:         less than 1600
                            </div>
                            <div style={{color: L2_4?color2:''}}>
                                PM2.5:       less than 2
                            </div>
                        </div>
                    </div>
                    <div className="level">
                        Level 1: Normal
                        <div className="Toilet_Cond_info_airQ_1"></div>
                        <div className="desc">
                            Some IAQ requirements are not reached.
                        </div>
                    </div>
                </dataset>
                <dataset>
                    <legend>Cleanliness</legend>
                    <div className="level">
                        Level 3: Very Good
                        <div className="Toilet_Cond_info_clean_3"></div>
                        <div className="desc">
                            <div style={{color: leakage?'':color2}}>
                                No water Leakage
                            </div>
                            <div style={{color: flush?'':color2}}>
                                No flushing water
                            </div>
                            <div style={{color: wastefull?'':color2}}>
                                Waste Bin is available
                            </div>
                        </div>
                    </div>
                    <div className="level">
                        Level 2: Good
                        <div className="Toilet_Cond_info_clean_2"></div>
                        <div className="desc">
                            2 or less clean issues are detected:<br></br>
                            <div style={{color: leakage?'red':''}}>
                                Water Leakage
                            </div>
                            <div style={{color: flush?'red':''}}>
                                Flushing water
                            </div>
                            <div style={{color: wastefull?'red':''}}>
                                Waste Bin is full
                            </div>
                        </div>
                    </div>
                    <div className="level">
                        Level 1: Normal
                        <div className="Toilet_Cond_info_clean_1"></div>
                        <div className="desc">
                            more than 2 issues are detected:<br></br>
                            <div style={{color: leakage?'red':''}}>
                                Water Leakage
                            </div>
                            <div style={{color: flush?'red':''}}>
                                Flushing water
                            </div>
                            <div style={{color: wastefull?'red':''}}>
                                Waste Bin is full
                            </div>
                        </div>
                    </div>
                </dataset>
            </div>,
            <div className="Toilet_Cond_closeBtn" onClick={() => setisOpenInfo(false)}>
                <FontAwesomeIcon icon={faTimesCircle} className="fa" />
            </div>
            ]
            :
            <div style={{display: 'none'}}></div>
        }
    </div>
}

function mapStateToProps(state) {
    return {
        ToiletType: state.ToiletType,
        DeviceData: state.DeviceData,
        currentSite: state.currentSite,
        currentlevel: state.currentlevel,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate,
    }
}

export default connect(mapStateToProps)(ConditionToiletWidget)


//w = 4
//h = 8

//Get Relative Location items
function FilterLocationList(props) {
    const {DeviceData, currentSite, currentlevel, ToiletType} = props
    if(DeviceData == null || currentlevel == null || currentSite == null) return

    const SiteItem = DeviceData[currentSite]
    if(SiteItem == null) return
    const LevelItem = SiteItem.nestedLocs.find(item => {
        return item.locName.toString() == currentlevel.toString()
    })
    if(LevelItem == null) return
    const ReturnLocationList = LevelItem.nestedLocs.filter(item => {
        if(item.payload == null || item.payload_definition_id == null || item.payload.toiletType == null) return false
        return item.payload.toiletType.toString() == ToiletType.toString()
    })
    return ReturnLocationList
}

function CalAverage(LocationList, payloadKeystr) {
    if(LocationList == null) return 0
    const FilteredList = LocationList.filter(item => {
        if(item.payload == null) return false

        return item.payload[payloadKeystr] != null
    })
    if(FilteredList == null || FilteredList.length == null || FilteredList.length == 0) return 0

    const Total = FilteredList.length
    let Sum = 0
    FilteredList.forEach(item => {
        Sum += item.payload[payloadKeystr]
    })
    const Result = Sum/Total
    return Result
}
function TrueTotal(LocationList, payloadKeystr) {
    if(LocationList == null) return 0
    const FilteredList = LocationList.filter(item => {
        if(item.payload == null) return false

        return item.payload[payloadKeystr] != null
    })
    if(FilteredList == null || FilteredList.length == null || FilteredList.length == 0) return 0

    // const Total = FilteredList.length
    let Sum = 0
    FilteredList.forEach(item => {
        if(payloadKeystr == "flushingWater") {
            //when flusing is false, +1
            if(item.payload[payloadKeystr] == false || item.payload[payloadKeystr].toString() == 'false' || item.payload[payloadKeystr].toString() == "FALSE") Sum += 1
        }
        else if(item.payload[payloadKeystr] == true || item.payload[payloadKeystr].toString() == 'true' || item.payload[payloadKeystr].toString() == "TRUE") {
            Sum += 1
        }
    })
    return Sum
    // const Result = Sum/Total * 100
    // return Result
}

//right 2 level items
function QualityLevel(LocationList) {
    //Air Q
    const temp = CalAverage(LocationList, 'temperature')
    const humid = CalAverage(LocationList, 'humidity')
    const co2 = CalAverage(LocationList, 'co2')
    const pm2_5 = CalAverage(LocationList, 'pm2_5')

    let AirQ_score = 2

    let ScoreDetail = [false, false, false, false, false, false, false, false]
    if(temp > 23 && temp < 25) {
        AirQ_score += 1
        ScoreDetail[0] = true
    }
    if(temp > 20 && temp < 28) {
        AirQ_score += 1
        ScoreDetail[1] = true
    }

    if(humid > 35 && humid < 45) {
        AirQ_score += 1
        ScoreDetail[2] = true
    }
    if(humid > 30 && humid < 50) {
        AirQ_score += 1
        ScoreDetail[3] = true
    }

    if(co2 < 1200) {
        AirQ_score += 1
        ScoreDetail[4] = true
    }
    if(co2 < 1400) {
        AirQ_score += 1
        ScoreDetail[5] = true
    }

    if(pm2_5 < 1.8) {
        AirQ_score += 1
        ScoreDetail[6] = true
    }
    if(pm2_5 < 2) {
        AirQ_score += 1
        ScoreDetail[7] = true
    }

    let AirQ_level
    if(AirQ_score <= 4) AirQ_level = 1
    else if(AirQ_score <= 7) AirQ_level = 2
    else AirQ_level = 3


    //Cleaniness
    const Flush = TrueTotal(LocationList, "flushingWater")
    const Leakage = TrueTotal(LocationList, "waterLeakageStatus")
    const Bin = TrueTotal(LocationList, "isFull")
    const totalIssues = Flush + Leakage + Bin

    let Clean_Level
    if(totalIssues == 0) Clean_Level = 3
    else if(totalIssues <= 2) Clean_Level = 2
    else Clean_Level = 1

    const {AirIcon, CleanIcon} = LevelIcons(AirQ_level, Clean_Level)
    return {
        AirIcon: AirIcon,      //1 lowest, 3 highest
        CleanIcon: CleanIcon,  //1 lowest, 3 highest
        AirStr: LevelStr(AirQ_level),
        CleanStr: LevelStr(Clean_Level),

        L3_1: ScoreDetail[0],
        L3_2: ScoreDetail[2],
        L3_3: ScoreDetail[4],
        L3_4: ScoreDetail[6],
        L2_1: ScoreDetail[1],
        L2_2: ScoreDetail[3],
        L2_3: ScoreDetail[5],
        L2_4: ScoreDetail[7],

        leakage: Leakage,
        flush: Flush,
        wastefull: Bin,
    }
}
function LevelIcons(AirQ_level, Clean_Level) {
    let AirIcon, CleanIcon
    switch (AirQ_level) {
        case 1:
            AirIcon = "airQ_1"
            break
        case 2:
            AirIcon = "airQ_2"
            break
        case 3:
            AirIcon = "airQ_3"
            break
    }
    switch (Clean_Level) {
        case 1:
            CleanIcon = "Clean_1"
            break
        case 2:
            CleanIcon = "Clean_2"
            break
        case 3:
            CleanIcon = "Clean_3"
            break
    }
    return {
        AirIcon: AirIcon,
        CleanIcon: CleanIcon,
    }
}
const LevelList = ['Normal', 'Good', 'Very Good']
function LevelStr(inputIndex) {
    return LevelList[inputIndex - 1]
}

//bottom paper and soap
function GetPaperSoapAver(LocationList) {
    const EmptyReturn = {
        SoapAverage: 0,
        PaperAverage: 0,
    }
    if(LocationList == null || LocationList.length == null || LocationList.length == 0) return EmptyReturn
    const RelatedList = LocationList.filter(item => {
        if(item.payload == null) return false

        const {PaperLevel, hasPaper, soapLevel, hasSoap} = item.payload
        return PaperLevel!=null || hasPaper!=null || soapLevel!=null || hasSoap!=null
    })

    if(RelatedList == null || RelatedList.length == null || RelatedList.length == 0) return EmptyReturn
    let soapSum = 0
    let PaperSum = 0
    RelatedList.forEach(item => {
        const {PaperLevel, hasPaper, soapLevel, hasSoap} = item.payload
        if(hasSoap == false) soapSum += 0
        else if(hasSoap == true) soapSum += 1
        if(hasPaper == false) PaperSum += 0
        else if(hasPaper == true && PaperLevel != null) PaperSum += 1
    })
    const SoapList = LocationList.filter(item => {
        if(item.payload == null) return false
        const {PaperLevel, hasPaper, soapLevel, hasSoap} = item.payload
        return soapLevel!=null || hasSoap!=null
    })
    const PaperList = LocationList.filter(item => {
        if(item.payload == null) return false
        const {PaperLevel, hasPaper, soapLevel, hasSoap} = item.payload
        return PaperLevel!=null || hasPaper!=null
    })
    const SoapAverage = soapSum/SoapList.length
    const PaperAverage = PaperSum/PaperList.length

    return {
        SoapAverage: SoapAverage,
        SoapAvail: soapSum,
        SoapTotal: SoapList.length,
        PaperAverage: PaperAverage,
        PaperAvail: PaperSum,
        PaperTotal: PaperList.length,
    }
}