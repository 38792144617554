import React from 'react'
import './SiteDisplay.css'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCompass, faBuilding } from '@fortawesome/free-solid-svg-icons'
import {getTxt} from '../../common/language/language'

class SiteDisplay extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            detailCard: false,
            pickSite: false,
        }
        
        this.togglecard = this.togglecard.bind(this)             //toggle the Site card for more details, also included pick level function
        this.pickSitePopup = this.pickSitePopup.bind(this)       //show / hide pick site popup
        this.pickSiteFunction = this.pickSiteFunction.bind(this) //pick site function
        this.cancelFunction = this.cancelFunction.bind(this)     //cancel btn
    }
    togglecard(event) {
        const _this = this
        if(event == null || event.target == null || event.target.id == null || event.target.id == "") {
            //not click levelbtn
        }
        else {
            //pick level
            const id_str = event.target.id
            let split_id = id_str.split('levelBtn_')
            if(split_id != null && split_id.length == null && split_id.length <= 1) {
                //exception
            }
            else {
                //update redux value by reducer function
                const level_str = split_id[1]
                _this.props.dispatch({ type: 'PickLevel', data: level_str })
                if (_this.props.UserInfo.client_id === "6006850161a378359cb738bf") { //reset coldbox dashboard data
                    _this.props.dispatch({
                        type: "DashboardPickedColdboxID",
                        data: ""
                    });
        
                    _this.props.dispatch({
                        type: 'PickPolygon',
                        data: "" //string of locName, highlight the polygon inside Map.js
                    })
                }
            }
        }

        //open / close detail
        const {detailCard} = this.state
        this.setState({
            detailCard: !detailCard,
            pickSite: false,
        })
    }
    pickSitePopup() {
        const {pickSite} = this.state
        this.setState({
            pickSite: !pickSite
        })
    }
    pickSiteFunction(event) {
        const {UserInfo} = this.props

        if(event == null || event.target == null || event.target.id == null) return

        try {
            const siteID = event.target.id
            let LevelList = []
            //get level list of that site
            if(UserInfo == null) {
                console.error('this.props.UserInfo == null')
                return
            }
            UserInfo.locations.map((data, i) => {
                let locIdx_int = parseInt(data.locIdx)
                let siteID_int = parseInt(siteID) + 1
                if(locIdx_int == siteID_int) LevelList = data.nestedLocs
                return null
            })
            //send the redux function
            this.props.dispatch({ type: 'PIckSite', data: {
                level: LevelList,
                Siteid: siteID
            }})
        }
        catch(err) {
            console.log(err)
        }

        this.setState({
            detailCard: false,
            pickSite: false,
        })
    }
    cancelFunction() {
        this.setState({
            detailCard: false,
            pickSite: false,
        })
    }

    render() {
        let img_src = "https://image.freepik.com/free-vector/skyscrapers-with-illuminated-windows_23-2147510845.jpg"   //this is the default image
        let SiteName = "..."           //this is the default Site Name
        // let SiteDetail = ".."
        let levelList = []
        const {SiteList, currentSite, UserInfo, currentlevel, configStorage} = this.props
        const _this = this
        if(UserInfo == null) return <div className="SiteWidget_div"></div>
        try {
            // img_src = UserInfo.locations[currentSite].img;
            img_src = configStorage.imgUrl + '/assets/images/floors/' +  UserInfo.locations[currentSite].imgUrl + '/default.jpg'
            SiteName = UserInfo.locations[currentSite].locName
            // SiteDetail = UserInfo.locations[currentSite].locDesc
            if(UserInfo.locations[currentSite].nestedLocs != null || UserInfo.locations[currentSite].nestedLocs.length != null) {
                levelList = UserInfo.locations[currentSite].nestedLocs
                levelList = levelList.sort(function(a, b) {
                    return a.locName - b.locName
                })
            }
        }
        catch(err) {
            console.log(err);
        }

        const {detailCard, pickSite} = this.state
        return  <div className="SiteWidget_div" style={{backgroundImage: 'url(' + img_src + ')'}}>
            <div className={(detailCard || pickSite)?"SiteWidget_header SiteWidget_detail":"SiteWidget_header"}>
                {
                    (!detailCard && !pickSite)?
                    <div>
                        <div className="SiteWidget_row" onClick={this.pickSitePopup}>
                            <div className="SiteWidget_sitedisplay">
                                {SiteName}
                            </div>
                            <div className="SiteWidget_sitePickBtn">
                                <FontAwesomeIcon icon={faCompass} className="fa" />
                            </div>
                        </div>
                        <div className="SiteWidget_row" onClick={this.togglecard}>
                            <div className="SiteWidget_leveldisplay">
                                {currentlevel + '/F'}
                            </div>
                            <div className="SiteWidget_siteLevelBtn">
                                <FontAwesomeIcon icon={faBuilding} className="fa" />
                            </div>
                        </div>
                    </div>
                    :
                    <div className="SiteWidget_cancelBtn" onClick={_this.cancelFunction}>x</div>
                }
                {
                    (detailCard)?<div className="SiteWidget_levelList">
                        <div className="SiteWidget_pickSiteTitle">{getTxt('Statistic', 'pickLvl')}</div>
                        {
                            levelList.map(function(levelItem) {
                                let levelStr = levelItem.locName.toString()
                                return <div className={(levelStr == currentlevel)?"SiteWidget_levelItem pickedlevel":"SiteWidget_levelItem"} id={"levelBtn_" + levelStr}  onClick={_this.togglecard}>
                                    {levelStr}
                                </div>
                            })
                        }
                    </div>:
                    <div></div>
                }
                {
                    (pickSite)?<div className="SiteWidget_siteList">
                        <div className="SiteWidget_pickSiteTitle">{getTxt('Statistic', 'pickSite')}</div>
                        {
                            UserInfo.locations.map(function(siteItem, i) {
                                return <div className={(siteItem.locName==SiteName)?"SiteWidget_siteItem pickedSite":"SiteWidget_siteItem"} value={i} id={i} onClick={_this.pickSiteFunction}>
                                    {siteItem.locName}
                                </div>
                            })
                        }
                    </div>
                    :
                    <div></div>
                }
            </div>
        </div>        
    }
}

function mapStateToProps(state) {
    return {
      currentlevel: state.currentlevel,
      currentSite: state.currentSite,
      SiteList: state.SiteList,
      UserInfo: state.UserInfo,
      configStorage: state.configStorage,
      DeviceDataLastUpdate: state.DeviceDataLastUpdate
    }
}
export default connect(mapStateToProps)(SiteDisplay)