import React from 'react'
import './coldboxStatus.css'
import Loading from '../../loading';
//Redux Import
import { connect } from 'react-redux'
 
//
class ColdboxStatus extends React.Component {
    constructor(props) {
        super(props)
        this.renderColdboxStatus = this.renderColdboxStatus.bind(this)
        this.renderAlarmSetPoint = this.renderAlarmSetPoint.bind(this)
        this.renderAlarmList = this.renderAlarmList.bind(this)
        this.renderRow = this.renderRow.bind(this)
        this.state = {
            isLoading: false,
            payloadData: {},
            item: {},
            CurrentID: "",
            CurrentLevel: ""
        };

    }

    renderRow(title, value, unit) {
        return (
            <div className="Statusbox_row">
                <div className="Statusbox_txt">
                    {title}
                </div>
                <div className="Statusbox_Value">
                    {value} {(unit === "C" ? <sup>o</sup> : unit)}{(unit === "C" ? unit : "")}
                </div>
            </div>
        )
    }

    renderAlarmSetPoint(ColdBoxItem) {
        let noData = false
        if(ColdBoxItem === undefined ||
           ColdBoxItem.payload === undefined){
               noData = true
           }
        return (

            <fieldset >
             <legend>Alarm SetPoint</legend>  
            <div className="ColdboxStatus_Statusbox">
                {/* <div className="ColdboxStatus_BoxTitle">Alarm SetPoint</div> */}
                <div className="AlarmSetPointbox_Col">
                    {this.renderRow("Temperature", (noData? "" :ColdBoxItem.payload.TempSetPoint), "C")}
                    {this.renderRow("High Temperature Warning", (noData? "" :ColdBoxItem.payload.TempHighSetPoint), "C")}
                    {this.renderRow("Low Temperature Warning", (noData? "" :ColdBoxItem.payload.TempLowSetPoint), "C")}
                    {this.renderRow("High Suction Temperature Warning", (noData? "" :ColdBoxItem.payload.SuctionTempWarningHighSetPoint), "C")}
                    {this.renderRow("High RH Warning", (noData? "" :ColdBoxItem.payload.RHWarningHighSetPoint), "%")}
                    {this.renderRow("Low RH Warning",(noData? "" : ColdBoxItem.payload.RHWarningLowSetPoint), "%")}
                    {this.renderRow("High Condensing Pressure Warning",(noData? "" : ColdBoxItem.payload.CondensingPressureWarningHighSetPoint), "Bar")}
                    {this.renderRow("Low Evaporating Pressure Warning", (noData? "" :ColdBoxItem.payload.EvaporatingPressureWarningLowSetpoint), "Bar")}

                </div>
 
            </div>

            </fieldset > 
        )
    }

    renderColdboxStatus(ColdBoxItem) {
        const { UserInfo, currentSite, currentlevel, DeviceData, DashboardPickedColdboxID } = this.props 
        let noData = false
         if(ColdBoxItem === undefined ||
            ColdBoxItem.payload === undefined){
                noData = true
            }
        return (
             <fieldset >
             <legend>Status</legend>  
            <div className="ColdboxStatus_Statusbox"> 
                {/* <div className="ColdboxStatus_BoxTitle">Status</div> */}
                <div className="Statusbox_Col">
                    {this.renderRow("Mean Weighted Temp",(noData? "" :ColdBoxItem.payload.WeightedMeanTemp) , "C")}
                    {this.renderRow("Temperature Suction",(noData? "" :ColdBoxItem.payload.TempSuction) , "C")}
                    {this.renderRow("Temperature Sensor 1",(noData? "" :ColdBoxItem.payload.Temp1) , "C")}
                    {this.renderRow("Temperature Sensor 2", (noData? "" :ColdBoxItem.payload.Temp2), "C")}
                    {this.renderRow("Temperature Sensor 3", (noData? "" :ColdBoxItem.payload.Temp3), "C")}
                    {this.renderRow("Temperature Sensor 4", (noData? "" :ColdBoxItem.payload.Temp4), "C")}
                    {this.renderRow("Temperature Sensor 5", (noData? "" :ColdBoxItem.payload.Temp5), "C")}
                    {this.renderRow("Pressure Condensing", (noData? "" :ColdBoxItem.payload.PressureCondensing), "Bar")}
                    {this.renderRow("Pressure Evaporating", (noData? "" :ColdBoxItem.payload.PressureEvaporating), "Bar")}
                    {this.renderRow("Expansion Valve Opening", (noData? "" :ColdBoxItem.payload.ExpansionValveOpening), "%")}
                </div>
                <div className="Statusbox_Col">
                    {this.renderRow("Battery Capacity", (noData? "" :ColdBoxItem.payload.UPSBatteryCapacity), "%")}
                    {this.renderRow("RH", (noData? "" :ColdBoxItem.payload.RH), "%")}
                    {this.renderRow("Light", (noData? "" :(ColdBoxItem.payload.Light === true ? "On" : "Off")), "")}
                    {this.renderRow("Running Hr", (noData? "" :ColdBoxItem.payload.Runhrs), "hrs")}
                    {this.renderRow("Door Position", (noData? "" :ColdBoxItem.payload.DoorPosition=== false ? "Open" : "Closed"), "")}
                    {this.renderRow("Defrost Heater", (noData? "" :ColdBoxItem.payload.DefrostHeater=== true ? "On" : "Off"), "")}
                    {this.renderRow("Compressor Fan", (noData? "" :ColdBoxItem.payload.CompressorFan=== true ? "Run" : "Stop"), "")}
                    {this.renderRow("Evaporator Fan", (noData? "" :ColdBoxItem.payload.EvaporatorFan === true ? "Run" : "Stop"), "")}
                    {this.renderRow("Sys Start/Stop", (noData? "" :ColdBoxItem.payload.SystemStart === true ? "Start" : "Stop"), "")}
                    {this.renderRow(" Sys Running Status", (noData? "" :ColdBoxItem.payload.SystemRunningStatus === true ? "On" : "Off"), "")}
                </div>
            </div>
            </fieldset > 
        )
    }

    renderAlarmList(ColdBoxItem) {
        let noData = false
        if(ColdBoxItem === undefined ||
            ColdBoxItem.payload === undefined){
                noData = true
            }
        return (
            <fieldset >
             <legend>Alarm</legend>  
            <div className="ColdboxStatus_Statusbox">
                {/* <div className="ColdboxStatus_BoxTitle">Alarm</div> */}
                <div className="AlarmSetPointbox_Col">
                    {this.renderRow("General Alarm", (noData? "" :ColdBoxItem.payload.GeneralAlarm === false ? "Normal" : "Fault"), "")}
                    {this.renderRow("Alarm (HMI)", (noData? "" :ColdBoxItem.payload.HMIAlarm === false ? "Normal" : "Fault"), "")}
                    {this.renderRow("High Pressure Cutout", (noData? "" :ColdBoxItem.payload.HighPressureCutout === false ? "Normal" : "Fault"), "")}
                    {this.renderRow("Low Pressure Cutout", (noData? "" :ColdBoxItem.payload.LowPressureCutout=== false ? "Normal" : "Fault"), "")}
                    {this.renderRow("Fan Motor Fault", (noData? "" :ColdBoxItem.payload.CompressorMotorFault=== false ? "Normal" : "Fault"), "")}
                    {this.renderRow("Compressor Motor Fault", (noData? "" :ColdBoxItem.payload.FanMotorFault === false ? "Normal" : "Fault"), "")}
                    {this.renderRow("Compressor Not Run Alarm Upon Command issued", (noData? "" :ColdBoxItem.payload.CompressorNotRunFault === false ? "Normal" : "Fault"), "")}
                    {this.renderRow("Evaporstor Fan Not Run Alarm Upon Command issued", (noData? "" :ColdBoxItem.payload.EvaporatorFanNotRunFault === false ? "Normal" : "Fault"), "")}
                    {this.renderRow("High Temperature Alarm", (noData? "" :ColdBoxItem.payload.ColdboxHighTemperatureAlarm === false ? "Normal" : "Fault"), "")}
                    {this.renderRow("Low Temperature Alarm", (noData? "" :ColdBoxItem.payload.ColdboxLowTemperatureAlarm === false ? "Normal" : "Fault"), "")}
                    {this.renderRow("Temperature Sensor 1 Fault",(noData? "" : ColdBoxItem.payload.TempSensor1Fault === false ? "Normal" : "Fault"), "")}
                    {this.renderRow("Temperature Sensor 2 Fault",(noData? "" : ColdBoxItem.payload.TempSensor2Fault === false ? "Normal" : "Fault"), "")}
                    {this.renderRow("Temperature Sensor 3 Fault", (noData? "" :ColdBoxItem.payload.TempSensor3Fault === false ? "Normal" : "Fault"), "")}
                    {this.renderRow("Temperature Sensor 4 Fault", (noData? "" :ColdBoxItem.payload.TempSensor4Fault === false ? "Normal" : "Fault"), "")}
                    {this.renderRow("Temperature Sensor 5 Fault", (noData? "" :ColdBoxItem.payload.TempSensor5Fault === false ? "Normal" : "Fault"), "")}
                    {this.renderRow("RH Sensor Fault (over-range)",(noData? "" : ColdBoxItem.payload.RHSensorFault === false ? "Normal" : "Fault"), "")}
                    {this.renderRow("Condensing Pressure Sensor Fault (over-range)",(noData? "" : ColdBoxItem.payload.CondensingPressureSensorFault === false ? "Normal" : "Fault"), "")}
                    {this.renderRow("Evaporating Pressure Sensor Fault (over-range)",(noData? "" :ColdBoxItem.payload.EvaporatingPressureSensorFault === false ? "Normal" : "Fault"), "")}
                    {this.renderRow("Defrost Heater Fault (not run upon command issued)",(noData? "" : ColdBoxItem.payload.DefrostHeaterFault === false ? "Normal" : "Fault"), "")}
                    {/* no defrostheatfault item //.. */}
                    {this.renderRow("Expansion Valve Controller Fault", (noData? "" :ColdBoxItem.payload.ExpansionValveControllerFault === false ? "Normal" : "Fault"), "")}

                </div> 
            </div>
            </fieldset > 
        )
    }  

    render() {
        const { UserInfo, currentSite, currentlevel, DeviceData, DashboardPickedColdboxID, currentBottomCard } = this.props

        let ColdBoxItem = null
        //console.log(this.props)
        if (DeviceData == null || UserInfo == null || DeviceData[currentSite] == null) return <Loading />
        const CurrentFloorItem = DeviceData[currentSite].nestedLocs.find(function (floorItem) { return floorItem.locName == currentlevel })
        if (CurrentFloorItem == null) return <Loading />
        if (this.state.isLoading === true) return <Loading />

        try {
            if (CurrentFloorItem.nestedLocs !== null && CurrentFloorItem.nestedLocs !== undefined) {
                ColdBoxItem = GetSelectedColdboxItem(CurrentFloorItem, currentBottomCard)
            }
        }
        catch (err) {
            console.log('4 reading Summary error');
            console.log(err);
        }
        // check if there are missing Temperature, Humidity, CO2, or PM25 reading, if missing then hide that row
        // console.log("Status render")
        // console.log(ColdBoxItem)
        if (ColdBoxItem === null ){
            // || ColdBoxItem ==={} || ColdBoxItem === undefined  || ColdBoxItem.payload === undefined) {
            return <Loading />
        } else {
            return <div className="ColdboxStatus">
                 <div className="ColdboxStatus_header">
                    Coldbox {(ColdBoxItem === null|| ColdBoxItem === {}|| ColdBoxItem === undefined? "" : ColdBoxItem.locName)}
                </div>
               <div className="ColdboxStatus_innerscroll">
                    {this.renderColdboxStatus(ColdBoxItem)}
                    <br />
                    {this.renderAlarmSetPoint(ColdBoxItem)}
                    <br />
                    {this.renderAlarmList(ColdBoxItem)}
                </div>
            </div>
        }
    }
}

function mapStateToProps(state) {
    return {
        currentlevel: state.currentlevel,
        currentSite: state.currentSite,
        UserInfo: state.UserInfo,
        DeviceData: state.DeviceData,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate,
        DashboardPickedColdboxID: state.DashboardPickedColdboxID,
        currentBottomCard: state.currentBottomCard,
    }
}
export default connect(mapStateToProps)(ColdboxStatus)

function GetSelectedColdboxItem(CurrentFloorItem, currentBottomCard) {
    const coldboxList = CurrentFloorItem.nestedLocs

    const splitIDStr = currentBottomCard.split('bottomcard_')
    const cardIDStr = splitIDStr==null||splitIDStr.length==0?'0':splitIDStr[1]
    const cardID = parseInt(cardIDStr)

    // console.log({
    //     CurrentFloorItem: CurrentFloorItem,
    //     currentBottomCard: currentBottomCard,
    //     coldboxList: coldboxList,
    //     splitIDStr: splitIDStr,
    //     cardIDStr: cardIDStr,
    //     cardID: cardID,
    //     return: coldboxList[cardID]
    // })

    return coldboxList[cardID]
}