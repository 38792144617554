import React, { useEffect, useState } from 'react'
import './LiftAcc.css'
import { connect } from 'react-redux'
import Moment from 'react-moment'

const alarmList = [
    {
        title: 'Door Open When not Levelled',
        key: 'Door Opened When not Levelled'
    },
    {
        title: 'Stopped When not Levelled',
        key: 'Stopped When not Levelled'
    },
    {
        title: 'People Trapped When not Levelled',
        key: 'People Trapped When not Levelled'
    },
    {
        title: 'Lift Shaking Severly',
        key: 'Lift Shaking Severly'
    },
    {
        title: 'Move with Door Open',
        key: 'Move with Door Open'
    },
    {
        title: 'Lift Door Closing Malfunction',
        key: 'Lift Door Closing Malfunction'
    }
    // {
    //     title: 'Stopped Mid Floor',  //wrong hard code data, not used
    //     key: 'stoppedMidFloor'
    // }
]
const LiftAcc = props => {
    const {DeviceData, currentSite, currentlevel, Notification, DeviceDataLastUpdate} = props
    const [notiList, setNotiList] = useState([])
    useEffect(() => {
        if(Notification==null||Notification.data==null) return
        try {
            const TimeLimitList = Notification.data.filter(item => {
                return DateLessThan1Week(item.createdTime)
            })
            const currentLiftList = getCurrentLiftData(TimeLimitList, props)
            setNotiList(currentLiftList)
        }
        catch(err) {
            console.log(err)
        }
    }, [DeviceDataLastUpdate])

    if(currentlevel == null || currentSite == null || DeviceData == null) return
    const averageSpeed = AverageSpeed(props)

    return <div className="Lift_graphImage">
        <div className='title'>General</div>
        <div className='row'>
            <p>Average Speed</p>
            <p className='value'>{averageSpeed} m/s</p>
        </div>
        {
            alarmList.map(alarmItem => {
                const {title, key} = alarmItem
                return malfunctionCheck(notiList, title, key)
            })
        }
        <div className='row'>
            <p>Last Update</p>
            <Moment className='lastupdate' format="HH:mm:ss">{DeviceDataLastUpdate.toString()}</Moment>
        </div>
        {/* <button onClick={() => DEBUG(props, 0)}>Debug Test Button 1</button>
        <button onClick={() => DEBUG(props, 1)}>Debug Test Button 2</button>
        <button onClick={() => DEBUG(props, 2)}>Debug Test Button 3</button>
        <button onClick={() => DEBUG(props, 3)}>Debug Test Button 4</button>
        <button onClick={() => DEBUG(props, 4)}>Debug Test Button 5</button>
        <button onClick={() => DEBUG(props, 5)}>Debug Test Button 6</button> */}
    </div>
}
function mapStateToProps(state) {
    return {
        currentSite: state.currentSite,
        currentlevel: state.currentlevel,
        DeviceData: state.DeviceData,
        Notification: state.Notification,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate
    }
}
export default connect(mapStateToProps)(LiftAcc)

const AverageSpeed = (props) => {
    const {DeviceData, currentSite, currentlevel} = props
    const FindSite = DeviceData[currentSite]
    if(FindSite == null) return
    const FindLevel = FindSite.nestedLocs.find(levelItem => {
        return levelItem.locName == currentlevel || levelItem.locName == currentlevel.toString()
    })
    const {payload} = FindLevel.nestedLocs[0]
    const {averageSpeed} = payload
    return averageSpeed==null?'--':averageSpeed.toFixed(2)
}

const malfunctionCheck = (notiList, title, key) => {
    if(notiList==null || notiList.length==null) return <></>

    let findAllNoti = notiList.filter(item => {
        const isLessThan1Week = DateLessThan1Week(item.createdTime)
        return item.name.toString()==key.toString() && item.clearParty==null && isLessThan1Week
    })
    const isAlarmOn = !(findAllNoti==null||findAllNoti.length==null||findAllNoti.length==0)

    return <div className='row'>
        <p>{title}</p>
        <p className={isAlarmOn?'alarmvalue on':'alarmvalue off'}>
            {isAlarmOn?'Alarm On':'No Alarm'}
        </p>
    </div>
}

const DateLessThan1Week = (dateStr) => {
    const DateV = new Date(dateStr)
    let DateOneWeek = new Date()
    const OneWeekDay = DateOneWeek.getDate() - 7
    DateOneWeek.setDate(OneWeekDay)

    return DateV >= DateOneWeek
}
const getCurrentLiftData = (inputList, props) => {
    const {currentSite, currentlevel, DeviceData} = props
    const filterList = inputList.filter(item => {
        const locData = item.locationInfo
        const siteName = DeviceData[currentSite].locName
        const isCurrentLvl = locData.find(item => item.name == siteName && item.lvl == 1)
        const isCurrentSite = locData.find(item => item.name == currentlevel && item.lvl == 2)

        return isCurrentLvl != null && isCurrentSite != null
    })

    return filterList
}

// function DEBUG(props, alarmIndex) {
//     const hardcodeResp = {
//         timestamp: "2021-12-13T13:13:13.13Z",
//         id: "61a9edad2830b100183d74ab",
//         message: alarmList[alarmIndex].key,
//         name: alarmList[alarmIndex].key
//     }
//     const data = hardcodeResp
//     // console.log(data)

//     try {
//         const {Notification} = props
//         if(Notification==null||Notification.data==null||Notification.data.length==null) return
//         const notiList = Notification.data
//         let isExist = notiList.filter(item => {
//             return item.id==data.id
//         })
//         if(isExist==null||isExist.length==null||isExist.length==0) return
        
//         let newNotification = Notification
//         newNotification.data.push({
//             ...data,
//             createdTime: data.timestamp
//         })
//         props.dispatch({
//             type: 'Notification',
//             data: newNotification
//         })
//     }
//     catch(err) {
//         console.log(err)
//     }
// }