import React from 'react'
import './table.css'
import { connect } from 'react-redux'
import Moment from 'react-moment'
import Loading from '../../../../loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faBellSlash, faCameraRetro, faExclamationTriangle, faUser } from '@fortawesome/free-solid-svg-icons'

class Table extends React.Component {
    constructor(props) {
        super(props) 
        this.state = {
            isLoading: false        //loading effect on button
        }
        this.acknowledge = this.acknowledge.bind(this)
    }
    componentDidUpdate() {
        if(this.state.isLoading === true) {
            this.setState({ isLoading: false })
        }
    }

    acknowledge() {
        console.log(this.props)
        //get request data
        const {UserInfo } = this.props
        const alarm = this.props.alarm 
        const UserID = UserInfo.userID
        const AlertID = alarm.alarmID
        const AlertName = alarm.name
        const UserName = UserInfo.username
        //request json
        const requestData = {
            msgType: "UpdateAlarm",
			AlarmID: AlertID,	//acknowledging Alarm ID
			UserID: UserID,		//acknowledge person
            Username: UserName,	//acknowledge person
            AckAlarmName:AlertName
        }
        console.log(requestData)
        //emit request
        this.props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "AlarmRequest",
            EmitSocketData: requestData
        })

        this.setState({
            isLoading: true
        })
    }
     
    render() {
        const _this = this
        const {alarm} = this.props 

        if(alarm === null && alarm === undefined) return <div></div>
        const {status, clearParty, type, location} = alarm

        const {rowColor, statusIcon} = GetStatus(status, type)

        return <div className='Coldbox_alarm_row'>
            <div className='gpA'>
                <div className='rw1'>{alarm.coldboxID}</div>
                <div className='rw2' style={{background: rowColor}} onClick={_this.acknowledge}>
                    <FontAwesomeIcon icon={statusIcon} className='fa' />
                </div>
            </div>
            <div className='gpB'>
                <div className='rw1'>{alarm.name}</div>
                <div className='rw2'>{alarm.message}</div>
            </div>
            <div className='gpC'>
                {/* <div className='rw1'>
                    Alarm Type:
                    <div className='type' style={{background: type=='Fault'?'red':'orange'}}>{type}</div>
                </div> */}
                <div className={status=='Open'?'rw2 open':'rw2'} onClick={_this.acknowledge}>
                    <FontAwesomeIcon icon={statusIcon} className='fa' />
                    {/* <FontAwesomeIcon icon={status=='Open'?faExclamationTriangle:faUser} className='fa' /> */}
                    <div style={{color: rowColor}}>{clearParty==''||clearParty==null?'Acknowledge':clearParty}</div>
                </div>
            </div>
            <div className='gpD'>
                {location}
            </div>
            <div className='gpE'>
                <div className='rw1'>Trigger:<Moment className="" format="YYYY-MMM-DD HH:mm">{alarm.createdTime}</Moment></div>
                <div className='rw2'>
                    Clear:
                    {
                        status=='Open'?
                        ''
                        :
                        <Moment className="" format="YYYY-MMM-DD HH:mm">{alarm.clearTime}</Moment>
                    }
                </div>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        UserInfo: state.UserInfo,
        socket: state.socket,
    }
}
export default connect(mapStateToProps)(Table)

function GetStatus(status, type) {   
    console.log(type) 
    let rowColor = 'transparent'
    let statusIcon = faCameraRetro
    
    if(type=='Fault') rowColor = 'rgba(255,0,0,1)'
    else rowColor = 'rgba(255,175,0,1)'

    switch(status) {
        case 'Open':
            // rowColor = 'rgba(255,0,0,1)'
            statusIcon = faBell
            break
        case 'Auto-resumed':
            // rowColor = 'rgba(255,175,0,1)'
            statusIcon = faBellSlash
            break
        case 'Acknowledged':
            // rowColor = 'rgba(0,255,0,1)'
            statusIcon = faBellSlash
            break
    }

    return {
        rowColor: rowColor,
        statusIcon: statusIcon
    }
}