import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import './PerformanceReport.css'
import SearchBar from "./SearchBar";
import TableHead from "./TableHead";
import Table from "./Table";
import { useHistory } from "react-router-dom";
import Pagebtns from "../bottom/pagebtns";
import AddButton from "../bottom/addButton";
import moment from 'moment'
import Downloadcsv from "../bottom/downloadcsv";
import Loading from "../../loading";

const axios = require('axios');
function PerformanceReport(props) {
    const history = useHistory()
    // const [isAdvanceSearch, setAdvanceSearch] = useState({
    //     isAdvanceSearch: false,
    //     AlarmList: null,
    //     rawAlarmList: null,
    //     //sorted result (by table head .js)
    //     sortedList: null,
    //     //filtered result (by searchbar.js)
    //     filteredList: null,
    //     //slice by page (by pages.js widget)
    //     pageSliceList: null,

    //     updateCount: 0
    // })
    const [displayList, setdisplayList] = useState([])
    const [filteredList, setFilteredList] = useState([])
    const [pageSliceList, setPageSliceList] = useState([])
    const [startDate, setStartDate] = useState(new Date(moment().subtract(30, 'days')))
    const [endDate, setEndDate] = useState(new Date())
    const [page, setPage] = useState(0)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        refresh()
    }, [startDate, endDate])

    const RequestData_MaintenanceRule_List = {
        "options": {
            "startTime": startDate,
            "endTime": endDate
        }
    }

    function refresh() {
        axios.post('https://47.52.20.40:31001/AtalAPI/v1/CPSL_WorkOrder_List', RequestData_MaintenanceRule_List)
            .then(function (response) {
                console.log("CPSL_WorkOrder_List recv!", response)
                let list = response.data.info.map(item => {
                    let workOrder = {
                        DateTime: item.requestDate.replace('T', ' ').slice(0, item.requestDate.length - 5),
                        WONumber: item.number,
                        DownTIme: item.downTime,
                        WOType: item.type,
                        order_id: item.order_id
                    }
                    return workOrder
                })
                let asyncFun = [];
                list.forEach(async workOrder => {
                    asyncFun.push(getDetail(workOrder))
                });
                Promise.all(asyncFun).then(() => {
                    setdisplayList(list)
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function getDetail(workOrder) {
        return new Promise((resolve, reject) => {
            const RequestData_MaintenanceRule_Read = {
                "method": "Read",
                "info": {
                    "order_id": workOrder.order_id
                }
            }
            axios.post('https://47.52.20.40:31001/AtalAPI/v1/CPSL_WorkOrder', RequestData_MaintenanceRule_Read)
                .then(function (response) {
                    console.log("CPSL_WorkOrder recv!", response)
                    let resp = response.data.info
                    workOrder.ReplacedRepairedPaths = resp.partsFixed.toString()
                    workOrder.Fault = resp.description
                    workOrder.DeviceID = resp.device.name
                    resolve()
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    }

    function setStartTime(date) {
        setStartDate(date)
    }
    function setEndTime(date) {
        setEndDate(date)
    }
    function UpdateList(key, list) {
        if (key === "pageSliceList")
            setPageSliceList(list)
        else setFilteredList(list)
    }

    function openPdf(order_id) {
        setLoading(true)
        const RequestData_GeneratePDF = {
            "info": {
                "order_id": order_id
            }
        }

        axios.post('https://47.52.20.40:31001/AtalAPI/v1/CPSL_WorkOrder_GeneratePDF', RequestData_GeneratePDF, { responseType: "blob" })
            .then(function (response) {
                var file = new Blob([response.data], { type: 'application/pdf' })
                window.open(URL.createObjectURL(file))
                setLoading(false)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const CsvHeaders = [
        {
            key: 'DateTime',
            label: 'Date & Time',
        },
        {
            key: 'WONumber',
            label: 'W.O. Number',
        },
        {
            key: 'DownTIme',
            label: 'Down TIme (hr)',
        },
        {
            key: 'ReplacedRepairedPaths',
            label: 'Replaced/Repaired Paths',
        },
        {
            key: 'Fault',
            label: 'Fault',
        },
        {
            key: 'WOType',
            label: 'W.O. Type',
        }
    ]
    return <div className="workOrderList">
        {loading ? <Loading DeviceDataStatus={1} />
            : <div>
                <SearchBar sortedList={displayList} UpdateList={UpdateList} refresh={refresh} setStartTime={setStartTime} setEndTime={setEndTime} />
                <TableHead />
                {
                    (pageSliceList != null && pageSliceList != undefined) ?
                        pageSliceList.map(row => {
                            return <Table row={row} onClick={() => { openPdf(row.order_id) }} />
                        }) : ""
                }
                <div className="ColdboxIndi_bottom">
                    <Downloadcsv sortedList={displayList} headers={CsvHeaders} filename='PerformanceReportByDevice.csv' />
                    <Pagebtns sortedList={filteredList} UpdateList={UpdateList} />
                </div>
            </div>}
    </div>
}

function mapStateToProps(state) {
    return {
        socket: state.socket,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate, //when this value is updated, render would triggered
        UserInfo: state.UserInfo,
        Notification: state.Notification,
    }
}
export default connect(mapStateToProps)(PerformanceReport)