const genAlarmRecord = (RawData, DeviceData, currentSite, currentlevel) => {
    const {siteName, lvlName} = GetLocNames(DeviceData, currentSite, currentlevel)
    const filteredList = GetFilteredList(RawData, siteName, lvlName)

    const FormatList = filteredList.map(item => {
        return {
            name: item.name,
            message: item.message
        }
    })
    const uniqList = getUniqStrList(FormatList, 'name')

    return CountEachAlarm(FormatList, uniqList, lvlName)
}
const GetLocNames = (DeviceData, currentSite, currentlevel) => {
    let siteName = DeviceData[currentSite].locName
    let lvlName = DeviceData[currentSite].nestedLocs.find(item => item.locName.toString() == currentlevel.toString()).locName
    return {
        siteName: siteName,
        lvlName: lvlName
    }
}
const GetFilteredList = (RawData, siteName, lvlName) => {
    if(RawData==null || RawData.length==null) return []
    const filteredList = RawData.filter(item => {
        const {locationInfo} = item
        if(locationInfo==null || locationInfo.length==null || locationInfo.length<3) return 0
        let itemSite = locationInfo.find(item => item.lvl==1).name
        let itemlvl = locationInfo.find(item => item.lvl==2).name
        if(itemSite!==siteName || itemlvl!==lvlName) return 0
        return 1
    })
    return filteredList
}
const CountEachAlarm = (FormatList, uniqList, lvlName) => {
    if(uniqList==null || uniqList.length==null || FormatList==null || FormatList.length==null) return []
    let returnList = []
    uniqList.forEach(nameStr => {
        const List = FormatList.filter(item => item.name==nameStr)
        returnList.push({
            typeName: nameStr,
            count: List.length,
            liftID: 'Lift ' + lvlName
        })
    })
    return returnList
}
const getUniqStrList = (ArrayList, keystr) => {
    const MapStrList = ArrayList.map((item) => {
        return item[keystr]
    })
    return MapStrList.filter(onlyUnique)
}
const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index
}
export default genAlarmRecord