//This is the default config JS file
//in index.js, default would read this js file'ssettings
//Then. index.js would try to read config.json file in asset folder
//If read success ,would overwrite all settings in this file    (When config.json file is read successfully)
//else would use this file's settings                           (either when react is compile, not cannot read config.json file)

//CAUTIONS:
//1. make sure this file's format is same as assets folder's format
//2. To check if config file is read successful, you can check the redux.config parameter
//3. To read redux log, open chrome > inspect > console log > console log of navbar.js

export let DefaultConfig = {
    //socket connect url, used in index.js
    socketUrl: "https://atec-ibs.com",    //PROD          //socket version: 2.3.0
    //socketUrl: "https://47.52.20.40:30405",    //DEV    //socket version: 3.1.1 ** need check the CORS extention in chrome + run https://47.52.20.40:30405

    //image url to get floor plan, used in /FloorPlan/Map.js
    imgUrl: "https://atec-ibs.com",          //PROD
    //imgUrl: "https://47.52.20.40:30405",       //DEV

    //Navbar popup message on bottom: hide or show (currently, all are shown so knowing if there are any problem in socket listener/connection)
    isShowBtmMsg: true,         //at navbar/RealTimeAlert.js

    //color theme setting paramters (used in setting page, navbar popup on right slide)
    colorThemeList: [
        {
            ColorHighlight: '#F15A2D',
            ColorNavigationBar: '#123342',
            ColorBackground: '#FFFFFF',
            ColorHeader: '#878683'
        },
        {
            ColorHighlight: '#E42C6A',
            ColorNavigationBar: '#32064A',
            ColorBackground: '#FFFFFF',
            ColorHeader: '#878683'
        },
        {
            ColorHighlight: '#0297A7',
            ColorNavigationBar: '#0297A7',
            ColorBackground: '#7DCFD9',
            ColorHeader: '#878683'
        },
        {
            ColorHighlight: '#C05D3A',
            ColorNavigationBar: '#C05D3A',
            ColorBackground: '#D8B982',
            ColorHeader: '#878683'
        },
        {
            ColorHighlight: '#5DAA68',
            ColorNavigationBar: '#3F6844',
            ColorBackground: '#FAF1CF',
            ColorHeader: '#878683'
        },
        {
            ColorHighlight: '#6688CC',
            ColorNavigationBar: '#182978',
            ColorBackground: '#ffffff',
            ColorHeader: '#6688CC'
        },
        {
            ColorHighlight: '#C6AD8F',
            ColorNavigationBar: '#425664',
            ColorBackground: '#F6F4F2',
            ColorHeader: '#425664'
        },
        {
            ColorHighlight: '#1181B2',
            ColorNavigationBar: '#212221',
            ColorBackground: '#DDEDF4',
            ColorHeader: '#1181B2'
        },
        {
            ColorHighlight: '#c53211',
            ColorNavigationBar: '#2e3830',
            ColorBackground: '#e6dbc9',
            ColorHeader: '#878683'
        },
        {
          ColorHighlight: '#01655b',
          ColorNavigationBar: '#123342',
          ColorBackground: '#FFFFFF',
          ColorHeader: '#878683'
        },
    ],
    darkColorTheme: {
        //dark mode colors
        '--colortheme1': '#4e4e4e',
        '--colortheme2': '#2e2e2e',
        '--optiontext': '#white',
        '--slideBG_1': 'rgba(75,75,75,1)',
        '--slideBG_2': 'rgba(75,75,75, .7)',
        '--slideBG_3': 'rgba(75,75,75,0.5)',
        //normal resume color
        '--slideBG_1_Normal': 'rgba(255,255,255,1)',
        '--slideBG_2_Normal': 'rgba(255,255,255,.7)',
        '--slideBG_3_Normal': 'rgba(255,255,255,0.5)',
    },

    //Google Map Theme, on main page
    GoogleMapNormal: [
        {
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#ebe3cd"
            }
          ]
        },
        {
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#523735"
            }
          ]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#f5f1e6"
            }
          ]
        },
        {
          "featureType": "administrative",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#c9b2a6"
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#dcd2be"
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#ae9e90"
            }
          ]
        },
        {
          "featureType": "landscape.natural",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#dfd2ae"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#dfd2ae"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#93817c"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#a5b076"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#447530"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#f5f1e6"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#fdfcf8"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#f8c967"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#e9bc62"
            }
          ]
        },
        {
          "featureType": "road.highway.controlled_access",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e98d58"
            }
          ]
        },
        {
          "featureType": "road.highway.controlled_access",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#db8555"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#806b63"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#dfd2ae"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#8f7d77"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#ebe3cd"
            }
          ]
        },
        {
          "featureType": "transit.station",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#dfd2ae"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#b9d3c2"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#92998d"
            }
          ]
        }
    ],
    GoogleMapDark: [
        {
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#1d2c4d"
            }
          ]
        },
        {
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#8ec3b9"
            }
          ]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#1a3646"
            }
          ]
        },
        {
          "featureType": "administrative.country",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#4b6878"
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#64779e"
            }
          ]
        },
        {
          "featureType": "administrative.province",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#4b6878"
            }
          ]
        },
        {
          "featureType": "landscape.man_made",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#334e87"
            }
          ]
        },
        {
          "featureType": "landscape.natural",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#023e58"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#283d6a"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#6f9ba5"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#1d2c4d"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#023e58"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#3C7680"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#304a7d"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#98a5be"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#1d2c4d"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#2c6675"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#255763"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#b0d5ce"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#023e58"
            }
          ]
        },
        {
          "featureType": "transit",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#98a5be"
            }
          ]
        },
        {
          "featureType": "transit",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#1d2c4d"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#283d6a"
            }
          ]
        },
        {
          "featureType": "transit.station",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#3a4762"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#0e1626"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#4e6d70"
            }
          ]
        }
    ],

    //Google Map Key, on main page
    GoogleMapKey: 'AIzaSyBX6Vqy4Z1SEfsyRDsTO6IF3IMFHesZHO8',        //develop mode key, real key is in asset/config.json file

    //mobile app download OR install
    android: "https://47.52.20.40:30405/assets/IoT_Hub.apk",
    ios: "https://apps.apple.com/np/app/iot-hub-platform/id1502967168",

    //hardcode public page display of Central Market, the Port setting in here
    CentralMarketPublicPagePort: '30080',
    CCGHQPublicPagePort: '30081'
}
