import React from 'react';
//Redux Import
import { connect } from 'react-redux';

//import the 3D viewer
import {JSONModel, OBJModel} from 'react-3d-viewer';      //The library that want to use but have errors

//another less performance Library
import { ObjViewer } from 'react-obj-viewer';               //No error but not looking great

import './demo3D.css';

class Demo3D extends React.Component {
    constructor(props)
    {
        super(props);
    }
    componentDidMount()
    {
        return;
        //document.querySelector('iframe').contentWindow.document.querySelector("header").style.color = "red";
        //console.log(document.querySelector('iframe').contentWindow.document.querySelector("header"));
        //console.log(document.querySelector('iframe'));
    }

    render()
    {
        //return <div className="Demo3D_Container">
            {
            /*
            <ObjViewer 
                model="http://47.56.108.35/assets/test3D/freedom.obj"
                material="http://47.56.108.35/assets/test3D/freedom.png"
                width={1000}
                height={1000}
            />
            */
            }
        const {isShow3D} = this.props;
        //let hideClass = "";
        if(!isShow3D)
        {
            //hideClass = "hide";
            return <div></div>
        }
        return [
        //<iframe className={"Demo3D_Container " + hideClass}
        <iframe className={"Demo3D_Container "}
                title="Inline Frame Example"
                scrolling="yes"
                src="https://autode.sk/2HRnCmG">
            </iframe>,
        // <div className={"Demo3D_topGrad " + hideClass}></div>,
        // <div className={"Demo3D_botGrad " + hideClass}></div>,
        // <div className={"Demo3D_sideGrad " + hideClass}></div>,
        // <div className={"Demo3D_rightGrad " + hideClass}>
        <div className={"Demo3D_topGrad "}></div>,
        <div className={"Demo3D_botGrad "}></div>,
        <div className={"Demo3D_sideGrad "}></div>,
        <div className={"Demo3D_rightGrad "}>
            <div className="Demo3D_row">
                <div className="fa fa-home Demo3D_icon"></div>
                <div className="Demo3D_txt">Default View</div>
            </div>
            <div className="Demo3D_row">
                <img src={require('../../../assets/images/click1.png')} alt="ATAL" className="Demo3D_icon"></img>
                <div className="Demo3D_txt">Rotate</div>
            </div>
            <div className="Demo3D_row">
                <img src={require('../../../assets/images/click2.png')} alt="ATAL" className="Demo3D_icon"></img>
                <div className="Demo3D_txt">Zoom</div>
            </div>
            <div className="Demo3D_row">
                <img src={require('../../../assets/images/click3.png')} alt="ATAL" className="Demo3D_icon"></img>
                <div className="Demo3D_txt">Move</div>
            </div>
        </div>,
        ]
        //</div>
    }
}

//Redux inside component function
function mapStateToProps(state) {
    return {
      socket: state.socket,
      configStorage: state.configStorage,
      DeviceDataLastUpdate: state.DeviceDataLastUpdate
    };
}

export default connect(mapStateToProps)(Demo3D);