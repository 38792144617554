import React from 'react'
import './coldboxFloorPlan.css'
import $ from 'jquery'
import { connect } from 'react-redux'

import Coldbox_popup from './popup/popup'
import coldboxList from './coldboxList'

//test function, for changing location
import TestLocUpdate from './testLocUpdate/testLocUpdate'

// let timer = 0;
let delay = 200
let prevent = false
class ColdboxFloorPlan extends React.Component {
    constructor(props) {
        super()

        this.mapwidth = React.createRef()
        this.mapdiv = React.createRef()
        this.mapoutsidediv = React.createRef()
        this.maptitle = React.createRef()
        this.DrawArea = React.createRef()
        this.MainPopUpDiv = React.createRef()
        this.TitlePopUpDiv = React.createRef()

        //parameters for map style settings
        this.timer = 0
        this.marginLeft = '5'
        this.paddingTop = '1'
        this.paddingBottom = '1'
        this.state = {
            Mapitem: {
                'marginLeft': this.marginLeft + 'px',
                'marginTop': this.paddingTop + 'px',
                'marginBottom': this.paddingBottom + 'px'
            },
            MapAreaStyle: {
                'marginLeft': this.marginLeft + 'px'
            },
            editmap: false,
            mapImagePath: '',//'http://47.56.108.35/assets/images/floors/1.png', //this is the hard code sample image src
            ImageFailedToLoad: false, //default is false
            isShow3D: false,

            pickedItem: null,           //item for deviceInfo widget to display
            Floor: "",
            showStatusPopup: false,
            BeaconList: null,
            clickedBLEID: null,
        }
        this.MapWidthHeightRatio = 1
        this.MapOnloadLoop = {}         //loop function storage

        this.MapOnloaded = this.MapOnloaded.bind(this)
        this.resize = this.resize.bind(this)

        this.editMapArea = this.editMapArea.bind(this);
        this.newMapArea = this.newMapArea.bind(this);
        this.removeMapArea = this.removeMapArea.bind(this);
        this.saveMapArea = this.saveMapArea.bind(this);
        this.addpolygon = this.addpolygon.bind(this);
        this.MapAreaOnload = this.MapAreaOnload.bind(this);
        this.addMarker = this.addMarker.bind(this); //this function is incompleted (not called, not draw marker)
        this.ImageError = this.ImageError.bind(this);
        this.positionClick = this.positionClick.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.handleClick1 = this.handleClick1.bind(this)
        this.StatusScreenRedirect = this.StatusScreenRedirect.bind(this)

        this.redirectOperatorOverview = this.redirectOperatorOverview.bind(this)
        this.ColdBoxIDSelect = this.ColdBoxIDSelect.bind(this)
        this.closePopup = this.closePopup.bind(this)

        this.BLEGWItemclicked = this.BLEGWItemclicked.bind(this)
    }

    componentDidUpdate(preProps) {
        return
        const { UserInfo, currentSite, currentlevel, DeviceData, DashboardPickedColdboxID } = this.props

        const CurrentFloorItem = DeviceData[currentSite].nestedLocs.find(function (floorItem) { return floorItem.locName == currentlevel })
        let current_floor = ""
        if (CurrentFloorItem !== null) {
            current_floor = CurrentFloorItem.locDesc
            if (DashboardPickedColdboxID === "" || DashboardPickedColdboxID === undefined || DashboardPickedColdboxID === null) {

            }
        }
    }
    componentWillUnmount() {
        //remove the socket listener on this page
        try {
            this.props.socket.removeListener('MapArea', console.log('Remove Listener Completed'))
        }
        catch (err) {
            console.error('ColdboxFloorPlan.js ComponentWillUnmount() error')
        }
        window.removeEventListener('resize', this.resize)
        clearInterval(this.MapOnloadLoop)
    }

    componentDidMount() {
        const {UserInfo, currentSite, currentlevel, DeviceData, DashboardPickedColdboxID, BeaconManageList, currentlevelID} = this.props
        const _this = this

        if (this.props.socket == null) {
            console.error('this.props.socket == null')
            return
        }
        window.addEventListener('resize', this.resize)

        //infinite loop untill this component will unmount 
        this.MapOnloadLoop = setInterval(() => {
            _this.MapOnloaded()
            _this.MapAreaOnload()
        }, 500 * 10)

        setTimeout(function () {
            _this.MapOnloaded()
        }, 500)

        this.props.dispatch({ type: 'LatestPage', data: '/Main/FloorPlan' })
        const CurrentFloorItem = DeviceData[currentSite].nestedLocs.find((floorItem) => {
            return floorItem.locName == currentlevel
        })

        let current_floor = ''
        if (CurrentFloorItem !== null) {
            current_floor = CurrentFloorItem.locDesc
            if (DashboardPickedColdboxID === "" || DashboardPickedColdboxID === undefined || DashboardPickedColdboxID === null) {
                if (CurrentFloorItem.nestedLocs !== null && CurrentFloorItem.nestedLocs !== undefined && CurrentFloorItem.nestedLocs.length > 0) {
                    this.props.dispatch({
                        type: "DashboardPickedColdboxID",
                        data: CurrentFloorItem.nestedLocs[0].locName
                    });

                    this.props.dispatch({
                        type: 'PickPolygon',
                        data: CurrentFloorItem.nestedLocs[0].locName //string of locName, highlight the polygon inside Map.js
                    })
                }
            }
        }
        let newList = []
        if (BeaconManageList !== null) {
            BeaconManageList.map((element) => {
                if (element.zone[0] !== undefined && element.zone[0].location_id !== undefined && element.zone[0].location_id == currentlevelID) newList.push(element)
            })
        }

        this.setState(prevState => ({
            ...prevState,
            Floor: current_floor,
            BeaconList: newList
        }))
    }

    componentWillUnmount() {
        //remove the socket listener on this page
        try {
            this.props.socket.removeListener('MapArea', console.log('Remove Listener Completed'))
        }
        catch (err) {
            console.error('Map.js ComponentWillUnmount() error')
        }
        window.removeEventListener('resize', this.resize)
        clearInterval(this.MapOnloadLoop)
    }

    resize() {
        const thisoutside = this
        setTimeout(function () {
            thisoutside.MapAreaOnload();
            thisoutside.MapOnloaded()
        }, 500)
    }

    MapAreaOnload() {
        const _this = this
        //clear the polygons
        $("#coldbox_polygonsvgbody").empty()

        //use real data
        //get currentSite and currentlevel
        let {UserInfo, currentSite, currentlevel} = this.props
        let {BeaconList} = this.state
        //get the MapArea Reading
        try {
            //since the level is the level value, not level count, get the level value first
            let currentlevel_i = 0
            let i = 0
            UserInfo.locations[currentSite].nestedLocs.forEach(function (element) {
                if (currentlevel == element.locName) {
                    currentlevel_i = i
                }
                i++
            })

            let MapArea = UserInfo.locations[currentSite].nestedLocs[currentlevel_i]

            let polygon_i = 1
            let PolygonJson = []
            if (MapArea.nestedLocs == null || MapArea.nestedLocs == undefined) {
                console.log('MapArea.nestedLocs == null Map.js MapAreaOnload()')
            }
            else {
                console.log('MapAreaOnload()')
                console.log(MapArea.nestedLocs)
                MapArea.nestedLocs.forEach(function (element) {
                    if (element.locUI==undefined || element.locUI==null || element.locUI.indicator.point.type == "Point") {
                        //there is no draw point method yet
                    }
                    else {
                        PolygonJson.push({
                            id: polygon_i,                           //id start from 1, max 10
                            locName: element.locName,                //locName is the item inside UserInfo
                            polygon: element.locUI.indicator.point   //polygon array
                        })

                        //check it is marker or polygon area
                        if (element.locUI.type == "marker") {
                            if (element != null && element.hasOwnProperty('payload_definition_id') && element.payload_definition_id.hasOwnProperty('_id')) {
                                let payloadID = element.payload_definition_id._id
                                let color = ''
                                console.log('3a')
                                switch (payloadID) {
                                    case "601910a5d55b3442b4226003": // Door Contact
                                        color = "rgb(109, 214, 255)"
                                        break
                                    default:
                                        color = "var(--focuscardbackground)"
                                        break
                                }
                                //call marker function or polygon area
                                _this.addMarker(element.locUI.indicator.point[0].coordinates, 'var(--markercolor' + polygon_i + ')', color, element.locName)
                            } else if (element != null && element.payload_definition_id != null && element.payload_definition_id.name != null) {
                                //different color
                                let deviceType = element.payload_definition_id.name
                                let color = ""
                                if (deviceType === "Temp & Humid" || deviceType === "Temperature & Humdity") color = "cyan"
                                else if (deviceType === "Occupancy") color = "#B93B8F"
                                else if (deviceType === "IAQ") color = "rgba(100, 250, 0, 1)"
                                else if (deviceType === "LUX") color = "#FFDB58"
                                else if (deviceType === "AC Power") color = "#48CCCD"
                                else if (deviceType === "Rubbish Bin") color = "orange"
                                else if (deviceType === "Toilet Occupancy") color = "magenta"
                                else if (deviceType === "People Count") color = "#e42c6a6e"
                                else if (deviceType === "Water Leakage") color = "#8c0eff6e"
                                else if (deviceType === "Wetness") color = "rgba(46, 134, 193)"
                                else if (deviceType === "Vibration") color = "Thistle"
                                else if (deviceType === "RFID") color = "rgba(23, 32, 42)"
                                else if (deviceType === "Water Leakage (Point)") color = "#8c0eff6e"
                                else if (deviceType === "Door Contact") color = "green"
                                else {
                                    color = "var(--focuscardbackground)"
                                }

                                //call marker function or polygon area
                                _this.addMarker(element.locUI.indicator.point[0].coordinates, 'var(--markercolor' + polygon_i + ')', color, element.locName)
                            }
                            else _this.addMarker(element.locUI.indicator.point[0].coordinates, 'var(--markercolor' + polygon_i + ')', "", "No Device Name")
                        }
                        else if (element.locUI.type == "zone") {
                            console.log('5');
                            //draw polygon to UI
                            _this.addpolygon(element.locUI.indicator.point, 'var(--polygoncolor' + polygon_i + ')')
                        }
                        else {
                            console.log('undefined')
                        }
                    }
                    polygon_i++
                })
            }

            //CPSL draw iBeacon
            if (BeaconList == null) console.log('BeaconList == null coldboxFloorPlan.js')
            else {
                BeaconList.forEach(BLEItem => {
                    PolygonJson.push({
                        id: polygon_i,                         //id start from 1, max 10
                        locName: BLEItem.zone_id,              //locName is the item inside UserInfo
                        polygon: BLEItem.zone[0].coor,         //polygon array
                    }) 
                    const color = "rgb(75, 255, 0)"
                    _this.addMarker(BLEItem.zone[0].coor.coordinates, 'var(--markercolor' + polygon_i + ')', color, BLEItem.location_id)
                    polygon_i++
                })
            }
            //CPSL draw Gateway
            const FloorItem = GetFloorItem(_this.props)
            const GatewayList = GetGatewayList(FloorItem)
            if(GatewayList==null) console.log('GatewayList == null coldboxFloorPlan.js')
            else {
                GatewayList.forEach(gwItem => {
                    const {id, status, desc, coor} = gwItem
                    PolygonJson.push({
                        id: polygon_i,                         //id start from 1, max 10
                        locName: desc,
                        polygon: coor
                    })
                    const color = 'rgb(169, 0, 255)'
                    _this.addMarker(coor, 'var(--markercolor' + polygon_i + ')', color, id)
                    
                    polygon_i++
                })
            }

            //save the polygon into global
            _this.props.dispatch({
                type: 'CurrentPolygon',
                data: PolygonJson
            })
        }
        catch (err) {
            console.error(err)
        }
    }

    addMarker(markerPt, id, fillcolor, deviceName) {     //#color is the ID of svg item, fill color is the default color of the circle
        //get the width and the height of svg object
        let mapimagewidth = this.mapwidth.current.offsetWidth
        let mapimageheight = this.mapwidth.current.offsetHeight
        //get the margin-top value
        let ImageMarginTop_str = this.state.Mapitem.marginTop
        ImageMarginTop_str = ImageMarginTop_str.replace('px', '')
        //find the marker location (real coordinate)
        let xCoor = mapimagewidth * markerPt[0]
        let yCoor = markerPt[1] * mapimageheight + this.paddingTop

        //add marker into svg
        let svgimg = document.createElementNS('http://www.w3.org/2000/svg', 'circle')
        svgimg.setAttributeNS(null, 'cx', xCoor)
        svgimg.setAttributeNS(null, 'cy', yCoor)
        svgimg.setAttributeNS(null, 'r', '8')
        svgimg.setAttributeNS(null, 'stroke', '#00000040')
        svgimg.setAttributeNS(null, 'stroke-width', '7px')
        svgimg.setAttributeNS(null, 'fill', fillcolor)
        svgimg.setAttributeNS(null, 'id', id)
        svgimg.setAttributeNS(null, 'title', deviceName)
        document.getElementById('coldbox_polygonsvgbody').appendChild(svgimg)
    }

    addpolygon(polygon_pts, color_drawing) {
        //get the width and the height of the image in browser
        const mapimagewidth = this.mapwidth.current.offsetWidth
        const mapimageheight = this.mapwidth.current.offsetHeight// - this.paddingTop*2;

        //foreach the polygon_pts
        let realCoordinateArray = []
        polygon_pts.map((data, i) => {
            //ratio the width and height from percentage to 'px'
            const x_coor = data[0] * mapimagewidth
            const y_coor = data[1] * mapimageheight + this.paddingTop
            //push the result into an new array
            realCoordinateArray.push([x_coor, y_coor])
        })

        //set the points to the map image
        let color_str = 'fill: ' + color_drawing

        var polygon = this.makeSVG('polygon', { "points": realCoordinateArray, "style": color_str })
        document.getElementById('coldbox_polygonsvgbody').appendChild(polygon)
    }

    MapOnloaded() {
        const _this = this
        //set the map to middle of the map
        let mapimagewidth = 0
        let mapdivwidth = 0
        //set the map to vertical middle
        let mapdivheight = 0
        let mapimageheight = 0
        let maptitleheight = 0
        if(this.mapwidth.current == null || this.mapdiv.current == null || this.mapoutsidediv.current == null || this.maptitle.current == null) return //not every time ref() is ready (e.g. render() is running)
        try {
            mapimagewidth = this.mapwidth.current.offsetWidth
            mapdivwidth = this.mapdiv.current.offsetWidth
            this.marginLeft = (mapdivwidth - mapimagewidth) / 2

            mapdivheight = this.mapoutsidediv.current.offsetHeight
            mapimageheight = this.mapwidth.current.offsetHeight
            maptitleheight = this.maptitle.current.offsetHeight
        }
        catch (err) {
            console.log('Error in MapOnloaded()')
            console.log(err)
            return
        }
        if(mapimageheight == 0 || mapimagewidth == 0) {
            console.log('Image is not loaded, so redo the MapOnload() again Map.js')
            setTimeout(function () { _this.MapOnloaded() }, 1000)
            return
        }

        let remainheightspace = (mapdivheight - mapimageheight - maptitleheight) / 2
        this.paddingTop = remainheightspace
        this.paddingBottom = remainheightspace

        //get width of image
        let widthImage = mapimagewidth
        let heightImage = mapimageheight
        //set value
        this.setState({
            Mapitem: {
                'marginLeft': this.marginLeft + 'px',
                'marginTop': this.paddingTop + 'px',
                'marginBottom': this.paddingBottom + 'px'
            },
            MapAreaStyle: {
                'marginLeft': this.marginLeft + 'px',
                'marginTop': this.paddingTop + 'px',
                'marginBottom': this.paddingBottom + 'px',
                'width': widthImage,
                'height': heightImage,
            }
        })

        this.MapWidthHeightRatio = mapimagewidth / mapimageheight
    }


    editMapArea() {
        this.setState({
            editmap: !this.state.editmap
        })
    }

    newMapArea() {
        this.DrawArea.current.nextArea()
    }

    removeMapArea() {
        this.DrawArea.current.removeArea()
    }

    saveMapArea() {
        //This is the function to add polygons to map.js when save the pop-up drawed areas

        const _this = this;
        //Get the Area Json from the DrawArea.js component
        let savereturn = this.DrawArea.current.saveArea()

        //Convert the Area Position Reading to Percentage Unit
        let Map_W = 1050;   //correct
        let Map_H = 1050 / this.MapWidthHeightRatio;    //use image ratio to find the height
        //get the true height:
        Map_H = this.MainPopUpDiv.current.offsetHeight;// - this.TitlePopUpDiv.current.offsetHeight;    //since change the title to left hand side, no need to minus the hight of the header
        let mapimagewidth = this.mapwidth.current.offsetWidth;
        let mapimageheight = this.mapwidth.current.offsetHeight - _this.paddingTop * 2

        //let mapdivwidth = this.mapdiv.current.offsetWidth;
        //let marginLeftfintune = (mapdivwidth - mapimagewidth)/2;

        let convertedAreaArray = {}
        let currentPolygon = ''
        let currentpolygonnumber = 1
        $.each(savereturn, function (index, value) {
            currentPolygon = ''
            $.each(value, function (innerindex, innervalue) {
                if (innerindex == 0) {
                    //open a new polygon to push point
                    convertedAreaArray[index] = []
                }
                else {
                    //not the first polygon point
                }

                //push into the polygon
                let itemwidth = innervalue[0]
                let itemheght = innervalue[1]
                let itemwidthpercent = itemwidth / Map_W * mapimagewidth
                let itemheightpercent = itemheght / Map_H * mapimageheight + _this.paddingTop

                let converteditem = [itemwidth / Map_W, itemheght / Map_H]     //percentage of the area
                convertedAreaArray[index].push(converteditem)
                currentPolygon += ' ' + itemwidthpercent + ',' + itemheightpercent
            })
        })
    }

    makeSVG(tag, attrs) {
        var el = document.createElementNS('http://www.w3.org/2000/svg', tag)
        for (var k in attrs)
            el.setAttribute(k, attrs[k])
        return el
    }

    ImageError() {
        //triggered when the Image loaded failed
        console.error('Image Failed to loaded! Map.js')
        alert('Map / Dashboard Image is not found!')
        this.setState({
            ImageFailedToLoad: true
        })
    }

    handleClick1() {
        this.setState(prevState => ({
            ...prevState,
            showStatusPopup: true,
        }))
    }

    handleClick(event) {
        clearTimeout(this.timer)

        const eventTarget = event.target
        const eventDetail = event.detail
        if (event.detail === 1) {
            this.timer = setTimeout(function () {
                this.positionClick(eventTarget, eventDetail)
            }.bind(this), 300)
        } else if (event.detail === 2) {
            this.positionClick(eventTarget, eventDetail)
        }
    }

    positionClick(eventTarget, clickCount) {                  ///when clicked on position marker, highlight it and bottom card reading
        const _this = this
        if (eventTarget == null || eventTarget.id == null) return     //exception, when clicked on no id item

        const id = eventTarget.id
        const splitItem = id.includes('rgb(109, 214, 255)')?id.split('rgb(109, 214, 255)'):id.split('var(--markercolor')
        if (splitItem == null || splitItem.length == 0 || splitItem[1] == null) return   //exception, when the ID is not splitable, which is not position html item

        //get position item id
        const splitedItem = splitItem[1]
        const idStr = splitedItem.replace(')', '')
        //result: position item id
        const idInt = parseInt(idStr, 0)

        //get position's name
        const {Polygons} = this.props
        if (Polygons == null || Polygons.length == 0) return
        const clickedLocation = Polygons.find(function (locItem) {
            return locItem.id == idInt
        })
        if(clickedLocation==null) return
        const locName = clickedLocation.locName

        //get location type, count in array
        let getLocItem
        const { UserInfo } = this.props
        if (UserInfo == null || UserInfo.locations == null || UserInfo.locations.length == null || UserInfo.locations.length == 0) return
        UserInfo.locations.forEach(siteItem => {
            if (siteItem == null || siteItem.nestedLocs == null || siteItem.nestedLocs.length == null) return
            siteItem.nestedLocs.forEach(floorItem => {
                if (floorItem == null || floorItem.nestedLocs == null || floorItem.nestedLocs.length == null) return
                floorItem.nestedLocs.forEach(locItem => {
                    if (locItem.locName == locName) getLocItem = locItem
                })
            })
        })

        if(getLocItem==null) {
            _this.BLEGWItemclicked(idInt)
        }

        this.setState({
            pickedItem: getLocItem,
        })

        //get count on menu
        if (getLocItem == null || getLocItem.payload_definition_id == null || getLocItem.payload_definition_id.name == null) return
        if (clickCount === 2) {
            this.StatusScreenRedirect(getLocItem)
        } else if (clickCount === 1) {
            this.handleClick1()
        }
    }
    BLEGWItemclicked(BLECountID) {
        this.setState({
            clickedBLEID: BLECountID
        })
    }

    StatusScreenRedirect(item) {
        localStorage.setItem('selectedColdboxID', item.locName)
        this.props.history.push({
            pathname: '/Main/FloorPlan/ColdboxStatus',
            Item: item,
        })
    }
    redirectOperatorOverview() {
        this.props.history.push('/Main/FloorPlan/ColdboxOperationOverview')
    }

    ColdBoxIDSelect(event) {
        let selectedID = event.target.value
        const _this = this
        if (selectedID !== null || selectedID !== undefined) {
            this.props.dispatch({
                type: "DashboardPickedColdboxID",
                data: selectedID
            })
            this.props.dispatch({
                type: 'PickPolygon',
                data: selectedID //string of locName, highlight the polygon inside Map.js
            })
            const i = getIValue(selectedID, _this.props)
            this.props.dispatch({ type: 'bottomcardhover', data: 'bottomcard_' + i })
        }
    }

    closePopup() {
        this.setState(prevState => ({
            ...prevState,
            showStatusPopup: false,
            clickedBLEID: null
        }))
    }

    render() {
        let mapItem = ""
        const { Mapitem, MapAreaStyle, Floor, showStatusPopup, pickedItem, clickedBLEID } = this.state
        const { UserInfo, configStorage, currentSite, currentlevel, Polygons, history } = this.props
        let coldBoxList = []
        try {
            if (UserInfo == null || UserInfo == undefined) return <div>UserInfo is missing</div>
            else {
                const rooturl = configStorage.imgUrl
                mapItem = rooturl + "/assets/images/floors/" + this.props.UserInfo.locations[this.props.currentSite].imgUrl + "/" + this.props.currentlevel + ".png";
                //replace space with %20
                mapItem = mapItem.replace(' ', '%20')
                let curSite = UserInfo.locations[currentSite]
                if (curSite !== null && curSite !== undefined) {
                    let floor_items = curSite.nestedLocs.find(function (floorItem) { return floorItem.locName == currentlevel })
                    coldBoxList = floor_items.nestedLocs
                }
            }
        }
        catch (err) {
            return <div>Some error happened</div>
        }

        //remove all polygon highlight color
        let i = 0;
        for (i = 0; i < 31; i++) {
            document.documentElement.style.setProperty('--polygoncolor' + i, 'transparent')
            document.documentElement.style.setProperty('--markercolor' + i, 'transparent')
        }
        //set newhighlight color
        try {
            //find the polygon to highlight with the locName in redux
            let { PickedPolygonLoc, Polygons, configStorage } = this.props
            
            if (Polygons == null) {
                console.log('this.props.Polygons == null coldboxFloorPlan.js render()')
            }
            else {
                Polygons.forEach(PolygonItem => {
                    let highlightItem = document.getElementById('var(--markercolor' + PolygonItem.id + ")")
                    if (PolygonItem.locName == PickedPolygonLoc) {
                        //draw on that polygon
                        document.documentElement.style.setProperty('--markercolor' + PolygonItem.id, 'red')
                        document.documentElement.style.setProperty('--polygoncolor' + PolygonItem.id, '#00000060')
                        if (highlightItem != null) highlightItem.setAttribute('class', "circleItem")
                    }
                    else {
                        if (highlightItem != null) highlightItem.setAttribute('class', "")         //remove animation class
                    }
                })
            }
        }
        catch (err) {
            console.error('coldboxFloorPlan.js highlight card failed')
            console.error(err)
        }

        return <div className="coldboxfloor_outside" onClick={this.handleClick} >
            <div className="coldboxfloor_inside" ref={this.mapoutsidediv}>
                <div className="flex flex-wrap shadow-lg floor_plan_inside_head" ref={this.maptitle}>
                    {(coldBoxList.length > 0 ?
                        <div className="coldboxfloor_boxID">
                            <div className="coldboxfloor_boxIDtxt">Coldbox ID</div>
                            <input placeholder='Search Coldbox' className='coldboxfloor_boxIDSelect' list='coldboxs' onChange={this.ColdBoxIDSelect} type="search"></input>
                            <datalist id='coldboxs'>
                                {
                                    coldBoxList.map(item => {
                                        return <option value={item.locName} />
                                    })
                                }
                            </datalist>
                        </div>
                        :
                        <div></div>
                    )}
                    <div className="cdbx_fr_pnl">
                        <div className="pnl_item">
                            <div className="color" style={{background: 'rgb(109, 214, 255, 0.8)'}}></div>
                            <div className="desc">Coldbox</div>
                        </div>
                        <div className="pnl_item">
                            <div className="color" style={{background: 'rgb(75, 255, 0, 0.8)'}}></div>
                            <div className="desc">BLE</div>
                        </div>
                        <div className="pnl_item">
                            <div className="color" style={{background: 'rgb(128, 0, 128, 0.6)'}}></div>
                            <div className="desc">Gateway</div>
                        </div>
                    </div>
                    <div className="floor_floor">
                        {currentlevel}
                    </div>
                </div>
                <div className="coldboxFloorPlan_imageMain">
                    <div id="coldboxFloorPlan_imageInner" ref={this.mapdiv}>
                        <img id="coldbox_FloorImage" src={mapItem} style={Mapitem} ref={this.mapwidth} onError={this.ImageError} />
                        <div id="coldboxFloor_overlapp">
                            <svg id="coldbox_polygonsvgbody" xmlns="http://www.w3.org/2000/svg" style={MapAreaStyle}></svg>
                        </div>
                    </div>
                </div>
                <div className="coldboxFloorPlan_opertoroverviewBtn" onClick={this.redirectOperatorOverview}>Operator Overview</div>
            </div>
            <Coldbox_popup
                isPopup={showStatusPopup}
                item={pickedItem}
                closePopup={this.closePopup}
                currentArea={currentlevel}
                clickedBLEID={clickedBLEID}
                history={history}
            ></Coldbox_popup>
            <TestLocUpdate />
            {
                //..
            }
        </div>
    }
}

function mapStateToProps(state) {
    return {
        socket: state.socket,
        UserInfo: state.UserInfo,
        configStorage: state.configStorage,
        currentlevel: state.currentlevel,
        currentSite: state.currentSite,
        currentlevelID:state.currentlevelID,
        MenuDisplay: state.MenuDisplay,
        SiteList: state.SiteList,
        DeviceData: state.DeviceData,
        DashboardPickedColdboxID: state.DashboardPickedColdboxID,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate,
        FloorPlanBottomMenu: state.FloorPlanBottomMenu,
        ImgStorage: state.ImgStorage,
        Polygons: state.Polygons,
        PickedPolygonLoc: state.PickedPolygonLoc,
        BeaconManageList: state.BeaconManageList
    }
}
export default connect(mapStateToProps)(ColdboxFloorPlan)

function GetFloorItem(props) {
    const {DeviceData, currentSite, currentlevel} = props
    if(DeviceData==null||DeviceData.length==null||DeviceData.length==0||currentSite==null||currentlevel==null) return

    const Site = DeviceData[currentSite]
    if(Site==null||Site.nestedLocs==null) return

    const Level = Site.nestedLocs.find(item => {
        return item.locName == currentlevel
    })
    return Level
}
function GetGatewayList(FloorItem) {
    if(FloorItem==null||FloorItem.equipments==null||FloorItem.equipments.length==null) return

    const equipList = FloorItem.equipments
    const returnList = equipList.map(item => {
        return {
            id: item._id,
            status: item.status,
            desc: (item.locUI==null)?'locUI missing':item.locUI.desc,
            coor: (item.locUI==null||item.locUI.indicator==null||item.locUI.indicator.point==null||item.locUI.indicator.point[0]==null)?'--':item.locUI.indicator.point[0].coordinates,
        }
    })

    return returnList
}

function getIValue(selectedID, props) {
    //get bottom_+i, the 'i' value
    if(selectedID == null || selectedID == '' || props == null) return

    const {UserInfo, currentSite, currentlevel} = props
    if(UserInfo==null || currentSite==null || currentlevel==null) return

    let coldBoxList = []
    let curSite = UserInfo.locations[currentSite]
    if (curSite !== null && curSite !== undefined) {
        let floor_items = curSite.nestedLocs.find(function (floorItem) { return floorItem.locName == currentlevel })
        coldBoxList = floor_items.nestedLocs
    }

    if(coldBoxList==null || coldBoxList.length == null) return
    const coldboxIDList = coldBoxList.map(item => item.locName)
    const i = coldboxIDList.indexOf(selectedID)

    return i
}