import React, { useState, useEffect } from 'react'
import './valueCard.scss'
import {GetLocName, GetLocDesc} from '../../../../common/language/locDisplay'
import Moment from 'react-moment'

const ValueCard = props => {
    const {dataFields, deviceItem, isFocus, hoverFunc, cardnumber, cardW, lastUpdate} = props
    const {payload} = deviceItem
    const locName = GetLocName(deviceItem)
    const locDesc = GetLocDesc(deviceItem)
    const [wWidth, setWWidth] = useState(0)

    useEffect(() => {
        setWWidth(window.screen.width)
    }, [])

    const cardWidth = cardW==null?'13.55rem':cardW.toString()+'rem'
    const innercardWidth = cardW==null?'10rem':(cardW-3).toString()+'rem'

    const isAlarm = dataFields.find(item => {
        const {isBool, strKey, colorTrue, colorFalse} = item
        if(!isBool) return //not boolean
        if(
            colorTrue !== 'red' && colorFalse !== 'red'
            && colorTrue !== 'rgb(255, 0, 0)' && colorFalse !== 'rgb(255, 0, 0)'
            && colorTrue !== 'rgba(255, 0, 0, 1)' && colorFalse !== 'rgba(255, 0, 0, 1)'
        ) return // booleans that are not alarm
        if(payload==null) return //no payload
        const isFalse = (
            strKey==null||payload==null||payload[strKey]==null
            ||payload[strKey]==0||payload[strKey]==false
        )
        return !isFalse //if Payload has TRUE, it is alarm on
    }); //boolean

    return <div className='BtmCard' style={{
            background: !isFocus?''
                :
                isAlarm?'red':'var(--focuscardbackground)',
            color: isFocus?'white':'',
            width: cardWidth
        }}  onMouseOver={() => hoverFunc(cardnumber)}>
        <div className='title'>{locName}</div>
        <div className='subTitle' style={{color: isFocus?'white':''}}>{locDesc}</div>
        <div className='valueList' style={{
            color: isFocus?'white':'',
            width: innercardWidth
        }}>
        {
            dataFields.map(item => {
                const {
                    isBool,
                    //bool and value
                    strKey,
                    icon,
                    //bool
                    trueDisplay,
                    falseDisplay,
                    iconColor,
                    iconSize,
                    colorTrue,
                    colorFalse,
                    //value
                    color,
                    fontSize,
                    unit,

                    title
                } = item

                const txtSize = (fontSize==null || (wWidth<=1300 && fontSize[0]>=18))?'18px':fontSize[0]
                const unitSize = (fontSize==null || (wWidth<=1300 && fontSize[1]>=18))?'18px':fontSize[1]

                if(isBool) {
                    const isFalse = (strKey==null||payload==null||payload[strKey]==null||payload[strKey]==0||payload[strKey]==false)
                    return <div className='row' style={{ color: isFocus?'white':isFalse?colorFalse:colorTrue }}>
                        {
                            icon==null?<div style={{display: 'none'}}></div>
                            :
                            <div className={'fa fa-' + icon} style={{ color: isFocus?'white':iconColor, fontSize: fontSize[2] }}></div>
                        }
                        {
                            title==null||title==''?<div style={{display: 'none'}}></div>
                            :
                            <div style={{ color: isFocus?'white':iconColor, fontSize: fontSize[0] }}>{title}</div>
                        }
                        {
                            <div className='value' style={{fontSize: fontSize[0]}}>{isFalse?falseDisplay:trueDisplay}</div>
                        }
                    </div>
                }

                //value
                return <div className='row' style={{ color: isFocus?'white':color }}>
                    {
                        title==null?<></>
                        :
                        <div className='name' style={{fontSize: txtSize}}>{title}</div>
                    }
                    <div className='value' style={{fontSize: txtSize}}>
                        {getValue(payload, strKey)==null?
                            '--'
                            :
                            parseFloat(getValue(payload, strKey).toString()).toString()=='NaN'?
                                getValue(payload, strKey).toString()
                                :
                                parseFloat(getValue(payload, strKey).toString()).toFixed(1)}
                        {
                            unit==null||unit==''?<div className='unit'></div>
                            :
                            <div className='unit' style={{fontSize: unitSize}}>{unit}</div>
                        }
                        
                    </div>
                    {
                        title==null?
                        <div className={'fa fa-' + icon} style={{ fontSize: fontSize[2] }}></div>
                        :<></>
                    }
                </div>
            })
        }
        {
            lastUpdate==null?
            <div style={{display: 'none'}}></div>
            :
            <div className='lastUpdate'>
                <div className='text'>Last Update:</div>
                <div className='datetime'>{getLastUpdate(deviceItem)}</div>
            </div>
        }
        </div>
    </div>
}
export default ValueCard

const getLastUpdate = deviceItem => {
    const lastUpdate = deviceItem.payload.updateDate //deviceItem.payload.lastUpdate  // deviceItem.lastUpdate
    if(lastUpdate==null||lastUpdate=='') return ''
    return <Moment format='DD/MM HH:mm'>{lastUpdate}</Moment>
}

const getValue = (payload, strKey) => {
    if(payload == null) return
    if(payload[strKey] != null) return payload[strKey]

    let value
    Object.keys(payload).forEach(key => {
        if(key.toUpperCase() === strKey.toUpperCase()) {
            value = payload[key]
        }
    })
    return value
}

// Input example to call this card
// dataFields: [
//     //Bool
//     {
//         isBool: true,
//         strKey: 'doorOpen',
//         icon: '', //null if no icon
//         iconColor: 'purple',
//         iconSize: 15,   //px
//         trueDisplay: 'Open',
//         falseDisplay: 'Close',
//         colorTrue: 'red',
//         colorFalse: 'green'
//     },
//     //Value
    // {
//         isBool: false,
    //     strKey: 'temperature',
    //     icon: '',   //null if using text
    //     color: 'red',
    //     fontSize: [30, 12, 20]  // [value, unit, icon] px
    //     unit: '',
    //     title: '',  //null if using icon
    // }
// ]