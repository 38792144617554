export function GetSiteName(siteItem) {
    if(siteItem==null) return 'siteItem is empty'               //exception check

    const langStr = getLang()                                   //lang from localstorage

    if(langStr=='eng') return siteItem.locName                  //ENG

    if(siteItem['locName_zh-hk']==null) return siteItem.locName     //IF no chin is available
    return siteItem['locName_zh-hk']                                //CHIN
}

export function GetSiteDesc(siteItem) {
    if(siteItem==null) return 'siteItem is empty'

    const langStr = getLang()

    if(langStr=='eng' || siteItem['locDesc_zh-hk']==null) return siteItem.locDesc
    return siteItem['locDesc_zh-hk']
}

export function GetLvlName(lvlItem) {
    if(lvlItem==null) return 'lvlItem is empty'

    const langStr = getLang()

    if(langStr=='eng' || lvlItem.locName_chin==null) return lvlItem.locName
    return lvlItem.locName_chin
}

export function GetLvlDesc(lvlItem) {
    if(lvlItem==null) return 'lvlItem is empty'
    // if(lvlItem.locUI==null) return 'locUI is empty'

    const langStr = getLang()

    // if(langStr=='eng' || lvlItem.locUI.desc_chin == null) return lvlItem.locUI.desc
    // return lvlItem.locUI.desc_chin
    if(langStr=='eng' || lvlItem.locDesc_chin==null) return lvlItem.locDesc
    return lvlItem.locDesc_chin
}

export function GetLocName(locItem) {
    if(locItem==null) return 'locItem is empty'

    const langStr = getLang()

    if(langStr=='eng'||locItem.locName_chin==null) return locItem.locName
    return locItem.locName_chin
}

export function GetLocDesc(locItem) {
    //return 'DCS-001, switch room A Near fairwood fast food (G13) (status logo)'  //hardcode test return string
    if(locItem==null) return 'locItem is empty'

    const langStr = getLang()

    if(locItem.locUI==null||locItem.locUI.desc==null) return ''                         // no need to show loc desc
    if(langStr=='eng'||locItem.locUI['desc_zh-hk']==null) return locItem.locUI.desc     //ENG
    return locItem.locUI['desc_zh-hk']                                                  //Chin
}

export function GetSensorTypeName(sensorTypeItem) {
    if(sensorTypeItem==null) return 'sensorTypeItem is empty'
    // if(sensorTypeItem.payload_definition_id==null) return 'payload_definition_id is missing'

    const langStr = getLang()
    
    // if(langStr=='eng'||sensorTypeItem.payload_definition_id.cardTitle_chin==null) return sensorTypeItem.payload_definition_id.cardTitle
    // return sensorTypeItem.payload_definition_id.cardTitle_chin
    if(langStr=='eng') return sensorTypeItem.cardTitle
    if(sensorTypeItem.cardTitle_chin==null) return hardcodeChinType(sensorTypeItem.cardTitle)
    return sensorTypeItem.cardTitle_chin
}
function hardcodeChinType(title) {
    if(title=='Temperature & Humidity') return '溫濕度'
    if(title=='Occupancy') return '在場感測器'
    if(title=='Car Park') return '停車場'
    if(title=='Disabled Parking') return '傷健車位'
    if(title=='AC Power') return '用電量'
    if(title=='LUX') return '光感'
    if(title=='Rubbish Bin') return '垃圾箱'
    if(title=='Waste Bin') return '垃圾桶'
    if(title=='Toilet Occupancy') return '使用中'
    if(title=='Fall Detection') return '跌倒感應'
    if(title=='People Count') return '人數統計'
    if(title=='Door Contact') return '門'
    if(title=='Door') return '門'
    if(title=='Water Leakage (Point)') return '漏水 (point)'
    if(title=='Water Leakage') return '漏水'
    if(title=='Vibration') return '振動'
    if(title=='Push Button') return '按鈕'
    if(title=='LED Tube') return 'LED燈管'

    return title
}


function getLang() {
    const langStr = localStorage.getItem('lang')
    if(langStr==null||langStr=='') {
        localStorage.setItem('lang', 'eng')
        return 'eng'
    }

    return langStr
}