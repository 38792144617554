import React from 'react';
//Redux Import
import { connect } from 'react-redux';
import './SettingPage1.css';

import SetColorTheme from './SetColorTheme';
import SetDarkMode from './SetDarkMode';
import SetSound from './SetSound';
import SetSoundRange from './SetSoundRange';
import SetPopupAlarm from './SetPopupAlarm';
import SetLanguage from './SetLanguage';

class SettingPage1 extends React.Component {
    constructor(props)
    {
        super(props);
    }
    render() {
        return <div className="SettingPage1Container">
            <SetColorTheme/>
            <SetDarkMode/>
            <SetSound/>
            {/* 
            <SetSoundRange/>
            <SetPopupAlarm/>
            <SetLanguage/> */}
        </div>
    }
}

//Redux inside component function
function mapStateToProps(state) {
    return {
      socket: state.socket,
      configStorage: state.configStorage
    };
}

export default connect(mapStateToProps)(SettingPage1);