import React, { useState, useEffect } from 'react'
import './FloorPlan.css'

import { connect } from 'react-redux'

import getColorCode from '../commonfunction'
import FloorPlanInnerWidget from './floorPlanInner/floorPlanInner'
import SelectWidget from './selectWidget/selectWidget'

function FloorPlanWidget(props) {
    const {ToiletType, configStorage} = props
    const [DisplayIndex, setDisplayIndex] = useState(3)     //0 > site list, 1 > level list, 2 > toilet list, 3 > floor plan list

    const {color1, color2, gradient1, gradient2} = getColorCode(ToiletType)

    const FloorPlanInner = (DisplayIndex == 3)?
    <FloorPlanInnerWidget color2={color2} setDisplayIndex={setDisplayIndex} />
    :
    <SelectWidget DisplayIndex={DisplayIndex} setDisplayIndex={setDisplayIndex} />

    return <div className="Toilet_ad_floorplanMain">
        {FloorPlanInner}
    </div>
}

function mapStateToProps(state) {
    return {
        ToiletType: state.ToiletType,        
        configStorage: state.configStorage,
    }
}

export default connect(mapStateToProps)(FloorPlanWidget)

//w = 5
//h = 8