export default function FormatData(inputData, props) {
    if(inputData==null||inputData.length==null||inputData.length==0) return []

    let formattedArray = inputData.map(function(item) {
        if(item.payload==null) return {}
        const {ColdboxID, UPSBatteryCapacity, BeaconLowBatteryFault, RunHrs, Status, ZoneID, ZoneNumber, updateDate} = item.payload
        return {
            coldboxID: ColdboxID,
            group: getGroupOfColdbox(props, ColdboxID),
            UPSBattery: UPSBatteryCapacity,
            iBBattery: BeaconLowBatteryFault==true || BeaconLowBatteryFault==1?'TRUE':'FALSE',  // T and F
            operatinghrs: RunHrs,
            status: Status==0?'Empty':Status==1?'Precooling':Status==2?'loaded':'Damage / maintenance',   // 0 = healthy, 1 = disconnected
            availability: (item.status == null || item.status.status == null)?'No status':item.status.status,
            ZoneID: ZoneID,
            ZoneNumber: ZoneNumber,
            updateDate: updateDate
        }
    })

    return formattedArray
}


const getGroupOfColdbox = (props, coldboxID) => {
    const {GroupList} = props
    if(GroupList==null || coldboxID == null) return

    const {groups} = GroupList
    let groupName = ''
    groups.forEach(gpItem => {
        const {members} = gpItem
        let tryFind = members.find(item => item.locName == coldboxID)
        if(tryFind != null) groupName = gpItem.group_name
    })
    
    return groupName
}