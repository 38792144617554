import React from 'react'
import Loading from '../../../loading'
import { connect } from 'react-redux'
import Moment from 'react-moment'

class Status extends React.Component {
    constructor(props) {
        super() 
        this.renderRow = this.renderRow.bind(this)
        this.groupRow = this.groupRow.bind(this)
        this.state = {
            isLoading: false,
            payloadData: {}
        }
    } 

    renderRow(title, value, unit, statusTxtColor) {
        if(value!=null&&value.min!=null&&value.max!=null) return <div></div>
        return <div className="row">
            <div className="readingTitle">
                {title}
            </div>
            <div className="reading" style={{color: statusTxtColor}}>
                {value} {(unit === "C" ? <sub>o</sub> : unit)}{(unit === "C" ? unit : "")}
            </div>
        </div>
    }

    groupRow(title, data, unit, isBool, trueDisplay, falseDisplay, faultConfig) {
        const isNumber = !isBool
        const isAllSame = isNumber?(data.min==data.max):false

        let minDisplay = ''
        let maxDisplay = ''
        let boolTDisplay = ''
        let boolFDisplay = ''
        if(title=='Sys Start/Stop'||title==' Sys Running Status') {
            if(data===1) boolTDisplay = trueDisplay
            else boolFDisplay = falseDisplay
        }
        if(isAllSame && isNumber) minDisplay = data.min
        if(!isAllSame && isNumber) {
            minDisplay = (data.min.toString().includes('Infinity'))?'-':data.min + '-'       //to prevent infinite value
            maxDisplay = (data.max.toString().includes('Infinity'))?'-':data.max              //to prevent infinite value
        }
        if(!isNumber) {
            if(data == 1) boolTDisplay = trueDisplay
            if(data == 2 || data == 0 || data == undefined || data == null) boolFDisplay = falseDisplay
            if(data == 3) {
                boolTDisplay = trueDisplay + '&'
                boolFDisplay = falseDisplay
            }
        }

        let minFault = ''
        let maxFault = ''
        let boolTFault = ''
        let boolFFault = ''
        if(faultConfig!=null) {
            let {min, max, FValue} = faultConfig
            if(min!=null&&min.min!=null) min = min.min  //debug
            if(max!=null&&max.max!=null) max = max.max  //debug

            if(min!=null&&data.min!=null&&data.min<=min) minFault = 'red'
            if(max!=null&&data.max!=null&&data.max>=max) maxFault = 'red'
            if(FValue!=null&&FValue==true&&data==1) boolTFault = 'red'
            if(FValue!=null&&FValue==false&&data==2) boolFFault = 'red'
            if(FValue!=null&&FValue==true&&data==3) boolTFault = 'red'
            if(FValue!=null&&FValue==false&&data==3) boolFFault = 'red'
        }

        return <div className="row">
            <div className="readingTitle">
                {title}
            </div>
            <div className="reading">
                <div style={{color: minFault}}>{minDisplay}</div>
                <div style={{color: maxFault}}>{maxDisplay}</div>
                <div style={{color: boolTFault}}>{boolTDisplay}</div>
                <div style={{color: boolFFault}}>{boolFDisplay}</div>
                {(unit==="C"?<sub>o</sub>:unit)}{(unit==="C"?unit:"")}
            </div>
        </div>
    }

    componentDidUpdate(prevProps) {
        const preDeviceDataLastUpdate = prevProps.DeviceDataLastUpdate
        const {DeviceDataLastUpdate} = this.props
        if(preDeviceDataLastUpdate==DeviceDataLastUpdate&&prevProps.Item==this.props.Item) return

        const {Item} = this.props
        if(Item==null||Item.payload==null) return

        this.setState({
            isLoading: false,
            payloadData: Item.payload
        })
    }

    render() {
        const _this = this
        const {groupDisplay, isGpDisplay} = this.props
        const {payloadData, isLoading} = this.state
        if(isLoading) return <Loading/>

        if(isGpDisplay) {
            return <fieldset>
                <legend>Status</legend>
                <div className="Coldbox_S_table">
                    <div className="col_half">
                        {_this.groupRow("Mean Weighted Temp", groupDisplay.WeightedMeanTemp, "C", false, null, null, {
                            min: getSetTempLimits(groupDisplay).L_Temp,
                            max: getSetTempLimits(groupDisplay).H_Temp
                        })}
                        {_this.groupRow("Temperature Suction", groupDisplay.TempSuction, "C", false, null, null, {
                            max: getSetTempLimits(groupDisplay).H_SuctTemp
                        })}
                        {_this.groupRow("Temperature Sensor 1", groupDisplay.Temp1, "C", false, null, null, {
                            min: getSetTempLimits(groupDisplay).L_Temp,
                            max: getSetTempLimits(groupDisplay).H_Temp
                        })}
                        {_this.groupRow("Temperature Sensor 2", groupDisplay.Temp2, "C", false, null, null, {
                            min: getSetTempLimits(groupDisplay).L_Temp,
                            max: getSetTempLimits(groupDisplay).H_Temp
                        })}
                        {_this.groupRow("Temperature Sensor 3", groupDisplay.Temp3, "C", false, null, null, {
                            min: getSetTempLimits(groupDisplay).L_Temp,
                            max: getSetTempLimits(groupDisplay).H_Temp
                        })}
                        {_this.groupRow("Temperature Sensor 4", groupDisplay.Temp4, "C", false, null, null, {
                            min: getSetTempLimits(groupDisplay).L_Temp,
                            max: getSetTempLimits(groupDisplay).H_Temp
                        })}
                        {_this.groupRow("Temperature Sensor 5", groupDisplay.Temp5, "C", false, null, null, {
                            min: getSetTempLimits(groupDisplay).L_Temp,
                            max: getSetTempLimits(groupDisplay).H_Temp
                        })}
                        {_this.groupRow("Pressure Condensing", groupDisplay.PressureCondensing, "Bar", false, null, null, {
                            max: getSetTempLimits(groupDisplay).H_condP
                        })}
                        {_this.groupRow("Pressure Evaporating", groupDisplay.PressureEvaporating, "Bar", false, null, null, {
                            min: getSetTempLimits(groupDisplay).L_evaP
                        })}
                        {_this.groupRow("Expansion Valve Opening", groupDisplay.ExpansionValveOpening, "%", false)}
                    </div>
                    <div className="col_half">
                        {_this.groupRow("Battery Capacity", groupDisplay.UPSBatteryCapacity, "%", false, null, null, {
                            min: 10
                        })}
                        {_this.groupRow("RH", groupDisplay.RH, "%", false, null, null, {
                            min: getSetTempLimits(groupDisplay).L_RH,
                            max: getSetTempLimits(groupDisplay).H_RH
                        })}
                        {_this.groupRow("Light", groupDisplay.Light, "", true, "On", "Off")}
                        {_this.groupRow("Running Hr", groupDisplay.RunHrs, "hrs", false)}
                        {_this.groupRow("Door Position", groupDisplay.DoorPosition, "", false, "Open", "Closed")}
                        {_this.groupRow("Defrost Heater", groupDisplay.DefrostHeater, "", true, "On", "Off")}
                        {_this.groupRow("Compressor Fan", groupDisplay.CompressorFan, "", true, "Stop", "Run")}
                        {_this.groupRow("Evaporator Fan", groupDisplay.EvaporatorFan, "", true, "Stop", "Run")}
                        {_this.groupRow("Sys Start/Stop", groupDisplay.SystemStart, "", true, "Stop", "Start", {FValue: true})}
                        {_this.groupRow(" Sys Running Status", groupDisplay.SystemRunningStatus, "", true, "Off", "On", {FValue: true})}
                    </div>
                </div>
            </fieldset>
        }
        return  <fieldset>
        <legend>Status</legend> 
        <div className="Coldbox_S_table">
            <div className="col_half">
                {this.renderRow("Mean Weighted Temp", payloadData.WeightedMeanTemp, "C", IfIsFault(payloadData.WeightedMeanTemp, 'WeightedMeanTemp', payloadData))}
                {this.renderRow("Temperature Suction", payloadData.TempSuction, "C", IfIsFault(payloadData.TempSuction, 'TempSuction', payloadData))}
                {this.renderRow("Temperature Sensor 1", payloadData.Temp1, "C", IfIsFault(payloadData.Temp1, 'Temp1', payloadData))}
                {this.renderRow("Temperature Sensor 2", payloadData.Temp2, "C", IfIsFault(payloadData.Temp2, 'Temp2', payloadData))}
                {this.renderRow("Temperature Sensor 3", payloadData.Temp3, "C", IfIsFault(payloadData.Temp3, 'Temp3', payloadData))}
                {this.renderRow("Temperature Sensor 4", payloadData.Temp4, "C", IfIsFault(payloadData.Temp4, 'Temp4', payloadData))}
                {this.renderRow("Temperature Sensor 5", payloadData.Temp5, "C", IfIsFault(payloadData.Temp5, 'Temp5', payloadData))}
                {this.renderRow("Pressure Condensing", payloadData.PressureCondensing, "Bar", IfIsFault(payloadData.PressureCondensing, 'PressureCondensing', payloadData))}
                {this.renderRow("Pressure Evaporating", payloadData.PressureEvaporating, "Bar", IfIsFault(payloadData.PressureEvaporating, 'PressureEvaporating', payloadData))}
                {this.renderRow("Expansion Valve Opening", payloadData.ExpansionValveOpening, "%", IfIsFault(payloadData.ExpansionValveOpening, 'ExpansionValveOpening'))}
                <div className="row lastUpdateTime">
                    <div className="readingTitle">Last Update Time</div>
                    <div className="reading" style={{color: 'grey'}}>
                        <Moment format="DD-MMM HH:mm">{payloadData.updateDate==null||payloadData.updateDate==undefined?'':payloadData.updateDate.toString()}</Moment>
                    </div>
                </div>
            </div>
            <div className="col_half">
                {this.renderRow("Battery Capacity", payloadData.UPSBatteryCapacity, "%", IfIsFault(payloadData.UPSBatteryCapacity, 'UPSBatteryCapacity'))}
                {this.renderRow("RH", payloadData.RH, "%", IfIsFault(payloadData.RH, 'RH', payloadData))}
                {this.renderRow("Light", payloadData.Light === true ? "On" : "Off", "", IfIsFault(payloadData.Light, 'Light'))}
                {this.renderRow("Running Hr", payloadData.RunHrs, "hrs", IfIsFault(payloadData.RunHrs, 'RunHrs'))}
                {this.renderRow("Door Position", payloadData.DoorPosition=== false ? "Open" : "Closed", "", IfIsFault(payloadData.DoorPosition, 'DoorPosition'))}
                {this.renderRow("Defrost Heater", payloadData.DefrostHeater=== true ? "On" : "Off", "", IfIsFault(payloadData.DefrostHeater, 'DefrostHeater'))}
                {this.renderRow("Compressor Fan", payloadData.CompressorFan=== true ? "Run" : "Stop", "", IfIsFault(payloadData.CompressorFan, 'CompressorFan'))}
                {this.renderRow("Evaporator Fan", payloadData.EvaporatorFan === true ? "Run" : "Stop", "", IfIsFault(payloadData.EvaporatorFan, 'EvaporatorFan'))}
                {this.renderRow("Sys Start/Stop", payloadData.SystemStart === true ? "Start" : "Stop", "", IfIsFault(payloadData.SystemStart, 'SystemStart'))}
                {this.renderRow(" Sys Running Status", payloadData.SystemRunningStatus === true ? "On" : "Off", "", IfIsFault(payloadData.SystemRunningStatus, 'SystemRunningStatus'))}
            </div>
        </div>
        </fieldset>
    }
}

function mapStateToProps(state) {
    return {
        currentlevel: state.currentlevel,
        currentSite: state.currentSite,
        UserInfo: state.UserInfo,
        DeviceData: state.DeviceData,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate,
        DashboardPickedColdboxID: state.DashboardPickedColdboxID
    }
}

export default connect(mapStateToProps)(Status)

function IfIsFault(value, fieldName, payloadData) {
    if(fieldName=='WeightedMeanTemp'||fieldName=='Temp1'||fieldName=='Temp2'||fieldName=='Temp3'||fieldName=='Temp4'||fieldName=='Temp5') {
        const {H_Temp, L_Temp} = getSetTempLimits(payloadData)

        if(value>=H_Temp) return 'red'
        if(value<=L_Temp) return 'red'
    }
    if(fieldName=='TempSuction') {
        const {H_SuctTemp} = getSetTempLimits(payloadData)

        if(value>=H_SuctTemp) return 'red'
    }
    if(fieldName=='RH') {
        const {H_RH, L_RH} = getSetTempLimits(payloadData)

        if(value>=H_RH) return 'red'
        if(value<=L_RH) return 'red'
    }
    if(fieldName=='PressureCondensing') {
        const {H_condP} = getSetTempLimits(payloadData)

        if(value>=H_condP) return 'red'
    }
    if(fieldName=='PressureEvaporating') {
        const {L_evaP} = getSetTempLimits(payloadData)

        if(value<=L_evaP) return 'red'
    }

    const configItem = fieldConfig.find(item => item.name == fieldName)
    if(configItem==null) {
        return ''
    }

    const {type, faultV} = configItem

    if(type=='bool') {
        if(faultV==true&&value==true) return 'red'
        if(faultV==false&&value==false) return 'red'
        if(value==null||value==undefined) return 'red'
    }
    return ''
}

function getSetTempLimits(payloadData) {
    const {
        TempHighSetPoint,
        TempLowSetPoint,
        SuctionTempWarningHighSetPoint,
        RHWarningHighSetPoint,
        RHWarningLowSetPoint,
        CondensingPressureWarningHighSetPoint,
        EvaporatingPressureWarningLowSetpoint
    } = payloadData
    return {
        H_Temp: TempHighSetPoint,
        L_Temp: TempLowSetPoint,
        H_SuctTemp: SuctionTempWarningHighSetPoint,
        H_RH: RHWarningHighSetPoint,
        L_RH: RHWarningLowSetPoint,
        H_condP: CondensingPressureWarningHighSetPoint,
        L_evaP: EvaporatingPressureWarningLowSetpoint
    }
}
const fieldConfig = [
    {
        name: 'DoorPosition',
        type: 'bool',
        faultV: false
    },
    {
        name: 'DefrostHeater',
        type: 'bool',
        faultV: true
    },
    {
        name: 'CompressorFan',
        type: 'bool',
        faultV: false
    },
    {
        name: 'EvaporatorFan',
        type: 'bool',
        faultV: false
    },
    {
        name: 'SystemStart',
        type: 'bool',
        faultV: false
    },
    {
        name: 'SystemRunningStatus',
        type: 'bool',
        faultV: false
    }
]