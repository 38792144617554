import React from 'react'
import './IAQStatus.css'
import { connect } from 'react-redux'

class IAQStatus extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        //FIND DEVICE ITEM
        const {DeviceData, currentSite, currentlevel} = this.props
        if(currentlevel == null || currentSite == null || DeviceData == null) return <div></div>        //exception case
        const FindSite = DeviceData[currentSite]
        if(FindSite == null) return <div></div>             //2nd exceptional case
        const FindLevel = FindSite.nestedLocs.find(function(levelItem) {
            return (levelItem.locName == currentlevel) || (levelItem.locName == currentlevel.toString())
        })
        // console.log(FindLevel)
        const Payload = FindLevel.nestedLocs[0].payload
        // console.log(Payload)

        //IAQ ADN FLOOR DETECTION READINGS
        let SumTemp = 0
        let CountTemp = 0
        let SumHumid = 0
        let CountHumid = 0
        FindLevel.nestedLocs.forEach(deviceItem => {
            if(deviceItem.payload != null) {
                const DevicePayload = deviceItem.payload
                if(DevicePayload.temperature != null) {
                    SumTemp += DevicePayload.temperature
                    CountTemp++
                }
                if(DevicePayload.humidity != null) {
                    SumHumid += DevicePayload.humidity
                    CountHumid++
                }
            }
        })
        let humid_value = 0
        let temp_value = 0
        if(CountTemp != 0) temp_value = (SumTemp/CountTemp).toFixed(1)
        else temp_value = "--"
        if(CountHumid != 0) humid_value = (SumHumid/CountHumid).toFixed(0)
        else humid_value = "--"

        return <div className="Lift_IAQ_main">
            <div className="Lift_graphTitle">
                IAQ Status
            </div>
            <div className="Lift_graphIAQContainer">
                <div className="Lift_graphIAQReadingBox IAQReadingBoxHumid">
                    <div className="fa fa-tint Lift_graphIAQReadingBoxIcon">
                    </div>
                    <div className="Lift_graphIAQReadingBoxValue">
                        {humid_value}
                    </div>
                    <div className="Lift_graphIAQReadingBoxunit">
                        %
                    </div>
                </div>
                <div className="Lift_graphIAQReadingBox IAQReadingBoxTemp">
                    <div className="fa fa-thermometer Lift_graphIAQReadingBoxIcon">
                    </div>
                    <div className="Lift_graphIAQReadingBoxValue">
                        {temp_value}
                    </div>
                    <div className="Lift_graphIAQReadingBoxunit">
                        C
                    </div>
                </div>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        socket: state.socket,
        UserInfo: state.UserInfo,
        currentSite: state.currentSite,
        currentlevel: state.currentlevel,
        DeviceData: state.DeviceData,
        currentlevelID: state.currentlevelID,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate
    }
}
export default connect(mapStateToProps)(IAQStatus)