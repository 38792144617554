import React from 'react'
import './coldboxManage.css'
import { connect } from 'react-redux'
import moment from 'moment'

class ColdboxManage extends React.Component {
    constructor(props) {
        super()
        this.state = {
            CardList: [
                {
                    DisplayTxt: 'Dashboard',
                    DisplayIcon: 'tv',
                    RedirectPage: '/Main/FloorPlan',
                    Desc: 'Show devices and coldbox in Floor Plan',
                },
                {
                    DisplayTxt: 'Alarm and Fault',
                    DisplayIcon: 'bell',
                    RedirectPage: '/Main/Coldbox/Alarm',
                    Desc: '',
                },
                {
                    DisplayTxt: 'Event Log',
                    DisplayIcon: 'history',
                    RedirectPage: '/Main/Coldbox/Event',
                    Desc: '',
                },
                {
                    DisplayTxt: 'Operator Overview',
                    DisplayIcon: 'object-ungroup',
                    RedirectPage: '/Main/FloorPlan/ColdboxOperationOverview',
                    Desc: 'Coldbox status operation and overview',
                },
                {
                    DisplayTxt: 'Coldbox Manage (Individual)',
                    DisplayIcon: 'square',
                    RedirectPage: '/Main/ColdboxManage/coldboxIndividual',
                    Desc: '',
                },
                {
                    DisplayTxt: 'Set-Point',
                    DisplayIcon: 'cog',
                    RedirectPage: '/Main/ColdboxManage/coldboxSetpoint',
                    Desc: '',
                },
                {
                    DisplayTxt: 'LoRaWAN Gateway Management',
                    DisplayIcon: 'cog',
                    RedirectPage: 'Main/ColdboxManage/gateway',
                    Desc: '',
                },
                {
                    DisplayTxt: 'BLE Beacon Management',
                    DisplayIcon: 'cog',
                    RedirectPage: 'Main/ColdboxManage/BLE',
                    Desc: '',
                },
                {
                    DisplayTxt: 'Coldbox Group Management',
                    DisplayIcon: 'cog',
                    RedirectPage: 'Main/ColdboxManage/coldboxgroup',
                    Desc: '',
                },
                {
                    DisplayTxt: 'Coldbox Data Trend',
                    DisplayIcon: 'line-chart',
                    RedirectPage: 'Main/ColdboxDataTrend',
                    Desc: '',
                },
                {
                    DisplayTxt: 'Maintenance Management System',
                    DisplayIcon: 'tv',
                    RedirectPage: 'Main/MmsDashboard',
                    Desc: 'Maintenance Management System',
                },
                {
                    DisplayTxt: 'Maintenance Planner',
                    DisplayIcon: 'tv',
                    RedirectPage: 'Main/MmsPlanner',
                    Desc: 'Maintenance Management System',
                },
                {
                    DisplayTxt: 'Work Order List',
                    DisplayIcon: 'tv',
                    RedirectPage: 'Main/MmsWorkOrderList',
                    Desc: '',
                },
                {
                    DisplayTxt: 'Device WO Summary',
                    DisplayIcon: 'tv',
                    RedirectPage: 'Main/MmsWorkOrderSummary',
                    Desc: '',
                },
                {
                    DisplayTxt: 'Dynamic Maintenance Scheduling Management',
                    DisplayIcon: 'tv',
                    RedirectPage: 'Main/MmsSchedulingManagement/Dynamic',
                    Desc: '',
                }, {
                    DisplayTxt: 'Periodic Maintenance Scheduling Management',
                    DisplayIcon: 'tv',
                    RedirectPage: 'Main/MmsSchedulingManagement/Periodic',
                    Desc: '',
                }, {
                    DisplayTxt: 'Performance Report',
                    DisplayIcon: 'tv',
                    RedirectPage: 'Main/MmsPerformanceReport',
                    Desc: '',
                },
                {
                    DisplayTxt: 'Performance Report by Device',
                    DisplayIcon: 'tv',
                    RedirectPage: 'Main/MmsPerformanceReportDevice',
                    Desc: '',
                },
            ],
            InfoDesc: null,         //when not null, display the info popup
        }

        this.Home = this.Home.bind(this)
        this.Redirect = this.Redirect.bind(this)
        this.Info = this.Info.bind(this)
    }
    Home() {
        this.props.history.push('/Main')
    }
    Redirect(path) {
        this.props.history.push(path)
    }
    Info(desc) {
        //show popup info box
        this.setState({ InfoDesc: desc })
    }

    render() {
        const { CardList, InfoDesc } = this.state
        const _this = this
        return [
            <div className="Coldbox_Manage">
                {
                    CardList.map(function (item) {
                        return [
                            <div className="Coldbox_Manage_groupBtn" onClick={() => _this.Redirect(item.RedirectPage)} key={item.DisplayTxt}>
                                <div className={"fa fa-" + item.DisplayIcon}></div>
                                <div className="txt">{item.DisplayTxt}</div>
                            </div>
                        ]
                    })
                }
                <div className="Coldbox_Manage_LogoDisplay" onClick={this.Home}></div>
            </div>,
            (InfoDesc == null || true) ? <div></div> :
                <div className="Coldbox_Manage_popup" onClick={() => _this.Info(null)}>
                    <div className="Coldbox_Manage_popupDesc">
                        {InfoDesc}
                        <div className="Coldbox_Manage_popuptips">Click to continue</div>
                    </div>
                </div>
        ]
    }
}

const mapStateToProps = state => {
    return {
        UserInfo: state.UserInfo
    }
}

export default connect(mapStateToProps)(ColdboxManage)
