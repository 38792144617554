import React from 'react'
import Map from '../../../Main/FloorPlan/Map/Map'

const GetWidgetByID = (widgetData) => {
    const {i} = widgetData
    const widget = widgetList[i]==null?
    <div className='emptyWidget'>Empty widget: {i}</div>
    :
    widgetList[i]
    
    return <div key={i} data-grid={widgetData}>{widget}</div>
}
export default GetWidgetByID

const widgetList = {
    'a': <div>widget A</div>,
    'b': <Map />,
    //other widget imported from other pages (e.g. floor plan widgets)
    'keyStr': <div>Widget Item Detail</div>
}