import React from 'react';
import './Level.css';

//Redux Import
import { connect } from 'react-redux';

class Level extends React.Component {
    constructor(props) {
        super(props);
    
        this.LevelPick = this.LevelPick.bind(this);
    }

    LevelPick(level) {
        //convert 'level_12' format to '12' as number before save to redux
        const level_str = level.target.id
        const level_replaced = level_str.replace('Level_', '')
        //Trigger action
        this.props.dispatch({ type: 'PickLevel', data: level_replaced })
        this.props.dispatch({
            type: "OpenLevelPopUp"
        })
        //reirect to floorplan
        this.props.history.push('/Main/FloorPlan')
    }

    render() {
        let { id, title, currentlevel, myclass } = this.props;
        let HighlightClass = "";
        if(title == currentlevel)
        {
            HighlightClass = "currentFloor";
        }
        //console.log(currentlevel);
        //console.log(title);
        return <button className={"overlay_div2_levelbox_div " + myclass + " " + HighlightClass} id={ id } onClick={this.LevelPick}>{title}</button>
    }
}

//Redux inside component function
function mapStateToProps(state) {
    return {
      count: state.count,
      log: state.log,
      socket: state.socket,
      level: state.level,
      currentlevel: state.currentlevel,
      SiteList: state.SiteList,
      currentSite: state.currentSite,
      MenuDisplay: state.MenuDisplay
    };
}

export default connect(mapStateToProps)(Level);