import React from 'react'
import './searchbar.css'

//Redux Import
import { connect } from 'react-redux'

class Searchbar extends React.Component {
    constructor(props)
    {
        super()
    }

    render()
    {
        return <div className="Coldbox_GD_header">
            <div className="Right">
                <div className="Coldbox_BackBtn" onClick={this.props.backpage}>
                    <div className="fa fa-arrow-left"></div>
                </div>
                <div className="Coldbox_Tab" onClick={this.props.statusPage}>Status Screen</div>
                <div className="Coldbox_Tab selected">Group Difference: CG PFE</div>
            </div>
            <div className="Left">
                <div className="Coldbox_GD_Title">Coldbox ID</div>
                <select className="Coldbox_GD_droplist">
                    <option>All Coldbox</option>
                    <option>C0001</option>
                    <option>C0002</option>
                    <option>C0003</option>
                </select>
                <div className="Coldbox_GD_Title">Group</div>
                <select className="Coldbox_GD_droplist">
                    <option>All Group</option>
                    <option>CG PFE</option>
                </select>
                <div className="Coldbox_GD_searchBtn">
                    <div className="fa fa-refresh"></div>
                </div>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
    }
}
  
export default connect(mapStateToProps)(Searchbar)