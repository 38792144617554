import React, { Suspense } from 'react';
import type from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import './drawarea.css';

import Canvas from 'react-canvas-polygons';
import $ from 'jquery';

class DrawArea extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fenceData: {
                "Polygon_1": [

                ]
            },
            CurrentArea: 'Polygon_1',
            CurrentAreaNumber: 1
        };

        this.addpoint = this.addpoint.bind(this);
        this.CompleteDraw = this.CompleteDraw.bind(this);
        this.nextArea = this.nextArea.bind(this);
        this.removeArea = this.removeArea.bind(this);
        this.saveArea = this.saveArea.bind(this);

        this.setArea = this.setArea.bind(this);
    };

    handleCleanCanva = () => this.canva.cleanCanvas();

    CompleteDraw(data) {
        console.log('completedraw');
        //console.log(data);
        //console.log(this);
        let thisoutside = this;
        try{
            $.each(data, function( index, value ) {
                //console.log( index + ": " + value );
    
                //get the key which is generated by the draw library
                //filter the polygon items
                let Polygonarray = ["Polygon_1", "Polygon_2", "Polygon_3", "Polygon_4", "Polygon_5", "Polygon_6" ];

                //console.log(CurrentArea);
                if(index.indexOf("Polygon_") >= 0 && !Polygonarray.includes(index))
                {
                    //console.log( index + ": " + value );
                    //console.log(value.length);
                    //console.log(value[value.length - 1]);
                    //add the auto-gen polygon
                    thisoutside.addpoint(value[value.length - 1]);
                }
            });
        }
        catch(err)
        {
            console.error(err);
        }
    }

    removeArea() {
        this.setState({
            fenceData: {
                "Polygon_1": [

                ]
            },
            CurrentArea: 'Polygon_1',
            CurrentAreaNumber: 1
        });

        let fenceDataparam = {
            "Polygon_1": [

            ]
        };

        this.canva.loadDraw(fenceDataparam);
    }

    nextArea() {
        //edit the area name by setting the state
        /*let nextpolygonnumber = this.state.CurrentAreaNumber + 1;
        let fenceDataCurrent = this.state.fenceData;
        fenceDataCurrent["Polygon_" + nextpolygonnumber] = [];
        this.setState({
            CurrentArea: "Polygon_" + nextpolygonnumber,
            CurrentAreaNumber: nextpolygonnumber,
            fenceData: fenceDataCurrent 
        });*/

        let {fenceData} = this.state;
        let currentPolygon = 0;
        let setPolygon = false;
        //check which polygon is empty
        let i=0;
        for (i = 1; i < 11; i++) {
            console.log(fenceData["Polygon_" + i]);
            if(fenceData["Polygon_" + i] == null || fenceData["Polygon_" + i] == [])
            {
                //console.log('NextPolygon:' + i);
                //set state to the first empty item
                currentPolygon = i;
                setPolygon = true;
                break;
            }
        }
        //Check if there is empty polygon available
        if(!setPolygon)
        {
            console.error('Cannot find empty polygons for drawing');
            return;
        }
        
        //set states
        let nextpolygonnumber = currentPolygon;
        let fenceDataCurrent = this.state.fenceData;
        fenceDataCurrent["Polygon_" + nextpolygonnumber] = [];
        console.log({
            CurrentArea: "Polygon_" + nextpolygonnumber,
            CurrentAreaNumber: nextpolygonnumber,
            fenceData: fenceDataCurrent 
        });
        this.setState({
            CurrentArea: "Polygon_" + nextpolygonnumber,
            CurrentAreaNumber: nextpolygonnumber,
            fenceData: fenceDataCurrent 
        });
    }

    saveArea() {
        return this.state.fenceData;
    }
    setArea(fenceData)
    {
        //console.error('setArea() in drawarea.js');
        //console.error(fenceData);
        this.setState({
            fenceData: fenceData
        });
        this.canva.loadDraw(fenceData);
    }

    addpoint(point) {
        //console.log(point);
        //let thisoutside = this;
        //console.log(this.state.fenceData.Polygon_1);
        
        /*$.each(this.state.fenceData, function ( index, value) {            
            if(index == thisoutside.state.CurrentArea)
            {
                console.log('each:' + index);
            }
            //let keyjson = 'keyvalue';
            //let testjsonitem = {
            //    [keyjson]: "some useless value"
            //};
            //console.log(testjsonitem);
        });*/

        let polygonitem = this.state.CurrentArea;
        let fenceData = this.state.fenceData;
        //let pushedploygonarray = ;
        //pushedploygonarray
        fenceData[polygonitem].push(point);
        //console.log(pushedploygonarray);        
        //console.log(fenceData);
        

        this.setState({
            fenceData: fenceData
        //    {
        //        [polygonitem]: pushedploygonarray
        //    }
        });

        let ploygondata = fenceData;
        //{
        //    [polygonitem]: pushedploygonarray
        //};
        //console.log(this.canva.loadDraw);
        this.canva.loadDraw(ploygondata);
    }

    render() {
        //console.log(this.state);
        let { imageUrl, MapWidthHeightRatio } = this.props;
        //console.log(MapWidthHeightRatio);

        let imageInfo = {
            url: imageUrl,
            width: 1050,
            height: 1050/MapWidthHeightRatio
        };
        // const brushSize = 1;
        this.brushSize = 1;
        const tool = 'Polygon';
        const onCompleteDraw = this.CompleteDraw;
        const imageInfoLength = Object.keys(imageInfo).length === 0;

        //console.log(imageUrl); 
        //<img id="draw_image" src={imageUrl}/>,
        //        <!--button variant="outlined" style={{ marginBottom: '20px' }}
        //onClick={this.handleCleanCanva}
        //>
        //    Clean Canvas
        //</button-->
        //<button onClick={this.nextArea}>
        //    Next Area
        //</button>
        return [<Suspense fallback={<CircularProgress color="secondary" />}>
        {imageInfoLength &&
            <CircularProgress color="secondary" />
        }
        <Canvas
            ref={canva => this.canva = canva}
            imgSrc={imageInfo.url}
            height={imageInfo.height}
            width={imageInfo.width}
            tool={tool}
            onCompleteDraw={onCompleteDraw}
            initialData={this.state.fenceData}
            brushSize={this.brushSize}
        />
    </Suspense>]
    }
}

export default DrawArea;

DrawArea.propTypes = {
    tool: type.string,
    imageInfo: type.object,
};