import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import ValueCard from '../../valueCard/valueCard'
import BotCarbonDetect from '../../carbonDetect/carbonDetect'
import PlantBotCard from '../plant/plant'
import BotControlMode from '../controlMode/controlMode'
import {getPumpConsumption} from './pumpCon'

import {getLastUpdate} from './lastUpdate'

//CONFIG
const maxAI = 10

const maxChl = 10
const maxpH = 14
const maxTemp = 100
const maxCon = 200
const maxNTU = 40

const maxflowMeter = 1000

const maxHz = 50

const SwimmingPoolCards = props => {
    const {deviceListItem, hoverFunc, cardnumber, isFocus} = props
    const locID = deviceListItem._id

    useEffect(() => {
        if(deviceListItem==null || isFocus==false) return
        if(deviceListItem.payload_definition_id != null && deviceListItem.payload_definition_id.nameID == 'ATAL_Ctrl_8AI_8DI') {
            const redirectLocName = deviceListItem.locName
            RedirectWhenPointIsClick(props, redirectLocName)        // find the item belongs to, then redirect the device type and locitem
        }
    }, [isFocus])

    const locList = getCurrentLocs(props)
    const ctrlboxes = locList.filter(item => {
        const {locName} = item
        if(locName.includes('Ctrl_000')) return 1
        return 0
    })

    let dataFields = []
    let finalPayload = new Map()
    const ctrl1 = ctrlboxes.find(item => item.locName == 'Ctrl_0001')
    const ctrl2 = ctrlboxes.find(item => item.locName == 'Ctrl_0002')
    const ctrl3 = ctrlboxes.find(item => item.locName == 'Ctrl_0003')
    const PMC0001 = locList.find(item => item.locName == 'PMC-0001')
    const PMC0002 = locList.find(item => item.locName == 'PMC-0002')

    //I:4-20or0-20, V:0-10, so offset for 4-20 = (Vx2-4)/16

    switch(locID) {
        case 'water q 1':
            if(ctrl2 != null && ctrl2.payload != null) {
                finalPayload.set('NTU', ctrl2.payload.VoltageIn_1 / maxAI * maxNTU)  // NTU: AI 1
            }
            if(ctrl3 != null && ctrl3.payload != null) {
                finalPayload.set('chl', ((ctrl3.payload.VoltageIn_1 * 2) - 4) / 16 * maxChl)  // chl: AI 1
                finalPayload.set('pH', ((ctrl3.payload.VoltageIn_2 * 2) - 4) / 16 * maxpH)   // pH:  AI 2
                finalPayload.set('Temp', ((ctrl3.payload.VoltageIn_3 * 2) - 4) / 16 * maxTemp) // Temp:AI 3
                finalPayload.set('dissolve', ((ctrl3.payload.VoltageIn_4 * 2) - 4) / 16 * maxCon) // dissolve AI 4
            }

            dataFields = waterQ
            break
        case 'water q 2':
            if(ctrl2 != null && ctrl2.payload != null) {
                finalPayload.set('NTU', ctrl2.payload.VoltageIn_3 / maxAI * maxNTU)  // NTU: AI 3
            }
            if(ctrl1 != null && ctrl1.payload != null) {
                finalPayload.set('chl', ((ctrl1.payload.VoltageIn_1 * 2) - 4) / 16 * maxChl)      // chl: AI 1
                finalPayload.set('pH', ctrl1.payload.VoltageIn_2 / maxAI * maxpH * 0.726)         // pH:  AI 2
                finalPayload.set('Temp', ((ctrl1.payload.VoltageIn_3 * 2) - 4) / 16 * maxTemp)    // Temp:AI 3
                finalPayload.set('dissolve', ((ctrl1.payload.VoltageIn_4 * 2) - 4) / 16 * maxCon) // dissolve AI 4
            }

            dataFields = waterQ
            break
        case 'water q 3':
            if(ctrl2 != null && ctrl2.payload != null) {
                finalPayload.set('NTU', ctrl2.payload.VoltageIn_2 / maxAI * maxNTU)  // NTU: AI 2
            }
            if(ctrl3 != null && ctrl3.payload != null) {
                finalPayload.set('chl', ((ctrl3.payload.VoltageIn_5 * 2) - 4) / 16 * maxChl)      // chl: AI 5
                finalPayload.set('pH', ((ctrl3.payload.VoltageIn_6 * 2) - 4) / 16 * maxpH)        // pH:  AI 6
                finalPayload.set('Temp', ((ctrl3.payload.VoltageIn_7 * 2) - 4) / 16 * maxTemp)    // Temp:AI 7
                finalPayload.set('dissolve', ((ctrl3.payload.VoltageIn_8 * 2) - 4) / 16 * maxCon) // dissolve AI 8
            }

            dataFields = waterQ
            break
        case 'plant status 1':
            if(ctrl2 != null && ctrl2.payload != null) {
                finalPayload.set('plant', ctrl2.payload.Enable_DI0)  // DI 1, 1=auto, 0=manual
            }
            break
        case 'Automatic Control Mode 1':
            if(ctrl2 != null && ctrl2.payload != null) {
                finalPayload.set('mode3', ctrl2.payload.Enable_DI4)  // mode 3: DI 5
                finalPayload.set('mode4', ctrl2.payload.Enable_DI5)  // mode 4: DI 6
                finalPayload.set('mode1', ctrl2.payload.Enable_DI6)  // mode 1: DI 7
                finalPayload.set('mode2', ctrl2.payload.Enable_DI7)  // mode 2: DI 8
            }

            dataFields = autoCtrl
            break
        case 'Flow Meter 1':
            if(ctrl1 != null && ctrl1.payload != null) {
                finalPayload.set('flowMeter', ((ctrl1.payload.VoltageIn_8 * 2) - 4) / 16 * maxflowMeter)// AI 8
            }

            dataFields = flowMeter
            break
        case 'Carbon detect 1':
            if(ctrl1 != null && ctrl1.payload != null) {
                finalPayload.set('carbon', ctrl1.payload.Enable_DI0)// filter1: DI 1
            }
            break
        case 'Carbon detect 2':
            if(ctrl1 != null && ctrl1.payload != null) {
                finalPayload.set('carbon', ctrl1.payload.Enable_DI1)// filter2: DI 2
            }
            break
        case 'Carbon detect 3':
            if(ctrl1 != null && ctrl1.payload != null) {
                finalPayload.set('carbon', ctrl1.payload.Enable_DI2)// filter3: DI 3
            }
            break
        case 'Carbon detect 4':
            if(ctrl1 != null && ctrl1.payload != null) {
                finalPayload.set('carbon', ctrl1.payload.Enable_DI3)// filter4: DI 4
            }
            break
        case 'Pump statistic 1':
            if(ctrl1 != null && ctrl1.payload != null) {
                finalPayload.set('speedLow', ctrl1.payload.VoltageIn_7 / maxAI * maxHz * 0.985) // Speed low: AI 7   
            }
            if(ctrl2 != null && ctrl2.payload != null) {
                finalPayload.set('speedHigh', ctrl2.payload.VoltageIn_4 / maxAI * maxHz) // Speed High (?): AI 4
                finalPayload.set('vsdFeedback', ctrl2.payload.VoltageIn_6 / maxAI * maxHz) // VSD feedback: AI 6
            }

            dataFields = pumpStat
            break
        case 'Pump statistic 2':
            if(ctrl1 != null && ctrl1.payload != null) {
                finalPayload.set('speedLow', ctrl1.payload.VoltageIn_6 / maxAI * maxHz * 0.928)    // Speed low: AI 6
            }
            if(ctrl2 != null && ctrl2.payload != null) {
                finalPayload.set('speedHigh', ctrl2.payload.VoltageIn_5 / maxAI * maxHz) // Speed High (?): AI 5
                finalPayload.set('vsdFeedback', ctrl2.payload.VoltageIn_7 / maxAI * maxHz) // VSD feedback: AI 7
            }

            dataFields = pumpStat
            break
        case 'Pump Consumption 1':
            if(PMC0001 != null && PMC0001.payload != null) {
                const powerPayload = getPumpConsumption(props)
                finalPayload.set('energy', powerPayload.PMC1.acPower * 0.25)
                finalPayload.set('power', powerPayload.PMC1.p * 0.25)
            }
            dataFields = pumpCons
            break
        case 'Pump Consumption 2':
            if(PMC0002 != null && PMC0002.payload != null) {
                const powerPayload = getPumpConsumption(props)
                finalPayload.set('energy', powerPayload.PMC2.acPower)
                finalPayload.set('power', powerPayload.PMC2.p)
            }
            dataFields = pumpCons
            break
        case 'Pump Status 1':
            if(ctrl1 != null && ctrl1.payload != null) {
                finalPayload.set('vsdOnOff', ctrl1.payload.Enable_DI7) // vsd on off: DI 8
                finalPayload.set('powerHealth', ctrl1.payload.Enable_DI6) // power health: DI 7
            }
            if(ctrl3 != null && ctrl3.payload != null) {
                finalPayload.set('pumpOnOff', ctrl3.payload.Enable_DI1) // pump on off: ctrl3 D0
                finalPayload.set('vsdbypass', ctrl3.payload.Enable_DI0) // vsd bypass on off: ctrl3 D1
            }

            dataFields = pumpStatus
            break
        case 'Pump Status 2':
            if(ctrl1 != null && ctrl1.payload != null) {
                finalPayload.set('vsdOnOff', ctrl1.payload.Enable_DI5) // vsd on off: DI 6
                finalPayload.set('powerHealth', ctrl1.payload.Enable_DI4) // power health: DI 5
            }
            if(ctrl3 != null && ctrl3.payload != null) {
                finalPayload.set('pumpOnOff', ctrl3.payload.Enable_DI5) // pump on off: ctrl3 D2
                finalPayload.set('vsdbypass', ctrl3.payload.Enable_DI4) // vsd bypass on off: ctrl3 D3
            }

            dataFields = pumpStatus
            break
    }

    let updateDate = getLastUpdate({
        ctrl1: ctrl1,
        ctrl2: ctrl2,
        ctrl3: ctrl3,
        PMC1: PMC0001,
        PMC2: PMC0002
    }, locID)
    let deviceItem = {...ctrl1}
    //console.log(locID)
    //console.log([...finalPayload.entries()])

    if(deviceItem != null) {
        // deviceItem.locName = {}
        deviceItem.locName = deviceListItem.locName
        deviceItem._id = deviceListItem._id
        if(deviceItem.locUI == null) {
            deviceItem.locUI = {}
        }
        deviceItem.locUI.desc = ''
        deviceItem.payload = Object.fromEntries(finalPayload)
        deviceItem.payload.updateDate = updateDate
    }
    else {
        return <></> //exception condition
    }

    if(locID.includes('Carbon detect')) {
        return <BotCarbonDetect
            deviceItem={deviceItem}
            isFocus={isFocus}
            hoverFunc={hoverFunc}
            cardnumber={cardnumber}
        />
    }
    if(locID == 'plant status 1') {
        return <PlantBotCard
            deviceItem={deviceItem}
            isFocus={isFocus}
            hoverFunc={hoverFunc}
            cardnumber={cardnumber}
        />
    }
    if(locID == 'Automatic Control Mode 1') {
        return <BotControlMode
            deviceItem={deviceItem}
            isFocus={isFocus}
            hoverFunc={hoverFunc}
            cardnumber={cardnumber}
        />
    }
    // if(deviceItem.payload_definition_id!= null && deviceItem.payload_definition_id.nameID == 'ATAL_Ctrl_8AI_8DI') {
    //     return <></>
    // }
    return <ValueCard
        dataFields={dataFields}
        deviceItem={deviceItem}
        isFocus={isFocus}
        hoverFunc={hoverFunc}
        cardnumber={cardnumber}
        cardW={16}
        lastUpdate='08/04 13:31'
    />
}
const mapStateToProps = state => {
    return {
        currentSite: state.currentSite,
        currentlevel: state.currentlevel,
        currentBottomCard: state.currentBottomCard,
        DeviceData: state.DeviceData,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate, //update this redux storage to trigger render of cards
    }
}
export default connect(mapStateToProps)(SwimmingPoolCards)

const getCurrentLocs = props => {
    const {currentSite, currentlevel, DeviceData} = props
    const site = DeviceData[currentSite]
    const level = site.nestedLocs.find(item => item.locName == currentlevel)
    return level.nestedLocs
}

// data display config
const waterQ = [
    {
        isBool: false,
        strKey: 'chl',
        icon: '',
        color: 'black',
        fontSize: [12, 12, 12],
        unit: 'ppm',
        title: 'Chorine'
    },
    {
        isBool: false,
        strKey: 'pH',
        icon: '',
        color: 'black',
        fontSize: [12, 12, 12],
        unit: 'pH',
        title: 'pH'
    },
    {
        isBool: false,
        strKey: 'Temp',
        icon: '',
        color: 'black',
        fontSize: [12, 12, 12],
        unit: 'C',
        title: 'Temp'
    },
    {
        isBool: false,
        strKey: 'dissolve',
        icon: '',
        color: 'black',
        fontSize: [12, 12, 12],
        unit: 'ppt',
        title: 'Diss. Solids'//'Dissolved Solids'
    },
    {
        isBool: false,
        strKey: 'NTU',
        icon: '',
        color: 'black',
        fontSize: [12, 12, 12],
        unit: 'NTU',
        title: 'Turbidity'
    }
]
const autoCtrl = [
    // mode1: false
    // mode2: false
    // mode3: false
    // mode4: false
]
const flowMeter = [
    {
        isBool: false,
        strKey: 'flowMeter',
        icon: '',
        color: 'black',
        fontSize: [35, 15, 15],
        unit: 'm3/hr',
        title: ''
    }
]

const pumpStat = [
    {
        isBool: false,
        strKey: 'vsdFeedback',
        icon: '',
        color: 'black',
        fontSize: [14, 14, 14],
        unit: 'Hz',
        title: 'VSD Feedback'
    },
    {
        isBool: false,
        strKey: 'speedHigh',
        icon: '',
        color: 'black',
        fontSize: [14, 14, 14],
        unit: 'Hz',
        title: 'Speed High Limit'
    },
    {
        isBool: false,
        strKey: 'speedLow',
        icon: '',
        color: 'black',
        fontSize: [14, 14, 14],
        unit: 'Hz',
        title: 'Speed Low Limit'
    }
]
const pumpCons = [
    {
        isBool: false,
        strKey: 'power',
        icon: '',
        color: 'black',
        fontSize: [14, 14, 14],
        unit: 'kW',
        title: 'Power'
    },
    {
        isBool: false,
        strKey: 'energy',
        icon: '',
        color: 'black',
        fontSize: [14, 14, 14],
        unit: 'kWh',
        title: 'Energy'
    },
]
const pumpStatus = [
    {
        isBool: true,
        strKey: 'pumpOnOff',
        icon: null,
        fontSize: [15, 15, 15],
        unit: '',
        title: 'Pump On/Off',
        trueDisplay: 'On',
        falseDisplay: 'Off',
        colorTrue: 'black',
        colorFalse: 'black'
    },
    {
        isBool: true,
        strKey: 'vsdOnOff',
        icon: null,
        fontSize: [15, 15, 15],
        unit: '',
        title: 'VSD On/Off',
        trueDisplay: 'On',
        falseDisplay: 'Off',
        colorTrue: 'black',
        colorFalse: 'black'
    },
    {
        isBool: true,
        strKey: 'vsdbypass',
        icon: null,
        fontSize: [15, 15, 15],
        unit: '',
        title: 'VSD By-pass On/Off',
        trueDisplay: 'On',
        falseDisplay: 'Off',
        colorTrue: 'black',
        colorFalse: 'black'
    },
    {
        isBool: true,
        strKey: 'powerHealth',
        icon: null,
        fontSize: [15, 15, 15],
        unit: '',
        title: 'Power Health',
        trueDisplay: 'Normal',
        falseDisplay: 'Fault',
        colorTrue: 'green',
        colorFalse: 'red'
    },
]
// const IAQ_15 = [
//     //..
// ]
// const IAQ_16 = [
//     //..
// ]

// point onclick redirect functions
const RedirectWhenPointIsClick = (props, locName) => {
    let menuResult = '100'
    // let newlocName = ''
    let resultCountCard = ''
    //get relative device type
    if(locName.includes('Water-Quality')) {
        menuResult = '100'
    }
    if(locName.includes('Flow Meter')) {
        menuResult = '103'
    }
    if(locName.includes('Carbon-Filter')) {
        menuResult = '104'
    }
    if(locName.includes('Pump-Statistic')) {
        menuResult = '105'
    }
    if(locName.includes('Pump-Consumption')) {
        menuResult = '106'
    }
    if(locName.includes('Pump-Status')) {
        menuResult = '107'
    }
    // if(locName.includes('ATN-IAQ-15in1')) { //..
    //     menuResult = '108'
    // }
    // if(locName.includes('ATN-IAQ-16in1')) { //..
    //     menuResult = '109'
    // }
    //get relative card count
    switch(locName) {
        case 'Main-Pool-Water-Quality':
            resultCountCard = '0'
            break
        case '2nd-Pool-Water-Quality':
            resultCountCard = '1'
            break
        case 'Diving-Pool-Water-Quality':
            resultCountCard = '2'
            break
        case 'Carbon-Filter-001':
            resultCountCard = '0'
            break
        case 'Carbon-Filter-002':
            resultCountCard = '1'
            break
        case 'Carbon-Filter-003':
            resultCountCard = '2'
            break
        case 'Carbon-Filter-004':
            resultCountCard = '3'
            break
        case 'Pump-Statistic-001':
            resultCountCard = '0'
            break
        case 'Pump-Statistic-002':
            resultCountCard = '1'
            break
        case 'Pump-Statistic-003':
            resultCountCard = '2'
            break
        case 'Pump-Consumption-001':
            resultCountCard = '0'
            break
        case 'Pump-Consumption-002':
            resultCountCard = '1'
            break
        case 'Pump-Status-001':
            resultCountCard = '0'
            break
        case 'Pump-Status-002':
            resultCountCard = '1'
            break
        // case 'iaq 15 in 1 item 1': //..
        //     resultCountCard = '0'
        //     break
        // case 'iaq 15 in 1 item 2': //..
        //     resultCountCard = '1'
        //     break
        // case 'iaq 16 in 1 item 1': //..
        //     resultCountCard = '0'
        //     break
        // case 'iaq 16 in 1 item 2': //..
        //     resultCountCard = '1'
        //     break
        default:
            resultCountCard = '0'
            break
    }
    //update reducer function
    // console.log({
    //     menuResult: menuResult,
    //     resultCountCard: resultCountCard
    // })
    props.dispatch({ type: 'FloorPlanBottomMenu', data: "bottom" + menuResult })
    props.dispatch({ type: 'bottomcardhover', data: "bottomcard_-1"})
    
    setTimeout(() => {
        props.dispatch({ type: 'bottomcardhover', data: 'bottomcard_' + resultCountCard })        
    }, 200)
}