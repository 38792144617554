import React from 'react'
// import './soapLevel.scss'

import ValueCard from '../valueCard/valueCard'

const SoapLevel = props => {
    const {item, isFocus, hoverFunc, cardnumber} = props

    const dataField = (item.payload != null && (item.payload['hasSoap']===false || item.payload['hasSoap']===true))?dataFields_hasSoap:dataFields

    return <ValueCard
        dataFields = {dataField}
        deviceItem = {item}
        isFocus={isFocus}
        hoverFunc={hoverFunc}
        cardnumber={cardnumber}
    />

    // return <div className='soapLevelCard'>
    //     <div className='cardItem'>
    //         <div className='inner'>
    //             <div className='title'>
    //                 LocName
    //             </div>
    //             <div className='value'>

    //             </div>
    //         </div>
    //     </div>
    // </div>
}
export default SoapLevel

const dataFields = [
    {
        isBool: true,
        strKey: 'liquidLevel',
        trueDisplay: 'Normal',
        falseDisplay: 'No Soap',
        icon: 'flask',
        iconColor: 'Purple',
        iconSize: 25,   //px
        fontSize: [25, 25, 30],
        colorTrue: 'purple',
        colorFalse: 'red'
    }
]

const dataFields_hasSoap = [
    {
        isBool: true,
        strKey: 'hasSoap',
        trueDisplay: 'Normal',
        falseDisplay: 'No Soap',
        icon: 'flask',
        iconColor: 'Purple',
        iconSize: 25,   //px
        fontSize: [25, 25, 30],
        colorTrue: 'purple',
        colorFalse: 'red'
    }
]