import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux'
import './Planner.css'
import Table from './table/table'
import TableHead from './table/tablehead'
import Timeline from './table/timeline'
import Pagebtns from '../bottom/pagebtns'
import AddButton from '../bottom/addButton'
import Status from './table/status'
import Downloadcsv from '../bottom/downloadcsv'
import SearchBar from '../Planner/searchbar/SearchBar'
import moment from 'moment'
import { type } from 'prop-types';

const axios = require('axios');

// function GetUserIDs(UserInfo) {
//     if(UserInfo == null) return {
//         clientID: '',
//         operatorID: '',
//         socketId: ''
//     }

//     const {client_id, userID, socketId} = UserInfo

//     return {
//         clientID: client_id,
//         operatorID: userID,
//         socketId: socketId
//     }
// }

function Planner(props) {
    const history = useHistory();
    // const {UserInfo} = props
    // const {clientID, operatorID, socketId} = GetUserIDs(UserInfo)
    // const [workOrderList, setWorkOrderList] = useState([]);
    const [isAdvanceSearch, setAdvanceSearch] = useState(false)
    const [workOrderList, setWorkOrderList] = useState([])
    const [plannedList, setPlannedList] = useState([])
    const [filteredList, setFilteredList] = useState([])
    const [pageSliceList, setPageSliceList] = useState([])
    const [startDate, setStartDate] = useState(new Date(moment().subtract(30, 'days')))
    const [endDate, setEndDate] = useState(new Date())

    useEffect(() => {
        refresh()
    }, [startDate, endDate])

    function refresh() {
        // send request to server
        const requestData = {
            "options": {
                "startTime": startDate,
                "endTime": endDate,
                "offset": 0
            }
        }

        const forecastData = {
            "options": {
                "start": new Date(),
                "end": new Date(moment().add(30, 'days')),
                "dummy": true
            }
        }

        console.log("CPSL_ColdboxWorkOrder send!")
        axios.post('https://47.52.20.40:31001/AtalAPI/v1/CPSL_WorkOrder_List', requestData)
            .then(function (response) {
                console.log(response);
                let pageSliceList = response.data.info.map(item => {
                    var startTime = item.requestDate;
                    var endTime = item.requestDate;
                    console.log(item.schedule)
                    if (item.schedule) {
                        startTime = item.schedule.start
                        endTime = item.schedule.end
                        console.log("startTime")
                    }
                    return {
                        "name": item.deviceName,
                        "workOrderType": item.type.toString(),
                        "parts": item.parts,
                        "orderNo": item.number,
                        "requestor": item.requestedStaffName,
                        "startTime": startTime,
                        "endTime": endTime,
                        "workOrder_id": item.order_id,
                        "schedule": startTime + " " + endTime,
                        "workOrder_Status": item.status,
                        "order_id": item.order_id,
                    }
                })
                setWorkOrderList(pageSliceList);
                console.log(pageSliceList);
            })
            .catch(function (error) {
                console.log(error);
            });

        console.log("CPSL_ColdBox_Forecast send!")
        axios.post('https://47.52.20.40:31001/AtalAPI/v1/CPSL_ColdBox_Forecast', forecastData)
            .then(function (response) {
                console.log(response);
                let pageSliceList = response.data.info.map(item => {
                    return {
                        "date": item.date,
                        "dummy": item.dummy,
                        "countTotal": item.countTotal,
                        "countPlanned": item.countPlanned,
                        "countAdhoc": item.countAdhoc,
                        "countAvailable": item.countAvailable,
                        "countLoaded": item.countLoaded,
                        "color": item.color,
                    }
                })
                setPlannedList(pageSliceList);
                console.log(plannedList);
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    // const requestData_CPSL_ColdboxWorkOrder_Planner = {
    //     "msgType": "CPSL_ColdboxWorkOrder_Planner",
    //     "socketId": socketId,
    //     "clientID": clientID,	// ID for CPSL
    //     "method": "Read",
    //     "operatorID": operatorID,	// UserID of the logged in user (for checking user permission)
    //     "startTime": "2021-08-01 00:00:00",		// required
    //     "endTime": "2021-08-01 00:00:00",			// required
    //     "workOrderType": [],			// optional, do not send this field if no filter
    //     "parts": [],					// optional
    //     "orderNo": [],					// optional
    //     "requestor": [],			// optional
    //     "coldboxID": []	// optional, coldbox's lv3 location id
    //   }

    //   const requestData_CPSL_ColdboxMaintenanceForecast = {
    //     "msgType": "CPSL_ColdboxMaintenanceForecast",
    //     "socketId": socketId,
    //     "clientID": clientID,	// ID for CPSL
    //     "operatorID": operatorID	// UserID of the logged in user (for checking user permission)
    //   }

    // console.log("CPSL_ColdboxWorkOrder_Planner send!")
    // console.log("CPSL_ColdboxWorkOrder_Planner", requestData_CPSL_ColdboxWorkOrder_Planner)
    // props.dispatch({
    //     type: 'EmitSocket',
    //     EmitSocketName: "CPSL_ColdboxWorkOrder_Planner",
    //     EmitSocketData: requestData_CPSL_ColdboxWorkOrder_Planner
    // })

    // console.log("CPSL_ColdboxMaintenanceForecast send!")
    // console.log("CPSL_ColdboxMaintenanceForecast", requestData_CPSL_ColdboxMaintenanceForecast)
    // props.dispatch({
    //     type: 'EmitSocket',
    //     EmitSocketName: "CPSL_ColdboxMaintenanceForecast",
    //     EmitSocketData: requestData_CPSL_ColdboxMaintenanceForecast
    // })
    // }, [])

    // if (props.socket != null) {
    //     props.socket.on("CPSL_ColdboxWorkOrder_Planner", data => {
    //         console.log("CPSL_ColdboxWorkOrder_Planner recv!")
    //         console.log('CPSL_ColdboxWorkOrder_Planner', data)
    //         // redux
    //         //console.log(data.status)
    //         //setStatus(data.status)
    //     })

    //     props.socket.on("CPSL_ColdboxMaintenanceForecast", data => {
    //         console.log("CPSL_ColdboxMaintenanceForecast recv!")
    //         console.log('CPSL_ColdboxMaintenanceForecast', data)
    //         // redux
    //         //console.log(data.status)
    //         //setStatus(data.status)
    //     })
    // }

    function UpdateList(key, list) {
        if (key === "pageSliceList")
            setPageSliceList(list)
        else setFilteredList(list)
    }
    function setStartTime(date) {
        setStartDate(date)
    }
    function setEndTime(date) {
        setEndDate(date)
    }
    function AdvanceSearch() {
        setAdvanceSearch(true)
    }
    function closeAdSearch() {
        setAdvanceSearch(false)
    }

    let maintenanceStatus = [
        {
            "Date": "2022-07-27",
            "Planned": 2,
            "Adhoc": 2,
            "Available": 40,
            "Color": "Blue"
        },
        {
            "Date": "2022-07-28",
            "Planned": 2,
            "Adhoc": 1,
            "Available": 41,
            "Color": "Red"
        }
    ]

    const CsvHeaders = [
        {
            key: 'name',
            label: 'Coldbox ID',
        },
        {
            key: 'workOrderType',
            label: 'Type',
        },
        {
            key: 'parts',
            label: 'Parts',
        },
        {
            key: 'orderNo',
            label: 'Work Order',
        },
        {
            key: 'requestor',
            label: 'Requestor',
        },
        {
            key: 'schedule',
            label: 'Schedule',
        }
    ]

    let duration = 30
    let updateCount = 0
    return (
        <div className="Planner">
            <SearchBar isAdvanceSearch={isAdvanceSearch} AdvanceSearch={AdvanceSearch} closeAdSearch={closeAdSearch} workOrderList={workOrderList} UpdateList={UpdateList} refresh={refresh} setStartTime={setStartTime} setEndTime={setEndTime} />
            <div className="Planner_table">
                <TableHead duration={duration} />
                <Timeline duration={duration} />
                {
                    // workOrderList.map(row => {
                    //     return <Table row={row} duration={duration} onClick={()=> history.push({pathname: "/Main/MmsWorkOrder", state: {"mode": "Update", "data": (row)}})}/>
                    // })
                    (pageSliceList != null && pageSliceList != undefined) ?
                        pageSliceList.map(row => {
                            return <Table row={row} onClick={() => history.push({ pathname: "/Main/MmsWorkOrderDetail", state: { "mode": "Update", "data": (row) } })} />
                        }) : ""
                }
                <Status status={plannedList} duration={duration} />
            </div>
            <div className="Planner_bottom">
                <Downloadcsv sortedList={filteredList} headers={CsvHeaders} />
                <Pagebtns sortedList={filteredList} UpdateList={UpdateList} count={updateCount} />
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        UserInfo: state.UserInfo,
        socket: state.socket
    }
}

export default connect(mapStateToProps)(Planner)