import React, { useEffect, useState } from 'react'
import './ToiletSelect.css'

import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMale, faFemale, faUserTie } from '@fortawesome/free-solid-svg-icons'

import getColorCode from './commonfunction'

function ToiletSelectWidget(props) {
    const {ToiletType} = props
    const [selectToilet, setselectToilet] = useState(0)

    useEffect(()=> {
        //when toilet type is updated, save to redux
        props.dispatch({ type: 'ToiletType', data: selectToilet })
    }, [selectToilet])
    useEffect(()=> {
        setTimeout(() => {
            setselectToilet(ToiletType)            
        }, 500)
    }, [])
    useEffect(() => {
        if(ToiletType != selectToilet) setselectToilet(ToiletType)
    }, [ToiletType])

    const {color1, color2, gradient1, gradient2} = getColorCode(ToiletType)
    return <div className="Toilet_select">
        <div className={"Toilet_select_bg item"+selectToilet} style={{background: color2}}></div>
        <div className={selectToilet==1?"Toilet_select_btn highlight":"Toilet_select_btn male"} onClick={()=>setselectToilet(1)}>
            <FontAwesomeIcon icon={faMale} className="fa" />
        </div>
        <div className={selectToilet==2?"Toilet_select_btn highlight":"Toilet_select_btn female"} onClick={()=>setselectToilet(2)}>
            <FontAwesomeIcon icon={faFemale} className="fa" />
        </div>
        <div className={selectToilet==3?"Toilet_select_btn highlight":"Toilet_select_btn disable"} onClick={()=>setselectToilet(3)}>
            {/* <FontAwesomeIcon icon={faUserTie} className="fa" /> */}
            <div id="Toilet_select_disable"></div>
        </div>
    </div>
}

function mapStateToProps(state) {
    return {
        ToiletType: state.ToiletType
    }
}

export default connect(mapStateToProps)(ToiletSelectWidget)

// w = 2
// h = 2