//main function
export default function updateDeviceDataLocation(DeviceData, pushData) {
// return DeviceData //TODO:remove
    if(DeviceData==null||pushData==null||pushData.newLocationInfo==null||pushData.locationInfo==null||pushData.coordinates==null) return
    const {newLocationInfo, locationInfo, coordinates} = pushData

    let updatedDeviceData = DeviceData
    const {locName} = getSiteLvlLocName(locationInfo)     // get site, level, loc name
    const {siteIndex, lvlIndex, locIndex} = getSiteLvlLocIndex(DeviceData, locName)

    if(siteIndex==-1 || lvlIndex==-1 || locIndex==-1) {
        console.error('Index==-1 for locInfo')
        return updatedDeviceData
    }

    // update the coordinate
    let newCoor = coordinates
    if(newCoor[0] > 1) newCoor = [1, newCoor[1]]
    if(newCoor[1] > 1) newCoor = [newCoor[0], 1] 
    updatedDeviceData[siteIndex].nestedLocs[lvlIndex].nestedLocs[locIndex].locUI.indicator.point[0].coordinates = newCoor

    //2. change coldbox floor
    if(!isUpdatedLevel(DeviceData, newLocationInfo)) return updatedDeviceData
    
    let changeFloorItem = updatedDeviceData[siteIndex].nestedLocs[lvlIndex].nestedLocs[locIndex]  //2.4 save the item in parameter
    changeFloorItem.payload.updateDate = new Date().toLocaleString()                              //    update last update time
    updatedDeviceData = removeLoc(DeviceData, locName)                                            //2.5 remove old location

    //paste in new location
    const {addSiteIndex, addLvlIndex} = getNewLocIndex(DeviceData, newLocationInfo)
    updatedDeviceData[addSiteIndex].nestedLocs[addLvlIndex].nestedLocs.push(changeFloorItem)
    
    console.log({   //..
        debug: 'debug 02',
        addSiteIndex: addSiteIndex,
        siteName: updatedDeviceData[addSiteIndex].locName,
        addLvlIndex: addLvlIndex,
        lvlName: updatedDeviceData[addSiteIndex].nestedLocs[addLvlIndex].locName,
        changeFloorItem: changeFloorItem
    })
    return updatedDeviceData
}

//sub functions
const getSiteLvlLocName = (locationInfo, isNew) => {
    let siteName, levelName, locName
    locationInfo.forEach(locInfo => {
        if(locInfo.lvl==1) siteName = locInfo.name
        if(locInfo.lvl==2) levelName = locInfo.name
        if(locInfo.lvl==3) locName = locInfo.name
    })

    if(isNew) return {
        new_siteName: siteName,
        new_levelName: levelName,
        new_locName: locName
    }

    return {
        siteName: siteName,
        levelName: levelName,
        locName: locName
    }
}
const getSiteLvlLocIndex = (DeviceData, locName) => {
    let siteName, levelName
    DeviceData.forEach(siteItem => {
        siteItem.nestedLocs.forEach(levelItem => {
            levelItem.nestedLocs.forEach(locItem => {
                if(locItem.locName==locName) {
                    siteName = siteItem.locName
                    levelName = levelItem.locName
                }
            })
        })
    })
    
    const siteIndex = DeviceData.findIndex(siteItem => siteItem.locName == siteName)                                        // get index of site
    const lvlIndex = DeviceData[siteIndex].nestedLocs.findIndex(lvlItem => lvlItem.locName == levelName)                    // get index of level
    const locIndex = DeviceData[siteIndex].nestedLocs[lvlIndex].nestedLocs.findIndex(locItem => locItem.locName == locName) // get index of loc
    
    return {
        siteIndex: siteIndex,
        lvlIndex: lvlIndex,
        locIndex: locIndex,
        siteName: siteName,
        levelName: levelName,
        locName: locName
    }
}
const isUpdatedLevel = (DeviceData, newLocInfo) => {
    const {new_siteName, new_levelName, new_locName} = getSiteLvlLocName(newLocInfo, true)
    const {siteName, levelName} = getSiteLvlLocIndex(DeviceData, new_locName)
    return !(new_siteName==siteName && new_levelName==levelName)                    //same floor = return false
}
const removeLoc = (DeviceData, locName) => {
    const {siteIndex, lvlIndex, locIndex} = getSiteLvlLocIndex(DeviceData, locName)
    let updatedDeviceData = DeviceData
    if (locIndex > -1) updatedDeviceData[siteIndex].nestedLocs[lvlIndex].nestedLocs.splice(locIndex, 1)
    return updatedDeviceData
}
const getNewLocIndex = (DeviceData, newLocInfo) => {
    const {siteName, levelName} = getSiteLvlLocName(newLocInfo)
    const siteIndex = DeviceData.findIndex(siteItem => siteItem.locName == siteName)
    const lvlIndex = DeviceData[siteIndex].nestedLocs.findIndex(lvlItem => lvlItem.locName == levelName)
    return {
        addSiteIndex: siteIndex,
        addLvlIndex: lvlIndex
    }
}