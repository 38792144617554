import React, { createRef } from 'react'
import './UserManagement.css'
import { connect } from 'react-redux'
import md5 from 'md5'
import FileBase64 from 'react-file-base64'
import Moment from 'react-moment'

import PopupNotification from '../../popupNotification/PopupNotification'
import UserRoleDescription from './UserRoleDescription'

import {getTxt} from '../common/language/language'

class UserManagement extends React.Component {
    constructor(props) {
        super()

        this.backbuttonClicked = this.backbuttonClicked.bind(this)
        this.editRow = this.editRow.bind(this)
        this.AddUser = this.AddUser.bind(this)
        this.ClosePopup = this.ClosePopup.bind(this)

        this.submitUser = this.submitUser.bind(this)
        this.PickCompany = this.PickCompany.bind(this)
        this.displayUserRoles = this.displayUserRoles.bind(this)

        this.handleInputChage = this.handleInputChage.bind(this)       //
        this.siteCheckbox = this.siteCheckbox.bind(this)
        this.UserLevelCheckbox = this.UserLevelCheckbox.bind(this)

        this.DeleteAcc = this.DeleteAcc.bind(this)

        this.ReloadData = this.ReloadData.bind(this)

        this.checkSubmitInputValid = this.checkSubmitInputValid.bind(this)
        this.checkIdUserCanModify = this.checkIdUserCanModify.bind(this)
        this.changePage = this.changePage.bind(this)

        this.confirmDel = this.confirmDel.bind(this)
        this.CloseDelPopup = this.CloseDelPopup.bind(this)

        this.responseListener = this.responseListener.bind(this)

        this.state = {
            isPopupShow: false,
            PopupDetail: {},             //json of all the PopupDetail
            UploadPhoto: null,           //save image if image is uploaded
            //MsgPopup: false,            //show msg box when it is true
            //MsgItem: "",                 //text display on msg popup
            selectedCompany: "",       //selected Company [i], use to gen site list

            isEdit: true,           //true = edit, false = add

            //input items
            username: "",
            accName: "",
            password: "",
            ResubPassword: "",
            RolePicked: "",
            emailInput: "",
            firstName: "",
            lastName: "",
            SitePicked: [],
            SiteList: [{
                name: 'loading company list..'
            }],
            SystemAdminChecked: false,
            EngineerChecked: false,
            OperatorChecked: false,

            EditingID: 0,            //This is the Acc item ID that is editing
            currentPage: 1,

            editingAccID: "",

            DeleteAskPopup: false,   //Show the popup asking before delete
            isLoadingList: false
        }

        this.username = createRef()
        this.accName = createRef()
        this.password = createRef()
        this.ResubPassword = createRef()
        this.RolePicked = createRef()
        this.emailInput = createRef()
        this.firstName = createRef()
        this.lastName = createRef()
    }
    CloseDelPopup() {
        this.setState({
            DeleteAskPopup: false
        })
    }
    confirmDel() {
        this.setState({
            DeleteAskPopup: true
        })
    }
    getFiles(files) {
        try {
            this.setState({
                UploadPhoto: files.base64
            });
        }
        catch (err) {
            console.log('empty or wrong input of Photo');
            console.log(err);
        }
    }

    componentDidUpdate() {
        // console.log("componentDidUpdate")
        console.log(this.props)

        let { UserManageInfo } = this.props;
        let _userManageInfo = []
        if (UserManageInfo == null || UserManageInfo == undefined) {
            _userManageInfo = [{
                name: 'loading company list..'
            }];
        } else {
            _userManageInfo = UserManageInfo.clients


            if (this.state.SiteList !== _userManageInfo) {

                this.setState(prevState => ({
                    ...prevState,
                    SiteList: _userManageInfo
                }))
            }
        }
    }

    componentDidMount() {
        // console.log("componentDidMount")
        console.log(this.props)
        //test msg popup (debug and sample)
        //this.MsgPopup("test popup function");

        //save current url to redux
        let pathUrl = "/Main/UserManagement";
        this.props.dispatch({ type: 'LatestPage', data: pathUrl });

        //request siteList / company list(if super admin)
        let { UserInfo, UserManageInfo } = this.props;
        if (UserInfo == null || UserInfo == undefined) {
            console.error('UserInfo is null Usermanagement.js componentDidMount()');
            return;
        }

        let requestData = {
            msgType: "SiteInfo",
            clientID: UserInfo.client_id,
            username: UserInfo.username
            //socketId will be added inside socket server (before to vincent's server)
        };
        //emit to socket server
        this.props.dispatch({
            type: 'EmitSocket',
            EmitSocketName: "StatRequest",      //StatRequest can request for any type data
            EmitSocketData: requestData
        });

        this.ReloadData();      //get the AccountList (listner inside navbar.js)

        this.responseListener();
    }

    PickCompany(event) {
        this.setState({
            selectedCompany: event.target.value
        });
    }

    backbuttonClicked() {
        //redirect to previous page
        this.props.history.goBack();
    }
    editRow(event) {
        const thisoutside = this
        const isValid = this.checkIdUserCanModify()
        const { username } = this.state
        const { UserManageUserList } = this.props
        if (!isValid) return
        //get the onclick item id
        const onclickBtnId = event.target.id
        const AccID_str = onclickBtnId.replace("UM_table_R", "")
        const findEditItem = UserManageUserList.users.find(function (userItem) {
            return userItem.userID == AccID_str
        })
        console.log(findEditItem)
        //this function should be inside the inner component (per each row)
        let RolePicked = ""
        let SystemAdmin = false
        let Operator = false
        let Engineer = false
        try {
            if (findEditItem.client_id.clientName === "CPSL") {
                if (findEditItem.userLevel !== null && findEditItem.userLevel !== undefined) {
                    console.log(findEditItem.userLevel);
                    console.log(typeof findEditItem.userLevel);
                    findEditItem.userLevel.forEach(i => {
                        console.log(i);
                        switch (i) {
                            case "Operators":
                                Operator = true;
                                break;
                            case "System Admin":
                                break;
                            case "Client Admin":
                                SystemAdmin = true;
                                console.log(SystemAdmin);
                                break;
                            case "Site Admin":
                                Engineer = true;
                                break;
                            default:
                                RolePicked = "-1";      //default not select any item
                                break;
                        }
                    })
                }


            }
            else {

                switch (findEditItem.userLevel) {
                    case "Operators":
                        RolePicked = "3";
                        break;
                    case "System Admin":
                        RolePicked = "0";
                        break;
                    case "Client Admin":
                        RolePicked = "1";
                        break;
                    case "Site Admin":
                        RolePicked = "2";
                        break;
                    default:
                        RolePicked = "-1";      //default not select any item
                        break;
                }
            }
        }
        catch (err) {
            console.log(err);
        }
        if (findEditItem != null && findEditItem != undefined) {
            let siteIDList = [];
            try {
                let inputSiteList = findEditItem.sites;
                let { UserManageInfo } = this.props;

                let SiteFind = this.state.SiteList.find(function (UserManagementItem) // UserManageInfo.find(function(UserManagementItem)
                {
                    return UserManagementItem.name == findEditItem.client_id.clientName;
                });
                console.log("inputSiteList");
                console.log(inputSiteList);
                console.log("SiteFind");
                console.log(SiteFind);
                inputSiteList.forEach(inputSiteListItem => {
                    let foundSiteItem = SiteFind.sites.find(function (siteItem) {
                        console.log(siteItem);
                        console.log(siteItem.name);
                        console.log(inputSiteListItem);
                        return siteItem.name == inputSiteListItem
                    });
                    console.log("foundSiteItem");
                    console.log(foundSiteItem);
                    if (foundSiteItem !== undefined) {

                        siteIDList.push(foundSiteItem.siteID);
                    }
                });
            }
            catch (err) {
                console.log(err);
            }
            console.log("siteIDList");
            console.log(siteIDList);
            //console.log(findEditItem);
            let profileDisplayName;
            let profileEmail;
            let profileFirstName;
            let profileLastName;
            if (findEditItem.profile != null) {
                if (findEditItem.profile.displayName == null) {
                    profileDisplayName = "";
                }
                else {
                    profileDisplayName = findEditItem.profile.displayName;
                }
                if (findEditItem.profile.email == null) {
                    profileEmail = "";
                }
                else {
                    profileEmail = findEditItem.profile.email;
                }
                if (findEditItem.profile.firstName == null) {
                    profileFirstName = "";
                }
                else {
                    profileFirstName = findEditItem.profile.firstName;
                }
                if (findEditItem.profile.lastName == null) {
                    profileLastName = "";
                }
                else {
                    profileLastName = findEditItem.profile.lastName;
                }
            }
            else {
                profileDisplayName = "";
                profileEmail = "";
            }
            thisoutside.setState({
                isPopupShow: true,
                PopupDetail: {
                    Title: "Modify User Account",
                    UserName: username,                 //this.state.username
                    UserID: null,
                },
                isEdit: true,
                //accName: findEditItem.AccountName,
                firstName: profileFirstName,
                lastName: profileLastName,
                selectedCompany: findEditItem.client_id.clientName,
                SitePicked: siteIDList,
                password: "",
                ResubPassword: "",
                RolePicked: RolePicked,
                emailInput: profileEmail,
                username: findEditItem.username,
                editingAccID: findEditItem.userID,     //ID of the user
                accName: profileDisplayName,
                //EditingID: AccID_int,            //the Acc ID that is editing (not using this method now)
                UploadPhoto: findEditItem.profile.image,           //this is the image item, not url
                SystemAdminChecked: SystemAdmin,
                OperatorChecked: Operator,
                EngineerChecked: Engineer
            });
        }
    }
    AddUser() {
        let { UserInfo } = this.props
        let isValid = this.checkIdUserCanModify();
        if (!isValid) {
            return;
        }
        //..
        let currentCompany = ""
        let SiteNewList = [];
        if (UserInfo.company === "CPSL") {

            let companySelected = this.state.SiteList.find(function (companyItem) {//UserManageInfo.find(function(companyItem) {
                return companyItem.name = UserInfo.company
            });
            currentCompany = UserInfo.company;
            //get current site name, id
            if (companySelected !== null && companySelected !== undefined && companySelected !== "") {
                if(companySelected.sites !== undefined && companySelected.sites.length>0){
                    companySelected.sites.forEach(s=>{
                        SiteNewList.push(s.siteID)
                    })
                    
                }
            }
        }

        this.setState({
            isPopupShow: true,
            PopupDetail: {
                Title: "Add New User",
            },
            isEdit: false,
            accName: "",
            firstName: "",
            lastName: "",
            selectedCompany: currentCompany,
            password: "",
            ResubPassword: "",
            RolePicked: "",
            emailInput: "",
            username: "",
            editingAccID: "",
            SystemAdminChecked: false,
            OperatorChecked: false,
            EngineerChecked: false,
            SitePicked: SiteNewList,

            UploadPhoto: null,          //clear the image display
        });
    }
    ClosePopup() {
        this.setState({
            isPopupShow: false,
            PopupDetail: {}
        });
    }
    handleInputChage(event) {
        console.log('handleInputChage()');
        //console.log({[event.target.name]: event.target.value});
        this.setState({
            [event.target.name]: event.target.value
        })
    }
    submitUser() {
        //set to EmitData
        let { username, accName, password, ResubPassword, RolePicked,
            emailInput, firstName, lastName, editingAccID,
            UploadPhoto, selectedCompany, SitePicked, isEdit, SystemAdminChecked, OperatorChecked, EngineerChecked } = this.state;
        let { UserInfo, UserManageInfo } = this.props;

        let isValid = this.checkSubmitInputValid();     //check if the input is valid
        if (!isValid) {
            return;             //terminate the function when it is invalid
        }

        //get the Company id
        if (UserManageInfo == null) {
            console.log('UserManageInfo == null');      //data is not loaded correctly
            return;
        }
        let pickCompany = this.state.SiteList.find(function (CompanyItem) { //UserManageInfo.find(function(CompanyItem){
            return CompanyItem.name == selectedCompany
        })
        let pickCompanyId = pickCompany._id;
        console.log({
            pickCompanyId: pickCompanyId,
            pickCompany: pickCompany
        });

        //get the Site Ids: this.state.EditingID

        //get the submit person
        let submitPerson = UserInfo.username;
        let submitPersonID = UserInfo.userID;
        let password_hash = "";
        if (ResubPassword != "") {
            password_hash = md5(ResubPassword);
        }
        let EmitData = {};
        let roleStr
        try {
            if (UserInfo.company === "CPSL") {
                roleStr = []
                if (SystemAdminChecked) {
                    roleStr.push("Client Admin")
                }

                if (OperatorChecked) {
                    roleStr.push("Operators")
                }
                if (EngineerChecked) {
                    roleStr.push("Site Admin")
                }

            } else {
                roleStr = "";
                switch (RolePicked) {
                    case "0":
                        roleStr = "System Admin";
                        break;
                    case "1":
                        roleStr = "Client Admin";
                        break;
                    case "2":
                        roleStr = "Site Admin";
                        break;
                    case "3":
                        roleStr = "Operators";
                        break;
                    default:
                        break;
                }
            }
        }
        catch (err) {
            console.log(err);
        }
        console.log(roleStr)
        let img = UploadPhoto
        if(UploadPhoto === null){
            img = ""
        }
        if (isEdit) {
            //Other items that also need to edit
            console.log({
                selectedCompany: this.state.selectedCompany,
                SitePicked: this.state.SitePicked,
                accName: this.state.accName,
                RolePicked: this.state.RolePicked,
                SystemAdminChecked: this.state.SystemAdminChecked,
                OperatorChecked: this.state.OperatorChecked,
                EngineerChecked: this.state.EngineerChecked
            });
            if (ResubPassword == "" && password == "") {
                //modify without changing the password
                /*EmitData = {
                    msgType: "ModifyAccount",
                    username: submitPerson,         //user who submit modify
                    //AccountName: editingAccName,         //the old account name, using user ID currently
                    //AccountID: EditingID,           //original format does not have this input
                    newAccountName: username,        //the new account name
                    id: editingAccID,                          //this is the acc unique ID
                };*/
                EmitData = {
                    msgType: "ModifyAccount",
                    operator: submitPerson,     //submitPerson
                    operatorID: submitPersonID,  
                    userID: editingAccID,
                    username: username,
                    //password: password_hash,
                    displayName: accName,
                    image: img,
                    email: emailInput,
                    userLevel: roleStr,
                    siteIDs: SitePicked,
                    client_id: UserInfo.client_id
                }
            }
            else {
                //modify and re-set the password
                /*EmitData = {
                    msgType: "ModifyAccount",
                    username: submitPerson,         //user who submit modify
                    //AccountName: editingAccName,         //the old account name, using user ID currently
                    //AccountID: EditingID,           //original format does not have this input
                    newAccountName: username,        //the new account name
                    id: editingAccID,                          //this is the acc unique ID
                    password: password_hash,
                    //firstName: firstName,
                    //lastname: lastName
                    displayName: accName
                };*/
                EmitData = {
                    msgType: "ModifyAccount",
                    operator: submitPerson,     //submitPerson
                    operatorID: submitPersonID,  
                    userID: editingAccID,
                    username: username,
                    password: password_hash,
                    displayName: accName,
                    image: img,
                    email: emailInput,
                    userLevel: roleStr,
                    siteIDs: SitePicked,
                    client_id: UserInfo.client_id
                }
            }
        }
        else {
            EmitData = {
                msgType: "CreateAccount",
                operator: submitPerson,
                operatorID: submitPersonID,  
                newUsername: username,
                password: password_hash,
                //firstName: firstName,
                //lastname: lastName,
                email: emailInput,
                image: img, //Can upload
                userLevel: roleStr,
                //other not prepared inputs
                siteIDs: SitePicked,
                clientID: pickCompanyId,
                displayName: accName
            };
        }

        console.log(EmitData);
        //return;                     //debug, not emit socket
        //emit to socket server
        this.props.dispatch({
            type: 'EmitSocket',
            EmitSocketName: "UserManageRequest",      //UserManageRequest can request for any type data
            EmitSocketData: EmitData
        });

        this.ReloadData();          //request socket to get the Acc List
        this.ClosePopup();          //close popup window
    }
    siteCheckbox(event) {
        console.log("siteCheckbox")
        //get if it is selected or unselected
        let isSelected = event.target.checked;
        //get the site name
        let { selectedCompany, SitePicked } = this.state;
        let { UserManageInfo } = this.props;
        //get the company name, id
        let companySelected = this.state.SiteList.find(function (companyItem) {//UserManageInfo.find(function(companyItem) {
            return companyItem.name = selectedCompany
        });
        let companyId = companySelected.userID;
        //get current site name, id
        let SiteId = event.target.id;
        //remove any state that the company name is not the same
        //..
        //set to this.state
        let SiteNewList = [];
        if (isSelected) {
            //add item to state
            SiteNewList = SitePicked.concat(SiteId);
        }
        else {
            //remove item from state
            SiteNewList = SitePicked.filter(function (siteItem) {
                return siteItem != event.target.id;
            });
        }
        console.log(SiteNewList);
        this.setState({
            SitePicked: SiteNewList
        });
    }


    UserLevelCheckbox(event) {
        //site list is selected or unselected 
        console.log("UserLevelCheckbox");
        //get if it is selected or unselected
        let isSelected = event.target.checked;
        let checkbox_id = event.target.name;
        console.log(checkbox_id);
        switch (checkbox_id) {
            case "Client Admin":

                this.setState(prevState => ({
                    ...prevState,
                    SystemAdminChecked: !this.state.SystemAdminChecked
                }));
                break;
            case "Site Admin":
                this.setState(prevState => ({
                    ...prevState,
                    EngineerChecked: !this.state.EngineerChecked
                }));
                break;
            case "Operator":
                this.setState(prevState => ({
                    ...prevState,
                    OperatorChecked: !this.state.OperatorChecked
                }));
                break

        }

    }

    DeleteAcc() {
        //get the account ID
        let { editingAccID, isEdit } = this.state;
        let thisoutside = this;
        if (!isEdit) {
            console.warn('DeleteAcc() should not be triggered when isEdit == false');
            return;
        }
        if (editingAccID == null || editingAccID == undefined) {
            console.warn('DeleteAcc() cannot read this.state.EditingID');
            return;
        }
        //submit delete action through socket.io
        let { UserInfo } = this.props;
        let EmitData = {
            msgType: "RemoveAccount",
            //username: submitPerson,         //user who submit modify
            operator: UserInfo.username,
            userID: editingAccID,           //ID of User Account
        }
        console.log(EmitData);
        this.props.dispatch({
            type: 'EmitSocket',
            EmitSocketName: "UserManageRequest",      //UserManageRequest can request for any type data
            EmitSocketData: EmitData
        });

        this.ReloadData();
        this.ClosePopup();

        this.CloseDelPopup();       //close popup
    }
    ReloadData() {
        this.props.dispatch({
            type: 'UserManageUserList',
            data: null,
        })
        let { UserInfo } = this.props;
        //let submitPerson = UserInfo.profile.firstName + " " + UserInfo.profile.lastName;
        let EmitData = {
            msgType: "AccountList",
            //username: submitPerson,         //user who request data
            //username: UserInfo.username
            userID: UserInfo.userID
        }
        this.setState({
            isLoadingList: true
        });
        let thisoutside = this;
        //this function is used to reload the page after add/edit/remove user data
        setTimeout(() => {
            thisoutside.props.dispatch({
                type: 'EmitSocket',
                EmitSocketName: "UserManageRequest",      //UserManageRequest can request for any type data
                EmitSocketData: EmitData
            });
            console.log('ReloadData()');
        }, 1000);
        setTimeout(() => {
            thisoutside.setState({
                isLoadingList: false
            });
        }, 4000);

    }
    checkSubmitInputValid() {
        //console.log('checkSubmitInputValid()');
        const { username, accName, password, ResubPassword, RolePicked,
            emailInput, firstName, lastName, CompanyPicked,
            UploadPhoto, selectedCompany, SitePicked, isEdit, EditingID, SystemAdminChecked, OperatorChecked, EngineerChecked } = this.state
        const { UserInfo, UserManageInfo } = this.props
        const thisoutside = this
        if (ResubPassword != password) {
            thisoutside.PopupNotificationRef.addNotificationPopup("Submit Invalid", "Password input is not the same", null, true)
            return false
        }
        if (selectedCompany == "" || selectedCompany == null) {
            thisoutside.PopupNotificationRef.addNotificationPopup("Submit Invalid", "Please pick at least one company", null, true)
            return false
        }
        if (SitePicked == "" || SitePicked == null) {
            thisoutside.PopupNotificationRef.addNotificationPopup("Submit Invalid", "Please pick at least one Site", null, true)
            return false
        }

        if (username == "") {
            thisoutside.PopupNotificationRef.addNotificationPopup("Submit Invalid", "Login Name Input is empty", null, true)
            return false;
        }
        let spaceInUserName = username.indexOf(' ');
        if (spaceInUserName != -1 && spaceInUserName != "-1") {
            thisoutside.PopupNotificationRef.addNotificationPopup("Submit Invalid", "Login Name Input cannot contain any Space", null, true)
            return false;
        }
        if (accName == "") {
            thisoutside.PopupNotificationRef.addNotificationPopup("Submit Invalid", "Display Name Input is empty", null, true)
            return false;
        }
        if ((password == "" || ResubPassword == "") && !isEdit)   //for edit, can keep input empty
        {
            thisoutside.PopupNotificationRef.addNotificationPopup("Submit Invalid", "Password Input is empty", null, true)
            return false;
        }

        if (UserInfo.company !== "CPSL") {

            if (RolePicked == -1 || RolePicked == "-1" || RolePicked == "") {
                thisoutside.PopupNotificationRef.addNotificationPopup("Submit Invalid", "Role is not picked", null, true)
                return false;
            }
        } else {
            if (!SystemAdminChecked && !OperatorChecked && !EngineerChecked) {
                thisoutside.PopupNotificationRef.addNotificationPopup("Submit Invalid", "Role is not picked", null, true)
                return false;
            }
        }

        //check if email is valid
        if (!emailInput.includes("@") || !emailInput.includes(".com")) {
            thisoutside.PopupNotificationRef.addNotificationPopup("Submit Invalid", "Submited Email is invalid", null, true)
            return false;
        }
        //get user level
        let UserRole = UserInfo.userLevel;
        let PermissionLevel = 0;

        //get input role level
        let RoleInputInt = parseInt(RolePicked);
        let LevelCal = 0
        if (UserInfo.company !== "CPSL") {

            switch (UserRole) {
                case "System Admin":
                    PermissionLevel = 0;
                    break;
                case "Client Admin":
                    PermissionLevel = 1;
                    break;
                case "Site Admin":
                    PermissionLevel = 2;
                    break;
                case "Operators":
                    PermissionLevel = 3;
                    break;
                default:
                    PermissionLevel = -1;
                    break;
            }
            LevelCal = RoleInputInt - PermissionLevel;
        } else {
            if (UserInfo.userLevel !== null && UserInfo.userLevel !== undefined) {
                let highestLevel = -1
                UserInfo.userLevel.forEach(i => {
                    switch (i) {
                        case "System Admin":
                            PermissionLevel = 0;
                            if (highestLevel = -1 || highestLevel >= PermissionLevel) {
                                highestLevel = PermissionLevel
                            }
                            break;
                        case "Client Admin":
                            PermissionLevel = 1;
                            if (highestLevel = -1 || highestLevel >= PermissionLevel) {
                                highestLevel = PermissionLevel
                            }
                            break;
                        case "Site Admin":
                            PermissionLevel = 2;
                            if (highestLevel = -1 || highestLevel >= PermissionLevel) {
                                highestLevel = PermissionLevel
                            }
                            break;
                        case "Operators":
                            PermissionLevel = 3;
                            if (highestLevel = -1 || highestLevel >= PermissionLevel) {
                                highestLevel = PermissionLevel
                            }
                            break;
                        default:
                            PermissionLevel = -1;
                            highestLevel = PermissionLevel
                            break;
                    }
                })

                LevelCal = RoleInputInt - highestLevel;
            }
        }

        //check if user can create that account level
        if (PermissionLevel == -1 || RoleInputInt == -1 || LevelCal <= 0) {
            thisoutside.PopupNotificationRef.addNotificationPopup("Permission", "Cannot create or manage account: same of higher Permission Level than this account. Current Account Permission Level: " + UserRole, null, true)
            return false;
        }
        //return false;       //debug
        if (emailInput == "") {
            thisoutside.PopupNotificationRef.addNotificationPopup("Submit Invalid", "Email Input is empty", null, true)
            return false;
        }
        return true;
    }
    checkIdUserCanModify() {
        //get the user permission level
        const { UserInfo } = this.props
        const UserLevel = UserInfo.userLevel
        const thisoutside = this
        //if the permission level is too low
        if (typeof UserLevel === "string") {
            if (UserLevel != "System Admin" && UserLevel != "Client Admin" && UserLevel != "Site Admin") {
                //user is not admin //show the permission not allow message
                thisoutside.PopupNotificationRef.addNotificationPopup("No Permission", "User is not admin, not allow edit", null, true)
                return false
            }
            else return true
        } else {
            if (UserLevel !== null && UserLevel !== undefined) {
                let lvl = UserLevel.find(function (Item) {//UserManageInfo.find(function(companyItem) {
                    return Item == "System Admin" || Item == "Client Admin" || Item == "Site Admin"
                });

                if (lvl !== null && lvl !== undefined) {
                    return true
                } else {
                    return false
                }

            }
        }
    }
    changePage(event) {
        //remove "PageNumber_" from id
        let clickedID = event.target.id;
        let ID_str = clickedID.replace('PageNumber_', '');
        //parse to int
        let ID_int = parseInt(ID_str, 0);
        //setstate to currentPage
        this.setState({
            currentPage: ID_int
        });
    }
    responseListener() {
        //socket listener for all the response
        let { socket } = this.props;
        if (socket == null || socket.connected == false) {
            alert('Server Connect has error');
            return;
        }
        this.props.socket.on("CreateAccount", data => {
            let thisoutside = this;
            if (data.result == "Success") {
                thisoutside.ReloadData();
            }
        });
        this.props.socket.on("ModifyAccount", data => {
            let thisoutside = this;
            if (data.result == "Success") {
                thisoutside.ReloadData();
            }
        });
    }

    displayUserRoles(roles) {
        let { UserInfo } = this.props
        if (typeof roles === "string") {
            return roles
        } else {
            let str_roles = ""
            if (roles !== null && roles !== undefined) {
                roles.forEach(r => {
                    if (str_roles !== "") {
                        str_roles += ", "
                    }
                    if (UserInfo.company === "CPSL") {
                        if (r === "Client Admin") {
                            str_roles += "System Administrator"
                        } else if (r === "Site Admin") {
                            str_roles += "Engineer"
                        }
                        else {
                            str_roles += r
                        }
                    } else {
                        str_roles += r
                    }
                })
            }
            return str_roles
        }
        return roles
    }

    render() {
        console.log(this.state)
        console.log(this.props)
        let { isPopupShow, selectedCompany } = this.state
        let { UserManageUserList, UserInfo } = this.props
        let hidePopup = ""
        let popupItem = <div></div>

        if (!isPopupShow) {
            hidePopup = "hidePopup"
        }
        else {
            popupItem = <div className={"UserManagePopupBg " + hidePopup} onClick={this.ClosePopup}></div>
        }

        // let {UserManageInfo} = this.props; 
        // if(UserManageInfo == null || UserManageInfo == undefined)
        // {
        //     UserManageInfo = [{
        //         name: 'loading company list..'
        //     }];
        // }else{
        //     UserManageInfo = UserManageInfo.clients
        // }
        //get Site List (selectedCompany is state item)
        let PickedCompany
        try {

            PickedCompany = this.state.SiteList.find(function (companyItem) {//UserManageInfo.find(function(companyItem) {
                return companyItem.name == selectedCompany
            })
        }
        catch (err) {
            console.log(err)
        }
        let SiteList = [{
            desc: "--",
            name: "Site list is empty",
            siteID: "",
        }]
        try {
            if (PickedCompany == null || PickedCompany == undefined) {
                console.log('PickedCompany == null')
            }
            else {
                if (PickedCompany.sites == null || PickedCompany.sites == undefined) {
                    console.log('PickedCompany.sites == null')
                }
                else {
                    SiteList = PickedCompany.sites
                }
            }
        }
        catch (err) {
            console.log(err)
        }

        let { currentPage } = this.state

        let isEditPopupClass = "";
        if (!this.state.isEdit) {
            isEditPopupClass = "RemoveBtnHide"
        }

        let thisoutside = this

        //paging of account table
        //account count:
        let totalAccItems = 0
        try {
            if (UserManageUserList !== null) {
                totalAccItems = UserManageUserList.users.length
            }
        }
        catch (err) {
            console.log(err)
        }
        console.log('total Account: ' + totalAccItems)
        //each page have 15 readings
        let EachPageMax = 15
        //current page: currentPage
        //search current page items
        if (currentPage == 0 || currentPage == null) {
            console.warn('this.state.currentPage should start from 1, cannot be 0 or null')
        }
        let DisplayAccList = []
        try {
            if (UserManageUserList !== null) {
                DisplayAccList = UserManageUserList.users.slice((currentPage - 1) * EachPageMax, (currentPage) * EachPageMax)
            }
        }
        catch (err) {
            console.log(err)
        }
        let totalPages = totalAccItems / EachPageMax
        let PageNumbers = []
        let i = 0
        for (i = 0; i <= totalPages; i++) {
            PageNumbers.push(i + 1)
        }
        let changePage = this.changePage

        console.log(DisplayAccList);
        if (DisplayAccList == null || DisplayAccList.length == 0) {
            return <div className="shadow-lg UserManagementContainer" key="UserManagementContainer">
                <div className="UserManagementLoadIcon">
                    <i class="fa fa-spinner fa-spin"></i>
                </div>
            </div>
        }

        let HideSiteAdmin, HideClientAdmin, HideSystemAdmin, HideSiteList, HideSiteCheckbox = ""

        try {
            if (UserInfo.userLevel == "System Admin") { }      //show all option items
            if (UserInfo.userLevel == "Client Admin") {
                HideSystemAdmin = "hide"
                HideClientAdmin = "hide"
            }      //no client and system admin item
            if (UserInfo.userLevel == "Site Admin") {
                HideSystemAdmin = "hide"
                HideClientAdmin = "hide"
                HideSiteAdmin = "hide"
            }
            if (UserInfo.company == "CPSL") {
                HideSiteList = "hide"
                HideSiteCheckbox = "hide"
            } else {
                HideSiteList = "UserManagePopupSitelist"
                HideSiteCheckbox = "SitelistcheckboxInnerContainer"
            }

        }
        catch (err) {
            console.log(err)
        }

        let confirmPopupClass = ""
        if (!this.state.DeleteAskPopup) {
            confirmPopupClass = "hideConfirmPopup"
        }
        let isLoadingListClass = ""
        if (this.state.isLoadingList) {
            isLoadingListClass = "fa-spin"
        }

        let UserRoles = ["System Administrator", "Operator", "Engineer"]
        return [
            <div className="fa fa-long-arrow-left UserMangemenetBackBtn" onClick={this.backbuttonClicked}>
            </div>,
            <div className="shadow-lg UserManagementContainer">
                <table className="UserManageTable">
                    <tr className="header">
                        <th>
                            {getTxt('userMgt', 'accName')}
                        </th>
                        <th>
                            {getTxt('userMgt', 'userRole')}
                        </th>
                        <th>
                            {getTxt('userMgt', 'company')}
                        </th>
                        <th>
                            {getTxt('userMgt', 'createDateTime')}
                        </th>
                        <th>
                            {getTxt('userMgt', 'lastLogin')}
                        </th>
                        <th>
                            <div className="fa fa-plus UserManageAddBtn" onClick={this.AddUser}></div>
                        </th>
                    </tr>
                    {
                        DisplayAccList.map(function (accItem) {
                            return (<tr>
                                <td>
                                    {accItem.username}
                                </td>
                                <td>
                                    {/* {accItem.userLevel} */}
                                    {thisoutside.displayUserRoles(accItem.userLevel)}
                                </td>
                                <td>
                                    {accItem.client_id.clientName}
                                </td>
                                <td>
                                    <Moment className="" format="YYYY-MMM-DD HH:mm">{accItem.joinedDate}</Moment>
                                </td>
                                <td>
                                    <Moment className="" format="YYYY-MMM-DD HH:mm">{accItem.lastLogin}</Moment>
                                </td>
                                <td>
                                    <div id={"UM_table_R" + accItem.userID} className="fa fa-edit UserManageEditBtn" onClick={thisoutside.editRow}></div>
                                </td>
                            </tr>)
                        })
                    }
                </table>
                <div className="UserManagePageContainer">
                    {
                        PageNumbers.map(function (data) {
                            let highlightPage = "";
                            if (data == currentPage) {
                                highlightPage = "usermanagehighlightPage";
                            }
                            return <div className={"UserManagePageBtn " + highlightPage} id={"PageNumber_" + data}
                                onClick={changePage}>
                                {data}
                            </div>
                        })
                    }
                </div>
                <div className="UMRefreshBtn" onClick={this.ReloadData}>
                    <i className={"fa fa-refresh " + isLoadingListClass}></i>
                </div>
            </div>,
            <div className={"UMDeletePopup_Container " + confirmPopupClass}>
                <div className="UNDeletePopup_Title">
                    {getTxt('userMgt', 'del')}?
                </div>
                <div className="UMDeletePopupBtnContainer">
                    <div className="fa fa-check UMDeletePopup_okBtn" onClick={this.DeleteAcc}>
                    </div>
                    <div className="fa fa-times UNDeletePopup_cancelBtn" onClick={this.CloseDelPopup}>
                    </div>
                </div>
            </div>,
            <div className={"UserManagePopup " + hidePopup}>
                <div className="fa fa-times UserManagePopupCloseBtn" onClick={this.ClosePopup}></div>
                {/*<div className="UserManagePopupHead"></div>
            */}
                <div className="UserManagePopupTitle">
                    {this.state.PopupDetail.Title}
                </div>
                <div className="UserManagePopupRowContainer">
                    <div className="UserManagePopupPhotoContainer">
                        <img src={this.state.UploadPhoto} className="UM_popupPhoto"
                        ></img>
                        <FileBase64 multiple={false} onDone={this.getFiles.bind(this)} />
                    </div>
                    <div className="UserManagePopupInnerContainer">
                        <div className="UserManagePopupRowContainer">
                            <input className="UserManagePopupInput" placeholder="Login Name" name="username" ref={this.username}
                                onChange={this.handleInputChage} value={this.state.username} autoComplete="off"></input>
                            <input className="UserManagePopupInput" placeholder="Display Name" name="accName" ref={this.accName}
                                onChange={this.handleInputChage} value={this.state.accName} autoComplete="off"></input>
                        </div>
                        <div className="UserManagePopupRowContainer">
                            <input className="UserManagePopupInput" placeholder="Password (if need re-set)" name="password" ref={this.password}
                                onChange={this.handleInputChage} type="password" value={this.state.password}
                                autoComplete="off"></input>
                            <input className="UserManagePopupInput" placeholder="re-submit Password (if need re-set)" name="ResubPassword"
                                onChange={this.handleInputChage} ref={this.ResubPassword} type="password"
                                value={this.state.ResubPassword} autoComplete="off"></input>
                        </div>

                        {(UserInfo.company === "CPSL" ?
                            <div className="UserManagePopupRowContainer">
                                <div className="UserManagePopupColContainer">

                                    <input type="email" placeholder="email" className="UserManagePopupInput" name="emailInput"
                                        onChange={this.handleInputChage} ref={this.emailInput} value={this.state.emailInput}
                                        autoComplete="off"></input>
                                    <div className="UserRolecheckboxesContainer"  >

                                        <div className="UserRolecheckboxInnerContainer">Permission Level</div>

                                        <div className="UserRolecheckboxInnerContainer">
                                            <input type="checkbox" id="Client Admin" name="Client Admin"
                                                onChange={this.UserLevelCheckbox} checked={this.state.SystemAdminChecked} />
                                            <label for="Client Admin"><span></span>System Administrator</label>
                                        </div>
                                        <div className="UserRolecheckboxInnerContainer">
                                            <input type="checkbox" id="Site Admin" name="Site Admin"
                                                onChange={this.UserLevelCheckbox} checked={this.state.EngineerChecked} />
                                            <label for="Site Admin" ><span></span>Engineer</label>
                                        </div>
                                        <div className="UserRolecheckboxInnerContainer">
                                            <input type="checkbox" id="Operator" name="Operator"
                                                onChange={this.UserLevelCheckbox} checked={this.state.OperatorChecked} />
                                            <label for="Operator"><span></span>Operator</label>
                                        </div>
                                    </div>
                                </div>
                                {<UserRoleDescription></UserRoleDescription>}
                            </div>
                            :
                            <div className="UserManagePopupRowContainer">
                                <select className="UserManagePopupRole" name="RolePicked" onChange={this.handleInputChage}
                                    ref={this.RolePicked} value={this.state.RolePicked}>
                                    <option value="-1">- Permission Level -</option>
                                    <option value="0" className={HideSystemAdmin}>System Admin</option>
                                    <option value="1" className={HideClientAdmin}>Client Admin</option>
                                    <option value="2" className={HideSiteAdmin}>Site Admin</option>
                                    <option value="3">Operator</option>
                                </select>
                                <input type="email" placeholder="email" className="UserManagePopupInput" name="emailInput"
                                    onChange={this.handleInputChage} ref={this.emailInput} value={this.state.emailInput}
                                    autoComplete="off"></input>
                            </div>
                        )}
                        <div className="UserManagePopupRowContainer">
                            <input type="text" placeholder="User First Name" className="UserManagePopupInput hide" name="firstName"
                                onChange={this.handleInputChage} ref={this.firstName} value={this.state.firstName}
                                autoComplete="off"></input>
                            <input type="text" placeholder="User Last Name" className="UserManagePopupInput hide" name="lastName"
                                onChange={this.handleInputChage} ref={this.lastName} value={this.state.lastName}
                                autoComplete="off"></input>
                        </div>
                        <div className="UserManagePopupRowContainer">
                            <select name="Site List" multiple className={HideSiteList}
                                onChange={this.PickCompany} name="CompanyPicked" value={this.state.selectedCompany}>
                                {
                                    this.state.SiteList.map(companyItem => {
                                        return <option value={companyItem.name}>
                                            {companyItem.name}
                                        </option>
                                    })
                                }
                            </select>
                            <div className="UserManagePopupRowContainer UsermanageSitelist">
                                {
                                    this.state.SiteList.map(SiteItem => {
                                        let { SitePicked } = this.state;
                                        let isPicked = false;
                                        console.log(SitePicked);
                                        SitePicked.forEach(SitePickedItem => {
                                            /*console.log({
                                                SiteItem: SiteItem,
                                                SitePickedItem: SitePickedItem 
                                            });*/
                                            if (SitePickedItem == SiteItem.siteID) {
                                                isPicked = true;
                                            }
                                        });
                                        return <div className={HideSiteCheckbox}>
                                            <input type="checkbox" id={SiteItem.siteID} name={SiteItem.siteID}
                                                onChange={this.siteCheckbox} checked={isPicked} />
                                            <label for={SiteItem.siteID}><span></span>{SiteItem.name}</label>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="UserManagePopupRowContainer UserManagebuttonsContainer">
                    <div className={"UserManageSubmit UserManageRemoveAcc " + isEditPopupClass} onClick={this.confirmDel}>{getTxt('userMgt', 'del')}</div>
                    <div className="UserManageSubmit" onClick={this.submitUser}>{getTxt('userMgt', 'submit')}</div>
                    <div className="UserManageCancel" onClick={this.ClosePopup}>{getTxt('userMgt', 'cancel')}</div>
                </div>
            </div>,
            popupItem,
            <PopupNotification onRef={ref => { this.PopupNotificationRef = ref }} />
        ]
    }
}

//Redux inside component function
function mapStateToProps(state) {
    //console.log(state);
    return {
        UserInfo: state.UserInfo,
        UserManageInfo: state.UserManageInfo,
        UserManageUserList: state.UserManageUserList,
        socket: state.socket
    };
}

export default connect(mapStateToProps)(UserManagement);