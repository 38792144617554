import React from 'react'
import './TableHead.css'
//Redux Import
import { connect } from 'react-redux'


function TableHead(props) {

    return <div className="DMSM_Table">
        <div className={"col2"}>
            Device
            {
                <div style={{ display: 'none' }}></div>
            }
        </div>
        <div className={"col2"}>
            Fault
            {
                <div style={{ display: 'none' }}></div>
            }
        </div>
        <div className={"col2"}>
            Service Impact
            {
                <div style={{ display: 'none' }}></div>
            }
        </div>
        <div className={"col2"}>
            Duration
            {
                <div style={{ display: 'none' }}></div>
            }
        </div>
        <div className={"col2"}>
            Vendor
            {
                <div style={{ display: 'none' }}></div>
            }
        </div>
        <div className={"col3"}>
            Detail
            {
                <div style={{ display: 'none' }}></div>
            }
        </div>
    </div>
}

//Redux inside component function
function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps)(TableHead)