import './LiftCCTV.css';
import React from 'react';
import YouTubePlayer from 'react-player/lib/players/YouTube';
//Redux Import
import { connect } from 'react-redux';
import { red } from '@material-ui/core/colors';
import { isNumber } from 'highcharts';
class LiftCCTV extends React.Component {
    constructor(props)
    {
        super(props);
    }
    render()
    {
        //FIND DEVICE ITEM
        const {DeviceData, currentSite, currentlevel, UserInfo, currentlevelID, configStorage} = this.props;
        if(currentlevel == null || currentSite == null || DeviceData == null) return <div></div>        //exception case
        const FindSite = DeviceData[currentSite]
        if(FindSite == null) return <div></div>             //2nd exceptional case
        const FindLevel = FindSite.nestedLocs.find(function(levelItem) {
            return (levelItem.locName == currentlevel) || (levelItem.locName == currentlevel.toString())
        })
        //console.log(FindLevel)
        const Payload = FindLevel.nestedLocs[0].payload
        //console.log(Payload)
        let reduxDisplaying = {
            temperature: "0.00",
            humidity: "0.00",
            accel_X: -100,
            accel_Y: -100,
            accel_Z: -100,
            doorClose: false,
            floorDetection: false,
            liftOccupied: false,
        }
        if(DeviceData != null) {
            reduxDisplaying = Payload
        }
        if(reduxDisplaying.accel_X != null && isNumber(reduxDisplaying.accel_X)) reduxDisplaying.accel_X = reduxDisplaying.accel_X.toFixed(5)
        if(reduxDisplaying.accel_Y != null && isNumber(reduxDisplaying.accel_Y)) reduxDisplaying.accel_Y = reduxDisplaying.accel_Y.toFixed(5)
        if(reduxDisplaying.accel_Z != null && isNumber(reduxDisplaying.accel_Z)) reduxDisplaying.accel_Z = reduxDisplaying.accel_Z.toFixed(5)

        const ImgPath = configStorage.imgUrl + '/assets/images/floors/' +  UserInfo.locations[currentSite].imgUrl + '/default.jpg'
        return <div className="Lift_cctvContainer">
            <div className="Lift_cctvPlayerSample" style={{backgroundImage: 'url(' + ImgPath + ')'}}>
                <div className="Lift_cctcPlayerSampleInner">
                    CCTV Screen
                    {/* <div className="LiftLogDisplay">
                        <div>
                        {"Temp       :" + reduxDisplaying.temperature}
                        </div>
                        <div>
                        {"Humid      :" + reduxDisplaying.humidity}
                        </div>
                        <div>
                        {"accel_X    :" + reduxDisplaying.accel_X}
                        </div>
                        <div>
                        {"accel_Y    :" + reduxDisplaying.accel_Y}
                        </div>
                        <div>
                        {"accel_Z    :" + reduxDisplaying.accel_Z}
                        </div>
                        <div>
                        {"DoorClose  :" + reduxDisplaying.doorClose}
                        </div>
                        <div>
                        {"floorDetect:" + reduxDisplaying.floorDetection}
                        </div>
                        <div>
                        {"LiftOccup  :" + reduxDisplaying.liftOccupied}
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
        return <div className="Lift_cctvPlayer">
            {/*<YouTubePlayer
              url='https://www.youtube.com/watch?v=mdZzw4Yk_ag'
              playing width="100%" height="100%">
            </YouTubePlayer>*/}
            {/*
            <video controls autoPlay={true}>
                <source src="http://localhost:8080/mycamera" type="video/ogg" />
            </video>            
            */}
            <video controls autoPlay={true} className="Lift_cctvPlayer">
                <source src="http://192.168.1.64:8080/mycamera" type="video/ogg" />
            </video>
        </div>
    }
}

function mapStateToProps(state) {
    return {
      socket: state.socket,
      UserInfo: state.UserInfo,
      currentSite: state.currentSite,
      currentlevel: state.currentlevel,
      DeviceData: state.DeviceData,
      configStorage: state.configStorage,
      DeviceDataLastUpdate: state.DeviceDataLastUpdate              // ** to make the widget render(), since redux variable update inside more than 2 level does not trigger render()
    };
}
export default connect(mapStateToProps)(LiftCCTV);