import React from 'react'
import './Summary.css'
//Redux Import
import { connect } from 'react-redux'
//Component Import
import Side from '../Side/Side'
import DragLayout from './DragLayout/DragLayout'
//Drag lib
import RGL, { WidthProvider } from 'react-grid-layout'
import _ from "lodash"

class Summary extends React.Component {
    constructor(props) {
        super(props)

        this.MenuPick = this.MenuPick.bind(this)
        this.WidgetLock = this.WidgetLock.bind(this)

        this.checkPermission = this.checkPermission.bind(this)

        this.state = {
            LockWidget: false
        }
    }

    componentDidMount() {
        //save current url to redux (since this page onlaod failed right after App.js, disable it)
		/*let pathUrl = "/Main/Summary";
        this.props.dispatch({ type: 'LatestPage', data: pathUrl });  */      
        window.addEventListener('resize', this.resize)

        //request data from server with socket
        //this.requestData();

        this.checkPermission()
    }
    checkPermission() {
        const {UserInfo} = this.props
        let isInvalid = false
        if(UserInfo == null){
            isInvalid = true
            return
        }
        const permission = UserInfo.permissions
        if(permission == null || permission.length == null) isInvalid = true
        const StatisticPermission = permission.find(function(permissionItem) {
            return permissionItem.collectionType == "Statistics"
        })
        if(StatisticPermission == null) isInvalid = true

        if(isInvalid) this.props.history.push("/Main")                   //no permission to access this page, go to /Main
    }
    
    componentWillReceiveProps(nextProps) {
        let currentSiteNext = nextProps.currentSite
        let currentlevelNext = nextProps.currentlevel
        const {currentSite, currentlevel} = this.props
        if(currentSiteNext!=currentlevel||currentlevelNext!=currentSite)
        {
            //request Statistic Data
            //this.requestData();
        }
    }

    requestData() {
        return
        console.log('this.requestData() Summary.js');
        let {currentlevelID} = this.props;
        //CO2 data
        this.props.dispatch({ type: 'EmitSocket', 
        EmitSocketName: "DeviceStatistics",
            EmitSocketData: {
                deviceType:"IAQ-CO2",
                siteID: currentlevelID
            }
        });
        //temp and humid
        this.props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "DeviceStatistics",
            EmitSocketData: {
                deviceType:"IAQ-TH",
                siteID: currentlevelID
            }
        });
        //ACP (Power)
        this.props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "DeviceStatistics",
            EmitSocketData: {
                deviceType:"ACP",
                siteID: currentlevelID
            }
        });
        //request power of previous month (1 column 1 location)
        //..
        //request Heatmap, 1 week per day, 1 column one location
        //..
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }

    resize = () => this.forceUpdate()

    MenuPick() {     
        this.props.dispatch({ type: 'MenuButton' })
    }

    WidgetLock() {
        this.setState({
            LockWidget: !this.state.LockWidget
        })

        this.child.UpdateWidgetLockUnlock()
    }
    
    componentDidCatch(error, info)
    {
        console.error('componentDidCatch() on Summary.js')
        console.error(error)
        console.error(info)
        //redirect to App.js to reload all the datas
        this.props.history.push("/App")
    }

    render() {
        const { history, MenuDisplay } = this.props
        const IsLockWidget = this.state.LockWidget

        let LockButtonClass = ""
        let LockButtonIcon = ""
        if(IsLockWidget) {
            LockButtonIcon = "unlock"
            LockButtonClass = "unLock_Widget_Button"
        }
        else {
            LockButtonIcon = "lock"
            LockButtonClass = "Lock_Widget_Button"
        }
        const IsShowLockBtn = MenuDisplay?'':'hideMenuLock'

        return (
            <div className="body" key={ "SummaryMainbodyOutsideDiv" }>
                <div className={"menu_div_Statistic h-auto " + IsShowLockBtn}>
                    <button className={LockButtonClass} onClick={this.WidgetLock}>
                        <i className={"fa fa-" + LockButtonIcon}>
                        </i>
                    </button>
                </div>
                <div className="shadow-lg floor_base">
                    <DragLayout isLock={IsLockWidget} onRef={ref => (this.child = ref)} key="SummaryDragLayoutComponent" 
                     rowHeight={(window.innerHeight - 75)/20} history={this.props.history} WidgetLock={this.WidgetLock} />
                </div>
            </div>
        )

    }
}

function mapStateToProps(state) {
    return {
        socket: state.socket,
        level: state.level,
        UserInfo: state.UserInfo,
        currentlevel: state.currentlevel,
        SiteList: state.SiteList,
        currentSite: state.currentSite,
        MenuDisplay: state.MenuDisplay,
        currentBottomMenu: state.currentBottomMenu,
        currentBottomCard: state.currentBottomCard,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate
    }
}
export default connect(mapStateToProps)(Summary)