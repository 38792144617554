import React, { useState, useEffect } from 'react'
import './highchart_daily.css'
import { connect } from 'react-redux'

import LoadingWidget from '../../common/loading/loading'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import GetDataList from '../commonfunction'
import ColorCodeList from './chartcolorList'

const MonthList = ['Jan', 'Feb', 'Mar', "Apr", 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

function LiftDoorStatHighchart_daily(props) {
    const {ToShow, DailyOrMonth, forceUpdate} = props   //parent local state
    const {StatisticData, DeviceDataLastUpdate} = props //redux variables
    const {setOptionIsOpen} = props                     //parent function

    const [FloorList, setFloorList] = useState([])
    const [LiftNameList, setLiftNameList] = useState([])
    const [DateList, setDateList] = useState([])

    const [FilterLift, setFilterLift] = useState(null)
    const [FilterFloor, setFilterFloor] = useState(null)
    const [filterDate, setFilterDate] = useState(null)

    const [displayData, setdisplayData] = useState([])
    const [XAxisArray, setXAxisArray] = useState([])

    useEffect(() => {
        //when redux data is loaded
        const DataList = GetDataList(StatisticData)
        const FloorList = getUniqStrList(DataList, 2)
        const FinalFloorList = FloorList==null?[]
        :
        FloorList.sort(function(a, b) {
            return a - b
        })
        .filter(item => {
            return item != null && item != undefined
        })
        .map(item => {
            return item.toString()
        })
        setFloorList(FinalFloorList)

        const LiftNameList = getUniqStrList(DataList, 1)
        setLiftNameList(LiftNameList)

        const DateTimeList = getUniqStrList(DataList, 0)
        const DateList_map = DateTimeList.map(item => {
            const d = new Date(item)
            const MonthStr = MonthList[d.getMonth()]
            return d.getDate().toString() + '-' + MonthStr
        })
        const finalDateList = DateList_map.filter(onlyUnique)
        setDateList(finalDateList)
   }, [StatisticData, DeviceDataLastUpdate])
   
   useEffect(() => {
        //set initial filter item when option list is updated
        if(LiftNameList!=null && LiftNameList.length!=0) setFilterLift(LiftNameList[0])
        if(FloorList!=null && FloorList.length!=0) setFilterFloor(FloorList[0])
        if(DateList!=null && DateList.length!=0) setFilterDate(DateList[0])
    }, [FloorList, LiftNameList, DateList])

    useEffect(() => {
        if(FilterLift == null || FilterFloor == null || filterDate == null) return

        //update the display data when filter droplist updated
        const DataList = GetDataList(StatisticData)

        const floorsplit = FilterFloor.split('/')
        const floorStr = floorsplit[0]

        const splitDate = filterDate.split('-')
        const DateStr = splitDate[0]
        const MonthStr = splitDate[1]
        const MonthIndex = MonthList.indexOf(MonthStr)

        const FilterList = DataList.filter((item) => {
            const d = new Date(item[0])
            const InDateRange = (d.getMonth() == MonthIndex && d.getDate().toString() == DateStr)
            const isSameFloor = (item[2] != null)?(item[2].toString() == floorStr):false
            return item[1] == FilterLift && isSameFloor && InDateRange
        })
        console.log(FilterList)

        let SeriesArray = []
        let i = 3
        const readingtypelist = ['Door', 'Startup', 'Direct Reverse']
        const colorTypeStr = ['type1', 'type2', 'type3']
        for(i; i<=5; i++) {
            const DataSeries = FilterList.map(item => {
                let d = new Date(item[0])
                d = d.setHours(d.getHours() + 8) //hk time offset
                return [
                    d,
                    item[i]
                ]
            })
            const ReadingTypeindex = i - 3      //index is 0, 1, 2
            const HighchartDataItem = {
                type : "area",
                name: readingtypelist[ReadingTypeindex],
                data: DataSeries,
                color: {
                    linearGradient: [0, 0, 0, 500],
                    stops: [
                        [0, ColorCodeList[colorTypeStr[ReadingTypeindex]][0]['color2']],        //solid color
                        [1, 'rgba(2,0,0,0)']                                                    //transoarent
                    ]
                },
            }
            const IsShow = ToShow[ReadingTypeindex]
            if(IsShow) SeriesArray.push(HighchartDataItem)
        }
        setdisplayData(SeriesArray)
    }, [FilterLift, FilterFloor, filterDate, forceUpdate])

    function SelectFilterState(typeStr, Value) {
        switch (typeStr) {
            case 'Lift':
                setFilterLift(Value)
                break
            case 'Floor':
                setFilterFloor(Value)
                break
            case 'Date':
                setFilterDate(Value)
                break
        }
    }

    if(StatisticData == null || StatisticData.doorOpenCount == null || StatisticData.liftStartupCount == null || 
        StatisticData.liftDirectionReverseCount == null) return <LoadingWidget />
    if(!DailyOrMonth) return <LoadingWidget />
    
    let options = {
        chart: {
            type: 'line',
            backgroundColor: 'transparent'
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
                day: '%H:%M'
            }
        },
        yAxis: {
            title: {
                text: ''
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            line: {
                dataLabels: {
                    enabled: true
                },
                enableMouseTracking: false
            }
        },
        credits: {
            enabled: false
        },
        series: displayData
    }

    const floorNameList = getFloorList(props)

    return <div className="LiftDrStat_Highchart_daily">
        <div className="LiftDrStat_chart_daily_option">
            <select onChange={(e)=>SelectFilterState('Lift', e.target.value)}>
                {
                    LiftNameList.map(item => {
                        return <option value={item}>{item}</option>
                    })
                }
            </select>
            <select onChange={(e)=>SelectFilterState('Date', e.target.value)}>
                {
                    DateList.map(item => {
                        return <option value={item}>{item}</option>
                    })
                }
            </select>
            <select onChange={(e)=>SelectFilterState('Floor', e.target.value)}>
                {
                    FloorList.map(item => {
                        return <option value={item}>{floorNameList[item]}/F</option>
                    })
                }
            </select>
            {
                ToShow[0]?
                <div className="LiftDrStat_chart_daily_tab" style={{background: ColorCodeList.type1[0].color2}} onClick={()=> setOptionIsOpen(true)}>Door Count</div>
                :
                <div style={{display: 'none'}}></div>
            }
            {
                ToShow[1]?
                <div className="LiftDrStat_chart_daily_tab" style={{background: ColorCodeList.type2[0].color2}} onClick={()=> setOptionIsOpen(true)}>Startup Count</div>
                :
                <div style={{display: 'none'}}></div>
            }
                        {
                ToShow[2]?
                <div className="LiftDrStat_chart_daily_tab" style={{background: ColorCodeList.type3[0].color2}} onClick={()=> setOptionIsOpen(true)}>Direct Reverse Count</div>
                :
                <div style={{display: 'none'}}></div>
            }
        </div>
        <HighchartsReact highcharts={Highcharts} options={options} constructorType={'chart'} className="Lift_highchartDaily"
                    containerProps={{ style: { height: '100%', width: '100%' } }}/>
    </div>
}

function mapStateToProps(state) {
    return {
        StatisticData: state.StatisticData,
        currentSite: state.currentSite,
        currentlevel: state.currentlevel,
        DeviceData: state.DeviceData,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate,
    }
}
export default connect(mapStateToProps)(LiftDoorStatHighchart_daily)


function getUniqStrList(ArrayList, keystr) {
    const MapStrList = ArrayList.map((item) => {
        return item[keystr]
    })
    return MapStrList.filter(onlyUnique)
}
function onlyUnique(value, index, self) {
    return self.indexOf(value) === index
}

const getFloorList = (props) => {
    const {DeviceData, currentSite, currentlevel} = props
    if(DeviceData==null || currentSite==null || currentlevel==null) return []
    const siteItem = DeviceData[currentSite]
    if(siteItem==null) return []
    const levelItem = siteItem.nestedLocs.find(item => {
        return item.locName == currentlevel
    })
    if(levelItem==null) return []

    const locItem = levelItem.nestedLocs[0]
    return locItem.payload.LiftLevels
}