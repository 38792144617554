import React from 'react'
import './Heatmap.css'
import HighCharts from 'highcharts/highcharts'
import highchartsMore from 'highcharts/highcharts-more'
import heatmap from "highcharts/modules/heatmap"
import HighchartsReact from 'highcharts-react-official'
import { connect } from 'react-redux'
import {getTxt} from '../../common/language/language'

highchartsMore(HighCharts)
heatmap(HighCharts)

class HeatMap extends React.Component {
    constructor(props) {
        super(props)
        this.Heatmap_maindiv = React.createRef()
        this.Heatmap_header = React.createRef()

        this.ReadingTypeChange = this.ReadingTypeChange.bind(this)
        this.genHighchart = this.genHighchart.bind(this)
        this.loadData = this.loadData.bind(this)

        this.formatDate = this.formatDate.bind(this)
        this.convertDate = this.convertDate.bind(this)

        this.state = {
            chartOption: {},
            ReadingType: "temp",     //"Temp" or "Humid"
            ChartData: []
        }
    }

    componentDidMount() {
        //Temperature, use red and blue
        const thisoutside = this
        const {ReadingType} = this.state
        this.genHighchart('#88faff', "#ff0000", "C", thisoutside.props, ReadingType)
        this.loadData(thisoutside.props)
    }
    convertDate(DateInput) {
        let DateMonth = DateInput.getMonth() + 1
        let DateDay = DateInput.getDate()
        if(DateMonth < 10) DateMonth = "0" + DateMonth
        if(DateDay < 10) DateDay = "0" + DateDay
        let HourStr = DateInput.getHours()
        if(HourStr < 10) HourStr = "0" + HourStr
        let MinutesStr = DateInput.getUTCMinutes()
        if(MinutesStr < 10) MinutesStr = "0" + MinutesStr
        const DateStr = DateInput.getFullYear() + "-" + DateMonth + "-" + DateDay + "T" + HourStr + ":" + MinutesStr + ":00Z"
        return DateStr
    }
    loadData(props) {
        const {currentlevelID} = props

        //datetime range variables (today, 7 day)
        let startDate = new Date()
        let EndDate = new Date()
        startDate.setDate(startDate.getDate()-10)
        EndDate.setDate(EndDate.getDate())
        let startDateStr = this.convertDate(startDate)
        let EndDateStr = this.convertDate(EndDate)

        //emit request data format (one for CO2, one for Temp and Humid)
        const {UserInfo} = this.props
        let clientID = ""
        if(UserInfo != null) clientID = UserInfo.client_id
        const requestData = {
            "msgType": "LocationStatistics",
            "msgID": "HeatmapIAQ",
            "socketId": "8SpxJb0PqsT0zcboAAEa",
            "clientID": clientID,
            // "deviceType": "IAQ-TH",						// IAQ-CO2 / IAQ-TH / ACP (Power)
            "payload_field": ["temperature", "humidity", "co2", "illuminance"],
            "timeConstraint": {
                  "start": startDateStr,
                  "end": EndDateStr,
            },
            "timeFrequency": {
              "unit": "day",                    // month / day / hours / minutes
              "value": 1
            },
            "locationConstraint": {
              "locLvl": 2,                      // 1 - Site / 2 - Floor / 3 - Location
              "id": currentlevelID  // location id
            },
            "locationGrouping": 3,              // 1 - Site / 2 - Floor / 3 - Location
            "calculationMethod": "average"      // average / difference
        }
        console.log(requestData)
        this.props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "StatRequest",
            EmitSocketData: requestData
        })
    }

    formatDate(date) {
        let d = date;
        let month = (d.getMonth() + 1);
        let day = d.getDate();
        let year = d.getFullYear();
    
        if (month < 10)
            month = '0' + month;
        if (day < 10)
            day = '0' + day;
        return [year, month, day].join('-');
    }

    genHighchart(colortheme1, colortheme2, Unit, InputProps, stateRadingType) {
        //clear the chart option data before reload the chart data
        this.setState({
            chartOption: {
                credits: false,
                chart: {
                    type: 'heatmap',
                    marginTop: 40,
                    marginBottom: 80,
                    plotBorderWidth: 1,
                    backgroundColor: {
                        linearGradient: [0, 0, 500, 500],
                        stops: [
                            [0, 'rgba(255, 255, 255, 0)'],
                            [1, 'rgba(255, 255, 255, 0)']
                        ]
                    },
                },          
                yAxis: [{
                    labels: {
                        style: {
                            color: 'var(--optiontext)'
                        },
                    },
                },], 
                xAxis: [{
                    labels: {
                        style: {
                            color: 'var(--optiontext)'
                        },
                    },
                },], 
                legend: {
                    backgroundColor: 'rgba(255, 255, 255, .6)'
                },
                series: [{
                    name: 'Sales per employee',
                    borderWidth: 1,
                    data: [],
                    dataLabels: {
                        enabled: true,
                        color: '#000000',
                        formatter: function () {
                            if(this.point.value != null)
                            {
                                return this.point.value.toFixed(2);
                            }
                            else
                            {
                                return "";
                            }
                        }
                    }
                }]
            }
        })

        const caledHeight = this.Heatmap_maindiv.current.offsetHeight - this.Heatmap_header.current.offsetHeight
        //gen the date for previous 7 days
        let dateArray = [];
        const currentDate = new Date()
        let i = 0
        for(i = 1; i < 8; i++) {
            let newDate = new Date()
            newDate.setDate(currentDate.getDate()+i-1)
            //gen the day string for previous 7 days
            const newDay = newDate.getDay()
            let DayString = ""
            switch(newDay)
            {
                case 1:
                    DayString = "Monday"
                    break
                case 2:
                    DayString = "Tuesday"
                    break
                case 3:
                    DayString = "Webnesday"
                    break
                case 4:
                    DayString = "Thursday"
                    break
                case 5:
                    DayString = "Friday"
                    break
                case 6:
                    DayString = "Saturday"
                    break
                case 0:
                    DayString = "Sunday"
                    break
            }
            dateArray.push(DayString)       //list of string for display in X-axis name
        }
        //get and display the device Name
        const ReadingType = stateRadingType
        const {StatHeatmapTH, StatHeatmapCO2} = InputProps   //should not get from this redux, would have another place to get value
        let dataList = []
        try {
            switch(ReadingType) {
                case "temp":
                    if(StatHeatmapTH != null) dataList = StatHeatmapTH.data.temperature
                    break
                case "humid":
                    if(StatHeatmapTH != null) dataList = StatHeatmapTH.data.humidity
                    break
                case "CO2":
                    if(StatHeatmapTH != null) dataList = StatHeatmapTH.data.co2
                    break
                case "illu":
                    if(StatHeatmapTH != null) dataList = StatHeatmapTH.data.illuminance
                    break
                default:
                    console.log('other switch:' + ReadingType)
                    break
            }
        }
        catch(err) {
            console.log(err)
        }
        //get the distint device list
        let distinctList = []
        let deviceCount = 0
        if(dataList == null || dataList == undefined) console.log('dataList == null')
        else {
            dataList.forEach(dataItem => {
                const tryfound = distinctList.find(function(listItem){
                    return listItem == dataItem.locationName
                })
                if(tryfound == null)
                {
                    distinctList.push(dataItem.locationName)
                    deviceCount++
                }
            })
        }
        //generate the per day per device array list
        let outputData = []
        try {
            let i = 0;
            let j = 0;
            for(j = 0; j < deviceCount; j++)  //j max == device count
            {
                const currentDeviceName = distinctList[j]
                const foundReadingList = dataList.filter(function(dataItem) {
                    return dataItem.locationName == currentDeviceName
                })
                for(i = 0; i < 7; i++)
                {
                    //gen date
                    let newDate = new Date()
                    newDate.setDate(currentDate.getDate()-7+i  -1) //do not show today  //since server side send UTC date format, HK time would +8 hours. Simply add 1 day to it
                    //format date string
                    const dateString = this.formatDate(newDate)
                    //console.log(dateString)     //2020-06-11
                    //get the reading
                    let reading = null  ///null if no data is found
                    //find the relative data in string
                    const foundReadingValue = foundReadingList.filter(function(readingDataItem){
                        return readingDataItem.time.includes(dateString)
                    })
                    //dataList
                    switch(ReadingType) {
                        case "temp":
                            if(foundReadingValue.length != 0) reading = foundReadingValue[0].temperature
                            break
                        case "humid":
                            if(foundReadingValue.length != 0) reading = foundReadingValue[0].humidity
                            break
                        case "CO2":
                            if(foundReadingValue.length != 0) reading = foundReadingValue[0].co2
                            break
                        case "illu":
                            if(foundReadingValue.length != 0) reading = foundReadingValue[0].illuminance
                            break
                    }
                    //set into array
                outputData.push([i, j, reading, dateString])
                }
            }
        }
        catch(err) {
            console.log(err)
        }

        //create highchart option (included series data)
        const thisoutside = this
        setTimeout(() => {
            thisoutside.setState({
                chartHeight: caledHeight,
                chartOption: {
                    credits: false,
                    chart: {
                        type: 'heatmap',
                        marginTop: 40,
                        marginBottom: 80,
                        plotBorderWidth: 1,
                        backgroundColor: {
                            linearGradient: [0, 0, 500, 500],
                            stops: [
                                [0, 'rgba(255, 255, 255, 0)'],
                                [1, 'rgba(255, 255, 255, 0)']
                            ]
                        },
                    },
                    title: {
                        text: ''
                    },
                    xAxis: {
                        categories: dateArray,
                    },
                    yAxis: {
                        categories: distinctList,
                        title: null
                    },
                    colorAxis: {
                        //min: 0,
                        minColor: colortheme1,
                        maxColor: colortheme2
                    },            
                    legend: {
                        align: 'right',
                        layout: 'vertical',
                        margin: 0,
                        verticalAlign: 'top',
                        y: 25,
                        symbolHeight: 280
                    },            
                    tooltip: {
                        formatter: function () {
                            if(this.point.value != null) return '<b>' + this.series.yAxis.categories[this.point.y] + '</b><br><b>' +
                                this.point.value.toFixed(2) + '</b> ' + Unit + ' on <br><b>' + this.series.xAxis.categories[this.point.x] + '</b>'
                            else return '' + this.series.yAxis.categories[this.point.y] + ', ' + this.series.xAxis.categories[this.point.x] + " * Data is Missing *"                    
                        },
                    },            
                    series: [{
                        name: 'Sales per employee',
                        borderWidth: 1,
                        data: outputData,
                        dataLabels: {
                            enabled: true,
                            color: '#000000',
                            formatter: function () {
                                if(this.point.value != null)
                                {
                                    return this.point.value.toFixed(2);
                                }
                                else
                                {
                                    return "";
                                }
                            }
                        }
                    }]
                }
            })
        }, 0)
    }

    ReadingTypeChange(event) {
        this.setState({ 
            ReadingType: event.target.value,
            chartOption: {}
        })
        if(event.target.value === "temp") this.genHighchart('#88faff', "#ff0000", 'C', this.props, event.target.value)
        else if(event.target.value === "humid") this.genHighchart('#7dd6ff', "#1b516a", '%', this.props, event.target.value)
        else if(event.target.value === "CO2") this.genHighchart('#ffffff', "#000000", 'ppmv', this.props, event.target.value)
        else if(event.target.value === "illu") this.genHighchart('brown', "yellow", 'lux', this.props, event.target.value)
    }

    componentWillReceiveProps(nextProps) {
        const {ReadingType} = this.state
        const {StatHeatmapCO2, StatHeatmapTH} = this.props
        if(nextProps.StatHeatmapCO2 != StatHeatmapCO2 || nextProps.StatHeatmapTH != StatHeatmapTH) {
            //heatmap update
            if(ReadingType === "temp") this.genHighchart('#88faff', "#ff0000", 'C', nextProps, ReadingType)
            else if(ReadingType === "humid") this.genHighchart('#7dd6ff', "#1b516a", '%', nextProps, ReadingType)
            else if(ReadingType === "CO2") this.genHighchart('#ffffff', "#000000", 'ppmv', nextProps, ReadingType)
            else if(ReadingType === "illu") this.genHighchart('brown', "yellow", 'lux', nextProps, ReadingType)
        }

        //check if site / Level is updated
        const {currentSite, currentlevel} = this.props
        if(currentSite != nextProps.currentSite || currentlevel != nextProps.currentlevel) this.loadData(nextProps)
    }

    render() {
        const {chartOption, chartHeight} = this.state
        const {UserInfo} = this.props
        if(UserInfo == null) return <div>UserInfo is missing</div>
        const client_id = UserInfo.client_id

        console.log(chartOption)
        //only ad the chart when the highchart this.state parameter is prepared, else just add a empty body (need 'ref' for calculation)
        if(this.state.chartHeight == null) {
            return <div className="Heatmap_maindiv" ref={this.Heatmap_maindiv}>
                <div className="shadow-lg Heatmap_header" ref={this.Heatmap_header}>
                    <div className="fa fa-calendar"></div>
                    {getTxt('Statistic', 'last7Sum')}
                </div>
                <div className="Heatmap_body">
                </div>
            </div>
        }
        return <div className="Heatmap_maindiv" ref={this.Heatmap_maindiv}>
            <div className="shadow-lg Heatmap_header" ref={this.Heatmap_header}>
                <div className="fa fa-calendar"></div>
                {getTxt('Statistic', 'last7Sum')}
                <select className="HeapMapOption" 
                value={this.state.ReadingType} onChange={this.ReadingTypeChange}>
                    <option value="temp">Temperature</option>
                    <option value="humid">Humidity</option>
                    <option value="CO2">CO2</option>
                    {
                        (client_id != "5ecf8ab62f196f392848de8c")?<option></option>
                        :
                        <option value="illu">Illuminance</option>
                    }
                </select>
            </div>
            <div className="Heatmap_body">
                <HighchartsReact highcharts={HighCharts} options={chartOption} constructorType={'chart'} className="Heatmap_maindiv" 
                    containerProps={{ style: { height: chartHeight + "px" } }}/>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
      DeviceDataLastUpdate: state.DeviceDataLastUpdate,
      currentlevelID: state.currentlevelID,
      currentSite: state.currentSite,
      currentlevel: state.currentlevel,
      StatHeatmapCO2: state.StatHeatmapCO2,
      StatHeatmapTH: state.StatHeatmapTH,
      UserInfo: state.UserInfo
    }
}

export default connect(mapStateToProps)(HeatMap)