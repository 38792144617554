import React, { useEffect, useState } from 'react'
import './connectStatus.css'
import {connect} from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWifi } from '@fortawesome/free-solid-svg-icons'

let prevSocketConnect = null
let lastDisconnect = null
const ConnectStatusWidget = props => {
    const {isReconnecting, setIsReconnecting} = useState(false)
    const {socket, socketConnect} = props

    useEffect(() => {
        if(socketConnect==true && prevSocketConnect==false && lastDisconnect!=null) {
            //send Auth to server, so join room of client ID
            alert('Reconnecting ...')
            socket.emit('Login', {
                "msgType": 'Auth',
                "username": localStorage.getItem('useracc'),
                "password": localStorage.getItem('userhashpassword'), //password md5
                "sessionID": localStorage.getItem('SessionID'),
                "expires": '2022-12-01',
                "data": 'session cookie'
            })
            lastDisconnect = null
        }
        if(socketConnect==false && prevSocketConnect==true) {
            lastDisconnect = new Date()
        }
        prevSocketConnect = socketConnect
    }, [socketConnect])

    return socketConnect?
    <div className='conStat connected'>
        <FontAwesomeIcon icon={faWifi} />
        <div className='tips'>Connection status: Normal</div>
    </div>
    :
    <div className='conStat disconnected'>
        <FontAwesomeIcon icon={faWifi} />
        <div className='tips'>Disconnected from server</div>
    </div>
}

const mapStateToProps = state => {
    return {
        socket: state.socket,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate,
        socketConnect: state.socketConnect
    }
}
export default connect(mapStateToProps)(ConnectStatusWidget)