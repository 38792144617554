function GetLocList(props) {
    const {StatisticData, DeviceData, currentSite, currentlevel} = props
    
    const PickedLevel = DeviceData[currentSite].nestedLocs.find(item => {
        return item.locName == currentlevel
    })
    const LocList = PickedLevel.nestedLocs.map(item => {
        return {
            name: item.locName,
            value: item.locName
        }
    })
    return LocList
}

export default GetLocList