import React from 'react'
import './downloadcsv.css'
import { CSVLink, CSVDownload } from "react-csv"
import { connect } from 'react-redux'
import FormatData from './downloadcsv_formatData'

class Downloadcsv extends React.Component {
    constructor(props) {
        super()

        this.state = {
            csvData: null,              //array, with items contain the keyStr
        }

        this.PrepareData = this.PrepareData.bind(this)
        this.ClearData = this.ClearData.bind(this)
    }
    PrepareData() {
        const _this = this
        const {sortedList} = this.props
        if(sortedList == null || sortedList.length == null || sortedList.length == 0) return           //exception case
        let formmattedList = FormatData(sortedList, _this.props)
        console.log(formmattedList)
        this.setState({
            csvData: formmattedList,
        }, function() {
            setTimeout(function(){
                document.getElementById("downloadCSVBTN").click()
                document.getElementById("downloadCSVBTN_link").click()
            }, 1000)
        })
    }
    ClearData() {
        const _this = this
        setTimeout(function(){
            _this.setState({
                csvData: null
            })
        }, 1000)
    }

    render() {
        const {csvData} = this.state
        const _this = this

        if(csvData != null) {
            return <div className="ColdboxIndi_downloadcsvBtn" onClick={_this.ClearData} id="downloadCSVBTN">
                <CSVLink data={csvData} headers={CsvHeaders} filename='ColdboxManagementList.csv' id="downloadCSVBTN_link">
                    Download csv
                </CSVLink>
                <div className="fa fa-check"></div>
            </div>
        }
        return <div className="ColdboxIndi_downloadcsvBtn" onClick={this.PrepareData}>Download csv</div>
    }
}

function mapStateToProps(state) {
    return {
        GroupList: state.GroupList
    }
}  
export default connect(mapStateToProps)(Downloadcsv)

const CsvHeaders = [
    {
        key: 'coldboxID',
        label: 'Coldbox ID',
    },
    {
        key: 'group',               //..
        label: 'Coldbox Group',
    },
    {
        key: 'status',
        label: 'Status',
    },
    {
        key: 'UPSBattery',
        label: 'Battery Power',
    },
    {
        key: 'MTBF',
        label: 'MTBF',//..
    },
    {
        key: 'MTTR',
        label: 'MTTR',//..
    },
    {
        key: 'faultRate',
        label: 'Fault Rate',//..
    },
    {
        key: 'faultCount',
        label: 'Fault Count',//..
    },
    {
        key: 'operatinghrs',
        label: 'Operating Hours'
    },
    {
        key: 'availability',
        label: 'Availability'
    },
    {
        key: 'ZoneID',
        label: 'Zone ID'
    },
    {
        key: 'updateDate',
        label: 'Update Datetime'
    },
    {
        key: 'iBBattery',
        label: 'iBBattery'
    }
]