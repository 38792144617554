import React, { useState } from 'react'
import './GetCoor.css'

// THIS WIDGET IS FOR FINDING COORDINATE ON FLOOR PLAN WIDGET (X Y)
const WIDTH = 795
const HEIGHT = 449
const IMAGE_MARGINLEFT = '0px'
const IMAGE_MARGINTOP = '8px'
const IMAGEURL = 'https://47.52.20.40:30405/assets/images/floors/KSSP/G.png' //'https://atec-ibs.com/assets/images/floors/HA_SFMS/7.png'
const PT_WIDTH = '16px'

export default function GetCoor(props) {
    const [coorX, setCoorX] = useState(0)
    const [coorY, setCoorY] = useState(0)

    const editX = event => {
        setCoorX(event.target.value)
    }
    const editY = event => {
        setCoorY(event.target.value)
    }
    return <div className='getcoor main'>
        <div className='inputs'>
            <input placeholder='X 0~100' type='integer' value={coorX} onChange={editX}></input>
            <input placeholder='Y 0~100' type='integer' value={coorY} onChange={editY}></input>
        </div>
        <div className='cover' style={{width: WIDTH+'px', height: HEIGHT+'px'}}>
            <img id="floor_image" src={IMAGEURL} style={{
                width: WIDTH+'px',
                height: HEIGHT+'px',
                marginTop: IMAGE_MARGINTOP,
                marginLeft: IMAGE_MARGINLEFT
            }}></img>
            <div className='ptcontainer' style={{
                width: WIDTH+'px',
                height: HEIGHT+'px'
            }}>
                <div className='point' style={{
                    width: PT_WIDTH,
                    height: PT_WIDTH,
                    marginLeft: GetByRatio(coorX, WIDTH),
                    marginTop: GetByRatio(coorY, HEIGHT)
                }}></div>
            </div>
        </div>
    </div>
}

const GetByRatio = (input, maxvalue) => {
    const value = input/100 * maxvalue
    return value + 'px'
}