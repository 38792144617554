import React, {useState} from 'react'
import './ExportExcel.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv, faTimes, faWindowClose, faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { CSVLink, CSVDownload } from "react-csv"

function ExportExcelWidget(props) {
    const {header, unit, keystr, Data} = props
    const [ExcelData, setExcelData] = useState()

    function GetExcelData() {
        let ExcelArray = [
            ['Lift', header + "(" + unit + ")", 'DateTime']
        ]
        //add the array items
        const DataArray = (Data != null && Data[keystr] != null)?Data[keystr]:[]
        console.log(DataArray)
        const ExcelData = DataArray.map((dataItem) => {
            return [dataItem.locationName, dataItem[keystr], convertDateStr(dataItem.time)]
        })
        ExcelArray = ExcelArray.concat(ExcelData)
        setExcelData(ExcelArray)
    }
    function CloseExcelDownload() {
        setExcelData(null)
    }

    return (ExcelData == null)?
    <div className="ExportExcelBtn" onClick={GetExcelData}>
        <FontAwesomeIcon icon={faFileCsv} className="fa" />
    </div>
    :
    <div className="ExportExcelWindow" key="csvpopup">
        <div className="ExportExcel_CancelBtn" onClick={CloseExcelDownload}>
            <FontAwesomeIcon icon={faTimes} className="" />
        </div>
        <CSVLink data={ExcelData} filename={header + ".csv"} className="ExportExcel_CancelBtn">
            Download CSV
        </CSVLink>
        {/* <div className="ExportExcel_img"></div> */}
    </div>
}

export default ExportExcelWidget



function convertDateStr(DateValue) {
    if(DateValue == null) return 'Empty Date str input'
    const DateValueStr = DateValue.toString()
    let DateRaw = new Date(DateValueStr)
    DateRaw.setHours( DateRaw.getHours() + 8)
    const ReturnDateStr = DateRaw.getUTCFullYear() + '-' + MonthList[DateRaw.getUTCMonth()]// + '-' + AutofillZero(DateRaw.getUTCDate()) + ' ' + AutofillZero(DateRaw.getUTCHours()) + ':' + AutofillZero(DateRaw.getUTCMinutes()) + ':' + AutofillZero(DateRaw.getUTCSeconds())
    return ReturnDateStr
}

const MonthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', "Jul", 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

// function AutofillZero(inputNumber) {
//     if(inputNumber <10) return '0' + inputNumber.toString()
//     else return inputNumber.toString()
// }