import React from 'react'
import '../bottom/ThreePartSearchBar.css'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { Checkbox } from '@material-ui/core'

class Searchbar extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            filterTxt: '',
        }

        this.FilterOnchange = this.FilterOnchange.bind(this)
        this.Filter = this.Filter.bind(this)
    }
    componentDidMount() {
        var { sortedList } = this.props
        if (sortedList == null || sortedList == undefined) return
        this.Filter()
    }
    componentDidUpdate(preProps) {
        //Re-gen option list when data is updated
        var { sortedList } = this.props
        var oldsortedList = preProps.sortedList
        if (sortedList != oldsortedList || (sortedList != null && oldsortedList == null)) this.Filter()
    }
    FilterOnchange(keyStr, e) {
        try {
            const value = e.target.value.toString()
            this.setState({
                [keyStr]: value,
            }, function () {        //run right after state is set
                this.Filter()       //re-filter
            })
        }
        catch (err) {
            console.log(err)
        }
    }

    Filter() {
        const { filterTxt } = this.state
        const { sortedList } = this.props

        var FilteredList = sortedList
        if (filterTxt != null && filterTxt !== "") {
            FilteredList = FilteredList.filter(function (item) {
                return (item.Device != null && item.Device.includes(filterTxt)) ||
                    (item.PMFrequency != null && item.PMFrequency.includes(filterTxt)) ||
                    (item.Fault != null && item.Fault.includes(filterTxt)) ||
                    (item.ServiceImpact != null && item.ServiceImpact.includes(filterTxt)) ||
                    (item.Duration != null && item.Duration.includes(filterTxt)) ||
                    (item.Vendor != null && item.Vendor.includes(filterTxt))

            })
        }
        this.setState({
            Filtered: FilteredList
        })
        this.props.UpdateList("filterList", FilteredList)
    }

    render() {
        const _this = this
        return <div className='ThreePartSearch_Main'>
            <div className='ThreePartSearch_middle'>
                <div className="ThreePartSearch_txt">Keyword search:</div>
                <input className="ThreePartSearch_input" placeholder="Keyword" onChange={(e) => _this.FilterOnchange('filterTxt', e)}></input>
                <div className="ThreePartSearch_adSearchBtn" onClick={_this.props.refresh}>
                    <div className="fa fa-refresh" />
                </div>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {

        UserInfo: state.UserInfo,
        currentSite: state.currentSite,
    }
}
export default connect(mapStateToProps)(Searchbar)