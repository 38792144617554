import React from 'react'
import './AddWidget.css'
//Redux Import
import { connect } from 'react-redux'

class AddWidget extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            widgetList: [
                {
                    name: "Map",
                    rawStr: "Map",
                    itemStr: "map",
                    desc: "Floor Plan with Level Map image. Position of locations of sensors are marked on Map",
                    W: "8",
                    H: "10",
                },
                {
                    name: "Reading Cards",
                    rawStr: "MenuList",
                    itemStr: "menu",
                    desc: "Show Reading values of each location, with reading type list",
                    W: "12",
                    H: "5",
                },
                {
                    name: "Reading Type",
                    rawStr: "MenuList_new",
                    itemStr: "MenuList_new",
                    desc: "Select Reading Type to view",
                    W: "2",
                    H: "5",
                },
                {
                    name: "Scroll Reading Cards",
                    rawStr: "BottomDisplay",
                    itemStr: "BottomDisplay",
                    desc: "With Reading Type, select the reading type and show in a list of scroll cards for different reading type.",
                    W: "10",
                    H: "5",
                },
                {
                    name: "Header",
                    rawStr: "SiteLevelPicker",
                    itemStr: "siteLevelPicker",
                    desc: "Header to display and select Site and Level",
                    W: "12",
                    H: "1",
                },
                {
                    name: "Summary Card 1",
                    rawStr: "SummartCard",
                    itemStr: "card1",
                    desc: "",
                    W: "4",
                    H: "5",
                },
                {
                    name: "Summart Card 2",
                    rawStr: "SummartCard2",
                    itemStr: "card2",
                    desc: "",
                    W: "4",
                    H: "5",
                },
                {
                    name: "Summart Card 3",
                    rawStr: "SummartCard3",
                    itemStr: "card3",
                    desc: "",
                    W: "4",
                    H: "5",
                },
                {
                    name: "Summart Card 4",
                    rawStr: "SummartCard4",
                    itemStr: "card4",
                    desc: "",
                    W: "4",
                    H: "5",
                },
                {
                    name: "Lux Summary Card",
                    rawStr: "LuxSummary",
                    itemStr: "LuxSummary",
                    desc: "",
                    W: "4",
                    H: "5",
                },
                {
                    name: "Bin Summary Card",
                    rawStr: "BinSummary",
                    itemStr: "BinSummary",
                    desc: "",
                    W: "4",
                    H: "5",
                },
                {
                    name: "Lift CCTV",
                    rawStr: "LiftCCTV",
                    itemStr: "Lift_CCTV",
                    desc: "Demo Widget for display the CCTV, not done yet",
                    W: "8",
                    H: "10",
                },
                {
                    name: "Lift Graph",
                    rawStr: "LiftGraph",
                    itemStr: "Lift_graph",
                    desc: "",
                    W: "10",
                    H: "1",
                },
                {
                    name: "Header for Lift",
                    rawStr: "LiftHeader",
                    itemStr: "Lift_header",
                    desc: "Header for lift, display and select site and level",
                    W: "10",
                    H: "1",
                },
                {
                    name: "Lift Records",
                    rawStr: "LiftRecords",
                    itemStr: "Lift_records",
                    desc: "",
                    W: "10",
                    H: "1",
                },
                {
                    name: "Lift Display",
                    rawStr: "LiftAcc",
                    itemStr: "LiftAcc",
                    desc: "Lift widget for display and select site and level",
                    W: "10",
                    H: "1",
                },
                {
                    name: "Range Limit",
                    rawStr: "RangeLimit",
                    itemStr: "RangeLimit",
                    desc: "",
                    W: "10",
                    H: "1",
                },
                {
                    name: "Door Status Display",
                    rawStr: "DoorStatus",
                    itemStr: "DoorStatus",
                    desc: "Display the Lift Door Status",
                    W: "10",
                    H: "1",
                },
                {
                    name: "IAQ Status Display",
                    rawStr: "IAQStatus",
                    itemStr: "IAQStatus",
                    desc: "",
                    W: "10",
                    H: "1",
                },
                {
                    name: "Ppl Detect Card",
                    rawStr: "PplDetect",
                    itemStr: "PplDetect",
                    desc: "",
                    W: "10",
                    H: "1",
                },
                {
                    name: "Level Widget",
                    rawStr: "LevelWidget",
                    itemStr: "LevelWidget",
                    desc: "Verticle scrollable Widget to display the levels, allow select and change level.",
                    W: "1",
                    H: "15",
                },
                {
                    name: "Toilet Floor Plan",
                    rawStr: "ToiletFloorPlan",
                    itemStr: "ToiletFloorPlan",
                    desc: "Floor Plan, Summary, Detection result. Three in one with Switch control between 3 inner components",
                    W: "11",
                    H: "9",
                },
                {
                    name: "Toilet Info",
                    rawStr: "ToiletInfo",
                    itemStr: "ToiletInfo",
                    desc: "Toilet Information display. Include picked site, current level, etc",
                    W: "2",
                    H: "6",
                },
                {
                    name: "Toilet Display List",
                    rawStr: "toiletList",
                    itemStr: "toiletList",
                    desc: "Select Male, Female or disable toilet. Each toilet individuals would be shown with: occupied, toilet paper re-fill, water level",
                    W: "9",
                    H: "6",
                },
                {
                    name: "Toilet Feedback Widget",
                    rawStr: "ToiletFeedback",
                    itemStr: "ToiletFeedback",
                    desc: "Display user feedback score, button to popup feedback form or manual clean request",
                    W: "9",
                    H: "6",
                },
                {
                    name: "Toilet Defect Checklist Widget",
                    rawStr: "DefectList",
                    itemStr: "DefectList",
                    desc: "Display user feedback score, button to popup feedback form or manual clean request",
                    W: "4",
                    H: "6",
                },
            ],
            currentWidget: null,        //current widget that show in detail
        }

        //this.props.OpenAddWidgetGUI()
        //this.props.isOpenAddList   T/F

        this.CloseThis = this.CloseThis.bind(this)
        this.ShowDetail = this.ShowDetail.bind(this)
        this.AddWidget = this.AddWidget.bind(this)
    }

    CloseThis() {
        this.props.OpenAddWidgetGUI()
    }
    ShowDetail(WidgetItem) {
        this.setState({
            currentWidget: WidgetItem
        })
    }
    AddWidget(AddWidget) {
        this.props.onAddItem(AddWidget)
    }

    render() {
        const _this = this
        const {isOpenAddList} = this.props
        const {widgetList, currentWidget} = this.state
        if(!isOpenAddList) {
            return <div></div>
        }

       return <div className="AddWidgetList">
           <div className="AddWidget_head">
                <div className="AddWidget_title">
                </div>
                <div className="Add_Widget_closeBtn" onClick={_this.CloseThis}>
                    <div className="fa fa-times"></div>
                </div>
           </div>
           <div className="AddWidget_WidgetList">
               {
                   widgetList.map(function(item) {
                       
                       const isSelected = (currentWidget!=null && item.name == currentWidget.name)?" AddWidget_row_selected":""
                       return <div className={"AddWidget_row"+isSelected}>
                        <div className="AddWidget_widgetname" onClick={() => _this.ShowDetail(item)}>
                            {
                                item.name
                            }
                        </div>
                        <div className="AddWidget_row_addBtn" onClick={() => _this.AddWidget(item)}>
                            <div className="fa fa-plus">
                            </div>
                        </div>
                    </div>
                   })
               }
           </div>
           {
                (currentWidget == null)?<div></div>
                :
                <div className="AddWidget_Detail">
                    <div className="AddWidget_DetailRow">
                        <div className="AddWidget_DetailTitle">
                            Widget Name:
                        </div>
                        <div className="AddWidget_DetailTitle">
                            {currentWidget.name}
                        </div>
                    </div>
                    <div className="AddWidget_DetailRow">
                        <div className="AddWidget_DetailTitle">
                            W:
                        </div>
                        <div className="AddWidget_DetailTitle">
                            {currentWidget.W}
                        </div>
                    </div>
                    <div className="AddWidget_DetailRow">
                        <div className="AddWidget_DetailTitle">
                            H:
                        </div>
                        <div className="AddWidget_DetailTitle">
                            {currentWidget.H}
                        </div>
                    </div>
                    <div className="AddWidget_DetailRow">
                        <div className="AddWidget_DetailTitle">
                            Raw String:
                        </div>
                        <div className="AddWidget_DetailTitle">
                            {currentWidget.rawStr}
                        </div>
                    </div>
                    <div className="AddWidget_DetailRow">
                        <div className="AddWidget_DetailTitle">
                            Description:
                        </div>
                        <div className="AddWidget_DetailPar">
                            {currentWidget.desc}
                        </div>
                    </div>
                </div>   
            }
       </div>
    }
}

//Redux inside component function
function mapStateToProps(state) {
    return {
      UserInfo: state.UserInfo,
      currentlevelID: state.currentlevelID,
    }
}

export default connect(mapStateToProps)(AddWidget)