const checkIfNotificationForWaterPump = (notificationinput, title, msg) => {
    if(notificationinput == null) return false
    const { locationInfo,  } = notificationinput
    if(locationInfo == null || locationInfo.length == null) return false

    const levelItem = locationInfo.find(item => item.lvl == 2)
    if(levelItem == null) return false
    const {id, name} = levelItem
    if(id == null || name == null) return false
    const locItem = locationInfo.find(item => item.lvl === 3)
    if(locItem==null) return false
    const locName = locItem == null?null:locItem.name

    const waterPumpLocNames = ['TCP-WP', 'CLP-WP', 'CHC-WP'];
    const isWaterpump = (
        levelsForWaterPump.includes(id) &&
        title.includes('Water Leakage') &&
        waterPumpLocNames.some(str => locName.includes(str))
    )

    return {
        title: isWaterpump?waterPumpAbnormalTitle:title,
        description: isWaterpump?waterPumpAbnormalMsg:msg,
    }
}
const levelsForWaterPump = [
    '62b94ac6854329b83855b398',
    '62b96a54b677f61754b00571',
    '62b94ac6854329b83855b397',
    '62b96a54b677f61754b00671'
]
const waterPumpAbnormalTitle = 'Water Pump Abnormal'
const waterPumpAbnormalMsg = 'Water Pump Abnormal detected from sensor'

export {
    checkIfNotificationForWaterPump,
    levelsForWaterPump,
    waterPumpAbnormalTitle,
}