import React from 'react';
//Redux Import
import { connect } from 'react-redux';
import './SetLanguage.css';

class SetLanguage extends React.Component {
    constructor(props)
    {
        super(props);

        this.state = {
            LangInt: "0",
            LangStr: "English"
        };

        this.changeLang = this.changeLang.bind(this);
    }
    changeLang(event)
    {
        let OptionValue = event.target.value;
        let LangInt = 0;
        let LangDisplay = "";
        if(OptionValue == 0)
        {
            LangInt = 0;
            LangDisplay = "English";
        }
        else if(OptionValue == 1)
        {
            LangInt = 1;
            LangDisplay = "中文";
        }
        this.setState({
            LangInt: LangInt,
            LangStr: LangDisplay
        });
        //save to MongoDB
        //..
    }
    componentDidMount()
    {
        //get from MongoDB
        //..
    }
    render() {
        let {LangStr} = this.state;
        return <div className="SetLangContainer">
            <div className="SetLangnTitle">
                Set Language
            </div>
            <div className="SetLangDisplay">
                { LangStr }
            </div>
            <select onChange={this.changeLang} className="SetLangSelect">
                <option value="0">
                    ENG
                </option>
                <option value="1">
                    中文
                </option>
            </select>
        </div>
    }
}

//Redux inside component function
function mapStateToProps(state) {
    return {
      socket: state.socket,
      configStorage: state.configStorage
    };
}

export default connect(mapStateToProps)(SetLanguage);