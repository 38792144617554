import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import './AddScheduleType.css'
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import { useFormik } from 'formik';

const axios = require('axios');
function AddScheduleType(props) {

    const initialRule = {
        "deviceType": "Cold Box",
        "serviceSuspension": "Inspection",
        "type": props.match.params.type === "Dynamic" ? "Dynamic" : "Periodic",
        "maintenance": {},
        "repeatFrequency": "Yearly"
    }

    var mode = props.history.location.state?.mode
    var data = props.history.location.state?.data
    const [rule, setRule] = useState(initialRule)
    const [deviceType, setDeviceType] = useState("Cold Box")
    const [vendorList, setVendorList] = useState([])
    const [partList, setPartList] = useState([])
    const [selectedPartList, setSelectedPartList] = useState([])
    const [nextScheduleDate, setNextScheduleDate] = useState()
    const history = useHistory()

    const triggerField = [
        "DoorOpenWarning", "HMIAlarm", "EmergencyStop", "HighPressureCutout",
        "LowPressureCutout", "CompressorMotorFault", "FanMotorFault", "CompressorNotRunFault", "EvaporatorFanNotRunFault",
        "ColdboxHighTemperatureAlarm", "ColdboxLowTemperatureAlarm",
        "TempSensor1Fault", "TempSensor2Fault", "TempSensor3Fault", "TempSensor4Fault", "TempSensor5Fault",
        "GeneralAlarm", "TempSensorARFault", "RHSensorFault",
        "CondensingPressureSensorFault", "EvaporatingPressureSensorFault", "ExpansionValveControllerFault", "FreqInverterFault",
        "UPSBatteryLowWarning", "UPSEPO", "UPSBatteryLowFault", "UPSBatteryNotConnected", "UPSWarningInputVoltageAbnormal",
        "UPSOutputShorted", "UPSRectifierFault", "UPSInverterFault", "UPSOverTemperature", "UPSFanFailure",
        "UPSInverterOverload",
        "ChargerAbnormal_Zigbee", "ChargerAbnormal_WPT",
        "ChargerAbnormal_AC_V_in", "ChargerAbnormal_AC_I_in", "ChargerAbnormal_Rx_V_in",
        "ChargerAbnormal_Rx_I_in", "ChargerAbnormal_Tx_Temp", "ChargerAbnormal_Rx_Temp",
    ]

    const RequestData_MaintenanceRule = {
        "method": "Read",
        "info": {
            "rule_id": data?.ruleID
        }
    }
    const RequestData_PersonnelList = {
        "options": {
            "type": ["Vendor"]
        }
    }
    const RequestData_PartList = {
        "options": {
            "deviceType": deviceType
        }
    }
    const RequestData_Delete = {
        "method": "Delete",
        "info": {
            "rule_id": data?.ruleID
        }
    }

    useEffect(() => {
        if (deviceType === "") return
        axios.post('https://47.52.20.40:31001/AtalAPI/v1/CPSL_Parts_List', RequestData_PartList)
            .then(function (response) {
                console.log("CPSL_Parts_List recv!", response)
                setPartList(response.data.info.parts)
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [deviceType])

    useEffect(() => {
        if (mode !== "Create") {
            console.log("CPSL_MaintenanceRule send1!")
            axios.post('https://47.52.20.40:31001/AtalAPI/v1/CPSL_MaintRule', RequestData_MaintenanceRule)
                .then(function (response) {
                    console.log("CPSL_MaintenanceRule recv!", response)
                    let rule = response.data.info
                    setRule({
                        ...rule,
                        vendor_id: rule.vendor?.user_id
                    })
                    setNextScheduleDate(new Date(rule.nextScheduleDate))
                    setDeviceType(rule.deviceType)
                    setSelectedPartList(rule.parts?.map(part => { return { value: part, label: part } }))
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        axios.post('https://47.52.20.40:31001/AtalAPI/v1/CPSL_Personnel_List', RequestData_PersonnelList)
            .then(function (response) {
                console.log("CPSL_Personnel_List recv!", response)
                setVendorList(response.data.info)
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])

    const condition = Yup.object({
        serviceSuspension: Yup.string().required('Required'),
        deviceType: Yup.string().required('Required'),
        type: Yup.string().required('Required'),
        triggerField: Yup.string().when("type", {
            is: "Dynamic",
            then: Yup.string().required('Required')
        }),
        repeatFrequency: Yup.string().when("type", {
            is: "Periodic",
            then: Yup.string().required('Required')
        }),
        maintenance: Yup.object({
            hour: Yup.number(),
            minute: Yup.number().min(0, "range: 0-59").max(59, "range: 0-59"),
            location: Yup.string().matches('WZ[1-6][A-G][0-9]{2}', "Invalid location"),
            duration: Yup.number().min(60000, "min value is 60000"),
        }).when("serviceSuspension", {
            is: (v) => v !== "N/A",
            then: Yup.object({
                delayDay: Yup.number().required('Required'),
                hour: Yup.number().min(0, "range: 0-23").max(23, "range: 0-23").required('Required'),
                minute: Yup.number().min(0, "range: 0-59").max(59, "range: 0-59").required('Required'),
                location: Yup.string().matches('WZ[1-6][A-G][0-9]{2}', "Invalid location").required('Required'),
                duration: Yup.number().min(60000, "min value is 60000").required('Required'),
            }).required('Required'),
            otherwise: Yup.object({
                hour: Yup.number().min(0, "range: 0-23").max(23, "range: 0-23"),
                minute: Yup.number().min(0, "range: 0-59").max(59, "range: 0-59"),
                location: Yup.string().matches('WZ[1-6][A-G][0-9]{2}', "Invalid location"),
                duration: Yup.number().min(60000, "min value is 60000"),
            }).optional()
        }),
        description: Yup.string().max(200, "max length: 200")
    })

    let handleTextChange = (key, e) => {
        let text = e.target.value
        setRule({
            ...rule,
            [key]: text
        })
    }

    let handleTriggerFieldChange = (e) => {
        setRule({
            ...rule,
            triggerField: e.target.value
        })
    }

    let handleMaintenanceChange = (key, e) => {
        let text = e.target.value
        setRule({
            ...rule,
            maintenance: {
                ...rule.maintenance,
                [key]: text
            }
        })
    }

    let handleDeviceChange = e => {
        let selected_device = e.target.value
        if (deviceType !== selected_device) {
            setRule({
                ...rule,
                deviceType: selected_device
            })
            setDeviceType(selected_device)
            setPartList([])
            setSelectedPartList([])
        }
    }

    let handleVendorChange = e => {
        if (rule.vendor_id !== e.target.value) {
            const selected_user = vendorList.find(vendor => vendor.user_id === e.target.value);
            setRule({
                ...rule,
                vendor_id: selected_user?.user_id
            })
        }
    }

    const formik = useFormik({
        initialValues: rule,
        enableReinitialize: true,
        validationSchema: condition,
        onSubmit: values => {
            // send request and then push to planner page
            values.parts = selectedPartList.map(part => part.value)
            if (nextScheduleDate !== undefined && nextScheduleDate != null)
                values.nextScheduleDate = nextScheduleDate
            values.maintenance = {
                delayDay: Number(values.maintenance.delayDay),
                hour: Number(values.maintenance.hour),
                minute: Number(values.maintenance.minute),
                duration: Number(values.maintenance.duration),
                location: values.maintenance.location
            }
            let requestData = {
                "method": mode,
                "info": values,
            }
            //emit
            axios.post("https://47.52.20.40:31001/AtalAPI/v1/CPSL_MaintRule", requestData)
                .then(function (response) {
                    history.goBack()
                })
                .catch(function (error) {
                    if (error.response) {
                        alert(error.response.data.message)
                    }
                });
        },
    });

    function deleteRule() {
        axios.post("https://47.52.20.40:31001/AtalAPI/v1/CPSL_MaintRule", RequestData_Delete)
            .then(function (response) {
                history.goBack()
            })
            .catch(function (error) {
                if (error.response) {
                    alert(error.response.data.message)
                }
            });
    }

    let vendor = vendorList.find((v) => v.user_id === rule.vendor_id)
    return <div className="ADD_DMST">
        <div className="heading">{
            (mode === "Create" ? "Add" : "Change / Delete") + " Maintenance Scheduling Type"
        }</div>
        <form onSubmit={formik.handleSubmit}>
            <div className="row"><div className="col1">DeviceType</div>
                <select className="text" value={deviceType} disabled={mode !== "Create"} onChange={(e) => handleDeviceChange(e)}>
                    <option value="Cold Box">Cold Box</option>
                    <option value="LoRaWAN Gateway">LoRaWAN Gateway</option>
                    <option value="Wireless Charger">Wireless Charger</option>
                    <option value="BLE Beacon">BLE Beacon</option>
                </select>
            </div>
            <div className="row"><div className="col1">Service Suspension</div>
                <select className="text" value={rule.serviceSuspension} onChange={(e) => handleTextChange("serviceSuspension", e)}>
                    <option value="Inspection">Inspection</option>
                    <option value="Immediate">Immediate</option>
                    <option value="Maint.">Maint.</option>
                    <option value="N/A">N/A</option>
                </select>
            </div>
            <div className="row">Schedule Info</div>
            <div className="row1"><div className="col1">Delay Day</div>
                <input className="text" placeholder="Delay Day" value={rule.maintenance?.delayDay} onChange={(e) => handleMaintenanceChange("delayDay", e)} />
                {formik.errors.maintenance?.delayDay ? (<div><font color="red">{formik.errors.maintenance.delayDay}</font></div>) : null}
            </div>
            <div className="row1"><div className="col1">Duration</div>
                <input className="text" placeholder="Duration" value={rule.maintenance?.duration} onChange={(e) => handleMaintenanceChange("duration", e)} />
                {formik.errors.maintenance?.duration ? (<div><font color="red">{formik.errors.maintenance.duration}</font></div>) : null}
            </div>
            <div className="row1"><div className="col1">Hour</div>
                <input className="text" placeholder="Hour" value={rule.maintenance?.hour} onChange={(e) => handleMaintenanceChange("hour", e)} />
                {formik.errors.maintenance?.hour ? (<div><font color="red">{formik.errors.maintenance.hour}</font></div>) : null}
            </div>
            <div className="row1"><div className="col1">Minute</div>
                <input className="text" placeholder="Minute" value={rule.maintenance?.minute} onChange={(e) => handleMaintenanceChange("minute", e)} />
                {formik.errors.maintenance?.minute ? (<div><font color="red">{formik.errors.maintenance.minute}</font></div>) : null}
            </div>
            <div className="row1"><div className="col1">Location</div>
                <input className="text" placeholder="Location" value={rule.maintenance?.location} onChange={(e) => handleMaintenanceChange("location", e)} />
                {formik.errors.maintenance?.location ? (<div><font color="red">{formik.errors.maintenance.location}</font></div>) : null}
            </div>

            <div className="row"><div className="col1">Verdor</div>
                <select className="text" value={vendor?.user_id} onChange={(e) => handleVendorChange(e)}>
                    <option value=""></option>
                    {vendorList.map(function (item) {
                        return <option value={item.user_id}>{item.name}</option>
                    })}
                </select>
            </div>
            <div className="row"><div className="col1">Contact No.</div>
                <input className="text_dim" disabled placeholder="Contact No." value={vendor === undefined ? "" : vendor?.contactNo} />
            </div>
            <div className="row"><div className="col1">Email</div>
                <input className="text_dim" disabled placeholder="Email" value={vendor === undefined ? "" : vendor?.email} />
            </div>
            <div className="row"><div className="col1">Parts</div>
                <Select
                    isMulti
                    options={partList.map(function (part) { return { label: part, value: part } })}
                    value={selectedPartList}
                    onChange={setSelectedPartList} />
            </div>
            <div className="row"><div className="col1">Rule Type</div>
                <select className="text" value={rule.type} disabled={mode !== "Create"} onChange={(e) => handleTextChange("type", e)}>
                    <option value="Dynamic">Dynamic</option>
                    <option value="Periodic">Periodic</option>
                </select>
            </div>
            {rule.type === "Dynamic" ?
                <div className="row"><div className="col1">Trigger field</div>
                    <select className="text" value={rule.triggerField} onChange={(e) => handleTriggerFieldChange(e)}>
                        <option value=""></option>
                        {triggerField.map(function (item) {
                            return <option value={item}>{item}</option>
                        })}
                    </select>
                    {formik.errors.triggerField ? (<div><font color="red">{formik.errors.triggerField}</font></div>) : null}
                </div>
                : null}
            {rule.type === "Periodic" ?
                <div className="row"><div className="col1">Frequency</div>
                    <select className="text" value={rule.repeatFrequency} onChange={(e) => handleTextChange("repeatFrequency", e)}>
                        <option value="Yearly">Yearly</option>
                        <option value="Quarterly">Quarterly</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Weekly">Weekly</option>
                    </select>
                </div>
                : null}
            {rule.type === "Periodic" ?
                <div className="row"><div className="col1">New scheduled date</div>
                    <DatePicker
                        selected={nextScheduleDate}
                        onChange={setNextScheduleDate}
                        dateFormat="d-MMMM yyyy"
                        timeCaption="time"
                    />
                </div>
                : null}
            <div className="row"><div className="col1">Fault</div>
                <textarea className="textarea" value={rule.description} type="textarea" placeholder="Fault" onChange={(e) => handleTextChange('description', e)} />
                {formik.errors.description ? (<div><font color="red">{formik.errors.description}</font></div>) : null}
            </div>
            <div className="row">
                <div className="col1">&nbsp;</div>
                {
                    mode === "Create" ? "" : <button className="button" type="button" onClick={() => deleteRule()}>Delete</button>
                }
                <button className="button" type="submit">{mode === "Create" ? "Create" : "Update"}</button>
                <button className="button" type="button" onClick={() => history.goBack()}>{mode === "Create" ? "Cancel" : "Discard"}</button>
            </div>
        </form>
    </div>
}

function mapStateToProps(state) {
    return {
        socket: state.socket,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate, //when this value is updated, render would triggered
        UserInfo: state.UserInfo,
        Notification: state.Notification
    }
}
export default connect(mapStateToProps)(AddScheduleType)