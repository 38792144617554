import React from 'react'
import './TableHead.css'
//Redux Import
import { connect } from 'react-redux'


function TableHead(props) {

    return <div className="WOS_Table">
        <div className={"col2"}>
            PM Frequency
            {
                <div style={{ display: 'none' }}></div>
            }
        </div>
        <div className={"col2"}>
            Works to do
            {
                <div style={{ display: 'none' }}></div>
            }
        </div>
        <div className={"col2"}>
            Service Impact
            {
                <div style={{ display: 'none' }}></div>
            }
        </div>
        <div className={"col1"}>
            Duration
            {
                <div style={{ display: 'none' }}></div>
            }
        </div>
        <div className={"col1"}>
            Vendor
            {
                <div style={{ display: 'none' }}></div>
            }
        </div>
        <div className={"col2"}>
            Last Maint. Date
            {
                <div style={{ display: 'none' }}></div>
            }
        </div>
        <div className={"col1"}>
            Maint. Type
            {
                <div style={{ display: 'none' }}></div>
            }
        </div>
        <div className={"col2"}>
            Next Maint. Date
            {
                <div style={{ display: 'none' }}></div>
            }
        </div>
        <div className={"col1"}>
            Maint. Type
            {
                <div style={{ display: 'none' }}></div>
            }
        </div>
        <div className='col3' />
    </div>
}

//Redux inside component function
function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps)(TableHead)