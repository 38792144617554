import React from 'react'
import Moment from 'react-moment'
import { connect } from 'react-redux'
import TablePopup from './tablePopup'
import {getTxt, getLang} from '../common/language/language'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCube, faDesktop, faInfo, faSitemap, faSpinner, faTasks } from '@fortawesome/free-solid-svg-icons'
import getTransFromLocInfo from '../common/language/transFromLocInfo'
import { isWaterPumpAlarmItem } from './util'

class Table extends React.Component {
    constructor(props) {
        super(props)

        this.state={
            rows: [],
            count: 0,
            onChangePage: null,
            page: 0,
            rowsPerPage: 10,
            CurrentAlarm: null,
            IsShowPopup: false
        }

        this.handleFirstPageButtonClick = this.handleFirstPageButtonClick.bind(this)
        this.handleBackButtonClick = this.handleBackButtonClick.bind(this)
        this.handleNextButtonClick = this.handleNextButtonClick.bind(this)
        this.handleLastPageButtonClick = this.handleLastPageButtonClick.bind(this)
        this.onChangePage = this.onChangePage.bind(this)

        this.selectPage = this.selectPage.bind(this)

        this.RecordPopupShow = this.RecordPopupShow.bind(this)
        this.popupbackgroundClick = this.popupbackgroundClick.bind(this)
        this.popupclose = this.popupclose.bind(this)

        this.reloadSystemAlert = this.reloadSystemAlert.bind(this)

        this.acknowledgeUpdate = this.acknowledgeUpdate.bind(this)
    }
    
    componentDidMount() {
        this.reloadSystemAlert()
    }

    componentDidUpdate(preProps) {
        const {Notification} = this.props
        const oldNotification = preProps.Notification
        if(Notification==null) return
        const notiData = Notification.data
        const oldNotiData = (oldNotification!=null)?oldNotification.data:null

        //if the two list is different, udpate the list
        if(notiData!=null&&oldNotiData==null) this.reloadSystemAlert()        //notification data is updated from null to something, so reload the alarm list
    }

    acknowledgeUpdate() {
        //request from tablePopup.js, when it received response from socket that acknowledge is completed
        //Now trigger Notification.js > Search function() to reload the Alarm table
        this.props.searchAlarm()
        const {UserInfo} = this.props
        const {CurrentAlarm} = this.state      
        //update the current alarm data
        const UserName = UserInfo.username
        const acknowledgedTime = new Date()
        let newAlarm = CurrentAlarm
        newAlarm.clearTime = acknowledgedTime
        newAlarm.clearUserID = UserInfo.userID
        newAlarm.clearParty = UserName
        newAlarm.isClear = true
        this.setState({CurrentAlarm: newAlarm})
    }

    reloadSystemAlert() {
        //real alert data
        const {Notification} = this.props
        const {CurrentAlarm} = this.state
        const _this = this
        if(Notification == null || Notification.data == null) {
            console.log('props Notification == null Table.js reloadSystemAlert()')
            return
        }
        if(CurrentAlarm != null) {
            _this.setState({
                rows: Notification.data,
                count: Notification.data.length,
                page: 0,
            })
        }
        else {
            _this.setState({
                rows: Notification.data,
                count: Notification.data.length,
                page: 0,
                CurrentAlarm: Notification.data[0]
            })
        }
    }
    handleFirstPageButtonClick(event) {
       this.onChangePage(event, 0)
    }
    handleBackButtonClick(event) {
        const {page} = this.state
        if(page - 5 < 0) this.onChangePage(event, 0)
        else this.onChangePage(event, page - 5)
    }
    handleNextButtonClick(event) {
        const {Notification} = this.props
        let count = 0
        if(Notification != null || Notification.data != null) count = Notification.data.length
        const {rowsPerPage, page} = this.state
        if(page + 5 > Math.max(0, Math.ceil(count / rowsPerPage) - 1)) this.handleLastPageButtonClick()
        else this.onChangePage(event, page + 5)
    }
    handleLastPageButtonClick(event) {
        const {Notification} = this.props
        if(Notification == null || Notification.data == null) return
        const count = Notification.data.length
        const {rowsPerPage} = this.state

        this.onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
    }
    onChangePage(event, data) {
        const {Notification} = this.props
        let count = 0
        if(Notification != null || Notification.data != null) count = Notification.data.length
        const {rowsPerPage} = this.state
        if(data < 0 || data > Math.max(0, Math.ceil(count / rowsPerPage) - 1)) return //since the page is larger or smaller than the max / min value, page change is not allowed
        this.setState({page: data})
    }

    selectPage(data) {
        console.log(data.target.value); //this page value start from 0
        if(data.target.value == null) return

        let pageValue_int = Number(data.target.value);
        this.setState({
            page: pageValue_int
        })
    }

    RecordPopupShow(result) {
        this.setState({
            CurrentAlarm: result,
            IsShowPopup: true
        })
    }
    popupbackgroundClick() {
        this.setState({IsShowPopup: false})
    }
    popupclose() {
        this.setState({IsShowPopup: false})
    }
    componentDidCatch(error, info) {
        console.error('componentDidCatch() on Table.js')
        console.error(error)
        console.error(info)
        //redirect to App.js to reload all the datas
        this.props.history.push("/App")
    }
    render() {
        const _this = this
        const {Notification} = this.props     //real data

        if(Notification == null || Notification.data == null) return <div className="Table_maindiv">
            <table className="shadow-lg Table_tablediv">
                <tr className="Table_tableheader">
                    <td className="headerfirstitem">{getTxt('Notification', 'Ackowledge')}</td>
                    <td>{getTxt('Notification', 'type')}</td>
                    <td>{getTxt('Notification', 'alarmName')}</td>
                    <td>{getTxt('Notification', 'severity')}</td>
                    <td>{getTxt('Notification', 'msg')}</td>
                    <td className="headerlastitem">{getTxt('Notification', 'triggerT')}</td>
                </tr>
            </table>
            {
                (Notification == null || Notification.data == null)?<div className="Table_loading">
                    <FontAwesomeIcon icon={faSpinner} className='fa fa-spin' />
                </div>
                :
                <div className="Table_tableNoRecord">
                    No Record
                </div>
            }
            <TablePopup popupbackgroundClick={this.popupbackgroundClick} />
        </div>

        const { count, page, rowsPerPage, rows } = this.state
        const AlarmList = Notification.data
        const {searchString, filterStatus, filterType, siteFilter, floorFilter} = this.props
        //filter the alarm with filter from Notification.js (this.props)
        //filter with Status
        let sortedSystemAlerts = AlarmList.filter(function(AlertItem) {
            if(filterStatus == "all") return true //no filtering
            else if(filterStatus == "acknowledged") return AlertItem.isClear == true
            else if(filterStatus == "newAlarm") return AlertItem.isClear == false || AlertItem.isClear == null
            else return true    //unexpected item
        })
        //filter with type
        sortedSystemAlerts = sortedSystemAlerts.filter(function(AlertItem) {
            if(filterType == "all") return true    //no filtering
            else if(filterType == "system") return AlertItem.alarmComponent.type == "System"
            else if(filterType == "equipment") return AlertItem.alarmComponent.type == "Equipment"
            else if(filterType == "device") return AlertItem.alarmComponent.type == "Device"
            else return true    //unexpected item
        })
        // filter with site
        if(siteFilter == "all") {
            // no site filtering is required
        }
        else {
            sortedSystemAlerts = sortedSystemAlerts.filter(function(AlertItem) {
                if(AlertItem.locationInfo == null) return false
                if(AlertItem.locationInfo.length == null || AlertItem.locationInfo.length == 0) return false
                let result = false
                AlertItem.locationInfo.forEach(locationItem => {
                    if(locationItem.name == siteFilter && locationItem.lvl == 1) result = true
                })
                return result
            })
        }
        // filter with level
        if(floorFilter == "all") {
            // no level filtering is required
        }
        else {
            sortedSystemAlerts = sortedSystemAlerts.filter(function(AlertItem) {
                if(AlertItem.locationInfo == null) return false
                if(AlertItem.locationInfo.length == null || AlertItem.locationInfo.length == 0) return false
                let result = false
                AlertItem.locationInfo.forEach(locationItem => {
                    if(locationItem.name == floorFilter && locationItem.lvl == 2) result = true
                })
                return result
            })
        }

        //filter with search string (in name / message)
        sortedSystemAlerts = sortedSystemAlerts.filter(function(AlertItem) {
            if(searchString == "" || searchString == null) return true                //no filter

            //alarm name or message
            if(AlertItem.name.includes(searchString) || AlertItem.message.includes(searchString)) return true
            //location / sensor
            if(AlertItem.locationInfo != null) {
                let locInfo = AlertItem.locationInfo.find(item => item.lvl == 3).name
                if(locInfo.includes(searchString)) return true
            }

            return false
        })

        let CurrentDisplays = sortedSystemAlerts.slice(page*10, (page+1)*10).map(item => isWaterPumpAlarmItem(item))

        //use max page number and min page number to gen the page button list
        let pagelist = []
        let totalRecords = sortedSystemAlerts.length     //the total page should from sortedStstemAlerts
        let totalPages = Math.floor(totalRecords/10)
        //add the button onclick with the page nuumber to the function
        let i = 0
        for (i = 0; i <= totalPages; i++) { 
            const insertdata = {
                pagenumber: i + 1,
                page: i,
                id: i
            }
            pagelist.push(insertdata)
        }

        if(CurrentDisplays == null || CurrentDisplays.length == null || CurrentDisplays.length == 0) return <div className="Table_rowItem no_recordRow">
            No Alarm Record
        </div>
        
        const lang = getLang() //string: 'eng' or 'chin'
        let typeColor = 'black'

        return <div className="Table_maindiv">
        <table className="shadow-lg Table_tablediv">
            <tr className="Table_tableheader">
                <td className="headerfirstitem" width="10rem">{getTxt('Notification', 'Ackowledge')}</td>
                <td>{getTxt('Notification', 'type')}</td>
                <td>{getTxt('Notification', 'site')}</td>
                <td>{getTxt('Notification', 'level')}</td>
                <td>{getTxt('Notification', 'loc')}</td>
                <td>{getTxt('Notification', 'alarmName')}</td>
                <td>{getTxt('Notification', 'severity')}</td>
                <td>{getTxt('Notification', 'msg')}</td>
                <td className="headerlastitem">{getTxt('Notification', 'triggerT')}</td>
            </tr>
            {
                CurrentDisplays.map((result, i) => {
                    //set the icon for Type
                    let faIcon = <FontAwesomeIcon icon={faInfo} className='fa Not_icon' />
                    let deviceName = ""
                    let deviceType = ""
                    let AlertMessageTxt = ""
                if(result.alarmComponent != null) {
                    switch(result.alarmComponent.type) {
                        case "Device": 
                            faIcon = <FontAwesomeIcon icon={faTasks} style={{color: 'teal'}} className='fa Not_icon' />
                        break
                        case "System":
                            faIcon = <FontAwesomeIcon icon={faDesktop} style={{color: 'purple'}} className='fa Not_icon' />
                            break
                        case "Equipment":
                            faIcon = <FontAwesomeIcon icon={faCube} style={{color: 'orange'}} className='fa Not_icon' />
                            break
                    }
                    deviceName = result['name_zh-hk']==null||lang=='eng'?result.name:result['name_zh-hk']
                    var severity = result.severity
                    let displaySeverity = ''
                    switch(severity) {
                        case 'Low':
                            displaySeverity = lang=='eng'?'Low':'低'
                            typeColor = 'green'
                            break
                        case 'Normal':
                            displaySeverity = lang=='eng'?'Normal':'普通'
                            typeColor = '#DD6E0F'
                            break
                        case 'High':
                            displaySeverity = lang=='eng'?'High':'高'
                            typeColor = 'red'
                            break
                    }
                    deviceType = displaySeverity
                    if(result.message != null || result['message_zh-hk'] != null) AlertMessageTxt = result['message_zh-hk']==null||lang=='eng'?result.message:result['message_zh-hk']
                }

                // shorten the message, if the message length is too long
                if(AlertMessageTxt.length >= 55) AlertMessageTxt = AlertMessageTxt.substring(0,50) + ' ... (more ' + AlertMessageTxt.length + ")"
                
                const {DeviceData} = _this.props
                const {siteName, lvlName, locName} = lang=='eng'?getTransFromLocInfo(result.locationInfo, DeviceData).eng:getTransFromLocInfo(result.locationInfo, DeviceData).chin
                let siteDisplay = siteName
                let levelDisplay = lvlName
                let locDisplay = locName
                    
                    let alarmStatusClass = "Undefined_NewAlarm"
                    if(!result.isClear) {
                        alarmStatusClass =  "Not_NewAlarm"
                    }
                    //new alarm, show red
                    return <tr className="Table_rowItem" onClick={() => this.RecordPopupShow(result)} id={"rowitem_" + result.ID}>
                        <td>
                            <button className={"fa fa-bell " + alarmStatusClass} id={"NotBtn_"+result.ID}></button>                              
                        </td>
                        <td>
                            {faIcon}
                        </td>
                        <td>{siteDisplay}</td>
                        <td>{levelDisplay}</td>
                        <td>{locDisplay}</td>
                        <td>
                            {deviceName}
                        </td>
                        <td style={{color: typeColor}}>
                            {deviceType}
                        </td>
                        <td>
                            {AlertMessageTxt}
                        </td>
                        <td>
                            <Moment format="YYYY/MM/DD HH:mm:ss">{result.createdTime}</Moment>
                        </td>
                    </tr>
                })
            }
        </table>
            <div className="Table_bottomdiv">
                <div className="Table_pagecontrol">
                    <div onClick={this.handleFirstPageButtonClick} disabled={page === 0} className="Notification_firstpageBtn" >
                        {"<"}
                    </div>
                    <div onClick={this.handleBackButtonClick} disabled={page === 0} className="Notification_BackBtn">
                        Back
                    </div>
                    <div className="Table_pagenumber">
                        {
                            pagelist.map((data, key) => {
                                if(pagelist.length >= 5) {
                                    //if too many pages, hide some pages
                                    const pageIntDiff = Number(data.page) - Number(page)
                                    // if(page <=2) {
                                    //     if(Number(data.page) == Number(page)) return <button className="TablePageBtn currentpage" onClick={this.selectPage} value={data.page}>{data.pagenumber}</button>  //current page

                                    //     return <button className="TablePageBtn" onClick={this.selectPage} value={data.page}>{data.pagenumber}</button>
                                    // }
                                    if(pageIntDiff <= 2 && pageIntDiff >= -2) {
                                        if(Number(data.page) == Number(page)) return <button className="TablePageBtn currentpage" onClick={this.selectPage} value={data.page}>{data.pagenumber}</button>  //current page

                                        return <button className="TablePageBtn" onClick={this.selectPage} value={data.page}>{data.pagenumber}</button>
                                    }
                                    else return <></>
                                }

                                //not too mang pages case
                                if(Number(data.page) == Number(page)) {
                                    return <button className="TablePageBtn currentpage" onClick={this.selectPage} value={data.page}>{data.pagenumber}</button>  //current page
                                }
                                return <button className="TablePageBtn" onClick={this.selectPage} value={data.page}>{data.pagenumber}</button>
                            })
                        }
                        {
                            (Number(page)<=2&&pagelist.length >= 5)?<button className='TablePageBtn'>...</button>
                            :<></>
                        }
                    </div>
                    <div onClick={this.handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} className="Notification_ForwardBtn" >
                        Forward
                    </div>
                    <div onClick={this.handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} className="Notification_lastpageBtn" >
                        {">"}
                    </div>
                </div>
            </div>
            <TablePopup
                popupbackgroundClick={this.popupbackgroundClick} 
                IsShowPopup={this.state.IsShowPopup}
                currentAlarm={this.state.CurrentAlarm}
                popupclose={this.popupclose}
                acknowledgeUpdate={_this.acknowledgeUpdate}
                history={this.props.history}
            />
        </div>
    }
}

function mapStateToProps(state) {
    return {
        SystemAlerts: state.SystemAlerts,   //this is the old alarm record list, now this is only for realtime Active Alarms
        Notification: state.Notification,   //Current using alarm record storage
        DeviceDataLastUpdate: state.DeviceDataLastUpdate,
        UserInfo: state.UserInfo,
        DeviceData: state.DeviceData
    }
}
export default connect(mapStateToProps)(Table)