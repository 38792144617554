import axios from 'axios'

// const url = 'https://47.52.20.40:31769/AtalAPI/v1'
// const getUrl = 'https://47.52.20.40:30080/AtalAPI/v1/SensorPointData'
const port = '31769/AtalAPI/v1'
const port2 = '30080/AtalAPI/v1/SensorPointData'

let token = localStorage.getItem('NTUtoken')

const GetUrl = () => {
    const origin = window.location.origin
    const originWithoutPort = origin.split(':')
    if(origin.includes('localhost')) return 'https://47.52.20.40:' + port
    return originWithoutPort[0] + ':' + originWithoutPort[1] + ':' + port
}
const getSensorUrl = () => {
    const origin = window.location.origin
    const originWithoutPort = origin.split(':')
    if(origin.includes('localhost')) return 'https://47.52.20.40:' + port2
    return originWithoutPort[0] + ':' + originWithoutPort[1] + ':' + port2
}

const SetNTU = async props => {
    if(token==''||token==null) {
        token = await restfulAuth()
        SetNTU(props)
        return
    }

    let res = await SetPayload(props)
    if(res.message=='Token is not valid') {
        console.error(res.message)
        token = await restfulAuth()
        SetNTU(props)
        return
    }
    if(res.message!=null) {
        // alert('Error:' + res.message)
        return
    }
    if(res.Result == 'Success') {
        return 'Success'
    }
    return 'Fail'
    // alert('No Response from server')
}

const restfulAuth = async () => {
    const userAcc = localStorage.getItem('useracc')
    const pwdStr = localStorage.getItem('userhashpassword') //('userpassword')
    if(userAcc==null||userAcc==''||pwdStr==null||pwdStr=='') {
        alert('Data is missing, please re-login')
        return
    }
    const body = {
        'username': userAcc,
        'password': pwdStr
    }
    let res = await restfulData(GetUrl() + '/auth', '', body)
    if(res==null || res.message!=null || res.token==null) {
        // alert('Error: Response is null')
        // alert('Error:' + res.message)
        // alert('Error: no token return')
        // window.open(GetUrl() + '/Auth', '_blank').focus()
        return 'error'
    }

    token = res.token
    localStorage.setItem('NTUtoken', token)

    return token
}
const SetPayload = async props => {
    const {DeviceSerial, NTU_Setpt, setting1, setting2} = props

    let body = {
        'Payload': {
            // 'NTU_Setpt':NTU_Setpt.toString(),
            // 'setting1':setting1.toString(),
            // 'setting2':setting2.toString()
        },
        'DeviceSerial': DeviceSerial //'KSSP_Setting_S'
    }
    if(NTU_Setpt!=null) body['Payload']['NTU_Setpt'] = NTU_Setpt.toString()
    if(setting1!=null) body['Payload']['setting1'] = setting1.toString()
    if(setting2!=null) body['Payload']['setting2'] = setting2.toString()

    let res = await restfulData(GetUrl() + '/postData', token, body)
    return res
}

const GetNTU = async props => {
    const {DeviceSerial, configStorage} = props
    if(token==''||token==null) {
        token = await restfulAuth(configStorage)
        
        return await GetNTU(props)
    }
    let body = {
        sensorpoint: DeviceSerial
    }
    let res = await restfulData(getSensorUrl(), token, body)
    if(res==null) {
        alert('get NTU data error: no response')
        return
    }
    if(res.message=='Token is not valid'||(res.data==null && res.message==null)) {
        console.error('get ntu: token wrong', res)
        token = await restfulAuth(configStorage)

        return await GetNTU(props)
    }
    if(res.data==null) {
        alert('get NTU data error:' + res.message)
        return
    }
    return res.data
}

const restfulData = async (restUrl, token, body) => {
    let res = {}
    await axios.post(
        restUrl,
        body,
        token==null?
            {}
            :
            {
                headers: { Authorization: 'Bearer ' + token }
            }
    )
    .then(response => {
        res = response.data
    })
    .catch((error) => {
        console.log(error.message)
    })
    return res
}

export { SetNTU, GetNTU, GetUrl }