const checkvalid = (SaveRequestJson) => {
    const {deviceConfiguration} = SaveRequestJson

    let isValid = true
    let messagedisplay = []
    let TempWeightFactorSum = 0
    LimitArray.forEach(LimitItem => {
        if(LimitItem.type == "minmax" || LimitItem.type == "TempWF100") {
            const Value = deviceConfiguration[LimitItem.key]
            const isMinValid = (Value>=LimitItem.min)
            const isMaxValid = (Value<=LimitItem.max)

            if(!isMinValid) {
                isValid = false
                messagedisplay.push({
                    title: "Input smaller than minimum limit",
                    body: "Input: " + LimitItem.name
                })
            }
            if(!isMaxValid) {
                isValid = false
                messagedisplay.push({
                    title: "Input larger than maximum limit",
                    body: "Input: " + LimitItem.name
                })
            }
        }

        if(LimitItem.type == "TempWF100") {
            TempWeightFactorSum += deviceConfiguration[LimitItem.key]
        }
    })

    if(TempWeightFactorSum != 100) {
        isValid = false
        messagedisplay.push({
            title: "Wrong Temperature Weight Factor Input",
            body: "Sum of Weight Factors should be 100%"
        })
    }

    return {
        isValid: isValid,
        message: messagedisplay
    }
}

export default checkvalid


const LimitArray = [
    {
        key: 'TempSetPoint',
        type: 'minmax',
        min: 0,
        max: 25,
        name: 'Coldbox temperature',
    },
    {
        key: 'TempHighSetPoint',
        type: 'minmax',
        min: -20,
        max: 80,
        name: 'High temperature warning',
    },
    {
        key: 'TempLowSetPoint',
        type: 'minmax',
        min: -20,
        max: 80,
        name: 'Low temperature warning',
    },
    {
        key: 'CondensingPressureWarningHighSetPoint',
        type: 'minmax',
        min: 0,
        max: 40,
        name: 'High Condensing Pressure Alarm',
    },
    {
        key: 'EvaporatingPressureWaringLowSetpoint',
        type: 'minmax',
        min: 0,
        max: 40,
        name: 'Low Condensing Pressure Alarm',
    },
    {
        key: 'RHWarningHighSetPoint',
        type: 'minmax',
        min: 0,
        max: 100,
        name: 'High RH warning',
    },
    {
        key: 'RHWarningLowSetPoint',
        type: 'minmax',
        min: 0,
        max: 100,
        name: 'Low RH warning',
    },
    {
        key: 'SuctionTempWarningHighSetPoint',
        type: 'minmax',
        min: 0,
        max: 100,
        name: 'High Suction Temperature Warning',
    },


    {
        key: 'WeightedFactorTemp1',
        type: 'TempWF100',
        min: 0,
        max: 100,
        name: 'Temperature 1 Weighted Factor',
    },
    {
        key: 'WeightedFactorTemp2',
        type: 'TempWF100',
        min: 0,
        max: 100,
        name: 'Temperature 2 Weighted Factor',
    },
    {
        key: 'WeightedFactorTemp3',
        type: 'TempWF100',
        min: 0,
        max: 100,
        name: 'Temperature 3 Weighted Factor',
    },
    {
        key: 'WeightedFactorTemp4',
        type: 'TempWF100',
        min: 0,
        max: 100,
        name: 'Temperature 4 Weighted Factor',
    },
    {
        key: 'WeightedFactorTemp5',
        type: 'TempWF100',
        min: 0,
        max: 100,
        name: 'Temperature 5 Weighted Factor',
    },
    {
        key: 'WeightedFactorReturnAirTemp',
        type: 'TempWF100',
        min: 0,
        max: 100,
        name: 'Return Air Weighted Factor',
    },
]