import React from 'react'
import { connect } from 'react-redux'
import Moment from 'react-moment'
import PopupNotification from '../../popupNotification/PopupNotification'
import Loading from '../loading'
import SoundPlay from '../../Sound/Sound'
import {getTxt, getLang} from '../common/language/language'
import getTransFromLocInfo from '../common/language/transFromLocInfo'
import { isWaterPumpAlarmItem } from './util'

class TablePopup extends React.Component {
    constructor(props) {
        super(props)
        this.acknowledge = this.acknowledge.bind(this)
        this.acknowledgeListener = this.acknowledgeListener.bind(this)

        this.RedirectFlrPlan = this.RedirectFlrPlan.bind(this)

        this.state = {
            isLoading: false        //loading effect on button
        }
        this.soundRef = React.createRef()
    }
    componentDidMount() {
        console.log('noti popup widget did mount()')
        this.acknowledgeListener() //create listener when first load
    }
    acknowledgeListener() {
        //create socket listener for acknowledged alarm
        const {socket} = this.props
        const _this = this
        if(socket == null) {
            console.log('socket is null, tablePopup.js acknowledgeListener()')
            return
        }
        console.log('create socket listener for acknowledge response')
        this.props.socket.on("UpdateAlarm", data=> {
            _this.setState({
                isLoading: false            //returned from loading GUI
            })
            console.log(data)
            //display result in popup msg box
            try {
                if(data.result == "Success") {
                    _this.PopupNotificationRef.addNotificationPopup("Acknowledge Success!", "Status of alarm record is updated.", "")
                }
                else {
                    _this.PopupNotificationRef.addNotificationPopup("Acknowledge Failed!", "Failed Message: " + data.message, "")
                }
            }
            catch(err) {
                console.log(err)
            }

            if(data.result == "Success") {
                _this.props.acknowledgeUpdate(data)
            }
        })
    }
    acknowledge() {
        this.soundRef.current.alarmMp3Run("click")
        //get request data
        const {UserInfo, currentAlarm} = this.props
        const UserID = UserInfo.userID
        const AlertID = currentAlarm.id
        const UserName = UserInfo.username
        //request json
        const requestData = {
            msgType: "UpdateAlarm",
			AlarmID: AlertID,	//acknowledging Alarm ID
			UserID: UserID,		//acknowledge person
            Username: UserName,	//acknowledge person
        }
        console.log(requestData)
        this.props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "AlarmRequest",
            EmitSocketData: requestData
        })

        this.setState({
            isLoading: true
        })
        const _this = this
        setTimeout(() => {
            _this.setState({
                isLoading: false
            })
        }, 5000)
    }

    RedirectFlrPlan() {
        const {currentAlarm, DeviceData} = this.props
        if(currentAlarm==null||DeviceData==null) return

        const locationInfo = currentAlarm.locationInfo
        const site = locationInfo.find(item => item.lvl == 1)
        const lvl = locationInfo.find(item => item.lvl == 2)
        const loc = locationInfo.find(item => item.lvl == 3)

        if(site == null||lvl == null||loc == null) return
        //set redux data
        const siteName = site.name
        const lvlName = lvl.name
        const locName = loc.name
        //get site count number
        const siteList = DeviceData.map(item => item.locName)
        const siteIndex = siteList.indexOf(siteName)
        //set currentsite in redux
        this.props.dispatch({
            type: 'PIckSite',
            data: {
                Siteid: siteIndex
            }
        })
        //set currentlvl in redux
        this.props.dispatch({
            type: 'PickLevel',
            data: lvlName
        })

        localStorage.setItem('notiRedirect_locName', locName)
        //redirect to floor plan page
        this.props.history.push("/Main/FloorPlan")
    }

    render() {
        const _this = this
        const {IsShowPopup, DeviceData} = this.props
        const currentAlarm = isWaterPumpAlarmItem(this.props.currentAlarm)
        console.log(currentAlarm)
        const {popupbackgroundClick, popupclose} = this.props        //the function from parent component
        const {isLoading} = this.state
        const lang = getLang()

        if(currentAlarm == null) return <div></div>
        let isShowPopupClass = "hidealarmpopup"
        if(IsShowPopup) isShowPopupClass = "showalarmpopup"
        let popupStatusClass = ""
        try {
            if(currentAlarm.isClear == true) popupStatusClass = "popupAlarm_2"          //acknowledged
            else popupStatusClass = "popupAlarm_1"                                      //new alarm
        }
        catch(err) {
            console.error(err)
        }
        let acknowledgeDisplay
        if(currentAlarm.clearTime == "--") acknowledgeDisplay = <div className="popupalarm_acktime">{currentAlarm.clearTime}</div>
        else acknowledgeDisplay = <Moment className="popupalarm_acktime" format="YYYY/MM/DD HH:mm:ss">{currentAlarm.clearTime}</Moment>

        // Get and set style for Status, Type and Severity
        let StatusIcon = "fa-bell"
        let StatusColor = ""
        let SeverityColor = ""
        let SeverityTxt = ""
        let DeviceTypeTxt = ""
        try {
            let StatusIsClear = currentAlarm.isClear;   //bool
            if(!StatusIsClear) {        //false or null
                StatusIcon = "fa-bell"
                StatusColor = "StatusNew"
            }
            else {
                StatusIcon = "fa-bell-slash"
                StatusColor = "StatusClear"
            }
            let Severity = currentAlarm.severity       //String
            switch(Severity) {
                case "High":
                    SeverityTxt = lang=='eng'?'High':'高'
                    SeverityColor = "SeverityHigh"
                    break
                case "Normal":
                    SeverityTxt = lang=='eng'?'Normal':'普通'
                    SeverityColor = "SeverityNormal"
                    break
                default:
                    SeverityTxt = lang=='eng'?'Low':'低'
                    SeverityColor = "SeverityLow"
                    break
            }
            if(currentAlarm.alarmComponent != null) {
                var type = currentAlarm.alarmComponent.type
                switch(type) {
                    case 'Device':
                        DeviceTypeTxt = lang=='eng'?'Device':'裝置'
                        break
                    case 'System':
                        DeviceTypeTxt = lang=='eng'?'System':'系統'
                        break
                    case 'Equipment':
                        DeviceTypeTxt = lang=='eng'?'Equipment':'設備'
                        break
                }
            }
            else {
                DeviceTypeTxt = "System"
            }
        }
        catch(err) {
            console.log(err)
        }

        return [
            <div className={"popupalarm_maindiv " + isShowPopupClass}>
                <SoundPlay ref={this.soundRef}/>
                <div className="popupalarmHead"></div>
                <div className="fa fa-times popupalarm_closeBtn" onClick={popupclose}></div>
                <div className="popupalarm_titleContainer">
                    <div className="popupalarm_titleSub">
                        {getTxt('Notification', 'alarmName')}
                    </div>
                    <div className="popupalarm_title">
                        {lang=='eng'||currentAlarm['name_zh-hk']==null?currentAlarm.name:currentAlarm['name_zh-hk']}
                    </div>
                </div>
                <div className="popupalarm_iconsContainer">
                    <div className="popupAlarm_iconItemContainer">
                        <div className="popupAlarm_iconItemTitle">
                            {getTxt('Notification', 'status')}
                        </div>
                        <div className={"fa " + StatusIcon + " popupAlarm_iconItemIcon " + StatusColor}>
                        </div>
                    </div>
                    <div className="popupAlarm_iconItemContainer">
                        <div className="popupAlarm_iconItemTitle">
                            {getTxt('Notification', 'type')}
                        </div>
                        <div className="popupAlarm_iconItemIcon">
                            {DeviceTypeTxt}
                        </div>
                    </div>
                    <div className="popupAlarm_iconItemContainer">
                        <div className="popupAlarm_iconItemTitle">
                            {getTxt('Notification', 'severity')}
                        </div>
                        <div className={"popupAlarm_iconItemIcon " + SeverityColor}>
                            {SeverityTxt}
                        </div>
                    </div>
                </div>
                <div className="popupalarm_detailContainer">
                    <div className="popupalarm_detailRowItem">
                        <div className="popupalarm_detailTitle">
                            {getTxt('Notification', 'triggerT')}
                        </div>
                        <div className="popupalarm_detailDescription">
                            <Moment className="popupalarm_acktime" format="YYYY/MM/DD HH:mm:ss">{currentAlarm.createdTime}
                            </Moment>
                        </div>
                    </div>
                    <div className="popupalarm_detailRowItem">
                        <div className="popupalarm_detailTitle">
                            {getTxt('Notification', 'msg')}
                        </div>
                        <div className="popupalarm_detailDescription">
                            {currentAlarm['message_zh-hk']==null||lang=='eng'?currentAlarm.message:currentAlarm['message_zh-hk']}
                        </div>
                    </div>
                    <div className="popupalarm_detailRowItem">
                        <div className="popupalarm_detailTitle">
                            {getTxt('Notification', 'ackT')}
                        </div>
                        <div className="popupalarm_detailDescription">
                            {acknowledgeDisplay}
                        </div>
                    </div>
                    <div className="popupalarm_detailRowItem">
                        <div className="popupalarm_detailTitle">
                            {getTxt('Notification', 'ackPer')}
                        </div>
                        <div className="popupalarm_detailDescription">
                            {currentAlarm.clearParty}
                        </div>
                    </div>
                    <div className='popupalarm_detailRowItem'>
                        <div className='popupalarm_detailTitle'>
                            {getTxt('Notification', 'loc')}
                        </div>
                        <div className='popupalarm_detailDescription'>
                            {getAlarmLoc(currentAlarm, lang, DeviceData)}
                            <br></br>
                            {getAlarmLocDesc(currentAlarm, lang, DeviceData)}
                        </div>
                    </div>
                    <div className='popupalarm_detailRowItem'>
                        <div className='popupalarm_detailTitle'>
                            {getTxt('Notification', 'shwInFlrPlan')}
                        </div>
                        <div className='popupalarm_detailDescription'>
                            <div className='popupalarm_redirectBtn' onClick={this.RedirectFlrPlan}>
                                {getTxt('Notification', 'shw')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="">
                    {
                        isLoading?<div className={"popupalarm_ackBtn loadingColor"}><Loading/>.</div>:
                        <div className={"popupalarm_ackBtn " + popupStatusClass} style={{background: popupStatusClass=='popupAlarm_1'?'red':''}} onClick={this.acknowledge}>
                            <div className="fa fa-bell"></div>
                            {getTxt('Notification', 'Ackowledge')}
                        </div>
                    }
                </div>
            </div>,
            <div className={"popupalarm_backgroundDiv " + isShowPopupClass} onClick={popupbackgroundClick}></div>,
            <PopupNotification onRef={ref => {_this.PopupNotificationRef = ref}} />
        ]
    }
}

function mapStateToProps(state) {
    return {
        Notification: state.Notification,
        SystemAlerts: state.SystemAlerts,
        UserInfo: state.UserInfo,
        socket: state.socket,
        DeviceData: state.DeviceData,
        currentSite: state.currentSite,
        currentlevel: state.currentlevel
    }
}
export default connect(mapStateToProps)(TablePopup)

function getAlarmLoc(currentAlarm, lang, DeviceData) {
    if(currentAlarm == null) return

    const {locationInfo} = currentAlarm
    if(locationInfo == null || locationInfo.length == null) return

    const TranLocNames = lang=='eng'?getTransFromLocInfo(locationInfo, DeviceData).eng:getTransFromLocInfo(locationInfo, DeviceData).chin
    
    return TranLocNames.siteName + ', ' + TranLocNames.lvlName + ', ' + TranLocNames.locName
}

function getAlarmLocDesc(currentAlarm, lang, DeviceData) {
    if(currentAlarm == null) return

    const {locationInfo} = currentAlarm
    if(locationInfo == null || locationInfo.length == null) return

    const site = locationInfo.find(item => item.lvl == 1)
    const lvl = locationInfo.find(item => item.lvl == 2)
    const loc = locationInfo.find(item => item.lvl == 3)

    if(site == null || lvl == null || loc == null) return

    const siteName = site.name
    const lvlName = lvl.name
    const locName = loc.name

    if(DeviceData==null || siteName==null || lvlName==null || locName==null) return '--'
    let siteItem = DeviceData.find(item => item.locName == siteName)
    if(siteItem==null) return '--'
    let lvlItem = siteItem.nestedLocs.find(item => item.locName == lvlName)
    if(lvlItem==null) return '--'
    let locItem = lvlItem.nestedLocs.find(item => item.locName == locName)
    if(locItem==null) return '--'

    return lang=='eng'?locItem.locUI.desc:locItem.locUI['desc_zh-hk']
}