import React, { useEffect, useState } from 'react'
import './searchinput.css'

import {GetRelatedList, GetLocItem} from './function'

function SearchInput(props) {
    const {filterSite, filterLevel, DeviceData} = props                                         //parent filter input
    const [List, SetList] = useState([])
    const [SelectItem, SetSelectItem] = useState(null)
    useEffect(() => {
        const relatedList = GetRelatedList(DeviceData, filterSite, filterLevel)
        SetList(relatedList)
    }, [filterSite, filterLevel])

    function _onSelect(e) {
        const Value = e.target.value
        const SplitArray = Value.split(" | ")
        //check if is Site | Level | Loc or Level | Loc or Loc
        if(SplitArray != null && SplitArray.length == 3) {
            //get three inputs
            const SiteName = SplitArray[0]
            const LevelName = SplitArray[1]
            const LocName = SplitArray[2]
            //check if the item exist
            const locitem = GetLocItem(DeviceData, SiteName, LevelName, LocName)
            console.log(locitem)
            if(locitem != null && locitem.length != null && locitem.length != 0) {
                //if exist, send to parent (highlight the item in table widget)
                props.SetSelectedColdbox(locitem[0])
            }
        }
        else if(Value == "" || Value == null) {
            props.SetSelectedColdbox(null)
        }
        else {
            //..
        }
    }

    return <div className="OperOver_searchBar">
        <div className="searchTxt">Search ID:</div>
        <input list="coldboxitems" placeholder="Coldbox ID" type="search" className="inputfield"  onChange={_onSelect}></input>
        <datalist id="coldboxitems">
            {
                List.map((item) => {
                    const {SiteLocName, LevelLocName, locName} = item
                    return <option data-value={locName}>
                        {
                            (SiteLocName != null && LevelLocName != null)?SiteLocName + " | " + LevelLocName + " | " + locName
                            :
                            (SiteLocName == null && LevelLocName != null)?LevelLocName + " | " + locName
                            :
                            locName
                        }
                    </option>
                })
            }
        </datalist>
    </div>
}
  
export default SearchInput