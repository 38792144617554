import React from 'react'
import './Table.css'
import { connect } from 'react-redux'

function Table(props) {
    let row = props.row

    return (
        <div className="wrap" >
            <div className="DMSM_TableRow">
                <div className="col2">{row.Device}</div>
                <div className="col2">{row.Fault}</div>
                <div className="col2">{row.ServiceImpact}</div>
                <div className="col2">{row.Duration}</div>
                <div className="col2">{row.Vendor}</div>
                <div id='UM_table_R' className='fa fa-edit EditBtn' onClick={props.onClick}></div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        UserInfo: state.UserInfo,
        socket: state.socket,
    }
}
export default connect(mapStateToProps)(Table) 