import React from 'react'
import './coldboxList.css'
import Loading from '../../loading'
import { connect } from 'react-redux'
import ScrollMenu from 'react-horizontal-scrolling-menu'

class ColdboxList extends React.Component {
    constructor(props) {
        super()
        this.StatusScreenRedirect = this.StatusScreenRedirect.bind(this)
        this.renderCardList = this.renderCardList.bind(this)
        this.HoverCard = this.HoverCard.bind(this)
        this.defaultHighlight = this.defaultHighlight.bind(this)
        this.state = {
            isLoading: false,
            ColdBoxList: [],
            Floor: ""
        }
    }
    StatusScreenRedirect(item) {
        localStorage.setItem("selectedColdboxID", item.locName)
        console.log("StatusScreenRedirect")
        console.log(item)
        // this.props.history.push('/Main/FloorPlan/ColdboxStatus')
        this.props.history.push({
            pathname: '/Main/FloorPlan/ColdboxStatus',
            Item: item,
        })
    }

    componentDidMount() {
        console.log("componentDidMount")
        const { UserInfo, currentSite, currentlevel, DeviceData, DashboardPickedColdboxID } = this.props
        const thisoutside = this

        let pathUrl = "/Main/FloorPlan";
        this.props.dispatch({ type: 'LatestPage', data: pathUrl });
        const CurrentFloorItem = DeviceData[currentSite].nestedLocs.find(function (floorItem) { return floorItem.locName == currentlevel })

        let current_floor = ""
        let list = []
        if (CurrentFloorItem !== null) {
            current_floor = CurrentFloorItem.locDesc
            if (CurrentFloorItem.nestedLocs !== null && CurrentFloorItem.nestedLocs !== undefined) {
                list = CurrentFloorItem.nestedLocs
            }
        }
        this.setState(prevState => ({
            ...prevState,
            Floor: current_floor,
            ColdBoxList: list
        }))

        //onload auto highlight function (from operator overview page)
        this.defaultHighlight()
    }

    renderCardList(_this) {
        const { ColdBoxList, Floor } = this.state

        console.log("ColdBoxList")
        ColdBoxList.map(function(item) {
            return  _this.renderCardItem(item)
        })        
    }

    defaultHighlight() {
        const {DeviceData, currentSite, currentlevel} = this.props
        if(DeviceData==null||currentSite==null||currentlevel==null) return

        let getLocalStorage = localStorage.getItem('onloadSelectLocation')
        if(getLocalStorage==null||getLocalStorage=='') return          //no default highlight

        localStorage.setItem('onloadSelectLocation', '')                    //clear as its usage is done

        //get locName and index
        const coldboxList = DeviceData[currentSite].nestedLocs.find(item => item.locName == currentlevel).nestedLocs
        if(coldboxList==null) return
        const coldboxIDList = coldboxList.map(item => item._id)
        let i = coldboxIDList.indexOf(getLocalStorage)
        let locItem = coldboxList.find(item => item._id == getLocalStorage)
        if(locItem==null) return

        this.HoverCard(locItem.locName, i)
    }
    HoverCard(locName, i) {
        // redux dispatch function
        this.props.dispatch({ type: 'PickPolygon', data: locName })
        this.props.dispatch({ type: 'bottomcardhover', data: 'bottomcard_' + i })
    }
    
    render() {
        const _this = this
        const { ColdBoxList, Floor } = this.state
        const {currentBottomCard} = this.props

        return <div className="ColdboxList">
            <div className="ColdboxList_header">
                Coldbox List at {_this.props.currentlevel}
                <div className='detail'>
                    <i className='fa fa-info'></i>
                    double click for more detail
                </div>
            </div>
            <div className="ColdboxList_CardListXXX">
                {
                    ColdBoxList==null||ColdBoxList.length==null||ColdBoxList.length==0||ColdBoxList[0].payload==null?
                    <div>No Coldbox Item</div>
                    :
                    <ScrollMenu
                        data={
                            ColdBoxList.map(function(item, i) {
                                if(item !== {} && item !== null && item !== undefined && item.payload !== undefined) {
                                return renderCardItem(item, _this, i, currentBottomCard)
                                }
                            })
                        }
                    />
                }
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        currentlevel: state.currentlevel,
        currentSite: state.currentSite,
        UserInfo: state.UserInfo,
        DeviceData: state.DeviceData,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate,
        DashboardPickedColdBoxID: state.DashboardPickedColdBoxID,
        currentBottomCard: state.currentBottomCard,
    }
}
export default connect(mapStateToProps)(ColdboxList)

function renderCardItem(item, thisoutside, i, currentBottomCard) {
    const { ColdBoxList, Floor, isLoading } = thisoutside.state 
    const _this = thisoutside
    
    const splitIDStr = currentBottomCard.split('bottomcard_')
    const cardIDStr = splitIDStr==null||splitIDStr.length==0?'0':splitIDStr[1]
    const cardID = parseInt(cardIDStr)

    if (isLoading === true) {
        return <Loading />
    }
    else {
        const {status, payload, locName} = item
        const statusDisplay = status.status
        let StatusDisplayStr
        if(statusDisplay.Status == null) StatusDisplayStr = statusDisplay
        else {
            switch (statusDisplay.Status) {
                case 0:
                    StatusDisplayStr = 'Healthy'
                    break
                case 1:
                    StatusDisplayStr = 'Disconnected'
                    break
            }
        }
        return <div id={'coldboxCard_'+i} className={cardID==i?"ColdboxList_card highlight":"ColdboxList_card"} key={"coldboxitem"+i} onDoubleClick={()=>_this.StatusScreenRedirect(item)} onClick={()=>_this.HoverCard(item.locName, i)}>
            <div className="location">{locName}</div>
            <div className="zone">{_this.props.currentlevel}</div>
            <div className="commonStatus">           
                <div className="hourStatus">
                    <div className="hourValue">
                        {payload.RunHrs}
                        <div className="hourUnit">hrs</div>
                    </div>
                    <div className="Right">
                        <div className="statusValue">
                            {StatusDisplayStr}
                        </div>
                    </div>
                </div>
            </div>
            <div className="tempStatus">
                <div className="Left">
                    <div className="top">
                        <div className="tempValue">
                        {payload.TempSetPoint}
                        <div className="tempUnit">C</div>
                        </div>
                        <div className="fa fa-thermometer-half"></div>
                    </div>
                    <div className="bottom">set</div>
                </div>
                <div className="Right">
                    <div className="top">
                        <div className="tempValue">
                            {payload.ReturnAirTemp}
                        <div className="tempUnit">C</div>
                        </div>
                        <div className="fa fa-thermometer-half"></div>
                    </div>
                    <div className="bottom">current</div>
                </div>
            </div>
            <div className="humidStatus">
                <div className="humidValue">
                    {payload.RH}
                <div className="humidUnit">%</div>
                </div>
                <div className="humidIcon">
                    <div className="fa fa-tint"></div>
                </div>
            </div>
        </div>
    }
}