import React from 'react'
import './searchbar.css'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import moment from 'moment'

class Searchbar extends React.Component {
    constructor(props) {
        super(props)
        
        this.state = {
            startdate: new Date(moment().subtract(7, 'days')),
            enddate: new Date(),
            //option list
            groupList: null,
            siteList: null,
            floorList: null,
            typeList: ['Fault','Warning' ],
            statusList: ['Open','Closed','Acknowledged' ],
            //filter current values (null or empty str = no filter)
            filterTxt: '',
            filterGroup: '',
            filterSite: '',
            filterLevel: '',
            filterStatus: '',
            filterType:'',
            //Filter Result
            Filtered: null
        }

        this.GenOptions = this.GenOptions.bind(this)
        this.FilterOnchange = this.FilterOnchange.bind(this)
        this.Filter = this.Filter.bind(this)
        this.setStartTime = this.setStartTime.bind(this)
        this.setEndTime = this.setEndTime.bind(this)
    }
    componentDidMount() {
        var {coldboxList} = this.props
        if(coldboxList==null||coldboxList==undefined) return
        this.GenOptions()
    }
    componentDidUpdate(preProps) {
        //Re-gen option list when data is updated
        var {coldboxList} = this.props
        var oldcoldboxList = preProps.coldboxList
        if(coldboxList != oldcoldboxList || (coldboxList!=null&&oldcoldboxList==null)) this.GenOptions()
    }
    GenOptions() {        
        const {coldboxList, UserInfo,currentSite} = this.props
        if(coldboxList===null || coldboxList===undefined ){
            return
        } else {
            var locationInfo = UserInfo.locations[currentSite].nestedLocs
            var Options = coldboxList.map(function(coldboxItem, index) {
                const {group, site, floor, type, status } = coldboxItem
                return {
                    group: group,
                    site: site,
                    level: floor,
                    id: index,
                    type:type,
                    status:status
                }
            })
            // console.log(Options)
    
            //Group List
            var GroupList = Options.map(item => item.group)
            .filter((value, index, self) => self.indexOf(value) === index)
            GroupList = GroupList.sort()
            //Site List
            var SiteList = Options.map(item => item.site)
            .filter((value, index, self) => self.indexOf(value) === index)
            SiteList = SiteList.sort()
            //Level List
            // var levelList = Options.map(item => item.level)
            // .filter((value, index, self) => self.indexOf(value) === index)
            var levelList = locationInfo 
            levelList = levelList.sort()
            //Type List
            var typeList = Options.map(item => item.type)
                .filter((value, index, self) => self.indexOf(value) === index)
            typeList = typeList.sort()
            //Status List
            var statusList = Options.map(item => item.status)
                .filter((value, index, self) => self.indexOf(value) === index)
                statusList = statusList.sort()
    
            this.setState({
                groupList: GroupList,
                siteList: SiteList,
                floorList: levelList,
                Filtered: coldboxList,
                typeList:typeList,
                statusList:statusList
            })
            this.Filter()
        }
    }
    FilterOnchange(keyStr, e) {
        try {
            if(keyStr ==="filterLevel"){  
                let obj = this.state.floorList.find(o =>o._id===e.target.value) 
                const id = e.target.value.toString() 
                const name = obj.locName.toString() 
                this.props.setSelectedLevel(id)
                this.setState({
                    [keyStr]: name,
                }, function () {        //run right after state is set
                    this.Filter()       //re-filter
                })
            }else{
                const value = e.target.value.toString()
                console.log(e.target)
                this.setState({
                    [keyStr]: value,
                }, function () {        //run right after state is set
                    this.Filter()       //re-filter
                })
            }
          
        }
        catch(err) {
            console.log(err)
        }
    }
    Filter() {
       const { filterTxt, filterGroup, filterType, filterLevel, filterStatus,
            statusList,floorList, typeList } = this.state
        const { coldboxList } = this.props

        var FilteredList = coldboxList
        if (filterTxt != null && filterTxt != "") {
            FilteredList = FilteredList.filter(function (coldboxItem) {
                return coldboxItem.keywords.includes(filterTxt.toString())
            })
        }
        if (filterGroup != null && filterGroup != "") {
            FilteredList = FilteredList.filter(function (coldboxItem) {
                return coldboxItem.group == filterGroup.toString()
            })
        }
        if (filterType != null && filterType != "") {
            FilteredList = FilteredList.filter(function (coldboxItem) {
                return coldboxItem.type == filterType.toString()
            })
        }
        if (filterLevel != null && filterLevel != "") {
            FilteredList = FilteredList.filter(function (coldboxItem) {
                return coldboxItem.floor.toString() == filterLevel.toString()
            })
        }
        if (filterStatus != null && filterStatus != "") {
            console.log(filterStatus)
            FilteredList = FilteredList.filter(function (coldboxItem) {
                // console.log(coldboxItem)
                return coldboxItem.status.toString() == filterStatus.toString()
            })
        }

        this.setState({
            Filtered: FilteredList
        })
        this.props.UpdateList('filteredList', FilteredList)
    }
    

    setStartTime(data) {
        this.setState({ startdate: data })
        this.props.setStartTime(data)
    }

    setEndTime(data) {
        this.setState({ enddate: data })
        this.props.setEndTime(data)
    }

    render() {
        const _this = this
        const {isAdvanceSearch} = this.props

        const {groupList, siteList, floorList, statusList, typeList,
            filterTxt, filterGroup, filterSite, filterLevel, filterStatus, filterType} = this.state

        return <div className="CBEvent_head">
            <div className="CBEvent_txt">From:</div>
            <div className="CBEDatepicker">
                <DatePicker   
                    selected={this.state.startdate}
                    onChange={this.setStartTime}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="d-MMMM yyyy HH:mm"
                    timeCaption="time"
                    className='cdbxEPicker'
                />
            </div>
            <div className="CBEvent_txt">To:</div>
            <div className="CBEDatepicker">
                <DatePicker
                    selected={this.state.enddate}
                    onChange={this.setEndTime}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="d-MMMM yyyy HH:mm"
                    timeCaption="time"
                    className='cdbxEPicker'
                />
            </div>
            {
                isAdvanceSearch?
                <div className="CBEvent_hiddenSearches">
                    <div className="CBEvent_txt">Floor</div>
                    <select className="CBEvent_select" onChange={(e) => _this.FilterOnchange('filterLevel', e)}>
                    <option value="">All Floor</option>
                    {
                        (floorList==null||floorList.length==null||floorList.length==0)?
                        <div style={{display:'none'}}></div>
                        :
                        floorList.map(function(levelItem) {
                            return  <option value={levelItem._id} >{levelItem.locName}</option>
                        })
                    }
                </select>
                    <div className="CBEvent_txt">Keyword</div>
                    <input className="CBEvent_input" placeholder="Keyword search"  onChange={(e) => _this.FilterOnchange('filterTxt', e)}></input>
                    <div className="CBEvent_txt">Status</div>
                    <select className="CBEvent_select" onChange={(e) => _this.FilterOnchange('filterStatus', e)}>
                    <option value="">All</option>
                    {
                        (statusList==null||statusList.length==null||statusList.length==0)?
                        <div style={{display: 'none'}}></div>
                        :
                        statusList.map(function(statusItem) {
                            return <option value={statusItem }>{statusItem }</option>
                        })
                    }
                </select>
                    <div className="CBEvent_adSearchBtn" onClick={_this.props.closeAdSearch}>
                        <div className="fa fa-times"></div>
                    </div>
                </div>
                :
                <div className="CBEvent_adSearchBtn" key="CBEsearchBtn" onClick={_this.props.AdvanceSearch}>Advanced Search</div>
            }
            <div className="CBEvent_searchBtn"  onClick={_this.props.refresh}>
                <div className="fa fa-refresh" ></div>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {

        UserInfo: state.UserInfo,
        currentSite: state.currentSite,
    }
}
export default connect(mapStateToProps)(Searchbar)