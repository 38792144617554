import './LiftXYZStatistic.css'
import React from 'react'
//Redux Import
import { connect } from 'react-redux'
//import 2 highchat libraries
import HighCharts, { Axis } from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'

import HighchartsExporting from 'highcharts/modules/exporting'

//import datetimepicker library
import DatePicker from 'react-datepicker'

HighCharts.setOptions({
    time: {
        timezoneOffset: - 8 * 60
    }
})
HighchartsExporting(HighCharts)
class LiftXYZStatistic extends React.Component {
    constructor(props)
    {
        super(props)
        this.state = {
            startdate: new Date(),
            enddate: new Date(),

            //loading chart status variables
            isOnloading: true,      //timeout function to set it false, to show timeout status

            //local storage of display chart data
            highchartData: [],

            //Mode displaying (there are total three mode of data display) 1 - 3
            displayMode: 1,
        }
        //Start End Datetime Picker
        this.setStartTime = this.setStartTime.bind(this)
        this.setEndTime = this.setEndTime.bind(this)
        this.convertDate = this.convertDate.bind(this)     //This is the function to convert datetime to string format (to emit request socket)

        //Search Request Function
        this.searchRequest = this.searchRequest.bind(this)
        // +/- 10 minutes Request functions
        this.offsetMinus = this.offsetMinus.bind(this)
        this.offsetAdd = this.offsetAdd.bind(this)

        //display Highchart: highchart.option update
        this.HighChartData = this.HighChartData.bind(this)

        //change mode
        this.changeMode = this.changeMode.bind(this)
    }
    offsetMinus() {
        const {startdate, enddate} = this.state
        let datetimeNow = enddate
        let datetimeStart = startdate
        datetimeNow.setMinutes(datetimeNow.getMinutes()-10)
        datetimeStart.setMinutes(datetimeStart.getMinutes()-10)
        this.setState({
            startdate: datetimeStart,
            enddate: datetimeNow,
        })
        this.searchRequest()
    }
    offsetAdd() {
        const {startdate, enddate} = this.state
        let datetimeNow = enddate
        let datetimeStart = startdate
        datetimeNow.setMinutes(datetimeNow.getMinutes()+10)
        datetimeStart.setMinutes(datetimeStart.getMinutes()+10)
        this.setState({
            startdate: datetimeStart,
            enddate: datetimeNow,
        })
        this.searchRequest()
    }
    searchRequest() {
        const thisoutside = this
        const {UserInfo, currentlevel, currentSite, StatisticData} = this.props
        //console.log('Request StartDebug log');
        const {startdate, enddate} = this.state            //when requesting, would prevent changing the local state value
        // * need a totally new date variable, else it would set the state value accidently
        let datetimeEnd = new Date(enddate.getFullYear(), enddate.getMonth(), enddate.getDate(), enddate.getHours(), enddate.getMinutes(), enddate.getSeconds(), enddate.getUTCMilliseconds())
        let datetimeStart = new Date(startdate.getFullYear(), startdate.getMonth(), startdate.getDate(), startdate.getHours(), startdate.getMinutes(), startdate.getSeconds(), startdate.getUTCMilliseconds())
        datetimeEnd.setHours(datetimeEnd.getHours() - 8)               //8 is the timezone offset
        datetimeStart.setHours(datetimeStart.getHours() - 8)           //8 is the timezone offset
        const datetimeEndStr = this.convertDate(datetimeEnd)
        const datetimeStartStr = this.convertDate(datetimeStart)
        // 2. Get the current picked site and level
        const locationList = UserInfo.locations
        const pickedSiteItem = locationList[currentSite]
        const pickedSiteLevelList = pickedSiteItem.nestedLocs
        const pickedLevelItem = pickedSiteLevelList.find(function(levelItem) {
            return levelItem.locName == currentlevel
        })
        // 3. Get the location (floor) and id (floor id)
        const floorID = pickedLevelItem._id;
        // 4. Get the client ID
        const ClientID = UserInfo.client_id;
        const socketID = UserInfo.socketId;
        if(pickedSiteItem.imgUrl != "Lift") {
            console.error('Lift widget onloaded a non-Lift site! Please use non-Lift Linechart widget for viewing')
            return
        }
        const requestjson = {
            "msgType": "LocationStatistics",
            "msgID": "lift",
            "clientID": ClientID,
            "payload_field": ['accel_X', 'accel_Y', 'accel_Z'],
            "timeConstraint": {
                "start": datetimeStartStr,
                "end": datetimeEndStr,
            },
            "timeFrequency": {
                "unit": "minutes",                    // month / day / hours / minutes
                "value": 1
            },
            "locationConstraint": {
                "locLvl": 2,                      // 1 - Site / 2 - Floor / 3 - Location
                "id": floorID  // location id
            },
            "locationGrouping": 3,              // 1 - Site / 2 - Floor / 3 - Location
            "calculationMethod": "diff"
        }

        console.log(requestjson)
        //Emit request with redux action
        this.props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "StatRequest",
            EmitSocketData: requestjson
        })
        //datetimeEnd.setHours(datetimeEnd.getHours() + 8);               //8 is the timezone offset
        //datetimeStart.setHours(datetimeStart.getHours() + 8);           //8 is the timezone offset

        //clear current data
        this.props.dispatch({ type: 'DeviceStatistic', data: {      //store all the DeviceStatistic data in Statistic page
            co2: StatisticData.co2,
            pm25: StatisticData.pm25,
            temp: StatisticData.temp,
            humid: StatisticData.humid,
            power: StatisticData.power,
            loading: false,
            lift: null
        },})

        this.setState({
            isOnloading: true,
        })
        setTimeout(function() {
            try {
                thisoutside.setState({isOnloading: false})
            }
            catch(err) {
                console.log(err)
            }
        }, 10000)
    }
    setStartTime(data)
    {
        console.log(data)
        this.setState({
            startdate: data
        })
    }
    setEndTime(data)
    {
        console.log(data)
        this.setState({
            enddate: data
        })
    }
    convertDate(DateInput)
    {
        let DateMonth = DateInput.getMonth() + 1
        let DateDay = DateInput.getDate()
        if(DateMonth < 10) DateMonth = "0" + DateMonth
        if(DateDay < 10) DateDay = "0" + DateDay
        let HourStr = DateInput.getHours()
        if(HourStr < 10) HourStr = "0" + HourStr
        let MinutesStr = DateInput.getUTCMinutes()
        if(MinutesStr < 10) MinutesStr = "0" + MinutesStr
        const DateStr = DateInput.getFullYear() + "-" + DateMonth + "-" + DateDay + "T" + HourStr + ":" + MinutesStr + ":00Z"
        return DateStr
    }
    componentDidMount() {
        const thisoutside = this
        // When this page is onlaoded, request socket emit to server for lift statistic data
        let EndDatetimeStr = new Date()
        let StartDatetimeStr = new Date()
        StartDatetimeStr.setMinutes(StartDatetimeStr.getMinutes()-10);
        this.setState({
            startdate: StartDatetimeStr,
            enddate: EndDatetimeStr,
        })
        //request function
        setTimeout(function(){ thisoutside.searchRequest() }, 500)

        // Timeout state for loading too long
        setTimeout(function(){ 
            thisoutside.setState({
                isOnloading: false
            })
        }, 10000)
    }
    componentDidUpdate(preProps) {
        // if something is updated, update the chart data
        const {DeviceDataLastUpdate} = this.props
        const PreDeviceDataLastUpdate = preProps.DeviceDataLastUpdate
        if(PreDeviceDataLastUpdate != DeviceDataLastUpdate) this.HighChartData()
    }
    HighChartData() {
        const {StatisticData} = this.props
        const {displayMode} = this.state
        let chartValueNew  = []
        if(StatisticData != null && StatisticData.lift != null) {
            let xArray = []
            let yArray = []
            let zArray = []
            let floorDetectArray = []
            let staticArray = []
            let ActiveArray = []
            StatisticData.lift.forEach(dataItem => {
                if(dataItem.accel_X != null && displayMode == 1) xArray.push([ Date.parse(dataItem.time), dataItem.accel_X, dataItem.locationName,])
                if(dataItem.accel_Y != null && displayMode == 1) yArray.push([ Date.parse(dataItem.time), dataItem.accel_Y, dataItem.locationName,])
                if(dataItem.accel_Z != null && displayMode == 1) zArray.push([ Date.parse(dataItem.time), dataItem.accel_Z, dataItem.locationName,])
                if(dataItem.floorDetection != null && displayMode == 3) floorDetectArray.push([ Date.parse(dataItem.time), dataItem.floorDetection?1:-1, dataItem.locationName ])
                if(dataItem.Static != null && displayMode == 2) staticArray.push([ Date.parse(dataItem.time), dataItem.Static, dataItem.locationName,])
                if(dataItem.Active != null && displayMode == 2) ActiveArray.push([ Date.parse(dataItem.time), dataItem.Active, dataItem.locationName,])
            })
            // order the array to accending (descending the highchart cannot plot)
            xArray.sort()
            yArray.sort()
            zArray.sort()
            floorDetectArray.sort()
            staticArray.sort()
            ActiveArray.sort()

            console.log({
                xArray: xArray,
                yArray: yArray,
                zArray: zArray,
                floorDetectArray: floorDetectArray,
                staticArray: staticArray,
                ActiveArray: ActiveArray,
            })
            // put into highchart
            if(xArray != null && xArray != []) chartValueNew.push({
                data: xArray,
                name: "X",
                type: "spline",
                tooltip: {
                    valueDecimals: 2,
                    split: true,
                    xDateFormat: '%Y-%m-%d %H:%M:%S.%L'
                },
            })
            if(yArray != null && yArray != []) chartValueNew.push({
                data: yArray,
                name: "Y",
                type: "spline",
                tooltip: {
                    valueDecimals: 2,
                    split: true,
                    xDateFormat: '%Y-%m-%d %H:%M:%S.%L'
                },
            })
            if(zArray != null && zArray != []) chartValueNew.push({
                data: zArray,
                name: "Z",
                type: "spline",
                tooltip: {
                    valueDecimals: 2,
                    split: true,
                    xDateFormat: '%Y-%m-%d %H:%M:%S.%L'
                },
            })
            if(staticArray != null && staticArray != []) chartValueNew.push({
                data: staticArray,
                name: "Static",
                type: "spline",
                tooltip: {
                    valueDecimals: 4,
                    split: true,
                    xDateFormat: '%Y-%m-%d %H:%M:%S.%L'
                },
            })
            if(ActiveArray != null && ActiveArray != []) chartValueNew.push({
                data: ActiveArray,
                name: "Active",
                type: "spline",
                tooltip: {
                    valueDecimals: 4,
                    split: true,
                    xDateFormat: '%Y-%m-%d %H:%M:%S.%L'
                },
            })
            if(floorDetectArray != null && floorDetectArray != []) chartValueNew.push({
                data: floorDetectArray,
                name: "Floor Detect",
                type: "spline",
            })
        }
        console.log(chartValueNew)
        this.setState({
            highchartData: chartValueNew
        })
    }
    changeMode(event) {
        this.setState({
            displayMode: parseInt(event.target.value, 0)
        })
        //then, reload the daata
        this.HighChartData()
    }

    render()
    {
        const {startdate, enddate, isOnloading, highchartData, displayMode } = this.state
        console.log(displayMode)
        const {StatisticData} = this.props
        const thisoutside = this

        // Loading status checkings
        let isNotLoadComplete = false
        let NotLoadCompleteTxt = "Loading.."
        let LoadingIcon = "fa fa-spinner fa-spin "
        if(StatisticData == null) {
            isNotLoadComplete = true
            if(isOnloading) NotLoadCompleteTxt = "Loading StatisticData"
            else {
                NotLoadCompleteTxt = "Timeout"
                LoadingIcon = "fa fa-exclamation-circle "
            }
        } else {
            if(StatisticData.lift == null) {
                isNotLoadComplete = true
                if(isOnloading) NotLoadCompleteTxt = "Loading Lift StatisticData"
                else {
                    NotLoadCompleteTxt = "Loading Lift Timeout"
                    LoadingIcon = "fa fa-exclamation-circle "
                }
            } else {
                if(StatisticData.lift.length == 0) {
                    isNotLoadComplete = true
                    NotLoadCompleteTxt = "No Data"
                    LoadingIcon = "fa fa-exclamation-circle "
                }
            }
        }
        // End of Loading status checking

        // Highchart option item
        const options = {
            credits: true,
            chart: {
                backgroundColor: {
                    linearGradient: [0, 0, 500, 500],
                    stops: [
                        [0, 'rgba(200, 200, 200, 0)'],
                        [1, 'rgba(0, 0, 0, 0)']
                    ]
                },
            },
            title: {
              text: ''
            },
            rangeSelector: {
                enabled: false
            },
            plotOptions: {
                series: {
                    showInNavigator: true,       //show all line inside the bottom filter controller
                    lineWidth: 1
                }
            },
            yAxis: {
                labels: {
                    enabled: true,
                    format: '{value} ' + "m/s"
                },
                opposite: false,
            },
            series: highchartData
        }

        if(isNotLoadComplete) {
            return <div className="LiftAlarmList">
            <div className="Lift3DLineChart_header">
                <div className="fa fa-history"></div>
                <div className="Lift3DLineChart_headerTitle">
                    X Y Z Acceleration by Time
                </div>
            </div>
            <div className="Lift3DLineChart_SearchHeader">
                {/* <div className="Lift3DLineChart_SearchTxt">Start date</div> */}
                <DatePicker
                        selected={startdate}
                        onChange={this.setStartTime}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="d-MMMM yyyy HH:mm"
                        timeCaption="time"
                    />
                <div className="Lift3DLineChart_SearchTxt">To</div>
                <DatePicker
                        selected={enddate}
                        onChange={this.setEndTime}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="d-MMMM yyyy HH:mm"
                        timeCaption="time"
                    />
                <div className="fa fa-search lift3DLineChart_header_searchBtn" onClick={this.searchRequest}>
                </div>
            </div>
            <div className="Lift3DLineChart_ChartContainer">
                <div className="Lift3DLineChart_itemFailTxt">
                    <i className={LoadingIcon + " loadingUIIcon"}></i>
                    <br></br>
                    {NotLoadCompleteTxt}
                </div>
            </div>
        </div>
        }

        let displayTitle = "Undefined Data Type"
        switch(displayMode) {
            case 1:
                displayTitle = "X Y Z Acceleration bt Time"
                break
            case 2:
                displayTitle = "Static / Active bt Time"
                break
            case 3:
                displayTitle = "Floor Detection bt Time"
                break
        }
        return <div className="LiftAlarmList">
            <div className="Lift3DLineChart_header">
                <div className="fa fa-history"></div>
                <div className="Lift3DLineChart_headerTitle">
                    {displayTitle}
                </div>
            </div>
            <div className="Lift3DLineChart_SearchHeader">
                {/* <div className="Lift3DLineChart_SearchTxt">Start date</div> */}
                <DatePicker
                        selected={startdate}
                        onChange={this.setStartTime}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="d-MMMM yyyy HH:mm"
                        timeCaption="time"
                    />
                <div className="Lift3DLineChart_SearchTxt">To</div>
                <DatePicker
                        selected={enddate}
                        onChange={this.setEndTime}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="d-MMMM yyyy HH:mm"
                        timeCaption="time"
                    />
                <div className="fa fa-search lift3DLineChart_header_searchBtn" onClick={this.searchRequest}>
                </div>
                <div className="lift3DLineChart_debugBtn" onClick={this.offsetMinus}>
                    -10 min
                </div>
                <div className="lift3DLineChart_debugBtn" onClick={this.offsetAdd}>
                    +10 min
                </div>
                <select className="LiftGraphSelect" onChange={thisoutside.changeMode}>
                    <option value="1">Acceleration</option>
                    <option value="2">Active / Static</option>
                    <option value="3">Floor Detection</option>
                </select>
            </div>
            <div className="Lift3DLineChart_ChartContainer">
                <div className="Lift3DLineChart_item">
                    <HighchartsReact highcharts={HighCharts} options={options} constructorType={'stockChart'} 
                    className="" containerProps={{ style: { height: 'calc(23rem)' } }}/>
                </div>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
      socket: state.socket,
      DeviceDataLastUpdate: state.DeviceDataLastUpdate, //when this value is updated, render would triggered
      StatisticData: state.StatisticData,
      UserInfo: state.UserInfo,
      currentlevelID: state.currentlevelID,
      currentlevel: state.currentlevel,
      currentSite: state.currentSite,
    }
}
export default connect(mapStateToProps)(LiftXYZStatistic)