import React, { useEffect, useState } from 'react'
import './Occupancy.css'
import { connect } from 'react-redux'

import ProgressWidget from '../../Toilet/progress/progress'
import getColorCode from '../commonfunction'

const ScheduleUpdateInterval = 1       //minutes
let TickFunction

function OccupancyWidget(props) {
    const {UserInfo, DeviceData, ToiletType, socket, currentSite, currentlevel, DeviceDataLastUpdate} = props
    const [OccPercent, setOccPercent] = useState(0)
    const [MOccPercent, setMOccPercent] = useState(0)

    useEffect(() => {               //retrigger interval loop
        const {Vac, total, MVac, Mtotal} = GetOccPercent(DeviceData, currentSite, currentlevel, ToiletType)
        setOccPercent(Vac/total*100)
        setMOccPercent(MVac/Mtotal*100)
    }, [ToiletType, currentlevel, currentSite, DeviceDataLastUpdate])

    const {color1, color2, gradient1, gradient2} = getColorCode(ToiletType)

    const {Vac, total, MVac, Mtotal} = GetOccPercent(DeviceData, currentSite, currentlevel, ToiletType)

    return <div className="Toilet_admin_occ">
        <div className="head">Occupancy</div>
        {/* {
            (ToiletType==1 || ToiletType=="1")?
            <div className="chartitem">
                <ProgressWidget PercentInput={MOccPercent} ColorDefault={color1} Highlight={color2} WhiteColor="white" text={"Vacancy"} OccTotal={Mtotal} OccInUse={Mtotal-MVac} />
                <div className="title">Urinal</div>
            </div>
            :
            <div style={{display: 'none'}}></div>
        } */}
        <div className="chartitem">
            <ProgressWidget PercentInput={OccPercent} ColorDefault={color1} Highlight={color2} WhiteColor="white" text={"Vacancy"} OccTotal={total} OccInUse={total-Vac} />
            {/* <div className="title">Seat Toilet</div> */}
        </div>
    </div>
}

function mapStateToProps(state) {
    return {
        UserInfo: state.UserInfo,
        DeviceData: state.DeviceData,
        ToiletType: state.ToiletType,
        currentlevel: state.currentlevel,
        currentSite: state.currentSite,
        socket: state.socket,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate,
    }
}

export default connect(mapStateToProps)(OccupancyWidget)

// w = 2
// h = 8

function GetOccPercent(DeviceData, currentSite, currentlevel, ToiletType) {
    if(DeviceData == null) return {}

    const SiteItem = DeviceData[currentSite].nestedLocs
    const LevelItem = SiteItem.find(item => {
        return item.locName == currentlevel
    })
    const LocationList = LevelItem.nestedLocs

    //filter the occ items only
    const RelatedOccList = LocationList.filter(item => {
        return (item.payload_definition_id.nameID == "Urinal Occupancy" || item.payload_definition_id.nameID == "Cubicle Occupancy") && item.payload.toiletType.toString() == ToiletType.toString()
    })
    const MRelatedOccList = LocationList.filter(item => {
        return item.payload_definition_id.nameID == "Urinal Occupancy" && item.payload.toiletType.toString() == ToiletType.toString()
    })

    const TotalCount = RelatedOccList.length
    const MTotalCount = MRelatedOccList.length

    const VacancyList = RelatedOccList.filter(item => {
        return item.payload.occupied == false
    })
    const VacancyCount = VacancyList.length

    const MVacancyList = MRelatedOccList.filter(item => {
        return item.payload.occupied == false
    })
    const MVacancyCount = MVacancyList.length

    return {
        Vac: VacancyCount,
        total: TotalCount,

        MVac: MVacancyCount,
        Mtotal: MTotalCount,
    }
}

// 1. Separate Male point occ and Area Seats Toilet
// 2. Return from this function to render
// 3. input and display relative data