import React, { useEffect, useState } from 'react'
import './Feedback.css'
import { connect } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSmileBeam, faSmile, faFrown, faSadTear } from '@fortawesome/free-solid-svg-icons'
import resetPassword from '../../../resetPassword'

function FeedbackWidget(props) {
    const {UserInfo, ToiletType, socket, DeviceData, currentSite, currentlevel} = props

    const [selectFace, setselectFace] = useState(1)
    const [TextArea, setTextArea] = useState('')

    const [IsLoading, setIsLoading] = useState(false)
    const [ShowMsg, SetShowMsg] = useState(null)            //null = hide, string = display message

    useEffect(() => {
        setTimeout(() => {
            setselectFace(0)
        }, 500)
        //set feedback response socket listneer
        if(socket == null) return

        let LocalSocketListener = socket.on("Toilet_feedback", data=> {
            console.log(data)
            if(data.result == "Success") {
                SetShowMsg("Success")
                ResetForm()
                setTimeout(() => {
                    setIsLoading(false)
                    SetShowMsg(null)
                }, 2000)
            }
            else SetShowMsg("Submit Failed:" + data.error)

            setTimeout(() => {
                setIsLoading(false)
            }, 2000)
        })
        return function ClearSocketListener() {
            socket.off("Toilet_feedback")
        }
    }, [])

    function SelectFaceFunction(Input) {
        // if(selectFace == Input) setselectFace(null)
        setselectFace(Input)
    }
    function Submit() {
        //emit socket to server
        const {LevelID, LevelName, SiteID, SiteName} = GetSelectedLevelSiteData(DeviceData, currentSite, currentlevel)
        var Timestamp = new Date()
        Timestamp = Timestamp.setHours(Timestamp.getHours()-8)
        const EmitJson = {
            'msgType': 'Toilet_feedback',
            "method": "Create",
            "timestamp": convertDate(new Date(Timestamp)),
            "client_id": UserInfo.client_id,
            "UserID": UserInfo.userID,
            "locationInfo": [
              {
                "id": SiteID,
                "lvl": 1,
                "name": SiteName
              },
              {
                "id": LevelID,
                "lvl": 2,
                "name": LevelName
              }
            ],
            "toiletType": ToiletType.toString(), //1 = male, 2 = female, 3 = other
            score: selectFace,
            feedback: TextArea,
        }
        console.log(EmitJson)
        props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "StatRequest",
            EmitSocketData: EmitJson
        })
        SetShowMsg('loading')

        //loading message
        setIsLoading(true)
        setTimeout(() => {
            ResetForm()
            SetShowMsg('Timeout')
        }, 6000)
        setTimeout(() => {
            setIsLoading(false)
            SetShowMsg(null)
        }, 10000)

    }
    function ResetForm() {
        setselectFace(0)
        setTextArea('')
    }

    let animationclass
    switch (selectFace) {
        case 0:
            animationclass = 'fb_icon_1'
            break
        case 1:
            animationclass = 'fb_icon_2'        
            break
        case 2:
            animationclass = 'fb_icon_3'    
            break
    }
    return <div className="Toilet_fb">
        <div className="Toilet_fb_title">Submit your feedback and experience!</div>
        <div className="Toilet_fb_icons">
            {
                <div className={"AnimationIcon " + animationclass}></div>
            }
            <div className={selectFace==2?"Toilet_fb_icon":"Toilet_fb_icon icon3"} onClick={()=>SelectFaceFunction(2)}>
                <FontAwesomeIcon icon={faSadTear} className="fa" />
            </div>
            <div className={selectFace==1?"Toilet_fb_icon":"Toilet_fb_icon icon2"} onClick={()=>SelectFaceFunction(1)}>
                <FontAwesomeIcon icon={faSmile} className="fa" />
            </div>
            <div className={selectFace==0?"Toilet_fb_icon":"Toilet_fb_icon icon1"} onClick={()=>SelectFaceFunction(0)}>
                <FontAwesomeIcon icon={faSmileBeam} className="fa" />
            </div>
        </div>
        <div className="Toilet_fb_improve">Tell us how we can improve</div>
        <textarea className="Toilet_fb_textarea" rows="5" cols="50" maxlength="5000" style={{resize: 'none'}} 
            placeholder="Leave your message here. (optional)" onChange={(e) => setTextArea(e.target.value)} value={TextArea}></textarea>
        {
            IsLoading?
            <div className="Toilet_fb_btn">
                <div className="fa fa-spinner fa-spin"></div>
            </div>
            :
            <div className="Toilet_fb_btn" onClick={Submit}>Submit</div>
        }
        {
            IsLoading?
            ShowMsg=="loading"?
            <div className="Toilet_fb_popupmsg">
                Submiting...
            </div>
            :
                ShowMsg=="Timeout"?
                <div className="Toilet_fb_popupmsg">
                    Submit Failed: Timeout
                </div>
                :
                    ShowMsg=="Success"?
                    <div className="Toilet_fb_popupmsg">
                        Submit Success. Thank You!
                    </div>
                    :
                    <div style={{display: 'none'}}></div>
            :
            <div style={{display: 'none'}}></div>
        }
    </div>
}

function mapStateToProps(state) {
    return {
      socket: state.socket,
      ToiletType: state.ToiletType,
      UserInfo: state.UserInfo,
      DeviceData: state.DeviceData,
      currentSite: state.currentSite,
      currentlevel: state.currentlevel,
    }
}
export default connect(mapStateToProps)(FeedbackWidget)

// w = 3
// h = 6


function GetSelectedLevelSiteData(DeviceData, currentSite, currentlevel) {
    const SiteItem = DeviceData[currentSite]
    if(SiteItem == null) return {}

    const LevelItem = SiteItem.nestedLocs.find(item => {
        return item.locName.toString() == currentlevel.toString()
    })
    if(LevelItem == null) return {}

    return {
        SiteID: SiteItem._id,
        SiteName: SiteItem.locName,
        LevelID: LevelItem._id,
        LevelName: LevelItem.locName,
    }
}

function convertDate(DateInput)
{
    let DateMonth = DateInput.getMonth() + 1
    let DateDay = DateInput.getDate()
    if(DateMonth < 10) DateMonth = "0" + DateMonth
    if(DateDay < 10) DateDay = "0" + DateDay
    let HourStr = DateInput.getHours()
    if(HourStr < 10) HourStr = "0" + HourStr
    let MinutesStr = DateInput.getUTCMinutes()
    if(MinutesStr < 10) MinutesStr = "0" + MinutesStr
    const DateStr = DateInput.getFullYear() + "-" + DateMonth + "-" + DateDay + "T" + HourStr + ":" + MinutesStr + ":00Z"
    return DateStr
}