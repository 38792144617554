import React from 'react';
//Routing lib
import { Route, Switch } from 'react-router-dom';

//Redux Import
import { connect } from 'react-redux';
import Navbar from './Navbar/Navbar';
import FloorPlan from './FloorPlan/FloorPlan';
import FloorPlanTable from './FloorPlanTable/FloorPlanTable'
import Summary from './Summary/Summary';
import Setting from './Setting/Setting';
import Notification from './Notification/Notification';
import Mainbody from './Mainbody';
import ReportIssue from './ReportIssue/ReportIssue';
import UserManagement from './UserManagement/UserManagement';
import AlarmTrigger from '../Main/AlarmTrigger/AlarmTrigger';
import InstallMobileApp from '../Main/InstallMobileApp/InstallMobileApp'
import TestCard from './FloorPlan/MenuList/BottomDisplay/testcard'
import ColdboxManage from './Coldbox/coldboxManage'
import ColdboxGroupManage from './Coldbox/group/group'
import ColdboxIndividual from './Coldbox/individual/individual'
import ColdboxGateway from './Coldbox/gateway/gateway'
import ColdboxBLE from './Coldbox/BLE/BLE'
import ColdboxStatus from './Coldbox/ColdboxStatus/ColdboxStatus'
import ColdboxGroupDiff from './Coldbox/Coldbox_GroupDiff/Coldbox_GroupDiff'
import OperationOverview from './Coldbox/OperationOverview/OperationOverview'
import ColdboxSetpoint from './Coldbox/setpoint/setpoint'
import ColdboxEvent from './Coldbox/Alarm/event'
import ColdboxAlarm from './Coldbox/Alarm/alarm'
import ColdboxAuditTrial from './Coldbox/AuditTrial/AuditTrial'
import ColdboxDataTrend from './Coldbox/DataTrend/DataTrend'
import MmsDashboard from './MMS/Dashboard/Dashboard';
import MmsPlanner from './MMS/Planner/Planner';
import MmsWorkOrderList from './MMS/WorkOrderList/WorkOrderList';
import MmsWorkOrderSummary from './MMS/WorkOrderSummary/WorkOrderSummary';
import MmsPerformanceReport from './MMS/PerformanceReport/PerformanceReport';
import MmsSchedulingManagement from './MMS/SchedulingManagement/SchedulingManagement'
import MmsAddScheduleType from './MMS/SchedulingManagement/AddScheduleType'
import MmsPerformanceReportDevice from './MMS/PerformanceReportByDevice/PerformanceReport'
import MmsWorkOrderDetail from './MMS/WorkOrderDetail/WorkOrderDetail'

class Main extends React.Component {
    componentDidMount() {
        //willmount works
        //set the css parameter based on the localstorage
        console.log('set css color theme');
        let ColorHighlight = localStorage.getItem('--focuscardbackground');
        let ColorNavigationBar = localStorage.getItem('--colortheme1');
        let ColorBackground = localStorage.getItem('--colortheme2');
        let ColorHeader = localStorage.getItem('--divheader');

        //set to the css variable
        if (ColorHighlight !== null && ColorHighlight !== '') {
            document.documentElement.style.setProperty('--focuscardbackground', ColorHighlight);
            // document.documentElement.style.setProperty('--colortheme1', ColorNavigationBar);
            // document.documentElement.style.setProperty('--colortheme2', ColorBackground);
            document.documentElement.style.setProperty('--divheader', ColorHeader);
        }
        //end of will mount works

        //check if the socket is connected or not
        //console.log(this.props.socket);

        let thisoutside = this;
        if (this.props.socket == null) {
            console.log('undefined socketIO, reconnect');
            this.props.dispatch({ type: 'SocketCon' });
            this.props.history.push('/App');
        }
        else {
            //For Auto Login function, check the auth here
            //get auth variables
            let username = localStorage.getItem('useracc');
            let hashPassword = localStorage.getItem('userhashpassword');
            let authToken = localStorage.getItem('AuthToken');
            let sessionID = localStorage.getItem('SessionID');

            //test request function
            let requestdata = {
                authToken: authToken,
                sessionID: sessionID,
                building: thisoutside.props.currentlevel,
                level: thisoutside.props.currentSite
            };
            thisoutside.props.socket.emit("greet", requestdata);

            //checkAuth
            let data = {
                username: username,
                hashPassword: hashPassword,
                authToken: authToken,
                sessionID: sessionID
            };
            //check with server
            thisoutside.props.socket.emit("checkAuth", data);

            //socket listeners
            thisoutside.props.socket.on("checkAuth", data => {
                //show login failed message
                /*console.log('checkAuth Status: ' + data.status);
                if(!data.status)    //when checck auth failed
                {
                    localStorage.setItem("rememberme", false);
                    thisoutside.props.history.push('/');
                }*/
            });

            /*thisoutside.props.socket.on("getResult", data => {
                //console.log("getResult triggered!");
                console.log(data);
            });*/
        }
    }

    render() {
        return [
            <Navbar key={"Navbar"} history={this.props.history} />,
            <Switch key={"Mainswitch"}>
                {/* cold box project items for CPSL */}
                <Route path="/Main/FloorPlan/ColdboxOperationOverview" component={OperationOverview} history={this.props.history} key={"ColdboxOperationOverviewPage"} />
                <Route path="/Main/FloorPlan/ColdboxStatus" component={ColdboxStatus} history={this.props.history} key={"ColdboxStatusPage"} />
                <Route path="/Main/FloorPlan/ColdboxGroupDiff" component={ColdboxGroupDiff} history={this.props.history} key={"ColdboxGroupDiffPage"} />
                {/* Common used pages */}
                <Route path="/Main/FloorPlan" component={FloorPlan} key={"FloorPlanPage"} />
                <Route path="/Main/FloorPlanTable" component={FloorPlanTable} key="FloorPlanTablePage" />
                <Route path="/Main/Summary" component={Summary} key={"SummaryPage"} />
                <Route path="/Main/Setting" component={Setting} key={"SettingPage"} />
                <Route path="/Main/Notification" component={Notification} key={"NotificationPage"} />
                <Route path="/Main/ReportIssue" component={ReportIssue} key={"ReportIssuePage"} />
                <Route path="/Main/UserManagement" component={UserManagement} key={"UserManagementPage"} />
                <Route path="/Main/AlarmTrigger" component={AlarmTrigger} key={"AlarmTrigger"} />
                <Route path="/Main/InstallMobileApp" component={InstallMobileApp} key="InstallMobileApp" />
                {/* <Route path="/Main/testCard" component={TestCard} key="TestCardPage" /> */}
                {/* cold box project items for CPSL */}
                <Route path="/Main/ColdboxManage/coldboxgroup" component={ColdboxGroupManage} history={this.props.history} key={"ColdboxgpPage"} />
                <Route path="/Main/ColdboxManage/coldboxIndividual" component={ColdboxIndividual} history={this.props.history} key={"ColdboxindiPage"} />
                <Route path="/Main/ColdboxManage/gateway" component={ColdboxGateway} history={this.props.history} key={"ColdboxGWPage"} />
                <Route path="/Main/ColdboxManage/BLE" component={ColdboxBLE} history={this.props.history} key={"ColdboxBLEPage"} />
                <Route path="/Main/ColdboxManage/coldboxSetpoint" component={ColdboxSetpoint} history={this.props.history} key={"ColdboxSetpointPage"} />
                <Route path="/Main/ColdboxManage" component={ColdboxManage} history={this.props.history} key={"ColdboxMgtPage"} />
                <Route path="/Main/Coldbox/Event" component={ColdboxEvent} history={this.props.history} key={"ColdboxEventPage"} />
                <Route path="/Main/Coldbox/Alarm" component={ColdboxAlarm} history={this.props.history} key={"ColdboxAlarmPage"} />
                <Route path="/Main/Coldbox/AuditTrial" component={ColdboxAuditTrial} history={this.props.history} key={"ColdboxAuditTrial"} />
                <Route path="/Main/ColdboxDataTrend" component={ColdboxDataTrend} history={this.props.history} key={"ColdboxDataTrend"} />
                <Route path="/Main/MmsDashboard" component={MmsDashboard} history={this.props.history} key={"MmsDashboard"} />
                <Route path="/Main/MmsPlanner" component={MmsPlanner} history={this.props.history} key={"MmsPlanner"} />
                <Route path="/Main/MmsWorkOrderList" component={MmsWorkOrderList} history={this.props.history} key={"MmsWorkOrderList"} />
                <Route path="/Main/MmsWorkOrderSummary" component={MmsWorkOrderSummary} history={this.props.history} key={"MmsWorkOrderSummary"} />
                <Route path="/Main/MmsPerformanceReport" component={MmsPerformanceReport} history={this.props.history} key={"MmsPerformanceReport"} />
                <Route path="/Main/MmsSchedulingManagement/:type" component={MmsSchedulingManagement} history={this.props.history} key={"MmsSchedulingManagement"} />
                <Route path="/Main/MmsAddSchedueType/:type" component={MmsAddScheduleType} history={this.props.history} key={"MmsAddScheduleType"} />
                <Route path="/Main/MmsPerformanceReportDevice" component={MmsPerformanceReportDevice} history={this.props.history} key={"MmsPerformanceReportDevice"} />
                <Route path="/Main/MmsWorkOrderDetail" component={MmsWorkOrderDetail} history={this.props.history} key={"MmsWorkOrderDetail"} />

                {/* for main page, or path is main/undefined will drop to this */}
                <Route path="/Main/" component={Mainbody} history={this.props.history} key={"MainbodyPage"} />
            </Switch>
        ]
        //            <div onClick={this.TestFunction}>Test Socket emit</div>
    }
}

//Redux inside component function
function mapStateToProps(state) {
    return {
        count: state.count,
        log: state.log,
        socket: state.socket,
        level: state.level,
        currentlevel: state.currentlevel,
        SiteList: state.SiteList,
        currentSite: state.currentSite,
        MenuDisplay: state.MenuDisplay,
        DashboardPickedColdboxID: state.DashboardPickedColdboxID
    };
}
export default connect(mapStateToProps)(Main);