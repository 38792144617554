import React from 'react'
//Redux Import
import { connect } from 'react-redux'
import './pickSiteWidget.css'

class PickSiteWidget extends React.Component {
    constructor(props)
    {
        super(props)

        this.PickSite = this.PickSite.bind(this)
        this.setReduxCurrentLevel = this.setReduxCurrentLevel.bind(this)

        this.state = {
            SiteList: []
        }
    }

    componentDidMount()
    {
        //set the SiteList
        //console.log(this.props.SiteList);
        //foreach >name
        let SiteList = [];
        if(this.props.UserInfo == null) return
        this.props.UserInfo.locations.map((data, i) => {     
            //console.log(data);
            SiteList.push(data.locName);
            return null
        })
    }


    PickSite(event)
    {
        //get the picker item
        //console.log(event.target.id); //this is the site ID
        let siteID = event.target.id
        let LevelList = []
        //get level list of that site
        if(this.props.UserInfo == null)
        {
            console.error('this.props.UserInfo == null')
            return
        }
        this.props.UserInfo.locations.map((data, i) => {
            let locIdx_int = parseInt(data.locIdx)
            let siteID_int = parseInt(siteID) + 1
            //console.log(locIdx_int);
            //console.log(siteID_int + 1);
            if(locIdx_int == siteID_int) LevelList = data.nestedLocs
            return null
        })
        //send the redux function
        this.props.dispatch({ type: 'PIckSite', data: {
            level: LevelList,
            Siteid: siteID
        }})
        //console.log(LevelList);

        //close the popup
        this.props.closePopup()

        
        this.setReduxCurrentLevel(siteID, 0)
    }


    setReduxCurrentLevel(currentSite, currentLevel)
    {
        //set the current level in redux
        //currentLevel is the array[0] number, not the real level value
        const thisoutside = this
        try
        {
            //console.log(thisoutside.props.UserInfo.locations[currentSite].nestedLocs[currentLevel].locName);
            thisoutside.props.dispatch({ type: 'PickLevel', data: thisoutside.props.UserInfo.locations[currentSite].nestedLocs[currentLevel].locName })
        }
        catch(err)
        {
            console.error(err)
        }
    }

    render()
    {
        const { isShow, closePopup} = this.props            //parent paramters
        const {UserInfo, currentSite} = this.props          //redux value

        if(UserInfo == null || UserInfo == undefined) return <div>Some UserInfo Missing</div>
        
        let BuildingDisplay = "showsitepopup"
        if(!isShow) BuildingDisplay = "hidesitepopup"

        return [
        <div className={BuildingDisplay}>
            <div className="PickSiteWidget_ListContainer">
                {
                    this.props.UserInfo.locations.map((data, i) => {
                        let IsSelectedSite = "";
                        if(i == this.props.currentSite)
                        {
                            IsSelectedSite = "highlightSiteItem";
                        }
                        return <div value={i} id={i} onClick={this.PickSite} className={"PickSiteWIdget_item " + IsSelectedSite}>
                            {data.locName}
                        </div>
                    })
                }
            </div>
            <i className="fa fa-times PickSiteWidget_closebtn" onClick={closePopup}></i>
        </div>,
        <div className={" PickSiteWidget_BgDiv " + BuildingDisplay} onClick={closePopup}></div>
        ]
    }
}

//Redux inside component function
function mapStateToProps(state) {
    return {
      socket: state.socket,
      level: state.level,
      currentlevel: state.currentlevel,
      currentSite: state.currentSite,
      MenuDisplay: state.MenuDisplay,
      UserInfo: state.UserInfo,
      SiteList: state.SiteList
    }
}

export default connect(mapStateToProps)(PickSiteWidget)