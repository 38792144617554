import React from 'react'
import './RealTimeAlert.css'
import { connect } from 'react-redux'

class RealTimeAlert extends React.Component {
    constructor(props) {
        super()
        this.state = {
            displayTxt: "",     //debug message that would be shown on bottom left
            isShow: false,      //show / not show debug message
            color: "black",

            newAlertIsShow: false,  //display or not, new alert notification popup
            newAlertTitle: "",
            newAlertMsg: "",
            newAlertDatetime: ""
        }

        this.CreateBottomAlert = this.CreateBottomAlert.bind(this)
        this.closeAlert = this.closeAlert.bind(this)
    }
    componentDidMount() {
        this.props.onRef(this)
    }
    componentWillUnmount() {
        this.props.onRef(undefined)
    }
    CreateBottomAlert(displayTxt, isShow, color) {
        //if config setting is false, hide alert
        const {configStorage} = this.props
        if(configStorage!=null&&configStorage.isShowBtmMsg==false) {
            console.log('debug log CreateBottomAlert() in Narbar/RealTimeAlert would not function, since config setting is false')
            return
        }
        //show bottom alert
        this.setState({
            displayTxt: displayTxt,
            isShow: isShow,
            color: color
        })
        const _this = this
        setTimeout(() => {
            _this.setState({
                displayTxt: "",
                isShow: false
            })
        }, 2000)
    }
    closeAlert() {
        this.setState({
            newAlertIsShow: false,  //display or not, new alert notification popup
            newAlertTitle: '',
            newAlertMsg: '',
            newAlertDatetime: ''
        })
    }

    render() {
        const {displayTxt, isShow, color, newAlertTitle, newAlertMsg, newAlertDatetime, newAlertIsShow} = this.state
        let alertColor = ''
        let isShowClass = 'hideBottomAlert'
        if(color == 'black') alertColor = 'blackBottomAlert'
        if(isShow) isShowClass = ''

        let newAlertShow = 'hideNewAlertPopup'
        if(newAlertIsShow) newAlertShow = ''

        let {severity, type, typeName, location} = this.state
        if(location == null) location = ''
        if(typeName == null) typeName = ''
        if(type == null) type = ''
        let severityColor = ''
        if(severity == 'Urgent') severityColor = ' Urgent'
        if(this.props.UserInfo != null){ //20210503 do not show bottom alert (CPSL)
            if(this.props.UserInfo.client_id =="6006850161a378359cb738bf") return ''
        }
        return [
            <div className={"RealTimeAlertContainer " + alertColor + " " + isShowClass}>
                <div className="fa fa-refresh fa-spin RealTimeAlertIcon"></div>
                {displayTxt}
            </div>,
            <div className={"RealTimeAlert_newAlertContainer " + newAlertShow + severityColor} onClick={this.closeAlert}>
                <div className="RealTimeAlert_newAlertTitle">
                    {newAlertTitle}
                </div>
                <div className="RealTimeAlert_newAlertMsg">
                    {type + ": " + typeName}
                </div>
                <div className="RealTimeAlert_newAlertMsg">
                    {location}
                </div>
                <div className="RealTimeAlert_newAlertMsg">
                    {newAlertMsg}
                </div>
                <div className="RealTimeAlert_newAlertDatetime">
                    {newAlertDatetime}
                </div>
            </div>
        ]
    }
}

function mapStateToProps(state) {
    return {
        EmitSocketStr: state.EmitSocketStr,
        configStorage: state.configStorage,
        UserInfo:state.UserInfo
    }
}
export default connect(mapStateToProps)(RealTimeAlert)