import React from 'react';
//Redux Import
import { connect } from 'react-redux';
import './SetSound.css';

class SetSound extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            SettingControlIsOn: true
        };

        this.OnOffControlClick = this.OnOffControlClick.bind(this);
        this.setStateValue = this.setStateValue.bind(this);

        // this.IntervalLoopCheckStatus = {};
    }
    setStateValue()
    {
        //check is dark or light mode now
        let SoundOnStorage = localStorage.getItem('SoundOn')
        let OnOffStatus = false
        if(SoundOnStorage == null || SoundOnStorage == "") OnOffStatus = false
        else if(SoundOnStorage == "TRUE") OnOffStatus = true
        else OnOffStatus = false

        if(OnOffStatus)
        {
            this.setState({
                SettingControlIsOn: false 
            })
        }
        else
        {
            this.setState({
                SettingControlIsOn: true 
            })
        }
    }
    componentDidMount()
    {
        // const thisoutside = this
        this.setStateValue()
        // this.MapOnloadLoop = setInterval(() => {
        //     thisoutside.setStateValue()                //Set the loop function
        //     //console.log('I am alive');
        // }, 1000);
    }
    // componentWillUnmount()
    // {
    //     // clearInterval(this.IntervalLoopCheckStatus);    //remove the loop function
    // }
    OnOffControlClick()
    {
        const {SettingControlIsOn} = this.state
        // let thisoutside = this;
        this.setState({
            SettingControlIsOn: !SettingControlIsOn
        });
        //save setting to local storage
        if(SettingControlIsOn) localStorage.setItem('SoundOn', 'FALSE')
        else localStorage.setItem('SoundOn', 'TRUE')
    }
    render() {
        const {SettingControlIsOn} = this.state
        let OnOffState = ""
        let OnOffMarginLeft = ""
        let SoundDisplayText =  "Disabled"
        if(SettingControlIsOn)
        {
            OnOffState = "OnOffState"
            OnOffMarginLeft = "OnOffMarginLeft"
            SoundDisplayText = "Enabled"
        }
        return <div className="SetDarkModeContainer">
            <div className="SetDarkModeTitle">
                Sound {SoundDisplayText}
            </div>
            <div className={"SettingOnOffControl "+OnOffState} onClick={this.OnOffControlClick}>
                <div className={"SettingOnOffPointer "+OnOffMarginLeft}></div>
            </div>
        </div>
    }
}

//Redux inside component function
function mapStateToProps(state) {
    return {
      socket: state.socket,
      configStorage: state.configStorage,
    };
}

export default connect(mapStateToProps)(SetSound);