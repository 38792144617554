import React, { useState, useEffect } from 'react'
import './LineChart.css'
import LoadingWidget from '../loading/loading'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

function LineChartWidget(props) {
    const {ChartData, title, keystr} = props

    if(ChartData == null || ChartData.length == null) return <LoadingWidget />
    const HighchartData = GenHighchartData(ChartData, keystr)

    let options = {
        title:{
            text:''
        },
        chart: {
            zoomType: 'x',
            backgroundColor: 'transparent'
        },
        xAxis: {
            type: 'datetime',
            labels: {
                formatter: function() {
                  return Highcharts.dateFormat('%b %e, %Y', this.value);
                }
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: title
            }
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        series: HighchartData
    }

    return <div className="LiftSum_Linechart">
        <HighchartsReact highcharts={Highcharts} options={options} constructorType={'chart'} className=""
            containerProps={{ style: { height: '100%' } }}/>
    </div>
}

export default LineChartWidget


function GenHighchartData(ChartData, keystr) {
    const UniqueLocList = GetDataNameList(ChartData)
    if(UniqueLocList == null || UniqueLocList.length == null || UniqueLocList.length == 0) return []
    
    let ReturnItem = []
    UniqueLocList.forEach(LocStr => {
        const ValueDataList = ChartData.filter((item) => {
            return item.locationName == LocStr
        })
        const formattedDataList = ValueDataList.map((item) => {
            return [
                Date.parse(item.time),
                item[keystr],
            ]
        })
        ReturnItem.push({
            name: LocStr,
            data: formattedDataList,
        })
    })
    return ReturnItem
}
function GetDataNameList(ArrayInput) {
    const NameList = ArrayInput.map((item) => {
        return item.locationName
    })
    const UniqueLocation = NameList.filter(onlyUnique)
    return UniqueLocation
}
function onlyUnique(value, index, self) {
    return self.indexOf(value) === index
}