import React from 'react';
//Redux Import
import { connect } from 'react-redux';
import './SetSoundRange.css';

class SetSoundRange extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            SettingControlIsOn: true,
            Volume: 0,
            VolumeDisplay: "0%"
        };

        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(event)
    {
        let displayVolume = event.target.value/1000 * 100;
        this.setState({
            VolumeDisplay: displayVolume.toFixed(1) + "%",
            Volume: event.target.value
        });
    }
    render() {
        return <div className="SetSoundRangeContainer">
        <div className="SetSoundRangeTItle">
            Sound Volume:
            <div className="SetSoundVolumeReading">
                {this.state.VolumeDisplay}
            </div> 
        </div>
        <div className="SettingRangeControl">
            <input type="range" min="1" max="1000" value={this.state.Volume}  className="rangeControl" onChange={this.handleChange}></input>
        </div>
    </div>
    }
}

//Redux inside component function
function mapStateToProps(state) {
    return {
      socket: state.socket,
      configStorage: state.configStorage
    };
}

export default connect(mapStateToProps)(SetSoundRange);