import React from 'react'
import {connect} from 'react-redux'
import Moment from 'react-moment'
import {motion} from "framer-motion"
import './style.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faServer, faTimes} from '@fortawesome/free-solid-svg-icons'

import revertGreyTxt from './textColor'
import { isConvertWaterLeakageToWaterPump, isConvertToWaterLeakageAndPump } from '../../MenuList/MenuListItem'

class DeviceInfo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            menuList: []
        }
        this.closeGWPopup = this.closeGWPopup.bind(this)
    }
    componentDidMount() { //create local state: menuList
        const {currentSite, currentlevel, UserInfo} = this.props
        let deviceList_try = []
        UserInfo.locations[currentSite].nestedLocs.forEach(floorItem => {
            if(currentlevel !== floorItem.locName) return
            floorItem.nestedLocs.forEach(LocItem => {
                let newItem = false
                deviceList_try.forEach(element => {
                    if(element.cardTitle == LocItem.payload_definition_id.cardTitle) newItem = true
                })
                if(!newItem) {    //new cardTitle
                    try {
                        deviceList_try.push(LocItem.payload_definition_id)
                    }
                    catch(err) {
                        console.log(err)
                    }
                }
            })
        })
        this.setState({menuList: deviceList_try})
    }
    closeGWPopup() {
        this.props.closeGWItem()
    }
    render() {
        const {currentSite, currentlevel, UserInfo} = this.props
        const {menuList} = this.state
        const client_id= UserInfo?UserInfo.client_id:'--'

        if(isSwimmingPool(this.props)) return <></>

        // 1. IS GW POPUP?
        const {pickedGWItem} = this.props
        if(pickedGWItem!=null) return <div className='deviceInfo_GW'>
            <div className="title">
                <FontAwesomeIcon icon={faServer} className='fa' />
            </div>
            <div className='bottom'>
                <div className="deviceName">
                    {pickedGWItem.locName}
                </div>
                {/* <div className={pickedGWItem.payload.status=='Disconnected'?'status error':'status normal'}>
                    {pickedGWItem.payload.status}
                </div> */}
                <div className='closeBtn' onClick={this.closeGWPopup}>
                    <FontAwesomeIcon icon={faTimes} className='fa' />
                </div>
            </div>
        </div>

        // 2. NORMAL POPUP FOR DEVICES
        const {PickedPolygonLoc, currentBottomMenu, DeviceData, Notification} = this.props   //redux parameter
        if(currentBottomMenu == null || PickedPolygonLoc == null) return <div></div>

        let splitMenuInt = currentBottomMenu.split('bottom')
        if(splitMenuInt == null || splitMenuInt.length == null || splitMenuInt.length == 0 || splitMenuInt.length == 1) return <div className="deviceInfo_dvcName">splitMenuInt is empty</div>
        if(menuList == null || menuList.length == null || menuList.length == 0) return <div className="deviceInfo_dvcName">menuList os empty</div>
        const MenuInt = Number(splitMenuInt[1], 0)

        if(DeviceData == null || DeviceData.length == null || DeviceData.length == 0) return <div className="deviceInfo_dvcName">Device Data is not loaded</div>
        const {payload, deviceTypeColor, isShowPopup, locationItem} = getLocData(DeviceData, PickedPolygonLoc)

        if(isShowPopup == 0 || isShowPopup == false) {
            console.log('since the location setting not allow show popup, this widget would remain unshown')
            return <div style={{display: 'none'}}></div>
        }

        const readingArray = payloadToArray(payload)

            // 2.1 IS WATER PUMP POPUP
            const isWaterPump = isConvertWaterLeakageToWaterPump({
                ...this.props,
                nameID: menuList && MenuInt ? menuList[MenuInt]?.cardTitle : ''
            }) || isConvertToWaterLeakageAndPump({
                ...this.props,
                nameID: menuList && MenuInt ? menuList[MenuInt]?.cardTitle : ''
            });
            if(isWaterPump) {
                if(!payload) return <></>
                return <motion.div className="deviceInfo" style={{
                    marginTop: '0.5rem'
                }}
                drag
                dragConstraints={{
                    top: 0,
                    left: 0,
                    right: window.innerWidth * 0.6,
                    bottom: 450,
                }}>
                    <div className="deviceInfo_card">
                        <div className="deviceInfo_dvcName" style={{background: 'rgb(0,0,128)', color: 'white'}}>Water Pump</div>
                        <div className="deviceInfo_locName">{PickedPolygonLoc}</div>
                        <div className="DeviceInfo_LastUpdaeRow">
                            <div className="DeviceInfo_LastUpdateTitle">{langDisplay('title', 'Last Update', null)}</div>
                            {
                                (payload!= null && payload.updateDate != null)?<Moment className="LastUpdateDateMoment" format="DD/MM HH:mm">{payload.updateDate}</Moment>:
                                <div className="LastUpdateDateMoment">--</div>
                            }
                        </div>
                        <div className="deviceInfo_scrollData" style={{maxHeight: readingArray.length>=8?'15rem':'10rem'}}>
                            <div className="deviceInfo_readings" style={{background: payload.waterLeakageStatus ? 'red' : 'green'}}>
                                <div className="deviceInfoTxt">Water Pump</div>
                                <div className="deviceInfo_readingValue">{payload.waterLeakageStatus ? 'Fault' : 'Normal'}</div>
                            </div>
                        </div>
                    </div>
                </motion.div>
            }

        //component GUI
        return <motion.div className="deviceInfo" style={{marginTop: readingArray.length>=10?'4rem':'0.5rem'}}
        drag
        dragConstraints={{
          top: 0,
          left: 0,
          right: window.innerWidth * 0.6,
          bottom: 450,
        }} >
            <div className="deviceInfo_card">
                <div className="deviceInfo_dvcName" style={{background: deviceTypeColor, color: revertGreyTxt(deviceTypeColor)}}>
                    {(menuList==null || menuList.length==null || menuList.length==0||menuList[MenuInt]==null)?"--":langDisplay('title', menuList[MenuInt].cardTitle, null)}
                </div>
                <div className="deviceInfo_locName">{PickedPolygonLoc}</div>
                <div className="DeviceInfo_LastUpdaeRow">
                    <div className="DeviceInfo_LastUpdateTitle">{langDisplay('title', 'Last Update', null)}</div>
                    {
                        (payload!= null && payload.updateDate != null)?<Moment className="LastUpdateDateMoment" format="DD/MM HH:mm">{payload.updateDate}</Moment>:
                        <div className="LastUpdateDateMoment">--</div>
                    }
                </div>
                {
                    isWaterLeakageItem(PickedPolygonLoc, payload, Notification)
                }
                <div className="deviceInfo_scrollData" style={{maxHeight: readingArray.length>=8?'15rem':'10rem'}}>
                {
                    (payload == null)? <div className="deviceInfo_readings" style={{background: "rgba(0, 0, 0, 0)"}}>
                        <div className={"fa fa-warning" + " deviceInfoIcon"}></div>
                        <div className="deviceInfoTxt">
                            {langDisplay('title', 'noData', null)}
                        </div>
                    </div>:<div></div>
                }
                {
                    readingArray.map(function(payloadItem) {
                        const {FaIcon, displayTitle, cardColor, readingDisplay, unitStr} = genFieldDisplayData(payloadItem.key, payloadItem)
                        switch(payloadItem.key) {
                            case "lightOn":
                            case "lastTriggerTime":
                            case "white":
                            case "yellow":
                            case "isSecondMsg":
                            case 'pm1_0':
                            case 'lowBattery':
                                return <div></div>      //not show to client
                            case "occupiedTime":
                                //check if there is vacant or occupied items in list
                                const isVacant = (payload.roomOccupied == false || payload.roomOccupied == "false")
                                const now = new Date()
                                const lastTriggerTime = Date.parse(payload.lastTriggerTime);
                                const min = (Math.floor((now - lastTriggerTime)/60000))
                                //if not exist, not show this row
                                if(isVacant) return <div></div>
                                else return <div className="DeviceInfo_LastUpdaeRow">
                                    <div className="DeviceInfo_LastUpdateTitle">
                                        Occupied:
                                    </div>
                                    <div className="deviceInfo_readingValue">
                                    {
                                        (payload.lastTriggerTime == null)?"--":min + " min"
                                    }
                                    </div>
                                </div>                      //if exist, show alarm trigger X min before
                            case 'windDirection':
                                return <div className="deviceInfo_readings" style={{background: cardColor}}>
                                    <div className={"fa fa-arrows-alt deviceInfoIcon"}></div>
                                    <div className="deviceInfoTxt">Direction</div>
                                    <div className="deviceInfo_readingValue">{readingDisplay + " " + unitStr}</div>
                                </div>
                            case 'o3':
                                if(PickedPolygonLoc.includes('NT-IAQ')) {
                                    return <div className="deviceInfo_readings" style={{background: cardColor}}>
                                        {
                                            FaIcon==''?
                                            <div style={{display: 'none'}}></div>
                                            :
                                            <div className={"fa fa-" + FaIcon + " deviceInfoIcon"}></div>
                                        }
                                        <div className="deviceInfoTxt">
                                            {displayTitle}
                                        </div>
                                        <div className="deviceInfo_readingValue">
                                            {readingDisplay + ' ppb'}
                                        </div>
                                    </div>
                                }
                                break
                            case 'hcho':
                                if(PickedPolygonLoc.includes('NT-IAQ')) {
                                    return <div className="deviceInfo_readings" style={{background: cardColor}}>
                                        {
                                            FaIcon==''?
                                            <div style={{display: 'none'}}></div>
                                            :
                                            <div className={"fa fa-" + FaIcon + " deviceInfoIcon"}></div>
                                        }
                                        <div className="deviceInfoTxt">
                                            {displayTitle}
                                        </div>
                                        <div className="deviceInfo_readingValue">
                                            {readingDisplay + ' ppb'}
                                        </div>
                                    </div>
                                }
                                break
                            case 'airflow':
                                if(client_id === '62946538e522c897b5d126d4') {
                                    return <div className="deviceInfo_readings" style={{background: cardColor}}>
                                        <div className="deviceInfoTxt">
                                            Air Flow
                                        </div>
                                        <div className="deviceInfo_readingValue">
                                            {readingDisplay + " " + 'L/s'} 
                                        </div>
                                    </div>
                                }
                                //'m³/s'
                                break
                        }

                        if(isCurrentItemOfAitFlowUsage(locationItem, payloadItem.key)) return <></>

                        return <div className="deviceInfo_readings" style={{background: cardColor}}>
                            {
                                FaIcon==''?
                                <div style={{display: 'none'}}></div>
                                :
                                <div className={"fa fa-" + FaIcon + " deviceInfoIcon"}></div>
                            }
                            <div className="deviceInfoTxt">
                                {displayTitle}
                            </div>
                            <div className="deviceInfo_readingValue">
                                {readingDisplay + " " + unitStr}
                            </div>
                        </div>
                    })
                }
                </div>
            </div>
        </motion.div>
    }
}

function mapStateToProps(state) {
    return {
      UserInfo: state.UserInfo,
      currentSite: state.currentSite,
      currentlevel: state.currentlevel,
      currentlevelID: state.currentlevelID,
      PickedPolygonLoc: state.PickedPolygonLoc,
      currentBottomMenu: state.currentBottomMenu,
      Notification: state.Notification,
      DeviceData: state.DeviceData,
      DeviceDataLastUpdate: state.DeviceDataLastUpdate
    }
}
export default connect(mapStateToProps)(DeviceInfo)

function genFieldDisplayData(key, payloadItem) {
    let returnData = {
        FaIcon: setting[key]==null||setting[key].FaIcon==null?'':setting[key].FaIcon,
        displayTitle: setting[key]==null||setting[key].displayTitle==null?key:setting[key].displayTitle,
        cardColor: setting[key]==null||setting[key].cardColor==null?'rgba(0, 0, 0, 0)':setting[key].cardColor,
        readingDisplay: setting[key]==null||setting[key].readingDisplay==null?payloadItem.reading:setting[key].readingDisplay,
        unitStr: setting[key]==null||setting[key].unitStr==null?'':setting[key].unitStr
    }
    let readingDisplay = returnData.readingDisplay

    if(boolSetting[key] != null) {
        const {condition, icon} = boolSetting[key]
        let isTrue = false
        condition.forEach(value => {
            if(value == readingDisplay) isTrue = true
        })
        const boolData = isTrue?boolSetting[key]['true']:boolSetting[key]['false']
        returnData.FaIcon = icon
        returnData = {
            ...returnData,
            ...boolData
        }
    }

    return returnData
}
function getLocData(DeviceData, PickedPolygonLoc) {
    let payload, deviceTypeColor, isShowPopup, locationItem

    DeviceData.forEach(siteItem => {
        siteItem.nestedLocs.forEach(floorItem => {
            floorItem.nestedLocs.forEach(LocItem => {
                if(LocItem.locName == PickedPolygonLoc) {
                    payload = LocItem.payload
                    try {
                        isShowPopup = LocItem.locUI.showPopup
                        deviceTypeColor = LocItem.locUI.indicator.color
                        locationItem = LocItem
                    }
                    catch(err) {
                        console.log(err)
                    }
                }
            })
        })
    })
    return {
        payload: payload,
        deviceTypeColor: deviceTypeColor,
        isShowPopup: isShowPopup,
        locationItem: locationItem
    }
}
function payloadToArray(payload) {
    let readingArray = []
    for (var key in payload) {
        let readingValue
        if(key !== 'updateDate') {      //update date time is not a reading type
            if (typeof payload[key] === 'boolean' || key == 'windDirection') readingValue = payload[key].toString()
            else readingValue = Number(payload[key]).toFixed(1)

            readingArray.push({
                key: key,
                reading: readingValue
            })
        }
    }
    //sorting the list before display
    readingArray = readingArray.sort(function(a, b) {
        if (a.key < b.key) return -1
        if (a.key > b.key) return 1
        return 0
    })

    return readingArray
}

const langDisplay = (type, input, value) => {
    const lang = getLang()

    if(type == 'fieldDisplay') {
        const statusStr = (value==true || value=='true' || value==1)?'true':'false'
        if(languageDisplay[type][input]==null) return input
        return languageDisplay[type][input][lang][statusStr]
    }

    if(languageDisplay[type]==null||languageDisplay[type][input]==null) return input
    return languageDisplay[type][input][lang]
}
const getLang = () => {
    const langStr = localStorage.getItem('lang')
    if(langStr==null||langStr=='') {
        localStorage.setItem('lang', 'eng')
        return 'eng'
    }

    return langStr
}

//Settings (config) ---------------------------------------------------------------------------------------
const languageDisplay = {
    title: {       //eng = display original value, sensorType(input)
        'Door': {
            eng: 'Door',
            chin: '門'
        },
        'Water Leakage': {
            eng: 'Water Leakage',
            chin: '漏水'
        },
        'Occupancy': {
            eng: 'Occupancy',
            chin: '在場感測器'
        },
        'Disabled Parking': {
            eng: 'Disabled Parking',
            chin: '傷健車位'
        },
        'Car Park': {
            eng: 'Car Park',
            chin: '停車場'
        },
        'Last Update': {
            eng: 'Last Update',
            chin: '最後更新'
        },
        'noData': {
            eng: 'No Data',
            chin: '沒有數據'
        },
    },
    fieldDisplay: {  //value==true || value=='true' || value==1
        'Door': {
            eng: {
                true: 'Open',
                false: 'Close'
            },
            chin: {
                true: '開',
                false: '關'
            }
        },
        'Water Leakage': {
            eng: {
                true: 'Normal',
                false: 'Abnormal'
            },
            chin: {
                true: '正常',
                false: '漏水'
            }
        },
        'Occ': {
            eng: {
                true: 'Occupied',
                false: '使用中'
            },
            chin: {
                true: 'Vacant',
                false: '可使用'
            }
        },
    }
}
const setting = {
    'co2': {
        FaIcon: 'cloud',
        displayTitle: 'CO₂',
        unitStr: 'ppm'
    },
    'co': {
        FaIcon: 'cloud',
        displayTitle: 'CO',
        unitStr: 'ppm'
    },
    'o3': {
        FaIcon: 'cloud',
        displayTitle: 'O₃',
        unitStr: 'ppm'
    },
    'pm2_5': {
        FaIcon: "cloud",
        displayTitle: "PM 2.5",
        unitStr: 'µg/m³'
    },
    'pm01': {
        FaIcon: 'cloud',
        displayTitle: 'PM 1.0',
        unitStr: 'µg/m³'
    },
    'pm10': {
        FaIcon: 'cloud',
        displayTitle: 'PM 10',
        unitStr: 'µg/m³'
    },
    'nh3': {
        FaIcon: 'cloud',
        displayTitle: 'NH3',
        unitStr: 'ppb'
    },
    'no2': {
        FaIcon: 'cloud',
        displayTitle: 'NO2',
        unitStr: 'ppb'
    },
    'temperature': {
        FaIcon: 'thermometer-half',
        displayTitle: 'Temp',
        unitStr: '°C'
    },
    'humidity': {
        FaIcon: 'tint',
        displayTitle: 'Humid',
        unitStr: '%'
    },
    'illuminance': {
        FaIcon: 'lightbulb-o',
        unitStr: 'lx'
    },
    'light': {
        FaIcon: 'lightbulb-o',
        displayTitle: 'Light',
        unitStr: 'lx'
    },
    'fillDistance': {
        FaIcon: 'trash',
        unitStr: '%'
    },
    'peopleIn': {
        FaIcon: 'users',
        unitStr: 'People In'
    },
    'peopleOut': {
        FaIcon: 'users',
        unitStr: 'People Out'
    },
    'tvoc': {
        FaIcon: 'cloud',
        displayTitle: 'TVOC',
        unitStr: 'µg/m³'
    },
    'hcho': {
        FaIcon: 'cloud',
        displayTitle: 'HCHO',
        unitStr: 'ppm'
    },
    'sound': {
        FaIcon: 'soundcloud',
        displayTitle: 'Sound',
        unitStr: 'dB'
    },
    'motion': {
        FaIcon: 'compass',
        displayTitle: 'Motion'
    },
    'windSpeed': {
        FaIcon: 'tachometer',
        displayTitle: 'Speed',
        unitStr: 'km/hr'
    },
    //power rows
    'acPower': {
        FaIcon: 'bolt',
        displayTitle: 'Real Energy',
        unitStr: 'kWh'
    },
    'i1_DMD': {
        FaIcon: 'bolt',
        displayTitle: 'Current 1',
        unitStr: 'A'
    },
    'i2_DMD': {
        FaIcon: 'bolt',
        displayTitle: 'Current 2',
        unitStr: 'A'
    },
    'i3_DMD': {
        FaIcon: 'bolt',
        displayTitle: 'Current 3',
        unitStr: 'A'
    },
    'kVAh': {
        FaIcon: 'bolt',
        displayTitle: 'Apparent',
        unitStr: 'kVAh'
    },
    'kWhExp': {
        FaIcon: 'bolt',
        displayTitle: 'Energy',
        unitStr: 'kWhExp'
    },
    'kvarhExp': {
        FaIcon: 'bolt',
        displayTitle: 'React. Exp',
        unitStr: 'kvarh'
    },
    'kvarhImp': {
        FaIcon: 'bolt',
        displayTitle: 'React. Imp',
        unitStr: 'kvarh'
    },
    'p_DMD': {
        FaIcon: 'bolt',
        displayTitle: 'Demand P',
        unitStr: 'W'
    },
    'q_DMD': {
        FaIcon: 'bolt',
        displayTitle: 'Demand q',
        unitStr: 'W'
    },
    's_DMD': {
        FaIcon: 'bolt',
        displayTitle: 'Demand s',
        unitStr: 'W'
    },
    'current': {
        displayTitle: 'Raw Current',
        unitStr: 'mA'
    },
    // 'fineCurrent': {
    //     displayTitle: 'Current',
    //     unitStr: 'mA'
    // },
    'adcRawValue2': {
        displayTitle: 'Voltage',
        unitStr: 'V'
    },
    'airflow': {
        displayTitle: 'Air Flow',
        unitStr: 'kPa'
    },
    //toilet sensors
    'waterLevel': {
        FaIcon: 'tint',
        displayTitle: 'Level',
        unitStr: '%'
    },
    'pH': {
        FaIcon: 'asterisk',
        displayTitle: 'pH',
        unitStr: 'pH'
    },
    'ph': {
        FaIcon: 'asterisk',
        displayTitle: 'pH',
        unitStr: 'pH'
    },
    'noiseLevel': {
        FaIcon: 'soundcloud',
        displayTitle: 'Noise Lv.',
        unitStr: 'dB'
    },
    'h2s': {
        FaIcon: 'cloud',
        displayTitle: 'H₂S',
        unitStr: 'ppm'
    }
}
const boolSetting = {
    'roomOccupied': {
        condition: ['false', false],
        'true': {
            cardColor: 'rgba(51, 255, 0, 0.5)',
            readingDisplay: 'Vacant',
            displayTitle: ''
        },
        'false': {
            cardColor: 'rgba(255, 0, 0, 1)',
            readingDisplay: 'Occupied',
            displayTitle: ''
        },
        'icon': 'user'
    },
    'fallDetected': {
        condition: ['false', false],
        'true': {
            cardColor: 'rgba(51, 255, 0, 0.5)',
            readingDisplay: 'No Fall Alarm',
            displayTitle: ''
        },
        'false': {
            cardColor: 'rgba(255, 0, 0, 1)',
            readingDisplay: 'Fall Detected Alarm',
            displayTitle: ''
        },
        'icon': 'bell'
    },
    'abnormalVibration': {
        condition: ['false', false, 0, null],
        'true': {
            cardColor: 'rgba(0, 169, 0, 1)',
            readingDisplay: '',
            displayTitle: 'No Abnormal Vibrat.'
        },
        'false': {
            cardColor: 'rgba(255, 0, 0, 1)',
            readingDisplay: '',
            displayTitle: 'Abnormal Vibration'
        },
        'icon': 'bell'
    },
    'waterLeakageStatus': {
        condition: ['false', false, 0, null],
        'true': {
            cardColor: 'rgba(0, 169, 0, 1)',
            readingDisplay: '',
            FaIcon: '',
            displayTitle: langDisplay('fieldDisplay', 'Water Leakage', true)
        },
        'false': {
            cardColor: 'rgba(255, 0, 0, 1)',
            FaIcon: '',
            readingDisplay: '',
            displayTitle: langDisplay('fieldDisplay', 'Water Leakage', false)
        },
        'icon': 'bell'
    },
    'doorOpen': {
        condition: ['true', true, 1, null],
        'true': {
            readingDisplay: langDisplay('fieldDisplay', 'Door', true),
            displayTitle: 'Door'
        },
        'false': {
            readingDisplay: langDisplay('fieldDisplay', 'Door', false),
            displayTitle: 'Door'
        },
        'icon': ''
    },
    'occupied': {
        condition: ['false', false, 0, null],
        'true': {
            readingDisplay: langDisplay('fieldDisplay', 'Occ', true),
            displayTitle: ''
        },
        'false': {
            readingDisplay: langDisplay('fieldDisplay', 'Occ', false),
            displayTitle: ''
        },
        'icon': ''
    },
    'liquidLevel': {
        condition: ['true', true, 1, null],
        'true': {
            readingDisplay: 'Normal',
            displayTitle: ''
        },
        'false': {
            readingDisplay: 'No Soap',
            displayTitle: ''
        },
        'icon': 'flask'
    },
    'hasPaper': {
        condition: ['true', true, 1, null],
        'true': {
            readingDisplay: 'Normal',
            displayTitle: ''
        },
        'false': {
            readingDisplay: 'No Toilet Paper',
            displayTitle: ''
        },
        'icon': 'sticky-note'
    },
    'isFull': {
        condition: ['true', true, 1, null],
        'true': {
            readingDisplay: 'Full',
            displayTitle: ''
        },
        'false': {
            readingDisplay: 'Available',
            displayTitle: ''
        },
        'icon': 'trash'
    },
    // 'status': {
    //     condition: ['true', true, 1, null],
    //     'true': {
    //         readingDisplay: 'Open',
    //         displayTitle: 'Dry Contact'
    //     },
    //     'false': {
    //         readingDisplay: 'Close',
    //         displayTitle: 'Dry Contact'
    //     },
    //     'icon': ''
    // },
}

//water leakage extend
const isWaterLeakageItem = (PickedPolygonLoc, payload, Notification) => {
    if(payload==null) return <></>
    if(payload.waterLeakageStatus==null) return <></>
    return waterLeakageDisplay(PickedPolygonLoc, Notification)
}
const waterLeakageDisplay = (PickedPolygonLoc, Notification) => {
    const {data} = Notification

    if(data==null) return <></>
    const relatedLocs = data.filter(locItem => {
        const {locationInfo} = locItem
        if(locationInfo==null) return 0
        let boolResult = false
        locationInfo.forEach(item => {
            if(item.lvl==3||item.lvl=='3') {
                if(item.name==PickedPolygonLoc) boolResult = true
            }
        })
        return boolResult?1:0
    })

    if(relatedLocs==null||relatedLocs.length==null||relatedLocs.length==0) return <></>
    //get latest alert create date
    let alertTimeList = relatedLocs.map(item => item.createdTime)
    alertTimeList.sort()

    const lastAlertTime = alertTimeList[alertTimeList.length-1]
    return <div className="DeviceInfo_LastUpdaeRow">
        <div className="DeviceInfo_LastUpdateTitle">{langDisplay('title', 'Last Alarm', null)}</div>
        {
            lastAlertTime!=null?<Moment className="LastUpdateDateMoment" format="DD/MM HH:mm">{lastAlertTime}</Moment>:
            <div className="LastUpdateDateMoment">--</div>
        }
    </div>
}

const isSwimmingPool = props => {
    const {DeviceData, currentSite, currentlevel} = props
    let site = DeviceData[currentSite]
    let siteName = site.locName

    return siteName.includes('Swimming')
}
const isCurrentItemOfAitFlowUsage = (deviceItem, key) => {
    if(key != 'current' && key != 'fineCurrent' && key != 'adcRawValue2' && key != 'status') return false
    if(deviceItem==null || deviceItem.payload_definition_id==null || deviceItem.payload_definition_id.nameID==null) return false

    return deviceItem.payload_definition_id.nameID=='Netvox_ADC'
}