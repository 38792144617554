import React from 'react'
import './panel.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsAltV, faSnowflake, faThermometerEmpty, faBolt, faInfo, faDesktop, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'

function OperOverWidget(props) {
    return <div className="OprOver_panel">
        <div className="PanelTitle">Legend</div>
        <div className="OpOr_Row">
            <div className="OpOr_icon">
                <div className="coldbox_statusIcon error">
                    <FontAwesomeIcon icon={faSnowflake} className="fa tempicon1" />
                    <FontAwesomeIcon icon={faThermometerEmpty} className="fa" />
                </div>
            </div>
            <div className="OpOr_txt">
                Not Cooling
            </div>
        </div>
        <div className="OpOr_Row">
            <div className="OpOr_icon">
                <div className="coldbox_statusIcon warn">
                    <FontAwesomeIcon icon={faSnowflake} className="fa tempicon1" />
                    <FontAwesomeIcon icon={faThermometerEmpty} className="fa" />
                </div>
            </div>
            <div className="OpOr_txt">
                Cooling
            </div>
        </div>
        <div className="OpOr_Row">
            <div className="OpOr_icon">
                <div className="coldbox_statusIcon normal">
                    <FontAwesomeIcon icon={faSnowflake} className="fa tempicon1" />
                    <FontAwesomeIcon icon={faThermometerEmpty} className="fa" />
                </div>
            </div>
            <div className="OpOr_txt">
                Cooled
            </div>
        </div>
        <div className="OpOr_Row">
            <div className="OpOr_icon">
                <div className={"coldbox_statusIcon error"}>
                    <div className="fa fa-battery-empty fa-rotate-270"></div>
                    <FontAwesomeIcon icon={faBolt} className="fa tempicon2" />
                </div>
            </div>
            <div className="OpOr_txt">
                Not Charging
            </div>
        </div>
        <div className="OpOr_Row">
            <div className="OpOr_icon">
                <div className={"coldbox_statusIcon warn"}>
                    <div className="fa fa-battery-three-quarters fa-rotate-270"></div>
                    <FontAwesomeIcon icon={faBolt} className="fa tempicon2" />
                </div>
            </div>
            <div className="OpOr_txt">
                Charging
            </div>
        </div>
        <div className="OpOr_Row">
            <div className="OpOr_icon">
                <div className="coldbox_OprIcon">
                    <FontAwesomeIcon icon={faInfo} className="fa" />
                </div>
            </div>
            <div className="OpOr_txt">
                Status
            </div>
        </div>
        <div className="OpOr_Row">
            <div className="OpOr_icon">
                <div className="coldbox_OprIcon">
                    <FontAwesomeIcon icon={faEdit} className="fa" />
                </div>
            </div>
            <div className="OpOr_txt">
                Set Point
            </div>
        </div>
        <div className="OpOr_Row">
            <div className="OpOr_icon">
                <div className="coldbox_OprIcon">
                    <FontAwesomeIcon icon={faDesktop} className="fa" />
                </div>
            </div>
            <div className="OpOr_txt">
                Floor Plan
            </div>
        </div>
        <div className="OpOr_Row">
            <div className="OpOr_icon">
                <div className="coldbox_OprIcon">
                    <FontAwesomeIcon icon={faTimes} className="fa" />
                </div>
            </div>
            <div className="OpOr_txt">
                Close
            </div>
        </div>
        <div className="OpOr_DecorateImg"></div>
    </div>
}

export default OperOverWidget