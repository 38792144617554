import React from 'react'
import './alarm.css'
import { connect } from 'react-redux'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import PopupNotification from '../../../popupNotification/PopupNotification'
//import inner widgets
import Searchbar from './alarm/searchbar/searchbar'
import Table from './alarm/table/table'
import TableHead from './alarm/table/tablehead'
import Downloadcsv from './alarm/bottom/downloadcsv'
import Pagebtns from './alarm/bottom/pagebtns'

class ColdboxAlarm extends React.Component {
    constructor(props) {
        super(props)        
        this.state = {
            isAdvanceSearch: false,
            rawAlarmList: null,
            AlarmList: null,        //sorted result (by table head .js)
            sortedList: null,       //filtered result (by searchbar.js)
            filteredList: null,     //slice by page (by pages.js widget)
            pageSliceList: null,
            updateCount: 0,
            startdate: new Date(moment().subtract(7, 'days')),
            enddate: new Date(),
            selectedLevel:""
        }
        this.getAlarm = this.getAlarm.bind(this)
        this.refresh = this.refresh.bind(this)
        this.relist = this.relist.bind(this)
        this.setSelectedLevel = this.setSelectedLevel.bind(this)
        this.acknowledgeUpdate = this.acknowledgeUpdate.bind(this)
        this.acknowledgeListener = this.acknowledgeListener.bind(this)

        //Update function from child widgets
        this.UpdateList = this.UpdateList.bind(this)
    }
    componentDidMount() {
        //clear redux storage to prevent show wrong logs
        this.props.dispatch({
            type: 'Notification',
            data: null
        })

        setTimeout((item) => {
            this.acknowledgeListener()
            this.refresh()
        }, 500)
    }
    componentDidUpdate() {
        if (this.props.Notification !== null && this.state.rawAlarmList !== this.props.Notification.data) this.relist()
    }
    getAlarm() {
        const {UserInfo} = this.props
        const {startdate, enddate, selectedLevel} = this.state
        if (UserInfo == null) return

        startdate.setMonth(startdate.getMonth())
        let requestData = {
            "msgType": "AlarmList",
            "userID": UserInfo.userID,
            "timeConstraint": {
                "start": convertDate(startdate),
                "end": convertDate(enddate),
            },
            "severityConstraint": "Normal,High",//"Low,Normal,High",
        }
        if(selectedLevel === "" || selectedLevel ===undefined || selectedLevel === null) {}
        else {
            requestData['locationConstraint'] = {
                "locLvl": 2,         // 1 - Site / 2 - Floor / 3 - Location
                "id": selectedLevel  // location id
            }
        }
        this.props.dispatch({
            type: 'EmitSocket',
            EmitSocketName: "AlarmRequest",
            EmitSocketData: requestData
        })
    }
    refresh() {
        this.getAlarm()
        this.relist()
    }
    relist() {
        const {Notification} = this.props
        if(Notification===null||Notification.data===null) return
        const _this = this
        let newList = []
        Notification.data.map(function (alarm) {
            const alarmItem = {
                coldboxID: (alarm.locationInfo!==null&&alarm.locationInfo!==undefined)?alarm.locationInfo[2].name:"",
                type: alarm.severity==="Normal"?"Warning":"Fault",
                // location: (alarm.locationInfo!==null&&alarm.locationInfo!==undefined)?alarm.locationInfo[0].name + ' - ' + alarm.locationInfo[1].name:"",
                location: (alarm.locationInfo!==null&&alarm.locationInfo!==undefined)?alarm.locationInfo[1].name:"",
                name: alarm.name,
                message: alarm.message,
                createdTime: alarm.createdTime,
                status: setStatus(alarm),
                remark: '',
                alarmID:alarm.id,  
                clearParty: alarm.clearParty,
                clearTime: alarm.clearTime,
                floor: (alarm.locationInfo!==null&&alarm.locationInfo!==undefined)?alarm.locationInfo[1].name:"",
                keywords: JSON.stringify(alarm)
            }
            newList.push(alarmItem)
        })
        _this.setState(prevState => ({
            ...prevState,
            AlarmList: newList,
            rawAlarmList: this.props.Notification.data
        }))
    }
    setSelectedLevel(data) {
        this.setState({ selectedLevel: data })
    }    
    acknowledgeUpdate(acknowledgedItem) {
        this.getAlarm()
        this.relist()    
        return
    }
    acknowledgeListener() {       //create socket listener for acknowledged alarm
        const _this = this
        const {socket} = this.props
        if(socket==null) {
            console.log('socket is null, tablePopup.js acknowledgeListener()')
            return
        }
        socket.on("UpdateAlarm", data=> {
            if(_this.PopupNotificationRef=== undefined) return
            if( data.result==="Success") {                    
                _this.PopupNotificationRef.addNotificationPopup("Acknowledge Success!", "Status of alarm record is updated.", "")
                _this.acknowledgeUpdate(data)
            }
            else _this.PopupNotificationRef.addNotificationPopup("Acknowledge Failed!", "Failed Message: " + data.message, "")
        })
    }
    //Update function from child widgets
    UpdateList(StateStr, Value) {
        const { updateCount } = this.state
        this.setState({
            [StateStr]: Value,
            updateCount: updateCount + 1,
        })
    }
    render() {
        const _this = this
        const { isAdvanceSearch, AlarmList, sortedList, filteredList, pageSliceList, updateCount } = this.state

        const { Notification } = this.props
        if(Notification==null||Notification.data==null||Notification.data.length==null||Notification.data.length==0) return <div className="CBAlarm">
            <Searchbar
                isAdvanceSearch={isAdvanceSearch}
                coldboxList={AlarmList}
                closeAdSearch={()=>_this.setState({isAdvanceSearch: false})}
                AdvanceSearch={()=>_this.setState({isAdvanceSearch: true})}
                setStartTime={(data)=>_this.setState({startdate: data})}
                setEndTime={(data)=>_this.setState({enddate: data})}
                refresh={_this.refresh}
                UpdateList={_this.UpdateList}
                setSelectedLevel={_this.setSelectedLevel}
            />
            <div className="CBAlarm_table">
                <TableHead
                    filteredList={filteredList}
                    UpdateList={_this.UpdateList}
                />
                {
                    Notification!=null&&Notification.data!=null&&Notification.data.length==0?
                    <div className='coldboxAlarm_center'>No Record</div>
                    :
                    <div className='coldboxAlarm_center'>
                        <FontAwesomeIcon icon={faSpinner} className='fa fa-spin' />
                    </div>
                }
            </div>
            <div className="CBAlarm_bottom">
                <Downloadcsv sortedList={sortedList} />
                <Pagebtns sortedList={sortedList} UpdateList={_this.UpdateList} count={updateCount} />
            </div> 
            <PopupNotification onRef={ref => {_this.PopupNotificationRef = ref}} />
        </div>

        return <div className="CBAlarm">
            <Searchbar
                isAdvanceSearch={isAdvanceSearch}
                coldboxList={AlarmList}
                closeAdSearch={()=>_this.setState({isAdvanceSearch: false})}
                AdvanceSearch={()=>_this.setState({isAdvanceSearch: true})}
                setStartTime={(data)=>_this.setState({startdate: data})}
                setEndTime={(data)=>_this.setState({enddate: data})}
                refresh={_this.refresh}
                UpdateList={_this.UpdateList}
                setSelectedLevel={_this.setSelectedLevel}
            />
            <div className="CBAlarm_table">
                <TableHead
                    filteredList={filteredList}
                    UpdateList={_this.UpdateList}
                />
                {(pageSliceList!==null&&pageSliceList!==undefined?
                    pageSliceList.map(alarm => {
                        return <Table
                            alarm={alarm}
                            acknowledgeUpdate={ _this.acknowledgeUpdate}
                        />
                    })
                    :
                    ""
                )}
            </div>
            <div className="CBAlarm_bottom">
                <Downloadcsv sortedList={sortedList} />
                <Pagebtns sortedList={sortedList} UpdateList={_this.UpdateList} count={updateCount} />
            </div> 
            <PopupNotification onRef={ref => {_this.PopupNotificationRef = ref}} />
        </div>
    }
}
function mapStateToProps(state) {
    return {
        socket: state.socket,
        UserInfo: state.UserInfo,
        Notification: state.Notification,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate, //when this value is updated, render would triggered
    }
}
export default connect(mapStateToProps)(ColdboxAlarm)

function convertDate(DateInput) {
    let RawTimestamp = DateInput
    var Output = RawTimestamp.toISOString()
    return Output
}
function setStatus(alarm) {
    if(alarm===null&&alarm===undefined) return ''
    if(alarm.isClear){
        if(alarm.clearParty === "System") return "Auto-resumed"
        return "Acknowledged"
    }
    return 'Open'
}