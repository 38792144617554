import React from 'react'
import './PopupNotification.css'
//inner noti widget
import NotiItem from './NotiItem'
import { checkIfNotificationForWaterPump } from './util'

class PopupNotification extends React.Component {
    constructor(props) {
        super(props)

        this.addNotificationPopup = this.addNotificationPopup.bind(this)
        this.removeNotifItem = this.removeNotifItem.bind(this)
        this.removeAll = this.removeAll.bind(this)

        this.state = {
            notiList: [

            ],
            showListCount: 0,
            totalListCount: 0,
            closedList: [],             //count which item is close and which is not
        }
    }

    addNotificationPopup(Title,Msg, AlertDatetime, notAutoClose, fullItemInput) {
        const {notiList, showListCount, totalListCount, closedList} = this.state
        const {title, description} = checkIfNotificationForWaterPump(fullItemInput, Title, Msg)
        const NotiItem = {
            title: title,
            id: totalListCount,
            description: description,
            datetime: AlertDatetime,
            locationInfo: (fullItemInput!=null)?fullItemInput.locationInfo:null,
        }

        let UpdateNotiList = notiList
        UpdateNotiList.push(NotiItem)     //unshift == add item to first in array

        let newclosedList = closedList
        newclosedList.push(false)
        
        this.setState({
            notiList: UpdateNotiList,
            closedList: newclosedList,
            showListCount: showListCount+1,
            totalListCount: totalListCount+1,
        })
    }
    removeNotifItem(Index) {
        const {showListCount, closedList} = this.state
        let newclosedList = closedList
        newclosedList[Index] = true

        this.setState({
            showListCount: showListCount-1,
            closedList: newclosedList,
        })
    }
    removeAll() {
        this.setState({
            notiList: [],
            showListCount: 0,
        })
    }

    //set as ref (for triggering function from parent component)
    componentDidMount()
    {
        this.props.onRef(this)
    }
    componentWillUnmount()
    {
        this.props.onRef(undefined)
    }
    render() {
        const {notiList, showListCount, closedList} = this.state

        const ManyItem = (showListCount >= 4)?
        <div className="NotificationPopup_">
            {showListCount-3}+
        </div>
        :
        <div style={{display: 'none'}}></div>

        const ClearAllButton = (showListCount == 0)?
        <div style={{display: 'none'}}></div>
        :
        <div className="NotiPopup_clearAll" onClick={this.removeAll}>Clear All</div>

        return <div className="NotificationPopupList">
            {ClearAllButton}
            {ManyItem}
            {notiList.map((notiItem, index) => {
                return <NotiItem notiItem={notiItem} removeNotifItem={this.removeNotifItem} closedList={closedList} />
            })}
        </div>
    }
}

export default PopupNotification