import React from 'react'
import './Side.css'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartBar, faBell, faHome, faUsers, faDesktop } from '@fortawesome/free-solid-svg-icons'
//component
import LevelContainer from '../Level/LevelContainer'
import ColdboxDrawerItems from './coldboxitems/coldboxitems'
import DashboardTableBtn from './dashboardtable/dashboardtable'
//functions
import {getTxt} from '../common/language/language'
import {GetSiteName} from '../common/language/locDisplay'

class Side extends React.Component {
    constructor(props) {
        super(props)

        this.PageRedirect = this.PageRedirect.bind(this)
        this.Notification = this.Notification.bind(this)

        this.PreSite = this.PreSite.bind(this)
        this.NextSite = this.NextSite.bind(this)

        this.setReduxCurrentLevel = this.setReduxCurrentLevel.bind(this)
    }

    componentDidMount() {
        //set up the site list with this.props.UserInfo
        //check if this.props.currentsite or this.props.level exist
        if(this.props.currentSite != null) return

        //if not, Initial with SiteList[0] and  SiteList[0].LevelList
        let LeveList
        try {
            LeveList = this.props.locations[0].nestedLoc
        }
        catch(err) {
            console.error(err)
        }
        this.props.dispatch({ type: 'PIckSite', data: {
            Siteid: 0,  //default site is the first item in the list
            Level: LeveList
        }})
        // this.setReduxCurrentLevel(thisoutside.props.currentSite, 0) //set the selected level
    }

    setReduxCurrentLevel(currentSite, currentLevel) {
        try {
            this.props.dispatch({ type: 'PickLevel', data: this.props.UserInfo.locations[currentSite].nestedLocs[currentLevel].locName }) //set the current level in redux //currentLevel is the array[0] number, not the real level value
        }
        catch(err) {
            console.error(err)
        }
    }
    PageRedirect(pathUrl) {
        this.props.closefunction()
        this.props.history.push(pathUrl)
    }

    Notification() {       
        const _this = this
        this.props.closefunction()
        //check coldbox (if yes, redirect to coldbox used page)
        const {UserInfo} = this.props
        if(UserInfo == null || UserInfo.permissions == null || UserInfo.permissions.length == null || UserInfo.permissions.length == 0) { //unexpected condition
            console.log('Side.js Notification() Unexpected condition')
            return
        }

        const CdbxPermission = UserInfo.permissions.find(function(item) {
            return item.collectionType == 'Coldbox Manage' || item.collectionType == 'Coldbox Location'
        })
        if(CdbxPermission != null) {
            _this.props.history.push('/Main/Coldbox/Alarm')
            return
        }
        //else other project shares Notification page
        this.props.history.push('/Main/Notification')
    }
    PreSite() {
        //check if this.props.currentsite or this.props.level exist
        const _this = this
        const {currentSite} = this.props
        let IntCurrentSite = 0
        if(typeof currentSite === 'string') IntCurrentSite = parseInt(currentSite, 0)  //convert string to integer
        else if(currentSite == null) console.error('currentSite == null in PreSite() in Side.js')
        else IntCurrentSite = currentSite //set the currentSite to variable (number)

        try {
            if(currentSite==null) {
                console.error('currentSite is missing!')
                return
            }
            if(IntCurrentSite <= 0) {
                console.log('pre click, first page')  //if less or equal to 0, return
                return
            }
            const newCurrentSite = IntCurrentSite - 1
            const levelList = _this.props.UserInfo.locations[newCurrentSite].nestedLocs //get the level list
            
            this.props.dispatch({ type: 'PIckSite', data: {  //set the Site and level list to redux
                Siteid: newCurrentSite,
                Level: levelList
            }})            
            _this.setReduxCurrentLevel(newCurrentSite, 0)    //set the selected level    
        }
        catch(err) {
            console.log(err)
        }
    }
    NextSite() {
        //check if this.props.currentsite or this.props.level exist
        const _this = this
        const {currentSite} = this.props
        let IntCurrentSite = 0
        if(typeof currentSite === 'string') IntCurrentSite = parseInt(currentSite, 0)  //convert string to integer
        else if(currentSite == null) console.error('currentSite == null in PreSite() in Side.js')
        else IntCurrentSite = currentSite //set the currentSite to variable (number)

        try {
            if(currentSite==null) {
                console.error('currentSite is missing!') //if false, popup error and return
                return
            }
            if(IntCurrentSite >= _this.props.UserInfo.locations.length - 1) {                
                console.log('next click, last page') //if less or equal to 0, return
                return
            }
            const newCurrentSite = IntCurrentSite + 1
            const levelList = _this.props.UserInfo.locations[newCurrentSite].nestedLocs //get the level list
            
            this.props.dispatch({ type: 'PIckSite', data: {  //set the Site and level list to redux
                Siteid: newCurrentSite,
                Level: levelList
            }})
            _this.setReduxCurrentLevel(newCurrentSite, 0)
        }
        catch(err) {
            console.log(err)
        }
    }
    componentDidCatch(error, info) {
        console.error('componentDidCatch() on Side.js')
        console.error(error)
        console.error(info)
        
        this.props.history.push('/App') //redirect to App.js to reload all the datas
    }

    render() {
        const {UserInfo, configStorage, history} = this.props
        const userID = (UserInfo != null)?UserInfo.userID:''

        let SiteItem
        try {
            SiteItem = this.props.UserInfo.locations[this.props.currentSite]
        }
        catch(err) {
            console.error(err)
        }
        let BuildingPhotoUrl = ''
        try {
            //BuildingPhotoUrl = this.props.SiteList[this.props.currentSite].img;
            BuildingPhotoUrl = configStorage.imgUrl + '/assets/images/floors/' +  this.props.UserInfo.locations[this.props.currentSite].imgUrl + '/default.jpg'
        }
        catch(err) {
            console.error(err)
        }
        let IsLiftSite = false
        // let ChooseLevelString = 'Choose Level:'
        // let ChooseLevelIcon = ' fa-building'
        try {
            let currentLocName = this.props.UserInfo.locations[this.props.currentSite].locName
            let currentLocDesc = this.props.UserInfo.locations[this.props.currentSite].locDesc
            if(currentLocName.includes('Lift')||currentLocName.includes('lift')) {
                IsLiftSite = true
            }
            if(currentLocDesc.includes('Lift')||currentLocDesc.includes('lift')) {
                IsLiftSite = true
            }
        }
        catch(err) {
            console.log('error checking isLiftSite in render() Side.js')
            console.log(err)
        }
        if(IsLiftSite) {
            // ChooseLevelString = 'Choose Lift:'
            // ChooseLevelIcon = 'fa-level-up'
        }

        if(UserInfo == null) return <div></div>
        const PermList = GetPermList(UserInfo)

        return (
            <div className='LeftHandSide_popup_menu'>
                <div className='NavPopUpTitle'>
                    {getTxt('LSide', 'menu')}
                </div>
                <div className='SideNavRowItem' onClick={()=>this.PageRedirect('/Main')}>
                    <FontAwesomeIcon icon={faHome} className='fa sideIcon' />
                    <div>
                    {getTxt('LSide', 'home')}
                    </div>
                </div>
                {
                    PermList.Dashboard==true?
                    <div className='SideNavRowItem' onClick={()=>this.PageRedirect('/Main/FloorPlan')}>
                        <FontAwesomeIcon icon={faDesktop} className='fa sideIcon' />
                        <div>
                        {getTxt('LSide', 'dashbd')}
                        </div>
                    </div>
                    :
                    <div></div>
                }
                {
                    PermList.DashboardTable==true?
                    <DashboardTableBtn FloorPlanTable={()=>this.PageRedirect('/Main/FloorPlanTable')} />
                    :
                    <div></div>
                }
                {
                    (PermList.Statistics==false)?
                    <div className='SideNavRowItem disabledSlideItem'>
                        <FontAwesomeIcon icon={faChartBar} className='fa sideIcon' />
                        <div>
                        {getTxt('LSide', 'statistic')}
                        </div>
                    </div>
                    :
                    <div className='SideNavRowItem' onClick={()=>this.PageRedirect('/Main/Summary')}>
                        <FontAwesomeIcon icon={faChartBar} className='fa sideIcon' />
                        <div>
                        {getTxt('LSide', 'statistic')}
                        </div>
                    </div>
                }
                {
                    PermList.Notifications==true?
                    <div className='SideNavRowItem' onClick={this.Notification}>
                        <FontAwesomeIcon icon={faBell} className='fa sideIcon' />
                        <div>
                        {getTxt('LSide', 'notification')}
                        </div>                    
                    </div>
                    :
                    <div></div>
                }
                <ColdboxDrawerItems history={history} closefunction={this.props.closefunction} />

                <div className='Navbar_PickSite_Maindiv'>
                    <div className='Navbar_PickSite_BuildingName'>
                        {GetSiteName(SiteItem)}
                    </div> 
                </div>
                <div className='Navbar_PickSite_img'>
                    <div className='fa fa-angle-left Navbar_PickSite_Btn' onClick={this.PreSite}>
                    </div>
                    <div className='fa fa-angle-right Navbar_PickSite_Btn2' onClick={this.NextSite}>
                    </div>
                    <div className='Navbar_Pick_Floor'>
                        <LevelContainer currentLevel={this.props.currentlevel} history={this.props.history}/>
                    </div>
                    <img src={BuildingPhotoUrl} className='Navbar_PickSite_img2'>
                    </img>
                </div>
                {
                    PermList.UserAccount==true?
                    <div className='SideNavRowItem' onClick={()=>this.PageRedirect('/Main/UserManagement')}>
                        <FontAwesomeIcon icon={faUsers} className='fa sideIcon' />
                        <div>
                        {getTxt('LSide', 'userMgt')}
                        </div>                    
                    </div>
                    :
                    <div></div>
                }
                {
                    PermList.AlarmThreshold==true?
                    <div className='SideNavRowItem' onClick={()=>this.PageRedirect('/Main/AlarmTrigger')}>
                        <FontAwesomeIcon icon={faBell} className='fa sideIcon' />
                        <div>
                        {getTxt('LSide', 'alrmTrigger')}
                        </div>
                    </div>
                    :
                    <div></div>
                }
                {
                    PermList.AuditTrailLog==true?
                    <div className='SideNavRowItem' onClick={()=>this.PageRedirect('/Main/Coldbox/AuditTrial')}>
                        <FontAwesomeIcon icon={faUsers} className='fa sideIcon' />
                        <div>
                        {getTxt('LSide', 'auditTrail')}
                        </div>                    
                    </div>
                    :
                    <div></div>
                }
                <div style={{height: '3rem'}}></div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
      SiteList: state.SiteList,
      currentSite: state.currentSite,
      level: state.level,
      UserInfo: state.UserInfo,
      configStorage: state.configStorage,
      DeviceDataLastUpdate: state.DeviceDataLastUpdate
    }
}
export default connect(mapStateToProps)(Side)

const PermissionList = [
    [0, 'Dashboard'],
    [1, 'DashboardTable'],
    [2, 'Statistics'],
    [3, 'Notifications'],
    [4, 'UserAccount'],
    [5, 'AlarmThreshold'],
    [6, 'AuditTrailLog'],
]
const GetPermList = UserInfo => {
    if(UserInfo==null) return []
    const {permissions} = UserInfo
    let returnList = {}
    PermissionList.forEach(PerItem => {
        const IsInList = permissions.find(item => {
            return item.collectionType == PerItem[1]
        })
        returnList[PerItem[1]] = (IsInList!=null)

    })
    const coldboxPerm = permissions.find(item => item.collectionType.includes('Coldbox'))
    if(coldboxPerm!=null) {      //coldbox also allow audit trail
        returnList.AuditTrailLog = true
    }

    return returnList
}