import React from 'react'
import './delete.css'

//Redux Import
import { connect } from 'react-redux'

class Delete extends React.Component {
    constructor(props)
    {
        super()

        this.state = {
        }

        this.Delete = this.Delete.bind(this)
        this.Close = this.Close.bind(this)
    }
    Delete() {
        const {Editingitem, UserInfo} = this.props
        const ColdboxID = Editingitem.payload_definition_id._id

        const requestData = {
            "msgType": "LocationManagement",
            "method": "Delete",
            "userID": UserInfo.userID,
            "name": Editingitem.locName,
            "device_id": ColdboxID,
            Editingitem: Editingitem
        }
        console.log(requestData)

        this.props.dispatch({
            type: 'EmitSocket',
            EmitSocketName: "StatRequest",      //StatRequest can request for any type data
            EmitSocketData: requestData
        })
        this.props.closePopup()
    }
    Close() {
        this.props.closePopup()
    }

    render()
    {
        const {isEdit, isPopup, deleteConfirm, Editingitem} = this.props

        return (deleteConfirm)?
        <div className="ColdboxIndi_popupMain">
            <div className="ColdboxIndi_popupTitle">Delete Coldbox?</div>
            <div className="ColdboxIndi_popupcloseBtn" onClick={this.Close}>
                <div className="fa fa-times"></div>
            </div>
            <input className="ColdboxIndi_popupInput" placeholder="Coldbox ID" value={Editingitem.payload.ColdboxID} readOnly></input>
            <div className='ColdboxIndi_popupBottom'>
                <div className="ColdboxIndi_popupDel" onClick={this.Delete}>Delete</div>
                <div className="ColdboxIndi_popupCancel" onClick={this.Close}>Cancel</div>
            </div>
        </div>
        :
        <div style={{display:'none'}}></div>
    }
}

function mapStateToProps(state) {
    return {
        UserInfo: state.UserInfo
    }
}
  
export default connect(mapStateToProps)(Delete)