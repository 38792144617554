import React from 'react'
import './Linechart.css'
import { connect } from 'react-redux'
import HighCharts, { Axis } from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import DatePicker from 'react-datepicker'

import Loading from '../../loading'
import RealTimeAlert from '../../Navbar/RealTimeAlert'
import SoundPlay from '../../../Sound/Sound'
import { CSVLink, CSVDownload } from "react-csv"

HighCharts.setOptions({
    time: {
        timezoneOffset: -8 * 60         // to hk time zone
    }
})

class ColdboxLineChart extends React.Component {
    constructor(props) {
        super(props)

        this.mapoutsidediv = React.createRef()
        this.Linechart_maindiv = React.createRef()
        this.Linechart_header = React.createRef()

        this.drawLineChart = this.drawLineChart.bind(this)
        this.SearchClicked = this.SearchClicked.bind(this)
        this.socketListener = this.socketListener.bind(this)

        this.SetReadingTimeInterval = this.SetReadingTimeInterval.bind(this)
        this.SetReadingType = this.SetReadingType.bind(this)
        this.setStartTime = this.setStartTime.bind(this)
        this.setEndTime = this.setEndTime.bind(this)
        this.convertDate = this.convertDate.bind(this)
        this.PickColdbox = this.PickColdbox.bind(this)
        this.PickReadingType = this.PickReadingType.bind(this)
        this.formatDate = this.formatDate.bind(this)
        this.genCsvData = this.genCsvData.bind(this)

        this.state = {
            chartValues: [1, 2, 3, 4, 5, 6],
            ChartCsvData: [
                ['Coldbox Name', 'Date', 'Value', 'Unit']
            ],
            isLoading: false,
            finalChartValue: [],
            startdate: new Date(),
            enddate: new Date(),
            readingType: 4,      //CO2, Temp, Humid, Power, Illuminance
            timeInterval: 3,     //Month, Day, 5 Hours, 1 Hour
            currentUnit: 4,      //this is the unit that is updated when search complete (similar to readingType)
            selectedColdboxIdx: null,
            readingTypesList: [
                {
                    displayStr: "Temperature of sensor 1",
                    readingUnit: "C",
                    DataKey: "Temp1",
                    InnerDataKey: "Temp1",
                    readingTypeStr : "Temp1",
                    YMax  : 80,
                    YMin : -20,
                },
                {
                    displayStr: "Temperature of sensor 2",
                    readingUnit: "C",
                    DataKey: "Temp2",
                    InnerDataKey: "Temp2",
                    readingTypeStr : "Temp2",
                    YMax  : 80,
                    YMin : -20,
                },
                {
                    displayStr: "Temperature of sensor 3",
                    readingUnit: "C",
                    DataKey: "Temp3",
                    InnerDataKey: "Temp3",
                    readingTypeStr : "Temp3",
                    YMax  : 80,
                    YMin : -20,
                },
                {
                    displayStr: "Temperature of sensor 4",
                    readingUnit: "C",
                    DataKey: "Temp4",
                    InnerDataKey: "Temp4",
                    readingTypeStr : "Temp4",
                    YMax  : 80,
                    YMin : -20,
                },
                {
                    displayStr: "Temperature of sensor 5",
                    readingUnit: "C",
                    DataKey: "Temp5",
                    InnerDataKey: "Temp5",
                    readingTypeStr : "Temp5",
                    YMax  : 80,
                    YMin : -20,
                },
                {
                    displayStr: "Return Air Temperature",
                    readingUnit: "C",
                    DataKey: "ReturnAirTemp",
                    InnerDataKey: "ReturnAirTemp",
                    readingTypeStr : "ReturnAirTemp",
                    YMax  : 80,
                    YMin : -20,
                },
                {
                    displayStr: "Weighted Mean Temperature",
                    readingUnit: "C",
                    DataKey: "WeightedMeanTemp",
                    InnerDataKey: "WeightedMeanTemp",
                    readingTypeStr : "WeightedMeanTemp",
                    YMax  : 80,
                    YMin : -20,
                },
                {
                    displayStr: "Relative Humidity of coldbox",
                    readingUnit: "%",
                    DataKey: "RH",
                    InnerDataKey: "RH",
                    readingTypeStr : "RH",
                    YMax  : 100,
                    YMin : 0,
                },
                {
                    displayStr: "Refrigerant pressure - Condensing",
                    readingUnit: "Bar",
                    DataKey: "PressureCondensing",
                    InnerDataKey: "PressureCondensing",
                    readingTypeStr : "PressureCondensing",
                    YMax  : 100,
                    YMin : 0,
                },
                {
                    displayStr: "Refrigerant pressure - Evaporating",
                    readingUnit: "Bar",
                    DataKey: "PressureEvaporating",
                    InnerDataKey: "PressureEvaporating",
                    readingTypeStr : "PressureEvaporating",
                    YMax  : 100,
                    YMin : 0,
                },
                {
                    displayStr: "Suction Temperature",
                    readingUnit: "C",
                    DataKey: "TempSuction",
                    InnerDataKey: "TempSuction",
                    readingTypeStr : "TempSuction",
                    YMax  : 80,
                    YMin : -20,
                },
                {
                    displayStr: "Expansion valve opening",
                    readingUnit: "%",
                    DataKey: "ExpansionValveOpening",
                    InnerDataKey: "ExpansionValveOpening",
                    readingTypeStr : "ExpansionValveOpening",
                    YMax  : 100,
                    YMin : 0,
                },
                {
                    displayStr: "UPS-Battery Voltage",
                    readingUnit: "V",
                    DataKey: "UPSBatteryVoltage",
                    InnerDataKey: "UPSBatteryVoltage",
                    readingTypeStr : "UPSBatteryVoltage",
                    YMax  : 50,
                    YMin : 0,
                },
                {
                    displayStr: "UPS-Battery Capacity",
                    readingUnit: "%",
                    DataKey: "UPSBatteryCapacity",
                    InnerDataKey: "UPSBatteryCapacity",
                    readingTypeStr : "UPSBatteryCapacity",
                    YMax  : 100,
                    YMin : 0,
                },
                {
                    displayStr: "UPS-Load",
                    readingUnit: "kW",
                    DataKey: "UPSLoad",
                    InnerDataKey: "UPSLoad",
                    readingTypeStr : "UPSLoad",
                    YMax  : 50,
                    YMin : -2,
                },
                {
                    displayStr: "UPS-Output Voltage",
                    readingUnit: "V",
                    DataKey: "UPSOutputVoltage",
                    InnerDataKey: "UPSOutputVoltage",
                    readingTypeStr : "UPSOutputVoltage",
                    YMax  : 50,
                    YMin : 0,
                },
                {
                    displayStr: "UPS-Output Frequency",
                    readingUnit: "Hz",
                    DataKey: "UPSoutputfrequency",
                    InnerDataKey: "UPSoutputfrequency",
                    readingTypeStr : "UPSoutputfrequency",
                    YMax  : 100,
                    YMin : 0,
                },
                {
                    displayStr: "UPS-Output Current",
                    readingUnit: "A",
                    DataKey: "UPSoutputcurrent",
                    InnerDataKey: "UPSoutputcurrent",
                    readingTypeStr : "UPSoutputcurrent",
                    YMax  : 100,
                    YMin : 0,
                },
                {
                    displayStr: "UPS-Charging Time",
                    readingUnit: "h",
                    DataKey: "UPSchargingtime",
                    InnerDataKey: "UPSchargingtime",
                    readingTypeStr : "UPSchargingtime",
                    YMax  : 100,
                    YMin : 0,
                },
                {
                    displayStr: "COP",
                    readingUnit: "",
                    DataKey: "COP",
                    InnerDataKey: "COP",
                    readingTypeStr : "COP",
                    YMax  : 100,
                    YMin : -2,
                }],
            selectedReadingTypeIdx: 6,
            coldboxList: [],
            statData: []

        }

        this.soundRef = React.createRef()
    }
 
    formatDate(datestr) {
        const date = new Date(datestr)
        //console.log(date)
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        const result = (date.getMonth()+1) + "/" + date.getDate() + "/" + date.getFullYear() + "  " + strTime
        //console.log(result)
        return result
    }

    PickColdbox(event) {
        this.setState({
            selectedColdboxIdx: event.target.value
        })
    }

    PickReadingType(event) {
        this.setState({
            selectedReadingTypeIdx: event.target.value
        })

        if(event.target.value == 6) {
            this.setState({
                timeInterval: 3
            })
        }
    }

    socketListener() {
        const { socket } = this.props
        const { selectedReadingTypeIdx } = this.state
        const _this = this

        if (socket == null) {
            console.error('socket is null. LineChart.js socketListener()')
            return
        }
        if (socket.connected == false) {
            console.error('socket is disconnected LineChart.js socketListener()')
            return
        }
        socket.on("LocationStatistics", data => {
            console.log("statData")
            console.log(data)
            _this.setState({
                currentUnit: selectedReadingTypeIdx,
                statData: data.data
            })
            this.drawLineChart(_this.props, data.data)
        })
    }

    componentDidMount() {
        let startDate = new Date()
        let EndDate = new Date()
        startDate.setHours(startDate.getHours() - 48)
        let startDateStr = this.convertDate(startDate)
        let EndDateStr = this.convertDate(EndDate)

        const { UserInfo, DeviceData } = this.props
        let coldboxes = []
        if (DeviceData !== null && DeviceData !== undefined) {
            if (DeviceData[0] !== null && DeviceData[0] !== undefined) {
                //CT
                if (DeviceData[0].nestedLocs !== null && DeviceData[0].nestedLocs !== undefined) {
                    DeviceData[0].nestedLocs.map(function (data) {
                        //coldbox id
                        if (data !== null && data !== undefined) {
                            if (data.nestedLocs !== null && data.nestedLocs !== undefined) {
                                data.nestedLocs.map(function (cBox) {
                                    if (cBox !== null && cBox !== undefined) {
                                        if (cBox._id !== undefined && cBox._id !== null) {
                                            coldboxes.push(cBox)
                                        }
                                    }
                                })
                            }
                        }
                    })
                }
            }
        }

        let ClientID = ''
        let locID = ''
        const {selectedReadingTypeIdx, readingTypesList} = this.state
        let readingTypeStr = readingTypesList[selectedReadingTypeIdx].readingTypeStr
        if (UserInfo != null) {
            ClientID = UserInfo.client_id
        }
        if (coldboxes !== undefined && coldboxes !== null) {
            if (coldboxes[0] !== undefined && coldboxes[0] !== null) {
                locID = coldboxes[0]._id
            }
        }

        const requestData = {
            "msgType": "LocationStatistics",
            "msgID": "LineChart",
            "clientID": ClientID,
            "payload_field": [readingTypeStr],
            "timeConstraint": {
                "start": startDateStr,
                "end": EndDateStr,
            },
            "timeFrequency": {
                "unit": "minute",
                "value": 1
            },
            "locationConstraint": {
                "locLvl": 3,
                "id": locID
            },
            "locationGrouping": 3,
            "calculationMethod": "average"
        }
        console.log(requestData)
        this.props.dispatch({
            type: 'EmitSocket',
            EmitSocketName: "StatRequest",
            EmitSocketData: requestData
        })
        this.drawLineChart(this.props)

        this.setState({
            startdate: startDate,
            coldboxList: coldboxes
        })
    }

    convertDate(DateInput) {
        let DateMonth = DateInput.getMonth() + 1
        let DateDay = DateInput.getDate()
        if (DateMonth < 10) DateMonth = "0" + DateMonth
        if (DateDay < 10) DateDay = "0" + DateDay
        let HourStr = DateInput.getHours()
        if (HourStr < 10) HourStr = "0" + HourStr
        let MinutesStr = DateInput.getUTCMinutes()
        if (MinutesStr < 10) MinutesStr = "0" + MinutesStr
        const DateStr = DateInput.getFullYear() + "-" + DateMonth + "-" + DateDay + "T" + HourStr + ":" + MinutesStr + ":00Z"
        return DateStr
    }
    SetReadingTimeInterval(event) {
        let readingTimeIntervalInt = 0
        try {
            readingTimeIntervalInt = parseInt(event.target.value, 0)
        }
        catch (err) {
            console.log(err);
        }
        this.setState({
            timeInterval: readingTimeIntervalInt
        })
    }
    SetReadingType(event) {
        let readingTypeInt = 0
        try {
            readingTypeInt = parseInt(event.target.value, 0)
        }
        catch (err) {
            console.log(err)
        }
        this.setState({
            readingType: readingTypeInt
        })
    }
    SearchClicked() {
        this.soundRef.current.alarmMp3Run("hover")

        //get the start end date, reading type
        const { startdate, enddate, readingType, selectedColdboxIdx, readingTypesList, selectedReadingTypeIdx,
            timeInterval } = this.state
        const { currentlevelID, UserInfo } = this.props
        let readingTypeStr =readingTypesList[selectedReadingTypeIdx].readingTypeStr
        //send request to socket  
        let timeFrequency = ""
        let timeValue = 1
        switch (timeInterval) {
            case 0:
                //Default
                timeFrequency = "day"
                timeValue = 30
                break
            case 1:
                timeFrequency = "day"
                timeValue = 1
                break
            case 2:
                timeFrequency = "hour"
                timeValue = 1
                break
            case 3:
                timeFrequency = "minute"
                timeValue = 1
                break
            default:
                console.log('LineChart time Interval item is not identified')
                timeFrequency = "day"
                timeValue = 1
                break
        }
        // format the date format to string
        const startDateStr = this.convertDate(startdate)
        const endDateStr = this.convertDate(enddate)
        let selectedCodbox = this.state.coldboxList[selectedColdboxIdx]
        let selectedReadingTypeStr = readingTypesList[selectedReadingTypeIdx]

        let locID
        if (selectedCodbox == undefined || selectedCodbox == null) {
            locID = this.state.coldboxList[0]._id
        } else {
            locID = selectedCodbox._id
        }
        const requestData = {
            "msgType": "LocationStatistics",
            "msgID": "LineChart",
            "clientID": UserInfo.client_id.toString(),
            "payload_field": [readingTypeStr],
            "timeConstraint": {
                "start": startDateStr,
                "end": endDateStr,
            },
            "timeFrequency": {
                "unit": timeFrequency,
                "value": timeValue
            },
            "locationConstraint": {
                "locLvl": 3,
                "id": locID.toString()
            },
            "locationGrouping": 3,
            "calculationMethod": "average"
        }

        console.log(requestData)
        this.props.dispatch({
            type: 'EmitSocket',
            EmitSocketName: "StatRequest",
            EmitSocketData: requestData
        })

        this.drawLineChart(this.props)

        this.setState({     //Show loading GUI
            isLoading: true
        })
    }

    componentDidUpdate(preProps) {
        console.log('componentDidUpdate() LineChart.js')

        const _this = this
        try {
            const { StatisticData } = preProps
            const newStatisticData = this.props.StatisticData
            let isUpdatedData = false
            if (StatisticData.coldboxStat != newStatisticData.coldboxStat) isUpdatedData = true
            //if Yes, this.state.isLoading = false
            if (isUpdatedData) {
                _this.setState({
                    isLoading: false
                })
                this.drawLineChart(_this.props)
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    drawLineChart(inputProps) {
        if (this.Linechart_maindiv.current == null || this.Linechart_header.current == null || this.mapoutsidediv.current == null) {
            console.log('some ref() are null, return')
            return
        }

        if (inputProps == null) return
        try {
            const { StatisticData, currentSite } = inputProps
            const { readingType, currentUnit, selectedReadingTypeIdx, readingTypesList, statData } = this.state

            let data = []

            let readingUnit = "undefined unit"
            let DataKey = "undefined Data Key"
            let InnerDataKey = "undefined Inner Data Key"
            if (StatisticData == null) {
                console.log('StatisticData == null')
                return
            }
            if(readingTypesList[selectedReadingTypeIdx] !== null && readingTypesList[selectedReadingTypeIdx] !== undefined ){
                readingUnit = readingTypesList[selectedReadingTypeIdx].readingUnit
                DataKey = readingTypesList[selectedReadingTypeIdx].DataKey
                InnerDataKey = readingTypesList[selectedReadingTypeIdx].InnerDataKey
            }

            console.log("StatisticData.coldboxStat")
            console.log(StatisticData.coldboxStat)
            console.log(DataKey)
            console.log(StatisticData.coldboxStat[DataKey])
            console.log(InnerDataKey)
            StatisticData.coldboxStat[DataKey].forEach(Item => {
                data.push([
                    Date.parse(Item.time),
                    Item[InnerDataKey],
                    Item.locationName
                ])
            })

            //data
            data.sort(function (a, b) {
                return a[0] - b[0]
            })
            let DeviceNameArray = []
            let SeriesArray = []
            //1. get all devicename in array
            const unique = (value, index, self) => {
                return self.indexOf(value) === index
            }
            console.log("data")
            console.log(data)
            data.forEach(dataItem => {
                DeviceNameArray.push(dataItem[2])
            })
            let uniqueDeviceNameList = DeviceNameArray.filter(unique)
            //2. split devices to separate arrays
            console.log("uniqueDeviceNameList")
            console.log(uniqueDeviceNameList)
            uniqueDeviceNameList.forEach(DeviceString => {
                let pushArrayItem = data.filter(function (dataItem) {
                    return dataItem[2] === DeviceString
                })
                SeriesArray.push({
                    data: pushArrayItem,
                    name: DeviceString,
                    type: 'spline',
                    tooltip: {
                        valueDecimals: 2,
                        valuePrefix: '',
                        valueSuffix: ' ' + readingUnit
                    }
                })
            })
            //3. format the series json to insert
            const SeriesArrayOutput = SeriesArray
            this.setState({
                finalChartValue: SeriesArrayOutput,
                chartValues:SeriesArrayOutput
            })

            this.genCsvData()
        }
        catch (err) {
            console.log('onload Linechart failed LineChart.js')
            console.log(err)
        }
    }

    genCsvData() {
        //When data is updated, update the state and generate csv data
        //** need complete drawLineChart() function and generate chartValues state first
        const {chartValues} = this.state
        console.log("genCsvData")
        console.log(chartValues)
        const _this = this
        //block exceptional cases
        if(chartValues == null || chartValues.length == 0) return
        if(chartValues[0].data == null) return
        if(chartValues[0].tooltip == null) return
        //generate csv data
        let csvResult = [
            ['Coldbox Name', 'Date', 'Value', 'Unit']
        ]
        chartValues.forEach(DataItem => {
            //console.log(DataItem)
            const deviceData = DataItem.data
            const deviceName = DataItem.name
            const deviceUnit = DataItem.tooltip.valueSuffix
            deviceData.forEach(readingItem => {
                const DateRaw = readingItem[0]
                const DateTimeString = _this.formatDate(DateRaw)
                const readingValue = readingItem[1]
                csvResult.push(
                    [ deviceName, DateTimeString, readingValue, deviceUnit, ]
                )
            })
        })
        console.log(csvResult)

        // Update it to local state
        this.setState({
            ChartCsvData: csvResult
        })
    }
    setStartTime(data) {
        this.setState({
            startdate: data
        })
    }
    setEndTime(data) {
        this.setState({
            enddate: data
        })
    }

    render() {
        const { readingType, timeInterval, isLoading, currentUnit, startdate,ChartCsvData,
            enddate, finalChartValue, selectedColdboxIdx, readingTypesList, selectedReadingTypeIdx, coldboxList } = this.state
        const { UserInfo } = this.props
        if (UserInfo == null) return <div>UserInfo is missing</div>
        const client_id = UserInfo.client_id

        let readingTypeStr, readingUnit = ""
        let YMax, YMin = 0      //set max and min range for Y Axis in chart
        try {
            if(readingTypesList[selectedReadingTypeIdx] !== null && readingTypesList[selectedReadingTypeIdx] !== undefined ){
                readingTypeStr = readingTypesList[selectedReadingTypeIdx].readingTypeStr
                readingUnit = readingTypesList[selectedReadingTypeIdx].readingUnit
                YMax = readingTypesList[selectedReadingTypeIdx].YMax
                YMin = readingTypesList[selectedReadingTypeIdx].YMin
            }
        }
        catch (err) {
            console.log(err)
        }

        const options = {
            credits: true,
            chart: {
                backgroundColor: {
                    linearGradient: [0, 0, 500, 500],
                    stops: [
                        [0, 'rgba(200, 200, 200, 0)'],
                        [1, 'rgba(0, 0, 0, 0)']
                    ]
                },
            },
            title: {
                text: ''
            },
            rangeSelector: {
                //selected: 0
                enabled: false
            },
            tooltip: {
                valueDecimals: 2,
                split: true
            },
            plotOptions: {
                series: {
                    showInNavigator: true       //show all line inside the bottom filter controller
                }
            },
            yAxis: {
                labels: {
                    enabled: true,
                    format: '{value} ' + readingUnit
                },
                opposite: false,
                max: YMax,
                min: YMin,
            },
            xAxis: {
                labels: {
                    style: {
                        color: 'var(--optiontext)'
                    }
                }
            },
            series: this.state.finalChartValue,

            //finalChartValue,
        }

        if (this.props.StatisticData.loading == true) {
            return <div className="ColdboxLineChart_outside">
                <div className="Coldboxlinechart_header" ref={this.Linechart_header}>
                    <i className="fa fa-book headericon"></i>
                    Data Trend
                </div>
                <Loading />
            </div>
        }

        return [
            <div className="ColdboxLineChart_outside" ref={this.Linechart_maindiv}>
                <div className="Coldboxlinechart_header" ref={this.Linechart_header}>
                    <i className="fa fa-book headericon"></i>
                Data Trend {"(" + readingTypeStr + ")"}
                </div>
                <div className="Coldbox_Linechart_div" ref={this.mapoutsidediv}>
                    <div className="Coldbox_searchbar_div">
                        <div className="Coldbox_searchbar_title">
                            Start:
                    </div>
                        <DatePicker
                            selected={startdate}
                            onChange={this.setStartTime}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="d-MMMM yyyy HH:mm"
                            timeCaption="time"
                        />
                        <div className="Coldbox_searchbar_title">
                            End:
                    </div>
                        <DatePicker
                            selected={enddate}
                            onChange={this.setEndTime}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="d-MMMM yyyy HH:mm"
                            timeCaption="time"
                        />
                        <select name="ColdboxLineChartOption" className="ColdboxLineChartOption" onChange={this.PickColdbox}
                            value={selectedColdboxIdx}  >
                            {
                                coldboxList.map((item, index) => {
                                    return <option value={index} >
                                        {item.locName}
                                    </option>
                                })
                            }

                        </select>
                        <select className="ColdboxLineChartOption" onChange={this.PickReadingType} value={selectedReadingTypeIdx}>
                            {
                                readingTypesList.map((item, index) => {
                                    return <option value={index} >
                                        {item.displayStr}
                                    </option>
                                })
                            }
                        </select>
                        <select className="ColdboxLineChartOption" onChange={this.SetReadingTimeInterval} value={timeInterval}>
                            <option value="0">
                                Time Interval
                            </option>
                            <option value="0">
                                Month
                            </option>
                            <option value="1">
                                Day
                            </option>
                            <option value="2">
                                Hour
                            </option>
                            <option value="3">
                                Minute
                            </option>
                        </select>
                        <button className="fa fa-search ColdboxLinechart_searchbutton" onClick={this.SearchClicked}>
                        </button>
                        <a className="Coldbox_linechart_Downloadcsv" onClick={this.genCsvData}>
                           
                        <CSVLink data={ChartCsvData} filename={this.formatDate(startdate.toString()) + "-" + this.formatDate(enddate.toString()) + "StatisticData.csv"}>Download csv</CSVLink>
                    </a>
                        <SoundPlay ref={this.soundRef} />
                    </div>
                    <div className="ColdboxLinechart_maindiv">
                        <HighchartsReact highcharts={HighCharts} options={options} constructorType={'stockChart'} className="ColdboxLinechart_maindiv"
                            containerProps={{ style: { height: '100%' } }} />
                    </div>
                </div>
            </div>,
            <RealTimeAlert onRef={ref => (this.RealTimeAlertRef = ref)} />,
            isLoading ? <div className="ColdboxlineChartLoading">
                <div className="fa fa-spinner fa-spin ColdboxlineChartLoadingIcon"></div>
            </div>
                : <div></div>
        ]
    }
}

function mapStateToProps(state) {
    return {
        socket: state.socket,
        currentlevel: state.currentlevel,
        currentSite: state.currentSite,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate, //when this value is updated, render would triggered
        StatisticData: state.StatisticData,
        UserInfo: state.UserInfo,
        currentlevelID: state.currentlevelID,
        DeviceData: state.DeviceData
    }
}
export default connect(mapStateToProps)(ColdboxLineChart)