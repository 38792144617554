import React from 'react';
import './alarmTriggerTable.css';
//Redux Import
import { connect } from 'react-redux';
import AlarmTriggerHeader from './Header/alarmTriggerHeader.js';
import RealTimeAlert from '../../Navbar/RealTimeAlert';

class AlarmTriggerTable extends React.Component {
    constructor(props)
    {
        super(props);

        this.state = {
            List: [
                {
                    Type: "Temp",
                    Site: "Paul Y",
                    Level: "Floor 18",
                    LocName: "Area 1",
                    AlarmTiggerType: "Up",
                    AlarmTriggerLimit: 28,
                    AlarmName: "Demo Alarm Record",
                    Priority: "",
                    Enabled: 1
                }
            ],
            currentPage: 1,
            //pageCount: [1, 2, 3],
            isShowSeeMorePopup: false,
            seeMoreList: [],

            isShowConfirmDeletePopup: false,
            willDeleteTriggerItem: null,

            //search filtering
            searchText: "",
            searchType: "",
            searchPriority: "",
            searchSite: "",
        };

        this.moreDevices = this.moreDevices.bind(this);
        // this.moreLocation = this.moreLocation.bind(this);
        this.closeMoreWindow = this.closeMoreWindow.bind(this);

        this.changePage = this.changePage.bind(this);

        //delete row (will popup confirm box)
        this.deleteRow = this.deleteRow.bind(this);
        this.closeDeleteConfirmBox = this.closeDeleteConfirmBox.bind(this);
        this.confirmedDelete = this.confirmedDelete.bind(this);

        this.refreshList = this.refreshList.bind(this);

        this.SearchHeader = this.SearchHeader.bind(this);           //trigger from alarmTriggerHeader.js, when search variables are updated
    }
    SearchHeader(stateName, stateValue) {
        this.setState({
            [stateName]: stateValue
        })
    }
    refreshList() {
        //request list
        const {UserInfo} = this.props
        if(UserInfo == null) return
        const userID = UserInfo.userID
        const requestData = {
            msgType: 'AlarmSettings',
            method: "GET",
            userID: userID
        }
        console.log(requestData)
        //emit
        this.props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "AlarmRequest",
            EmitSocketData: requestData
        })
    }
    componentDidMount()
    {
        //request list
        const {UserInfo} = this.props
        if(UserInfo == null) return
        const userID = UserInfo.userID
        const requestData = {
            msgType: 'AlarmSettings',
            method: "GET",
            userID: userID
        }
        console.log(requestData)
        //emit
        this.props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "AlarmRequest",
            EmitSocketData: requestData
        })

        //create a listener to trigger list update when received: create/update/remove success
        const {socket} = this.props
        const thisoutside = this
        socket.on('AlarmSettings', data => {
            if(data.method == "Update" || data.method == "Create")
            {
                thisoutside.props.dispatch({ type: 'EmitSocket', 
                EmitSocketName: "AlarmRequest",
                EmitSocketData: requestData
            })
            }
        })
    }
    componentWillReceiveProps(nextProps)
    {
        return
    }
    changePage(PageNumber)
    {
        //update the current page
        this.setState({
            currentPage: PageNumber
        })
    }
    deleteRow(pickedRow)
    {
        console.log(pickedRow)
        //save the willDeleteTrigger in this.state.WilDeleteTrigger
        //also, popup the confirm box
        this.setState({
            isShowConfirmDeletePopup: true,
            willDeleteTriggerItem: pickedRow
        })
    }
    closeDeleteConfirmBox()
    {
        this.setState({
            isShowConfirmDeletePopup: false
        })
    }
    confirmedDelete()
    {
        //get the state stored will delete item
        const {willDeleteTriggerItem} = this.state
        const thisoutside = this
        if(willDeleteTriggerItem == null)
        {
            alert('Delete Trigger is not found, unexpected condition')
            thisoutside.setState({
                isShowConfirmDeletePopup: false,
                willDeleteTriggerItem: null
            })
            return
        }
        const alarmComponentIDList = [willDeleteTriggerItem.id]

        //get UserID
        const {UserInfo} = this.props
        const UserID = UserInfo.userID
        //prepare the emit json format
        const emitJson = {
            "msgType": "AlarmSettings",
            "method": "Delete",
            //"socketId": "8SpxJb0PqsT0zcboAAEa",   //add inside server side
            "userID": UserID,
            "ids": alarmComponentIDList
        }
        console.log(emitJson)
        //emit with redux function
        this.props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "AlarmRequest",
            EmitSocketData: emitJson
        })
        //close popup
        this.closeDeleteConfirmBox()
    }
    moreDevices(clickedItem)
    {
        const thisoutside = this
        try
        {
            const alarmList = clickedItem.locationInfo
            thisoutside.setState({
                seeMoreList: alarmList,         //update the popup list data
                isShowSeeMorePopup: true        //show popup
            })
        }
        catch(err)
        {
            console.log(err)
        }
    }
    closeMoreWindow()
    {
        //close all 'more' windows
        this.setState({
            seeMoreList: [],                //clear the popup list data
            isShowSeeMorePopup: false        //hide popup
        });
    }
    render()
    {
        const thisoutside = this
        const {currentPage, isShowSeeMorePopup, seeMoreList, isShowConfirmDeletePopup} = this.state
        const {triggerAlarmList} = this.props               //get real redux data //..
        // const triggerAlarmList = {
        //     "msgType": "AlarmSettings",
        //     "socketId": "8SpxJb0PqsT0zcboAAEa",
        //     "method": "GET",
        //     "data": [
        //       {
        //         "id": "5ef9b2758174b74ea04c6083",
        //         "severity" : "Urgent",
        //         "isEnabled" : true,
        //         "client_id": "5d493f254f91b53a70132473",
        //         "locationInfo": [{
        //                 "id" : "5dc2602a13bdd85f88efa1ff",
        //                 "lvl" : 1,
        //                 "name" : "Island Place Tower"
        //             }],
        //         "alarmComponentType": "System",
        //         "msgType" : "Status",
        //           "name" : "LoRa Server offline",
        //           "message" : "LoRa Server offline.",
        //         "rule": "Disconnect",
        //           "emailReceive" : [
        //               "System Admin"
        //           ],
        //         "smsReceive" : [
        //           "System Admin"
        //         ]
        //       },
        //       {
        //         "id" : "5ddce7e21d5fa5784c8c775b",
        //           "severity" : "High",
        //           "isEnabled" : true,
        //           "client_id" : "5d493f254f91b53a70132473",
        //           "locationInfo" : [{
        //               "id" : "5d7a13e7ba75d477f0a9b56c",
        //               "lvl" : 1,
        //           "name": "Island Place Tower"
        //            }, {
        //                 "_id" : "5d7a13e7ba75d477f0a9b56c",
        //                 "lvl" : 1,
        //                 "name" : "Paul Y. Center"
        //             }],
        //           "alarmComponentType" : "Equipment",
        //           "msgType" : "Status",
        //           "name" : "Equipment Offline",
        //         "message" : "LoRa Gateway is offline.",
        //         "rule": "Disconnect",
        //           "emailReceive" : [
        //               "System Admin",
        //               "Client Admin",
        //               "Site Admin"
        //           ],
        //           "smsReceive" : [
        //               "System Admin",
        //               "Client Admin",
        //               "Site Admin"
        //           ]
        //       },
        //       {
        //           "id" : "5ef9aab98174b74ea04c607d",
        //           "severity" : "Normal",
        //           "isEnabled" : true,
        //           "client_id" : "5d493f254f91b53a70132473",
        //           "locationInfo": [
        //               {
        //                   "id" : "5ecf8f0e2f196f392848de96",
        //                   "lvl" : 1,
        //                   "name" : "VCity"
        //               }
        //           ],
        //           "alarmComponentType": "Device",
        //           "msgType" : "Status",
        //           "name" : "Device Offline",
        //           "message" : "Device is offline.",
        //           "rule" : "Disconnected",
        //           "emailReceive" : [
        //               "System Admin",
        //               "Client Admin",
        //               "Site Admin",
        //               "Operators"
        //           ],
        //           "smsReceive" : [
        //               "System Admin",
        //               "Client Admin",
        //               "Site Admin",
        //               "Operators"
        //           ]
        //       },
        //       {
        //           "id" : "5ef9abc98174b74ea04c607f",
        //           "severity" : "Normal",
        //           "isEnabled" : true,
        //           "client_id" : "5ecf8ab62f196f392848de8c",
        //           "locationInfo" : [
        //               {
        //                   "id" : "5ee2f8d138fb2727dc9af58f",
        //                   "lvl" : 3,
        //                   "name" : "TH-01"
        //               },
        //               {
        //                   "id" : "5ee2f8d138fb2727dc9af590",
        //                   "lvl" : 3,
        //                   "name" : "TH-02"
        //               },
        //               {
        //                   "id" : "5ee2f8d138fb2727dc9af591",
        //                   "lvl" : 3,
        //                   "name" : "TH-03"
        //               },
        //               {
        //                   "id" : "5ee2f8d138fb2727dc9af592",
        //                   "lvl" : 3,
        //                   "name" : "TH-04"
        //               },
        //               {
        //                   "id" : "5ee2f8d138fb2727dc9af593",
        //                   "lvl" : 3,
        //                   "name" : "TH-05"
        //               }
        //           ],
        //           "alarmComponentType": "Device",
        //           "msgType" : "Status",
        //           "name" : "Device Low Battery",
        //           "message" : "Device battery level is lower than 3.2",
        //           "rule" : "batteryLevel less than or equal to 3.2",
        //           "emailReceive" : [
        //               "System Admin",
        //               "Client Admin",
        //               "Site Admin",
        //               "Operators"
        //           ],
        //           "smsReceive" : [
        //               "System Admin",
        //               "Client Admin",
        //               "Site Admin",
        //               "Operators"
        //           ]
        //       },
        //       {
        //           "id" : "5ef9b2758174b74ea04c6083",
        //           "severity" : "Low",
        //           "isEnabled" : true,
        //           "client_id" : "5d493f254f91b53a70132473",
        //           "locationInfo" : [
        //               {
        //                   "_id" : "5dc2602a13bdd85f88efa1ff",
        //                   "lvl" : 1,
        //                   "name" : "Island Place Tower"
        //               },
        //               {
        //                   "_id" : "5d7a13e7ba75d477f0a9b56c",
        //                   "lvl" : 1,
        //                   "name" : "Paul Y. Center"
        //               }
        //           ],
        //           "alarmComponentType": "Device",
        //           "msgType" : "Data",
        //           "name" : "Test Alarm",
        //           "message" : "High temperature Alarm.",
        //           "rule" : "temperature larger than or equal to 24",
        //           "emailReceive" : [
        //               "System Admin",
        //               "Client Admin",
        //               "Site Admin",
        //               "Operators"
        //           ],
        //           "smsReceive" : [
        //               "System Admin",
        //               "Client Admin",
        //               "Site Admin",
        //               "Operators"
        //           ]
        //       }
        //     ]
        // }

        // let List = []
        // if(triggerAlarmList != null && triggerAlarmList.data != null) List = triggerAlarmList.data

        let countingDevicesPerRow = 0
        let countingDevicesPerRow2 = 0

        let displayingList = []
        if(triggerAlarmList != null) displayingList = triggerAlarmList.data

        //seemore list
        let ShowSeeMorePopup = ""
        if(!isShowSeeMorePopup) ShowSeeMorePopup = "HideMorePopup"
        //delete confirm popup
        let isShowConfirmDelete = "HideConfirmPopup"
        if(isShowConfirmDeletePopup) isShowConfirmDelete = ""

        if(displayingList == null || displayingList.length == null || displayingList.length == 0) {
            return <div className="AT_container">
            <div className="AT_title">
                Alarm Trigger Setting
            </div>
            <AlarmTriggerHeader refreshList={this.refreshList} SearchHeader={this.SearchHeader}/>
            <table className="AT_tableContainer">
                <tr className="header">
                    <th className="AT_table_name">
                        Alarm Name
                    </th>
                    <th className="AT_table_Type">
                        Alarm Component
                    </th>
                    <th className="AT_table_priority">
                        Priority
                    </th>
                    <th className="AT_table_location">
                        Location
                    </th>
                    <th className="AT_table_triggers">
                        Triggers
                    </th>
                    <th className="AT_table_message">
                        Message
                    </th>
                    <th className="AT_table_Enabled">
                        Enabled
                    </th>  
                    <th>
                        <div className="fa fa-plus AT_addPopupBtn" onClick={this.props.AddUser}></div>
                    </th>
                </tr>
            </table>
            <div className="loadingUIMainDiv alarmtriggertableLoading">  
            </div>
            </div>
        }


        //filtering items
        const {searchText, searchType, searchPriority, searchSite, searchLevel, searchLocation} = this.state

        //txt
        if(searchText.includes('system') || searchText.includes('System') || searchText.includes('device') || searchText.includes('Device') || searchText.includes('equipment') || searchText.includes('Equipment')) {
            //search txt to do type filtering
            let TxtSearch = ""
            switch(searchText) {
                case "system":
                    TxtSearch = "System"
                    break
                case "device":
                    TxtSearch = "Device"
                    break
                case "equipment":
                    TxtSearch = "Equipment"
                    break
            }
            displayingList = displayingList.filter(function(triggerItem) {
                return (triggerItem.alarmComponent.type == searchText || triggerItem.alarmComponent.type == TxtSearch)
            })
        } else if(searchText.includes('urgent') || searchText.includes('Urgent') || searchText.includes('high') || searchText.includes('High') || searchText.includes('normal') || searchText.includes('Normal') || searchText.includes('low') || searchText.includes('Low')) {
            //search txt to do priority filtering ("severity")
            let TxtSearch = ""
            switch(searchText) {
                case "urgent":
                    TxtSearch = "Urgent"
                    break
                case "high":
                    TxtSearch = "High"
                    break
                case "normal":
                    TxtSearch = "Normal"
                    break
                case "low":
                    TxtSearch = "Low"
                    break
            }
            displayingList = displayingList.filter(function(triggerItem) {
                return (triggerItem.severity == searchText || triggerItem.severity == TxtSearch)
            })
        } else if(searchText.includes('disable') || searchText.includes('Disable')) {
            //search txt to do enable filtering
            displayingList = displayingList.filter(function(triggerItem) {
                return (triggerItem.isEnabled == false)
            })
        } else if(searchText.includes('enable') || searchText.includes('Enable')) {
            //search txt to do enable filtering
            displayingList = displayingList.filter(function(triggerItem) {
                return (triggerItem.isEnabled == true)
            })
        } else if(searchText != "" && searchText != null) {
            displayingList = displayingList.filter(function(triggerItem) {
                return (triggerItem.message.includes(searchText) || triggerItem.name.includes(searchText) || triggerItem.rule.includes(searchText))
            })
        }
        //type
        if(searchType != null && searchType != "") {
            displayingList = displayingList.filter(function(triggerItem) {
                return (triggerItem.alarmComponent.type.includes(searchType))
            })
        }
        //priority
        if(searchPriority != null && searchPriority != "") {
            displayingList = displayingList.filter(function(triggerItem) {
                return (triggerItem.severity.includes(searchPriority))
            })
        }
        const {UserInfo} = this.props
        if(UserInfo != null && searchSite != null && searchSite != "" && searchLevel != null && searchLevel != "" && searchLocation != null && searchLocation != "") {
            //location filtering
            displayingList = displayingList.filter(function(triggerItem) {
                const locationInfoArray = triggerItem.locationInfo
                let isExist = false
                if(locationInfoArray != null && locationInfoArray.length != null && locationInfoArray.length != 0) {
                    locationInfoArray.forEach(locationItem => {
                        if(locationItem.id == searchSite || locationItem._id == searchSite || locationItem.id == searchLevel || locationItem._id == searchLevel || locationItem.id == searchLocation || locationItem._id == searchLocation) {
                            isExist = true
                        }
                    })
                }
                return isExist
            })
        }
        else if(UserInfo != null && searchSite != null && searchSite != "" && searchLevel != null && searchLevel != "") {
            //level filtering
            displayingList = displayingList.filter(function(triggerItem) {
                const locationInfoArray = triggerItem.locationInfo
                let isExist = false
                if(locationInfoArray != null && locationInfoArray.length != null && locationInfoArray.length != 0) {
                    locationInfoArray.forEach(locationItem => {
                        if(locationItem.id == searchSite || locationItem._id == searchSite || locationItem.id == searchLevel || locationItem._id == searchLevel) isExist = true
                    })
                }
                return isExist
            })
        }
        else if(UserInfo != null && searchSite != null && searchSite != "") {
            //site filtering
            displayingList = displayingList.filter(function(triggerItem) {
                const locationInfoArray = triggerItem.locationInfo
                let isExist = false
                if(locationInfoArray != null && locationInfoArray.length != null && locationInfoArray.length != 0) {
                    locationInfoArray.forEach(locationItem => {
                        if(locationItem.id == searchSite || locationItem._id == searchSite) isExist = true
                    })
                }
                return isExist
            })
        }
        else {
            //no site/level/location filtering
        }
        console.log(displayingList)
        //enble or disable option check
        const {searchEnable} = this.state
        if(searchEnable == null || searchEnable == "Both") {
            //no filtering with enable/disable is required
        }
        else if(searchEnable == "Enabled") {
            displayingList = displayingList.filter(function(triggerItem) {
                return triggerItem.isEnabled == true
            })
        }
        else {
            //Disabled
            displayingList = displayingList.filter(function(triggerItem) {
                return triggerItem.isEnabled == false
            })
        }

        //splice the alarm array with current page (each page fixed 10 records)
        let EachPage = 8;  //8 reords per each page
        let totalRecords = displayingList.length;
        let PageList = [];
        let PageItem = 1;
        while(PageItem*EachPage - EachPage < totalRecords)
        {
            PageList.push(PageItem);
            PageItem++;
        }
        let pageCount = PageList;
        let startSplice = (currentPage-1)*EachPage;
        let endSplice = (currentPage-1)*EachPage + EachPage;
        displayingList = displayingList.slice(startSplice, endSplice);

        return [
        <div className="AT_container">
        <div className="AT_title">
            Alarm Trigger Setting
        </div>
        <AlarmTriggerHeader refreshList={this.refreshList} SearchHeader={this.SearchHeader} />
        <table className="AT_tableContainer">
            <tr className="header">
                <th className="AT_table_name">
                    Alarm Name
                </th>
                <th className="AT_table_Type">
                    Alarm Component
                </th>
                <th className="AT_table_priority">
                    Priority
                </th>
                <th className="AT_table_location">
                    Location
                </th>
                <th className="AT_table_triggers">
                    Triggers
                </th>
                <th className="AT_table_message">
                    Message
                </th>
                <th className="AT_table_Enabled">
                    Enabled
                </th>  
                <th>
                    <div className="fa fa-plus AT_addPopupBtn" onClick={this.props.AddUser} style={{display: 'none' }}></div>
                </th>
            </tr>
        </table>
        <div className="AT_tableContainerscroll">
        <table className="AT_tableContainer">
            {
                displayingList.map(function(ListItem){
                    countingDevicesPerRow = 0
                    countingDevicesPerRow2 = 0
                    return <tr className="row">
                    <td className="AT_table_name">
                        {ListItem.name}
                    </td>
                    <td className="AT_table_Type">
                        {ListItem.alarmComponent.type}
                    </td>
                    <td className="AT_table_priority">
                        {ListItem.severity}
                    </td>
                    <td className="AT_table_Devices">
                        <div className="ATTable_droplist">
                            {
                                (ListItem.locationInfo==null)?<div></div>:ListItem.locationInfo.map(function(deviceItem){
                                    countingDevicesPerRow++
                                    if(countingDevicesPerRow > 4){
                                        return <div></div>
                                    }
                                    if(countingDevicesPerRow == 4){
                                        return <div className="AT_Table_moreThan3" onClick={() => thisoutside.moreDevices(ListItem)}>... more</div>
                                    }
                                    return <div>
                                        {
                                            deviceItem.name
                                        }
                                    </div>
                                })
                            }
                        </div>
                    </td>
                    <td className="AT_table_triggers">
                        {ListItem.rule}
                    </td>
                    <td className="AT_table_message">
                        {ListItem.message}
                    </td>
                    <td className="AT_table_Enabled">
                        {"Enabled"}
                    </td>
                    <td>
                        <div className="fa fa-edit AT_tableEditBtn" onClick={() => thisoutside.props.editRow(ListItem)}  style={{display: 'none' }}
                        ></div>
                        <div className="fa fa-trash AT_tableDeleteBtn"  onClick={() => thisoutside.deleteRow(ListItem)}  style={{display: 'none' }}>
                        </div>
                    </td>
                </tr>
                })
            }
        </table>
        </div>
        <div className="AT_table_pageContainer">
            {
                pageCount.map(function(pageItem){
                    if(pageItem == currentPage)
                    {
                        return <div className="AT_table_pageBtn AT_pageHighlight">{pageItem}</div>
                    }
                    else
                    {
                        return <div className="AT_table_pageBtn" onClick={()=>thisoutside.changePage(pageItem)}>{pageItem}</div>
                    }
                })
            }
        </div>
    </div>,
    <RealTimeAlert onRef={ref => (this.RealTimeAlertRef = ref)} />,
    <div className={"AT_more_bg " + ShowSeeMorePopup} onClick={this.closeMoreWindow}>
        <div className="AT_more_popupContainer">
            <div className="fa fa-times AT_more_closeBtn" onClick={this.closeMoreWindow}></div>
            See More:
            <div className="AT_more_listContainer">
                {
                    //xxx.map
                    seeMoreList.map(function(seeMoreItem){
                        //get locName from locationID
                        const locationID = seeMoreItem.id
                        let displayLocName = ""
                        UserInfo.locations.forEach(siteItem => {
                            if(siteItem._id == locationID) displayLocName = siteItem.locName
                            siteItem.nestedLocs.forEach(levelItem => {
                                if(levelItem._id == locationID) displayLocName = siteItem.locName + ", " + levelItem.locName
                                levelItem.nestedLocs.forEach(locItem => {
                                    if(locItem._id == locationID) {
                                        displayLocName = siteItem.locName + ", " + levelItem.locName + ", " + locItem.locName
                                    }
                                })
                            })
                        })
                        return <div className="AT_seemore_listItem">
                            <div className="">
                                {
                                    displayLocName
                                }
                            </div>
                            <div className="">
                                {
                                   seeMoreItem.name
                                }
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    </div>,
    <div className={"AT_deleteConfirmBG " + isShowConfirmDelete} onClick={this.closeDeleteConfirmBox}>
    </div>,
    <div className={"AT_deleteBox " + isShowConfirmDelete}>
        <div className="fa fa-times AT_deleteCloseBtn" onClick={this.closeDeleteConfirmBox}></div>
        <div className="AT_deleteTitle">
            Delete the alarm trigger ?
        </div>
        <div className="AT_btnContainer">
            <div className="fa fa-check AT_deleteConfirmBtn" onClick={this.confirmedDelete}>
            </div>
            <div className="AT_deleteCancelBtn" onClick={this.closeDeleteConfirmBox}>
                Cancel
            </div>
        </div>
    </div>
    ]
    }
}

//Redux inside component function
function mapStateToProps(state) {
    return {
      UserInfo: state.UserInfo,
      triggerAlarmList: state.triggerAlarmList,
      socket: state.socket
    };
}

export default connect(mapStateToProps)(AlarmTriggerTable);