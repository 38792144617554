import React from 'react';
//Redux Import
import { connect } from 'react-redux';
import './SetColorTheme.css';
import SoundPlay from '../../Sound/Sound'

class SetColorTheme extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            Colortheme: []
        }

        this.PickColorThemeInPopUp = this.PickColorThemeInPopUp.bind(this);
        this.SetDarkMode = this.SetDarkMode.bind(this);
        this.NormalMode = this.NormalMode.bind(this);

        this.soundRef = React.createRef()
    }
    componentDidMount()
    {
        //get and set the color theme list
        const {configStorage} = this.props
        const thisoutside = this
        if(configStorage != null)
        {
            thisoutside.setState({
                Colortheme: configStorage.colorThemeList
            })
        }
    }
    SetDarkMode()
    {
        localStorage.setItem('DarkMode', true)
        var element = document.getElementById("root")
        element.classList.add("DarkMode")
        //since the background gradient is outside root, have to set the style manually
        const {configStorage} = this.props
        if(configStorage==null||configStorage.darkColorTheme==null) return  //exception condition
        document.documentElement.style.setProperty('--colortheme1', configStorage.darkColorTheme['--colortheme1'])
        document.documentElement.style.setProperty('--colortheme2', configStorage.darkColorTheme['--colortheme2'])
        document.documentElement.style.setProperty('--optiontext',  configStorage.darkColorTheme['--optiontext'])
        console.log('set dark mode')
        document.documentElement.style.setProperty('--slideBG_1', configStorage.darkColorTheme['--slideBG_1'])
        document.documentElement.style.setProperty('--slideBG_2', configStorage.darkColorTheme['--slideBG_2'])
        document.documentElement.style.setProperty('--slideBG_3', configStorage.darkColorTheme['--slideBG_3'])
        document.documentElement.style.setProperty('--optiontext', 'white')
    }
    NormalMode()
    {
        //convert to Light Mode
        localStorage.setItem('DarkMode', false)
        var element = document.getElementById("root")
        element.classList.remove("DarkMode")

        //since the background gradient is outside root, have to set the style manually
        let ColorNavigationBar = localStorage.getItem('--colortheme1')
        let ColorBackground = localStorage.getItem('--colortheme2')

        //set to the css variable
        if(ColorNavigationBar != null && ColorNavigationBar != '')
        {
            document.documentElement.style.setProperty('--colortheme1', ColorNavigationBar)
            document.documentElement.style.setProperty('--colortheme2', ColorBackground)
        }
        document.documentElement.style.setProperty('--optiontext', 'black')

        console.log('set normal mode')
        //Navbar styling variable, since navbar slide is outside "#root"
        const {configStorage} = this.props
        if(configStorage==null||configStorage.darkColorTheme==null) return  //exception condition
        document.documentElement.style.setProperty('--slideBG_1', configStorage.darkColorTheme['--slideBG_1_Normal'])
        document.documentElement.style.setProperty('--slideBG_2', configStorage.darkColorTheme['--slideBG_2_Normal'])
        document.documentElement.style.setProperty('--slideBG_3', configStorage.darkColorTheme['--slideBG_3_Normal'])
    }
    PickColorThemeInPopUp(e)
    {
        this.soundRef.current.alarmMp3Run("click")
        if(e.target.id == null || e.target.id == undefined || e.target.id == "")
        {
            console.error('get OnClick ID Failed');
            return;
        }
        //get id
        let RawId = e.target.id.replace('PickColor_', '');
        //get array count
        let Id_int = parseInt(RawId);
        //get the color theme data
        let ColorData = this.state.Colortheme[Id_int];
        //set the color
        //console.log(ColorData);
        //set to the css variable
        document.documentElement.style.setProperty('--focuscardbackground', ColorData.ColorHighlight);
        document.documentElement.style.setProperty('--colortheme1', ColorData.ColorNavigationBar);
        document.documentElement.style.setProperty('--colortheme2', ColorData.ColorBackground);
        document.documentElement.style.setProperty('--divheader', ColorData.ColorHeader);
        
        //set to the localstorage
        localStorage.setItem('--focuscardbackground', ColorData.ColorHighlight);
        localStorage.setItem('--colortheme1', ColorData.ColorNavigationBar);
        localStorage.setItem('--colortheme2', ColorData.ColorBackground);
        localStorage.setItem('--divheader', ColorData.ColorHeader);
        let DarkMode = localStorage.getItem('DarkMode');
        if(DarkMode != "false")
        {
            this.SetDarkMode();
        }
        else
        {
            this.NormalMode();
        }
    }
    render() {
        return <div className="SetColorThemeContainer">
            <div className="SetColorThemeTitle">
                Set Color Theme
            </div>
            {
                this.state.Colortheme.map((result, i) => {
                    return <div className="SettingColor" onClick={this.PickColorThemeInPopUp} id={"PickColor_" + i}>
                        <div className="SettingColorRow" style={{backgroundColor: result.ColorHighlight}} id={"PickColor_" + i}>
                            {result.ColorHighlight}
                        </div>
                        <div className="SettingColorRow" style={{backgroundColor: result.ColorNavigationBar}} id={"PickColor_" + i}>
                            {result.ColorNavigationBar}
                        </div>
                        <div className="SettingColorRow" style={{backgroundColor: result.ColorBackground}} id={"PickColor_" + i}>
                            {result.ColorBackground}
                        </div>
                        <div className="SettingColorRow" style={{backgroundColor: result.ColorHeader}} id={"PickColor_" + i}>
                            {result.ColorHeader}
                        </div>
                    </div>
                })
            }
            <SoundPlay ref={this.soundRef}/>
        </div>
    }
}

//Redux inside component function
function mapStateToProps(state) {
    return {
      socket: state.socket,
      configStorage: state.configStorage
    };
}

export default connect(mapStateToProps)(SetColorTheme);