import React from 'react'
import './tablehead.css'
import { connect } from 'react-redux'

const HeaderKeyList = [
    {
        HeaderCount: 0,
        key: 'ColdboxID'
    },
    {
        HeaderCount: 1,
        key: 'controllerID',
    },
    {
        HeaderCount: 2,
        key: 'UPSBatteryCapacity',
    },
    {
        HeaderCount: 3,
        key: 'status',
    },
    {
        HeaderCount: 4,
        key: 'MTBF'
    },
    {
        HeaderCount: 5,
        key: 'MTTR'
    },
    {
        HeaderCount: 6,
        key: 'faultRate'
    },
    {
        HeaderCount: 7,
        key: 'faultCount'
    },
    {
        HeaderCount: 8,
        key: 'RunHrs'
    },
]

class TableHead extends React.Component {
    constructor(props) {
        super()

        this.state = {
            sortedList: null,
            sortOption: {
                key: 'id',
                parentkey: null,
                value: true,        //true = Asec, false = Desc
                col: 0,
            }
        }

        this.OptnChange = this.OptnChange.bind(this)
        this.Sort = this.Sort.bind(this)
    }
    componentDidMount() {
        this.Sort()
    }
    componentDidUpdate(preProps) {
        const {filteredList} = this.props
        const oldfilteredList = preProps.filteredList
        if(filteredList != oldfilteredList || oldfilteredList == null && oldfilteredList != null) this.Sort()
    }
    OptnChange(keyStr, parentKeyStr) {
        const {sortOption} = this.state
        const {key, value} = sortOption
        let newsortOption = sortOption
        if(key == keyStr) {
            //doggle DESC/AESC
            newsortOption.value = !value
        }
        else {
            //Set new Key sorting
            newsortOption.key = keyStr
            if(parentKeyStr != null) newsortOption.parentkey = parentKeyStr
            else newsortOption.parentkey = null
        }

        const ColCount = HeaderKeyList.findIndex((item) => item.key == keyStr)
        newsortOption.col = ColCount

        this.setState({
            sortOption: newsortOption
        }, function() {this.Sort()})
    }
    Sort() {
        const _this = this
        const {filteredList} = this.props
        if(filteredList == null || filteredList.length == null) return

        const {sortOption} = this.state
        const {key, parentkey, value, col} = sortOption

        var newFilteredList = filteredList
        const SortOrder = value?1:-1
        newFilteredList = newFilteredList.sort(function(a, b) {
            let A_value = (parentkey==null)?a[key]
            :
            (a[parentkey]==null)?'':a[parentkey][key]        //check parentkey if need get inner key value

            let B_value = (parentkey==null)?b[key]
            :
            (b[parentkey]==null)?'':b[parentkey][key]        //check parentkey if need get inner key value

            A_value = (A_value==null)?'0':A_value.toString()
            B_value = (B_value==null)?'0':B_value.toString()
            if(A_value > B_value) return 1 * SortOrder
            if(A_value < B_value) return -1 * SortOrder
            return 0
        })
        // newFilteredList.forEach(item => {
        //     console.log(item[key])
        // })
        //update local state
        this.setState({
            sortedList: newFilteredList
        })
        //update parent's coldbox Filtered List (state)
        this.props.UpdateList('sortedList', null)
        setTimeout(function() {_this.props.UpdateList('sortedList', newFilteredList)}, 200)
    }

    render() {
        const {sortOption} = this.state
        const {key, value, col} = sortOption        //selected sort option values
        var arrowIcon = value?'sort-up':'sort-down'

        return <div className="ColdboxIndi_tablehead">
            <div className={col==0?"Col1 sortHighlight":"Col1"} onClick={() => this.OptnChange(HeaderKeyList[0].key, 'payload')}>
                ID
                {
                    col==0?<div className={"fa fa-" + arrowIcon}></div>
                    :
                    <div style={{display: 'none'}}></div>
                }
            </div>
            <div className={col==2?"Col2 sortHighlight":"Col2"} onClick={() => this.OptnChange(HeaderKeyList[2].key, 'status')}>
                Remaining Battery
                {
                    col==2?<div className={"fa fa-" + arrowIcon}></div>
                    :
                    <div style={{display: 'none'}}></div>
                }
            </div>
            <div className={col==3?"Col2 sortHighlight":"Col2"} onClick={() => this.OptnChange(HeaderKeyList[3].key, 'status')}>
                Availability
                {
                    col==3?<div className={"fa fa-" + arrowIcon}></div>
                    :
                    <div style={{display: 'none'}}></div>
                }
            </div>
            <div className={col==4?"Col2 sortHighlight":"Col2"} onClick={() => this.OptnChange(HeaderKeyList[4].key, null)}>
                MTBF
                {
                    col==4?<div className={"fa fa-" + arrowIcon}></div>
                    :
                    <div style={{display: 'none'}}></div>
                }
            </div>
            <div className={col==5?"Col2 sortHighlight":"Col2"} onClick={() => this.OptnChange(HeaderKeyList[5].key, null)}>
                MTTR
                {
                    col==5?<div className={"fa fa-" + arrowIcon}></div>
                    :
                    <div style={{display: 'none'}}></div>
                }
            </div>
            <div className={col==6?"Col2 sortHighlight":"Col2"} onClick={() => this.OptnChange(HeaderKeyList[6].key, null)}>
                fault Rate
                {
                    col==6?<div className={"fa fa-" + arrowIcon}></div>
                    :
                    <div style={{display: 'none'}}></div>
                }
            </div>
            <div className={col==7?"Col2 sortHighlight":"Col2"} onClick={() => this.OptnChange(HeaderKeyList[7].key, null)}>
                Fault Count
                {
                    col==7?<div className={"fa fa-" + arrowIcon}></div>
                    :
                    <div style={{display: 'none'}}></div>
                }
            </div>
            <div className={col==8?"Col2 sortHighlight":"Col2"} onClick={() => this.OptnChange(HeaderKeyList[8].key, 'payload')}>
                Operating Hours
                {
                    col==8?<div className={"fa fa-" + arrowIcon}></div>
                    :
                    <div style={{display: 'none'}}></div>
                }
            </div>
            <div className="Col3">
                <div className="ColdboxIndi_AddBtn" onClick={this.props.addPopup}>
                    <div className="fa fa-plus"></div>
                </div>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
    }
}
export default connect(mapStateToProps)(TableHead)