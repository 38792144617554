import React from 'react'
import './pagebtns.css'

//Redux Import
import { connect } from 'react-redux'

class PageBtns extends React.Component {
    constructor(props)
    {
        super()
    }

    render()
    {
        return <div className="Coldbox_GD_pageMain">
            <div className="Coldbox_GD_pageBtn">{"<"}</div>
            <div className="Coldbox_GD_pageBtn">Back</div>
            <div className="Coldbox_GD_pageBtn current">1</div>
            <div className="Coldbox_GD_pageBtn">Next</div>
            <div className="Coldbox_GD_pageBtn">{'>'}</div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
    }
}
  
export default connect(mapStateToProps)(PageBtns)