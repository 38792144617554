/**

Input:
DefaultDate: Define the TYPE
>> Today
>> PreMonth

ID: datepicker ID
ChangeDate: function to trigger when date value is changed, with input date 9string)

*/

import React, { useState, useEffect } from 'react'
import './datepick.css'

import DatePicker from 'react-datepicker'

function DatePickWidget(props) {
    const [inputDate, setDate] = useState(new Date())
    const {DefaultDate, ChangeDate, ID} = props

    useEffect(() => {
        //onload function
        var SetDateValue
        var x = new Date()

        // switch(DefaultDate) {
        //     case "Today":
        //         x.setMonth(x.getMonth() + 1)
        //         break
        //     case "PreMonth":
        //         x.setMonth(x.getMonth())
        //         break
        //     default:
        //         break
        // }
        SetDateValue = new Date(x.getFullYear(), x.getMonth(), 0)
        SetDateValue.setHours(16)
        SetDateValue.setMinutes(0)
        setDate(SetDateValue)
    }, [])

    useEffect(() => {
        //send the updated Date to parent
        if(inputDate == null) return
        const d = inputDate
        let outputDate = new Date(d.getFullYear(), d.getMonth(), 0)         //e.g. Jan to Feb > Dec-31 to Jan-31
        outputDate.setHours(16)
        outputDate.setMinutes(0)
        ChangeDate(ID, outputDate)    
    }, [inputDate])
    
    return <div className="DatePickerClass">
        <DatePicker selected={inputDate} className="LiftSummary_input" dateFormat="MMM yyyy" onChange={(date) => setDate(date)} 
        showMonthYearPicker />
    </div>
    
}

export default DatePickWidget