import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import './WorkOrderList.css'
import SearchBar from "./SearchBar";
import TableHead from "./TableHead";
import Table from "./Table";
import { useHistory } from "react-router-dom";
import AddButton from '../bottom/addButton'
import Downloadcsv from "../bottom/downloadcsv";
import moment from 'moment'
import Pagebtns from "../bottom/pagebtns";

const axios = require('axios');
function MmsWorkOrderList(props) {
    const history = useHistory()
    const [isAdvanceSearch, setAdvanceSearch] = useState(false)
    const [workOrderList, setWorkOrderList] = useState([])
    const [filteredList, setFilteredList] = useState([])
    const [pageSliceList, setPageSliceList] = useState([])
    const [startDate, setStartDate] = useState(new Date(moment().subtract(30, 'days')))
    const [endDate, setEndDate] = useState(new Date())
    const [page, setPage] = useState(0)

    useEffect(() => {
        refresh()
    }, [startDate, endDate])

    const requestData_workorder_list = {
        "options": {
            "startTime": startDate,
            "endTime": endDate,
            "offset": 0 // required, for list paging
        }
    }

    function refresh() {

        axios.post('https://47.52.20.40:31001/AtalAPI/v1/CPSL_WorkOrder_List', requestData_workorder_list)
            .then(function (response) {
                console.log("CPSL_WorkOrder_List recv!", response)
                let pageSliceList = response.data.info.map(item => {
                    return {
                        "name": item.deviceName,
                        "workOrderType": item.type,
                        "parts": item.parts,
                        "orderNo": item.number,
                        "requestor": item.requestedStaffName,
                        "status": item.status,
                        "requestDate": item.requestDate,
                        "followUp": item.followUpStaffName,
                        "reported": item.vendorName,
                        "location": item.lastKnownLocation,
                        "downTime": item.downTime,
                        "order_id": item.order_id
                    }
                })
                setWorkOrderList(pageSliceList)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function UpdateList(key, list) {
        if (key === "pageSliceList")
            setPageSliceList(list)
        else setFilteredList(list)
    }

    function setStartTime(date) {
        setStartDate(date)
    }
    function setEndTime(date) {
        setEndDate(date)
    }
    function AdvanceSearch() {
        setAdvanceSearch(true)
    }
    function closeAdSearch() {
        setAdvanceSearch(false)
    }
    const CsvHeaders = [
        {
            key: 'name',
            label: 'Coldbox ID',
        },
        {
            key: 'workOrderType',
            label: 'Type',
        },
        {
            key: 'parts',
            label: 'Parts',
        },
        {
            key: 'orderNo',
            label: 'Work Order',
        },
        {
            key: 'requestor',
            label: 'Requestor',
        },
        {
            key: 'status',
            label: 'Status',
        },
        {
            key: 'requestDate',
            label: 'Request Date & Time',
        },
        {
            key: 'followUp',
            label: 'Follow-up By',
        },
        {
            key: 'reported',
            label: 'Reported To',
        },
        {
            key: 'location',
            label: 'Last Known Location',
        },
        {
            key: 'downTime',
            label: 'Down Time',
        }
    ]
    return <div className="workOrderList">
        <SearchBar isAdvanceSearch={isAdvanceSearch} AdvanceSearch={AdvanceSearch} closeAdSearch={closeAdSearch} workOrderList={workOrderList} UpdateList={UpdateList} refresh={refresh} setStartTime={setStartTime} setEndTime={setEndTime} />
        <TableHead />
        {
            (pageSliceList != null && pageSliceList != undefined) ?
                pageSliceList.map(row => {
                    return <Table row={row} onClick={() => history.push({ pathname: "/Main/MmsWorkOrderDetail", state: { "mode": "Update", "data": (row) } })} />
                }) : ""
        }
        <div className="ColdboxIndi_bottom">
            <Downloadcsv sortedList={workOrderList} headers={CsvHeaders} filename='WorkOrderList.csv' />
            <Pagebtns sortedList={filteredList} UpdateList={UpdateList} />
        </div>
    </div>
}

function mapStateToProps(state) {
    return {
        socket: state.socket,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate, //when this value is updated, render would triggered
        UserInfo: state.UserInfo,
        Notification: state.Notification,
    }
}
export default connect(mapStateToProps)(MmsWorkOrderList)