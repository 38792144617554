import React from 'react'
import './table.css'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsAltV, faSnowflake, faThermometerEmpty, faBolt, faThermometerThreeQuarters, faDesktop } from '@fortawesome/free-solid-svg-icons'
import {GetRelatedList} from './function'
import PopupCard from './popupCard'

class TableItem extends React.Component {
    constructor(props) {
        super()
        this.state = {
            optionOpenID: null,
            DetailItem: null,           //when null, not show
            CardPopup: null
        }
        this.OpenOption = this.OpenOption.bind(this)
        this.CloseOption = this.CloseOption.bind(this)

        this.OpenCard = this.OpenCard.bind(this)

        this.IndividualStatus = this.IndividualStatus.bind(this)
        this.SetpointRedirect = this.SetpointRedirect.bind(this)
        this.DashRedirect = this.DashRedirect.bind(this)

        this.ShowFullTable = this.ShowFullTable.bind(this)
    }
    OpenOption(coldboxID) {
        this.props.OptionDroplist(coldboxID)
    }
    CloseOption() {
        this.props.OptionDroplist(null)
    }
    IndividualStatus(coldboxItem) {
        //redirect to individual status
        localStorage.setItem('selectedColdboxID', coldboxItem.locName)
        this.props.history.push({
            pathname: '/Main/FloorPlan/ColdboxStatus',
            Item: coldboxItem,
        })
    }
    SetpointRedirect(coldboxItem) {
        localStorage.setItem('coldboxSetPt', coldboxItem.locName)
        this.props.history.push('/Main/ColdboxManage/coldboxSetpoint')
    }
    ShowFullTable(card) {
        const {MaxTableList} = this.props
        if(card == MaxTableList) this.props.ShowFullTable(null)         //show all
        else this.props.ShowFullTable(card)                             //show current table only
    }
    OpenCard(coldboxID) {
        this.setState({
            CardPopup: coldboxID
        })
    }
    DashRedirect(coldboxItem) {
        const {
            LevelLocName,
            SiteLocName,
            _id
        } = coldboxItem

        const {DeviceData} = this.props
        if(DeviceData==null) return
        
        var siteList = DeviceData.map(item => item.locName)
        console.table(siteList)

        const siteIndex = siteList.indexOf(SiteLocName)
        console.log('siteIndex:'+siteIndex)
        this.props.dispatch({                                   //set site
            type: 'PIckSite',
            data: {
                Siteid: siteIndex
            }
        })
        this.props.dispatch({                                   //set level
            type: 'PickLevel',
            data: LevelLocName
        })        

        localStorage.setItem('onloadSelectLocation', _id)       //loc detail (id) > prefs        
        this.props.history.push('/Main/FloorPlan')              //redirect to dashboard page
    }
    render() {
        const _this = this
        const {DetailItem, CardPopup} = this.state
        const {card, MaxTableList, optionOpenID, filterSite, filterLevel, selectedColdbox} = this.props       //card: 1,2,3,4 (string), coldboxStatus: array list of card
        const {DeviceData, UserInfo} = this.props
        if(UserInfo == null) return <div></div>

        if(DeviceData == null) return <div>DeviceData is Undefined</div>

        let FullscreenClass = ''
        if(MaxTableList != null && card != MaxTableList.toString()) return <div style={{display: 'none'}}></div>
        else if(MaxTableList != null && MaxTableList.toString() == card) {
            FullscreenClass = 'ColdboxListFull'                             //full screen table
        }

        const convertStatus = {
            '1': '2',  //loaded
            '2': '0',  //Empty
            '3': '1',  //Precooling
            '4': '3'   //Damage / maintenance
        }
        const StatusString = convertStatus[card]
        const relatedList = GetRelatedList(_this.props, filterSite, filterLevel, StatusString)           //function.js

        let counttxt, cardColorClass
        switch(card) {
            case '1':
                counttxt = 'Loaded'
                cardColorClass = 'loaded'
                break
            case '2':
                counttxt = 'Empty'
                cardColorClass = 'Empty'
                break
            case '3':
                counttxt = 'Precooling'
                cardColorClass = 'Precooling'
                break
            case '4':
                counttxt = 'Maintenance'
                cardColorClass = 'Maintenance'
                break
        }

        var DisplayColdboxItems = relatedList.map(function(coldboxItem) {
            const {
                WeightedMeanTemp,
                TempSetPoint,
                TempHighSetPoint,
                TempLowSetPoint,
                BeaconLowBatteryFault,
                UPSBatteryLowFault,
                UPSBatteryLowWarning,
                LoRaWANGatewayFault
            } = coldboxItem.payload
            
            const batteryclass = (BeaconLowBatteryFault==true||UPSBatteryLowFault==true||UPSBatteryLowWarning==true)?'error':'warn'
            const batteryIcon = (BeaconLowBatteryFault==true||UPSBatteryLowFault==true||UPSBatteryLowWarning==true)?'battery-empty fa-rotate-270':'battery-three-quarters fa-rotate-270'

            var Temp_Average = parseFloat(WeightedMeanTemp)
            var Temp_H = parseFloat(TempHighSetPoint)
            var Temp_L = parseFloat(TempLowSetPoint)
            var Temp_M = parseFloat(TempSetPoint)
            var tempclass
            if(Temp_Average >= Temp_H) tempclass = 'error'
            else if(Temp_Average < Temp_H && Temp_Average >= Temp_M) tempclass = 'warn'
            else if(Temp_Average < Temp_M && Temp_Average > Temp_L) tempclass = 'normal'
            const tempBool = (Temp_Average >= Temp_H)

            let BoxCardClass = 'OperOver_coldboxItem'
            if(optionOpenID == coldboxItem._id) BoxCardClass += ' highlight'
            if(LoRaWANGatewayFault==true) BoxCardClass += ' warning'
            if(selectedColdbox != null && selectedColdbox.locName == coldboxItem.locName && selectedColdbox.LevelLocName == coldboxItem.LevelLocName) BoxCardClass += ' searchingitem'
            return [
                <div className={BoxCardClass} onClick={() => _this.OpenOption(coldboxItem._id)}>
                    <div className='coldbox_name'>
                        {coldboxItem.locName}
                    </div>
                    <div className='coldbox_statusIcons'>
                        <div className={'coldbox_statusIcon ' + tempclass}>
                            <FontAwesomeIcon icon={faSnowflake} className='fa tempicon1' />
                            {
                                tempBool?
                                <FontAwesomeIcon icon={faThermometerThreeQuarters} className='fa' />
                                :
                                <FontAwesomeIcon icon={faThermometerEmpty} className='fa' />
                            }
                        </div>
                        <div className={'coldbox_statusIcon ' + batteryclass}>
                            <div className={'fa fa-' + batteryIcon}></div>
                            <FontAwesomeIcon icon={faBolt} className='fa tempicon2' />
                        </div>
                    </div>
                    <PopupCard CardPopup={coldboxItem._id} />
                </div>,

                //open coldbox option window (same page)
                !(optionOpenID == coldboxItem._id)?<div style={{display: 'none'}}></div>:
                <div className='coldbox_dropOption' onClick={_this.CloseOption}>
                    <div className='' onClick={() => _this.IndividualStatus(coldboxItem)}>
                        <i className='fa fa-info'></i>
                    </div>
                    <div className='' onClick={() => _this.SetpointRedirect(coldboxItem)}>
                        <i className='fa fa-edit'></i>
                    </div>
                    <div className='' onClick={() => _this.DashRedirect(coldboxItem)}>
                        <FontAwesomeIcon icon={faDesktop} className='fa' />
                    </div>
                    <div className='' onClick={() => null}>                    
                        <i className='fa fa-times'></i>
                    </div>
                </div>
            ]
        })

        return <div className={'OperOver_RowItem ' + cardColorClass + ' ' + FullscreenClass} key={card}>
            <div className='coldbox_list'>
                {DisplayColdboxItems}
            </div>
            <div className="bottomoptions">
                <div className='bottom_statusTxt'>{counttxt + ':' + relatedList.length}</div>
                <div className='bottom_ListBtn' onClick={() => _this.ShowFullTable(card)}>
                    {
                        (MaxTableList != null && MaxTableList.toString() == card)?
                        <div className='fa fa-list'></div>
                        :
                        <FontAwesomeIcon icon={faArrowsAltV} className='fa' />
                    }
                </div>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        DeviceData: state.DeviceData,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate,
        currentSite: state.currentSite,
        currentlevel: state.currentlevel,
        UserInfo: state.UserInfo,
    }
}  
export default connect(mapStateToProps)(TableItem)