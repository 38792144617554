import React, { useState } from 'react'
import './tasks.css'

import { connect } from 'react-redux'
import getColorCode from '../commonfunction'

function ToiletTaskWidget(props) {
    const {DeviceData, currentlevel, currentSite, ToiletType} = props
    const [ExpandItem, setExpandItem] = useState(0)             //0 = show all, 1 = max size urgent, 2 = max size important, 3 = max size normal

    function ExpandAList(index) {
        if(ExpandItem == index) setExpandItem(0)        //show all
        else setExpandItem(index)
    }

    const {color1, color2, gradient1, gradient2} = getColorCode(ToiletType)
    const FilteredDeviceData = CurrentToiletData(DeviceData, currentlevel, currentSite, ToiletType)
    const IssueList = GenerateIssueList(FilteredDeviceData)
    console.log(IssueList)

    const UrgentList = IssueList.filter(item=> {
        return item.priority == 1
    })
    const ImportantList = IssueList.filter(item=> {
        return item.priority == 2
    })
    const NormalList = IssueList.filter(item => {
        return item.priority == 3
    })

    let expandclass1 = 'tasklist_hide'
    let expandclass2 = 'tasklist_hide'
    let expandclass3 = 'tasklist_hide'
    switch (ExpandItem) {
        case 0:
            expandclass1 = 'tasklist_expanded'
            expandclass2 = 'tasklist_expanded'
            expandclass3 = 'tasklist_expanded'
            break
        case 1:
            expandclass1 = 'tasklist_expanded'
            break
        case 2:
            expandclass2 = 'tasklist_expanded'
            break
        case 3:
            expandclass3 = 'tasklist_expanded'
            break
    }
    return <div className="Toilet_admin_task">
        <div className="Toilet_admin_task_title">Outstanding Tasks</div>
        <div className={"cardList MostImportant " + expandclass1}>
            <div className="cardhead" onClick={()=>ExpandAList(1)}>Urgent</div>
            <div className="innerlist">
            {
                UrgentList.map(item => {
                    return <div className="Toilet_admin_taskItem" style={{background: 'linear-gradient(to right, '+color1+' 10px, white 10px)'}}>
                            <div className="loc">{item.loc}</div>
                            <div className="desc">{item.desc}</div>
                    </div>
                })
            }
            </div>
        </div>
        <div className={"cardList Important " + expandclass2}>
            <div className="cardhead" onClick={()=>ExpandAList(2)}>Important</div>
            <div className="innerlist">
            {
                ImportantList.map(item => {
                    return <div className="Toilet_admin_taskItem" style={{background: 'linear-gradient(to right, '+color2+' 10px, white 10px)'}}>
                            <div className="loc">{item.loc}</div>
                            <div className="desc">{item.desc}</div>
                    </div>
                })
            }
            </div>
        </div>
        <div className={"cardList Normal " + expandclass3}>
            <div className="cardhead" onClick={()=>ExpandAList(3)}>Normal</div>
            <div className="innerlist">
            {
                NormalList.map(item => {
                    return <div className="Toilet_admin_taskItem" style={{background: 'linear-gradient(to right, '+gradient1+' 10px, white 10px)'}}>
                            <div className="loc">{item.loc}</div>
                            <div className="desc">{item.desc}</div>
                    </div>
                })
            }
            </div>
        </div>
    </div>
}

function mapStateToProps(state) {
    return {
        DeviceData: state.DeviceData,
        currentlevel: state.currentlevel,
        currentSite: state.currentSite,
        ToiletType: state.ToiletType,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate,
    }
}
export default connect(mapStateToProps)(ToiletTaskWidget)

// w = 5
// h = 8

function CurrentToiletData(DeviceData, currentlevel, currentSite, ToiletType) {
    if(DeviceData == null) return

    const SiteItem = DeviceData[currentSite]
    const LevelItem = SiteItem.nestedLocs.find(item => {
        return item.locName == currentlevel
    })
    const LocList = LevelItem.nestedLocs
    const ToiletTypeLocList = LocList.filter(item => {
        if(item.payload.toiletType == null) return false
        return item.payload.toiletType.toString() == ToiletType.toString()
    })
    return ToiletTypeLocList
}

function GenerateIssueList(DeviceDataList) {
    if(DeviceDataList == null || DeviceDataList?.length == null || DeviceDataList.length == 0) return []

    let IssueList = []
    DeviceDataList.forEach(ReadingItem => {
        //separate diff sensor type
        const TypeName = ReadingItem?.payload_definition_id?.nameID
        //console.log(TypeName)
        switch (TypeName) {
            case 'Flushing Water':
                const {flushingWater} = ReadingItem.payload
                if(flushingWater == false) {
                    IssueList.push({
                        type: TypeName,
                        loc: ReadingItem.locName,
                        desc: 'No Flushing Water Alarm',
                        priority: 1
                    })
                }
                break
            case 'IAQ 4in1':
                const {temperature, humidity, co2, pm2_5, ammonia} = ReadingItem.payload
                if(temperature > 27) {
                    IssueList.push({
                        type: TypeName,
                        loc: ReadingItem.locName,
                        desc: 'Temperature high (>27): ' + temperature,
                        priority: 3
                    })
                }
                if(temperature < 21) {
                    IssueList.push({
                        type: TypeName,
                        loc: ReadingItem.locName,
                        desc: 'Temperature low (<21): ' + temperature,
                        priority: 3
                    })
                }
                if(humidity > 50) {
                    IssueList.push({
                        type: TypeName,
                        loc: ReadingItem.locName,
                        desc: 'Humidity high (>50): ' + humidity,
                        priority: 3
                    })
                }
                if(humidity < 30) {
                    IssueList.push({
                        type: TypeName,
                        loc: ReadingItem.locName,
                        desc: 'Humidity low (<30): ' + humidity,
                        priority: 3
                    })
                }
                if(co2 > 1600) {
                    IssueList.push({
                        type: TypeName,
                        loc: ReadingItem.locName,
                        desc: 'CO2 reading large (>1600): ' + co2,
                        priority: 2
                    })
                }
                if(pm2_5 > 2.5) {
                    IssueList.push({
                        type: TypeName,
                        loc: ReadingItem.locName,
                        desc: 'PM2.5 reading large (>2.5): ' + pm2_5,
                        priority: 2
                    })
                }
                if(ammonia > 5) {
                    IssueList.push({
                        type: TypeName,
                        loc: ReadingItem.locName,
                        desc: 'ammonia reading large (>5): ' + ammonia,
                        priority: 2
                    })
                }
                break
            case 'Fall Detection':
                const {fallDetected} = ReadingItem.payload
                if(fallDetected == true) {
                    IssueList.push({
                        type: TypeName,
                        loc: ReadingItem.locName,
                        desc: 'Fall Alarm On',
                        priority: 1
                    })
                }
                break
            case 'Toilet Paper':
                const {hasPaper, PaperLevel} = ReadingItem.payload
                if(hasPaper == false) {
                    IssueList.push({
                        type: TypeName,
                        loc: ReadingItem.locName,
                        desc: 'No toilet paper, need refill',
                        priority: 2
                    })
                }
                break
            case 'Soap':
                const {hasSoap, soapLevel} = ReadingItem.payload
                if(hasSoap == false) {
                    IssueList.push({
                        type: TypeName,
                        loc: ReadingItem.locName,
                        desc: 'No Soap, need refill',
                        priority: 2
                    })
                }
                break
            case 'Waste Bin':
                const {isFull} = ReadingItem.payload
                if(isFull == true) {
                    IssueList.push({
                        type: TypeName,
                        loc: ReadingItem.locName,
                        desc: 'Waste Bin is full',
                        priority: 2
                    })
                }
                break
            case 'Water Leakage':
                const {waterLeakageStatus} = ReadingItem.payload
                if(waterLeakageStatus == true || waterLeakageStatus == 1) {
                    IssueList.push({
                        type: TypeName,
                        loc: ReadingItem.locName,
                        desc: 'Water Leakage Alarm On',
                        priority: 1
                    })
                }
                break
            default:
                console.log('Undefined DeviceData Type: ' + TypeName)
                break
        }
    })

    //return the issue list
    return IssueList
}