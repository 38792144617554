import React from 'react'
import './style.css'
import GoogleMapReact from 'google-map-react'
import { motion } from "framer-motion"
import { connect } from 'react-redux'
import {getTxt} from '../common/language/language'
import {GetSiteName} from '../common/language/locDisplay'

//** if UserInfo have "GoogleMap" == "Image" , would not show this googlemap.js widget  */

class GoogleMap extends React.Component {
    static defaultProps = {
        center: {
            lat: 0,
            lng: 0
        },
        zoom: 12
    }

    constructor(props) {
        super(props)

        this.CloseSiteDetail = this.CloseSiteDetail.bind(this)
        this.ViewBuildingSiteDetail = this.ViewBuildingSiteDetail.bind(this)
        this.ViewSummaryDetail = this.ViewSummaryDetail.bind(this)

        this.MapCenter = this.MapCenter.bind(this)
        this.Map_onChange = this.Map_onChange.bind(this)

        this.AlarmRequest = this.AlarmRequest.bind(this)


        //new location picked function
        this.pickSite = this.pickSite.bind(this)

        if(props.configStorage == null) {
            console.warn('Config file is missing, Insert Hardcode Data for googlemap.js')
            this.state = {
                BuildingName: '',
                BuildingDetail: '',
                BuildingAckDetail: '',
                BuildingPhotoUrl: '',
                BuildingId: '',
                BuildingX: -700,
                BuildingY: 0,
                ShowBuildingDetail: false,
                center: {
                    lat: 0,
                    lng: 0
                },
                //GoogleMapKey: "AIzaSyBX6Vqy4Z1SEfsyRDsTO6IF3IMFHesZHO8" //this is the developmode google key
            }
            return
        }

        //console.warn(props.configStorage.GoogleMapKey);
        this.state = {
            BuildingName: '',
            BuildingDetail: '',
            BuildingAckDetail: '',
            BuildingPhotoUrl: '',
            BuildingId: '',
            BuildingX: -700,
            BuildingY: 0,
            ShowBuildingDetail: false,
            center: {
                lat: 0,
                lng: 0
            },
            GoogleMapKey: props.configStorage.GoogleMapKey
        }
    }
    
    pickSite(siteData) {
      // console.log(siteData)
      if(siteData == null || siteData.locName == null || siteData.nestedLocs == null || siteData.nestedLocs.length == null || siteData.nestedLocs.length == 0) return     //all exception cases
      const locName = siteData.locName
      const {UserInfo} = this.props
      if(UserInfo == null || UserInfo.locations == null || UserInfo.locations.length == null || UserInfo.locations.length == 0) return                                    //redux exception cases

      let siteCount = 0
      let currentSiteCount = null
      UserInfo.locations.forEach(SiteData => {
        if(SiteData.locName == locName) currentSiteCount = siteCount
        siteCount++
      })
      if(currentSiteCount == null) return     //no site is found exception

      const buildingLevelList = siteData.nestedLocs

      // console.log({
      //   SiteID: currentSiteCount,
      //   Level: buildingLevelList,
      // })
      this.props.dispatch({ type: 'PIckSite', data: {
        Siteid: currentSiteCount,
        Level: buildingLevelList,
      }})
    }

    CloseSiteDetail() {
        this.setState({
            BuildingX: -2000,
            BuildingY: 0
        })
    }

    ViewBuildingSiteDetail() {
      this.props.history.push("/Main/FloorPlan")
    }

    ViewSummaryDetail() {
      this.props.history.push("/Main/Summary")
    }

    componentDidMount() {
        this.MapCenter()
        this.AlarmRequest()    //load alarm list to redux: Notification, show new/ack alarm count
    }

    MapCenter() {
      try {
        const { UserInfo } = this.props
        let SiteCount = 0
        let xSum = 0
        let ySum = 0
        //get the Site Locations (foreach)
        UserInfo.locations.forEach(LocItem => {
          xSum += LocItem.coor.coordinates[0]
          ySum += LocItem.coor.coordinates[1]
          SiteCount++
        })

        //Sum and get average X Y Coor
        let xResult = xSum/SiteCount
        let yResult = ySum/SiteCount
        //Set to state.center
        this.setState({
          center: {
              lat: xResult,
              lng: yResult
          }
        })
      }
      catch(err) {
        console.error()
      }
    }

    Map_onChange() {
        try {
          const { UserInfo } = this.props
          let SiteCount = 0
          let xSum = 0
          let ySum = 0
          //get the Site Locations (foreach)
          UserInfo.locations.forEach(LocItem => {  
            xSum += LocItem.coor.coordinates[0]
            ySum += LocItem.coor.coordinates[1]
            SiteCount++
          })
  
          //Sum and get average X Y Coor
          let xResult = xSum/SiteCount
          let yResult = ySum/SiteCount
          this.setState({
              center: {
                  lat: xResult + 0.00001,
                  lng: yResult + 0.00001
              }
          })
        }
        catch(err) {
          console.log('error in getting the center of the sites')
          console.log(err)
        }
    }

    componentDidCatch(error, info) {
        console.error('componentDidCatch() on googlemap.js')
        console.error(error)
        console.error(info)
        //redirect to App.js to reload all the datas
        this.props.history.push("/App")
    }

    AlarmRequest() {
      const {UserInfo} = this.props
      if(UserInfo == null) {
          console.log('some input are missing! googlemap.js AlarmRequest()')
          return
      }
      // create start end Datetime String, UserID
      let EmitUserID = UserInfo.userID
      let startDate = new Date()
      startDate.setMonth(startDate.getMonth()-1)
      this.setState({
          startdate: startDate      //7 days before today
      })
      let EndDate = new Date()
      // emitJson format
      let requestData = {
        "msgType": "AlarmList",
        //"socketId": "8SpxJb0PqsT0zcboAAEa",
        "userID": EmitUserID,
        "timeConstraint": {
              "start": startDate.toString(),
              "end": EndDate.toString()
        }
      }
      // emit socket (request for alarm when this page is onload)
      this.props.dispatch({ type: 'EmitSocket', 
        EmitSocketName: "AlarmRequest",
        EmitSocketData: requestData
      })
    }

    render() {
      const {currentSite, UserInfo, configStorage} = this.props    //array count integer for which site have been picked
      if(currentSite == null || UserInfo == null || UserInfo.locations == null || UserInfo.locations.length == null || UserInfo.locations.length == 0) return <div>Loading</div>
      const pickedSite = UserInfo.locations[currentSite]
      const pickedSiteName = pickedSite.locName
      const pickedSiteUrl = pickedSite.imgUrl
      const ImageUrl = 'url("' + configStorage.imgUrl + "/assets/images/floors/" + pickedSiteUrl + "/default.jpg" + '")'

      let DarkStyle = []
      let NormalStyle = []
      //try load from setting json,
      try {
        const {configStorage} = this.props
        NormalStyle = configStorage.GoogleMapNormal
        DarkStyle = configStorage.GoogleMapDark
      }
      catch(err) {
        console.error('load config data failed in googlemap.js (if using npm run start, that is normal)')
      }
      //* if failed, would use the above hardcodestyle
      let mapOption
      let MapStyle
      let DarkMode = localStorage.getItem('DarkMode')
      if(DarkMode == "true") MapStyle = DarkStyle
      else MapStyle =  NormalStyle
      mapOption = {
        panControl: false,
        mapTypeControl: false,
        scrollwheel: true,
        styles: MapStyle,
        minZoom: 11,
        maxZoom: 19,
        zoomControl: false,
        scaleControl: false,
        fullscreenControl: false,
        clickableIcons: false
      }

      const permission = UserInfo.permissions
      if(permission == null || permission.length == null) return <div>Wrong Permission Data in UserInfo</div>
      const StatisticPermission = permission.find(function(permissionItem) {
          return permissionItem.collectionType == "Statistics"
      })

        if(this.props.UserInfo == null || this.props.UserInfo == []) return <div>loading</div>
        else {
          let {BuildingX} = this.state;
          let SiteDetailClass = "";
          if(BuildingX <= 100) {
            SiteDetailClass = "hideSiteDetailClass"
          }
          return (        
              <div className="googlemap_main_div">
                  <div className="googlemap_stylingdiv">
                  </div>
                  <div className="googlemap_stylingdivBot">
                  </div>
                  <motion.div className="google_map_detail" style={{backgroundImage: ImageUrl, backgroundPosition: 'center center no-repeat', backgroundSize: 'cover',}}>
                      <div className="google_map_title">
                        <div className="fa fa-map-marker dettail_locationIcon"></div>
                        {GetSiteName(pickedSite)}
                      </div>
                      <div className="google_map_btmRow">
                        {(StatisticPermission == null)?
                          <div className="google_map_btn_Statistics nopermission" onClick={this.ViewSummaryDetail}>{getTxt('Main', 'stat')}</div>
                          :
                          <div className="google_map_btn_Statistics" onClick={this.ViewSummaryDetail}>{getTxt('Main', 'stat')}</div>
                        }
                        {/* <div className="google_map_btn_Statistics" onClick={this.ViewSummaryDetail}>Statistics</div> */}
                        <div className="google_map_btn_Dashboard" onClick={this.ViewBuildingSiteDetail}>{getTxt('Main', 'dash')}</div>
                      </div>
                  </motion.div>
                  <div className="googleMap_centerBtn" onClick={this.MapCenter}>
                      <div className="fa fa-location-arrow"></div>
                      <div>{getTxt('Main', 'center')}</div>
                  </div>
                  <GoogleMapReact
                  bootstrapURLKeys={{ key: this.state.GoogleMapKey}}//"AIzaSyCl3ll-KmzZov_bFw6L2KTMOOxLd4wKY9g" }}//"AIzaSyBX6Vqy4Z1SEfsyRDsTO6IF3IMFHesZHO8"}}
                  //defaultCenter={this.props.center}
                  onChange={this.Map_onChange}
                  initialCenter={this.state.center}
                  center={this.state.center}
                  defaultZoom={this.props.zoom}
                  options={mapOption}
                  >
                      {
                          this.props.UserInfo.locations.map((data, i) => {
                            return <div lat={data.coor.coordinates[0]} lng={data.coor.coordinates[1]} className="GoogleMap_Marker" onMouseEnter={() => this.pickSite(data)} id={"Google_location_Onclicked_" + data.locIdx}>
                                <div class={(i==currentSite)?"pin highlight":"pin"} onMouseEnter={() => this.pickSite(data)}></div>
                                <div class={(i==currentSite)?"pulse pulsehighlight":"pulse"} onMouseEnter={() => this.pickSite(data)}></div>
                              </div>
                          })
                      }
                  </GoogleMapReact>
              </div>
          )
        }
    }
}

function mapStateToProps(state) {
    return {
      socket: state.socket,
      level: state.level,
      currentlevel: state.currentlevel,
      SiteList: state.SiteList,
      currentSite: state.currentSite,
      MenuDisplay: state.MenuDisplay,
      UserInfo: state.UserInfo,
      configStorage: state.configStorage,
      SystemAlerts: state.SystemAlerts,    //get the current system alarm inside
      Notification: state.Notification,    //Notification List, for counting the new and ack alarm for each site
      DeviceDataLastUpdate: state.DeviceDataLastUpdate,
    }
}
export default connect(mapStateToProps)(GoogleMap)