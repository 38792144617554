export default function GetGroupValue(getGroupData, groupDisplayData) {
    if(getGroupData==null) return    
    const {DeviceData, rowItem, selectedGroup} = getGroupData

    const grouplist = GetDataList(DeviceData, rowItem, selectedGroup)
 
    const payloadKey = GetKey[rowItem.key]
    const payloadType = rowItem.type

    let FinalDataReturn = GetDataRange(grouplist, payloadKey, payloadType, rowItem)

    let editingValue = groupDisplayData==null?null:groupDisplayData[rowItem.key]
    if(editingValue==null) {
        if(FinalDataReturn.type == 'Number') {
            // console.log(payloadKey)
            if(payloadKey=='TempHighSetPoint'||payloadKey=='CondensingPressureWarningHighSetPoint'||
                payloadKey=='RHWarningHighSetPoint'||payloadKey=='SuctionTempWarningHighSetPoint') editingValue = FinalDataReturn.max
            else editingValue = FinalDataReturn.min
        }
        else editingValue = FinalDataReturn.data==null?0:FinalDataReturn.data[0]
    }
    FinalDataReturn.input = editingValue

    return FinalDataReturn
}

// Step 1 key list
const GetKey = {
    "systemStart": "SystemStart", //SystemEvent?
    "status": "Status",
    "temp": "TempSetPoint",
    "temp1Factor": "WeightedFactorTemp1",
    "temp2Factor": "WeightedFactorTemp2",
    "temp3Factor": "WeightedFactorTemp3",
    "temp4Factor": "WeightedFactorTemp4",
    "temp5Factor": "WeightedFactorTemp5",
    "returnAirFactor": "WeightedFactorReturnAirTemp",		//ReturnAirTemp?
    "highTemp": "TempHighSetPoint",
    "lowTemp": "TempLowSetPoint",
    "HCondPress": "CondensingPressureWarningHighSetPoint",
    "LCondPress": "EvaporatingPressureWarningLowSetpoint",
    "HRH": "RHWarningHighSetPoint",
    "LRH": "RHWarningLowSetPoint",
    "HSuctionTemp": "SuctionTempWarningHighSetPoint",
}
// Step 2
function GetDataList(DeviceData, rowItem, selectedGroup) {
    if(DeviceData==null||rowItem==null||selectedGroup==null) return

    const groupMemberList = selectedGroup.members
    if(groupMemberList==null || groupMemberList.length==null) return

    let relatedGpList = []
    DeviceData.forEach(siteItem => {
        siteItem.nestedLocs.forEach(lvlItem => {
            lvlItem.nestedLocs.forEach(locItem => {
                let ifInGpMemList = groupMemberList.find(item => {
                    return item.locName==locItem.locName
                })
                if(ifInGpMemList!=null) relatedGpList.push(locItem)
            })
        })
    })

    return relatedGpList
}
// Step 3
function GetDataRange(grouplist, payloadKey, payloadType, rowItem) {
    if(grouplist==null||grouplist==undefined) return {
        type: payloadType=='select'?'Select':'Number'
    }
    const dataList = grouplist.map(item => item.payload[payloadKey])

    if(payloadType=='Number') {
        if(dataList==null||dataList.length==null||dataList[0]==null||dataList[0]==undefined) return {
            type: 'Number',
            max: 0,
            min: 0
        }
        return {
            type: 'Number',
            max: Math.max(...dataList),
            min: Math.min(...dataList)
        }
    }
    if(payloadType=='select') {
        const display = rowItem.display
        if(dataList==null||dataList.length==null||dataList[0]==null||dataList[0]==undefined) return {
            type: 'Select',
            data: []
        }

        let ExistItems = []
        display.forEach(displayItem => {
            let isAnyThisType = dataList.find(item => {
                if(displayItem.value=='0'&&item==false) return true      //boolean
                if(displayItem.value=='1'&&item==true) return true       //boolean
                return item.toString() == displayItem.value                         //Number string or Number
            })
            if(isAnyThisType!=null) ExistItems.push(displayItem)
        })

        return {
            type: 'Select',
            data: ExistItems
        }
    }
}