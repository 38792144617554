import React from 'react'
import { connect } from 'react-redux'

class SetPoint extends React.Component {
    constructor(props) {
        super()
        this.renderRow = this.renderRow.bind(this)
        this.groupRow = this.groupRow.bind(this)
        this.state = {
            isLoading: false,
            payloadData: {},
        };
    }

    componentDidUpdate(prevProps) {
        const preDeviceDataLastUpdate = prevProps.DeviceDataLastUpdate
        const {DeviceDataLastUpdate} = this.props
        if(preDeviceDataLastUpdate==DeviceDataLastUpdate&&prevProps.Item==this.props.Item) return

        const {Item} = this.props
        if(Item==null||Item.payload==null) return

        this.setState({
            isLoading: false,
            payloadData: Item.payload
        })
    }

    renderRow(title, value, unit) {
        return (
            <div className="row">
                <div className="readingTitle">
                    {title}
                </div>
                <div className="reading">
                    {value} {(unit === "C" ? <sup>o</sup> : unit)}{(unit === "C" ? unit : "")}
                </div>
            </div>
        )
    }
    groupRow(title, data, unit, isBool, trueDisplay, falseDisplay) {
        const isNumber = !isBool
        const isAllSame = isNumber?(data.min==data.max):false
        let valueDisplay = '--'
        if(isAllSame && isNumber) valueDisplay = data.min
        if(!isAllSame && isNumber) {
            if(data.min!=null&&data.min.toString().includes('Infinity')) valueDisplay = '--'
            else valueDisplay = data.min + ' - ' + data.max
        }
        if(!isNumber) {
            if(data == 1) valueDisplay = trueDisplay
            if(data == 2 || data == 0 || data == undefined || data == null) valueDisplay = falseDisplay
            if(data == 3) valueDisplay = trueDisplay + ' & ' + falseDisplay
        }
        return <div className="row">
            <div className="readingTitle">
                {title}
            </div>
            <div className="reading">
                {valueDisplay} {(unit==="C"?<sup>o</sup>:unit)}{(unit==="C"?unit:"")}
            </div>
        </div>
    }

    render() {
        const {groupDisplay, isGpDisplay} = this.props
        const {payloadData} = this.state

        if(isGpDisplay) {
            return <fieldset>
                <legend>Alarm SetPoint</legend> 
                <div className="Coldbox_S_table">
                    <div className="col_alarm_set_point">
                        {this.groupRow("Temperature", groupDisplay.TempSetPoint, "C", false)}
                        {this.groupRow("High Temperature Warning", groupDisplay.TempHighSetPoint, "C", false)}
                        {this.groupRow("Low Temperature Warning", groupDisplay.TempLowSetPoint, "C", false)}
                        {this.groupRow("High Suction Temperature Warning", groupDisplay.SuctionTempWarningHighSetPoint, "C", false)}
                        {this.groupRow("High RH Warning", groupDisplay.RHWarningHighSetPoint, "%", false)}
                        {this.groupRow("Low RH Warning", groupDisplay.RHWarningLowSetPoint, "%", false)}
                        {this.groupRow("High Condensing Pressure Warning", groupDisplay.CondensingPressureWarningHighSetPoint, "Bar", false)}
                        {this.groupRow("Low Evaporating Pressure Warning", groupDisplay.EvaporatingPressureWarningLowSetpoint, "Bar", false)}
                    </div>
                </div>
            </fieldset>
        }
        return <fieldset>
            <legend>Alarm SetPoint</legend> 
            <div className="Coldbox_S_table">
                <div className="col_alarm_set_point">
                    {this.renderRow("Temperature",payloadData.TempSetPoint, "C")}
                    {this.renderRow("High Temperature Warning",payloadData.TempHighSetPoint, "C")}
                    {this.renderRow("Low Temperature Warning",payloadData.TempLowSetPoint, "C")}
                    {this.renderRow("High Suction Temperature Warning",payloadData.SuctionTempWarningHighSetPoint, "C")}
                    {this.renderRow("High RH Warning",payloadData.RHWarningHighSetPoint, "%")}
                    {this.renderRow("Low RH Warning",payloadData.RHWarningLowSetPoint, "%")}
                    {this.renderRow("High Condensing Pressure Warning",payloadData.CondensingPressureWarningHighSetPoint, "Bar")}
                    {this.renderRow("Low Evaporating Pressure Warning",payloadData.EvaporatingPressureWarningLowSetpoint, "Bar")}
                </div>
            </div>
        </fieldset>
    }
}

function mapStateToProps(state) {
    return {
        currentlevel: state.currentlevel,
        currentSite: state.currentSite,
        UserInfo: state.UserInfo,
        DeviceData: state.DeviceData,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate,
        DashboardPickedColdboxID: state.DashboardPickedColdboxID
    }
}

export default connect(mapStateToProps)(SetPoint)