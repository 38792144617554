import React from 'react'
import './SearchBar.css'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { Checkbox } from '@material-ui/core'

class Searchbar extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            startdate: new Date(moment().subtract(30, 'days')),
            enddate: new Date(),
            coldBoxID: '',
            //option list
            typeList: ['Dynamic', 'Periodic', 'Manual'],
            partList: ['All'],
            filterType: '',
            filterPart: '',
            //filter (txt1 or txt2) and (txt3 or txt4)
            filterTxt1: '',
            filterTxt2: '',
            filterTxt3: '',
            filterTxt4: '',
            //Filter Result
            Filtered: null,
            //checkbox
            isActive: true,
            isPending: true,
            isCompleted: true,
            isWaiting: true,
        }

        this.GenOptions = this.GenOptions.bind(this)
        this.FilterOnchange = this.FilterOnchange.bind(this)
        this.Filter = this.Filter.bind(this)
        this.setStartTime = this.setStartTime.bind(this)
        this.setEndTime = this.setEndTime.bind(this)
        this.FilterCheckBox = this.FilterCheckBox.bind(this)
    }
    componentDidMount() {
        var { workOrderList } = this.props
        if (workOrderList == null || workOrderList == undefined) return
        this.GenOptions()
    }
    componentDidUpdate(preProps) {
        //Re-gen option list when data is updated
        var { workOrderList } = this.props
        var oldworkOrderList = preProps.workOrderList
        if (workOrderList != oldworkOrderList || (workOrderList != null && oldworkOrderList == null)) this.GenOptions()
    }
    GenOptions() {
        const { workOrderList } = this.props
        if (workOrderList === null || workOrderList === undefined) {
            return
        } else {
            var partList = []
            workOrderList.forEach(wo => {
                wo.parts.forEach(p => {
                    partList.push(p)
                })
            });
            // console.log(Options)

            //Group List
            this.setState({
                partList: Array.from(new Set(partList)).sort(),
            })
            this.Filter()
        }
        this.Filter()
    }
    FilterOnchange(keyStr, e) {
        try {
            const value = e.target.value.toString()
            this.setState({
                [keyStr]: value,
            }, function () {        //run right after state is set
                this.Filter()       //re-filter
            })

        }
        catch (err) {
            console.log(err)
        }
    }

    FilterCheckBox = (key,v) => {
        try {
            this.setState({
                [key]:v
            },function () {        //run right after state is set
                this.Filter()       //re-filter
            })
        } catch (err) {
            console.log(err)
        }
    }

    Filter() {
        const { coldBoxID, filterPart, filterType, isActive, isPending, isCompleted, isWaiting, filterTxt1, filterTxt2, filterTxt3, filterTxt4 } = this.state
        const { workOrderList } = this.props

        var FilteredList = workOrderList
        if (coldBoxID != null && coldBoxID != "") {
            FilteredList = FilteredList.filter(function (coldboxItem) {
                return coldboxItem.name.includes(coldBoxID.toString())
            })
        }
        if (filterPart != null && filterPart != "") {
            FilteredList = FilteredList.filter(function (coldboxItem) {
                return coldboxItem.parts.includes(filterPart.toString())
            })
        }
        if (filterType != null && filterType != "") {
            FilteredList = FilteredList.filter(function (coldboxItem) {
                return coldboxItem.workOrderType == filterType.toString()
            })
        }
        if (!isActive) {
            FilteredList = FilteredList.filter(function (coldboxItem) {
                return coldboxItem.workOrder_Status.toString() != "Active"
            })
        }
        if (!isPending) {
            FilteredList = FilteredList.filter(function (coldboxItem) {
                return coldboxItem.workOrder_Status.toString() != "Pending"
            })
        }
        if (!isCompleted) {
            FilteredList = FilteredList.filter(function (coldboxItem) {
                return coldboxItem.workOrder_Status.toString() != "Completed"
            })
        }
        if (!isWaiting) {
            FilteredList = FilteredList.filter(function (coldboxItem) {
                return !coldboxItem.workOrder_Status.toString().includes("Waiting")
            })
        }
        var flag1 = filterTxt1 != null && filterTxt1 != ""
        console.log(flag1)
        // var flag2 = filterTxt2 != null && filterTxt2 != ""
        if (flag1) {
            FilteredList = FilteredList.filter(function (coldboxItem) {
                console.log(flag1, coldboxItem)
                return (flag1 && (coldboxItem.requestor.toString().toUpperCase().includes(filterTxt1.toString().toUpperCase()) || coldboxItem.orderNo.toString().toUpperCase().includes(filterTxt1.toString().toUpperCase())))
            })
        }
        // flag1 = filterTxt3 != null && filterTxt3 != ""
        // flag2 = filterTxt4 != null && filterTxt4 != ""
        // if (flag1 || flag2) {
        //     FilteredList = FilteredList.filter(function (coldboxItem) {
        //         return (flag1 && coldboxItem.status.toString().includes(filterTxt3.toString()) || flag2 && coldboxItem.status.toString().includes(filterTxt4.toString()))
        //     })
        // }

        this.setState({
            Filtered: FilteredList
        })
        this.props.UpdateList('filteredList', FilteredList)
    }


    setStartTime(data) {
        this.setState({ startdate: data })
        this.props.setStartTime(data)
    }

    setEndTime(data) {
        this.setState({ enddate: data })
        this.props.setEndTime(data)
    }

    render() {
        const _this = this
        const { isAdvanceSearch } = this.props

        const { typeList, partList, isActive, isPending, isCompleted, isWaiting } = this.state

        return <div className='PlannerSearch_Main'>
            <div className='PlannerSearch_Block'>
                <div className="PlannerSearch_left">
                    <div className="PlannerSearch_txt">From:</div>
                    <div className="PLANNERDatepicker">
                        <DatePicker
                            selected={this.state.startdate}
                            onChange={this.setStartTime}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="d-MMMM yyyy HH:mm"
                            timeCaption="time"
                            className='cdbxEPicker'
                        />
                    </div>
                    <div className="PlannerSearch_txt">To:</div>
                    <div className="PLANNERDatepicker">
                        <DatePicker
                            selected={this.state.enddate}
                            onChange={this.setEndTime}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="d-MMMM yyyy HH:mm"
                            timeCaption="time"
                            className='cdbxEPicker'
                        />
                    </div>{
                        isAdvanceSearch ?
                            <div className="PlannerSearch_adSearchBtn" onClick={_this.props.closeAdSearch}>
                                <div className="fa fa-times"></div>
                            </div> :
                            <div className="PlannerSearch_adSearchBtn" key="PLANNERsearchBtn" onClick={_this.props.AdvanceSearch}>Advanced Search</div>
                    }
                    <div className="PlannerSearch_adSearchBtn" onClick={_this.props.refresh}>
                        <div className="fa fa-refresh"/>
                    </div>
                </div>
                {
                    isAdvanceSearch ?
                        <div className="PlannerSearch_hiddenSearches">
                            <div className="PlannerSearch_txt">Coldbox ID:</div>
                            <input className="PlannerSearch_input" placeholder="Coldbox ID" onChange={(e) => _this.FilterOnchange('coldBoxID', e)}></input>
                            <div className="PlannerSearch_txt">Type:</div>
                            <select className="PlannerSearch_select" onChange={(e) => _this.FilterOnchange('filterType', e)}>
                                <option value="">All</option>
                                {
                                    (typeList == null || typeList.length == null || typeList.length == 0) ?
                                        <option style={{ display: 'none' }}></option>
                                        :
                                        typeList.map(function (typeItem) {
                                            return <option value={typeItem} >{typeItem}</option>
                                        })
                                }
                            </select>
                            <div className="PlannerSearch_txt">Parts:</div>
                            <select className="PlannerSearch_select" onChange={(e) => _this.FilterOnchange('filterPart', e)}>
                                <option value="">All</option>
                                {
                                    (partList == null || partList.length == null || partList.length == 0) ?
                                        <option style={{ display: 'none' }}></option>
                                        :
                                        partList.map(function (partItem) {
                                            return <option value={partItem}>{partItem}</option>
                                        })
                                }
                            </select>
                        </div>
                        : <div />
                }
            </div>
            {isAdvanceSearch ?
                <div className='PlannerSearch_Block'>
                    <div className='PlannerSearch_middle'>
                        <div className='PlannerSearch_txt'>Active</div>
                        <Checkbox checked={isActive} onClick={()=>this.FilterCheckBox("isActive",!isActive)} />
                    </div>
                    <div className='PlannerSearch_middle'>
                        <div className='PlannerSearch_txt'>Complete</div>
                        <Checkbox checked={isCompleted} onClick={() => this.FilterCheckBox("isCompleted", !isCompleted)} />
                    </div>
                </div>
                : <div />
            }
            {isAdvanceSearch ?
                <div className='PlannerSearch_Block'>
                    <div className='PlannerSearch_middle'>
                        <div className='PlannerSearch_txt'>Pending</div>
                        <Checkbox checked={isPending} onClick={()=>this.FilterCheckBox("isPending",!isPending)} />
                    </div>
                    <div className='PlannerSearch_middle'>
                        <div className='PlannerSearch_txt'>Waiting</div>
                        <Checkbox checked={isWaiting} onClick={() => this.FilterCheckBox("isWaiting", !isWaiting)} />
                    </div>
                </div>
                : <div />
            }
            {isAdvanceSearch ?
                <div className='PlannerSearch_combine_title'>Keyword Search:</div>
                : <div />
            }
            {isAdvanceSearch ?
                <div className='PlannerSearch_Block'>
                    <div className='PlannerSearch_combine_line'>
                        <input className="PlannerSearch_input" placeholder="Keyword search" onChange={(e) => _this.FilterOnchange('filterTxt1', e)}></input>
                    </div>
                </div>
                : <div />
            }
        </div>
    }
}

function mapStateToProps(state) {
    return {

        UserInfo: state.UserInfo,
        currentSite: state.currentSite,
    }
}
export default connect(mapStateToProps)(Searchbar)