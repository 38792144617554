import React from 'react'
import './TableHead.css'
//Redux Import
import { connect } from 'react-redux'


function TableHead(props) {

    return <div className="PRD_Table">
        <div className={"col2"}>
            Date & Time
            {
                <div style={{ display: 'none' }}></div>
            }
        </div>
        <div className={"col2"}>
            W.O. Number
            {
                <div style={{ display: 'none' }}></div>
            }
        </div>
        <div className={"col2"}>
            Down TIme
            {
                <div style={{ display: 'none' }}></div>
            }
        </div>
        <div className={"col2"}>
            Replaced/Repaired Paths
            {
                <div style={{ display: 'none' }}></div>
            }
        </div>
        <div className={"col2"}>
            Fault
            {
                <div style={{ display: 'none' }}></div>
            }
        </div>
        <div className={"col3"}>
            W.O. Type
            {
                <div style={{ display: 'none' }}></div>
            }
        </div>
    </div>
}

//Redux inside component function
function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps)(TableHead)