import './LiftRuntime.css';
import React from 'react';
//Redux Import
import { connect } from 'react-redux'

import Moment from 'react-moment'

//Import smallest widgets
import HeaderWidget from '../common/head/head'
import ButtonWidget from '../common/searchbtn/searchbtn'
// import DroplistWidget from '../common/droplist/droplist'
// import PageButtonsWidget from '../common/pagebtn/pagebtn'
import DatePickWidget from '../common/datepick/datepick'
import LoadingWidget from '../common/loading/loading'
import ExportExcelWidget from '../common/ExportExcel/ExportExcel'
import LiftSelectWidget from '../common/LiftSelect/LiftSelect'
import LineChartbtnWidget from '../common/LineChart/LineChartbtn'
import LineChartWidget from '../common/LineChart/LineChart'

import convertDate from '../common/convertDate'
import GetLocList from '../common/getLocList'
import getMonthList from '../MonthSummary/getMonthList'
import genDataArray from '../MonthSummary/genDataArray'

class LiftRuntimeSummary extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            startDate: null,
            endDate: null,
            LiftList: [], 
            ShowChart: false,
        }

        this.onChangeFunction = this.onChangeFunction.bind(this)
        // this.ClickPageBtn = this.ClickPageBtn.bind(this)
        this.ChangeDate = this.ChangeDate.bind(this)
        this.SetShowChart = this.SetShowChart.bind(this)

        this.RequestData = this.RequestData.bind(this)
    }

    SetShowChart(boolValue) {
        this.setState({
            ShowChart: boolValue
        })
    }
    onChangeFunction(SelectedLiftList) {
        const LiftList = SelectedLiftList.map((item) => {
            return item._id
        })
        console.log(LiftList)
        this.setState({
            LiftList: LiftList
        })
    }
    // ClickPageBtn(page) {
        
    // }
    ChangeDate(ID, DateInput) {
        var newDate = DateInput
        this.setState({
            [ID]: newDate
        })
    }
    componentDidMount() {
        //emit request to get data
        const _this = this
        setTimeout(function() {
            _this.RequestData()
        }, 100)
    }
    RequestData() {
        const {UserInfo, currentlevelID, StatisticData} = this.props

        //clear redux
        this.props.dispatch({ type: 'DeviceStatistic', 
            data: {
                co2: StatisticData.co2,
                pm25: StatisticData.pm25,
                temp: StatisticData.temp,
                humid: StatisticData.humid,
                power: StatisticData.power,
                illuminance: StatisticData.illuminance,
                loading: false,
                lift: StatisticData.lift,
                mileageTravelled: StatisticData.mileageTravelled,
                powerConsumed: StatisticData.powerConsumed,
                runtimeTravelled: null,
                doorOpenCount: StatisticData.doorOpenCount,
                liftStartupCount: StatisticData.liftStartupCount,
                liftDirectionReverseCount: StatisticData.liftDirectionReverseCount,
            },
        })

        //request
        const {startDate, endDate, LiftList} = this.state

        if(UserInfo == null) return
        if(startDate == null || endDate == null) return

        //get data to emit
        const clientID = UserInfo.client_id
        let finalendDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 1)

        const EmitJson = {
            "msgType": "LocationStatistics",
            "clientID": clientID,
            "payload_field": ["runtimeTravelled"],
            "timeConstraint": {
              "start": convertDate(startDate),
              "end": convertDate(finalendDate),
            },
            "timeFrequency": {
              "unit": "month",
              "value": 1
            },
            "locationConstraint": {
              "locLvl": 3,
              "id": currentlevelID  //LiftList  //..
            },
            "locationGrouping": 3,
            "calculationMethod": "sum"
        }
        console.log(EmitJson)

        //emit socket
        this.props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "StatRequest",
            EmitSocketData: EmitJson
        })
    }

    render() {
        const {StatisticData, DeviceData} = this.props
        const {ShowChart, startDate, endDate} = this.state
        const MonthList = getMonthList(startDate, endDate)
        
        const DisplayList = (StatisticData == null || StatisticData.runtimeTravelled == null || StatisticData.runtimeTravelled.length == null)?
        <LoadingWidget />
        :
            (StatisticData.runtimeTravelled.length == 0)?
            <div className="LiftRuntime_row" key="No Data Widget">No Data</div>
            :
            genDataArray(MonthList, StatisticData, 'runtimeTravelled').map(rowItem => {
                return <div className="LiftSummary_row">
                    <div className="LiftSummary_col">
                        {rowItem.locationName}
                    </div>
                    <div className="LiftSummary_col">
                        {rowItem.runtimeTravelled==null?'No Data':timeConvert(rowItem.runtimeTravelled)}
                    </div>
                    <div className="LiftSummary_col">
                        {rowItem.timeStr}
                    </div>
                </div>
            })

        return <div className="LiftRuntime">
            <HeaderWidget Title="Monthly Runtime Report" Icon="file" />
            <div className="LiftRuntime_search">
                <div className="LiftRuntime_searchTitle">
                    Months:
                </div>
                <DatePickWidget holderTxt="Start Date" DefaultDate="PreMonth" ID="startDate" ChangeDate={this.ChangeDate} />
                <DatePickWidget holderTxt="End Date" DefaultDate="Today" ID="endDate" ChangeDate={this.ChangeDate} />
                {/* <LiftSelectWidget onChangeFunction={this.onChangeFunction} DeviceData={DeviceData} /> */}
                <ButtonWidget Icon="search" ClickFunction={this.RequestData} />
                <ExportExcelWidget header="Runtime" unit="Hr(s)" keystr="runtimeTravelled" Data={StatisticData} />
                <LineChartbtnWidget SetShowChart={this.SetShowChart} />
            </div>
            {
                ShowChart?
                <LineChartWidget ChartData={StatisticData.runtimeTravelled} title="Hr(s)" keystr="runtimeTravelled" />
                :
                [
                    <div className="LiftStartup_rowheader">
                        <div className="LiftStartup_col">
                            Lift Name
                        </div>
                        <div className="LiftStartup_col">
                            Runtime Travelled
                        </div>
                        <div className="LiftStartup_col">
                            Month
                        </div>
                    </div>,
                    <div className="LiftRuntime_table1_Container">
                        {DisplayList}
                    </div>
                ]
            }
        </div>
    }
}

function mapStateToProps(state) {
    return {
      socket: state.socket,
      UserInfo: state.UserInfo,
      currentlevel: state.currentlevel,
      currentSite: state.currentSite,
      DeviceData: state.DeviceData,
      currentlevelID: state.currentlevelID,
      StatisticData: state.StatisticData,
      DeviceDataLastUpdate: state.DeviceDataLastUpdate,
    };
}
export default connect(mapStateToProps)(LiftRuntimeSummary)


const timeConvert = (n) => {
    var hours = n
    var rhours = Math.floor(hours/60)
    var minutes = (hours - rhours*60)
    var rminutes = Math.round(minutes)
    return fillZero(rhours) + ':' + fillZero(rminutes)
}
const fillZero  = (number) => {
    if(number < 10) return '0' + number
    return number
}