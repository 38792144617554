
import React from 'react'
import './create.css'

//Redux Import
import { connect } from 'react-redux'

class Create extends React.Component {
    constructor(props)
    {
        super()
        this.state = {
            group_name:""
        }
        this.sendGroupReq = this.sendGroupReq.bind(this)
        this.ClosePopup = this.ClosePopup.bind(this)
        this.handleInputChage = this.handleInputChage.bind(this)
        this.submit = this.submit.bind(this)
    } 

    handleInputChage(event) {
        // console.log('handleInputChage()');
        // console.log({[event.target.name]: event.target.value});

        this.setState({
            [event.target.name]: event.target.value
        })
    }
    
    ClosePopup() {
        const { closePopup } = this.props
        console.log("closePopup1")
        closePopup()
    }

    submit(){
        this.sendGroupReq()
    }

    sendGroupReq() {
        const { UserInfo, GroupList } = this.props
        const {group_name} = this.state 
        if (UserInfo == null) return
        const EmitUserID = UserInfo.userID
        //request json 
        let requestData = {}
        requestData = {
            "msgType": "CreateGroup",
            "userID": EmitUserID,
            "client_id": UserInfo.client_id, 
            "members" : [{}],
            group_name:group_name
        }

        //send with redux socket function
        this.props.dispatch({
            type: 'EmitSocket',
            EmitSocketName: "GroupManageRequest",
            EmitSocketData: requestData
        })

        this.ClosePopup()
    }

    render()
    {
        
        const _this = this
        const {isPopup,  isEdit, isDelete} = this.props     //send from parent
        const {group_name} = this.state 

        return (isPopup && !isEdit && !isDelete)?
        <div className="Coldbox_create_group_PopupMain">
            <div className="Coldbox_group_PopupTitle">Create Group</div>
            <div className="Coldbox_group_PopupcloseBtn" onClick={_this.props.closePopup }>
                <div className="fa fa-times"></div>
            </div>
            <input className="Coldbox_create_group_Popup_input" placeholder="Group Name" name="group_name"
             onChange={this.handleInputChage}  
             value={group_name === undefined || group_name == null? "" : group_name}></input>
            <div className="Coldbox_group_Popup_Btns">
                <div className="Coldbox_group_Popup_SubmitBtns" onClick={this.submit}>Submit</div>
                <div className="Coldbox_group_Popup_CancelBtns" onClick={_this.ClosePopup}>Cancel</div>
            </div>
        </div>
        :
        <div style={{display: 'none'}}></div>
    }
}

function mapStateToProps(state) {
    return {
        socket: state.socket,
        currentlevel: state.currentlevel,
        currentSite: state.currentSite,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate, //when this value is updated, render would triggered
        StatisticData: state.StatisticData,
        UserInfo: state.UserInfo,
        currentlevelID: state.currentlevelID,
        GroupList:state.GroupList
    }
}
  
export default connect(mapStateToProps)(Create)