export default function genReqJson(props, start, end) {        //main func
    if(props==null) return
    const {UserInfo} = props
    if(UserInfo==null) return

    const {startStr, endStr} = genStartEndTimeStr(start, end)
    return {
        "msgType": "AlarmList",
        "userID": UserInfo.userID,
        "timeConstraint": {
              "start": startStr,
              "end": endStr,
        }
    }
}
//sub func
const genStartEndTimeStr = (start, end) => {
    if(start==null || end==null || start=='' || end=='') return genDefaultDateRng()
    return {
        startStr: datetimeFormatStr(start),
        endStr: datetimeFormatStr(end)
    }
}
const genDefaultDateRng = () => {
    const end = new Date()
    let start = new Date()
    start.setDate(start.getDate() - 7)

    return {
        startStr: datetimeFormatStr(start),
        endStr: datetimeFormatStr(end)
    }
}
const datetimeFormatStr = (dateTimeInput) => {
    if(dateTimeInput==null || dateTimeInput=='') return 'Null DateTime'
    let strItem = dateTimeInput.toString()
    let DTItem = new Date(strItem)

    const year = DTItem.getFullYear()
    const month = DTItem.getMonth() + 1
    const date = DTItem.getDate()
    const hrs = '16'  //DTItem.getHours()
    const mins = '00' //DTItem.getMinutes()
    const secs = '00' //DTItem.getSeconds()
    const formattedStr = year + '-' + fillZero(month) + '-' + fillZero(date) + ' ' + hrs + ':' + mins + ':' + secs
    return formattedStr
}
function fillZero(num) {
    if(num < 10) return '0' + num
    return num.toString()
}