import React, { createRef } from 'react'
import './popup.css'

//Redux Import
import { connect } from 'react-redux'

class coldbox_popup extends React.Component {
    constructor(props) {
        super()
        this.state = {
            UniqueID: null,
            selectedTerminal: "", 
            selectedZone: "", 
            selectedZoneIdx: "",
            TerminalList:[], 
            ZoneList:[]
        }
        this.handleInputChage = this.handleInputChage.bind(this)

        this.UniqueID = createRef()
        this.submitRecord = this.submitRecord.bind(this)
        this.closePopup = this.closePopup.bind(this)
        this.PickTerminal = this.PickTerminal.bind(this)
        this.PickZone = this.PickZone.bind(this)

        this.stateUpdate = this.stateUpdate.bind(this)
    }

    PickZone(event) {
        const {ZoneList} = this.props
        const zlItem = ZoneList.find(function(element) {
            return element.id == event.target.value
        })
        this.setState({
            selectedZoneIdx: event.target.value, 
            selectedZone:zlItem
        });
    }


    PickTerminal(event) {
        this.setState({
            selectedTerminal: this.state.TerminalList[event.target.value],
            // ZoneList:this.state.TerminalList[event.target.value].nestedLocs
        });
    }

    closePopup() {
        this.setState({
            UniqueID: null
        })
        this.props.closePopup()
    }

    componentDidMount(){        
        const _this = this
        const {  DeviceData,  currentSite} = this.props
        // console.log( this.state)      
        let tempList = []
        let final = []
        let item1 = {
            locName:"--- Terminal ---"
        }
        tempList.push(item1) 
        if (DeviceData !== undefined && DeviceData !== null){
            if (DeviceData.length > 0){ 
                let sites = DeviceData[currentSite]
                if(sites !== undefined && sites !== null){
                    if(sites.nestedLocs !== null && sites.nestedLocs !== undefined ) {
                        final=  tempList.concat(sites.nestedLocs)
                    }
                }
            }
        } 
        this.setState({
            TerminalList: final
        })

        this.initZoneList()
    }

    initZoneList(){
        // console.log('initZoneList');
        const {  ZoneList} = this.props
        // console.log(ZoneList);
        let tempList = []
        let final = []
        // let item1 = {
        //     id:0,
        //     name:"--- Zone ---"
        // }
        // tempList.push(item1) 
        if (ZoneList !== undefined && ZoneList !== null){
            if (ZoneList.length > 0){ 
                final=  tempList.concat(ZoneList)

            }

        } 
        // console.log(final);
        this.setState({
            ZoneList: final
        });
    }

    componentDidUpdate(preProps) {
        const { data, isPopup } = this.props
        const preData = preProps.data
        const preIsPopup = preProps.isPopup
        if(data == preData && isPopup == preIsPopup) return

        this.stateUpdate()
    }
    stateUpdate() {
        console.log('stateUpdate()')
        console.log(this.state)
        console.log(this.props)

        const _this = this
        const { data } = this.props
        const { ZoneList } = this.state

        if(_this.state == null || _this.state == undefined) return

        if (data !== null && data !== undefined) {
            if (_this.state.UniqueID == null) {
                _this.setState({
                    UniqueID: data.uniqueID,
                    selectedTerminal:(data.locationObj ===null || data.locationObj === undefined || data.locationObj ===[] || data.locationObj[0] === undefined ? {locIdx:0} : data.locationObj[0].nestedLocs ) , 
                    selectedZoneIdx:(data.zoneObj ===[] || data.zoneObj === undefined || data.zoneObj[0] === undefined ? 0: 
                        data.zoneObj[0]._id ),
                        selectedZone: data.zoneObj, 
                })
            }
        }
        if(data !== null && data !== undefined&&data.locationObj==null&&data.zoneObj==null) {
            // clicked from floor plan Beacon
            _this.setState({
                UniqueID: data.deviceSerial,
                selectedTerminal:data.location===null||data.location===undefined||data.location===[]||data.location[0]===undefined?
                    {locIdx:0}
                    :
                    data.location[0].nestedLocs, 
                selectedZoneIdx: data.zone_id,
                selectedZone: data.zone, 
            })
        }
    }
    handleInputChage(event) {
        // 
        // console.log({[event.target.name]: event.target.value});

        this.setState({
            [event.target.name]: event.target.value
        })
    }

    submitRecord() {
        const { UniqueID,selectedTerminal, ZoneList,selectedZoneIdx, selectedZone } = this.state
        const { UserInfo, data } = this.props
        if (UniqueID == null || UniqueID == "") return
        const EmitUserID = UserInfo.userID
        let location_id = ""
        if(selectedTerminal._id !== undefined){
            location_id = selectedTerminal._id
        }
        let zone_id = ""
        if(selectedZoneIdx  !== undefined || selectedZoneIdx !== null){
            zone_id = selectedZone.id
        }
        //request json 
        let requestData = {}
        requestData = {
            "msgType": "DeviceConfiguration",
            "deviceSerial": UniqueID,
            "updateDeviceName":data.gatewayID==null?data.deviceName:data.gatewayID,
            "operatorID": EmitUserID,
            "client_id": UserInfo.client_id,
            "id": data._id,
            "deviceConfiguration": data.deviceConfiguration,
            "location_id": location_id, 
            "zone_id": zone_id
        }
        console.log(requestData); 
        //send with redux socket function
        this.props.dispatch({
            type: 'EmitSocket',
            EmitSocketName: "StatRequest",
            EmitSocketData: requestData
        })
        // this.props.reloadData()
        this.closePopup()
    }

    render() {
        const { UniqueID ,selectedTerminal,TerminalList, ZoneList} = this.state
        const { isPopup, item, DeviceData, data ,currentSite} = this.props 
     
        let filteredZoneList = []
        let item1 = {
            id:0,
            name:"--- Zone ---"
        }
        filteredZoneList.push(item1)
        ZoneList.map(function (data) {
          if(data.location_id === selectedTerminal._id )
            filteredZoneList.push(data)
        })
        return [
            isPopup ?
                <div className="Gateway_GD_popupBG" onClick={this.props.closePopup}></div> : <div></div>,
            isPopup ?
                <div className="Gateway_GD_popupMain">
                    <div className="gateway_popup_header">BLE Beacon Management</div>
                    <div className="main">
                        <div className="PopupRowContainer">
                            <input className="PopupInput" placeholder="Gateway ID" name="gatewayID"
                                onChange={this.handleInputChage}
                                value={data === undefined || data == null ? "" : data.gatewayID == null ? data.deviceName : data.gatewayID} disabled
                                autoComplete="off"></input>
                            <input className="PopupInput" placeholder="Unique ID" name="UniqueID"
                                onChange={this.handleInputChage}
                                value={UniqueID === undefined || UniqueID == null ? "" : UniqueID}
                                autoComplete="off"></input>
                        </div>

                        <div className="PopupRowContainer">
                            <select name="Terminal"  className="PopupSelect" onChange={this.PickTerminal} 
                            value={this.state.selectedTerminal == 0? 0:this.state.selectedTerminal.locIdx }  >
                            {
                            
                                TerminalList.map((term, index) => {
                                    return <option value={index} >
                                        {term.locName} 
                                    </option>
                                })
                            }                                
                            </select>
                            <select name="Zone"  className="PopupSelect"  onChange={this.PickZone} 
                            value={this.state.selectedZoneIdx == 0? 0:this.state.selectedZoneIdx }  >
                            {                                
                                filteredZoneList.map((term, index) => {
                                    return <option value={term.id} >
                                        {term.name} 
                                    </option>
                                })
                            }
                            </select>
                        </div>

                    </div>
                    <div className="buttonsContainer">
                        <div className="UserManageSubmit"
                            onClick={this.submitRecord}
                        >Submit</div>
                        <div className="UserManageCancel"
                            onClick={this.closePopup}
                        >Cancel</div>
                    </div>
                </div>
                :<div></div>
        ]
    }
}

function mapStateToProps(state) {
    return {
        UserInfo: state.UserInfo,
        DeviceData:state.DeviceData,
        currentlevel: state.currentlevel,
        currentSite: state.currentSite, 
    }
}

export default connect(mapStateToProps)(coldbox_popup)