import React from 'react'
import './manage.css'
import { connect } from 'react-redux'
import PopupNotification from '../../../../popupNotification/PopupNotification'

class Manage extends React.Component {
    constructor(props) {
        super()
        this.state = {
            coldboxID: '',
            DeviceID: '',   //device Name
            groupPicked: ''
        }
        this.InitialData = this.InitialData.bind(this)
        this.InputChange = this.InputChange.bind(this)
        this.Submit = this.Submit.bind(this)
        this.Delete = this.Delete.bind(this)
        this.Close = this.Close.bind(this)

        this.selectGroup = this.selectGroup.bind(this)
    }

    componentDidUpdate(preProps) {
        const oldIsPopup = preProps.isPopup
        const {isPopup, isEdit} = this.props
        const _this = this

        if(isEdit && isPopup && !oldIsPopup) _this.InitialData()
    }

    InitialData() {
        const _this = this
        const {Editingitem} = this.props
        const {locName} = Editingitem

        const deviceSerial = (Editingitem==null||Editingitem.device_id==null)?'':Editingitem.device_id.deviceSerial

        const groupName = getGroupOfColdbox(_this.props, locName)

        this.setState({
            coldboxID: locName,
            DeviceID: deviceSerial,      //deviceID
            groupPicked: groupName
        })
    }
    InputChange(event, keyStr) {
        try {
            const Value = event.target.value
            this.setState({
                [keyStr]: Value
            })
        }
        catch(err) {
            console.log(err)
        }
    }
    Submit() {
        const {groupPicked} = this.state
        const {UserInfo} = this.props
        const {Editingitem} = this.props        //input from parent (the selected popup item)
        const userID = UserInfo.userID
        const deviceID = Editingitem.device_id._id
        const LocName = Editingitem.locName
        const locID = Editingitem._id
        
        let levelID, levelName, siteID, siteName
        UserInfo.locations.forEach(siteItem => {
            siteItem.nestedLocs.forEach(LevelItem => {
                LevelItem.nestedLocs.forEach(locItem => {
                    if(locItem.locName == LocName) {
                        levelID = LevelItem._id
                        levelName = LevelItem.locName
                        siteID = siteItem._id
                        siteName = siteItem.locName
                    }
                })
            })
        })

        const requestData = {
            "msgType": "LocationManagement",
            "method": "Update",
            "userID": userID,
            "locationInfo": [
                {
                    "id": siteID,
                    "lvl": 1,
                    "name": siteName
                },
                {
                    "id": levelID,
                    "lvl": 2,
                    "name": levelName
                },
                {
                    "id": locID,
                    "lvl": 3,
                    "name": LocName
                }
            ],
            "device_id": deviceID,
            "group": groupPicked,
        }
        console.log(requestData)
        this.props.dispatch({
            type: 'EmitSocket',
            EmitSocketName: "StatRequest",      //StatRequest can request for any type data
            EmitSocketData: requestData
        })
        //close this popup (when done)
        this.props.closePopup()
    }
    Delete() {
        this.props.deletePopup()
    }
    Close() {
        this.props.closePopup()
    }
    selectGroup(event) {
        if(event == null || event.target == null || event.target.value == null) return
        const groupValue = event.target.value

        this.setState({groupPicked: groupValue})
    }

    render() {
        const _this = this
        const {isEdit, isPopup, deleteConfirm} = this.props
        const {DeviceManageList} = this.props           //from redux
        const {coldboxID, DeviceID, groupPicked} = this.state  //isShowDeviceList
        const groupOptionList = genGroupOptionList(_this.props)
        
        return (isEdit && isPopup && !deleteConfirm)?
        [
            <div className="ColdboxIndi_popupMain">
                <div className="ColdboxIndi_popupTitle">Edit Coldbox</div>
                <div className="ColdboxIndi_popupcloseBtn" onClick={this.Close}>
                    <div className="fa fa-times"></div>
                </div>
                <div className="ColdboxIndi_popupRow">
                    <input className="ColdboxIndi_popupInput" placeholder="Coldbox ID" value={coldboxID} onChange={(e) => this.InputChange(e, 'coldboxID')} readOnly></input>
                    <input className="ColdboxIndi_popupInput" placeholder="Device ID" value={DeviceID} onChange={(e) => this.InputChange(e, 'DeviceID')} list="coldboxitems" type="search"></input>
                    <datalist id="coldboxitems" className="ColdboxIndi_DeviceIDList">
                        {
                            DeviceManageList.map(deviceItem => {
                                const {deviceSerial, deviceName, id, deviceSeg_id, deviceType_id} = deviceItem
                                return <option value={deviceSerial}>
                                    {deviceName}
                                </option>
                            })
                        }
                    </datalist>
                </div>
                <div className="row">
                    <div>Select Coldbox Group:</div>
                    <select className="ColdboxIndi_popupselect" onChange={_this.selectGroup}>
                        <option>No Group</option>
                        {
                            groupOptionList.map(function(groupItem) {
                                return <option value={groupItem.name} selected={(groupPicked==groupItem.name)?"selected":""}>
                                    {groupItem.name}
                                </option>
                            })
                        }
                    </select>
                </div>
                <div className='ColdboxIndi_popupBottom'>
                    <div className="ColdboxIndi_popupDel" onClick={this.Delete}>Delete Coldbox</div>
                    <div className="ColdboxIndi_popupSubmit" onClick={this.Submit}>Submit</div>
                    <div className="ColdboxIndi_popupCancel" onClick={this.Close}>Cancel</div>
                </div>
            </div>,
            <PopupNotification onRef={ref => {this.PopupNotificationRef = ref}} />
        ]
        :
        <div style={{display:'none'}}></div>
    }
}

function mapStateToProps(state) {
    return {
        DeviceManageList: state.DeviceManageList,
        UserInfo: state.UserInfo,
        GroupList: state.GroupList
    }
}
export default connect(mapStateToProps)(Manage)

function genGroupOptionList(props) {
    const {GroupList} = props
    if(GroupList == null || GroupList.groups == null || GroupList.groups.length == null || GroupList.groups.length == 0) return []
    var optionList = GroupList.groups.map(item => {
        return {
            value: item.id,
            name: item.group_name,
        }
    })
    var sortedList = optionList.sort((a,b) => {
        if (b.name > a.name) return -1
        if (b.name < a.name) return 1
        return 0
    })
    return sortedList
}

function getGroupOfColdbox(props, coldboxID) {
    const {GroupList} = props
    if(GroupList==null || coldboxID == null) return

    const {groups} = GroupList
    let groupName = ''
    groups.forEach(gpItem => {
        const {members} = gpItem
        let tryFind = members.find(item => item.locName == coldboxID)
        if(tryFind != null) groupName = gpItem.group_name
    })
    
    return groupName
}