import React from 'react'
import './DragOptionbar.css'
//Redux Import
import { connect } from 'react-redux'

//AddWidgetList
import AddWidgetList from './AddWidget'

class DragOptionBar extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isOpenAddList: false,           //true would open the add Widget GUI to add new widget from list
        }

        this.OpenAddWidgetGUI = this.OpenAddWidgetGUI.bind(this)
        this.SaveWidget = this.SaveWidget.bind(this)
        this.CancelModify = this.CancelModify.bind(this)
    }

    OpenAddWidgetGUI() {
        const {isOpenAddList} = this.state
        this.setState({
            isOpenAddList: !isOpenAddList
        })
    }
    SaveWidget() {
        const {UserInfo, currentlevelID} = this.props
        const {widgetType} = this.props                 //Dashboard or Statistic
        //get widget ui (current)
        const ui_position = this.props.saveLayoutPosition()
        //get Floor ID: currentlevelID
        const userID = UserInfo.userID   //get userID
        const UI_type = "Dashboard"      //Should be 'Dashboard'
        //Emit to server
        const EmitJson = {
            "msgType": "SaveWidgets",
            ui: ui_position,
            userID: userID,
            type: widgetType,
            levelID: currentlevelID,
        }
        console.log(EmitJson)
        this.props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "AlarmRequest",
            EmitSocketData: EmitJson
        })
        //progressing GUI (loading)
        //response inside navbar widget's socket listener
        //trigger message display to user to notify the save is done
        //update the UserInfo.ui value

        //lock the screen
        this.CancelModify()
    }
    CancelModify() {
        //call parent's function to stop modifying
        this.props.WidgetLock()
    }

    render() {
        const _this = this
        const {isLock} = this.props
        const {isOpenAddList} = this.state

        if(!isLock) return <div></div>

       return [<div className="DragOptionBar">
           <div className="DragOptionBar_AddWidget" onClick={_this.OpenAddWidgetGUI}>
               Add New Widget
           </div>
           <div className="DragOptionBar_save" onClick={_this.SaveWidget}>
               Save
           </div>
           <div className="DragOptionBar_cancel" onClick={_this.CancelModify}>
               Lock
           </div>
       </div>,
       <AddWidgetList isOpenAddList={isOpenAddList} OpenAddWidgetGUI={_this.OpenAddWidgetGUI} onAddItem={_this.props.onAddItem} />
       ]
    }
}

//Redux inside component function
function mapStateToProps(state) {
    return {
      UserInfo: state.UserInfo,
      currentlevelID: state.currentlevelID,
    }
}

export default connect(mapStateToProps)(DragOptionBar)