import React from 'react'
import './downloadcsv.css'
//Redux Import
import { connect } from 'react-redux'

import { CSVLink, CSVDownload } from "react-csv"

class Downloadcsv extends React.Component {
    constructor(props)
    {
        super()

        this.state = {
            csvData: null,              //array, with items contain the keyStr
        }

        this.PrepareData = this.PrepareData.bind(this)
        this.ClearData = this.ClearData.bind(this)
        this.sendDLActionforAuditTrial = this.sendDLActionforAuditTrial.bind(this)
    }

    sendDLActionforAuditTrial(){
        const { UserInfo } = this.props
        let requestData = {}
        requestData = {
            "msgType": "AddAuditTrial",
            "username": UserInfo.username,
            "user_id":UserInfo.userID,
            "userLevel":UserInfo.userLevel,
            "client_id": UserInfo.client_id,
            "action":"Download",
            "actionDetail":"Download audit trial log",
            "remark":""
        }
        this.props.dispatch({
            type: 'EmitSocket',
            EmitSocketName: "AuditTrialRequest",
            EmitSocketData: requestData
        })
    }

    PrepareData() {
        const {sortedList} = this.props
        if(sortedList == null || sortedList.length == null || sortedList.length == 0) return           //exception case
        this.setState({
            csvData: sortedList,
        }, function() {
            setTimeout(function(){
                document.getElementById("downloadCSVBTN").click()
                document.getElementById("downloadCSVBTN_link").click()
            }, 1000)
        })
        this.sendDLActionforAuditTrial()
    }

    ClearData() {
        const _this = this
        setTimeout(function(){
            _this.setState({
                csvData: null
            })
        }, 1000)
    }
     
   
    render()
    {
        const {csvData} = this.state
        const _this = this

        if(csvData != null) {
            return <div className="CBAudit_downloadcsv" onClick={_this.ClearData} id="downloadCSVBTN">
                <CSVLink data={csvData} headers={CsvHeaders} filename='ColdboxAlarmList.csv' id="downloadCSVBTN_link">
                    Download csv
                </CSVLink>
                <div className="fa fa-check"></div>
            </div>
        }
        return <div className="CBAudit_downloadcsv" onClick={this.PrepareData}>Download csv</div>
    } 
     
}

//Redux inside component function
function mapStateToProps(state) {
    return { 
        UserInfo: state.UserInfo,
    }
}

export default connect(mapStateToProps)(Downloadcsv)
  
const CsvHeaders = [
    {
        key: 'username',
        label: 'User ID',
    },
    {
        key: 'userLevel',
        label: 'Type',
    },
    {
        key: 'action',
        label: 'Action',
    },
    {
        key: 'actionDetail',
        label: 'Action Detail',
    }, 
    {
        key: 'createdTime',
        label: 'Trigger Time',
    }, 
    {
        key: 'remark',
        label: 'Remark',
    },
     
]