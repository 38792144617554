export default function getMonthList(startStr, endStr) {  
    if(startStr==null||endStr==null) return []

    const startMonth = startStr.getMonth()
    const endMonth = endStr.getMonth()
    const startYear = startStr.getFullYear()
    const endYear = endStr.getFullYear()

    let yearLoop = startYear
    let monthLoop = startMonth
    let returnMonthArray = []
    while(yearLoop < endYear || (monthLoop <= endMonth)) {
        const {monthFinal, yearFinal} = plusOneMonth(monthLoop, yearLoop)
        returnMonthArray.push(monthArray[monthFinal] + '-' + yearFinal)

        monthLoop = monthFinal
        yearLoop = yearFinal
        
        if(yearLoop > endYear) break
    }
    return returnMonthArray
}
const plusOneMonth = (monthInput, yearInput) => {
    if(monthInput==11) {
        return {
            monthFinal: 0,
            yearFinal: yearInput + 1
        }
    }
    return {
        monthFinal: monthInput + 1,
        yearFinal: yearInput
    }    
}
const monthArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
// console.log(getMonthList(new Date('2021-11-30'), new Date('2023-01-30')))