import React from 'react'
import {connect} from 'react-redux'
import './pumpSummary.scss'

import {getPumpData} from './getPumpData'

const PumpSummary = props => {
    const {top, bottom} = getPumpData(props)

    return <div className='pumpSummary'>
        <div className='head'>Pump</div>
        <div className='inner'>
            <div className='row'>
                <div className='left'>
                    <div className='img'></div>
                    <div className='device'>AC Motor</div>
                </div>
                <div className='right'>
                    <div className='reading'>{top.toFixed(1)}</div>
                    <div className='unit'>kW</div>
                </div>
            </div>
            <div className='row'>
                <div className='left'>
                    <div className='img'></div>
                    <div className='device'>PMSM</div>
                </div>
                <div className='right'>
                    <div className='reading'>{bottom.toFixed(1)}</div>
                    <div className='unit'>kW</div>
                </div>
            </div>
        </div>
    </div>
}
const mapStateToProps = state => {
    return {
      currentlevel: state.currentlevel,
      currentSite: state.currentSite,
      DeviceData: state.DeviceData,
      DeviceDataLastUpdate: state.DeviceDataLastUpdate
    }
}
export default connect(mapStateToProps)(PumpSummary)