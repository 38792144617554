// for some user, do not have google map as the main page
// would show a Image of building in main page
// which is this image
import React from 'react'
//import './testCard.css'       //..
//Redux Import
import { connect } from 'react-redux'

class TestCard extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return <div className="testCard_page">
            <div className="testCard_Title">
                Prepare bottom reading cards in FloorPlan page
            </div>
            <div className="testCard_innerContainer">

                <div className="DefaultCardSize waterLeakage">
                    <div className="card_innerContainer">
                        <div className="Location_title">
                            Water Leakage
                        </div>
                        <div className="Card_readingContainer">
                            <div className="Card_reading">
                                123
                                <div className="Card_readingUnit">
                                    %
                                </div>
                            </div>
                            <div className="waterLeak_icon_on">
                            </div>
                        </div>
                    </div>
                </div>
                <div className="DefaultCardSize waterLeakage">
                    <div className="card_innerContainer">
                        <div className="Location_title">
                            Water Leakage (T/F)
                        </div>
                        <div className="Card_readingContainer TrueFalseAlarm">
                            <div className="Card_reading_TF">
                                No Leakage
                            </div>
                            <div className="waterLeak_icon">
                            </div>
                        </div>
                    </div>
                </div>
                <div className="DefaultCardSize waterLeakage">
                    <div className="card_innerContainer">
                        <div className="Location_title">
                            Water Leakage (T/F)
                        </div>
                        <div className="Card_readingContainer TrueFalseAlarm_on">
                            <div className="Card_reading_TF">
                                Leakage
                            </div>
                            <div className="waterLeak_icon_on">
                            </div>
                        </div>
                    </div>
                </div>

                <div className="DefaultCardSize wetness">
                    <div className="card_innerContainer">
                        <div className="Location_title">
                            Wetness Sensor (T/F)
                        </div>
                        <div className="Card_readingContainer TrueFalseAlarm">
                            <div className="Card_reading_TF">
                                No Alarm
                            </div>
                            <div className="fa fa-umbrella Card_icon">
                            </div>
                        </div>
                    </div>
                </div>
                <div className="DefaultCardSize wetness">
                    <div className="card_innerContainer">
                        <div className="Location_title">
                            Wetness Sensor (T/F)
                        </div>
                        <div className="Card_readingContainer TrueFalseAlarm_on">
                            <div className="Card_reading_TF">
                                Wetness Alarm
                            </div>
                            <div className="fa fa-umbrella Card_icon">
                            </div>
                        </div>
                    </div>
                </div>

                
                <div className="DefaultCardSize RFID">
                    <div className="card_innerContainer">
                        <div className="Location_title">
                            RFID Location
                        </div>
                        <div className="Card_readingContainer">
                            <div className="Card_detail">
                                RFID Location
                            </div>
                            <div className="fa fa-map-marker Card_icon">
                            </div>
                        </div>
                    </div>
                </div>

                <div className="DefaultCardSize vibration">
                    <div className="card_innerContainer">
                        <div className="Location_title">
                            Vibration Sensor
                        </div>
                        <div className="Card_readingContainer vibrate_container">
                            <div className="vibrate_row">
                                <div className="vibrate_freq">
                                    <div className="">
                                        640
                                    </div>
                                    <div className="vibrate_freqUnit">
                                        freq
                                    </div>
                                </div>
                                <div className=" fa fa-signal vibrate_icon"></div>
                            </div>
                            <div className="vibrate_row">
                                <div className="vibrate_accel">
                                    <div className="">
                                        20.2                                        
                                    </div>
                                    <div className="vibrate_accUnit">
                                        ms
                                        <div className="vibrate_accelUnit2">
                                            -2
                                        </div>
                                    </div>
                                </div>
                                <div className="vibrate_accel_title">
                                    Max Accel
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="DefaultCardSize vibration">
                    <div className="card_innerContainer">
                        <div className="Location_title">
                            Vibration Sensor
                        </div>
                        <div className="Card_readingContainer vibrate_container">
                            <div className="vibrate_row">
                                <div className="vibreate_alarmContainer">
                                    <div className="vibrate_alarmtxt">No Alarm</div>
                                    <div className="fa fa-bell vibrate_alarmIcon"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="DefaultCardSize vibration">
                    <div className="card_innerContainer">
                        <div className="Location_title">
                            Vibration Sensor
                        </div>
                        <div className="Card_readingContainer vibrate_container">
                            <div className="vibrate_row">
                                <div className="vibreate_alarmContainerOn">
                                    <div className="vibrate_alarmtxt">Vibration Alarm</div>
                                    <div className="fa fa-bell vibrate_alarmIcon"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    }
}

//Redux inside component function
function mapStateToProps(state) {
    return {
      UserInfo: state.UserInfo,
      currentSite: state.currentSite,
    }
}

export default connect(mapStateToProps)(TestCard)