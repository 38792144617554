// for some user, do not have google map as the main page
// would show a Image of building in main page
// which is this image
import React from 'react'
import './MainWithImage.css'
//Redux Import
import { connect } from 'react-redux';
import SoundPlay from '../../Sound/Sound'

class MainWithImage extends React.Component {
    constructor(props) {
        super(props)

        this.pickSite = this.pickSite.bind(this)
        this.ViewBuildingSiteDetail = this.ViewBuildingSiteDetail.bind(this)
        this.ViewSummaryDetail = this.ViewSummaryDetail.bind(this)

        this.soundRef = React.createRef()
    }
    componentDidMount() {
        //default set the current site and level
        this.props.dispatch({ type: 'PIckSite', data: {
            Siteid: 0
        }})
    }
    pickSite(thisSiteNumber) {
        this.soundRef.current.alarmMp3Run("hover")
        this.props.dispatch({ type: 'PIckSite', data: {
            Siteid: thisSiteNumber,
        }})
    }
    ViewBuildingSiteDetail() {
        this.soundRef.current.alarmMp3Run("hover")
        this.props.history.push("/Main/FloorPlan")
      }
    ViewSummaryDetail() {
        this.soundRef.current.alarmMp3Run("hover")
        this.props.history.push("/Main/Summary")
    }

    render() {
        const thisoutside = this
        const {UserInfo, currentSite} = this.props
        if(UserInfo == null || currentSite == null) return <div></div>
        if(UserInfo.locations == null) return <div></div>
        const CurrentSiteItem = UserInfo.locations[currentSite]
        if(CurrentSiteItem == null) return <div></div>
        const SiteName = CurrentSiteItem.locName
        const imgUrlStr = CurrentSiteItem.imgUrl
        const UrlRoot = "http://47.56.108.35/assets/images/floors/"
        const fullImageUrl = UrlRoot + imgUrlStr + "/default.jpg"

        const permission = UserInfo.permissions
        if(permission == null || permission.length == null) return <div>Wrong Permission Data in UserInfo</div>
        const StatisticPermission = permission.find(function(permissionItem) {
            return permissionItem.collectionType == "Statistics"
        })

        return [
        <div key="backgroundCoverImage" className="MainWithImage_overlapBG" style={ { backgroundImage: "url(" + fullImageUrl + ")", backgroundSize: "cover" } }></div>,
        <SoundPlay ref={this.soundRef}/>,
        <div className="MainWithImage_Container">
            <div className="MainWithImage_CurrentSiteDisplay" style={ { backgroundImage: "url("+fullImageUrl+")" } }>
                <div className="MainWithImage_CurrentSiteTitle">
                    {SiteName}
                </div>
                <div className="MainWithImage_BtnRow">
                    {(StatisticPermission == null)?
                    <div className="MainWithImage_BtnStatistic nopermission">Statistic</div>
                    :
                    <div className="MainWithImage_BtnStatistic" onClick={this.ViewSummaryDetail}>Statistic</div>
                    }
                    <div className="MainWithImage_BtnDashboard" onClick={this.ViewBuildingSiteDetail}>Dashboard</div>
                </div>
            </div>
            <div className="MainWithImage_SiteList">
                {
                    UserInfo.locations.map((siteItem, i) => {
                        let highlightclass = ""
                        const thisSiteNumber = i
                        if(i == currentSite) {
                            highlightclass = "currentPicked"
                        }
                        let displayNumber = "";
                        if(i < 10) displayNumber = "0" + (i+1).toString()
                        else displayNumber = (i+1).toString()
                        return <div className={"MainWithImage_SiteItem "} onClick={() => thisoutside.pickSite(thisSiteNumber)}>
                            <div className={"MainWithImage_ListImage " + highlightclass}>
                                {displayNumber}
                            </div>
                            <div className="MainWithImage_ListInner">
                                {siteItem.locName}
                            </div>
                        </div>
                    })
                }
            </div>
        </div>,]
    }
}

//Redux inside component function
function mapStateToProps(state) {
    return {
      UserInfo: state.UserInfo,
      currentSite: state.currentSite,
    }
}

export default connect(mapStateToProps)(MainWithImage)