import React, { useEffect, useState } from 'react'
import './feedbackRecord.css'

import { connect } from 'react-redux'
import Moment from 'react-moment'

function FeedbackRecordWidget(props) {
    const {UserInfo, DeviceData, ToiletType, currentSite, currentlevel, socket} = props
    const [responseData, setresponseData] = useState(null)

    const RequestTimestamp = GetTimestampForReq()

    useEffect(()=> {
        if(socket == null) return
        //socket listener 
        socket.on("Toilet_feedback", data => {
            console.log(data)
            // console.log(data.result)
            if(data.result == null) {
                console.error('Get Toilet Feedback Failed')
            }
            else if(data.result == "Fail") {
                console.error('Get Toilet Feedback Failed: ' + data.error)
            }
            else if(data.result == "Success") {
                //save to local state
                // setresponseData(data.data)
                const toilettype = localStorage.getItem('toilettype')
                const FeedbackList = data.data.filter(item => {
                    return item.toiletType.toString() == toilettype.toString()
                })
                console.log(toilettype)
                const HappyCount = FeedbackList.filter(item => {
                    return item.score == 3 || item.score == "3"
                })
                const FairCount = FeedbackList.filter(item => {
                    return item.score == 2 || item.score == "2"
                })
                const poorCount = FeedbackList.filter(item => {
                    return item.score == 1 || item.score == "1"
                })
                const FormattedDataList = FeedbackList.map(item => {
                    return {
                        score: item.score,
                        desc: item.feedback,
                        time: item.timestamp,
                        UserID: item.UserID,    //maybe useful
                        id: item.id,            //maybe useful
                    }
                })
                const SetValue = {
                    happy: HappyCount.length,
                    fair: FairCount.length,
                    poor: poorCount.length,
                    data: FormattedDataList
                }
                // console.log({
                //     FeedbackList: FeedbackList,
                //     HappyCount: HappyCount,
                //     FairCount: FairCount,
                //     poorCount: poorCount,
                //     FormattedDataList: FormattedDataList,
                //     SetValue: SetValue,
                // })
                setresponseData(SetValue)
            }
        })
        return function WidgetStop() {
            //clear socket listener in this page
            socket.off("Toilet_feedback")
        }
    }, [])

    useEffect(()=> {
        setresponseData(null)
        if(UserInfo == null) return
        //request data from server
        const {SiteID, SiteName, LevelID, LevelName} = GetSelectedLevelSiteData(DeviceData, currentSite, currentlevel)
        const EmitJson = {
            "msgType": "Toilet_feedback",
            "method": "Read",
            "client_id": UserInfo.client_id,
            "toiletType": ToiletType, //1 = male, 2 = female, 3 = other
            "startTime": convertDate(RequestTimestamp),
            "endTime": convertDate(new Date())
        }

        console.log(EmitJson)
        props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "StatRequest",
            EmitSocketData: EmitJson
        })

        localStorage.setItem('toilettype', ToiletType)
    }, [currentSite, currentlevel, ToiletType])


    const {happy, fair, poor} = responseData==null?
    {
        happy: 0,
        fair: 0,
        poor: 0
    }
    :
    responseData

    const Total = (happy + fair + poor)/1.5
    const happyPercent = Total==0?0:happy/Total * 100
    const fairPercent = Total==0?0:fair/Total * 100
    const poorPercent = Total==0?0:poor/Total * 100

    return <div className="feedbackRecord">
        <div className="left">
            <div className="fdbk_record_title">Feedback</div>
            <div className="fdbk_record_chart">
                <div className="fdbk_record_chartinner">
                    <div className="bar" style={{background: 'linear-gradient(to top, #f06681 '+poorPercent+'%, transparent '+poorPercent+'%)'}}>Poor</div>
                    <div className="type">{poor}</div>
                </div>
                <div className="fdbk_record_chartinner">
                    <div className="bar" style={{background: 'linear-gradient(to top, #6dcef5 '+fairPercent+'%, transparent '+fairPercent+'%)'}}>Fair</div>
                    <div className="type">{fair}</div>
                </div>
                <div className="fdbk_record_chartinner">
                    <div className="bar" style={{background: 'linear-gradient(to top, #49bfb5 '+happyPercent+'%, transparent '+happyPercent+'%)'}}>Happy</div>
                    <div className="type">{happy}</div>
                </div>
            </div>
        </div>
        <div className="fdbk_record_table">
            Feedback Records:
        {
            responseData==null||responseData.data==null?[]:responseData.data.map(item => {
                let BGcolor
                switch (item.score) {
                    case 1:
                        BGcolor = '#f06681'
                        break
                    case 2:
                        BGcolor = '#6dcef5'
                        break
                    case 3:
                        BGcolor = '#49bfb5'
                        break
                }
                return <div className={(item.desc == null || item.desc.length == 0 || item.desc.length <= 25)?"record":"record maxsize"} style={{background: 'linear-gradient(to right, '+BGcolor+' 5px, white 5px)'}}>
                    <div className="time">
                        <Moment format="YYYY/MM/DD HH:mm">{item.time}</Moment>
                    </div>
                    <div className={(item.desc == null || item.desc.length == 0 || item.desc.length <= 25)?"desc":"desc maxsize"}>{item.desc}</div>
                </div>
            })
        }
            </div>
    </div>
}

function mapStateToProps(state) {
    return {
        UserInfo: state.UserInfo,
        DeviceData: state.DeviceData,
        ToiletType: state.ToiletType,
        currentSite: state.currentSite,
        currentlevel: state.currentlevel,
        socket: state.socket,
    }
}
export default connect(mapStateToProps)(FeedbackRecordWidget)

// w = 1
// h = 2

function GetSelectedLevelSiteData(DeviceData, currentSite, currentlevel) {
    const SiteItem = DeviceData[currentSite]
    if(SiteItem == null) return {}

    const LevelItem = SiteItem.nestedLocs.find(item => {
        return item.locName.toString() == currentlevel.toString()
    })
    if(LevelItem == null) return {}

    return {
        SiteID: SiteItem._id,
        SiteName: SiteItem.locName,
        LevelID: LevelItem._id,
        LevelName: LevelItem.locName,
    }
}

function GetTimestampForReq() {
    var d = new Date()
    d.setDate(d.getDate() - 30)     //get all feedback in one month
    return d
}

function convertDate(DateInput)
{
    let DateMonth = DateInput.getMonth() + 1
    let DateDay = DateInput.getDate()
    if(DateMonth < 10) DateMonth = "0" + DateMonth
    if(DateDay < 10) DateDay = "0" + DateDay
    let HourStr = DateInput.getHours()
    if(HourStr < 10) HourStr = "0" + HourStr
    let MinutesStr = DateInput.getUTCMinutes()
    if(MinutesStr < 10) MinutesStr = "0" + MinutesStr
    const DateStr = DateInput.getFullYear() + "-" + DateMonth + "-" + DateDay + "T" + HourStr + ":" + MinutesStr + ":00Z"
    return DateStr
}