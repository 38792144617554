import React from 'react'
import './event.css'
import { connect } from 'react-redux'
import moment from 'moment'
//inner widgets
import PopupNotification from '../../../popupNotification/PopupNotification'
import Searchbar from './event/searchbar/searchbar'
import Table from './event/table/table'
import TableHead from './event/table/tablehead'
import Downloadcsv from './event/bottom/downloadcsv'
import Pagebtns from './event/bottom/pagebtns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

class ColdboxEvent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isAdvanceSearch: false,
            AlarmList: null,
            rawAlarmList: null,
            //sorted result (by table head .js)
            sortedList: null,
            //filtered result (by searchbar.js)
            filteredList: null,
            //slice by page (by pages.js widget)
            pageSliceList: null,

            updateCount: 0,
            startdate:new Date(moment().subtract(7, 'days')),
            enddate: new Date(),
        }
        this.getEvent = this.getEvent.bind(this)
        this.AdvanceSearch = this.AdvanceSearch.bind(this)
        this.closeAdSearch = this.closeAdSearch.bind(this)
        this.redirectEventPage = this.redirectEventPage.bind(this)
        this.refresh = this.refresh.bind(this)
        this.relist = this.relist.bind(this)
        this.setStartTime = this.setStartTime.bind(this)
        this.setEndTime = this.setEndTime.bind(this)
        this.setSelectedLevel = this.setSelectedLevel.bind(this)
        //Update function from child widgets
        this.UpdateList = this.UpdateList.bind(this)
        this.acknowledgeUpdate = this.acknowledgeUpdate.bind(this)
        this.acknowledgeListener = this.acknowledgeListener.bind(this)
        this.setStatus = this.setStatus.bind(this)
    }
    AdvanceSearch() {
        // console.log("AdvanceSearch")
        this.setState({ isAdvanceSearch: true })
    }
    closeAdSearch() {
        this.setState({ isAdvanceSearch: false })
    }
    redirectEventPage() {
        this.props.history.push('/Main/Coldbox/Alarm')
    }
    refresh() {
        this.getEvent()
        this.relist()
    }

    setStatus(alarm){
        if(alarm == null || alarm == undefined) return ''

        if(alarm.isClear) {
            if(alarm.severity ==="Warning"){
                if(alarm.clearParty === "System") return "Auto-resumed"
                return "Acknowledged"
            }
            return "Closed"          
        }
        return "Open"
    }
    relist() {
        const _this = this
        const {Notification} = this.props

        let newList = []
        if (Notification !== null) {
            Notification.data.map(function (alarm) {
                const alarmItem = {
                    coldboxID: (alarm.locationInfo !== null && alarm.locationInfo !== undefined ? alarm.locationInfo[2].name : ""),
                    type:  (alarm.severity ==="Low"? "Warning": "Fault"),
                    location: (alarm.locationInfo !== null && alarm.locationInfo !== undefined ? alarm.locationInfo[0].name : ""),
                    name: alarm.name,
                    message: alarm.message,
                    createdTime: alarm.createdTime,
                    status: _this.setStatus(alarm),
                    remark: "",
                    alarmID:alarm.id, 
                    clearParty: alarm.clearParty,
                    floor: (alarm.locationInfo !== null && alarm.locationInfo !== undefined ? alarm.locationInfo[1].name : ""),
                    keywords: JSON.stringify(alarm)

                }
                newList.push(alarmItem)
            })
            _this.setState(prevState => ({
                ...prevState,
                AlarmList: newList,
                rawAlarmList: Notification.data
            }))
        }
    }
    componentDidUpdate() {
        if (this.props.Notification !== null && this.state.rawAlarmList !== this.props.Notification.data) this.relist()
    }

    componentDidMount() {
        // console.log("componentDidUpdate")
        const outside = this
        outside.acknowledgeListener() 
        outside.refresh()

        //clear redux storage to prevent show wrong logs
        this.props.dispatch({
            type: 'Notification',
            data: null
        })
    }

    convertDate(DateInput) {
        let RawTimestamp = DateInput
        var Output = RawTimestamp.toISOString()
        return Output
    }
    getEvent() {
        const { UserInfo } = this.props
        const { startdate, enddate } = this.state
        if (UserInfo == null) return
        const EmitUserID = UserInfo.userID
        //request json 
        const startDateStr = this.convertDate(startdate)
        const EndDateStr = this.convertDate(enddate)
        const requestData = {
            "msgType": "AlarmList",
            "userID": EmitUserID,
            "timeConstraint": {
                "start": startDateStr,
                "end": EndDateStr,
            },
            "severityConstraint": "Low"
        }
        //send with redux socket function
        this.props.dispatch({
            type: 'EmitSocket',
            EmitSocketName: "AlarmRequest",
            EmitSocketData: requestData
        })
    }

    
    acknowledgeListener() {
        //create socket listener for acknowledged alarm
        const {socket} = this.props
        const _this = this
        if(socket == null) {
            console.log('socket is null, tablePopup.js acknowledgeListener()')
            return
        }
        console.log('create socket listener for acknowledge response')
        this.props.socket.on("UpdateAlarm", data=> {
            if( _this.PopupNotificationRef !== undefined) {  
                if( data.result == "Success") {
                    _this.PopupNotificationRef.addNotificationPopup("Acknowledge Success!", "Status of alarm record is updated.", "")
                    //Update the current popup data
                    _this.acknowledgeUpdate(data)
                }
                else {
                    _this.PopupNotificationRef.addNotificationPopup("Acknowledge Failed!", "Failed Message: " + data.message, "")
                }
            }          
        })
    }

    acknowledgeUpdate(acknowledgedItem) {
        console.log("acknowledgeUpdate")
        this.getEvent()
        this.relist()
    }

    //Update function from child widgets
    UpdateList(StateStr, Value) {
        const { updateCount } = this.state
        this.setState({
            [StateStr]: Value,
            updateCount: updateCount + 1,
        })
    }

    setSelectedLevel(data) {
        this.setState({ selectedLevel: data })
    }
    setStartTime(data) {
        this.setState({ startdate: data })
    }

    setEndTime(data) {
        this.setState({ enddate: data })
    }
    render() {
        const { isAdvanceSearch, AlarmList, sortedList, filteredList, pageSliceList, updateCount } = this.state
        const { Notification } = this.props
        const _this = this

        if(Notification==null||Notification.data==null||Notification.data.length==null||Notification.data.length==0) return <div className="CBEvent">
            <Searchbar isAdvanceSearch={isAdvanceSearch} closeAdSearch={_this.closeAdSearch} AdvanceSearch={_this.AdvanceSearch}
                    redirectEventPage={_this.redirectEventPage} refresh={this.refresh}
                    coldboxList={AlarmList} UpdateList={_this.UpdateList}  
                    setStartTime={_this.setStartTime} setEndTime={_this.setEndTime} setSelectedLevel={_this.setSelectedLevel}/>
            <div className="CBEvent_table">
                <TableHead filteredList={filteredList} UpdateList={_this.UpdateList} />
                {
                    Notification!=null&&Notification.data!=null&&Notification.data.length==0?
                    <div className="CBE_norecord">No Record</div>
                    :
                    <div className="CBE_norecord">
                        <FontAwesomeIcon icon={faSpinner} className='fa fa-spin' />
                    </div>
                }
            </div>
            <div className="CBEvent_bottom">
                <Downloadcsv sortedList={sortedList} />
                <Pagebtns sortedList={sortedList} UpdateList={_this.UpdateList} count={updateCount} />
            </div>
        </div>

        return <div className="CBEvent">
           <Searchbar isAdvanceSearch={isAdvanceSearch} closeAdSearch={_this.closeAdSearch} AdvanceSearch={_this.AdvanceSearch}
                    redirectEventPage={_this.redirectEventPage} refresh={this.refresh}
                    coldboxList={AlarmList} UpdateList={_this.UpdateList}  
                    setStartTime={_this.setStartTime} setEndTime={_this.setEndTime} setSelectedLevel={_this.setSelectedLevel}/>
            <div className="CBEvent_table">
                <TableHead filteredList={filteredList} UpdateList={_this.UpdateList} />
                {(pageSliceList !== null && pageSliceList !== undefined ?
                    pageSliceList.map(function (alarm) {
                        return <Table
                            alarm={alarm}
                            acknowledgeUpdate={ _this.acknowledgeUpdate}
                        />
                    })
                    :
                    ""
                )}
            </div>
            <div className="CBEvent_bottom">
                <Downloadcsv sortedList={sortedList} />
                <Pagebtns sortedList={sortedList} UpdateList={_this.UpdateList} count={updateCount} />
            </div>
            <PopupNotification onRef={ref => {this.PopupNotificationRef = ref}} />
        </div>
    }
}

function mapStateToProps(state) {
    return {
        socket: state.socket,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate, //when this value is updated, render would triggered
        UserInfo: state.UserInfo,
        Notification: state.Notification,
    }
}
export default connect(mapStateToProps)(ColdboxEvent)