const colorList = [
    ['rgb(0, 0, 0)', 'Black'],
    ['rgb(0, 0, 128)', 'Navy'],
    ['rgb(0, 0, 139)', 'DarkBlue'],
    ['rgb(0, 0, 205)', 'MediumBlue'],
    ['rgb(0, 0, 205)', 'MediumBlue'],
    ['rgb(0, 0, 255)', 'Blue'],
    ['rgb(0, 100, 0)', 'DarkGreen'],
    ['rgb(0, 128, 0)', 'Green'],
    ['rgb(0, 128, 128)', 'Teal'],
    ['rgb(0, 139, 139)', 'DarkCyan'],
    ['rgb(0, 191, 255)', 'DeepSkyBlue'],
    ['rgb(0, 206, 209)' ,'DarkTurquoise'],
    ['rgb(0, 250, 154)', 'MediumSpringGreen'],
    ['rgb(0, 255, 0)', 'Lime'],
    ['rgb(0, 255, 127)', 'SpringGreen'],
    ['rgb(0, 255, 255)', 'Aqua'],
    ['rgb(0, 255, 255)', 'Cyan'],
    ['rgb(25, 25, 112)', 'MidnightBlue'],
    ['rgb(30, 144, 255)', 'DodgerBlue'],
    ['rgb(32, 178, 170)', 'LightSeaGreen'],
    ['rgb(34, 139, 34)', 'ForestGreen'],
    ['rgb(46, 139, 87)', 'SeaGreen'],
    ['rgb(47, 79, 79)', 'DarkSlateGray'],
    ['rgb(47, 79, 79)', 'DarkSlateGrey'],
    ['rgb(50, 205, 50)', 'LimeGreen'],
    ['rgb(60, 179, 113)', 'MediumSeaGreen'],
    ['rgb(64, 224, 208)', 'Turquoise'],
    ['rgb(65, 105, 225)', 'RoyalBlue'],
    ['rgb(70, 130, 180)', 'SteelBlue'],
    ['rgb(72, 61, 139)', 'DarkSlateBlue'],
    ['rgb(72, 209, 204)', 'MediumTurquoise'],
    ['rgb(75, 0, 130)', 'Indigo'],
    ['rgb(85, 107, 47)', 'DarkOliveGreen'],
    ['rgb(95, 158, 160)', 'CadetBlue'],
    ['rgb(100, 149, 237)', 'CornflowerBlue'],
    ['rgb(102, 51, 153)', 'RebeccaPurple'],
    ['rgb(102, 205, 170)', 'MediumAquaMarine'],
    ['rgb(105, 105, 105)', 'DimGray'],
    ['rgb(105, 105, 105)', 'DimGrey'],
    ['rgb(106, 90, 205)', 'SlateBlue'],
    ['rgb(107, 142, 35)', 'OliveDrab'],
    ['rgb(112, 128, 144)', 'SlateGray'],
    ['rgb(112, 128, 144)', 'SlateGrey'],
    ['rgb(119, 136, 153)', 'LightSlateGray'],
    ['rgb(123, 104, 238)', 'MediumSlateBlue'],
    ['rgb(124, 252, 0)', 'LawnGreen'],
    ['rgb(127, 255, 0)', 'Chartreuse'],
    ['rgb(127, 255, 212)', 'Aquamarine'],
    ['rgb(128, 0, 0)', 'Maroon'],
    ['rgb(128, 0, 128)', 'Purple'],
    ['rgb(128, 128, 0)', 'Olive'],
    ['rgb(128, 128, 128)', 'Gray'],
    ['rgb(128, 128, 128)', 'Grey'],
    ['rgb(135, 206, 235)', 'SkyBlue'],
    ['rgb(135, 206, 250)', 'LightSkyBlue'],
    ['rgb(138, 43, 226)', 'BlueViolet'],
    ['rgb(139, 0, 0)', 'DarkRed'],
    ['rgb(139, 0, 139)', 'DarkMagenta'],
    ['rgb(139, 69, 19)', 'SaddleBrown'],
    ['rgb(143, 188, 143)', 'DarkSeaGreen'],
    ['rgb(144, 238, 144)', 'LightGreen'],
    ['rgb(147, 112, 219)', 'MediumPurple'],
    ['rgb(148, 0, 211)', 'DarkViolet'],
    ['rgb(152, 251, 152)', 'PaleGreen'],
    ['rgb(153, 50, 204)', 'DarkOrchid'],
    ['rgb(154, 205, 50)', 'YellowGreen'],
    ['rgb(160, 82, 45)',  'Sienna'],
    ['rgb(165, 42, 42)',  'Brown'],
    ['rgb(169, 169, 169)', 'DarkGray'],
    ['rgb(173, 216, 230)', 'LightBlue'],
    ['rgb(173, 255, 47)', 'GreenYellow'],
    ['rgb(175, 238, 238)', 'PaleTurquoise'],
    ['rgb(176, 196, 222)', 'LightSteelBlue'],
    ['rgb(176, 224, 230)', 'PowderBlue'],
    ['rgb(178, 34, 34)', 'FireBrick'],
    ['rgb(184, 134, 11)', 'DarkGoldenRod'],
    ['rgb(186, 85, 211)', 'MediumOrchid'],
    ['rgb(188, 143, 143)', 'RosyBrown'],
    ['rgb(189, 183, 107)', 'DarkKhaki'],
    ['rgb(192, 192, 192)', 'Silver'],
    ['rgb(199, 21, 133)', 'MediumVioletRed'],
    ['rgb(205, 92, 92)', 'IndianRed'],
    ['rgb(205, 133, 63)', 'Peru'],
    ['rgb(210, 105, 30)', 'Chocolate'],
    ['rgb(210, 180, 140)', 'Tan'],
    ['rgb(211, 211, 211)',  'LightSlateGray'],
    ['rgb(211, 211, 211)', 'LightGrey'],
    ['rgb(216, 191, 216)', 'Thistle'],
    ['rgb(218, 112, 214)', 'Orchid'],
    ['rgb(218, 165, 32)', 'GoldenRod'],
    ['rgb(219, 112, 147)', 'PaleVioletRed'],
    ['rgb(220, 20, 60)', 'CrimsonCrimson'],
    ['rgb(220, 220, 220)', 'Gainsboro'],
    ['rgb(221, 160, 221)', 'Plum'],
    ['rgb(222, 184, 135)', 'BurlyWood'],
    ['rgb(224, 255, 255)', 'LightCyan'],
    ['rgb(230, 230, 250)', 'Lavender'],
    ['rgb(233, 150, 122)', 'DarkSalmon'],
    ['rgb(238, 130, 238)', 'Violet'],
    ['rgb(238, 232, 170)', 'PaleGoldenRod'],
    ['rgb(240, 128, 128)', 'LightCoral'],
    ['rgb(240, 230, 140)', 'Khaki'],
    ['rgb(240, 248, 255)', 'AliceBlue'],
    ['rgb(240, 255, 240)', 'HoneyDew'],
    ['rgb(240, 255, 255)', 'Azure'],
    ['rgb(244, 164, 96)', 'SandyBrown'],
    ['rgb(245, 222, 179)', 'Wheat'],
    ['rgb(245, 245, 220)', 'Beige'],
    ['rgb(245, 245, 245)', 'WhiteSmoke'],
    ['rgb(245, 255, 250)', 'MintCream'],
    ['rgb(248, 248, 255)', 'GhostWhite'],
    ['rgb(250, 128, 114)', 'SalmonSalmon'],
    ['rgb(250, 235, 215)', 'AntiqueWhite'],
    ['rgb(250, 240, 230)', 'Linen'],
    ['rgb(250, 250, 210)', 'LightGoldenRodYellow'],
    ['rgb(253, 245, 230)', 'OldLace'],
    ['rgb(255, 0, 0)', 'Red'],
    ['rgb(255, 0, 255)', 'FuchsiaFuchsia'],
    ['rgb(255, 0, 255)', 'Magenta'],
    ['rgb(255, 20, 147)', 'DeepPink'],
    ['rgb(255, 69, 0)', 'OrangeRed'],
    ['rgb(255, 99, 71)', 'Tomato'],
    ['rgb(255, 105, 180)', 'HotPink'],
    ['rgb(255, 127, 80)', 'Coral'],
    ['rgb(255, 140, 0)', 'DarkOrange'],
    ['rgb(255, 160, 122)', 'LightSalmon'],
    ['rgb(255, 165, 0)', 'Orange'],
    ['rgb(255, 182, 193)', 'LightPink'],
    ['rgb(255, 192, 203)', 'Pink'],
    ['rgb(255, 215, 0)', 'Gold'],
    ['rgb(255, 218, 185)', 'PeachPuff'],
    ['rgb(255, 222, 173)', 'NavajoWhite'],
    ['rgb(255, 228, 181)', 'Moccasin'],
    ['rgb(255, 228, 196)', 'Bisque'],
    ['rgb(255, 228, 225)', 'MistyRose'],
    ['rgb(255, 255, 255)', 'White'],
    ['rgb(255, 255, 240)', 'Ivory'],
    ['rgb(255, 255, 224)', 'LightYellow'],
    ['rgb(255, 255, 0)', 'Yellow'],
    ['rgb(255, 250, 250)', 'Snow'],
    ['rgb(255, 250, 240)', 'FloralWhite'],
    ['rgb(255, 250, 205)', 'LemonChiffon'],
    ['rgb(255, 248, 220)', 'Cornsilk'],
    ['rgb(255, 245, 238)', 'SeaShell'],
    ['rgb(255, 240, 245)', 'LavenderBlush'],
    ['rgb(255, 239, 213)', 'PapayaWhip'],
    ['rgb(255, 235, 205)', 'BlanchedAlmond']
]
//Main function ---------------------------------------------------
const revertGreyTxt = (colorCode) => {
    if(colorCode == null || colorCode =='') return 'white'
    let rgbaColor = ''
    
    if(colorCode.includes('#')) rgbaColor = hexToRGB(colorCode)
    if(colorCode.includes('rgb')) rgbaColor = colorCode

    if(colorCode.includes('#') || colorCode.includes('rgb')) return colorCheck(rgbaColor)

    const FindColorFromList = colorList.find(colorItem => {
        return colorItem[1].toLowerCase() == colorCode.toLowerCase()
    })
    return FindColorFromList==null?'white':colorCheck(FindColorFromList[0])
}
//Main function END -----------------------------------------------
function hexToRGB(h) {
    let r = 0, g = 0, b = 0  
    // 3 digits
    if (h.length == 4) {
      r = '0x' + h[1] + h[1]
      g = '0x' + h[2] + h[2]
      b = '0x' + h[3] + h[3]  
    // 6 digits
    } else if (h.length == 7) {
      r = '0x' + h[1] + h[2]
      g = '0x' + h[3] + h[4]
      b = '0x' + h[5] + h[6]
    }    
    return 'rgb('+ +r + ',' + +g + ',' + +b + ')'
}
const colorCheck = (rgbaColor) => {
    let clearString = rgbaColor.replace('rgb', '').replace('a', '').replace('(', '').replace(')', '')
    let threeCode = clearString.split(',')
    let first = Number(threeCode[0].toString())
    let second = Number(threeCode[1].toString())
    let third = Number(threeCode[2].toString())
    let greyScale = (first+second+third)/3

    if(greyScale>=130) return 'black'
    return 'white'
}

export default revertGreyTxt