import React from 'react'
import './BLE.css'

//Redux Import
import { connect } from 'react-redux'
//import inner widgets
import Searchbar from './searchbar/searchbar'
import Table from './table/table'
import TableHead from './table/tablehead'
import Pagebtns from './bottom/pagebtns'
import Coldbox_popup from './popup/popup';
import PopupNotification from '../../../popupNotification/PopupNotification'
import Loading from '../../loading'

class ColdboxBLE extends React.Component {
    constructor(props) {
        super()

        this.state = {
            isLoading: true,
            isAdvanceSearch: false,
            rawDeviceList: null,
            DeviceList: null,
            //sorted result (by table head .js)
            sortedList: null,
            //filtered result (by searchbar.js)
            filteredList: null,
            //slice by page (by pages.js widget)
            pageSliceList: null,

            updateCount: 0,
            selectedData: null
        }
        this.getDeviceList = this.getDeviceList.bind(this)
        this.UpdateList = this.UpdateList.bind(this)
        this.refresh = this.refresh.bind(this)
        this.relist = this.relist.bind(this)
        this.ClosePopup = this.ClosePopup.bind(this)
        this.editRow = this.editRow.bind(this)
        this.setSelected = this.setSelected.bind(this)
        this.acknowledgeListener = this.acknowledgeListener.bind(this)
        this.getLocation = this.getLocation.bind(this)
        this.getZone = this.getZone.bind(this)
    }

    refresh() {
        console.log("refresh")

        const outside = this
        outside.setState(prevState => ({
            ...prevState,
            isLoading: true
        }))
        outside.getDeviceList()
        outside.relist()
    }

    getLocation(dataLoc) {
        if (dataLoc == [] || dataLoc == null || dataLoc == undefined) {
            return ""
        }

        // console.log(typeof(dataLoc))
        
        if (typeof(dataLoc) !== 'object') {
            console.log(dataLoc.length)
            return ""
        }
        if (dataLoc.length == 0) {
            return ""
        } else {
            let loc = dataLoc[0]
            if (loc !== undefined && loc !== null) {
                let loc2 = loc.nestedLocs
                if(loc2 !== undefined && loc2!== null){
                    return loc2.locName
                }else{
                    return ""
                }
            }else{
                return ""
            }
        }
 
    }

    
    getZone(dataZone) {
        if (dataZone == [] || dataZone == null || dataZone == undefined) {
            return ""
        }
 
        if (typeof(dataZone) !== 'object') {
            console.log(dataZone.length)
            return ""
        }
        if (dataZone.length == 0) {
            return ""
        } else {
            let z = dataZone[0]
            if (z !== undefined && z !== null) {
                return z.name
            }else{
                return ""
            }
        }
 
    }

    relist() {
        const outside = this
        let newList = []
        // console.log("coldbox BLE relist")
        // console.log(this.props.BeaconManageList)
        if (this.props.BeaconManageList !== null && this.props.BeaconManageList !== undefined) {
            this.props.BeaconManageList.map(function (data) {
                let tempConf = data.deviceConfiguration
                if (tempConf === null || tempConf === undefined) {
                    tempConf = {}
                }
                let dataItem = {
                    _id: data._id,
                    gatewayID: data.deviceName,
                    uniqueID: data.deviceSerial,
                    location:outside.getLocation(data.location) + " - " +  outside.getZone(data.zone) ,
                    locationObj :data.location,
                    zoneObj :data.zone,
                    deviceConfiguration: tempConf,
                    keywords: JSON.stringify(data),
                    updateTime: data.updateTime
                }
                if(data.deviceName != "BLE00079" 
                && data.deviceName != "BLE00080" 
            && data.deviceName != "BLE00081" 
                && data.deviceName != "BLE00082"
                && data.deviceName != "BLE00083"
                && data.deviceName != "BLE00084"
                && data.deviceName != "BLE00085"
                && data.deviceName != "BLE00086"
                && data.deviceName != "BLE00087"
                && data.deviceName != "BLE00088"   ){
                    newList.push(dataItem)
                }
                
            })

            // console.log(newList )
            outside.setState(prevState => ({
                ...prevState,
                DeviceList: newList,
                rawDeviceList: this.props.BeaconManageList,
                isLoading: false
            }))
        }
    }
    componentDidMount() {
        const _this = this
        this.acknowledgeListener()
        this.refresh()

        // check if is redirect from floor plan onclick
        const BLE_Id = localStorage.getItem('BLE_Id')
        if(BLE_Id == null || BLE_Id == '') return
        localStorage.setItem('BLE_Id', '')  //clear to prevent re-run again
        const {BeaconManageList} = this.props
        const BLEItem = BeaconManageList.find(item => {
            return item.deviceSerial == BLE_Id
        })

        setTimeout(function() {
            _this.setState({
                selectedData: BLEItem,
                isShowEditPopup: true,
            })
        }, 800)
    }

    componentDidUpdate() {
        if (this.state.DeviceList === null || this.state.rawDeviceList !== this.props.BeaconManageList) this.relist()
    }

    UpdateList(StateStr, Value) {
        const outside = this
        console.log("UpdateList " + StateStr)
        // console.log(Value)
        const { updateCount } = this.state
        outside.setState({
            [StateStr]: Value,
            updateCount: updateCount + 1,
        })

    }

    getDeviceList() {
        const { UserInfo } = this.props
        if (UserInfo == null) return     //exception case
        const EmitJson = {
            "msgType": "BeaconManagement",
            "method": "Get",
            "userID": UserInfo.userID,
            "client_id": UserInfo.client_id,
            "deviceType_id": "605aad52ec93eb0dd4ec5b49"
        }
        console.log(EmitJson)
        this.props.dispatch({
            type: 'EmitSocket',
            EmitSocketName: "StatRequest",      //StatRequest can request for any type data
            EmitSocketData: EmitJson
        })

        
        const EmitJson_Zone = {
            "msgType": "GetZoneList", 
            "client_id": UserInfo.client_id, 
            "userID": UserInfo.userID,
        }
        console.log(EmitJson)
        this.props.dispatch({
            type: 'EmitSocket',
            EmitSocketName: "ZoneManagement",      //GetZoneList can request for any type data
            EmitSocketData: EmitJson_Zone
        })
    }

    acknowledgeListener() {
        //create socket listener for acknowledged alarm
        const { socket } = this.props
        const thisoutside = this
        if (socket == null) {
            console.log('socket is null, BLE.js acknowledgeListener()')
            return
        }
        this.props.socket.on("DeviceConfiguration", data => {

            //display result in popup msg box
            if (thisoutside.PopupNotificationRef !== undefined) {
                if (data.result == "Success") {

                    thisoutside.PopupNotificationRef.addNotificationPopup("Update Success!", "BLE Beacon is updated.", "")
                    //Update the current popup data
                    thisoutside.refresh()
                }
                else {
                    thisoutside.PopupNotificationRef.addNotificationPopup("Update Failed!", "Failed Message: " + data.message, "")
                }
            }


        })
    }

    ClosePopup() {
        this.setState({
            isShowEditPopup: false,
            selectedData: null
        });
    }
    editRow() {
        this.setState({
            isShowEditPopup: true,
        });
    }
    setSelected(data) {
        this.setState({
            selectedData: data,
            isShowEditPopup: true,
        });
    }
    render() {
        const { DeviceList, sortedList, filteredList, pageSliceList, updateCount, selectedData } = this.state
        const {ZoneList, BeaconManageList } = this.props
        const _this = this

        return (BeaconManageList != null && ZoneList != null?
        <div className="CB_BLE">
            <div className="CB_BLE_title"> BLE Beacon Management</div>
            <Searchbar isAdvanceSearch={true} closeAdSearch={_this.closeAdSearch}
                refresh={_this.refresh}
                coldboxList={DeviceList} UpdateList={_this.UpdateList}
                setStartTime={_this.setStartTime} setEndTime={_this.setEndTime} />

            <div className="CB_BLE_table">
                <TableHead filteredList={filteredList} UpdateList={_this.UpdateList} />

                {(pageSliceList !== null && pageSliceList !== undefined ?
                    pageSliceList.map(function (data) {
                        if(data.gatewayID != "BLE00079" 
                        && data.gatewayID != "BLE00080" 
                    && data.gatewayID != "BLE00081" 
                        && data.gatewayID != "BLE00082"
                        && data.gatewayID != "BLE00083"
                        && data.gatewayID != "BLE00084"
                        && data.gatewayID != "BLE00085"
                        && data.gatewayID != "BLE00086"
                        && data.gatewayID != "BLE00087"
                        && data.gatewayID != "BLE00088"   ){
                            return <Table
                            data={data}
                            acknowledgeUpdate={_this.acknowledgeUpdate}
                            editRow={_this.editRow}
                            setSelected={_this.setSelected}
                        />
                        }
                       
                    })
                    :
                    ""
                )}
            </div>
            <div className="CB_BLE_bottom">
                {/* <Downloadcsv sortedList={sortedList} /> */}
                <Pagebtns sortedList={sortedList} UpdateList={_this.UpdateList} count={updateCount} />
            </div>

            <Coldbox_popup
                data={selectedData}
                isPopup={_this.state.isShowEditPopup}
                closePopup={_this.ClosePopup}
                reloadData={_this.refresh}
                ZoneList = {ZoneList}
            ></Coldbox_popup>
            <PopupNotification onRef={ref => { this.PopupNotificationRef = ref }} />
        </div>:
         <Loading/>)
    }
}

function mapStateToProps(state) {
    return {
        socket: state.socket,
        currentlevel: state.currentlevel,
        currentSite: state.currentSite,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate, //when this value is updated, render would triggered
        StatisticData: state.StatisticData,
        UserInfo: state.UserInfo,
        currentlevelID: state.currentlevelID,
        BeaconManageList: state.BeaconManageList, 
        ZoneList:state.ZoneList
    }
}

export default connect(mapStateToProps)(ColdboxBLE)