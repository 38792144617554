import React from 'react'
import Loading from '../../../loading'
import { connect } from 'react-redux'

class UPSAlarm extends React.Component {
    constructor(props) {
        super()
        this.renderRow = this.renderRow.bind(this)
        this.groupRow = this.groupRow.bind(this)
        this.state = {
            isLoading: false,
            payloadData: {}
        }
    }

    componentDidUpdate(prevProps) {
        const preDeviceDataLastUpdate = prevProps.DeviceDataLastUpdate
        const {DeviceDataLastUpdate} = this.props
        if(preDeviceDataLastUpdate==DeviceDataLastUpdate&&prevProps.Item==this.props.Item) return

        const {Item} = this.props
        if(Item==null||Item.payload==null) return

        this.setState({
            isLoading: false,
            payloadData: Item.payload
        })
    }

    renderRow(title, value, unit) {
        return (
            <div className="row">
                <div className="readingTitle">
                    {title}
                </div>
                <div className="reading">
                    {value} {(unit === "C" ? <sup>o</sup> : unit)}{(unit === "C" ? unit : "")}
                </div>
            </div>
        )
    }
    groupRow(title, data, unit, isbool, trueDisplay, falseDisplay, isRedWhenTrue) {
        let valueDisplay = '--' + JSON.stringify(data)
        if(isbool) {
            if(data == 1) valueDisplay = trueDisplay
            if(data == 2 || data == 0 || data == undefined || data == null) valueDisplay = falseDisplay
            if(data == 3) valueDisplay = trueDisplay + ' & ' + falseDisplay
        }

        if(!isbool) {
            const {min, max} = data
            if(min==0&&max==0) valueDisplay = trueDisplay
            if(min==1&&max==1) valueDisplay = falseDisplay
            if(min!=max) valueDisplay = trueDisplay + ' & ' + falseDisplay
        }

        let isRed = false
        if(isRedWhenTrue&&(data==1||data==3)) isRed = true
        if(isRedWhenTrue==false&&(data==2||data==3)) isRed = true

        return <div className="row">
            <div className="readingTitle">
                {title}
            </div>
            <div className="reading" style={{color: isRed?'red':''}}>
                {valueDisplay} {(unit==="C"?<sub>o</sub>:unit)}{(unit==="C"?unit:"")}
            </div>
        </div>
    }

    render() {
        const _this = this
        const {groupDisplay, isGpDisplay} = this.props
        const {payloadData} = this.state
        if(this.state.isLoading){
            return <Loading/>
        }
        if(isGpDisplay) {
            return <fieldset>
                <legend>UPS Alarm</legend> 
                <div className="Coldbox_S_table">
                    <div className="col_alarm_set_point">
                        {_this.groupRow("UPS General", groupDisplay.GeneralAlarmUPS, "", true, "Fault", "Normal")}
                        {_this.groupRow("Battery Low Fault", groupDisplay.UPSBatteryLowfault, "", true, "Fault", "Normal")}
                        {_this.groupRow("Battery Not Connected", groupDisplay.UPSBatteryNotConnected, "", true, "Fault", "Normal")}
                        {_this.groupRow("Output Shorted", groupDisplay.UPSOutputShorted, "", true, "Fault", "Normal")}
                        {_this.groupRow("Rectifire Fault", groupDisplay.UPSRectifierFault, "", true, "Fault", "Normal")}
                        {_this.groupRow("Inverter Fault", groupDisplay.UPSInverterFault, "", true, "Fault", "Normal")}
                        {_this.groupRow("Over Temperature", groupDisplay.UPSOverTemperature, "", true, "Fault", "Normal")}
                        {_this.groupRow("Fan Failure", groupDisplay.UPSFanFailure, "", true, "Fault", "Normal")}
                        {_this.groupRow("Inverter Overload", groupDisplay.UPSInverterOverload, "", true, "Fault", "Normal")}
                    </div>
                </div>
            </fieldset>
        }

        return <fieldset>
        <legend>UPS Alarm</legend> 
        <div className="Coldbox_S_table">
            <div className="col_alarm_set_point">
                {this.renderRow("UPS General", payloadData.GeneralAlarmUPS === false ? "Normal" : "Fault", "", IfIsFault(payloadData.GeneralAlarmUPS, 'GeneralAlarmUPS'))}
                {this.renderRow("Battery Low Fault", payloadData.UPSBatteryLowfault === false ? "Normal" : "Fault", "", IfIsFault(payloadData.UPSBatteryLowfault, 'UPSBatteryLowfault'))}
                {this.renderRow("Battery Not Connected", payloadData.UPSBatteryNotConnected === false ? "Normal" : "Fault", "", IfIsFault(payloadData.UPSBatteryNotConnected, 'UPSBatteryNotConnected'))}
                {this.renderRow("Output Shorted", payloadData.UPSOutputShorted === false ? "Normal" : "Fault", "", IfIsFault(payloadData.UPSOutputShorted, 'UPSOutputShorted'))}
                {this.renderRow("Rectifire Fault", payloadData.UPSRectifierFault === false ? "Normal" : "Fault", "", IfIsFault(payloadData.UPSRectifierFault, 'UPSRectifierFault'))}
                {this.renderRow("Inverter Fault", payloadData.UPSInverterFault === false ? "Normal" : "Fault", "", IfIsFault(payloadData.UPSInverterFault, 'UPSInverterFault'))}
                {this.renderRow("Over Temperature", payloadData.UPSOverTemperature === false ? "Normal" : "Fault", "", IfIsFault(payloadData.UPSOverTemperature, 'UPSOverTemperature'))}
                {this.renderRow("Fan Failure", payloadData.UPSFanFailure === false ? "Normal" : "Fault", "", IfIsFault(payloadData.UPSFanFailure, 'UPSFanFailure'))}
                {this.renderRow("Inverter Overload", payloadData.UPSInverterOverload === false ? "Normal" : "Fault", "", IfIsFault(payloadData.UPSInverterOverload, 'UPSInverterOverload'))}
            </div>
        </div>
        </fieldset>
    }
}

function mapStateToProps(state) {
    return {
        currentlevel: state.currentlevel,
        currentSite: state.currentSite,
        UserInfo: state.UserInfo,
        DeviceData: state.DeviceData,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate,
        DashboardPickedColdboxID: state.DashboardPickedColdboxID
    }
}
  
export default connect(mapStateToProps)(UPSAlarm)

function IfIsFault(value, fieldName) {
    const configItem = fieldConfig.find(item => item.name == fieldName)
    if(configItem==null) {
        // console.log('fieldConfig exception')
        // console.log('fieldName: ' + fieldName)
        return
    }

    const {type, faultV, faultMax, faultMin} = configItem

    if(type=='bool') {
        if(faultV==true&&(value==true||value==1||value=='1')) return 'red'
        if(faultV==false&&(value==false||value==0||value=='0')) return 'red'
        if(value==undefined||value==null) return 'red'  //exception: no value, highlight it red for attention
    }
    if(type=='string') {
        if(faultV==value) return 'red'
        return ''
    }
    if(type=='number') {
        if(faultMax!=null && value>faultMax) {
            return 'red'
        }
        if(faultMax!=null && value<faultMin) {
            return 'red'
        }
    }

    return ''
}

const fieldConfig = [
    //samples
    {
        name: 'bool sample',
        type: 'bool',   //bool sample
        faultV: true
    },
    {
        name: 'number sample',
        type: 'number', //number sample
        faultMax: 100,
        faultMin: null  //null means no minimum
    },
    {
        name: 'string sample',
        type: 'string', //string sample
        faultV: 'Fault String Value'
    },
    //real usage
    {
        name: 'GeneralAlarmUPS',
        type: 'bool',
        faultV: true
    },
    {
        name: 'UPSBatteryLowfault',
        type: 'bool',
        faultV: true
    },
    {
        name: 'UPSBatteryNotConnected',
        type: 'bool',
        faultV: true
    },
    {
        name: 'UPSOutputShorted',
        type: 'bool',
        faultV: true
    },
    {
        name: 'UPSRectifierFault',
        type: 'bool',
        faultV: true
    },
    {
        name: 'UPSInverterFault',
        type: 'bool',
        faultV: true
    },
    {
        name: 'UPSOverTemperature',
        type: 'bool',
        faultV: true
    },
    {
        name: 'UPSFanFailure',
        type: 'bool',
        faultV: true
    },
    {
        name: 'UPSInverterOverload',
        type: 'bool',
        faultV: true
    },
]