import React, { useEffect, useState } from 'react'
import './chart.css'

import getColorCode from '../../commonfunction'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const UsagePercentChangecolorRange = 30

function OccChartWidget(props) {
    const {ToiletType, IsDaily, ResponseData} = props
    const {color1, color2, gradient1, gradient2} = getColorCode(ToiletType)

    const [ChartData, setChartData] = useState()

    useEffect(() => {
        setChartData()  //clear
        setTimeout(()=> {
            setChartData(
                HgihChartOption(color1, color2, gradient1, gradient2, IsDaily)
            )
        }, 500)
    }, [IsDaily, ToiletType])

    if(ChartData==null) return <div className="Toilet_admin_occ_chartMain"></div>

    return <div className="Toilet_admin_occ_chartMain">
        {/* <div className="Toilet_admin_occ_barlist">
            {
                UseDataList.map(item => {
                    const Diff = MaxValue - item.v
                    const percentage = Diff/MaxValue * 100
                    const opacity = 1.2 - Diff/MaxValue
                    const txtstyle = (percentage<UsagePercentChangecolorRange)?color2:'grey'

                    if(!IsDaily) {
                        const XAxisDisplay = (item.hr % 4 == 0 || item.hr == 23)?(item.hr):'-'
                        const ReadingLocation = (item.hr % 2 == 0)
                        return <div className="Toilet_admin_occ_bar">
                            <div className={ReadingLocation?"reading":"reading moveup"} style={{color: txtstyle}}>{item.v}</div>
                            <div className="bar" style={{ background: 'linear-gradient(to bottom, transparent '+ percentage +'%, '+ color1 +' '+ percentage +'%, '+ color2 +')', opacity: opacity }}></div>
                            <div className="timeline"></div>
                            <div className="timeValue">{XAxisDisplay}</div>
                        </div>
                    }
                    else {
                        const ReadingLocation = (item.id % 2 == 0)
                        return <div className="Toilet_admin_occ_bar">
                            <div className={ReadingLocation?"reading":"reading moveup"} style={{color: txtstyle}}>{item.v}</div>
                            <div className="bar" style={{ background: 'linear-gradient(to bottom, transparent '+ percentage +'%, '+ color1 +' '+ percentage +'%, '+ color2 +')', opacity: opacity }}></div>
                            <div className="timeline"></div>
                            <div className="timeValue" style={{fontSize: '10px'}}>{item.date}</div>
                        </div>
                    }
                })
            }
        </div> */}
        <HighchartsReact highcharts={Highcharts} options={ChartData} constructorType={'chart'} className=""
            containerProps={{ style: { height: '100%' } }}/>
    </div>
}

export default OccChartWidget

//hardcode data to display
const OccTime = [
    [
        new Date('2021-04-10 08:00:00').getTime(),
        10
    ],
    [
        new Date('2021-04-10 10:00:00').getTime(),
        15
    ],
    [
        new Date('2021-04-10 12:00:00').getTime(),
        18
    ],
    [
        new Date('2021-04-10 14:00:00').getTime(),
        26
    ],
    [
        new Date('2021-04-10 16:00:00').getTime(),
        22
    ],
    [
        new Date('2021-04-10 18:00:00').getTime(),
        24
    ],
    [
        new Date('2021-04-10 20:00:00').getTime(),
        18
    ],
    [
        new Date('2021-04-10 22:00:00').getTime(),
        22
    ],
    [
        new Date('2021-04-11 00:00:00').getTime(),
        27
    ],
    [
        new Date('2021-04-11 02:00:00').getTime(),
        22
    ],
    [
        new Date('2021-04-11 04:00:00').getTime(),
        20
    ],
    [
        new Date('2021-04-11 06:00:00').getTime(),
        24
    ],
]

const OccDaily = [
    [
        new Date('2021-04-01').getTime(),
        100
    ],
    [
        new Date('2021-04-02').getTime(),
        825
    ],
    [
        new Date('2021-04-03').getTime(),
        450
    ],
    [
        new Date('2021-04-04').getTime(),
        642
    ],
    [
        new Date('2021-04-05').getTime(),
        722
    ],
    [
        new Date('2021-04-06').getTime(),
        754
    ],
    [
        new Date('2021-04-07').getTime(),
        385
    ]
]

function HgihChartOption(color1, color2, gradient1, gradient2, IsDaily) {
    const options = {
        title:{
            text:''
        },
        chart: {
            type: 'area',
            backgroundColor: 'transparent',
            zoomType: 'x'
        },
        xAxis: {
            type: 'datetime',
        },
        yAxis: {
            min: 0,
            title: {
                text: ''
            },
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            series: {
                series: {
                    stickyTracking: false
                },
                fillColor: {
                    linearGradient : {
                        x1: 0,
                        y1: 0,
                        x2: 1,
                        y2: 0
                    },
                    stops: [
                        [0, gradient1],
                        [1, gradient2]
                    ]
                },
                lineWidth: 0,
                marker: {
                    enabled: false,
                    fillColor: color1
                }
            }
        },
        series: [{
            type: 'areaspline',
            name: 'Occupancy',
            data: !IsDaily?
            OccTime
            :
            OccDaily
        }],
    }

    return options
}