import React from 'react'
import './popup.css'
import '../coldboxList.css'

//Redux Import
import { connect } from 'react-redux'

class coldbox_popup extends React.Component {
    constructor(props) {
        super()

        this.RedirectDetail = this.RedirectDetail.bind(this)
    }

    RedirectDetail() {
        const SItem = GetBLEData(this.props)

        localStorage.setItem('BLE_Id', SItem.deviceSerial)
        this.props.history.push('/Main/ColdboxManage/BLE')
    }

    render() {
        const _this = this
        const {isPopup, item,currentArea, clickedBLEID, BeaconManageList} = this.props
        if(clickedBLEID!=null) {
            const SItem = GetBLEData(_this.props)
            const GWItem = GetGWItem(_this.props)
            const IsGWItem = (GWItem!=null)
            return [
                <div className="Coldbox_Fr_popupBG" onClick={_this.props.closePopup}></div>,
                <div className="Coldbox_Fr_popupMain">
                    <div className="header">
                        {IsGWItem?'Gateway':'Floor Plan Beacon (BLE)'}
                    </div>
                    <div className="Coldbox_Fr_row">
                        <div>Device ID</div>
                        {IsGWItem?GWItem.desc:SItem.deviceName}
                    </div>
                    {
                        IsGWItem?
                        <div className="Coldbox_Fr_row">
                            <div>Status</div>
                            {GWItem.status}
                        </div>
                        :
                        <div>
                            <div className="Coldbox_Fr_row">
                                <div>Device Serial</div>
                                {SItem.deviceSerial}
                            </div>
                            <div className="Coldbox_Fr_row">
                                <div>Zone ID</div>
                                {GetZoneName(BeaconManageList, SItem.deviceName)}
                            </div>
                        </div>
                    }
                    <div className="Coldbox_Fr_row">
                        <div className="button detail" onClick={_this.RedirectDetail}>Detail</div>
                        <div className="button close" onClick={_this.props.closePopup}>Close</div>
                    </div>
                </div>
            ]
        }
        return [
            isPopup?
            <div className="Coldbox_Fr_popupBG" onClick={this.props.closePopup}></div>:<div></div>,

            isPopup?
            <div className="Coldbox_Fr_popupMain">
                <div className="header">{item.locName}</div>
                <div className="main">
                    <div className="zone">{currentArea}</div>
                    <div className="Hours">
                        <div className="hourreading">
                        {item.payload.RunHrs}
                            <div className="hourunit">hrs</div>
                        </div>
                        <div className="hourStatus">
                            {item.status.status}
                        </div>
                    </div>
                    <div className="temp">
                        <div className="Left">
                            <div className="top">
                                <div className="tempReading">
                                {item.payload.TempSetPoint}
                                <div className="tempUnit">C</div>
                                </div>
                                <div className="tempIcon">
                                    <div className="fa fa-thermometer-half"></div>
                                </div>
                            </div>
                            <div className="bottom">set</div>
                        </div>
                        <div className="Right">
                            <div className="top">
                                <div className="tempReading">
                                {item.payload.ReturnAirTemp}
                                <div className="tempUnit">C</div>
                                </div>
                                <div className="tempIcon">
                                    <div className="fa fa-thermometer-half"></div>
                                </div>
                            </div>
                            <div className="bottom">current</div>
                        </div>
                    </div>
                    <div className="humid">
                        <div className="humidReading">
                        {item.payload.RH}<div className="humidUnit">%</div>
                        </div>
                        <div className="humidIcon">
                            <div className="fa fa-tint"></div>
                        </div>
                    </div>
                </div>
            </div>
            :<div></div>
    ]
    }
}

function mapStateToProps(state) {
    return {
        BeaconManageList: state.BeaconManageList,
        DeviceData: state.DeviceData,
        currentSite: state.currentSite,
        currentlevel: state.currentlevel,
    }
}
  
export default connect(mapStateToProps)(coldbox_popup)

// BLE functions
function GetBLEData(props) {
    const {DeviceData, currentSite, currentlevel, clickedBLEID, BeaconManageList} = props
    const BLEList = GetBLEList(BeaconManageList, currentlevel)
    const CdbxCount = GetCdbxCount(DeviceData, currentSite, currentlevel)
    const arrayCountAdjust = clickedBLEID - CdbxCount - 1 //array start from 0, so -1. Also, cdbx also in count of clickBLEID, so remove the count value

    const SItem = BLEList.length<arrayCountAdjust?null:BLEList[arrayCountAdjust]

    return {
        'deviceName': SItem==null?'--':SItem.deviceName,
        'deviceSerial': SItem==null?'--':SItem.deviceSerial
    }
}
function GetBLEList(BeaconManageList, currentlevel) {
    if(BeaconManageList==null||currentlevel==null) return []
    const BLEList = BeaconManageList.filter(item => {
        if(item.location==null||item.location.length==null||item.location.length==0) return false
        if(item.location[0].nestedLocs==null) return false
        const {locName} = item.location[0].nestedLocs
        return locName == currentlevel
    })
    return BLEList
}
function GetCdbxCount(DeviceData, currentSite, currentlevel) {
    if(DeviceData==null||DeviceData.length==null||DeviceData.length==0) return 0
    const Site = DeviceData[currentSite]
    if(Site==null) return 0
    const LevelList = Site.nestedLocs
    if(LevelList==null||LevelList.length==null||LevelList.length==0) return 0
    const Level = LevelList.find(item => {
        return item.locName == currentlevel
    })
    if(Level==null) return 0
    const locList = Level.nestedLocs
    if(locList==null||locList.length==null) return 0
    return locList.length
}

function GetZoneName(BLEList, BLEName) {
    if(BLEList==null||BLEList.length==null) return 'no BLEList'
    const BLEItem = BLEList.find(item => {
        return item.deviceName == BLEName
    })
    if(BLEItem==null) return 'no BLE item found'
    if(BLEItem.zone==null||BLEItem.zone.length==null||BLEItem.zone.length==0) return 'no zone item'
    if(BLEItem.zone[0].name == null) return 'no zone name in zone item'
    return BLEItem.zone[0].name
}

// GW function
function GetGWList(props) {
    const {DeviceData, currentSite, currentlevel} = props
    if(DeviceData==null||currentSite==null||currentlevel==null) return []
    const Site = DeviceData[currentSite]
    if(Site==null) return []
    const LevelList = Site.nestedLocs
    if(LevelList==null||LevelList.length==null) return []
    const Level = LevelList.find(item => {
        return item.locName == currentlevel
    })
    if(Level==null) return []
    const equipList = Level.equipments
    if(equipList==null||equipList.length==null) return []
    const returnList = equipList.map(item => {
        return {
            id: item._id,
            status: item.status,
            desc: (item.locUI==null)?'locUI missing':item.locUI.desc,
            coor: (item.locUI==null||item.locUI.indicator==null||item.locUI.indicator.point==null||item.locUI.indicator.point[0]==null)?'--':item.locUI.indicator.point[0].coordinates,
        }
    })
    return returnList
}
function GetGWItem(props) {
    const {DeviceData, currentSite, currentlevel, clickedBLEID, BeaconManageList} = props
    const GWList = GetGWList(props)
    const BLEList = GetBLEList(BeaconManageList, currentlevel)
    const CdbxCount = GetCdbxCount(DeviceData, currentSite, currentlevel)
    const arrayCountAdjust = clickedBLEID - CdbxCount - BLEList.length - 1

    const GWItem = GWList.length<arrayCountAdjust?null:GWList[arrayCountAdjust]

    return GWItem
}