import React from 'react';

//import SummartCard from './SummaryCard/SummaryCard';
//import SummartCard2 from './SummaryCard/SummaryCard2';
//import SummartCard3 from './SummaryCard/SummaryCard3';
//import SummartCard4 from './SummaryCard/SummaryCard4';

class Summary extends React.Component {
    render() {
        return <div className="summary_outside">
            <div className="summary_head">
                <div className="text-left testlangdiv">
                    <i className="fa fa-university headericon"></i>
                    Summary
                </div>
            </div>
        </div>
    }
}

export default Summary;