import React from 'react'
import './table.css'
import { connect } from 'react-redux'

class Table extends React.Component {
    constructor(props) {
        super()
    }

    render() {
        const {coldboxList} = this.props
        const _this = this
        if(coldboxList==null) {
            return <div className="ColdboxIndi_tableRow">
                <div></div>
            </div>
        }
        if(coldboxList.length == null || coldboxList.length == 0) {
            return <div className="ColdboxIndi_tableRow">
                No Coldbox Record
            </div>
        }
        return coldboxList.map(function(coldboxItem, index) {
            const {MTBF, MTTR, faultRate, faultCount} = coldboxItem

            const {RunHrs, ColdboxID, UPSBatteryCapacity} = coldboxItem.payload
            const BatteryPW = (UPSBatteryCapacity==null)?0:UPSBatteryCapacity
            const deviceSerial = ColdboxID

            const Availability = (coldboxItem.status==null||coldboxItem.status.status==null)?'Disconnected':coldboxItem.status.status

            return <div className="ColdboxIndi_tableRow">
                <div className="Col1">{deviceSerial}</div>
                <div className="Col2">{BatteryPW}/100</div>
                <div className="Col2" style={{color: (Availability=='Disconnected')?'red':''}}>{Availability}</div>
                <div className="Col2">{MTBF}</div>
                <div className="Col2">{MTTR}</div>
                <div className="Col2">{faultRate}</div>
                <div className="Col2">{faultCount}</div>
                <div className="Col2">{RunHrs}</div>
                <div className="Col3">
                    <div className="ColdboxIndi_editBtn" onClick={() => _this.props.editPopup(coldboxItem)}>
                        <div className="fa fa-edit"></div>
                    </div>
                </div>
            </div>
        })
    }
}

function mapStateToProps(state) {
    return {}
}  
export default connect(mapStateToProps)(Table)