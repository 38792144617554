export default function getTransFromLocInfo(locInfo, DeviceData) {
    const {siteName, lvlName, locName} = getLocNames(locInfo)
    const {siteName_eng, siteName_chin} = getSiteNames(DeviceData, siteName)
    const {lvlName_eng, lvlName_chin} = getLvlNames(DeviceData, siteName, lvlName)

    return {
        chin: {
            siteName: siteName_chin,
            lvlName: lvlName_chin,
            locName: locName
        },
        eng: {
            siteName: siteName_eng,
            lvlName: lvlName_eng,
            locName: locName
        }
    }
}

//sub functions
function getLocNames(locInfo) {
    if(locInfo==null) return
    
    let siteName, lvlName, locName
    locInfo.forEach(locItem => {
        if(locItem.lvl == 1) siteName = locItem.name
        if(locItem.lvl == 2) lvlName = locItem.name
        if(locItem.lvl == 3) locName = locItem.name
    })

    return {
        siteName: siteName,
        lvlName: lvlName,
        locName: locName
    }
}
function getSiteNames(DeviceData, siteName) {
    if(DeviceData==null || siteName==null) return {
        siteName_eng: '--',
        siteName_chin: '--'
    }

    let siteItem = DeviceData.find(item => item.locName == siteName)
    if(siteItem==null) return {
        siteName_eng: '--',
        siteName_chin: '--'
    }

    return {
        siteName_eng: siteItem.locName,
        siteName_chin: siteItem['locName_zh-hk']==null?siteItem.locName:siteItem['locName_zh-hk']
    }
}
function getLvlNames(DeviceData, siteName, lvlName) {
    if(DeviceData==null || siteName==null || lvlName==null) return {
        lvlName_eng: '--',
        lvlName_chin: '--'
    }

    let siteItem = DeviceData.find(item => item.locName == siteName)
    if(siteItem==null) return {
        lvlName_eng: '--',
        lvlName_chin: '--'
    }

    let lvlItem = siteItem.nestedLocs.find(item => item.locName == lvlName)
    if(lvlItem==null) return {
        lvlName_eng: '--',
        lvlName_chin: '--'
    }

    return {
        lvlName_eng: lvlItem.locName,
        lvlName_chin: lvlItem['locName_zh-hk']==null?lvlItem.locName:lvlItem['locName_zh-hk']
    }
}

// const HardDeviceData = [
//     {
//         client_id: "60f544b8ac68e9329cfb244c",
//         img: "",
//         imgUrl: "YT",
//         locDesc: "Yan Tin Estate",
//         'locDesc_zh-hk': "欣田邨",
//         locIdx: 1,
//         locLvl: 1,
//         locName: "Yan Tin Estate",
//         'locName_zh-hk': "欣田邨",
//         nestedLocs: [
//             {
//                 locDesc: "G/F",
//                 'locDesc_zh-hk': "地下",
//                 locIdx: 1,
//                 locLvl: 2,
//                 locName: "G",
//                 'locName_zh-hk': "地下",
//                 nestedLocs: [
//                     {
//                         lastUpdate: "2021-09-24T01:35:00.085Z",
//                         locIdx: 21,
//                         locLvl: 3,
//                         locName: "CPS-002",
//                         locUI: {
//                             desc: "Disabled parking, PC93",
//                             'desc_zh-hk': "傷健車位, 2號停車場PC93露天車位"
//                         }
//                     }
//                 ]
//             }
//         ]
//     }
// ]
// const HardLocInfo = [
//     {id: '60f54a00ac68e9329cfb2450', lvl: 1, name: 'Yan Tin Estate'},
//     {id: '60f54a00ac68e9329cfb244d', lvl: 2, name: 'G'},
//     {id: '6110c8a28542bb2660ed483f', lvl: 3, name: 'CPS-002'}
// ]

//trigger and test
// console.log(JSON.stringify(getTransFromLocInfo(HardLocInfo, HardDeviceData)))