import React, { useState, useEffect } from 'react'
import './options.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faTimes } from '@fortawesome/free-solid-svg-icons'

function OptionWidget(props) {
    const {OptionIsOpen} = props      //state saved in parent local state
    const {setOptionIsOpen} = props   //set parent state function
    const [DailyOrMonth, setDailyOrMonth] = useState(false)
    const [TableOrChart, setTableOrChart] = useState(true)
    const [TypeFilter, setTypeFilter] = useState([true, true, true])
    const [UpdateCount, setUpdateCount] = useState(0)

    const {updateFunction, optionFunction} = props          ///function

    useEffect(() => {
        //update parent local state
        updateFunction(TypeFilter)
        optionFunction(DailyOrMonth, TableOrChart)
    }, [DailyOrMonth, TableOrChart, UpdateCount])

    function OpenPopup() {
        setOptionIsOpen(true)
    }
    function ChangeTypeFilter(input) {
        let newTypeFilter = TypeFilter
        const isSelectAll = (TypeFilter[0] == true && TypeFilter[1] == true && TypeFilter[2] == true)
        switch(input) {
            case 1:
                if(isSelectAll) newTypeFilter = [true, false, false]
                else if(TypeFilter[0] == false) newTypeFilter = [true, TypeFilter[1], TypeFilter[2]]
                else newTypeFilter = [false, TypeFilter[1], TypeFilter[2]]
                break
            case 2:
                if(isSelectAll) newTypeFilter = [false, true, false]
                else if(TypeFilter[1] == false) newTypeFilter = [TypeFilter[0], true, TypeFilter[2]]
                else newTypeFilter = [TypeFilter[0], false, TypeFilter[2]]
                break
            case 3:
                if(isSelectAll) newTypeFilter = [false, false, true]
                else if(TypeFilter[2] == false) newTypeFilter = [TypeFilter[0], TypeFilter[1], true]
                else newTypeFilter = [TypeFilter[0], TypeFilter[1], false]
                break
        }
        console.log(input)
        console.log(!isSelectAll)
        console.log(TypeFilter)
        setTypeFilter(newTypeFilter)
        setUpdateCount(UpdateCount+1)
    }

    return !OptionIsOpen?
    <div className="Lift_DrStat_optBtn" onClick={OpenPopup}>
        <FontAwesomeIcon icon={faFilter} className="fa" />
    </div>
    :
    <div className="Lift_DrStat_optPopup" key="DrStatPopup">
        <div className="LiftDrSt_pophead">
            <div className="LiftDrSt_popheadtxt">Options</div>
            <div className="LiftDrSt_popheadbtn" onClick={()=>setOptionIsOpen(false)}>
                <FontAwesomeIcon icon={faTimes} className="fa" />
            </div>
        </div>
        <div className="LiftDrSt_popbody">
            <div className="LiftDrSt_popbodyL">
                <div className={DailyOrMonth?"LiftDrSt_popCheckbox1 Daily":"LiftDrSt_popCheckbox1 Month"} onClick={()=>setDailyOrMonth(!DailyOrMonth)}>
                    {
                        DailyOrMonth?"Daily":"Month"
                    }
                </div>
                <div className={TableOrChart?"LiftDrSt_popCheckbox2 Table":"LiftDrSt_popCheckbox2 Chart"} onClick={()=>setTableOrChart(!TableOrChart)}>
                    {
                        TableOrChart?"Table":"Chart"
                    }
                </div>
            </div>
            <div className="LiftDrSt_popbodyR">
                <div className={(TypeFilter[0]==true?"LiftDrSt_popType Door highlight":"LiftDrSt_popType Door")} onClick={()=>ChangeTypeFilter(1)}>
                    {
                        TypeFilter[0]?"+ Door Open":"Door Open"
                    }
                </div>
                <div className={(TypeFilter[1]==true?"LiftDrSt_popType Startup highlight":"LiftDrSt_popType Startup")} onClick={()=>ChangeTypeFilter(2)}>
                    {
                        TypeFilter[1]?"+ Startup":"Startup"
                    }
                </div>
                <div className={(TypeFilter[2]==true?"LiftDrSt_popType Direct highlight":"LiftDrSt_popType Direct")} onClick={()=>ChangeTypeFilter(3)}>
                    {
                        TypeFilter[2]?"+ Direct Reverse":"Direct Reverse"
                    }
                </div>
            </div>
        </div>
    </div>
}

export default OptionWidget