import React, { useEffect, useState } from 'react'
import './draglayout.scss'
import { WidthProvider, Responsive } from 'react-grid-layout'
import genWidgetByID from './widgetList/widgetList'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faLock } from '@fortawesome/free-solid-svg-icons'

const ResponsiveGridLayout = WidthProvider(Responsive)

const DragLayoutV2 = (props) => {
    const [layouts, setLayouts] = useState(props.layouts==null?layoutsSample:props.layouts)
    const [isLock, setIsLock] = useState(true)

    useEffect(() => {
        setLayouts([])
        setTimeout(() => {
            setLayouts(layouts.map(item => {
                return {
                    ...item,
                    isResizable: !isLock,
                    static: isLock
                }
            }))
        }, 100)

        if(isLock==true) {
            //send the widget data to server and save in DB
            //..
            console.log(layouts)
        }
    }, [isLock, props.layouts])

    const UpdateLayout = (layout) => {
        setLayouts(layout)
    }
    return [
        <ResponsiveGridLayout
            className='dragLayout2_container'
            layouts={layouts}
            onLayoutChange={UpdateLayout}
            cols={Columns}
            breakpoints={breakpoint}
            rowHeight={100}
        >
            {layouts.map(item => genWidgetByID(item))}
        </ResponsiveGridLayout>,
        <div className='lockBtn' onClick={()=>setIsLock(!isLock)}>
            <FontAwesomeIcon icon={isLock?faEdit:faLock} />
        </div>
    ]
}
export default DragLayoutV2

//Configs:
const layoutsSample = [         //When no layout input, show this sample
    {i: 'a', x: 0, y: 0, w: 2, h: 1},
    {i: 'b', x: 10, y: 0, w: 6, h: 2, minW: 6, minH: 2},
    {i: 'c', x: 4, y: 0, w: 2, h: 1}
]
const Columns = {lg: 12, md: 10, sm: 7, xs: 4, xxs: 2}             //define the columns in different width size
const breakpoint = {lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}   //define the width size