function convertDate(DateInput)
{
    let DateMonth = DateInput.getMonth() + 1
    let DateDay = DateInput.getDate()
    if(DateMonth < 10) DateMonth = "0" + DateMonth
    if(DateDay < 10) DateDay = "0" + DateDay
    let HourStr = DateInput.getHours()
    if(HourStr < 10) HourStr = "0" + HourStr
    let MinutesStr = DateInput.getUTCMinutes()
    if(MinutesStr < 10) MinutesStr = "0" + MinutesStr
    const DateStr = DateInput.getFullYear() + "-" + DateMonth + "-" + DateDay + "T" + HourStr + ":" + MinutesStr + ":00Z"
    return DateStr
}

export default convertDate