import React from 'react'
import './SearchBar.css'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import moment from 'moment'

class Searchbar extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            startdate: new Date(moment().subtract(30, 'days')),
            enddate: new Date(),
            //option list
            deviceIdList: ['All'],
            filterDevice: '',
            //filter (txt1 or txt2) and (txt3 or txt4)
            filterTxt1: '',
            //Filter Result
            Filtered: null,
        }

        this.GenOptions = this.GenOptions.bind(this)
        this.FilterOnchange = this.FilterOnchange.bind(this)
        this.Filter = this.Filter.bind(this)
        this.setStartTime = this.setStartTime.bind(this)
        this.setEndTime = this.setEndTime.bind(this)
    }
    componentDidMount() {
        var { performanceList } = this.props
        if (performanceList == null || performanceList == undefined) return
        this.GenOptions()
    }
    componentDidUpdate(preProps) {
        //Re-gen option list when data is updated
        var { performanceList } = this.props
        var oldperformanceList = preProps.performanceList
        if (performanceList != oldperformanceList || (performanceList != null && oldperformanceList == null)) this.GenOptions()
    }
    
    GenOptions() {
        const { performanceList } = this.props
        console.log("performanceList:" + performanceList)
        if (performanceList === null || performanceList === undefined) {
            return
        } else {
            var Options = performanceList.map(function (performanceItem, index) {
                // const { ame } = performanceItem
                console.log("performanceItemName:" + performanceItem.Name)
                return performanceItem.Name
                
            })
            console.log(Options + 'Options')

            //DeviceId List
            // var DeviceIdList = Options.map(item => item.Name)
            //     .filter((value, index, self) => self.indexOf(value) === index)
            Options = Options.sort()
            this.setState({
                deviceIdList: Options,
            })
            this.Filter()
        }
        this.Filter()
    }
    FilterOnchange(keyStr, e) {
        try {
            // if (keyStr === "filterLevel") {
            //     let obj = this.state.floorList.find(o => o._id === e.target.value)
            //     const id = e.target.value.toString()
            //     const name = obj.locName.toString()
            //     this.props.setSelectedLevel(id)
            //     this.setState({
            //         [keyStr]: name,
            //     }, function () {        //run right after state is set
            //         this.Filter()       //re-filter
            //     })
            // } else {
                const value = e.target.value.toString()
                this.setState({
                    [keyStr]: value,
                }, function () {        //run right after state is set
                    this.Filter()       //re-filter
                })
            // }

        }
        catch (err) {
            console.log(err)
        }
    }

    Filter() {
        const { filterDevice, filterTxt1} = this.state
        const { performanceList } = this.props
        
        var FilteredList = performanceList
        if (filterDevice != null && filterDevice != "") {
            FilteredList = FilteredList.filter(function (item) {
                console.log(item.Name + 'Name' + filterDevice)
                return item.Name == filterDevice.toString()
            })
        }
        var flag1 = filterTxt1 != null && filterTxt1 != ""
        // var flag2 = filterTxt2 != null && filterTxt2 != ""
        if (flag1) {
            FilteredList = FilteredList.filter(function (item) {
                return (flag1 && (item.Duration.toString().includes(filterTxt1.toString()) || item.Name.toString().toUpperCase().includes(filterTxt1.toString().toUpperCase())))
            })
        }
        // }
        // flag1 = filterTxt3 != null && filterTxt3 != ""
        // flag2 = filterTxt4 != null && filterTxt4 != ""
        // if (flag1 || flag2) {
        //     FilteredList = FilteredList.filter(function (coldboxItem) {
        //         return (flag1 && coldboxItem.status.toString().includes(filterTxt3.toString()) || flag2 && coldboxItem.status.toString().includes(filterTxt4.toString()))
        //     })
        // }

        console.log('FilteredList----2'+ FilteredList)
        this.setState({
            Filtered: FilteredList
        })
        this.props.UpdateList("filter", FilteredList)
    }


    setStartTime(data) {
        this.setState({ startdate: data })
        this.props.setStartTime(data)
    }

    setEndTime(data) {
        this.setState({ enddate: data })
        this.props.setEndTime(data)
    }

    render() {
        const _this = this

        const { deviceIdList} = this.state

        return <div className='PRSearch_Main'>
            <div className='PRSearch_Block'>
                <div className="PRSearch_left">
                    <div className="PRSearch_txt">From:</div>
                    <div className="PRDatepicker">
                        <DatePicker
                            selected={this.state.startdate}
                            onChange={this.setStartTime}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="d-MMMM yyyy HH:mm"
                            timeCaption="time"
                            className='cdbxEPicker'
                        />
                    </div>
                    <div className="PRSearch_txt">To:</div>
                    <div className="PRDatepicker">
                        <DatePicker
                            selected={this.state.enddate}
                            onChange={this.setEndTime}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="d-MMMM yyyy HH:mm"
                            timeCaption="time"
                            className='cdbxEPicker'
                        />
                    </div>
                </div>
                        <div className="PRSearch_hiddenSearches">
                            <div className="PRSearch_txt">Device ID:</div>
                            <select className="PRSearch_select" onChange={(e) => _this.FilterOnchange('filterDevice', e)}>
                                <option value="">All</option>
                                {
                                    (deviceIdList == null || deviceIdList.length == null || deviceIdList.length == 0) ?
                                        <option style={{ display: 'none' }}></option>
                                        :
                                        deviceIdList.map(function (deviceIdItem) {
                                            return <option value={deviceIdItem}>{deviceIdItem}</option>
                                        })
                                }
                            </select>
                        </div>
            </div>
                <div className='PRSearch_Block'>
                    <div className='PRSearch_combine_title'></div>     
                </div>
                <div className='PRSearch_combine_title'>Keyword:</div>
                <div className='PRSearch_Block'>
                    <div className='PRSearch_combine_line'>
                        <input className="PRSearch_input" placeholder="Keyword search" onChange={(e) => _this.FilterOnchange('filterTxt1', e)}></input>
                    </div>
                </div>
                <div className="PRSearch_adSearchBtn" onClick={_this.props.refresh}>
                    <div className="fa fa-refresh"/>
                </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {

        UserInfo: state.UserInfo,
        currentSite: state.currentSite,
    }
}
export default connect(mapStateToProps)(Searchbar)