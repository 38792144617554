import React from 'react'
import './downloadcsv.css'
import { connect } from 'react-redux'
import { CSVLink, CSVDownload } from "react-csv"

class Downloadcsv extends React.Component {
    constructor(props) {
        super()
        this.state = {
            csvData: null,              //array, with items contain the keyStr
        }
        this.PrepareData = this.PrepareData.bind(this)
        this.ClearData = this.ClearData.bind(this)
    }

    PrepareData() {
        const {sortedList} = this.props
        if(sortedList == null || sortedList.length == null || sortedList.length == 0) return           //exception case
        this.setState({
            csvData: sortedList,
        }, function() {
            setTimeout(function(){
                document.getElementById("downloadCSVBTN").click()
                document.getElementById("downloadCSVBTN_link").click()
            }, 1000)
        })
    }

    ClearData() {
        const _this = this
        setTimeout(function(){
            _this.setState({
                csvData: null
            })
        }, 1000)
    }

    render() {
        const {csvData} = this.state
        const {sortedList} = this.props
        const _this = this

        if(csvData != null) {
            return <div className="Planner_downloadcsv" onClick={_this.ClearData} id="downloadCSVBTN">
                <CSVLink data={csvData} headers={this.props.headers} filename={this.props.filename} id="downloadCSVBTN_link">
                    Download csv
                </CSVLink>
                <div className="fa fa-check"></div>
            </div>
        }
        return <div className="Planner_downloadcsv" onClick={this.PrepareData}>Download csv</div>
    } 
     
}

function mapStateToProps(state) {
    return {
    }
}
export default connect(mapStateToProps)(Downloadcsv)