import React, { useEffect, useState } from 'react'
import './fallalarm.css'

import FallSvgIcon from './image.svg'

function FallAlarmWidget(props) {
    const {fallTime} = props
    const [Minute, setMinute] = useState(0)
    const [Second, setSecond] = useState(0)

    const [isShowDetail, setisShowDetail] = useState(false)

    useEffect(() => {
        const interval = setInterval(() => {
            UpdateDateTime()
        }, 1000)

        return () => {
          clearInterval(interval)
        }
    }, [])

    function UpdateDateTime() {
        const current_T = new Date()
        const DateTimeDiff = (current_T - fallTime) / 1000
        const newMinute = parseInt(DateTimeDiff / 60)
        const newSecond = DateTimeDiff %  60
        setMinute(newMinute)
        setSecond(newSecond)
    }

    return <div className="Toilet_occ_fallalarm" onMouseOver={() => setisShowDetail(true)} onMouseLeave={() => setisShowDetail(false)} style={{minWidth: isShowDetail?'12rem':'6rem'}}>
        <img src={FallSvgIcon} />
        {
            isShowDetail?
            <div className="Toilet_occ_fallalarm_desc">Fall Alarm Lasted for:</div>
            :
            <div style={{display: 'none'}}></div>
        }
        <div>{ConvertTimeDisplay(Minute, Second)}</div>
    </div>
}

export default FallAlarmWidget

function ConvertTimeDisplay(Minute, Second) {
    let displaymin, displaysec
    if(Minute < 10) displaymin = '0' + Minute.toString()
    else displaymin = Minute.toString()
    if(Second < 10) displaysec = '0' + Second.toFixed(0)
    else displaysec = Second.toFixed(0)

    if(Minute < 0) return displaysec
    else return displaymin + ':' + displaysec
}