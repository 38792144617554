import React, { useEffect, useRef, useState } from 'react'
import './CentralSummary.css'
import Moment from 'react-moment'

import bannerImg from './image/banner.png'
import icon_humImg from './image/icon_hum.png'
import icon_tempImg from './image/icon_temp.png'
import ieqImg from './image/ieq.png'
import logoImg from './image/logo.png'
import wc_fImg from './image/wc_f.png'
import wc_mImg from './image/wc_m.png'

const updateInterval = 30        // unit: second
const InitData = [
	{
		'timestamp': '',
		'dataField': 'L0-female',
		'data': 0
	},
	{
		'timestamp': '',
		'dataField': 'L0-male',
		'data': 0
	},
	{
		'timestamp': '',
		'dataField': 'L1-female',
		'data': 0
	},
	{
		'timestamp': '',
		'dataField': 'L1-male',
		'data': 0
	},
	{
		'timestamp': '',
		'dataField': 'L2-female',
		'data': 0
	},
	{
		'timestamp': '',
		'dataField': 'L2-male',
		'data': 0
	},
	{
		'timestamp': '',
		'dataField': 'Temperature',
		'data': 0
	},
	{
		'timestamp': '',
		'dataField': 'Humidity',
		'data': 0
	},
	{
		'timestamp': '',
		'dataField': 'CO2',
		'data': 0
	},
	{
		'timestamp': '',
		'dataField': 'PM2.5',
		'data': 0
	}
]

const CentralPublicPage = props => {
    const [time, setTime] = useState(new Date().toString())
    const [res, setRes] = useState(InitData)
	const [mainStyle, setMainStyle] = useState()
	const restUrl = getUrl(props)
	console.log(restUrl)

    useEffect(() => {							//loop timer trigger function + display of date time
        const interval = setInterval(() => {
            const newTime = new Date().toString()
            setTime(newTime)
        }, updateInterval * 1000)
        return () => {
            clearInterval(interval)
        }
    }, [time])

    useEffect(() => {							//request socket emit
		async function CallRestful() {
			const data = await restfulData(restUrl)
	
			console.log(data)
			if(data==null || data.result != 'Success' || data.dataList == null) return
			setRes(data.dataList)
		}
		CallRestful()
		
		getScalingCSS(setMainStyle)
    }, [time])

	// useLayoutEffect(() => {				//when resize layout
	// 	getScalingCSS(setMainStyle)
	// })

	const Data = formatRepData(res)
	console.log(mainStyle)

    return <div className='centralBody' style={mainStyle}>
        <div className="cm-pv-head">
        	<img className="cm-pv-head-logo" src={logoImg} />
            <img className="cm-pv-head-banner" src={bannerImg} />
            <div className="cm-pv-head-time">
				<span><Moment format='DD MMMM, YYYY'>{time.toString()}</Moment></span>
				<span><Moment format="HH:mm">{time.toString()}</Moment></span>
			</div>
		</div>
		<div className="cm-pv-body">
			<div className="cm-pv-body-male">
				<div><img src={wc_mImg} /></div>
				<dl>
					<dt>
						<div>樓層<br />FLOOR</div>
						<div>閒置廁格<br />VACANCY</div>
					</dt>
					<dd>
						<div>2<span>F</span></div>
						<div className={OccValueColor(Data['L2-male'])}>{Data['L2-male']}</div>
					</dd>
					<dd>
						<div>1<span>F</span></div>
						<div className={OccValueColor(Data['L1-male'])}>{Data['L1-male']}</div>
					</dd>
					<dd>
						<div>G<span>F</span></div>
						<div className={OccValueColor(Data['L0-male'])}>{Data['L0-male']}</div>
					</dd>
				</dl>
			</div>
			<div className="cm-pv-body-female">
				<div><img src={wc_fImg} /></div>
				<dl>
					<dt>
						<div>樓層<br />FLOOR</div>
						<div>閒置廁格<br />VACANCY</div>
					</dt>
					<dd>
						<div>2<span>F</span></div>
						<div className={OccValueColor(Data['L2-female'])}>{Data['L2-female']}</div>
					</dd>
					<dd>
						<div>1<span>F</span></div>
						<div className={OccValueColor(Data['L1-female'])}>{Data['L1-female']}</div>
					</dd>
					<dd>
						<div>G<span>F</span></div>
						<div className={OccValueColor(Data['L0-female'])}>{Data['L0-female']}</div>
					</dd>
				</dl>
			</div>
			<div className="cm-pv-body-ieq">
				<div><img src={ieqImg} /></div>
				<dl>
					<dd>
						<div><img src={icon_tempImg} /></div>
						<div className="cm-pv-grey">
						{Data['Temperature']}<span><sup>o</sup>C</span>
						</div>
					</dd>
					<dd>
						<div><img src={icon_humImg} /></div>
						<div className="cm-pv-grey">{Data['Humidity']}<span>%</span></div>
					</dd>
					<dd>
						<div>CO<sub>2</sub></div>
						<div style={{color: IAQValueColor(Data['CO2'], 'CO2')}}>
							{Data['CO2']}<span>ppm</span>
						</div>
					</dd>
					<dd>
						<div>PM<sub>2.5</sub></div>
						<div style={{color: IAQValueColor(Data['PM2.5'], 'PM2.5')}}>
							{Data['PM2.5']}<span>µg/m<sup>3</sup></span>
						</div>
					</dd>
				</dl>
			</div>
		</div>
    </div>
}

export default CentralPublicPage

//restful function
const getUrl = () => {
	const port = window.IoTConfig==null?'999':window.IoTConfig.CentralMarketPublicPagePort
	const currentUrl = window.location.href

	if(currentUrl.includes('localhost')) return 'https://47.52.20.40:' + port + '/AtalAPI/v1/CCG_PublicDataList'		//local testing

	const splitUrl = currentUrl.split('://')
	if(splitUrl.length < 2) return 'split 1 fail'

	const urlWithoutHttp = splitUrl[1]
	const secondSplit = urlWithoutHttp.split(':')

	return 'https://' + secondSplit[0].replace('/#/Central', '').replace('/Central', '') + ':' + port + '/AtalAPI/v1/CCG_PublicDataList'
}
const restfulData = async restUrl => {
	return fetch(restUrl)
	.then(response => response.json())
	.then(data => {
		return data
	})
}

// display functions
const formatRepData = resp => {
	if(resp==null||resp.length==null) return
	let result = {}
	resp.forEach(item => {
		const {dataField, data} = item
		if(dataField==null||data==null) return

		result[dataField] = data
	})

	return result
}

const OccValueColor = value => {
	if(value == null || value == 0) return 'cm-pv-grey'
	return ''
}

const IAQValueColor = (value, type) => {
	if(value==null||type==null) return 'grey'
	switch(type) {
		case 'CO2':
			if(value <= 1800) return 'green'
			break
		case 'PM2.5':
			if(value <= 50) return 'green'
			break
	}
	return '#a8b300'
}

// display size function
const getScalingCSS = setMainStyle => {
	const window_w = window.screen.width
	const window_h = window.screen.height
	const MaxCon_w = 1280	//px
	const MaxCon_h = 800 	//px

	if(MaxCon_w / window_w <= 1 && MaxCon_h / window_h <= 1) return		// fit in screen already

	let maxRatio = 1
	if(MaxCon_w / window_w >= 1) maxRatio = window_w / MaxCon_w
	if(window_h / MaxCon_h < maxRatio) maxRatio = window_h / MaxCon_h
	const W_diff = MaxCon_w - window_w
	const H_diff = MaxCon_h - window_h
	const movedRightBy = (W_diff/maxRatio/2 * -1)
	const movedDownBy = (H_diff/maxRatio/2 * -1)

	setMainStyle({
		transform: 'scale(' + maxRatio + ') translate(' + movedRightBy + 'px, ' + movedDownBy + 'px)'
	})
}