import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import './WorkOrderDetail.css'
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import { useFormik } from 'formik';
import { Checkbox } from "@material-ui/core";

const axios = require('axios');
function WorkOrderDetail(props) {

    const initialWorkOrder = {
        type: "Dynamic",
        status: "Pending Resume",
        schedule: {
            suspension: false,
            status: "Temp"
        },
    }

    var mode = props.history.location.state?.mode
    var data = props.history.location.state?.data
    const [workOrder, setWorkOrder] = useState(initialWorkOrder)
    const [number, setNumber] = useState()
    const [deviceList, setDeviceList] = useState([])
    const [partList, setPartList] = useState([])
    const [staffList, setStaffList] = useState([])
    const [vendorList, setVendorList] = useState([])
    const [selectedPartList, setSelectedPartList] = useState([])
    const [fixedPartList, setFixedPartList] = useState([])
    const [requestDate, setRequestDate] = useState(new Date())
    const [scheduleStart, setScheduleStart] = useState()
    const [scheduleEnd, setScheduleEnd] = useState()
    const [deviceType, setDeviceType] = useState("Cold Box")
    const history = useHistory()

    const RequestData_WorkOrder = {
        "method": "Read",
        "info": {
            "order_id": data?.order_id
        }
    }
    const RequestData_DeviceParts = {
        "options": {
            "deviceType": deviceType
        }
    }
    const RequestData_VendorList = {
        "options": {
            "type": [
                "Vendor"
            ]
        }
    }
    const RequestData_StaffList = {
        "options": {
            "type": [
                "Site Admin",
                "Client Admin",
                "Operators"
            ]
        }
    }
    const RequestData_Delete = {
        "method": "Delete",
        "info": {
            "order_id": data?.order_id
        }
    }

    useEffect(() => {
        if (deviceType === undefined || deviceType === "") return
        axios.post('https://47.52.20.40:31001/AtalAPI/v1/CPSL_Device_List', RequestData_DeviceParts)
            .then(function (response) {
                console.log("CPSL_Device_List recv!", response)
                setDeviceList(response.data.info)
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.post('https://47.52.20.40:31001/AtalAPI/v1/CPSL_Parts_List', RequestData_DeviceParts)
            .then(function (response) {
                console.log("CPSL_Parts_List recv!", response)
                setPartList(response.data.info.parts)
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [deviceType])

    useEffect(() => {
        axios.post('https://47.52.20.40:31001/AtalAPI/v1/CPSL_WorkOrder', RequestData_WorkOrder)
            .then(function (response) {
                console.log("CPSL_WorkOrder recv!", response)
                let workOrder = response.data.info
                setNumber(workOrder.number)
                setWorkOrder(workOrder)
                if ((workOrder.schedule?.start || "N/A") !== "N/A")
                    setScheduleStart(new Date(workOrder.schedule?.start))
                if ((workOrder.schedule?.end || "N/A") !== "N/A")
                    setScheduleEnd(new Date(workOrder.schedule?.end))
                setRequestDate(new Date(workOrder.requestDate))
                setDeviceType(workOrder.device?.type)
                setSelectedPartList(workOrder.partsOrder?.map(part => { return { value: part, label: part } }))
                setFixedPartList(workOrder.partsFixed?.map(part => { return { value: part, label: part } }))
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.post('https://47.52.20.40:31001/AtalAPI/v1/CPSL_Personnel_List', RequestData_StaffList)
            .then(function (response) {
                setStaffList(response.data.info)
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.post('https://47.52.20.40:31001/AtalAPI/v1/CPSL_Personnel_List', RequestData_VendorList)
            .then(function (response) {
                setVendorList(response.data.info)
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])

    const condition = Yup.object({
        number: Yup.string().required('Required'),
        device: Yup.object({
            device_id: Yup.string().required('Required')
        }).required('Required'),
        requestedStaff: Yup.object({
            user_id: Yup.string().required('Required'),
            name: Yup.string().required('Required')
        }).required('Required'),
        schedule: Yup.object({
            start: Yup.string().required('Required'),
            end: Yup.string().required('Required'),
            suspension: Yup.boolean().required('Required'),
            status: Yup.string().required('Required'),
            location: Yup.string().matches('WZ[1-6][A-G][0-9]{2}', "Invalid location")
        }).required('Required'),
        description: Yup.string().max(200, "max length: 200"),
        remarks: Yup.string().max(1000, "max length: 1000")
    })

    let handleTextChange = (key, e) => {
        let text = typeof (e) === "boolean" ? e : e.target.value
        setWorkOrder({
            ...workOrder,
            [key]: text
        })
    }

    let handleStaffChange = (staff, e) => {
        let id = e.target.value
        let user = (staff === "vendor" ? vendorList : staffList).find(v => v.user_id === id)
        setWorkOrder({
            ...workOrder,
            [staff]: user ? {
                user_id: user.user_id,
                name: user.name,
                contactNo: user.contactNo,
                email: user.email
            } : {}
        })
    }

    let handleScheduleChange = (key, e) => {
        let text = typeof (e) === "boolean" ? e : e.target.value
        setWorkOrder({
            ...workOrder,
            schedule: {
                ...workOrder.schedule,
                [key]: text
            }
        })
    }

    let handleDeviceChange = (e) => {
        let device = deviceList.find((v) => v.device_id === e.target.value)
        setWorkOrder({
            ...workOrder,
            device: device
        })
    }

    let handleDeviceTypeChange = e => {
        let selected_device = e.target.value
        if (deviceType !== selected_device) {
            setDeviceType(selected_device)
            setDeviceList([])
            setPartList([])
            setSelectedPartList([])
            setFixedPartList([])
            setWorkOrder({
                ...workOrder,
                device: {}
            })
        }
    }

    const formik = useFormik({
        initialValues: {
            ...workOrder,
            number: number,
            schedule: {
                ...workOrder.schedule,
                start: scheduleStart,
                end: scheduleEnd,
            }
        },
        enableReinitialize: true,
        validationSchema: condition,
        onSubmit: values => {
            // send request and then push to planner page
            values.partsOrder = selectedPartList.map(part => part.value)
            values.partsFixed = fixedPartList.map(part => part.value)
            if (requestDate !== undefined && requestDate != null)
                values.requestDate = requestDate
            let requestData = {
                "method": mode,
                "info": values,
            }
            console.log("call save", requestData)
            //emit
            axios.post("https://47.52.20.40:31001/AtalAPI/v1/CPSL_WorkOrder", requestData)
                .then(function (response) {
                    history.goBack()
                })
                .catch(function (error) {
                    if (error.response) {
                        alert(error.response.data.message)
                    }
                });
        },
    });

    function deleteWorkOrder() {
        axios.post("https://47.52.20.40:31001/AtalAPI/v1/CPSL_WorkOrder", RequestData_Delete)
            .then(function (response) {
                history.goBack()
            })
            .catch(function (error) {
                if (error.response) {
                    alert(error.response.data.message)
                }
            });
    }

    function approve() {
        if (formik.isValid) {
            formik.setFieldValue("status", "Active");
        }
    }

    return <div className="WO_DETAIL">
        <div className="heading">Update Work Order</div>
        <form onSubmit={formik.handleSubmit}>
            <div className="row"><div className="col1">W.O. Number</div>
                <input className="text_dim" value={number} />
            </div>
            <div className="row"><div className="col1">W.O. Type</div>
                <select className="text_dim" value={workOrder.type}>
                    <option value="Manual">Manual</option>
                    <option value="Dynamic">Dynamic</option>
                    <option value="Periodic">Periodic</option>
                </select>
            </div>
            <div className="row"><div className="col1">W.O. Status</div>
                <select className="text_dim" value={workOrder.status}>
                    <option value="Waiting Approval">Waiting</option>
                    <option value="Active">Active</option>
                    <option value="Pending Resume">Pending</option>
                    <option value="Completed">Completed</option>
                </select>
            </div>

            <div className="row"><div className="col1">Requested By</div>
                <select className="text" value={workOrder.requestedStaff?.user_id} onChange={(e) => handleStaffChange("requestedStaff", e)}>
                    <option value=""></option>
                    {staffList.map(function (item) {
                        return <option value={item.user_id}>{item.name}</option>
                    })}
                </select>
                {formik.errors.requestedStaff?.user_id ? (<div><font color="red">{formik.errors.requestedStaff.user_id}</font></div>) : null}
            </div>
            <div className="row"><div className="col1">Requestor Contact Number</div>
                <input className="text_dim" placeholder="Contact Number" value={workOrder.requestedStaff?.contactNo ? workOrder.requestedStaff?.contactNo : ""} />
            </div>
            <div className="row"><div className="col1">Requestor Email</div>
                <input className="text_dim" placeholder="Email" value={workOrder.requestedStaff?.email ? workOrder.requestedStaff?.email : ""} />
            </div>
            <div className="row"><div className="col1">Request Date</div>
                <DatePicker
                    selected={requestDate}
                    onChange={setRequestDate}
                    dateFormat="d-MMMM yyyy"
                    timeCaption="time"
                />
            </div>
            <div className="row"><div className="col1">Full Time Suspension</div>
                <Checkbox checked={workOrder.fullTimeSuspension === true} onClick={() => handleTextChange("fullTimeSuspension", workOrder.fullTimeSuspension !== true)} />
            </div>

            <div className="row"><div className="col1">Device Type</div>
                <select className="text" value={deviceType} onChange={(e) => handleDeviceTypeChange(e)}>
                    <option value="Cold Box">Cold Box</option>
                    <option value="Wireless Charger">Wireless Charger</option>
                    <option value="LoRaWAN Gateway">LoRaWAN Gateway</option>
                    <option value="BLE Beacon">BLE Beacon</option>
                </select>
            </div>
            <div className="row"><div className="col1">Device Name</div>
                <select className="text" value={workOrder.device?.device_id} onChange={(e) => handleDeviceChange(e)}>
                    <option value=""></option>
                    {deviceList.map(function (item) {
                        return <option value={item.device_id}>{item.name}</option>
                    })}
                </select>
                {formik.errors.device?.device_id ? (<div><font color="red">{formik.errors.device.device_id}</font></div>) : null}
            </div>
            <div className="row"><div className="col1">Last Known Location</div>
                <input className="text_dim" disabled placeholder="Last Known Location" value={workOrder.device?.lastKnownLocation ? workOrder.device?.lastKnownLocation : ""} />
            </div>
            <div className="row"><div className="col1">Last Seen Date</div>
                <input className="text_dim" disabled placeholder="Last Seen Date" value={workOrder.device?.lastSeenDate ? workOrder.device?.lastSeenDate : ""} />
            </div>

            <div className="row"><div className="col1">Description</div>
                <textarea className="textarea" type="textarea" placeholder="Description" value={workOrder.description} onChange={(e) => handleTextChange("description", e)} />
                {formik.errors.description ? (<div><font color="red">{formik.errors.description}</font></div>) : null}
            </div>
            <div className="row"><div className="col1">Parts Order</div>
                <Select
                    isMulti
                    options={partList.map(function (part) { return { label: part, value: part } })}
                    value={selectedPartList}
                    onChange={setSelectedPartList} />
            </div>

            <div className="row"><div className="col1">Follow-up By</div>
                <select className="text" value={workOrder.followUpStaff?.user_id} onChange={(e) => handleStaffChange("followUpStaff", e)}>
                    <option value=""></option>
                    {staffList.map(function (item) {
                        return <option value={item.user_id}>{item.name}</option>
                    })}
                </select>
            </div>
            <div className="row"><div className="col1">Follow-up Staff Contact Number</div>
                <input className="text_dim" placeholder="Contact Number" value={workOrder.followUpStaff?.contactNo ? workOrder.followUpStaff?.contactNo : ""} />
            </div>
            <div className="row"><div className="col1">Follow-up Staff Email</div>
                <input className="text_dim" placeholder="Email" value={workOrder.followUpStaff?.email ? workOrder.followUpStaff?.email : ""} />
            </div>

            <div className="row"><div className="col1">Reported to vendor</div>
                <select className="text" value={workOrder.vendor?.user_id} onChange={(e) => handleStaffChange("vendor", e)}>
                    <option value=""></option>
                    {vendorList.map(function (item) {
                        return <option value={item.user_id}>{item.name}</option>
                    })}
                </select>
            </div>
            <div className="row"><div className="col1">Vendor Contact Number</div>
                <input className="text_dim" placeholder="Contact Number" value={workOrder.vendor?.contactNo ? workOrder.vendor?.contactNo : ""} />
            </div>
            <div className="row"><div className="col1">Vendor Email</div>
                <input className="text_dim" placeholder="Email" value={workOrder.vendor?.email ? workOrder.vendor?.email : ""} />
            </div>

            <div className="row"><div className="col1">Schedule</div></div>
            <div className="row1"><div className="col1">Start At</div>
                <DatePicker
                    selected={scheduleStart}
                    onChange={setScheduleStart}
                    dateFormat="d-MMMM yyyy"
                    timeCaption="time"
                />
                {formik.errors.schedule?.start ? (<div><font color="red">{formik.errors.schedule.start}</font></div>) : null}
            </div>
            <div className="row1"><div className="col1">End At</div>
                <DatePicker
                    selected={scheduleEnd}
                    onChange={setScheduleEnd}
                    dateFormat="d-MMMM yyyy"
                    timeCaption="time"
                />
                {formik.errors.schedule?.end ? (<div><font color="red">{formik.errors.schedule.end}</font></div>) : null}
            </div>
            <div className="row1"><div className="col1">Suspension</div>
                <Checkbox checked={workOrder.schedule?.suspension === true} onClick={() => handleScheduleChange("suspension", workOrder.schedule?.suspension !== true)} />
            </div>
            <div className="row1"><div className="col1">Status</div>
                <select className="text" value={workOrder.schedule?.status} onChange={(e) => handleScheduleChange("status", e)}>
                    <option value="Temp">Temp</option>
                    <option value="Reserved">Reserved</option>
                    <option value="Pending confirm">Pending confirm</option>
                    <option value="Completed">Completed</option>
                    <option value="Cancelled">Cancelled</option>
                </select>
            </div>
            <div className="row1"><div className="col1">Location</div>
                <input className="text" placeholder="Location" value={workOrder.schedule?.location} onChange={(e) => handleScheduleChange("location", e)} />
                {formik.errors.schedule?.location ? (<div><font color="red">{formik.errors.schedule.location}</font></div>) : null}
            </div>

            <div className="row"><div className="col1">Parts Fixed</div>
                <Select
                    isMulti
                    options={partList.map(function (part) { return { label: part, value: part } })}
                    value={fixedPartList}
                    onChange={setFixedPartList} />
            </div>
            <div className="row"><div className="col1">Remark</div>
                <textarea className="textarea" value={workOrder.remarks} type="textarea" placeholder="Remark" onChange={(e) => handleTextChange('remarks', e)} />
                {formik.errors.remarks ? (<div><font color="red">{formik.errors.remarks}</font></div>) : null}
            </div>
            <div className="row">
                <div className="col1">&nbsp;</div>
                <button className="button" onClick={() => deleteWorkOrder()}>Delete</button>
                <button className="button" type="submit">Update</button>
                {
                    workOrder.status !== "Waiting Approval" ? "" : <button className="button" onClick={() => approve()}>Approve</button>
                }
                <button className="button" type="button" onClick={() => history.goBack()}>Discard</button>
            </div>
        </form>
    </div>
}

function mapStateToProps(state) {
    return {
        socket: state.socket,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate, //when this value is updated, render would triggered
        UserInfo: state.UserInfo,
        Notification: state.Notification
    }
}
export default connect(mapStateToProps)(WorkOrderDetail)