import React, { useEffect, useState } from 'react'
import './requestService.css'

import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faTimes, faHistory } from '@fortawesome/free-solid-svg-icons'

function RequestServiceWidget(props) {
    const {UserInfo, DeviceData, ToiletType, currentSite, currentlevel, socket} = props
    const [isShowPopup, setisShowPopup] = useState(false)
    const [responseData, setresponseData] = useState(null)

    useEffect(()=> {
        if(socket == null) return
        //socket listener 
        socket.on("Toilet_Emergency", data => {
            console.log(data)

            // client_id: "600685e361a378359cb738c0"
            // id: "6090b5a95c0888001882eb6d"
            // locationInfo: (2) [{…}, {…}]
            // toiletType: "1"
            // triggerUserID: "5ff52c45144ee53e0cd08f8e"

            const DisplayData = (data==null||data.data==null||data.data.length==null)?[{'site': 'No Request are found'}]:data.data.map(item => {
                const {triggerUserID, locationInfo, id, toiletType} = item
                const {SiteName, LevelName} = GetLocationInfo(locationInfo)

                return {
                    'site': SiteName,
                    'level': LevelName,
                    'toilet': toiletType,
                    'time': '03-May 15:22',     //..
                    'username': triggerUserID
                }
            })

            //save to local state
            setresponseData(DisplayData)
        })
        return function WidgetStop() {
            //clear socket listener in this page
            socket.off("Toilet_Emergency")
        }
    }, [])

    useEffect(()=> {
        if(isShowPopup == false) return     //only request and update data when open popup

        setresponseData(null)
        //request data from server
        const {SiteID, SiteName} = GetSiteID(DeviceData, currentSite)
        const {LevelID, LevelName} = GetLevelID(DeviceData, currentSite, currentlevel)
        const EmitJson = {
            "msgType": "Toilet_Emergency",
            "method": "Read",
            "operatorID": UserInfo.userID,
            "client_id": UserInfo.client_id,
            "locationInfo": [
              {
                "id": SiteID,
                "lvl": 1,
                "name": SiteName
              },
              {
                "id": LevelID,
                "lvl": 2,
                "name": LevelName
              }
            ],
            "toiletType": ToiletType?ToiletType.toString():"1" //1 = male, 2 = female, 3 = other
        }
        console.log(EmitJson)

        props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "StatRequest",
            EmitSocketData: EmitJson
        })
    }, [isShowPopup])


    if(isShowPopup) {
        const DataList = responseData==null?[]:responseData
        return <div className="requestRecord">
            <div className="popup">
                <div className="closebtn" onClick={()=>setisShowPopup(false)}>
                    <FontAwesomeIcon icon={faTimes} className="fa" />
                </div>
                <div className="Table">
                    <div className="header">
                        <div className="col1">Site</div>
                        <div className="col2">Level</div>
                        <div className="col3">Toilet</div>
                        <div className="col4">Time</div>
                        <div className="col5">Request User</div>
                    </div>
                    <div className="rows">
                        {
                            DataList.map(item => {
                                let toiletdisplay
                                if(item.toilet == 1) toiletdisplay = 'male'
                                else if(item.toilet == 2) toiletdisplay = 'female'
                                else toiletdisplay = 'disable'
                                return <div className="row">
                                    <div className="col1">{item.site}</div>
                                    <div className="col2">{item.level}/F</div>
                                    <div className="col3">{toiletdisplay}</div>
                                    <div className="col4">{item.time}</div>
                                    <div className="col5">{item.username}</div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    }
    return <div className="requestRecord" onClick={()=>setisShowPopup(true)}>
        <FontAwesomeIcon icon={faHistory} className="fa" />
        <div className="desc">Service Request History</div>
    </div>
}

function mapStateToProps(state) {
    return {
        UserInfo: state.UserInfo,
        DeviceData: state.DeviceData,
        ToiletType: state.ToiletType,
        currentSite: state.currentSite,
        currentlevel: state.currentlevel,
        socket: state.socket,
    }
}
export default connect(mapStateToProps)(RequestServiceWidget)

// w = 1
// h = 2


function GetSiteID(DeviceData, currentSite) {
    try {
        const SiteItem = DeviceData[currentSite]
        const SiteID = SiteItem._id
        const SiteName = SiteItem.locName
        return {
            SiteID: SiteID,
            SiteName: SiteName,
        }
    }
    catch(err) {
        return err
    }
}
function GetLevelID(DeviceData, currentSite, currentlevel) {
    try {
        const LevelList = DeviceData[currentSite].nestedLocs
        let LevelID = "No Level ID found"
        let LevelName = "No Level Name found"
        LevelList.forEach(LevelItem => {
            if(LevelItem.locName == currentlevel || LevelItem.locDesc == currentlevel) {
                LevelID = LevelItem._id
                LevelName = LevelItem.locName
            }
        })
        return {
            LevelID: LevelID,
            LevelName: LevelName,
        }
    }
    catch(err) {
        return err
    }
}

function GetLocationInfo(locationInfo) {
    if(locationInfo == null || locationInfo.length == null || locationInfo.length == 0) return {
        SiteName: '',
        LevelName: ''
    }

    let SiteName = ''
    let LevelName = ''
    locationInfo.forEach(item => {
        if(item.lvl == 1) SiteName = item.name
        if(item.lvl == 2) LevelName = item.name        
    })
    return {
        SiteName: SiteName,
        LevelName: LevelName,
    }
}