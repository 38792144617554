import React from 'react'
import './timeline.css'
import { connect } from 'react-redux'
import Moment from 'react-moment'

function Timeline(props) {
    let rowColor = 'transparent'
    let duration = props.duration
    let today = new Date()
    let timeline_arr = [...Array(duration).keys()]

    return (
        <div className="Planner_timeline" style={{background: rowColor}}>
            <div className="col1"></div>
            <div className="col1"></div>
            <div className="col1"></div>
            <div className="col1"></div>
            <div className="col2"></div>
            <div className="subScroll" onScroll={bindHandleScroll}>
            {
                timeline_arr.map(idx => {
                    let D = new Date()
                    D.setDate(D.getDate() + idx)
                    return <div className="col2"><Moment className="" format="DD MMM YY">{D}</Moment></div>
                })
            }
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        //UserInfo: state.UserInfo,
        //socket: state.socket
    }
}

function bindHandleScroll(event) {
  let subScrollList = document.getElementsByClassName("subScroll")
  for (let i=0; i< subScrollList.length; i++) {
    let item = subScrollList[i]
    item.scrollLeft = event.currentTarget.scrollLeft
  }
}

export default connect(mapStateToProps)(Timeline)
