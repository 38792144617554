import React from 'react'
import './Table.css'
import { connect } from 'react-redux'
//import Moment from 'react-moment'
import moment from 'moment';
import { useFormik } from 'formik';

function Table(props) {
    let row = props.row
    let startTime = moment(row.startTime, "YYYY-MM-DD").toDate()
    let endTime = moment(row.endTime, "YYYY-MM-DD").toDate()
    startTime.setHours(0, 0, 0, 0)
    endTime.setHours(0, 0, 0, 0)

    return (
        <div className="wrap" >
            <div className="WOL_TableRow" onClick={props.onClick}>
                <div className="col1">{row.name}</div>
                <div className="col2">{row.workOrderType}</div>
                <div className="col1">{row.parts.join(", ")}</div>
                <div className="col2">{row.orderNo}</div>
                <div className="col1">{row.requestor}</div>
                <div className="col2">{row.status}</div>
                <div className="col3">{row.requestDate}</div>
                <div className="col1">{row.followUp}</div>
                <div className="col1">{row.reported}</div>
                <div className="col3">{row.location}</div>
                <div className="col2">{row.downTime}</div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        UserInfo: state.UserInfo,
        socket: state.socket,
    }
}
export default connect(mapStateToProps)(Table) 