import React from 'react'
import './popupCard.css'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Moment from 'react-moment'
import { faBatteryHalf, faThermometerThreeQuarters, faTint, faWifi } from '@fortawesome/free-solid-svg-icons'

function CardPopup(props) {
    const {CardPopup} = props
    const coldboxItem = getColdboxWithID(CardPopup, props)
    const {coldboxIDDisplay, lvl, locDesc, RunHrs, status, setTemp, currentTemp, humid, updateDate, batteryCap} = GetColdboxValues(coldboxItem)

    return <div className='coldbox_Card'>
            <div className='inner'>
                <div className='lvl'>
                    {lvl + ' ' + locDesc}
                </div>
                <div className='status'>
                    <div className='time'>
                        <div className='value'>{RunHrs}</div>
                        <div className='unit'>hrs</div>
                    </div>
                    <div className='statusTxt'>{status}</div>
                </div>
                <div className='temp'>
                    <div className='left'>
                        <div className='top'>
                            <div className='value'>
                                {setTemp}
                                <div className='unit'>
                                    C
                                    <div className='unit2'>o</div>
                                </div>
                            </div>
                            <div>
                                <FontAwesomeIcon icon={faThermometerThreeQuarters} className='fa' />
                            </div>
                        </div>
                        <div className='bottom'>Set</div>
                    </div>
                    <div className='right'>
                        <div className='top'>
                            <div className='value'>
                                {currentTemp}
                                <div className='unit'>
                                    C
                                    <div className='unit2'>o</div>
                                </div>
                            </div>
                            <div>
                                <FontAwesomeIcon icon={faThermometerThreeQuarters} className='fa' />
                            </div>
                        </div>
                        <div className='bottom'>Current</div>
                    </div>
                </div>
                <div className='humid'>
                    <div className='value'>
                        {humid}
                        <div className='unit'>%</div>
                    </div>
                    <div>
                        <FontAwesomeIcon icon={faTint} className='fa' />
                    </div>
                </div>
                <div className='battery' style={{color: batteryCap<=10?'red':''}}>
                    <FontAwesomeIcon icon={faBatteryHalf} className='fa' />
                    {batteryCap + '%'}
                </div>
                <div className='updateTime' style={{color: 'grey'}}>
                    <FontAwesomeIcon icon={faWifi} className='fa' />
                    <Moment className="" format="MMM-DD HH:mm">{updateDate}</Moment>
                </div>
            </div>
        </div>
}

function mapStateToProps(state) {
    return {
        DeviceData: state.DeviceData,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate
    }
}  
export default connect(mapStateToProps)(CardPopup)

const getColdboxWithID = (coldboxID, props) => {
    if(coldboxID==null||props==null) return
    const {DeviceData} = props
    if(DeviceData==null) return

    let AllColdbox = []
    DeviceData.forEach(site => {
        site.nestedLocs.forEach(lvl => {
            lvl.nestedLocs.forEach(coldboxItem => {
                console.log(coldboxItem._id)
                AllColdbox.push(coldboxItem)
            });
        })
    })

    const coldboxItem = AllColdbox.find(item => item._id == coldboxID)
    console.log(coldboxItem)
    return coldboxItem
}

const GetColdboxValues = (coldboxItem) => {
    if(coldboxItem==null) return {}
    const {payload} = coldboxItem
    return {
        coldboxIDDisplay: coldboxItem.locName,
        lvl: coldboxItem.LevelLocName,
        locDesc: payload==null?'--':payload.ZoneID,
        status: payload==null?'--':payload.Status,
        setTemp: payload==null?'--':payload.TempSetPoint,
        currentTemp: payload==null?'--':payload.WeightedMeanTemp,
        humid: payload==null?'--':payload.RH,
        RunHrs: payload==null?'--':payload.RunHrs,
        updateDate: payload==null?'--':payload.updateDate,
        batteryCap: payload==null?'--':payload.UPSBatteryCapacity
    }
}