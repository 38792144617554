import React, {useEffect, useState} from 'react'
import './dynamicCard.scss'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import DynamicCard from './card/card'
import DynamicCardForm from './form/form'

const DynamicCardMaker = props => {
    const [config, setConfig] = useState(Config)
    const [forceUpdate, setForceUpdate] = useState(0)

    const updateData = input => {
        setConfig(input)
        console.log(config)
        setForceUpdate(forceUpdate + 1)
    }

    return <div className='dynamicCardPage'>
        <div  className='left'>
            <DynamicCardForm Config={config} updateData={updateData} />
        </div>
        <div className='right'>
            <DynamicCard Config={config} forceUpdate={forceUpdate} />
        </div>
    </div>
}
export default DynamicCardMaker

//** size unit is px **
const Config = {
    cardId: 1,
    deviceType: {   //what situation to show this
        str: ['IAQ', 'IEQ', '2h948339grp39rim49nr39rngk'],
        whereToGet: ['nameID', 'DeviceType', 'DeviceID']
    },
    currentType: 1,     //1 = simple, 2 = detail, 3 = raw data
    typeIds: [1, 2, 3], //can limit how many type of display
    size: {
        padding: 10,
        aspectRatio: 5.5,   //width = 10.55rem (height fixed) * aspectRatio
        readingRow_height: 15    // rem
    },
    title: {
        titleSize: 12,
        titleColor: 'black',
        subtitleSize: 10,
        subtitleColor: 'grey'
    },
    lastUpdate: {
        datetimeFormat: 'DD/MM HH:mm',
        size: 10
    },
    types: {
        '1': {
            title: {
                isTitle: true,
                isSubTitle: false
            },
            isShowLastUpdate: false
        },
        '2': {
            title: {
                isTitle: true,
                isSubTitle: true
            },
            isShowLastUpdate: true
        },
        '3': {
            title: {
                isTitle: true,
                isSubTitle: true
            },
            isShowLastUpdate: true
        }
    },
    readings: {
        columns: 1,     //one column downward
        '1': [
            {
                key: 'temperature',
                title: 'Temp',
                icon: null,
                isBool: false,
                decimal: 1,
                unit: 'C',
                size_w: [15, 15, 15, 15],            // title icon reading unit (fix width of each part)
                size_font: [15, 15, 15, 15],         // title icon reading unit (font size)
                weight_font: ['bold', '100', '100'], // title reading unit (font wiehgt)
                color: ['red', 'red', 'red', 'red'], // title icon reading unit (font color)
                align_v: ['center', 'bottom', 'center', 'center'],
                align_h: ['center', 'left', 'center', 'center'],
                order: ['title', 'icon', 'reading', 'unit'],
                unitPosition: 'center'     // 'under_Reading', 'Upper', 'Bottom'
            },
            {
                key: 'occupied',
                title: 'Occupancy',
                // icon: faUser,
                isBool: true,
                inColorBox: true,
                size_w: [15, 15, 15],            // title icon display (fix width of each part)
                size_font: [15, 15, 15],         // title icon display (font size)
                weight_font: ['bold', '100'],    // title reading (font weight)
                trueDisplay: {
                    colors: ['white', 'white', 'white'],// title icon display (font color)
                    bg_color: 'red'              // status box
                },
                falseDisplay: {
                    colors: ['green', 'green', 'green'], // title icon display (font color)
                    bg_color: 'transparent'             // status box
                },
                align_v: ['center', 'bottom', 'center', 'center'],
                align_h: ['center', 'left', 'center', 'center'],
                order: ['title', 'icon', 'display'],
                statusPosition: 'row'     // 'under_Icon'
            }
        ],
        '2': [],
        '3': []
    }
}