import React, {useState} from 'react'
import './doorcontact.css'
import { connect } from 'react-redux'
import { faDoorClosed, faDoorOpen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CardContainer from '../../../common/cardContainer/cardContainer'
import {getTxt} from '../../../common/language/language'

function DoorContact(props) {
    const {hasSensor, isAllClose} = getData(props)
    const isClose = isAllClose

    return <CardContainer icon={faDoorClosed} head={getTxt('Dashbd', 'sensorTitle', 'drContact')}>
        <div className='doorcontact' style={{opacity: hasSensor?'1': '0.5'}}>
            <div className='icon'>
                <FontAwesomeIcon icon={isClose?faDoorClosed:faDoorOpen} className='fa' />
            </div>
            <div className={isClose?'txtDisplay close':'txtDisplay open'}>
                {isClose?getTxt('Dashbd', 'sensorTitle', 'allClose'):getTxt('Dashbd', 'sensorTitle', 'someOpen')}
            </div>
        </div>
    </CardContainer>
}

function mapStateToProps(state) {
    return {
        DeviceData: state.DeviceData,
        currentSite: state.currentSite,
        currentlevel: state.currentlevel,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate
    }
}
export default connect(mapStateToProps)(DoorContact)

function getData(props) {
    const locList = getLocList(props)
    console.log(locList)

    const relatedList = locList.filter(item => {
        if(item.payload_definition_id==null) return false
        if(item.payload_definition_id.nameID=='Door Contact') return true
        return false
    })
    console.log(relatedList)

    const {count, total} = separateData(relatedList)

    console.log({
        count: count,
        total: total
    })
    
    return {
        hasSensor: !(total==0),
        isAllClose: count==0
    }
}

// sub function
function getLocList(props) {
    const {DeviceData, currentSite, currentlevel} = props
    if(DeviceData==null) return []

    const site = DeviceData[currentSite]
    if(site == null || site.nestedLocs == null) return []

    const lvl = site.nestedLocs.find(item => item.locName == currentlevel)
    if(lvl == null || lvl.nestedLocs == null) return []

    return lvl.nestedLocs
}

function separateData(dataList) {
    if(dataList == null||dataList.length==null) return

    console.log(dataList)
    let count = 0
    let total = 0

    dataList.forEach(item => {
        total++
        if(item.payload==null) return
        if(item.payload.doorOpen=='doorOpen' || item.payload.doorOpen == true) {
            count++
        }
    })

    return {
        count: count,
        total: total,
    }
}