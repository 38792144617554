import React, { useEffect, useState } from 'react'
import CCGHTML from './htmlPart'

import axios from 'axios'
import oauth from 'axios-oauth-client'

import { getSensorList } from './getSensorList'
import { getFloor } from './getSensorList'

const updateInterval = 100 //second

const CCGPublic = props => {
    const [timer, setTimer] = useState(0)
    const [floor, setFloor] = useState('')
    const [token, setToken] = useState('')
    const [res, setRes] = useState({})
    const [forceUpdate, setForceUpdate] = useState(0)

    useEffect(async () => {  //Auth when onload OAuth2.0
        const getAuthorizationCode = oauth.client(axios.create(), {
            url: getAuthUrl(),
            grant_type: 'authorization_code',
            client_id: 'foo',
            client_secret: 'bar',
            username: 'ninatowerapi',
            password: 'ninatowerpw',
            scope: 'baz',
        })  
        const auth = await getAuthorizationCode()
        if(auth==null||auth.token==null) return
        setToken(auth.token)
    }, [])
    useEffect(() => {
        setTimeout(() => {
            setTimer(timer + 1)
        }, 1000 * updateInterval)
        setFloor(getFloor())
    }, [timer])
    useEffect(() => {
        if(token=='') return

        const fetchData = async () => {
            const restUrl = getApiUrl()
            const sensorList = getSensorList(getFloor()) //35-38

            sensorList.forEach(async sensorID => {
                const data = await restfulData(restUrl, token, sensorID)
                if(data==null || data.data==null) return
                // console.log(data.data)
                let newRes = res
                newRes[data.data.id] = data.data
                setRes(newRes)
                setForceUpdate(forceUpdate+1)
            })
        }
        try {
            fetchData()
        }
        catch(err) {
            console.log(err)
        }
    }, [timer, token])

    console.log(res)
    return <CCGHTML res={res} floor={floor} />
}
export default CCGPublic

const getAuthUrl = () => {
    const port = window.IoTConfig==null?'30080':window.IoTConfig.CCGHQPublicPagePort
    const origin = window.location.origin
    const originWithoutPort = origin.split(':')
    if(origin.includes('localhost')) return 'https://47.52.20.40:' + port + '/AtalAPI/v1/auth'
    return originWithoutPort[0] + ':' + originWithoutPort[1] + ':' + port + '/AtalAPI/v1/auth'
}
const getApiUrl = () => {
    const port = window.IoTConfig==null?'30080':window.IoTConfig.CCGHQPublicPagePort
    const origin = window.location.origin
    // console.log('origin: ' + origin)
    const originWithoutPort = origin.split(':')
    // console.log(originWithoutPort)
    if(origin.includes('localhost')) return 'https://47.52.20.40:' + port + '/AtalAPI/v1/SensorPointData'
    return originWithoutPort[0] + ':' + originWithoutPort[1] + ':' + port + '/AtalAPI/v1/SensorPointData'
}
const restfulData = async (restUrl, token, sensorID) => {
    let res = {}
    await axios.post(
        restUrl,
        {
            sensorpoint: sensorID
        },
        {
            headers: { Authorization: 'Bearer ' + token }
        }
    )
    .then(response => {
        res = response.data
    })

    return res
}
// sample link: http://localhost:3000/#/CCGHQ/38