import React, { createRef } from 'react'
import {DefaultConfig} from '../DefaultConfig'

class SoundPlay extends React.Component {
    constructor(props)
    {
        super(props)
        this.alarmMp3Run = this.alarmMp3Run.bind(this)
        this.alarmMp3 = React.createRef()

        //real used sound effect ref()
        this.alarmSound = React.createRef()
        this.clickSound = React.createRef()
        this.hoverSound = React.createRef()
        this.pageSound = React.createRef()
    }
    alarmMp3Run(type)
    {
        //check if setting enabled sound effect
        const soundSetting = localStorage.getItem('SoundOn')
        if(soundSetting == null || soundSetting == "") {
            //default allow sound effect, so do nothing here
        }
        else if(soundSetting == 'TRUE') {
            //setting allowed sound effect, continue
        }
        else if(soundSetting == 'FALSE') {
            //setting not allow sound effect, terminate this function here
            return
        }

        console.log('alarmMp3Run() in Sound.js')
        try
        {
            if(this.alarmMp3 != null && this.alarmMp3 != undefined && type == null) this.alarmMp3.play()
            else if(this.alarmSound != null && this.alarmSound != undefined && type == "alarm") this.alarmSound.play()
            else if(this.clickSound != null && this.clickSound != undefined && type == "click") this.clickSound.play()
            else if(this.hoverSound != null && this.hoverSound != undefined && type == "hover") this.hoverSound.play()
            else if(this.pageSound != null && this.pageSound != undefined && type == "page") this.pageSound.play()
        }
        catch(err)
        {
            console.log(err)
        }
    }
    render()
    {
        const ioTConfig = window.IoTConfig
        const socketUrl = DefaultConfig.socketUrl
        const rootUrl = (ioTConfig == null)?socketUrl + "/assets/sounds/":ioTConfig.imgUrl + '/assets/sounds/'
        //console.log(rootUrl)
        return <div>
            <audio src={rootUrl+"click.mp3"} type="audio/mpeg" ref={(alarmMp3) => { this.alarmMp3 = alarmMp3 }} />

            <audio src={rootUrl+"alarm.mp3"} type="audio/mpeg" ref={(alarmSound) => { this.alarmSound = alarmSound }} />
            <audio src={rootUrl+"click.mp3"} type="audio/mpeg" ref={(clickSound) => { this.clickSound = clickSound }} />
            <audio src={rootUrl+"hover.mp3"} type="audio/mpeg" ref={(hoverSound) => { this.hoverSound = hoverSound }} />
            <audio src={rootUrl+"page.mp3"} type="audio/mpeg" ref={(pageSound) => { this.pageSound = pageSound }} />
        </div>
    }
}

export default SoundPlay

//guideline (How to apply this widget function to pages and trigger sound effect):
//1.import this widget
//import SoundPlay from '../../Sound/Sound'
//2. set a Ref
//this.soundRef = React.createRef()
//3. link widget to Ref()
//<SoundPlay ref={this.soundRef}/>
//4. trigger ref()'s Function
//this.soundRef.current.alarmMp3Run("hover")