import React from 'react'
import './DataTrend.css'
import { connect } from 'react-redux'
import Searchbar from './searchbar/searchbar'
import ColdboxLineChart from '../../Summary/ColdboxWidgets/Linechart'

class DataTrend extends React.Component {
    constructor(props) {
        super()

        this.backpage = this.backpage.bind(this)
        this.GetLocLv3Req = this.GetLocLv3Req.bind(this)
    }
    componentDidMount() {
        let pathUrl = "/Main/FloorPlan"
        this.props.dispatch({ type: 'LatestPage', data: pathUrl }) 

        this.GetLocLv3Req()
    }

    GetLocLv3Req() {
        const {UserInfo} = this.props
        if (UserInfo == null) return

        const requestData = {
            "msgType": "GetAllLocLv3List",
            "userID": UserInfo.userID,
            "client_id": UserInfo.client_id,
        }

        this.props.dispatch({
            type: 'EmitSocket',
            EmitSocketName: "GroupManageRequest",
            EmitSocketData: requestData
        })
    }

    backpage() {
        this.props.history.goBack()
    }

    render() {
        return <div className="Coldbox_SPage">
            <Searchbar backpage={this.backpage} />
            <div className="Coldbox_S_tablemain">
                <ColdboxLineChart />
                <div className="Coldbox_S_tablemainTitle"></div>
                <div className="Coldbox_S_tableList"></div>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        UserInfo: state.UserInfo,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate //when this value is updated, render would triggered
    }
}
export default connect(mapStateToProps)(DataTrend)