import React from 'react'
import './individual.css'
import { connect } from 'react-redux'
//import inner widget
import Searchbar from './searchbar/searchbar'
import Table from './table/table'
import TableHead from './table/tablehead'
import Downloadcsv from './bottombtns/downloadcsv'
import PageBtns from './bottombtns/pagebtns'
import Create from './popup/create'
import Manage from './popup/manage'
import Delete from './popup/delete'

class ColdboxIndividual extends React.Component {
    constructor(props) {
        super()

        this.state = {
            isEdit: false,      //true means edit, false mean add
            Editingitem: null,
            isPopup: false,     //true means open popup, false mean hide popup
            deleteConfirm: false,

            //raw coldboxlist
            coldboxList: null,
            //filtered result (by searchbar.js)
            filteredList: null,
            //sorted result (by table head .js)
            sortedList: null,
            //slice by page (by pages.js widget)
            pageSliceList: null,

            updateCount: 0,   // count++ to force Table to update
        }

        this.backPage = this.backPage.bind(this)
        this.reload = this.reload.bind(this)
        this.getDeviceList = this.getDeviceList.bind(this)

        this.addPopup = this.addPopup.bind(this)
        this.editPopup = this.editPopup.bind(this)

        this.closePopup = this.closePopup.bind(this)

        this.deletePopup = this.deletePopup.bind(this)
        this.deleteCancel = this.deleteCancel.bind(this)

        //Update function from child widgets
        this.UpdateList = this.UpdateList.bind(this)
    }

    componentDidMount() {
        this.reload()
        this.getDeviceList()  //'DeviceManagement' 'read' method, get the DeviceList
        this.getColdboxGroupList()
    }
    backPage() {
        this.props.history.goBack()
    }
    reload() {
        const {DeviceData} = this.props
        if(DeviceData == null) return
        let CdbxLocList = []
        //find all coldbox location items
        DeviceData.forEach(SiteItem => {
          if(SiteItem.nestedLocs == null || SiteItem.nestedLocs.length == null) return                                //exception case 1
          SiteItem.nestedLocs.forEach(levelItem => {
            const SiteName = SiteItem.locName
            levelItem.nestedLocs.forEach(locItem => {
              const LevelName = levelItem.locName
              if(locItem.payload_definition_id == null || locItem.payload_definition_id.nameID == null) {
                //skip
              }
              else if(locItem.payload_definition_id.nameID == "Coldbox") {
                let newlocItem = locItem
                newlocItem.SiteName = SiteName
                newlocItem.LevelName = LevelName
                CdbxLocList.push(newlocItem)
              }
              else {
                //skip
              }
            })
          })
        })

        this.setState({
            coldboxList: CdbxLocList,
        })
    }
    getDeviceList() {
      const {UserInfo} = this.props
      if(UserInfo == null) return     //exception case
      const EmitJson = {
        "msgType": "DeviceManagement",
        "method": "Get",
        "userID": UserInfo.userID,
        "client_id": UserInfo.client_id,
      }
      console.log(EmitJson)
      this.props.dispatch({
        type: 'EmitSocket',
        EmitSocketName: "StatRequest",
        EmitSocketData: EmitJson
      })
    }
    getColdboxGroupList() {
      const {UserInfo} = this.props
      if(UserInfo == null) return
      const EmitJson = {
        "msgType": "GetGroupList",
        "userID": UserInfo.userID,
        "client_id": UserInfo.client_id,
      }
      console.log(EmitJson)
      this.props.dispatch({
        type: 'EmitSocket',
        EmitSocketName: "StatRequest",
        EmitSocketData: EmitJson
      })
    }
    addPopup() {
        this.setState({
            isEdit: false,
            isPopup: true,
        })
    }
    editPopup(Editingitem) {
        this.setState({
            isEdit: true,
            isPopup: true,
            Editingitem: Editingitem
        })
    }
    closePopup() {
        this.setState({
            isEdit: false,
            isPopup: false,
            deleteConfirm: false,
        })
    }
    deletePopup() {
        this.setState({
            deleteConfirm: true
        })
    }
    deleteCancel() {
        this.setState({
            deleteConfirm: false
        }) 
    }
    //Update function from child widgets
    UpdateList(StateStr, Value) {
      const {updateCount} = this.state
      this.setState({
          [StateStr]: Value,
          updateCount: updateCount + 1,
      })
    }
    
    render() {
        const _this = this
        const {isEdit, isPopup, deleteConfirm, coldboxList, filteredList, sortedList, updateCount, pageSliceList, Editingitem} = this.state

        return [
        <div className="ColdboxIndividual">
            <Searchbar reload={_this.reload} backPage={_this.backPage} coldboxList={coldboxList} UpdateList={_this.UpdateList} />
            <div className="ColdboxIndi_table">
                <TableHead addPopup={_this.addPopup} filteredList={filteredList} UpdateList={_this.UpdateList} />
                <Table editPopup={_this.editPopup} coldboxList={pageSliceList} count={updateCount} />
            </div>
            <div className="ColdboxIndi_bottom">
                <Downloadcsv sortedList={pageSliceList} />
                <PageBtns sortedList={sortedList} UpdateList={_this.UpdateList} count={updateCount} />
            </div>
        </div>,
        (isPopup)?
        <div className="Coldboxindi_BG" onClick={this.closePopup}></div>
        :
        <div style={{display: 'none'}}></div>,
        <Create isEdit={isEdit} isPopup={isPopup} deleteConfirm={deleteConfirm} closePopup={this.closePopup} />,
        <Manage isEdit={isEdit} isPopup={isPopup} deleteConfirm={deleteConfirm} closePopup={this.closePopup} Editingitem={Editingitem} deletePopup={this.deletePopup} />,
        <Delete isEdit={isEdit} isPopup={isPopup} deleteConfirm={deleteConfirm} closePopup={this.closePopup} Editingitem={Editingitem} />,
        ]
    }
}

function mapStateToProps(state) {
    return {
      UserInfo: state.UserInfo,
      DeviceData: state.DeviceData,
    }
}
export default connect(mapStateToProps)(ColdboxIndividual)

// const HardcodeData = [
//     {
//       "id": "C0001",
//       "name": "C0001",
//       "group": "Group B",
//       "site": "CPSL",
//       "level": 4,
//       "status": 4,
//       "OpeHr": 264,
//       "BatteryPw": 87,
//       "avail": 4,
//       "MTBF": 80166,
//       "MTTR": 9,
//       "faultRate": "263 x 10^-4",
//       "faultCount": 2
//     },
//     {
//       "id": "C0002",
//       "name": "C0002",
//       "group": "Group A",
//       "site": "CPSL",
//       "level": 3,
//       "status": 2,
//       "OpeHr": 602,
//       "BatteryPw": 12,
//       "avail": 2,
//       "MTBF": 243274,
//       "MTTR": 0,
//       "faultRate": "98 x 10^-4",
//       "faultCount": 2
//     },
//     {
//       "id": "C0003",
//       "name": "C0003",
//       "group": "Group C",
//       "site": "CPSL",
//       "level": 4,
//       "status": 2,
//       "OpeHr": 583,
//       "BatteryPw": 49,
//       "avail": 4,
//       "MTBF": 154452,
//       "MTTR": 1,
//       "faultRate": "442 x 10^-4",
//       "faultCount": 4
//     },
//     {
//       "id": "C0004",
//       "name": "C0004",
//       "group": "Group B",
//       "site": "CPSL",
//       "level": "G",
//       "status": "G",
//       "OpeHr": 650,
//       "BatteryPw": 80,
//       "avail": 2,
//       "MTBF": 37813,
//       "MTTR": 4,
//       "faultRate": "262 x 10^-4",
//       "faultCount": 2
//     },
//     {
//       "id": "C0005",
//       "name": "C0005",
//       "group": "Group B",
//       "site": "CPSL",
//       "level": 3,
//       "status": 3,
//       "OpeHr": 736,
//       "BatteryPw": 64,
//       "avail": 2,
//       "MTBF": 171797,
//       "MTTR": 7,
//       "faultRate": "708 x 10^-4",
//       "faultCount": 0
//     },
//     {
//       "id": "C0006",
//       "name": "C0006",
//       "group": "Group A",
//       "site": "CPSL",
//       "level": 3,
//       "status": 3,
//       "OpeHr": 554,
//       "BatteryPw": 96,
//       "avail": 4,
//       "MTBF": 11979,
//       "MTTR": 7,
//       "faultRate": "530 x 10^-4",
//       "faultCount": 0
//     },
//     {
//       "id": "C0007",
//       "name": "C0007",
//       "group": "Group C",
//       "site": "CPSL",
//       "level": 2,
//       "status": 4,
//       "OpeHr": 523,
//       "BatteryPw": 30,
//       "avail": 4,
//       "MTBF": 254328,
//       "MTTR": 6,
//       "faultRate": "486 x 10^-4",
//       "faultCount": 3
//     },
//     {
//       "id": "C0008",
//       "name": "C0008",
//       "group": "Group B",
//       "site": "CPSL",
//       "level": 1,
//       "status": 4,
//       "OpeHr": 645,
//       "BatteryPw": 45,
//       "avail": 3,
//       "MTBF": 144116,
//       "MTTR": 7,
//       "faultRate": "321 x 10^-4",
//       "faultCount": 0
//     },
//     {      
//       "id": "C0009",
//       "name": "C0009",
//       "group": "Group A",
//       "site": "CPSL",
//       "level": 4,
//       "status": 2,
//       "OpeHr": 396,
//       "BatteryPw": 58,
//       "avail": "G",
//       "MTBF": 276402,
//       "MTTR": 6,
//       "faultRate": "416 x 10^-4",
//       "faultCount": 1
//     },
//     {
//       "id": "C0010",
//       "name": "C0010",
//       "group": "Group A",
//       "site": "CPSL",
//       "level": 4,
//       "status": 1,
//       "OpeHr": 85,
//       "BatteryPw": 86,
//       "avail": 4,
//       "MTBF": 16047,
//       "MTTR": 6,
//       "faultRate": "600 x 10^-4",
//       "faultCount": 2
//     },
//     {
//       "id": "C0011",
//       "name": "C0011",
//       "group": "Group C",
//       "site": "CPSL",
//       "level": "G",
//       "status": 4,
//       "OpeHr": 297,
//       "BatteryPw": 6,
//       "avail": 3,
//       "MTBF": 265370,
//       "MTTR": 4,
//       "faultRate": "353 x 10^-4",
//       "faultCount": 1
//     },
//     {
//       "id": "C0012",
//       "name": "C0012",
//       "group": "Group B",
//       "site": "CPSL",
//       "level": "G",
//       "status": "G",
//       "OpeHr": 681,
//       "BatteryPw": 63,
//       "avail": 4,
//       "MTBF": 203874,
//       "MTTR": 5,
//       "faultRate": "509 x 10^-4",
//       "faultCount": 1
//     },
//     {
//       "id": "C0013",
//       "name": "C0013",
//       "group": "Group B",
//       "site": "CPSL",
//       "level": 1,
//       "status": 1,
//       "OpeHr": 724,
//       "BatteryPw": 77,
//       "avail": 1,
//       "MTBF": 50467,
//       "MTTR": 7,
//       "faultRate": "263 x 10^-4",
//       "faultCount": 1
//     },
//     {
//       "id": "C0014",
//       "name": "C0014",
//       "group": "Group C",
//       "site": "CPSL",
//       "level": 3,
//       "status": 4,
//       "OpeHr": 779,
//       "BatteryPw": 7,
//       "avail": "G",
//       "MTBF": 107352,
//       "MTTR": 2,
//       "faultRate": "431 x 10^-4",
//       "faultCount": 4
//     },
//     {
//       "id": "C0015",
//       "name": "C0015",
//       "group": "Group C",
//       "site": "CPSL",
//       "level": 2,
//       "status": 2,
//       "OpeHr": 815,
//       "BatteryPw": 84,
//       "avail": "G",
//       "MTBF": 226642,
//       "MTTR": 2,
//       "faultRate": "146 x 10^-4",
//       "faultCount": 4
//     },
//     {
//       "id": "C0016",
//       "name": "C0016",
//       "group": "Group B",
//       "site": "CPSL",
//       "level": 3,
//       "status": "G",
//       "OpeHr": 625,
//       "BatteryPw": 6,
//       "avail": 2,
//       "MTBF": 246942,
//       "MTTR": 8,
//       "faultRate": "703 x 10^-4",
//       "faultCount": 5
//     },
//     {
//       "id": "C0017",
//       "name": "C0017",
//       "group": "Group C",
//       "site": "CPSL",
//       "level": 2,
//       "status": 4,
//       "OpeHr": 99,
//       "BatteryPw": 57,
//       "avail": 3,
//       "MTBF": 235010,
//       "MTTR": 1,
//       "faultRate": "203 x 10^-4",
//       "faultCount": 4
//     },
//     {
//       "id": "C0018",
//       "name": "C0018",
//       "group": "Group A",
//       "site": "CPSL",
//       "level": 2,
//       "status": "G",
//       "OpeHr": 272,
//       "BatteryPw": 100,
//       "avail": "G",
//       "MTBF": 31730,
//       "MTTR": 0,
//       "faultRate": "7 x 10^-4",
//       "faultCount": 1
//     },
//     {
//       "id": "C0019",
//       "name": "C0019",
//       "group": "Group C",
//       "site": "CPSL",
//       "level": "G",
//       "status": 4,
//       "OpeHr": 908,
//       "BatteryPw": 35,
//       "avail": 1,
//       "MTBF": 299827,
//       "MTTR": 6,
//       "faultRate": "425 x 10^-4",
//       "faultCount": 1
//     },
//     {
//       "id": "C0020",
//       "name": "C0020",
//       "group": "Group C",
//       "site": "CPSL",
//       "level": 2,
//       "status": 3,
//       "OpeHr": 395,
//       "BatteryPw": 24,
//       "avail": 3,
//       "MTBF": 82408,
//       "MTTR": 10,
//       "faultRate": "579 x 10^-4",
//       "faultCount": 0
//     },
//     {
//       "id": "C0021",
//       "name": "C0021",
//       "group": "Group B",
//       "site": "CPSL",
//       "level": 4,
//       "status": "G",
//       "OpeHr": 727,
//       "BatteryPw": 61,
//       "avail": 2,
//       "MTBF": 80872,
//       "MTTR": 4,
//       "faultRate": "76 x 10^-4",
//       "faultCount": 5
//     },
//     {
//       "id": "C0022",
//       "name": "C0022",
//       "group": "Group B",
//       "site": "CPSL",
//       "level": 4,
//       "status": 3,
//       "OpeHr": 917,
//       "BatteryPw": 31,
//       "avail": 4,
//       "MTBF": 65722,
//       "MTTR": 7,
//       "faultRate": "588 x 10^-4",
//       "faultCount": 1
//     },
//     {
//       "id": "C0023",
//       "name": "C0023",
//       "group": "Group B",
//       "site": "CPSL",
//       "level": 1,
//       "status": 2,
//       "OpeHr": 160,
//       "BatteryPw": 37,
//       "avail": "G",
//       "MTBF": 14785,
//       "MTTR": 0,
//       "faultRate": "439 x 10^-4",
//       "faultCount": 1
//     },
//     {
//       "id": "C0024",
//       "name": "C0024",
//       "group": "Group A",
//       "site": "CPSL",
//       "level": 2,
//       "status": 4,
//       "OpeHr": 344,
//       "BatteryPw": 17,
//       "avail": 3,
//       "MTBF": 97951,
//       "MTTR": 9,
//       "faultRate": "552 x 10^-4",
//       "faultCount": 2
//     },
//     {
//       "id": "C0025",
//       "name": "C0025",
//       "group": "Group A",
//       "site": "CPSL",
//       "level": 4,
//       "status": 1,
//       "OpeHr": 176,
//       "BatteryPw": 1,
//       "avail": 2,
//       "MTBF": 238965,
//       "MTTR": 2,
//       "faultRate": "445 x 10^-4",
//       "faultCount": 5
//     }
// ]