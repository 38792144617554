export const  GetSiteList = (DeviceData) => {
    if(DeviceData == null) return []
    
    const ReturnSiteList = DeviceData.map((item) => {
        return item.locName
    })
    return ReturnSiteList
}

export const  GetLevelList = (props, Site) => {
    const {DeviceData, currentSite} = props
    if(DeviceData == null || currentSite == null) return []
    if(Site == "All") return []
    const PickedSite = DeviceData[currentSite]
    if(PickedSite == null || PickedSite.nestedLocs == null) return []

    const LevelList = PickedSite.nestedLocs.map((item) => {
        return item.locName
    })
    return LevelList
}