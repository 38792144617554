import React, { useRef, useEffect } from 'react'
import './Chart.css'

function render(ctx, props) {
    let data = props.options.series.reduce((a, x) => ({ ...a, [x.name]: x.y }), {})

    ctx.clearRect(0, 0, ctx.width, ctx.height);

    //component setting
    let cx = ctx.width / 2
    let cy = ctx.height / 2
    let radius = Math.min(ctx.width, ctx.height) / 2 * 0.85
    let colors = ['#FFFFCC', '#CCFFFF', '#FFCCCC', '#FFCC99'];

    let data_raw = { ...data };
    const total = Object.values(data).reduce((a, b) => a + b, 0)

    Object.keys(data).map(function (key, index) {
        data[key] *= 2 * Math.PI / total;
    });

    var beginAngle = 0;
    var endAngle = 0;

    // draw segment
    var i = 0
    var x = cx + radius / 2
    var y = cy
    for (var key in data_raw) {
        beginAngle = endAngle;
        endAngle = endAngle + data[key];
        ctx.beginPath();
        ctx.fillStyle = colors[i % colors.length];
        ctx.moveTo(cx, cy);
        ctx.arc(cx, cy, radius, beginAngle, endAngle);
        ctx.fill();

        i += 1
    }

    beginAngle = 0;
    endAngle = 0;
    for (var key in data_raw) {
        beginAngle = endAngle;
        endAngle = endAngle + data[key];
        //draw legand
        ctx.fillStyle = "black";
        ctx.textAlign = "left";
        var angle = endAngle - data[key] / 2;
        var cos = Math.cos(-1 * angle)
        var sin = Math.sin(-1 * angle)
        var word_x = (cos * (x - cx)) + (sin * (y - cy)) + cx
        var word_y = (cos * (y - cy)) - (sin * (x - cx)) + cy;
        ctx.fillText(key + ": " + data_raw[key], word_x, word_y);

        i += 1
    }

    // draw circle border
    if (props.options.border.enabled) {
        if (props.options.border.color.length > 0) {
            ctx.beginPath();
            ctx.strokeStyle = props.options.border.color;
            ctx.lineWidth = 5;
            ctx.arc(cx, cy, radius, 0, 2 * Math.PI);
            ctx.stroke();
        }
    }

    // draw title
    if (props.options.title.text.length > 0) {
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.fillText(props.options.title.text, cx, cy - radius - 10);
    }
}

function Chart(props) {
    const canvasRef = useRef(null)

    useEffect(() => {
        const canvas = canvasRef.current
        const ctx = canvas.getContext('2d')
        ctx.width = props.width
        ctx.height = props.height
        //ctx.fillStyle = 'blue'
        //ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height)
        render(ctx, props)
    }, [props.options])

    return (
        <div>
            <canvas ref={canvasRef} {...props} />
        </div>
    )
}

export default Chart