import React, { useState } from 'react'
import './table.css'
import Moment from 'react-moment'
import { connect } from 'react-redux'

import LoadingWidget from '../../common/loading/loading'
// import PageButtonsWidget from '../pagebuttons/pagebuttons'
import genAlarmRecord from './genAlarmRecords'

function LiftAlarmTableWidget(props) {
    // const {LiftAlarmStatistic, DeviceDataLastUpdate} = props
    // const AlarmRawData = (LiftAlarmStatistic==null||LiftAlarmStatistic.data==null)?[]:LiftAlarmStatistic.data

    const {Notification, currentSite, currentlevel, DeviceData} = props
    const AlarmRawData = Notification==null||Notification.data==null?[]:Notification.data

    const [OpenDetail, setOpenDetail] = useState(null)      //null mean no detail show, else show specified row data

    function OpenDetailFunction(RowID) {
        if(OpenDetail == RowID) setOpenDetail(null)
        else setOpenDetail(RowID)
    }
    // let AlarmList = []
    // let RowID = 1

    // Object.keys(AlarmRawData).forEach(k => {
    //     const LiftList = getUniqStrList(AlarmRawData[k], 'locationName')

    //     LiftList.forEach(LiftStr => {
    //         const FilteredList = LiftAlarmStatistic.data[k].filter(item => {
    //             return item.locationName == LiftStr
    //         })
    //         console.log(FilteredList)
    //         let TotalCount = 0
    //         FilteredList.forEach(recordItem => {
    //             TotalCount += recordItem[k]
    //         })
    //         AlarmList.push({
    //             Lift: LiftStr,
    //             keystr: k,
    //             alarmArray: FilteredList,
    //             count: TotalCount,
    //             RowID: RowID
    //         })
    //         RowID++
    //     })
    // })
    // AlarmList = AlarmList.sort(function(a, b) {
    //     return a.count - b.count
    // })
    const newAlarmList = genAlarmRecord(AlarmRawData, DeviceData, currentSite, currentlevel)

    return [
        <div className="ListAlarm_inner">
            <div className="ListAlarm_headrow">
                <div className="ListAlarm_col">
                    Lift ID
                </div>
                <div className="ListAlarm_col">
                    Alarm Type
                </div>
                <div className="ListAlarm_col">
                    Alarm Number
                </div>
            </div>
                {
                    (Notification==null || Notification.data==null)?
                    <LoadingWidget />
                    :
                    (newAlarmList==null||newAlarmList.length==null||newAlarmList.length==0)?
                    <div className='ListAlarm_nodata'>No Alarm Record</div>
                    :
                    newAlarmList.map(item => {
                        const {typeName, liftID, count, rowID} = item
                        
                        let CountStatusClass = ''
                        if(count >= 100) CountStatusClass = 'manycolor'
                        else if(count >= 50)  CountStatusClass = 'somecolor'

                        return <div className="ListAlarm_row" onClick={()=>OpenDetailFunction(rowID)}>
                            <div className="ListAlarm_col">
                                {liftID}
                            </div>
                            <div className="ListAlarm_col">
                                {typeName}
                            </div>
                            <div className={"ListAlarm_col " + CountStatusClass}>
                                {count}
                            </div>
                        </div>
                        // if(OpenDetail == rowID) {
                        //     const alarmArray = item.alarmArray
                        //     const DetailWidgets = alarmArray.map(inneralarm => {
                        //         return <div className="LiftAlarm_detail">
                        //             <div className="LiftAlarm_detailrow">
                        //                 <div className="col">
                        //                     <Moment className="" format="DD MMM YYYY">{inneralarm.time}</Moment>
                        //                 </div>
                        //                 <div className="col">
                        //                     {inneralarm[item.keystr]}
                        //                 </div>
                        //             </div>
                        //         </div>
                        //     })
                        //     return [
                        //     <div className="ListAlarm_row opendetail" onClick={()=>OpenDetailFunction(rowID)}>
                        //         <div className="ListAlarm_col">
                        //             {item.Lift}
                        //         </div>
                        //         <div className="ListAlarm_col">
                        //             {item.keystr}
                        //         </div>
                        //         <div className={"ListAlarm_col " + CountStatusClass}>
                        //             {item.count}
                        //         </div>
                        //     </div>,
                        //     <div className="LiftAlarm_detailhead" onClick={()=>OpenDetailFunction(rowID)}>
                        //         <div className="col">
                        //             Time
                        //         </div>
                        //         <div className="col">
                        //             Alarm No.
                        //         </div>
                        //     </div>,
                        //     DetailWidgets
                        //     ]
                        // }
                        // else {
                        //     return <div className="ListAlarm_row" onClick={()=>OpenDetailFunction(item.RowID)}>
                        //         <div className="ListAlarm_col">
                        //             {item.Lift}
                        //         </div>
                        //         <div className="ListAlarm_col">
                        //             {item.keystr}
                        //         </div>
                        //         <div className={"ListAlarm_col " + CountStatusClass}>
                        //             {item.count}
                        //         </div>
                        //     </div>
                        // }
                    })
                }
        </div>,
        // <PageButtonsWidget AlarmList={AlarmList} DeviceDataLastUpdate={DeviceDataLastUpdate} />
    ]
}

function mapStateToProps(state) {
    return {
        DeviceData: state.DeviceData,
      DeviceDataLastUpdate: state.DeviceDataLastUpdate, //when this value is updated, render would triggered
      LiftAlarmStatistic: state.LiftAlarmStatistic, //result of this page
      Notification: state.Notification,
      currentSite: state.currentSite,
      currentlevel: state.currentlevel
    }
}
export default connect(mapStateToProps)(LiftAlarmTableWidget)


// function getUniqStrList(ArrayList, keystr) {
//     const MapStrList = ArrayList.map((item) => {
//         return item[keystr]
//     })
//     return MapStrList.filter(onlyUnique)
// }
// function onlyUnique(value, index, self) {
//     return self.indexOf(value) === index
// }