import React from 'react'
import './pagebtns.css'
//Redux Import
import { connect } from 'react-redux'

const RecordsPerPage = 10                   //fixed 10 records per page

class PageBtns extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            sortedList: null,
            totalPage: 0,
            currentPage: 1,
        }

        //initial the total page, current page when data is ready
        this.PageCount = this.PageCount.bind(this)
        this.PageRecordUpdate = this.PageRecordUpdate.bind(this)

        this.PickPage = this.PickPage.bind(this)
    }

    componentDidMount() {
        this.PageCount()
    }
    componentDidUpdate(preProps) {
        const {sortedList} = this.props
        const oldsortedList = preProps.sortedList

        if(sortedList != oldsortedList) this.PageCount()
        // console.log(sortedList)
    }
    PageCount() {
        const _this = this
        const {sortedList} = this.props
        const {currentPage} = this.state

        this.setState({
            sortedList: sortedList
        })
        if(sortedList == null || sortedList.length == null || sortedList  == undefined) {
            //no record, set back to empty states
            _this.setState({
                totalPage: 0,
                currentPage: 1,
            })
            return
        }

        var totalPage_number = sortedList.length / RecordsPerPage
        var totalPage_int = Math.ceil(totalPage_number)
        this.setState({
            totalPage: totalPage_int
        }, function() {
            if(totalPage_int < currentPage) {
                _this.setState({
                    currentPage: 1
                })
            }
            _this.PageRecordUpdate()        //update parent
        })
    }
    
    PageRecordUpdate() {
        const _this = this
        const {sortedList} = this.props
        const {currentPage, totalPage} = this.state
        let displayList = sortedList.slice((currentPage-1)*RecordsPerPage, (currentPage)*RecordsPerPage)
        console.log(displayList)
        this.props.UpdateList('pageSliceList', displayList)
    }

    PickPage(value) {
        const _this = this
        const {totalPage} = this.state

        if(value <= 0 || value > totalPage) return       //exception input

        this.setState({
            currentPage: value
        }, function() {
            _this.PageRecordUpdate()
        })
    }

     
    render() {
        const {sortedList, count} = this.props
        const {currentPage, totalPage} = this.state
        const _this = this

        var pageArray = []
        var i=0
        for (i = 0; i < totalPage; i++) {
            pageArray.push(i+1)
        }

        var isHideSomeBtns
        if(totalPage < 8) {
            //no need '...'
            isHideSomeBtns = false
        }
        else {
            // > 5 pages
            isHideSomeBtns = true
        }

        return <div className="CBAuditTrial_pagewidget">
            {/* <div className="CBAuditTrial_pageBtn" onClick={() => _this.PickPage(1)}>{"<"}</div> */}
            <div className="CBAuditTrial_pageBtn" onClick={() => _this.PickPage(currentPage-1)}>Back</div>
            {
                pageArray.map(function(pageInt) {
                    var needShow = NeedShow(isHideSomeBtns, totalPage, currentPage, pageInt)
                    if(needShow=='hide') return <div style={{display: 'none'}}></div>
                    if(needShow=='...') return <div className='ColdboxIndi_btn'>...</div>

                    return <div className={currentPage==pageInt?"CBAuditTrial_pageBtn current":"CBAuditTrial_pageBtn"} onClick={() => _this.PickPage(pageInt)}>
                        {pageInt}
                    </div>
                })
            }
            <div className="CBAuditTrial_pageBtn" onClick={() => _this.PickPage(currentPage+1)}>Next</div>
            {/* <div className="CBAuditTrial_pageBtn" onClick={() => _this.PickPage(totalPage)}>{'>'}</div> */}
        </div>
       
    }
}
//Redux inside component function
function mapStateToProps(state) {
    return {

    }
}
export default connect(mapStateToProps)(PageBtns)

function NeedShow(isHideSomeBtns, totalPage, currentPage, pageInt) {
    if(isHideSomeBtns==false) return 'show'

    if(pageInt==1||pageInt==2) return 'show'
    if(pageInt==totalPage||pageInt==totalPage-1) return 'show'
    if(pageInt==currentPage||pageInt==currentPage-1||pageInt==currentPage+1) return 'show'

    if(currentPage!=pageInt&&(pageInt==3||pageInt==totalPage-2)) return '...'
    return 'hide'
}