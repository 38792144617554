import './LiftSensors.css'
import React from 'react'
import { connect } from 'react-redux'

import HeaderWidget from '../common/head/head'
import ButtonWidget from '../common/searchbtn/searchbtn'
import DatePickWidget from '../common/datepick/datepick'
import LoadingWidget from '../common/loading/loading'
import ExportExcelWidget from '../common/ExportExcel/ExportExcel'
import LiftSelectWidget from '../common/LiftSelect/LiftSelect'
import LineChartbtnWidget from '../common/LineChart/LineChartbtn'
import LineChartWidget from '../common/LineChart/LineChart'

import convertDate from '../common/convertDate'
import getMonthList from './getMonthList'
import genDataArray from './genDataArray'

class LiftSensors extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            startDate: null,
            endDate: null,
            LiftList: [], 
            ShowChart: false,
        }

        this.onChangeFunction = this.onChangeFunction.bind(this)

        this.ChangeDate = this.ChangeDate.bind(this)
        this.SetShowChart = this.SetShowChart.bind(this)

        this.RequestData = this.RequestData.bind(this)
    }

    SetShowChart(boolValue) {
        this.setState({
            ShowChart: boolValue
        })
    }

    onChangeFunction(SelectedLiftList) {
        const LiftList = SelectedLiftList.map((item) => {
            return item._id
        })
        console.log(LiftList)
        this.setState({
            LiftList: LiftList
        })
    }
    ChangeDate(ID, DateInput) {
        var newDate = DateInput
        this.setState({
            [ID]: newDate
        })
    }
    componentDidMount() {
        //emit request to get data
        const _this = this
        setTimeout(function() {
            _this.RequestData()
        }, 100)
    }
    RequestData() {
        const {UserInfo, currentlevelID, StatisticData} = this.props

        //clear redux
        this.props.dispatch({ type: 'DeviceStatistic', 
            data: {
                co2: StatisticData.co2,
                pm25: StatisticData.pm25,
                temp: StatisticData.temp,
                humid: StatisticData.humid,
                power: StatisticData.power,
                illuminance: StatisticData.illuminance,
                loading: false,
                lift: StatisticData.lift,
                mileageTravelled: null,
                powerConsumed: StatisticData.powerConsumed,
                runtimeTravelled: StatisticData.runtimeTravelled,
                doorOpenCount: StatisticData.doorOpenCount,
                liftStartupCount: StatisticData.liftStartupCount,
                liftDirectionReverseCount: StatisticData.liftDirectionReverseCount,
            },
        })

        //request
        const {startDate, endDate} = this.state

        if(UserInfo == null) return
        if(startDate == null || endDate == null) return

        //get data to emit
        const clientID = UserInfo.client_id
        let finalendDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 1)

        const EmitJson = {
            "msgType": "LocationStatistics",
            "clientID": clientID,
            "payload_field": ["mileageTravelled"],
            "timeConstraint": {
              "start": convertDate(startDate),
              "end": convertDate(finalendDate),
            },
            "timeFrequency": {
              "unit": "month",
              "value": 1
            },
            "locationConstraint": {
              "locLvl": 3,
              "id": currentlevelID
            },
            "locationGrouping": 3,
            "calculationMethod": "sum"
        }
        console.log(EmitJson)

        this.props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "StatRequest",
            EmitSocketData: EmitJson
        })
    }
    
    render() {
        const {StatisticData, DeviceData} = this.props
        const {ShowChart, startDate, endDate} = this.state
        const MonthList = getMonthList(startDate, endDate)
        
        const DisplayList = (StatisticData == null || StatisticData.mileageTravelled == null || StatisticData.mileageTravelled.length == null)?
        <LoadingWidget />
        :
            (StatisticData.mileageTravelled.length == 0)?
            <div className="LiftSummary_row" key="No Data Widget">No Data</div>
            :
            genDataArray(MonthList, StatisticData, 'mileageTravelled').map(rowItem => {
                return <div className="LiftSummary_row">
                    <div className="LiftSummary_col">
                        {rowItem.locationName}
                    </div>
                    <div className="LiftSummary_col">
                        {rowItem.mileageTravelled==null?'No Data':(rowItem.mileageTravelled/1000).toFixed(2) + ' km'}
                    </div>
                    <div className="LiftSummary_col">
                        {rowItem.timeStr}
                    </div>
                </div>
            })

        return <div className="LiftSummary">
            <HeaderWidget Title="Monthly Mileage Report" Icon="table" />
            <div className="LiftSummary_search">
                <div className='LiftSummary_searchTitle'>
                    Months:
                </div>
                <DatePickWidget holderTxt="Start Date" DefaultDate="PreMonth" ID="startDate" ChangeDate={this.ChangeDate} />
                <DatePickWidget holderTxt="End Date" DefaultDate="Today" ID="endDate" ChangeDate={this.ChangeDate} />
                {/* <LiftSelectWidget onChangeFunction={this.onChangeFunction} DeviceData={DeviceData} /> */}
                <ButtonWidget Icon="search" ClickFunction={this.RequestData} />
                <ExportExcelWidget header="Mileage" unit="kM" keystr="mileageTravelled" Data={StatisticData} />
                <LineChartbtnWidget SetShowChart={this.SetShowChart} />
            </div>
            {
                ShowChart?
                <LineChartWidget ChartData={StatisticData.mileageTravelled} title="(kM)" keystr="mileageTravelled" />
                :
                [
                    <div className="LiftStartup_rowheader">
                        <div className="LiftStartup_col">
                            Lift Name
                        </div>
                        <div className="LiftStartup_col">
                            Mileage Travelled
                        </div>
                        <div className="LiftStartup_col">
                            Month
                        </div>
                    </div>,
                    <div className="LiftSummary_table">
                        {DisplayList}
                    </div>
                ]
            }
        </div>
    }
}

function mapStateToProps(state) {
    return {
      socket: state.socket,
      UserInfo: state.UserInfo,
      currentlevel: state.currentlevel,
      currentSite: state.currentSite,
      DeviceData: state.DeviceData,
      currentlevelID: state.currentlevelID,
      StatisticData: state.StatisticData,
      DeviceDataLastUpdate: state.DeviceDataLastUpdate,
    }
}
export default connect(mapStateToProps)(LiftSensors)