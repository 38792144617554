import React from 'react'
import './tablehead.css'

//Redux Import
import { connect } from 'react-redux'

class TableHead extends React.Component {
    constructor(props)
    {
        super()
    }

    render()
    {
        return <div className="Coldbox_GD_TableHead">
            <div className="col1">ID</div>
            <div className="col2">Temperature Suction</div>
            <div className="col3">Pressure Condensing</div>
            <div className="col4">High Condensing Pressure Warning</div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
    }
}
  
export default connect(mapStateToProps)(TableHead)