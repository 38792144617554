import React, { useEffect, useState } from 'react'
import './chart1.css'
import { connect } from 'react-redux'

import HighchartWidget from './highchart/highchart'

const MinutesInterval = 1
let TickFunction

function ToiletIAQWidget(props) {
    const {UserInfo, DeviceData, ToiletType, currentlevel, currentSite, socket} = props
    // const [ResponseData, setResponseData] = useState()
    const [ReadingType, setReadingType] = useState(0)   //0 - 3
    const DateStart = GetStartDate()
    const DateEnd = new Date()

    useEffect(() => {
        StartIntervalLoop()
    }, [DeviceData, ToiletType, currentlevel, currentSite, ReadingType])
    useEffect(() => {
        RequestData()
    }, [ReadingType, ToiletType])
    useEffect(() => {
        return function TerminateInterval() {
            clearInterval(TickFunction)
        }
    }, [])
    function StartIntervalLoop() {
        if(TickFunction != null) clearInterval(TickFunction)

        TickFunction = setInterval(RequestData, 1000 * 60 * 60 * MinutesInterval)
        RequestData()
    }
    function RequestData() {
        if(UserInfo == null) return
        // setResponseData(null)           //clear old data

        const SiteID = GetSiteID(DeviceData, currentSite)
        const LevelID = GetLevelID(DeviceData, currentSite, currentlevel)
        let reaType = ''
        switch(ReadingType) {
            case 0:
                reaType = 'temperature'
                break
            case 1:
                reaType = 'humidity'
                break
            case 2:
                reaType = 'co2'
                break
            case 3:
                reaType = 'pm2_5'
                break
            case 4:
                reaType = 'h2S'
                break
            case 5:
                reaType = 'sO2'
                break
        }
        const EmitJson = {
            "msgType": "LocationStatistics",
            "msgID": "LineChart",
            "UserID": UserInfo.userID,
            "clientID": UserInfo.client_id,
            "payload_field": [reaType],
            "timeConstraint": {
                "start": DateStart,
                "end": DateEnd,
            },
            "timeFrequency": {
                "unit": "day",
                "value": 1
            },
            "locationConstraint": {
                "locLvl": 2,
                "id": LevelID
            },
            "locationGrouping": 3,
            "calculationMethod": "average",
            "toiletType": ToiletType //1 = male, 2 = female, 3 = other
        }
        console.log(EmitJson)
        setTimeout(() => {
            //emit statistic request
            props.dispatch({ type: 'EmitSocket', 
                EmitSocketName: "StatRequest",
                EmitSocketData: EmitJson
            })
        }, 100)
    }

    let marginLeftV
    let ribbonColor
    let chartColor
    let typeKey = 'temp'
    let currentReading = ''
    let currentReadingUnit = '°C'
    const LocationList = FilterLocationList(props)
    const screenWidth = window.screen.width
    switch(ReadingType) {
        case 0:
            marginLeftV = 0
            ribbonColor = 'teal'
            chartColor = '0, 193, 193'
            typeKey = 'temp'
            currentReading = CalAverage(LocationList, "temperature").toFixed(1)
            currentReadingUnit = '°C'
            break
        case 1:
            marginLeftV = screenWidth>1280?6.5:6.1
            ribbonColor = 'rgba(73, 130, 255, 1)'
            chartColor = '255, 193, 193'
            typeKey = 'humid'
            currentReading = CalAverage(LocationList, "humidity").toFixed(1)
            currentReadingUnit = '%'
            break
        case 2:
            marginLeftV = screenWidth>1280?13:12.2
            ribbonColor = 'rgba(185, 73, 255, 1)'
            chartColor = '0, 255, 0'
            typeKey = 'co2'
            currentReading = CalAverage(LocationList, "co2").toFixed(1)
            currentReadingUnit = 'ppmv'
            break
        case 3:
            marginLeftV = screenWidth>1280?19.5:18.3
            ribbonColor = 'rgba(254, 50, 139, 1)'
            chartColor = '0, 0, 255'
            typeKey = 'pm25'
            currentReading = CalAverage(LocationList, "pm2_5").toFixed(1)
            currentReadingUnit = 'ppmv'
            break
        case 4:
            marginLeftV = screenWidth>1280?26:24.4
            ribbonColor = 'rgba(255, 152, 0, 1)'
            chartColor = '0, 150, 136'
            typeKey = 'h2s'
            currentReading = CalAverage(LocationList, "h2S").toFixed(1)
            currentReadingUnit = 'ppm'
            break
        case 5:
            marginLeftV = screenWidth>1280?32.5:30.5
            ribbonColor = 'rgba(139, 195, 74, 1)'
            chartColor = '63, 81, 181'
            typeKey = 'so2'
            currentReading = CalAverage(LocationList, "sO2").toFixed(1)
            currentReadingUnit = 'ppm'
            break
    }
    return <div className="ToiletAdmin_chart">
        <div className="ToiletAdmin_title">
            <div className="ToiletAdmin_tabs">
                <div className="ToiletAdmin_tab_ribbon" style={{marginLeft: marginLeftV + 'rem', background: ribbonColor}}></div>
                <div className="ToiletAdmin_tab" style={{color: ReadingType==0?'white':'black'}} onClick={()=>setReadingType(0)}>Temp</div>
                <div className="ToiletAdmin_tab" style={{color: ReadingType==1?'white':'black'}} onClick={()=>setReadingType(1)}>Humid</div>
                <div className="ToiletAdmin_tab" style={{color: ReadingType==2?'white':'black'}} onClick={()=>setReadingType(2)}>CO2</div>
                <div className="ToiletAdmin_tab" style={{color: ReadingType==3?'white':'black'}} onClick={()=>setReadingType(3)}>PM2.5</div>
                <div className="ToiletAdmin_tab" style={{color: ReadingType==4?'white':'black'}} onClick={()=>setReadingType(4)}>H2S</div>
                <div className="ToiletAdmin_tab" style={{color: ReadingType==5?'white':'black'}} onClick={()=>setReadingType(5)}>SO2</div>
            </div>
            <div className="ToiletAdmin_Reading">
                <div className="readingtitle">Current</div>
                <div className="reading" style={{color: ribbonColor}}>{currentReading}{currentReadingUnit}</div>
            </div>
        </div>
        <HighchartWidget color1={'rgba(' + chartColor + ', 1)'} color2={'rgb(' + chartColor + ', 0.3)'} markercolor={ribbonColor} typeKey={typeKey} />
    </div>
}

function mapStateToProps(state) {
    return {
        UserInfo: state.UserInfo,
        DeviceData: state.DeviceData,
        ToiletType: state.ToiletType,
        currentlevel: state.currentlevel,
        currentSite: state.currentSite,
        socket: state.socket,
    }
}
export default connect(mapStateToProps)(ToiletIAQWidget)

function GetStartDate() {
    var d = new Date()
    d.setMonth(d.getMonth() - 3)
    return d
}
function GetSiteID(DeviceData, currentSite) {
    try {
        const SiteItem = DeviceData[currentSite]
        const SiteID = SiteItem._id
        return SiteID
    }
    catch(err) {
        return err
    }
}
function GetLevelID(DeviceData, currentSite, currentlevel) {
    try {
        const LevelList = DeviceData[currentSite].nestedLocs
        let LevelID = "No Level ID found"
        LevelList.forEach(LevelItem => {
            if(LevelItem.locName == currentlevel || LevelItem.locDesc == currentlevel) {
                LevelID = LevelItem._id
            }
        })
        return LevelID
    }
    catch(err) {
        return err
    }
}
// Get current data
function FilterLocationList(props) {
    const {DeviceData, currentSite, currentlevel, ToiletType} = props
    if(DeviceData == null || currentlevel == null || currentSite == null) return

    const SiteItem = DeviceData[currentSite]
    if(SiteItem == null) return
    const LevelItem = SiteItem.nestedLocs.find(item => {
        return item.locName.toString() == currentlevel.toString()
    })
    if(LevelItem == null) return
    const ReturnLocationList = LevelItem.nestedLocs.filter(item => {
        if(item.payload == null || item.payload_definition_id == null || item.payload.toiletType == null) return false
        return item.payload.toiletType.toString() == ToiletType.toString()
    })
    return ReturnLocationList
}
function CalAverage(LocationList, payloadKeystr) {
    if(LocationList == null) return 0
    const FilteredList = LocationList.filter(item => {
        if(item.payload == null) return false

        return item.payload[payloadKeystr] != null
    })
    if(FilteredList == null || FilteredList.length == null || FilteredList.length == 0) return 0

    const Total = FilteredList.length
    let Sum = 0
    FilteredList.forEach(item => {
        Sum += item.payload[payloadKeystr]
    })
    const Result = Sum/Total
    return Result
}