/**

Input:
DefaultDate: Define the TYPE
>> Today
>> PreMonth

ID: datepicker ID
ChangeDate: function to trigger when date value is changed, with input date 9string)

*/

import React, { useState, useEffect } from 'react'
import './WeekPick.css'

import DatePicker from 'react-datepicker'

function WeekPickWidget(props) {
    const [inputDate, setDate] = useState(new Date())
    const {DefaultDate, ChangeDate, ID, input} = props

    useEffect(() => {
        //onload function
        var SetDateValue
        var x = new Date()

        switch(DefaultDate) {
            case "Start":
                SetDateValue = new Date(x.getFullYear(), x.getMonth(), x.getDate() - 7)
                break
            case "End":
                SetDateValue = new Date(x.getFullYear(), x.getMonth(), x.getDate())
                break
        }
        SetDateValue.setHours(16)
        SetDateValue.setMinutes(0)
        setDate(SetDateValue)
    }, [])

    useEffect(() => {
        //send the updated Date to parent
        if(inputDate == null) return
        let StartDate, EndDate
        switch(DefaultDate) {
            case "Start":
                // StartDate = inputDate
                // EndDate = new Date(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate() + 7)
                ChangeDate(DefaultDate, inputDate, null)
                break
            case "End":
                // StartDate = new Date(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate() - 7)
                // EndDate = inputDate
                ChangeDate(DefaultDate, null, inputDate)
                break
        }
        // ChangeDate(ID, StartDate, EndDate)
    }, [inputDate])    

    return <div className="WeekPickerClass">
        <DatePicker selected={input} className="LiftSummary_weekinput" dateFormat="dd MMM yyyy" onChange={(date) => setDate(date)} />
    </div>
    
}

export default WeekPickWidget