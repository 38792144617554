import React, { useState, useEffect } from 'react'
import './headbar.css'

import { connect } from 'react-redux'

import {GetSiteList, GetLevelList} from '../functions'

function Headbar(props) {
    const {DeviceData, currentSite, currentlevel, currentlevelID, UserInfo} = props
    const [Site, setSite] = useState('All')
    const [SiteList, setSiteList] = useState([])
    const [level, setLevel] = useState("All")
    const [levelList, setLevelList] = useState([])

    useEffect(() => {
        //Update the Site List
        const SiteList = GetSiteList(DeviceData)
        setSiteList(SiteList)
        //Update the Level List
        const LevelList = GetLevelList(props, Site)
        setLevelList(LevelList)
        //Update Parent state
        props.SetFilterSiteLevel(Site, level)
    }, [Site, level])

    function ChangeSite(input) {
        const OptionValue = input.target.value
        if(OptionValue == 'All') setSite('All')
        else {
            setSite(OptionValue)
            // const newlevelList = UserInfo.locations[OptionValue].nestedLocs
            // props.dispatch({ type: 'PIckSite', data: {
            //     Siteid: OptionValue,
            //     Level: newlevelList
            // }})
        }
    }
    function SetLevel(input) {
        const OptionValue = input.target.value
        if(OptionValue == 'All') setLevel('All')
        else {
            setLevel(OptionValue)
        }
    }

    return <div className="OperOver_head">
        <div className="OperOver_backBtn" onClick={props.backBtn}>
            <div className="fa fa-arrow-left"></div>
        </div>
        <div className="fa fa-building"></div>
        <div className="OperOver_headTxt">Coldbox Operator Overview</div>
        <select className="OperOver_headSelect" onChange={ChangeSite}>
            <option value="All">All Site</option>
            {
                SiteList.map((item, index) => {
                    return <option value={index}>{item}</option>
                })
            }
        </select>
        <select className="OperOver_headSelect" onChange={SetLevel}>
            <option value="All">All Level</option>
            {
                levelList.map((item, index) => {
                    return <option value={item}>{item}</option>
                })
            }
        </select>
    </div>
}

function mapStateToProps(state) {
    return {
        UserInfo: state.UserInfo,
        DeviceData: state.DeviceData,
        currentSite: state.currentSite,
        currentlevel: state.currentlevel,
        currentlevelID: state.currentlevelID,
    }
}
  
export default connect(mapStateToProps)(Headbar)