import React from 'react'
import './searchbar.css'
import { connect } from 'react-redux'

class SearchBar extends React.Component {
    constructor(props) {
        super()
        this.state = {
            filterTxt: '',    //Filter search txt
            isRefreshing: false
        } 
        this.FilterOnchange = this.FilterOnchange.bind(this)
        this.Filter = this.Filter.bind(this)
    }

    componentDidMount() {
        const {GroupList} = this.props
        console.log(GroupList)
        if (GroupList == null || GroupList == undefined) return 
        this.Filter()
    }   
    componentDidUpdate(preProps) {
        //Re-gen option list when data is updated
        const {GroupList} = this.props
        const oldGroupList = preProps.GroupList
        if (GroupList != oldGroupList || (GroupList != null && oldGroupList == null)) this.Filter()
    }  
    FilterOnchange(keyStr, e) {
        try {
            const value = e.target.value.toString()
                // console.log(e.target)
                this.setState({
                    [keyStr]: value,
                }, function () {        //run right after state is set
                    this.Filter()       //re-filter
                })
        }
        catch (err) {
            console.log(err)
        }
    }
    Filter() {
        const {filterTxt} = this.state
        const {GroupList} = this.props

        var FilteredList = GroupList
        if (filterTxt != null && filterTxt != "") {
            FilteredList = GroupList.filter(function (coldboxItem) {
                if(coldboxItem.group_name.includes(filterTxt.toString())) return true   //coldboxItem.keywords.includes(filterTxt.toString())
                return false
            })
            console.log(FilteredList)
        }

        this.props.UpdateList('filteredList', FilteredList)
    }

    render() {
        const _this = this
        const {isRefreshing} = this.state

        return <div className="Coldbox_Manage_searchbar">
            <div className="Coldbox_Manage_searchbarrow">
                <div className="Coldbox_Manage_BackBtn" onClick={this.props.backPage}>
                    <div className="fa fa-arrow-left"></div>
                </div>
                <div className="ColdboxGp_Manage_title">Coldbox Group Management</div>
            </div>
            <div className="Coldbox_Manage_searchbarrow">
                <div>Filter</div>
                <input placeholder="Search by text"  onChange={(e) => this.FilterOnchange('filterTxt', e)}></input>
                <div className="Coldbox_Manage_ReloadBtn" onClick={() => {
                    this.props.refresh()
                    this.setState({
                        isRefreshing: true
                    })
                    setTimeout(() => {
                        this.setState({
                            isRefreshing: false
                        })
                    }, 2000)
                }}>
                    <div className={isRefreshing?'fa fa-refresh fa-spin':'fa fa-refresh'}></div>
                </div>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {}
}  
export default connect(mapStateToProps)(SearchBar)