import React from 'react'
import { connect } from 'react-redux'

class MenuListItem extends React.Component {
    constructor(props) {
        super(props)
        this.ListMenuPicked = this.ListMenuPicked.bind(this)
        this.RemoveFocusHover = this.RemoveFocusHover.bind(this)
    }

    ListMenuPicked(Event) {
        this.props.pickeditem(Event.currentTarget.children[1].id)  //pick the second chidren item and get its id, put inside the input value      
        this.RemoveFocusHover()
    }
    RemoveFocusHover() {
        this.props.dispatch({ type: 'bottomcardhover', 
            data: "bottomcard_-1"
        }) //no card is highlight
    }

    render() {
        let listfocus = ""
        const { cardType, typeTitle, name, nameID, currentBottomMenu } = this.props

        //highlight the area card if it is hovered
        if(this.props.currentBottomMenu === name) listfocus = "listfocus"

        if(isSwimmingCtrl(this.props)) return <SwimmingCtrl currentBottomMenu={currentBottomMenu} ListMenuPicked={this.ListMenuPicked}  />
        if(isConvertWaterLeakageToWaterPump(this.props)) {
            return <div className={'flex flex-row list ' + listfocus} onClick={ this.ListMenuPicked } >
                <i className='fa fa-tint menucardicon'></i>
                <div className='text-left menucardtitle' id={name}>Water Pump</div>
            </div>
        }
        if(isConvertToWaterLeakageAndPump(this.props, true)) {
            const [highlight1, highlight2] = [
                currentBottomMenu === 'bottom0'? 'listfocus': '',
                currentBottomMenu === 'bottom2'? 'listfocus': '',
            ]
            return [
                <div className={'flex flex-row list ' + highlight1} onClick={ this.ListMenuPicked } >
                    <i className='fa fa-tint menucardicon'></i>
                    <div className='text-left menucardtitle' id={name}>Water Leakage</div>
                </div>,
                <div className={'flex flex-row list ' + highlight2} onClick={()=>{
                    this.props.pickeditem('bottom2')  //pick the second chidren item and get its id, put inside the input value      
                    this.RemoveFocusHover()
                }} >
                    <i className='fa fa-tint menucardicon'></i>
                    <div className='text-left menucardtitle' id='Water pump'>Water Pump</div>
                </div>,
            ]
        }

        let iconClassName = TypeToIconClassName[cardType]==null?'wrench fa-rotate-270':TypeToIconClassName[cardType]
        if(cardType === "Occupancy" && typeTitle !== 'Car Park') iconClassName = "users"
        if(cardType === "LED Tube" || nameID === "LED Tube") iconClassName = "minus"
        if(cardType === 'EnergyMeter' || cardType === 'pplCount' || cardType === 'parameters') return <></>

        return <div className={"flex flex-row list " + listfocus} onClick={ this.ListMenuPicked } >
            <i className={"fa fa-" + iconClassName + " menucardicon"}></i>
            <div className="text-left menucardtitle" id={name}>
                {typeTitle}
            </div>
        </div>
    }
}

const mapStateToProps = state => {
    return {
      currentBottomMenu: state.currentBottomMenu,
      currentlevelID: state.currentlevelID,
      DeviceDataLastUpdate: state.DeviceDataLastUpdate
    }
}
export default connect(mapStateToProps)(MenuListItem)

const TypeToIconClassName = {
    'Temperature & Humidity': 'thermometer-half',
    'Car Park': 'car',
    'Disabled Parking': 'wheelchair',
    'IAQ': 'cloud',
    'IEQ': 'cloud',
    'LUX': 'lightbulb-o',
    'AC Power': 'bolt',
    'Rubbish Bin': 'trash',
    'Waste Bin': 'trash',
    'Toilet Occupancy': 'user',
    'Fall Detection': 'user',
    'People Count': 'user',
    'Water Leakage (Point)': 'tint',
    'Water Leakage': 'tint',
    'Door Contact': 'opencart',
    'Door': 'opencart',
    'Vibration': 'arrows',
    'Push Button': 'bell',
    'LED Tube': 'minus',
    'Soap Level': 'database',
    'Water Level': 'tint',
    'Toilet Paper': 'sticky-note',
    'Water Quality': 'tint',
    'Outdoor Environment Quality': 'cloud',
    'Outdoor Environment Quality (Wind)': 'cloud',
    'Soap Level': 'flask',
    'Soap': 'flask',
    'PMC': 'flash',
    'CCTV': 'eye',
    'Thermocouple': 'tachometer',
    'Reed Switch Detection': 'magnet',
    'IAQ15in1': 'thermometer-half',
    'IAQ16in1': 'thermometer-half',

    //new item for swimming pool (kwai shing)
    'Water Quality Sensor': 'tint',
    'Plant Status': 'flash',
    'Automatic Control Mode': 'cogs',
    'Flow Meter': 'compass',
    'Carbon Detection Sensor': 'cloud',
    'Pump Statistic': 'book',
    'Pump Consumption': 'book',
    'Pump Status': 'book',
    'EnergyMeter': 'bolt',
    'PeopleflowSensor': 'users',

    'Netvox_ADC': 'flash',              //HSF site new device
}

//swimming pool item
const isSwimmingCtrl = props => {
    const {nameID} = props
    if(nameID=='ATAL_Ctrl_8AI_8DI') return true //fixed nameID of swimming pool
    return false
}
const SwimmingCtrl = props => {
    const {currentBottomMenu, ListMenuPicked} = props
    const menuList = [
        {
            icon: 'tint',
            name: 'Water Quality Sensor',
            id: 'bottom' + '100'
        },
        {
            icon: 'flash',
            name: 'Plant Status',
            id: 'bottom' + '101'
        },
        {
            icon: 'bars',
            name: 'Automatic Control Mode',
            id: 'bottom' + '102'
        },
        {
            icon: 'tint',
            name: 'Flow Meter',
            id: 'bottom' + '103'
        },
        {
            icon: 'cloud',
            name: 'Carbon Detection Sensor',
            id: 'bottom' + '104'
        },
        {
            icon: 'line-chart',
            name: 'Pump Statistic',
            id: 'bottom' + '105'
        },
        {
            icon: 'bolt',
            name: 'Pump Consumption',
            id: 'bottom' + '106'
        },
        {
            icon: 'flag',
            name: 'Pump Status',
            id: 'bottom' + '107'
        }
    ]
    return menuList.map(item => {
        const listfocus = currentBottomMenu===item.id?'listfocus':''
        const {icon, name, id} = item
        return <div className={"flex flex-row list " + listfocus} onClick={ ListMenuPicked } >
            <i className={"fa fa-" + icon + " menucardicon"}></i>
            <div className="text-left menucardtitle" id={id}>
                {name}
            </div>
        </div>
    })
}

// china chem water pump with water leakage sensor (CLP)
export const isConvertWaterLeakageToWaterPump = props => {
    const {currentlevelID, nameID} = props

    return (currentlevelID==='62b96a54b677f61754b00571' && nameID === 'Water Leakage') || currentlevelID == '62b96a54b677f61754b00671'
}
// (TCP)
export const isConvertToWaterLeakageAndPump = (props, ismenu) => {
    const {currentlevelID, nameID, currentBottomMenu} = props
    return currentlevelID==='62b94ac6854329b83855b398' && 
        ((nameID === 'Water Leakage' && ismenu) || (currentBottomMenu === 'bottom2' && !ismenu))
}
export const isWaterPumpLocations = (locName) => {
    if(!locName) return false
    return locName.includes('TCP-WP') || locName.includes('CLP-WP') || locName.includes('CHC-WP')
}
export const resolvePositionOnClick = (locName, props) => {
    const locType = locName.includes('TCP-WP')?'TCP-WP':locName.includes('CLP-WP')?'CLP-WP':'CHC-WP'
    props.dispatch({
        type: 'FloorPlanBottomMenu',
        data: locType=='CHC-WP'?'bottom0':'bottom2'
    })

    const {DeviceData} = props
    const siteIds = {
        'TCP-WP': '62b94ac6854329b83855b397',
        'CLP-WP': '62b96a54b677f61754b30571',
        'CHC-WP': '62947878e522c897b5d126dd',
    }
    const findSite = siteIds[locType]
    const levelIds = {
        'TCP-WP': '62b94ac6854329b83855b398',
        'CLP-WP': '62b96a54b677f61754b00571',
        'CHC-WP': '62b96a54b677f61754b00671',
    }
    const findLevel = levelIds[locType]
    const locs = DeviceData.find(item => item._id == findSite)
        .nestedLocs.find(item => item._id == findLevel)
        .nestedLocs.filter(item => item.locName.includes(locType))
    
    const cardIndex = locs.findIndex(item => item.locName === locName)
    props.dispatch({
        type: 'bottomcardhover',
        data: 'bottomcard_' + cardIndex}
    )
    props.dispatch({ type: 'PickPolygon', 
        data: locName
    })
}