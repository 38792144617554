import React, { useEffect, useState } from 'react'
import './OccupancyStatistic.css'
import { connect } from 'react-redux'

import OccChartWidget from './chart/chart'

const ScheduleUpdateInterval = 1       //minutes
let TickFunction

function OccupancyWidget(props) {
    const {UserInfo, DeviceData, ToiletType, socket, currentSite, currentlevel} = props
    const [ResponseData, setResponseData] = useState()      //storage of occ record response
    const [IsDaily, setIsDaily] = useState(false)

    const DateStart = GetStartDate()
    const DateEnd = new Date()

    useEffect(() => {
        if(socket == null) return
        socket.on("Toilet_occ", data => {
            console.log(data)
            //save to local state
            setResponseData(data)
        })
        return function ClearWidgetItems() {
            socket.off("Toilet_occ")
        }
    }, [])

    useEffect(() => {               //retrigger interval loop
        StartIntervalLoop()
    }, [ToiletType, currentlevel, currentSite])
    useEffect(() => {               //clear interval loop
        return function TerminateInterval() {
            clearInterval(TickFunction)
        }
    }, [])
    function StartIntervalLoop() {
        if(TickFunction != null) clearInterval(TickFunction)

        TickFunction = setInterval(RequestData, 1000 * 60 * ScheduleUpdateInterval)
        RequestData()
    }
    function RequestData() {
        if(DeviceData == null) return

        const LevelID = GetLevelID(DeviceData, currentSite, currentlevel)
        const EmitJson = {
            "msgType": "Toilet_occ",
            "clientID": UserInfo.client_id,
            "timeConstraint": {
                "start": DateStart,
                "end": DateEnd,
            },
            "locationConstraint": {
                "locLvl": 2,
                "id": LevelID
            },
            "toiletType": ToiletType //1 = male, 2 = female, 3 = other
        }
        console.log(EmitJson)
        setTimeout(() => {
            //emit statistic request
            props.dispatch({ type: 'EmitSocket', 
                EmitSocketName: "StatRequest",
                EmitSocketData: EmitJson
            })
        }, 100)
    }

    return <div className="Toilet_admin_occStat">
        <div className="head">
            Cubicle Usage HIstory
            <div className="Toilet_admin_occ_AMPM" onClick={()=>setIsDaily(!IsDaily)}>
            <div className="AM" style={{color: IsDaily?'teal':'black'}}>Daily</div>
            <div className="PM" style={{color: IsDaily?'black':'teal'}}>Hourly</div>
            <div className="Cover" style={{transform: IsDaily?'translateX(-2.5rem)':'translateX(2.5rem)'}}></div>
        </div>
        </div>
        <OccChartWidget ToiletType={ToiletType} IsDaily={IsDaily} ResponseData={ResponseData} />
    </div>
}

function mapStateToProps(state) {
    return {
        UserInfo: state.UserInfo,
        DeviceData: state.DeviceData,
        ToiletType: state.ToiletType,
        currentlevel: state.currentlevel,
        currentSite: state.currentSite,
        socket: state.socket,
    }
}

export default connect(mapStateToProps)(OccupancyWidget)

// w = 4
// h = 4


function GetLevelID(DeviceData, currentSite, currentlevel) {
    try {
        const LevelList = DeviceData[currentSite].nestedLocs
        let LevelID = "No Level ID found"
        LevelList.forEach(LevelItem => {
            if(LevelItem.locName == currentlevel || LevelItem.locDesc == currentlevel) {
                LevelID = LevelItem._id
            }
        })
        return LevelID
    }
    catch(err) {
        return err
    }
}

function GetStartDate() {
    var d = new Date()
    d.setMonth(d.getMonth() - 3)
    return d
}