import React, { useState, useEffect } from 'react'
import './LineChartbtn.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartLine, faTable } from '@fortawesome/free-solid-svg-icons'

function LineChartbtnWidget(props) {
    const [DisplayChart, setDisplayChart] = useState(false)
    const {SetShowChart} = props                //parent set state function

    useEffect(() => {
        //update parent local state
        SetShowChart(DisplayChart)
    }, [DisplayChart])

    return !DisplayChart?
    <div className="LiftSum_LinechartBtn" onClick={()=>setDisplayChart(true)}>
        <FontAwesomeIcon icon={faChartLine} className="fa" />
    </div>
    :
    <div className="LiftSum_LinechartBtn" onClick={()=>setDisplayChart(false)}>
        <FontAwesomeIcon icon={faTable} className="fa" />
    </div>
}

export default LineChartbtnWidget