import React, { createRef } from 'react'
import './style.scss'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAppleAlt, faPlay } from '@fortawesome/free-solid-svg-icons'

class UserManagement extends React.Component {
    constructor(props) {
        super()

        this.android = this.android.bind(this)
        this.ios = this.ios.bind(this)
    }
    android() {
        //get configure url
        const {configStorage} = this.props
        if(configStorage == null || configStorage.android == null) return
        //open .apk url in new tab
        const androidurl = configStorage.android
        console.log(androidurl)
        window.open(androidurl, "_blank")
    }
    ios() {
        //get configure url
        const {configStorage} = this.props
        if(configStorage == null || configStorage.android == null) return
        //open in new tab: app store of iot hub platform
        const appstoreurl = configStorage.ios
        console.log(appstoreurl)
        window.open(appstoreurl, "_blank")
    }
    
    render() {
        return <div className="Install_Main">
            <div className='left'>
                <div className='title'>Install Mobile App</div>
                <div className='subtitle'>For Apple Devices, please download the app in Apple App Store.</div>
                <div className='subtitle'>For Android Devices, please download the .apk file and install manually.</div>
                <div className='btns'>
                    <div className='btn' onClick={()=>this.ios()}>
                        <FontAwesomeIcon icon={faAppleAlt} />
                        App Store
                    </div>
                    <div className='btn' onClick={()=>this.android()}>
                        <FontAwesomeIcon icon={faPlay} />
                        Android
                    </div>
                </div>
            </div>
            <div className='right'>
                <div className='phone'>
                    <div className='screen'></div>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = state => {
    return {
        configStorage: state.configStorage
    }
}
export default connect(mapStateToProps)(UserManagement)