import React, { useEffect } from 'react'
import './Tablehead.css'

function TableHead(props) {
    const {coldboxID, isGroupSetPt, selectedGroup} = props
    const displayStr = isGroupSetPt?selectedGroup==null?'Select a coldbox / group':selectedGroup.group_name:coldboxID

    return <div className="ColdboxSetPt_TableHead">
        <div className="ColdboxSetPt_TableHeadTxt">Coldbox ID / Group: {displayStr}</div>
        <div className="CdbxSetPt_prevTitle">Current Setting</div>
    </div>
}

export default TableHead