import React from 'react'
import './tablehead.css'
//Redux Import
import { connect } from 'react-redux'


function TableHead(props) {

    return <div className="Planner_tableHead">
        <div className={"col1"}>
            Coldbox ID
            {
                <div style={{ display: 'none' }}></div>
            }
        </div>
        <div className={"col1"}>
            Type
            {
                <div style={{ display: 'none' }}></div>
            }
        </div>
        <div className={"col2"}>
            Parts
            {
                <div style={{ display: 'none' }}></div>
            }
        </div>
        <div className={"col1"}>
            Work Order
            {
                <div style={{ display: 'none' }}></div>
            }
        </div>
        <div className={"col1"}>
            Requestor
            {
                <div style={{ display: 'none' }}></div>
            }
        </div>
        <div className={"col2"}>

            {
                <div style={{ display: 'none' }}></div>
            }
        </div>
    </div>
}

//Redux inside component function
function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps)(TableHead)