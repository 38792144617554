import React from 'react'
import './table.css'

class Table extends React.Component {
    constructor(props) {
        super()
        this.openEditPopup = this.openEditPopup.bind(this)
    }

    openEditPopup() {
        const {data} = this.props
        this.props.setSelected(data)
    }

    render() {
        const {data} = this.props
        if(data==null||data==undefined) return <div></div>

        return <div className="Coldbox_Manage_tableRow" >
            <div className="Col2">{(data.group_name)}</div> 
            <div className="Col3">
                <div className="Coldbox_Manage_EditBtn" onClick={this.openEditPopup}>
                    <div className="fa fa-edit"></div>
                </div>
            </div>
        </div>
    }
}

export default Table