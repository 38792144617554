import React from 'react';
//Redux Import
import { connect } from 'react-redux';
import './SetPopupAlarm.css';

class SetPopupAlarm extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            SettingControlIsOn: false
        };

        this.OnOffControlClick = this.OnOffControlClick.bind(this);
    }
    OnOffControlClick()
    {
        let {SettingControlIsOn} = this.state;
        this.setState({
            SettingControlIsOn: !SettingControlIsOn
        });
        //submit setting to DB
        //..
    }
    render() {
        let {SettingControlIsOn} = this.state;
        let OnOffState = "";
        let OnOffMarginLeft = "";
        let DisplayText = "Disabled";
        if(SettingControlIsOn)
        {
            OnOffState = "OnOffState";
            OnOffMarginLeft = "OnOffMarginLeft";
            DisplayText = "Enabled";
        }
        return <div className="SetPopupAlarmContainer">
            <div className="SetDarkModeTitle">
                Pop Up Alarm { DisplayText }
            </div>
            <div className={"SettingOnOffControl "+OnOffState} onClick={this.OnOffControlClick}>
                <div className={"SettingOnOffPointer "+OnOffMarginLeft}></div>
            </div>
        </div>
    }
}

//Redux inside component function
function mapStateToProps(state) {
    return {
      socket: state.socket,
      configStorage: state.configStorage
    };
}

export default connect(mapStateToProps)(SetPopupAlarm);