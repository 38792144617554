import React from 'react'
import './Table.css'
import { connect } from 'react-redux'

function Table(props) {
    let row = props.row

    return (
        <div className="wrap" >
            <div className="WOS_TableRow">
                <div className="col2">{row.PMFrequency}</div>
                <div className="col2">{row.Works}</div>
                <div className="col2">{row.ServiceImpact}</div>
                <div className="col1">{row.Duration}</div>
                <div className="col1">{row.Vendor}</div>
                <div className="col2">{row.LastMaintDate}</div>
                <div className="col1">{row.LastMaintType}</div>
                <div className="col2">{row.NextMaintDate}</div>
                <div className="col1">{row.NextMaintType}</div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        UserInfo: state.UserInfo,
        socket: state.socket,
    }
}
export default connect(mapStateToProps)(Table) 