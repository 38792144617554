import React from 'react'
import './controlMode.scss'
import { connect } from 'react-redux'
import Moment from 'react-moment'

const green = 'rgb(0, 188, 0)'
const grey = 'grey'

const BotControlMode = props => {
    const {deviceItem, hoverFunc, cardnumber, isFocus} = props
    
    const [mode1, mode2, mode3, mode4] = getData(props)

    return <div className='botCardCtrlMode' style={{
        background: isFocus?'var(--focuscardbackground)':'',
        color: isFocus?'white':''
    }} onMouseOver={() => hoverFunc(cardnumber)}>
        <div className='title'>{deviceItem==null?'--':deviceItem.locName}</div>
        <div className='modeList'>
            <div className='mode' style={{background: mode1?green:grey}}>Mode1 - Turbidity</div>
            <div className='mode' style={{background: mode2?green:grey}}>Mode2 - People Counting</div>
            <div className='mode' style={{background: mode3?green:grey}}>Mode3 - Mode1 + Mode2</div>
            <div className='mode' style={{background: mode4?green:grey}}>Mode4 - Predictive</div>
        </div>
        <div className='bot'>Last Update: {getLastUpdate(deviceItem)}</div>
    </div>
}
const mapStateToProps = state => {
    return {
        currentSite: state.currentSite,
        currentlevel: state.currentlevel,
        DeviceData: state.DeviceData,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate
    }
}
export default connect(mapStateToProps)(BotControlMode)


const getLastUpdate = deviceItem => {
    if(deviceItem == null || deviceItem.payload == null) return '--'
    const lastUpdate = deviceItem.payload.updateDate //deviceItem.payload.lastUpdate  // deviceItem.lastUpdate
    if(lastUpdate==null||lastUpdate=='') return ''
    return <Moment format='DD/MM HH:mm'>{lastUpdate}</Moment>
}

const getData = props => {
    const locList = getLocs(props)
    const controller = getController(locList, '0002')

    const Mode3 = getInput(controller, 'digital', 5)
    const Mode4 = getInput(controller, 'digital', 6)
    const Mode1 = getInput(controller, 'digital', 7)
    const Mode2 = getInput(controller, 'digital', 8)
    
    return [Mode1, Mode2, Mode3, Mode4]
}
const getLocs = props => {
    const {currentSite, currentlevel, DeviceData} = props
    if(currentSite == null || currentlevel == null || DeviceData == null) return []
    const site = DeviceData[currentSite]
    if(site == null || site.nestedLocs == null) return []
    const level = site.nestedLocs.find(item => item.locName == currentlevel)
    if(level == null || level.nestedLocs == null) return []
    return level.nestedLocs
}
const getController = (locList, ctrlNumber) => {
    if(locList == null || locList.length == null) return
    const controllerName = 'Ctrl_' + ctrlNumber
    const controller = locList.find(item => item.locName == controllerName)

    return controller
}
const getInput = (controller, type, number) => {
    if(controller==null || controller.payload==null) return

    let fieldName = ''
    if(type == 'digital') {
        fieldName = 'Enable_DI'
        number = number - 1
    }
    else {
        fieldName = 'VoltageIn_'
    }
    fieldName += number.toString()

    return controller.payload[fieldName]
}