import React, { useState, useEffect } from 'react'
import './LiftDoorStat.css'
import { connect } from 'react-redux'

//Import smallest widgets
import HeaderWidget from '../common/head/head'
import ButtonWidget from '../common/searchbtn/searchbtn'
import DroplistWidget from '../common/droplist/droplist'
// import PageButtonsWidget from '../common/pagebtn/pagebtn'
import DatePickWidget from '../common/datepick/datepick'
// import LoadingWidget from '../common/loading/loading'

import convertDate from '../common/convertDate'
import GetLocList from '../common/getLocList'
import LiftSelectWidget from '../common/LiftSelect/LiftSelect'

import LiftDoorStatHighchart from './highchart/highchart'
import LiftDoorStatHighchart_daily from './highchart/highchart_daily'
import TableWidget from './Table/Table'
import OptionWidget from './options/options'
import ExportExcelWidget from './ExportCsv/ExportCsv'
import SingleDatePickerWidget from './SingleDatePicker/SingleDatePicker'

function LiftDoorStat(props) {
    const {DeviceData, StatisticData} = props

    const [StartDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 0))
    const [EndDate, setEndDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 0))
    const [ToShow, setToShow] = useState([true, true, true])
    const [DailyOrMonth, setDailyOrMonth] = useState(false)
    const [TableOrChart, setTableOrChart] = useState(true)
    const [LiftList, setLiftList] = useState([])

    const [OptionIsOpen, setOptionIsOpen] = useState(false)

    const [forceUpdate, setforceUpdate] = useState(0)

    const [hideHeader, setHideHeader] = useState(false)

    useEffect(() => {
         //onload function
        setTimeout(() => {
            RequestData()
        }, 100)
    }, [])
    useEffect(() => {
        //onload function
       setTimeout(() => {
           RequestData()
       }, 100)
   }, [DailyOrMonth])

    function RequestData() {
        const {UserInfo, currentlevelID, StatisticData} = props
        //clear previous reading before new request
        props.dispatch({ type: 'DeviceStatistic', 
            data: {
                co2: StatisticData.co2,
                pm25: StatisticData.pm25,
                temp: StatisticData.temp,
                humid: StatisticData.humid,
                power: StatisticData.power,
                illuminance: StatisticData.illuminance,
                loading: false,
                lift: StatisticData.lift,
                mileageTravelled: StatisticData.mileageTravelled,
                powerConsumed: StatisticData.powerConsumed,
                runtimeTravelled: StatisticData.runtimeTravelled,
                doorOpenCount: null,
                liftStartupCount: null,
                liftDirectionReverseCount: null,
            },
        })
        setToShow([true, true,true])

        if(UserInfo == null) return
        if(StartDate == null || EndDate == null) return
        const clientID = UserInfo.client_id
        let finalendDate = (!DailyOrMonth)?
        new Date(EndDate.getFullYear(), EndDate.getMonth() + 1, 1)
        :
        EndDate

        let finalstartDate = (!DailyOrMonth)?
        StartDate
        :
        new Date(StartDate.getFullYear(), StartDate.getMonth(), StartDate.getDate(), StartDate.getHours()-16)

        //emit json
        const EmitJson = {
            "msgType": "LocationStatistics",
            "clientID": clientID,
            "payload_field": ["doorOpenCount", "liftStartupCount", "liftDirectionReverseCount"],
            "timeConstraint": {
                "start": convertDate(finalstartDate),
                "end": convertDate(finalendDate),
            },
            "timeFrequency": {
              "unit": (DailyOrMonth)?"hour":"month",
              "value": 1
            },
            "locationConstraint": {
              "locLvl": 3,
              "id": currentlevelID
            },
            "locationGrouping": 3,
            "calculationMethod": "groupedSum"
        }
        console.log(EmitJson)
        //emit socket
        props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "StatRequest",
            EmitSocketData: EmitJson
        })
    }
    function ChangeDate(ID, DateInput) {
        var newDate = DateInput
        switch(ID) {
            case "startDate":
                setStartDate(newDate)
                break
            case "endDate":
                setEndDate(newDate)
                break
        }
    }

    function Tab(arrayInput) {
        setToShow(arrayInput)
        setforceUpdate(forceUpdate+1)
    }
    function optionFunction(DailyOrMonth, TableOrChart) {
        setDailyOrMonth(DailyOrMonth)
        setTableOrChart(TableOrChart)
    }
    function LiftSelect(SelectedLiftList) {
        const LiftList = SelectedLiftList.map((item) => {
            return item._id
        })
        setLiftList(LiftList)
    }

    const LocList = GetLocList(props)

    return <div className="LiftDrStat_main">
        {
            hideHeader?<div className='CloseBtn' onClick={() => setHideHeader(false)}>x</div>
            :
            <HeaderWidget Title="Lift Statistic" Icon="history" />
        }
        {
            hideHeader?<div style={{display: 'none'}}></div>
            :
            <div className="LiftDrStat_search">
                <div className="LiftDrStat_searchTitle">
                    Time Range:
                </div>
                {
                    DailyOrMonth?
                    [
                        <SingleDatePickerWidget DefaultDate="Yesterday" ID="startDate" ChangeDate={ChangeDate} />,
                        <SingleDatePickerWidget DefaultDate="Today" ID="endDate" ChangeDate={ChangeDate} />
                    ]
                    :
                    [
                        <DatePickWidget holderTxt="Start Date" DefaultDate="PreMonth" ID="startDate" ChangeDate={ChangeDate} />,
                        <DatePickWidget holderTxt="End Date" DefaultDate="Today" ID="endDate" ChangeDate={ChangeDate} />
                    ]
                }
                <OptionWidget updateFunction={Tab} optionFunction={optionFunction} setOptionIsOpen={setOptionIsOpen} OptionIsOpen={OptionIsOpen} />
                {/* <LiftSelectWidget onChangeFunction={LiftSelect} DeviceData={DeviceData} /> */}
                <ButtonWidget Icon="search" ClickFunction={RequestData} />
                <ExportExcelWidget Data={StatisticData} />
                <ButtonWidget Icon="expand" ClickFunction={() => setHideHeader(true)} />
            </div>
        }
        {
            TableOrChart?
            <TableWidget DailyOrMonth={DailyOrMonth} />
            :
                DailyOrMonth?
                <LiftDoorStatHighchart_daily ToShow={ToShow} forceUpdate={forceUpdate} DailyOrMonth={DailyOrMonth} setOptionIsOpen={setOptionIsOpen} />
                :
                <LiftDoorStatHighchart ToShow={ToShow} DailyOrMonth={DailyOrMonth} />
        }
    </div>
}

function mapStateToProps(state) {
    return {
        UserInfo: state.UserInfo,
        currentlevel: state.currentlevel,
        currentSite: state.currentSite,
        currentlevelID: state.currentlevelID,
        StatisticData: state.StatisticData,
        DeviceData: state.DeviceData,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate,
    }
}
export default connect(mapStateToProps)(LiftDoorStat)