import React from 'react';
import './checkbox.css';

class Checkbox extends React.Component {
    constructor(props) {
      super(props);
      this.rememberMeCheckbox = React.createRef();
      this.rememberMe = this.rememberMe.bind(this);
    }
  
    rememberMe() {
        let rememberme_status = this.rememberMeCheckbox.current.checked;
        //true == save acc and password
        //console.log(rememberme_status);
        let {rememberme} = this.props;
        rememberme(rememberme_status);
    }

    componentDidMount()
    {
      //set the check box to checked
      //..
    }

    render() {
      return (
        <div className="">
            <label className="container">
                <input type="checkbox" ref={this.rememberMeCheckbox} onChange={this.rememberMe} defaultChecked={"checked"}>

                </input>
                <span className="checkmark"></span>
            </label>
        </div>
      );
    }
}

export default Checkbox;