import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { faMale, faFemale, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './ToiletOcc_V2.css'

const updateInterval = 30 * 1000        // unit: second

function ToiletOcc_V2(props) {
    const {currentSite, currentlevel} = props
    const [occDataM, setOccDataM] = useState(null)
    const [occDataF, setOccDataF] = useState(null)
    const [time, setTime] = useState()

    useEffect(() => {       // Timer for React function component
        const interval = setInterval(() => {
            const newTime = new Date().toLocaleTimeString()
            setTime(newTime)
        }, updateInterval)
    
        return () => {
            clearInterval(interval)
        }
    }, [time])
    useEffect(() => {
        const {socket} = props
        if(socket==null) return

        socket.on("CCG_Blutech_OccData", data => {  //create socket listener
            // console.log('CCG_Blutech_OccData()')
            console.log(data)
            if(data.result=='Fail') {
                setOccDataF(null)
                setOccDataM(null)
                return
            }
            if(data==null||data.data==null||data.toilet==null) return
            if(data.toilet.includes("female")) {
                setOccDataF(data.data)
                return
            }
            if(data.toilet.includes('male')) {
                setOccDataM(data.data)
                return
            }
        })
        return () => {
            socket.off('CCG_Blutech_OccData')       //clear socket listener
        }
    }, [])

    useEffect(() => {
        const {UserInfo} = props
        if(UserInfo==null) return

        const req = {
            "msgType": "CCG_Blutech_OccData",
            "clientID": UserInfo.client_id,
            "toilet": getToilet(props) + "-female"						// L0-female / L0-male / L1-female / L1-male / L2-female / L2-male
        }
        const req2 = {
            "msgType": "CCG_Blutech_OccData",
            "clientID": UserInfo.client_id,
            "toilet": getToilet(props) + "-male"						// L0-female / L0-male / L1-female / L1-male / L2-female / L2-male
        }
        console.log(req)
        console.log(req2)
        props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "StatRequest",
            EmitSocketData: req
        })
        props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "StatRequest",
            EmitSocketData: req2
        })
    }, [currentSite, currentlevel, time])

    return <div className='OccV2'>
        <div className='head'>
            <FontAwesomeIcon icon={faUser} className='fa icon' />
            <div className='txt'>Toilet Vacancy</div>
        </div>
        <div className='inner'>
            <div className='row'>
                <FontAwesomeIcon icon={faMale} className='fa icon male' />
                <div className='txt'>{occDataM==null?'--':occDataM}</div>
            </div>
            <div className='row'>
                <FontAwesomeIcon icon={faFemale} className='fa icon female' />
                <div className='txt'>{occDataF==null?'--':occDataF}</div>
            </div>
        </div>
    </div>
}
  
function mapStateToProps(state) {
    return {
        socket: state.socket,
        UserInfo: state.UserInfo,
        currentSite: state.currentSite,
        currentlevel: state.currentlevel
    }
}  
export default connect(mapStateToProps)(ToiletOcc_V2)


function getToilet(props) {
    const {currentlevel} = props
    if(currentlevel==null) return

    let returnName = ''
    switch(currentlevel) {
        case 'G':
            returnName = 'L0'
            break
        case '1':
            returnName = 'L1'
            break
        case '2':
            returnName = 'L2'
            break
        case 'R':
            returnName = 'L3'
            break
    }
    return returnName
}