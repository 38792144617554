import React from 'react'
import {connect} from 'react-redux'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBuilding} from '@fortawesome/free-solid-svg-icons'
import './SiteLevelPicker_single.css'
import {GetSiteName} from '../../../common/language/locDisplay'

function SiteLevelPicker_single(props) {
    const {currentlevel} = props
    return <div className='LvlPick_main'>
        <div className='siteInfo'>
            <FontAwesomeIcon icon={faBuilding} className="fa icon" />
            <div className='txt'>{GetSiteName2(props)}</div>
        </div>
        <div className='lvlCtrl'>
            {
                GetLevelList(props).map(item=> {
                    if(item==null||item.length==null||item.length==0) return <>{item}</>
                    const isLongLevelName = item.length>=3
                    return <div className={currentlevel==item?'btn current':'btn'} style={{width: isLongLevelName?'4rem':''}} onClick={()=>SelectLvl(item, props)}>{item}</div>
                })
            }
        </div>
    </div>
}
function mapStateToProps(state) {
    return {
      currentlevel: state.currentlevel,
      currentSite: state.currentSite,
      DeviceData: state.DeviceData,
    }
}
export default connect(mapStateToProps)(SiteLevelPicker_single)

const GetSiteName2 = props => {
    const {currentSite, DeviceData} = props
    if(currentSite==null||DeviceData==null||DeviceData.length==null||DeviceData.length==0) return '--'
    return GetSiteName(DeviceData[currentSite])
}
const GetLevelList = props => {
    const {currentSite, DeviceData} = props
    if(currentSite==null||DeviceData==null||DeviceData.length==null||DeviceData.length==0) return []
    const site = DeviceData[currentSite]
    if(site==null||site.nestedLocs==null) return []
    const lvlList = site.nestedLocs.sort((a, b) => {
        if (a.locIdx > b.locIdx) return 1
        if (b.locIdx > a.locIdx) return -1
        return 0
    }).map(item => {
        return item.locName
    })
    const returnLvlList = PutRFloorToLast(lvlList)
    return returnLvlList
}
const SelectLvl = (lvlStr, props) => {
    props.dispatch({ type: 'PickLevel', data: lvlStr })
}
const PutRFloorToLast = lvlList => {
    let lvlList_input = lvlList
    let indexOfRFloor = lvlList_input.indexOf('R')
    if(indexOfRFloor == null || indexOfRFloor == -1) return lvlList_input
    lvlList_input.splice(indexOfRFloor, 1)
    lvlList_input.push('R')
    return lvlList_input
}