import React from 'react'
import './LineChart.css'
import { connect } from 'react-redux'
import HighCharts, { Axis } from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import DatePicker from 'react-datepicker'
import { CSVLink, CSVDownload } from "react-csv"

//inner widgets
import Loading from '../../loading'
import RealTimeAlert from '../../Navbar/RealTimeAlert'
import LineChartDataItem from './LineChartDataItem'
import SoundPlay from '../../../Sound/Sound'

import {getTxt} from '../../common/language/language'

HighCharts.setOptions({
    time: {
        timezoneOffset: -8 * 60
    }
})

class LineChart extends React.Component {
    constructor(props) {
        super(props)

        this.mapoutsidediv = React.createRef()

        this.Linechart_maindiv = React.createRef()
        this.Linechart_header = React.createRef()

        this.setStartTime = this.setStartTime.bind(this)
        this.setEndTime = this.setEndTime.bind(this)

        this.drawLineChart = this.drawLineChart.bind(this)
        this.genCsvData = this.genCsvData.bind(this)
        this.formatDate = this.formatDate.bind(this)

        this.convertDate = this.convertDate.bind(this)
        this.SetReadingType = this.SetReadingType.bind(this)
        this.SearchClicked = this.SearchClicked.bind(this)
        this.SetReadingTimeInterval = this.SetReadingTimeInterval.bind(this)
        this.socketListener = this.socketListener.bind(this)
        this.CheckIfRequestToomany = this.CheckIfRequestToomany.bind(this)

        //select device list widget return function
        this.setDeviceFunction = this.setDeviceFunction.bind(this)
        //function of child widget to set IsAuto local State
        this.SetIsAuto = this.SetIsAuto.bind(this)

        this.state = {
            startdate: new Date(),
            enddate: new Date(),
            readingType: 1,     //CO2, Temp, Humid, Power, Illuminance
            chartValues: [1, 2, 3, 4, 5, 6],
            finalChartValue: [],
            timeInterval: 0,     //Month, Day, 5 Hours, 1 Hour
            isLoading: false,
            currentUnit: 1,     //this is the unit that is updated when search complete (similar to readingType)

            ChartCsvData: [
                ['Device Name', 'Date', 'Value', 'Unit']
            ],

            IsAuto: false,
        }

        this.soundRef = React.createRef()
    }
    socketListener()
    {
        //trigger when componentDidMount
        const {socket} = this.props
        const thisoutside = this
        //check socket status
        if(socket == null)
        {
            console.error('socket is null. LineChart.js socketListener()')
            return
        }
        if(socket.connected == false)
        {
            console.error('socket is disconnected LineChart.js socketListener()')
            return
        }
        //create listeners
        this.props.socket.on("LocationStatistics", data => {
            //trigger update this.state unit (value: 0,1,2)
            thisoutside.setState({
                currentUnit: thisoutside.state.readingType
            })
            //update the HighChart data and options
            this.drawLineChart(thisoutside.props)
        })
    }
    CheckIfRequestToomany()
    {
        //the variables: startdate enddate timeInterval
        const {startdate, enddate, timeInterval} = this.state
        try
        {
            //get date different (timestamp)
            let different = enddate.getTime() - startdate.getTime()
            let differentSecond = different / 1000; //unit: second
            //calc how many data
            let timeIntervalSecond = 0;   //unit: second
            switch(timeInterval)
            {
                case 0:
                    timeIntervalSecond = 60 * 60 * 24;    //1 day
                    break;
                case 1:
                    timeIntervalSecond = 60 * 60 * 24;    //1 day
                    break;
                case 2:
                    timeIntervalSecond = 60 * 60;     //1 hr
                    break;
                case 3:
                    timeIntervalSecond = 15 * 60;     //15 min
                    break;
                default:
                    timeIntervalSecond = 60 * 60 * 24;    //1 day
                    break;
            }
            let dataNumber = differentSecond/timeIntervalSecond;
            console.log({
                startdate: startdate,
                enddate: enddate,
                timeInterval: timeInterval,
                different: different,
                differentSecond: differentSecond,
                timeIntervalSecond: timeIntervalSecond,
                dataNumber: dataNumber
            });
            //check if data is too much
            if(dataNumber >= 5000)
            {
                return true;        //too many data, alert and not request
            }
            else
            {
                return false;       //not too many data, continue
            }
        }
        catch(err)
        {
            console.log(err);
            return false;   //not too many
        }
    }
    componentDidMount() {      
        //request data from socket
        let startDate = new Date()
        let EndDate = new Date()
        startDate.setMonth(startDate.getMonth()-1)
        let startDateStr = this.convertDate(startDate)
        let EndDateStr = this.convertDate(EndDate)
        const {currentlevelID, UserInfo} = this.props
        let ClientID = ""
        if(UserInfo != null)
        {
            ClientID = UserInfo.client_id
        }
        // let requestData = {
        //     "msgType": "DeviceStatistics",
        //     //"socketId": "8SpxJb0PqsT0zcboAAEa",
        //     "msgID": "LineChart",
        //     "clientID": ClientID,
        //     "deviceType": "IAQ-TH",						// IAQ-CO2 / IAQ-TH / ACP (Power)
        //     "timeConstraint": {
        //           "start": startDateStr,
        //           "end": EndDateStr,
        //     },
        //     "timeFrequency": {
        //       "unit": "day",                    // month / day / hour / minute
        //       "value": 1
        //     },
        //     "locationConstraint": {
        //       "locLvl": 2,                      // 1 - Site / 2 - Floor / 3 - Location
        //       "id": currentlevelID // location id
        //     },
        //     "locationGrouping": 3,              // 1 - Site / 2 - Floor / 3 - Location
        //     "calculationMethod": "average"      // average / difference
        // }
        const requestData = {
            "msgType": "LocationStatistics",
            "msgID": "LineChart",
            "clientID": ClientID,
            "payload_field": ["temperature"],
            "timeConstraint": {
              "start": startDateStr,
              "end": EndDateStr,
            },
            "timeFrequency": {
              "unit": "day",
              "value": 1
            },
            "locationConstraint": {
              "locLvl": 2,
              "id": currentlevelID
            },
            "locationGrouping": 3,
            "calculationMethod": "average"
          }
          
        console.log(requestData)
        //request the socket for Device Staticstics
        this.props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "StatRequest",
            EmitSocketData: requestData
        })

        this.drawLineChart(this.props)
        this.socketListener()

        //setstate of the startDate
        this.setState({
            startdate: startDate,
        })
    }

    convertDate(DateInput)
    {
        let DateMonth = DateInput.getMonth() + 1
        let DateDay = DateInput.getDate()
        if(DateMonth < 10) DateMonth = "0" + DateMonth
        if(DateDay < 10) DateDay = "0" + DateDay
        let HourStr = DateInput.getHours()
        if(HourStr < 10) HourStr = "0" + HourStr
        let MinutesStr = DateInput.getUTCMinutes()
        if(MinutesStr < 10) MinutesStr = "0" + MinutesStr
        const DateStr = DateInput.getFullYear() + "-" + DateMonth + "-" + DateDay + "T" + HourStr + ":" + MinutesStr + ":00Z"
        return DateStr
    }
    SetReadingTimeInterval(event)
    {
        console.log(event.target.value);
        let readingTimeIntervalInt = 0;
        try
        {
            readingTimeIntervalInt = parseInt(event.target.value, 0);
        }
        catch(err)
        {
            console.log(err);
        }
        this.setState({
            timeInterval: readingTimeIntervalInt
        });
    }
    SetReadingType(event)
    {
        console.log(event.target.value);
        let readingTypeInt = 0;
        try
        {
            readingTypeInt = parseInt(event.target.value, 0);
        }
        catch(err)
        {
            console.log(err);
        }
        this.setState({
            readingType: readingTypeInt
        });
    }

    SearchClicked()
    {
        this.soundRef.current.alarmMp3Run("hover")
        //Check if the request Data is too much
        const isTooMany = this.CheckIfRequestToomany()
        const thisoutside = this
        if(isTooMany)
        {
            thisoutside.RealTimeAlertRef.newAlertNotification("Too Many Data", 
            "You request too much data. Please try 'Day'/'Hour' interval or reduce the date range you search.",
             "", true)
            return
        }

        //get the start end date, reading type
        const { startdate, enddate, readingType} = this.state
        let readingTypeStr = ""
        switch(readingType)
        {
            case 0:
                // readingTypeStr = "IAQ-CO2"
                readingTypeStr = "co2"
                break
            case 1:
                // readingTypeStr = "IAQ-TH"  //** same request to socket for temperature and humidity
                readingTypeStr = "temperature"
                break
            case 2:
                // readingTypeStr = "IAQ-TH"  //** by checking the state in this component, show temp / humid with redux value
                readingTypeStr = "humidity"
                break
            case 3:
                // readingTypeStr = "ACP" //ac power
                readingTypeStr = "acPower"
                break
            case 4:
                readingTypeStr = "illuminance"
                break
            default:
                readingTypeStr = "Reading Type Undefined!"
                console.error('Reading Type switch undefined! LineChart.js')
                break
        }
        //send request to socket
        const {currentlevelID, UserInfo} = this.props
        const {timeInterval} = this.state
        let timeFrequency = ""
        let timeValue = 1
        switch(timeInterval)
        {
            case 0:
                //Default
                timeFrequency = "day"
                timeValue = 30
                break;
            case 1:
                timeFrequency = "day"
                timeValue = 1
                break;
            case 2:
                timeFrequency = "hour"
                timeValue = 1
                break;
            case 3:
                timeFrequency = "minute"
                timeValue = 15
                break;
            default:
                console.log('LineChart time Interval item is not identified')
                timeFrequency = "day"
                timeValue = 1
                break;
        }
        // format the date format to string
        const startDateStr = this.convertDate(startdate)
        const endDateStr = this.convertDate(enddate)
        // let requestData = {
        //     "msgType": "DeviceStatistics",
        //     //"socketId": "8SpxJb0PqsT0zcboAAEa",
        //     "msgID": "LineChart",
        //     "clientID": UserInfo.client_id,
        //     "deviceType": readingTypeStr,						// IAQ-CO2 / IAQ-TH / ACP (Power)
        //     "timeConstraint": {
        //           "start": startDateStr,
        //           "end": endDateStr
        //     },
        //     "timeFrequency": {
        //       "unit": timeFrequency,                    // month / day / hour / minute
        //       "value": timeValue
        //     },
        //     "locationConstraint": {
        //       "locLvl": 2,                      // 1 - Site / 2 - Floor / 3 - Location
        //       "id": currentlevelID // location id
        //     },
        //     "locationGrouping": 3,              // 1 - Site / 2 - Floor / 3 - Location
        //     "calculationMethod": "average"      // average / difference
        // }
        const requestData = {
            "msgType": "LocationStatistics",
            "msgID": "LineChart",
            "clientID": UserInfo.client_id.toString(),
            "payload_field": [readingTypeStr],
            "timeConstraint": {
              "start": startDateStr,
              "end": endDateStr,
            },
            "timeFrequency": {
              "unit": timeFrequency,
              "value": timeValue
            },
            "locationConstraint": {
              "locLvl": 2,
              "id": currentlevelID
            },
            "locationGrouping": 3,
            "calculationMethod": "average"
        }
          
        console.log(requestData)
        //request the socket for Device Staticstics
        this.props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "StatRequest",
            EmitSocketData: requestData
        })

        this.drawLineChart(this.props)
        //line chart would then update when componentWillReceiveProps()

        this.setState({     //Show loading GUI
            isLoading: true
        })
    }

    componentDidUpdate(preProps)
    {
        console.log('componentDidUpdate() LineChart.js')

        //check if props statistic data is updated
        const thisoutside = this
        try
        {
            const {StatisticData} = preProps
            const newStatisticData = this.props.StatisticData
            let isUpdatedData = false
            if((StatisticData.co2 != newStatisticData.co2)||(StatisticData.power != newStatisticData.power)
            ||(StatisticData.temp != newStatisticData.temp)||(StatisticData.humid != newStatisticData.humid)
            ||(StatisticData.illuminance != newStatisticData.illuminance)) isUpdatedData = true
            //if Yes, this.state.isLoading = false
            if(isUpdatedData)
            {
                thisoutside.setState({
                    isLoading: false
                })
                this.drawLineChart(thisoutside.props)
            }
        }
        catch(err)
        {
            console.log(err);
        }
    }

    drawLineChart(inputProps)
    {
        if(this.Linechart_maindiv.current == null || this.Linechart_header.current == null ||this.mapoutsidediv.current == null)
        {
            console.log('some ref() are null, return');
            return;
        }
        let innerbody_height = this.Linechart_maindiv.current.offsetHeight 
        - this.Linechart_header.current.offsetHeight;

        //get DeviceStatistics in redux function
        if(inputProps==null) return
        try
        {
            const { StatisticData, currentSite } = inputProps
            const {readingType, currentUnit} = this.state
            let data = [];
            let readingUnit = "undefined unit";
            if(StatisticData == null)
            {
                console.log('StatisticData == null');
                return;
            }
            // if(StatisticData.co2 == null)
            // {
            //     console.log('StatisticData.co2 == null');
            //     return;
            // }

            switch(currentUnit)//readingType)
            {
                case 0:
                    StatisticData.co2.forEach(co2Item => {
                        let co2ItemReading = co2Item.co2;
                        co2ItemReading = co2ItemReading.toFixed(2);
                        data.push([
                            Date.parse(co2Item.time),
                            co2Item.co2,
                            co2Item.locationName
                        ]);
                    });
                    readingUnit = "ppmv";
                break;
                case 1:
                    StatisticData.temp.forEach(tempItem => {
                        data.push([
                            Date.parse(tempItem.time),
                            tempItem.temperature,
                            tempItem.locationName
                        ]);
                    });
                    readingUnit = "C";
                break;
                case 2:
                    StatisticData.humid.forEach(humidItem => {
                        data.push([
                            Date.parse(humidItem.time),
                            humidItem.humidity,
                            humidItem.locationName
                        ]);
                    });
                    readingUnit = "%";
                break;
                case 3:
                    StatisticData.power.forEach(powerItem => {
                        data.push([
                            Date.parse(powerItem.time),
                            powerItem.acPower,
                            powerItem.locationName
                        ]);
                    });
                    readingUnit = "kW";
                break;
                case 4:
                    StatisticData.illuminance.forEach(luxItem => {
                        data.push([
                            Date.parse(luxItem.time),
                            luxItem.illuminance,
                            luxItem.locationName
                        ])
                    });
                    readingUnit = "lux"
                    break
            }
            //data
            //console.log(data);
            data.sort(function (a, b) {
                return a[0] - b[0];
            });
            let DeviceNameArray = [];
            let SeriesArray = [];
            //1. get all devicename in array
            const unique = (value, index, self) => {
                return self.indexOf(value) === index
            }
            data.forEach(dataItem => {
                DeviceNameArray.push(dataItem[2]);
            });
            // console.log({
            //     DeviceNameArray: DeviceNameArray,
            //     data: data
            // });
            let uniqueDeviceNameList = DeviceNameArray.filter(unique);
            //console.log(uniqueDeviceNameList);
            //2. split devices to separate arrays
            uniqueDeviceNameList.forEach(DeviceString => {      
                let pushArrayItem = data.filter(function(dataItem) {
                    return dataItem[2] === DeviceString;
                });
                //console.log(pushArrayItem);
                SeriesArray.push({
                    data: pushArrayItem,
                    name: DeviceString,
                    type: 'spline',
                    tooltip: {
                        valueDecimals: 2,
                        valuePrefix: '',
                        valueSuffix: ' ' + readingUnit 
                    }
                });
                //console.log({
                //    testing: testing,
                //    DeviceString: DeviceString,
                //    data: data
                //});
            });
            //3. format the series json to insert
            console.log({
                SeriesArray: SeriesArray,
                uniqueDeviceNameList: uniqueDeviceNameList,
            })
            const SeriesArrayOutput = SeriesArray
            this.setState({
                chartheight: innerbody_height,
                chartValues: SeriesArrayOutput,
                finalChartValue: SeriesArrayOutput,
            })

            //after generate chartValue to local state, generate csv file
            this.genCsvData()
        }
        catch(err)
        {
            console.log('onload Linechart failed LineChart.js')
            console.log(err)
        }
    }
    genCsvData() {
        //When data is updated, update the state and generate csv data
        //** need complete drawLineChart() function and generate chartValues state first
        const {chartValues} = this.state
        const thisoutside = this
        //block exceptional cases
        if(chartValues == null || chartValues.length == 0) return
        if(chartValues[0].data == null) return
        if(chartValues[0].tooltip == null) return
        //generate csv data
        let csvResult = [
            ['Device Name', 'Date', 'Value', 'Unit']
        ]
        chartValues.forEach(DataItem => {
            //console.log(DataItem)
            const deviceData = DataItem.data
            const deviceName = DataItem.name
            const deviceUnit = DataItem.tooltip.valueSuffix
            deviceData.forEach(readingItem => {
                const DateRaw = readingItem[0]
                const DateTimeString = thisoutside.formatDate(DateRaw)
                const readingValue = readingItem[1]
                csvResult.push(
                    [ deviceName, DateTimeString, readingValue, deviceUnit, ]
                )
            })
        })
        console.log(csvResult)

        // Update it to local state
        this.setState({
            ChartCsvData: csvResult
        })
    }

    formatDate(datestr) {
        const date = new Date(datestr)
        //console.log(date)
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        const result = (date.getMonth()+1) + "/" + date.getDate() + "/" + date.getFullYear() + "  " + strTime
        //console.log(result)
        return result
    }

    setStartTime(data)
    {
        console.log(data);
        this.setState({
            startdate: data
        });
    }

    setEndTime(data)
    {
        console.log(data);
        this.setState({
            enddate: data
        });
    }
    setDeviceFunction(input) {
        //function triggered from inner widget, when hide/show the deivce list
        console.log('setDeviceFunction()')
        const {chartValues, finalChartValue} = this.state
        if(input == null || input.length == null) return            //exception case 1
        if(chartValues == null || chartValues.length == 0) return   //exception case 2
        if(input.length != chartValues.length) return               //exception case 3

        let newChartValue = []
        chartValues.forEach(dataItem => {
            const dataName = dataItem.name
            const isExist = input.find(function(inputItem) {
                return inputItem.name == dataName
            })
            if(isExist == null) return      //exception case 1
            if(isExist.isShow == null) return //exception case 2
            if(isExist.isShow == true) dataItem.visible = true
            else dataItem.visible = false
            newChartValue.push(dataItem)
        })
        this.setState({
            finalChartValue: newChartValue
        })
    }
    SetIsAuto(boolValue) {          //..
        const _this = this
        const {finalChartValue} = this.state

        setTimeout(() => {
            _this.setState({
                IsAuto: boolValue,
            })
        }, 500)
    }
    //the class are using the FloorPlan widgets
    //would add extra class to fin-tune the css style
    render() {
        const {readingType, timeInterval, isLoading, currentUnit, ChartCsvData, startdate, enddate, finalChartValue, IsAuto} = this.state
        const {UserInfo} = this.props
        if(UserInfo == null) return <div>UserInfo is missing</div>
        const client_id = UserInfo.client_id

        ///**START OF HARDCODE FOR NEW VCITY USER (SHOULD DELETE THIS WHEN PERMISSION WORK IS DONE) */
        // const {UserInfo} = this.props
        // if(UserInfo == null) return <div></div>
        // if(UserInfo.userID == "5f59a0e1114c77095486ca67") return <div className="NoPerm_main">
        //     <div className="noPerm_blur">
        //         <div className="NoPerm_Txt">No Permission</div>
        //     </div>
        // </div>
        ///**END OF HARDCODE FOR NEW VCITY USER (SHOULD DELETE THIS WHEN PERMISSION WORK IS DONE) */
        let readingTypeStr, readingUnit = ""
        let YMax, YMin = 0      //set max and min range for Y Axis in chart
        try
        {
            switch(currentUnit)//readingType)
            {
                case 0:
                    readingTypeStr = "CO2"
                    readingUnit = "ppmv"
                    YMax = 1000
                    YMin = 0
                    break;
                case 1:
                    readingTypeStr = "Temperature"
                    readingUnit = "C"
                    YMax = 40
                    YMin = 0
                    break;
                case 2:
                    readingTypeStr = "Humidity"
                    readingUnit = "%"
                    YMax = 100
                    YMin = 0
                    break;
                case 3:
                    readingTypeStr = "Power"
                    readingUnit = "kW"
                    YMax = 20000
                    YMin = 0
                    break;
                case 4:
                    readingTypeStr = "Illuminance"
                    readingUnit = "lux"
                    YMax = 2500
                    YMin = 0
                    break
                default:
                    readingTypeStr = "Reading Type Undefined"
                    break;
            }
        }
        catch(err)
        {
            console.log(err)
        }

        //console.log(this.state.chartValues)
        let options = {
            credits: true,
            chart: {
                backgroundColor: {
                    linearGradient: [0, 0, 500, 500],
                    stops: [
                        [0, 'rgba(200, 200, 200, 0)'],
                        [1, 'rgba(0, 0, 0, 0)']
                    ]
                },
            },
            title: {
              text: ''
            },
            rangeSelector: {
                //selected: 0
                enabled: false
            },
            tooltip: {
                valueDecimals: 2,
                split: true
            },
            plotOptions: {
                series: {
                    showInNavigator: true       //show all line inside the bottom filter controller
                }
            },
            yAxis: {
                labels: {
                    enabled: true,
                    format: '{value} ' + readingUnit
                },
                opposite: false,
                max: YMax,
                min: YMin,
            },
            xAxis: {
                labels: {
                    style: {
                        color: 'var(--optiontext)'
                    }
                }
            },
            series: this.state.finalChartValue,
        }
        //check ISAuto, if not set min and max value in yAxis
        //YMax YMin IsAuto
        if(IsAuto) {
            options.yAxis.max = null
            options.yAxis.min = null
        }

        if(this.props.StatisticData.loading == true)
        {
            return <div className="LineChart_outside">
                <div className="linechart_header" ref={this.Linechart_header}>
                    <i className="fa fa-book headericon"></i>
                    {getTxt('Statistic', 'IAQSum')}
                </div>
                <Loading/>
            </div>
        }

        return [
        <div className="shadow-lg LineChart_outside" ref={this.Linechart_maindiv}>
            <div className="linechart_header" ref={this.Linechart_header}>
                <i className="fa fa-book headericon"></i>
                {getTxt('Statistic', 'IAQSum')} {"(" + readingTypeStr + ")"}
                
                {/* <div className="LineChart_loadingContainer">
                    <div className={"fa " + isLoadingClass}></div>
                </div> */}
            </div>
            <div className="Summary_Linechart_div" ref={this.mapoutsidediv}>
                <div className="Summary_searchbar_div">
                    <div className="Summary_searchbar_title">
                        Start:
                    </div>
                    <DatePicker
                        selected={startdate}
                        onChange={this.setStartTime}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="d-MMMM yyyy HH:mm"
                        timeCaption="time"
                    />
                    <div className="Summary_searchbar_title">
                        End:
                    </div>
                    <DatePicker
                        selected={enddate}
                        onChange={this.setEndTime}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="d-MMMM yyyy HH:mm"
                        timeCaption="time"
                    />
                    <select className="LineChartReadingTypeOption" onChange={this.SetReadingType}>
                        <option value="-1">
                            Reading Type
                        </option>
                        <option value="0">
                            CO2
                        </option>
                        <option value="1">
                            Temperature
                        </option>
                        <option value="2">
                            Humidity
                        </option>
                        {
                            (client_id != "5ecf8ab62f196f392848de8c")?
                            <option value="3">
                                Power
                            </option>
                            :
                            <option value="4">
                                Illuminance
                            </option>
                        }
                    </select>
                    <select className="LineChartReadingTypeOption" onChange={this.SetReadingTimeInterval}>
                        <option value="0">
                            Time Interval
                        </option>
                        <option value="0">
                            Month
                        </option>
                        <option value="1">
                            Day
                        </option>
                        <option value="2">
                            Hour
                        </option>
                        <option value="3">
                            Minute
                        </option>
                    </select>
                    <button className="fa fa-search Summarypage_searchbutton" onClick={this.SearchClicked}>
                    </button>
                    <div className="SummaryPage_DownloadCSV">
                        <CSVLink data={ChartCsvData} filename={this.formatDate(startdate.toString()) + "-" + this.formatDate(enddate.toString()) + "StatisticData.csv"}>Download csv</CSVLink>
                    </div>
                    <SoundPlay ref={this.soundRef}/>
                </div>
                <div className="Linechart_maindiv">
                    <HighchartsReact highcharts={HighCharts} options={options} constructorType={'stockChart'} className="Linechart_maindiv"
                    containerProps={{ style: { height: 'calc(23rem)' } }}/>
                </div>
            </div>
        </div>,
        <RealTimeAlert onRef={ref => (this.RealTimeAlertRef = ref)} />,
        <LineChartDataItem chartValue={this.state.chartValues} setDeviceFunction={this.setDeviceFunction} SetIsAuto={this.SetIsAuto} />,
        isLoading?<div className="lineChartLoading">
            <div className="fa fa-spinner fa-spin lineChartLoadingIcon"></div>
        </div>
        :<div></div>,
        ]
    }
}

//Redux inside component function
function mapStateToProps(state) {
    //console.log(state);
    return {
      socket: state.socket,
      level: state.level,
      currentlevel: state.currentlevel,
      currentSite: state.currentSite,
      MenuDisplay: state.MenuDisplay,
      SiteList: state.SiteList,
      currentBottomMenu: state.currentBottomMenu,
      currentBottomCard: state.currentBottomCard,      
      DeviceDataLastUpdate: state.DeviceDataLastUpdate, //when this value is updated, render would triggered
      StatisticData: state.StatisticData,
      UserInfo: state.UserInfo,
      currentlevelID: state.currentlevelID
    }
}

export default connect(mapStateToProps)(LineChart)