import React from 'react'
import './resetPwd.css'
import { connect } from 'react-redux'
import md5 from 'md5'

import PopupNotification from '../../../popupNotification/PopupNotification'
import {getTxt} from '../../common/language/language'

class ResetPwd extends React.Component {
    constructor(props) {
        super(props)
        this.ResetPwd = this.ResetPwd.bind(this)
        this.submitNewPassword = this.submitNewPassword.bind(this)
        this.socketListener = this.socketListener.bind(this)

        //sub functions
        this.checkPasswordValid = this.checkPasswordValid.bind(this)
        this.getInputs = this.getInputs.bind(this)

        this.resubPassword = React.createRef()
        this.oldPassword = React.createRef()
        this.newPassword = React.createRef()

        this.state = {
            isLoading: false
        }
    }
    socketListener() {
        //create socket lisnter for success
        const thisoutside = this
        this.props.socket.on("UpdatePassword", data=> {
            console.log(data)
            if(data.result == "Success") {
                // this.RealTimeAlertRef.newAlertNotification("Submit Success", 
                // "Your Password is reset.", null, true);
                thisoutside.PopupNotificationRef.addNotificationPopup("Submit Success", 
                "Your Password is reset", null, true)

                //update new password to localstorage
                const NewPwd = this.newPassword.current.value
                localStorage.setItem('userhashpassword', md5(NewPwd))

                this.props.ResetPwd()      //close popup

                //refresh the website
                setTimeout(() => {
                    const currentUrl = window.location.href
                    localStorage.setItem('refreshUrl', currentUrl)
                    window.location.reload(false)
                }, 1000)
            }
            else {
                // this.RealTimeAlertRef.newAlertNotification("Reset Password Failed", 
                // "Reset Failed: " + data.message, null, true);
                thisoutside.PopupNotificationRef.addNotificationPopup("Reset Password Failed", 
                "Reset Failed: " + data.message, null, true)
            }
            //unlock submit button
            this.setState({
                isLoading: false
            })
        })
    }
    componentDidMount() {
        //create submit success listener
        this.socketListener()
    }

    submitNewPassword() {
        //get inputs
        let PasswordInput = this.getInputs()
        //check valid
        let isValid = this.checkPasswordValid(PasswordInput)
        if(!isValid) return
        //hash (md5)
        let password_hash = md5(PasswordInput.newPassword)
        let oldPassword_hash = md5(PasswordInput.oldPassword)
        //get user ID (input for both userID and operator ID)
        const {UserInfo} = this.props
        //emitsocket by redux function
        let emitData = {
            "msgType": "UpdatePassword",
            //"socketId": "8SpxJb0PqsT0zcboAAEa",   //will add in socket server side
            "operatorID": UserInfo.userID,
            "operatorPassword": oldPassword_hash,
            "userID": UserInfo.userID,
            "newPassword": password_hash
        }
        console.log(emitData)
        //emit socket
        this.props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "UserManageRequest",        //socket function for any input
            EmitSocketData: emitData
        })

        this.setState({
            isLoading: true
        })
    }
    getInputs() {
        return {
            newPassword: this.newPassword.current.value,
            resubPassword: this.resubPassword.current.value,
            oldPassword: this.oldPassword.current.value
        }
    }
    checkPasswordValid(InputJson) {
        const {newPassword, resubPassword, oldPassword} = InputJson
        if(oldPassword == "" || resubPassword == "" || newPassword == "") {
            this.PopupNotificationRef.addNotificationPopup("Some input(s) is/are empty", 
            "Please fill in all 3 fields", null, true)
            return false
        }
        if(resubPassword != newPassword) {
            this.PopupNotificationRef.addNotificationPopup("Wrong resubmit Password", 
            "Resubmit Password and New Password input is different", null, true)
            return false
        }
        if(newPassword.length < 5) {
            this.PopupNotificationRef.addNotificationPopup("New Password is too Short", 
            "Password input should be longer than 5", null, true)
            return false
        }
        return true
    }
    ResetPwd() {
        this.props.ResetPwd()      //close popup
    }

    render() {
        const {showPopup, UserInfo} = this.props
        const {isLoading} = this.state

        const IsFirstLogin = (UserInfo != null && UserInfo.IsFirstLogin == true)

        return [
            (IsFirstLogin)?
            <div className="resetPwd_bg"></div>
            :
            <div style={{display: 'none'}}></div>,

            showPopup?<div className={"resetPwdContainer "} key="rest_main">
            {
                IsFirstLogin?
                <div style={{display: 'none'}}></div>
                :
                <div className="fa fa-times resetPwdCloseBtn" onClick={this.ResetPwd}></div>
            }
            {
                IsFirstLogin?
                <div className="resetPwdHeader">{getTxt('resetPwd', 'firstChangePassword')}</div>
                :
                <div className="resetPwdHeader">
                    {getTxt('resetPwd', 'changePassword')}
                </div>
            }
            <div className="resetPwdInputContainer">
                <input type="password" className="resetPwdInput" ref={this.oldPassword} placeholder={getTxt('resetPwd', 'currentPassword')} autoFocus="true"></input>
                <input type="password" className="resetPwdInput" ref={this.newPassword} placeholder={getTxt('resetPwd', 'newPassword')}></input>
                <input type="password" className="resetPwdInput" ref={this.resubPassword} placeholder={getTxt('resetPwd', 'retypeNewPassword')}></input>
            </div>
            <div className="resetPwdBtnContainer">
                <div className="resetPwdSubmitBtn" onClick={this.submitNewPassword}>
                    {isLoading?<div className="fa fa-spinner fa-spin"></div>:getTxt('resetPwd', 'submit')}
                </div>
                {
                    IsFirstLogin?
                    <div style={{display: 'none'}}></div>
                    :
                    <div className="resetPwdCancelBtn" onClick={this.ResetPwd}>
                        {getTxt('resetPwd', 'cancel')}
                    </div>
                }
            </div>
        </div>:<div></div>,
        <PopupNotification onRef={ref => {this.PopupNotificationRef = ref}} />
        ]
    }
}

function mapStateToProps(state) {
    return {
        socket: state.socket,
        level: state.level,
        currentlevel: state.currentlevel,
        currentSite: state.currentSite,
        MenuDisplay: state.MenuDisplay,
        Notification: state.Notification,
        configStorage: state.configStorage,
        UserInfo: state.UserInfo
    }
}
export default connect(mapStateToProps)(ResetPwd)