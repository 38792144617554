import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import './PerformanceReport.css'
import SearchBar from "./SearchBar";
import TableHead from "./TableHead";
import Table from "./Table";
import { useHistory } from "react-router-dom";
import Downloadcsv from "../bottom/downloadcsv";
import Pagebtns from "../bottom/pagebtns";
import moment from 'moment'

const axios = require('axios');
function MmsPerformanceReport(props) {
    const history = useHistory()
    // const [isAdvanceSearch, setAdvanceSearch] = useState({
    //     isAdvanceSearch: false,
    //     AlarmList: null,
    //     rawAlarmList: null,
    //     //sorted result (by table head .js)
    //     sortedList: null,
    //     //filtered result (by searchbar.js)
    //     filteredList: null,
    //     //slice by page (by pages.js widget)
    //     pageSliceList: null,

    //     updateCount: 0
    // })
    const [performanceList, setPerformanceList] = useState([])
    const [filteredList, setFilteredList] = useState([])
    const [pageSliceList, setPageSliceList] = useState([])
    const [page, setPage] = useState(0)
    const [startDate, setStartDate] = useState(new Date(moment().subtract(180, 'days')))
    const [endDate, setEndDate] = useState(new Date())
    

    useEffect(() => {
        refresh()
    }, [startDate, endDate])

    function refresh() {
        const RequestData_ColdBoxPerformance_List = {
            "options": {
                "startTime": startDate,
                "endTime": endDate
            }
        }
        console.log("GetColdBoxPerformance send!")
        axios.post('https://47.52.20.40:31001/AtalAPI/v1/CPSL_ColdBox_GetPerformance', RequestData_ColdBoxPerformance_List)
            .then(function (response) {
                console.log("GetColdBoxPerformance recv!")
                console.log(response);
                let list = response.data.info.map(item => {
                    return {
                        Name: item.name,
                        TotalDownTime: Math.round(item.totalDownTime / 36000.0) / 100.0,
                        CountWO: item.countWO,
                        CountFailure: item.countFailure,
                        Duration: Math.round(item.duration / 36000.0) / 100.0,
                        Mtfb: Math.round(item.mtfb / 36000.0) / 100.0,
                        Mttr: Math.round(item.mttr / 36000.0) / 100.0,
                        Availability: (Number(item.availability) * 100).toFixed(2) + '%',
                    }
                })
                setPerformanceList(list)
                console.log(list);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function UpdateList(key, list) {
        if (key === "pageSliceList")
            setPageSliceList(list)
        else setFilteredList(list)
    }
    function setStartTime(date) {
        setStartDate(date)
        console.log(date + 'date')
    }
    function setEndTime(date) {
        setEndDate(date)
    }
    const CsvHeaders = [
        {
            key: 'Name',
            label: 'Device ID',
        },
        {
            key: 'TotalDownTime',
            label: 'Total Down Time(hr)',
        },
        {
            key: 'CountWO',
            label: 'W.O. Count',
        },
        {
            key: 'CountFailure',
            label: 'Fault Count',
        },
        {
            key: 'Availability',
            label: 'Availability',
        },
        {
            key: 'Mtfb',
            label: 'MTBF(hr)',
        },
        {
            key: 'Mttr',
            label: 'MTTR(hr)',
        }
    ]
    console.log('filteredList' + filteredList)
    return <div className="performanceReport">
        <SearchBar performanceList={performanceList} UpdateList={UpdateList} refresh={refresh} setStartTime={setStartTime} setEndTime={setEndTime} />
        <TableHead />
        {
            (pageSliceList != null && pageSliceList != undefined) ?
            pageSliceList.map(row => {
                    return <Table row={row} onClick={() => history.push({ pathname: "/Main/MmsPerformanceReport", state: { "mode": "Update", "data": (row) } })} />
                }) : ""
        }
        <div className="ColdboxIndi_bottom">
            <Downloadcsv sortedList={filteredList} headers={CsvHeaders} filename='Device_Performance_Report.csv' />
            <Pagebtns sortedList={filteredList} UpdateList={UpdateList} />
        </div>
    </div>
}

function mapStateToProps(state) {
    return {
        socket: state.socket,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate, //when this value is updated, render would triggered
        UserInfo: state.UserInfo,
        Notification: state.Notification,
    }
}
export default connect(mapStateToProps)(MmsPerformanceReport)