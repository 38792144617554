import React from 'react'
import './Map.css'
import $ from 'jquery'
import { connect } from 'react-redux'
import { setTimeout } from 'timers'

//component
import DrawArea from './drawarea/drawarea.js'
import Demo3D from '../../Setting/3dDemo/demo3D'   //sample for north point only, hardcoded, not used now
import DeviceInfo from './deviceInfo/deviceInfo'    //widget next to the map image, shoing the device info
import InfoPanel from './infoPanel/infoPanel'
import SoundPlay from '../../../Sound/Sound'
import {GetSiteDesc, GetLvlDesc} from '../../common/language/locDisplay'
import { isWaterPumpLocations, resolvePositionOnClick } from '../MenuList/MenuListItem'

class Map extends React.Component {
    constructor(props) {
        super(props)

        //component to check the style of html elements (with this setting, the html element real time style can be get)
        this.mapwidth = React.createRef()
        this.mapdiv = React.createRef()
        this.mapoutsidediv = React.createRef()
        this.maptitle = React.createRef()
        this.DrawArea = React.createRef()
        this.MainPopUpDiv = React.createRef()
        this.TitlePopUpDiv = React.createRef()

        //parameters for map style settings
        this.marginLeft = '5'
        this.paddingTop = '1'
        this.paddingBottom = '1'
        this.state = {
            Mapitem: {
                'marginLeft': this.marginLeft + 'px',
                'marginTop': this.paddingTop + 'px',
                'marginBottom': this.paddingBottom + 'px'
            },
            MapAreaStyle: {
                'marginLeft': this.marginLeft + 'px'
            },
            editmap: false,
            mapImagePath: '',//'http://47.56.108.35/assets/images/floors/1.png', //this is the hard code sample image src
            ImageFailedToLoad: false, //default is false
            isShow3D: false,

            pickedItem: null,           //item for deviceInfo widget to display
            pickedGWItem: null          //picked Gateway, display on the map
        }
        this.MapWidthHeightRatio = 1

        this.MapOnloadLoop = {}

        //function prepare
        this.editMapArea = this.editMapArea.bind(this)
        this.MapOnloaded = this.MapOnloaded.bind(this)
        this.newMapArea = this.newMapArea.bind(this)
        this.removeMapArea = this.removeMapArea.bind(this)
        this.saveMapArea = this.saveMapArea.bind(this)

        this.addpolygon = this.addpolygon.bind(this)

        this.MapAreaOnload = this.MapAreaOnload.bind(this)
        this.GatewayOnload = this.GatewayOnload.bind(this)
        this.closeGWItem = this.closeGWItem.bind(this)
        this.resize = this.resize.bind(this)

        this.addMarker = this.addMarker.bind(this) //this function is incompleted (not called, not draw marker)
        this.ImageError = this.ImageError.bind(this)
        this.pick2D3D = this.pick2D3D.bind(this)
        this.positionClick = this.positionClick.bind(this)
        this.soundRef = React.createRef()
    }
    pick2D3D(event) {
        let pickedValue = event.target.value
        let isPicked
        if(pickedValue == "2d") isPicked = false
        else isPicked = true

        this.setState({
            isShow3D: isPicked
        })
    }
    componentWillUnmount() {
        //remove the socket listener on this page
        try {
            this.props.socket.removeListener('MapArea', console.log('Remove Listener Completed'))
        }
        catch(err) {
            console.error('Map.js ComponentWillUnmount() error')
        }

        window.removeEventListener('resize', this.resize)
        clearInterval(this.MapOnloadLoop)
    }

    componentDidMount() {
        const _this = this
        //check socket variable is null or not
        if(this.props.socket == null) {
            //return to login page
            console.error('this.props.socket == null')
            return
        }
        window.addEventListener('resize', this.resize)

        //infinite loop untill this component will unmount
        this.MapOnloadLoop = setInterval(() => {
            _this.MapOnloaded()
            _this.MapAreaOnload()
            _this.GatewayOnload()
        }, 1000 * 10)
        
        setTimeout(function(){     
            _this.MapOnloaded()
            _this.MapAreaOnload()
            _this.GatewayOnload()
        }, 500)
    }

    resize() {
        const _this = this
        setTimeout(function(){     
            _this.MapOnloaded()
            _this.MapAreaOnload() 
        }, 500)
    }

    MapAreaOnload() {
        let _this = this
        console.log('MapArea()')
        //clear the polygons
        $("#polygonsvgbody").empty()

        //use real data
        //get currentSite and currentlevel
        let {UserInfo, currentSite, currentlevel, currentlevelID} = this.props
        //get the MapArea Reading
        try {
            //since the level is the level value, not level count, get the level value first
            let currentlevel_i =  0
            let i = 0
            UserInfo.locations[currentSite].nestedLocs.forEach(function(element) {
                if(currentlevel == element.locName) currentlevel_i = i
                i++
            })

            let MapArea = UserInfo.locations[currentSite].nestedLocs[currentlevel_i]
            //draw polygon
            if(MapArea.nestedLocs == null || MapArea.nestedLocs == undefined) {
                console.warn('There is no polygon readings inside.')
                return
            }

            let polygon_i = 1
            let PolygonJson = []
            if(MapArea.nestedLocs == null) console.log('MapArea.nestedLocs == null Map.js MapAreaOnload()')
            else {
                MapArea.nestedLocs.forEach(function(element) {
                    if(element.locUI.indicator.point.type == "Point") {
                        //there is no draw point method yet
                    }
                    else if(element.showPoint==false) {
                        //not show the point / polygon in map
                    }
                    else {
                        PolygonJson.push({
                            id: polygon_i,      //id start from 1, max 10
                            locName:element.locName,        //locName is the item inside UserInfo
                            polygon: element.locUI.indicator.point,         //polygon array
                        })
                        //check it is marker or polygon area
                        if(element.locUI.type == "marker") {
                            if (element != null && typeof element.payload_definition_id === 'object') {
                                let payloadID = element.payload_definition_id._id
                                let color = ""
                                switch (payloadID) {
                                    case '5ee1970001eaf57614fdea21': // TH
                                        color = "cyan"
                                        break
                                    case '5fc07832aad6fa2b0506dbe5': // Room Occupancy
                                    case '5f61ba8c0279fe5188203f94':
                                    case '5f51af2686395af744f9f477':
                                        color = "#B93B8F"
                                        break
                                    case '5fbe2b70df115e1f0cac33fc': // IAQ
                                    case '5f51ae4486395af744f9f472':
                                    case '5ee1970901eaf57614fdea22':
                                    case '5ee196ee01eaf57614fdea20':
                                        color = "rgba(100, 250, 0, 1)"
                                        break
                                    case '5f61847f0279fe5188203f92': // LUX
                                    case '5f51ad3e86395af744f9f471':
                                    case '5f51ad3686395af744f9f470':
                                        color = "#FFDB58"
                                        break
                                    case '5f894cb3528f5ee6e0a96e92': // AC Power
                                    case '5f51af1686395af744f9f476':
                                        color = "#48CCCD"
                                        break
                                    case '5f6184b90279fe5188203f93': // Rubbish Bin
                                    case '5f51aeb286395af744f9f475':
                                    case '5f51aea986395af744f9f474':
                                    case '60541dfab80eec26b06eae97':
                                    case '60f8ea6a8f8f97770881c9d8':
                                    case '61039d702a3b4925342cbceb':
                                        color = "orange"
                                        break
                                    case '5f61c1770279fe5188203f95': // Toilet Occupancy
                                    case '5f51ae9686395af744f9f473':
                                        color = "magenta"
                                        break
                                    case '5f606d36d69d2c875c84c0dc': // People Count
                                    case '5f51af3386395af744f9f478':
                                        color = "#e42c6a6e"
                                        break
                                    case '5fbe0a20df115e1f0cac33f4': // Water Leakage
                                    case '5fe9389e239ba261bc75217b':
                                        color = "#8c0eff6e"
                                        break
                                    case '5fc0784aaad6fa2b0506dbe6': // Wetness
                                        color = "rgba(46, 134, 193)"
                                        break
                                    case '5fd9ebddaf0893478472999a': // Vibration
                                        color = "Thistle"
                                        break
                                    case '5fbe0a8bdf115e1f0cac33f5': // Door Contact
                                    case '605d865c6f9df05830711f1d':
                                        color = "green"
                                        break
                                    case "608a17cd34aaef1264c73ba3":
                                        color = "rgba(204,255,0)"
                                        break
                                    default:
                                        // console.log('undefined marker type', {
                                        //     payloadID: payloadID,
                                        //     locName: element.locName,
                                        //     coordinate: element.locUI.indicator.point[0].coordinates
                                        // })
                                        color = "var(--focuscardbackground)"
                                        break
                                }

                                if(element.locUI.indicator != null && element.locUI.indicator.color != null) color = element.locUI.indicator.color
                                //call marker function or polygon area
                                _this.addMarker(element.locUI.indicator.point[0].coordinates, 'var(--markercolor' + polygon_i + ')', color, element.locName, element.locUI)
                            } else if (element != null && element.payload_definition_id != null && element.payload_definition_id.name != null) {
                                //different color
                                let deviceType = element.payload_definition_id.name
                                let NameID = element.payload_definition_id.nameID
                                let color = ""
                                if(element.locUI.indicator != null && element.locUI.indicator.color != null) color = element.locUI.indicator.color
                                else if(deviceType === "Temp & Humid" || deviceType === "Temperature & Humdity") color = "cyan"
                                else if(deviceType === "Occupancy") color = "#B93B8F"
                                else if(deviceType === "IAQ") color = "rgba(100, 250, 0, 1)"
                                else if(deviceType === "LUX") color = "#FFDB58"
                                else if(deviceType === "AC Power") color = "#48CCCD"
                                else if(deviceType === "Rubbish Bin" || deviceType ==='Waste Bin' || deviceType === 'Rubbish Bin (Public)' || deviceType==='Rubbish Bin (Toilet)') color = "orange"
                                else if(deviceType === "Toilet Occupancy") color = "magenta"
                                else if(deviceType === "People Count") color = "#e42c6a6e"
                                else if(deviceType === "Water Leakage") color = "#8c0eff6e"
                                else if(deviceType === "Water Leakage (Point)") color = "#8c0eff6e"
                                else if(deviceType === "Wetness") color = "rgba(46, 134, 193)"
                                else if(deviceType === "Vibration") color = "Thistle"
                                else if(deviceType === "RFID") color = "rgba(23, 32, 42)"
                                else if(deviceType === "Door Contact") color = "green"
                                else if(deviceType === "LED Tube" || NameID === "LED Tube") color = "rgba(204,255,0)"
                                else if(deviceType === "IAQ16in1" || deviceType === "IAQ15in1") color = "orange"
                                else {
                                    console.group(deviceType)
                                    color = "var(--focuscardbackground)"
                                }
                                //call marker function or polygon area
                                _this.addMarker(element.locUI.indicator.point[0].coordinates, 'var(--markercolor' + polygon_i + ')', color, element.locName, element.locUI)            //.. original code without hardcode check
                            }
                            else _this.addMarker(element.locUI.indicator.point[0].coordinates, 'var(--markercolor' + polygon_i + ')', "", "No Device Name")
                        }
                        else if(element.locUI.type == "zone") {
                            //draw polygon to UI
                            _this.addpolygon(element.locUI.indicator.point, 'var(--polygoncolor' + polygon_i + ')')
                        }
                        else {
                            console.log('undefined')
                        }
                    }
                    polygon_i++
                })
            }
            //save the polygon into global
            _this.props.dispatch({ type: 'CurrentPolygon', 
                data: PolygonJson
            })
        }
        catch(err) {
            console.error(err)
        }
    }
    GatewayOnload() {
        const _this = this
        const {DeviceData, currentSite, currentlevel} = this.props
        if(DeviceData==null||DeviceData.length==null||DeviceData.length==0||currentSite==null||currentlevel==null) return

        const levelItem = GetLevelItem(DeviceData, currentSite, currentlevel)
        const GWList = GetGWList(levelItem)
        GWList.forEach(GWItem => {
            const {status, coor, name, color} = GWItem
            //_this.addMarker(coor, 'GatewayPt-'+name, 'rgba(255, 0, 0, 1)', status)
            _this.addMarker(coor, 'GatewayPt-'+name, 'rgba(0, 255, 0, 0.5)', status)
        })
    }
    closeGWItem() {
        this.setState({
            pickedGWItem: null
        })
    }

    addMarker(markerPt, id, fillcolor, deviceName, locUI) {     //#color is the ID of svg item, fill color is the default color of the circle
        if(this.mapwidth.current==null) return
        //get the width and the height of svg object
        let mapimagewidth = this.mapwidth.current.offsetWidth
        let mapimageheight = this.mapwidth.current.offsetHeight
        //get the margin-top value
        let ImageMarginTop_str = this.state.Mapitem.marginTop
        ImageMarginTop_str = ImageMarginTop_str.replace('px', '')
        let xCoor = mapimagewidth * markerPt[0]
        let yCoor = markerPt[1]*mapimageheight + this.paddingTop

        //change size if there is config
        const r = (locUI==null||locUI.size==null)?'8':locUI.size.toString()
        let strokeW = '7'
        if(locUI != null && locUI.size != null) strokeW = (locUI.size - 1).toString()

        //add marker into svg
        let svgimg = document.createElementNS('http://www.w3.org/2000/svg','circle')

        svgimg.setAttributeNS(null,'cx',xCoor)
        svgimg.setAttributeNS(null,'cy',yCoor)
        svgimg.setAttributeNS(null,'r', r)
        svgimg.setAttributeNS(null,'stroke','#00000040')
        svgimg.setAttributeNS(null,'stroke-width', strokeW + 'px')
        let finalColor = fillcolor
        finalColor = getFinalMarkerColor(this.props, deviceName, fillcolor)
        svgimg.setAttributeNS(null,'fill',finalColor)

        svgimg.setAttributeNS(null,'id',id)
        svgimg.setAttributeNS(null,'title',deviceName)
        document.getElementById('polygonsvgbody').appendChild(svgimg)
    }

    //sub function for onload polygon
    addpolygon(polygon_pts, color_drawing) {
        const thisoutside = this

        //get the width and the height of the image in browser
        const mapimagewidth = this.mapwidth.current.offsetWidth
        const mapimageheight = this.mapwidth.current.offsetHeight// - this.paddingTop*2;
        //foreach the polygon_pts
        let realCoordinateArray = []
        polygon_pts.map((data, i) => {
            //console.log(data);
            //ratio the width and height from percentage to 'px'
            const x_coor = data[0]*mapimagewidth
            const y_coor = data[1]*mapimageheight + this.paddingTop
            //push the result into an new array
            realCoordinateArray.push([x_coor, y_coor])
        })

        //set the points to the map image
        let color_str = 'fill: '+ color_drawing

        var polygon = thisoutside.makeSVG('polygon', {"points": realCoordinateArray, "style": color_str})
        document.getElementById('polygonsvgbody').appendChild(polygon)
    }

    MapOnloaded() {
        //This part only reload when the components are loaded
        //This part is function to resize and positioning the map image in the map div
        const thisoutside = this
        //set the map to middle of the map
        let mapimagewidth = 0
        let mapdivwidth = 0
        //set the map to vertical middle
        let mapdivheight = 0
        let mapimageheight = 0
        let maptitleheight = 0
        if(this.mapwidth.current == null || this.mapdiv.current == null || this.mapoutsidediv.current == null || this.maptitle.current == null) {
            //not every time ref() is ready (e.g. render() is running)
            //console.log('some items are not ready MapOnloaded()');
            return
        }
        try {
            mapimagewidth = this.mapwidth.current.offsetWidth
            mapdivwidth = this.mapdiv.current.offsetWidth
            this.marginLeft = (mapdivwidth - mapimagewidth)/2
            mapdivheight = this.mapoutsidediv.current.offsetHeight
            mapimageheight = this.mapwidth.current.offsetHeight
            maptitleheight = this.maptitle.current.offsetHeight
        }
        catch(err) {
            console.log('Error in MapOnloaded()')
            console.log(err)
            return
        }
        if(mapimageheight == 0 || mapimagewidth == 0) {
            console.log('Image is not loaded, so redo the MapOnload() again Map.js')
            setTimeout(function(){thisoutside.MapOnloaded()}, 1000)
            return
        }

        let remainheightspace = (mapdivheight - mapimageheight - maptitleheight)/2
        this.paddingTop = remainheightspace
        this.paddingBottom = remainheightspace

        //get width of image
        let widthImage = mapimagewidth
        //set value
        this.setState({
            Mapitem: {
                'marginLeft': this.marginLeft + 'px',
                'marginTop': this.paddingTop + 'px',
                'marginBottom': this.paddingBottom + 'px'
            },
            MapAreaStyle: {
                'marginLeft': this.marginLeft + 'px',
                'width': widthImage,
            },
        })

        this.MapWidthHeightRatio = mapimagewidth / mapimageheight
    }

    editMapArea() {
        //alert('edit map onclick');
        this.setState({
            editmap: !this.state.editmap
        });
    }

    newMapArea() {
        this.DrawArea.current.nextArea();
    }

    removeMapArea() {
        this.DrawArea.current.removeArea();
    }

    saveMapArea() {
        //This is the function to add polygons to map.js when save the pop-up drawed areas
        let thisoutside = this
        //Get the Area Json from the DrawArea.js component
        let savereturn = this.DrawArea.current.saveArea()

        //Convert the Area Position Reading to Percentage Unit
        let Map_W = 1050;   //correct
        let Map_H = 1050/this.MapWidthHeightRatio    //use image ratio to find the height
        //get the true height:
        Map_H = this.MainPopUpDiv.current.offsetHeight// - this.TitlePopUpDiv.current.offsetHeight;    //since change the title to left hand side, no need to minus the hight of the header

        let mapimagewidth = this.mapwidth.current.offsetWidth
        let mapimageheight = this.mapwidth.current.offsetHeight - thisoutside.paddingTop*2

        let convertedAreaArray = {}
        let currentPolygon = ''
        let currentpolygonnumber = 1
        $.each(savereturn, function( index, value ) {
            currentPolygon = ''
            $.each(value, function( innerindex, innervalue){
                //console.log(index + "|" + innerindex + "|" + innervalue);
                if(innerindex == 0) {
                    //open a new polygon to push point
                    convertedAreaArray[index] = []
                }
                else {
                    //not the first polygon point
                }

                //push into the polygon
                let itemwidth = innervalue[0]
                let itemheght = innervalue[1]
                let itemwidthpercent = itemwidth/Map_W*mapimagewidth
                let itemheightpercent = itemheght/Map_H*mapimageheight + thisoutside.paddingTop

                let converteditem = [itemwidth/Map_W, itemheght/Map_H]     //percentage of the area
                convertedAreaArray[index].push(converteditem)
                currentPolygon += ' ' + itemwidthpercent + ',' + itemheightpercent
            })
        });
        console.log(convertedAreaArray)
    }

    makeSVG(tag, attrs) {
        var el= document.createElementNS('http://www.w3.org/2000/svg', tag)
          for (var k in attrs)
          el.setAttribute(k, attrs[k])
          return el
    }

    ImageError() {
        //triggered when the Image loaded failed
        console.error('Image Failed to loaded! Map.js')
        alert('Map / Dashboard Image is not found!')
        this.setState({
            ImageFailedToLoad: true
        })
    }

    positionClick(event) {                  ///when clicked on position marker, highlight it and bottom card reading
        const _this = this
        this.soundRef.current.alarmMp3Run("click")
        //check if it is position item
        if(event == null || event.target == null || event.target.id == null) return     //exception, when clicked on no id item
        const id = event.target.id
        const splitItem = id.split('var(--markercolor')
        if(splitItem == null || splitItem.length == 0 || splitItem[1] == null) {
            if(IsGatwWayItem(id)) ShowGWPopup(_this, id)
            return
        }

        //get position item id
        const splitedItem = splitItem[1]            //"XX)"
        const idStr = splitedItem.replace(')', '')
        //result: position item id
        const idInt = parseInt(idStr, 0)

        //get position's name
        const {Polygons} = this.props
        // if(Polygons == null || Polygons.length < idInt) return
        const clickedLocation = Polygons.find(function(locItem) {
            return locItem.id == idInt
        })
        const locName = clickedLocation.locName

        if(isWaterPumpLocations(locName)) {
            resolvePositionOnClick(locName, this.props)
            return
        }

        //get location type, count in array
        let getLocItem
        const {UserInfo} = this.props
        if(UserInfo == null || UserInfo.locations == null || UserInfo.locations.length == null || UserInfo.locations.length == 0) return
        UserInfo.locations.forEach(siteItem => {
            if(siteItem == null || siteItem.nestedLocs == null || siteItem.nestedLocs.length == null) return
            siteItem.nestedLocs.forEach(floorItem => {
                if(floorItem == null || floorItem.nestedLocs == null || floorItem.nestedLocs.length == null) return
                floorItem.nestedLocs.forEach(locItem => {
                    if(locItem.locName == locName) getLocItem = locItem
                })
            })
        })
        this.setState({
            pickedItem: getLocItem,
        })

        //get count on menu
        if(getLocItem == null || getLocItem.payload_definition_id == null) return // || getLocItem.payload_definition_id.name == null) return
        const deviceName = getLocItem.payload_definition_id.name
        let deviceList_try = []
        let deviceList = []
        const {currentSite, currentlevel} = this.props
        UserInfo.locations[currentSite].nestedLocs.forEach(floorItem => {
            //console.log(floorItem);
            if(currentlevel == floorItem.locName) {
                var isFirstTempHumid = true
                floorItem.nestedLocs.forEach(LocItem => {
                    let newItem = false;
                    deviceList_try.forEach(element => {
                        if(element.name == LocItem.payload_definition_id.name) newItem = true
                    })
                    if(!newItem) {   //new deviceName
                        try {
                            if(LocItem.payload_definition_id.name == "Temperature & Humidity" && isFirstTempHumid) {
                                deviceList_try.push({
                                    //_id: LocItem.device_id._id,
                                    //deviceSerial: LocItem.device_id.deviceSerial,
                                    deviceName: "Temp & Humid"
                                })
                                isFirstTempHumid = false
                            }
                            else if(LocItem.payload_definition_id.name == "Temperature & Humidity" && !isFirstTempHumid) {
                            }
                            else {
                                deviceList_try.push(LocItem.payload_definition_id)
                            }
                        }
                        catch(err){
                            console.log(err)
                        }
                    }
                })
                deviceList = deviceList_try
            }
        })
        if(deviceList == null || deviceList.length == null) return
        let countMenu = 0
        let menuResult = 0
        deviceList.forEach(menuItem => {
            if(menuItem.name == deviceName) menuResult = countMenu
            countMenu++
        })
        //get card number
        //generate list of device that are same type
        let SameTypeValues = []
        UserInfo.locations[currentSite].nestedLocs.forEach(floorItem => {
            if(currentlevel == floorItem.locName)
            {
                floorItem.nestedLocs.forEach(LocItem => {
                    if(LocItem == null || LocItem.payload_definition_id == null || LocItem.payload_definition_id.name == null) return
                    if(LocItem.payload_definition_id.name == deviceName) SameTypeValues.push(LocItem)
                })
            }
        })
        let countCard = 0
        let resultCountCard = 0
        SameTypeValues.forEach(cardItem => {
            if(cardItem.locName == locName) resultCountCard = countCard
            countCard++
        })

        //set redux, to highlight that position           
        this.props.dispatch({ type: 'FloorPlanBottomMenu', data: "bottom" + menuResult })  //menu item, "bottom" + i
        this.props.dispatch({ type: 'bottomcardhover', data: 'bottomcard_' + resultCountCard })
        this.props.dispatch({ type: 'PickPolygon', 
            data: locName //string of locName, highlight the polygon inside Map.js
        })
    }

    render() {
        const {pickedItem, pickedGWItem} = this.state
        let mapItem = ""
        try {
            if(this.props.UserInfo == null || this.props.UserInfo == undefined) {
                return <div>Storage is missing, please refresh</div>
            }
            else {
                const {configStorage} = this.props
                const rooturl = configStorage.imgUrl
                mapItem = rooturl + "/assets/images/floors/" + this.props.UserInfo.locations[this.props.currentSite].imgUrl + "/" + this.props.currentlevel + ".png"
                //replace space with %20
                mapItem = mapItem.replace(' ', '%20')
            }
        }
        catch(err) {
            return <div>Some error happened</div>
        }

        //highlight polygon by adjust color of css
        const { currentBottomCard, editbuttonhideclass } = this.props
        let number_str = currentBottomCard.replace('bottomcard_', '')
        let cardnumber = parseInt(number_str)

        //remove all polygon highlight color
        let i = 0
        for(i = 0; i < 31; i++) {
            document.documentElement.style.setProperty('--polygoncolor'+i, 'transparent')
            document.documentElement.style.setProperty('--markercolor'+i, 'transparent')
        }
        //set newhighlight color
        try {
            //find the polygon to highlight with the locName in redux
            let {PickedPolygonLoc, Polygons, configStorage} = this.props
            if(Polygons == null) console.log('this.props.Polygons == null map.js render()')
            else {
                Polygons.forEach(PolygonItem => {
                    let highlightItem = document.getElementById('var(--markercolor'+PolygonItem.id + ")")
                    if(PolygonItem.locName == PickedPolygonLoc) {
                        //draw on that polygon
                        document.documentElement.style.setProperty('--markercolor'+PolygonItem.id, 'red')
                        document.documentElement.style.setProperty('--polygoncolor'+PolygonItem.id, '#00000060')
                        if(highlightItem!= null) {
                            let r_value = 8
                            try {
                                r_value = highlightItem.r.animVal.value
                            }
                            catch(err) {
                                console.log('error when get point radius')
                                console.log(err)
                            }
                            if(r_value==8) highlightItem.setAttribute('class', "circleItem")
                            else highlightItem.setAttribute('class', "circleItem2")
                        }
                    }
                    else {
                        if(highlightItem!= null) highlightItem.setAttribute('class', "")         //remove animation class
                    }
                })
            }
        }
        catch(err) {
            console.error('Map.js highlight card failed')
            console.error(err)
        }

        let { currentlevel, currentSite, history, UserInfo } = this.props
        let SiteInfo = {}
        try {
            SiteInfo = UserInfo.locations[currentSite]
        }
        catch(err) {
            console.error('Something wrong when get currentSite Info @Map.js')
        }

        //load image failed
        if(this.state.ImageFailedToLoad) {
            return <div className="floor_plan_outside loadingUICardTxt">
                Load Image Failed!
            </div>
        }

        const {isShow3D} = this.state
        let hide2DMap = ""
        if(isShow3D) {
            hide2DMap = "hide"
        }
        let isAllow3D = <option value="3d">3D</option>
        if(this.props.currentlevel!="18" && false) {
            isAllow3D = null
        }       //.. Allow 3D option is hardcode, only for Floor 18 in PY
        const {DeviceData} = this.props
        const lvlItem = DeviceData[currentSite].nestedLocs.find(item => item.locName == currentlevel)

        return <div className="floor_plan_outside" onClick={this.positionClick}>
            <div className="floor_plan_inside" ref={this.mapoutsidediv}>
                <div className="flex flex-wrap shadow-lg floor_plan_inside_head" ref={this.maptitle}>
                    <div _ngcontent-ihe-c2="" className="floor_address">
                        {GetSiteDesc(SiteInfo)}
                    </div>
                    {
                        false?
                        <select className="viewerSelecter" onChange={this.pick2D3D}>
                            <option value="2d">2D</option>
                            {isAllow3D}
                        </select>
                        :
                        <div className='viewerSelecter'></div>
                    }
                    <div _ngcontent-ihe-c2="" className="floor_floor">
                        {GetLvlDesc(lvlItem)}
                    </div>
                    <InfoPanel />
                </div>
                <Demo3D isShow3D={isShow3D}/>
                <div className={"floor_plan_inside_body " + hide2DMap}>
                    <div id="floor_Image_div" ref={this.mapdiv}>
                        <img id="floor_image" src={mapItem} style={this.state.Mapitem} ref={this.mapwidth} onError={this.ImageError} />
                        <div id="polygon_overlapping_div">
                            <svg className="polygonsvgbody" id="polygonsvgbody" xmlns="http://www.w3.org/2000/svg" style={this.state.MapAreaStyle}>
                            </svg>
                            <i className={"fa fa-pencil map_editbutton " + editbuttonhideclass} onClick={ this.editMapArea }>Edit</i>
                            <DeviceInfo pickedItem={pickedItem} pickedGWItem={pickedGWItem} closeGWItem={this.closeGWItem} />
                        </div>
                    </div>
                </div>           
            </div>
            <SoundPlay ref={this.soundRef}/>
            <div className={this.state.editmap ? "editmap" : "hidemap"} ref={this.MainPopUpDiv}>
                <div className="editarealistdiv" ref={this.TitlePopUpDiv}>
                    <div className="MapcloseDiv">
                        <i className="fa fa-times editarea_closebtn" onClick={ this.editMapArea }></i>
                    </div>
                    <button className="area_btn" onClick={ this.newMapArea }>
                        <i className="fa fa-plus-square"></i>
                    </button>
                    <button className="area_btn_2" onClick={ this.removeMapArea }>
                        <i className="fa fa-minus-square"></i>
                        
                    </button>
                    <button className="area_btn_3" onClick={ this.saveMapArea }>
                        <i className="fa fa-save"></i>                        
                    </button>
                    <div className="PolygonsTxt">
                        Polygons:
                    </div>
                </div>
                <DrawArea imageUrl={mapItem} MapWidthHeightRatio={this.MapWidthHeightRatio} ref={this.DrawArea} />
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
      socket: state.socket,
      level: state.level,
      DeviceData: state.DeviceData,
      currentlevel: state.currentlevel,
      currentlevelID: state.currentlevelID,
      currentSite: state.currentSite,
      MenuDisplay: state.MenuDisplay,
      SiteList: state.SiteList,
      currentBottomMenu: state.currentBottomMenu,
      currentBottomCard: state.currentBottomCard,
      LatestPage: state.LatestPage,
      configStorage: state.configStorage,
      UserInfo: state.UserInfo,
      ImgStorage: state.ImgStorage,
      Polygons: state.Polygons,
      configStorage: state.configStorage,
      PickedPolygonLoc: state.PickedPolygonLoc,
    }
}
export default connect(mapStateToProps)(Map)

// Gateway functions
function GetLevelItem(DeviceData, currentSite, currentlevel) {
    const SiteItem = DeviceData[currentSite]
    if(SiteItem==null) return
    const LvlList = SiteItem.nestedLocs
    if(LvlList==null||LvlList.length==null||LvlList.length==0) return
    const LvlItem = LvlList.find(item => {
        return item.locName == currentlevel
    })
    return LvlItem
}
function GetGWList(LvlItem) {
    if(LvlItem==null) return []
    const EquipList = LvlItem.equipments
    if(EquipList==null||EquipList.length==null||EquipList.length==0) return []

    const returnGWList = EquipList.map(item => {
        const {status} = item
        const coor = item.locUI.indicator.point[0].coordinates
        const color = item.locUI.indicator.point[0].color
        const name = item.locUI.desc
        return {
            status: status,
            coor: coor,
            name: name,
            color: color
        }
    })

    return returnGWList
}

function IsGatwWayItem(id) {
    const IDSplit = id.split('GatewayPt-')
    if(IDSplit==null||IDSplit.length==0||IDSplit.length==1) return false
    return true
}
function ShowGWPopup(_this, id) {
    const IDSplit = id.split('GatewayPt-')
    if(IDSplit==null||IDSplit.length==0||IDSplit.length==1) return
    const locName = IDSplit[1]

    const {DeviceData, currentSite, currentlevel} = _this.props
    if(DeviceData==null||DeviceData.length==null||DeviceData.length==0) return
    const LvlItem = GetLevelItem(DeviceData, currentSite, currentlevel)
    const GWList = GetGWList(LvlItem)
    const GWItem = GWList.find(item => {
        return item.name == locName
    })    
    const status = GWItem.status

    _this.setState({
        pickedGWItem: {
            locName: locName,
            payload: {
                updateDate: '',
                status: status
            },
            payload_definition_id: {
                name: 'Gateway',
                nameID: 'Gateway',
                cardTitle: 'Gateway'
            }
        }
    })
}

// alert color check
function getFinalMarkerColor(props, deviceName, fillcolor) {
    const {DeviceData, currentSite, currentlevel} = props
    if(DeviceData==null||currentSite==null||currentlevel==null) return fillcolor

    const siteItem = DeviceData[currentSite]
    const levelItem = siteItem.nestedLocs.find(item => {
        return item.locName == currentlevel
    })
    if(levelItem==null) return fillcolor
    const deviceItem = levelItem.nestedLocs.find(item => {
        return item.locName == deviceName
    })
    if(deviceItem==null) return fillcolor

    const {payload} = deviceItem
    // payload reading range check: if exist max, return red color
    if(payload==null) return fillcolor

    // water leakage: waterLeakageStatus: 0
    if(payload.waterLeakageStatus != null && payload.waterLeakageStatus != 0) return 'red'
    // occ
    if(payload.roomOccupied != null && payload.roomOccupied == true) return 'red'

    return fillcolor
}

/* --------------------------point items with different size:--------------------------------------------------------------------------------- */
// locUI: {
//     desc: "G/F Multi-Purpose Hall (MPH)",
//     indicator: {point: Array(1), color: 'purple'},
//     showPopup: 1,
//     type: "marker",
//     size: 8             // 8 is default size, min point size is 3
// }