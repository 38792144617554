import React, { useEffect, useState } from 'react'
import './selectWidget.css'

import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding } from '@fortawesome/free-solid-svg-icons'

function SelectWidget(props) {
    const {color2, DeviceData, currentSite, currentlevel, ToiletType} = props
    const {DisplayIndex, setDisplayIndex} = props
    const [SiteName, setSiteName] = useState(null)
    const [LevelName, setLevelName] = useState(null)
    
    useEffect(()=> {
        //onload set picked site and level to local state
        if(DeviceData==null) return

        const ReduxSiteName = DeviceData[currentSite].locName
        setSiteName(ReduxSiteName)

        const ReduxLevelName = currentlevel
        setLevelName(ReduxLevelName)
    }, [])

    function PickSite(SiteName, SiteIndex) {
        props.dispatch({
            type: "PIckSite",
            data: {
                Siteid: SiteIndex,
                Level: '1',
            },
        })

        setTimeout(() => {
            setSiteName(SiteName)
            setDisplayIndex(2)            
        }, 100)
    }
    function PickLevel(LevelName) {
        props.dispatch({
            type: "PickLevel",
            data: LevelName
        })


        setTimeout(() => {
            setLevelName(LevelName)
            setDisplayIndex(3)
        }, 100)
    }
    // function PickToilet(ToiletIndex) {
    //     props.dispatch({
    //         type: "ToiletType",
    //         data: ToiletIndex
    //     })

    //     setTimeout(() => {
    //         setDisplayIndex(3)            
    //     }, 500)
    // }

    if(DeviceData == null) return <div>No DeviceData</div>

    return <div className="Toilet_ad_selectMain">
        {
            DeviceData.map((item, index) => {
                const IsHighlight = (index==currentSite)?'highlight':''
                return <div className={"Toilet_ad_site " + IsHighlight} onClick={()=>PickSite(item.locName, index)}>
                    {item.locName}
                </div>
            })
        }
            {/* <div className={"Toilet_ad_site"}>
                hardcode site 1
            </div>
            <div className={"Toilet_ad_site"}>
                hardcode site 2
            </div> */}
        {
            (DisplayIndex==2)?
            <div>
                Level:
                <div className="Toilet_ad_level_list">
                    {/* <div className="Toilet_ad_level">
                        12
                    </div>
                    <div className="Toilet_ad_level">
                        13
                    </div>
                    <div className="Toilet_ad_level">
                        14
                    </div>
                    <div className="Toilet_ad_level">
                        15
                    </div>
                    <div className="Toilet_ad_level">
                        16
                    </div>
                    <div className="Toilet_ad_level">
                        17
                    </div> */}
                    {
                        DeviceData[currentSite].nestedLocs.map(item => {
                            const IsHighlight = (item.locName==currentlevel)?'highlight':''
                            return <div className={"Toilet_ad_level " + IsHighlight} onClick={()=>PickLevel(item.locName)}>
                                {item.locName}
                            </div>
                        })
                    }
                </div>
            </div>
            :
            <></>
        }
        {/* {
            (DisplayIndex==2)?
            <div>
                Toilet:
                {
                    ToiletList.map(item => {
                        const IsHighlight = (item.index == ToiletType)?'highlight':''
                        return <div className={"Toilet_ad_toilet " + IsHighlight} onClick={()=>PickToilet(item.index)}>
                            {item.name}
                        </div>
                    })
                }
            </div>
            :
            <></>
        } */}
    </div>
}

function mapStateToProps(state) {
    return {
        ToiletType: state.ToiletType,
        DeviceData: state.DeviceData,
        currentSite: state.currentSite,
        currentlevel: state.currentlevel,
    }
}

export default connect(mapStateToProps)(SelectWidget)

const ToiletList = [
    {
        index: 1,
        name: 'male'
    },
    {
        index: 2,
        name: 'female'
    },
    {
        index: 3,
        name: 'disable'
    }
]