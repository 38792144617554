import React from 'react'
import './searchbar.css'
import { connect } from 'react-redux'

class SearchBar extends React.Component {
    constructor(props) {
        super()
        
        this.state = {
            //option list
            // groupList: null,
            siteList: null,
            floorList: null,
            statusList: [
                {
                    name: 'Disconnected',
                    value: 'Disconnected'
                },
                {
                    name: 'Healthy',
                    value: 'Healthy'
                }
            ],
            //filter current values (null or empty str = no filter)
            filterTxt: '',
            filterGroup: '',
            filterSite: '',
            filterLevel: '',
            filterStatus: '',
            //Filter Result
            Filtered: null,

            loading: false,
        }

        this.GenOptions = this.GenOptions.bind(this)
        this.FilterOnchange = this.FilterOnchange.bind(this)
        this.Filter = this.Filter.bind(this)
        this.reload = this.reload.bind(this)
    }
    componentDidMount() {
        this.GenOptions()
    }
    componentDidUpdate(preProps) {
        //Re-gen option list when data is updated
        var {coldboxList} = this.props
        var oldcoldboxList = preProps.coldboxList
        if(coldboxList != oldcoldboxList || (coldboxList!=null&&oldcoldboxList==null)) this.GenOptions()
    }
    reload() {
        const _this = this
        this.props.reload()
        this.setState({
            loading: true
        })
        setTimeout(() => {
            _this.setState({
                loading: false
            })
        }, 2000)
    }
    GenOptions() {
        const {coldboxList, UserInfo} = this.props
        console.log(coldboxList)
        if(coldboxList==null) return
        var Options = coldboxList.map(function(coldboxItem, index) {
            const {group, site, level} = coldboxItem
            return {
                group: group,
                site: site,
                level: level,
                id: index
            }
        })
        console.log(Options)

        var SiteList = []
        var levelList = []
        UserInfo.locations.forEach(siteItem => {
            SiteList.push(siteItem.imgUrl)
            siteItem.nestedLocs.forEach(lvlItem => {
                levelList.push(lvlItem.locName)
            })
        })

        this.setState({
            // groupList: GroupList,
            siteList: SiteList,
            floorList: levelList,
            Filtered: coldboxList,
        })
        this.Filter()
    }
    FilterOnchange(keyStr, e) {
        try {
            const value = e.target.value.toString()
            this.setState({
                [keyStr]: value,
            }, function () {        //run right after state is set
                this.Filter()       //re-filter
            })
        }
        catch(err) {
            console.log(err)
        }
    }
    Filter() {
        const _this = this
        const {filterTxt, filterGroup, filterSite, filterLevel, filterStatus} = this.state
        const {coldboxList, GroupList, UserInfo} = this.props

        var FilteredList = coldboxList
        if(filterTxt!=null&&filterTxt!='') {
            FilteredList = FilteredList.filter(function(coldboxItem) {
                // const deviceSerial = (coldboxItem.device_id == null)?'':coldboxItem.device_id.deviceSerial
                // return deviceSerial.includes(filterTxt.toString()) //|| coldboxItem.device_id.controllerID.includes(filterTxt.toString()) || coldboxItem.device_id.deviceSerial.includes(filterTxt.toString())
                const coldboxID = (coldboxItem.payload == null)?'':coldboxItem.payload.ColdboxID
                return coldboxID.includes(filterTxt.toString()) //|| coldboxItem.device_id.controllerID.includes(filterTxt.toString()) || coldboxItem.device_id.deviceSerial.includes(filterTxt.toString())
            })
        }
        if(filterGroup!=null&&filterGroup!='') {
            // const groupList = GroupList==null||GroupList.groups==null||GroupList.groups.length==null?[]:GroupList.groups   // get redux group list
            // const gpID = filterGroup
            // const selectedGpItem = groupList.find(item => {return item._id == gpID})
            // const coldboxList = selectedGpItem==null||selectedGpItem.location==null||selectedGpItem.location[0]==null?[]:selectedGpItem.location[0]
            // const cdbxIDList = coldboxList.map(item => {return item._id})
            // // filter the list, only keep id that is in the above array
            // FilteredList = FilteredList.filter(cdbxItem => {
            //     const cdbxID = cdbxItem._id
            //     let isInList = false
            //     cdbxIDList.forEach(itemID => {
            //         if(cdbxID==itemID) isInList = true
            //     })
            //     return isInList
            // })
            FilteredList = getColdboxInGroup(_this.props, filterGroup, coldboxList)
        }
        if(filterSite!=null&&filterSite!='') {
            const SiteName = getSiteNameFromSiteLocName(filterSite, UserInfo)
            FilteredList = FilteredList.filter(function(coldboxItem) {
                return coldboxItem.SiteName == SiteName
            })
        }
        if(filterLevel!=null&&filterLevel!='') {
            FilteredList = FilteredList.filter(function(coldboxItem) {
                return coldboxItem.LevelName.toString() == filterLevel.toString()
            })
        }
        if(filterStatus!=null&&filterStatus!='') {
            FilteredList = FilteredList.filter(function(coldboxItem) {
                return coldboxItem.status.status.toString() == filterStatus.toString()
            })
        }

        this.setState({
            Filtered: FilteredList
        })
        this.props.UpdateList('filteredList', FilteredList)
    }

    render() {
        const {siteList, floorList, statusList, filterTxt, filterGroup, filterSite, filterLevel, filterStatus, loading} = this.state //groupList, 
        const _this = this
        const {coldboxList, GroupList} = this.props

        let searchbarcoldboxList = getColdboxInGroup(_this.props, filterGroup, coldboxList)

        return <div className="ColdboxIndi_head">
            <div className="ColdboxIndi_head_row">
                <div className="ColdboxIndi_backBtn" onClick={this.props.backPage}>
                    <div className="fa fa-arrow-left"></div>
                </div>
                <div className="ColdboxIndi_headTxt">Coldbox Individual Management</div>
            </div>
            <div className="ColdboxIndi_head_row">
                <div className="ColdboxIndi_headTxt">Search</div>
                <input list="coldboxseriallist" type="search" className="ColdboxIndi_headInput" placeholder="search text" onChange={(e) => _this.FilterOnchange('filterTxt', e)}></input>
                <datalist id="coldboxseriallist">
                    {
                        (searchbarcoldboxList==null)?<option></option>
                        :
                        searchbarcoldboxList.sort((a, b) => {
                            if (a.locName < b.locName) return -1
                            if (a.locName > b.locName) return 1
                            return 0
                        }).map((coldboxitem) => {
                            const levelName = (coldboxitem == null || coldboxitem.LevelName == null)?'':coldboxitem.LevelName
                            const locName = (coldboxitem == null || coldboxitem.locName == null)?'':coldboxitem.locName
                            return <option value={locName}>{levelName}</option>
                        })
                    }
                </datalist>
                <div className="ColdboxIndi_headTxt">Group</div>
                <select className="ColdboxIndi_select" onChange={(e) => _this.FilterOnchange('filterGroup', e)}>
                    <option value="" style={{background: 'grey'}}>All Group</option>
                    {
                        (GroupList==null||GroupList.groups==null||GroupList.groups.length==null)?
                        <div style={{display:'none'}}></div>
                        :
                        GroupList.groups.sort((a, b) => {
                            if (a.group_name < b.group_name) return -1
                            if (a.group_name > b.group_name) return 1
                            return 0
                        }).map(gpitem=> {
                            return <option value={gpitem._id} style={{background: 'grey'}}>{gpitem.group_name}</option>
                        })
                    }
                </select>
                <div className="ColdboxIndi_headTxt">Status</div>
                <select className="ColdboxIndi_select" onChange={(e) => _this.FilterOnchange('filterStatus', e)}>
                    <option value="" style={{background: 'grey'}}>All</option>
                    {
                        (statusList==null||statusList.length==null||statusList.length==0)?
                        <div style={{display: 'none'}}></div>
                        :
                        statusList.map(function(statusItem) {
                            return <option value={statusItem.value} style={{background: 'grey'}}>{statusItem.name}</option>
                        })
                    }
                </select>
                <div className="ColdboxIndi_headTxt">Site</div>
                <select className="ColdboxIndi_select" onChange={(e) => _this.FilterOnchange('filterSite', e)}>
                    <option value="" style={{background: 'grey'}}>All Site</option>
                    {
                        (siteList==null||siteList.length==null||siteList.length==0)?
                        <div style={{display:'none'}}></div>
                        :
                        siteList.map(function(siteItem) {
                            return <option value={siteItem} style={{background: 'grey'}}>{siteItem}</option>
                        })
                    }
                </select>
                <div className="ColdboxIndi_headTxt">Floor</div>
                <select className="ColdboxIndi_select" onChange={(e) => _this.FilterOnchange('filterLevel', e)}>
                    <option value="" style={{background: 'grey'}}>All Floor</option>
                    {
                        (floorList==null||floorList.length==null||floorList.length==0)?
                        <div style={{display:'none'}}></div>
                        :
                        floorList.map(function(levelItem) {
                            return <option value={levelItem} style={{background: 'grey'}}>{levelItem}</option>
                        })
                    }
                </select>
                <div className="ColdboxIndi_ReloadBtn" onClick={this.reload}>
                    <div className={loading?"fa fa-refresh fa-spin":"fa fa-refresh"}></div>
                </div>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        UserInfo: state.UserInfo,
        GroupList: state.GroupList
    }
}  
export default connect(mapStateToProps)(SearchBar)

function getSiteNameFromSiteLocName(siteLocName, UserInfo) {
    if(UserInfo==null) return '--'
    const siteList = UserInfo.locations
    const siteItem = siteList.find(item => {
        return item.imgUrl == siteLocName
    })
    return siteItem==null||siteItem.locName==null?'---':siteItem.locName.toString()
}

function getColdboxInGroup(props, filterGroup, coldboxList) {
    if(filterGroup==null||filterGroup==''||coldboxList==null) return coldboxList       //no need filtering

    const {GroupList} = props
    let groupSelected = GroupList.groups.find(item => item._id==filterGroup)

    if(groupSelected==null) return coldboxList
    let locList = groupSelected.members.map(item => item.locName)

    let result = []
    locList.forEach(locItem => {
        let findLocData = coldboxList.find(item => item.locName == locItem)
        if(findLocData!=null) result.push(findLocData)
    })

    return result
}