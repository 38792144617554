import { levelsForWaterPump } from "../../popupNotification/util"

const isWaterPumpAlarmItem = (alarmItem) => {
    if(alarmItem ==null) return
    const {locationInfo} = alarmItem
    if(locationInfo == null || locationInfo.length == null) return false
    const levelItem = locationInfo.find(item => item.lvl === 2)
    if(levelItem == null) return false
    const {id, name} = levelItem
    if(id == null || name == null) return false
    const locItem = locationInfo.find(item => item.lvl === 3)
    const locName = locItem == null?null:locItem.name

    const waterPumpLocNames = ['TCP-WP', 'CLP-WP', 'CHC-WP'];
    const isWaterPump = (
        levelsForWaterPump.includes(id) &&
        waterPumpLocNames.some(str => locName.includes(str)) &&
        alarmItem.name.includes('Water Leakage')
    )

    return isWaterPump?{
        ...alarmItem,
        name: 'Water Pump Alert',
        message: 'Water pump alert detected from sensor.'
    }
    :alarmItem
}

export {
    isWaterPumpAlarmItem
}