import React from 'react';
import './Mainbody.css';

//Redux Import
import { connect } from 'react-redux';

//Import image load item
import ReactImageAppear from 'react-image-appear';

//components import 
import LevelContainer from './Level/LevelContainer';
//import Sites from './Sites/Sites';
import GoogleMap from './Sites/googlemap'
import MainWithImage from './Sites/MainWithImage'
import Side from './Side/Side'

//Coldbox (CPSL), home page would be menu list rather than googlemap
import ColdboxManage from './Coldbox/coldboxManage'

import {getTxt} from './common/language/language'

class Mainbody extends React.Component {
    constructor(props) {
        super(props);
        //console.log(props);

        this.state = {IsDisplay: false}

        this.SitePicked = this.SitePicked.bind(this)
        this.buildingList = this.buildingList.bind(this)

        this.ActiveAlarmRequest = this.ActiveAlarmRequest.bind(this)
    }

    componentDidMount() {
        const thisoutside = this
        if(this.props.socket ==  null)
        {
            console.log('undefined socketIO, reconnect')
            this.props.dispatch({ type: 'SocketCon' })
        }
        else
        {
            //initial functions
            thisoutside.buildingList()
            console.log('create builing list X 1')
        }

        //save current url to redux
        let data = "/Main"
        this.props.dispatch({ type: 'LatestPage', data })

        //request Active Alarm socket
        const {UserInfo} = this.props
        this.ActiveAlarmRequest(UserInfo)
    }
    componentDidUpdate(preProps) {
        const {UserInfo} = this.props
        //console.log(UserInfo)
        const thisoutside = this
        if(UserInfo != null && preProps.UserInfo == null) thisoutside.ActiveAlarmRequest(UserInfo)   //request Active Alarm socket
    }
    ActiveAlarmRequest(UserInfo) {        
        const thisoutside = this
        //console.log(UserInfo)
        console.log('ActiveAlarmRequest() in MainBody.js')
        //get socketID from UserInfo
        if(UserInfo == null) return
        const userSocketID = UserInfo.socketId
        const userID = UserInfo.userID
        if(userSocketID == null || userID == null) return
        //socket request function
        const requestData = {
            "msgType": "ActiveAlarms",
            "socketId": userSocketID.toString(),
            "userID": userID.toString(),
        }
        console.log(requestData)
        //request the socket for Device Staticstics
        thisoutside.props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "AlarmRequest",
            EmitSocketData: requestData
        })
    }

    buildingList() {
        //console.log('buildinglist()');
        let thisoutside = this;
        let username = localStorage.getItem('useracc');
        let hashPassword = localStorage.getItem('userhashpassword');
        let authToken = localStorage.getItem('AuthToken');
        let sessionID = localStorage.getItem('SessionID');
        let data = {
            username: username,
            hashPassword: hashPassword,
            authToken: authToken,
            sessionID: sessionID
        };
        //Request Initial from server
        //this.props.socket.emit("InitialBuilding", data);
        thisoutside.props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "InitialBuilding",
            EmitSocketData: data
        });
    }

    SitePicked(object) {
        //console.log('SitePicked()');
        //console.log(object.target.value);   //this is the siteID
        //trigger to reload the level list
        this.props.dispatch({ type: 'PIckSite', data: object.target.value });
    }
    componentDidCatch(error, info)
    {
        console.error('componentDidCatch() on Mainbody.js');
        console.error(error);
        console.error(info);
        //redirect to App.js to reload all the datas
        this.props.history.push("/App");
    }

    render() {
        let { history } = this.props        //routing history from React Lib
        const {UserInfo} = this.props
        if(UserInfo == null) return <div></div>
        const landingPageType = UserInfo.landingPageType            //check if the user is showing image or googlemap
        const permission = UserInfo.permissions
        if(permission == null || permission.length == null) {
            return <div className="">UserInfo Permission is missing</div>
        }
        const findColdboxPermission = permission.find(function(item) {
            return item.collectionType == "Coldbox Manage" || item.collectionType == "Coldbox Location"
        })
        if(landingPageType != null && landingPageType == "Site Image") {
            //show image gui is returned
            return (
                <div className="body" key={ "MainbodyOutsideDiv" }>
                <div className="shadow-lg middle_div">
                    <MainWithImage history={this.props.history}/>
                <div className="flex flex-row overlay_body">
                    <div className="overlay_div1">
                        <div className="overlap_div1_background_color">
                            <div>
                                <p className="overlap_title">
                                    {getTxt('Main', 'company')}
                                </p>
                                <p className="overlap_subtitle">
                                    {getTxt('Main', 'iothub')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                </div>
            )
        }
        else if(findColdboxPermission != null) {
            return <div className="body" key={ "MainbodyOutsideDiv" }>
                <ColdboxManage history={history} />
            </div>
        }
        else {  //landingPageType == "Google Map"
            //show google map gui is returned
            return (            
                <div className="body" key={ "MainbodyOutsideDiv" }>
                    <div className="bg-white shadow-lg middle_div">
                        <GoogleMap history={this.props.history} />
                        <div className="flex flex-row overlay_body">
                            <div className="overlay_div1">
                                {ShowClientOrATALLogo(this.props)}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

//Redux inside component function
function mapStateToProps(state) {
    //console.log(state);
    return {
      count: state.count,
      log: state.log,
      socket: state.socket,
      level: state.level,
      currentlevel: state.currentlevel,
      SiteList: state.SiteList,
      currentSite: state.currentSite,
      MenuDisplay: state.MenuDisplay,
      UserInfo: state.UserInfo,
      configStorage: state.configStorage,
      DeviceDataLastUpdate: state.DeviceDataLastUpdate,
    };
}

export default connect(mapStateToProps)(Mainbody)

function ShowClientOrATALLogo(props) {
    const {UserInfo, configStorage} = props
    if(UserInfo==null) return <div>Exception: UserInfo undefined</div>
    if(UserInfo.permissions==null||UserInfo.permissions.length==null) return <div>Exception: UserInfo.permission is undefined</div>
    if(configStorage==null) return <div>Exception: configstorage undefined</div>

    const findPermItem = UserInfo.permissions.find(item => {
        return item.collectionType == 'ClientLogo'
    })
    if(findPermItem==null) {
        return <div className="newMainBottomLogo">
            <div className='left'>
                <div className="title">{getTxt('Main', 'company')}</div>
                <div className="subtitle">{getTxt('Main', 'iothub')}</div>
            </div>
            <div className='right'></div>
        </div>
        // return <div className="overlap_div1_background_color">
        //     <div>
        //         <p className="overlap_title">
        //             {getTxt('Main', 'company')}
        //         </p>
        //         <p className="overlap_subtitle">
        //             {getTxt('Main', 'iothub')}
        //         </p>
        //     </div>
        // </div>
    }

    const imgUrl = 'url("' + configStorage.imgUrl + "/assets/images/floors/" + findPermItem.imgKey + "/ClientLogo.jpg" + '")'
    return <div className='clientLogo'>
        <div className='bg' style={{backgroundImage: imgUrl, backgroundPosition: 'no-repeat', backgroundSize: 'contain'}}></div>
    </div>
}