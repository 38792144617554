import React, { useEffect, useState } from "react"
import { checkIfNotificationForWaterPump } from "../../popupNotification/util"

const ChinaChemTestFunction = props => {
    const [ result, setResult ] = useState(null)

    useEffect(() => {
        // const res = checkIfNotificationForWaterPump(notiInput, title, Msg)
        const res = resolvePositionOnClickCopy(locName, hardcodeProps)
        setResult(res)
    }, [])

    return <>
        <pre>{JSON.stringify(result, null, 2)}</pre>
    </>
}
export default ChinaChemTestFunction

// const title = 'alarm title Water Leakage'
// const Msg = 'Message content'
// const notiInput = {
//     "id": "649f3304d808660018c9532e",
//     "severity": "Normal",
//     "client_id": "62b932f0854329b83855b395",
//     "locationInfo": [
//         {
//             "id": "62b94ac6854329b83855b397",
//             "lvl": 1,
//             "name": "Two Chinachem Plaza"
//         },
//         {
//             "id": "62b94ac6854329b83855b401",
//             "lvl": 2,
//             "name": "19"
//         },
//         {
//             "id": "62b94ac6854329b83855b76b",
//             "lvl": 3,
//             "name": "WLS-19F-003"
//         }
//     ],
//     "alarmComponent": {
//         "type": "Device"
//     },
//     "alarmSettings_id": "62d3a012387c9dac342e9ad0",
//     "name": "Water Leakage Alert",
//     "message": "Water Leakage detected from sensor.",
//     "createdTime": "2023-06-30T19:54:44.195Z",
//     "locationData_id": "649ecd08d808660018b9b1be",
//     "__v": 0,
//     "clearParty": "System",
//     "clearTime": "2023-06-30T19:59:44.304Z",
//     "isClear": true
// }

const locName = 'CHC-WP-PWP-001'
const hardcodeProps = {
    DeviceData: [
        {
            "_id": "62947878e522c897b5d126dd",
            "coor": {
                "type": "Point",
                "coordinates": [
                    22.2820075,
                    114.1542571
                ]
            },
            "client_id": "62946f87e522c897b5d126dc",
            "locLvl": 1,
            "locIdx": 1,
            "locName": "Chinachem Hollywood Centre",
            "locName_zh-hk": null,
            "locDesc": "CHC",
            "locDesc_zh-hk": null,
            "img": "",
            "imgUrl": "CHC",
            "nestedLocs": [
                {
                    "_id": "62b96a54b677f61754b00671",
                    "locLvl": 2,
                    "locIdx": 1,
                    "locName": "B1",
                    "locDesc": "B1",
                    "equipments": [],
                    "nestedLocs": [
                        {
                            "_id": "62b96a54b678f61754b3057c",
                            "locLvl": 3,
                            "locIdx": 1,
                            "locName": "CHC-WP-FWF-001",
                            "locUI": {
                                "desc": "咸水泵故障",
                                "indicator": {
                                    "point": [
                                        {
                                            "type": "Point",
                                            "coordinates": [
                                                0.6415313,
                                                0.2415458
                                            ]
                                        }
                                    ],
                                    "color": "rgb(0, 208, 255)"
                                },
                                "type": "marker",
                                "color": "Purple"
                            },
                            "device_id": {
                                "_id": "6495420ceb6fab04559c20e7",
                                "client_id": "62946f87e522c897b5d126dc",
                                "deviceSerial": "7012018a55951d3e",
                                "deviceType_id": "62b97f62b677f61754b3057d",
                                "deviceName": "NVX-WPS",
                                "createdTime": "2022-07-13T02:24:43.651Z",
                                "updateTime": "2022-07-13T02:24:43.651Z",
                                "__v": 0
                            },
                            "payload_definition_id": {
                                "_id": "5fbe0a20df115e1f0cac33f4",
                                "payload": {
                                    "waterLeakageStatus": "var rs;if (typeof waterLeakageStatus !== 'undefined') {rs = waterLeakageStatus;};rs"
                                },
                                "name": "Water Leakage",
                                "nameID": "Water Leakage",
                                "createdTime": "2021-02-16T07:58:00.000Z",
                                "updateTime": "2021-04-28T09:04:12.371Z",
                                "cardTitle": "Water Leakage",
                                "displayedFields": [
                                    "waterLeakageStatus"
                                ],
                                "mayReturnEmptyObject": false
                            },
                            "payload": {
                                "lastTriggerTime": null,
                                "updateDate": "2023-07-12T02:10:27.126Z",
                                "waterLeakageStatus": 1
                            },
                            "lastUpdate": "2023-07-12T02:39:27.623Z",
                            "signal": {
                                "RSSI": -87,
                                "SNR": 10
                            },
                            "status": {
                                "status": "Healthy",
                                "batteryLevel": 3.6
                            }
                        },
                        {
                            "_id": "62b96a54b678f61754b3057d",
                            "locLvl": 3,
                            "locIdx": 2,
                            "locName": "CHC-WP-FWP-001",
                            "locUI": {
                                "desc": "咸水泵電源",
                                "indicator": {
                                    "point": [
                                        {
                                            "type": "Point",
                                            "coordinates": [
                                                0.6715313,
                                                0.2415458
                                            ]
                                        }
                                    ],
                                    "color": "rgb(0, 208, 255)"
                                },
                                "type": "marker",
                                "color": "Purple"
                            },
                            "device_id": {
                                "_id": "64954215eb6fab04559c20e8",
                                "client_id": "62946f87e522c897b5d126dc",
                                "deviceSerial": "7012018a55951d3f",
                                "deviceType_id": "62b97f62b677f61754b3057d",
                                "deviceName": "NVX-WPS",
                                "createdTime": "2022-07-13T02:24:43.651Z",
                                "updateTime": "2022-07-13T02:24:43.651Z",
                                "__v": 0
                            },
                            "payload_definition_id": {
                                "_id": "5fbe0a20df115e1f0cac33f4",
                                "payload": {
                                    "waterLeakageStatus": "var rs;if (typeof waterLeakageStatus !== 'undefined') {rs = waterLeakageStatus;};rs"
                                },
                                "name": "Water Leakage",
                                "nameID": "Water Leakage",
                                "createdTime": "2021-02-16T07:58:00.000Z",
                                "updateTime": "2021-04-28T09:04:12.371Z",
                                "cardTitle": "Water Leakage",
                                "displayedFields": [
                                    "waterLeakageStatus"
                                ],
                                "mayReturnEmptyObject": false
                            },
                            "payload": {
                                "lastTriggerTime": null,
                                "updateDate": "2023-07-12T02:10:28.324Z",
                                "waterLeakageStatus": 0
                            },
                            "lastUpdate": "2023-07-12T02:40:33.505Z",
                            "signal": {
                                "RSSI": -84,
                                "SNR": 8.2
                            },
                            "status": {
                                "status": "Healthy",
                                "batteryLevel": 3.6
                            }
                        },
                        {
                            "_id": "62b96a54b678f61754b3057e",
                            "locLvl": 3,
                            "locIdx": 3,
                            "locName": "CHC-WP-PWF-001",
                            "locUI": {
                                "desc": "食水泵故障",
                                "indicator": {
                                    "point": [
                                        {
                                            "type": "Point",
                                            "coordinates": [
                                                0.6415313,
                                                0.2715458
                                            ]
                                        }
                                    ],
                                    "color": "rgb(0, 208, 255)"
                                },
                                "type": "marker",
                                "color": "Purple"
                            },
                            "device_id": {
                                "_id": "6495421deb6fab04559c20e9",
                                "client_id": "62946f87e522c897b5d126dc",
                                "deviceSerial": "7012018a55952063",
                                "deviceType_id": "62b97f62b677f61754b3057d",
                                "deviceName": "NVX-WPS",
                                "createdTime": "2022-07-13T02:24:43.651Z",
                                "updateTime": "2022-07-13T02:24:43.651Z",
                                "__v": 0
                            },
                            "payload_definition_id": {
                                "_id": "5fbe0a20df115e1f0cac33f4",
                                "payload": {
                                    "waterLeakageStatus": "var rs;if (typeof waterLeakageStatus !== 'undefined') {rs = waterLeakageStatus;};rs"
                                },
                                "name": "Water Leakage",
                                "nameID": "Water Leakage",
                                "createdTime": "2021-02-16T07:58:00.000Z",
                                "updateTime": "2021-04-28T09:04:12.371Z",
                                "cardTitle": "Water Leakage",
                                "displayedFields": [
                                    "waterLeakageStatus"
                                ],
                                "mayReturnEmptyObject": false
                            },
                            "payload": {
                                "lastTriggerTime": null,
                                "updateDate": "2023-07-11T03:48:37.093Z",
                                "waterLeakageStatus": 1
                            },
                            "lastUpdate": "2023-07-12T02:58:30.639Z",
                            "signal": {
                                "RSSI": -90,
                                "SNR": 8.5
                            },
                            "status": {
                                "status": "Healthy",
                                "batteryLevel": 3.6
                            }
                        },
                        {
                            "_id": "62b96a54b678f61754b3057f",
                            "locLvl": 3,
                            "locIdx": 4,
                            "locName": "CHC-WP-PWP-001",
                            "locUI": {
                                "desc": "食水泵電源",
                                "indicator": {
                                    "point": [
                                        {
                                            "type": "Point",
                                            "coordinates": [
                                                0.6715313,
                                                0.2715458
                                            ]
                                        }
                                    ],
                                    "color": "rgb(0, 208, 255)"
                                },
                                "type": "marker",
                                "color": "Purple"
                            },
                            "device_id": {
                                "_id": "64954225eb6fab04559c20ea",
                                "client_id": "62946f87e522c897b5d126dc",
                                "deviceSerial": "7012018a55952060",
                                "deviceType_id": "62b97f62b677f61754b3057d",
                                "deviceName": "NVX-WPS",
                                "createdTime": "2022-07-13T02:24:43.651Z",
                                "updateTime": "2022-07-13T02:24:43.651Z",
                                "__v": 0
                            },
                            "payload_definition_id": {
                                "_id": "5fbe0a20df115e1f0cac33f4",
                                "payload": {
                                    "waterLeakageStatus": "var rs;if (typeof waterLeakageStatus !== 'undefined') {rs = waterLeakageStatus;};rs"
                                },
                                "name": "Water Leakage",
                                "nameID": "Water Leakage",
                                "createdTime": "2021-02-16T07:58:00.000Z",
                                "updateTime": "2021-04-28T09:04:12.371Z",
                                "cardTitle": "Water Leakage",
                                "displayedFields": [
                                    "waterLeakageStatus"
                                ],
                                "mayReturnEmptyObject": false
                            },
                            "payload": {
                                "lastTriggerTime": null,
                                "updateDate": "2023-07-12T02:58:30.721Z",
                                "waterLeakageStatus": 1
                            },
                            "lastUpdate": "2023-07-12T02:58:30.710Z",
                            "signal": {
                                "RSSI": -100,
                                "SNR": 11
                            },
                            "status": {
                                "status": "Healthy",
                                "batteryLevel": 3.6
                            }
                        }
                    ]
                },
            ]
        }
    ]
}


const resolvePositionOnClickCopy = (locName, props) => {
    // props.dispatch({ type: 'FloorPlanBottomMenu', data: 'bottom2' })

    const {DeviceData} = props
    const locType = locName.includes('TCP-WP')?'TCP-WP':locName.includes('CLP-WP')?'CLP-WP':'CHC-WP'
    const siteIds = {
        'TCP-WP': '62b94ac6854329b83855b397',
        'CLP-WP': '62b96a54b677f61754b30571',
        'CHC-WP': '62947878e522c897b5d126dd',
    }
    const findSite = siteIds[locType]
    const levelIds = {
        'TCP-WP': '62b94ac6854329b83855b398',
        'CLP-WP': '62b96a54b677f61754b00571',
        'CHC-WP': '62b96a54b677f61754b00671',
    }
    const findLevel = levelIds[locType]
    const locs = DeviceData.find(item => item._id == findSite)
        .nestedLocs.find(item => item._id == findLevel)
        .nestedLocs.filter(item => item.locName.includes(locType))
    
    const cardIndex = locs.findIndex(item => item.locName === locName)

    return {
        locs: locs.map(item => item.locName),
        cardIndex
    }
    // props.dispatch({
    //     type: 'bottomcardhover',
    //     data: 'bottomcard_' + cardIndex}
    // )
    // props.dispatch({ type: 'PickPolygon', 
    //     data: locName
    // })
}