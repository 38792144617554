import React from 'react'
import './Notification.css'                 //css of datepicker
import './Notification2.css'                //css of the page (header only)
import './Notification3.css'                //css for the page (table)
import { connect } from 'react-redux'
//libraries
import DatePicker from 'react-datepicker'
import Table from './Table'
import SoundPlay from '../../Sound/Sound'
import {getTxt} from '../common/language/language'
import {GetSiteName, GetLvlName} from '../common/language/locDisplay'

class Notification extends React.Component {
    constructor(props){ 
        super(props)

        this.setStartTime = this.setStartTime.bind(this)
        this.setEndTime = this.setEndTime.bind(this)
        this.searchAlarm = this.searchAlarm.bind(this)     //search button request
        this.convertDate = this.convertDate.bind(this)
        this.pickSite = this.pickSite.bind(this)           //pick the site when clicked on select>option
        this.pickLevel = this.pickLevel.bind(this)         //pick the site when clicked
        //search bar filter functions
        this.pickType = this.pickType.bind(this)
        this.pickStatus = this.pickStatus.bind(this)
        this.searchText = this.searchText.bind(this)
        this.previouspage = this.previouspage.bind(this)

        this.state = {
            startdate: new Date(),
            enddate: new Date(),
            LoadingAnimation: false,
            currentSite: "all",          //use site.locName string
            currentLevel: "all",        //level.locName string

            //filter alarms variables
            searchString: "",           //Default value is ""
            filterStatus: "all",
            filterType: "all",
        }
        this.soundRef = React.createRef()
    }

    pickType(event) {
        this.setState({filterType: event.target.value})
    }
    pickStatus(event) {
        this.setState({filterStatus: event.target.value})
    }
    searchText(event) {
        this.setState({searchString: event.target.value})
    }

    setStartTime(data) {
        this.setState({startdate: data})
    }

    setEndTime(data) {
        this.setState({enddate: data})
    }

    pickSite(event) {
        const pickedItemValue = event.target.value
        this.setState({currentSite: pickedItemValue})
    }
    pickLevel(event) {
        const pickedLevelItem = event.target.value
        this.setState({currentLevel: pickedLevelItem})
    }

    componentDidMount() {
        //save current url to redux
		const pathUrl = "/Main/Notification"
        this.props.dispatch({ type: 'LatestPage', data: pathUrl })
        
        //set the date time picker
        let startDate = new Date()
        startDate.setMonth(startDate.getMonth()-1)
        this.setState({
            startdate: startDate,      //7 days before today
        })

        //request for the alarm from socket
        const _this = this
        this.props.dispatch({ type: 'Notification', data: null})   //clear the redux storage of notification
        setTimeout(
            function() {_this.searchAlarm()},
            500
        )

        // get the web url
        const webUrl = window.location.href
        // check if contain 'new' or 'ack'
        const ifNew = webUrl.includes("new")
        const ifAck = webUrl.includes("ack")
        // set the local state to filter alarm status
        if(ifNew) _this.setState({filterStatus: "newAlarm"})
        else if(ifAck) _this.setState({filterStatus: "acknowledged"})
    }
    convertDate(DateInput) {
        /*let DateMonth = DateInput.getMonth() + 1;
        let DateDay = DateInput.getDate();
        if(DateMonth < 10)
        {
            DateMonth = "0" + DateMonth;
        }
        if(DateDay < 10)
        {
            DateDay = "0" + DateDay;
        }*/
        //time item, which need to update to HK timezone
        let RawTimestamp = DateInput
        //add 8 hours
        //RawTimestamp.setHours( RawTimestamp.getHours() + 8 );
        //convert to IOS string Date
        var Output = RawTimestamp.toISOString()

        //let DateStr = DateInput.getFullYear() + "-" + DateMonth + "-" + 
        //DateDay + "T16:00:00Z";
        /*console.log({
            input: DateInput,
            output: Output
        });*/
        return Output
    }
    searchAlarm() {
        this.props.dispatch({ type: 'Notification', data: null})   //clear the redux storage of notification
        //get all the input value
        const {startdate, enddate, currentSite, currentLevel} = this.state
        const {UserInfo} = this.props
        const _this = this
        //check if the input are valid
        if(UserInfo==null || startdate==null || enddate==null || currentSite==null || currentLevel==null) {
            console.log('some input are missing! Notification.js searchAlarm()')
            return
        }
        //set request variables
        const startDateStr = this.convertDate(startdate)
        const EndDateStr = this.convertDate(enddate)

        const EmitUserID = UserInfo.userID
        let LocationID = ""
        let locLvl                  //1-3, all, site, level, location
        
        let requestData = {}
        if(currentSite == "all") {
            //search for all site
            locLvl = 1
            requestData = {
                "msgType": "AlarmList",
                "userID": EmitUserID,
                "timeConstraint": {
                      "start": startDateStr,
                      "end": EndDateStr,
                }
            }
        }
        else if(currentLevel == "all") {
            //searh for all records in one site
            locLvl = 1
            let pickedSite = UserInfo.locations.find(function(locationItem){
                return locationItem.locName == currentSite
            })
            LocationID = pickedSite._id
            requestData = {
                "msgType": "AlarmList",
                //"socketId": "8SpxJb0PqsT0zcboAAEa",
                "userID": EmitUserID,
                "timeConstraint": {
                      "start": startDateStr,
                      "end": EndDateStr,
                },
                "locationConstraint": {
                  "locLvl": locLvl,                      // 1 - Site / 2 - Floor / 3 - Location
                  "id": LocationID  // location id
                }
            }
        }
        else {
            //search records on single floor
            locLvl = 2
            let pickedSite = UserInfo.locations.find(function(locationItem){
                return locationItem.locName == currentSite
            })
            let pickedLevel = pickedSite.nestedLocs.find(function(levelItem){
                return levelItem.locName == currentLevel
            })
            if(pickedLevel != null) {
                if(pickedLevel._id != null) LocationID = pickedLevel._id
                else alert('pickedLevel._id null, Notification.js')
            }
            else alert('pickedLevel null, Notification.js')

            requestData = {
                "msgType": "AlarmList",
                //"socketId": "8SpxJb0PqsT0zcboAAEa",
                "userID": EmitUserID,
                "timeConstraint": {
                      "start": startDateStr,
                      "end": EndDateStr,
                },
                "locationConstraint": {
                  "locLvl": locLvl,                      // 1 - Site / 2 - Floor / 3 - Location
                  "id": LocationID  // location id
                }
            }
        }
        console.log(requestData)
        this.props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "AlarmRequest",
            EmitSocketData: requestData
        })
        this.setState({LoadingAnimation: true})
        setTimeout(() => {
            _this.setState({
                LoadingAnimation: false
            })
        }, 2000)
    }

    componentDidCatch(error, info) {
        console.error('componentDidCatch() on Notification.js')
        console.error(error)
        console.error(info)
        //redirect to App.js to reload all the datas
        this.props.history.push("/App")
    }
    previouspage() {
        this.props.history.goBack()   //redirect to previous page
    }

    render() {
        let LoadingClass = ""
        const {LoadingAnimation, currentSite} = this.state
        const {UserInfo} = this.props
        let pickedLevel = []
        if(currentSite != "all") {
            const PickedSite = UserInfo.locations.find(function(siteItem){
                return siteItem.locName == currentSite
            })
            pickedLevel = PickedSite.nestedLocs
        }
        if(UserInfo == null) return <div>..</div>
        if(LoadingAnimation) LoadingClass = "fa-spin"
        return <div className="shadow-lg Notification_maindiv">
            <div className="Notification_backBtn" onClick={this.previouspage}>
                <div className="fa fa-arrow-left"></div>
            </div>
            <div className="shadow-lg Notification_header">
                <div className="fa fa-bell Notification_icon"></div>
                <div className="Notification_searchdiv">
                    <input className="Notification_searchinput" placeholder='Alarm / Message / Sensor' onChange={this.searchText}></input>
                    <div className="fa fa-search Notification_searchicon"></div>
                </div>
                <div className="Notification_searchOptiondiv">
                    <div className="Notification_txt">{getTxt('Notification', 'type')}:</div>
                    <select className="Notification_searchOption" onChange={this.pickType}>
                        <option value="all" style={{background: 'grey'}}>
                            {getTxt('Notification', 'all')}
                        </option>
                        <option value="system" style={{background: 'grey'}}>
                            {getTxt('Notification', 'system')}
                        </option>
                        <option value="equipment" style={{background: 'grey'}}>
                            {getTxt('Notification', 'equip')}
                        </option>
                        <option value="device" style={{background: 'grey'}}>
                            {getTxt('Notification', 'device')}
                        </option>
                    </select>
                </div>
                <div className="Notification_searchStatus">
                    <div className="Notification_searchStatus_title">
                        {getTxt('Notification', 'status')}:
                    </div>
                    <select className="Notification_searchStatusOption" onChange={this.pickStatus}>
                        <option value="all" style={{background: 'grey'}}>
                            {getTxt('Notification', 'all')}
                        </option>
                        <option value="acknowledged" style={{background: 'grey'}}>
                            {getTxt('Notification', 'ack')}
                        </option>
                        <option value="newAlarm" style={{background: 'grey'}}>
                            {getTxt('Notification', 'newAlarm')}
                        </option>
                    </select>
                </div>
                <div className="Notification_searchDaterange">
                    <div className="Notification_txt">{getTxt('Notification', 'from')}:</div>
                    <div className="Notification_startdatetimepicker">
                        <DatePicker
                            selected={this.state.startdate}
                            onChange={this.setStartTime}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="d-MMMM yyyy HH:mm"
                            timeCaption="time"
                        />
                    </div>
                    <div className="Notification_txt">{getTxt('Notification', 'to')}:</div>
                    <div className="Notification_enddatetimepicker">
                        <DatePicker
                            selected={this.state.enddate}
                            onChange={this.setEndTime}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="d-MMMM yyyy HH:mm"
                            timeCaption="time"
                        />
                    </div>
                    <div className="Notification_searchOptiondiv">
                        <div className="Notification_txt">{getTxt('Notification', 'site')}:</div>
                        <select className="Notification_searchOption" onChange={this.pickSite}>
                            <option value="all" style={{background: 'grey'}}>
                                {getTxt('Notification', 'all')}
                            </option>
                            {
                                UserInfo.locations.map(function(SiteItem){
                                return <option value={SiteItem.locName} style={{background: 'grey'}}>
                                    {GetSiteName(SiteItem)}
                                </option>
                                })
                            }
                        </select>
                    </div>
                    <div className="Notification_searchOptiondiv">
                        <div className="Notification_txt">{getTxt('Notification', 'level')}:</div>
                        <select className="Notification_searchOption" onChange={this.pickLevel}>
                            <option value="all" style={{background: 'grey'}}>
                                {getTxt('Notification', 'all')}
                            </option>
                            {
                                pickedLevel.map(function(levelItem){
                                    return <option value={levelItem.locName} style={{background: 'grey'}}>
                                        {levelItem.locName}
                                    </option>
                                })
                            }
                        </select>
                    </div>
                    <div className="NotificationRefreshBtn" onClick={this.searchAlarm}>
                        <div className={"fa fa-refresh "+ LoadingClass}></div>
                    </div>
                </div>
            </div>
            <div className="Notification_table">
                {<Table 
                    //variables for filtering
                    filterStatus={this.state.filterStatus} filterType={this.state.filterType} searchString={this.state.searchString}
                    //function to refresh the page (when acknowledge complete and need refresh in Popup.js)
                    searchAlarm={this.searchAlarm}
                    // site and level filter
                    siteFilter={this.state.currentSite} floorFilter={this.state.currentLevel}
                    history={this.props.history}
                />}
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        socket: state.socket,
        level: state.level,
        currentlevel: state.currentlevel,
        currentSite: state.currentSite,
        MenuDisplay: state.MenuDisplay,
        UserInfo: state.UserInfo,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate,
    }
}
export default connect(mapStateToProps)(Notification)