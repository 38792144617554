import React from 'react'
import './card.scss'

const DynamicCard = props => {
    const {Config} = props
    const size = GetCardSize(Config)
    const {title, subTitle} = GetCardTitle(Config)
    return <div>
        {JSON.stringify(Config)}
        <div className='newcardItem' style={size}>
            <div className='title' style={title}>Title</div>
            <div className='subtitle' style={subTitle}>Sub title</div>
        </div>
    </div>
}
export default DynamicCard

const GetCardSize = Config => {
    const size = Config.size
    const {padding, aspectRatio, readingRow_height} = size
    return {
        width: readingRow_height * aspectRatio + 'rem',
        height: readingRow_height + 'rem',
        padding: padding + 'px'
    }
}
const GetCardTitle = Config => {
    const title = Config.title
    const {
        titleSize,
        titleColor,
        subtitleSize,
        subtitleColor
    } = title
    return {
        title: {
            fontSize: titleSize + 'px',
            color: titleColor
        },
        subTitle: {
            fontSize: subtitleSize + 'px',
            color: subtitleColor
        }
    }
}