import React from 'react'
import './popup.css'

//Redux Import
import { connect } from 'react-redux'

class Popup extends React.Component {
    constructor(props)
    {
        super()
    }

    render()
    {
        const {isPopup} = this.props

        return [isPopup?
        <div className="Coldbox_GD_popupBG" onClick={this.props.closePopup}></div>:<div></div>,
        isPopup?
        <div className="Coldbox_GD_popupMain">
            <div className="header">C00001</div>
            <div className="main">
                <div className="zone">CT1 Zone A</div>
                <div className="Hours">
                    <div className="hourreading">
                        11
                        <div className="hourunit">hrs</div>
                    </div>
                    <div className="hourStatus">
                        loaded
                    </div>
                </div>
                <div className="temp">
                    <div className="Left">
                        <div className="top">
                            <div className="tempReading">
                                3<div className="tempUnit">C</div>
                            </div>
                            <div className="tempIcon">
                                <div className="fa fa-thermometer-half"></div>
                            </div>
                        </div>
                        <div className="bottom">set</div>
                    </div>
                    <div className="Right">
                        <div className="top">
                            <div className="tempReading">
                                4.3<div className="tempUnit">C</div>
                            </div>
                            <div className="tempIcon">
                                <div className="fa fa-thermometer-half"></div>
                            </div>
                        </div>
                        <div className="bottom">current</div>
                    </div>
                </div>
                <div className="humid">
                    <div className="humidReading">
                        56<div className="humidUnit">%</div>
                    </div>
                    <div className="humidIcon">
                        <div className="fa fa-tint"></div>
                    </div>
                </div>
            </div>
        </div>
        :<div></div>]
    }
}

function mapStateToProps(state) {
    return {
    }
}
  
export default connect(mapStateToProps)(Popup)