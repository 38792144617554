import React from 'react'
import ValueCard from '../valueCard/valueCard'

const ToiletPaperCard = props => {
    const {item, isFocus, hoverFunc, cardnumber} = props

    return <ValueCard
        dataFields = {dataFields}
        deviceItem = {item}
        isFocus={isFocus}
        hoverFunc={hoverFunc}
        cardnumber={cardnumber}
    />
}
export default ToiletPaperCard

const dataFields = [
    {
        isBool: true,
        strKey: 'hasPaper',
        trueDisplay: 'Normal',
        falseDisplay: 'No Paper',
        icon: 'sticky-note',
        iconColor: 'teal',
        iconSize: 25,   //px
        fontSize: [25, 25, 30],
        colorTrue: 'teal',
        colorFalse: 'red'
    }
]