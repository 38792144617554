import React from 'react'
import './DoorStatus.css'
import { connect } from 'react-redux'

class DoorStatus extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        //FIND DEVICE ITEM
        const {DeviceData, currentSite, currentlevel, UserInfo, currentlevelID} = this.props;
        if(currentlevel == null || currentSite == null || DeviceData == null) return <div></div>        //exception case
        const FindSite = DeviceData[currentSite]
        if(FindSite == null) return <div></div>             //2nd exceptional case
        const FindLevel = FindSite.nestedLocs.find(function(levelItem) {
            return (levelItem.locName == currentlevel) || (levelItem.locName == currentlevel.toString())
        })
        // console.log(FindLevel)
        const Payload = FindLevel.nestedLocs[0].payload
        // console.log(Payload)

        //IAQ ADN FLOOR DETECTION READINGS
        let isDoorClose = true
        FindLevel.nestedLocs.forEach(deviceItem => {
            if(deviceItem.payload != null) {
                const DevicePayload = deviceItem.payload
                if(DevicePayload.doorClose != null) isDoorClose = DevicePayload.doorClose
            }
        })
        let DoorStatusClass = ""
        // let DoorStatusDisplayText = "Doors Closed"
        if(!isDoorClose) {
            DoorStatusClass = "LiftDoorOpenStatus"
            // DoorStatusDisplayText = "Doors Open"
        }

        return <div className="Lift_door_main">
            <div className="Lift_graphTitle">
                Door Status
            </div>
            <div className="Lift_graphStatusBackground">
                <div className={"Lift_graphStatusDoorItem_L " + DoorStatusClass}></div>
                {/* <div className="Lift_graphStatusDoorDisplay">
                    {DoorStatusDisplayText}
                </div> */}
                <div className='LiftDr_switch'>
                    <div className={!isDoorClose?'open current':'open'}>Open</div>
                    <div className={isDoorClose?'close current':'close'}>Close</div>
                </div>
                <div className={"Lift_graphStatusDoorItem_R " + DoorStatusClass}></div>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        socket: state.socket,
        UserInfo: state.UserInfo,
        currentSite: state.currentSite,
        currentlevel: state.currentlevel,
        DeviceData: state.DeviceData,
        currentlevelID: state.currentlevelID,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate
    }
}
export default connect(mapStateToProps)(DoorStatus)