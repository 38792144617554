import React from 'react'
import { connect } from 'react-redux'
import './SiteLevelPicker.css'
//2 inner components are reqiured
import PickSiteWidget from './pickSiteWidget'
import PickLevelWidget from './pickLevelWidget'

import SiteLevelPicker_single from './SiteLevelPicker_single/SiteLevelPicker_single'

//this is the header (full width)
//user can pick the building and level
class SiteLevelPicker extends React.Component {
    constructor(props) {
        super(props)

        this.OpenSiteList = this.OpenSiteList.bind(this)
        this.OpenLevelList = this.OpenLevelList.bind(this)
        this.closePopups = this.closePopups.bind(this)
        this.closePopupsLevel = this.closePopupsLevel.bind(this)

        this.state = {
            isPickSite: false,
            isPickLevel: false
        }
    }
    OpenSiteList() {
        this.setState({
            isPickSite: true
        })
    }
    OpenLevelList() {
        this.setState({
            isPickLevel: true
        })
    }
    closePopups() {
        this.setState({
            isPickSite: false
        })
    }
    closePopupsLevel() {
        this.setState({
            isPickLevel: false
        })
    }
    render() {
        const {currentSite, SiteList, UserInfo, DeviceData} = this.props

        let currentSite_Name = ''
        let site_Desc = ''
        try {
            currentSite_Name = UserInfo.locations[currentSite].locName;
            site_Desc = UserInfo.locations[currentSite].locDesc
            if(this.state.isPickSite) {
                //if showing the droplsit for select site, hdie the current site name
                currentSite_Name = ""
            }
        }
        catch(error) {
            currentSite_Name = "Loading"
        }

        if(isSingleSite(DeviceData) == true) return <SiteLevelPicker_single />

        return <div className="pickLevelSite_outside">
            <div className="pickLevelSite_outside_head">
                <div className="WidgetRow">
                    <i className="fa fa-building headericon"></i>
                    <div className="pickLevelSite_SiteTxt">
                        <div onClick={this.OpenSiteList}>
                            {currentSite_Name}
                        </div>
                        <PickSiteWidget isShow={this.state.isPickSite} closePopup={this.closePopups}/>
                    </div>
                    <button className="pickLevelSite_PickSiteBtn" onClick={this.OpenSiteList}>Other Site</button>

                    <div className="pickLevelSite_DescTxt"> 
                    {site_Desc}
                    </div>
                </div>
                <div className="WidgetRow">
                    <button className="pickLevelSite_PickLevelBtn" onClick={this.OpenLevelList}>Other Level</button>
                    <div className="pickLevelSite_LevelTxt" onClick={this.OpenLevelList}>
                        {this.props.currentlevel}
                        {(UserInfo.client_id ==="6006850161a378359cb738bf" ? "" : "/F")}
                    </div>
                </div>
            </div>
            <PickLevelWidget isShow={this.state.isPickLevel} closePopup={this.closePopupsLevel}/>
        </div>
    }
}

function mapStateToProps(state) {
    return {
      level: state.level,
      currentlevel: state.currentlevel,
      currentSite: state.currentSite,
      SiteList: state.SiteList,
      UserInfo: state.UserInfo,
      DeviceData: state.DeviceData
    }
}
export default connect(mapStateToProps)(SiteLevelPicker)

const isSingleSite = (DeviceData) => {
    if(DeviceData==null || DeviceData.length==null) return
    if(DeviceData.length != 1) return false                 //more than one site
    if(DeviceData[0].nestedLocs == null || DeviceData[0].nestedLocs.length == null) return
    if(DeviceData[0].nestedLocs.length > 26) return false   // too many floors to display

    return true
}