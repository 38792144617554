import React from 'react';
import BottomDisplay from './BottomDisplay/BottomDisplay';
import MenuListItem from './MenuListItem';

//Redux Import
import { connect } from 'react-redux';

class MenuList extends React.Component {
    constructor(props) {
        super(props);

        this.PickedItem = this.PickedItem.bind(this);
    }

    PickedItem(EventID) {
        //console.log('Called from MenuListItem, EventID:' + EventID);
        this.props.dispatch({ type: 'FloorPlanBottomMenu', data: EventID });
    }

    render() {
        return <div className="w-full flex flex-row">
            <div className="consolelist_outside">
                <div className="flex flex-row consolelist_head">
                    <i className="fa fa-building menucardicon"></i>
                    <div className="text-left menucardtitle">  
                        IoT Smart Console
                    </div>
                </div>
                <div className="consolelist_inside">
                    <MenuListItem title={"IAQ"} name={"bottom1"} pickeditem={ this.PickedItem } />
                    <MenuListItem title={"People Counting"} name={"bottom2"} pickeditem={ this.PickedItem } />
                    <MenuListItem title={"Power Consumption"} name={ "bottom3" } pickeditem={ this.PickedItem } />
                    <MenuListItem title={"Smart Litter Bin"} name={ "bottom4" } pickeditem={ this.PickedItem } />
                </div>
            </div>
            <div className="bottom_outside">
                <BottomDisplay/>
            </div>
        </div>
    }
}


//Redux inside component function
function mapStateToProps(state) {
    return {
      count: state.count,
      log: state.log,
      socket: state.socket,
      level: state.level,
      currentlevel: state.currentlevel,
      SiteList: state.SiteList,
      currentSite: state.currentSite,
      MenuDisplay: state.MenuDisplay,
      currentBottomMenu: state.currentBottomMenu,
      currentBottomCard: state.currentBottomCard
    };
}

export default connect(mapStateToProps)(MenuList);  