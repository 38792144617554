import React from 'react'
import './alertbox.css'
import { faExclamationTriangle, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Alertbox extends React.Component {
    render() {
        const {isShow, msg, closealertbox} = this.props
        if(!isShow) return<div></div>  //not show the alert box
        //show alert box
        return [<div className="LoginAlertBox">
            <FontAwesomeIcon icon={faTimes} className='login_close' onClick={closealertbox}/>
            <div className='center'>
                <FontAwesomeIcon icon={faExclamationTriangle} className='login_alerticon' />
                <div className="login_alertmsg">{msg}</div>
                <div className='btn' onClick={closealertbox}>Close</div>
            </div>
        </div>,
        <div className="loginalert_darkbg" onClick={closealertbox}></div>
        ]
    }
}

export default Alertbox