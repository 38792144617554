import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import TestPage from './TestPage/TestPage'
import DragLayoutV2 from './TestPage/draglayout2/draglayout'
import GetCoor from './TestPage/GetCoor/GetCoor'
import DynamicCardMaker from './TestPage/dynamicCard/dynamicCard'

//Import pages
import Login from './Main/Login/Login'
import Main from './Main/Main'
import ForgetPassword from './forgetPassword'
import ResetPassword from './resetPassword'
import CentralPublic from './CentralPublic/CentralSummary'
import CCGPublic from './CCGPublic/public'
import PublicPage from './NinaPublic/public'

//Default exist item
import * as serviceWorker from './serviceWorker'

//Routing lib
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
//ReactDOM.render(<App />, document.getElementById('root'));    //old render function

//import Redux
import { Provider } from 'react-redux'
import { createStore } from 'redux'

//import socketio client
import webSocket from 'socket.io-client'

//My Css lib
import './lib/tailwind.css'

//font-awesome css
import '../node_modules/font-awesome/css/font-awesome.css'

//import default config, which is the config settings apply when no config.json file is found
import { DefaultConfig } from './DefaultConfig'
import updateDeviceDataLocation from './Main/common/changeLoc/CPSL_updateLoc'
import LocationData from './Main/common/redux/locationdata'

const initialState = {    //This is the redux initial values
  socketConnect: false,
  level: [],            //not using, but stored level item
  currentlevel: 1,
  currentlevelID: null,
  currentSite: 0,
  MenuDisplay: false,
  currentBottomMenu: "bottom0",
  currentBottomCard: "bottomcard_-1",
  ShowLevelPopUp: false,
  LatestPage: "/",      //check the latest using page when redirect from App.js
  UserInfo: null,       //real initial data
  configStorage: null,  //save config.json settings
  DeviceData: null,     //real data
  DeviceDataStatus: 1,  //1 is waiting data onload
  DeviceDataLastUpdate: null, //update this props value to trigger display update (update inner keys would not trigger render())
  SystemAlerts: null,    //real data (Realtime Data show on slide (incomplete))
  Notification: null,    //Notification that show on table (Search returned result, storage in here)
  ImgStorage: null,      //re-loaded images
  Polygons: null,        //use to identify which polygon to highlight when hover on bottomCards
  PickedPolygonLoc: null,
  StatisticData: {      //store all the DeviceStatistic data in Statistic page
    co2: null,
    pm25: null,
    temp: null,
    humid: null,
    power: [],
    illuminance: null,
    //each reading type have location to store data
    loading: true,   //this value is updated when data is onload
    lift: null,     //this is the place to store the Lift statistic datas
  },
  StatHeatmapCO2: null,   //store statistic > heatmap CO2
  StatHeatmapTH: null,    //store statistic > heatmap temp/humid
  StatSpider: null,       //store statistic > SpiderChart (temp/humid)
  DashboardPower: null,   //store for Dashboard > power summary card (previous month value)
  DashboardPower2: null,  //previous month power value
  EmitSocketStr: "",   //DEBUG USE show emitted socket on bottom alert popup (inside navbar.js)
  UserManageInfo: null, //user management page, store the user list, company list and site list
  UserManageUserList: null,  //user management page, store the displaying user list in table
  triggerAlarmList: null,    // the alarm trigger list get from server
  // DashboardPickedColdBoxID:"",
  DeviceManageList: null,   //Coldbox Individual Management page Usage, store the DeviceManage 'READ' List
  BeaconManageList: null,
  ZoneList: null, //CPSL
  LiftAlarmStatistic: null,
  GroupList: null,
  AuditTrialList: null,
  EquipmentList: null, //Coldbox LoraWANGateway Management page Usage, store the EquipmentList 'READ' List
  ToiletType: 1,      //1,2,3
}

//for socketio connection item ( connection triggered in reducer() )
let socket
let configStorage = DefaultConfig     //set the DefaultConfig.js 's default reading to it

function reducer(state = initialState, action) {
  //console.log(action.type + '()')
  state.socket = socket

  let UpdatedDatetime = new Date()
  switch (action.type) {
    case 'SocketCon':
      //Onload Config
      try {
        // console.log('try get config reading')
        configStorage = window.IoTConfig   //config.json file > setting parameters to store in redux, if failed, use the avobe DefaultConfig.js parameters
        console.log('config reading:')
        console.log(configStorage)
      }
      catch (err) {
        console.error('Fail to read config file (index.js) --When using npm run start, would read the hardcode default config--')
      }
      if (configStorage == null) configStorage = DefaultConfig
      let socketConnectUrl = configStorage.socketUrl

      //Connect OR Reconnect
      try {
        if (false) {
          socket.disconnect();
        }
        socket = webSocket(socketConnectUrl, {
          withCredentials: false,
          cors: {
            origin: socketConnectUrl,//socketConnectUrl,
            methods: ["GET", "POST"],
            credentials: false
          }
        })

        socketListener(socket)
      }
      catch (err) {
        console.error('Re connect socket failed');
        console.error(err);
      }
      return {
        ...state,
        configStorage: configStorage,
        socket: socket,
        socketConnect: true
      }
    case 'PickLevel':
      //return state; //can remvoe the error
      try {
        let AuthToken = localStorage.getItem('AuthToken');
        let Accuser = localStorage.getItem("useracc");
        let currentInfo = {
          username: Accuser,
          currentSite: state.currentSite,
          currentLevel: action.data,
          AuthToken: AuthToken
        };
        if (true)//!state.DashboardNeedReload)
        {
          //state.socket.emit("DashboardData", currentInfo);
          state.socket.emit("MapArea", currentInfo);
          //new request function (real, not hardcode)
          //state.socket.emit("LevelReload", currentInfo);
        }
        else {
          console.log('Waiting the onload, so stop new request of DashboardData');
        }
      }
      catch (err) {
        console.error(err);
      }
      let levelID = "";
      try {
        //find the levelID inside this.props.UserInfo
        //then set the value inside Redux: currentlevelID
        //1. get currentSite and currentLevel value
        let { currentSite, currentlevel, UserInfo } = state;
        /*console.log({
          currentSite: currentSite,
          currentlevel: currentlevel,
          UserInfo: UserInfo
        });*/
        //2. find the level item
        let LevelItemFound = UserInfo.locations[currentSite].nestedLocs.find(function (LeveItem) {
          return LeveItem.locName == action.data; //currentlevel input is action.data
        })
        console.log("LevelItemFound");
        console.log(LevelItemFound);
        //3. get the _id
        levelID = LevelItemFound._id;
      }
      catch (err) {
        console.log(err);
      }
      return {
        ...state,
        currentlevel: action.data,
        currentlevelID: levelID,
        DeviceDataLastUpdate: UpdatedDatetime
      }
    case 'PIckSite':
      try {
        let AuthToken = localStorage.getItem('AuthToken');
        let Accuser = localStorage.getItem("useracc");
        let currentInfo = {
          username: Accuser,
          currentSite: action.data.Siteid,
          currentLevel: state.currentLevel,
          AuthToken: AuthToken
        };
        if (true)//!state.DashboardNeedReload)
        {
          //state.socket.emit("DashboardData", currentInfo);
          state.socket.emit("MapArea", currentInfo);

          //new request function (real, not hardcode)
          //state.socket.emit("LevelReload", currentInfo);
        }
        else {
          console.log('Waiting the onload, so stop new request of DashboardData');
        }
      }
      catch (err) {
        console.error(err)
      }

      //get levelID and currentlevel
      let levelID_site = "";
      let currentlevel_site = "";
      try {
        //get all states variables
        let { UserInfo, currentlevel } = state;
        //get first level item in site list
        let LevelItemFound = UserInfo.locations[action.data.Siteid].nestedLocs[0];
        // console.log(LevelItemFound)
        //get the currentlevel name (string) and levelID
        levelID_site = LevelItemFound._id;
        currentlevel_site = LevelItemFound.locName;
        // console.log({
        //   levelID_site: levelID_site,
        //   currentlevel_site: currentlevel_site
        // })
      }
      catch (err) {
        console.log(err)
      }
      return {
        ...state,
        configStorage: configStorage,
        currentSite: action.data.Siteid,
        level: action.data.Level,
        DeviceDataLastUpdate: UpdatedDatetime,
        currentlevelID: levelID_site,
        currentlevel: currentlevel_site
      }
    case "MenuButton":
      return {
        ...state,
        configStorage: configStorage,
        MenuDisplay: !state.MenuDisplay,
      }
    case "FloorPlanBottomMenu":
      return {
        ...state,
        configStorage: configStorage,
        currentBottomMenu: action.data,
      }
    case "bottomcardhover":
      return {
        ...state,
        configStorage: configStorage,
        currentBottomCard: action.data,
      }
    case "OpenLevelPopUp":
      return {
        ...state,
        configStorage: configStorage,
        ShowLevelPopUp: !state.ShowLevelPopUp,
      }
    /*case "DashboardDataLoaded":
      return { 
        ...state, 
        configStorage: configStorage,
        DashboardData: action.data
      }*/
    case "LatestPage":
      console.log('set LatestPage in index.js:' + action.data);
      return {
        ...state,
        configStorage: configStorage,
        LatestPage: action.data
      }
    case "EmitSocket":
      //check input socketname and socketdata
      if (action.EmitSocketName != null && action.EmitSocketData != null && socket != null) {
        // console.log('Socket Emiting:' + action.EmitSocketName)
        //if both input exist, run the emit socket
        try {
          socket.emit(action.EmitSocketName, action.EmitSocketData)
          // console.log('Socket Emit Completed')
        }
        catch (err) {
          console.log('Socket Emit Failed')
          console.log(err)
        }
      }
      else {
        console.error('some neccessary data are missing, would not emit the socket')
      }
      let EmitSocketStrUpdated = state.EmitSocketStr + " | " + action.EmitSocketName + "()"
      console.log('Socket Emit History:' + EmitSocketStrUpdated)
      return {
        ...state,
        EmitSocketStr: EmitSocketStrUpdated
      }
    case "SaveUserInfo":
      //console.log(action.data);
      // check if there is location, if yes, save it to DeviceData
      if (action.data == null) {
        return {
          ...state,
          configStorage: configStorage,
          UserInfo: action.data,
        }
      }
      else {

        return {
          ...state,
          configStorage: configStorage,
          UserInfo: action.data,
          DeviceData: action.data.locations,
          DeviceDataLastUpdate: new Date()
        }
      }
    case "SaveDeviceData":
      return {
        ...state,
        configStorage: configStorage,
        DeviceData: action.data,
        DeviceDataLastUpdate: new Date(),
      }
    case "LocationData":
      // // new Update DeviceData function
      const InputData = action.data
      // //console.log(InputData)
      // if (state.DeviceData == null || InputData == null) return state      //exception cases
      // //break and get items
      // let {DeviceData} = state
      // const SiteList = DeviceData

      // //update search string
      // let siteName = ''
      // let siteID = ''
      // let floorName = ''
      // let floorID = ''
      // let locationName = ''
      // let LocID = ''
      // InputData.locationInfo.forEach(locationInfoItem => {
      //   if (locationInfoItem.lvl == 1) {
      //     siteName = locationInfoItem.name
      //     siteID = locationInfoItem.id
      //   }
      //   else if (locationInfoItem.lvl == 2) {
      //     floorName = locationInfoItem.name
      //     floorID = locationInfoItem.id
      //   }
      //   else {
      //     locationName = locationInfoItem.name
      //     LocID = locationInfoItem.id
      //   }
      // })
      // // Lvl 1, get the Site Location
      // const FindSite = SiteList.find(siteItem => siteItem.locName == siteName)
      // if (FindSite == null || FindSite.nestedLocs == null) return state   // not device that included in this user
      // const SiteCount = SiteList.findIndex(siteItem => siteItem.locName == siteName || siteItem.id == siteID)
      // //get floor array count
      // const FloorCount = SiteList[SiteCount].nestedLocs.findIndex(floorItem => floorItem.locName == floorName || floorItem.id == floorID)
      // //get location array count
      // if (SiteList[SiteCount] == null) return state
      // if (SiteList[SiteCount].nestedLocs[FloorCount] == null) return state
      // const LocationCount = SiteList[SiteCount].nestedLocs[FloorCount].nestedLocs.findIndex(locationItem => locationItem.locName == locationName || locationItem.id == LocID)
      // if (LocationCount == null) return state           //** if same site, but this user does not have relative reading to display (first example, Vbity_tech user)

      // const arrayPayload = json2array(InputData.payload)
      // if(SiteCount==-1 || FloorCount==-1 || LocationCount==-1) {
      //   console.error('payload item not found::')
      //   console.error({
      //     SiteCount: SiteCount,
      //     FloorCount: FloorCount,
      //     LocationCount: LocationCount,
      //     InputData: InputData
      //   })
      //   return state
      // }
      // let newPayloadItem = DeviceData[SiteCount].nestedLocs[FloorCount].nestedLocs[LocationCount].payload

      // arrayPayload.forEach(updateItem => {
      //   if(newPayloadItem == null || newPayloadItem == undefined) return
      //   newPayloadItem[updateItem[0]] = updateItem[1]
      // })
      // newPayloadItem.updateDate = new Date()
      // DeviceData[SiteCount].nestedLocs[FloorCount].nestedLocs[LocationCount].payload = newPayloadItem

      return {
        ...state,
        DeviceData: LocationData(state, action),
        DeviceDataLastUpdate: new Date()
      }
    case 'LocationCoordinatesUpdate':
      let updatedDeviceData = updateDeviceDataLocation(state.DeviceData, action.data)
      return {
        ...state,
        configStorage: configStorage,
        DeviceData: updatedDeviceData,
        DeviceDataLastUpdate: new Date(),
      }
    case "LocationStatus":
      const newStatus = action.data
      //Manage Input Data
      const { locationInfo, status } = newStatus
      var GetSite = locationInfo.find(locItem => {
        return locItem.lvl = 1 || locItem.lvl == '1'
      })
      var GetLevel = locationInfo.find(locItem => {
        return locItem.lvl = 2 || locItem.lvl == '2'
      })
      var GetLoc = locationInfo.find(locItem => {
        return locItem.lvl = 3 || locItem.lvl == '3'
      })
      if (GetSite == null || GetLevel == null || GetLoc == null) return    //exception trap

      var SiteName = GetSite.name
      var LevelName = GetLevel.name
      var locName = GetLoc.name

      var StatusSiteCount, LevelCount, LocCount
      //get Site item
      const StatusSiteList = state.DeviceData
      StatusSiteCount = StatusSiteList.findIndex(siteItem => {
        return siteItem.locName == SiteName
      })
      //get Level item
      const LevelList = StatusSiteList[StatusSiteCount].nestedLocs
      LevelCount = LevelList.findIndex(levelItem => {
        return levelItem.locName == LevelName
      })
      //get Location Item
      const LocList = LevelList[LevelCount].nestedLocs
      LocCount = LocList.findIndex(locItem => {
        return locItem.locName == locName
      })
      if (StatusSiteCount == -1 || LevelCount == -1 || LocCount == -1) return    //some indexFind value is undefined

      //Update the Keys in 'Status'
      var StatusItem = StatusSiteList[StatusSiteCount].nestedLocs[LevelCount].nestedLocs[LocCount].status
      var newStatusItem = StatusItem
      const arrayStatus = json2array(InputData.newStatusItem)
      arrayStatus.forEach(updateItem => {
        newStatusItem[updateItem[0]] = updateItem[1]
      })
      console.log({
        StatusSiteCount: StatusSiteCount,
        LevelCount: LevelCount,
        LocCount: LocCount,
        StatusItem: StatusItem,
        newStatusItem: newStatusItem,
      })

      //Return State (update)
      let newDeviceData = state.DeviceData
      newDeviceData[StatusSiteCount].nestedLocs[LevelCount].nestedLocs[LocCount].status = newStatusItem
      return {
        ...state,
        DeviceData: newDeviceData,
        DeviceDataLastUpdate: new Date()
      }
    case "LatestDeviceData":
      //console.log('LatestDeviceData')   //disabled in 2020, load latest data when login
      return state;
    // console.log(action.data)
    // if(action.data == null)
    // {
    //   return {
    //     ...state,
    //     DeviceDataStatus: 2,    //2 is no response
    //     DeviceDataLastUpdate: new Date()
    //   }
    // }
    // return {
    //   ...state, 
    //   configStorage: configStorage,
    //   DeviceData: action.data,
    //   DeviceDataLastUpdate: action.lastUpdate,
    //   DeviceDataStatus: 1     //1 is complete onload
    // };
    case "TimeoutDeviceData":
      return {
        ...state,
        DeviceDataStatus: 4     //4 is timeout no response
      }
    case "ActiveAlarms":
      // console.log(action.data);
      return {
        ...state,
        configStorage: configStorage,
        SystemAlerts: action.data.data
      }
    case "SaveImgStorage":
      return {
        ...state,
        configStorage: configStorage,
        ImgStorage: action.data
      }
    case "CurrentPolygon":
      return {
        ...state,
        configStorage: configStorage,
        Polygons: action.data
      }
    case "PickPolygon":
      return {
        ...state,
        configStorage: configStorage,
        PickedPolygonLoc: action.data
      }
    case "DeviceStatistic":
      return {
        ...state,
        configStorage: configStorage,
        StatisticData: action.data,
        DeviceDataLastUpdate: new Date()
      }
    case "UserManageInfo":  //UserManageInfo
      return {
        ...state,
        configStorage: configStorage,
        DeviceDataLastUpdate: new Date(),
        UserManageInfo: action.data
      }
    case "UserManageUserList":
      return {
        ...state,
        configStorage: configStorage,
        DeviceDataLastUpdate: new Date(),
        UserManageUserList: action.data
      }
    // case "HeatmapCO2":
    //   //console.log(action.data)
    //   return {
    //     ...state,
    //     configStorage: configStorage,
    //     DeviceDataLastUpdate: new Date(),
    //     StatHeatmapCO2: action.data
    //   }
    case "HeatmapIAQ":
      //console.log(action.data)
      return {
        ...state,
        configStorage: configStorage,
        DeviceDataLastUpdate: new Date(),
        StatHeatmapTH: action.data
      }
    case "IAQSpiderChart":
      return {
        ...state,
        configStorage: configStorage,
        DeviceDataLastUpdate: new Date(),
        StatSpider: action.data
      }
    case "DashboardPower1":      //this is the storage on Dashboard > power, get previous Power Value
      return {
        ...state,
        configStorage: configStorage,
        DeviceDataLastUpdate: new Date(),
        DashboardPower: action.data
      }
    case "DashboardPower2":      //this is the storage on Dashboard > power, get previous Power Value
      return {
        ...state,
        configStorage: configStorage,
        DeviceDataLastUpdate: new Date(),
        DashboardPower2: action.data
      }
    case "Notification":        //the Notification record table in Notification.js and table.js
      return {
        ...state,
        configStorage: configStorage,
        Notification: action.data,
        DeviceDataLastUpdate: new Date(),
      }
    case "AuditTrial":        //the Notification record table in Notification.js and table.js
      return {
        ...state,
        configStorage: configStorage,
        AuditTrialList: action.data,
      }
    case "GroupList": //CPSL get GroupList  
      return {
        ...state,
        configStorage: configStorage,
        GroupList: action.data,
      }
    case "EquipmentList": //CPSL get LoraWANGateway list
      return {
        ...state,
        configStorage: configStorage,
        EquipmentList: action.data,
      }
    case "triggerAlarmList":
      return {
        ...state,
        configStorage: configStorage,
        triggerAlarmList: action.data,
        DeviceDataLastUpdate: new Date(),
      }
    case "DashboardPickedColdboxID":
      return {
        ...state,
        configStorage: configStorage,
        DashboardPickedColdboxID: action.data,
        DeviceDataLastUpdate: new Date(),
      }
    case "DeviceManagement":
      return {
        ...state,
        configStorage: configStorage,
        DeviceManageList: action.data.devices,
      }
    case "BeaconManagement":
      return {
        ...state,
        configStorage: configStorage,
        BeaconManageList: action.data.devices,
      }
    //GetZoneList
    case "GetZoneList":
      return {
        ...state,
        configStorage: configStorage,
        ZoneList: action.data,
      }
    case "LiftAlarmStatistic":
      return {
        ...state,
        configStorage: configStorage,
        LiftAlarmStatistic: action.data,
        DeviceDataLastUpdate: new Date(),
      }
    case "ToiletType":
      return {
        ...state,
        configStorage: configStorage,
        ToiletType: action.data,
        DeviceDataLastUpdate: new Date(),
      }
    case 'forceUpdate':   // update redux value to refresh some pages
      return {
        ...state,
        DeviceDataLastUpdate: new Date(),
      }
    case 'socketUpdate': // update the socket status (T/F)
      return {
        ...state,
        socketConnect: action.data
      }
    default:
      return state;
  }
}

function socketListener(socket) {
  if(socket == null) return

  socket.on('disconnect', (reason) => {
    console.error('Socket Disconnect Detected (index.js)')
    if (reason === 'io server disconnect') {
      console.error('Socket Re-connect manually (index.js)')
      // the disconnection was initiated by the server, you need to reconnect manually
      socket.connect()
    }
  })
}

//for redux > store
const store = createStore(reducer);

ReactDOM.render(
  (
    <Provider store={store}>
      <Router basename={'/'}>
        <Switch>
          <Route path="/Main" component={Main} />
          <Route path="/App" component={App} />
          <Route path="/forgetPassword" component={ForgetPassword} />
          <Route path="/resetpassword" component={ResetPassword} />
          <Route path="/Central" component={CentralPublic} />
          <Route path='/CCGHQ*' component={CCGPublic} />
          <Route path='/public' component={PublicPage} />
          <Route path="/Test" component={TestPage} />
          <Route path='/new' component={DragLayoutV2} />
          <Route path="/GetCoor" component={GetCoor} />
          <Route path='/dynamiccard' component={DynamicCardMaker} />
          <Route component={Login} key="Login Page" />
        </Switch>
      </Router>
    </Provider>
  ),
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

function json2array(json) {
  var result = []
  var keys = Object.keys(json)
  keys.forEach(function (key) {
    result.push([key, json[key]])
  });
  return result
}