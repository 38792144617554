//this is the new menu list for botton scroll card, where it is independent from scroll part
import React from 'react';
import MenuListItem from '../MenuList/MenuListItem';

//Redux Import
import { connect } from 'react-redux';

class MenuList_new extends React.Component {
    constructor(props) {
        super(props)
        this.PickedItem = this.PickedItem.bind(this)
    }

    PickedItem(EventID) {
        this.props.dispatch({ type: 'FloorPlanBottomMenu', data: EventID })
        //clear polygon redux value
        this.props.dispatch({ type: 'PickPolygon', 
            data: null
        })
    }

    render() {
        const { UserInfo, currentSite, currentlevel, currentBottomMenu } = this.props
        if(UserInfo == null) return <div></div>

        let deviceList = [{name: "Menu List is Empty"}]
        try
        {
            let deviceList_try = []
            UserInfo.locations[currentSite].nestedLocs.forEach(floorItem => {
                if(currentlevel == floorItem.locName) {
                    var isFirstTempHumid = true
                    floorItem.nestedLocs.forEach(LocItem => {
                        let newItem = false
                        deviceList_try.forEach(element => {
                            if(element.name == LocItem.payload_definition_id.name) newItem = true
                        })
                        if(!newItem)    //new deviceName
                        {
                            try {
                                if(LocItem.payload_definition_id.name == "Temperature & Humidity" && isFirstTempHumid) {
                                    deviceList_try.push({
                                        //_id: LocItem.device_id._id,
                                        //deviceSerial: LocItem.device_id.deviceSerial,
                                        name: "Temp & Humid"
                                    })
                                    isFirstTempHumid = false
                                }
                                else if(LocItem.payload_definition_id.name == "Temperature & Humidity" && !isFirstTempHumid) {  }
                                else deviceList_try.push(LocItem.payload_definition_id)
                            }
                            catch(err){
                                console.log(err)
                            }
                        }
                    })
                    deviceList = deviceList_try
                }
            })
        }
        catch(err)
        {
            console.error(err)
        }

        return <div className="consolelist_outside" style={{width: "100%"}}>
            <div className="flex flex-row consolelist_head">
                <i className="fa fa-building menucardicon"></i>
                <div className="text-left menucardtitle">
                    IoT Smart Console
                </div>
            </div>
            <div className="consolelist_inside">
                {
                    deviceList.map((deviceListItem, i) =>
                        <MenuListItem title={deviceListItem.name} name={"bottom" + i} pickeditem={ this.PickedItem } />
                    )
                }
            </div>
        </div>
    }
}


//Redux inside component function
function mapStateToProps(state) {
    return {
      socket: state.socket,
      level: state.level,
      currentlevel: state.currentlevel,
      SiteList: state.SiteList,
      currentSite: state.currentSite,
      MenuDisplay: state.MenuDisplay,
      currentBottomMenu: state.currentBottomMenu,
      currentBottomCard: state.currentBottomCard,
      UserInfo: state.UserInfo,
      deviceList: state.deviceList
    };
}

export default connect(mapStateToProps)(MenuList_new);  