import React from 'react'
import './searchbar.css'
//Redux Import
import { connect } from 'react-redux'
import moment from 'moment'

class Searchbar extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            startdate: new Date(moment().subtract(1, 'months')),
            enddate: new Date(),
            //option list
            groupList: null,
            siteList: null,
            floorList: null,
            typeList: ['Fault', 'Warning'],
            statusList: ['Closed', 'Open', 'Acknowledged', 'Auto-resumed'],
            //filter current values (null or empty str = no filter)
            filterTxt: '',
            filterGroup: '',
            filterSite: '',
            filterLevel: '',
            filterStatus: '',
            filterType: '',
            filterFrom: '',
            filterTo: '',
            //Filter Result
            Filtered: null,

            loading: false,
        }

        this.GenOptions = this.GenOptions.bind(this)
        this.FilterOnchange = this.FilterOnchange.bind(this)
        this.Filter = this.Filter.bind(this)
        this.setStartTime = this.setStartTime.bind(this)
        this.setEndTime = this.setEndTime.bind(this)

        this.refresh = this.refresh.bind(this)
    }

    componentDidMount() {
        var { coldboxList } = this.props
        var {startdate, enddate} = this.setState

        if (coldboxList == null || coldboxList == undefined) return
        if(startdate === null){
            let temp = enddate
            temp.setDate(enddate.getMonth -1)
            this.setState(prevState => ({
                ...prevState,
                startdate: temp, 
            }))
        }
        this.GenOptions()
    }
    componentDidUpdate(preProps) {
        const {coldboxList} = this.props

        var oldcoldboxList = preProps.coldboxList
        if (coldboxList != oldcoldboxList || (coldboxList != null && oldcoldboxList == null)) this.GenOptions()
    }
    refresh() {
        const _this = this
        this.setState({
            loading: true
        })
        this.props.refresh()

        setTimeout(function() {
            _this.setState({
                loading: false
            })
        }, 2000)
    }
    GenOptions() {
        const {coldboxList, UserInfo,currentSite} = this.props
        if (coldboxList === null || coldboxList === undefined) return
        
        var locationInfo = UserInfo.locations[currentSite].nestedLocs
        var Options = coldboxList.map(function (coldboxItem, index) {
            const { group, site, floor, type, status } = coldboxItem
            return {
                group: group,
                site: site,
                level: floor,
                id: index,
                type: type,
                status: status
            }
        })
        //Group List
        var GroupList = Options.map(item => item.group)
            .filter((value, index, self) => self.indexOf(value) === index)
        GroupList = GroupList.sort()
        //Site List
        var SiteList = Options.map(item => item.site)
            .filter((value, index, self) => self.indexOf(value) === index)
        SiteList = SiteList.sort()
         var levelList = locationInfo
        levelList = levelList.sort()
        //Type List
        var typeList = Options.map(item => item.type)
            .filter((value, index, self) => self.indexOf(value) === index)
        typeList = typeList.sort()
        //Status List
        var statusList = Options.map(item => item.status)
            .filter((value, index, self) => self.indexOf(value) === index)
        statusList = statusList.sort()

        this.setState({
            groupList: GroupList,
            siteList: SiteList,
            floorList: levelList,
            Filtered: coldboxList,
            typeList: typeList,
            statusList: statusList
        })
        this.Filter()
    }
    FilterOnchange(keyStr, e) {
        try {            
            if(keyStr ==="filterLevel"){  
                let obj = this.state.floorList.find(o =>o._id===e.target.value) 
                const id = e.target.value.toString() 
                const name = obj.locName.toString()  
                this.setState({
                    [keyStr]: name,
                }, function () {        //run right after state is set
                    this.Filter()       //re-filter
                })
            }else{
                const value = e.target.value.toString()

                this.setState({
                    [keyStr]: value,
                }, function () {        //run right after state is set
                    this.Filter()       //re-filter
                })
            }
        }
        catch (err) {
            console.log(err)
        }
    }
    Filter() {
        const { filterTxt, filterGroup, filterType, filterLevel, filterStatus} = this.state
        const { coldboxList } = this.props

        var FilteredList = coldboxList
        if (filterTxt != null && filterTxt != "") {
            FilteredList = FilteredList.filter(function (coldboxItem) {
                return coldboxItem.keywords.includes(filterTxt.toString())
            })
        }
        if (filterGroup != null && filterGroup != "") {
            FilteredList = FilteredList.filter(function (coldboxItem) {
                return coldboxItem.group == filterGroup.toString()
            })
        }
        if (filterType != null && filterType != "") {
            FilteredList = FilteredList.filter(function (coldboxItem) {
                return coldboxItem.type == filterType.toString()
            })
        } 
        if (filterLevel != null && filterLevel != "") {
            FilteredList = FilteredList.filter(function (coldboxItem) { 
                return coldboxItem.floor.toString() == filterLevel.toString()
            })
        }
        if (filterStatus != null && filterStatus != "") {
            FilteredList = FilteredList.filter(function (coldboxItem) { 
                return coldboxItem.status.toString() == filterStatus.toString()
            })
        }
        this.setState({
            Filtered: FilteredList
        })
        this.props.UpdateList('filteredList', FilteredList)
    }

    setStartTime(data) {
        this.setState({startdate: data})
        this.props.setStartTime(data)
    }

    setEndTime(data) {
        this.setState({ enddate: data })
        this.props.setEndTime(data)
    }

    render() {
        const _this = this
        const {isAdvanceSearch} = this.props
        const {loading} = this.state

        return <div className="CBGateway_head"> 
            {
                isAdvanceSearch?
                    <div className="CBGateway_hiddenSearches"> 
                        <div className="CBGateway_txt">Keyword</div>
                        <input className="CBGateway_input" placeholder="Keyword search" onChange={(e) => _this.FilterOnchange('filterTxt', e)}></input>
                    </div>
                    :
                    <div className="CBGateway_adSearchBtn" onClick={_this.props.AdvanceSearch}>Advanced Search</div>
            }  
            <div className="CBGateway_searchBtn">
                <div className={loading?"fa fa-refresh fa-spin":"fa fa-refresh"} onClick={_this.refresh}></div>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        UserInfo: state.UserInfo,
        currentSite: state.currentSite
    }
}
export default connect(mapStateToProps)(Searchbar)