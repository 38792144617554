import React from 'react'
import { connect } from 'react-redux'
import './style.css'
//Import smallest widgets
import HeaderWidget from '../common/head/head'
import ButtonWidget from '../common/searchbtn/searchbtn'
import LiftSelectWidget from '../common/LiftSelect/LiftSelect'
import WeekPickWidget from '../common/WeekPick/WeekPick'
//inner widget (use in this widget only
import OptionPopupWidget from './optionpopup/optionpopup'           //export excel, legend for meaning of colors
import LiftAlarmTableWidget from './table/table'
//func
import genReqJson from './table/genReqJson'

class LiftAlarmList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            Start: null,
            End: null,
            csvData: [
                ['Lift ID', 'Alarm Type', 'Alarm Number'],
            ]
        }
        this.onChangeFunction = this.onChangeFunction.bind(this)
        this.ChangeDate = this.ChangeDate.bind(this)

        this.RequestData = this.RequestData.bind(this)
    }

    onChangeFunction(SelectedLiftList) {
        console.log(SelectedLiftList)
    }
    ChangeDate(ID, DateStart, DateEnd) {
        const _this = this
        if(ID == 'Start') {
            _this.setState({
                Start: DateStart,
            })
        }
        else {
            _this.setState({
                End: DateEnd
            })
        }
    }
    componentDidMount() {
        //emit request to get data
        const _this = this
        setTimeout(function() {
            _this.RequestData()
        }, 1000)
    }
    RequestData() {  //..
        const {currentlevelID, UserInfo} = this.props
        if(currentlevelID == null || UserInfo == null) return

        // this.props.dispatch({type: "LiftAlarmStatistic", data: null})
        this.props.dispatch({type: "Notification", data: null})

        const {Start, End} = this.state
        const {client_id} = UserInfo

        const EmitJson = genReqJson(this.props, Start, End)
        console.log(JSON.stringify(EmitJson))
        //emit socket
        this.props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "StatRequest",
            EmitSocketData: EmitJson
        })
    }

    render() {
        const {DeviceData, currentSite, currentlevel, LiftAlarmStatistic} = this.props
        const {Start, End, csvData} = this.state

        const PickedLevel = DeviceData[currentSite].nestedLocs.find(item => {
            return item.locName == currentlevel
        })
        const LocList = PickedLevel.nestedLocs.map(item => {
            return {
                name: item.locName,
                value: item.locName
            }
        })
        return <div className="ListAlarmContainer">
            <HeaderWidget Title="Weekly Fault Alarm Report" Icon="bell" />
            <div className="ListAlarm_searchbar">
                <div className="ListAlarm_searchTxt">
                    Week:
                </div>
                <WeekPickWidget input={Start} holderTxt="Start Date" DefaultDate="Start" ID="startDate" ChangeDate={this.ChangeDate} />
                -
                <WeekPickWidget input={End} holderTxt="End Date" DefaultDate="End" ID="endDate" ChangeDate={this.ChangeDate} />
                {/* <LiftSelectWidget onChangeFunction={this.onChangeFunction} DeviceData={DeviceData} /> */}
                <ButtonWidget Icon="search" ClickFunction={this.RequestData} />
                <OptionPopupWidget csvData={csvData} LiftAlarmStatistic={LiftAlarmStatistic} />
            </div>
            <LiftAlarmTableWidget />
        </div>
    }
}

function mapStateToProps(state) {
    return {
      DeviceDataLastUpdate: state.DeviceDataLastUpdate, //when this value is updated, render would triggered
      UserInfo: state.UserInfo,
      currentlevelID: state.currentlevelID,
      currentlevel: state.currentlevel,
      currentSite: state.currentSite,
      DeviceData: state.DeviceData,
      LiftAlarmStatistic: state.LiftAlarmStatistic, //result of this page
    }
}
export default connect(mapStateToProps)(LiftAlarmList)