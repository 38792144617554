import React from 'react'
import '../bottom/ThreePartSearchBar.css'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { Checkbox } from '@material-ui/core'

class Searchbar extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            startdate: new Date(moment().subtract(30, 'days')),
            enddate: new Date(),
            coldBoxID: '',
            //option list
            typeList: ['All', 'Dynamic', 'Periodic', 'Manual'],
            partList: ['All'],
            filterType: '',
            filterPart: '',
            filterTxt: '',
            //Filter Result
            Filtered: null,
            //checkbox
            isActive: true,
            isPending: true,
            isCompleted: true,
            isWaiting: true,
        }

        this.GenOptions = this.GenOptions.bind(this)
        this.FilterOnchange = this.FilterOnchange.bind(this)
        this.Filter = this.Filter.bind(this)
        this.setStartTime = this.setStartTime.bind(this)
        this.setEndTime = this.setEndTime.bind(this)
        this.FilterCheckBox = this.FilterCheckBox.bind(this)
    }
    componentDidMount() {
        var { workOrderList } = this.props
        if (workOrderList == null || workOrderList == undefined) return
        this.GenOptions()
    }
    componentDidUpdate(preProps) {
        //Re-gen option list when data is updated
        var { workOrderList } = this.props
        var oldworkOrderList = preProps.workOrderList
        if (workOrderList != oldworkOrderList || (workOrderList != null && oldworkOrderList == null)) this.GenOptions()
    }
    GenOptions() {
        const { workOrderList } = this.props
        if (workOrderList === null || workOrderList === undefined) {
            return
        } else {
            var typeList = []
            var partList = []
            workOrderList.forEach(wo => {
                typeList.push(wo.workOrderType)
                wo.parts.forEach(p => {
                    partList.push(p)
                })
            });
            // console.log(Options)

            //Group List
            this.setState({
                typeList: Array.from(new Set(typeList)).sort(),
                partList: Array.from(new Set(partList)).sort(),
            })
            this.Filter()
        }
        this.Filter()
    }
    FilterOnchange(keyStr, e) {
        try {
            const value = e.target.value.toString()
            this.setState({
                [keyStr]: value,
            }, function () {        //run right after state is set
                this.Filter()       //re-filter
            })
        }
        catch (err) {
            console.log(err)
        }
    }

    FilterCheckBox = (key, v) => {
        try {
            this.setState({
                [key]: v
            }, function () {        //run right after state is set
                this.Filter()       //re-filter
            })
        } catch (err) {
            console.log(err)
        }
    }

    Filter() {
        const { coldBoxID, filterPart, filterType, isActive, isPending, isCompleted, isWaiting, filterTxt } = this.state
        const { workOrderList } = this.props

        var FilteredList = workOrderList
        if (coldBoxID != null && coldBoxID !== "") {
            FilteredList = FilteredList.filter(function (coldboxItem) {
                return coldboxItem.name.includes(coldBoxID.toString())
            })
        }
        if (filterPart != null && filterPart !== "") {
            FilteredList = FilteredList.filter(function (coldboxItem) {
                return coldboxItem.parts.includes(filterPart.toString())
            })
        }
        if (filterType != null && filterType !== "") {
            FilteredList = FilteredList.filter(function (coldboxItem) {
                return coldboxItem.workOrderType === filterType.toString()
            })
        }
        if (!isActive) {
            FilteredList = FilteredList.filter(function (coldboxItem) {
                return coldboxItem.status.toString() !== "Active"
            })
        }
        if (!isPending) {
            FilteredList = FilteredList.filter(function (coldboxItem) {
                return coldboxItem.status.toString() !== "Pending Resume"
            })
        }
        if (!isCompleted) {
            FilteredList = FilteredList.filter(function (coldboxItem) {
                return coldboxItem.status.toString() !== "Completed"
            })
        }
        if (!isWaiting) {
            FilteredList = FilteredList.filter(function (coldboxItem) {
                return coldboxItem.status.toString() !== "Waiting Approval"
            })
        }
        if (filterTxt != null && filterTxt !== "") {
            FilteredList = FilteredList.filter(function (coldboxItem) {
                return (coldboxItem.name !== undefined && coldboxItem.name.includes(filterTxt)) ||
                    (coldboxItem.workOrderType !== undefined && coldboxItem.workOrderType.includes(filterTxt)) ||
                    (coldboxItem.parts !== undefined && coldboxItem.parts.includes(filterTxt)) ||
                    (coldboxItem.orderNo !== undefined && coldboxItem.orderNo.includes(filterTxt)) ||
                    (coldboxItem.requestor !== undefined && coldboxItem.requestor.includes(filterTxt)) ||
                    (coldboxItem.status !== undefined && coldboxItem.status.includes(filterTxt)) ||
                    (coldboxItem.requestDate !== undefined && coldboxItem.requestDate.includes(filterTxt)) ||
                    (coldboxItem.followUp !== undefined && coldboxItem.followUp.includes(filterTxt)) ||
                    (coldboxItem.reported !== undefined && coldboxItem.reported.includes(filterTxt)) ||
                    (coldboxItem.location !== undefined && coldboxItem.location.includes(filterTxt)) ||
                    (coldboxItem.downTime !== undefined && coldboxItem.downTime.includes(filterTxt))
            })
        }
        this.setState({
            Filtered: FilteredList
        })
        this.props.UpdateList("Filter", FilteredList)
    }


    setStartTime(data) {
        this.setState({ startdate: data })
        this.props.setStartTime(data)
    }

    setEndTime(data) {
        this.setState({ enddate: data })
        this.props.setEndTime(data)
    }

    render() {
        const _this = this
        const { isAdvanceSearch } = this.props

        const { typeList, partList, filterPart, filterType, filterTxt, coldBoxID, isActive, isPending, isWaiting, isCompleted } = this.state

        return <div className='ThreePartSearch_Main'>
            <div className='ThreePartSearch_Block'>
                <div className="ThreePartSearch_left">
                    <div className="ThreePartSearch_txt">From:</div>
                    <div className="WOLDatepicker">
                        <DatePicker
                            selected={this.state.startdate}
                            onChange={this.setStartTime}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="d-MMMM yyyy HH:mm"
                            timeCaption="time"
                            className='cdbxEPicker'
                        />
                    </div>
                    <div className="ThreePartSearch_txt">To:</div>
                    <div className="WOLDatepicker">
                        <DatePicker
                            selected={this.state.enddate}
                            onChange={this.setEndTime}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="d-MMMM yyyy HH:mm"
                            timeCaption="time"
                            className='cdbxEPicker'
                        />
                    </div>{
                        isAdvanceSearch ?
                            <div className="ThreePartSearch_adSearchBtn" onClick={_this.props.closeAdSearch}>
                                <div className="fa fa-times"></div>
                            </div> :
                            <div className="ThreePartSearch_adSearchBtn" key="WOLsearchBtn" onClick={_this.props.AdvanceSearch}>Advanced Search</div>
                    }
                    <div className="ThreePartSearch_adSearchBtn" onClick={_this.props.refresh}>
                        <div className="fa fa-refresh" />
                    </div>
                </div>
                {
                    isAdvanceSearch ?
                        <div className="ThreePartSearch_hiddenSearches">
                            <div className="ThreePartSearch_txt">Coldbox ID:</div>
                            <input className="ThreePartSearch_input" value={coldBoxID} placeholder="Keyword search" onChange={(e) => _this.FilterOnchange('coldBoxID', e)}></input>
                            <div className="ThreePartSearch_txt">Type:</div>
                            <select className="ThreePartSearch_select" value={filterType} onChange={(e) => _this.FilterOnchange('filterType', e)}>
                                <option value="">All</option>
                                {
                                    typeList.map(function (item) {
                                        return <option value={item} >{item}</option>
                                    })
                                }
                            </select>
                            <div className="ThreePartSearch_txt">Parts:</div>
                            <select className="ThreePartSearch_select" value={filterPart} onChange={(e) => _this.FilterOnchange('filterPart', e)}>
                                <option value="">All</option>
                                {
                                    partList.map(function (item) {
                                        return <option value={item}>{item}</option>
                                    })
                                }
                            </select>
                        </div>
                        : <div />
                }
            </div>
            {isAdvanceSearch ?
                <div className='ThreePartSearch_Block'>
                    <div className='ThreePartSearch_middle'>
                        <div className='ThreePartSearch_txt'>Active</div>
                        <Checkbox checked={isActive} onClick={() => this.FilterCheckBox("isActive", !isActive)} />
                        <div className='ThreePartSearch_txt'>Pending</div>
                        <Checkbox checked={isPending} onClick={() => this.FilterCheckBox("isPending", !isPending)} />
                    </div>
                    <div className='ThreePartSearch_middle'>
                        <div className='ThreePartSearch_txt'>Complete</div>
                        <Checkbox checked={isCompleted} onClick={() => this.FilterCheckBox("isCompleted", !isCompleted)} />
                        <div className='ThreePartSearch_txt'>Waiting</div>
                        <Checkbox checked={isWaiting} onClick={() => this.FilterCheckBox("isWaiting", !isWaiting)} />
                    </div>
                </div>
                : <div />
            }
            {isAdvanceSearch ?
                <div className='ThreePartSearch_Block'>
                    <div className='ThreePartSearch_right'>
                        <div className='ThreePartSearch_keyword_title'>Keyword search:</div>
                        <input className="ThreePartSearch_keyword_input" value={filterTxt} placeholder="Keyword search" onChange={(e) => _this.FilterOnchange('filterTxt', e)}></input>
                    </div>
                </div>
                : <div />
            }
        </div>
    }
}

function mapStateToProps(state) {
    return {

        UserInfo: state.UserInfo,
        currentSite: state.currentSite,
    }
}
export default connect(mapStateToProps)(Searchbar)