import React from 'react'
import { connect } from 'react-redux'
import BottomDisplay from './BottomDisplay/BottomDisplay'
import MenuListItem from './MenuListItem'
import {GetSensorTypeName} from '../../common/language/locDisplay'

class MenuList extends React.Component {
    constructor(props) {
        super(props)
        this.PickedItem = this.PickedItem.bind(this)
    }
    PickedItem(EventID) {
        this.props.dispatch({ type: 'FloorPlanBottomMenu', data: EventID })
        this.props.dispatch({ type: 'PickPolygon', data: null })            //clear polygon redux value
    }
    componentDidMount() {
        // default selected device of special requirement site
        SelectSite(this.props)
        // if redirect from notification page
        const locName = localStorage.getItem('notiRedirect_locName')
        if(locName==''||locName==null) return

        const {DeviceData, currentSite, currentlevel} = this.props
        if(DeviceData==null) return
        const locItem = DeviceData[currentSite].nestedLocs.find(item => item.locName == currentlevel).nestedLocs.find(item => item.locName == locName)
        if(locItem==null||locItem==undefined) return
        const deviceList = GetDeviceList(this.props).map(item => item.nameID)
        const menuIndex = deviceList.indexOf(locItem.payload_definition_id.nameID)
        if(menuIndex==-1) return

        this.props.dispatch({ type: 'FloorPlanBottomMenu', data: 'bottom' + menuIndex })
    }
    render() {
        const {UserInfo} = this.props
        if(UserInfo == null) return <></>

        const deviceList = GetDeviceList(this.props)
        return <div className="w-full flex flex-row">
            <div className="consolelist_outside">
                <div className="flex flex-row consolelist_head">
                    <i className="fa fa-building menucardicon"></i>
                    <div className="text-left menucardtitle">
                        IoT Smart Console
                    </div>
                </div>
                <div className="consolelist_inside">
                    {
                        deviceList.map((deviceListItem, i) =>
                            <MenuListItem
                                cardType={deviceListItem?deviceListItem.name:''}
                                nameID={deviceListItem?deviceListItem.nameID:''}
                                typeTitle={GetSensorTypeName(deviceListItem)}
                                name={"bottom" + i}
                                pickeditem={this.PickedItem}
                            />
                        )
                    }
                </div>
            </div>
            <div className="bottom_outside">
                {
                    // to separate the menulist widget with scroll reading cards
                    <BottomDisplay deviceList={deviceList} />
                }
            </div>
        </div>
    }
}
function mapStateToProps(state) {
    return {
      currentlevel: state.currentlevel,
      currentSite: state.currentSite,
      DeviceData: state.DeviceData,
      currentBottomMenu: state.currentBottomMenu,
      UserInfo: state.UserInfo,
      deviceList: state.deviceList,
      DeviceDataLastUpdate: state.DeviceDataLastUpdate
    }
}
export default connect(mapStateToProps)(MenuList)

const GetDeviceList = props => {
    const {UserInfo, currentSite, currentlevel} = props
    let deviceList = [{name: "Menu List is Empty"}]
    try {
        let deviceList_try = []
        UserInfo.locations[currentSite].nestedLocs.forEach(floorItem => {
            if(currentlevel == floorItem.locName) {
                floorItem.nestedLocs.forEach(LocItem => {
                    let newItem = false
                    deviceList_try.forEach(element => {
                        if(element.cardTitle == LocItem.payload_definition_id.cardTitle) newItem = true
                    })
                    if(!newItem) {
                        try {
                            if(pushItemCheck(LocItem)) deviceList_try.push(LocItem.payload_definition_id)
                        }
                        catch(err) {
                            console.log(err)
                        }
                    }
                })
                deviceList = deviceList_try
            }
        })
    }
    catch(err) {
        console.error(err)
    }
    return deviceList
}

//filter away not show menu items (if false, not show)
const pushItemCheck = locItem => {
    if(locItem==null) return true
    const {isSwimmingPool, payload_definition_id} = locItem
    if(payload_definition_id==null) return true
    const cardTitle = payload_definition_id.cardTitle
    if(cardTitle=='PMC' && isSwimmingPool==true) {
        return false
    }
    return true
}

const SelectSite = props => {
    if(props == null) return
    const {UserInfo, DeviceData} = props
    if(DeviceData == null) return
    let firstLoc = DeviceData[0].nestedLocs[0].nestedLocs[0]
    if(firstLoc.isSwimmingpool == true) {
        //swimming pool, need preselect default location
        props.dispatch({ type: 'FloorPlanBottomMenu', data: 'bottom100' })
    }
}