import React, {useState} from 'react'
import { connect } from 'react-redux'
import './ExportCsv.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv, faTimes } from '@fortawesome/free-solid-svg-icons'
import { CSVLink } from "react-csv"

function ExportExcelWidget(props) {
    const {Data} = props
    const [ExcelData, setExcelData] = useState()

    function GetExcelData() {
        let ExcelArray = [
            ['Date', "Lift ID", 'Floor', 'Door Open Count', 'Lift Startup Count', 'Lift Direction Reverse Count']
        ]

        const displayFloorList = getFloorList(props)
        //add the array items
        const doorList = (Data != null && Data['doorOpenCount'] != null)?Data['doorOpenCount']:[]
        const DirectRevList = (Data != null && Data['liftDirectionReverseCount'] != null)?Data['liftDirectionReverseCount']:[]
        const StartupList = (Data != null && Data['liftStartupCount'] != null)?Data['liftStartupCount']:[]

        const CombinedList = CombineInnerKeys(doorList, StartupList, DirectRevList, displayFloorList)

        ExcelArray = ExcelArray.concat(CombinedList)
        setExcelData(ExcelArray)
    }
    function CloseExcelDownload() {
        setExcelData(null)
    }

    return (ExcelData == null)?
    <div className="ExportCsvBtn" onClick={GetExcelData}>
        <FontAwesomeIcon icon={faFileCsv} className="fa" />
    </div>
    :
    <div className="ExportCsvWindow" key="csvpopup">
        <div className="ExportCsv_CancelBtn" onClick={CloseExcelDownload}>
            <FontAwesomeIcon icon={faTimes} />
        </div>
        <CSVLink data={ExcelData} filename="Monthly Lift Door Statistical Report.csv" className="ExportCsv_CancelBtn">
            Download CSV
        </CSVLink>
    </div>
}
function mapStateToProps(state) {
    return {
        DeviceData: state.DeviceData,
        currentSite: state.currentSite,
        currentlevel: state.currentlevel
    }
}
export default connect(mapStateToProps)(ExportExcelWidget)

function CombineInnerKeys(array1, array2, array3, displayFloorList) {
    // create unique array list with time
    const CombinedArray = [...array1, ...array2, ...array3]
    const LocList = getUniqStrList(CombinedArray, 'locationName').sort()
    const FlrList = getUniqStrList(CombinedArray, 'floor').sort()
    const TmeList = getUniqStrList(CombinedArray, 'time').sort()

    // Fill in the array keys inside
    let ReturnArray = []
    LocList.forEach(LocName => {
        FlrList.forEach(FlrName => {
            TmeList.forEach(tmeValue => {
                const value1 = findValue(array1, 'doorOpenCount', tmeValue, LocName, FlrName)
                const value2 = findValue(array2, 'liftStartupCount', tmeValue, LocName, FlrName)
                const value3 = findValue(array3, 'liftDirectionReverseCount', tmeValue, LocName, FlrName)
                ReturnArray.push([convertDateStr(tmeValue), LocName, displayFloorList[FlrName], value1, value2, value3, FlrName])
            })
        })
    })
    const sortedArray = ReturnArray.sort((a, b) => { //sorting array with floor name
        return a[6] - b[6]
    })
    return sortedArray
}

function findValue(ArrayList, valuekeystr, tmeValue, LocName, FlrName) {
    if(ArrayList == null || ArrayList.length == null || ArrayList.length == 0) return 0
    const FindItem = ArrayList.find((item) => {
        return item.locationName == LocName && item.time == tmeValue && item.floor == FlrName
    })
    if(FindItem == null) return 0
    const ValueReturn = FindItem[valuekeystr]
    return ValueReturn
}
function getUniqStrList(ArrayList, keystr) {
    const MapStrList = ArrayList.map((item) => {
        return item[keystr]
    })
    return MapStrList.filter(onlyUnique)
}
function onlyUnique(value, index, self) {
    return self.indexOf(value) === index
}


function convertDateStr(DateValue) {
    if(DateValue == null) return 'Empty Date str input'
    const DateValueStr = DateValue.toString()
    let DateRaw = new Date(DateValueStr)
    DateRaw.setHours( DateRaw.getHours() + 8)
    const ReturnDateStr = DateRaw.getUTCFullYear() + '-' + MonthList[DateRaw.getUTCMonth()] + '-' + AutofillZero(DateRaw.getUTCDate())
     + ' ' + AutofillZero(DateRaw.getUTCHours()) + ':' + AutofillZero(DateRaw.getUTCMinutes()) + ':' + AutofillZero(DateRaw.getUTCSeconds())
    return ReturnDateStr
}

const MonthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', "Jul", 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

function AutofillZero(inputNumber) {
    if(inputNumber <10) return '0' + inputNumber.toString()
    else return inputNumber.toString()
}

const getFloorList = (props) => {
    if(props==null) return
    const {DeviceData, currentSite, currentlevel} = props
    if(DeviceData==null||currentSite==null||currentlevel==null) return

    const site = DeviceData[currentSite]
    if(site==null) return
    const level = site.nestedLocs.find(item => item.locName == currentlevel)
    if(level==null || level.nestedLocs==null || level.nestedLocs.length==null || level.nestedLocs.length==0) return
    const liftItem = level.nestedLocs[0]

    const lvlList = liftItem.payload==null?[]:liftItem.payload.LiftLevels
    return lvlList
}