import React, { useState, useEffect } from 'react'
import './Table.css'
import { connect } from 'react-redux'
import Moment from 'react-moment'

import LoadingWidget from '../../common/loading/loading'
import GetDataList from '../commonfunction'

function TableWidget(props) {
    const {DailyOrMonth, StatisticData} = props
    if(StatisticData == null || StatisticData.doorOpenCount == null || StatisticData.liftStartupCount == null || 
        StatisticData.liftDirectionReverseCount == null)  return <LoadingWidget />

    const DataList = GetDataList(StatisticData)
    const dateList = getUniqStrList(DataList, 0)
    const LiftList = getUniqStrList(DataList, 1)
    const FloorList = getUniqStrList(DataList, 2).sort(function(a, b) {
        return a - b
    })

    const DisplayFloorList = getFloorList(props)    // e.g. [1,2,3,4,...,13]
    let DisplayHTMLList = []
    let rowCount = 0            //when count to 9, reset
    let realRowCount = 1        //count for each dateList Item

    LiftList.forEach(LiftName => {
        let DateList = []
        dateList.forEach(dateItem => {
            let FloorReadingList = []
            FloorList.forEach(FloorName => {
                const GetRelativeReading = DataList.find(item => {
                    return item[0] == dateItem && item[1] == LiftName && item[2] == FloorName
                })
                const [item1, item2, item3, item4, item5, item6] = GetRelativeReading
                let floorNameStr = DisplayFloorList==null?'--':DisplayFloorList[FloorName]
                FloorReadingList.push(
                    <div className="Lift_drst_ReadingRow">
                        {floorNameStr + "/F"}
                        {
                            <div className="Lift_drst_3value">
                                <div className="Lift_drst_Value">
                                    {item4}
                                </div>
                                <div className="Lift_drst_Value">
                                    {item5}
                                </div>
                                <div className="Lift_drst_Value">
                                    {item6}
                                </div>
                            </div>
                        }
                    </div>
                )
                if(rowCount == 9 || FloorList.length == realRowCount) {     //when array has 10 records (e.g. 10, 11, 12, ..., 19)  //when not 10 row enough (e.g. 20, 21, 21, 23)
                    DateList.push(
                        <div className="Lift_drst_DateRow">
                            <div className="Lift_drst_DateTitle">
                                <Moment className="" format={DailyOrMonth?"ddd DD MMM YYYY | HH:mm":"MMM YYYY"}>{dateItem}</Moment>
                                <div className="Lift_drst_rowheader">
                                    <div className="Lift_drst_rowheader1">Floor</div>
                                    <div className="Lift_drst_rowheader2">Door</div>
                                    <div className="Lift_drst_rowheader2">Startup</div>
                                    <div className="Lift_drst_rowheader2">Dir Rev</div>
                                </div>
                            </div>
                            {FloorReadingList}
                        </div>
                    )
                    FloorReadingList = []       //reset for next 10 rows
                    rowCount = 0
                }
                else rowCount++

                realRowCount++
            })

            //reset for next datetime
            realRowCount = 1
            rowCount = 0
            FloorReadingList = []
        })
        DisplayHTMLList.push(
            <div className="Lift_drst_LiftItem">
                <div className="Lift_drst_LiftTitle">
                    {LiftName}
                </div>
                <div className="Lift_drst_DateLiftContainer">
                    {DateList}
                </div>
            </div>
        )
    })

    const isNoData = (DisplayHTMLList.length == 0)
    return <div className="Lift_drst_Table">
        {
            isNoData?
            <div className="Lift_drst_TableNoData">No Data</div>
            :
            DisplayHTMLList
        }
    </div>
}

function mapStateToProps(state) {
    return {
        DeviceData: state.DeviceData,
        currentSite: state.currentSite,
        currentlevel: state.currentlevel,
        StatisticData: state.StatisticData,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate
    }
}
export default connect(mapStateToProps)(TableWidget)

function getUniqStrList(ArrayList, keystr) {
    const MapStrList = ArrayList.map((item) => {
        return item[keystr]
    })
    return MapStrList.filter(onlyUnique)
}
function onlyUnique(value, index, self) {
    return self.indexOf(value) === index
}
const getFloorList = (props) => {
    if(props==null) return
    const {DeviceData, currentSite, currentlevel} = props
    if(DeviceData==null||currentSite==null||currentlevel==null) return

    const site = DeviceData[currentSite]
    if(site==null) return
    const level = site.nestedLocs.find(item => item.locName == currentlevel)
    if(level==null || level.nestedLocs==null || level.nestedLocs.length==null || level.nestedLocs.length==0) return
    const liftItem = level.nestedLocs[0]

    const lvlList = liftItem.payload==null?[]:liftItem.payload.LiftLevels
    return lvlList
}