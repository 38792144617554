import React from 'react'
import './WLeakage.css'

//Redux Import
import { connect } from 'react-redux'

import {getTxt} from '../../common/language/language'
import { isConvertWaterLeakageToWaterPump } from '../MenuList/MenuListItem'

class WLeakage extends React.Component {
    constructor(props) {
        super()
    }

    render() {
        const {DeviceData, currentSite, currentlevel, currentlevelID} = this.props
        if(DeviceData == null || currentSite == null || currentlevel == null) return <div></div>            //exceptional cases
        const CurrentFloorItem = DeviceData[currentSite].nestedLocs.find(function(floorItem) {return floorItem.locName == currentlevel})
        const CurrentDeviceList = CurrentFloorItem.nestedLocs
        // console.log(CurrentDeviceList)
        if(CurrentDeviceList == null || CurrentDeviceList.length == null || CurrentDeviceList.length == 0) return <div className="WLeak_summary">
            <div className="WLeak_Summary_head">
                {getTxt('Dashbd', 'sensorTitle', 'waterL')}
            </div>
            <div className="WLeak_Summary_inner">
                <div className="WLeak_Summary_Display">
                    No Data
                </div>
            </div>
        </div>
        
        let IsWaterLeakage = false
        CurrentDeviceList.forEach(item => {
            if(item == null || item.payload == null) return         //ex exception
            let value = item.payload.waterLeakageStatus
            if(value != 0 && value != null) IsWaterLeakage = true
        })

        const isWaterPump = isConvertWaterLeakageToWaterPump({
            ...this.props,
            nameID: 'Water Leakage' //bypass nameID check
        })
        if(isWaterPump) {
            return <div className="WLeak_summary">
                <div className="WLeak_Summary_head">
                    {getTxt('Dashbd', 'sensorTitle', 'waterP')}
                </div>
                <div className="WLeak_Summary_inner">
                    {
                        (IsWaterLeakage)?
                        <div className="WLeak_Summary_Display WLeak_Summary_Leakage">
                            <div className="fa fa-exclamation-circle"></div>
                            <div className="">
                                {getTxt('Dashbd', 'sensorTitle', 'waterPF')}
                            </div>
                        </div>
                        :
                        <div className="WLeak_Summary_Display WLeak_Summary_normal">
                            <div className="fa fa-tint"></div>
                            <div className="">
                                {getTxt('Dashbd', 'cardTxt', 'normal')}
                            </div>
                        </div>
                    }
                </div>
            </div>
        }
        if(currentlevelID === "62b94ac6854329b83855b398") {
            const {isWL, isWP} = TCPGF(CurrentDeviceList)
            return <div className="WLeak_summary">
                <div className="WLeak_Summary_head">
                    {getTxt('Dashbd', 'sensorTitle', 'waterL') + ' & ' + getTxt('Dashbd', 'sensorTitle', 'waterP')}
                </div>
                <div className="WLeak_Summary_inner">
                    <div className={isWL?"WLeak_Summary_Display WLeak_Summary_Leakage":"WLeak_Summary_Display WLeak_Summary_normal"}>
                        <div className={isWL?"fa fa-exclamation-circle":"fa fa-tint"}></div>
                        <div>{isWL?getTxt('Dashbd', 'sensorTitle', 'waterL'):'No Leakage'}</div>
                    </div>
                    <div style={{height: '10px'}}></div>
                    <div className={isWP?"WLeak_Summary_Display WLeak_Summary_Leakage":"WLeak_Summary_Display WLeak_Summary_normal"}>
                        <div className={isWP?"fa fa-exclamation-circle":"fa fa-tint"}></div>
                        <div style={{fontSize: '1.8rem'}}>{isWP?getTxt('Dashbd', 'sensorTitle', 'waterPF'):'Pump '+getTxt('Dashbd', 'cardTxt', 'normal')}</div>
                    </div>
                </div>
            </div>
        }

        return <div className="WLeak_summary">
            <div className="WLeak_Summary_head">
                {getTxt('Dashbd', 'sensorTitle', 'waterL')}
            </div>
            <div className="WLeak_Summary_inner">
                {
                    (IsWaterLeakage)?
                    <div className="WLeak_Summary_Display WLeak_Summary_Leakage">
                        <div className="fa fa-exclamation-circle"></div>
                        <div className="">
                            {getTxt('Dashbd', 'sensorTitle', 'waterL')}
                        </div>
                    </div>
                    :
                    <div className="WLeak_Summary_Display WLeak_Summary_normal">
                        <div className="fa fa-tint"></div>
                        <div className="">
                            {getTxt('Dashbd', 'cardTxt', 'normal')}
                        </div>
                    </div>
                }
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
      DeviceData: state.DeviceData,
      currentlevel: state.currentlevel,
      currentSite: state.currentSite,      
      currentlevelID: state.currentlevelID,
      DeviceDataLastUpdate: state.DeviceDataLastUpdate,
    }
}
  
export default connect(mapStateToProps)(WLeakage)

const TCPGF = CurrentDeviceList => {
    const waterLeakageItems = CurrentDeviceList.filter(item => item.locName.includes('WLS-0GF'))
    const waterPumpItems = CurrentDeviceList.filter(item => item.locName.includes('TCP-WP-'))

    const waterLeakageValues = waterLeakageItems.map(item => item?.payload?.waterLeakageStatus)
    const waterPumpValues = waterPumpItems.map(item => item?.payload?.waterLeakageStatus)

    return {
        isWL: waterLeakageValues.find(item => item == 1),
        isWP: waterPumpValues.find(item => item == 1)
    }
}