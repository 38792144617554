const getPumpConsumption = props => {
    if(props == null) return {}
    const locs = getLocs(props)
    const PMC1 = getPMC(locs, 'PMC-0001')
    const PMC2 = getPMC(locs, 'PMC-0002')

    return {
        PMC1: PMC1,
        PMC2: PMC2
    }
}
export {getPumpConsumption}

const getLocs = props => {
    const {currentSite, currentlevel, DeviceData} = props
    if(currentSite == null || currentlevel == null || DeviceData == null) return []
    const site = DeviceData[currentSite]
    if(site == null || site.nestedLocs == null) return []
    const level = site.nestedLocs.find(item => item.locName == currentlevel)
    if(level == null || level.nestedLocs == null) return []
    return level.nestedLocs
}

const getPMC = (locs, locStr) => {
    if(locs == null || locs.length == null) return
    const PMC = locs.find(item => item.locName == locStr)
    return PMC==null||PMC.payload==null?{}:PMC.payload
}