const fieldName = 'p'
const OFFSET = 0.25

const getPumpData = props => {
    let locs = getLocs(props)
    let PMC1 = getLoc(locs, 'PMC-0001')
    let PMC2 = getLoc(locs, 'PMC-0002')

    return {
        top: PMC1.payload[fieldName] * OFFSET,
        bottom: PMC2.payload[fieldName]
    }
}
const getLocs = props => {
    const {DeviceData, currentSite, currentlevel} = props
    if(DeviceData==null || currentSite==null || currentlevel==null) return []
    let site = DeviceData[currentSite]
    if(site==null) return []
    let level = site.nestedLocs.find(item => item.locName==currentlevel)
    if(level==null || level.nestedLocs==null) return []
    return level.nestedLocs
}
const getLoc = (locs, locName) => {
    return locs.find(item => item.locName == locName)
}

export {getPumpData}