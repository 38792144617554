import React from 'react'
import './cardContainer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function CardContainer(props) {
    return <div className='cardContainer'>
        <div className='head'>
            <FontAwesomeIcon icon={props.icon} className='fa icon'></FontAwesomeIcon>
            <div className='txt'>{props.head}</div>
        </div>
        <div className='body'>
            {props.children}
        </div>
    </div>
}