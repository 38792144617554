import React, {useState} from 'react'
import { connect } from 'react-redux'
import './peopleCountSum.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMale, faFemale, faBars, faUsers } from '@fortawesome/free-solid-svg-icons'

const PeopleCountSum = props => {
    const {current} = getData(props)

    return <div className='PeopleCountSum'>
        <div className='head'>
            People Count (Current)
        </div>
        <div className='inner'>
            <div className='row'>
                <div className='left'>
                    <div className='img' style={{background: '#0091ff'}}>
                        <FontAwesomeIcon icon={faMale} />
                    </div>
                </div>
                <div className='right'>
                    <div className='reading'>{pveNum(current.male)}</div>
                </div>
            </div>
            <div className='row'>
                <div className='left'>
                    <div className='img' style={{background: 'red'}}>
                        <FontAwesomeIcon icon={faFemale} />
                    </div>
                </div>
                <div className='right'>
                    <div className='reading'>{pveNum(current.female)}</div>
                </div>
            </div>
            <div className='row'>
                <div className='left'>
                    <div className='img' style={{background: 'lightgreen'}}>
                        <FontAwesomeIcon icon={faUsers} />
                    </div>
                </div>
                <div className='right'>
                    <div className='reading'>{pveNum(current.total)}</div>
                </div>
            </div>
        </div>
    </div>
}
const mapStateToProps = state => {
    return {
        DeviceData: state.DeviceData,
        currentSite: state.currentSite,
        currentlevel: state.currentlevel,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate
    }
}
export default connect(mapStateToProps)(PeopleCountSum)


const getData = props => {
    const {male, female, total} = getLocs(props)
    return getPayload(male, female, total)
}
const getLocs = props => {
    const {currentSite, currentlevel, DeviceData} = props
    const site = DeviceData[currentSite]
    const level = site.nestedLocs.find(item => item.locName==currentlevel)
    const locs = level.nestedLocs

    const male = locs.find(item => item.locName == 'MALE')
    const female = locs.find(item => item.locName == 'FEMALE')
    const total = locs.find(item => item.locName == 'TOTAL')

    return {
        male: male,
        female: female,
        total: total
    }
}
const getPayload = (male, female, total) => {
    let current = {
        male: 0,
        female: 0,
        total: 0
    }
    if(male!=null && male.payload!=null && male.payload.PeopleCumulativeDaily!=null) current.male = male.payload.PeopleCumulativeDaily
    if(female!=null && female.payload!=null && female.payload.PeopleCumulativeDaily!=null) current.female = female.payload.PeopleCumulativeDaily
    if(total!=null && total.payload!=null && total.payload.PeopleCumulativeDaily!=null) current.total = total.payload.PeopleCumulativeDaily

    return {
        current: current
    }
}

const pveNum = input => {
    if(input < 0) return 0
    return input
}