import React, { useState, useEffect } from 'react'
import './coldboxitems.css'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHistory, faObjectUngroup, faThLarge, faSquare, faCogs, faTv, faBell, faChartLine } from '@fortawesome/free-solid-svg-icons'

const ColdboxDrawerItems = props => {
    const [IsColdboxUser, SetIsColdboxUser] = useState(false)

    useEffect(() => {
        const {UserInfo} = props
        if(UserInfo == null) return     //exception
        const permissions = UserInfo.permissions
        if(permissions == null || permissions.length == null || permissions.length == 0) return
        let IsColdboxBool = false
        permissions.forEach(PerItem => {
            if(PerItem.collectionType.includes('Coldbox')) IsColdboxBool = true
        })
        SetIsColdboxUser(IsColdboxBool)
    }, [])

    const Redirect = pagestr => {
        try {
            props.history.push('/Main/' + pagestr)
            props.closefunction()
        }
        catch(err) {
            console.log(err)
        }
    }

    return (IsColdboxUser==true)?
    <div className='drawer_col'>
        {
            RouteList.map(item => {
                const {title, route, icon}= item

                return <div className='SideNavRowItem' onClick={()=>Redirect(route)}>
                    <FontAwesomeIcon icon={icon} className='fa sideIcon' />
                    <>{title}</>
                </div> 
            })
        }
    </div>
    :
    <div style={{display: 'none'}}>Not Coldbox User, would not show anything</div>
}

const mapStateToProps = state => {
    return {
      UserInfo: state.UserInfo
    }
}
export default connect(mapStateToProps)(ColdboxDrawerItems)

const RouteList = [
    {
        title: 'Dashboard',
        route: 'FloorPlan',
        icon: faTv
    },
    {
        title: 'Alarm and Fault',
        route: 'Coldbox/Alarm',
        icon: faBell
    },
    {
        title: 'Event Log',
        route: 'Coldbox/Event',
        icon: faHistory
    },
    {
        title: 'Operator Overview',
        route: 'FloorPlan/ColdboxOperationOverview',
        icon: faObjectUngroup
    },
    {
        title: 'Coldbox (Individual)',
        route: 'ColdboxManage/coldboxIndividual',
        icon: faSquare
    },
    {
        title: 'Set-Point',
        route: 'ColdboxManage/coldboxSetpoint',
        icon: faCogs
    },
    {
        title: 'LoRaWAN Gateway',
        route: 'ColdboxManage/gateway',
        icon: faCogs
    },
    {
        title: 'BLE Beacon',
        route: 'ColdboxManage/BLE',
        icon: faCogs
    },
    {
        title: 'Coldbox (Group)',
        route: 'ColdboxManage/coldboxgroup',
        icon: faThLarge
    },
    {
        title: 'Coldbox Data Trend',
        route: 'ColdboxDataTrend',
        icon: faChartLine
    },
    {
        title: 'Maintenance System',
        route: 'MmsDashboard',
        icon: faTv
    },
    {
        title: 'Maintenance Planner',
        route: 'MmsPlanner',
        icon: faTv
    }
]