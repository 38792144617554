const GetDataList = (Data) => {
    const doorList = (Data != null && Data['doorOpenCount'] != null)?Data['doorOpenCount']:[]
    const DirectRevList = (Data != null && Data['liftDirectionReverseCount'] != null)?Data['liftDirectionReverseCount']:[]
    const StartupList = (Data != null && Data['liftStartupCount'] != null)?Data['liftStartupCount']:[]

    const CombinedList = CombineInnerKeys(doorList, StartupList, DirectRevList)
    return CombinedList
}
export default GetDataList



function CombineInnerKeys(array1, array2, array3) {
    //1. create unique array list with time
    const CombinedArray = [...array1, ...array2, ...array3]
    const LocList = getUniqStrList(CombinedArray, 'locationName').sort()
    const FlrList = getUniqStrList(CombinedArray, 'floor').sort()
    const TmeList = getUniqStrList(CombinedArray, 'time').sort()

    //2. Fill in the array keys inside
    let ReturnArray = []
    LocList.forEach(LocName => {
        FlrList.forEach(FlrName => {
            TmeList.forEach(tmeValue => {
                const value1 = findValue(array1, 'doorOpenCount', tmeValue, LocName, FlrName)
                const value2 = findValue(array2, 'liftStartupCount', tmeValue, LocName, FlrName)
                const value3 = findValue(array3, 'liftDirectionReverseCount', tmeValue, LocName, FlrName)
                if(FlrName != null) ReturnArray.push([tmeValue, LocName, FlrName, value1, value2, value3])
            })
        })
    })
    //3. return the combined result
    return ReturnArray
}

function findValue(ArrayList, valuekeystr, tmeValue, LocName, FlrName) {
    if(ArrayList == null || ArrayList.length == null || ArrayList.length == 0) return 0
    const FindItem = ArrayList.find((item) => {
        return item.locationName == LocName && item.time == tmeValue && item.floor == FlrName
    })
    if(FindItem == null) return 0
    const ValueReturn = FindItem[valuekeystr]
    return ValueReturn
}
function getUniqStrList(ArrayList, keystr) {
    const MapStrList = ArrayList.map((item) => {
        return item[keystr]==null?'--':item[keystr].toString()              //to string, so can prevent duplicated floor index / string
    })
    return MapStrList.filter(onlyUnique)
}
function onlyUnique(value, index, self) {
    return self.indexOf(value) === index
}