import './LiftPowerSummary.css'
import React from 'react'
import { connect } from 'react-redux'

//Import smallest widgets
import HeaderWidget from '../common/head/head'
import ButtonWidget from '../common/searchbtn/searchbtn'
import DatePickWidget from '../common/datepick/datepick'
import LoadingWidget from '../common/loading/loading'
import ExportExcelWidget from '../common/ExportExcel/ExportExcel'
import LineChartbtnWidget from '../common/LineChart/LineChartbtn'
import LineChartWidget from '../common/LineChart/LineChart'

import convertDate from '../common/convertDate'

import getMonthList from '../MonthSummary/getMonthList'
import genDataArray from '../MonthSummary/genDataArray'

class LiftPowerSummary extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            startDate: null,
            endDate: null,
            LiftList: [],
            ShowChart: false,
        }
        this.onChangeFunction = this.onChangeFunction.bind(this)
        this.ChangeDate = this.ChangeDate.bind(this)
        this.SetShowChart = this.SetShowChart.bind(this)

        this.RequestData = this.RequestData.bind(this)
    }

    componentDidMount() {
        const _this = this
        setTimeout(function() {
            _this.RequestData()
        }, 100)          //socket request emit
    }
    SetShowChart(boolValue) {
        this.setState({
            ShowChart: boolValue
        })
    }
    onChangeFunction(SelectedLiftList) {
        const LiftList = SelectedLiftList.map((item) => {
            return item._id
        })
        console.log(LiftList)
        this.setState({
            LiftList: LiftList
        })
    }

    ChangeDate(ID, DateInput) {
        var newDate = DateInput
        this.setState({
            [ID]: newDate
        })
    }
    RequestData() {
        const {UserInfo, currentlevelID, StatisticData} = this.props

        //clear redux
        this.props.dispatch({ type: 'DeviceStatistic', 
            data: {
                co2: StatisticData.co2,
                pm25: StatisticData.pm25,
                temp: StatisticData.temp,
                humid: StatisticData.humid,
                power: StatisticData.power,
                illuminance: StatisticData.illuminance,
                loading: false,
                lift: StatisticData.lift,
                mileageTravelled: StatisticData.mileageTravelled,
                powerConsumed: null,
                runtimeTravelled: StatisticData.runtimeTravelled,
                doorOpenCount: StatisticData.doorOpenCount,
                liftStartupCount: StatisticData.liftStartupCount,
                liftDirectionReverseCount: StatisticData.liftDirectionReverseCount,
            },
        })

        //request
        const {startDate, endDate, LiftList} = this.state

        if(UserInfo == null) return
        if(startDate == null || endDate == null) return

        //get data to emit
        const clientID = UserInfo.client_id
        let finalendDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 1)

        const EmitJson = {
            "msgType": "LocationStatistics",
            "clientID": clientID,
            "payload_field": ["powerConsumed"],
            "timeConstraint": {
              "start": convertDate(startDate),
              "end": convertDate(finalendDate),
            },
            "timeFrequency": {
              "unit": "month",
              "value": 1
            },
            "locationConstraint": {
              "locLvl": 3,
              "id": currentlevelID          //LiftList //..
            },
            "locationGrouping": 3,
            "calculationMethod": "diff"
        }
        console.log(JSON.stringify(EmitJson))

        //emit socket
        this.props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "StatRequest",
            EmitSocketData: EmitJson
        })
    }

    render() {
        const {StatisticData, DeviceData} = this.props
        const {ShowChart, startDate, endDate} = this.state
        const MonthList = getMonthList(startDate, endDate)
        
        const DisplayList = (StatisticData == null || StatisticData.powerConsumed == null || StatisticData.powerConsumed.length == null)?
        <LoadingWidget />
        :
            (StatisticData.powerConsumed.length == 0)?
            <div className="LiftStartup_row" key="No Data Widget">No Data</div>
            :
            genDataArray(MonthList, StatisticData, 'powerConsumed').map(rowItem => {
                return <div className="LiftSummary_row">
                    <div className="LiftSummary_col">
                        {rowItem.locationName}
                    </div>
                    <div className="LiftSummary_col">
                        {rowItem.powerConsumed==null?'No Data':rowItem.powerConsumed.toFixed(2) + ' kwh'}
                    </div>
                    <div className="LiftSummary_col">
                        {rowItem.timeStr}
                    </div>
                </div>
            })

        return <div className="LiftAlarmHeatmap">
            <HeaderWidget Title="Monthly Power Consumption Report" Icon="file" />
            <div className="LiftStartup_search">
                <div className="LiftStartup_searchTitle">
                Months:
                </div>
                <DatePickWidget holderTxt="Start Date" DefaultDate="PreMonth" ID="startDate" ChangeDate={this.ChangeDate} />
                <DatePickWidget holderTxt="End Date" DefaultDate="Today" ID="endDate" ChangeDate={this.ChangeDate} />
                {/* <LiftSelectWidget onChangeFunction={this.onChangeFunction} DeviceData={DeviceData} /> */}
                <ButtonWidget Icon="search" ClickFunction={this.RequestData} />
                <ExportExcelWidget header="Power Consumption" unit="kw/h" keystr="powerConsumed" Data={StatisticData} />
                <LineChartbtnWidget SetShowChart={this.SetShowChart} />
            </div>
            {
                ShowChart?
                <LineChartWidget ChartData={StatisticData.powerConsumed} title="Power (kW)" keystr="powerConsumed" />
                :
                [
                    <div className="LiftStartup_rowheader">
                        <div className="LiftStartup_col">
                            Lift Name
                        </div>
                        <div className="LiftStartup_col">
                            Power Consumption
                        </div>
                        <div className="LiftStartup_col">
                            Month
                        </div>
                    </div>,
                    <div className="LiftAlarm_table1_Container">
                        {DisplayList}
                    </div>
                ]
            }
        </div>
    }
}

function mapStateToProps(state) {
    return {
        socket: state.socket,
        UserInfo: state.UserInfo,
        currentlevel: state.currentlevel,
        currentSite: state.currentSite,
        DeviceData: state.DeviceData,
        currentlevelID: state.currentlevelID,
        StatisticData: state.StatisticData,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate,
    }
}
export default connect(mapStateToProps)(LiftPowerSummary)