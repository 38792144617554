import React from 'react'
import { connect } from 'react-redux'
import './gateway.css'

import Searchbar from './searchbar/searchbar'
import Table from './table/table'
import TableHead from './table/tablehead'
import Pagebtns from './bottom/pagebtns'
import Coldbox_popup from './popup/popup';
import PopupNotification from '../../../popupNotification/PopupNotification'

class ColdboxGateway extends React.Component {
    constructor(props) {
        super()

        this.state = {
            isLoading: true,
            isAdvanceSearch: false,
            rawEquipmentsList: null,
            EquipmentList: null,
            
            sortedList: null,       //sorted result (by table head .js)
            filteredList: null,     //filtered result (by searchbar.js)
            pageSliceList: null,    //slice by page (by pages.js widget)

            updateCount: 0,
            selectedData:null
        }
        this.getEquipments = this.getEquipments.bind(this)
        this.UpdateList = this.UpdateList.bind(this)
        this.refresh = this.refresh.bind(this)
        this.relist = this.relist.bind(this)
        this.ClosePopup = this.ClosePopup.bind(this)
        this.editRow = this.editRow.bind(this)
        this.setSelected = this.setSelected.bind(this)
        this.acknowledgeListener = this.acknowledgeListener.bind(this)
    }

    refresh() {
        this.setState(prevState => ({
            ...prevState, 
            isLoading: true
        }))
        this.getEquipments()
        this.relist()
    }    
    relist() {
        const _this = this
        const {EquipmentList} = this.props
        if(EquipmentList == null) return

        let newList = []
        if (EquipmentList.equipment !== null) {
            EquipmentList.equipment.map(function (data) {
                const {_id, equipmentDesc, equipmentSerial, location, zone, equipment_data, updateTime} = data
                const dataItem = {
                    _id : _id,
                    gatewayID: equipmentDesc,
                    uniqueID: equipmentSerial,
                    location: returnFunc.getLocation(location) + " - " + returnFunc.getZone(zone) , 
                    onlineStatus: returnFunc.returnStatus(equipment_data),
                    keywords: JSON.stringify(data),
                    updateTime: returnFunc.getUpdateTime(equipment_data)
                }
                newList.push(dataItem)
            })
        }
        this.setState(prevState => ({
            ...prevState,
            EquipmentList: newList,
            rawEquipmentList: EquipmentList.equipment, 
            isLoading: false
        }))
    }
    componentDidMount() {
        this.acknowledgeListener()
        this.refresh()
    }
    componentDidUpdate() {
        const {rawEquipmentList} = this.state
        const {EquipmentList} = this.props
        if (EquipmentList === null || rawEquipmentList !== EquipmentList.equipment) this.relist()
    }
    UpdateList(StateStr, Value) {
        const {updateCount} = this.state
        this.setState({
            [StateStr]: Value,
            updateCount: updateCount + 1
        })
    }
    acknowledgeListener() {
        const {socket} = this.props
        const _this = this
        if(socket == null) {
            console.log('socket is null, gateway.js acknowledgeListener()')
            return
        } 
        socket.on("ModifyEquipment", data => {
            if(_this.PopupNotificationRef === undefined) return
            
            if(data.result == "Success") {                        
                _this.PopupNotificationRef.addNotificationPopup("Update Success!", "LoraWAN gateway is updated.", "")
                _this.refresh()     //Update the current popup data
            }
            else {
                _this.PopupNotificationRef.addNotificationPopup("Update Failed!", "Failed Message: " + data.message, "")
            }
        })
    }
    getEquipments() {
        const {UserInfo} = this.props
        if (UserInfo == null) return
        const {userID,client_id} = UserInfo

        this.props.dispatch({
            type: 'EmitSocket',
            EmitSocketName: "EquipmentManageRequest",
            EmitSocketData: {
                "msgType": "GetEquipmentList",
                "userID": userID,
                "client_id": client_id,
            }
        })
    }
    ClosePopup() {
        this.setState({
            isShowEditPopup: false,
            selectedData:null
        })
    }
    editRow() {
        this.setState({
            isShowEditPopup: true
        })
    }
    setSelected(data) { 
        this.setState({ 
            selectedData: data,
            isShowEditPopup: true
        })
    }
    render() { 
        const {EquipmentList, sortedList, filteredList, pageSliceList, updateCount,selectedData} = this.state
        const _this = this
        return (
            <div className="CBGateway">
                <div className="CBGateway_title">LoRaWAN Gateway Management</div>
                <Searchbar isAdvanceSearch={true} closeAdSearch={_this.closeAdSearch}
                    refresh={_this.refresh}
                    coldboxList={EquipmentList} UpdateList={_this.UpdateList}
                    setStartTime={_this.setStartTime} setEndTime={_this.setEndTime} />
                <div className="CBGateway_table">
                    <TableHead filteredList={filteredList} UpdateList={_this.UpdateList} />
                    {
                        (pageSliceList !== null && pageSliceList !== undefined)?
                            pageSliceList.map((data) => {
                                return <Table
                                    data={data}
                                    acknowledgeUpdate={_this.acknowledgeUpdate}
                                    editRow={_this.editRow}
                                    setSelected={_this.setSelected}
                                />
                            })
                            :
                            ''
                    }
                </div>
                <div className="CBGateway_bottom">
                    <Pagebtns sortedList={sortedList} UpdateList={_this.UpdateList} count={updateCount} />
                </div>
                <Coldbox_popup
                    data = {selectedData}
                    isPopup={_this.state.isShowEditPopup}
                    closePopup={_this.ClosePopup}
                    reloadData = {_this.refresh}
                ></Coldbox_popup>
                <PopupNotification onRef={ref => {this.PopupNotificationRef = ref}} />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        socket: state.socket,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate, //when this value is updated, render would triggered
        UserInfo: state.UserInfo,
        EquipmentList: state.EquipmentList
    }
}
export default connect(mapStateToProps)(ColdboxGateway)

const returnFunc = {
    returnStatus: (objStatus) => {
        if (objStatus === null || objStatus === undefined) return 'Disconnected'
        if (objStatus.length == 0) return 'Disconnected'
        return objStatus[0].status.status
    },
    getLocation: (dataLoc) => {
        if (dataLoc == [] || dataLoc == null || dataLoc == undefined) return ''        
        if (typeof(dataLoc) !== 'object' || dataLoc.length == 0) return ''
    
        let loc = dataLoc[0]
        if (loc !== undefined && loc !== null) {
            let loc2 = loc.nestedLocs
            if(loc2 !== undefined && loc2!== null) return loc2.locName
        }
        return '' 
    },
    getZone: (dataZone) => {
        if (dataZone == [] || dataZone == null || dataZone == undefined) return ''
        if (typeof(dataZone) !== 'object' || dataZone.length == 0) return ''
    
        let z = dataZone[0]
        if (z !== undefined && z !== null) return z.name
        return ''
    },
    getUpdateTime: (equipData) => {
        if(equipData==null || equipData.length==null || equipData.length==0) return

        return equipData[0].lastUpdateTime
    }
    // convertDate: (DateInput) => {
    //     return DateInput.toISOString()
    // }
}