import React, {useEffect, useState} from 'react'
import './form.scss'

const DynamicCardForm = props => {
    const {Config} = props
    const [input, setInput] = useState(null)
    const [tab, setTab] = useState('Basic') //basic, location, types, rows
    const [expandkey, setExpandKey] = useState('') //select key in rows config

    useEffect(() => {
        setInput(Config)
    }, [Config])

    const updateParent = newInput => {
        setInput(newInput)
        props.updateData(newInput)
    }

    if(Config == null) return <div>Card Data is missing!</div>
    if(input == null) return <div>loading ...</div>
    return <div className='form'>
        <select className='tabList' onChange={(e) => setTab(e.target.value)}>
            <option value='Basic'>Basic</option>
            <option value='Location'>Location</option>
            <option value='Types'>Types</option>
            <option value='Rows'>Rows</option>
        </select>
        {
            inputFields.filter(item => item.tabId==tab).map(item => {
                const {tabId, id, keyFields, name, htmlType} = item
                let value = input
                switch(htmlType) {
                    case 'input':
                        keyFields.forEach(key => {
                            value = value[key]
                        })
                        return <div className='row'>
                            <div className='name'>{name}</div>
                            <input key={name} placeholder={id} defaultValue={value} onChange={e=>InputChange(e, updateParent, input, item)} />
                        </div>
                    case 'inputList':
                        keyFields.forEach(key => {
                            value = value[key]
                        })
                        return <div className='row'>
                            <div className='name'>{name}</div>
                            <input key={name} placeholder={id} defaultValue={value.join(', ')} onChange={e=>InputListChange(e, updateParent, input, item)} />
                        </div>
                    case 'msg':
                        return <div style={{color: 'black'}}>{name}</div>
                    case 'RowList':
                        keyFields.forEach(key => {
                            value = value[key]
                        })
                        return <div className='payloadFieldform'>
                            {
                                value.map(readingItem => {
                                    return [
                                        <div className='payloadField' onClick={()=>setExpandKey(readingItem.key)}>{readingItem.key}</div>,
                                        RowsFields.filter(configItem => {
                                            return configItem.isBool==readingItem.isBool || configItem.isBool==null
                                        }).map(configItem => {
                                            if(expandkey!==readingItem.key) return <></>
                                            let rowValue = readingItem
                                            if(configItem.htmlType=='input') {
                                                configItem.keyFields.forEach(key => {
                                                    rowValue = rowValue[key]
                                                })
                                                return <div className='row'>
                                                    <div className='name'>{configItem.name}</div>
                                                    <input key={configItem.name} placeholder={id} defaultValue={rowValue} onChange={e=>rowInputChange(e, updateParent, input, configItem, keyFields)} />
                                                </div>
                                            }
                                            if(configItem.htmlType=='inputList') {
                                                configItem.keyFields.forEach(key => {
                                                    rowValue = rowValue[key]
                                                })
                                                return <div className='row'>
                                                    <div className='name'>{configItem.name}</div>
                                                    <input key={configItem.name} placeholder={id} defaultValue={rowValue.join(', ')} onChange={e=>rowInputListChange(e, updateParent, input, configItem, keyFields)} />
                                                </div>
                                            }
                                            if(configItem.htmlType=='msg') {
                                                return <div style={{color: 'black'}}>{configItem.name}</div>
                                            }
                                        })
                                    ]
                                })
                                
                            }
                        </div>
                }
            })
        }
    </div>
}
export default DynamicCardForm

const inputFields = [
    {
        tabId: 'Basic',
        id: 'cardId',
        keyFields: ['cardId'],
        name: 'cardId:',
        htmlType: 'input'
    },
    {
        tabId: 'Basic',
        name: 'Device Type',
        htmlType: 'msg'
    },
    {
        tabId: 'Basic',
        id: 'deviceTypestr',
        keyFields: ['deviceType', 'str'],
        name: 'device Type str:',
        htmlType: 'inputList'
    },
    {
        tabId: 'Basic',
        id: 'deviceTypewhereToGet',
        keyFields: ['deviceType', 'whereToGet'],
        name: 'devicetype from:',
        htmlType: 'inputList'
    },
    {
        tabId: 'Basic',
        id: 'typeIds',
        keyFields: ['typeIds'],
        name: 'typeid (1-3):',
        htmlType: 'inputList'
    },
    {
        tabId: 'Basic',
        name: 'Device Type',
        htmlType: 'msg'
    },
    {
        tabId: 'Basic',
        id: 'Padding',
        keyFields: ['size', 'padding'],
        name: 'Padding:',
        htmlType: 'input'
    },
    {
        tabId: 'Basic',
        id: 'aspectRatio',
        keyFields: ['size', 'aspectRatio'],
        name: 'Aspect Ratio:',
        htmlType: 'input'
    },
    {
        tabId: 'Basic',
        id: 'readingRow_height',
        keyFields: ['size', 'readingRow_height'],
        name: 'Row Height:',
        htmlType: 'input'
    },
    //-----------------------------------------------------
    {
        tabId: 'Location',
        id: 'titleSize',
        keyFields: ['title', 'titleSize'],
        name: 'Title Size:',
        htmlType: 'input'
    },
    {
        tabId: 'Location',
        id: 'titleColor',
        keyFields: ['title', 'titleColor'],
        name: 'Title Color:',
        htmlType: 'input'
    },
    {
        tabId: 'Location',
        id: 'subtitleSize',
        keyFields: ['title', 'subtitleSize'],
        name: 'Sub Title Size:',
        htmlType: 'input'
    },
    {
        tabId: 'Location',
        id: 'subtitleColor',
        keyFields: ['title', 'subtitleColor'],
        name: 'Sub Title Color:',
        htmlType: 'input'
    },
    {
        tabId: 'Location',
        name: 'Last Update',
        htmlType: 'msg'
    },
    {
        tabId: 'Location',
        id: 'datetimeFormat',
        keyFields: ['lastUpdate', 'datetimeFormat'],
        name: 'datetime format:',
        htmlType: 'input'
    },
    {
        tabId: 'Location',
        id: 'lastUpdatesize',
        keyFields: ['lastUpdate', 'size'],
        name: 'Font size:',
        htmlType: 'input'
    },
    //-----------------------------------------------------
    {
        tabId: 'Types',
        name: '1',
        htmlType: 'msg'
    },
    {
        tabId: 'Types',
        id: '1isTitle',
        keyFields: ['types', '1', 'title', 'isTitle'],
        name: 'Show Title:',
        htmlType: 'input'
    },
    {
        tabId: 'Types',
        id: '1isSubTitle',
        keyFields: ['types', '1', 'title', 'isSubTitle'],
        name: 'Show Sub Title:',
        htmlType: 'input'
    },
    {
        tabId: 'Types',
        id: '1isShowLastUpdate',
        keyFields: ['types', '1', 'isShowLastUpdate'],
        name: 'Show Last Update:',
        htmlType: 'input'
    },
    {
        tabId: 'Types',
        name: '2',
        htmlType: 'msg'
    },
    {
        tabId: 'Types',
        id: '2isTitle',
        keyFields: ['types', '2', 'title', 'isTitle'],
        name: 'Show Title:',
        htmlType: 'input'
    },
    {
        tabId: 'Types',
        id: '2isSubTitle',
        keyFields: ['types', '2', 'title', 'isSubTitle'],
        name: 'Show Sub Title:',
        htmlType: 'input'
    },
    {
        tabId: 'Types',
        id: '2isShowLastUpdate',
        keyFields: ['types', '2', 'isShowLastUpdate'],
        name: 'Show Last Update:',
        htmlType: 'input'
    },
    {
        tabId: 'Types',
        name: '3',
        htmlType: 'msg'
    },
    {
        tabId: 'Types',
        id: '3isTitle',
        keyFields: ['types', '3', 'title', 'isTitle'],
        name: 'Show Title:',
        htmlType: 'input'
    },
    {
        tabId: 'Types',
        id: '3isSubTitle',
        keyFields: ['types', '3', 'title', 'isSubTitle'],
        name: 'Show Sub Title:',
        htmlType: 'input'
    },
    {
        tabId: 'Types',
        id: '3isShowLastUpdate',
        keyFields: ['types', '3', 'isShowLastUpdate'],
        name: 'Show Last Update:',
        htmlType: 'input'
    },
    //-----------------------------------------------------
    {
        tabId: 'Rows',
        id: 'columns',
        keyFields: ['readings', ['columns']],
        name: 'Columns',
        htmlType: 'input'
    },
    {
        tabId: 'Rows',
        name: '1',
        htmlType: 'msg'
    },
    {
        tabId: 'Rows',
        keyFields: ['readings', ['1']],
        htmlType: 'RowList'
    },
    {
        tabId: 'Rows',
        name: '2',
        htmlType: 'msg'
    },
    {
        tabId: 'Rows',
        keyFields: ['readings', ['2']],
        htmlType: 'RowList'
    },
    {
        tabId: 'Rows',
        name: '3',
        htmlType: 'msg'
    },
    {
        tabId: 'Rows',
        keyFields: ['readings', ['3']],
        htmlType: 'RowList'
    }
]
const RowsFields = [
    {
        isBool: null,
        keyFields: ['key'],
        id: 'Key',
        name: 'Key',
        htmlType: 'input'
    },
    {
        isBool: null,
        keyFields: ['title'],
        id: 'rowtitle',
        name: 'title',
        htmlType: 'input'
    },
    {
        isBool: null,
        keyFields: ['isBool'],
        id: 'rowisBool',
        name: 'Is Boolean',
        htmlType: 'input'
    },

    {
        isBool: false,
        keyFields: ['decimal'],
        id: 'decimal',
        name: 'Decimal',
        htmlType: 'input'
    },
    {
        isBool: false,
        keyFields: ['unit'],
        id: 'unit',
        name: 'Unit',
        htmlType: 'input'
    },
    {
        isBool: false,
        name: 'List order: title, icon, reading, unit',
        htmlType: 'msg'
    },
    {
        isBool: true,
        name: 'List order: title, icon, display',
        htmlType: 'msg'
    },
    {
        isBool: null,
        keyFields: ['size_w'],
        id: 'Width',
        name: 'Width',
        htmlType: 'inputList'
    },
    {
        isBool: false,
        keyFields: ['size_font'],
        id: 'FontSize',
        name: 'Font Size',
        htmlType: 'inputList'
    },
    {
        isBool: true,
        name: 'Bold / 100 / 200 / 300 / 400 / 500',
        htmlType: 'msg'
    },
    {
        isBool: false,
        keyFields: ['weight_font'],
        id: 'FontWeight',
        name: 'Font Weight',
        htmlType: 'inputList'
    },
    {
        isBool: false,
        keyFields: ['color'],
        id: 'FontColor',
        name: 'Font Color',
        htmlType: 'inputList'
    },
    {
        isBool: null,
        keyFields: ['align_v'],
        id: 'align_v',
        name: 'Align up/dwn',
        htmlType: 'inputList'
    },
    {
        isBool: null,
        keyFields: ['align_h'],
        id: 'align_h',
        name: 'Align L/R',
        htmlType: 'inputList'
    },
    {
        isBool: null,
        keyFields: ['order'],
        id: 'order',
        name: 'Display Order',
        htmlType: 'inputList'
    },
    {
        isBool: false,
        name: 'center under_Reading Upper Bottom',
        htmlType: 'msg'
    },
    {
        isBool: false,
        keyFields: ['unitPosition'],
        id: 'unitPosition',
        name: 'Unit Position',
        htmlType: 'input'
    },

    {
        isBool: true,
        name: 'True (title, icon, display)',
        htmlType: 'msg'
    },
    {
        isBool: true,
        keyFields: ['trueDisplay', 'colors'],
        id: 'TrueFontColor',
        name: 'Font Color',
        htmlType: 'inputList'
    },
    {
        isBool: true,
        keyFields: ['trueDisplay', 'bg_color'],
        id: 'TrueBoxColor',
        name: 'Box Color',
        htmlType: 'input'
    },
    {
        isBool: true,
        name: 'False',
        htmlType: 'msg'
    },
    {
        isBool: true,
        keyFields: ['falseDisplay', 'colors'],
        id: 'FalseFontColor',
        name: 'Font Color',
        htmlType: 'inputList'
    },
    {
        isBool: true,
        keyFields: ['falseDisplay', 'bg_color'],
        id: 'FalseBoxColor',
        name: 'Box Color',
        htmlType: 'input'
    },
    {
        isBool: true,
        keyFields: ['inColorBox'],
        id: 'inColorBox',
        name: 'Use Color Box',
        htmlType: 'input'
    },
    {
        isBool: true,
        keyFields: ['statusPosition'],
        id: 'statusPosition',
        name: 'Status Text Position',
        htmlType: 'input'
    },
]

const InputChange = (e, updateParent, input, item) => {
    let value = e.target.value
    let newInput = input

    const {keyFields} = item
    switch(keyFields.length) {
        case 1:
            newInput[keyFields[0]] = value
            break
        case 2:
            newInput[keyFields[0]][keyFields[1]] = value
            break
        case 3:
            newInput[keyFields[0]][keyFields[1]][keyFields[2]] = value
            break
        case 4:
            newInput[keyFields[0]][keyFields[1]][keyFields[2]][keyFields[3]] = value
            break
        case 5:
            newInput[keyFields[0]][keyFields[1]][keyFields[2]][keyFields[3]][keyFields[4]] = value
            break
    }
    updateParent(newInput)
}
const InputListChange = (e, updateParent, input, item) => {
    let value = e.target.value
    try {
        value = value.split(', ')
    }
    catch(err) {
        console.log(err)
    }
    let newInput = input

    const {keyFields} = item
    switch(keyFields.length) {
        case 1:
            newInput[keyFields[0]] = value
            break
        case 2:
            newInput[keyFields[0]][keyFields[1]] = value
            break
        case 3:
            newInput[keyFields[0]][keyFields[1]][keyFields[2]] = value
            break
        case 4:
            newInput[keyFields[0]][keyFields[1]][keyFields[2]][keyFields[3]] = value
            break
        case 5:
            newInput[keyFields[0]][keyFields[1]][keyFields[2]][keyFields[3]][keyFields[4]] = value
            break
    }
    updateParent(newInput)
}
const rowInputChange = (e, updateParent, input, configItem, keyFields) => {
    let value = e.target.value
    let newInput = input
    let configKeyField = configItem.keyFields
    //keyFields > configKeyField
    switch(configKeyField.length) {
        case 1:
            newInput[keyFields[0]][keyFields[1]][configKeyField[0]] = value
            break
        case 2:
            newInput[keyFields[0]][keyFields[1]][configKeyField[0]][configKeyField[1]] = value
            break
        case 3:
            newInput[keyFields[0]][keyFields[1]][configKeyField[0]][configKeyField[1]][configKeyField[2]] = value
            break
    }
    
    updateParent(newInput)
}
const rowInputListChange = (e, updateParent, input, configItem, keyFields) => {
    let value = e.target.value
    try {
        value = value.split(', ')
    }
    catch(err) {
        console.log(err)
    }
    let newInput = input
    let configKeyField = configItem.keyFields
    //keyFields > configKeyField
    switch(configKeyField.length) {
        case 1:
            newInput[keyFields[0]][keyFields[1]][configKeyField[0]] = value
            break
        case 2:
            newInput[keyFields[0]][keyFields[1]][configKeyField[0]][configKeyField[1]] = value
            break
        case 3:
            newInput[keyFields[0]][keyFields[1]][configKeyField[0]][configKeyField[1]][configKeyField[2]] = value
            break
    }
    
    updateParent(newInput)
}