import React, { useEffect, useState } from 'react'
import './public.css'
// import logo from './image/logo.png'
import Moment from 'react-moment'
import { getFloor, getSensorList } from './getSensorList'

const UPDATEINTERVAL = 5

const CCGHTML = props => {
    const [timer, setTimer] = useState({
        lastUpdate: null,
        lastUpdateTime: null
    })
    const {floor, res, forceUpdate} = props
    const {temp, hum, pm10, co2} = getDataFromRes(res, floor)

    useEffect(() => {
        setTimeout(() => {
            const sensorList = getSensorList(getFloor())
            let timestamps = []
            sensorList.forEach(sensorID => {
                if(res[sensorID]!=null&&res[sensorID].timestamp!=null) timestamps.push(res[sensorID].timestamp)
            })
            const lastUpdateList = timestamps.map(item => Date.parse(item))
            const finalastUpdate = Math.max(...lastUpdateList)
            setTimer({
                lastUpdate: <Moment format='DD MMMM'>{new Date(+finalastUpdate)}</Moment>,
                lastUpdateTime: <Moment format='HH:mm'>{new Date(+finalastUpdate)}</Moment>
            })
        }, 1000 * UPDATEINTERVAL)
    }, [timer])

    return <div className='CCGPubliccontainer'>
        <dl id="dashboard">
            <dt>Last update: <label>{timer.lastUpdate}, {timer.lastUpdateTime}</label></dt>
            <dd className="temp">
            <div><label>{temp.toFixed(1)}</label><sup>o</sup>C</div>
            <div>Indoor</div>
            </dd>
            <dd className="hum">
            <div><label>{hum.toFixed(1)}</label>%</div>
            <div>Indoor</div>
            </dd>
            <dd className="pm10">
            <div><label>{pm10.toFixed(1)}</label></div>
            <div>CO<sub>2</sub> ppm</div>
            </dd>
            <dd className="co2">
            <div><label>{co2.toFixed(1)}</label></div>
            <div>PM<sub>10</sub> µg/m<sup>3</sup></div>
            </dd>
        </dl>
    </div>
}
export default CCGHTML

const getDataFromRes = (res, floor) => {
    const emptyReturn = {
        temp: 0,
        hum: 0,
        pm10: 0,
        co2: 0
    }
    const sensorList = getSensorList(getFloor())
    let Temp_avg = 0
    let CO2_avg = 0
    let PM10_avg = 0
    let RH_avg = 0
    let count = 0
    sensorList.forEach(sensorID => {
        if(sensorID==null||res[sensorID]==null) return
        const readingList = res[sensorID].readings.split('|')

        readingList.forEach(readingItem => {
            // if(readingItem.includes('Temp')) console.log(Number(readingItem.split(':')[1].toString()))
            if(readingItem.includes('Temp')) Temp_avg += Number(readingItem.split(':')[1].toString())
            if(readingItem.includes('RH')) RH_avg += Number(readingItem.split(':')[1].toString())
            if(readingItem.includes('CO2')) CO2_avg += Number(readingItem.split(':')[1].toString())
            if(readingItem.includes('PM10')) PM10_avg += Number(readingItem.split(':')[1].toString())
        })
        count++
    })

    if(count==0) return emptyReturn

    return {
        temp: Temp_avg/count,
        hum: RH_avg/count,
        pm10: PM10_avg/count,
        co2: CO2_avg/count,
    }
}