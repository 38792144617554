import React from 'react'
import './FloorPlan.css'
//Redux Import
import { connect } from 'react-redux'
//Component Import
import Side from '../Side/Side'
import DragLayout from './DragLayout/DragLayout'
//Drag lib
import RGL, { WidthProvider } from 'react-grid-layout'
import _ from "lodash"

//Drag init
const ReactGridLayout = WidthProvider(RGL)

class FloorPlan extends React.Component {
    constructor(props) {
        super(props)

        this.MenuPick = this.MenuPick.bind(this)
        this.WidgetLock = this.WidgetLock.bind(this)

        this.state = {
            LockWidget: false
        }
    }
    
    MenuPick() {     
        this.props.dispatch({ type: 'MenuButton' })
    }

    WidgetLock() {
        this.setState({
            LockWidget: !this.state.LockWidget
        })

        this.child.UpdateWidgetLockUnlock()
    }

    componentDidMount() {
        //if socket is not exist, return
        if(this.props.socket == null) {
            console.error('the socket value is null! Cannot set the socket Listener')
            return
        }
        //remove listeners
        //this.props.socket.removeAllListeners();
        
        //set the listner
        /*this.props.socket.on("DashboardData", data => {
            //console.log("DashboardData()");
            //console.log(data);
            //run the redux function to insert the DashboardData
            thisoutside.props.dispatch({ type: 'DashboardDataLoaded', 
                data: data
            });
        });*/

        //save current url to redux
		let pathUrl = "/Main/FloorPlan"
        this.props.dispatch({ type: 'LatestPage', data: pathUrl })
        
        /*thisoutside.props.dispatch({ type: 'EmitSocket', 
            EmitSocketName: "DashboardData",
            EmitSocketData: {}
        });*/

        window.addEventListener('resize', this.resize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }

    resize = () => this.forceUpdate()

    componentDidCatch(error, info) {
        console.error('componentDidCatch() on FloorPlan.js')
        console.error(error)
        console.error(info)
        //redirect to App.js to reload all the datas
        this.props.history.push("/App")
    }

    render() {
        const _this = this
        const { history, MenuDisplay, UserInfo } = this.props
        if(UserInfo == null) return <div>UserInfo Missing</div>

        const IsLockWidget = this.state.LockWidget

        const LockButtonClass = IsLockWidget?'unLock_Widget_Button':'Lock_Widget_Button'
        const LockButtonIcon = IsLockWidget?'unlock':'lock'

        const IsShowLockBtn = MenuDisplay?'':'showLockButton'

        return (
            <div className='body' key={ 'MainbodyOutsideDiv' }>
                <div className={'menu_div h-auto ' + IsShowLockBtn}>
                    <button className={LockButtonClass} onClick={this.WidgetLock} style={{display: IsShow()?'':'none'}}>
                        <i className={'fa fa-' + LockButtonIcon}></i>
                    </button>
                </div>
                <div className='floor_base'>
                    <DragLayout isLock={IsLockWidget} onRef={ref => (this.child = ref)} rowHeight={(window.innerHeight - 75)/20} history={history} WidgetLock={_this.WidgetLock} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
      count: state.count,
      log: state.log,
      socket: state.socket,
      level: state.level,
      currentlevel: state.currentlevel,
      SiteList: state.SiteList,
      currentSite: state.currentSite,
      MenuDisplay: state.MenuDisplay,
      currentBottomMenu: state.currentBottomMenu,
      currentBottomCard: state.currentBottomCard,
      DashboardNeedReload: state.DashboardNeedReload,
      DashboardData: state.DashboardData,
      DeviceDataLastUpdate: state.DeviceDataLastUpdate,
      UserInfo: state.UserInfo,
      DashboardPickedColdboxID: state.DashboardPickedColdboxID
    }
}
export default connect(mapStateToProps)(FloorPlan)

const IsShow = () => {
    return window.IoTConfig==null // when localhost, config is empty, so show the lock button
}