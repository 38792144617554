import './LiftRecords.css'
import React from 'react'
//Redux Import
import { connect } from 'react-redux'

class LiftRecords extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            LiftLevelList: [
                "B",
                "G",
                "M",
                "1",
                "2",
                "3",
                "4",
            ],
            floorDetect: null,        //when componentDidUpdate changed something, set this state > determined lift move direction
            direction: "angle-up",              //direction to display lift movement
        };
    }
    componentDidUpdate(preProps) {
        const {floorDetect} = this.state
        const {UserInfo, currentSite, currentlevel, DeviceData} = this.props
        let Sitename = "--"
        let SiteDesc = "--"
        try {
            Sitename = UserInfo.locations[currentSite].locName
            SiteDesc = UserInfo.locations[currentSite].locDesc
        }
        catch(err) {
            console.log(err);
        }
        let LiftlevelStr = ""
        let LiftDirection = ""
        let z_accel = 0
        let floorDetection = null
        // let preFloorDetection = null
        try {
            const siteItem = DeviceData[currentSite]
            const levelItem = siteItem.nestedLocs.find(function(levelItem) {        //**levelItem == lift's name in lift widget
                return levelItem.locName == currentlevel
            })
            const LiftPayload = levelItem.nestedLocs[0].payload
            LiftlevelStr = LiftPayload.floor.toString()
            LiftDirection = LiftPayload.liftMovement.toString()
            z_accel = LiftPayload.accel_Z.toString()
            floorDetection = LiftPayload.floorDetection.toString()
        }
        catch(err) {
            console.log(err)
        }

        const thisoutside = this
        if(floorDetect != floorDetection) {
            //when in range to out of range, check the z-accel
            let Number_z_accel = Number(z_accel)
            //set the arrow up or down
            let direction = ""
            if(Number_z_accel > -0.38) direction = "angle-up"
            else if(Number_z_accel < -0.42) direction = "angle-down"
            // console.log("direction: " + direction)
            if(direction != "") thisoutside.setState({
                floorDetect: floorDetection,
                direction: direction,
            })
        }
        // console.log('z_accel: ' + z_accel)
        // console.log('floorDetection: ' + floorDetection)
        // console.log('state floorDetect: ' + floorDetect)
    }
    componentDidMount() {
        const LeveList = GetLiftLevelList(this.props)
        if(LeveList == null) return

        this.setState({
            LiftLevelList: LeveList
        })
    }
    render() {
        const {LiftLevelList, direction} = this.state
        const {UserInfo, currentSite, currentlevel, DeviceData} = this.props
        const {WidgetType} = this.props
        if(UserInfo == null) return <div></div>

        let Sitename = "--"
        let SiteDesc = "--"
        try {
            Sitename = UserInfo.locations[currentSite].locName
            SiteDesc = UserInfo.locations[currentSite].locDesc
        }
        catch(err) {
            console.log(err);
        }

        //switch to new widgets
        switch(WidgetType) {
            case "LevelBtnList":
                if(DeviceData == null) return <div></div>
                let LiftlevelStr = ""
                let LiftDirection = ""
                let z_accel = 0
                let floorDetection = null
                let isDoorClose = false
                try {
                    const siteItem = DeviceData[currentSite]
                    const levelItem = siteItem.nestedLocs.find(function(levelItem) {        //**levelItem == lift's name in lift widget
                        return levelItem.locName == currentlevel
                    })
                    const LiftPayload = levelItem.nestedLocs[0].payload
                    LiftlevelStr = LiftPayload.floor.toString()
                    LiftDirection = LiftPayload.liftMovement.toString()
                    z_accel = LiftPayload.accel_Z.toString()
                    floorDetection = LiftPayload.floorDetection.toString()
                    isDoorClose = LiftPayload.doorClose
                }
                catch(err) {
                    console.log(err)
                }
                let iconStr = ""
                let floorDetectBool = (floorDetection == "true")?true:false

                if(LiftDirection=='1') iconStr = 'fa-angle-up'
                else if(LiftDirection=='-1') iconStr = 'fa-angle-down'
                else if(LiftDirection=='') iconStr = "fa-"+direction     //direction deteremined in local state  //.. old get arrow method
                else iconStr = 'fa-sort'

                //if floorDetectBool shows in range, show stop arrow icon
                //if(floorDetectBool) iconStr = "fa-sort"
                //if door is closed, show double arrow icon
                if(isDoorClose=='0'||isDoorClose==0||isDoorClose==false||isDoorClose=='false') iconStr = 'fa-sort'

                var LiftLevelInt = parseInt(LiftlevelStr, 0);
                const displayLiftLvl = (LiftLevelList == null || LiftLevelList.length == null || LiftLevelList.length <= LiftLevelInt)?"-":LiftLevelList[LiftLevelInt]      //count item
                return <div className="LiftRecord_displayFloorContainer">
                    <div className="Lift_record_displayBG">
                        <div className="Lift_recordFloorDisplay">
                            <div className={"fa " + iconStr + " LiftRecord_DisplayIcon"}></div>
                            <div className="LiftRecord_displayFloorTxt">{displayLiftLvl} F</div>
                        </div>
                    </div>
                    <div className="Lift_record_companyIcon">
                    </div>
                </div>
                break
            case "LevelDisplay":
                if(DeviceData == null) return <div></div>
                var Liftlevel = ""
                try {
                    const siteItem = UserInfo.locations[currentSite]
                    const levelItem = siteItem.nestedLocs.find(function(levelItem) {        //**levelItem == lift's name in lift widget
                        return levelItem.locName == currentlevel
                    })
                    const LiftPayload = levelItem.nestedLocs[0].payload
                    Liftlevel = LiftPayload.floor.toString()
                }
                catch(err) {
                    console.log(err)
                }
                return <div className="LiftRecord_ListFloormain">
                    <div className="LiftRecord_ListFloorContainer" style={{width: (LiftLevelList.length ==null || LiftLevelList.length <=10?'5.5rem':'23.5rem')}}>
                        {LiftLevelList.map(function(data, i){
                            let CurrentLevelClass = "";
                            if(i.toString() == Liftlevel)       //if Current LiftLevel = Array[Count], change it to another color
                            {
                                CurrentLevelClass = "LiftCurrentLevelClass"
                            }
                            return <div className={"LiftRecord_LiftlevelItem " + CurrentLevelClass}>
                                <div>{data}</div>
                            </div>
                        })}
                    </div>
                </div>
                break
            case "LiftDetail":
                const {configStorage} = this.props
                const ImgPath = configStorage.imgUrl + '/assets/images/floors/' +  UserInfo.locations[currentSite].imgUrl + '/default.jpg'
                return <div className="LiftRecord_LiftName" style={{backgroundImage: 'url(' + ImgPath + ')', backgroundPosition: 'center', backgroundSize: 'cover'}}>
                    <div className="LiftName_SiteName">
                        {Sitename}
                    </div>
                    <div className="LiftName_SiteDesc">
                        {SiteDesc}
                    </div>
                    <div className="LiftName_LiftName">
                        {"Lift " + currentlevel}
                    </div>
                    <div className="LiftName_LiftDesc">
                        Lift Monitoring Dashboard
                    </div>
                </div>
                break
            case "LiftSiteImg":
                return <div className="Lift_bottomSiteDetail">
                    <div className="Lift_bottomSiteName">
                        Monitor Sensor Location
                    </div>
                    <div className="Lift_bottomPhotoContainer">
                        <div className="Lift_bottomImage_1">
                        </div>
                        <div className="Lift_bottomImage_2">
                        </div>
                    </div>
                </div>
                break
        }
    }
}

function mapStateToProps(state) {
    return {
      socket: state.socket,
      UserInfo: state.UserInfo,
      currentSite: state.currentSite,
      currentlevel: state.currentlevel,
      DeviceData: state.DeviceData,
      configStorage: state.configStorage,
      DeviceDataLastUpdate: state.DeviceDataLastUpdate,
    }
}
export default connect(mapStateToProps)(LiftRecords)





function GetLiftLevelList(props) {
    const {DeviceData, currentlevel, currentSite} = props
    if(DeviceData == null || currentSite == null || currentlevel == null) return null

    const LevelList = DeviceData[currentSite].nestedLocs

    if(LevelList == null) return null

    const LevelItem = LevelList.find(item => {
        return item.locName == currentlevel
    })

    if(LevelItem == null) return null

    const LocationPayload = LevelItem.nestedLocs[0].payload
    const {LiftLevels} = LocationPayload

    if(LiftLevels == null) return null

    return LiftLevels
}