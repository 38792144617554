/* 

Inputs:
Title: string to display in header
Icon:  Icon to display in Header

*/

import React from 'react'
import './head.css'

function HeaderWidget(props) {
    return <div className="LiftSummary_header">
        <div className={"fa fa-" + props.Icon}></div>
        <div className="LiftSummary_title">
            {props.Title}
        </div>
    </div>
}

export default HeaderWidget