export const doorStatus = (input) => {
    if(getLang()=='eng') return input

    if(input=='Open'||input=='open') return '開'
    return '關'
}
export const waterLeakage = (input) => {
    if(getLang()=='eng') return input

    if(input=='Normal'||input=='normal') return '正常'
    return '漏水'
}
export const OccDisplay = (input) => {
    if(getLang()=='eng') return input

    if(input=='Occupied' || input=='occupied') return '使用中'
    if(input=='No People' || input == 'No people' || input == 'no people') return '沒有人'
    if(input=='Disconnected' || input=='disconnected') return '服務器連接中斷'

    return '--'
}
export const DisablePark = (input) => {
    if(getLang()=='eng') return input

    if(input=='Occupy' || input=='occupy') return '使用中'
    if(input=='Not Occupy' || input=='Not occupy' || input=='not occupy') return '可使用'
    if(input=='Disconnected' || input=='disconnected') return '服務器連接中斷'
}
export const CarPark = (input) => {
    if(getLang()=='eng') return input

    if(input=='Occupy' || input=='occupy') return '使用中'
    if(input=='Not Occupy' || input=='Not occupy' || input=='not occupy') return '可使用'
    if(input=='Disconnected' || input=='disconnected') return '服務器連接中斷'
}

//sub func
function getLang() {
    const langStr = localStorage.getItem('lang')
    if(langStr==null||langStr=='') {
        localStorage.setItem('lang', 'eng')
        return 'eng'
    }
    return langStr
}