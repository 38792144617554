import React, { useEffect, useState } from 'react'
import './Position.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPumpSoap, faToiletPaper, faWater, faTint, faTintSlash, faExclamationCircle, faThermometerThreeQuarters, faMapMarker, faCloud, faWind } from '@fortawesome/free-solid-svg-icons'

function PositionWidget(props) {
    const item = props.item
    const {type, x, y, isFull, hasSoap, soapLevel, hasPaper, PaperLevel, flushingWater, waterLeakageStatus, h2s, so2} = item==null?{}:item
    const color2 = props.color2

    const {w, h} = props

    const [ShowDetail, setShowDetail] = useState(false)
    useEffect(()=> {
        if(ShowDetail == true) {
            setTimeout(() => {
                setShowDetail(false)
            }, 1000 * 10)
        }
    }, [ShowDetail])

    if(type == 'bin') {
        return <div className="Toilet_floor_plan_bin" style={{marginLeft: item.x * w +'rem', marginTop: item.y * h +'rem'}} onMouseOver={()=>setShowDetail(true)} onMouseLeave={()=>setShowDetail(false)}>
            {
                ShowDetail?
                <div className="Toilet_floor_plan_bin_detail" style={{color: isFull?'red':color2}}>
                    {isFull?"Full":"Available"}
                </div>
                :
                [
                    <div className="fa fa-trash"></div>,
                    <div className={isFull?"BinFull":"binempty"}></div>
                ]
            }
        </div>
    }
    if(type == 'soap') {
        let PercentLevel// = (100 - soapLevel * 100)
        let SoapColor = color2
        if(hasSoap == false) {
            PercentLevel = 90
            SoapColor = 'red'
        }
        else {
            PercentLevel = 5
        }
        return <div className="Toilet_floor_plan_soap" style={{marginLeft: item.x * w +'rem', marginTop: item.y * h +'rem'}} onMouseOver={()=>setShowDetail(true)} onMouseLeave={()=>setShowDetail(false)}>
            {
                ShowDetail?
                <div className="Toilet_floor_plan_bin_detail" style={{color: hasSoap?color2:'red'}}>
                    {/* {(soapLevel * 100).toFixed(0) + "%"} */}
                    {
                        hasSoap?"Has Soap":"Need Refill"
                    }
                </div>
                :
                [
                    <FontAwesomeIcon icon={faPumpSoap} className="fa" />,
                    <div class="Toilet_floor_plan_soap_wave" style={{background: 'linear-gradient(to bottom, transparent '+PercentLevel+'%, '+SoapColor+' '+PercentLevel+'%)'}}></div>
                ]
            }
        </div>
    }
    if(type == 'paper') {
        //paper
        let PercentLevel = (100 - PaperLevel * 30)  //max level is 5
        if(PercentLevel <=0) PercentLevel = 0
        if(PercentLevel >=100) PercentLevel = 100

        let TextLevel = PaperLevel * 30
        if(TextLevel >= 100) TextLevel = 100

        let PaperColor = color2
        if(hasPaper == false) {
            PercentLevel = 90
            PaperColor = 'red'
        }
        return <div className="Toilet_floor_plan_soap" style={{marginLeft: item.x * w +'rem', marginTop: item.y * h +'rem'}} onMouseOver={()=>setShowDetail(true)} onMouseLeave={()=>setShowDetail(false)}>
            {
                ShowDetail?
                <div className="Toilet_floor_plan_bin_detail" style={{color: hasPaper?color2:'red'}}>
                    {(TextLevel).toFixed(0) + "%"}
                </div>
                :
                [
                    <FontAwesomeIcon icon={faToiletPaper} className="fa" />,
                    <div class="Toilet_floor_plan_soap_wave" style={{background: 'linear-gradient(to bottom, transparent '+PercentLevel+'%, '+PaperColor+' '+PercentLevel+'%)'}}></div>
                ]
            }
        </div>
    }
    if(type == "waterLeakage") {
        const isLeakage = (waterLeakageStatus==1)
        return <div className="Toilet_floor_plan_soap" style={{marginLeft: item.x * w +'rem', marginTop: item.y * h +'rem'}} onMouseOver={()=>setShowDetail(true)} onMouseLeave={()=>setShowDetail(false)}>
            {
                ShowDetail?
                <div className="Toilet_floor_plan_bin_detail" style={{color: isLeakage?'red':color2}} >
                    {isLeakage?'Water Leakage':'No Leakage'}
                </div>
                :
                [
                    isLeakage?
                    <FontAwesomeIcon icon={faTint} className="fa" style={{color: 'red'}} />
                    :
                    <FontAwesomeIcon icon={faTintSlash} className="fa" style={{color: color2}} />
                ]
            }
        </div>
    }
    if(type == "flushingWater") {
        return <div className="Toilet_floor_plan_soap" style={{marginLeft: item.x * w +'rem', marginTop: item.y * h +'rem'}} onMouseOver={()=>setShowDetail(true)} onMouseLeave={()=>setShowDetail(false)}>
            {
                ShowDetail?
                <div className="Toilet_floor_plan_bin_detail" style={{color: !flushingWater?'red':color2}} >
                    {!flushingWater?'No Flushing Water':'Have Flushing Water'}
                </div>
                :
                [
                    !flushingWater?
                    <div className="flushwater_position">
                        <FontAwesomeIcon icon={faWater} className="fa" style={{color: color2}} />
                        <FontAwesomeIcon icon={faExclamationCircle} className="fa hover" style={{color: 'red'}} />
                    </div>
                    :
                    <FontAwesomeIcon icon={faWater} className="fa" style={{color: color2}} />
                ]
            }
        </div>
    }
    if(type == 'IAQ') {
        return <div className="Toilet_floor_plan_soap" style={{marginLeft: item.x * w +'rem', marginTop: item.y * h +'rem'}} onMouseOver={()=>setShowDetail(true)} onMouseLeave={()=>setShowDetail(false)}>
        {
            ShowDetail?
            <div className="Toilet_floor_plan_bin_detail" style={{color: color2}} >
                IAQ Sensor
            </div>
            :            
            <FontAwesomeIcon icon={faThermometerThreeQuarters} className="fa" style={{color: color2}} />
        }
        </div>
    }
    if(type == 'SO2|H2S') {
        return <div className="Toilet_floor_plan_soap" style={{marginLeft: item.x * w +'rem', marginTop: item.y * h +'rem'}} onMouseOver={()=>setShowDetail(true)} onMouseLeave={()=>setShowDetail(false)}>
        {
            ShowDetail?
            <div className="Toilet_floor_plan_bin_detail" style={{color: color2}}>
                {so2==null?'H2S':'SO2'}
                <br />
                {so2==null?h2s:so2}
            </div>
            :
            <FontAwesomeIcon icon={faWind} className="fa" style={{color: color2}} />
        }
        </div>
    }
    
    if(item==null) {
        return <div></div>
    }
    return <div className="Toilet_floor_plan_soap" style={{marginLeft: item.x * w +'rem', marginTop: item.y * h +'rem'}} onMouseOver={()=>setShowDetail(true)} onMouseLeave={()=>setShowDetail(false)}>
        item type undefined: {type}
    </div>
}

export default PositionWidget