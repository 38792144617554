import React from 'react';
//Redux Import
import { connect } from 'react-redux';

class SummaryCard4 extends React.Component {
    render() {
        if(this.props.DashboardData == null)
        {
            return <div>Data is missing..</div>
        }

        return <div className="smallcard_right_bottom">
            <div className="text-left small_head">
                <i className="fa-rotate-270 fa fa-battery-half headericon"></i>
                Battery Usage
            </div>
            <div className="flex justify-between flex-col small_body">
                <div className="flex flex-col level1 box4_level1">
                    <i className="battery icon_pplcount fa-rotate-270 fa fa-battery-half">                        
                    </i>
                    <div className="flex flex-row lbl_battery">
                        {this.props.DashboardData.Summary.BatteryUsage}
                        <div className="smallunit_battery">
                            %
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
      count: state.count,
      log: state.log,
      socket: state.socket,
      level: state.level,
      currentlevel: state.currentlevel,
      SiteList: state.SiteList,
      currentSite: state.currentSite,
      MenuDisplay: state.MenuDisplay,
      currentBottomMenu: state.currentBottomMenu,
      currentBottomCard: state.currentBottomCard,
      DashboardNeedReload: state.DashboardNeedReload,
      DashboardData: state.DashboardData,
      DeviceDataLastUpdate: state.DeviceDataLastUpdate
    };
}

export default connect(mapStateToProps)(SummaryCard4);