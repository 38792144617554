/**

Input:
Default: String to display for first droplist item
Options: array[] with:
{
    name: xxx
    value: xxx
}
onChangeFunction: Function trigger when droplist changed (event.target.value)

*/

import React from 'react'
import './droplist.css'

function DroplistWidget(props) {
    const {Default, Options, onChangeFunction} = props
    if(Default == null || Options == null) return           //exceptions
    
    return <select className="LiftSummary_droplist" onChange={(e) => onChangeFunction(e)}>
        <option value="0">
            {Default}
        </option>
        {
            Options.map(item => {
                return <option value={item.value}>
                    {item.name}
                </option>
            })
        }
    </select>
}

export default DroplistWidget