import React from 'react'
import { connect } from 'react-redux'
import './peopleCount.css'
import './BottomDisplayCards.css'
import LEDCard from './ledCard/ledCard'
import SoapCard from './soapLevel/soapLevel'
import ToiletPaperCard from './toiletPaper/toiletPaper'
import WaterLevelCard from './waterLevel/waterLevel'
import ValueCard from './valueCard/valueCard'           //multiple use card
import IEQCard from './IEQ/IEQ'
import SwimmingPoolCards from './swimming/swimmingCards/swimmingCards'
import './testcard'
import {GetLocName, GetLocDesc} from '../../../common/language/locDisplay'
import {doorStatus, waterLeakage, OccDisplay, DisablePark, CarPark} from './language'
import { isConvertWaterLeakageToWaterPump, isConvertToWaterLeakageAndPump } from '../MenuListItem'

class BottomDisplayCards extends React.Component {
    constructor(props) {
        super(props)
        this.bottomcardhover = this.bottomcardhover.bind(this)
    }
    bottomcardhover(IDNumberStr) {
        this.props.PickedCard('bottomcard_' + IDNumberStr) //highlight the card
        //check this cards's locName (polygon's location name under that floor)
        const {LocationName} = this.props
        //send the location name to redux
        this.props.dispatch({ type: 'PickPolygon', 
            data: LocationName
        })            //highlight the polygon inside Map.js
    }
    render() {
        const {cardnumber, cardType, nameID, deviceListItem, DeviceData, DeviceDataStatus, currentBottomCard, currentSite, currentlevel, DeviceDataLastUpdate} = this.props
        
        if(DeviceData == null) {
            if(DeviceDataStatus == 1) {
                return <div className="flex flex-col justify-between cardScrollable">
                    <div className="loadingUIMainDiv">
                        ...
                    </div>
                </div>
            }            
            else if(DeviceDataStatus == 4) {
                return <div className="flex flex-col justify-between cardScrollable">
                    <div className="loadingUIMainDiv">
                        No Response
                    </div>
                </div>
            }
            else if(DeviceDataStatus == 2) {
                return <div className="flex flex-col justify-between cardScrollable">
                    <div className="loadingUICardTxt">
                        No Reading
                    </div>
                </div>  
            }
        }
        const cardnumber_focus = "bottomcard_" + cardnumber
        const listfocus = (currentBottomCard === cardnumber_focus)?'listfocus2':''              // card className
        const listfocus_inner = (currentBottomCard === cardnumber_focus)?'listfocus_inner':''   // card className
        let fontsize = {
            fontSize: '2rem',
            margin: 'auto',
            marginRight: 0
        }

        // get the Payload Data from it
        const CurrentFloorItem = DeviceData[currentSite].nestedLocs.find(function(floorItem) {return floorItem.locName == currentlevel})
        const CurrentDeviceItem = CurrentFloorItem.nestedLocs.find(function(deviceItem) {
            return deviceItem._id == deviceListItem._id
        })

        const locName = GetLocName(deviceListItem)
        const locDesc = GetLocDesc(deviceListItem)
        try {
            if(deviceListItem.isSwimmingPool==true || cardType=='ATAL_Ctrl_8AI_8DI') {
                return <SwimmingPoolCards
                    hoverFunc={() => this.bottomcardhover(cardnumber)}
                    deviceListItem={deviceListItem}
                    cardnumber={cardnumber}
                    isFocus={currentBottomCard===cardnumber_focus}
                />
            }
            if(cardType == "Temperature & Humidity") {
                let readingTemp = 0
                let readingHumid = 0
                try { //get temp and humid, set values
                    readingTemp = CurrentDeviceItem.payload.temperature.toFixed(1)
                    readingHumid = CurrentDeviceItem.payload.humidity.toFixed(0)
                }
                catch(err) {
                    console.error('Read Card Data Failed')
                    readingTemp = "--"
                    readingHumid = "--"
                }
                return <div className={"flex flex-col justify-between cardScrollable " + listfocus} onMouseEnter={ () => this.bottomcardhover(cardnumber) } id={ "bottomcard_" + cardnumber } >
                    <div className="BottomCardInnerDiv">
                        <div className="BottomCardTitle">
                            <div className="BottomCardTitleLine">
                                {locName}
                            </div>
                        </div>
                        <div className={"BottomCardReadingBody temptextcolor " + listfocus_inner}>
                            <div className="flex flex-row justify-between BottomCardReadingLine">
                                <div className="flex flex-row BottomCardReadingDisplay">
                                    {readingTemp}
                                    <div className="flex flex-row BottomCardReadingSymbol">
                                        <div className="BottomCardReadingSymbolSmall">o</div>C
                                    </div>
                                </div>
                                <i className="fa fa-thermometer-half BottomCardIcon" style={fontsize}></i>
                            </div>
                            <div className={"BottomCardReadingLine humidtextcolor " + listfocus_inner}>
                                <div className="flex flex-row justify-between BottomCardReadingLine">
                                    <div className="flex flex-row BottomCardReadingDisplay">
                                        {readingHumid}
                                        <div className="flex flex-row BottomCardReadingSymbol">%</div>
                                    </div>
                                    <i className="fa fa-tint BottomCardIcon" style={fontsize}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            if (cardType == "Occupancy") {
                let OccupancyStatus = false
                try {
                    OccupancyStatus = CurrentDeviceItem.payload.roomOccupied==null?CurrentDeviceItem.payload.occupied:CurrentDeviceItem.payload.roomOccupied
                }
                catch(err) {
                    console.warn('Read Card Data Failed (card2)')
                    OccupancyStatus = false
                }
                
                let bottomCardOccupancy = ""
                let occuLbl = ""
                if(OccupancyStatus) {
                    bottomCardOccupancy = "bottomCardOccupancyTrue"
                    occuLbl = OccDisplay('Occupied')
                }
                else if(OccupancyStatus == false) {
                    bottomCardOccupancy = "bottomCardOccupancyFalse"
                    occuLbl = OccDisplay('No People')
                }
                else {
                    bottomCardOccupancy = "bottomCardOccupancyFalse"
                    occuLbl = OccDisplay('Disconnected"')
                }

                return <div className={"flex flex-col justify-between cardScrollable " + listfocus} onMouseEnter={ () => this.bottomcardhover(cardnumber) } id={ "bottomcard_" + cardnumber } >
                    <div className="BottomCardInnerDiv">
                        <div className="BottomCardTitle">
                            <div className="BottomCardTitleLine">
                                {locName}
                            </div>
                            <div className='locDescForBottomCard'>
                                {locDesc}
                            </div>
                        </div>
                        <div className={"BottomCardReadingBody peopletextcolor " + listfocus_inner}>
                            <div className="flex flex-row justify-between BottomCardReadingLine">
                                <div className={"flex flex-row BottomCardReadingDisplay occuLbl " + bottomCardOccupancy} style={{color: (currentBottomCard === cardnumber_focus)?'white':''}}>
                                    {occuLbl}
                                    <div className="flex flex-row BottomCardReadingSymbol"></div>
                                </div>
                                <i className={'fa fa-users BottomCardIcon ' + bottomCardOccupancy} style={{
                                    fontSize: '2rem',
                                    margin: 'auto',
                                    marginRight: 0,
                                    color: (currentBottomCard === cardnumber_focus)?'white':''
                                }}></i>
                            </div>
                        </div>
                    </div>
                </div>
            }
            if ((cardType == "IAQ" || cardType == "IEQ") && nameID != 'IAQ 15in1') {
                if(CurrentDeviceItem.payload==null) return <div className={"flex flex-col justify-between cardScrollable4Reading " + listfocus} onMouseEnter={ () => this.bottomcardhover(cardnumber) } id={ "bottomcard_" + cardnumber } >
                    <div className="BottomCardInnerDiv CO2BottomCard">
                        <div className="BottomCardTitle">
                            <div className="BottomCardTitleLine">
                                {locName}
                            </div>
                        </div>
                        <div className={"BottomCardReadingBody LitterBintextcolor " + listfocus_inner}>
                            No Data
                        </div>
                    </div>
                </div>

                let readingTemp = 0
                let readingHumid = 0
                let readingCO2 = 0
                let readingPM25 = 0
                try {
                    readingTemp = CurrentDeviceItem.payload.temperature
                    readingHumid = CurrentDeviceItem.payload.humidity
                    readingCO2 = CurrentDeviceItem.payload.co2
                    let co2ReadingNumber = Number(readingCO2)
                    readingCO2 = co2ReadingNumber.toFixed(0)
                    readingPM25 = CurrentDeviceItem.payload.pm2_5
                }
                catch(err) {
                    console.warn('Read Card Data Failed')
                }

                let TempNumber = Number(readingTemp)
                let HumidNumber = Number(readingHumid)
                readingTemp = TempNumber.toFixed(1)
                readingHumid = HumidNumber.toFixed(0)

                if(CurrentDeviceItem.payload != null && CurrentDeviceItem.payload.temperature != null && CurrentDeviceItem.payload.humidity != null && CurrentDeviceItem.payload.co != null
                    && CurrentDeviceItem.payload.co2 != null && CurrentDeviceItem.payload.hcho != null && CurrentDeviceItem.payload.pm2_5 != null && CurrentDeviceItem.payload.tvoc != null) {
                        return <IEQCard
                        payload={CurrentDeviceItem.payload}
                        locName={locName}
                        DeviceDataLastUpdate={DeviceDataLastUpdate}
                        isFocus={currentBottomCard===cardnumber_focus}
                        hoverFunc={() => this.bottomcardhover(cardnumber)}
                        cardnumber={cardnumber} />
                    // const coValue = CurrentDeviceItem.payload.co
                    // const hchoValue = CurrentDeviceItem.payload.hcho
                    // const pm2_5Value = CurrentDeviceItem.payload.pm2_5
                    // const tvocValue = CurrentDeviceItem.payload.tvoc
                    // const {UserInfo} = this.props
                    // return <div className={"IAQ_7-in-1 " + listfocus} onMouseEnter={ () => this.bottomcardhover(cardnumber) } id={ "bottomcard_" + cardnumber }>
                    //     <div className="IAQ_7-in-1_loc">
                    //         {locName}
                    //     </div>
                    //     <div className="IAQ_7-in-1_items">
                    //         <div className="IAQ_7-in-1_row">
                    //             <div className="IAQ_7-in-1_item">
                    //                 <div className="IAQ_7-in-1_Title">
                    //                     Temp
                    //                 </div>
                    //                 <div className="IAQ_7-in-1_Title" style={{color: IAQTxtColor('temp', readingTemp, UserInfo.client_id, currentBottomCard === cardnumber_focus)}}>
                    //                     {readingTemp}
                    //                 </div>
                    //             </div>
                    //             <div className="IAQ_7-in-1_item">
                    //                 <div className="IAQ_7-in-1_Title">
                    //                     Humid
                    //                 </div>
                    //                 <div className="IAQ_7-in-1_Title" style={{color: IAQTxtColor('humid', readingHumid, UserInfo.client_id, currentBottomCard === cardnumber_focus)}}>
                    //                     {readingHumid}
                    //                 </div>
                    //             </div>
                    //             <div className="IAQ_7-in-1_item">
                    //                 <div className="IAQ_7-in-1_Title">
                    //                     CO
                    //                 </div>
                    //                 <div className="IAQ_7-in-1_Title" style={{color: IAQTxtColor('CO', coValue, UserInfo.client_id, currentBottomCard === cardnumber_focus)}}>
                    //                     {coValue}
                    //                 </div>
                    //             </div>
                    //             <div className="IAQ_7-in-1_item">
                    //                 <div className="IAQ_7-in-1_Title">
                    //                     CO₂
                    //                 </div>
                    //                 <div className="IAQ_7-in-1_Title" style={{color: IAQTxtColor('CO2', readingCO2, UserInfo.client_id, currentBottomCard === cardnumber_focus)}}>
                    //                     {readingCO2}
                    //                 </div>
                    //             </div>
                    //             {
                    //                 CurrentDeviceItem.payload==null||CurrentDeviceItem.payload.o3==null?<></>
                    //                 :
                    //                 <div className="IAQ_7-in-1_item">
                    //                     <div className="IAQ_7-in-1_Title">
                    //                         O<sub>3</sub>
                    //                     </div>
                    //                     <div className="IAQ_7-in-1_Title" style={{color: IAQTxtColor('O3', CurrentDeviceItem.payload.o3, UserInfo.client_id, currentBottomCard === cardnumber_focus)}}>
                    //                         {Number(CurrentDeviceItem.payload.o3).toFixed(2)}
                    //                     </div>
                    //                 </div>
                    //             }
                    //             <div className="IAQ_7-in-1_item">
                    //                 <div className="IAQ_7-in-1_Title">
                    //                     HCHO
                    //                 </div>
                    //                 <div className="IAQ_7-in-1_Title" style={{color: IAQTxtColor('PM10', hchoValue, UserInfo.client_id, currentBottomCard === cardnumber_focus)}}>
                    //                     {hchoValue}
                    //                 </div>
                    //             </div>
                    //             <div className="IAQ_7-in-1_item">
                    //                 <div className="IAQ_7-in-1_Title">
                    //                     PM 2.5
                    //                 </div>
                    //                 <div className="IAQ_7-in-1_Title" style={{color: IAQTxtColor('Pm2.5', pm2_5Value, UserInfo.client_id, currentBottomCard === cardnumber_focus)}}>
                    //                     {pm2_5Value}
                    //                 </div>
                    //             </div>
                    //             <div className="IAQ_7-in-1_item">
                    //                 <div className="IAQ_7-in-1_Title">
                    //                     TVOC
                    //                 </div>
                    //                 <div className="IAQ_7-in-1_Title" style={{color: IAQTxtColor('TVOC', tvocValue, UserInfo.client_id, currentBottomCard === cardnumber_focus)}}>
                    //                     {tvocValue}
                    //                 </div>
                    //             </div>
                    //         </div>
                    //     </div>
                    // </div>

                }
                if(CurrentDeviceItem.payload.temperature != null && CurrentDeviceItem.payload.humidity != null && CurrentDeviceItem.payload.co2 != null && CurrentDeviceItem.payload.pm2_5 != null) { //for North point, there are 4 kinds of reading inside
                    return <div className={"flex flex-col justify-between cardScrollable4Reading " + listfocus} onMouseEnter={ () => this.bottomcardhover(cardnumber) } id={ "bottomcard_" + cardnumber } >
                        <div className="BottomCardInnerDiv reading4InnerDiv">
                            <div className="BottomCardTitle">
                                <div className="BottomCardTitleLine">
                                    {locName}
                                </div>
                            </div>
                            <div className="BtCard4Read_Container">
                                <div className="BtCard4Read_Row">
                                    <div className={"fa fa-thermometer-half BtCard4Read_icon color1 " + listfocus_inner}>
                                    </div>
                                    <div className={"BtCard4Read_Reading color1 " + listfocus_inner}>
                                        {readingTemp}
                                        <div className="BottomCardReadingSymbol">C</div>
                                    </div>
                                    <div className="BtCard4Read_space"></div>
                                    <div className={"fa fa-tint BtCard4Read_icon color2 " + listfocus_inner}>
                                    </div>
                                    <div className={"BtCard4Read_Reading color2 " + listfocus_inner}>
                                        {readingHumid}
                                        <div className="BottomCardReadingSymbol">%</div>
                                    </div>
                                </div>
                                <div className="BtCard4Read_Row">
                                    <div className={"BtCard4Read_Reading color3 " + listfocus_inner}>
                                        <div className={"BtCard4Read_Title color3 " + listfocus_inner}>
                                            CO
                                            <div className={"BtCard4Read_subTitle color3 " + listfocus_inner}>2</div>
                                        </div>
                                        {readingCO2}
                                    </div>
                                    <div className={"BtCard4Read_Reading color4 " + listfocus_inner}>
                                        <div className={"BtCard4Read_Title color4 " + listfocus_inner}>
                                            PM
                                            <div className={"BtCard4Read_subTitle " + listfocus_inner}>2.5</div>
                                        </div>
                                        {readingPM25}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                if(CurrentDeviceItem.payload.temperature != null && CurrentDeviceItem.payload.humidity != null && CurrentDeviceItem.payload.co2 != null && CurrentDeviceItem.payload.pm2_5 == null) {
                    //no pm25, have temp, humid and co2
                    return <div className={"flex flex-col justify-between cardScrollable4Reading " + listfocus} onMouseEnter={ () => this.bottomcardhover(cardnumber) } id={ "bottomcard_" + cardnumber } >
                        <div className="BottomCardInnerDiv reading4InnerDiv">
                            <div className="BottomCardTitle">
                                <div className="BottomCardTitleLine">
                                    {locName}
                                </div>
                            </div>
                            <div className="BtCard4Read_Container">
                                <div className="BtCard4Read_Row">
                                    <div className={"fa fa-thermometer-half BtCard4Read_icon color1 " + listfocus_inner}>
                                    </div>
                                    <div className={"BtCard4Read_Reading color1 " + listfocus_inner}>
                                        {readingTemp}
                                        <div className="BottomCardReadingSymbol">
                                            C
                                        </div>
                                    </div>
                                    <div className="BtCard4Read_space"></div>
                                    <div className={"fa fa-tint BtCard4Read_icon color2 " + listfocus_inner}>
                                    </div>
                                    <div className={"BtCard4Read_Reading color2 " + listfocus_inner}>
                                        {readingHumid}
                                        <div className="BottomCardReadingSymbol">
                                            %
                                        </div>
                                    </div>
                                </div>
                                <div className="BtCard4Read_Row">
                                    <div className={"BtCard4Read_Reading color3 " + listfocus_inner}>
                                        <div className={"BtCard4Read_Title color3 " + listfocus_inner}>
                                            CO  
                                            <div className={"BtCard4Read_subTitle color3 " + listfocus_inner}>
                                                2
                                            </div> 
                                        </div>
                                        {readingCO2}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                if(CurrentDeviceItem.payload.temperature == null && CurrentDeviceItem.payload.humidity == null) {
                    return <div className={"flex flex-col justify-between cardScrollable " + listfocus} onMouseEnter={ () => this.bottomcardhover(cardnumber) } id={ "bottomcard_" + cardnumber } >
                        <div className="BottomCardInnerDiv CO2BottomCard">
                            <div className="BottomCardTitle">
                                <div className="BottomCardTitleLine">
                                    {locName}
                                </div>
                            </div>
                            <div className={"BottomCardReadingBody LitterBintextcolor " + listfocus_inner}>
                                <div className="flex flex-row justify-between BottomCardReadingLine">
                                    <div className="flex flex-row BottomCardReadingDisplay">
                                        {readingCO2}
                                        <div className="flex flex-row BottomCardReadingSymbol">
                                            ppmv
                                        </div>
                                    </div>
                                    <i className="fa fa-cloud BottomCardIcon" style={fontsize}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                return <div className={"flex flex-col justify-between cardScrollable " + listfocus} onMouseEnter={ () => this.bottomcardhover(cardnumber) } 
                id={ "bottomcard_" + cardnumber } >
                    <div className="BottomCardInnerDiv">
                        <div className="BottomCardTitle">
                            <div className="BottomCardTitleLine">
                                {locName}
                            </div>
                        </div>
                        <div className={"BottomCardReadingBody temptextcolor " + listfocus_inner}>
                            <div className="flex flex-row justify-between BottomCardReadingLine">
                                <div className="flex flex-row BottomCardReadingDisplay">
                                    {readingTemp}
                                    <div className="flex flex-row BottomCardReadingSymbol">
                                        <div className="BottomCardReadingSymbolSmall">
                                            o
                                        </div>
                                        C
                                    </div>
                                </div>
                                <i className="fa fa-thermometer-half BottomCardIcon" style={fontsize}></i>
                            </div>
                            <div className={"BottomCardReadingLine humidtextcolor " + listfocus_inner}>
                                <div className="flex flex-row justify-between BottomCardReadingLine">
                                    <div className="flex flex-row BottomCardReadingDisplay">
                                        {readingHumid}
                                        <div className="flex flex-row BottomCardReadingSymbol">
                                            %
                                        </div>
                                    </div>
                                    <i className="fa fa-tint BottomCardIcon" style={fontsize}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            if (cardType == "LUX") {
                let reading = 0
                try {
                    reading = CurrentDeviceItem.payload.lightOn
                }
                catch(err) {
                    console.warn('Read Card Data Failed (card4)')
                }
                let displayreading
                let lightSymbol
                if(!reading) {
                    displayreading = "Off"
                    lightSymbol = "fa-lightbulb-o"
                }
                else {
                    displayreading = "On"
                    lightSymbol = "fa-lightbulb-o"
                }
                //for illuminance reading exist, show reading instead of on/off
                if(CurrentDeviceItem != null && CurrentDeviceItem.payload != null && CurrentDeviceItem.payload.illuminance != null) displayreading = CurrentDeviceItem.payload.illuminance
                return <div className={"flex flex-col justify-between cardScrollable " + listfocus} onMouseEnter={ () => this.bottomcardhover(cardnumber) } >
                    <div className="BottomCardInnerDiv">
                        <div className="BottomCardTitle">
                            <div className="BottomCardTitleLine">
                                {locName}
                            </div>
                        </div>
                        <div className={"BottomCardReadingBody LitterBintextcolor " + listfocus_inner}>
                            <div className="flex flex-row justify-between BottomCardReadingLine">
                                <div className="flex flex-row BottomCardReadingDisplay">
                                    {displayreading}
                                </div>
                                <div className="DashboardReadingUnit">lx</div>
                                <i className={"fa "+ lightSymbol +" BottomCardIcon"} style={fontsize}></i>
                            </div>
                        </div>
                    </div>
                </div>
            }
            if (cardType == "AC Power") {
                let reading = 0
                let HaveReading = false
                let isLargeNumber = ""              // class item for large number of reading
                try {
                    reading = CurrentDeviceItem.payload.acPower
                    HaveReading = true
                    if(reading >= 10000) isLargeNumber = " LargerCard "
                }
                catch(err) {
                    console.warn('Read Card Data Failed (ac power card)')
                    return <div className={"flex flex-col justify-between cardScrollable " + listfocus} onMouseEnter={ () => this.bottomcardhover(cardnumber) } >
                        <div className="BottomCardInnerDiv">
                            <div className="BottomCardTitle">
                                <div className="BottomCardTitleLine">
                                    {locName}
                                </div>
                            </div>
                            <div className={"BottomCardReadingBody powertextcolor " + listfocus_inner}>
                                <div className="flex flex-row justify-between BottomCardReadingLine">
                                    <div className="flex flex-row BottomCardReadingDisplay BottomCardACPower">
                                        0
                                        <div className="flex flex-row BottomCardReadingSymbol">
                                            kwh
                                        </div>
                                    </div>
                                    <i className="fa fa-bolt BottomCardIcon" style={fontsize}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                let displayreading
                if(!HaveReading) displayreading = "--.-"
                else displayreading = reading

                return <div className={"flex flex-col justify-between cardScrollable " + isLargeNumber + listfocus}onMouseEnter={ () => this.bottomcardhover(cardnumber) } id={ "bottomcard_" + cardnumber } >
                    <div className="BottomCardInnerDiv">
                        <div className="BottomCardTitle">
                            <div className="BottomCardTitleLine">
                                {locName}
                            </div>
                        </div>
                        <div className={"BottomCardReadingBody powertextcolor " + listfocus_inner}>
                            <div className="flex flex-row justify-between BottomCardReadingLine">
                                <div className="flex flex-row BottomCardReadingDisplay BottomCardACPower">
                                    {displayreading.toFixed(1)}
                                    <div className="flex flex-row BottomCardReadingSymbol">
                                        kwh
                                    </div>
                                </div>
                                <i className="fa fa-bolt BottomCardIcon" style={fontsize}></i>
                            </div>
                        </div>
                    </div>
                </div>
            }
            if(cardType == "Toilet Occupancy" || cardType == "Fall Detection") {
                if(deviceListItem.payload == null) return <div className={"flex flex-col justify-between cardScrollable " + listfocus} onMouseEnter={ () => this.bottomcardhover(cardnumber) } id={ "bottomcard_" + cardnumber } >
                    <div className="BottomCardInnerDiv">
                        <div className="BottomCardTitle">
                            <div className="BottomCardTitleLine">
                                {locName}
                            </div>
                        </div>
                        <div className={"BottomCardReadingBody powertextcolor " + listfocus_inner}>
                            <div className="FallDetectionBox">
                                No Data
                            </div>
                        </div>
                    </div>
                </div>

                const {fallDetected, roomOccupied, lastTriggerTime} = deviceListItem.payload
                const now = new Date()
                const lastTriggerTime_Date = Date.parse(lastTriggerTime)
                let min = (Math.floor((now - lastTriggerTime_Date)/60000))
                if(min > 120 || min == undefined || min == null || min.toString() == 'NaN') min = "--"

                return <div className={"flex flex-col justify-between cardScrollable " + listfocus} onMouseEnter={ () => this.bottomcardhover(cardnumber) } id={ "bottomcard_" + cardnumber } >
                    <div className="BottomCardInnerDiv">
                        <div className="BottomCardTitle">
                            <div className="BottomCardTitleLine">
                                {locName}
                            </div>
                        </div>
                        <div className={"BottomCardReadingBody powertextcolor " + listfocus_inner}>
                            <div className={roomOccupied?"FallDetectionBox Occupied":"FallDetectionBox Vacant"}>
                                <div className="BottomCardReadingDisplay">
                                    {roomOccupied?"Occupied":"Vacant"}
                                    {roomOccupied?<div className="OccupiedTimeRow">
                                        <div className="OccupiedRowDateTime">{(lastTriggerTime == null || lastTriggerTime == {})?"--":min + " min"}</div>
                                    </div>
                                    :<div></div>}
                                </div>
                                <i className="fa fa-user BottomCardIcon" style={fontsize}></i>
                            </div>
                            <div className={fallDetected?"FallDetectionBox FallDetected":"FallDetectionBox NoFallDetected"}>
                                <div className="BottomCardReadingDisplay">
                                    {fallDetected?"Fall Detected":"No Fall Alarm"}
                                </div>
                                <i className="fa fa-bell BottomCardIcon" style={fontsize}></i>
                            </div>
                        </div>
                    </div>
                </div>
            }
            if(cardType == "Rubbish Bin" || cardType === 'Waste Bin') {
                if(deviceListItem.payload == null || (deviceListItem.payload.fillDistance==null&&deviceListItem.payload.fillPercentage==null&&deviceListItem.payload.isFull==null)) return <div className={"flex flex-col justify-between cardScrollable " + listfocus} onMouseEnter={ () => this.bottomcardhover(cardnumber) } id={ "bottomcard_" + cardnumber } >
                    <div className="BottomCardInnerDiv">
                        <div className="BottomCardTitle">
                            <div className="BottomCardTitleLine">
                                {locName}
                            </div>
                        </div>
                        No Data
                    </div>
                </div>

                const {fillDistance, fillPercentage, isFull} = deviceListItem.payload

                return <div className={"flex flex-col justify-between cardScrollable " + listfocus} onMouseEnter={ () => this.bottomcardhover(cardnumber) } id={ "bottomcard_" + cardnumber } >
                    <div className="BottomCardInnerDiv">
                        <div className="BottomCardTitle">
                            <div className="BottomCardTitleLine">
                                {locName}
                            </div>
                        </div>
                        <div className={"BottomCardReadingBody powertextcolor " + listfocus_inner}>
                            <div className="flex flex-row justify-between BottomCardReadingLine">
                                {
                                    isFull!=null?
                                    <div className="flex flex-row BottomCardReadingDisplay" style={{color: isFull&&(currentBottomCard!==cardnumber_focus)?'red':''}}>
                                        {isFull?'Full':'Empty'}
                                    </div>
                                    :
                                    <div className="flex flex-row BottomCardReadingDisplay">
                                        {
                                            fillDistance!=null?
                                                fillDistance.toFixed(1)
                                                :
                                                fillPercentage.toFixed(1)
                                        }
                                        <div className="flex flex-row BottomCardReadingSymbol">
                                            %
                                        </div>
                                    </div>
                                }
                                <i className="fa fa-trash BottomCardIcon" style={fontsize}></i>
                            </div>
                        </div>
                    </div>
                </div>
            }
            if(cardType == "People Count" || cardType == 'PeopleflowSensor' || cardType == 'People Flow Sensor') {
                if(deviceListItem == null || deviceListItem.payload == null) return <div className={"flex flex-col justify-between card " + listfocus} onMouseEnter={ () => this.bottomcardhover(cardnumber) } id={ "bottomcard_" + cardnumber } >No Data</div>

                const payload = deviceListItem.payload
                return <div className={"flex flex-col justify-between cardScrollable " + listfocus} onMouseEnter={ () => this.bottomcardhover(cardnumber) } id={ "bottomcard_" + cardnumber } >
                    <div className="peopleCountsize">
                        <div className="BottomCardTitle">
                            <div className="BottomCardTitleLine people_header">
                                {locName}
                            </div>
                        </div>
                        <div className={"BottomCardReadingBody peopleCountInner" + listfocus_inner}>
                            <div className={"peopleCount_row PeopleIn " + listfocus_inner}>
                                <div className="peopleCount_reading">
                                    <div className="peopleCount_title">In</div>
                                    {/* there are two type of sensors, with different payload reading values */}
                                    <div className="peopleCount_Value">{(payload.in==null)?"0":payload.in}</div>
                                </div>
                                <div className="fa fa-users peopleCountIcon"></div>
                            </div>
                            <div className={"peopleCount_row PeopleOut " + listfocus_inner}>
                                <div className="peopleCount_reading">
                                    <div className="peopleCount_title">Out</div>
                                    <div className="peopleCount_Value">{(payload.out==null)?"0":payload.out}</div>
                                </div>
                                <div className="fa fa-users peopleCountIcon"></div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            if((cardType == "Water Leakage" || cardType == "Water Leakage (Point)") && !isConvertWaterLeakageToWaterPump(this.props)) {
                if(deviceListItem == null || deviceListItem.payload == null) return <div className={"flex flex-col justify-between cardScrollable " + listfocus} onMouseEnter={ () => this.bottomcardhover(cardnumber) } id={ "bottomcard_" + cardnumber } >
                    <div className="BottomCardInnerDiv">
                        <div className="BottomCardTitle">
                            <div className="BottomCardTitleLine">
                                {locName}
                            </div>
                        </div>
                        No Data
                    </div>
                </div>

                const payload = deviceListItem.payload
                const isWaterLeakage = (payload.waterLeakageStatus == true || payload.waterLeakageStatus == 1)
                const innerHighlightclass = (isWaterLeakage)?" leakageAlarm ":""
                return <div className={
                    (isWaterLeakage)?
                        "flex flex-col justify-between cardScrollable leakageAlarm " + listfocus
                        :
                        "flex flex-col justify-between cardScrollable " + listfocus
                    }
                        onMouseEnter={ () => this.bottomcardhover(cardnumber) }
                        id={ "bottomcard_" + cardnumber }
                        style={{boxShadow: isWaterLeakage && listfocus!==''?
                        '0 0px 10px 0 red, 0 3px 5px 0 rgba(0, 0, 0, 0.05), 0px -400px 0 0 red inset'
                        :''}}
                >
                    <div className="BottomCardInnerDiv" style={{width: deviceListItem.locName.length>=18?'90%':'65%' }}>
                        <div className="BottomCardTitle">
                            <div className="BottomCardTitleLine">
                                {locName}
                            </div>
                            <div className='locDescForBottomCard'>
                                {locDesc}
                            </div>
                        </div>
                        <div className={"BottomCardReadingBody powertextcolor " + innerHighlightclass + listfocus_inner}>
                            <div className="flex flex-row justify-between BottomCardReadingLine">
                                <div className="flex flex-row BottomCardReadingDisplay waterleakTxt">
                                    {isWaterLeakage?waterLeakage('Alarm'):waterLeakage('Normal')}
                                    <div className="flex flex-row BottomCardReadingSymbol">
                                    </div>
                                </div>
                                <div className={isWaterLeakage?"BottomCard_waterLeakageTrue":"BottomCard_waterLeakage"}></div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            if(cardType == "Door Contact") {
                if(CurrentDeviceItem.payload == null || CurrentDeviceItem.payload.doorOpen == null)  return <div className={"DefaultCardSize " + listfocus} onMouseEnter={ () => this.bottomcardhover(cardnumber) } id={ "bottomcard_" + cardnumber }>
                    <div className="card_innerContainer">
                        <div className={"" + listfocus_inner}>
                            {locName}
                        </div>
                        <div className="Card_readingContainer vibrate_container">
                            <div className="vibrate_row">
                                <div className="vibreate_alarmContainer">
                                    <div className="DoorStatusInner">
                                        No Data
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                const DoorStatus = (CurrentDeviceItem.payload.doorOpen == 1)    //open = true
                
                const {lastTriggerTime} = deviceListItem.payload
                const now = new Date()
                const lastTriggerTime_Date = Date.parse(lastTriggerTime)
                let min = (Math.floor((now - lastTriggerTime_Date)/60000))
                let alarmStatus = false //false = no alarm
                if(min == undefined || min == null || min.toString() == 'NaN') min = "--"
                else if(min > 40) alarmStatus = true

                return <div className={"DefaultCardSize " + listfocus} onMouseEnter={ () => this.bottomcardhover(cardnumber) } id={ "bottomcard_" + cardnumber }>
                    <div className="card_innerContainer">
                        <div className={"" + listfocus_inner}>
                            {locName}
                        </div>
                        <div className="Card_readingContainer vibrate_container">
                            <div className="vibrate_row">
                                <div className={(DoorStatus)?"vibreate_alarmContainer OpenDoor":"vibreate_alarmContainer OpenClose"}>
                                    <div className="DoorStatusInner">
                                        {
                                            (DoorStatus)?"Open":"Close"
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={(alarmStatus)?"DoorContactItem DoorOccAlarm":"DoorContactItem"}>
                                <div className="DoorContactInner">
                                {
                                    (alarmStatus)?"Occ Alarm (40min)":"No Occ Alarm"
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            if(cardType == "Door") {
                if(CurrentDeviceItem.payload == null || CurrentDeviceItem.payload.doorOpen == null)  return <div className={"DefaultCardSize " + listfocus} onMouseEnter={ () => this.bottomcardhover(cardnumber) } id={ "bottomcard_" + cardnumber }>
                    <div className="card_innerContainer">
                        <div className={"" + listfocus_inner}>
                            {locName}
                        </div>
                        <div className="Card_readingContainer vibrate_container">
                            <div className="vibrate_row">
                                <div className="vibreate_alarmContainer">
                                    <div className="DoorStatusInner">
                                        No Data
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                const DoorStatus = (CurrentDeviceItem.payload.doorOpen == 1)    //open = true

                return <div className={"DefaultCardSize " + listfocus} onMouseEnter={ () => this.bottomcardhover(cardnumber) } id={ "bottomcard_" + cardnumber }>
                    <div className="card_innerContainer">
                        <div className={"" + listfocus_inner}>
                            {locName}
                        </div>
                        <div className='locDescForBottomCard'>
                            {locDesc}
                        </div>
                        <div className="Card_readingContainer vibrate_container">
                            <div className="vibrate_row">
                                <div className={(DoorStatus)?"vibreate_alarmContainer OpenDoor":"vibreate_alarmContainer OpenClose"}>
                                    <div className="DoorStatusInner">
                                        {
                                            (DoorStatus)?doorStatus('Open'):doorStatus('Close')
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            if(cardType == "Vibration") { // TODO: update Vibration Card CSS
                const isAbnormal = (CurrentDeviceItem.payload.abnormalVibration == 1)    //open = true
                const {temperature} = CurrentDeviceItem.payload

                return <div className={"DefaultCardSize " + listfocus} onMouseEnter={ () => this.bottomcardhover(cardnumber) } id={ "bottomcard_" + cardnumber }>
                    <div className="card_innerContainer">
                        <div className={"" + listfocus_inner}>
                            {locName}
                        </div>
                        <div className="Card_readingContainer vibrate_container">
                            <div className="vibrate_row">
                                <div className={(isAbnormal)?"vibreate_alarmContainer HaveVibrate":"vibreate_alarmContainer NoVibrate"}>
                                    <div className="DoorStatusInner">
                                        {
                                            (isAbnormal)?"Abnormal":"Normal"
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            temperature!=null?<div className='vibrate_temp'>
                                <div className='title'>Temp</div>
                                <div className='value'>
                                    {temperature.toFixed(1)}
                                    <div className='unit'>
                                        <div className='unit2'>o</div>
                                        C
                                    </div>
                                </div>
                            </div>
                            :
                            <div style={{display: 'none'}}></div>
                        }
                    </div>
                </div>
            }
            if(cardType == "Push Button") {
                const Status = (CurrentDeviceItem.payload.isAlert == 1)
                return <div className={"DefaultCardSize " + listfocus} onMouseEnter={ () => this.bottomcardhover(cardnumber) } id={ "bottomcard_" + cardnumber }>
                    <div className="card_innerContainer">
                        <div className={"" + listfocus_inner}>
                            {locName}
                        </div>
                        <div className="Card_readingContainer vibrate_container">
                            <div className="vibrate_row">
                                <div className={(Status)?"vibreate_alarmContainer HaveVibrate":"vibreate_alarmContainer NoVibrate"}>
                                    <div className="DoorStatusInner">
                                        {
                                            (Status)?"Emergency":"Normal"
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            if(cardType == "LED Tube" || nameID == "LED Tube") {
                const Status = (CurrentDeviceItem.payload.isAlert == 1)
                return <div className={"DefaultCardSize " + listfocus} onMouseEnter={ () => this.bottomcardhover(cardnumber) } id={ "bottomcard_" + cardnumber }>
                    <div className="card_innerContainer">
                        <div className={"" + listfocus_inner}>
                            {locName}
                        </div>
                        <div className="Card_readingContainer">
                            <LEDCard listfocus_inner={listfocus_inner} Data={CurrentDeviceItem} />
                        </div>
                    </div>
                </div>
            }
            if(cardType == 'Disabled Parking') {
                let OccupancyStatus = false
                try {
                    OccupancyStatus = CurrentDeviceItem.payload.occupied
                }
                catch(err) {}
                
                let bottomCardOccupancy = ""
                let occuLbl = ""
                if(OccupancyStatus==true||OccupancyStatus=='occupied') {
                    bottomCardOccupancy = "bottomCardOccupancyTrue"
                    occuLbl = DisablePark('Occupy')
                }
                else if(OccupancyStatus == false) {
                    bottomCardOccupancy = "bottomCardOccupancyFalse"
                    occuLbl = DisablePark('Not Occupy')
                }
                else {
                    bottomCardOccupancy = "bottomCardOccupancyFalse"
                    occuLbl = DisablePark('Disconnected')
                }

                return <div className={"flex flex-col justify-between cardScrollable " + listfocus} onMouseEnter={ () => this.bottomcardhover(cardnumber) } id={ "bottomcard_" + cardnumber } >
                    <div className="BottomCardInnerDiv">
                        <div className="BottomCardTitle">
                            <div className="BottomCardTitleLine">
                                {locName}
                            </div>
                            <div className='locDescForBottomCard'>
                                {locDesc}
                            </div>
                        </div>
                        <div className={"BottomCardReadingBody peopletextcolor " + listfocus_inner}>
                            <div className="flex flex-row justify-between BottomCardReadingLine">
                                <div className={"flex flex-row BottomCardReadingDisplay occuLbl " + bottomCardOccupancy} style={{color: (currentBottomCard === cardnumber_focus)?'white':''}}>
                                    {occuLbl}
                                    <div className="flex flex-row BottomCardReadingSymbol"></div>
                                </div>
                                <i className={'fa fa-wheelchair BottomCardIcon ' + bottomCardOccupancy} style={{
                                    fontSize: '2rem',
                                    margin: 'auto',
                                    marginRight: 0,
                                    color: (currentBottomCard === cardnumber_focus)?'white':''
                                }}></i>
                            </div>
                        </div>
                    </div>
                </div>
            }
            if(cardType == 'Car Park') {
                let OccupancyStatus = false
                try {
                    OccupancyStatus = CurrentDeviceItem.payload.occupied
                }
                catch(err) {}
                
                let bottomCardOccupancy = ""
                let occuLbl = ""
                if(OccupancyStatus==true||OccupancyStatus=='occupied') {
                    bottomCardOccupancy = "bottomCardOccupancyTrue"
                    occuLbl = CarPark('Occupy')
                }
                else if(OccupancyStatus == false) {
                    bottomCardOccupancy = "bottomCardOccupancyFalse"
                    occuLbl = CarPark('Not Occupy')
                }
                else {
                    bottomCardOccupancy = "bottomCardOccupancyFalse"
                    occuLbl = CarPark('Disconnected')
                }

                return <div className={"flex flex-col justify-between cardScrollable " + listfocus} onMouseEnter={ () => this.bottomcardhover(cardnumber) } id={ "bottomcard_" + cardnumber } >
                    <div className="BottomCardInnerDiv">
                        <div className="BottomCardTitle">
                            <div className="BottomCardTitleLine">
                                {locName}
                            </div>
                            <div className='locDescForBottomCard'>
                                {locDesc}
                            </div>
                        </div>
                        <div className={"BottomCardReadingBody peopletextcolor " + listfocus_inner}>
                            <div className="flex flex-row justify-between BottomCardReadingLine">
                                <div className={"flex flex-row BottomCardReadingDisplay occuLbl " + bottomCardOccupancy} style={{color: (currentBottomCard === cardnumber_focus)?'white':''}}>
                                    {occuLbl}
                                    <div className="flex flex-row BottomCardReadingSymbol"></div>
                                </div>
                                <i className={'fa fa-car BottomCardIcon ' + bottomCardOccupancy} style={{
                                    fontSize: '2rem',
                                    margin: 'auto',
                                    marginRight: 0,
                                    color: (currentBottomCard === cardnumber_focus)?'white':''
                                }}></i>
                            </div>
                        </div>
                    </div>
                </div>
            }
            if(cardType == 'Water Level') {
                return <WaterLevelCard
                    item={CurrentDeviceItem}
                    isFocus={currentBottomCard===cardnumber_focus}
                    hoverFunc={() => this.bottomcardhover(cardnumber)}
                    cardnumber={cardnumber}
                />
            }
            if(cardType == 'Soap Level' || cardType == 'Soap') {
                return <SoapCard
                    item={CurrentDeviceItem}
                    isFocus={currentBottomCard===cardnumber_focus}
                    hoverFunc={() => this.bottomcardhover(cardnumber)}
                    cardnumber={cardnumber}
                />
            }
            if(cardType == 'Toilet Paper') {
                return <ToiletPaperCard
                    item={CurrentDeviceItem}
                    isFocus={currentBottomCard===cardnumber_focus}
                    hoverFunc={() => this.bottomcardhover(cardnumber)}
                    cardnumber={cardnumber}
                />
            }
            if(cardType == 'PMC') {
                const size = [24, 24, 0]
                return <ValueCard
                    dataFields = {[
                        {
                            isBool: false,
                            strKey: 'acPower',
                            icon: null,
                            color: 'brown',
                            fontSize: size,
                            unit: 'kwh',
                            title: 'Real Energy'
                        },
                        {
                            isBool: false,
                            strKey: 'i1_DMD',
                            icon: null,
                            color: 'brown',
                            fontSize: size,
                            unit: 'A',
                            title: 'Current 1'
                        },
                        {
                            isBool: false,
                            strKey: 'i2_DMD',
                            icon: null,
                            color: 'brown',
                            fontSize: size,
                            unit: 'A',
                            title: 'Current 2'
                        },
                        {
                            isBool: false,
                            strKey: 'i3_DMD',
                            icon: null,
                            color: 'brown',
                            fontSize: size,
                            unit: 'A',
                            title: 'Current 3'
                        },
                        //new items
                        {
                            isBool: false,
                            strKey: 'kVAh',
                            icon: null,
                            color: 'brown',
                            fontSize: size,
                            unit: 'kVAh',
                            title: 'Apparent'
                        },
                        {
                            isBool: false,
                            strKey: 'kWhExp',
                            icon: null,
                            color: 'brown',
                            fontSize: size,
                            unit: 'kWh',
                            title: 'Energy'
                        },
                        {
                            isBool: false,
                            strKey: 'kvarhExp',
                            icon: null,
                            color: 'brown',
                            fontSize: size,
                            unit: 'kvarh',
                            title: 'kvarhExp'
                        },
                        {
                            isBool: false,
                            strKey: 'kvarhImp',
                            icon: null,
                            color: 'brown',
                            fontSize: size,
                            unit: 'kvarh',
                            title: 'kvarhImp'
                        },
                        {
                            isBool: false,
                            strKey: 'p_DMD',
                            icon: null,
                            color: 'brown',
                            fontSize: size,
                            unit: 'W',
                            title: 'p_DMD'
                        },
                        {
                            isBool: false,
                            strKey: 'q_DMD',
                            icon: null,
                            color: 'brown',
                            fontSize: size,
                            unit: 'W',
                            title: 'q_DMD'
                        },
                        {
                            isBool: false,
                            strKey: 's_DMD',
                            icon: null,
                            color: 'brown',
                            fontSize: size,
                            unit: 'W',
                            title: 's_DMD'
                        },
                    ]}
                    deviceItem={CurrentDeviceItem}
                    isFocus={currentBottomCard===cardnumber_focus}
                    hoverFunc={() => this.bottomcardhover(cardnumber)}
                    cardnumber={cardnumber}
                    cardW={50}
                />
            }
            if(cardType == 'Water Quality') {
                return <ValueCard
                    dataFields={[
                        {
                            isBool: false,
                            strKey: 'pH',
                            icon: null,
                            color: 'rgba(9, 58, 197, 0.8)',
                            fontSize: [24, 24, 0],
                            unit: ' pH',
                            title: 'pH'
                        },
                        {
                            isBool: false,
                            strKey: 'temperature',
                            // icon: 'thermometer-three-quarters',
                            color: 'rgba(221, 2, 0, 0.8)',
                            fontSize: [24, 24, 0],
                            unit: '°C',
                            title: 'Temp'
                        }
                    ]}
                    deviceItem={CurrentDeviceItem}
                    isFocus={currentBottomCard===cardnumber_focus}
                    hoverFunc={() => this.bottomcardhover(cardnumber)}
                    cardnumber={cardnumber}
                />
            }
            if(cardType == 'Outdoor Environment Quality') {
                return <ValueCard
                    dataFields={[
                        {
                            isBool: false,
                            strKey: 'co2',
                            icon: null,
                            color: 'rgba(123, 123, 123, 1)',
                            fontSize: [24, 24, 0],
                            unit: ' ppmv',
                            title: 'CO2'
                        },
                        {
                            isBool: false,
                            strKey: 'humidity',
                            icon: null,
                            color: 'rgba(0, 114, 255, 1)',
                            fontSize: [24, 24, 0],
                            unit: '%',
                            title: 'Humid'
                        },
                        {
                            isBool: false,
                            strKey: 'temperature',
                            icon: null,
                            color: 'rgba(221, 2, 0, 0.8)',
                            fontSize: [24, 24, 0],
                            unit: '°C',
                            title: 'Temp'
                        }
                    ]}
                    deviceItem={CurrentDeviceItem}
                    isFocus={currentBottomCard===cardnumber_focus}
                    hoverFunc={() => this.bottomcardhover(cardnumber)}
                    cardnumber={cardnumber}
                    cardW={20}
                />
            }
            if(cardType == 'Outdoor Environment Quality (Wind)') {
                const size = [24, 24, 0]
                return <ValueCard
                    dataFields={[
                        {
                            isBool: false,
                            strKey: 'temperature',
                            icon: null,
                            color: 'rgba(221, 2, 0, 0.8)',
                            fontSize: size,
                            unit: '°C',
                            title: 'Temp'
                        },                        
                        {
                            isBool: false,
                            strKey: 'humidity',
                            icon: null,
                            color: 'rgba(0, 114, 255, 1)',
                            fontSize: size,
                            unit: '%',
                            title: 'Humid'
                        },                        
                        {
                            isBool: false,
                            strKey: 'windSpeed',
                            icon: null,
                            color: 'rgba(0, 120, 133, 1)',
                            fontSize: size,
                            unit: 'km/hr',
                            title: 'Wind'
                        },
                        {
                            isBool: false,
                            strKey: 'windDirection',
                            icon: null,
                            color: 'rgba(0, 120, 133, 1)',
                            fontSize: size,
                            unit: '',
                            title: 'Direction'
                        }
                    ]}
                    deviceItem={CurrentDeviceItem}
                    isFocus={currentBottomCard===cardnumber_focus}
                    hoverFunc={() => this.bottomcardhover(cardnumber)}
                    cardnumber={cardnumber}
                    cardW={20}
                />
            }
            if(cardType == 'CCTV') {
                return <ValueCard
                    dataFields={[]}
                    deviceItem={CurrentDeviceItem}
                    isFocus={currentBottomCard===cardnumber_focus}
                    hoverFunc={() => this.bottomcardhover(cardnumber)}
                    cardnumber={cardnumber}
                />
            }
            if(cardType == 'Thermocouple') {
                return <ValueCard
                    dataFields={[
                        {
                            isBool: false,
                            strKey: 'temperature',
                            icon: '',
                            color: '#f30000',
                            fontSize: [30, 30, 0],
                            unit: 'C',
                            title: 'Temp'
                        }
                    ]}
                    deviceItem={CurrentDeviceItem}
                    isFocus={currentBottomCard===cardnumber_focus}
                    hoverFunc={() => this.bottomcardhover(cardnumber)}
                    cardnumber={cardnumber}
                    cardW={16}
                />
            }
            if(cardType == 'Reed Switch Detection') {
                return <ValueCard
                    dataFields={[
                        {
                            isBool: true,
                            strKey: 'isOpened',
                            icon: null,
                            fontSize: [25, 0, 0],
                            unit: '',
                            title: 'Detection',
                            trueDisplay: 'Open',
                            falseDisplay: 'Close',
                            colorTrue: 'grey',
                            colorFalse: 'grey'
                        }
                    ]}
                    deviceItem={CurrentDeviceItem}
                    isFocus={currentBottomCard===cardnumber_focus}
                    hoverFunc={() => this.bottomcardhover(cardnumber)}
                    cardnumber={cardnumber}
                    cardW={16}
                />
            }
            if(isConvertWaterLeakageToWaterPump({...this.props,nameID}) || cardType == 'Water Pump') {
                const  hoverFunc = (this.props.LocationName.includes('TCP-WP')) ? () => {
                    this.props.dispatch({type: 'bottomcardhover', data: 'bottomcard_' + this.props.cardnumber})
                    const {LocationName} = this.props
                    this.props.dispatch({ type: 'PickPolygon', 
                        data: LocationName
                    })
                }: () => this.bottomcardhover(cardnumber)

                return <ValueCard
                    dataFields={[
                        {
                            isBool: true,
                            strKey: 'waterLeakageStatus',
                            icon: 'tachometer',
                            color: '',
                            fontSize: [20, 20, 35],
                            unit: '',
                            title: '',
                            trueDisplay: 'Fault',
                            falseDisplay: 'Normal',
                            colorTrue: 'red',
                            colorFalse: 'green'
                        }
                    ]}
                    cardW={15}
                    deviceItem={CurrentDeviceItem}
                    isFocus={currentBottomCard===cardnumber_focus}
                    hoverFunc={hoverFunc}
                    cardnumber={cardnumber}
                />
            }
            if(cardType == 'IAQ16in1' || nameID == 'IAQ 15in1') {
                const size = [15, 15, 0]
                return <ValueCard
                    dataFields={[
                        {
                            isBool: false,
                            strKey: 'temperature',
                            icon: '',
                            color: 'black',
                            fontSize: size,
                            unit: '',
                            title: 'Temp'
                        },
                        {
                            isBool: false,
                            strKey: 'humidity',
                            icon: '',
                            color: 'black',
                            fontSize: size,
                            unit: '',
                            title: 'Humid'
                        },
                        {
                            isBool: false,
                            strKey: 'co',
                            icon: '',
                            color: 'black',
                            fontSize: size,
                            unit: '',
                            title: 'CO'
                        },
                        {
                            isBool: false,
                            strKey: 'co2',
                            icon: '',
                            color: 'black',
                            fontSize: size,
                            unit: '',
                            title: 'CO2'
                        },
                        {
                            isBool: false,
                            strKey: 'pm1_0',
                            icon: '',
                            color: 'black',
                            fontSize: size,
                            unit: '',
                            title: 'PM1.0'
                        },
                        {
                            isBool: false,
                            strKey: 'pm2_5',
                            icon: '',
                            color: 'black',
                            fontSize: size,
                            unit: '',
                            title: 'PM2.5'
                        },
                        {
                            isBool: false,
                            strKey: 'pm10',
                            icon: '',
                            color: 'black',
                            fontSize: size,
                            unit: '',
                            title: 'PM10'
                        },
                        {
                            isBool: false,
                            strKey: 'no2',
                            icon: '',
                            color: 'black',
                            fontSize: size,
                            unit: '',
                            title: 'NO2'
                        },
                        {
                            isBool: false,
                            strKey: 'nh3',
                            icon: '',
                            color: 'black',
                            fontSize: size,
                            unit: '',
                            title: 'NH3'
                        },
                        {
                            isBool: false,
                            strKey: 'hcho',
                            icon: '',
                            color: 'black',
                            fontSize: size,
                            unit: '',
                            title: 'HCHO'
                        },
                        {
                            isBool: false,
                            strKey: 'h2s',
                            icon: '',
                            color: 'black',
                            fontSize: nameID=='IAQ 15in1'?[0,0,0]:size,
                            unit: '',
                            title: 'H2S'
                        },
                        {
                            isBool: false,
                            strKey: 'o3',
                            icon: '',
                            color: 'black',
                            fontSize: size,
                            unit: '',
                            title: 'O3'
                        },
                        {
                            isBool: false,
                            strKey: 'illuminance',
                            icon: '',
                            color: 'black',
                            fontSize: size,
                            unit: '',
                            title: 'Illumin'
                        },
                        {
                            isBool: false,
                            strKey: 'noiseLevel',
                            icon: '',
                            color: 'black',
                            fontSize: size,
                            unit: '',
                            title: 'Noise Lv.'
                        },
                        {
                            isBool: false,
                            strKey: 'tvoc',
                            icon: '',
                            color: 'black',
                            fontSize: size,
                            unit: '',
                            title: 'TVOC'
                        },
                        {
                            isBool: false,
                            strKey: 'motion',
                            icon: '',
                            color: 'black',
                            fontSize: size,
                            unit: '',
                            title: 'Motion'
                        }
                    ]}
                    deviceItem={CurrentDeviceItem}
                    isFocus={currentBottomCard===cardnumber_focus}
                    hoverFunc={() => this.bottomcardhover(cardnumber)}
                    cardnumber={cardnumber}
                    cardW={40}
                />
            }
            if(cardType == 'Netvox_ADC') {
                const size = [20, 20, 0]
                const {UserInfo} = this.props
                const {client_id} = UserInfo
                if(client_id === '62946538e522c897b5d126d4') {
                    return <ValueCard
                        dataFields={[
                            {
                                isBool: false,
                                strKey: 'airflow',
                                icon: '',
                                color: 'black',
                                fontSize: size,
                                unit: 'L/s',//'m³/s',
                                title: 'Air Flow'
                            }
                        ]}
                        deviceItem={CurrentDeviceItem}
                        isFocus={currentBottomCard===cardnumber_focus}
                        hoverFunc={() => this.bottomcardhover(cardnumber)}
                        cardnumber={cardnumber}
                        cardW={15}
                    />
                }
                return <ValueCard
                    dataFields={[
                        // {
                        //     isBool: false,
                        //     strKey: 'adcRawValue2',
                        //     icon: '',
                        //     color: 'black',
                        //     fontSize: size,
                        //     unit: 'V',
                        //     title: 'Voltage'
                        // },
                        // {
                        //     isBool: false,
                        //     strKey: 'fineCurrent',
                        //     icon: '',
                        //     color: 'black',
                        //     fontSize: size,
                        //     unit: 'mA',
                        //     title: 'Current'
                        // },
                        {
                            isBool: false,
                            strKey: 'airflow',
                            icon: '',
                            color: 'black',
                            fontSize: size,
                            unit: 'kPa',
                            title: 'Air Flow'
                        },
                        // {
                        //     isBool: true,
                        //     strKey: 'status',
                        //     icon: null,
                        //     fontSize: [15, 0, 0],
                        //     unit: '',
                        //     title: 'Dry Contact',
                        //     trueDisplay: 'Open',
                        //     falseDisplay: 'Close',
                        //     colorTrue: 'grey',
                        //     colorFalse: 'red'
                        // }
                    ]}
                    deviceItem={CurrentDeviceItem}
                    isFocus={currentBottomCard===cardnumber_focus}
                    hoverFunc={() => this.bottomcardhover(cardnumber)}
                    cardnumber={cardnumber}
                    cardW={15}
                />
            }
            //new items for swimming pool
            // if(cardType=='Water Quality Sensor') {
            //     return <ValueCard
            //         dataFields={[
            //             {
            //                 isBool: false,
            //                 strKey: 'chorine',
            //                 icon: '',
            //                 color: 'grey',
            //                 fontSize: [15, 15, 15],
            //                 unit: 'ppm',
            //                 title: 'Chorine'
            //             },
            //             {
            //                 isBool: false,
            //                 strKey: 'pH',
            //                 icon: '',
            //                 color: 'grey',
            //                 fontSize: [15, 15, 15],
            //                 unit: 'pH',
            //                 title: 'pH'
            //             },
            //             {
            //                 isBool: false,
            //                 strKey: 'temperature',
            //                 icon: '',
            //                 color: 'grey',
            //                 fontSize: [15, 15, 15],
            //                 unit: 'C',
            //                 title: 'Temp'
            //             },
            //             {
            //                 isBool: false,
            //                 strKey: 'dissolvedSolids',
            //                 icon: '',
            //                 color: 'grey',
            //                 fontSize: [15, 15, 15],
            //                 unit: 'ppt',
            //                 title: 'Dissolved Solids'
            //             },
            //             {
            //                 isBool: false,
            //                 strKey: 'turbidity',
            //                 icon: '',
            //                 color: 'grey',
            //                 fontSize: [15, 12, 15],
            //                 unit: 'NTU',
            //                 title: 'Turbidity'
            //             },
            //             {
            //                 isBool: false,
            //                 strKey: 'lastUpdate',
            //                 icon: '',
            //                 color: 'grey',
            //                 fontSize: [15, 15, 15],
            //                 unit: '--',
            //                 title: 'Last Update'
            //             }
            //         ]}
            //         deviceItem={CurrentDeviceItem}
            //         isFocus={currentBottomCard===cardnumber_focus}
            //         hoverFunc={() => this.bottomcardhover(cardnumber)}
            //         cardnumber={cardnumber}
            //         cardW={16}
            //         lastUpdate='08/04 13:31' //..
            //     />
            // }
            // if(cardType=='Plant Status') {
            //     return <PlantBotCard />
            // }
            // if(cardType=='Automatic Control Mode') {
            //     return <BotControlMode />
            // }
            // if(cardType=='Flow Meter') {
            //     return <ValueCard
            //         dataFields={[
            //             {
            //                 isBool: false,
            //                 strKey: 'flow',
            //                 icon: '',
            //                 color: 'black',
            //                 fontSize: [35, 15, 15],
            //                 unit: 'm3/hr',
            //                 title: ''
            //             }
            //         ]}
            //         deviceItem={CurrentDeviceItem}
            //         isFocus={currentBottomCard===cardnumber_focus}
            //         hoverFunc={() => this.bottomcardhover(cardnumber)}
            //         cardnumber={cardnumber}
            //         cardW={16}
            //         lastUpdate='08/04 13:31' //..
            //     />
            // }
            // if(cardType=='Carbon Detection Sensor') {
            //     return <BotCarbonDetect />
            // }
            // if(cardType=='Pump Statistic') {
            //     return <ValueCard
            //         dataFields={[
            //             {
            //                 isBool: false,
            //                 strKey: 'vsd',
            //                 icon: '',
            //                 color: 'black',
            //                 fontSize: [15, 15, 15],
            //                 unit: 'Hz',
            //                 title: 'VSD Feedback'
            //             },
            //             {
            //                 isBool: false,
            //                 strKey: 'sppedHigh',
            //                 icon: '',
            //                 color: 'black',
            //                 fontSize: [15, 15, 15],
            //                 unit: 'Hz',
            //                 title: 'Speed High Limit'
            //             },
            //             {
            //                 isBool: false,
            //                 strKey: 'speedLow',
            //                 icon: '',
            //                 color: 'black',
            //                 fontSize: [15, 15, 15],
            //                 unit: 'Hz',
            //                 title: 'Speed Low Limit'
            //             }
            //         ]}
            //         deviceItem={CurrentDeviceItem}
            //         isFocus={currentBottomCard===cardnumber_focus}
            //         hoverFunc={() => this.bottomcardhover(cardnumber)}
            //         cardnumber={cardnumber}
            //         cardW={16}
            //         lastUpdate='08/04 13:31' //..
            //     />
            // }
            // if(cardType=='Pump Consumption') {
            //     return <ValueCard
            //         dataFields={[
            //             {
            //                 isBool: false,
            //                 strKey: 'power',
            //                 icon: '',
            //                 color: 'black',
            //                 fontSize: [14, 14, 14],
            //                 unit: 'kW',
            //                 title: 'Power Consump'
            //             },
            //             {
            //                 isBool: false,
            //                 strKey: 'energy',
            //                 icon: '',
            //                 color: 'black',
            //                 fontSize: [14, 14, 14],
            //                 unit: 'kWh',
            //                 title: 'Energy Consump'
            //             }
            //         ]}
            //         deviceItem={CurrentDeviceItem}
            //         isFocus={currentBottomCard===cardnumber_focus}
            //         hoverFunc={() => this.bottomcardhover(cardnumber)}
            //         cardnumber={cardnumber}
            //         cardW={16}
            //         lastUpdate='08/04 13:31' //..
            //     />
            // }
            // if(cardType=='Pump Status') {
            //     return <ValueCard
            //         dataFields={[
            //             {
            //                 isBool: true,
            //                 strKey: 'pump',
            //                 icon: null,
            //                 fontSize: [15, 15, 15],
            //                 unit: '',
            //                 title: 'Pump On/Off',
            //                 trueDisplay: 'On',
            //                 falseDisplay: 'Off',
            //                 colorTrue: 'grey',
            //                 colorFalse: 'grey'
            //             },
            //             {
            //                 isBool: true,
            //                 strKey: 'vsd',
            //                 icon: null,
            //                 fontSize: [15, 15, 15],
            //                 unit: '',
            //                 title: 'VSD On/Off',
            //                 trueDisplay: 'On',
            //                 falseDisplay: 'Off',
            //                 colorTrue: 'grey',
            //                 colorFalse: 'grey'
            //             },
            //             {
            //                 isBool: true,
            //                 strKey: 'vsdBypass',
            //                 icon: null,
            //                 fontSize: [15, 15, 15],
            //                 unit: '',
            //                 title: 'VSD Bypass On/Off',
            //                 trueDisplay: 'On',
            //                 falseDisplay: 'Off',
            //                 colorTrue: 'grey',
            //                 colorFalse: 'grey'
            //             },
            //             {
            //                 isBool: true,
            //                 strKey: 'powerHealth',
            //                 icon: null,
            //                 fontSize: [15, 15, 15],
            //                 unit: '',
            //                 title: 'Power Helathy',
            //                 trueDisplay: 'Normal',
            //                 falseDisplay: 'Fault',
            //                 colorTrue: 'grey',
            //                 colorFalse: 'grey'
            //             }
            //         ]}
            //         deviceItem={CurrentDeviceItem}
            //         isFocus={currentBottomCard===cardnumber_focus}
            //         hoverFunc={() => this.bottomcardhover(cardnumber)}
            //         cardnumber={cardnumber}
            //         cardW={16}
            //         lastUpdate='08/04 13:31' //..
            //     />
            // }
            // if(cardType == 'Energymeter' || cardType=='Energy Meter') {
            //     return <div>Energy Meter card</div>
            // }
            return <div className={"flex flex-col justify-between card cardScrollable " + listfocus} onMouseEnter={ () => this.bottomcardhover(cardnumber) } id={ "bottomcard_" + cardnumber } >
                Error: Card Type is undefined, type: {cardType + " " + nameID}
                nameID: {nameID}
            </div>
        }
        catch(err) {
            console.error('BottomDisplayCard.js check reading type failed')
            console.error(err)
            return <div className={"flex flex-col justify-between card cardScrollable " + listfocus} onMouseEnter={ () => this.bottomcardhover(cardnumber) } id={ "bottomcard_" + cardnumber } >
                Some thing is wrong ! {err.toString()}
            </div>
        }
    }
}

function mapStateToProps(state) {
    return {
      currentlevel: state.currentlevel,
      currentlevelID: state.currentlevelID,
      currentSite: state.currentSite,
      currentBottomCard: state.currentBottomCard,
      UserInfo: state.UserInfo,
      DeviceData: state.DeviceData,
      DeviceDataLastUpdate: state.DeviceDataLastUpdate,              //update this redux storage to trigger render of cards
      DeviceDataStatus: state.DeviceDataStatus
    }
}
export default connect(mapStateToProps)(BottomDisplayCards)

const excellentcolor = 'green'
const goodcolor = 'yellow'
function IAQTxtColor(type, valueStr, clientId, ishover) {
    if(ishover) return 'white'
    if(clientId != '60d00728633f3484443850bd') return           //central market requirement only
    if(valueStr==null||type==null) return
    const float = parseFloat(valueStr)
    if(float==NaN||float==undefined) return

    let goodLimit = 0
    let excellentLimit = 0
    let boundaryDirection = false   // false = downward
    switch(type) {
        case 'temp':
            goodLimit = null
            excellentLimit = null
            break
        case 'humid':
            goodLimit = null
            excellentLimit = null
            break
        case 'PM2.5':
            goodLimit = 25
            excellentLimit = 15
            break
        case 'PM10':
            goodLimit = 50
            excellentLimit = 30
            break
        case 'O3':
            goodLimit = 61
            excellentLimit = 51
            break
        case 'CO':
            goodLimit = 9
            excellentLimit = 6
            break
        case 'CO2':
            goodLimit = null
            excellentLimit = null
            break
        case 'O3':
            goodLimit = null
            excellentLimit = null
            break
        case 'TVOC':
            goodLimit = 600
            excellentLimit = 500
            break
    }
    if(goodLimit==null||excellentLimit==null) return
    
    if(boundaryDirection && float >= excellentLimit) return excellentcolor
    if(boundaryDirection && float >= goodLimit) return goodcolor
    if(!boundaryDirection && float <= excellentLimit) return excellentcolor 
    if(!boundaryDirection && float <= goodLimit) return goodcolor 
}