import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import './WorkOrderSummary.css'
import SearchBar from "./SearchBar";
import TableHead from "./TableHead";
import Table from "./Table";
import { useHistory } from "react-router-dom";
import Downloadcsv from "../bottom/downloadcsv";
import Pagebtns from "../bottom/pagebtns";

const axios = require('axios');
function MmsWorkOrderSummary(props) {
    const history = useHistory()
    // const [isAdvanceSearch, setAdvanceSearch] = useState({
    //     isAdvanceSearch: false,
    //     AlarmList: null,
    //     rawAlarmList: null,
    //     //sorted result (by table head .js)
    //     sortedList: null,
    //     //filtered result (by searchbar.js)
    //     filteredList: null,
    //     //slice by page (by pages.js widget)
    //     pageSliceList: null,

    //     updateCount: 0
    // })
    const [ruleList, setRuleList] = useState([])
    const [filteredList, setFilteredList] = useState([])
    const [pageSliceList, setPageSliceList] = useState([])
    const [page, setPage] = useState(0)

    useEffect(() => {
        refresh()
    }, [])

    function refresh() {
        const RequestData_MaintenanceRule_List = {
            "options": {
            }
        }

        console.log("CPSL_MaintenanceRuleList send!")
        axios.post('https://47.52.20.40:31001/AtalAPI/v1/CPSL_MaintRule_List', RequestData_MaintenanceRule_List)
            .then(function (response) {
                console.log("CPSL_MaintenanceRuleList recv!")
                console.log(response);
                let list = response.data.info.map(item => {
                    return {
                        PMFrequency: item.repeatFrequency,
                        Works: item.description,
                        ServiceImpact: item.serviceSuspension,
                        Duration: Math.round(item.maintenanceDuration / 36000.0) / 100.0 + 'hr',
                        Vendor: item.vendorName,
                        LastMaintDate: item.lastTriggerDate,
                        LastMaintType: item.type,
                        NextMaintDate: item.nextScheduleDate,
                        NextMaintType: "Periodic",
                        ruleID: item.rule_id,
                        DeviceType: item.deviceType
                    }
                })
                setRuleList(list)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function UpdateList(key, list) {
        if (key === "pageSliceList")
            setPageSliceList(list)
        else setFilteredList(list)
    }

    const CsvHeaders = [
        {
            key: 'PMFrequency',
            label: 'PM Frequency',
        },
        {
            key: 'Works',
            label: 'Works to do',
        },
        {
            key: 'ServiceImpact',
            label: 'Service Impact',
        },
        {
            key: 'Duration',
            label: 'Duration',
        },
        {
            key: 'Vendor',
            label: 'Vendor',
        },
        {
            key: 'LastMaintDate',
            label: 'Last Maint. Date',
        },
        {
            key: 'LastMaintType',
            label: 'Maint. Type',
        },
        {
            key: 'NextMaintDate',
            label: 'Next Maint. Date',
        },
        {
            key: 'NextMaintType',
            label: 'Maint. Date',
        }
    ]
    return <div className="workOrderList">
        <SearchBar sortedList={ruleList} UpdateList={UpdateList} refresh={refresh} />
        <TableHead />
        {
            (pageSliceList != null && pageSliceList != undefined) ?
                pageSliceList.map(row => {
                    return <Table row={row} onClick={() => history.push({ pathname: "/Main/MmsWorkOrder", state: { "mode": "Update", "data": (row) } })} />
                }) : ""
        }
        <div className="ColdboxIndi_bottom">
            <Downloadcsv sortedList={ruleList} headers={CsvHeaders} filename='Device_WO_Summary.csv' />
            <Pagebtns sortedList={filteredList} UpdateList={UpdateList} />
        </div>
    </div>
}

function mapStateToProps(state) {
    return {
        socket: state.socket,
        DeviceDataLastUpdate: state.DeviceDataLastUpdate, //when this value is updated, render would triggered
        UserInfo: state.UserInfo,
        Notification: state.Notification,
    }
}
export default connect(mapStateToProps)(MmsWorkOrderSummary)